import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MarketingOffersService } from "../_services/marketing-offers.service";
import { ConfirmationService } from "primeng/api"
import { MatSnackBar } from '@angular/material/snack-bar';
// import {ConfirmDialogModule} from 'primeng/confirmdialog';

@Component({
  selector: 'app-marketting-offer-card',
  templateUrl: './marketing-offer-card.component.html',
  styleUrls: ['./marketing-offer-card.component.scss'],
  providers: [ConfirmationService]
})
export class MarketingOfferCardComponent implements OnInit {

  @Input() offerData;
  @Input() typeOffer: string;
  @Output() offerAction = new EventEmitter<any>();
  constructor(
    private offerService: MarketingOffersService,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    console.log(this.offerData)
  }
  deleteOffer(offer, index) {
    this.confirmationService.confirm({
      message: ` Are you sure that you want to delete ${offer.offerName} offer?`,
      header: 'Confirmation',
      // icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.offerAction.emit({ type: 'delete', index: index, eventType: offer.event });
      },
      reject: () => {
        console.log('rejected');
      }
    });

  }
  editOffer(offer) {
    this.offerAction.emit({ type: 'edit', offerDetails: offer });
  }

  changeOfferStatus(offerId, isActive) {
    let object: any = {};
    object['updatedOn'] = new Date();
    object['isActive'] = isActive
    this.offerService.updateOffer(offerId, object, '').then(resp => {
      this._snackBar.open("Offer updated sucessfuly !...", '', {
        horizontalPosition: 'center',
        verticalPosition: 'top', duration: 2000
      })
    })
    console.log(offerId, isActive)
  }
}
