<div class="view">
	<mat-card>
		<div>
			<form #configForm="ngForm" style="border:10ch" (ngSubmit)="saveCalendar()">
				<mat-card>
					<div class="row">
						<div class="col-md-6">
							<mat-form-field class="first-matcard-width"><input matInput placeholder='Calendar name'
									name="SlugEle" [(ngModel)]="calenderName" (change)="slugValidation()"
									matTooltip="Unique ID of calendar {{config.slug}}" class="example-full-width"
									required>
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field class="first-matcard-width dropdown">
								<mat-label>Timezone</mat-label>
								<mat-select [(value)]="selectedTimezone" (selectionChange)="changeTimezone()">
									<mat-option *ngFor="let timezone of tzSelected;let i=index"
										[value]="tzSelected[i].tzname">{{timezone.name}}</mat-option>

								</mat-select>
							</mat-form-field>
						</div>
					</div><br>

					<div class="row">
						<div class="col-md-6">
							<mat-form-field class="first-matcard-width"><input matInput
									placeholder='Email Associated With calendar' name="MsgEle"
									[(ngModel)]="config.owner_email" type="email"
									matTooltip="Email Associated With Calendar: {{config.owner_email}}" required>
							</mat-form-field><br>
						</div>
					</div>
				</mat-card>
				<mat-card class="fixedPosition">
					<div class="row">
						<div class="col-6">
							<mat-calendar [selected]="myFilter" [minDate]="min" [maxDate]="max"
								(selectedChange)="onSelect($event)" [dateFilter]="myFilter"
								style="height: 200px;width: 300px">
							</mat-calendar>
						</div>

						<div class="col-md-6 calander-space">
							<div class="row-3">
								<b>The Days on which you are open :</b><br><br>
								<mat-checkbox labelPosition='before'
									*ngFor="let option of checkboxOptions; let i = index" [name]="checkboxOptions[i]"
									[(ngModel)]="toggleDays[i].selected" style="margin: 11px"
									(change)="changeCalenderConstarint('myFilter')" required>{{option}}</mat-checkbox>
							</div><br>
							<div class="row-3">
								<div class="col-6" *ngIf="disableField==false">
									<mat-form-field>
										<input matInput [min]="minDate" [max]="maxDate" [matDatepickerFilter]="myFilter"
											[(ngModel)]="block_date" name="block_date" [matDatepicker]="picker"
											placeholder="Pick a date you want to block"
											(dateChange)="Blocked_Dates_Function()" disabled>
										<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-datepicker #picker disabled="false"></mat-datepicker>
									</mat-form-field>
								</div>
							</div><br>

							<div class="row-3 addvirticalscroll">
								<div *ngFor="let option of Blocked_Date;let i =index" class="row">
									<div class="col-6">{{option}}</div>
									<div class="col-6">
										<mat-icon (click)="Remove_Blocked_Dates(i)" class="icon-btn">
											remove_circle</mat-icon>
									</div>
								</div>
							</div><br>
							
							<div class="row-3">
								<div class="col">
									<div class="row-6" *ngIf="disableField==false">
										<div class="row">
											<div class="col-sd-6">
												<mat-form-field><input matInput placeholder="Lead Time" name="minDate"
														matTooltip="Lead Time" [(ngModel)]="config.minDate.key"
														(change)="changeCalenderConstarint('minDate')" required>
												</mat-form-field>&nbsp;&nbsp;
											</div>
											<div class="col-sd-6">

												<mat-label>Days</mat-label>

											</div>
										</div>
									</div>
									<div class="row-6" *ngIf="disableField==false">
										<div class="row">
											<div class="col-sd-6">
												<mat-form-field><input matInput placeholder="Rolling Period"
														name="maxDate" matTooltip="Rolling Periode"
														[(ngModel)]="config.maxDate.key"
														(change)="changeCalenderConstarint('maxDate')" required>
												</mat-form-field>&nbsp;&nbsp;
											</div>
											<div class="col-sd-6">

												<mat-label>Weeks</mat-label>

											</div>
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>
				</mat-card>

				<mat-card>
					<div class="row">
						<div class="col-6">
							<b>
								<p>{{timeslots.day}}</p>
							</b>
							<div *ngIf="timeslots.slots.length > 0">
								<b>
									<p>What time works for you?</p>
								</b>
								<div class="appointment_slot timeslotwidth " *ngFor="let timeslot of timeslots.slots">
									<p>{{timeslot.start}} - {{timeslot.end}}</p>

								</div>
							</div>
						</div>


						<div class="col-md-6">
							<div class="row-4">
								<br>
								<b>
									<mat-label>Set timeslots for</mat-label>
								</b><br>
								<mat-form-field>
									<mat-select [(value)]="day" name="day" (selectionChange)="changeTimeslot()">
										<mat-option *ngFor="let option of SelectDays;let i=index" [value]="i">{{option}}
										</mat-option>
									</mat-select>
								</mat-form-field>

							</div>
							<div class="row-4">
								<div class="row">
									<div class="col-4">

										<mat-form-field class="exy">
											<input matInput placeholder="From" [(ngModel)]="start" name="start">
										</mat-form-field>
									</div>
									<div class="col-md-2">
										<mat-form-field class="exy"><input matInput placeholder="To" [(ngModel)]="end"
												name="end"></mat-form-field>

									</div>
									<div class="col-md-4">

										<mat-icon (click)="Add_Time_Slot()" style="padding-left: 35px;" class="add-btn"
											class="icon-btn-service1">add_circle

										</mat-icon>
									</div>
								</div>
							</div>
							<br>
							<div class="row-md-4">
								<div class="row" *ngIf="TimeSlot[day].slots!=[]">
									<div *ngFor="let option of TimeSlot[day].slots;let j =index">
										<div class="col-sm">
											<div class="col" class="TimeSlot">
												<button mat-raised-button class="timeslot-button">
													{{TimeSlot[day].slots[j].start}}-{{TimeSlot[day].slots[j].end}}
												</button>

												<mat-icon (click)="Remove_Time_Slots(j)" class="icon-btn-service1">
													remove_circle</mat-icon>

											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</mat-card>
				<mat-card>
					<div class="row">

						<div class="col-md-6 ">
							<div class="col">
								<div class="row" class="service_font">
									<div *ngIf="service_enable==true">
										<div class="row-6">
											<mat-label><b>Please choose the treatments you are looking for:</b>
											</mat-label>
										</div><br>
										<div class="row-6">
											<section *ngFor="let service of this.config.services.provided; let i=index">
												<mat-checkbox name="{{this.config.services.provided[i]}}"
													onclick="return false"
													[(ngModel)]="this.config.services.provided[i]">{{service}}
												</mat-checkbox>
											</section>
										</div>
									</div>
								</div>
							</div>
						</div><br><br>
						<div class="col-md-6">
							<div class="row-4" style="margin:5px;padding:5px">
								<mat-label><b>Show services</b></mat-label>
								<mat-slide-toggle labelPosition='before' name="service_enable"
									[(ngModel)]="service_enable" (change)="changedServiceEnabled($event)"
									matTooltip="Services which are provided by clinic">
								</mat-slide-toggle>
							</div><br>

							<div class="row-4" *ngIf="service_enable==true">
								<div class=" row">
									<div class=" col-md-6">
										<mat-form-field style="margin: 10px">
											<input matInput placeholder="Services" name="SER" [(ngModel)]="input"
												matTooltip="Enter Services To Add">
										</mat-form-field>
									</div>
									<div class="col-md-6 row">
										<div class="col-4">
											<mat-icon (click)="service()" class="icon-btn-service2">
												add_circle</mat-icon>
										</div>
										<div class="col-4" class="icon-btn-service2">
											<mat-label>Delete All</mat-label>
											<button mat-icon-button (click)="Remove_All_services()" label="Delete All"
												type="submit" [disabled]="enable" name="try">
												<mat-icon style="font-size: 25px">delete</mat-icon>
											</button>
										</div>
									</div>
								</div>
							</div><br>

							<div class="row-4">
								<div *ngIf="service_enable==true">
									<div *ngFor="let option of provided;let i =index" class="row">
										<div class="col-6">
											{{option}}
										</div>
										<div class="col-6">
											<mat-icon (click)="Remove_Services(i)" class="icon-btn">
												remove_circle</mat-icon>
										</div>
									</div>
								</div>
							</div><br>
							<br>
						</div>
					</div>
				</mat-card>

				<mat-card>
					<div class="row">
						<div class="col-md-6" style="font-size: 15px">
							<h style="font-size: 25px"><b>Your appointment with {{AccountName}} has been booked</b></h>
							<br>
							<br><br>
							<b>Date:</b> Appointment Date<br><br>
							<b>Slot:</b> Appointment Slot<br><br>
							<b>You can reach at:</b> <textarea matInput placeholder="Enter location" name="entLoc"
								[(ngModel)]="config.location.address"
								matTooltip="Address Of Clinic :{{config.location.address}}" rows="5" required>
</textarea><br><br>
							<a href="{{config.location.map_link}}">Get Directions</a><br><br>
							<b>Contact No:</b> <a href="tel:{{config.location.phone}}">
								{{config.location.phone}}</a><br><br>

							<textarea matInput placeholder="Message to patient" name="msg" [(ngModel)]="config.message"
								matTooltip="Message To Patient :{{config.message}}" style="overflow :auto;resize: none"
								rows="5">
</textarea><br><br>
							Click here to <a href="">reschedule</a> or <a href>cancel</a> the appointment<br><br>
						</div><br>

						<br>

						<div class="col-md-6">

							<div class="row-3">
								<div class="col">
									<div class="row-2">
										<div class="row">
											<div class="col-md-6">
												<mat-form-field class="example-full-width">
													<input matInput placeholder="Location Name" name="CNT"
														[(ngModel)]="config.location.name"
														matTooltip="Location Name :{{config.location.name}}"
														size="	10px" width="10px" required>
												</mat-form-field>
											</div>
											<div class="col-md-6">
												{{config.location.name}} is associated with {{config.slug}}
											</div>
										</div>
									</div><br>

									<div class="row-2">
										<mat-form-field class="example-full-width">
											<textarea matInput placeholder="Enter location" name="entLoc"
												[(ngModel)]="config.location.address"
												matTooltip="Address Of Clinic :{{config.location.address}}" rows="5"
												required>
</textarea>
										</mat-form-field>
									</div><br>

									<div class="row-2">

										<mat-form-field class="example-full-width">
											<input matInput placeholder="MapLink" name="Lk"
												[(ngModel)]="config.location.map_link"
												matTooltip="Link Of Clinic:{{config.location.map_link}}" required>
										</mat-form-field>&nbsp;
										<button mat-icon-button (click)="ShowMap()" class="icon-btn"
											[disabled]="iconStatus" matTooltip="Show The Information About map_link"
											name="mapinfo">
											<mat-icon>contact_support</mat-icon>
										</button>

									</div><br>

									<div class="row-2">
										<div *ngIf="showMapInfo==true">
											<li><b>Open Google Maps -> Directions</b><br></li>
											<li><b>Put your location in the ‘To’ section.</b><br></li>
											<li><b>Copy the link generated in the browser URL bar.</b><br></li>
										</div>

									</div><br>

									<div class="row-2">
										<mat-form-field class="example-full-width">
											<input matInput placeholder="Phone Number" name="phone"
												[(ngModel)]="config.location.phone"
												matTooltip="Phone Of Clinic:{{config.location.phone}}" required>
										</mat-form-field>
									</div><br>

									<div class="row-2">
										<mat-form-field class="example-full-width">
											<textarea matInput placeholder="Message to patient" name="msg"
												[(ngModel)]="config.message"
												matTooltip="Message To Patient :{{config.message}}"
												style="overflow :auto;resize: none" rows="10">
</textarea>
										</mat-form-field>
									</div>
								</div><br>

							</div><br>

						</div>
					</div>
				</mat-card><br>
				<div align="center" style="padding-bottom: 20px">
					<button mat-button id="button" type="submit" [disabled]="!configForm.form.valid">Save
					</button>&nbsp;&nbsp;
					<button mat-button type="button" id="button" (click)="ResetChanges()">Reset</button>
				</div>
			</form>
		</div>
	</mat-card>
</div>