import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { event } from 'jquery';
import { AccountService } from './../_services/account.service';
import { ChatBotService } from './../_services/chat-bot.service';
import { MessageService } from './../_services/message.service';

@Component({
  selector: 'app-chatbot-list',
  templateUrl: './chatbot-list.component.html',
  styleUrls: ['./chatbot-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatbotListComponent implements OnInit {
  @Input() contactlist: any[];
  @Input() hasMoreRecords: boolean;
  // @Input() chatListReady: boolean;
  @Output() getMessagesOfSelctedContact = new EventEmitter<any>();
  @Output() newContacts = new EventEmitter<any>();
  @Output() rejectEmiter = new EventEmitter<any>();
  allContacts = [];
  docId: any;
  liveChatCheck: any;
  agentName: any;
  active: number;
  audio = new Audio('../assets/ringtone/notification.mp3');
  ringtoneTimer: NodeJS.Timeout;
  constructor(private messageService: MessageService,
    private chatbotService: ChatBotService,
    private accountService: AccountService) { }

  ngOnInit() {
    this.allContacts = this.contactlist;
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    console.log(changes)
    if ('contactlist' in changes) {
      this.allContacts = changes.contactlist.currentValue;
    }
  }
  ringtone(contact) {
    this.audio.play();
    this.audio.loop = true;
    this.ringtoneTimer = setTimeout(() => {
      this.audio.pause();
    }, 10000);
  }

  sendPhone(last_message) {
    // let obj = { phone: phone, docid: docid, first_name: first_name, last_name: last_name, unread: unread };
    // console.log(phone);
    // this.getMessagesOfSelctedContact.emit(obj);
    console.log(last_message);
    this.getMessagesOfSelctedContact.emit(last_message);
  }
  onClick(index: number) {
    this.active = index;
  }

  fetchContact() {
    // console.log("fetch next contacts");
    this.newContacts.emit("");
  }
  async acceptLiveChat(contact) {
    console.log("Accepted", contact);
    this.getMessagesOfSelctedContact.emit(contact);
    let docId = contact.id;
    let snapshot = await this.chatbotService.getSelectedMessages(docId);
    let currUserRef = this.accountService.getUserRef();
    let agentName = (await this.accountService.getUserRef().get()).data().displayName;
    snapshot.ref.update({
      status: 'ACCEPTED',
      liveChat: true,
      agentRef: currUserRef,
      agentName: agentName
    });
    this.audio.pause();
    clearTimeout(this.ringtoneTimer);
  }

  async rejectLiveChat(contact) {
    console.log("Rejected", contact);
    console.log(contact.id);
    contact.status = 'REJECTED';
    this.audio.pause();
    // this.rejectEmiter.emit(contact.id);
    clearTimeout(this.ringtoneTimer);
  }

}
