<!-- <div *ngIf="!loading" #offerDiv>
  <div>
    <p-messages></p-messages>
  </div>
  <mat-horizontal-stepper [linear]="true" #stepper *ngIf="!confirmation">
    <mat-step [stepControl]="campaignInfo" [completed]="campaignInfo.valid">
      <form [formGroup]="campaignInfo">
        <ng-template matStepLabel>Campaign Information</ng-template>
        <div class="row" style="margin: 5px;">
          <label for="name">Campaign Name : </label>
          <input type="text" [readOnly]="readOnly" id="name" formControlName="name" class="form-control" required
            name="campaignName" placeholder="Enter Campaign Name">
        </div>
        <div class="row" style="margin: 5px;">
          <label for="details">Campaign Description : </label>
          <textarea id="details" [readOnly]="readOnly" name="details" placeholder="Enter Campaign Description"
            formControlName="description" class="form-control" type="text" rows="9"></textarea>
        </div>
        <div style="margin-bottom: 6%;">
          <button mat-raised-button matStepperNext style="float:right" [disabled]="!campaignInfo.valid">Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step label="Contacts">
      <div *ngIf="selectedCampaignInfo?.status =='SCHEDULED' && this.action!='add'" class="scheduleDiv row"
        style="margin:0px 4px">
        <div class="col-10 mz">
          <span class="material-icons" style="padding-right: 10px;font-size: 20px;">schedule_send</span>
          Campaign scheduled for {{(selectedCampaignInfo?.scheduleTime).toDate()|date:'MMM d, y, h:mm a'}}
        </div>
        <div class="col-2" style="text-align: right;padding: 0px;">
          <button mat-raised-button [disabled]="cancelSchedule"
            style="color: #7e84f2 !important;background: transparent !important;border: none;box-shadow: none;"
            class="cencelSchedular" (click)="cancelCampaignSchedule()"> Cancel</button>
        </div>
      </div>
      <label style="margin: 5px;" class="label">Filter By</label><br>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="filterName" (change)="clearFilter()"
        [disabled]="readOnly">
        <mat-radio-button value="birthday">Birthday</mat-radio-button>
        <mat-radio-button value="lastVisited">Last Visited</mat-radio-button>
      </mat-radio-group>
      <div class="row" style="margin: 5px;">
        <div class="col-6 col-md-6" style="padding-left: 0px;" [hidden]="filterName=='lastVisited'">
          <label class="label"> Birthday :</label>
          <input class="form-control" [disabled]="readOnly" ngxDaterangepickerMd #birthdayPicker
            [locale]="{format: 'MMM DD'}" [(ngModel)]="selectedBirthDate" startKey="start" endKey="end"
            [ranges]="rangesBDate" [alwaysShowCalendars]="true" [showClearButton]="true" [showRangeLabelOnInput]="false"
            (datesUpdated)="filterBirthDate($event)">
        </div>
        <div class="col-6 col-md-6" style="padding: 0px;" [hidden]="filterName=='birthday'">
          <label for="basic">Last Visited :</label>
          <input class="form-control" [disabled]="readOnly" ngxDaterangepickerMd [locale]="{format: 'MMM DD'}"
            #lastVisitedPicker [(ngModel)]="selectedLastVisited" startKey="start" endKey="end"
            [ranges]="rangesLastVisited" [alwaysShowCalendars]="true" [showClearButton]="true"
            [showRangeLabelOnInput]="false" (datesUpdated)="filterLastVisited($event)">
        </div>
      </div>
      <div style="padding: 7px;" class="mobileViewElevation">
        <div class="mobileViewContent">
          <p-table [value]="selectedContacts" #dt [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
            currentPageReportTemplate="Total Contacts - {totalRecords}">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th pSortableColumn="birthday">Birthdate (MM-DD-YYYY)<p-sortIcon field="birthday"></p-sortIcon>
                </th>
                <th pSortableColumn="last_visited"> Last Visited (MM-DD-YYYY) <p-sortIcon field="last_visited">
                  </p-sortIcon>
                </th>
                <th *ngIf="action=='details'"> Status</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>
                  {{rowData['first_name']?rowData['first_name']:rowData['firstName']}}&nbsp;{{rowData['last_name']?rowData['last_name']:rowData['lastName']}}
                </td>
                <td>{{rowData['full_phone']?rowData['full_phone']:rowData['fullPhone']}} </td>
                <td>{{rowData['birthday']?(rowData['birthday']|date:'MM-dd-yyyy'):''}} </td>
                <td>

                  {{rowData['last_visited']?(rowData['last_visited']|date:'MM-dd-yyyy'):
                  rowData['lastVisited']|date:'MM-dd-yyyy h:mm a'}}
                </td>
                <td *ngIf="action=='details'">
                  <span [class]="rowData?.status?.toLowerCase().replace(' ','')"
                    style="border-radius: 10px;text-align: center">
                    <b style="margin:10px;">{{rowData?.status}}</b>
                  </span>
                  <i class="pi pi-info-circle errorIcon"
                    *ngIf="(rowData?.status?.toLowerCase().replace(' ','')) == 'error'"
                    (click)="showDialog(rowData?.errorMsg)" [matTooltip]="rowData?.errorMsg"></i>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div>
        <button mat-raised-button matStepperPrevious style="float: left;">Previous</button>
        <button [disabled]="selectedContacts.length==0" mat-raised-button matStepperNext
          style="float: right;">Next</button>
      </div>
    </mat-step>
    <mat-step label="Message">
      <div *ngIf="selectedCampaignInfo?.status =='SCHEDULED' && this.action!='add'" class="scheduleDiv row"
        style="margin:0px 4px">
        <div class="col-10 mz">
          <span class="material-icons" style="padding-right: 10px;font-size: 20px;">schedule_send</span>
          Campaign scheduled for {{(selectedCampaignInfo?.scheduleTime).toDate()|date:'MMM d, y, h:mm a'}}
        </div>
        <div class="col-2" style="text-align: right;padding: 0px;">
          <button mat-raised-button [disabled]="cancelSchedule"
            style="color: #7e84f2 !important;background: transparent !important;border: none;box-shadow: none;"
            class="cencelSchedular" (click)="cancelCampaignSchedule()">Cancel</button>
        </div>
      </div>
      <div>
        <div class="row" style="margin: 5px;">
          <div class="col-md-12" style="padding: 0px;">
            <span style="padding: 5px 0px 5px 0px;font-size: 1rem;">SMS Body</span>
            <textarea rows="12" cols="30" [readOnly]="readOnly" pInputTextarea placeholder="Enter sms body"
              style="width: 100%;" autoResize="autoResize" [(ngModel)]="smsBody" class="inputText" required></textarea>
          </div>
        </div>
      </div>
      <div>
        <div class="row" style="padding-left: 13px;padding-top:10px;padding-bottom: 10px;">
          <div class="col-md-6" style="text-align: left">
            <button mat-raised-button matStepperPrevious>Previous</button>
          </div>
          <div class="col-md-6" *ngIf="action=='add' || action=='edit'" style="text-align: right;min-height: 100px;">
            <p-splitButton icon="pi pi-save" *ngIf="action=='add' || action=='edit'"
              [disabled]="smsBody=='' || disabledOverall" [class.splitButtonDisabled]="smsBody=='' || disabledOverall"
              label="Save" (onClick)="action=='edit'?updateCampaign('update'):addCampaign('add')" [model]="items"
              #splitButton></p-splitButton>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
  <div *ngIf="confirmation" id="content"
    style="width: 100%;min-height: 300px;margin-top:  10%;font-size: 20px;text-align: center;">
    <div style="text-align: center;" style="height: 100%;width: 100%;">
      <b>Saving Sms campaign .....</b>
    </div>
  </div>
</div> -->
<!-- <div *ngIf="loading" style="text-align: center;width: 100%;height: 100%;margin-top: 15%;">
  <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
</div> -->

<!-- <p-dialog header="Error" [(visible)]="display" class="mobileView">
  {{errorContent}}
</p-dialog> -->
<div *ngIf="!campaignAdded">
  <p-dialog [(visible)]="isTaskOnSchedular" header="Schedule Campaign" class="dateTime" (onHide)="cancel(dialog)"
    #dialog [closable]="false">
    <div class="row">
      <div class="col-md-6">
        <p-calendar [inline]="true" class="calender" [minDate]="dateTime" (onSelect)="getDateTime('date')"
          [(ngModel)]="campaignSchedularData.date"></p-calendar>
      </div>
      <div class="col-md-6" style="padding-right: 25px;">
        <div style="padding-top: 26px;" class="row">
          <input type="text" [readOnly]="true" pInputText style="width: 100%;" [(ngModel)]="selectedDate">
        </div>
        <div style="padding-top: 10px;" class="row">
          <p-calendar class="timeOnly" style="width: 100%;" (onSelect)="getDateTime('time')"
            [(ngModel)]="campaignSchedularData.time" timeOnly="true" hourFormat="12"></p-calendar>
        </div>
        <div style="padding-top: 175px;" class="row">
          <div class="col-md-4" style="padding-left: 0px;">
          </div>
          <div class="col-md-4" style="padding-left: 0px;">
            <button mat-raised-button (click)="cancel(dialog)" [disabled]="disabledSchedule">Cancel</button>
          </div>
          <div class="col-md-4" style="padding-right: 0px;">
            <button mat-raised-button style="text-align: right;float: right;"
              [disabled]="campaignSchedularData.time == null || campaignSchedularData.date == null || disabledSchedule"
              (click)="scheduleCampaign()"><i *ngIf="isLoading" class="pi pi-spin pi-spinner"
                style="margin-right: 10px;"></i>Schedule Campaign</button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
  <div *ngIf="selectedCampaignInfo?.status =='SCHEDULED' && this.action!='add'" class="scheduleDiv row"
    style="margin:1em 2em">
    <div class="col-10 mz">
      <span class="material-icons" style="padding-right: 10px;font-size: 20px;">schedule_send</span>
      Campaign scheduled for {{(selectedCampaignInfo?.scheduleTime).toDate()|date:'MMM d, y, h:mm a'}}
    </div>
    <div class="col-2" style="text-align: right;padding: 0px;">
      <button mat-raised-button [disabled]="cancelSchedule"
        style="color: #7e84f2 !important;background: transparent !important;border: none;box-shadow: none;"
        class="cencelSchedular" (click)="cancelCampaignSchedule()">Cancel</button>
    </div>
  </div>
  <mat-horizontal-stepper labelPosition='bottom' #stepper linear (selectionChange)="matStepSecondClicked()">

    <mat-step>
      <ng-template matStepLabel>Quicklist</ng-template>
      <mat-list>
        <div *ngIf="step1Loading;else step1" style="text-align: center;width: 100%;height: 100%;margin-top: 15%;">
          <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
        </div>
        <ng-template #step1>
          <mat-divider></mat-divider>
          <ng-container *ngFor="let camapign of quickList;let i=index">

            <mat-list-item style="padding-left: 10px;cursor: pointer;"
              [style.pointer-events]="this.action == 'details'?'none':''"
              (click)="this.action == 'details'?selctedCampaignIndex=selctedCampaignIndex:selctedCampaignIndex=i;getSelectedSelectedQuicklistsPatients()"
              [style.background-color]="selctedCampaignIndex==i?'darkgray':''">{{camapign.name |titlecase }}
            </mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>
          <div style="text-align: center;" *ngIf="quickList.length==0">
            <mat-error> Quicklist configurations is not set, please configure </mat-error>
          </div>
        </ng-template>
      </mat-list>
      <div style="text-align: center;">
        <button mat-raised-button style="margin-top: 5em;" [disabled]="selctedCampaignIndex==-1"
          (click)="getSelectedSelectedQuicklistsPatients()">Next</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Contacts</ng-template>
      <div style="min-height: 20em;">
        <div *ngIf="conatctLoading;" style="text-align: center;width: 100%;height: 100%;margin-top: 2em;">
          <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
        </div>

        <div *ngIf="!conatctLoading">
          <p-table *ngIf="this.filteredContact.length>0" [value]="this.filteredContact" #dt [paginator]="true"
            [rows]="10" [showCurrentPageReport]="true" currentPageReportTemplate="Total Contacts - {totalRecords}">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th style="width: 7em; text-align:center">{{this.action == "details"?'Status':'Suppress'}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <tr>
                <td>
                  {{rowData['first_name']?rowData['first_name']:rowData['firstName']}}&nbsp;{{rowData['last_name']?rowData['last_name']:rowData['lastName']}}
                </td>
                <td>{{rowData['full_phone']?rowData['full_phone']:rowData['fullPhone']}} </td>
                <td>{{rowData['email']?rowData['email']:rowData['email']}} </td>
                <td *ngIf="this.action == 'details'">
                  <div *ngIf="rowData['methodType']=='EMAIL'"
                    style="text-align: center;text-decoration: underline;cursor: pointer;"
                    (click)="showActivityStatus(rowData)">{{rowData['status'] }}</div>
                  <div *ngIf="rowData['methodType']=='SMS'" style="text-align: center;">{{rowData['status'] }}</div>
                </td>
                <td *ngIf="this.action != 'details'">
                  <div style="text-align: center;">
                    <i *ngIf="rowData['supress']==true" (click)="supress(rowIndex,false)"
                      class="material-icons add_circle" style="cursor: pointer;">add_circle</i>
                    <i *ngIf="rowData['supress']!=true" class="material-icons cancel" (click)="supress(rowIndex,true)"
                      style="cursor: pointer;">cancel</i>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <div style="text-align: center;" *ngIf="this.filteredContact.length==0">
            <mat-error> No conatcts available for this kind of Quicklist</mat-error>
          </div>
        </div>
      </div>
      <div class="row" style="padding-top: 1em;">
        <div class="col-md-4">
          <div style="text-align: center;">
            <button mat-raised-button class="stepButton" matStepperPrevious>Previous</button>
          </div>
        </div>
        <div class="col-md-4">

        </div>
        <div class="col-md-4">
          <div style="text-align: center;">
            <button mat-raised-button class="stepButton" [disabled]="filteredContact.length==0"
              matStepperNext>Next</button>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Messege</ng-template>
      <div style="min-height: 20em;">
        <!-- <mat-form-field style="width: 100%;">
        <mat-label>Select Messege Library</mat-label>
        <mat-select [(ngModel)]="selectedMessege">
          <mat-option [value]="option" *ngFor="let option of messegeLibrary"
            (onSelectionChange)="selectMessege(option)">{{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
        <div *ngIf="isShowMethodOption">
          <label style="margin: 5px;" class="label">Choose Mode</label><br>
          <mat-radio-group aria-label="Select an option" [(ngModel)]="methodType" [disabled]="this.action == 'details'">
            <mat-radio-button value="EMAIL">Email</mat-radio-button>
            <mat-radio-button value="SMS">SMS
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="methodType=='SMS'">
          <div style="position: relative;">
            <label>SMS Body</label>
            <textarea style="width: 100%;" rows="5" cols="30" pInputTextarea [(ngModel)]="smsBody">
      </textarea>
            <span style="position: absolute;bottom: 0.5em;right: 1em;">
              {{smsBody.length}}/160
            </span>
          </div>

          <mat-progress-bar mode="determinate" [value]="smsBody.length*0.625">
          </mat-progress-bar>
          <mat-error *ngIf="smsBody.length>160">
            Sms size should be 160 character or less
          </mat-error>
        </div>
        <div *ngIf="methodType=='EMAIL'">
          <label>Subject</label>
          <input style="width: 100%;" type="text" pInputText [(ngModel)]="emailSubject">
          <label>Email Body</label>
          <p-editor [style]="{'height':'320px','margin-bottom': '1em'}" [(ngModel)]="emailBody"></p-editor>
        </div>
      </div>

      <div style="text-align: center;margin-top: 3em;">
        <div class="row" style="padding-left: 13px;padding-top:10px;padding-bottom: 10px;">
          <div class="col-md-6" style="text-align: left">
            <button mat-raised-button matStepperPrevious>Previous</button>
          </div>
          <div class="col-md-6" *ngIf="action=='add' || action=='edit'" style="text-align: right;min-height: 100px;">
            <p-splitButton [disabled]="validateSmsAndEmailBody()" icon="pi pi-save"
              *ngIf="action=='add' || action=='edit'" label="Save"
              (onClick)="action=='edit'?updateCampaign('update'):addCampaign('add')" [model]="items" #splitButton>
            </p-splitButton>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div *ngIf="campaignAdded" style="text-align: center;
margin: auto;margin-top: 5em;padding-bottom: 5em;">
  <div style="font-size:20px">
    {{campaignActivityMessege}}
  </div>
  <div>
    <button mat-raised-button (click)="addNewCamapign()" style="margin: 10px;">Add New</button>
    <button mat-raised-button routerLink="/quick-list-activity" style="margin: 10px;">Activities</button>
  </div>
</div>