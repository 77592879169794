import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
//import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { Router } from "@angular/router";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { tap } from "rxjs/operators";
import { merge } from "rxjs";

import { AccountService } from "../../_services/account.service";
import { ContactService } from "../../_services/contact.service";
import { ContactsDataSource } from "../../_services/contacts.datasource";

import * as moment from "moment";

@Component({
  selector: "app-contacts-new",
  templateUrl: "./contacts-new.component.html",
  styleUrls: ["./contacts-new.component.scss"],
  /*
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
  */
})
export class ContactsNewComponent implements OnInit, AfterViewInit {
  contacts: any = [];
  displayedColumns: any = [
    "select",
    "first_name",
    "last_name",
    "email",
    "phone",
    "provider",
    "send_text_reminders",
    "last_visited",
    "created_on",
    "active",
  ];
  dataSource: ContactsDataSource;
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSize: number = 20;
  pageSizeOptions: number[] = [10, 20, 50];
  count: number = 0;

  filters: any = [
    /*
  	{
  		column: 'active',
  		operator: '==',
  		value: true
  	},
  	{
  		column: 'created_on',
  		operator: '>',
  		value: moment('2018-07-01', 'YYYY-MM-DD').toDate()
  	},
  	{
  		column: 'created_on',
  		operator: '<',
  		value: moment('2019-08-01', 'YYYY-MM-DD').toDate()
  	}
  	*/
  ];

  expandedElement: any | null;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private contactService: ContactService
  ) {
    this.accountService.accChange$.subscribe((accChange) => {
      this.contactService.resetPageEndpoints();
      this.router.navigateByUrl("/websites");
    });
  }

  ngOnInit() {
    this.contactService.setFilters(this.filters);
    this.dataSource = new ContactsDataSource(this.contactService);
    this.contactService.getContactsCount().then((count) => {
      this.count = count;
      this.dataSource.getContacts("created_on", "desc", 0, this.pageSize);
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      console.log(this.sort);
      this.paginator.pageIndex = 0;
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.getContacts()))
      .subscribe();
  }

  getContacts() {
    this.dataSource.getContacts(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.contacts.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.contacts.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  bulkAction() {
    console.log(this.selection.selected);
  }
}
