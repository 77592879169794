import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { TipsService,  AccountService } from '../_services/index';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss']
})
export class TipsComponent implements OnInit {
selectedValue:string = 'none';
filter_options = [
{value: 'facebook', displayAs: 'Facebook Tips'},
{value: 'adwords', displayAs: 'AdWords Tips'}
];
  tips: any[];
acc:any;
selectedIndex:any;
  constructor(
    private tipsService: TipsService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router
  ) {

      this.accountService.accChange$.subscribe( accChange => {
        this.router.navigateByUrl('/websites');
            /*
            this.acc = this.accountService.getCurrentAccDetails();
            tipsService.setTipsCount('/tips/' + this.acc.name);

            this.tipsService.getTips('/tips/' + this.acc.name)
                .subscribe( tips => {
                  this.tips = tips;
                  this.tips.reverse();
                } );
            */
        });

  }

  ngOnInit() {
    this.acc = this.accountService.getCurrentAccDetails();
    this.tipsService.getTips('/tips/' + this.acc.name)
        .subscribe( tips => {
          this.tips = tips;
          this.tips.reverse();
        } );

   this.route.queryParams.subscribe( (params) =>{
  this.selectedIndex = (+params['selectedIndex']);
});
  }

  getage(tip) {
    let now = Date.now();
    let tip_timestamp = new Date(tip.timestamp).getTime();
    return this.calculateage(now - tip_timestamp);
  }

  calculateage(time_diff) {
    let age = '';
    if(time_diff < 60000)
    {
      // There is a difference of a few seconds
      age = Math.floor(time_diff / 1000) + ' sec ago';
    }

    else if (time_diff < (3600000))
    {
      // There is a difference of a few minutes
      age = Math.floor(time_diff / 60000) + ' min ago';
    }

    else if (time_diff < (86400000))
    {
      // There is a difference of a few hours
      age = Math.floor(time_diff / 3600000) + ' hr ago';
    }
    else if (time_diff < 2592000000) {
      // There is a difference of a few days
      age = Math.floor(time_diff / 86400000) + ' d ago';
    }

    else if (time_diff < 31536000000) {
      // There is a difference of a few months
      age = Math.floor(time_diff / 2592000000) + ' mo(s) ago';
    }

    else {
      // There is a difference of a few years
      age = Math.floor(time_diff / 31536000000) + ' yr(s) ago';
    }

    return age;
  }
}
