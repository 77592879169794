import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss']
})
export class NewMessageComponent implements OnInit {
  @Output() contactPhone = new EventEmitter<any>();
  @Input() algoliaContactList: [];
  constructor() { }

  ngOnInit() {
    console.log("new message component triggered on click");
  }
  ngOnchange() {
    console.log(this.algoliaContactList);
  }

  sendContactDetails(contact: any) {
    this.contactPhone.emit(contact);
  }
}



