import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Question } from '../_services/form';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import { environment } from '../../environments/environment';
import { AccountService } from './account.service';
import * as moment from 'moment';


@Injectable()
export class InteractiveFormService {

  acc_details: any;
  account_id: any;
  constructor(
    private http: HttpClient,
    private db: AngularFireDatabase,
    public afs: AngularFirestore,
    private accountService: AccountService
  ) { }

  getQuestions(acc_slug: string, form_slug: string) {
    return firebase.firestore().collection('accounts')
      .where("account_slug", "==", acc_slug)
      .get()
      .then(
        accountSnapshot => {
          this.acc_details = accountSnapshot.docs[0].data();
          let acc_id = accountSnapshot.docs[0].id;
          this.account_id = acc_id;
          return firebase.firestore().collection('accounts').doc(acc_id).collection('forms')
            .where("slug", "==", form_slug)
            .get();
        }
      );
  }

  getAccountDetails() {
    return this.acc_details;
  }

  saveJsonToFirebase() {
    //let files = ["breast_surgery", "Facial_Rejuvenation", "Liposuction_VASER", "questions", "test-form", "TummyTuck_Lipoabdominoplasty", "form-1", "form-2", "form-3", "form-4", "form-5", "form-6", "form-7"];
    //let files = ["breast_surgery", "Liposuction_VASER", "TummyTuck_Lipoabdominoplasty"];
    let files = ['test-form'];
    for (let file of files) {
      this.http.get('../../assets/json/' + file + '.json')
        .subscribe(
          contents => {
            // firebase.firestore().collection('accounts').doc(`GuwocYxEiOZMRS5SBPYJ`).collection('forms').add(contents)
            this.afs.collection(`interactive_forms`).add(contents)
              .then(
                obj => {
                  console.log("Added " + file);
                },
                error => {
                  console.error("Could not add " + file);
                }
              );
          }
        );
    }
  }

  getCookie(): any {
    return this.http.get(`${environment.serverURL}/interactive-form-get-tracking.php`);
  }

  getAnalytics(): any {
    return this.http.get(`${environment.serverURL}/interactive-form-get-analytics.php`);
  }

  createResponse(name: string, uid: string, headers: any, response: Question[], formRef: any): any {
    const data = {
      "form_name": name,
      "uid": uid,
      "headers": headers,
      "response": response,
      "initiated": (new DatePipe('en-US')).transform(Date(), 'MMM d yyyy, hh:mm a (zzzz)'),
      "status": "initiated"
    };
    return formRef.collection('form_response').add(data);
  }

  getResponseDoc(uid: string, formRef: any): any {
    /*
    return firebase.firestore().collection('accounts').doc(`${acc_id}`).collection('forms').doc(form_id).collection('form_response')
                   .where("uid", "==", uid)
                   .get();
    */
    return formRef.collection('form_response')
      .where("uid", "==", uid)
      .get();
  }

  async saveUserInfo(userInfo: any) {
    let contactRef = await firebase.firestore().collection('accounts').doc(this.account_id).collection('contacts')
      .where("email", "==", userInfo['email'])
      .get();


    if (contactRef.empty === true) {
      console.log('No data in contacts collection !');
      userInfo['created_on'] = moment().toDate();
      firebase.firestore().collection('accounts').doc(this.account_id).collection('contacts').add(userInfo)
        .then(
          success => {
            console.log('Data added successfully!');
          },
          error => {
            console.error(error);
          }
        );
    }
    else {
      contactRef.forEach(
        contactDoc => {
          contactDoc.ref.update(userInfo);
        });
    }
  }

  saveAnswer(responseRef: any, response_array: any): Promise<any> {
    return responseRef.update({
      response: response_array
    });
  }

  submitForm(responseRef: any, response_array: any): Promise<any> {
    return responseRef.update({
      status: 'submitted',
      submitted: (new DatePipe('en-US')).transform(Date(), 'MMM d yyyy, hh:mm a (zzzz)'),
      response: response_array
    });
  }

  changeSubmissionStatus(responseRef: any): Promise<any> {
    return responseRef.update({
      status: 'initiated'
    });
  }

  uploadFile(fileName: any, form_name: string) {
    const id = Math.random().toString(36).substring(2);
    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef.child(`interactive_forms/answers/${form_name}/${id}.${fileName.name}`).put(fileName);
    return uploadTask;

  }

  deleteFromStorage(filePath: string) {
    let storageRef = firebase.storage().ref();
    storageRef.child(`${filePath}`).delete();
  }

  sendToHubspot(hs_input_obj: any) {
    this.http.post(`${environment.serverURL}/hubspot-add-interactive-form-contact.php`, JSON.stringify(hs_input_obj))
      .subscribe(
        response => {
          console.log("Contact successfully added to HubSpot!");
        },
        error => {
          console.error(error);
        }
      );
  }

  sendEmail(form_name: string, form_responses: any, email_id: string) {
    (firebase.functions().httpsCallable('sendFormEmail'))({ 'form_name': form_name, "response": form_responses, "email": email_id })
      .then(
        result => {
          console.log(result);
        },
        error => {
          console.error(error);
        }
      );
  }

  migrateForms() {
    firebase.initializeApp({
      apiKey: "AIzaSyCYSZZUmLcaP3Pz6C70A4Uu9kAniE9tWwM",
      authDomain: "mktg-bot2.firebaseapp.com",
      databaseURL: "https://mktg-bot2.firebaseio.com",
      projectId: "mktg-bot2",
      storageBucket: "mktg-bot2.appspot.com",
      messagingSenderId: "1089334441394"
    }, 'prod_firebase');
    let from_collection = 'accounts/4J61oN6uJk43LJITfoQR/forms';
    let to_collection = 'accounts/T7f7d2VEGjqXmQvkHZ6h/forms';
    /*
     * Add any properties that need to be changed here
     */

    let form_settings = {
      email: "harshal.limaye@nhansmedia.com",
      hubspot_id: "3331547",
      hubspot_form_id: "bf9f2c04-d417-4f22-94a9-5c87b75cfcfb"
    };
    /*
    let thank_you_screen = {
      headline_text: 'Ready to create your own forms?',
      short_description: '<p>Click the button below to start your free trial.</p>',
      button_text: 'Let\'s start your free trial',
      button_link: 'https://interactive-forms.herokuapp.com/signup',
      tracking_code: {
        adwords: {
          enabled: false
        },
        facebook: {
          enabled: false
        }
      }
    };
    */

    firebase.app('prod_firebase').firestore().collection(from_collection).get()
      .then(
        docsSnapshot => {
          docsSnapshot.forEach(
            doc => {
              let form = doc.data();
              form.form_settings = form_settings;
              //form.slug = form.name.replace(/[\s+/+_+]/g, '-').toLowerCase();
              //form.thank_you_screen = thank_you_screen;
              firebase.app('prod_firebase').firestore().collection(to_collection).add(form)
                .then(
                  success => {
                    console.log('Migrated form successfully!');
                  },
                  error => {
                    console.error(error);
                  }
                );
            }
          );
        }
      );
  }

  getSeoResults(url: string, country: string, email: string) {
    this.http.get(`${environment.pythonServerUrl}/seo-results?url=${url}&country=${country}&email=${email}`)
      .subscribe(
        success => {
          console.log(success);
          // Make a call to the email function
          //this.emailSeoReport(success["report"], url, email);
        },
        error => {
          console.error(error);
        }
      );
  }

  emailSeoReport(report: any, url: string, email: string) {
    (firebase.functions().httpsCallable('sendSeoReport'))({ 'report': report, "url": url, "email": email })
      .then(
        result => {
          console.log(result);
        },
        error => {
          console.error(error);
        }
      );
  }
}
