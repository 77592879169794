/*
 * Used in legacy code
 */

import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/index';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {

    constructor(private http: Http,
    private router: Router) { }

    login(user:User)
    {

      let body = JSON.stringify(user);

       return this.http.post(`${environment.serverURL}/login.php`, body);
      /*     .map((response: Response) => {
                // login successful if there's a jwt token in the response
                let user = response.json();

                if (user && user.body) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user.body));
                    //localStorage.removeItem('anonymousUser');
                }

            });
      */
     }

    logout() {
        // remove user and plan from local storage to log user out
        localStorage.removeItem('user');
        localStorage.removeItem('plan');

    }
}
