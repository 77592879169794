<div class="container-fluid view">

    <div class="row text-center" id="subHeader1">
        <div class="col-md-3" style="margin: auto;">
            <div id="pageTitle">
                <a [routerLink]="['/contacts']"> <i class="pi pi-chevron-left"></i>Contacts</a>
            </div>
        </div>

        <div *ngIf="contactDetails!==undefined" class="col-md-3" style="margin: auto;">
            <div id="subPageTitle">
                {{contactDetails.first_name }} {{contactDetails.last_name}}
            </div>
        </div>

        <div class="col-md-6">
            <div id="action-btn" style="display: inline-block;">
                <div class="col-md-12" *ngIf="contactDetails!==undefined" class="actions">
                    <button mat-mini-fab id="button" class="format" (click)="openDialogForRecurringAppointment()"
                        matTooltip="Schedule Recurring Appointment">
                        <span class="material-icons">
                            edit_calendar
                        </span>
                    </button>
                    <button mat-mini-fab id="button" class="format" (click)="scheduleNewAppointment()"
                        matTooltip="Make Appointment">
                        <i class="material-icons">today</i>
                    </button>

                    <button mat-mini-fab id="button" class="format" (click)="singleMessage(contactDetails)" *ngIf="contactDetails['send_text_reminders'] === true && twilio_from !== undefined
                  && contactDetails['active'] === true" matTooltip="Send SMS Now">
                        <i class="material-icons">message</i>
                    </button>

                    <button mat-mini-fab id="button" class="format" matTooltip="Add Note"
                        (click)="openDialogForNote(contactDetails.ref)">
                        <i class="material-icons">event_note</i>
                    </button>

                    <button mat-mini-fab id="button" class="format" (click)="openDialogToSendPatientForm()"
                        matTooltip="Send Patient Form">
                        <i class="material-icons">article</i>
                    </button>

                    <button mat-mini-fab id="button" class="format" (click)="openDialogForServeyForm()"
                        matTooltip="Survey Form">
                        <span class="material-icons">
                            fact_check
                        </span>
                    </button>

                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabClick($event)">
                <div class="text-center">
                    <mat-tab label="Contact Details" class="tabWidth" [selectedIndex]="1">
                        <app-contact-details [contact]="contactDetails" [languages]="acc.languages ? acc.languages : []"
                            (onContactUpdate)="updateContactInformation($event)">
                        </app-contact-details>
                    </mat-tab>
                    <mat-tab label="Appointments" class="tabWidth" [selectedIndex]="2">
                        <app-contact-appointments [contact]="contactDetails" [appointments]="contactAppointments"
                            (onStatusChange)="changeAppointmentStatus($event)">
                        </app-contact-appointments>
                    </mat-tab>
                    <mat-tab label="Notes" class="tabWidth" [selectedIndex]="3">
                        <app-contact-notes [notes]="notes" (onNoteUpdate)="updateNote($event)"></app-contact-notes>
                    </mat-tab>
                    <mat-tab label="Messages" class="tabWidth" [selectedIndex]="4">
                        <app-contact-message-log [messages]="messages"></app-contact-message-log>
                    </mat-tab>
                    <mat-tab label="Forms" class="tabWidth" [selectedIndex]="5">
                        <app-contact-form-details *ngIf="showFormDetails" [responses]="responses"
                            [contactId]="contact_id"></app-contact-form-details>
                    </mat-tab>
                    <mat-tab label="Activity" class="tabWidth" [selectedIndex]="5">
                        <app-contact-follow-up [contact]="contactDetails" [allTask]="allTask"></app-contact-follow-up>
                    </mat-tab>
                    <!----<mat-tab label="Rewards" class="tabWidth" [selectedIndex]="7">
                        <app-reward-details [contact]="contactDetails"></app-reward-details>
                    </mat-tab>-->
                </div>
            </mat-tab-group>
        </div>
    </div>
</div>