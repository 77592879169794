<div class="pageDiv2" id="localSearchRank">
  <div *ngIf="localPackResults.length>0" class="row" style="margin: 20px;">
    <div class="col-md-5" style="padding-left: 2em;">
      <div class="row" style="padding: 10px;">
        <div id="googleLogoLocalSearch">
          <img style="width:40px;height:40px;padding: 4px;" src="assets/images/googlelogo.png" alt="image">
        </div>
        <div id="inputBoxLocalSearch">
          <span class="p-input-icon-right">
            <i color="blue" style="right:2.5rem;"><img id="micImg" src="assets/images/google_mic.png" alt="image"></i>
            <i style="color: #8E7CC3;" class="pi pi-search"></i>
            <input type="text" disabled id="topic" style="border-radius:20px" size=70 class="form-control inputbox">
          </span>
        </div>
      </div>

      <div class="container">
        <div class="row localPackRow" (click)="showLocalPackAtRank(0)"
          [ngStyle]="currentLocalPack==0?{'background-color':'#f7f7f7'}:{'background-color':'white'}"
          style="cursor:pointer;">
          <div class="col-md-1" style="padding-left: 0px;padding-right: 0px;">
            <i class="fas fa-map-marker-alt" style="font-size: 20px;margin-top: 1em;
                padding-right: 5px;"></i>
          </div>
          <div class="col-md-9" id="localParaTitleCol">
            <p id="localParaTitle">
              NUMBER OF KEYWORDS IN LOCAL SEARCH
            </p>
          </div>
          <div class="col-md-2">
            <div class="card" id="localCardMain">
              <span>{{localPackAtRank1.length+localPackAtRank2.length+localPackAtRank3.length}}</span>
              <span style="font-size: 8px;"> KEYWORDS</span>
            </div>
          </div>
        </div>
        <div class="row localPackRow" id="localPackEvent" (click)="showLocalPackAtRank(1)"
          [ngStyle]="currentLocalPack==1?{'background-color':'#f7f7f7'}:{'background-color':'white'}">
          <button id="localEventButton"
            [ngStyle]="currentLocalPack==1?{'background-color':'#8E7CC3'}:{'background-color':'#e7e7e7'}">A</button>
          <div class="col-md-9">
            <div id="localPara">{{accountDetails['name']}}
              <!-- <br>
                  <span style="color: #afafb3; display: flex;">
                    {{practiceInfoObj.ratings}} &nbsp;
                    <p-rating [ngModel]="practiceInfoObj.ratings" readonly="true"
                    stars="5" [cancel]="false"></p-rating>
                  </span>  -->
              <div>{{practiceInfoObj.gmbSpeciality}}</div>
            </div>
          </div>

          <div class="col-md-2">
            <div id="localCard" class="card">
              <span>{{localPackAtRank1.length}}</span>
              <span style="font-size: 8px;"> KEYWORDS</span>
            </div>
          </div>
        </div>
        <div class="row localPackRow" id="localPackEvent" (click)="showLocalPackAtRank(2)"
          [ngStyle]="currentLocalPack==2?{'background-color':'#f7f7f7'}:{'background-color':'white'}">
          <button id="localEventButton"
            [ngStyle]="currentLocalPack==2?{'background-color':'#8E7CC3'}:{'background-color':'#e7e7e7'}">B</button>
          <div class="col-md-9">
            <p id="localPara">{{accountDetails['name']}}
              <!-- <br>
                <span style="color: #afafb3;">
                  {{practiceInfoObj.gmbSpeciality}}
                  <p-rating [ngModel]="practiceInfoObj.ratings" readonly="true" stars="5" [cancel]="false"></p-rating>
                </span> -->
            </p>
          </div>
          <div class="col-md-2">
            <div id="localCard" class="card">
              <span>{{localPackAtRank2.length}}</span>
              <span style="font-size: 8px;"> KEYWORDS</span>
            </div>
          </div>
        </div>
        <div class="row localPackRow" id="localPackEvent" (click)="showLocalPackAtRank(3)"
          [ngStyle]="currentLocalPack==3?{'background-color':'#f7f7f7'}:{'background-color':'white'}">
          <button id="localEventButton"
            [ngStyle]="currentLocalPack==3?{'background-color':'#8E7CC3'}:{'background-color':'#e7e7e7'}">C</button>
          <div class="col-md-9">
            <p id="localPara">{{accountDetails['name']}}
              <!-- <br> -->
              <!-- <span style="color: #afafb3;">
                  {{practiceInfoObj.gmbSpeciality}}
                  <p-rating [ngModel]="practiceInfoObj.ratings" readonly="true" stars="5" [cancel]="false"></p-rating>
                </span> -->
            </p>
          </div>

          <div class="col-md-2">
            <div id="localCard" class="card">
              <span>{{localPackAtRank3.length}}</span>
              <span style="font-size: 8px;"> KEYWORDS</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-7" id="localRankResultTable">
      <div *ngIf="showLocalResults.length>0;else nolocalPackTemplate">
        <div class="container-fluid" class="row" id="topPageRow" *ngFor="let localRank of showLocalResults">
          <div class="col-md-4" id="topPage" id="domain">
            <span style="font-size: 16px;color:#7e84f2;">
              {{localRank.keyword}}
            </span>
            <br>
            <span class="subscript">Keyword</span>
          </div>
          <div class="col-md-2" id="topPage" id="domain">
            <span style="font-size: 20px; font-weight: 500;">
              {{localRank.rank}}
            </span>
            <br>
            <span class="subscript">Position</span>
          </div>
          <!-- <div class="col-md-2" id="topPage" id="domain">
            <span
              [matTooltip]="localRank.keyword_data.keyword_info.monthly_searches[0].search_volume == null ? 'No data ':null"
              tooltipPosition="above" style="font-size: 20px; font-weight: 500;">
              {{localRank.keyword_data.keyword_info.monthly_searches[0].search_volume==null?'NA':localRank.keyword_data.keyword_info.monthly_searches[0].search_volume}}
            </span>
            <br>
            <span class="subscript">Monthly Searchs</span>
          </div> -->
          <div class="col-md-2" id="topPage" id="domain">
            <span [matTooltip]="localRank.search_volume?.competition_total == null ? 'No data ':null"
              tooltipPosition="above" style="font-size: 20px; font-weight: 500;">
              {{localRank.search_volume?.competition_total |number : '1.2-2'}}
            </span>
            <br>
            <span class="subscript">Competition</span>
          </div>
          <div class="col-md-2" id="topPage" id="domain">
            <span [matTooltip]="localRank.search_volume?.cpc_total == null ? 'No data ':null" tooltipPosition="above"
              style="font-size: 20px; font-weight: 500;">
              {{localRank.search_volume?.cpc_total|currency}}
            </span>
            <br>
            <span class="subscript">CPC</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <ng-template #nolocalPackTemplate>
        <div class="row text-center" id="noKeywordRankDiv"
          style="height:300px;text-transform: uppercase;font-weight: 500;">
          Currently you don't have any keywords ranking.
        </div>
      </ng-template> -->

  <!--if no local count-->
  <div *ngIf="localPackResults.length==0" class="row localpack1 ">

    <div class="col-md-5" id="noKeywordDiv">
      <div class="row" style="padding:10px;">
        <div style="width: 8%;">
          <img style="width:40px;height:40px;padding: 4px;" src="assets/images/googlelogo.png" alt="image">
        </div>
        <div style="width: 90%;padding-left: 3%;">
          <span class="p-input-icon-right">
            <i style="right:2.5rem;"><img id="micImg" src="assets/images/google_mic.png" alt="image"></i>
            <i style="color: #8E7CC3;" class="pi pi-search"></i>
            <input type="text" disabled id="topic" style="border-radius:20px" size=70 class="form-control inputbox">
          </span>
        </div>
      </div>
      <div class="container">
        <div class="row localPackRow" style="height: 6em;">
          <div class="col-md-1" style="padding-left: 0px;padding-right: 0px;">
            <i class="fas fa-map-marker-alt" style="font-size: 20px;margin-top: 1em;
                padding-right: 5px;"></i>
          </div>
          <div class="col-md-9" id="localParaTitleCol">
            <p id="localParaTitle">
              NUMBER OF KEYWORDS IN LOCAL SEARCH
            </p>
          </div>
          <div class="col-md-2">
            <div class="card" id="localCardMain1">
              <span>{{localPackAtRank1.length+localPackAtRank2.length+localPackAtRank3.length}}</span>
              <span style="font-size: 8px;"> KEYWORDS</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <div class="row text-center" id="noKeywordRankDiv"
        style="height:300px;text-transform: uppercase;font-weight: 500;">
        Currently you don't have any keywords ranking in Local search.
      </div>
    </div>
  </div>
  <ng-template #nolocalPackTemplate>
    <div class="row text-center"
      style="height:300px;text-transform: uppercase;justify-content: center;align-items: center; font-weight: 500;">
      Currently you don't have any local rankings.
    </div>
  </ng-template>
</div>
