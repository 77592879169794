<!-- <div *ngIf="responses.length >0">
    <p-table [value]="responses" dataKey="id" sortMode="multiple" rowExpandMode="single">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem"></th>
          <th>Form Name</th>
          <th>Initiated Date</th>
          <th>Status </th>
          <th>Form Type</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-response let-expanded="expanded">
        <tr>
          <td style="width:3em;">
            <a href="#" [pRowToggler]="response">
              <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
            </a>
          </td>
          <td>{{response.form_name}}</td>
          <td>{{response.date | date}}</td>
          <td>{{response.status | titlecase}}</td>
          <td>{{response.type.replace('_', ' ') | titlecase }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-response>
        <tr *ngIf="response.response else noQuestion">
          <td [attr.colspan]="5" *ngIf="response.type == 'SURVEY_FORM'">
            <div class="full-width" style="font-size:16px;"
              *ngIf="response.response.length>0 else noQuestion">
              <p-tabView>
                <p-tabPanel [header]="'Questions'"
                  [headerStyle]="{'width':'max-content','overflow':'hidden'}">
                  <div class="panelHeight" *ngIf="response.response">
                    <ng-container *ngFor="let question of response.response">
                      <div class="p-grid" *ngIf="question.question">
                        <span style="font-size:16px;font-weight:550;">{{ question.question}} &nbsp;</span>
                        <span *ngIf="question.answer_type!=='label'"> : &nbsp;</span>
                        <span *ngIf="question.answer_type=='signature'">
                          <span> <img [src]=question.answer width="100px" height="100px"> </span>
                        </span>
                        <span *ngIf="question.answer_type=='checkbox'">
                          <ng-container *ngFor="let option of question.answer; index as i;">
                            <span *ngIf="option">
                              {{question.choices[i]}}
                            </span>
                          </ng-container>
                        </span>
                        <span
                          *ngIf="question.answer_type !='signature' && question.answer_type !='checkbox' ">
                          <span>{{ question.answer}}</span>
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </p-tabPanel>
              </p-tabView>
            </div>
          </td>
          <td [attr.colspan]="5" *ngIf="response.type == 'PATIENT_FORM'">
            <div class="full-width" style="font-size:16px;">
              <p-tabView>
                <p-tabPanel [header]="section.name | titlecase"
                  [headerStyle]="{'width':'max-content','overflow':'hidden'}"
                  *ngFor="let section of response.response; let i = index" [selected]="i == 0">
                  <div class="panelHeight">
                    <ng-container *ngFor="let question of section.questions">
                      <div class="p-grid">
                        <span style="font-size:16px;font-weight:550;">{{ question.question}} &nbsp;</span>
                        <span *ngIf="question.answer_type!=='label'"> : &nbsp;</span>
                        <span *ngIf="question.answer_type=='signature'">
                          <span> <img [src]=question.answer width="100px" height="100px"> </span>
                        </span>
                        <span *ngIf="question.answer_type=='checkbox'">
                          <ng-container *ngFor="let option of question.answer; index as i;">
                            <span *ngIf="option">
                              {{question.choices[i]}}
                            </span>
                          </ng-container>
                        </span>
                        <span
                          *ngIf="question.answer_type !='signature' && question.answer_type !='checkbox' ">
                          <span>{{ question.answer}}</span>
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </p-tabPanel>
              </p-tabView>
            </div>
          </td>
        </tr>
        <ng-template #noQuestion style="height: 100%;width: 100%;">
          <tr>
            <td [attr.colspan]="5" class="full-width" style="font-size:16px;text-align: center;width: 30%;">
              <b>Not Started to fill the form</b>
            </td>
          </tr>
        </ng-template>
      </ng-template>
    </p-table>
  </div> -->


<div *ngIf="patientFormResponses.length >0">
    <p-table [value]="formData" rowExpandMode="single" [columns]="displayedColumns" dataKey="id"
    currentPageReportTemplate="{first} - {last} of {totalRecords}" rowExpandMode="single" #dt
    [globalFilterFields]="searchFields" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10,25,50]" sortMode="multiple" [multiSortMeta]="[{field: 'createdOn', order: -1}]"
    selectionMode="multiple">
        <!-- <ng-template pTemplate="caption">
          <div class="ui-helper-clearfix" style="text-align: left">
          </div>
          <div style="text-align: left;">
            <input class="filterInput" type="text" pInputText size="50" placeholder="Search "
              (input)="dt.filterGlobal($event.target.value, 'contains')">
          </div>
        </ng-template> -->
        <ng-template pTemplate="header">
            <tr>
              <th style="width:2rem;"></th>
              <th *ngFor="let col of displayedColumns;let i=index" [pSortableColumn]="col.value" [style]="col.style">
                {{col.header| titlecase}}
                <p-sortIcon [field]="col.value" *ngIf="!disableSortColumns.includes(col.value)"></p-sortIcon>
              </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-expanded='expanded' let-index="rowIndex">
            <tr>
                <td>
                <a href="#" [pRowToggler]="rowData" (click)="getSelectFormData(rowData)">
                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                </a>
                </td>
                <!-- <td>{{rowData['createdOn']?(rowData.createdOn.toDate()| date: 'dd/MM/yyyy hh:mm a'):''}}</td>
                <td>{{rowData['submittedOn']?(rowData.submittedOn.toDate()| date: 'dd/MM/yyyy hh:mm a'):''}}</td> -->
                <td>{{rowData['createdOn']?(rowData.createdOn.toDate()| date: 'MMM d, yyyy hh:mm a'):''}}</td>
                <td>{{rowData['submittedOn']?(rowData.submittedOn.toDate()| date: 'MMM d, yyyy hh:mm a'):''}}</td>
                <td>{{rowData.status |titlecase}}</td>
                <td>{{rowData.type.replace('_', ' ') | titlecase}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-data let-rowData let-index="rowIndex">
            <tr *ngIf="formDetails.length>0 && isDataAvailabel">
                <td style="width: 100%;padding: 0%;background-color:white" colspan="5">
                    <div class="row" style="margin: 10px;">
                        <p-table [value]="formDetails" dataKey="id"  sortMode="multiple" rowExpandMode="single">
                            <ng-template pTemplate="header">
                                <tr >
                                    <!-- <th style="width: 3rem"></th> -->
                                    <th pSortableColumn="id">Form Name</th>
                                    <th pSortableColumn="customer">Status</th>
                                    <th pSortableColumn="date" *ngIf="rowData['type'] == 'PATIENT_FORM'">Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-content let-expanded='expanded' let-index="rowIndex">
                                <tr *ngIf="content['type'] == 'PATIENT_FORM'">
                                    <!-- <td>
                                        <a href="#" [pRowToggler]="content">
                                            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                                        </a>
                                    </td> -->
                                    <td>
                                            {{content.form_name}}</td>
                                    <td>
                                        <span [class]="content.status.toLowerCase().replace(' ','')" style="border-radius: 10px;text-align: center">
                                            <b style="margin:10px">{{content.status |titlecase}}</b>
                                        </span>
                                    </td>
                                    <td>
                                        <p-button [ngStyle]="{'padding':'6px'}" label="Download PDF" [disabled]="content['pdfUrl']==undefined"
                                            (onClick)="downloadPdf(content.pdfUrl,content.form_name+' '+rowData.contactDetails.first_name+' '+rowData.contactDetails.last_name)">
                                        </p-button>
                                    </td>
                                </tr>
                                <tr *ngIf="content['type'] == 'SURVEY_FORM'">
                                  <!-- <td>
                                      <a href="#" [pRowToggler]="content">
                                          <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                                      </a>
                                  </td> -->
                                  <td>
                                          {{content.form_name}}</td>
                                  <td>
                                      <span [class]="content.status.toLowerCase().replace(' ','')" style="border-radius: 10px;text-align: center">
                                          <b style="margin:10px">{{content.status |titlecase}}</b>
                                      </span>
                                  </td>
                                  <!-- <td>
                                      <p-button [ngStyle]="{'padding':'6px'}" label="Download PDF" [disabled]="content['pdfUrl']==undefined"
                                          (onClick)="downloadPdf(content.pdfUrl,content.form_name+' '+rowData.contactDetails.first_name+' '+rowData.contactDetails.last_name)">
                                      </p-button>
                                  </td> -->
                              </tr>
                            </ng-template>
                            <!-- <ng-template pTemplate="rowexpansion" let-response let-index="rowIndex">
                                <tr *ngIf="response.response else noQuestion">
                                    <td [attr.colspan]="response.type == 'SURVEY_FORM'?3:4" *ngIf="response.type == 'SURVEY_FORM'" style="width: 100%;padding: 15px 30px;">
                                        <div class="full-width" style="font-size:16px;" *ngIf="response.response.length>0 else noQuestion">
                                            <p-tabView>
                                                    <p-tabPanel [header]="'Questions'" [headerStyle]="{'width':'max-content','overflow':'hidden'}">
                                                        <div class="panelHeight" *ngIf="response.response">
                                                            <ng-container *ngFor="let question of response.response">
                                                                <div class="p-grid" *ngIf="question.question">
                                                                <span style="font-size:16px;font-weight:550;">{{ question.question}} &nbsp;</span>
                                                                <span *ngIf="question.answer_type!=='label'"> : &nbsp;</span>
                                                                <span *ngIf="question.answer_type=='signature'">
                                                                <span> <img [src]=question.answer width="100px" height="100px"> </span>
                                                                </span>
                                                                <span *ngIf="question.answer_type=='checkbox'">
                                                                <ng-container *ngFor="let option of question.answer; index as i;">
                                                                    <span *ngIf="option">
                                                                    {{question.choices[i]}}
                                                                    </span>
                                                                </ng-container>
                                                                </span>
                                                                <span
                                                                *ngIf="question.answer_type !='signature' && question.answer_type !='checkbox' ">
                                                                <span>{{ question.answer}}</span>
                                                                </span>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </p-tabPanel>
                                            </p-tabView>
                                        </div>
                                    </td>
                                    <td [attr.colspan]="response.type == 'PATIENT_FORM'?4:3" *ngIf="response.type == 'PATIENT_FORM'" style="width: 100%;padding: 15px 30px;">
                                        <div class="full-width" style="font-size:16px;">
                                            <p-tabView>
                                                <p-tabPanel [header]="section.name | titlecase"
                                                [headerStyle]="{'width':'max-content','overflow':'hidden'}"
                                                *ngFor="let section of response.response; let i = index" [selected]="i == 0">
                                                    <div class="panelHeight">
                                                        <ng-container *ngFor="let question of section.questions">
                                                        <div class="p-grid">
                                                            <span style="font-size:16px;font-weight:550;">{{ question.question}} &nbsp;</span>
                                                            <span *ngIf="question.answer_type!=='label'"> : &nbsp;</span>
                                                            <span *ngIf="question.answer_type=='signature'">
                                                            <span> <img [src]=question.answer width="100px" height="100px"> </span>
                                                            </span>
                                                            <span *ngIf="question.answer_type=='checkbox'">
                                                            <ng-container *ngFor="let option of question.answer; index as i;">
                                                                <span *ngIf="option">
                                                                {{question.choices[i]}}
                                                                </span>
                                                            </ng-container>
                                                            </span>
                                                            <span
                                                            *ngIf="question.answer_type !='signature' && question.answer_type !='checkbox' ">
                                                            <span>{{ question.answer}}</span>
                                                            </span>
                                                        </div>
                                                        </ng-container>
                                                    </div>
                                                </p-tabPanel>
                                            </p-tabView>
                                        </div>
                                    </td>
                                </tr>
                                <ng-template #noQuestion style="height: 100%;width: 100%;">
                                    <tr>
                                        <td [attr.colspan]="4" class="full-width" style="font-size:16px;text-align: center;width: 30%;">
                                        <b>Not Started to fill the form</b>
                                        </td>
                                    </tr>
                                </ng-template>
                            </ng-template> -->
                        </p-table>
                    </div>
                </td>
            </tr>
            <tr *ngIf="formDetails.length<=0 && isDataAvailabel">
                <td colspan="5">
                    <div style="text-align: center;width: 100%;">
                        <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
                    </div>
                </td>
            </tr>
            <tr *ngIf="!isDataAvailabel">
            <td colspan="5">
                <div style="text-align: center;width: 100%;">
                No Record Found
                </div>
            </td>
            </tr>
        </ng-template>
    </p-table>
</div>

