<div class="container-fluid">
  <br />
  <!-- <div [hidden]="!state.showBreadcrumb">
    <a [routerLink]="[state.back_url]"
      ><mat-icon class="breadcrumbs">chevron_left</mat-icon>{{ state.icon }}</a
    >
    <div class="account-name">
      <span matTooltip="{{ state.title }}"
        >{{ state.title | slice: 0:25
        }}<span *ngIf="state.title.length > 25">...</span></span
      >
    </div>
  </div> -->
  <div class="row justify-content-center">
    <div class="col">
      <nav mat-tab-nav-bar>
        <a mat-tab-link [routerLink]="['/tips']" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
          <mat-icon>announcement</mat-icon>&nbsp; Tips &nbsp;
          <span class="badge red" [hidden]="!notification">{{notification}}</span>
        </a>
        <a mat-tab-link [routerLink]="state.url" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
          <mat-icon>dashboard</mat-icon>&nbsp;Dashboard
        </a>

        <a mat-tab-link [routerLink]="['/settings']" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
          <mat-icon>settings</mat-icon>&nbsp; Settings
        </a>
      </nav>
    </div>
  </div>
</div>