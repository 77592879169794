import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Filter } from '../_models/filter.model';
import {FormControl} from '@angular/forms';
import * as moment from 'moment';
//import {ContactService} from '../_services/index';

import { FormBuilder, FormGroup,Validators } from '@angular/forms';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

@Input() filters: Array <Filter>;
@Output() filteredArray = new EventEmitter<any>();

dateCreated :any;
dateValue:any;
// operatorsForCreatedOn = new FormControl();
//operatorsForVisitedOn = new FormControl();
operatorsLabel  = [];
maxDate : any = new Date();
//operators: string[] = ['is same','is before','is after'];
value : string;
visible :boolean = true;
selectable :boolean = true;
removable :boolean = true;
addOnBlur :boolean = true;
selectedOperator : any;
separatorKeysCodes: number[] = [ENTER, COMMA];

lists=[];
keywords=[];
operatorLabel=[];
//customFilter=true; 
customFilter: boolean = false;
//	operatorsForVisitedOn = new FormControl();
//filtersArray:Filter=[];


filtersArray: Array<Filter> = [

  {
    field: {
      label: '',
      columnName: ''
    },
    operators: [
      {
        label: '',
        value: ''
      }
    ],
    datatype: '' 
  }
];

constructor(){}
//ngOnInit(){
  ngOnInit(){
  	 this.customFilter = false;
  	 console.log(this.filters);
     //console.log(this.filters.datatype);
     
  }

hideFilterSection(){
  if(this.customFilter){
    this.customFilter = false;
  }
  else {
    this.customFilter=!this.customFilter;
  }
}

fetchOperatorsLabel(value){
	console.log(this.operatorsLabel['value']);
	return this.operatorsLabel['value'];

}

fetchDate(){
	// for(let item of this.filters.field.datatype.dateValue){
	//	return moment(this..selectedValue).format("MMM DD, YYYY");
		//this.add(event:);
	//}
  	//console.log("date created to moment : "+moment(this.dateValue).format("MMM DD, YYYY"));
  //add($event);
}

showOperatorSelectedDateLastVisited(matChipEvent){
	console.log(matChipEvent.value);
	//this.add(matChipEvent);
}

showOperatorLabel(filter){
  console.log(filter);
  if(filter.datatype==='boolean'){
    filter.selectedValue='';
  }
	// for(let filter of this.filters)
	// {
	// 	console.log(this.filters);
 //    if(this.filters.datatype==='boolean'){
 //        this.filters.selectedValue='';
 //    	}
 //  }
}

addFilter(){

  console.log("inside addFilter funcrion"+this.filters);
}

add(event: MatChipInputEvent){
      const input = event.input;
      const value = event.value;
      console.log(this.filters);

      for(let item of this.filters){
      	//console.log(item);
      	console.log("this is field label"+item.field.label);
      	if(item.selectedValue instanceof Date){
      		console.log("this is instanceof date"+item.selectedValue);
      	//	dateSelected=item.selectedValue.format("MMM DD, YYYY");
      	}
      	//console.log(item.selectedValue.datatype);
      	// console.log("this is selected operator"+item.selectedOperator.label);
      	// console.log("this is is selected value"+item.selectedOperatorValue);

        //this.keywords = [item.field.label+' '+item.selectedOperatorValue.label];
        this.keywords = [item.field.label+' '+item.selectedOperator+' '+item.selectedValue.format("MMM DD, YYYY")];
        //this.lists = [item.field.label+' '+item.selectedOperator.label+' '+item.selectedOperatorValue];
        console.log(this.keywords);
      	
        // Adding to keyword
        if ((value || '').trim()) {
          this.keywords.push(value.trim());
        }

        // Reset the input value
        if (input) {
          input.value = '';
        }
        
      }
      
    }
  

remove(filter: any){
	//for(let filter of this.filters){
	    let index = this.filters.indexOf(filter);

    	if (index >= 0) {
        console.log("filters removed before undefined "+filter.selectedOperator,filter.selectedValue);
        filter.selectedOperator=undefined;
        filter.selectedValue=undefined;
        console.log("filters removed after undefined "+filter.selectedOperator,filter.selectedValue);
       // console.log(this.filters.datatype);
      //	this.filters.splice(index, 1);
     	// this.dataSource = new MatTableDataSource(this.contacts);
    	}
  //  }
    this.next();
  }

next(){
	this.filteredArray.emit(this.filters);
  this.customFilter = false;
  //console.log(this.filteredArray);
  //this.ContactService.setFilters();
	//this.add(event,MatChipInputEvent);
	//console.log("emmiting the filters....."+this.filteredArray.emit(filters));
}

showCreatedOnAndLastVisited()
{	
	//add(event: MatChipInputEvent);
	//console.log(this.dateVisited);
//	this.add(event);
}

}



