export const MENU_ARRAY = [
{'menuitem':'Accounts','access':'true','link':'/allAccounts'},
{'menuitem':'Practice Details','access':'true','link':'/add-account'},
{'menuitem':'Websites','access':'true','link':'/websites'},
{'menuitem':'Campaigns','access':'true','link':'/main'},
{'menuitem':'Contacts','access':'true','link':'/contacts'},
{'menuitem':'Appointments-Stats','access':'true','link':'/appointment-stats'},
{'menuitem':'Appointments','access':'true','link':'/appointments'},
{'menuitem':'Calendars','access':'true','link':'/calendar-config'},
{'menuitem':'Forms','access':'true','link':'/form'},
{'menuitem':'Target Keyword','access':'true','link':'/target-keywords-Details'},
// {'menuitem':'Configure Calendar','access':'true','link':'/configure-calendar'}

//{'menuitem':'Collectedforms','access':'true','link':'/collected-forms'}
];

