import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() payload: any;
  @Output() scrollEmit = new EventEmitter<any>();
  items: any;
  constructor() {
  }
  ngOnInit(): void {
    this.items = this.payload.data;
  }
}
