import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { AccountService } from '../_services';

@Component({
  selector: 'app-new-sms-camapign',
  templateUrl: './new-sms-camapign.component.html',
  styleUrls: ['./new-sms-camapign.component.scss']
})
export class NewSmsCamapignComponent implements OnInit {
  link: any
  loading: boolean = true
  constructor(private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private accountServic: AccountService) { }

  ngOnInit(): void {
    this.route.url.subscribe(url => {
      console.log(url);

      if (url[0].path == 'activity') {
        this.link = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.campaignURL}/activity/${this.accountServic.acc_details.account_slug}`);
      }
      else {
        this.link = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.campaignURL}/account/${this.accountServic.acc_details.account_slug}`);
      }
    })

    document.querySelector('iframe').onload = () => {

      this.loading = false
      document.querySelector('iframe').style.height = "80vh"
    };
  }
  iframeLoaded() {

  }

}
