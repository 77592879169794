/* Used to maintain the navigation state for all campaign components */
export class NavigationState {
  showBreadcrumb: boolean = false;
  title:string;
  icon:string;
  back_url:string;
  url:string;
  constructor(showBreadcrumb:boolean, title:string, icon:string, back_url:string, url:string) {
  this.showBreadcrumb = showBreadcrumb;
  this.title = title;
  this.icon = icon;
  this.back_url = back_url;
  this.url = url;
  }
}
