<div *ngIf="reviews.length>0" class="child-container" style="overflow: hidden;height: 100%;">
    <div infinite-scroll style="overflow-x:hidden ;padding-left: 15px;height: 100%;padding-bottom: 2em;"
        (scrolled)="onScrollDown()" [alwaysCallback]="true" #ref [infiniteScrollContainer]="ref">
        <div class="reviewDiv" *ngFor="let content of reviews ;let index = index">
            <hr>
            <div class="row" style="padding:0px;">
                <div class="col-1 hidePadding" *ngIf="content!=undefined" style="padding-left: 26px;">
                    <img src={{content.reviewer.profilePhotoUrl}} style="height: 50px;width: 50px;">
                </div>
                <div class="col-11 hidePadding" id="authorInfo" style="padding-top: 0%;">
                    <div>
                        <div>
                            <img *ngIf="content.source == 'google' " id="googleLogo" src="assets/images/googlelogo.png"
                                alt="image">
                            {{(content['reviewer']['displayName'])}}
                        </div>
                    </div><br>
                    <div class="row" style="margin-top: -1em;">
                        <div style="padding-left: 15px;">
                            <p-rating [ngModel]="(content['starRating'])" readonly="true" stars="5" [cancel]="false">
                            </p-rating>

                        </div>
                        <div style="padding-left: 10px;font-size:12px;margin-top: 3px">
                            {{(moment(content['createTime'].toDate()).format('ll'))}}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="content['comment']" style="height: auto;">
                <div style="padding-top: 5px;padding-left: 80px;">
                    {{content.comment.length>200?content.comment.substring(0,200) : content.comment}}
                    <span *ngIf="content.comment.length > 200 &&  content.show ">
                        {{content.comment.substring(200,content.comment.length)}}
                    </span> <a *ngIf="content.comment.length > 200" (click)="showMoreContent(content)"
                        id="readMoreBtn">{{
                        content.show ? ' «less':
                        '..more»'}}</a>
                </div>
            </div>
            <div *ngIf="content?.reviewReply?.comment" style="padding-left: 10px;">
                <label style="color: rgb(9, 24, 231)">Reply :</label>
                <!-- <div style="padding-left: 10px;font-size:12px;margin-top: 3px">
                    {{(moment(content['reviewReply']['updateTime'].toDate()).format('ll'))}}
                </div> -->
                <div class="contentText" [class.show]="content.show" style="padding-left: 10px;padding-top: 4px;">
                    {{(content?.reviewReply?.comment)}}
                </div>
                <span *ngIf="content?.reviewReply?.comment.length > 425"
                    style="text-align: center;font-weight: 500;font-size: 16px;padding-left: 10px;">
                    <a (click)="showMoreContent(content)" id="readMoreBtn">..{{ content.show ? "Read Less": "Read More"}}</a>
                </span>
            </div>
            <div class="row" *ngIf="content.comment">
                <div class="col-md-5" style="padding-left: 15px;">
                </div>
                <div *ngIf="content.submit; else reply">
                </div>
                <div class="col-md-3" *ngIf="content.comment">
                    <p-splitButton #btn [label]="(content['postedStatus'] == 'posted')?'Posted':'Download Post'" [disabled]="!content.comment" (onDropdownClick)="onDropdownClick(content,index)" (onClick)="postOnSocialMedia(content)" [model]="content.model"></p-splitButton>
                </div>
                <ng-template #reply>
                    <div class="col-md-2" *ngIf='content.reviewReply?.comment'>
                        <button mat-raised-button [disabled]="!isAccountExists" style="float: right;" (click)='updateReply(content)'> Update
                            Reply</button>
                    </div>
                    <div class="col-md-2" *ngIf='content.reviewReply?.comment'>
                        <button mat-raised-button [disabled]="!isAccountExists" style="float: right;" (click)="deleteReply(content)">Delete
                            Reply</button>
                    </div>
                    <div class="col-md-2" *ngIf='!content.reviewReply?.comment'></div>
                    <div class="col-md-2" *ngIf='!content.reviewReply?.comment'>
                        <button mat-raised-button [disabled]="!isAccountExists" style="float: right;"
                            (click)='replyToReview(content)'>Reply</button>
                    </div>
                </ng-template>

            </div>
            <div class="row" *ngIf="!content.comment" style="padding:0px 15px 5px 0px">
                <div class="col-md-6" style="padding-left: 15px;">
                </div>
                <div *ngIf="content.submit; else reply">
                </div>
                <!-- <div class="col-md-3" *ngIf="content.comment">
                    <p-splitButton #btn label="Download Post" [disabled]="!content.comment" (onDropdownClick)="onDropdownClick(content)" (onClick)="postOnSocialMedia(content)" [model]="items"></p-splitButton>
                </div> -->
                <ng-template #reply>
                    <div class="col-md-3" *ngIf='content.reviewReply?.comment'>
                        <button mat-raised-button [disabled]="!isAccountExists" style="float: right;" (click)='updateReply(content)'> Update
                            Reply</button>
                    </div>
                    <div class="col-md-3" *ngIf='content.reviewReply?.comment'>
                        <button mat-raised-button [disabled]="!isAccountExists" style="float: right;" (click)="deleteReply(content)">Delete
                            Reply</button>
                    </div>
                    <div class="col-md-3" *ngIf='!content.reviewReply?.comment'></div>
                    <div class="col-md-3" *ngIf='!content.reviewReply?.comment' style="margin-left:-14px">
                        <button mat-raised-button [disabled]="!isAccountExists" style="float: right"
                            (click)='replyToReview(content)'>Reply</button>
                    </div>
                </ng-template>

            </div>
        </div>
        <div *ngIf="fetchReview" id="spinner" style="padding-top: 0%;">
            <div id="spinnerDiv">
                <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
            </div>
        </div>
        <div *ngIf="!fetchReview" style="padding-top: 0%;">
            <div style="text-align: center;">
                <h5>No more reviews</h5>
            </div>
        </div>
    </div>

</div>