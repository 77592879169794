/*
 * Legacy code
 */

import { Component, OnInit } from '@angular/core';
import { SeoCsvService } from '../../_services/index';

@Component({
  selector: 'app-seo-ranking',
  templateUrl: './seo-ranking.component.html',
  styleUrls: ['./seo-ranking.component.scss']
})
export class SeoRankingComponent implements OnInit {
seo:any;

  constructor(
    private seoCsvService:SeoCsvService
  ) { }

  ngOnInit() {
 this.seoCsvService.getCsvRank().subscribe( seo => {
   this.seo = seo;
   console.log(this.seo);

 });
  }



}
