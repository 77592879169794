import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-email-sms-status-activity',
  templateUrl: './email-sms-status-activity.component.html',
  styleUrls: ['./email-sms-status-activity.component.scss']
})
export class EmailSmsStatusActivityComponent implements OnInit {
  events = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data)
    for (let i = 0; i < data.events?.length; i++) {
      this.events.push({
        status: data.events[i].event,
        date: moment(data.events[i].timestamp * 1000).format("MM/DD/YYYY hh:mm a")
      })
    }
    console.log(this.events)
  }


  ngOnInit() {

  }
}