import { Component, OnChanges, Input, Output, SimpleChange, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { MessageService } from './../_services/message.service';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnChanges {

  @Output() newMessage = new EventEmitter<any>();
  @Output() nextMessages = new EventEmitter<any>();
  @Output() language = new EventEmitter<any>();
  @Input() messages: any[];
  @Input() name: any;
  @Input() twilio_credentials: any;
  @Input() languages: any;
  @Input() fullPhone: any;
  @Input() chatMessageLoading: boolean;

  phone: any;
  fullName: any = {};
  allMessages = [];
  isReady: boolean = false;
  newMessageText: any;
  selectedLanguage: string;
  selectedLanguageName: string;
  loading: boolean = false;
  scrollHeight: number = 0;
  scrollTo: number = 0;
  showMsg: boolean = false;

  constructor(public dialog: MatDialog,
    private messageService: MessageService,
  ) { }

  async ngOnInit() {
    this.allMessages = this.messages;
    console.log(this.allMessages);
    this.fullName = this.name;
    this.isReady = true;
    this.phone = this.fullPhone;
    this.selectedLanguage = await this.messageService.getTargetLanguage(this.phone);
    this.languages.forEach(language => {
      if (language.value == this.selectedLanguage)
        this.selectedLanguageName = language.name;
    })
    this.showMsg = true
    this.language.emit(this.selectedLanguage);
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  async ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('messages' in changes) {
      this.allMessages = changes.messages.currentValue;
      console.log(this.allMessages);
      this.loading = false;
    }
    if ('name' in changes) {
      this.fullName = changes.name.currentValue;
    }
    if ('fullPhone' in changes) {
      this.showMsg = false;
      this.phone = changes.fullPhone.currentValue;
      this.selectedLanguage = await this.messageService.getTargetLanguage(this.phone);
      this.languages.forEach(language => {
        if (language.value == this.selectedLanguage)
          this.selectedLanguageName = language.name;
      })
      this.showMsg = true;
    }
    this.isReady = true;
  }

  singleMessage() {
    if (this.twilio_credentials) {
      let newMessage = this.newMessageText.trim();
      this.newMessage.emit(newMessage);
      this.newMessageText = "";
    }
    else {
      this.dialog.open(ChatDialog);
    }
  }

  selectLanguage(event) {
    this.languages.forEach(language => {
      if (language.value == event.value)
        this.selectedLanguageName = language.name;
    })
    this.language.emit(event.value);
  }

  checkDate(d1, d2) {
    d1 = d1.toDate();
    d2 = d2.toDate();
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate() ? false : true;
  }

  scrollToBottom() {
    let msgBox = document.getElementById('scrollMe');
    if (msgBox)
      msgBox.scrollTop = msgBox.scrollHeight;
  }
}

@Component({
  selector: 'chat-dialog',
  templateUrl: 'chat-dialog.html',
})
export class ChatDialog { }
