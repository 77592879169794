/*
 * The data source for Contacts server-side pagination
 */

import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable ,  BehaviorSubject ,  of } from "rxjs";
import { ContactService } from "./contact.service";
import { catchError, finalize } from "rxjs/operators";



export class ContactsDataSource implements DataSource<any> {

    public contacts: any = [];

    public contact: any = [];

    private contactsSubject = new BehaviorSubject<any[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private notLoadingSubject = new BehaviorSubject<boolean>(true);

    public loading$ = this.loadingSubject.asObservable();

    public notLoading$ = this.notLoadingSubject.asObservable();

    doc_id :any;

    constructor(private contactService: ContactService) {

    }

    getContacts(sortCol: string, sortDirection: string, pageIndex: number, pageSize: number) {

        this.loadingSubject.next(true);
        this.notLoadingSubject.next(false);

        if(this.contactService.pageSize !== undefined && this.contactService.pageSize !== pageSize) {
            this.contactService.resetPageEndpoints();
        }


        if(this.contactService.sortCol !== sortCol) {
            this.contactService.sortCol = sortCol;
            this.contactService.resetPageEndpoints();
        }

        if(this.contactService.sortDirection !== sortDirection) {
            this.contactService.sortDirection = sortDirection;
            this.contactService.resetPageEndpoints();
        }

        this.contactService.getPaginatedContacts(pageIndex, pageSize)
        .then(
            contacts_snapshot => {
                let docsCount = contacts_snapshot.size;

                this.contacts = [];

                contacts_snapshot.forEach(
                    contact_snapshot => {
                        let contact = contact_snapshot.data();

                        contact.ref = contact_snapshot.ref;
                        if(contact.last_visited !== undefined && contact.last_visited !== "") {
                            contact.last_visited = contact.last_visited.toDate('MMM d, yyyy');
                        }
                        if(contact.active === undefined) {
                            contact.active=true;
                        }
                        if(contact.created_on !== undefined) {
                            contact.created_on = contact.created_on.toDate();
                        }

                        this.contacts.push(contact);
                    }
                );

                // If this is the first time we have fetched this page
                if(pageIndex === this.contactService.pagesFetched) {
                    this.contactService.setPageEndpoints(
                        contacts_snapshot.docs[0],
                        contacts_snapshot.docs[docsCount - 1]
                    );
                }

                this.loadingSubject.next(false);
                this.notLoadingSubject.next(true);

                this.contactsSubject.next(this.contacts);

                console.log("Contacts fetched!");
            },
            error => {
                this.loadingSubject.next(false);
                this.notLoadingSubject.next(true);
                this.contactsSubject.next([]);
                console.error(error);
            }
        );

    }

 getContactForNotification(doc_id) {


        this.contactService.getPaginatedContactNotification(doc_id)
        .then(
            contacts_snapshot => {

                this.contacts = [];

                //contacts_snapshot.forEach(
                  //  contact_snapshot => {
                        let contact = contacts_snapshot.data();
                        contact.ref = contacts_snapshot.ref;
                        if(contact.last_visited !== undefined && contact.last_visited !== "") {
                            contact.last_visited = contact.last_visited.toDate('MMM d, yyyy');
                        }
                        if(contact.active === undefined) {
                            contact.active=true;
                        }
                        if(contact.created_on !== undefined) {
                            contact.created_on = contact.created_on.toDate();
                        }

                        this.contacts.push(contact);
                   // }
               // );

                // If this is the first time we have fetched this page
                // if(pageIndex === this.contactService.pagesFetched) {
                //     this.contactService.setPageEndpoints(
                //         contacts_snapshot.docs[0],
                //         contacts_snapshot.docs[docsCount - 1]
                //     );
                // }

                this.loadingSubject.next(false);
                this.notLoadingSubject.next(true);

                this.contactsSubject.next(this.contacts);

                console.log("Contacts fetched!");
            },
            error => {
                this.loadingSubject.next(false);
                this.notLoadingSubject.next(true);
                this.contactsSubject.next([]);
                console.error(error);
            }
        );

    }



    getContact(doc_id){
        //this.contactService.getContact(doc_id);
        this.contactService.getContact(doc_id).then(
        data=>{

          //this.contact=data;
          if(data.last_visited !== undefined && data.last_visited !== "") {
                data.last_visited = data.last_visited.toDate('MMM d, yyyy');
                }
                if(data.active === undefined) {
                    data.active=true;
                }
                if(data.created_on !== undefined) {
                    data.created_on = data.created_on.toDate();
                }
          this.contacts.push(data);
                 });
        this.contactsSubject.next(this.contacts);
    }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        console.log("Connecting data source");
        return this.contactsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        console.log('Disconnecting data source');
        this.contactsSubject.complete();
        this.loadingSubject.complete();
    }

}
