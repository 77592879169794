import { Component, OnInit } from '@angular/core';
import { AlertService, NavigationService } from '../_services/index';
import { User } from '../_models/index';
import { Router, ActivatedRoute,Params } from '@angular/router';

import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { NavigationState } from '../_models/index';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  uuid: string;

   constructor(
       private router: Router,
       private alertService: AlertService,
       private navigationService: NavigationService
     ) {
        this.navigationService.updateState(new NavigationState(false, "Landing", "", "#", "/websites"));
     }


   ngOnInit() {
   }

   /*
  createUser()
  {
  //let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  //let anonymousUser = JSON.parse(localStorage.getItem('anonymousUser'));

    let user = this.userService.getUser();

  //if( currentUser == null && anonymousUser == null ){
  if ( user == null ) {
     this.userService.createUser()
         .then( data => {
             //console.log(data);
             localStorage.setItem('user', data.text());
             this.router.navigate(['/websites']);
             },
             error => {
                 this.alertService.error("Failed to create user ");
             });
  }
  else{
    this.router.navigate(['/all-plans']);
  }


}
*/
}
