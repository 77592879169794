import { Component, OnInit,OnDestroy } from "@angular/core";
import { NavigationService, AccountService } from "../_services/index";
import { NavigationState } from "../_models/index";
import { Router } from "@angular/router";

// export interface Country {
//   value: string;
//   viewValue: string;
// }

@Component({
  selector: "app-websites",
  templateUrl: "./websites.component.html",
  styleUrls: ["./websites.component.scss"],
})
export class WebsitesComponent implements OnInit,OnDestroy {
  state: NavigationState;
  acc: any;
  website: string;
  countryCodeList: any = [];
  countryCode: string = "";
  isReady: boolean;
  showButton: boolean = false;
  refresh: boolean;
  selected: any;
  acknowledge:boolean=true;
  // countries: Country[] = [
  //     // {value: 'IN', viewValue: 'India'},
  //     // {value: 'US', viewValue: 'USA'},
  //     // {value: 'UK', viewValue: 'UK'}
  //   ];

  constructor(
    private navigationService: NavigationService,
    private accountService: AccountService,
    private router: Router
  ) {

    this.accountService.accChange$.subscribe((accChange) => {
      this.isReady = false;
      //this.router.navigateByUrl('/main');
      let state = this.navigationService.getCurrentNavigationState();
      if (state.url === "/websites") {
        this.acc = this.accountService.getCurrentAccDetails();
        this.acknowledge = this.accountService.checkDemoDataAcknowledged(this.acc);
        this.website = this.acc.url;
        this.setCountryCode();
        this.isReady = true;
      }
    });
    //  this.navigationService.updateState(new NavigationState(false, "", "", "#", "/on-page"));
  }

  ngOnInit() {

    this.navigationService.updateState(
      new NavigationState(false, "Websites", "", "#", "/websites")
    );
    this.refresh = true;
    this.acc = this.accountService.getCurrentAccDetails();
    this.acknowledge = this.accountService.checkDemoDataAcknowledged(this.acc);
    this.setCountryCode();
    this.website = this.acc.url;
    this.refresh = false;
  }

  showSaveButton() {
    this.showButton = true;
  }

  changeData(newValue) {
    let result = this.countryCodeList.find((i) => i.countryName === newValue);
    this.selected = result.countryName;
    this.countryCode = result.countryCode;
  }

  setCountryCode() {
    this.countryCodeList = [];
    this.accountService.getCountryCodes().then(
      (response) => {
        response.forEach((doc) => {
          this.countryCodeList.push(doc.data());
        });
        if (this.acc != undefined) {
          let result = this.countryCodeList.find(
            (i) => i.countryCode === this.acc.countryCode
          );
          if (result != undefined) {
            this.countryCode = result.countryCode;
            this.selected = result.countryName;
          } else {
            this.countryCode = "";
            this.selected = "";
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  updateUrl(newUrl: any) {
    this.website = "";
    this.refresh = true;
    this.accountService
      .updateWebsiteUrl(newUrl)
      .then((success) => {
        this.acc = this.accountService.getCurrentAccDetails();
        this.website = this.acc.url;
        this.refresh = false;
      })
      .catch((error) => {
        console.log("error");
      });
    this.showButton = false;
  }
  ngOnDestroy(){

  }
}
