import { Injectable, OnDestroy } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject, Subject } from 'rxjs';
import algoliasearch from "algoliasearch/lite";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const searchClient = algoliasearch(
  'I0OTY0C5ZB',
  '25abaa049834414cfa024ce599de178c'
);
const algoliaContacts = searchClient.initIndex(environment.contacts);

@Injectable({
  providedIn: 'root'
})
export class MessageService implements OnDestroy {

  private contactSubject = new BehaviorSubject<any>([]);
  contact$ = this.contactSubject.asObservable();
  contactUnsubscribe: any;
  constructor(private afs: AngularFirestore, private http: HttpClient) {
    this.contactUnsubscribe = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('contacts').orderBy("lastMessages.date", "desc").limit(20).onSnapshot(async snapshot => {
        let list = [];
        await Promise.all(snapshot.docs.map(async doc => {
          let obj = doc.data();
          obj.id = doc.id;
          // obj.targetLangauge = await this.getTargetLanguage(obj.full_phone);
          list.push(obj);
        }))
        let pagingInfo = {
          list: list,
          startAfter: snapshot.docs[snapshot.docs.length - 1]
        }
        this.contactSubject.next(pagingInfo);
      });

  }
  async fetchContactByPagingAlgolia(searchText) {
    let resp = await algoliaContacts.search(searchText, {
      filters: `_tags:'${sessionStorage.getItem('current_acc_id')}' AND active:'true'`,
      hitsPerPage: 20,
      page: 0,
      attributesToHighlight: [],
      cacheable: false
    })
    return resp;
  }

  ngOnDestroy(): void {
    console.log("service destroyed")
    this.contactUnsubscribe();
  }

  // async getLastMessages() {
  //   let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
  //     .collection('contacts').orderBy("lastMessages.date", "desc").get();
  //   if (snapshot.empty) {
  //     console.log('No matching documents.');
  //     return [];
  //   }
  //   else {
  //     let messageData = [];
  //     return snapshot;
  //   }
  // }

  async getLastMessages(collection, startAfter) {
    let query = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection(collection).orderBy("lastMessages.date", "desc").limit(20);
    if (startAfter != null) {
      query = query.startAfter(startAfter);
    }
    let snapshot = await query.get();
    let list = [];
    await Promise.all(snapshot.docs.map(async doc => {
      let obj = doc.data();
      obj.id = doc.id;
      // obj.targetLangauge = await this.getTargetLanguage(obj.full_phone);
      list.push(obj);
    }))
    let pagingInfo = {
      list: list,
      startAfter: snapshot.docs[snapshot.docs.length - 1]
    }
    return pagingInfo;
  }

  async fetchSmsLogs(contactId) {
    let snapshot = await firebase.firestore()
      .doc(`accounts/${sessionStorage.getItem('current_acc_id')}/contacts/${contactId}`).get();
    let phone = snapshot.data().full_phone;
    let snap = await firebase.firestore()
      .collection(`accounts/${sessionStorage.getItem('current_acc_id')}/messages/${phone}/sms`)
      .orderBy("date", "asc").get();
    let list: any = [];
    snap.forEach(doc => {
      let obj = doc.data();
      obj.id = doc.id;
      list.push(obj);
    })
    let pagingInfo = {
      list: list,
      startAfter: snap.docs[snap.docs.length - 1]
    }
    return pagingInfo;
  }
  async fetchNextSmsLogs(contactId, startAfter) {
    let snapshot = await firebase.firestore()
      .doc(`accounts/${sessionStorage.getItem('current_acc_id')}/contacts/${contactId}`).get();
    let phone = snapshot.data().full_phone;
    let query = await firebase.firestore()
      .collection(`accounts/${sessionStorage.getItem('current_acc_id')}/messages/${phone}/sms`)
      .orderBy("date", "desc").limit(6);
    if (startAfter != null) {
      query = query.startAfter(startAfter);
    }
    let snap = await query.get();
    let list: any = [];
    snap.forEach(doc => {
      let obj = doc.data();
      obj.id = doc.id;
      list.push(obj);
    })
    let pagingInfo = {
      list: list,
      startAfter: snap.docs[snap.docs.length - 1]
    }
    return pagingInfo;
  }

  async changeTargetLanguage(full_phone, target) {
    let snap = await firebase.firestore().collection(`accounts/${sessionStorage.getItem('current_acc_id')}/messages`)
      .doc(`${full_phone.replace(/\s+/g, '')}`).get();
    snap.ref.update({ targetLanguage: target });
  }

  async getTargetLanguage(full_phone) {
    let snap = await firebase.firestore().collection(`accounts/${sessionStorage.getItem('current_acc_id')}/messages`)
      .doc(`${full_phone.replace(/\s+/g, '')}`).get();
    let data = snap.data();
    if (data) {
      if (data.targetLanguage) {
        return data.targetLanguage;
      }
      else {
        await snap.ref.update({ targetLanguage: 'en', full_phone: full_phone.replace(/\s+/g, '') });
        return 'en';
      }
    }
    else {
      await snap.ref.set({ targetLanguage: 'en', full_phone: full_phone.replace(/\s+/g, '') });
      return 'en';
    }
  }

  async updateReadMessage(docid) {
    await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('contacts').doc(docid).update({ "lastMessages.unread": false });
  }

  async getDummyContacts() {
    // return this.http.get(`../../assets/json/lastMessage.json`);

    let snapshot = await firebase.firestore().collection("accounts").doc('UakeKoWZqGdjWiezZzBC').collection("contacts").get();
    console.log(snapshot);
    return snapshot;
  }

  async getaDummyMessages() {
    // return this.http.get(`../../assets/json/allMessages.json`);

    let snapshot = await firebase.firestore().collection("accounts").doc('UakeKoWZqGdjWiezZzBC').
      collection("all-dummy-messages").get();
    console.log(snapshot);
    return snapshot;
    // console.log(snapshot.data());
    // let dummyMessages=[];
    // console.log(dummyMessages);
    // dummyMessages.push(snapshot.data());
    // return dummyMessages;
  }

  addDoc(data) {
    let id = 'UakeKoWZqGdjWiezZzBC';
    firebase.firestore().collection("accounts").doc(id).collection("all-dummy-messages").add(data)
      .then(
        success => {
          console.log("Doc created successfully !");
        },
        error => {
          console.error(error);
        }
      );
  }

  getJson() {
    return this.http.get(`../../assets/json/allMessages.json`);
  }
}
