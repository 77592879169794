import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Chart } from "chart.js";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NavigationState } from "../_models/index";
import { EmailCampaign } from "../_models/index";
import {
  NavigationService,
  EmailReportService,
  TimePeriodService,
  GoogleAnalyticsService,
  AccountService,
  AlertService,
} from "../_services/index";
import { DecimalPipe, DatePipe } from "@angular/common";

@Component({
  selector: "app-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.css"],
})
export class EmailComponent implements AfterViewInit, OnInit {
  canvas: any;
  loadingMap: boolean;
  isReady: boolean;
  isListsReady: boolean;
  timeperiod: any;
  spend: number = 10000;
  value: number = 13000;
  email_report: any = [];
  lists: any;
  totals = {
    recipients: 0,
    delivered: 0,
    bounced: 0,
    unsubscribed: 0,
    opens: 0,
    clicks: 0,
  };

  performance_graph = [];
  performance_graph_labels = [];
  open_rate_array = [];
  click_rate_array = [];
  bounce_rate_array = [];
  reach = 0;
  analytics: any;
  users = 0;
  sessions = 0;
  bounce_rate = 0;
  ppv = 0.0;
  duration = "00:00:00";

  acc: any;
  emailCredentials: any;
  analyticsCredentials: any;

  constructor(
    private emailReportService: EmailReportService,
    private router: Router,
    private navigationService: NavigationService,
    private timePeriodService: TimePeriodService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private accountService: AccountService,
    private alertService: AlertService
  ) {
    this.navigationService.updateState(
      new NavigationState(true, "Email", "Campaigns", "/main", "/email")
    );
    this.isReady = false;

    this.accountService.accChange$.subscribe((accChange) => {
      this.router.navigateByUrl("/websites");
      /*
          let state = navigationService.getCurrentNavigationState();
          if(state.url === '/email') {
            this.acc = this.accountService.getCurrentAccDetails();
            console.log('Account is changed');
            this.emailCredentials = this.accountService.getLinkedAccountCredentials('email');
            this.analyticsCredentials = this.accountService.getLinkedAccountCredentials('analytics');

            if(this.emailCredentials){
              if(this.emailCredentials.campaign_monitor_client_id){
                this.loadData();
                this.alertService.reset('');
              }
            }
            else{
              this.handleUnlinkedAcc();
            }

        }
        */
    });

    this.timePeriodService.timePeriod$.subscribe((timeperiod) => {
      let state = this.navigationService.getCurrentNavigationState();
      if (state.url === "/email") {
        this.timeperiod = timeperiod;
        this.loadData();
      }
    });
  }

  handleUnlinkedAcc() {
    this.spend = 0;
    this.value = 0;
    this.email_report = [];
    this.isReady = true;
    this.isListsReady = true;
    this.totals = {
      recipients: 0,
      delivered: 0,
      bounced: 0,
      unsubscribed: 0,
      opens: 0,
      clicks: 0,
    };

    this.lists = [];

    this.performance_graph = [];
    this.performance_graph_labels = [];
    this.open_rate_array = [];
    this.click_rate_array = [];
    this.bounce_rate_array = [];

    this.users = 0;
    this.sessions = 0;
    this.bounce_rate = 0;
    this.ppv = 0;
    this.duration = "00:00:00";

    this.alertService.error("You are trying to access an unlinked account");
  }

  calcReach(lists_obj) {
    this.reach = lists_obj.list_count;
  }

  loadData() {
    this.users = 0;
    this.sessions = 0;
    this.bounce_rate = 0;
    this.ppv = 0.0;
    this.duration = "00:00:00";

    this.isReady = false;
    this.isListsReady = false;
    if (this.analyticsCredentials) {
      this.googleAnalyticsService.getEmailEngagement(
        this.analyticsCredentials.analytics_refresh_token,
        this.analyticsCredentials.ga_view_id,
        this.timeperiod
      )
        .subscribe((analytics) => {
          this.analytics = analytics;
          this.isReady = true;
          if (this.analytics.results !== null) {
            this.users = this.analytics.results.users;
            this.sessions = this.analytics.results.sessions;
            this.bounce_rate = this.analytics.results.bounce_rate;
            this.ppv = this.analytics.results.page_view_per_session;
            let hrs = new DecimalPipe("en-US").transform(
              Math.floor(this.analytics.results.avg_session_duration / 3600),
              "2.0-0"
            );
            let min = this.analytics.results.avg_session_duration / 60;
            let min_transformed = new DecimalPipe("en-US").transform(
              Math.floor(min > 60 ? min % 60 : min),
              "2.0-0"
            );
            let sec = new DecimalPipe("en-US").transform(
              this.analytics.results.avg_session_duration % 60,
              "2.0-0"
            );
            this.duration = `${hrs}:${min_transformed}:${sec}`;
          } else {
            this.users = 0;
            this.sessions = 0;
            this.bounce_rate = 0;
            this.ppv = 0.0;
            this.duration = "00:00:00";
          }
        });
    }

    let record = JSON.parse(
      sessionStorage.getItem("Email-all-" + this.acc.name)
    );
    let list_record = JSON.parse(
      sessionStorage.getItem("Email-lists-" + this.acc.name)
    );

    if (record !== null) {
      if (
        new DatePipe("en-US").transform(this.timeperiod.start, "yyyy-MM-dd") ===
        record.timeperiod_start &&
        new DatePipe("en-US").transform(this.timeperiod.end, "yyyy-MM-dd") ===
        record.timeperiod_end
      ) {
        if (Date.now() - record.creation_timestamp < 300000) {
          console.log(
            "We have a fresh record for this time period. Fetched from localStorage..."
          );
          this.email_report = record.email_results.campaigns;
          this.addUp();

          setTimeout(() => {
            this.loadingMap = true;
            if (this.loadingMap == true) {
              this.paintPerformanceGraph();
            }
          }, 2000);

          this.lists = list_record.email_list_results;
          this.isReady = true;
          this.isListsReady = true;
        } else {
          console.log("We have an old record for this time period. Called API and updated record...");

          this.emailReportService.getCampaigns(
            this.emailCredentials.campaign_monitor_client_id,
            this.emailCredentials.campaign_monitor_api_key,
            this.timeperiod
          )
            .then(
              (res) => {
                this.email_report = res.campaigns;
                sessionStorage.setItem(
                  "Email-all-" + this.acc.name,
                  JSON.stringify({
                    creation_timestamp: Date.now(),
                    email_results: res,
                    timeperiod_start: new DatePipe("en-US").transform(this.timeperiod.start, "yyyy-MM-dd"),
                    timeperiod_end: new DatePipe("en-US").transform(this.timeperiod.end, "yyyy-MM-dd"),
                  })
                );
                this.addUp();
                this.paintPerformanceGraph();
                this.isReady = true;
              },
              (error) => console.error(error)
            );

          this.emailReportService
            .getSegments(
              this.emailCredentials.campaign_monitor_client_id,
              this.emailCredentials.campaign_monitor_api_key,
              this.emailCredentials.campaign_monitor_list_id
            )
            .then((lists) => {
              this.lists = lists;
              sessionStorage.setItem(
                "Email-lists-" + this.acc.name,
                JSON.stringify({
                  creation_timestamp: Date.now(),
                  email_list_results: this.lists,
                })
              );
              this.isListsReady = true;
            });
        }
      } else {
        console.log("We do not have a record for this timeperiod. Called API and added record..");

        this.emailReportService.getCampaigns(
          this.emailCredentials.campaign_monitor_client_id,
          this.emailCredentials.campaign_monitor_api_key,
          this.timeperiod
        )
          .then(
            (res) => {
              this.email_report = res.campaigns;
              sessionStorage.setItem(
                "Email-all-" + this.acc.name,
                JSON.stringify({
                  creation_timestamp: Date.now(),
                  email_results: res,
                  timeperiod_start: new DatePipe("en-US").transform(this.timeperiod.start, "yyyy-MM-dd"),
                  timeperiod_end: new DatePipe("en-US").transform(this.timeperiod.end, "yyyy-MM-dd"),
                })
              );
              this.addUp();
              this.paintPerformanceGraph();
              this.isReady = true;
            },
            (error) => console.error(error)
          );

        this.emailReportService
          .getSegments(
            this.emailCredentials.campaign_monitor_client_id,
            this.emailCredentials.campaign_monitor_api_key,
            this.emailCredentials.campaign_monitor_list_id
          )
          .then((lists) => {
            this.lists = lists;
            sessionStorage.setItem(
              "Email-lists-" + this.acc.name,
              JSON.stringify({
                creation_timestamp: Date.now(),
                email_list_results: this.lists,
              })
            );
            this.isListsReady = true;
          });
      }
    } else {
      console.log("We do not have a record for Email campaigns. Called API and added record...");

      this.emailReportService.getCampaigns(
        this.emailCredentials.campaign_monitor_client_id,
        this.emailCredentials.campaign_monitor_api_key,
        this.timeperiod
      )
        .then(
          (res) => {
            this.email_report = res.campaigns;
            sessionStorage.setItem(
              "Email-all-" + this.acc.name,
              JSON.stringify({
                creation_timestamp: Date.now(),
                email_results: res,
                timeperiod_start: new DatePipe("en-US").transform( this.timeperiod.start,"yyyy-MM-dd"),
                timeperiod_end: new DatePipe("en-US").transform( this.timeperiod.end, "yyyy-MM-dd"),
              })
            );
            this.addUp();
            this.paintPerformanceGraph();
            this.isReady = true;
          },
          (error) => console.log(error)
        );

      this.emailReportService
        .getSegments(
          this.emailCredentials.campaign_monitor_client_id,
          this.emailCredentials.campaign_monitor_api_key,
          this.emailCredentials.campaign_monitor_list_id
        )
        .then((lists) => {
          this.lists = lists;
          sessionStorage.setItem(
            "Email-lists-" + this.acc.name,
            JSON.stringify({
              creation_timestamp: Date.now(),
              email_list_results: this.lists,
            })
          );
          this.isListsReady = true;
        });
    }
  }

  ngOnInit() {
    this.timeperiod = this.timePeriodService.getCurrentTimeperiod();
    this.acc = this.accountService.getCurrentAccDetails();

    this.emailCredentials = this.accountService.getLinkedAccountCredentials(
      "email"
    );
    this.analyticsCredentials = this.accountService.getLinkedAccountCredentials(
      "analytics"
    );

    if (this.emailCredentials) {
      this.loadData();
      this.alertService.reset("");
    } else {
      this.handleUnlinkedAcc();
    }
  }

  addUp() {
    this.totals.recipients = 0;
    this.totals.delivered = 0;
    this.totals.bounced = 0;
    this.totals.unsubscribed = 0;
    this.totals.opens = 0;
    this.totals.clicks = 0;

    this.open_rate_array = [];
    this.click_rate_array = [];
    this.bounce_rate_array = [];
    this.performance_graph_labels = [];

    for (let i = this.email_report.length - 1; i >= 0; i--) {
      let successful_deliveries =
        this.email_report[i].details.Recipients -
        this.email_report[i].details.Bounced;

      this.totals.recipients += this.email_report[i].details.Recipients;
      this.totals.delivered += successful_deliveries;
      this.totals.bounced += this.email_report[i].details.Bounced;
      this.totals.unsubscribed += this.email_report[i].details.Unsubscribed;
      this.totals.opens += this.email_report[i].details.UniqueOpened;
      this.totals.clicks += this.email_report[i].details.Clicks;

      this.open_rate_array.push(
        (this.email_report[i].details.UniqueOpened / successful_deliveries) *
        100
      );
      this.click_rate_array.push(
        (this.email_report[i].details.Clicks / successful_deliveries) * 100
      );
      this.bounce_rate_array.push(
        (this.email_report[i].details.Bounced /
          this.email_report[i].details.Recipients) *
        100
      );

      this.performance_graph_labels.push("#" + (this.email_report.length - i));
    }
  }

  paintPerformanceGraph() {
    this.performance_graph.push(new Chart("canvas", {
      type: "line",
      data: {
        labels: this.performance_graph_labels,
        datasets: [
          {
            label: "Open Rate",
            data: this.open_rate_array,
            backgroundColor: ["rgba(255,255,255,0)"],
            borderColor: ["rgba(178,0,0,1)"],
            borderWidth: 1,
          },
          {
            label: "Click Rate",
            data: this.click_rate_array,
            backgroundColor: ["rgba(255,255,255,0)"],
            borderColor: ["rgba(58,95,11,1)"],
            borderWidth: 1,
          },
          {
            label: "Bounce Rate",
            data: this.bounce_rate_array,
            backgroundColor: ["rgba(255,255,255,0)"],
            borderColor: ["rgba(255,140,0,1)"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Campaigns",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                callback: function (value) {
                  return value + "%";
                },
              },
            },
          ],
        },
      },
    }));
  }

  ngAfterViewInit() { }
}
