import { Component, Inject, OnInit, SimpleChange } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from '../../_services/account.service';
import { MessageService } from '../../_services/message.service';
import { ReviewsService } from '../../_services/reviews.service';

@Component({
  selector: 'app-send-review-invite-dialog',
  templateUrl: './send-review-invite-dialog.component.html',
  styleUrls: ['./send-review-invite-dialog.component.scss']
})
export class SendReviewInviteDialogComponent implements OnInit {

  inputText: string;
  twilioCredentials: any;
  algoliaContactList: any;
  selectedContacts: any = [];
  selectedLocation: any = [];
  isFirstScreen: boolean = true;
  isSecondScreen: boolean = false;
  isThirdScreen: boolean = false
  loading: boolean = false;
  loadingForReview: boolean = false;
  messageSendStatus: boolean = false;
  sendDisable: boolean = false;
  // <responses@mktg.doctor>
  emailFrom: string = "<themktgdoctorteam@mktg.doctor>";
  smsFrom: string = "";
  emailSubject: string = "Request you to write us a review";
  emailBody: string = "Please click the link below to write us a review.";
  smsBody: string = "Please click the link below to write us a review.";
  gmbAccountsDetails: any = [];
  currentAccountDetails: any = [];
  isSendInviteBthDisabled: boolean = false;
  sendBySmsAndEmail: any = [];
  twilio: boolean;
  displayedColumns: any[] = [
    { header: "Name", field: "name", class: "mid" },
    { header: "Cell", field: "phone", class: "mid" },
    { header: "Email", field: "email", class: "mid" },
    { header: "Send By Email", field: "email", class: "mid" },
    { header: "Send By SMS", field: "email", class: "mid" },
    // { header: "Action", field: "action", class: "mid" },

  ];
  showEmail: boolean = true;
  showSms: boolean = true;
  disabledBtn: boolean;
  saveConfirm: boolean;
  constructor(
    private accountService: AccountService,
    public dialog: MatDialog,
    public messageService: MessageService,
    public reviewService: ReviewsService,
    @Inject(MAT_DIALOG_DATA) public dilogData: any
  ) { }

  async ngOnInit(): Promise<void> {
    this.getGmbInfo();
    await this.getCurrentAccountDetails();
    await this.getPatientFormConfiguration();
    if (this.dilogData && this.dilogData['fromScreen'] == 'appointment-detail') {
      this.isFirstScreen = false;
      this.isSecondScreen = true;
      this.getSelectedContacts(this.dilogData)
    }
  }

  async getPatientFormConfiguration() {
    this.reviewService.getReviewConfiguration().then(async (data) => {
      //console.log(data);
      if (!data.empty) {
        let configuration = data.docs[0].data();
        if (configuration) {
          if ("smsBody" in configuration)
            this.smsBody = configuration['smsBody'];
          if ("emailBody" in configuration)
            this.emailBody = configuration['emailBody'];
          if ("emailSubject" in configuration)
            this.emailSubject = configuration['emailSubject'];
        }
      }
    })
  }

  async getCurrentAccountDetails() {
    this.currentAccountDetails = (await this.reviewService.getCurrentAccountDetails()).data();
    if ("twilio_from" in this.currentAccountDetails) {
      this.smsFrom = this.currentAccountDetails['twilio_from'];
      console.log("twilio account is present. ");
      this.displayedColumns = [
        { header: "Name", field: "name", class: "mid" },
        { header: "Cell", field: "phone", class: "mid" },
        { header: "Email", field: "email", class: "mid" },
        { header: "Send By Email", field: "email", class: "mid" },
        { header: "Send By SMS", field: "email", class: "mid" },
      ];
      this.twilio = true;

    } else {
      console.log("twilio account isnt present ");
      this.displayedColumns = [
        { header: "Name", field: "name", class: "mid" },
        { header: "Cell", field: "phone", class: "mid" },
        { header: "Email", field: "email", class: "mid" },
        { header: "Send By Email", field: "email", class: "mid" }

      ];
      this.twilio = false;
    }
    if ("email" in this.currentAccountDetails) {
      this.emailFrom = this.currentAccountDetails['email'];
    }
    this.emailSubject = this.emailSubject + " for " + this.currentAccountDetails['name'];
    this.emailBody = this.emailBody + " for " + this.currentAccountDetails['name'];
    this.smsBody = this.smsBody + " for " + this.currentAccountDetails['name'];

    //console.log(this.currentAccountDetails);
  }

  async getGmbInfo() {
    this.gmbAccountsDetails = (await this.reviewService.getGmbAccountInfo()).data()['gmb_info'];
    this.gmbAccountsDetails = this.gmbAccountsDetails.map(v => Object.assign(v, { 'isSelected': false }));
    // this.selectedLocation = this.gmbAccountsDetails.filter(ele => ele);
    console.log(this.gmbAccountsDetails)
    this.gmbAccountsDetails[0]['isSelected'] = true;
    this.selectedLocation.push(this.gmbAccountsDetails[0]);
    // if (this.gmbAccountsDetails.length > 0) {
    //   this.selectedLocation.push(this.gmbAccountsDetails[this.gmbAccountsDetails.length - 1]);
    //   console.log(this.selectedLocation);
    // }
    if (this.gmbAccountsDetails.length <= 0)
      this.disabledBtn = true;
    //console.log(this.gmbAccountsDetails)
  }

  getSelectedContacts(data) {
    // Object.assign(this.selectedContacts,data['selectedContacts']);
    if (data.fromScreen) {
      this.selectedContacts = data.selectedContacts
    }
    else {

      this.selectedContacts = JSON.parse(data['selectedContacts']);
    }
    //console.log(this.selectedContacts);
    //console.log(this.sendBySmsAndEmail);

    for (var i = 0; i < this.selectedContacts.length; i++) {
      if (this.selectedContacts[i].providerReference) {
        delete this.selectedContacts[i].providerReference
      }
      this.sendBySmsAndEmail.push({
        "sendBySms": this.twilio ? true : false,
        "sendByEmail": true,
        "selectedContacts": this.selectedContacts[i]
      });
    }
    this.inputText = data['inputText']
  }


  Back(screen) {
    if (screen == 'second') {
      this.isFirstScreen = true;
      this.isSecondScreen = false;
      this.isThirdScreen = false;
    }
    if (screen == 'third') {
      this.isFirstScreen = false;
      this.isSecondScreen = true;
      this.isThirdScreen = false;
    }
  }

  getSendSource(event, type, data) {
    let index = this.selectedContacts.findIndex(ele => (ele === data));
    if (type == "email") {
      if (event.checked) {
        this.sendBySmsAndEmail[index]['sendByEmail'] = true;
      }
      if (!event.checked) {
        this.sendBySmsAndEmail[index]['sendByEmail'] = false;
      }
    }
    else {
      if (event.checked) {
        this.sendBySmsAndEmail[index]['sendBySms'] = true;
      }
      if (!event.checked) {
        this.sendBySmsAndEmail[index]['sendBySms'] = false;
      }
    }
    let indexBy = this.sendBySmsAndEmail.findIndex(element => (!element['sendByEmail'] && !element['sendBySms']));
    if (indexBy >= 0) {
      this.sendDisable = true;
    }
    else {
      this.sendDisable = false;
    }
  }

  locationSelection(event, data) {
    //console.log(event);
    // console.log(data);
    // if (event.checked) {
    //   // this.selectedLocation.push(data)
    //   let location = data.newReviewUrl;
    // }
    // if (!event.checked) {
    //   let index = this.selectedLocation.findIndex(ele => ele === data);
    //   this.selectedLocation.splice(index, 1);
    // }
    // //console.log(this.selectedLocation);
    // let flag = 0;
    // for (var i = 0; i < this.selectedLocation.length; i++) {
    //   if (this.selectedLocation[i]['isSelected'])
    //     flag = 1
    // }
    // if (!flag)
    //   this.isSendInviteBthDisabled = true;
    // else
    //   this.isSendInviteBthDisabled = false;
    // let locationData = data;
    this.selectedLocation = [];
    this.selectedLocation.push(data);
    console.log(this.selectedLocation);
  }

  SendReviewInvite() {
    this.saveConfirm = true;
    let sendEmailInfo = {
      "selectedContacts": this.selectedContacts,
      "acc_id": `${sessionStorage.getItem('current_acc_id')}`
    }
    sendEmailInfo['reviewEmailBody'] = this.emailBody + "<br>";
    sendEmailInfo['reviewSmsBody'] = this.smsBody + ' ';
    // for (var i = 0; i < this.selectedLocation.length; i++) {
    //   if (this.selectedLocation[i]['newReviewUrl']) {
    //     sendEmailInfo['reviewEmailBody'] = sendEmailInfo['reviewEmailBody'] + this.selectedLocation[i]['newReviewUrl'] + "<br>";
    // sendEmailInfo['reviewSmsBody'] = sendEmailInfo['reviewSmsBody'] + "  " + this.selectedLocation[0]['newReviewUrl'] + " ";
    //   }
    // }
    sendEmailInfo['reviewEmailSubject'] = this.emailSubject;
    sendEmailInfo['reviewEmailFrom'] = this.emailFrom;
    sendEmailInfo['smsFrom'] = this.smsFrom;
    let len = this.selectedLocation.length;
    sendEmailInfo['link'] = this.selectedLocation[len - 1].newReviewUrl;
    console.log(sendEmailInfo['link']);
    console.log(this.selectedLocation);
    //console.log(sendEmailInfo);
    this.loadingForReview = true;
    this.reviewService.sendEmailAndSms(sendEmailInfo, this.sendBySmsAndEmail).subscribe(data => {
      this.messageSendStatus = true
    })
  }

  Next(screen) {
    if (screen == 'first') {
      this.isFirstScreen = false;
      this.isSecondScreen = true;
      this.isThirdScreen = false;
    }
    if (screen == 'second') {
      this.isFirstScreen = false;
      this.isSecondScreen = false;
      this.isThirdScreen = true;

      let emailIndex = this.sendBySmsAndEmail.findIndex(ele => (ele['sendByEmail'] == true));
      if (emailIndex >= 0)
        this.showEmail = true;
      else
        this.showEmail = false;

      let smsIndex = this.sendBySmsAndEmail.findIndex(ele => (ele['sendBySms'] == true));
      if (smsIndex >= 0)
        this.showSms = true;
      else
        this.showSms = false;
      //console.log(emailIndex,smsIndex);
    }

  }

  onTextChange(event) {
    // //console.log(event);
    if (event['htmlValue']) {
      this.emailBody = event['htmlValue'];
      this.disabledBtn = false;
    }
    else {
      this.emailBody = "";
      this.disabledBtn = true;
    }
    // this.disabledStatus();
  }


}