import { Injectable } from "@angular/core";
import "firebase/firestore";
import "firebase/storage";
import firebase from "firebase/app";
import { Provider } from "../_models/interfaces";

@Injectable({
  providedIn: "root"
})
export class ProvidersService {
  constructor() {}

  async getProviderNameArray() {
    let providerDocsSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("providersDetails")
      .get();
    return providerDocsSnapshot;
  }

  async getProviders(account_slug: string): Promise<Array<Provider>> {
    /**
     * For the given account, query the providerDetails (providers) collection.
     * Return all documents formatted to array of Provider objects.
     *
     */
    let providers: Array<Provider> = [];
    let providerDocsSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(account_slug)
      .collection("providersDetails")
      .get();

    providerDocsSnapshot.forEach(results => {
      let provider = results.data();
      providers.push(<Provider>{
        fullname: provider.name,
        email: provider.email,
        degree: provider.speciality
      });
    });

    return providers;
  }

  async getProvidersForLocation(
    account_slug: string,
    location_slug: string
  ): Promise<Array<Provider>> {
    /**
     * For the given account, query the Calendars collection.
     * Fetch all documents where the location id matches.
     * Get list of provider references from these calendar documents.
     * From provider references go to providerDEtails collection and get provider documnents.
     * Return provider documents
     */
    let providers: Array<Provider> = [];
    let providerDocsSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(account_slug)
      .collection("providersDetails")
      .get();

    providerDocsSnapshot.forEach(results => {
      let provider = results.data();
      providers.push(<Provider>{
        fullname: provider.name,
        email: provider.email,
        degree: provider.speciality
      });
    });

    return providers;
  }
}
