import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from '../../environments/environment';

@Injectable()
export class EmailReportService {

  constructor(
    private http:HttpClient
  ) { }


  formatDate(timeperiod: any): any {
    let formattedDate = {'start': '', 'end': ''};

    formattedDate.start = (new DatePipe('en-US')).transform(timeperiod.start, 'yyyy-MM-dd hh:mm:ss');
    formattedDate.end = (new DatePipe('en-US')).transform(timeperiod.end, 'yyyy-MM-dd hh:mm:ss');

    return formattedDate;
  }


  getCampaigns(client_id: string, api_key: string, timeperiod: any): Promise<any> {
    let timeperiod_formatted = this.formatDate(timeperiod);
  //  return this.http.post('https://mktgbot-server.herokuapp.com/campaign-monitor-get-campaigns.php', JSON.stringify({'client_id': client_id, 'api_key': api_key, 'timeperiod': timeperiod_formatted}))
  //return this.http.post('http://localhost:8000/campaign-monitor-get-campaigns-copy.php', JSON.stringify({'client_id': client_id, 'api_key': api_key, 'timeperiod': timeperiod_formatted}))
  return this.http.post(`${environment.serverURL}/campaign-monitor-get-campaigns-copy.php`, JSON.stringify({'client_id': client_id, 'api_key': api_key, 'timeperiod': timeperiod_formatted}))
                    .toPromise();
  }

   getCampaignDetails(id: string, acc_name: string) {
     let record = JSON.parse(sessionStorage.getItem('Email-all-' + acc_name));
     let ind = record.email_results.campaigns.findIndex( x => x.id === id );
     return record.email_results.campaigns[ind];
   }

   getSegments(campaign_monitor_client_id:string,campaign_monitor_api_key:string, list_id: string):Promise<any> {
     return this.http.post(`${environment.serverURL}/campaign-monitor-get-segment-details.php`,JSON.stringify({"client_id": campaign_monitor_client_id, "api_key": campaign_monitor_api_key, "list_id":list_id}))
     .toPromise();
   }
  }
