<div>
    <div class="row">
        <div class="col-md-4" style="margin:1px auto 10px;font-size: 10px;font-weight: 500;text-align: left;">
        </div>
        <div class="col-md-4" style="margin:1px auto 10px;font-size: 20px;font-weight: 500;text-align: center;">
            {{accountsInformation['title']}}
        </div>
        <div class="col-md-4" style="margin:1px auto 10px;font-size: 10px;font-weight: 500">
        </div>
    </div>
</div>
<div *ngIf="accountConfigurationDetails['type'] == 'gmb'">
    <div *ngIf="accountDetails else noGmbAccount" class="row">
        <div class="col-4">

        </div>
        <div class="col-4">
            <div *ngIf="accountConfigurationDetails['screen'] == 'operation'">
                <div *ngIf="accountDetails.length>0">
                    Please select your location
                    <hr>
                    <ul *ngFor="let location of gmbAccountsDetails;let i =index">
                        <mat-checkbox (change)="selectAccount($event,location,i)" [checked]="location['checked']">
                            {{location['title']}}
                        </mat-checkbox>
                    </ul>
                    <hr>
                    <div class="row" *ngIf="accountConfigurationDetails['screen'] == 'operation'">
                        <div class="col-md-6" style="text-align: left;"
                            *ngIf="this.accountConfigurationDetails['isFirstTime']=='Yes'">
                            <button mat-raised-button id="button" color="primary" (click)="saveAccount()"
                                [disabled]="gmbAccounts.length<=0">Save</button>
                        </div>
                        <div class="col-md-6" style="text-align: left;"
                            *ngIf="this.accountConfigurationDetails['isFirstTime']=='No'">
                            <button mat-raised-button id="button" color="primary" (click)="editAccountInformation()"
                                [disabled]="gmbAccounts.length<=0">Update</button>
                        </div>
                        <div class="col-md-6" style="text-align: right;">
                            <button mat-raised-button id="button" color="primary" (click)="back()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">

        </div>
    </div>
    <ng-template #noGmbAccount>
        <div style="margin-bottom: 2%;text-align: center;">
            Sorry,we couldn't find an Google My Bussiness Account...
        </div>
        <div style="text-align: center;">
            <button mat-raised-button id="button" color="primary" (click)="back()">Cancel</button>
        </div>
    </ng-template>
</div>


<div *ngIf="accountConfigurationDetails['type'] == 'analytics'" style="width: 100%;height: 100%;padding: 0px">
    <div *ngIf="analyticsAccount else loading">
        <div *ngIf="analyticsAccount.length>0 else loading">
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="container">
                        <mat-select placeholder="Choose account" [(ngModel)]="selectedAnalyticsAcc"
                            (selectionChange)="analyticsAccountConfiguration('propertise')" name="account">
                            <mat-option *ngFor="let account of analyticsAccount" [value]="account">
                                {{account?.name}}
                                {{account?.id}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="container" *ngIf="!waitTillPropertiseFetch else loading">
                        <mat-select placeholder="Choose properties" [(ngModel)]="selectedProperty" name="account"
                            (selectionChange)="analyticsAccountConfiguration('view')">
                            <mat-option *ngFor="let property of analyticsPropertise" [value]="property">
                                {{property?.name}}
                                {{property?.id}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-template #loading>
                        <div id="spinnerDiv" style="text-align: center;">
                            <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                        </div>
                    </ng-template>
                </div>
                <div class="col-md-4" *ngIf="!waitTillViewsFetch else loading">
                    <mat-form-field class="container">
                        <mat-select placeholder="Choose views" [(ngModel)]="selectedView"
                            (selectionChange)="analyticsAccountConfiguration('save')" name="account">
                            <mat-option *ngFor="let views of analyticsViews" [value]="views">
                                {{views?.name}}
                                {{views?.id}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-template #loading>
                        <div id="spinnerDiv" style="text-align: center;">
                            <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="row">
                <div style="margin-left: 20%;margin-top: 5%;width: 50%;">
                    <div class="row">
                        <div class="col-md-6" style="text-align: left;">
                            <button mat-raised-button id="button" color="primary" (click)="saveAccount()"
                                [disabled]="!saveAnalytics">Save</button>
                        </div>
                        <div class="col-md-6" style="text-align: right;">
                            <button mat-raised-button id="button" color="primary" (click)="back()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #loading>
            <div style="text-align: center;margin-bottom: 2%;">
                Sorry,we couldn't find an Analytics account...
            </div>
            <div style="text-align: center;">
                <button mat-raised-button id="button" color="primary" (click)="back()">Cancel</button>
            </div>
        </ng-template>
    </div>
    <ng-template #loading>
        <div style="text-align: center;margin-bottom: 2%;">
            Sorry,we couldn't find an Analytics account...
        </div>
        <div style="text-align: center;">
            <button mat-raised-button id="button" color="primary" (click)="back()">Cancel</button>
        </div>
    </ng-template>
</div>