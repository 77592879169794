import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { AccountService } from '../_services';
import { ChatBotService } from './../_services/chat-bot.service';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})

export class ChatBotComponent implements OnInit {
  @Input() allMessages: any[];
  @Input() name: any;
  @Input() selectedDocId: any;
  @Input() contactDetailsObject: any
  docId: any;
  isReady: boolean = false;
  messages: any;
  formValue: string = "";
  showMsg: boolean = false;
  phone: any;
  email: any;
  userDetails: any;
  welcomeMessage: string;
  welcomeButtons: any;
  contactDetails: any;
  showEndButton: any = true;
  menuMessage: any;
  showMenu: any;
  items: any;
  constructor(
    private chatbotService: ChatBotService,
    private accountService: AccountService,
  ) {
    chatbotService.getWelcomeMessage().then(async snap => {
      let data = snap.docs[0].data();
      let item = await chatbotService.getMenuItems();
      this.items = item.docs[0].data();
      this.showMenu = data.enableMenu;
      this.welcomeMessage = data.welcomeMessage;
      this.welcomeButtons = data.welcomeButtons;
      this.menuMessage = data.menuMessage;
      this.showMsg = true;
    });
  }

  ngOnInit() {
    this.userDetails = this.contactDetailsObject;
    console.log(this.userDetails);
    this.messages = this.userDetails.messages;
    this.docId = this.userDetails.id;
    this.phone = this.userDetails.phone ? this.userDetails.phone : '';
    this.email = this.userDetails.email ? this.userDetails.email : '';
    if (this.userDetails) {
      if (this.userDetails.status == 'ACCEPTED' && this.userDetails.agentRef.isEqual(this.accountService.getUserRef()))
        this.showEndButton = true;
      else
        this.showEndButton = false;
    }
    this.isReady = true;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    console.log(changes);
    if ('contactDetailsObject' in changes) {
      this.userDetails = changes.contactDetailsObject.currentValue;
      console.log(this.userDetails)
      this.messages = this.userDetails.messages;
      this.docId = this.userDetails.id;
      this.phone = this.userDetails.phone ? this.userDetails.phone : '';
      this.email = this.userDetails.email ? this.userDetails.email : '';
      if (this.userDetails) {
        if (this.userDetails.status == 'ACCEPTED' && this.userDetails.agentRef.isEqual(this.accountService.getUserRef()))
          this.showEndButton = true;
        else
          this.showEndButton = false;
      }
    }
    this.isReady = true;
  }

  scrollToBottom(): void {
    let msgBox = document.getElementById('msgBox');
    if (msgBox)
      msgBox.scrollTop = msgBox.scrollHeight;
  }
  sendMessage(data) {
    let botMessage = {
      date: new Date(),
      displayType: 'TEXT',
      sentBy: "BOT",
      type: 'outgoing',
      data: data,
      unread: false
    }
    this.formValue = '';
    this.messages.push(botMessage);
    this.chatbotService.sendMessage(this.docId, botMessage);

  }
  async endLiveChat() {
    let snapshot = await this.chatbotService.getSelectedMessages(this.docId);
    snapshot.ref.update({ liveChat: false, status: 'END' });
    this.showEndButton = false;
  }
  async startLiveChat() {
    let snapshot = await this.chatbotService.getSelectedMessages(this.docId);
    snapshot.ref.update({ liveChat: true, status: 'ACCEPTED' });
    this.showEndButton = true;
  }
}