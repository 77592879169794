import { E } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RewardCampaignService } from '../../_services/reward-campaign.service';

@Component({
  selector: 'app-reward-message-configuration',
  templateUrl: './reward-message-configuration.component.html',
  styleUrls: ['./reward-message-configuration.component.scss']
})
export class RewardMessageConfigurationComponent implements OnInit {
  loadingForConfiguration: boolean = true;
  docId: string;
  emailSubject: string = "Your loyalty points";
  emailBody: string = "You have 10 loyalty points to redeem.";
  smsBody: string = "You have 10 loyalty points to redeem.";
  displayMsg: string = 'Updating patient form configuration is in progress....';
  inValid: boolean = false;
  confirmation: boolean;
  loading: boolean;
  ownerEmail: string;
  enableEmail: boolean = false;
  enableSms: boolean = false;
  configuration: any;
  smsBodyRedeemed: string;
  @ViewChild("emailSection") emailSection: ElementRef;
  @ViewChild("smsSection") smsSection: ElementRef;

  constructor(
    private rewardService: RewardCampaignService
  ) {
    this.getLoyaltyPointsConfiguration();
  }

  ngOnInit(): void {
    this.getLoyaltyPointsConfiguration();
    console.log(this.configuration);
  }

  inputValidator(event: any) {
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!pattern.test(event.target.value))
      this.inValid = true;
    else
      this.inValid = false;

  }

  async getLoyaltyPointsConfiguration() {
    this.loadingForConfiguration = true;
    this.rewardService.getLoyaltyMessageConfigurations().then(async (data) => {
      if (!data.empty) {
        this.docId = data.docs[0].id;
        this.configuration = data.docs[0].data();
        console.log(this.configuration);
        this.enableSms = this.configuration.enableSms;
        this.enableEmail = this.configuration.enableEmail;
        this.loadingForConfiguration = false;
        if (this.configuration) {
          if ("smsBody" in this.configuration)
            this.smsBody = this.configuration['smsBody'];
          this.smsBodyRedeemed = this.configuration['smsBodyRedeemed'];
          if ("emailBody" in this.configuration) {
            this.emailBody = this.configuration['emailBody'];
            // ////console.log(this.emailBody);
            if (this.emailBody == "") {
              this.inValid = true;
            }
          }
          if ("emailSubject" in this.configuration) {
            this.emailSubject = this.configuration['emailSubject'];
          }
          if ('ownerEmail' in this.configuration) {
            this.ownerEmail = this.configuration['ownerEmail'];
          }
        }
      }
      else {
        this.loadingForConfiguration = false;
      }
    })
  }

  Save() {
    this.confirmation = true;
    this.loading = true;
    let LoyaltyConfigurationData = {
      smsBody: this.smsBody,
      smsBodyRedeemed: this.smsBodyRedeemed,
      emailBody: this.emailBody.replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
      emailSubject: this.emailSubject,
      enableEmail: this.enableEmail,
      enableSms: this.enableSms,
      type: 'loyalty-message-configuration'
    };
    if (this.docId) {
      this.displayMsg = 'Updating Loyalty message configuration is in progress....';
      this.rewardService.updateLoyaltyMessageConfiguration(LoyaltyConfigurationData, this.docId).then(resp => {
        this.loading = false;
        this.displayMsg = `Loyalty message settings configuration updated successfully`;
        setTimeout(() => {
          this.confirmation = false;
        }, 3000);
      });
    }
    else {
      this.displayMsg = 'Updating Loyalty message configuration is in progress....';
      this.rewardService.addLoyaltyMessageConfiguration(LoyaltyConfigurationData).then(async (resp) => {
        if (resp.id) {
          this.loading = false;
          this.docId = resp.id
          this.displayMsg = `Loyalty messaging configuration added successfully`;
          setTimeout(() => {
            this.confirmation = false;
          }, 3000);
        }
      });
    }

  }

  onTextChange(event) {
    if (event['htmlValue']) {
      this.emailBody = event['htmlValue'];
      this.inValid = false;
    }
    else {
      this.emailBody = "";
      this.inValid = true;
    }
    // ////console.log(this.emailBody);
  }

  enableEmailSend(event) {
    console.log(event, this.enableEmail);
    console.log(event.target.checked);
    this.enableEmail = event.target.checked;
  }

  enableSmsSend(event) {
    console.log(event, this.enableSms);
    console.log(event.target.checked);
    this.enableSms = event.target.checked;
  }

}
