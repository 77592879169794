<div>
    <section pDroppable pDraggable *ngFor="let section of sections;let i=index"
        style="color:rgb(0, 255, 13);border: darkmagenta;border-radius: 15px;"
        (onDragStart)="dragSectionStart(section,i)" (onDrop)="dropSection(i)" (onDragEnd)="dragSectionEnd()">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="py-3"
            style="background-color:cyan;color:dimgray;">
            <h1>{{section.name}}</h1>
            <h2>Section{{i+1}}</h2>
        </div>
        <div pDroppable pDraggable style="background-color:rgb(17, 0, 255);"
            *ngFor="let question of section.questions;let j=index;">
            <mat-card pDroppable pDraggable style="color:rgb(0, 255, 13);" *ngIf="j<3"
                (onDragStart)="dragQuestionStart(question,i,j)" (onDrop)="dropQuestion(i,j)"
                (onDragEnd)="dragQuestionEnd()">
                <h1>{{question.question}}</h1>
            </mat-card>
        </div>
    </section>
</div>