import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

@Injectable({
  providedIn: 'root'
})
export class ImportHistoryService {
  importlog_acc_response:any;

  constructor() { }

  get_response_from_importlogs(current_acc_id){

    this.importlog_acc_response=firebase.firestore().collection('import_logs')
      .where('accountId', '==', current_acc_id)
      .orderBy('createdOn', "desc")
      .get();
      return this.importlog_acc_response;
  }
}
