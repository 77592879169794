import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute,Params } from '@angular/router';

import { User } from '../../_models/index';
import {Location} from '@angular/common';

import { AlertService, UserService, AuthenticationService } from '../../_services/index';

@Component({
    moduleId: module.id.toString(),
    selector:'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
    model: any = {};
    loading = false;
  currentUser: User;
    returnUrl: string;
    first_name: string;
    last_name: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private alertService: AlertService,
      private authenticationService: AuthenticationService,
      private location : Location) { }


    register() {
        this.loading = true;

        this.userService.create(this.model)
            .subscribe( data => {
                    this.alertService.success('Registration successful & mail verification link sent to ur Email id', true);
                  //  alert("Registration successful & mail verification link sent to ur email id");
                    this.authenticationService.login(this.model)
                        .subscribe( data => {
                              console.log(data);
                              localStorage.setItem('user', data.text());
                              //localStorage.removeItem('anonymousUser');
                              this.userService.toggleLogin(true);
                              this.router.navigate(['/all-plans']);
                            },
                            error => {
                                this.alertService.error("Failed to login automatically");
                                this.loading = false;
                            });
                },

                error => {
                    if(error.status===403){
                      this.alertService.error("Login id already taken");
                      //alert("Login Id already taken");
                    }
                    else if (error.status===404){
                      this.alertService.error("Email already taken");
                      //alert("Email already taken");
                      }
                    else if (error.status===500){
                      this.alertService.error("Cell no already taken");
                      //alert("cell no already taken");
                    }

                    else{
                      this.alertService.error("Mail fail to send");
                    }
                     this.loading = false;

                });
            }
/*
     onCancel(){
          this.location.back();
      }
*/
    ngOnInit() {


       this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

      let uuid: string;

    let user = this.userService.getUser();

      if ( user == null ) {
      this.userService.createUser()
          .then( data => {
              console.log(data);
              localStorage.setItem('user', data.text());
              },
              error => {
                  this.alertService.error("Failed to create user ");
              });


      }

    }
}
