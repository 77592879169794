import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { DialogData } from '../signup/signup.component';
import { AccountService } from '../_services';

@Component({
  selector: 'app-book-reccuring-appointment',
  templateUrl: './book-reccuring-appointment.component.html',
  styleUrls: ['./book-reccuring-appointment.component.scss']
})
export class BookReccuringAppointmentComponent implements OnInit {
  public link: any
  public data: any
  accDetails: any

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DialogData, public dialogRef: MatDialogRef<BookReccuringAppointmentComponent>,
    private sanitizer: DomSanitizer, private accountService: AccountService) { }

  ngOnInit(): void {
    this.accDetails = this.accountService.getCurrentAccDetails();
    this.data = this.dialogData

    let rcurring_link = `${environment.apptURL}/recurring/${this.accDetails.account_slug}/${this.data.contactId}`
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(rcurring_link);
  }

  close() {
    this.dialogRef.close()
  }
}
