import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Inject,
  SimpleChange,
  OnChanges,
  Output,
} from "@angular/core";
import { ContactService, AlertService } from "../_services/index";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

export interface DialogData {
  contact: any;
}

@Component({
  selector: "app-contact-notes",
  templateUrl: "./contact-notes.component.html",
  styleUrls: ["./contact-notes.component.scss"],
})
export class ContactNotesComponent implements OnInit, OnChanges {
  @Output() onNoteUpdate = new EventEmitter<any>();
  @Input() notes: any;

  contact: any;
  //note : boolean =false;
  add_note: boolean = false;
  //  createdNote : string;
  notesRef: any;
  fetchedNote: any;
  newNote: any;
  //  NoteDate : any;
  notes_array: any = [];
  show_notes: boolean = false;
  //noNotes : boolean = false;
  constructor(
    private contactService: ContactService,
    private alertService: AlertService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    // this.fetchNotes();
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ("notes" in changes) {
      this.notes_array = changes.notes.currentValue;
    }
  }
  /*addTextArea(){
    this.add_note = true;
  }

  addNote(){
    let note = {
      'note'  : this.createdNote,
      'created_on' : new Date()
      }
    this.notes_array.push(note);
    this.contactService.addNoteForContact(this.contact, this.createdNote)
      .then(notesRef => {
        console.log(notesRef.id);
      });
      this.add_note = false;
      this.alertService.success("Note added successfully.");
      this.createdNote = "";

  }*/

  fetchNotes() {
    this.show_notes = false;
    console.log("fetching notes.");
    this.contactService
      .fetchNotesForContact(this.contact)
      .then((notesSnapshot) => {
        notesSnapshot.forEach((note) => {
          this.newNote = note;
          this.fetchedNote = this.newNote.data();
          this.fetchedNote.created_on = this.fetchedNote.created_on.toDate();
          this.notes_array.push(this.fetchedNote);
        });
        console.log(this.notes_array);
        this.show_notes = true;
      });
  }

  updateNote(changedNote, note) {
    this.onNoteUpdate.emit({ updatedNote: changedNote, note: note });
  }
}

// openDialogForNote(){
//   const noteDialogRef = this.dialog.open(NotesDialog, {
//     height: '300px',
//     width: '600px',
//    data: { contact : this.contact}
//   });
// }

@Component({
  selector: "create-note-dialog",
  templateUrl: "./create-note-dialog.html",
  styleUrls: ["./contact-notes.component.scss"],
})
export class NotesDialog {
  contact: any;
  createdNote: string;
  //notes_array : any;
  constructor(
    private contactService: ContactService,
    private alertService: AlertService,
    public noteDialogRef: MatDialogRef<NotesDialog>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public contactData: DialogData
  ) {
    this.contact = contactData;
    console.log(this.contact);
  }

  addNote() {
    console.log(this.contact, this.createdNote);
    this.contactService.addNoteForContact(this.contact, this.createdNote);
    this.createdNote = "";
    let snackBarRef = this.snackBar.open("Note added successfully!", "", {
      duration: 3000,
    });
    this.noteDialogRef.close();
  }

  close() {
    this.noteDialogRef.close();
  }
}
