<div class="row">
    <input type="text" pInputText size="50" placeholder="Global Filter"
    (input)="filterGlobal($event.target.value, 'contains')" style="width:auto">
</div>

<div class="table" id="results">
    <div class='theader' >
        <div class='table_header' *ngFor="let col of header">{{col.header}}</div>
    </div>


    <div class='table_row' *ngFor="let content of data;let ind = index;">
        <div class='table_small'>
            <div class='table_cell'><b>{{header[0].header}}</b></div>
            <div class='table_cell'>{{content.first_name}}</div>
        </div>
        <div class='table_small'>
            <div class='table_cell'><b>{{header[1].header}}</b></div>
            <div class='table_cell'>{{content.last_name}}</div>
        </div>
        <div class='table_small'>
            <div class='table_cell'><b>{{header[2].header}}</b></div>
            <div class='table_cell other' [matTooltip]="content.email">{{content.email}}</div>
        </div>
        <div class='table_small'>
            <div class='table_cell'><b>{{header[3].header}}</b></div>
            <div class='table_cell'>{{content.phone}}</div>
        </div>
        <div class='table_small'>
            <div class='table_cell'><b>{{header[4].header}}</b></div>
            <div class='table_cell'>{{content.birthday}}</div>
        </div>
        <div class='table_small'>
            <div class='table_cell'><b>{{header[5].header}}</b></div>
            <div class='table_cell other'>{{lastVisited[ind]}}</div>
        </div>
        <div class='table_small'>
            <div class='table_cell'><b>{{header[6].header}}</b></div>
            <div class='table_cell other'>{{createdOn[ind]}}</div>
        </div>
    </div>

</div>

