import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import  firebase from "firebase/app";
// import "firebase/*";
// import "firebase/functions";

@Injectable({
  providedIn: 'root'
})
export class SearchConsoleService {

  constructor(private http: HttpClient) { }

  // getJson() {
  //   return this.http.get(`../../assets/json/search-console.json`);
  // }

  async getImpressions() {
    let accountSnapshot = await firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem("current_acc_id")}`).get();
    let currentAccount = accountSnapshot.data();
    let scarchConsoleSnapshot = await firebase.firestore().collection(`search_console_data`)
      .where("account_slug", "==", currentAccount.account_slug).get();
    if (scarchConsoleSnapshot.docs.length > 0) {
      let currentSearchConsoleId = scarchConsoleSnapshot.docs[0].id;
      let snapshot = await firebase.firestore().collection(`search_console_data/${currentSearchConsoleId}/data`).orderBy("createdOn", "desc").get();
      return snapshot;
    } else {
      return null;
    }
    // let currentSearchConsoleId = scarchConsoleSnapshot.docs[0].id;
    // let snapshot = await firebase.firestore().collection(`search_console_data/${currentSearchConsoleId}/data`).orderBy("createdOn", "desc").get();
    // return snapshot;

    //let snapshot = await firebase.firestore().collection('search_console_test').orderBy("createdOn", "desc").limit(10).get();
    // return snapshot.docs[0].data();
    //return snapshot;


  }

  fetchLatestSearchConsoleData(accObj: any) {
    console.log(accObj);
    return (firebase.functions().httpsCallable('fetchLatestSearchConsoleData'))(accObj).then(
      result => {
        console.log(result.data);
        return result.data;
      })

  }
}
