<!-- <div class="row" style="text-align: center;margin: 0px auto 10px;font-weight: 2;font-size: 25px;">
    <p style="margin: auto;padding: 0 0 10px;">Preview</p>
</div> -->
<div id="popup">
    <div id="headMktgBot">
        {{header}}
    </div>
    <div [ngClass]="{'noChatShowMsg': mode == 'NO_CHAT','showMsg':mode!='NO_CHAT'}">
        <div [ngClass]="{'noChatMsgBox': mode == 'NO_CHAT','msgBox':mode!='NO_CHAT'}" #msgBox class="scrolling-flexbox"
            [scrollTop]="msgBox.scrollHeight">
            <div id="msgs">
                <div [ngClass]="{'from': true }">
                    <div class="welcomeMessage">{{welcomeMessage}}</div>
                    <div *ngIf="showWelcomeButtons ==  'OUTER' || showWelcomeButtons ==  'BOTH'" style="padding: 2%;">
                        <div *ngFor="let button of welcomeButtons" style="display: inline-block">
                            <div *ngIf="button.showType == 'OUTER' || button.showType == 'BOTH' " class="tab"
                                [class.clicked]="button.name == buttonName"
                                (click)='sendMessage(button.name,button.type);buttonName = button.name'>
                                {{button.name}}
                            </div>
                        </div>
                    </div>
                    <div class="welcomeMessage" *ngIf="showMenu == 'OUTER' || showMenu == 'BOTH' "
                        [innerHTML]="menuMessage"></div>
                    <div *ngIf="showMenu == 'OUTER' || showMenu == 'BOTH' ">
                        <div class="d-flex justify-content-start" style="padding: 1%;" [ngClass]="{'from': true}">
                            <!-- <app-menu [payload]="menu" (scrollEmit)="scrollFunction()"></app-menu> -->
                            <div style="margin-top: 5px;">
                                <div *ngFor="let item of menu.data ;let i=index" class="row menu" id="menuEvent">
                                    <div style="display: table;width: 100%;" (click)="nextMenu(item)">
                                        <div style="display: table-cell;width: 15%;">
                                        </div>
                                        <div [innerHTML]="item.name"
                                            style="display:table-cell;padding-left: 10px;width: 85%;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div #msg *ngFor="let message of messages;let last = last;let i = index">
                    <div *ngIf="message.displayType =='MENU' ">
                        <div class="d-flex justify-content-start" style="padding: 1%;"
                            [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')}">
                            <!-- <app-menu [payload]="message" (scrollEmit)="scrollFunction()"></app-menu> -->
                            <div style="margin-top: 5px;">
                                <div *ngFor="let item of message.data;let i=index" class="row menu" id="menuEvent">
                                    <div style="display: table;width: 100%;" (click)="nextMenu(item)">
                                        <div *ngIf="item.icon" style="display: table-cell;width: 15%;">
                                            <img [src]="item.icon" width="40px">
                                        </div>
                                        <div [innerHTML]="item.name"
                                            style="display:table-cell;padding-left: 10px;width: 85%;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="message.displayType =='CUSTOM'">
                        <app-message-presenter [payload]="message"></app-message-presenter>
                    </div>
                    <div *ngIf="message.sentBy === 'AGENT'"
                        style="margin: 0;padding: 2px;position: relative;text-align: center;">
                        <div class="" style="display: flex;justify-content: center;color: rgb(146, 143, 143);">
                            <hr style="width:100%">
                            <p style="font-weight: bold;white-space: nowrap; margin:auto 10px;font-size: 11px;">
                                {{message.data}}</p>
                            <hr style="width: 100%">
                        </div>
                    </div>
                    <div *ngIf="message.sentBy === 'USER'">
                        <div class="message d-flex justify-content-end" [ngClass]="{'to': message.sentBy === 'USER' }"
                            [innerHTML]=" message.data">
                        </div>
                    </div>
                    <div *ngIf="message.displayType =='TYPING' && last">
                        <div class="message d-flex justify-content-start"
                            [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR') }">
                            {{ message.data }}
                        </div>
                    </div>
                    <div
                        *ngIf="message.displayType=='BUTTON' && (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')">
                        <div class="d-flex justify-content-start" style="padding: 1%;"
                            [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')}">
                            <app-button [payload]="message"></app-button>
                        </div>
                    </div>
                    <div *ngIf="message.displayType=='CARD' && (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')">
                        <app-card-carousel [payload]="message">
                        </app-card-carousel>
                    </div>
                    <div *ngIf="message.displayType=='TEXT' && (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')">
                        <div class="message d-flex justify-content-start"
                            [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR') }"
                            [innerHTML]=" message.data">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showMenuBox" (click)="showMenuBox = false" class="shadowBox"
            [ngClass]="{'noChatShadowBox': mode == 'NO_CHAT'}">
        </div>
        <div id="sendMessage" *ngIf="mode != 'NO_CHAT'">
            <div *ngIf="showMenuBox" class="menuBox">
                <div *ngIf="showWelcomeButtons == 'INNER' || showWelcomeButtons == 'BOTH' ">
                    <div *ngFor="let button of welcomeButtons" style="display: inline-block">
                        <div *ngIf="button.showType == 'INNER' || button.showType == 'BOTH' " class="tab"
                            (click)='showMenuBox = !showMenuBox;sendMessage(button.name,button.type)'>
                            {{button.name}}
                        </div>
                    </div>
                </div>
                <div *ngIf="showMenu == 'INNER' || showMenu == 'BOTH' " class="d-flex justify-content-start"
                    style="padding: 1%;" [ngClass]="{'from': true}">
                    <!-- <app-menu [payload]="items" (scrollEmit)="scrollFunction()" (click)='showMenuBox = false'>
                    </app-menu> -->
                    <div style="margin-top: 5px;">
                        <div *ngFor="let item of menu.data;let i=index" class="row menu" id="menuEvent">
                            <div style="display: table;width: 100%;" (click)="nextMenu(item)">
                                <div style="display: table-cell;width: 15%;">
                                </div>
                                <div [innerHTML]="item.name" style="display:table-cell;padding-left: 10px;width: 85%;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <i class="fas fa-chevron-circle-up" id="menuBtn" [class.menuBtnDown]="showMenuBox"
                [class.menuBtnUp]="!showMenuBox" (click)="showMenuBox =!showMenuBox"></i>
            <input [(ngModel)]="formValue" (keyup.enter)="sendMessage(formValue,'Dialogflow')"
                (keyup)='showMenuBox = false' type="text" id="input" placeholder="Type a message..." autofocus>
            <span class="material-icons" id="sendBtn" (click)="sendMessage(formValue,'Dialogflow')">send</span>
        </div>
        <div *ngIf="mode == 'NO_CHAT' " id="hideMessage">
            <div *ngIf="showMenuBox" class="menuBox">
                <div *ngIf="showWelcomeButtons == 'INNER' || showWelcomeButtons == 'BOTH' ">
                    <div *ngFor="let button of welcomeButtons" style="display: inline-block">
                        <div *ngIf="button.showType == 'INNER' || button.showType == 'BOTH' " class="tab"
                            (click)='showMenuBox = !showMenuBox;sendMessage(button.name,button.type)'>
                            {{button.name}}
                        </div>
                    </div>
                </div>
                <div *ngIf="showMenu == 'INNER' || showMenu == 'BOTH' " class="d-flex justify-content-start"
                    style="padding: 1%;" [ngClass]="{'from': true}">
                    <!-- <app-menu [payload]="items" (scrollEmit)="scrollFunction()" (click)='showMenuBox = false'>
                    </app-menu> -->
                    <div *ngFor="let item of menu.data;let i=index" class="row menu" id="menuEvent">
                        <div style="display: table;width: 100%;" (click)="nextMenu(item)">
                            <div style="display: table-cell;width: 15%;">
                            </div>
                            <div [innerHTML]="item.name" style="display:table-cell;padding-left: 10px;width: 85%;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <i class="fas fa-chevron-circle-up" id="menuBtn" [class.menuBtnDown]="showMenuBox"
                [class.menuBtnUp]="!showMenuBox" (click)="showMenuBox =!showMenuBox"></i>
        </div>
    </div>
</div>