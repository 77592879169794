<mat-toolbar class="head">
  <!-- Top header area -->
  <mat-toolbar-row>
    <div class="mobile-view">
      <button mat-icon-button (click)="openMenuForMobile()">
        <i class="material-icons" style="color: white;">menu</i>
      </button>
    </div>
    <!--top-menus-->
    <div class="account-title" *ngIf="user">
      {{ accounts[acc] }}
    </div>

    <div *ngIf="user" class="float-right">
      <i class="fa fa-bell" aria-hidden="true" style="color: white" [matMenuTriggerFor]="notificationMenu"></i>
      <sup>
        <span class="badge red" [hidden]="!notification">{{notification}}</span>
      </sup>
      <mat-menu #notificationMenu="matMenu" yPosition="below" overlapTrigger="false">
        <div *ngIf="notifiedvalue.length > 0">
          <mat-list class="scroll_mat_list">
            <mat-list-item style="width: 100%; height: max-content;"
              *ngFor="let account of notifiedvalue; let i = index">
              <div class="col" style="width: 100%; height: 100%; padding: 10px 18px;" id="something">
                <span class="pointer" *ngIf="account.status === 'unread'" (click)="changeStatus(notifiedvalue, i)">
                  <div class="row-6">
                    <div class="row">
                      <div class="col-6"></div>
                      <div class="col-6">
                        <mat-icon class="col-2" class="clear-bttn"
                          (click)="clearNotification(notifiedvalue, i, $event)">clear</mat-icon>
                      </div>
                    </div>
                    <div class="row">
                      <mat-icon class="col-6" style="font-size: 18px;">message</mat-icon>
                      <span class="col-6 duration_span">{{account.duration}}</span>
                    </div>
                  </div>
                  <div class="row-6">
                    <div matLine style="font-size: 12px; font-weight: 500;">
                      {{ account.name }}
                    </div>
                    <br />
                    <div matLine style="font-size: 12px; font-weight: 500;">
                      {{ account.message }}
                    </div>
                  </div>
                </span>

                <span class="pointer" *ngIf="account.status === 'read'" (click)="changeStatus(notifiedvalue, i)">
                  <div class="row-6">
                    <div class="row">
                      <div class="col-6"></div>
                      <div class="col-6">
                        <mat-icon class="col-2" class="clear-bttn"
                          (click)="clearNotification(notifiedvalue, i, $event)">clear</mat-icon>
                      </div>
                    </div>
                    <div class="row">
                      <mat-icon class="col-6" style="font-size: 18px; color: #6c6c6c;">message</mat-icon>
                      <span class="col-6 duration_span">{{account.duration}}</span>
                    </div>
                  </div>
                  <div class="row-6">
                    <span matLine style="font-size: 12px;">{{account.name}}</span>
                    <br />
                    <span matLine style="font-size: 12px;">{{account.message}}</span>
                  </div>
                </span><br />
                <mat-divider> </mat-divider>
              </div>
            </mat-list-item>
          </mat-list>
          <div style="text-align: center;" class="clear-all-btn">
            <button mat-flat-button color="primary" (click)="clearAllNotification($event)">
              Clear All
            </button>
          </div>
        </div>
        <div *ngIf="notifiedvalue.length === 0">
          <p>No Notifications</p>
        </div>
      </mat-menu>

      <span [matMenuTriggerFor]="usrMenu">
        <img *ngIf="user.photoURL" [src]="user.photoURL" height="30px" width="30px" />
        <i class="fa fa-user-circle fa-2x" style="color: white;" aria-hidden="true" *ngIf="!user.photoURL"></i>
        <span class="mobile-only" id="user-display">{{ user.displayName }}
        </span>
      </span>
      <!--Main Menu-->
      <mat-menu #usrMenu="matMenu" x-position="after" y-position="below" overlapTrigger="false">

        <button mat-menu-item (click)="logout()">
          <span>Logout</span>
        </button>
        <mat-divider></mat-divider>
        <!-- <button [routerLink]="" mat-menu-item disabled>
          <span>User Settings</span>
        </button>
        <mat-divider></mat-divider> -->
        <button *ngIf="user.roles.admin === true" [routerLink]="['/add-account']" mat-menu-item>
          <span>Practice Settings</span>
        </button>
        <mat-divider></mat-divider>
        <button *ngIf="user.roles.admin === true || (user.roles.subscriber && user.accounts && user.accounts.length>1)"
          [routerLink]="['/account']" [matMenuTriggerFor]="accountMenu" mat-menu-item>
          <span>Switch Account</span>
        </button>
      </mat-menu>
      <!---Main Menu -->
      <!--Trial sub Menu-->

      <mat-menu #accountMenu="matMenu">
        <button mat-menu-item *ngFor="let account of accounts; let i = index" [value]="user.accounts[i]"
          (click)="accChange(user.accounts[i])">
          {{ account }}
        </button>
      </mat-menu>
      <!--Trial sub Menu-->
    </div>
  </mat-toolbar-row>

  <!-- Main header area, where dynamic menus are displayed -->
  <!-- <mat-toolbar-row> -->

  <!--  <div class="flex-container">
    <mat-form-field *ngIf="user">
      <mat-select [(value)]="selectedAccount" (change)="accChange(selectedAccount)">
        <mat-option *ngFor="let account of accounts; let i = index" [value]="user.accounts[i]">
          {{ account }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>-->
  <!-- This fills the remaining space of the current row -->
  <!-- <div *ngIf="!user">
      <span class="spacer"></span>
      <button mat-button [routerLink]="['/login']">
        <h3>Login</h3>
      </button>
    </div> -->
  <!-- sub-header component -->


  <!-- ------------------- -->
  <!-- top menus-->
  <!-- <div class="top-menu format-center scrolling-wrapper-flexbox" *ngIf="user">
      <span *ngFor="let item of accountDetails.menu">
        <button
          *ngIf="item.access && routeList.indexOf(item.link) === -1"
          [routerLink]="item.link"
          routerLinkActive="mat-accent"
          mat-flat-button
          style
          mat-button
        >
          {{ item.menuitem }}
        </button>
        <button
          *ngIf="
            routeList.indexOf(item.link) !== -1 && user.roles.admin === true
          "
          [routerLink]="item.link"
          routerLinkActive=""
          mat-flat-button
          style
          mat-button
        >
          {{ item.menuitem }}
        </button>
      </span>
    </div>-->
  <!-- </mat-toolbar-row> -->
</mat-toolbar>