<div mat-dialog-title class="dialog-title">
    <div style="    padding-left: 10vh;">Activity Status </div>
    <button style="    position: fixed;margin-left: 35vh;background: #f4f4f4;" mat-icon-button aria-label="close dialog"
        mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<table style="width:40vh">
    <thead>
        <th>
            Date
        </th>
        <th>
            Status
        </th>
    </thead>
    <tbody>
        <ng-container *ngFor="let event of events">
            <tr>
                <td>{{event.date}}</td>
                <td>{{event.status | titlecase}}</td>
            </tr>
        </ng-container>

    </tbody>
</table>