<div class="row text-center" id="subHeader1">
  <div class="col-md-3" style="margin: auto;padding: 0px;">
    <!-- <div class="searchContainer" *ngIf="showCampaigns && !showCampaignDetails">
      <i class="fa fa-search searchIcon"></i>
      <input class="searchBox" type="text" name="search" placeholder="Search campaign"
        (input)="filterCampaignData($event.target.value)">
    </div>-->
    <div *ngIf="showCampaignDetails" id="pageTitle" style="color: #3f51b5;">
      <a (click)="closeCampaignDetails()"> <i class="pi pi-chevron-left"></i>Campaigns</a>
    </div>
  </div>

  <div class="col-md-6 titleSms">
    <div *ngIf="!titleloading">
      <div id="pageTitle" class="center" [hidden]="!showCampaigns||showCampaignDetails ">SMS Campaigns</div>
      <div id="pageTitle" class="center" [hidden]="showCampaigns||showCampaignDetails">Add New Campaign</div>
      <div id="pageTitle" class="center" [hidden]="!showCampaignDetails">{{selectedCampaign?.name | titlecase}}</div>
    </div>
  </div>

  <div class="col-md-3" style="margin: auto;">
  </div>

</div>

<div *ngIf="!loading" style="padding-bottom: 10px;">
  <div *ngIf="(!showCampaigns || showCampaignDetails) && !showSettings" class="mat-elevation-z8"
    style="overflow-y: hidden;">
    <app-add-new-campaign [action]="action" [selectedCampaignInfo]="selectedCampaign"
      (campaignDetails)="addCampaign($event)" [newCampaignAdded]="addedNewCampaign" [quickList]="quickList">
    </app-add-new-campaign>
  </div>
  <div *ngIf="showSettings" class="mat-elevation-z8">
    <app-sms-campaign-configuration [configExist]="smsCampaignConfigSet" (close)="closeSettings()">
    </app-sms-campaign-configuration>
  </div>
  <div *ngIf="showCampaigns && !showCampaignDetails && !showSettings">
    <app-list-all-campaigns [campaignsList]="campaignsList" (showCampaignDetails)="selectedCampaignDetails($event)"
      [campaignConfigSet]="smsCampaignService.smsCampaignConfigSet"></app-list-all-campaigns>
  </div>
</div>
<div *ngIf="loading" style="text-align: center;width: 100%;height: 100%;margin-top: 15%;">
  <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
</div>