import { Component, OnInit, Input, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-top-impression-gainer',
  templateUrl: './top-impression-gainer.component.html',
  styleUrls: ['./top-impression-gainer.component.scss']
})
export class TopImpressionGainerComponent implements OnInit {
  @Input() data: any;
  @Input() dataSourceGain: any[];
  filterColumns: any = [];
  dataSource: any = [];
  enteredValue: string = "01.00";
  disableSortColumns: string[] = ['page', 'page', 'deltaImpressionDiff', 'deltaImpressionDiffPercentage', 'deltaImpressionSharePercentage',
    'lastTotalImpressionShare', 'prevTotalImpressionShare', 'deltaPositionDiff', 'lastWeekClickCount', 'prevWeekClickCount', 'lastWeekImpressionsCount',
    'prevWeekImpressionsCount', 'lastWeekCtrCount', 'prevWeekCtrCount', 'lastWeekPositionCount', 'prevWeekPositionCount'];

  displayedColumns: any[] = [
    { header: "Page", value: "page", class: "page" },
    { header: "Delta Impression ", value: "deltaImpressionDiff", class: "mid" },
    { header: "Delta Impression %", value: "deltaImpressionDiffPercentage", class: "mid" },
    { header: "Delta Share %", value: "deltaImpressionSharePercentage", class: "mid" },
    { header: "Last Impression Share %", value: "lastTotalImpressionShare", class: "mid" },
    { header: "Previous Impression Share %", value: "prevTotalImpressionShare", class: "mid" },
    { header: "Delta Position Difference", value: "deltaPositionDiff", class: "mid" },
    { header: "Last 7 Days Clicks ", value: "lastWeekClickCount", class: "mid" },
    { header: "Previous 7 Days Clicks ", value: "prevWeekClickCount", class: "mid" },
    { header: "Last 7 Days Impressions ", value: "lastWeekImpressionsCount", class: "mid" },
    { header: "Previous 7 Days Impressions ", value: "prevWeekImpressionsCount", class: "mid" },
    { header: "Last 7 Days CTR ", value: "lastWeekCtrCount", class: "mid" },
    { header: "Previous 7 Days CTR ", value: "prevWeekCtrCount", class: "mid" },
    { header: "Last 7 Days Position ", value: "lastWeekPositionCount", class: "mid" },
    { header: "Previous 7 Days Position ", value: "prevWeekPositionCount", class: "mid" },

  ];

  constructor() { }

  ngOnInit() {
    this.filterColumns = ['page', 'deltaImpressionDiff', 'deltaImpressionDiffpercentage', 'deltaImpressionSharePercentage',
      'lastTotalImpressionShare', 'prevTotalImpressionShare', 'deltaPositionDiff', 'lastWeekClickCount', 'prevWeekClickCount', 'lastWeekImpressionsCount',
      'prevWeekImpressionsCount', 'lastWeekCtrCount', 'prevWeekCtrCount', 'lastWeekPositionCount', 'prevWeekPositionCount'];
    this.dataSource = this.dataSourceGain;
    console.log(this.dataSource);

  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('dataSourceGain' in changes) {
      this.dataSource = changes.dataSourceGain.currentValue;
    }
  }
  updateTable() {
    let gainedImpressionAnalysis = []
    let value = this.enteredValue;
    value = value.replace("%", "");
    this.data.impressionAnalysis.forEach(element => {
      if (element.deltaImpressionDiff > 0 && element.deltaImpressionSharePercentage > value) {
        gainedImpressionAnalysis.push(element)
      }
    });
    gainedImpressionAnalysis.sort((a, b) => {
      return b.deltaImpressionDiff - a.deltaImpressionDiff;
    })
    this.dataSourceGain = gainedImpressionAnalysis;
  }
}
