<div *ngIf="!showPreviewFlag" #offerDiv class="container-fluid" style="background: #f3f3f3ff;">
  <div class="row headerDiv">
    {{!update?'Add New':offerDetails.event=='past'?'View':'Edit'}} Marketing Offer
  </div>
  <div class="contentDiv">
    <div class="formGroup padding-30">
      <label for="offerType"> Offer Type</label>
      <div class="row">
        <div class="col-md-4" style="padding: 7px; " *ngFor="let temp of offerTypes">
          <!-- <p-card matBadgeSize="small" matBadgeOverlap="false" matBadge="check" [matBadgeHidden]="!temp.selected" matBadgePosition="above after"  (click)="enableSelected(temp)">
            {{temp.name}}
          </p-card> -->
          <button class="createButton" matBadgeSize="small" matBadgeOverlap="false" matBadge="check"
            [matBadgeHidden]="!temp.selected" matBadgePosition="above after" [disabled]="temp.disable||update"
            (click)="enableSelected(temp)">
            {{temp.name|uppercase}}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="selectedOffer !=undefined">

      <div *ngIf="loading else gotoStepper" style="padding-top: 0%;">
        <div style="text-align: center;">
          <i class="pi pi-spin pi-spinner" id="spinIcon1"></i>
        </div>
      </div>
      <ng-template #gotoStepper>
        <mat-horizontal-stepper labelPosition='bottom' #stepper linear>
          <ng-template matStepperIcon="edit">
            <mat-icon>done</mat-icon>
          </ng-template>
          <mat-step matStepperIcon="check">
            <ng-template matStepLabel>
              <mat-label>Enter offer Information</mat-label>
            </ng-template>
            <form #offerForm="ngForm" (ngSubmit)="onSubmit(offerForm);" *ngIf="selectedOffer?.selected" novalidate>
              <!-- For the Form Name -->
              <div class="form-group">
                <label for="offerName">Offer Name</label>
                <div>
                  <input type="text" id="offerName" class="form-control" [(ngModel)]="offer.offerName" required
                    name="offerName" placeholder="Enter discount Name" #offerName="ngModel">
                  <span class="alert alert-danger" *ngIf="!offerName.valid && (offerName.touched ||offerName.dirty)">
                    Please Enter Offer Name
                  </span>
                </div>
              </div>

              <!-- For the Offer Amount -->
              <div *ngIf="selectedOffer?.name === 'Flat Discount'">
                <label for="offerAmount">Offer Amount(per procedure)</label>
                <fieldset ngModelGroup="flatDiscount">
                  <div class="row form-group">
                    <div class="col-md-5">
                      <div id="offerDiv" style="width: 100%;" class="percent">
                        <input type="number" id="offerDiscount" class="form-control "
                          [(ngModel)]="offer.flatDiscount.discountPercentage" name="offerDiscount"
                          (keypress)="diselectOfferAmount()" placeholder="Enter discount % " #offerDiscount="ngModel"
                          oninput="this.value = !!this.value && Math.abs(this.value) >0 ? Math.abs(this.value) : null">
                        <!-- <span class="alert alert-danger" ngIf="(offerAmount.touched)|| offerDiscount.touched) && !checkFlatDiscountValid()">Please enter flat discount or percentage discount </span> -->
                      </div>
                    </div>
                    <div class="col-md-2" style="text-align: center;">
                      OR
                    </div>
                    <div class="col-md-5">
                      <div>
                        <input type="number" min="1" id="offerAmount" class="form-control"
                          placeholder="Enter discount amount" [(ngModel)]="offer.flatDiscount.discountAmount"
                          (keypress)="diselectOfferDiscount()" name="offerAmount" #offerAmount="ngModel"
                          oninput="this.value = !!this.value && Math.abs(this.value) >0? Math.abs(this.value) : null">
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>


              <!-- For BUY X GET Y FREE -->
              <div *ngIf="selectedOffer?.name === 'Buy X  & GET Y free'">
                <fieldset ngModelGroup="buyXgetY">
                  <div class="row form-group">
                    <div class="col-md-6">
                      <label for="offerAmount">Buy Quantity</label>
                      <!-- <div class="row">
                  <div class="col-md-6"> -->
                      <input type="number" min="1" id="buyQuantity" class="form-control"
                        [(ngModel)]="offer.buyXgetY.buyQuantity" required name="buyQuantity"
                        placeholder="Enter Buy Quantity" #buyQuantity="ngModel"
                        oninput="this.value = !!this.value && Math.abs(this.value) >0 ? Math.abs(this.value) : null">
                      <span class="alert alert-danger" *ngIf="!buyQuantity.valid && buyQuantity.touched">Please Enter
                        Buy
                        Quantity</span>
                      <!-- </div>
                </div> -->
                    </div>
                    <div class="col-md-6">
                      <label for="freeQuantity">Get Free Quantity</label>
                      <!-- <div class="row">
                  <div class="col-md-6"> -->
                      <input type="number" min="1" id="freeQuantity" class="form-control"
                        [(ngModel)]="offer.buyXgetY.freeQuantity" required name="freeQuantity"
                        placeholder="Enter Get Free Quantity" #freeQuantity="ngModel"
                        oninput="this.value = !!this.value && Math.abs(this.value) > 0 ? Math.abs(this.value) : null">
                      <span class="alert alert-danger" *ngIf="!freeQuantity.valid && freeQuantity.touched ">Please Enter
                        Free
                        Quantity</span>
                      <!-- </div>
                </div> -->
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Minimum Quantity for PAckage Discoutn -->
              <div *ngIf="selectedOffer?.name === 'Package Discount'">
                <fieldset ngModelGroup="packageDiscount">
                  <div class="row form-group">
                    <div class="col-md-6">
                      <label for="minQuantity">Minimum Quantity</label>
                      <input type="number" min="1" id="minQuantity" placeholder="Quantity" class="form-control"
                        [(ngModel)]="offer.packageDiscount.minimumQuantity" required placeholder="Enter Minimum Amount"
                        name="minQuantity" #minQuantity="ngModel"
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null">
                      <span class="alert alert-danger"
                        *ngIf="!minQuantity?.valid && (minQuantity?.touched|| minQuantity.dirty)">Please Enter Minimum
                        Offer
                        Amount</span>
                    </div>
                    <div class="col-md-6">
                      <label for="discount">Discount</label>
                      <input type="number" min="1" id="discount" placeholder="Discount" class="form-control"
                        [(ngModel)]="offer.packageDiscount.discount" required placeholder="Enter Minimum Amount"
                        name="discount" #discount="ngModel"
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null">
                      <span class="alert alert-danger"
                        *ngIf="!discount?.valid &&( discount?.touched|| discount.dirty)">Please
                        Enter Discount Details</span>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Offer Description-->
              <div class="form-group">
                <label for="offerDescription">Offer Description</label>
                <div>
                  <textarea type="text" id="offerDescription" class="form-control" [(ngModel)]="offer.offerDescription"
                    required name="offerDescription" placeholder="Enter Offer Description"
                    #offerDescription="ngModel"></textarea>
                  <span class="alert alert-danger"
                    *ngIf="!offerDescription.valid && (offerDescription.touched ||offerDescription.dirty)">
                    Please Enter Offer Description
                  </span>
                </div>
              </div>

              <!-- Terms & Condition -->
              <div class="form-group">
                <label for="termCondition">Terms & Condition</label>
                <div>
                  <textarea type="text" id="termCondition" class="form-control" [(ngModel)]="offer.termCondition"
                    name="termCondition" placeholder="Enter Terms And Conditons" #termCondition="ngModel"
                    required></textarea>
                  <span class="alert alert-danger"
                    *ngIf="!termCondition.valid && (termCondition.touched ||termCondition.dirty)">
                    Please Enter Terms And Condition
                  </span>
                </div>
              </div>
              <!-- Calendar  -->
              <div class="form-group row">
                <div class="col-md-6">
                  <label for="fromDate"> Valid From</label>
                  <div class="row form-group">
                    <div class="col-md-5">
                      <p-calendar icon="fa fa-calendar" [minDate]="minDateStart" [maxDate]="maxDateStart"
                        dateformat="mm/dd/yy" inputId="basic" [(ngModel)]="offer.startDate"
                        [inputStyleClass]="'form-control'" required name="fromDate" #fromDate="ngModel"
                        (onSelect)="changedFromDate($event)">
                      </p-calendar>
                      <span class="alert" *ngIf="!fromDate.valid && (fromDate.touched ||fromDate.dirty)">
                      </span>
                    </div>
                    <div class="col-md-2" style="text-align: center;">AT</div>
                    <div class="col-md-5">
                      <input class="col-xs-2 textStyle" name="fromTime" style="width: 100%;text-transform: uppercase;"
                        class="form-control" [(ngModel)]="offer.startTime" [ngxTimepicker]="picker1" required
                        #fromTime="ngModel">
                      <ngx-material-timepicker #picker1 [minutesGap]="5" required></ngx-material-timepicker>
                      <span class="alert" *ngIf="!fromTime.valid && (fromTime.touched ||fromTime.dirty)">
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <label for="toDate"> Valid To</label>
                  <div class="row form-group">
                    <div class="col-md-5">
                      <p-calendar icon="fa fa-calendar" [minDate]="minDateEnd" dateformat="mm/dd/yy" inputId="basic2"
                        [(ngModel)]="offer.endDate" [inputStyleClass]="'form-control'" required name="toDate"
                        (onSelect)="changedToDate($event)" #toDate="ngModel">
                      </p-calendar>
                      <span class="alert" *ngIf="!toDate.valid && (toDate.touched ||toDate.dirty)">
                      </span>
                    </div>
                    <div class="col-md-2" style="text-align: center;">AT</div>
                    <div class="col-md-5">
                      <input class="col-xs-2 textStyle" style="width: 100%;text-transform: uppercase;"
                        class="form-control" name="toTime" [(ngModel)]="offer.endTime" [ngxTimepicker]="picker2"
                        required #toTime="ngModel">
                      <ngx-material-timepicker #picker2 [minutesGap]="5" required></ngx-material-timepicker>
                      <span class="alert" *ngIf="!toTime.valid && (toTime.touched ||toTime.dirty)">
                      </span>
                    </div>
                  </div>
                </div>
              </div>




              <div class="col-md-12 text-center">
                <button pButton type="button" label="Next" matStepperNext class="p-button-help buttonStyle "
                  [disabled]=" !offerForm.valid || !checkFlatDiscountValid()"> </button>
              </div>

            </form>
          </mat-step>
          <mat-step matStepperIcon="done">
            <ng-template matStepLabel>
              <mat-label>Select Offer Template </mat-label>
            </ng-template>
            <div>
              <label>Offer Template</label>
              <div *ngIf="filteredOfferTemplates.length>0;else noTemplateFound">
                <div>
                  <p-dropdown [options]="filteredOfferTemplates" [(ngModel)]="offer.template" optionLabel="name"
                    [ngModelOptions]="{standalone: true}" [placeholder]="offer.template.name"></p-dropdown>
                </div>
                <br>
                <div>
                  <img [src]="offer.template.previewTemplateUrl" alt="preview image">
                </div>
                <div class="col-md-12 row text-center">
                  <div class="col-md-6" style="text-align: start;">

                    <button pButton matStepperPrevious type="button" label="Back" class="p-button-help buttonStyle ">

                    </button>
                  </div>
                  <div class="col-md-6" style="text-align: right;">
                    <button pButton type="button" label="Next" matStepperNext class="p-button-help buttonStyle ">
                    </button>

                  </div>
                </div>

              </div>
              <ng-template #noTemplateFound>
                <div class="col-md-12 text-center">
                  <div class="alert-danger" style="padding: 10px;">
                    No template found of type {{selectedOffer?.name}} offer .You can add <a
                      [routerLink]="['/template-parsing']">here</a>
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-step>
          <mat-step matStepperIcon="done">
            <ng-template matStepLabel>
              <mat-label> Offer Setting</mat-label>
            </ng-template>
            <form #triggerForm="ngForm">
              <div style="text-align: center;">
                <div style="text-align: center;">
                  <label style="font-size: larger;">Target</label>
                </div>
                <div *ngFor="let row of offer.target;let i = index;">
                  <div class="row col-md-12">
                    <div class="col-md-3" style="text-align : end;padding-top: 0.5rem;">
                      When Website Url
                    </div>
                    <div class="col-md-3">
                      <p-dropdown [options]="targetAuxilaryVerb" [(ngModel)]="row.condition" name="condition"
                        [placeholder]="row.condition" optionLabel="verb" [style]="{'width':'100%'}"
                        [(value)]="row.condition" required></p-dropdown>
                    </div>
                    <div class="col-md-5">
                      <input type="text" [(ngModel)]="row.rule" name={{i}} pInputText style="width: 100%;" required>
                    </div>
                    <div class="col-md-1" *ngIf="i>0">
                      <i class="material-icons delete" style="cursor: pointer;font-size: xx-large;"
                        (click)="deleteCondition(i)">delete</i>
                    </div>
                  </div>
                  <br>
                </div>
                <div class="row col-md-12">
                  <div class="col-md-3">

                  </div>
                  <div class="col-md-4" style="text-align: start;">
                    <i class="material-icons add_circle" style="cursor: pointer;"
                      (click)="addCondition()">add_circle</i>
                  </div>
                  <div class="col-md-4">

                  </div>
                </div>
                <br>
                <div style="text-align: center;">
                  <label style="font-size: larger;">Triggers</label>
                </div>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="offer.trigger.type" name="triggerType"
                  (change)="radioButtonValueChanged()" required>
                  <div class="row col-md-12">
                    <div class="col-md-3">
                      <mat-radio-button value="onScroll" (change)="radioButtonValueChanged()">On scroll
                      </mat-radio-button>
                    </div>
                    <div class="col-md-3">
                      <mat-radio-button value="inactiveMode">Ideal Mode/inactive Mode</mat-radio-button>
                    </div>
                    <div class="col-md-3">
                      <mat-radio-button value="afterPageLoad">After website page load</mat-radio-button>
                    </div>
                    <div class="col-md-3">
                      <mat-radio-button value="exitIntent">Exit intent.</mat-radio-button>
                    </div>
                  </div>
                </mat-radio-group>
                <br>
                <div class="row col-md-12" style="    text-align: left;
                font-size: 18px;">
                  <div class="col-md-3">

                  </div>
                  <div class="col-md-8">
                    <div *ngIf="offer.trigger.type=='onScroll' ">
                      <span> <b style="    font-weight: 500;"> Enter scroll Percentage</b></span>
                      <div id="triggerValue" style="width: 100%;" class="percent">
                        <input type="number" class="form-control " [(ngModel)]="offer.trigger.value" pInputText
                          name="scrollPercentage" placeholder="Enter  scroll Percentage" required>
                      </div>
                      <!-- <div class="percent">
                        <input type="number" [(ngModel)]="offer.trigger.value" name="onScroll" pInputText
                          placeholder="Enter  scroll Percentage" style="width: 100%;" required>
                      </div> -->
                    </div>
                    <div *ngIf="offer.trigger.type=='inactiveMode' || offer.trigger.type=='afterPageLoad'">
                      <span> <b style="    font-weight: 500;"> Enter time (in seconds)</b></span>

                      <div id="triggerValue" style="width: 100%;" class="seconds">
                        <input type="number" class="form-control " [(ngModel)]="offer.trigger.value" name="inactiveMode"
                          pInputText placeholder="Enter time (in seconds)" style="width: 100%;" required>
                      </div>




                    </div>
                  </div>
                  <div class="col-md-3">

                  </div>
                </div>
                <div class="col-md-12 row text-center">

                  <div class="col-md-6" style="text-align: start;">

                    <button pButton matStepperPrevious type="button" label="Back" class="p-button-help buttonStyle ">

                    </button>
                  </div>
                  <div class="col-md-6" style="text-align: right;">
                    <button pButton type="button" label="Preview" (click)="showPreview()"
                      class="p-button-help buttonStyle " [loading]="isLoading" [disabled]="!triggerForm.valid">
                      <i *ngIf="isLoading" class="pi pi-spin pi-spinner" style="margin-right: 10px;"></i>
                    </button>
                    <button pButton type="button" label="Save" matStepperNext class="p-button-help buttonStyle "
                      (click)="saveOffer()" [disabled]="!triggerForm.valid || submited ">
                    </button>

                  </div>
                </div>
              </div>
            </form>
          </mat-step>
        </mat-horizontal-stepper>
      </ng-template>
    </div>

  </div>
</div>
<!-- <div *ngIf="showPreviewFlag">
  <div [innerHtml]="previewTemplate |sanitizeHtml">

  </div>
</div> -->