import { Component, OnInit } from "@angular/core";
import { NavigationState } from "../_models/index";
import {
  FormService,
  NavigationService,
  AccountService,
} from "../_services/index";
import { ActivatedRoute, Router, UrlSegment } from "@angular/router";
import firebase from "firebase/app";
import "firebase/firestore";
import { ExportDataService } from '../_services/export-data.service';

@Component({
  selector: "app-form-details",
  templateUrl: "./form-details.component.html",
  styleUrls: ["./form-details.component.scss"],
})
export class FormDetailsComponent implements OnInit {
  response: any;
  url: UrlSegment[];
  responses: any = [];
  questions: any = [];
  answers: any = [];
  answer: any = [];
  lastVisible: any;
  firstVisible: any;
  starting_points: any = [];
  pageCount: number = 0;
  last_page_no: number;
  pageLimit: number = 10;

  allResponses: any = [];
  state: any;
  slug: string;
  rows: number = 10;
  first: number = 0;
  column: any = [];
  data: any = [];
  acc: any;
  responseIsReady: boolean = false;
  formName: any;
  // columns = [
  //   { header: "Status", value: "status" },
  //   { header: 'Date', value: "date" }
  // ];

  constructor(
    private formService: FormService,
    private accountService: AccountService,
    private navigationService: NavigationService,
    private router: Router,
    private route: ActivatedRoute,
    private exportData: ExportDataService,
  ) {
    this.accountService.accChange$.subscribe((accChange) => {
      this.router.navigateByUrl("/websites");

      this.acc = this.accountService.getCurrentAccDetails();
      this.state = this.navigationService.getCurrentNavigationState();
      if (this.state.url === "/enquiry-forms") {
        this.getFormResponses('enquiry-forms');
      }
      else if (this.state.url === "/survey-forms") {
        this.getFormResponses('survey-forms');
      }
    });
  }

  ngOnInit() {
    this.acc = this.accountService.getCurrentAccDetails();
    this.state = this.navigationService.getCurrentNavigationState();

    if (this.state?.url === "/enquiry-forms") {
      this.getFormResponses('enquiry-forms');
    }
    else if (this.state?.url === "/survey-forms") {
      this.getFormResponses('survey-forms');
    }


  }

  async getFormResponses(selectedSlug) {
    console.log(selectedSlug);
    this.slug = selectedSlug;
    // this.route.queryParams.subscribe((queryParams) => {
    //   var total_responses = queryParams.count;
    //   if (total_responses % this.pageLimit == 0) {
    //     this.last_page_no = total_responses / this.pageLimit - 1;
    //   } else {
    //     this.last_page_no = Math.floor(total_responses / this.pageLimit);
    //   }
    // });
    if (selectedSlug == 'enquiry-forms') {
      this.route.url.subscribe((url) => {
        this.url = url;
        console.log(this.url[1], this.url[2]);
        this.formService.getFormResponsesByLimit(this.url[1].path).then((querySnapshot) => {
          console.log(querySnapshot);
          this.responses = [];
          this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          this.firstVisible = querySnapshot.docs[0];
          this.starting_points.push(this.firstVisible);
          querySnapshot.forEach((response) => {
            this.response = response.data();
            console.log(this.response);
            this.parseAnswer();
            this.responses.push(this.response);
            this.responseIsReady = true;
          });
          console.log(this.responses);
          this.formName = this.response?.form_name;
          // if (this.responses.length > 0) {
          //   this.parseSectionAnswer(this.responses[0]);
          // }
          this.navigationService.updateState(
            new NavigationState(true, this.formName, "Enquiry Forms", "/enquiry-forms", "/form-details/" + this.url[1].path)
          );
        });
      });
    }
    else if (selectedSlug == 'survey-forms') {
      this.route.url.subscribe(async (url) => {
        this.url = url;
        this.formService.getSatisfactoryFormResponsesByLimit(this.url[1].path).then(async (querySnapshot) => {
          this.responses = [];
          this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          this.firstVisible = querySnapshot.docs[0];
          this.starting_points.push(this.firstVisible);
          await Promise.all(querySnapshot.docs.map(async (response) => {
            let resp = response.data();
            this.formName = resp.form_name;
            console.log(resp);
            if (resp.response) {
              let contactDoc = null;
              if (resp.contactReference) {
                contactDoc = await resp.contactReference.get();
              }
              if (resp.contact_ref) {
                contactDoc = await resp.contact_ref.get();
              }
              if (contactDoc) {
                resp.ref = response.ref
                resp.id = response.id
                let contact = contactDoc.data();
                resp.contactId = contactDoc.id
                resp.first_name = contact.first_name;
                resp.last_name = contact.last_name;
                this.parseSurveyFormAnswer(resp);
                this.responses.push(resp);
              }
            }
          }));
          this.responseIsReady = true;
          console.log(this.responses);
          this.navigationService.updateState(
            new NavigationState(true, this.formName, "Survey Forms", "/survey-forms", "/form-details/" + this.url[1].path)
          );
        });
      });
    }
  }
  parseAnswer() {

    // console.log(this.response.response.length);
    if (this.response && this.response.response)
      for (var i = 0; i < this.response.response.length; i++) {
        // console.log(this.response.response[i].name);
        // if (this.response.response[i].name != undefined) {
        //   console.log(this.response.response[i].questions.length);
        //   console.log(this.response.response[i].questions);
        //   for (var k = 0; k < this.response.response[i].questions.length; k++) {
        //     // this.columns.push(this.response.response[i].questions[k].question);
        //     console.log(this.response.response[i].questions[k].answer);
        //     if (this.response.response[i].questions[k].answer !== undefined) {
        //       if (this.response.response[i].questions[k].answer_type === "checkbox") {
        //         var answer = "";
        //         var total_choices = this.response.response[i].questions[k].choices.length;
        //         for (var j = 0; j < total_choices; j++) {
        //           if (this.response.response[i].questions[k].answer[j] === true) {
        //             answer = answer + this.response.response[i].questions[k].choices[j] + ", ";
        //           }
        //         }
        //         answer = answer !== "" ? answer.slice(0, -1) : "-";
        //       } else if (this.response.response[i].questions[k].answer_type === "declaration") {
        //         answer = "Yes";
        //       }
        //       else {
        //         answer = this.response.response[i].questions[k].answer;
        //       }
        //     }
        //     else if (this.response.response[i].questions[k].answer == undefined) {
        //       if (this.response.response[i].questions[k].answer_type === "name") {
        //         answer = "";
        //       }
        //     } else {
        //       answer = "-";
        //     }
        //     this.response.response[i].questions[k].answer = answer;
        //     console.log(this.response[i].questions[k].question);
        //   }
        // }
        // else {
        if (this.response.response[i].answer !== undefined) {
          if (this.response.response[i].answer_type === "checkbox") {
            var answer = "";
            var total_choices = this.response.response[i].choices.length;
            for (var j = 0; j < total_choices; j++) {
              if (this.response.response[i].answer[j] === true) {
                answer = answer + this.response.response[i].choices[j] + ", ";
              }
            }
            //remove last comma
            answer = answer !== "" ? answer.slice(0, -1) : "-";
          } else if (this.response.response[i].answer_type === "declaration") {
            answer = "Yes";
          }
          else {
            answer = this.response.response[i].answer;
          }
        }
        else {
          answer = "-";
        }
        if (this.response.response[i].answer == undefined) {
          if (this.response.response[i].answer_type === "name") {
            answer = "";
          }
        }
        this.response.response[i].answer = answer;
        // console.log(this.response.response);
        // }
      }

  }

  parseSurveyFormAnswer(resp: any) {

    // console.log(this.response.response.length);
    for (var i = 0; i < resp.response.length; i++) {
      // console.log(this.response.response[i].name);
      // if (this.response.response[i].name != undefined) {
      //   console.log(this.response.response[i].questions.length);
      //   console.log(this.response.response[i].questions);
      //   for (var k = 0; k < this.response.response[i].questions.length; k++) {
      //     // this.columns.push(this.response.response[i].questions[k].question);
      //     console.log(this.response.response[i].questions[k].answer);
      //     if (this.response.response[i].questions[k].answer !== undefined) {
      //       if (this.response.response[i].questions[k].answer_type === "checkbox") {
      //         var answer = "";
      //         var total_choices = this.response.response[i].questions[k].choices.length;
      //         for (var j = 0; j < total_choices; j++) {
      //           if (this.response.response[i].questions[k].answer[j] === true) {
      //             answer = answer + this.response.response[i].questions[k].choices[j] + ", ";
      //           }
      //         }
      //         answer = answer !== "" ? answer.slice(0, -1) : "-";
      //       } else if (this.response.response[i].questions[k].answer_type === "declaration") {
      //         answer = "Yes";
      //       }
      //       else {
      //         answer = this.response.response[i].questions[k].answer;
      //       }
      //     }
      //     else if (this.response.response[i].questions[k].answer == undefined) {
      //       if (this.response.response[i].questions[k].answer_type === "name") {
      //         answer = "";
      //       }
      //     } else {
      //       answer = "-";
      //     }
      //     this.response.response[i].questions[k].answer = answer;
      //     console.log(this.response[i].questions[k].question);
      //   }
      // }
      // else {
      if (resp.response[i].answer !== undefined) {
        if (resp.response[i].answer_type === "checkbox") {
          var answer = "";
          var total_choices = resp.response[i].choices.length;
          for (var j = 0; j < total_choices; j++) {
            if (resp.response[i].answer[j] === true) {
              answer = answer + resp.response[i].choices[j] + ", ";
            }
          }
          //remove last comma
          answer = answer !== "" ? answer.slice(0, -1) : "-";
        } else if (resp.response[i].answer_type === "declaration") {
          answer = "Yes";
        }
        else {
          answer = resp.response[i].answer;
        }
      }
      else {
        answer = "-";
      }
      if (resp.response[i].answer == undefined) {
        if (resp.response[i].answer_type === "name") {
          answer = "";
        }
      }
      resp.response[i].answer = answer;
      // console.log(this.response.response);
      // }
    }

  }


  // parseSectionAnswer(response) {
  //   console.log(response);
  //   let que;
  //   let ansValue;
  //   for (var i = 0; i < this.response.response.length; i++) {

  //     if (response.response.questions != undefined) {
  //       // if (this.response.response.name != undefined && this.response.response.name == 'section') {
  //       for (var i = 0; i < response.response.questions.length; i++) {
  //         console.log(response.response.questions.length)
  //         console.log(response.response.questions[i].answer);
  //         console.log(response.response.questions[i].question);
  //         console.log(`response.response.questions[${i}].answer`);

  //         que = response.response.questions[i].question;
  //         ansValue = `response.response.questions[${i}].answer`;

  //         if (response.response.questions[i].answer !== undefined) {
  //           if (response.response.questions[i].answer_type === "checkbox") {
  //             // var answer = "";
  //             // que = response.response.questions[i].question;
  //             // ansValue = `response.response.questions[${i}].answer`;

  //             // var total_choices = response.response.questions[i].choices.length;
  //             // for (var j = 0; j < total_choices; j++) {
  //             //   if (response.response.questions[i].answer[j] === true) {
  //             //     // answer = answer + response.response.questions[i].choices[j] + ", ";
  //             //   }
  //             // }

  //             // answer = answer !== "" ? answer.slice(0, -1) : "-";
  //           } else if (response.response.questions[i].answer_type === "declaration") {
  //             // answer = "Yes";
  //           }
  //           else {
  //             // answer = this.response.response.questions[i].answer;
  //           }
  //         }
  //         else if (response.response.questions[i].answer == undefined) {
  //           if (response.response.questions[i].answer_type === "name") {
  //             // answer = "";
  //           }
  //         } else {
  //           // answer = "-";
  //         }
  //         // this.response.response.questions[i].answer = answer;
  //         console.log(response.response.questions);
  //       }
  //     }
  //     // }
  //     // else{
  //     //   que = response.response.question;
  //     //   ansValue = `response.response.answer`;
  //     // }
  //     // }
  //     console.log(que, ansValue);
  //     this.columns.push({ header: que, value: ansValue });
  //     console.log(this.columns);
  //   }
  // }


  imageLink(url: string) {
    window.open(url);
  }

  // pageNext() {
  //   this.responses = [];
  //   //firebase.firestore().collection('interactive_forms').doc(`${this.url[1].path}`).collection('form_response')
  //   firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem("current_acc_id")}`)
  //     .collection(this.slug).doc(`${this.url[1].path}`).collection("form_response").startAfter(this.lastVisible)
  //     .limit(this.pageLimit).get().then((querySnapshot) => {

  //       this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  //       this.firstVisible = querySnapshot.docs[0];
  //       if (
  //         this.starting_points.find((x) => x === this.firstVisible) ===
  //         undefined
  //       ) {
  //         this.starting_points.push(this.firstVisible);
  //       }
  //       this.pageCount++;
  //       querySnapshot.forEach((response) => {
  //         this.response = response.data();
  //         this.parseAnswer();
  //         this.responses.push(this.response);
  //       });
  //     });
  // }

  // pagePrevious() {
  //   this.responses = [];
  //   this.pageCount -= 1;
  //   //firebase.firestore().collection('interactive_forms').doc(`${this.url[1].path}`).collection('form_response')
  //   firebase
  //     .firestore()
  //     .collection("accounts")
  //     .doc(`${sessionStorage.getItem("current_acc_id")}`)
  //     .collection(this.slug)
  //     .doc(`${this.url[1].path}`)
  //     .collection("form_response")
  //     .startAt(this.starting_points[this.pageCount])
  //     .limit(this.pageLimit)
  //     .get()
  //     .then((querySnapshot) => {
  //       this.firstVisible = querySnapshot.docs[0];
  //       this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  //       querySnapshot.forEach((response) => {
  //         this.response = response.data();
  //         this.parseAnswer();
  //         this.responses.push(this.response);
  //       });
  //     });
  // }

  download() {
    this.formService
      .getFormResponses(this.url[1].path)
      .subscribe((querySnapshot) => {
        querySnapshot.forEach((response) => {
          this.response = response;
          console.log(this.response);
          this.parseAnswer();
          this.allResponses.push(this.response);
        });

        this.questions.push("Status");
        this.questions.push("Submission Time and Date");
        for (let j = 0; j < this.allResponses[0].response.length; j++) {
          this.questions.push(
            '"' + this.allResponses[0].response[j].question + '"'
          );
        }

        for (let i = 0; i < this.allResponses.length; i++) {
          this.answer = [];
          this.answer.push(this.allResponses[i].status);
          if (this.allResponses[i].submitted == undefined) {
            this.allResponses[i].submitted = "-";
          }
          this.answer.push(this.allResponses[i].submitted);
          for (let j = 0; j < this.allResponses[i].response.length; j++) {
            this.answer.push(this.allResponses[i].response[j].answer);
          }
          this.answers.push(this.answer);
        }

        var options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalseparator: ".",
          showLabels: true,
          showTitle: true,
          headers: this.questions,
        };
        //new Angular5Csv(this.answers, this.allResponses[0].form_name + '_Report', options);
      });
  }


  formatData() {
    console.log(this.responses);
    this.data = [];
    for (let i = 0; i < this.responses.length; i++) {
      let temp = { Status: this.responses[i].status, Date: this.responses[i].submitted.toDate() };
      let response = this.responses[i].response;
      for (let j = 0; j < response.length; j++) {
        if (response[j].question == "First, let's get your name.")
          temp[response[j].question] = response[j].names[0] + ' ' + response[j].names[1];
        else
          temp[response[j].question] = response[j].answer;
      }
      console.log("temp:", temp);
      this.data.push(temp);
    }
  }

  exportPdf() { }

  exportCSV(dt) {
    // dt.value = this.data;
    dt.exportCSV();
  }

  exportExcel(dt) {
    this.formatData();
    let name = 'Enquiry_forms';
    if (this.slug == 'survey-forms')
      name = 'Survey_forms';
    this.exportData.downloadExcel([{ data: this.data, name: name }], name);
  }

  saveAsExcelFile(buffer: any, fileName: string): void { }

  /**Filter for prime ng table */
  reset() {
    this.first = 0;
  }
  next() {
    this.first = this.first + this.rows;
  }
  prev() {
    this.first = this.first - this.rows;
  }
  isLastPage(): boolean {
    return this.first === this.responses.length - this.rows;
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }
  /**Filter for prime ng table end*/

  async getContact(response: any) {
    let name;
    let contactDoc = await response.contactReference.get();
    let contact = contactDoc.data();
    response.first_name = contact.first_name;
    response.last_name = contact.last_name;
    // if (response.contactReference) {
    //   response.contactReference.get().then(async contactInfo => {
    //     contactInfo = await contactInfo.data();
    //     response.name = contactInfo.first_name + " " + contactInfo.last_name;
    //   })
    // }
    return response

  }
}
