import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { link } from "fs";
import { environment } from "../../environments/environment";
import { Subject } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class DataChannelsService {
  private notificationSource = new Subject<any>();
  notification$ = this.notificationSource.asObservable();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
  ) { }

  async getLinkedAccountDetailsByRealTime() {
    firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem("current_acc_id")}`).collection('linked_accounts')
      .where('type', '==', 'gmb').onSnapshot(async (snapshot) => {
        if (!snapshot.empty) {
          this.notificationSource.next(snapshot.docs[0]);
        }
        else {
          this.notificationSource.next(null);
        }
      })
  }

  async getLinkedAccountDetails() {
    return (await firebase.firestore().collection('accounts').doc(sessionStorage.getItem("current_acc_id")).collection('linked_accounts').get());
  }

  linkMyGMBAccount(accountType) {
    console.log(accountType);
    // window.location.href = "https://us-central1-mktgbot-181017.cloudfunctions.net/gmbReviewsConfiguration?root="+window.location.href ;
    let data = `${environment.cloudFunctionServerUrl}/gmbReviewsConfiguration?root=` + window.location.href + "&&accountType=" + accountType;
    console.log(data);
    //  window.location.href = "http://localhost:5001/mktgbot-181017/us-central1/gmbReviewsConfiguration?root="+window.location.href+"&accountType="+accountType;
    window.location.href = data;
  }

  getGmbAccounts(gmb_refresh_token: string) {
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json'
      })
    };
    let data = {
      "refresh_token": gmb_refresh_token
    }
    console.log(data);
    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/getGmbAccounts`, JSON.stringify(data), header);
  }
  async reviewNotification() {
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json',
      })
    };
    let body = {
      'accId': sessionStorage.getItem("current_acc_id")
    }
    // this.http.post<any>(`${environment.cloudFunctionServerUrl}/reviewRealtimeNotification`, body, header).subscribe();
  }
  async saveGmbInformation(gmbData, gmb_info) {
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json',
      })
    };
    let gmbinfo = {
      'gmb_info': gmb_info
    }

    let docRef = firebase.firestore().doc(`accounts/${sessionStorage.getItem("current_acc_id")}/linked_accounts/gmb_accounts`)

    if (!(await docRef.get()).exists) {
      docRef.set(gmbData);
      let accountData = await firebase.firestore().doc(`accounts/${sessionStorage.getItem("current_acc_id")}`).update(gmbinfo);

      let accountdata = (await firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem("current_acc_id")}`).get()).data()
      let body = {
        "account_slug": accountdata['account_slug'],
        "description": accountdata['name'],
        "timezone": accountdata['timezone'] ? accountdata['timezone'] : "America/New_York"
      };
      // let data = await this.http.get<any>(`${environment.cloudFunctionServerUrl}/deleteJobSchedulerOfGmbAccount`,header)
      // console.log(data);
      return this.http.post<any>(`${environment.cloudFunctionServerUrl}/createSchedulerJobForGmbAccount`, body, header);
    }
    else {
      return null;
    }

  }

  async saveAnalyticsData(analyticsdata) {
    return firebase.firestore().doc(`accounts/${sessionStorage.getItem("current_acc_id")}/linked_accounts/analytics_accounts`).set(analyticsdata);
  }


  editGmbInformation(gmbData) {
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'responseType': 'json',
      })
    };
    return firebase.firestore().doc(`accounts/${sessionStorage.getItem("current_acc_id")}/linked_accounts/gmb_accounts`).update(gmbData).then(async success => {
      let body = {
        'accSlug': await (await firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem("current_acc_id")}`).get()).data()['account_slug']
      }
      console.log(body);
      this.http.post<any>(`${environment.cloudFunctionServerUrl}/googleReviewsManagement`, body, header).subscribe(
        (resp) => {
          console.log(resp)
        })
    })
  }

  editAnalyticsInformation(analyticsData) {
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json',
      })
    };
    return firebase.firestore().doc(`accounts/${sessionStorage.getItem("current_acc_id")}/linked_accounts/analytics_accounts`).update(analyticsData);
  }

  updateGmbDetails(name) {
    firebase.firestore().doc(`accounts/${sessionStorage.getItem("current_acc_id")}/linked_accounts/gmb_accounts`).update({ 'schedule_job_name': name });
  }


  async deleteSchedularJob() {
    let linkedAccountRef = firebase.firestore().collection(`accounts/${sessionStorage.getItem("current_acc_id")}/linked_accounts`);
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json',
      })
    };
    let body = {
      job_name: (await linkedAccountRef.doc('gmb_accounts').get()).data()['schedule_job_name']
    }
    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/deleteJobSchedulerOfGmbAccount`, body, header);
  }

  deleteAccount(account: string) {
    let linkedAccountRef = firebase.firestore().collection(`accounts/${sessionStorage.getItem("current_acc_id")}/linked_accounts`);
    if (account == "gmb") {
      linkedAccountRef.doc('gmb_accounts').delete();
      this.deleteReviewsCollection();
      // let accRef = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem("current_acc_id")}`).get();
      // console.log(accRef.docs.length);
      // accRef.docs[0].ref.update({ gmb_info: firebase.firestore.FieldValue.delete() });
    }
    if (account == "analytics") {
      linkedAccountRef.doc('analytics_accounts').delete();
    }
    if (account == "gmail") {
      linkedAccountRef.doc('gmail_account').delete();
      //  docref.docs gmail_account
    }


    // linkedAccountRef.doc('Adwords_accounts').delete();
  }

  async deleteReviewsCollection() {
    var batch = firebase.firestore().batch()
    let reviewsCollectionRef = await firebase.firestore().collection(`accounts/${sessionStorage.getItem("current_acc_id")}/reviews`).limit(500).get();
    let batchCount = 0
    while (!reviewsCollectionRef.empty) {
      console.log("Length", reviewsCollectionRef.size);
      reviewsCollectionRef.forEach(data => {
        batch.delete(data.ref);
        batchCount = batchCount + 1;
        if (batchCount >= 500) {
          batch.commit();
          console.log("batch commited")
          batch = firebase.firestore().batch();
          batchCount = 0;
        }
      });
      reviewsCollectionRef = await firebase.firestore().collection(`accounts/${sessionStorage.getItem("current_acc_id")}/reviews`).startAfter(reviewsCollectionRef.docs[reviewsCollectionRef.docs.length - 1]).limit(500).get();
    }
    if (batchCount >= 1) {
      batch.commit();
      console.log("last batch commited");
      batchCount = 0;
    }
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 4000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  getAnalyticsAccounts(analytics_refresh_token: string, endPoint: string) {
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json',
      })
    };
    let body = {
      "refresh_token": analytics_refresh_token,
      "endPoint": endPoint
    }
    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/getAnalyticsAccounts`, body, header);
  }

  getAdwordsAccounts(adwords_refresh_token: string) {
    localStorage.setItem("adwords_refresh_token", adwords_refresh_token);
    return this.http.post(
      `${environment.serverURL}/adwords-get-accounts.php`,
      adwords_refresh_token
    );
  }
  async saveGmailInfo(data) {
    console.log("save gmail refresh token");
    let docRef = await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem("current_acc_id")}`).collection('linked_accounts')
      .where('type', '==', 'gmail').get();
    if (docRef.docs[0]) {
      await docRef.docs[0].ref.update(data);
    }
    else {
      await firebase.firestore().collection('accounts')
        .doc(`${sessionStorage.getItem("current_acc_id")}`).collection('linked_accounts').doc("gmail_account").set(data);
    }
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json',
      })
    };
    let body = {
      acc_id: `${sessionStorage.getItem("current_acc_id")}`,
      refreshToken: data.gmail_refresh_token
    }
    console.log(body);
    this.http.post<any>(`${environment.cloudFunctionServerUrl}/getEmailInfo`, body, header).subscribe(resp => {
      console.log(resp);
    })

  }
}
