<div *ngIf="loading; else loaded">
    <div style="height: fit-content;width:fit-content;margin: auto;">
        <mat-spinner></mat-spinner>
    </div>
</div>
<ng-template #loaded>
    <div *ngIf="allDocs?.length >0 ; else noResults">
        <p-table [value]="allDocs" dataKey="id" rowExpandMode="single">
            <ng-template pTemplate="header">
                <tr style="text-align: center;">
                    <th style="width: 3rem;"></th>
                    <th colspan="3">Fetched On</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-expanded='expanded'>
                <tr>
                    <td style="text-align: center; align-items: center;padding:0;">
                        <button type="button" pButton pRipple [pRowToggler]="rowData"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" style="margin:auto;">
                        </button>
                    </td>
                    <td colspan="3">{{rowData?.created_on.toDate() | date:'MMM d, y, hh:mm a'}}</td>
                    <td>{{rowData?.status | titlecase}}</td>
                    <td class="text-center">
                        <p-button *ngIf="rowData?.status =='completed'"
                            [ngStyle]="{'margin':'auto','white-space': 'nowrap'}" label="View Report"
                            (click)="showRanking(rowData)"></p-button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-data>
                <tr>
                    <td colspan="6">
                        <div class="p-p-3">
                            <p-table [value]="[data]">
                                <ng-template pTemplate="header">
                <tr>

                    <td colspan="2">Address</td>
                    <td colspan="2">Keywords</td>
                    <td colspan="2">Locations</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row dataKey="info">
                <tr>
                    <td colspan="2">
                        <div style="margin:0 5px;">
                            {{row.address}}
                        </div>
                    </td>
                    <td colspan="2">
                        <div
                            style="margin:0 5px;text-align: start;display:flex;flex-direction: column;white-space: nowrap;">
                            <div *ngFor="let kw of row?.targetKw;let last = last">{{kw}}</div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div
                            style="margin:0 5px;text-align: start;display:flex;flex-direction: column;white-space: nowrap;">
                            <div *ngFor="let location of row?.locations;let last = last">{{location}}
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="6">No records for this result.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    </td>
    </tr>
</ng-template>
</p-table>
</div>
<ng-template #noResults>
    You don't have any history.
</ng-template>
</ng-template>