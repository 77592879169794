import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { DragDropModule } from '@angular/cdk/drag-drop'

@Component({
  selector: 'app-drag-drop-question',
  templateUrl: './drag-drop-question.component.html',
  styleUrls: ['./drag-drop-question.component.scss']
})
export class DragDropQuestionComponent implements OnInit {
  sections: any = [];
  sectionIndex: number;
  questionIndex: number;
  constructor() { }
  draggedSection: any;
  draggedQuestion: any;
  sectionMovable: boolean = false;
  questionMovable: boolean = false;
  ngOnInit(): void {
    this.getForms().then(resp => {
      this.sections = resp.sections;
      console.log(this.sections);
    });
  }
  async getForms() {
    let result = await firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('patient_forms').doc('L8w381j2XRQQ7pH0wtPs').get();
    return result.data();
  }

  dragSectionStart(section, i) {
    this.draggedSection = section;
    this.sectionIndex = i;
    this.sectionMovable = true;
  }

  dropSection(toIndex) {
    if (this.draggedSection && this.sectionMovable && !this.questionMovable) {
      this.sections = this.section_move(this.sections, this.sectionIndex, toIndex);
      this.sectionIndex = -1;
    }
  }

  dragSectionEnd() {
    this.draggedSection = null;
    this.sectionMovable = false;
  }


  dragQuestionStart(question, i, j) {
    this.sectionIndex = i;
    this.questionIndex = j;
    this.draggedQuestion = question;
    this.questionMovable = true;
  }

  dropQuestion(toSectionIndex, toIndex) {
    if (this.draggedQuestion) {
      if (this.sectionIndex === toSectionIndex) {
        this.sections[toSectionIndex].questions = this.section_move(this.sections[toSectionIndex].questions, this.questionIndex, toIndex);
      }
      else {
        this.sections[toSectionIndex].questions.splice(toIndex, 0, this.draggedQuestion);
        this.sections[this.sectionIndex].questions.splice(this.questionIndex, 1);
      }
    }
  }
  dragQuestionEnd() {
    this.draggedQuestion = null;
    this.questionMovable = false;
  }



  moveSection(fromSection, toSection) {
    this.sections = this.section_move(this.sections, fromSection, toSection);
  }

  section_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };


}
