import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AfService } from "../_services/index";
import { AccountService } from "../_services/index";
import { BASIC_MENU, Admin_Menu } from "../_constants/appMenuItems";
import { access } from 'fs';
@Component({
  selector: 'app-material-side-nav',
  templateUrl: './material-side-nav.component.html',
  styleUrls: ['./material-side-nav.component.scss'],
})
export class MaterialSideNavComponent implements OnInit {
  isExpanded = false;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  mini: boolean = false;
  lock: boolean = false;
  user: any;
  disable: boolean = true;
  selectedIndex: number = -1;
  menuList: any = [];// BASIC_MENU;
  adminMenu: { label: string; icon: string; display: true; disabled: boolean; isExpanded: boolean; admin: boolean; routerLink: string[]; submenu: { label: string; icon: string; routerLink: string[]; display: boolean; disabled: boolean; }[]; };

  constructor(
    private afService: AfService,
    private accService: AccountService,
  ) {
    this.accService.menuChange$.subscribe(menu => {
      this.menuList = menu;
      // console.log(this.menuList);
    });
  }

  ngOnInit() {
  }

  mouseenter() {
    this.isExpanded = true;
  }

  mouseleave() {
    this.isExpanded = false;
  }
  selectListItem(i) {
    let k = this.selectedIndex;
    this.menuList[this.selectedIndex].selected = !this.menuList[this.selectedIndex].selected;
    this.menuList[i].selected = !this.menuList[i].selected;
    this.selectedIndex = i;
  }
}
