import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocialMediaService } from '../../_services/social-media.service';

@Component({
  selector: 'app-update-post',
  templateUrl: './update-post.component.html',
  styleUrls: ['./update-post.component.scss']
})
export class UpdatePostComponent implements OnInit {
  popupHeader: any;
  post: any;
  selectedTemplate: string;
  allTemplate: any;
  templates: any;
  ejsTemplateContent: any;
  postEjsContent: any;
  postEjsContentConfigure: any = []
  type: string;
  postDocId: string;
  postData: any;
  updateBtn: boolean = false;
  templatesUrl: string;
  editFlag: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<UpdatePostComponent>,
    private socialMediaService: SocialMediaService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.post = this.data.post;
    console.log(this.post);
    this.getPostData();
    // console.log(this.post);
    this.allTemplate = this.data.templateData;
    this.type = this.post.type.toLowerCase();
    this.ejsTemplateContent = this.data.templateObj[this.type];
    console.log(this.ejsTemplateContent);


    // for (let key in this.ejsTemplateContent) {
    //   if (this.type == key) {
    //     this.postEjsContent = this.ejsTemplateContent[key];
    //   }
    // }
    // console.log(this.postEjsContent);

    // for (let key in this.postEjsContent) {
    //   this.postEjsContent[key].key = key;
    //   this.postEjsContentConfigure.push(this.postEjsContent[key]);
    //   console.log(key);
    // }
    // console.log(this.postEjsContentConfigure);
    let temp = []
    for (let i = 0; i < this.allTemplate.length; i++) {
      if (this.allTemplate[i]['type'] == this.post.type) {
        temp.push(this.allTemplate[i]);
      }
    }

    this.templates = temp;
    if (this.templates.length > 0) {
      this.templatesUrl = this.templates[0].previewTemplateUrl
    }
    console.log(temp);
    this.popupHeader = this.data.popupHeader
  }
  async getPostData() {
    let data = await this.socialMediaService.getPostId(this.post.postUrl);
    this.postDocId = data.docid;
    this.postData = data.data;
    console.log("postData", this.postData);
    console.log("postDocId", this.postDocId);
  }
  async updatePost() {
    this.updateBtn = true;
    await (await this.socialMediaService.postUpdate(this.postDocId, this.postData, this.templates)).subscribe(resp => {
      if (resp['status'] == 'success') {
        this.post.postUrl = resp.postUrl;
        this.editFlag = true;
        console.log("post updated successfuly.")
        this.updateBtn = false;
        // this.dialogRef.close(resp);
      } else {

      }
    })
  }
  imageLoadingStatus(event) {

  }
  getSelectedTemplate(event) {
    console.log(event);
    this.templatesUrl = event.value.previewTemplateUrl;
  }
  editPost() {
    this.editFlag = false;
  }
  closeDialog() {
    let resp = {
      status: 'success'
    }
    this.dialogRef.close(resp);
  }
  openPageUrl(pageUrl) {
    window.open(pageUrl, "_blank");
  }
}
