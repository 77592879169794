<div class="container-fluid">
  <div class="row">
    <div class="col-1">
      <button class="m-3" mat-mini-fab (click)="back()">
        <i class="material-icons">arrow_back</i>
      </button>
    </div>
    <div class="col-sm">
      <mat-form-field class="example-full-width">
        <input matInput (change)="savePlanName()" [(ngModel)]="model.planName" #planName="ngModel"
          placeholder="Plan Name" maxlength="50" />
      </mat-form-field>
    </div>
  </div>
  <mat-tab-group>
    <mat-tab label="PLANNING">
      <div class="row">
        <div class="col-sm-6">
          <app-business-basic-info #basicinfo [competitors]="Competitors" [markets]="Markets"
            (confirmBasicValid)="confirmBasicValid($event)"></app-business-basic-info>
        </div>

        <div class="col-sm-6">
          <app-targeting #Targeting [hidden]="basicinfo.urlForm.invalid || basicinfo.urlForm.dirty"
            (confirmTargetingValid)="confirmTargetingValid($event)"></app-targeting>
        </div>

        <div class="col-sm-6">
          <app-lifecycle #Lifecycle [hidden]="basicinfo.urlForm.invalid || basicinfo.urlForm.dirty"
            (confirmLifecycleValid)="confirmLifecycleValid($event)"></app-lifecycle>
        </div>

        <div class="col-sm-6">
          <app-competitors #Competitors [hidden]="basicinfo.urlForm.invalid || basicinfo.urlForm.dirty"
            (confirmCompetitorsValid)="confirmCompetitorsValid($event)"></app-competitors>
        </div>

        <div class="col-sm-6">
          <app-markets #Markets [hidden]="basicinfo.urlForm.invalid || basicinfo.urlForm.dirty"
            (confirmMarketsValid)="confirmMarketsValid($event)"></app-markets>
        </div>
      </div>

    </mat-tab>
    <mat-tab label="PROPOSAL" [disabled]="hideProposal()">
      <mat-card class="info-card">
        <app-summary-plan (savedSpecifics)="savedSpecifics($event)"></app-summary-plan>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- <div mdbModal #demoRegister="mdb-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"> -->
<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="myModalLabel">Create an account</h4>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="demoRegister.hide()"> -->
        <button type="button" class="close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body modal_color">
        <alert></alert>
        <app-register></app-register>
      </div>
    </div>
  </div>
</div>
