import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";

@Component({
  selector: "app-targetkwdetails",
  templateUrl: "./targetkwdetails.component.html",
  styleUrls: ["./targetkwdetails.component.scss"],
})
export class TargetkwdetailsComponent implements OnInit {
  selectedIndex: any;
  // Counter = 0;
  @Input() selectVal: number;

  constructor(
    private navigationService: NavigationService,

    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    //sub-header
    this.navigationService.updateState(
      new NavigationState(
        false,
        "Target Keywords",
        "",
        "#",
        "/target-keywords-Details"
      )
    );
    // ------------------
  }

  checkTabChanged(index) {
    this.selectedIndex = index;
  }
}
