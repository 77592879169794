<div class="row" style="background-color: none;">
    <div style="margin:15px auto 10px;font-size: 20px;font-weight: 500;">Reviews</div>
</div>
<!-- <div *ngIf="!reviewData" class="row" style="background-color: none;">
    <div style="margin:15px auto 10px;font-size: 20px;font-weight: 500;">Your account is not connected.</div>
</div> -->
<div *ngIf="!isReviewReady" id="spinner">
    <div id="spinnerDiv">
        <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
    </div>
</div>
<div class="container" *ngIf="isReviewReady" style="height: 100%;width: auto;">
    <div class="container">
        <div class="row">
            <div class="col-md-10" *ngIf="reviews" id="reviewCards" style=" overflow: hidden;">
                <header>
                    <div class="row" style="padding:0px;">
                        <div class="col-md-4">
                            <h5>You have {{reviews.length}} Reviews.</h5>
                        </div>
                        <div class="col-md-5">
                            <!-- <div class="row" *ngIf="reviewData.averageRating">
                             <h6>{{reviewData?.averageRating}}</h6> 
                            <p-rating [ngModel]="reviewData.averageRating" readonly="true" stars="5" [cancel]="false">
                            </p-rating>
                        </div> -->
                        </div>
                        <div class="col-md-3">
                            <div class="row">
                                <p-dropdown [options]="reviewSortOnRating" [(ngModel)]="selectedReviewRecent"
                                    placeholder="Recent reviews" optionLabel="type"
                                    (onChange)="getDropdownValue($event.value,'recent')"
                                    [style]="{'height':'2.3rem','padding-left':'1px'}">
                                </p-dropdown>
                                <!-- &nbsp;
                            <p-dropdown [options]="reviewsSource" [(ngModel)]="selectedReviewAction"
                                placeholder="Actions" optionLabel="name"
                                (onChange)="getDropdownValue($event.value,'action')" autoWidth="false"
                                [style]="{'width':'8rem','height':'2.3rem','padding-left':'1px'}">
                            </p-dropdown> -->
                            </div>
                        </div>
                    </div>
                </header>

                <body  style="height: 84%;padding-left: 40px;">
                    <div *ngIf="!noReviews">
                        <app-reviews-card *ngIf="isReady" [reviews]="reviews">
                        </app-reviews-card>
                        <div *ngIf="!isReady" id="spinner">
                            <div id="spinnerDiv">
                                <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="noReviews">
                            No Reviews Availabel
                    </div>
                </body>
                <div style="padding-top: 0%;padding-bottom: 0%;">
                    <p-paginator #paginator [rows]="6" #pagination (onPageChange)="paginate($event)" [first]="first"
                        [totalRecords]="totalReviews" [rowsPerPageOptions]="[10,20,50]" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Total Records : {totalRecords}">
                    </p-paginator>
                </div>
            </div>
            <div class="col-md-2 ">
                <div style="text-align: center;margin-top: 30%;">
                    <p-dropdown [options]="reviewsSource" [(ngModel)]="selectedReviewSource" placeholder="Review source"
                        optionLabel="name" autoWidth="false" (onChange)="getDropdownValue($event.value,'source')"
                        [style]="{'width':'11rem','height':'2rem'}">
                    </p-dropdown>
                </div>
                <div style="text-align: center;margin-top: 10%;">
                    <p-dropdown [options]="reviewTimeSortTime" [(ngModel)]="selectedReviewTime"
                        placeholder="Time period" optionLabel="type" autoWidth="false"
                        (onChange)="getDropdownValue($event.value,'time')" [style]="{'width':'11rem','height':'2rem'}">
                    </p-dropdown>
                </div>
                <div style="text-align: center;margin-top: 40%;">
                    <button mat-raised-button class="sendReviewInitebtn">Send review invite</button>
                </div>
            </div>
        </div>
    </div>
</div>