import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { FormBuilderService } from '../../_services/form-builder.service';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AccountService } from '../../_services/account.service';
import { NavigationService } from '../../_services';
// import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { MatBadgeModule } from '@angular/material/badge';
import { ContactService } from "../../_services/contact.service";
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { FormsModule }   from '@angular/forms';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {ErrorStateMatcher} from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-copy-forms',
  templateUrl: './copy-forms.component.html',
  styleUrls: ['./copy-forms.component.scss']
})
export class CopyFormsComponent implements OnInit {

  formName:string="";
  formSlug:string="";
  formData :any;
  showError:boolean = false;
  slugArray = [];
  disabled:boolean = true;

  nameFormControl = new FormControl('', [
    Validators.required,
  ]);

  slugFormControl = new FormControl('',[Validators.required]);

  newFormCantrol = new FormControl();
  matcher = new MyErrorStateMatcher();
  state:any;
  formId:any;
  submitted:boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _snackBar: MatSnackBar,
    private formBuilderService: FormBuilderService,
    private navigationService: NavigationService,
    private router: Router, 
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.state = this.navigationService.getCurrentNavigationState();
    this.formData = this.config.data;
    console.log(this.formData);
    for(var i=0;i<this.formData['allForm'].length;i++)
      this.slugArray.push(this.formData['allForm'][i].data.slug);
    console.log(this.slugArray);
  }

  onChange(searchValue: string)
  {
      this.formSlug = (searchValue.split(' ').join('-')).toLowerCase();
      if(this.slugArray.includes(this.formSlug))
      { 
        this.showError = true;
        this.disabled = true;
      }
      else
      {
        this.showError = false;
        this.disabled = false;
      }

      if(this.formName.length<1 || this.formSlug.length<1)
          this.disabled = true
    
      console.log(this.formSlug,this.formName);
  }

  copy()
  {
  
    this.submitted = true;
    let dataToBeCopied = this.formData['selectedForm'];
    dataToBeCopied['name'] = this.formName;
    dataToBeCopied['slug'] = this.formSlug;
    dataToBeCopied['ref'] = "";

    let collRef = this.formBuilderService.copyForm(this.formData['type']);
    collRef.add(dataToBeCopied).then(doc=>{
      console.log(doc.id);
      this.formId = doc.id;
      this.router.navigate(['/form-design',this.state.url,this.formId], { relativeTo: this.route });
      this.ref.close();
    });

  }

}
