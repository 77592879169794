<div class="alert alert-danger" role="alert" *ngIf="showError">
    Form with this name already present try different name
</div>
<div class="outside">
    <form class="example-form" (ngSubmit)="copy()" #myForm="ngForm">
        <div class="col">
            <div class="row-4">
                <mat-form-field class="example-full-width">
                    <mat-label>Form Name</mat-label>
                    <input matInput [(ngModel)]="formName" id="formName" name="formName"
                        (input)="onChange($event.target.value)" placeholder="Enter form name"
                        [formControl]="nameFormControl" [errorStateMatcher]="matcher" required>
                    <mat-error *ngIf="nameFormControl.hasError('required')">
                        Name is required
                    </mat-error>

                </mat-form-field>
            </div>
            <div class="row-4">
                <mat-form-field class="example-full-width">
                    <mat-label>Form Slug</mat-label>
                    <input matInput [(ngModel)]="formSlug" id="formSlug" name="formSlug"
                        (input)="onChange($event.target.value)" [formControl]="slugFormControl"
                        placeholder="Enter form slug" required>
                    <mat-error *ngIf="slugFormControl.hasError('required')">
                        Slug is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="row-4" *ngIf="!submitted">
                <center><button mat-raised-button type="submit" [disabled]="disabled">Copy</button></center>
            </div>
            <div class="row-4" *ngIf="submitted">
                <center>
                    <mat-spinner [diameter]="70"></mat-spinner>
                </center>
            </div>
        </div>
    </form>
</div>