import {
  Component,
  OnInit,
  OnChanges,
  ViewChild,
  ElementRef,
  Input,
  SimpleChanges,
} from "@angular/core";
import { SeoService } from "../_services/seo-service.service";
import { MatPaginator } from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { AccountService } from "../_services/index";

@Component({
  selector: "app-ranked-keywords",
  templateUrl: "./ranked-keywords.component.html",
  styleUrls: ["./ranked-keywords.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class RankedKeywordsComponent implements OnInit, OnChanges {
  @Input()
  website: any;

  @Input() countryCode: any;

  isLoading: boolean = false;
  webUrl: string;
  actualMetric: any;
  metricArray: any = [];
  showMetricTable: boolean = false;
  //showKeywords : boolean = false;
  showError: boolean = false;
  displayedColumns: string[] = ["key", "value"];
  columnsToDisplay: string[] = [
    "key",
    "position",
    "etv",
    "cpc",
    "results_count",
    "search_volume",
    "traffic_cost",
    "competition",
  ];
  //dataSourceForMetric = new MatTableDataSource();
  dataSourceForKeywords = new MatTableDataSource();
  rankedKeywordsArray: any;
  keywords: any;
  noRankedData: string;
  expandedElement: any | null;
  topResultCount: any;
  errorMessage: string;
  //website:string;
  acc: any;
  showGetResults: boolean = true;

  @ViewChild(MatPaginator)
  set paginator(value: MatPaginator) {
    this.dataSourceForKeywords.paginator = value;
  }

  @ViewChild(MatSort)
  set sort(value1: MatSort) {
    this.dataSourceForKeywords.sort = value1;
  }

  @ViewChild("content") content: ElementRef;
  constructor(
    private seoService: SeoService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    //this.getUrl();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showGetResults = true;
    //this.getUrl();
  }

  /*  ngAfterViewInit() {
  console.log("View is initialized");
  console.log(this.showMetricTable);
  }
*/

  getUrl() {
    this.showGetResults = false;
    this.isLoading = true;
    this.showMetricTable = false;
    this.showError = false;
    //this.seoService.getRankedKeywords(this.webUrl)
    console.log("getting raked keywords for ", this.website,this.countryCode);
    this.seoService.getRankedKeywords(this.website, this.countryCode).subscribe(
      (res) => {
        this.topResultCount = res["results"]["meta"].result_count;
        this.actualMetric = res["results"]["meta"].actual_metric;
        this.rankedKeywordsArray = res["results"]["ranked"];
        this.getKeywordsDetails(this.rankedKeywordsArray);
        this.getActualMetric(this.actualMetric);
        this.isLoading = false;
        this.showMetricTable = true;
        this.showError = false;
      },
      (error) => {
        this.showMetricTable = false;
        this.isLoading = false;
        this.showError = true;
        this.errorMessage = error.error["message"];
      }
    );
  }

  getActualMetric(actualMetric) {
    this.metricArray = actualMetric;
    /*Object.keys(actualMetric).forEach((key)=>{
      let actMetric = {
        key : key,
        value : actualMetric[key]
      };
      this.metricArray.push(actMetric);
    });
    console.log(this.metricArray);
    this.dataSourceForMetric = new MatTableDataSource(this.metricArray);

    this.metricArray = [];*/
  }

  getKeywordsDetails(keywords: any) {
    this.keywords = keywords;
    if (this.keywords == "No data") {
      this.noRankedData = "There is no data for ranked keywords.";
    }
    this.expandedElement = this.keywords;
    this.dataSourceForKeywords = new MatTableDataSource(this.keywords);
  }

  applyFilter(filterValue: string) {
    this.dataSourceForKeywords.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceForKeywords.paginator) {
      this.dataSourceForKeywords.paginator.firstPage();
    }
  }
}
