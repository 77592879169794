<div class="row" id="subHeader1">
  <div id="pageTitle" style="text-align: center;">Campaigns</div>
</div>

<div class="container-fluid view">
  <center>
    <div *ngIf="!isReady">
      <!--Fetching your campaigns ...-->
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
  </center>

  <div *ngIf="!hasAdWordsAccess && !hasFBAccess && !hasEmailAccess">
    <p><strong>Looks like we do not have your campaigns data. To see your campaigns please connect your Google and
        Facebook accounts, <a [href]="['/account']" target="_self">click here.</a></strong></p>
  </div>
  <div class="format-center scrolling-wrapper-flexbox">
    <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi" *ngIf="hasFBAccess || hasAdWordsAccess; else NoSpend">
          {{ (+fb_spend * acc?.facebook_factor) + (adwords_spend * acc?.adwords_factor) | currency:acc?.account_currency:'symbol':'1.0-0' }}
        </div>
        <div class="metric-caption kpi">Spend</div>
      </mat-card-content>
    </mat-card>
    <ng-template #NoSpend>
      <div class="metric kpi"> ? </div>
    </ng-template>

    <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi" *ngIf="hasFBAccess || hasAdWordsAccess; else NoConversion">
          {{ (totalAdwordsConversions + totalFacebookConversions)}} </div>
        <div class="metric-caption kpi">Total conversions</div>
        <ng-template #NoConversion>
          <div class="metric kpi"> ? </div>
        </ng-template>
      </mat-card-content>
    </mat-card>

    <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi" *ngIf="hasFBAccess || hasAdWordsAccess; else NoCpc">
          {{ (((+fb_spend * acc?.facebook_factor) + (adwords_spend * acc?.adwords_factor)) /(totalAdwordsConversions + totalFacebookConversions)) | currency:acc?.account_currency:'symbol':'1.0-1'}}
        </div>
        <div class="metric-caption kpi">Cost per conversion</div>
        <ng-template #NoCpc>
          <div class="metric kpi"> ? </div>
        </ng-template>
      </mat-card-content>
    </mat-card>


    <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi">
          <!-- {{value | currency:'INR':'symbol':'1.0-0'}}-->? </div>
        <div class="metric-caption kpi"> Conversion Value </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi">
          <!-- {{value/spend}}X-->? </div>
        <div class="metric-caption kpi"> ROI</div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="row">
    <div class="col">
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="">
            Spend Breakdown
          </mat-card-title>
        </mat-card-header>
        <center>
          <div *ngIf="!isReady">
            <!--Fetching your campaigns ...-->
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
          </div>
        </center>
        <mat-card-content>
          <mat-list role="list">
            <mat-divider></mat-divider>
            <div [class.disable-channel]="!hasAdWordsAccess">
              <mat-list-item role="listitem">
                <h6>Adwords</h6>
                <span class="spacer"></span>
                <a [routerLink]="['/adwords']">
                  <mat-icon>chevron_right</mat-icon>
                </a>
              </mat-list-item>
              <div class="row">
                <div class="col-4 metric">
                  {{ (adwords_spend * acc?.adwords_factor) | currency:acc?.account_currency:'symbol':'1.0-0' }}</div>
                <div class="col-4 metric"> ?</div>
                <div class="col-4 metric"> ?</div>
              </div>
              <div class="row">
                <div class="col-4 metric-caption"> Spend</div>
                <div class="col-4 metric-caption"> Value</div>
                <div class="col-4 metric-caption"> ROI</div>
              </div>
            </div>
            <mat-list-item>
              <mat-divider></mat-divider>
            </mat-list-item>
            <div [class.disable-channel]="!hasFBAccess">
              <mat-list-item role="listitem">
                <h6>Facebook</h6>
                <span class="spacer"></span>
                <a [routerLink]="['/facebook']">
                  <mat-icon>chevron_right</mat-icon>
                </a>
              </mat-list-item>
              <div class="row">
                <div class="col-4 metric">
                  {{ (fb_spend * acc?.facebook_factor) | currency:acc?.account_currency:'symbol':'1.0-0' }}</div>
                <div class="col-4 metric"> ?</div>
                <div class="col-4 metric"> ?</div>
              </div>
              <div class="row">
                <div class="col-4 metric-caption"> Spend</div>
                <div class="col-4 metric-caption"> Value</div>
                <div class="col-4 metric-caption"> ROI</div>
              </div>
            </div>

            <mat-list-item>
              <mat-divider></mat-divider>
            </mat-list-item>
            <div [class.disable-channel]="!hasEmailAccess">
              <mat-list-item role="listitem">
                <h6>Email</h6>
                <span class="spacer"></span>
                <a [routerLink]="['/email']">
                  <mat-icon>chevron_right</mat-icon>
                </a>
              </mat-list-item>
              <div class="row">
                <!--  <div class="col-4 metric"> {{ email_spend | currency:acc?.account_currency:'symbol':'1.0-0' }} </div> -->
                <div class="col-4 metric"> ?</div>
                <div class="col-4 metric"> ?</div>
                <div class="col-4 metric"> ?</div>
              </div>
              <div class="row">
                <div class="col-4 metric-caption"> Spend</div>
                <div class="col-4 metric-caption"> Value</div>
                <div class="col-4 metric-caption"> ROI</div>
              </div>
            </div>
            <mat-list-item>
              <mat-divider></mat-divider>
            </mat-list-item>
            <!--  <div class="disable-whole-div__myDIV">
              <div class="disable-whole-div__cover"></div>
              <mat-list-item role="listitem">
                <h6>SEO</h6>
                <span class="spacer"></span>
                <mat-icon>chevron_right</mat-icon>
              </mat-list-item>
              <div class="row">
                <div class="col-4 metric"> 40,000</div>
                <div class="col-4 metric"> 80,000</div>
                <div class="col-4 metric"> 2.0X</div>
              </div>
              <div class="row">
                <div class="col-4 metric-caption"> Spend</div>
                <div class="col-4 metric-caption"> Value</div>
                <div class="col-4 metric-caption"> ROI</div>
              </div>
            </div>-->
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <!--<div class="row disable-whole-div__myDIV">
    <div class="disable-whole-div__cover"></div>
    <div class="col">
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title>

            Conversion Breakdown
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list role="list">
            <mat-divider></mat-divider>
            <mat-list-item role="listitem">
              <h6>Email sign-ups</h6>
              <span class="spacer"></span>
              <a href="">
                <mat-icon>chevron_right</mat-icon>
              </a>
            </mat-list-item>
            <div class="row">
              <div class="col-4 metric"> 10,000</div>
              <div class="col-4 metric"> ?</div>
              <div class="col-4 metric"> ?</div>
            </div>
            <div class="row">
              <div class="col-4 metric-caption"> Spend</div>
              <div class="col-4 metric-caption"> Value</div>
              <div class="col-4 metric-caption"> ROI</div>
            </div>
            <mat-list-item>
              <mat-divider></mat-divider>
            </mat-list-item>
            <mat-list-item role="listitem">
              <h6>Book Appointments</h6>
              <span class="spacer"></span>
              <mat-icon>chevron_right</mat-icon>
            </mat-list-item>
            <div class="row">
              <div class="col-4 metric"> 40,000</div>
              <div class="col-4 metric"> ?</div>
              <div class="col-4 metric"> ?</div>
            </div>
            <div class="row">
              <div class="col-4 metric-caption"> Spend</div>
              <div class="col-4 metric-caption"> Value</div>
              <div class="col-4 metric-caption"> ROI</div>
            </div>
            <mat-list-item>
              <mat-divider></mat-divider>
            </mat-list-item>
            <mat-list-item role="listitem">
              <h6>Calls</h6>
              <span class="spacer"></span>
              <mat-icon>chevron_right</mat-icon>
            </mat-list-item>
            <div class="row">
              <div class="col-4 metric"> 30,000</div>
              <div class="col-4 metric"> ?</div>
              <div class="col-4 metric"> ?</div>
            </div>
            <div class="row">
              <div class="col-4 metric-caption"> Spend</div>
              <div class="col-4 metric-caption"> Value</div>
              <div class="col-4 metric-caption"> ROI</div>
            </div>
            <mat-list-item>
              <mat-divider></mat-divider>
            </mat-list-item>
            <mat-list-item role="listitem">
              <h6>Goals Completed</h6>
              <span class="spacer"></span>
              <mat-icon>chevron_right</mat-icon>
            </mat-list-item>
            <div class="row">
              <div class="col-4 metric"> 10,000</div>
              <div class="col-4 metric"> ?</div>
              <div class="col-4 metric"> ?</div>
            </div>
            <div class="row">
              <div class="col-4 metric-caption"> Spend</div>
              <div class="col-4 metric-caption"> Value</div>
              <div class="col-4 metric-caption"> ROI</div>
            </div>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>-->
</div>