import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertService } from "../_services/index";
import { AngularFireAuth } from "angularfire2/auth";

@Component({
  moduleId: module.id.toString(),
  templateUrl: "forgot-password.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent {
  model: any = {};
  loading = false;
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public afAuth: AngularFireAuth
  ) {}
  ngOnInit() {
    let user = this.afAuth.auth.currentUser;
    if (user) {
      if (user.emailVerified) {
        this.router.navigateByUrl("/websites");
      } else {
        /*
         * An unverified user is trying to access the signup page, so send him back to
         * login page so that he can re-verify his account if he wants to.
         */

        this.router.navigateByUrl("/");
      }
    }
  }

  forgotPassword() {
    this.loading = true;
    console.log("in forgot password");
    this.afAuth.auth.sendPasswordResetEmail(this.model.email).then(
      () => {
        this.alertService.success(
          "You can reset your password by clicking on the link sent to '" +
            this.model.email +
            "'"
        );
      },
      (error) => {
        this.alertService.error(error.message);
      }
    );
  }
}
