<div class="items">
  <form #form="ngForm" (ngSubmit)="updateLocalRankConfigs(form)">
    <div class="row">
      <div class="col-12 col-md-6">
        <mat-form-field class="fieldWidth">
          <mat-label for="address">Address</mat-label>
          <input ngx-google-places-autocomplete matInput name="address" id="address" [(ngModel)]="details.address"
            class="form-control" type="text" pInputText required [options]="{ types: ['establishment'] }"
            (onAddressChange)="handleAddressSelect($event)">
        </mat-form-field>
      </div>
      <div class="col-12 col-md-3">
        <mat-form-field class="fieldWidth">
          <mat-label for="radius">Radius</mat-label>
          <input matInput id="radius" name="radius" [(ngModel)]="details.radius" class="form-control" type="number"
            (change)="addMarkerAndCircle()" required>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-3 ">
        <mat-form-field class="fieldWidth">
          <mat-label for="region">Region</mat-label>
          <mat-select name="region" class="form-control" [(ngModel)]="details.countryRegion" [compareWith]="comparer"
            (selectionChange)="getCountry($event.value)">
            <mat-option *ngFor="let country of countries" [value]="country">{{country['name']}}</mat-option>
          </mat-select>
          <!-- <input matInput id="region" name="region" [(ngModel)]="details.region" class="form-control" type="text" pInputText required> -->
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div>
          <mat-form-field class="fieldWidth">
            <mat-label for="targetKw">Target Keywords</mat-label>
            <textarea matInput id="targetKw" name="targetKw" [(ngModel)]="targetKw" class="form-control" type="text"
              rows="4" pInputTextarea required></textarea>
          </mat-form-field>
        </div>
        <div style="display:flex;">
          Cities
          <div style="width:80%;height:30px;margin-left:10px;">
            <input ngx-google-places-autocomplete id="city" name="city" type="text" [options]="{ types: ['geocode'] }"
              [(ngModel)]="city" (onAddressChange)="handleCitySelect($event)">
            <div class="addCity" (click)="addLocation(city)">
              <div style="width:fit-content;height:fit-content;margin:auto;">
                <i class="fas fa-plus"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="cities" style="margin-top: 10px;">
          <div *ngFor="let location of details.locations;let i = index;"
            style="width:75%;height:30px;margin-left:10px;margin-bottom:10px;margin-left:48px;">
            <input matInput class="cityList" name="city" type="text" readonly [value]='location'>
            <div class="addCity" (click)="deleteLocation(i)">
              <div style="width:fit-content;height:fit-content;margin:auto;">
                <i class="fas fa-minus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <p-gmap #gmap [options]="options" (onMapReady)="setMap($event)" (onMapClick)="onLocationSelect($event)"
          (onOverlayClick)="onOverlaySelect($event)" [overlays]="overlays" [style]="{'width':'100%','height':'320px'}">
        </p-gmap>
      </div>
    </div>
    <div class="row" style="text-align: start;margin:10px -15px;">
      <div class="col-md-4">
        <div style="margin-top:20px;">
          <mat-slide-toggle name="enableSchedule" color="primary" labelPosition='before'
            [checked]="cloudSchedulerEnabled" (toggleChange)="checked =!checked;">
            Get Local Rank Automation :
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-md-4" *ngIf="checked">
        <mat-form-field class="fieldWidth">
          <mat-label for="schedule">Schedule</mat-label>
          <mat-select name="schedule" class="form-control" [(ngModel)]="schedule"
            (selectionChange)="changeSchedule($event.value)">
            <mat-option *ngFor="let schedule of schedules" [value]="schedule.value">{{schedule['name']}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4" *ngIf="checked">
        <mat-form-field class="fieldWidth">
          <mat-label for="timezone">Timezone</mat-label>
          <mat-select name="timezone" class="form-control" [(ngModel)]="timezone"
            (selectionChange)="changeTimezone($event.value)">
            <mat-option *ngFor="let timezone of timezones" [value]="timezone.value">{{timezone['name']}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="items" style="text-align: center;">
      <button mat-raised-button class="text-center" type="submit" [disabled]="!form.valid">Update</button>
    </div>
  </form>
</div>