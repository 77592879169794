<div class="mat-elevation-z8 mobileViewElevation">
  <div class="contentDiv mobileViewContent" *ngIf="campaignConfigSet;else noShow">
    <div class="text-center" style="padding: 10px;" *ngIf="campaignsList.length==0;else campaigns">
      <div>No SMS Campaigns Created </div>
    </div>
    <ng-template #campaigns>
      <p-table #dt [columns]="columns" [value]="campaignsList" sortMode="multiple"
        [multiSortMeta]="[{field: 'createdOn', order: -1}]" selectionMode="multiple" [paginator]="true" [rows]="10"
        [showCurrentPageReport]="true" [rowsPerPageOptions]="[10]"
        currentPageReportTemplate="{first} - {last} of {totalRecords}">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 50px; text-align:center"></th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{col.name|titlecase}}<p-sortIcon p-sortIcon [field]="col.field"
                *ngIf="!disableSortColumns.includes(col.field)"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-index let-columns="columns">
          <tr>
            <td><a (click)="campaignDetails(index)"><i style="padding: 0px 0.5px; font-weight: 100;"
                  class="pi pi-chevron-right"></i></a></td>
            <td>{{rowData['name']|titlecase}} </td>

            <!-- <td>{{rowData['createdOn']|date:'MM-dd-yyyy'}} </td> -->
            <td>{{rowData['createdOn']?(rowData.createdOn| date: 'MMM d, yyyy hh:mm a'):''}}</td>
            <td>
              <span [class]="rowData.status.toLowerCase().replace(' ','')"
                style="border-radius: 10px;text-align: center">
                <b style="margin:10px">{{rowData.status}}</b>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>


    </ng-template>
  </div>
  <ng-template #noShow>
    <div class="text-center" style="padding: 10px;">
      SMS campaign configurations are not set
    </div>
  </ng-template>
</div>