import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { OrganicRankingService } from "../../_services/organic-ranking.service";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-organic-rank-history',
  templateUrl: './organic-rank-history.component.html',
  styleUrls: ['./organic-rank-history.component.scss']
})
export class OrganicRankHistoryComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  @Output() close = new EventEmitter<any>();
  @Input() websiteUrl: string;
  accDetails: any;
  loading: boolean = true;
  allDocs: any;
  totalRecords:number = 0;
  rows: number = 20;
  first: number = 0;
   constructor(
    private _snackBar: MatSnackBar,
    private organicRankService: OrganicRankingService,
  ) { }


  ngOnInit(): void {
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('websiteUrl' in changes) {
      this.loading = true;
      let url = changes.websiteUrl.currentValue;
      // console.log(url);
      if(url){
        this.organicRankService.getOrganicRankHistory(url).then(resp => {
          if('status' in resp){
            this.openSnackBar(resp['message'])
            // this.close.emit(false);
          }
          else{
            this.allDocs = resp;
            this.totalRecords = this.allDocs.length;
            // console.log(this.allDocs);
          }
          this.loading = false;
        })
      }
      else{
        // console.log("No History");
        this.loading = false;
      }
    }
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  showRanking(event){
    this.allDocs = [];
    this.close.emit(event);
  }
}
