import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as moment from 'moment';
import { Observable,BehaviorSubject,Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TargetKWService {

private targetResultSubject = new Subject<any>();
targetResult$ = this.targetResultSubject.asObservable();
allResults:any=[];

  constructor(
              private http: HttpClient,
            ) { }

  async getTargetKWResults()
  {
    let accId = `${sessionStorage.getItem('current_acc_id')}`;
  	let docData={};
    let date = moment().subtract(1,'months').toDate();
  	const docSnap = await firebase.firestore().collection('accounts').doc(accId).collection('targetKW')
                  .where('taskSetOn','>',date)
                  .orderBy('taskSetOn','desc').get();

    this.allResults = docSnap.docs.map(docs => docs);
    let resp:any;
    if(this.allResults.length>0)
    {
      let data = this.allResults[0].data();
        if(data['status'] == 'in progress' || data['status'] == 'to do')
        {

          resp = {'status':'in progress','result':'We are preparing  your target keywords .'};
          resp['location']= data['location'];
          resp['country'] = data['location'].split(',').reverse()[0];
          resp['date'] = data['taskSetOn'].toDate().toLocaleString();
          this.pushKeywordResult(resp);
        }
        else if(data['status'] == 'completed')
        {
          let url = `${environment.pythonServerUrl}/target-keywords-result?accountId=${accId}`;
          resp = this.http.get(url);

          this.http.get(url).subscribe(
          urldata=>{
            resp = urldata as JSON;
            resp['location']= data['location'];
            resp['country'] = data['location'].split(',').reverse()[0];
            resp['date'] = moment(data['taskSetOn'].toDate()).format('DD-MM-YYYY');
            this.pushKeywordResult(resp);
            // resp = responseData;
          });
        }
        else{
          resp = {'status':'error','result':'Error in getting target keywors'};
          this.pushKeywordResult(resp);
        }
      }
      else{
        resp = {'status':'no task','result':'No results found'};
          this.pushKeywordResult(resp);
      }
      // });
  }

  getResult(accId)
  {
     let url = `${environment.pythonServerUrl}/target-keywords-result?accountId=${accId}`;
     this.http.get(url).subscribe(
      data=>{
        let responseData = data as JSON;
        return responseData;
      });
  }

//compare array contains same values
compareArray(docArray,seedKw,helpers,location,countryCode)
{
  let arrLen = docArray.length;
  if(arrLen > 0)
  {
  for(let i=0;i<arrLen;i++)
    {
      let arr1 = docArray[i].data();
      if((arr1.seedKeyword.sort().join(',') === seedKw.sort().join(',')) && (arr1.helpers.sort().join(',') === helpers.sort().join(',')) && (location === arr1.location) && (countryCode=== arr1.countryCode))
      {
        return docArray[i];
      }
    }
  return -1;
  }
  else
  {
    return -1;
  }
}

//set task for getting target keywords
// async setTask(seedKw,helpers,countryCd,location)
async setTask(seedKw,helpers)
{
  let countryCode = 'IN';
  let loc = '';
  if(seedKw==[]){
    return {'status':'error','result':'error in seed keywords.'};
  }
  /* if(countryCd !== '')
   {
     countryCode = countryCd;
   }
  if(location !== '')
  {
    loc = location;
    let arr = loc.split(' ');
    if(arr.length!=1)
    {
      loc = arr.join('');
    }
  }*/

  let accId = `${sessionStorage.getItem('current_acc_id')}`;
  let date = moment().subtract(1,'months').toDate();
  let doc={
    'seedKeyword':seedKw,
    'helpers' : helpers,
    'language' : 'en',
    'countryCode': countryCode,
    'taskSetOn':moment().toDate(),
    'location':loc,
    'status':'to do',
  };
  let resp :any;
  let arrId = this.compareArray(this.allResults,seedKw,helpers,loc,countryCode);
  if(arrId == -1)
  {
    firebase.firestore().collection('accounts').doc(accId).collection('targetKW').add(doc).then(
    snap =>{
    this.http.get(`${environment.pythonServerUrl}/target-keywords?accountId=${accId}`).subscribe(
      data =>{
        resp = data as JSON;
      });
    });
    resp = {'status':'in progress','result':'We are preparing your target keywords '};
    this.pushKeywordResult(resp);
    return resp;
  }
  else
  {
    let docData =arrId.data();
    let docRef = arrId.id;
    if(docData['status'] !== 'error')
      {
      this.http.get(`${environment.pythonServerUrl}/target-keywords-result?accountId=${accId}&docId=${docRef}`).subscribe(
      data =>{
        resp['location']= data['location'];
        resp['country'] = data['location'].split(',').reverse()[0];
        resp['date'] = data['taskSetOn'].toDate().toLocaleString();
        resp = data as JSON;
        this.pushKeywordResult(resp);
      });
        this.pushKeywordResult({'status':'error','result':'Target keywords already fetched.'});
        return {'status':'error','result':'Target keywords already fetched.'};
      }
      else{
      return {'status':'error','result':'Error in getting target keywords.'};
      }
    }
// }

}

//observable to push targetKw result
pushKeywordResult(targetResult:any)
{
  this.targetResultSubject.next(targetResult);
}

//to get result

getTargetKwData():Observable<any>{
  return this.targetResultSubject.asObservable();
}

}
