import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { CalendarOptions, FullCalendarComponent, Calendar } from '@fullcalendar/angular';
import { AccountService } from "../../_services/account.service";
import { TitleCasePipe } from "@angular/common";
import { AppointmentService } from './../../_services/appointment.service';
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppointmentDetailComponent } from "../../appointment-detail/appointment-detail.component";
// import { ScheduleNewAppointmentComponent } from "../schedule-new-appointment/schedule-new-appointment.component";
import * as moment from 'moment';
import { RecurringAppointmentDetailsComponent } from '../../recurring-appointment-details/recurring-appointment-details.component';
import { Router } from '@angular/router';

const colors: any = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
  green: {
    primary: "#33cc33",
    secondary: "#E3F4D0",
  },
  theme_pink: {
    primary: "#ff4081",
    secondary: "#ffdae0",
  },
  theme_blue: {
    primary: "#3f51b5",
  },
  orange: {
    primary: "#FF8C00",
  },
  gray: {
    primary: "#a7a7a7",
    secondary: "#e7e7e7",
  },
  black: {
    primary: "#000000",
    secondary: "#e7e7e7"
  }
};

@Component({
  selector: 'app-appointment-full-calendar',
  templateUrl: './appointment-full-calendar.component.html',
  styleUrls: ['./../appointments.component.scss']
})
export class AppointmentFullCalendarComponent implements OnInit, OnDestroy {
  @ViewChild('calendar') fullCalendar: FullCalendarComponent;
  @ViewChild('calendar2') fullCalendar2: FullCalendarComponent;
  @Output() clearFilters = new EventEmitter<any>();
  link: string;
  showBookAppt: boolean = false;
  dView: any;
  mView: any;
  view: any;
  viewDate: any;
  startDate: Date;
  endDate: Date;
  title: string = '';
  providersArray = [];
  // selectedProvider = [];
  providerstatus: any;
  providersCount;
  allProvidersValue;
  providers: any = [];
  allCheckBoxValue: boolean = true;
  isDisabled: boolean;
  allProviders: any = [];

  isCalendarSelected: boolean = false;
  options1: CalendarOptions;
  options2: CalendarOptions;
  appointments: any[];
  allAppointments: any = [];
  appointmentEvent: any[];
  calendarApi: Calendar;
  isViewDesktop: boolean = true;
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private appointmentService: AppointmentService,
    private accountService: AccountService,
    private _router: Router) {
    //let snapshot = firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem("current_acc_id")}`).collection("calendar").where('slug', 'in', [this.selectedCalendar]).get();
    // console.log("snapshot", snapshot);
    // const name = Calendar.name;
    this.title = '';
    if (window.innerWidth < 769)
      this.isViewDesktop = false;
    else
      this.isViewDesktop = true;
  }
  ngOnDestroy(): void {
    if (($('.popover.fade')).length)
      $('.popover.fade').remove();
  }
  ngAfterViewInit() {
    console.log('afterViewInit');
    this.appointmentService.appointments$.subscribe((appointments) => {
      this.appointments = appointments;
      this.allAppointments = appointments;
      if (this.isViewDesktop)
        this.calendarApi = this.fullCalendar.getApi();
      else
        this.calendarApi = this.fullCalendar2.getApi();
      this.appointmentEvent = [];
      appointments.forEach((appointment) => {
        if (!(appointment.status === "rescheduled")) {
          let myEvent: any = {
            appointment: appointment,
            start: appointment.appt_start,
            end: appointment.appt_end,
            // provider:appointment.provider_name,
            // name: appointment.first_name + ' ' + appointment.last_name,
            // location:appointment.location,
            // status:appointment.status,
            // date:appointment.date,
            // time:appointment.slot,
            // calendar:appointment.calendarReference,
            status: `Status: ${new TitleCasePipe().transform(appointment.status)}`,
            location: appointment.location != undefined ? `${appointment.location}` : '',
            booked_on: `Booked on: ${moment(appointment.booked_on).format("MMM D, YYYY")}`,
            note: `Note: ${appointment.notes}`,
            description: `Status: ${new TitleCasePipe().transform(appointment.status)}
            ${appointment.location}
            Booked on ${moment(appointment.booked_on).format("MMM D, YYYY")} on ${new TitleCasePipe().transform(appointment.source)}.
            Note: ${appointment.notes}`,
            slot: `${appointment.slot}`,
            title: `${appointment.first_name} ${appointment.last_name}`,
            color: this.getColor(appointment.status),
            allDay: this.calendarApi?.view?.type == 'dayGridMonth' ? true : false,
          };

          this.appointmentEvent.push(myEvent);
        }
      });
      this.showBookAppt = true;
      this.calendarApi?.removeAllEventSources();
      this.calendarApi?.addEventSource(this.appointmentEvent);
    });
  }
  ngOnInit(): void {
    // console.log('onInit');
    this.options1 = {
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'Prev,Today,Next',
        center: 'title',
        right: 'DayGridMonth,TimeGridWeek,TimeGridDay,ListWeek'
      },
      customButtons: {
        Prev: {
          text: 'Prev',
          // icon: 'left-single-arrow',
          click: () => {
            this.clearFilters.emit("clear");
            this.fullCalendar.getApi().prev();
          }
        },
        Next: {
          text: 'Next',
          click: () => {
            this.clearFilters.emit("clear");
            this.fullCalendar.getApi().next();
          }
        },
        Today: {
          text: 'Today',
          click: () => {
            this.clearFilters.emit("clear");
            this.fullCalendar.getApi().today();
          }
        },
        DayGridMonth: {
          text: 'Month',
          click: () => {
            this.clearFilters.emit("clear");
            this.fullCalendar.getApi().changeView('dayGridMonth');
          }
        },
        TimeGridWeek: {
          text: 'Week',
          click: () => {
            this.clearFilters.emit("clear");
            this.fullCalendar.getApi().changeView('timeGridWeek');
          }
        },
        TimeGridDay: {
          text: 'Day',
          click: () => {
            this.clearFilters.emit("clear");
            this.fullCalendar.getApi().changeView('timeGridDay');
          }
        },
        ListWeek: {
          text: 'List',
          click: () => {
            this.clearFilters.emit("clear");
            this.fullCalendar.getApi().changeView('listWeek');
          }
        }
      },
      themeSystem: 'bootstrap',
      datesSet: this.datesSet.bind(this, 'desktop'),
      displayEventTime: false,
      dayMaxEventRows: true,
      slotMinTime: '06:00',
      slotMaxTime: '24:00',
      noEventsText: 'No Appointments...',
      hiddenDays: [],
      eventOrder: "start",
      eventClick: (eventClickEvent) => {
        this.handleEvent('Clicked', eventClickEvent.event._def.extendedProps);
      },
      moreLinkClick: (arg) => {
        // console.log('morelink click:', arg);
        // $('.fc-popover-header.popover-header').find('.fc-popover-close.fa.fa-times').css({ 'padding': '0px' });
        // console.log(($('.fc-popover-header') as any));
      },
      fixedWeekCount: false,
      editable: false,
      // eventAdd: function (info) {
      //   console.log('EventAdd', info);
      //   console.log(info.event);
      //   // if (info.view.type == 'dayGridMonth')
      //   //   $(info.el).prepend("<div style='height:10px;width:10px;margin:auto 10px auto 10px;border-radius:50%;background-color:" + info.event.backgroundColor + ";'></div>").css({ "display": "flex", "background-color": "transparent", "color": "black", "border": "1px solid #d3d3d3" });
      //   // else if (info.view.type == 'timeGridWeek' || info.view.type == 'timeGridDay') {
      //   //   let status = info.event.extendedProps.appointment.status;
      //   //   $(info.el).css({ "color": "blue", "border-color": color.primary });
      //   // }
      // },
      eventContent: this.eventContent.bind(this),
      viewDidMount: (arg) => {
        this.dView = arg.view;
      },
      eventsSet: (arg) => {
        if (($('.popover.fade')).length)
          $('.popover.fade').remove();
      },
      eventDidMount: function (arg) {
        ($(arg.el) as any).popover({
          title: `${arg.event.extendedProps.slot} ${arg.event.title}`,
          // content: arg.event.extendedProps.description,
          content: `<div style="display:flex;flex-direction:column">
          <div>${arg.event.extendedProps.appointment.recurringApptDetailReference ? 'Follow Up Visit' : ''}</div>
          <div>${arg.event.extendedProps.status}</div>
          <div>${arg.event.extendedProps.booked_on}</div>
          <div>${arg.event.extendedProps.location}</div>
          <div>${arg.event.extendedProps.note}</div>
          </div>`,
          html: true,
          trigger: 'hover',
          placement: 'top',
          container: 'body'
        });
        if (arg.view.type == 'dayGridMonth') {
          ($(arg.el) as any)
            .prepend("<div style='height:10px;width:10px;margin:auto 8px;flex-shrink: 0;border-radius:50%;background-color:" + arg.event.backgroundColor + ";'></div>")
            .css({ "margin": "0px 1px", "display": "flex", "background-color": "transparent", "border-color": "#d3d3d3", "overflow": "hidden" });
          ($(arg.el) as any).find('.fc-event-main').css({ "color": "black", "display": "flex", "flex-direction": "column" });
        }
        else if (arg.view.type == 'timeGridWeek' || arg.view.type == 'timeGridDay') {
          let status = arg.event.extendedProps.appointment.status;
          let color;
          switch (status) {
            case "booked":
              color = colors.blue;
              break;
            case "completed":
              color = colors.green;
              break;
            case "no-show":
              color = colors.red;
              break;
            case "rescheduled":
              color = colors.yellow;
              break;
            case "cancelled":
              color = colors.gray;
              break;
            case "requested":
              color = colors.black;
              break;
          }
          $((arg.el) as any).find('.fc-event-main').css({ "color": "blue", "background-color": color.secondary, "border-color": color.primary });
        }
      },
    };

    this.options2 = {
      themeSystem: 'bootstrap',
      initialView: 'listWeek',
      headerToolbar: {
        left: 'Prev,Today,Next',
        center: '',
        right: 'ListWeek,ListDay'
      },
      customButtons: {
        Prev: {
          text: 'Prev',
          click: () => { this.clearFilters.emit("clear"); this.fullCalendar2.getApi().prev(); }
        },
        Next: {
          text: 'Next',
          click: () => { this.clearFilters.emit("clear"); this.fullCalendar2.getApi().next(); }
        },
        Today: {
          text: 'Today',
          click: () => { this.clearFilters.emit("clear"); this.fullCalendar2.getApi().today(); }
        },
        ListWeek: {
          text: 'Week',
          click: () => { this.clearFilters.emit("clear"); this.fullCalendar2.getApi().changeView('listWeek'); }
        },
        ListDay: {
          text: 'Day',
          click: () => { this.clearFilters.emit("clear"); this.fullCalendar2.getApi().changeView('listDay'); }
        }
      },
      datesSet: this.datesSet.bind(this, 'mobile'),
      slotMinTime: '06:00',
      slotMaxTime: '24:00',
      noEventsText: 'No Appointments...',
      hiddenDays: [],
      eventClick: (eventClickEvent) => {
        this.handleEvent('Clicked', eventClickEvent.event._def.extendedProps);
      },
      eventsSet: (arg) => {
        if (($('.popover.fade')).length)
          $('.popover.fade').remove();
      },
      viewDidMount: (arg) => {
        this.mView = arg.view;
      },
    };

    // let sizeOfProviders = this.providersArray.length;
    // for (let i = 0; i < sizeOfProviders; i++) {
    //   if (this.allCheckBoxValue === true) {
    //     this.providers.push(true);
    //     this.isDisabled = true;
    //   } else {
    //     this.providers.push(false);
    //     this.isDisabled = false;
    //   }
    // }
  }
  onResize(event) {
    let view: any;
    let temp = this.isViewDesktop;
    if (event.target.innerWidth < 769)
      this.isViewDesktop = false;
    else
      this.isViewDesktop = true;
    if (temp != this.isViewDesktop) {
      if (this.isViewDesktop)
        view = this.dView;
      else
        view = this.mView;
      this.startDate = view.currentStart;
      this.endDate = view.currentEnd;
      this.fetchAppointments(view.currentStart, view.currentEnd);
    }
  }
  eventContent(arg) {
    // console.log("arg", arg);
    if (arg.view.type == 'dayGridMonth')
      return { html: '<p style="margin:0px;padding:0px;font-size:12px">' + arg.event.extendedProps.slot + '</p><p style="margin:0px;padding:0px;font-size:12px;">' + arg.event.title + '</p>' };
    else if (arg.view.type == 'timeGridWeek' || arg.view.type == 'timeGridDay')
      return { html: '<p style="margin:auto;padding:0px;font-size:14px;">' + arg.event.title + '</p>' }
  }
  datesSet(type, arg) {
    if (type == 'desktop') {
      if (this.isViewDesktop) {
        this.dView = arg.view;
        this.startDate = arg.view.currentStart;
        this.endDate = arg.view.currentEnd;
        this.fetchAppointments(arg.view.currentStart, arg.view.currentEnd);
      }
    }
    else if (type == 'mobile') {
      console.log(arg.view.title);
      this.title = arg.view.title;
      if (!this.isViewDesktop) {
        this.mView = arg.view;
        this.startDate = arg.view.currentStart;
        this.endDate = arg.view.currentEnd;
        this.fetchAppointments(arg.view.currentStart, arg.view.currentEnd);
      }
    }
  }

  getColor(status: string) {
    let color = colors.blue;
    switch (status) {
      case "booked":
        color = colors.blue;
        break;
      case "completed":
        color = colors.green;
        break;
      case "no-show":
        color = colors.red;
        break;
      case "rescheduled":
        color = colors.yellow;
        break;
      case "cancelled":
        color = colors.gray;
        break;
      case "requested":
        color = colors.black;
        break;
    }
    if (this.view == 'timeGridWeek' || this.view == 'timeGridDay')
      return color.secondary;
    else
      return color.primary;
  }

  // OnChangeProvider(provider, event, index) {
  //   if (event.checked === true) {
  //     if (provider === "All") {
  //       this.appointmentService.filterAppointmentOnProvider(this.allProviders);
  //     }
  //     if (!this.selectedProvider.includes(provider)) {
  //       this.selectedProvider.push(provider);
  //       this.appointmentService.filterAppointmentOnProvider(
  //         this.selectedProvider
  //       );
  //     } else {
  //       this.appointmentService.filterAppointmentOnProvider(
  //         this.selectedProvider
  //       );
  //     }
  //   } else if (event.checked === false) {
  //     let providerIndex = this.selectedProvider.indexOf(provider);
  //     this.selectedProvider.splice(providerIndex, 1);
  //     this.appointmentService.filterAppointmentOnProvider(
  //       this.selectedProvider
  //     );
  //   }
  // }

  // OnSelectingAllProvider($event, allProvidersValue, allCheckBoxValue) {
  //   if ($event.checked === true) {
  //     let sizeOfProviders = this.providersArray.length;
  //     for (let i = 0; i < sizeOfProviders; i++) {
  //       this.providers[i] = true;
  //     }
  //     // this.allCheckBoxValue=false;
  //     this.allProviders = this.providersArray;
  //     this.isDisabled = true;
  //     // this.selectedProvider.push(this.allProviders);
  //     this.appointmentService.filterAppointmentOnProvider(this.allProviders);
  //   } else if ($event.checked === false) {
  //     this.allCheckBoxValue = true;
  //     this.isDisabled = false;
  //     let sizeOfProviders = this.providersArray.length;
  //     for (let i = 0; i < sizeOfProviders; i++) {
  //       this.providers[i] = false;
  //     }

  //     this.allProviders = [];
  //     this.appointmentService.filterAppointmentOnProvider(this.allProviders);
  //   }
  // }

  // calendarSelected() {
  //   this.isCalendarSelected = true;
  //   this.updateViewDate();
  // }
  fetchAppointments(startDate, endDate) {
    this.appointmentService.fetchAppointments(
      startDate, endDate
    );
  }
  updateViewDate() {
    // this.activeDayIsOpen = false;
    this.appointmentEvent = [];
    this.fetchAppointments(this.startDate, this.endDate);
  }

  handleEvent(action: string, event: any): void {
    //let accName = this.accountService.getCurrentAccDetails().account_slug;
    //let name = this.accountService.getCurrentAccDetails();
    let data: any = {}
    if (event.appointment.recurringApptDetailReference) {
      data = {
        name: `${event.appointment.first_name} ${event.appointment.last_name}`,
        appt_start: event.appointment.appt_start,
        slot: event.appointment.slot,
        clinic_name: event.appointment.location,
        status: event.appointment.status,
        notes: event.appointment.notes,
        appt: event.appointment
      };
      this.openRecuringApptDetailsDialog(data, "Recurring Appointment Details");
    }
    else {
      data = {
        name: `${event.appointment.first_name} ${event.appointment.last_name}`,
        appt_start: event.appointment.appt_start,
        slot: event.appointment.slot,
        clinic_name: event.appointment.location,
        // city: event.appointment.contact_info.address.city,
        status: event.appointment.status,
        service: event.appointment.services,
        new_patient: event.appointment.new_patient,

        notes: event.appointment.notes,
        appt: event.appointment
      };
      this.openApptDetailsDialog(data, "Appointment Details");
    }

  }

  openRecuringApptDetailsDialog(data: any, title: string) {
    const dialogRef = this.dialog.open(RecurringAppointmentDetailsComponent, {
      panelClass: "custom-dialog-container",
      disableClose: true,
      height: "680px",
      width: "1230px",
      maxWidth: '100vw',
      data: { apptDetails: data, title: title },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this._router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    })

  }

  openApptDetailsDialog(data: any, title: string) {
    const dialogRef = this.dialog.open(AppointmentDetailComponent, {
      panelClass: "custom-dialog-container",
      disableClose: true,
      height: "680px",
      width: "1230px",
      maxWidth: '100vw',
      data: { apptDetails: data, title: title },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //if (result.edited) {
      //this.appointmentService.updateStatusAndNotes(this.appointments, result);
      let appointment = result.appointment;
      let status = result.status;
      let notes = result.notes;
      if (result.statusChanged) {
        if (status === "completed" || status === "no-show") {
          this.appointmentService.saveAppointmentStatus(appointment.ref, status)
            .then(() => {
              if (status === "completed")
                appointment.contact.update({ last_visited: appointment.appt_end });
              appointment.status = status;
              this.appointmentService.updateAppointments(this.appointments);
              this.snackBar.open(
                "The appointment has been marked as '" + (new TitleCasePipe().transform(status)) + "'.",
                "",
                { duration: 2000 }
              );
            });
        }
        else if (status === "cancelled" || status === "rescheduled") {
          appointment.ref.get().then((doc) => {
            let status = doc.data().status;
            console.log(status, appointment.status);
            let statusChanged = status === appointment.status ? false : true;
            if (statusChanged) {
              appointment.status = status;
              this.appointmentService.updateAppointments(this.appointments);
              this.snackBar.open(
                "The appointment has been marked as '" + (new TitleCasePipe().transform(result.status)) + "'.",
                "",
                { duration: 2000 }
              );
            }
          });
        }
      }
      if (result.notesEdited)
        this.appointmentService.saveAppointmentNotes(appointment.ref, notes)
          .then(() => {
            appointment.notes = notes;
            this.appointmentService.updateAppointments(this.appointments);
          });
    });
  }
}