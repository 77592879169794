<div class="container-fluid">
  <p>Provide an image <small>(Optional)</small></p>
  <input *ngIf="!section.image" #file_input type="file" accept="image/*" (change)="getImage($event)"
    style="cursor:pointer;">

  <div *ngIf="section.image">
    <img style="height: 100px; width: 100px;" [src]="section.image" alt="Q{{ ind }}-image" />
    <i class="material-icons" (click)="deleteFile(section.uploadedImagePath)">delete</i>
  </div>

  <div *ngIf="showProgress" class="progress">
    <div class="progress-bar progress-bar-animated" mode="determinate" [style.width]="(uploadProgress) + '%'">
      {{uploadProgress | number:'1.0-0'}}% Complete
    </div>
  </div>
</div>