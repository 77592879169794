import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-responsive-div',
  templateUrl: './responsive-div.component.html',
  styleUrls: ['./responsive-div.component.scss']
})
export class ResponsiveDivComponent implements OnInit,AfterViewInit {

  @Input() tableData;
  @Input() dt;
  header:any;
  data:any;
  lastVisited:any=[];
  createdOn:any=[];
  originalData:any =[];

  constructor() { }

  ngAfterViewInit(): void {
    console.log(this.dt)
    console.log(this.dt.filteredValue);
  }


  ngOnInit(): void {

    this.header = this.tableData.header;
    this.data = this.tableData.data;
    this.originalData = this.tableData.data;
    console.log(this.header);
    console.log(this.data);
    for(var i=0;i<this.data.length;i++)
    {
      if(this.data[i].last_visited)
        this.lastVisited.push(moment(this.data[i].last_visited).format("MMM DD, YYYY"));
      else
        this.lastVisited.push("-")

      if(this.data[i].created_on)
        this.createdOn.push(moment(this.data[i].created_on).format("MMM DD, YYYY"));
      else
        this.createdOn.push("-");
    }
    console.log(this.lastVisited);
    console.log(this.createdOn);

  }

  filterGlobal(event,mode)
  {
    if(event == null)
    {
      this.data = this.originalData;
    }
    else
    {
      this.dt.filterGlobal(event,mode);
      console.log(this.dt.filteredValue);
      console.log(this.dt.totalRecords);
      this.data = this.dt.filteredValue;
    }
  }

}
