import { NgModule, NO_ERRORS_SCHEMA, ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AvatarModule } from 'ngx-avatar';
import * as $ from 'jquery';

import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatChipsModule } from "@angular/material/chips";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatListModule } from "@angular/material/list";
import { MatDividerModule } from "@angular/material/divider";
import { MatDatepickerModule } from "@angular/material/datepicker";

import { MatExpansionModule } from "@angular/material/expansion";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatNativeDateModule } from "@angular/material/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatInputModule } from "@angular/material/input";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatBadgeModule } from "@angular/material/badge";

import { FormsModule } from "@angular/forms";
import { MatSliderModule } from "@angular/material/slider";
import { HttpModule } from "@angular/http";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { registerLocaleData } from "@angular/common";
import localeUS from "@angular/common/locales/en";
import localeUSExtra from "@angular/common/locales/extra/en";
import { Daterangepicker } from "ng2-daterangepicker";
import { CalendarModule, DateAdapter } from "angular-calendar"; //kitchen sink
import { adapterFactory } from "angular-calendar/date-adapters/date-fns"; //kitchen sink
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"; //kitchen sink
//import { MDBBootstrapModule } from "angular-bootstrap-md";
import { ReactiveFormsModule } from "@angular/forms";
// import { ChartsModule } from "ng2-charts"; //Charts.js
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { AlertComponent } from "./_directives/index";
import { NgxStarRatingModule } from 'ngx-star-rating';

import {
  TimePeriodService,
  AfService,
  FacebookService,
  NavigationService,
  AlertService,
  AuthenticationService,
  UserService,
  ApiService,
  MarketingPlanService,
  EmailReportService,
} from "./_services/index";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { WhatComponentComponent } from "./legacy/what-component/what-component.component";
import { WhoComponentComponent } from "./legacy/who-component/who-component.component";
import { WhyComponentComponent } from "./legacy/why-component/why-component.component";
import { SummaryPlanComponent } from "./legacy/summary-plan/summary-plan.component";
import { NewPlanComponent } from "./legacy/new-plan/new-plan.component";
import { AllPlansComponent } from "./legacy/all-plans/all-plans.component";
import { LoginComponent } from "./login/index";
import { RegisterComponent } from "./legacy/register/index";
import { ForgotPasswordComponent } from "./forgot-password/index";
import { ResetPasswordComponent } from "./forgot-password/index";
import { EditProfileComponent } from "./settings/index";
import { MailVerificationComponent } from "./legacy/register/index";
import { FormsComponent } from "./legacy/forms/forms.component";
import { TrafficSplitByDeviceComponent } from "./legacy/traffic-split-by-device/traffic-split-by-device.component";
//import { TrafficTrendsComponent } from './traffic-trends/traffic-trends.component';
import { SimilarSitesComponent } from "./legacy/similar-sites/similar-sites.component";
import { TypeformComponent } from "./legacy/typeform/typeform.component";
import { PlanComponent } from "./legacy/plan/plan.component";
import { BusinessBasicInfoComponent } from "./legacy/business-basic-info/business-basic-info.component";
import { CompetitorsComponent } from "./legacy/competitors/competitors.component";
//import { TrafficSourcesComponent } from './traffic-sources/traffic-sources.component';
import { TargetingComponent } from "./legacy/targeting/targeting.component";
import { ExampleComponent } from "./legacy/example/example.component";
import { MarketsComponent } from "./legacy/markets/markets.component";
import { LifecycleComponent } from "./legacy/lifecycle/lifecycle.component";
import { EmailComponent } from "./email/email.component";
import { MainComponent } from "./main/main.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { TipsComponent } from "./tips/tips.component";
import { FacebookComponent } from "./facebook/facebook.component";
import { FBCampaignComponent } from "./fb-campaign/fb-campaign.component";
import { TipsService } from "./_services/tips.service";
import { TipDetailsComponent } from "./tip-details/tip-details.component";
import { EmailCampaignComponent } from "./email-campaign/email-campaign.component";
import { SettingsComponent } from "./settings/settings.component";
import { environment } from "../environments/environment";
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireStorageModule } from 'angularfire2/storage';
// New imports to update based on AngularFire2 version 4
import { AngularFireAuthModule } from "angularfire2/auth";
import {
  AngularFirestore,
  AngularFirestoreModule,
} from "angularfire2/firestore";
import { AuthGuard } from "./auth.service";
import { EmailSignupComponent } from "./signup/signup.component";
import { SeoRankingComponent } from "./legacy/seo-ranking/seo-ranking.component";
import { AdminGuard } from "./guards/admin.guard";
import { SubscriberGuard } from "./guards/subscriber.guard";
import { AppErrorComponent } from "./legacy/app-error/app-error.component";
import { TimePeriodComponent } from "./time-period/time-period.component";
import { GoogleAnalyticsService } from "./_services/google-analytics.service";
import { AdminComponent } from "./legacy/admin/admin.component";
import { AdWordsComponent } from "./ad-words/ad-words.component";
import { AdWordsCampaignComponent } from "./ad-words-campaign/ad-words-campaign.component";
import { AdWordsService } from "./_services/ad-words.service";
import { SeoCsvService } from "./_services/seoCsv.service";
import { AccountService } from "./_services/account.service";
import { HubspotService } from "./_services/hubspot.service";
import { VerificationComponent } from "./verification/verification.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ConversionValueService } from "./_services/conversion-value.service";
import { EmailLandingComponent } from "./legacy/email-landing/email-landing.component";
import { AccountComponent } from "./account/account.component";
import { AddAccountComponent } from "./add-account/add-account.component";
import { EmbedEnquiryFormDialog, FormComponent } from "./form/form.component";
import { FormService } from "./_services/form.service";
import { FormDetailsComponent } from "./form-details/form-details.component";
import { AppointmentsComponent } from "./appointments/appointments.component";
import { AppointmentService } from "./_services/appointment.service";
import { ContactService } from "./_services/contact.service";
import { SeoService } from "./_services/seo-service.service";
import { AppointmentCalendarComponent } from "./appointments/appointment-calendar.component";

import firebase from "firebase/app";
import { ContactsComponent } from "./contacts/contacts.component";
import { TrialComponent } from "./legacy/trial/trial.component";
import { RemoveUnderscorePipe } from "./contacts/contacts.component";
// import { CurrencyMaskModule } from "ng2-currency-mask";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DialogOverviewExampleDialog } from "./appointments/appointments.component";
import { ContactsDialog } from "./contacts/contacts.component";
import { CreateContactDialog } from "./contacts/contacts.component";
import { NotesDialog } from "./contact-notes/contact-notes.component";
import { CreateMessageDialog } from "./contacts/contacts.component";
import { CreateContactMessageDialog } from "./contact-id/contact-id.component";
import { ContactFormComponent } from "./legacy/contact-form/contact-form.component";
import { CurrencyPipe } from "@angular/common";
import { DemoComponent } from "./demo/demo.component";
import { ActivitiesComponent } from "./activities/activities.component";
import { SmslogsComponent } from "./legacy/smslogs/smslogs.component";
import { ContactNotesComponent } from "./contact-notes/contact-notes.component";
import { AddNotesDialog } from "./activities/activities.component";
import { OnPageComponent } from "./onpage/onpage.component";
import { RankedKeywordsComponent } from "./ranked-keywords/ranked-keywords.component";
import { WebsitesComponent } from "./websites/websites.component";
import { WebAuditSignupComponent } from "./web-audit-signup/web-audit-signup.component";
import { AesurgGoogleRankingsComponent } from "./aesurg-google-rankings/aesurg-google-rankings.component";
import { CollectedFormsComponent } from "./legacy/collected-forms/collected-forms.component";
import { CollectedFormsService } from "./_services/collected-forms.service";
import { ScrollableDirective } from "./scrollable.directive";
import { InfiniteScrollingTrialComponent } from "./legacy/infinite-scrolling-trial/infinite-scrolling-trial.component";
import { PaginationService } from "./_services/pagination.service";
import { ImportHistoryComponent } from "./contacts/import-history/import-history.component";
import { ImportHistoryService } from "./_services/import-history.service";
import { CalenderConfigComponent } from "./calender-config/calender-config.component";
// import { ScrollDispatchModule } from "@angular/cdk/scrolling/scroll-dispatcher";
//import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
//import { AmazingTimePickerModule } from 'amazing-time-picker';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
//import { CalenderConfigComponent } from './calender-config.component';
//import { ScrollingModule } from '@angular/cdk/scrolling';
/* Calendar-config  */
import { TreeNode } from "primeng/api";
import { GMapModule } from 'primeng/gmap';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AccordionModule } from 'primeng/accordion';
import { CarouselModule } from 'primeng/carousel';
import { AppRoutingModule } from "./app-routing.module";
import { MatTreeModule } from "@angular/material/tree";
import { ConfigurationService } from "./configuration.service";
//import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { from } from "rxjs";
import { TestingComponent } from "./legacy/testing/testing.component";
import { ContactsNewComponent } from "./legacy/contacts-new/contacts-new.component";
import { FiltersComponent } from "./filters/filters.component";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { ContactIdComponent } from "./contact-id/contact-id.component";
import { AllAccountsComponent } from "./all-accounts/all-accounts.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSidenavModule } from "@angular/material/sidenav";
// import { SidebarModule } from 'ng-sidebar';
import { TargetKeywordsComponent } from "./target-keywords/target-keywords.component";
import { TargetKwResultComponent } from "./target-kw-result/target-kw-result.component";
import { TargetkwdetailsComponent } from "./targetkwdetails/targetkwdetails.component";
//import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
// import { AgmCoreModule } from "@agm/core";
import { ConfigureCalendarComponent } from "./legacy/configure-calendar/configure-calendar.component";
import { AgGridModule } from "ag-grid-angular";
import { AgGridComponent } from "./legacy/ag-grid/ag-grid.component";
import { ScheduleNewAppointmentComponent } from "./appointments/schedule-new-appointment/schedule-new-appointment.component";
import { AppointmentDetailsComponent } from "./appointments/appointment-details/appointment-details.component";
import { SideMenuComponent } from "./side-menu/side-menu.component";
// prime-ng components
import { MenuModule, Menu } from "primeng/menu";
import { MenuItem } from "primeng/api";
import { PasswordModule } from "primeng/password";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CardModule } from "primeng/card";
import { ButtonModule } from "primeng/button";
import { ContactsListComponent } from "./contacts-list/contacts-list.component";
import { TableModule } from "primeng/table";
import { TriStateCheckboxModule } from "primeng/tristatecheckbox";
import { DataViewModule } from 'primeng/dataview';
// import { FullCalendarModule } from 'primeng/fullcalendar';
import { FullCalendarModule } from '@fullcalendar/angular';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { CalendarModule as PCalendarModule } from 'primeng/calendar';
import { PanelModule } from "primeng/panel";
import { NewLoginComponent } from "./new-login/new-login.component";
import { PanelMenuModule } from "primeng/panelmenu";
import { InputMaskModule } from "primeng/inputmask";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { MessageDialogComponent } from "./contacts-list/message-dialog/message-dialog.component";
import { SidebarModule } from "primeng/sidebar";
import { PatientFormDetailsComponent } from "./patient-form-details/patient-form-details.component";
import { TabViewModule } from "primeng/tabview";
import { TooltipModule } from "primeng/tooltip";
import { SplitButtonModule } from 'primeng/splitbutton';



import { ContactDialogComponent } from "./contacts-list/contact-dialog/contact-dialog.component";
import { DialogOverview } from "./patient-form-details/patient-form-details.component";
import { SubHeaderComponent } from "./sub-header/sub-header.component";
import { ContactAppointmentDetailsComponent } from "./contact-appointment-details/contact-appointment-details.component";
import { ContactDetailsComponent } from "./contact-details/contact-details.component";
import {
  ContactAppointmentsComponent,
  AppointmentDetailsDialog,
} from "./contact-appointments/contact-appointments.component";
import { ContactMessageLogComponent } from "./contact-message-log/contact-message-log.component";
import { AppointmentNudgeComponent } from "./appointments/appointment-nudge/appointment-nudge.component";
import { ChatListComponent } from "./chat-list/chat-list.component";
import { ChatComponent, ChatDialog } from "./chat/chat.component";
import { MessageComponent, DummyMessageDialog } from "./message/message.component";
import { AppointmentStatsComponent } from "./appointment-stats/appointment-stats.component";
import { ChartModule } from "primeng/chart";
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { StatisticComponent } from './statistic/statistic.component';
import { NudgeMessageComponent } from './nudge-message/nudge-message.component';
import { AppointmentDetailComponent } from './appointment-detail/appointment-detail.component';
import { NewMessageComponent } from './new-message/new-message.component';
import { SearchConsoleComponent } from './search-console/search-console.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { TopImpressionGainerComponent } from './top-impression-gainer/top-impression-gainer.component';
import { TopImpressionLoserComponent } from './top-impression-loser/top-impression-loser.component';
import { RankingOnFirstPageComponent } from './ranking-on-first-page/ranking-on-first-page.component';
import { DroppingFromFirstPageComponent } from './dropping-from-first-page/dropping-from-first-page.component';
import { MixInputComponent } from './mix-input/mix-input.component';
// import {MatInputHarness} from '@angular/material/input/testing'
// import { CardModule} from 'primengca/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SearchConsoleStatsComponent } from './search-console-stats/search-console-stats.component';
import { AppointmentFullCalendarComponent } from './appointments/appointment-full-calendar/appointment-full-calendar.component';
import { MergeContactComponent } from './contacts-list/merge-contact/merge-contact.component';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { MergeContactsComponent } from './contacts-list/merge-contacts/merge-contacts.component';
import { OTPVerificationComponent } from './otp-verification/otp-verification.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { AllFormsComponent, DeleteDialogBox, DialogBox, PreviewDialogBox } from './enquiry-forms/all-forms/all-forms.component';
import { FormDesignerComponent, ErrorMessageOnFormSave, SaveFormDialog } from './enquiry-forms/form-designer/form-designer.component';
import { InteractiveFormComponent } from './enquiry-forms/interactive-form/interactive-form.component';
import { SamplesComponent } from './enquiry-forms/samples/samples.component';
import { FileUploadComponent } from './enquiry-forms/file-upload/file-upload.component';
import { InvteractiveNavigationComponent } from './enquiry-forms/navigation/navigation.component';
import { PreviewComponent } from './enquiry-forms/preview/preview.component';
import { QuestionsComponent } from './enquiry-forms/questions/questions.component';
import { ThankYouComponent } from './enquiry-forms/thank-you/thank-you.component';
import { WelcomeComponent } from './enquiry-forms/welcome/welcome.component';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilderService } from './_services/form-builder.service';
import { SatisfactoryFormComponent } from './satisfactory-form/satisfactory-form.component';
import { InteractiveFormService } from './_services/inveractive-form.service';
import { AccountSetupDialog } from "./signup/signup.component";
import { DataStudioReportComponent } from './data-studio-report/data-studio-report.component';
import { SignupProcessingComponent } from './signup-processing/signup-processing.component';
import { PatientFormComponent } from './contacts-list/patient-form/patient-form.component';

import { AccountProcessingComponent } from './account-processing/account-processing.component';
import { MarketingOfferCardComponent } from './marketing-offer-card/marketing-offer-card.component';
import { MarketingOfferListsComponent } from './marketing-offer-lists/marketing-offer-lists.component';
import { CreateMarketingOfferComponent, SanitizeHtmlPipe } from './create-marketing-offer/create-marketing-offer.component';
import { MarketingOffersComponent } from './marketing-offers/marketing-offers.component';
import { PercentageOrFlatDiscountDirective } from './_directives/percentage-or-flat-discount.directive';
import { EmailSmsCaptureDialogsComponent } from './contacts-list/email-sms-capture-dialogs/email-sms-capture-dialogs.component';
import { DisplayEmailSmsComponent } from './contacts-list/display-email-sms/display-email-sms.component';
import { FileUploadModule } from 'primeng/fileupload';
import { SurveyFormComponent } from './contacts-list/survey-form/survey-form.component';
import { ExportDataService } from './_services/export-data.service';
import { MaterialSideNavComponent } from './material-side-nav/material-side-nav.component';
import { CopyFormsComponent } from './enquiry-forms/copy-forms/copy-forms.component';
import { MatDrawerContainerMenuComponent } from './mat-drawer-container-menu/mat-drawer-container-menu.component';
import { OutletComponent } from './outlet/outlet.component';
import { ResponsiveDivComponent } from './responsive-div/responsive-div.component';
import { StepsModule } from 'primeng/steps';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SocialMediaCalenderComponent } from './social-media-calender/social-media-calender.component';
import { CreatePostComponent } from './social-media-calender/create-post/create-post.component';
import { DragDropQuestionComponent } from './social-media-calender/drag-drop-question/drag-drop-question.component';
import { DragDropModule } from 'primeng/dragdrop';
import { RatingModule } from 'primeng/rating';
import { ChatbotMessageComponent } from './chatbot-message/chatbot-message.component';
import { CardCarouselComponent } from './card-carousel/card-carousel.component';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { RippleModule } from 'primeng/ripple';
import { ReviewsCardComponent } from './reviews/reviews-card/reviews-card.component';
import { ChatbotListComponent } from './chatbot-list/chatbot-list.component';
import { ButtonComponent } from './button/button.component';
import { MenuComponent } from './chat-bot/menu/menu.component';
import { MessagePresenterComponent } from './chat-bot/message-presenter/message-presenter.component';
import { ChatbotSettingsComponent } from './chat-bot/chatbot-settings/chatbot-settings.component';
import { ChatbotPreviewComponent } from './chat-bot/chatbot-preview/chatbot-preview.component';
//import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
import { ColorPickerModule } from 'ngx-color-picker';
import { ChipsModule } from 'primeng/chips';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { LocalRankingsComponent } from './local-rankings/local-rankings.component';
import { LocalSearchRankingsComponent } from './local-rankings/local-search-rankings/local-search-rankings.component';
import { LocalRankAnalysisComponent } from './local-rankings/local-rank-analysis/local-rank-analysis.component';
import { LocalRanksComponent } from './local-rankings/local-ranks/local-ranks.component';
import { SetLocalrankTaskComponent } from './local-rankings/set-localrank-task/set-localrank-task.component';

import { PaginatorModule } from 'primeng/paginator';
import { ReviewsManagementComponent } from './reviews-management/reviews-management.component';
import { ReviewsDataComponent } from './reviews-management/reviews-data/reviews-data.component';
import { ReviewsOperationComponent } from './reviews-management/reviews-operation/reviews-operation.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MessagesModule } from 'primeng/messages';
import { DataChannelsComponent } from './data-channels/data-channels.component';
import { DataChannelsConfigurationComponent } from './data-channels/data-channels-configuration/data-channels-configuration.component';
import { DataChannelsInformationComponent } from './data-channels/data-channels-information/data-channels-information.component';
import { LocalRankHistoryComponent } from './local-rankings/local-rank-history/local-rank-history.component';
import { ReplyReviewDialogComponent } from "./reviews-management/reply-review-dialog/reply-review-dialog.component";
import { SendReviewInviteDialogComponent } from './reviews-management/send-review-invite-dialog/send-review-invite-dialog.component';
import { AlgoliaContactSearchComponent } from './algolia-contact-search/algolia-contact-search.component';
import { RecurringAppointmentDetailsComponent } from './recurring-appointment-details/recurring-appointment-details.component';
import { BookReccuringAppointmentComponent } from './book-reccuring-appointment/book-reccuring-appointment.component';
import { PatientFormResponsesComponent } from './patient-form-details/patient-form-responses/patient-form-responses.component';
import { SendPatientFormsComponent } from './patient-form-details/send-patient-forms/send-patient-forms.component';
import { TrackLocalRanksComponent } from './local-rankings/track-local-ranks/track-local-ranks.component';
import { EditorModule } from 'primeng/editor';
import { PatientFormConfigurationComponent } from './patient-form-details/patient-form-configuration/patient-form-configuration.component';
import { ReviewsConfigurationComponent } from './reviews-management/reviews-configuration/reviews-configuration.component';
import { ContactFormDetailsComponent } from './contact-form-details/contact-form-details.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OrganicRankingsComponent } from './organic-rankings/organic-rankings.component';
import { OrganicRankConfigurationComponent } from './organic-rankings/organic-rank-configuration/organic-rank-configuration.component';
import { OrganicRankHistoryComponent } from './organic-rankings/organic-rank-history/organic-rank-history.component';
import { OrganicRankAnalysisComponent } from './organic-rankings/organic-rank-analysis/organic-rank-analysis.component';
import { OrganicRanksComponent } from './organic-rankings/organic-ranks/organic-ranks.component';
import { PostReviewOnSocialMediaComponent } from './reviews-management/post-review-on-social-media/post-review-on-social-media.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { AddNewPostDailogComponent } from './social-media/add-new-post-dailog/add-new-post-dailog.component';
// import { DeleteTemplateComponent } from './social-media/delete-template/delete-template.component';
import { UpdatePostComponent } from './social-media/update-post/update-post.component'
import { DeleteTemplateComponent } from './social-media/delete-template/delete-template.component'
import { SocialMediaPostConfigComponent } from "./social-media/social-media-post-config/social-media-post-config.component";
import { SmsCampaignComponent } from './sms-campaign/sms-campaign.component';
import { AddNewCampaignComponent } from './sms-campaign/add-new-campaign/add-new-campaign.component';
import { ListAllCampaignsComponent } from './sms-campaign/list-all-campaigns/list-all-campaigns.component';
import { SmsCampaignConfigurationComponent } from './sms-campaign/sms-campaign-configuration/sms-campaign-configuration.component';
import { ContactFollowUpComponent } from './contact-follow-up/contact-follow-up.component';
import { NewSmsCamapignComponent } from './new-sms-camapign/new-sms-camapign.component';
import { SmsCampaignMonitorComponent } from './sms-campaign-monitor/sms-campaign-monitor.component';
import { RewardCampaignsComponent } from './reward-campaigns/reward-campaigns.component';
import { RewardDetailsComponent } from './reward-details/reward-details.component';
import { RewardsComponent } from './rewards/rewards.component';
import { RewardMessageConfigurationComponent } from './reward-campaigns/reward-message-configuration/reward-message-configuration.component';
import { EmailSmsStatusActivityComponent } from './sms-campaign/email-sms-status-activity/email-sms-status-activity.component';
import { NewSignUpComponent } from './signup/new-sign-up/new-sign-up.component';
import { SignUpLoadingComponent } from './signup/sign-up-loading/sign-up-loading.component';

/* ---------------------*/
firebase.initializeApp(environment.firebase);
// firestore.settings({ timestampsInSnapshots: true });
registerLocaleData(localeUS, "en-US", localeUSExtra);
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
  bootstrapPlugin
])

const appRoutes: Routes = [
  { path: "", component: LoginComponent },// outlet: "loggedOut" },
  { path: "landing", redirectTo: "", pathMatch: "full" },
  { path: "privacypolicy", component: PrivacyPolicyComponent },// outlet: "loggedOut" },
  {
    path: "footer",
    component: FooterComponent,
  },
  { path: "reward-campaigns", pathMatch: "full", component: RewardCampaignsComponent, canActivate: [AdminGuard, SubscriberGuard] },
  { path: "reward-details", pathMatch: "full", component: RewardDetailsComponent, canActivate: [AdminGuard, SubscriberGuard] },
  { path: "rewards", pathMatch: "full", component: RewardsComponent, canActivate: [AdminGuard, SubscriberGuard] },
  {
    path: "all-plans",
    component: AllPlansComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "new-plan/:flag",
    component: NewPlanComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "summary-plan",
    component: SummaryPlanComponent,
    canActivate: [SubscriberGuard],
  },
  { path: "register", component: RegisterComponent },
  { path: "login", component: LoginComponent },
  // { path: "login", component: NewLoginComponent },

  //data: {title: 'login'}},
  {
    path: "editProfile",
    component: EditProfileComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "mail-verification/:uuid",
    component: MailVerificationComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    // outlet: "loggedOut",
  },
  {
    path: "reset-password/:code",
    component: ResetPasswordComponent,
    //outlet: "loggedOut",
  },
  {
    path: "what",
    component: WhatComponentComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "why",
    component: WhyComponentComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "who",
    component: WhoComponentComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "type-form",
    component: TypeformComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "plan/:id/:name",
    component: PlanComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "basic-info",
    component: BusinessBasicInfoComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "competitors",
    component: CompetitorsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "targeting",
    component: TargetingComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "example",
    component: ExampleComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "targeting",
    component: TargetingComponent,
    canActivate: [SubscriberGuard],
  },
  { path: "email", component: EmailComponent, canActivate: [SubscriberGuard] },
  { path: "main", component: MainComponent, canActivate: [SubscriberGuard] },
  { path: "tips", component: TipsComponent, canActivate: [SubscriberGuard] },
  {
    path: "facebook",
    component: FacebookComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "fb-campaign/:id",
    component: FBCampaignComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "email-campaign/:id",
    component: EmailCampaignComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "quick-list-activity",
    component: SmsCampaignComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "quick-list",
    component: SmsCampaignComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "tip-details/:id",
    component: TipDetailsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "signup",
    component: NewSignUpComponent,
    // outlet: "loggedOut",
  },
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [SubscriberGuard, AdminGuard],
  },
  {
    path: "error",
    component: AppErrorComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "adwords",
    component: AdWordsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "adwords-campaign/:id",
    component: AdWordsCampaignComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "seo-ranking",
    component: SeoRankingComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "verification",
    component: VerificationComponent,
    // outlet: "loggedOut",
  },
  {
    path: "medical-marketing",
    component: EmailLandingComponent,
    canActivate: [SubscriberGuard],
  },

  {
    path: "account",
    component: AccountComponent,
    canActivate: [SubscriberGuard],
  },

  {
    path: "data-channels",
    component: DataChannelsComponent,
    canActivate: [SubscriberGuard],
  },

  {
    path: "add-account",
    component: AddAccountComponent,
    canActivate: [SubscriberGuard],
  },
  { path: "form", component: FormComponent, canActivate: [SubscriberGuard] },
  {
    path: "form-details/:id",
    component: FormDetailsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "appointments",
    component: AppointmentsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "nudgeappointments",
    component: AppointmentNudgeComponent,
  },
  //canActivate: [SubscriberGuard],
  {
    path: "appointment-stats",
    component: AppointmentStatsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "contacts",
    // component: ContactsComponent,
    component: ContactsListComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "on-page",
    component: OnPageComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "ranked-keywords",
    component: RankedKeywordsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "websites",
    component: WebsitesComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "aesurg-google-rankings",
    component: WebAuditSignupComponent,
    canActivate: [SubscriberGuard],
  },
  { path: "trial", component: TrialComponent, canActivate: [SubscriberGuard] },
  {
    path: "aesthetic-medicine",
    component: AesurgGoogleRankingsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "collected-forms",
    component: CollectedFormsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "infinite-scrolling",
    component: InfiniteScrollingTrialComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "import-history",
    component: ImportHistoryComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "calendar-config",
    component: CalenderConfigComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "testing",
    component: TestingComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "filters",
    component: FiltersComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "targetKwResult",
    component: TargetKwResultComponent,
    canActivate: [AdminGuard],
  },
  //{ path: 'contact-form', component: ContactFormComponent},
  //{ path: 'demo', component: DemoComponent},
  //{ path:'activities', component : ActivitiesComponent },
  //{ path:'sms-logs', component: SmslogsComponent},
  //{ path: 'contact-notes', component : ContactNotesComponent}

  {
    path: "testing",
    component: TestingComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "contacts-new",
    component: ContactsNewComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "contacts/:uid",
    // component: ContactIdComponent,
    component: ContactAppointmentDetailsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "contacts/:uid/:tab",
    component: ContactAppointmentDetailsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "allAccounts",
    component: AllAccountsComponent,
    canActivate: [SubscriberGuard],
  },

  {
    path: "target-keywords",
    component: TargetKeywordsComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "target-keywords-Details",
    component: TargetkwdetailsComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "configure-calendar",
    component: ConfigureCalendarComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "agGrid",
    component: AgGridComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "patient-intake-forms",
    component: PatientFormDetailsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "message",
    component: MessageComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "chatbot-message",
    component: ChatbotMessageComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "chatbot-settings",
    component: ChatbotSettingsComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "search-console",
    component: SearchConsoleComponent,
    canActivate: [SubscriberGuard],
  },
  {
    path: "mix-input",
    component: MixInputComponent,
    canActivate: [SubscriberGuard],
  },
  // {
  //   path: "otp-verification",
  //   component: OTPVerificationComponent,
  //   // canActivate: [SubscriberGuard],
  // },
  { path: 'interactive-form/:accountSlug/:formSlug', component: InteractiveFormComponent },
  { path: 'interactive-form/:accountSlug/:formSlug/:responseId', component: InteractiveFormComponent },
  { path: 'form-design/:accountId/:formId', component: FormDesignerComponent, canActivate: [SubscriberGuard] },
  { path: 'all-forms', component: AllFormsComponent, canActivate: [SubscriberGuard] },
  { path: 'samples', component: SamplesComponent },
  //{ path:'contact-id/:uid', component:ContactIdComponent }
  { path: "appointment-nudge", component: AppointmentNudgeComponent },
  { path: "data-studio-report", component: DataStudioReportComponent },
  { path: "enquiry-forms", component: FormComponent, canActivate: [SubscriberGuard] },
  { path: "enquiry-forms/:tab", component: FormComponent, canActivate: [SubscriberGuard] },
  { path: "survey-forms", component: SatisfactoryFormComponent, canActivate: [SubscriberGuard] },
  { path: "survey-forms/:tab", component: SatisfactoryFormComponent, canActivate: [SubscriberGuard] },
  {
    path: "patient-intake-forms", component: PatientFormDetailsComponent, canActivate: [SubscriberGuard],
  },
  {
    path: "marketing-offers",
    component: MarketingOffersComponent,// MarketingOfferListsComponent,
    canActivate: [SubscriberGuard]
  },
  {
    path: "create-offer",
    component: CreateMarketingOfferComponent,
    canActivate: [SubscriberGuard]
  },
  {
    path: "res",
    component: ResponsiveDivComponent,
    canActivate: [SubscriberGuard]
  },
  {
    path: "social-media-calender",
    component: DragDropQuestionComponent,
    canActivate: [SubscriberGuard]
  },
  // {
  //   path: "reviews",
  //   component: ReviewsComponent,
  //   canActivate: [SubscriberGuard]
  // },
  {
    path: "reviews",
    component: ReviewsManagementComponent,
    canActivate: [SubscriberGuard]
  },
  {
    path: "local-rank",
    component: LocalRankingsComponent,
    canActivate: [SubscriberGuard]
  },
  {
    path: "organic-rank",
    component: OrganicRankingsComponent,
    canActivate: [SubscriberGuard]
  },
  {
    path: "posts",
    component: SocialMediaComponent,
    canActivate: [SubscriberGuard]
  },

  {
    path: "template-parsing",
    component: SocialMediaPostConfigComponent,
    canActivate: [SubscriberGuard]
  },

  {
    path: "campaigns",
    component: SmsCampaignMonitorComponent,
    canActivate: [SubscriberGuard]
  },
  {
    path: "activity",
    component: SmsCampaignMonitorComponent,
    canActivate: [SubscriberGuard]
  },

];

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    HeaderComponent,
    FooterComponent,
    AlertComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    EditProfileComponent,
    MailVerificationComponent,
    WhatComponentComponent,
    WhyComponentComponent,
    WhoComponentComponent,
    NewPlanComponent,
    AllPlansComponent,
    SummaryPlanComponent,
    FormsComponent,
    TrafficSplitByDeviceComponent,
    SimilarSitesComponent,
    TypeformComponent,
    PlanComponent,
    BusinessBasicInfoComponent,
    CompetitorsComponent,
    TargetingComponent,
    ExampleComponent,
    MarketsComponent,
    LifecycleComponent,
    EmailComponent,
    MainComponent,
    NavigationComponent,
    TipsComponent,
    FacebookComponent,
    FBCampaignComponent,
    TipDetailsComponent,
    EmailCampaignComponent,
    SettingsComponent,
    EmailSignupComponent,
    AppErrorComponent,
    TimePeriodComponent,
    AdminComponent,
    AdWordsComponent,
    AdWordsCampaignComponent,
    SeoRankingComponent,
    VerificationComponent,
    PrivacyPolicyComponent,
    EmailLandingComponent,
    AccountComponent,
    AddAccountComponent,
    FormComponent,
    FormDetailsComponent,
    AppointmentsComponent,
    ContactsComponent,
    AppointmentCalendarComponent,
    TrialComponent,
    RemoveUnderscorePipe,
    DialogOverviewExampleDialog,
    EmbedEnquiryFormDialog,
    AppointmentDetailsDialog,
    ContactsDialog,
    ContactFormComponent,
    CreateContactDialog,
    CreateMessageDialog,
    DemoComponent,
    ActivitiesComponent,
    SmslogsComponent,
    ContactNotesComponent,
    NotesDialog,
    AddNotesDialog,
    OnPageComponent,
    RankedKeywordsComponent,
    WebsitesComponent,
    WebAuditSignupComponent,
    AesurgGoogleRankingsComponent,
    CollectedFormsComponent,
    ScrollableDirective,
    InfiniteScrollingTrialComponent,
    ImportHistoryComponent,
    CalenderConfigComponent,
    TestingComponent,
    ContactsNewComponent,
    FiltersComponent,
    ContactIdComponent,
    AllAccountsComponent,
    CreateContactMessageDialog,
    TargetKeywordsComponent,
    TargetKwResultComponent,
    TargetkwdetailsComponent,
    ConfigureCalendarComponent,
    AgGridComponent,
    ScheduleNewAppointmentComponent,
    AppointmentDetailsComponent,
    SideMenuComponent,
    NewLoginComponent,
    ContactsListComponent,
    MessageDialogComponent,
    PatientFormDetailsComponent,
    ContactDialogComponent,
    DialogOverview,
    SubHeaderComponent,
    ContactAppointmentDetailsComponent,
    ContactDetailsComponent,
    ContactAppointmentsComponent,
    ContactMessageLogComponent,
    AppointmentNudgeComponent,
    ChatListComponent,
    ChatComponent,
    MessageComponent,
    AppointmentStatsComponent,
    StatisticComponent,
    NudgeMessageComponent,
    AppointmentDetailComponent,
    NewMessageComponent,
    SearchConsoleComponent,
    TopImpressionGainerComponent,
    TopImpressionLoserComponent,
    RankingOnFirstPageComponent,
    DroppingFromFirstPageComponent,
    MixInputComponent,
    SearchConsoleStatsComponent,
    AppointmentFullCalendarComponent,
    MergeContactComponent,
    MergeContactsComponent,
    DummyMessageDialog,
    ChatDialog,
    OTPVerificationComponent,
    AllFormsComponent,
    InteractiveFormComponent,
    FormDesignerComponent,
    SamplesComponent,
    PreviewComponent,
    WelcomeComponent,
    ThankYouComponent,
    QuestionsComponent,
    InvteractiveNavigationComponent,
    FileUploadComponent,
    DialogBox,
    DeleteDialogBox,
    SatisfactoryFormComponent,
    PreviewDialogBox,
    AccountSetupDialog,
    DataStudioReportComponent,
    SignupProcessingComponent,
    PatientFormComponent,
    AccountProcessingComponent,
    MarketingOfferCardComponent,
    MarketingOfferListsComponent,
    CreateMarketingOfferComponent,
    MarketingOffersComponent,
    PercentageOrFlatDiscountDirective,
    EmailSmsCaptureDialogsComponent,
    DisplayEmailSmsComponent,
    SurveyFormComponent,
    MaterialSideNavComponent,
    CopyFormsComponent,
    SaveFormDialog,
    ErrorMessageOnFormSave,
    MatDrawerContainerMenuComponent,
    OutletComponent,
    ResponsiveDivComponent,
    SocialMediaCalenderComponent,
    CreatePostComponent,
    DragDropQuestionComponent,
    ChatbotMessageComponent,
    CardCarouselComponent,
    ChatBotComponent,
    ReviewsComponent,
    ReviewsCardComponent,
    ChatbotListComponent,
    ButtonComponent,
    MenuComponent,
    MessagePresenterComponent,
    ChatbotSettingsComponent,
    ChatbotPreviewComponent,
    LocalRankingsComponent,
    LocalSearchRankingsComponent,
    LocalRankAnalysisComponent,
    LocalRanksComponent,
    SetLocalrankTaskComponent,
    ReviewsManagementComponent,
    ReviewsDataComponent,
    ReviewsOperationComponent,
    DataChannelsComponent,
    DataChannelsConfigurationComponent,
    DataChannelsInformationComponent,
    LocalRankHistoryComponent,
    ReplyReviewDialogComponent,
    SendReviewInviteDialogComponent,
    AlgoliaContactSearchComponent,
    RecurringAppointmentDetailsComponent,
    BookReccuringAppointmentComponent,
    PatientFormResponsesComponent,
    SendPatientFormsComponent,
    TrackLocalRanksComponent,
    PatientFormConfigurationComponent,
    ReviewsConfigurationComponent,
    ContactFormDetailsComponent,
    OrganicRankingsComponent,
    OrganicRankConfigurationComponent,
    OrganicRankHistoryComponent,
    OrganicRankAnalysisComponent,
    OrganicRanksComponent,
    PostReviewOnSocialMediaComponent,
    SanitizeHtmlPipe,
    SocialMediaComponent,
    SocialMediaPostConfigComponent,
    AddNewPostDailogComponent,
    DeleteTemplateComponent,
    UpdatePostComponent,
    SmsCampaignComponent,
    AddNewCampaignComponent,
    ListAllCampaignsComponent,
    SmsCampaignConfigurationComponent,
    ContactFollowUpComponent,
    NewSmsCamapignComponent,
    SmsCampaignMonitorComponent,
    RewardCampaignsComponent,
    RewardDetailsComponent,
    RewardsComponent,
    RewardMessageConfigurationComponent,
    SmsCampaignComponent,
    AddNewCampaignComponent,
    ListAllCampaignsComponent,
    SmsCampaignConfigurationComponent,
    EmailSmsStatusActivityComponent,
    NewSignUpComponent,
    SignUpLoadingComponent,
  ],

  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, onSameUrlNavigation: "reload" } // <-- debugging purposes only
    ),
    ClipboardModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgOtpInputModule,
    GooglePlaceModule,
    PaginatorModule,
    //prime-ng components
    EditorModule,
    FullCalendarModule,
    PCalendarModule,
    MenuModule,
    PasswordModule,
    InputTextModule,
    NgxStarRatingModule,
    InputTextareaModule,
    CardModule,
    ButtonModule,
    PanelModule,
    TableModule,
    TriStateCheckboxModule,
    CheckboxModule,
    PanelMenuModule,
    InputMaskModule,
    ScrollPanelModule,
    VirtualScrollerModule,
    SidebarModule,
    TabViewModule,
    TooltipModule,
    DataViewModule,
    ChartModule,
    AvatarModule,
    RippleModule,
    //angular material components
    MatSliderModule,
    // ChartsModule,
    //AngularFireModule.initializeApp(environment.firebase, environment.firebaseloginconfig),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatStepperModule,
    MatProgressBarModule,
    FileUploadModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatDividerModule,
    MatListModule,
    MatTooltipModule,
    SplitButtonModule,
    MatChipsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatBadgeModule,
    FormsModule,
    ChipsModule,
    HttpModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    Daterangepicker,
    // CurrencyMaskModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    DialogModule,
    GMapModule,
    ProgressBarModule,
    AccordionModule,
    ProgressSpinnerModule,
    NgxMaterialTimepickerModule,
    //AmazingTimePickerModule ,
    DropdownModule,
    NgJsonEditorModule,
    ColorPickerModule,
    NgbModule,
    MatDialogModule,
    MatSidenavModule,
    InfiniteScrollModule,
    //SidebarModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    //MDBBootstrapModule.forRoot(),
    AgGridModule.withComponents([]),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AppRoutingModule,
    //MatGoogleMapsAutocompleteModule.forRoot(),
    // AgmCoreModule.forRoot({
    //   apiKey: "AIzaSyCbt-zTTLDa2xPzb6e3anQXLI5FhkBVz80",
    //   language: "en",
    //   libraries: ["places"],
    // }),
    FormsModule,
    // BsDropdownModule.forRoot(),
    // NgxIntlTelInputModule
    StepsModule,
    FlexLayoutModule,
    DragDropModule,
    RatingModule,
    CarouselModule,
    ToastModule,
    MessagesModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    AlertService,
    AuthenticationService,
    UserService,
    MarketingPlanService,
    ApiService,
    EmailReportService,
    NavigationService,
    FacebookService,
    TipsService,
    AfService,
    TimePeriodService,
    AdminGuard,
    SubscriberGuard,
    GoogleAnalyticsService,
    AdWordsService,
    SeoCsvService,
    AccountService,
    HubspotService,
    ConversionValueService,
    AppointmentService,
    ContactService,
    CurrencyPipe,
    CollectedFormsService,
    PaginationService,
    ImportHistoryService,
    // ScrollDispatchModule,
    FormService,
    FormBuilderService,
    CookieService,
    AccountService,
    HubspotService,
    InteractiveFormService,
    DialogService,
    ExportDataService,
  ],
  entryComponents: [
    // DropdownModule,
    PreviewDialogBox,
    DialogOverviewExampleDialog,
    ScheduleNewAppointmentComponent,
    AppointmentDetailComponent,
    ContactsDialog,
    CreateContactDialog,
    CreateMessageDialog,
    NotesDialog,
    AddNotesDialog,
    CreateContactMessageDialog,
    TestingComponent,
    AppointmentDetailsComponent,
    MessageDialogComponent,
    ContactDialogComponent,
    DialogOverview,
    AppointmentDetailsDialog,
    EmbedEnquiryFormDialog,
    MergeContactComponent,
    MergeContactsComponent,
    PatientFormComponent,
    DummyMessageDialog,
    ChatDialog,
    DialogBox,
    DeleteDialogBox,
    AccountSetupDialog,
    AccountProcessingComponent,
    SurveyFormComponent,
    CopyFormsComponent,
    SaveFormDialog,
    ErrorMessageOnFormSave,
    SetLocalrankTaskComponent,
    ReplyReviewDialogComponent,
    RecurringAppointmentDetailsComponent,
    BookReccuringAppointmentComponent,
  ],
  bootstrap: [AppComponent],
  exports: [AppointmentCalendarComponent],
})
export class AppModule { }
