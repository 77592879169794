import { Component, OnInit } from '@angular/core';
import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";
import {FormControl, Validators} from '@angular/forms';


@Component({
  selector: 'app-mix-input',
  templateUrl: './mix-input.component.html',
  styleUrls: ['./mix-input.component.scss']
})
export class MixInputComponent implements OnInit {

  emailFormControl = new FormControl();

  constructor(
    private navigationService: NavigationService,
  ) { }

  givenNumberInput :string = "+91-";
  givenEmailInput :string="";
  lebel:string = "Enter your mobile number or email";
  // placeholder:string = "Enter the Valid Email/Phone";
  isNumber :boolean = false;
  isEmail : boolean = false;
  renderInput :string = "";
  isValidEmail :boolean = false;
  isValidNumber:boolean = false;
  
  checkString :string="";
  color:string ="black";
  sendStyle:string ="";
  matPrefix:string = "";

  customeValidator:any;
  maxlength :number;



    ngOnInit() {

      this.navigationService.updateState(
        new NavigationState(false, "Mix Input", "", "#", "/mix-input")
      );
    }

    onKey(event)
    {
      if ((event.keyCode >= 64 && event.keyCode <= 90) || (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 46 || event.key=="Backspace")  
      {

              console.log(this.renderInput.length);

              if(this.renderInput.length==0)
              {
                  console.log("Wel");
                  this.lebel = "Enter your mobile number or email";
              }
              
              if(this.allnumeric(this.renderInput))
              {
                  this.isEmail = false;
                  this.isNumber = true;
                  this.lebel = "Mobile";
                  // if(this.renderInput.length!=10)
                  // {
                  //       this.isValidNumber = true;
                  // }
                  // console.log(this.renderInput);
                  this.maxlength = 10;
                  // this.emailFormControl.setValidators(Validators.pattern('^[0-9]*'));
                  this.emailFormControl.setValidators(Validators.compose(
                    [Validators.pattern('^[0-9]*'),Validators.minLength(10), Validators.maxLength(11), Validators.required]));
              }
              else
              {
                  this.isEmail = true;
                  this.isNumber = false;
                  
                  this.maxlength =100;
                  this.emailFormControl.setValidators(Validators.email);
                  if(this.renderInput.length==0)
                  {
                      console.log("Wel");
                      this.lebel = "Enter your mobile number or email";
                  }
                  else
                  {
                    this.lebel = "Email";
                  }
              }

         }
    }

  // onKey(event)
  // {

  //   let index = (event.key).charCodeAt(0);
     
  //   if((index>=48 && index<=57) || (index>=64 && index<=90) || (index>=97 && index<=122) || (index==46))
  //   {
  //       if(event.key!= "Backspace" && event.key!="Enter")
  //       {
  //           this.checkString = this.checkString + event.key;
  //       }
  //       if(parseInt(event.key) && this.allnumeric(this.checkString))
  //       {
  //         console.log("number");
  //           this.isNumber = true;
  //           this.isEmail = false;
  //           this.placeholder = "Number";
  //           this.label = "PhoneNumber";
  //           this.givenNumberInput = this.renderInput;
  //           this.givenNumberInput = this.givenNumberInput + event.key; 
  //           this.renderInput = this.givenNumberInput;
            
  //           if(this.givenNumberInput.length<10 || this.givenNumberInput.length>10)
  //           {
  //               this.color ="red";
  //           }
  //           if(this.givenNumberInput.length == 10)
  //           {
  //             this.color ="black";
  //           }
  //       }

        

  //       if(event.key!= "Backspace" && event.key!="Enter" && event.key!="shift" && !(parseInt(event.key)))
  //       {
  //         console.log("email");
  //         this.isNumber = false;
  //         this.isEmail = true;
  //         this.placeholder = "Email";
  //         this.label = "Email";
  //         this.givenEmailInput = this.renderInput;
  //         this.givenEmailInput = this.givenEmailInput + event.key;
  //         this.renderInput = this.givenEmailInput;
  //         let check = this.validateEmail(this.givenEmailInput);
  //         if(check)
  //         {
  //           this.color = "black";
  //         }
  //         else
  //         {
  //           this.color = "red";
  //         }

  //       }


  //       if(event.key == 'Backspace')
  //       {
  //         this.renderInput = this.renderInput.slice(0, -1);
  //         this.checkString = this.checkString.slice(0,-1);
  //         if(this.renderInput.length == 0)
  //         {
  //               this.givenEmailInput = "";
  //               this.givenNumberInput="";
  //               this.checkString= "";
  //               this.renderInput = "";
  //         }

  //         if(this.allnumeric(this.renderInput))
  //         {
  //           if(this.renderInput.length<10 || this.renderInput.length>10)
  //           {
  //               this.color ="red";
  //           }
  //           if(this.renderInput.length == 9)
  //           {
  //             this.color ="black";
  //           }
  //         }
  //         if(this.validateEmail(this.renderInput))
  //         {
  //             this.color="black";
  //         }
  //         else
  //         {
  //             this.color = "red";
  //         }
  //       }
  //   }
  // }

  Email(mail)
  {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true);
    }
    else
    {
      return (false);
    }
   
  }

  validateEmail(email) 
  {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  allnumeric(inputtxt)
   {
      var numbers = /^[0-9]+$/;
      if(inputtxt.match(numbers))
      {
        return true;
      }
      else
      {
        return false;
      }
   } 

}
