import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-carousel',
  templateUrl: './card-carousel.component.html',
  styleUrls: ['./card-carousel.component.scss']
})
export class CardCarouselComponent implements OnInit {
  @Input() payload: any;
  responsiveOptions: any;
  messages: any;
  show = false;

  constructor() {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit() {
    console.log(this.payload);
    this.messages = this.payload.data;
    console.log(this.messages);
  }
  click(item: any) {
    let data = {
      eventType: 'REDIRECT',
      url: item.url
    };
    console.log(data);
    window.parent.postMessage(JSON.stringify(data), "*");
  }
}
