<div class="container-fluid view">


  <div class="row">
    <div class="col-sm-12">
      <h5 class="text-center" style="font-weight: 400;">Ranked keywords</h5>
    </div>
    <div class="col-sm-12">
      <p>Using this method, you can get keywords for which a website is ranked. Also, you will get rankings and
        significant additional data for this keyword in the SERP(Search Engine Results Page).</p>
    </div>
  </div>

  <div *ngIf="showGetResults; else showResults">
    <center>
      <button mat-raised-button class="text-center" (click)="getUrl()">Get Results</button>
    </center>
  </div>


  <!--<div class="row">
    <div class="col-sm-12 text-center">
      <mat-form-field style="width : 60%;">
      <input matInput name="webUrl" [(ngModel)]="webUrl" placeholder="Enter your website url here..." required />

      <mat-icon matPrefix>public</mat-icon>
    </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 text-center">
      <button mat-raised-button  (click)="getUrl()" [disabled]="!webUrl">Get ranked keywords</button>
      </div>
  </div>-->
  <ng-template #showResults>
    <center>
      <div *ngIf="isLoading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <p styles="text-align : center;">Loading...</p>
      </div>
    </center>


    <div class="scrolling-wrapper-flexbox" *ngIf="showMetricTable">
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi"> {{ metricArray.organic_count}} </div>
          <div class="metric-caption kpi"> Organic count</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi" *ngIf="topResultCount;else zero_count"> {{ topResultCount }} </div>
          <ng-template #zero_count>
            <div class="metric kpi">0</div>
          </ng-template>
          <div class="metric-caption kpi"> Result count</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi"> {{ metricArray.etv }} </div>
          <div class="metric-caption kpi">Estimated Traffic Value(etv)</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi"> {{metricArray.pos1}} </div>
          <div class="metric-caption kpi"> Position 1</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi"> {{metricArray.pos2_3}} </div>
          <div class="metric-caption kpi"> Position 2_3</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi"> {{metricArray.pos4_10}} </div>
          <div class="metric-caption kpi"> Position 4_10</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi"> {{metricArray.pos11_20}} </div>
          <div class="metric-caption kpi"> Position 11_20</div>
        </mat-card-content>
      </mat-card>
      <!--<table mat-table [dataSource]="dataSourceForMetric" class="mat-elevation-z8">
    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef class="text-center">Actual metrics</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.key | removeUnderscore | titlecase}} </td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Values </th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.value}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
  </table>-->
    </div>



    <!---Ranked keywords---->
    <div *ngIf="showMetricTable">
      <div *ngIf=" keywords!== 'No data' ">
        <mat-form-field style="width : 100%;">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>

        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSourceForKeywords" multiTemplateDataRows matSort>
            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column | removeUnderscore | titlecase}} </th>
              <td mat-cell *matCellDef="let keyword">{{keyword[column]}}
                <span *ngIf="column==='position'">
                  <mat-icon class="decrArrow" *ngIf="keyword['rankDiff']==='decr'">arrow_downward</mat-icon>
                  <mat-icon class="incrArrow" *ngIf="keyword['rankDiff']==='incr'">arrow_upward</mat-icon>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let keyword" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail"
                  [@detailExpand]="keyword == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="example-element-description">
                    <p class="heading"><strong>{{keyword.title}}</strong></p>
                    <!--<p class="url">{{keyword.url}}</p>-->
                    <a class="url" [href]="keyword.url" target="_blank">{{keyword.url}}</a>
                    <p class="snippet">{{keyword.snippet}}</p>
                    <!--<p>Extra : {{keyword.extra}}</p>-->
                  </div>

                </div>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let keyword; columns: columnsToDisplay;" class="example-element-row"
              [class.example-expanded-row]="expandedElement === keyword"
              (click)="expandedElement = expandedElement === keyword ? null : keyword">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
        </div>
      </div>
      <div *ngIf="keywords == 'No data' ">
        <p style="font-family:roboto;" class="text-center">{{noRankedData}}</p>
      </div>
    </div>


    <!--<div *ngIf="showWorking"><p class="text-center">We are working on getting your results.</p></div>-->
    <div *ngIf="showError">
      <p style="font-family:roboto;" class="text-center">{{errorMessage}}</p>
    </div>


  </ng-template>
</div>