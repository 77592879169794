<mat-card class="card">
    <mat-card-header style="width: 100%;">
        <div class="row" style="width: 100%;">
            <div class="col-4">
            </div>
            <div class="col-4">
                <div style="text-align: center;padding-top: 10px;color: white;font-size: 20px;">Review Configuration
                </div>
            </div>
            <div class="col-4">
                <div style="text-align: right;padding-top: 10px;padding-right: 0px;">
                    <i class="pi pi-times" style="font-size: 1rem;cursor: pointer;font-size: 20px;" mat-dialog-close
                        (click)="close()"></i>
                </div>
            </div>
        </div>
    </mat-card-header>
    <mat-card-content>
        <form #form="ngForm" style="height: 100%;width: 100%;">
            <div class="row" style="margin: 5px;" *ngIf="!loadingForConfiguration">
                <div class="col-md-6">
                    <div class="row" style="margin: 2px;">
                        <span style="padding: 5px 0px 5px 0px;;font-size: 1rem;">Email Subject</span>
                        <input type="text" pInputText placeholder="Enter email subject" [(ngModel)]="emailSubject"
                            name="emailSubject" required style="width: 100%;">
                    </div>
                    <div class="row" style="margin: 2px;">
                        <span style="padding: 5px 0px 5px 0px;;font-size: 1rem;">Email Body</span>
                        <p-editor [(ngModel)]="emailBody" (onTextChange)="onTextChange($event)" name="emailBody"
                            placeholder="Enter email body" [style]="{'height':'235px'}"></p-editor>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row" style="margin: 2px;">
                        <span style="padding: 5px 0px 5px 0px;font-size: 1rem;">SMS Body</span>
                        <textarea rows="15" cols="30" pInputTextarea placeholder="Enter sms body" style="width: 100%;"
                            autoResize="autoResize" [(ngModel)]="smsBody" name="smsBody" class="inputText"
                            required></textarea>
                        <!-- <p-editor [(ngModel)]="smsBody" name="smsBody" (onTextChange)="onTextChangeOfSms($event)"
                            placeholder="Enter sms body" [style]="{'height':'235px'}"></p-editor> -->
                    </div>
                </div>
            </div>
            <div *ngIf="loadingForConfiguration" style="margin-top: 20px;">
                <div style="text-align: center">
                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
                </div>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions *ngIf="!confirmation">
        <div style="text-align: right;margin:9px 12px;" *ngIf="!loadingForConfiguration">
            <button mat-raised-button color="primary" (click)="Save()"
                [disabled]="form.invalid || disabledBtn">Save</button>
        </div>
    </mat-card-actions>
    <mat-card-actions *ngIf="confirmation">
        <div style="text-align: center;" *ngIf="loading">
            <b>{{displayMsg}}</b>
        </div>
        <div style="text-align: center;" *ngIf="!loading">
            <b>{{displayMsg}}</b>
        </div>
    </mat-card-actions>
</mat-card>