import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { event } from 'jquery';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COUNTRY_CODES } from "../../_constants/country_phone_codes";
import { DialogData } from '../contacts-list.component';
import { ContactService } from "../../_services/contact.service";
import {TooltipModule} from 'primeng/tooltip';

@Component({
  selector: 'app-display-email-sms',
  templateUrl: './display-email-sms.component.html',
  styleUrls: ['./display-email-sms.component.scss']
})
export class DisplayEmailSmsComponent implements OnInit {

  @Input() data: any;
  @Output() newItemEvent = new EventEmitter<any>();
  @Input() goLast: boolean = false;
  contact: any;
  forms: any = [];
  showConfirm: boolean = true;
  loading: boolean = false;
  loaded: boolean = false;
  goPrevious: boolean = false;
  emailColor :string ="red";
  smsColor :string = "green";
  smsTooltip:any="Valid SMS";
  emailTooltip:any="Valid Email";


  constructor() { }

  ngOnInit() {

    this.contact = this.data.contact;
    console.log(this.contact);
    let forms = this.data.formNames;
    for (let i = 0; i < forms.length; i++) {
      this.forms.push({ sr_no: i + 1, name: forms[i] });
    }
    console.log()
    console.log('forms:', this.forms);
    console.log("dialogInfo:", this.data);
  }

  save() {
    this.showConfirm = false;
    this.newItemEvent.emit('success');
    this.goLast = true;
    window.scrollTo(0,document.body.scrollHeight);
  }

  previous() {
    this.goPrevious = true;
  }

  validateEmail(email) 
  {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  allnumeric(phone)
   {
    let isnum = /^\d+$/.test(phone);
    if(isnum)
      return true;
    else 
      return false;
   } 

}
