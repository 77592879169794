<!-- <mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()"
  (mouseleave)="mouseleave()">
  <mat-nav-list>
    <mat-list-item class="parent" [ngStyle]="{'pointer-events':'none','color':'#999999'}">
      <span class="full-width" *ngIf="isExpanded || isShowing">Overview</span>
      <mat-icon mat-list-icon [ngStyle]="{'color':'#999999'}">home</mat-icon>
    </mat-list-item>
    <mat-list-item class="parent" [ngStyle]="{'pointer-events':'none','color':'#999999'}">
      <span class="full-width" *ngIf="isExpanded || isShowing">Recommendations</span>
      <mat-icon mat-list-icon [ngStyle]="{'color':'#999999'}">info</mat-icon>
    </mat-list-item>
    <mat-list-item class="parent" [routerLink]="['contacts']">
      <span class="full-width" *ngIf="isExpanded || isShowing">Contacts</span>
      <mat-icon mat-list-icon>person</mat-icon>
    </mat-list-item>
    <mat-list-item class="parent" [routerLink]="['appointments']">
      <span class="full-width" *ngIf="isExpanded || isShowing">Appointments</span>
      <mat-icon mat-list-icon>date_range</mat-icon>
    </mat-list-item>
    <mat-list-item class="parent" [routerLink]="['message']">
      <span class="full-width" *ngIf="isExpanded || isShowing">Messages</span>
      <mat-icon mat-list-icon>message</mat-icon>
    </mat-list-item>
    <mat-list-item class="parent" [routerLink]="['enquiry-forms']">
      <span class="full-width" *ngIf="isExpanded || isShowing">Enquiry Forms</span>
      <mat-icon mat-list-icon>folder</mat-icon>
    </mat-list-item>
    <mat-list-item class="parent" [routerLink]="['patient-intake-forms']">
      <span class="full-width" *ngIf="isExpanded || isShowing">Patient Forms</span>
      <mat-icon mat-list-icon>folder_shared</mat-icon>
    </mat-list-item>
    <mat-list-item class="parent" [routerLink]="['survey-forms']">
      <span class="full-width" *ngIf="isExpanded || isShowing">Survey Forms</span>
      <mat-icon mat-list-icon>rule_folder</mat-icon>
    </mat-list-item>
    <mat-list-item class="parent" (click)="showSubmenu =!showSubmenu">
      <span class="full-width" *ngIf="isExpanded || isShowing">Websites</span>
      <mat-icon mat-list-icon>web</mat-icon>
      <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}" *ngIf="isExpanded || isShowing">expand_more
      </mat-icon>
    </mat-list-item>
    <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="isShowing || isExpanded">
      <mat-list-item [ngStyle]="{'pointer-events':'none','color':'#999999'}">
        <span class="full-width" *ngIf="isExpanded || isShowing">Visitor and Traffic</span>
        <mat-icon mat-list-icon [ngStyle]="{'color':'#999999'}">traffic</mat-icon>
      </mat-list-item>
      <mat-list-item [routerLink]="['websites']">
        <span class="full-width" *ngIf="isExpanded || isShowing">SEO Audit</span>
        <mat-icon mat-list-icon>shop</mat-icon>
      </mat-list-item>
      <mat-list-item [routerLink]="['websites']">
        <span class="full-width" *ngIf="isExpanded || isShowing">Search Ranking</span>
        <mat-icon mat-list-icon>autorenew</mat-icon>
      </mat-list-item>
      <mat-list-item [ngStyle]="{'pointer-events':'none','color':'#999999'}">
        <span class="full-width" *ngIf="isExpanded || isShowing">Top Pages</span>
        <mat-icon mat-list-icon [ngStyle]="{'color':'#999999'}">arrow_upward</mat-icon>
      </mat-list-item>
      <mat-list-item [ngStyle]="{'pointer-events':'none','color':'#999999'}">
        <span class="full-width" *ngIf="isExpanded || isShowing">Content Updates</span>
        <mat-icon mat-list-icon [ngStyle]="{'color':'#999999'}">check</mat-icon>
      </mat-list-item>
    </div>
  </mat-nav-list>
</mat-sidenav>-->

<mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()"
  (mouseleave)="mouseleave()">
  <mat-nav-list>
    <ng-container *ngFor="let menuItem of menuList; index as i">

      <!-- <mat-list-item *ngIf="menuItem.admin?user.roles.admin:true" [routerLink]="menuItem.routerLink" -->
      <mat-list-item *ngIf="menuItem.display!=undefined?menuItem.display:true" [routerLink]="menuItem.routerLink"
        routerLinkActive="active-list-item" class="parent" [ngClass]="{'disabledItem':menuItem.disabled}"
        (click)="menuItem.isExpanded = !menuItem.isExpanded;">
        <mat-icon mat-list-icon [ngClass]="{'disabledItem':menuItem.disabled}">{{menuItem.icon}}</mat-icon>
        <span class="full-width" *ngIf="isExpanded || isShowing">{{menuItem.label}} </span>
        <i *ngIf="isExpanded && menuItem.contactUs" class='fas fa-crown' style='font-size: 15px;color:#fdcc0d;right: 0;
        position: absolute;'></i>
        <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
          *ngIf="!menuItem.isExpanded && isExpanded &&menuItem.submenu!=undefined">expand_more</mat-icon>
        <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
          *ngIf="menuItem.isExpanded && isExpanded && menuItem.submenu!=undefined">expand_less</mat-icon>
      </mat-list-item>

      <div class="submenu" *ngFor="let subItem of menuItem.submenu"
        [ngStyle]="{'padding-left':isExpanded  ? '35px' : '16px' }" [ngClass]="{'expanded' : menuItem.isExpanded}">
        <mat-list>

          <mat-list-item style="height: 30px" *ngIf="subItem.display!=undefined?subItem.display:true"
            [routerLink]="subItem.routerLink" routerLinkActive="active-list-item"
            [ngClass]="{'disabledItem':subItem.disabled}" (click)="subItem.isExpanded = !subItem.isExpanded;">
            <mat-icon style="float: right;" mat-list-icon [ngClass]="{'disabledItem':subItem.disabled}">
              {{subItem.icon}}
            </mat-icon>
            <span class="full-width submenuItem" *ngIf="isExpanded || isShowing">{{ subItem.label }} </span><i
              matToolTip="Contact-us" *ngIf="isExpanded && subItem.contactUs" class='fas fa-crown'
              style='font-size: 15px;color:#fdcc0d'></i>

            <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
              *ngIf="subItem.isExpanded && (isExpanded || isShowing) &&subItem.submenu!=undefined">
              expand_less</mat-icon>
            <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
              *ngIf="!subItem.isExpanded && (isExpanded || isShowing) &&subItem.submenu!=undefined">expand_more
            </mat-icon>
          </mat-list-item>

          <div class="submenu" *ngFor="let item of subItem.submenu" [ngClass]="{'expanded' : subItem.isExpanded}">
            <mat-list-item style="height: 30px" *ngIf="item.display!=undefined?item.display:true"
              [routerLink]="item.routerLink" routerLinkActive="active-list-item"
              [ngClass]="{'disabledItem':item.disabled}">
              <span class="full-width submenuItem" *ngIf="isExpanded || isShowing">{{ item.label }}</span>
              <mat-icon style="float: right;" mat-list-icon [ngClass]="{'disabledItem':item.disabled}">
                {{item.icon}}
              </mat-icon>
            </mat-list-item>
          </div>
        </mat-list>
      </div>
      <!-- </div> -->

    </ng-container>
  </mat-nav-list>
</mat-sidenav>