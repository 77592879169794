import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilderService } from '../../_services/form-builder.service';
import { AfService } from '../../_services/providers/af.service';
import { AlertService } from '../../_services/alert.service';
import { AccountService } from '../../_services/account.service';
import { User } from '../../_services/providers/user';

@Component({
  selector: 'interactive-app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class InvteractiveNavigationComponent implements OnInit {
  user: User;
  accounts: any[] = [];
  isLoggedIn: boolean;
  selectedAccount: string;
  selectedInput: string;
  printedInput: string;
  show: boolean = false;
  @Input() showIcons: boolean;
  @Input() state: any;


  constructor(
    private router: Router,
    private formBuilderService: FormBuilderService,
    public afService: AfService,
    private accountService: AccountService
  ) { }

  accChange(acc: string) {
    this.accountService.updateAcc(acc);
  }

  logout() {
    this.afService.logout();
    this.isLoggedIn = false;
    this.router.navigate(['/']);
  }


  ngOnInit() {

    this.afService.user$.subscribe((user: any) => {
      this.user = user;
      if (user) {
        this.accounts = this.accountService.getAccNames();
        if (sessionStorage.getItem('current_acc_id')) {
          this.selectedAccount = sessionStorage.getItem('current_acc_id');
        }
        else {
          this.selectedAccount = user.accounts[0];
        }
      }
    });
  }
}
