<h5 style="text-align:center;padding:1%;margin:1%;color:red;" *ngIf="twilio_credentials==undefined">Data shown in
  contact
  List and messages of selected contacts is dummy data.
</h5>

<div class="row" style="height:90vh;overflow-y: hidden;">
  <div class="col-md-4">
    <div id="pageTitle">Messages</div>
    <div class="bg-gray px-4 py-2 bg-light">
      <div class="searchContainer">
        <i class="fa fa-search searchIcon"></i>
        <input class="searchBox" type="text" name="search" placeholder="Search..." [(ngModel)]="searchText"
          class="form-control search" (ngModelChange)="fetchContactByPagingAlgolia()">
        <i *ngIf="searchText?.length > 0" class="fa fa-close searchIcon" (click)="clear()"></i>
      </div>
    </div>

    <div *ngIf="searchText?.length == 0">
      <app-chat-list [contactlist]="contactlist" [hasMoreRecords]="hasMoreRecords"
        (getMessagesOfSelctedContact)="getMessagesOfSelctedContact($event)" (newContacts)="fetchNextContacts()">
      </app-chat-list>
    </div>

    <div *ngIf="searchText?.length > 0">
      <app-new-message (contactPhone)="getMessagesOfSelctedContact($event)" [algoliaContactList]="algoliaContactList">
      </app-new-message>
    </div>
  </div>

  <div class="col-md-8">
    <div *ngIf="!isReady">
      <img src="../../assets/images/new-msg.jpg" id="img">
      <h1>Please select contact to start conversation</h1>
    </div>
    <div *ngIf="isReady">
      <app-chat [messages]="messages" [name]="name" [chatMessageLoading]="chatMessageLoading"
        [twilio_credentials]="twilio_credentials" [languages]="languages" [fullPhone]="twilio_to[0]"
        (language)="selectLanguage($event)" (newMessage)="newMessage($event)">
      </app-chat>
    </div>
  </div>
</div>