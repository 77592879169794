import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Input } from '@angular/core';
import * as moment from 'moment';
import { ReviewsService } from '../../_services/reviews.service';
import { MatDialog } from '@angular/material/dialog';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { ReplyReviewDialogComponent } from '../reply-review-dialog/reply-review-dialog.component';
import { PostReviewOnSocialMediaComponent } from '../post-review-on-social-media/post-review-on-social-media.component'
import { SplitButtonModule } from 'primeng/splitbutton';


@Component({
  selector: 'app-reviews-data',
  templateUrl: './reviews-data.component.html',
  styleUrls: ['./reviews-data.component.scss'],
})

export class ReviewsDataComponent implements OnInit {
  throttle = 300;
  scrollDistance = 5;
  scrollUpDistance = 2;
  // nisVersion = nisPackage.dependencies["ngx-infinite-scroll"];
  @Input() reviews: any;
  @Input() fetchReview: boolean;
  @Input() isAccountExists: boolean;
  @Input() popupLoder: boolean;
  @Output() nextDataEmitter = new EventEmitter<any>();
  @ViewChild('btn') splitButton: SplitButtonModule;

  //isAccountExists: boolean = false;
  show: boolean = false;
  content: any;
  scroll: number = 0;
  moment: any = moment;
  popupHeader: string = '';
  popupPlaceholder: string = '';
  snakbarMessage: string = '';
  replyByOwner: string;
  updateReplyData: any = {}
  btnDisable: boolean = false;
  operation: string = '';
  items: MenuItem[];
  isPost: boolean;
  currentDocRef: any;
  selectedIndex: number = 0;
  labelForSocialMediaPost: string = "Download Post"

  constructor(
    public dialog: MatDialog
  ) {
  }
  ngOnInit(): void {
    console.log(this.reviews);
    this.items = [
      {
        label: 'Mark as a posted', icon: 'pi pi-check-circle', style: { "background": "white" }, command: () => {
          this.getValue('POSTED')
        }
      },
      { separator: true },
      {
        label: 'Mark as a not posted', icon: 'pi pi-times-circle', style: { "background": "white" }, command: () => {
          this.getValue('NOT_POSTED')
        }
      }
    ];
    this.addItemToMenu();
  }

  addItemToMenu() {
    this.reviews.map(data => data.postStatus == 'POSTED' ? (data['model'] = [this.items[2]]) : (data['model'] = [this.items[0]]));
  }

  getValue(postStatus: string) {
    if (postStatus === 'POSTED') {
      this.isPost = true;
      this.currentDocRef.update({ 'postStatus': 'POSTED' }).then(() => {
        this.reviews[this.selectedIndex].postStatus = 'POSTED';
        this.reviews[this.selectedIndex].model = [this.items[2]];
      });
    }
    if (postStatus === 'NOT_POSTED') {
      this.isPost = false;
      this.currentDocRef.update({ 'postStatus': 'NOT_POSTED' }).then(() => {
        this.reviews[this.selectedIndex].postStatus = 'NOT_POSTED';
        this.reviews[this.selectedIndex].model = [this.items[0]];
      });
    }
  }

  onDropdownClick(data: any, index: number) {
    this.currentDocRef = data['docRef'];
    this.selectedIndex = index;
  }

  onScrollDown() {
    if (this.fetchReview) {
      let limit = 20;
      let offset = this.reviews.length
      this.nextDataEmitter.emit({ 'limit': limit, 'offset': offset });
    }
  }
  showMoreContent(content) {
    content.show = !content.show;
  }
  replyToReview(content) {
    this.updateReplyData = content;
    var replyData = content;
    const dialogRef = this.dialog.open(ReplyReviewDialogComponent, {
      width: '600px',
      disableClose: true,
      height: 'fit - content',
      data: {
        updateReplyData: replyData,
        popupHeader: "Add Reply To Review",
        popupPlaceholder: "Add Reply",
        snakbarMessage: "Reply added successfully"
      }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  updateReply(content) {
    content.replyByOwner = content.reviewReply.comment;
    this.updateReplyData = content;
    const dialogRef = this.dialog.open(ReplyReviewDialogComponent, {
      disableClose: true,
      width: '600px',
      height: 'fit - content',
      data: {
        updateReplyData: this.updateReplyData,
        popupHeader: "Update Reply Of Review",
        popupPlaceholder: "Update Reply",
        snakbarMessage: "Reply updated successfully"
      }
    });
    dialogRef.afterClosed().subscribe(() => {
    });

  }

  deleteReply(content) {
    this.updateReplyData = content;
  }

  postOnSocialMedia(content:any) {
    const dialogRef = this.dialog.open(PostReviewOnSocialMediaComponent, {
      width: '1200px',
      disableClose: true,
      height: '580px',
      data: {
        data: content,
        popupHeader: "Post Review On Social Media",
      },
      panelClass: 'custom-dialog-container'

    });
  }

  dropDownClick(event) {
    console.log(event);
  }
}


