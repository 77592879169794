import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { InteractiveFormService } from '../../_services/inveractive-form.service';
import { AccountService } from '../../_services/account.service';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Form, Question } from '../../_services/form';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CookieService } from 'ngx-cookie-service';
import { AfService } from '../../_services/providers/af.service';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

//declare const fbq: any;
declare const dataLayer: any;
// declare const gtag: any;
declare const WOW: any;
import { DialogData } from '../../_services/form';

@Component({
  selector: 'app-interactive-form',
  templateUrl: './interactive-form.component.html',
  styleUrls: ['./interactive-form.component.css'],
})
export class InteractiveFormComponent implements OnInit {

  @ViewChild('file_input', { static: true })
  fileInputVariable: any;

  questionSet: Form;
  answerSet: any = [];
  cnt: number = 0;
  total: number;
  showWelcomeScreen: boolean = true;
  showQuestions: boolean = false;
  showThankYou: boolean = false;
  showProgressConfirmation: boolean = false;
  showSubmissionConfirmation: boolean = false;
  totalQuestions: any = [];
  tooltip: string;
  form_id: string;
  form_slug: string;
  acc_slug: string;
  selectedFile: File;
  downloadURL: string;
  isUrl: boolean = false;
  snapshot: any;
  uploadProgress: number;
  showProgress: boolean = false;
  source: string = "";
  responseRef: any;
  snapshotRef: any;
  formRef: any;
  acc_settings: any;
  parsedQuestion: any;
  parsedDescription: any;
  curlyBraces: any;
  matchedString: any;
  stringCount: any;
  checkboxAnswer: any;
  accountSlug: any;
  formSlug: any;

  constructor(
    private interactiveformService: InteractiveFormService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private accountService: AccountService,
    private afService: AfService,
    // public previewdialogRef: MatDialogRef<InteractiveFormComponent>,
    // @Inject(MAT_DIALOG_DATA) private data: DialogData
  ) {
    // console.log(data);
    // this.accountSlug = this.data['accountSlug'];
    // this.formSlug = this.data['formSlug'];
    // this.form_id = this.data['formId'];
    // this.formRef = this.data['formRef'];

  }

  // close(): void {
  //   this.previewdialogRef.close();
  // }

  ngOnInit() {
    this.route.queryParams.subscribe(
      queryParams => {
        if (queryParams['source']) {
          let platform = queryParams['source'];
          switch (platform) {
            case 'adwords': this.source = 'google adwords';
              break;
            case 'facebook': this.source = 'Facebook';
              break;
            default: console.error('Invalid source provided');
              break;
          }
        }
      }
    );
    // this.route.url.subscribe(url => {
    // this.acc_slug = url[1].path;
    // this.form_slug = url[2].path;
    // this.interactiveformService.getQuestions(this.acc_slug, this.form_slug)
    this.interactiveformService.getQuestions(this.accountSlug, this.formSlug).then(questionSet => {
      this.form_id = questionSet.docs[0].id;
      this.formRef = questionSet.docs[0].ref;
      this.questionSet = <Form>questionSet.docs[0].data();
      this.total = this.questionSet.questions.length;
      this.acc_settings = this.interactiveformService.getAccountDetails();
      console.log(this.acc_settings);
    });
    // });

    //this.formService.saveJsonToFirebase();
  }

  previous() {
    new WOW().init();

    if (this.cnt == 0) {
      this.showWelcomeScreen = true;
      this.updateProgress();
      this.showQuestions = false;
    }
    else {
      if (this.questionSet.questions[this.cnt].jumped_from !== undefined) {
        this.cnt = this.questionSet.questions[this.cnt].jumped_from;
        this.updateProgress();
      }
      else {
        this.cnt -= 1;
        this.updateProgress();

      }
      this.processQues();
    }
  }

  next() {

    if (this.showWelcomeScreen) {
      this.handleUserTracking();
      this.showWelcomeScreen = false;
    }
    else if (this.cnt == this.total - 1) {
      this.submitForm();
    }
    else {
      this.saveAnswer();
    }

    //  new WOW().init();
  }

  handleUserTracking() {
    if (!this.cookieService.check('if_uid')) {
      this.interactiveformService.getCookie()
        .subscribe(
          cookie => {
            this.cookieService.set('if_uid', cookie.uid, new Date(cookie.expires));
            this.createResponse(cookie.uid, cookie.headers);
            this.processQues();
            this.updateProgress();
            this.showQuestions = true;
          },
          error => {
            console.error(error);
          }
        );
    }
    else {
      /*
       * The user has returned. Fetch response document and ask
       * whether they want to continue or start over.
       */
      let uid = this.cookieService.get('if_uid');
      this.interactiveformService.getResponseDoc(uid, this.formRef)
        .then(
          querySnapshot => {
            if (!querySnapshot.empty) {
              // The user HAS initiated this form
              this.responseRef = querySnapshot.docs[0].ref;
              this.snapshotRef = querySnapshot.docs[0];

              if (this.snapshotRef.data().status === 'initiated') {
                this.showProgressConfirmation = true;
              }
              else {
                this.showSubmissionConfirmation = true;
              }
            }
            else {
              /*
               * This user must have initiated a different form.
               * Create a new response for THIS form for them.
               */
              this.interactiveformService.getAnalytics()
                .subscribe(
                  headers => {
                    this.createResponse(uid, headers);
                  }
                );
              this.processQues();
              this.updateProgress();
              this.showQuestions = true;
            }
          },
          error => {
            console.error(error);
          }
        );
    }
  }

  createResponse(uid: string, headers: any) {
    this.interactiveformService.createResponse(this.questionSet.name, uid, headers, this.questionSet.questions, this.formRef)
      .then(
        response => {
          this.responseRef = response;
        },
        error => {
          console.error(error);
        }
      );
  }

  startOver(yes: boolean) {
    if (yes) {
      this.interactiveformService.saveAnswer(this.responseRef, this.questionSet.questions)
        .then(
          () => {
            console.log('Form successfully reset.');
          },
          error => {
            console.error(error);
          }
        );
    }
    else {
      this.questionSet.questions = this.snapshotRef.data().response;
      this.cnt = this.questionSet.questions.findIndex(x => x.answer === undefined);
      /*
       * If this user is resubmitting the form the answers will all be filled.
       * So show them the form from the beginning.
       */
      if (this.cnt == -1) {
        this.cnt = 0;
      }
    }
    this.processQues();
    this.updateProgress();
    this.showProgressConfirmation = false;
    this.showQuestions = true;
  }

  resubmitForm(yes: boolean) {
    if (yes) {
      this.questionSet.questions = this.snapshotRef.data().response;
      this.interactiveformService.changeSubmissionStatus(this.responseRef)
        .then(
          success => {
            console.log("Form status changed back to 'initiated'");
          },
          error => {
            console.error(error);
          }
        );
      this.processQues();
      this.updateProgress();
      this.showSubmissionConfirmation = false;
      this.showQuestions = true;
    }
    else {
      this.showSubmissionConfirmation = false;
      this.showThankYou = true;
    }
  }

  saveAnswer() {
    if (this.questionSet.questions[this.cnt].answer_type == 'checkbox') {
      this.questionSet.questions[this.cnt].answer = this.answerSet;
      console.log(this.answerSet);
      console.log(this.questionSet.questions[this.cnt].values);
      this.questionSet.questions[this.cnt].answer_value = 0;
      for (let i = 0; i <= this.answerSet.length; i++) {
        if (this.questionSet.questions[this.cnt].answer[i] == true) {
          console.log(this.questionSet.questions[this.cnt].answer[i]);
          console.log(this.questionSet.questions[this.cnt].values[i]);
          if (this.questionSet.questions[this.cnt].values[i] === undefined) {
            this.questionSet.questions[this.cnt].values[i] = 0;
          }
          this.questionSet.questions[this.cnt].answer_value += this.questionSet.questions[this.cnt].values[i];
          console.log(this.questionSet.questions[this.cnt].answer_value);
        }
        else {
          this.questionSet.questions[this.cnt].values[i] = 0;

        }
      }
    }
    if (this.questionSet.questions[this.cnt].answer_type == 'radio' || this.questionSet.questions[this.cnt].answer_type == 'dropdown') {
      let ans_ind = this.questionSet.questions[this.cnt].choices.findIndex(x => x === this.questionSet.questions[this.cnt].answer);
      if (this.questionSet.questions[this.cnt].values[ans_ind] !== undefined) {
        this.questionSet.questions[this.cnt].answer_value = this.questionSet.questions[this.cnt].values[ans_ind];
      }
      else {
        this.questionSet.questions[this.cnt].answer_value = 0;
      }
    }
    if (this.questionSet.questions[this.cnt].answer === undefined) {
      this.questionSet.questions[this.cnt].answer = "";
    }

    console.log(this.questionSet.questions);
    this.interactiveformService.saveAnswer(this.responseRef, this.questionSet.questions)
      .then(
        () => {
          console.log('Answer successfully saved!');
          this.moveForward();
        },
        error => {
          console.error(error);
        }
      );
  }

  moveForward() {
    if (this.questionSet.questions[this.cnt].jumps.length > 0) {
      let ind = 0;
      if (this.questionSet.questions[this.cnt].answer_type == 'radio') {
        if (this.questionSet.questions[this.cnt].answer !== undefined) {
          ind = this.questionSet.questions[this.cnt].choices.findIndex(x => x === this.questionSet.questions[this.cnt].answer);
          this.questionSet.questions[this.cnt].jumps.map(x => { if (this.questionSet.questions[x] !== undefined) { delete this.questionSet.questions[x].jumped_from } });
        }
      }
      let jumped_from = this.cnt;

      let jumpCount = this.questionSet.questions[this.cnt].jumps[ind];

      if (jumpCount == -1) {

        for (let count = this.cnt + 1; count < this.total; count++) {
          this.questionSet.questions[count].answer = "";
        }
        this.submitForm();
        console.log("Thank you!");
      }
      else {
        this.cnt = jumpCount;
        this.questionSet.questions[this.cnt].jumped_from = jumped_from;
        for (let int_cnt = jumped_from + 1; int_cnt < this.cnt; int_cnt++) {
          this.questionSet.questions[int_cnt].answer = "";
        }
        console.log("Forwarded!");
      }
      this.interactiveformService.saveAnswer(this.responseRef, this.questionSet.questions)
        .then(
          () => {
            console.log('Answer successfully saved!');
          },
          error => {
            console.error(error);
          }
        );
    }
    else {
      this.cnt += 1;
    }

    this.updateProgress();
    this.processQues();
    new WOW().init();
  }

  updateProgress() {
    console.log(this.total);
    this.totalQuestions = ((this.cnt + 1) / this.total) * 100;
    this.tooltip = (this.cnt + 1 + " of " + this.total);
  }

  processQues() {

    //For previous questions answer into the next question.
    this.parsedQuestion = this.questionSet.questions[this.cnt].question;
    console.log(this.questionSet.questions[this.cnt].question);
    //  console.log(this.questionSet.questions[this.cnt].answer);
    this.curlyBraces = /{{[0-9a-zA-Z]+?}}/g;
    while (this.matchedString = this.curlyBraces.exec(this.parsedQuestion)) {
      console.log(`Found ${this.matchedString[0]} at ${this.matchedString.index}`);
      console.log(this.matchedString);
      this.stringCount = this.matchedString[0].slice(2, -2);
      console.log(this.questionSet.questions[this.stringCount].answer_type);
      this.checkboxAnswer = "";
      if (this.questionSet.questions[this.stringCount].answer_type == 'checkbox') {

        console.log(this.questionSet.questions[this.stringCount].answer);
        for (let i = 0; i < this.questionSet.questions[this.stringCount].answer.length; i++) {
          if (this.questionSet.questions[this.stringCount].answer[i] === true) {
            console.log(this.questionSet.questions[this.stringCount].choices[i]);
            this.checkboxAnswer += this.questionSet.questions[this.stringCount].choices[i] + ", ";
            console.log(this.checkboxAnswer);
            this.parsedQuestion = this.questionSet.questions[this.cnt].question;
            console.log(this.parsedQuestion);
            this.parsedQuestion = this.parsedQuestion.replace(this.matchedString[0], this.checkboxAnswer);
            console.log(this.parsedQuestion);
            //  this.questionSet.questions[this.stringCount].answer += this.answerSet[i] + ",";
          }
        }
      }
      else {
        this.parsedQuestion = this.parsedQuestion.replace(this.matchedString[0], this.questionSet.questions[this.stringCount].answer);
        console.log(this.parsedQuestion);
      }
    }
    //
    this.parsedDescription = this.questionSet.questions[this.cnt].short_description;
    console.log(this.parsedDescription);
    this.curlyBraces = /{{[0-9a-zA-Z]+?}}/g;
    while (this.matchedString = this.curlyBraces.exec(this.parsedDescription)) {
      console.log(`Found ${this.matchedString[0]} at ${this.matchedString.index}`);
      console.log(this.matchedString);
      this.stringCount = this.matchedString[0].slice(2, -2);
      console.log(this.questionSet.questions[this.stringCount].answer);
      if (this.questionSet.questions[this.stringCount].answer_type == 'checkbox') {
        console.log(this.questionSet.questions[this.stringCount].answer);
        for (let i = 0; i < this.questionSet.questions[this.stringCount].answer.length; i++) {
          if (this.questionSet.questions[this.stringCount].answer[i] === true) {
            console.log(this.questionSet.questions[this.stringCount].choices[i]);
            this.checkboxAnswer += this.questionSet.questions[this.stringCount].choices[i] + ", ";
            console.log(this.checkboxAnswer);
            this.parsedDescription = this.questionSet.questions[this.cnt].short_description;
            this.parsedDescription = this.parsedDescription.replace(this.matchedString[0], this.checkboxAnswer);
            //  this.questionSet.questions[this.stringCount].answer += this.answerSet[i] + ",";
          }
        }
      }
      else {
        this.parsedDescription = this.parsedDescription.replace(this.matchedString[0], this.questionSet.questions[this.stringCount].answer);
        console.log(this.parsedDescription);
      }
    }

    if (this.questionSet.questions[this.cnt].answer_type == 'checkbox') {
      this.answerSet = [];
      if (this.questionSet.questions[this.cnt].answer !== undefined && this.questionSet.questions[this.cnt].answer !== "") {
        this.answerSet = this.questionSet.questions[this.cnt].answer;
      }
      else {
        let choicesCnt = this.questionSet.questions[this.cnt].choices.length;
        for (let i = 0; i < choicesCnt; i++) {
          this.answerSet[i] = false;
        }
      }
    }

    if (this.questionSet.questions[this.cnt].answer_type == 'file') {
      if (this.questionSet.questions[this.cnt].answer !== undefined && this.questionSet.questions[this.cnt].answer !== "") {
        this.isUrl = true;
      }
      else {
        this.isUrl = false;
      }
    }

    if (this.questionSet.questions[this.cnt].answer_type == 'radio' && this.questionSet.questions[this.cnt].jumps.length > 0 && this.questionSet.questions[this.cnt].answer === undefined) {
      this.questionSet.questions[this.cnt].answer = this.questionSet.questions[this.cnt].choices[0];
    }
  }

  getImage(event) {
    this.onUpload(event.target.files[0]);
  }

  onUpload(selectedImage: File) {
    let uploadTask = this.interactiveformService.uploadFile(selectedImage, this.questionSet.name);
    this.showProgress = true;
    uploadTask.on('state_changed',
      snapshot => {
        this.snapshot = snapshot;
        this.uploadProgress = (this.snapshot.bytesTransferred / this.snapshot.totalBytes) * 100;
      },
      error => {
        // upload failed
        console.error(error)
      },
      () => {

        // upload success
        console.log("File uploaded");
        this.questionSet.questions[this.cnt].uploadedImagePath = uploadTask.snapshot.metadata.fullPath;
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.isUrl = true;
          this.downloadURL = downloadURL;
          this.showProgress = false;
          this.questionSet.questions[this.cnt].answer = this.downloadURL;
          this.downloadURL = undefined;
          //this.isUrl = false;
          this.fileInputVariable.nativeElement.value = '';
        });

      });
  }

  deleteFile() {
    this.interactiveformService.deleteFromStorage(this.questionSet.questions[this.cnt].uploadedImagePath);
    console.log('File deleted');
    delete this.questionSet.questions[this.cnt].answer;
    this.isUrl = false;
  }

  getResult(question: Question) {
    /*
    qFunc.push('/');
    qFunc.push("100");
    */
    let qFunc = question.calculation.function;
    let operation = [];
    qFunc.forEach(
      part => {
        // Check if it is a constant
        if (typeof part === "number") {
          operation.push(part);
        }
        // Check if it's a variable (Q. No.)
        else if (part.includes("{{")) {
          let ques_ind = part.slice(2, -2);
          operation.push(this.questionSet.questions[ques_ind].answer_value);
        }
        // It must be an operator
        else {
          operation.push(part);
        }
        if (operation.length == 3) {
          operation = this.calculate(operation);
        }
      }
    );
    question.answer = operation[0];
    return operation[0];
  }

  calculate(operation: any) {
    let result = 0;
    switch (operation[1]) {
      case '*': result = operation[0] * operation[2];
        break;
      case '/': result = operation[0] / operation[2];
        break;
    }

    operation = [];
    operation.push(result);

    return operation;
  }

  sendToHubspot(form_name: string, form_response: any, response_id: string, source: string) {
    let msg_body = `<p>Congratulations! You have received a new response on your form called <strong>${form_name}</strong>.</p>`;
    let total = form_response.length;
    let question = '';
    let answer = '';
    let name = '';
    let email = '';
    let phone = '';

    for (let i = 0; i < total; i++) {
      question = `<strong>Q${i + 1}. ${form_response[i].question}</strong><br>`;
      if (form_response[i].answer !== "") {
        if (form_response[i].answer_type === 'checkbox') {
          answer = '';
          let total_choices = form_response[i].choices.length;
          for (let j = 0; j < total_choices; j++) {
            if (form_response[i].answer[j] === true) {
              answer = answer + form_response[i].choices[j] + ", ";
            }
          }
          // To remove the last comma
          answer = (answer !== '') ? answer.slice(0, -1) : '-';
        }
        else if (form_response[i].answer_type === 'file') {
          answer = `<a href="${form_response[i].answer}" target="_blank">View File</a>`;
        }
        else {
          if (i == 0) {
            name = form_response[i].answer;
          }
          if (i == total - 2) {
            phone = form_response[i].answer;
          }
          if (i == total - 1) {
            email = form_response[i].answer;
          }
          answer = form_response[i].answer;
        }
      }
      else {
        if (i == total - 1) {
          email = response_id + "@no-email.com";
        }
        answer = "-";
      }

      msg_body = msg_body + `<p>${question}${answer}</p>`;
    }


    let hs_input_obj = {
      "form_name": form_name,
      "name": name,
      "email": email,
      "phone": phone,
      "source": source,
      "message": msg_body,
      "hubspot_form_id": this.questionSet.form_settings.hubspot_form_id,
      "hubspot_id": this.questionSet.form_settings.hubspot_id,
    };

    this.interactiveformService.sendToHubspot(hs_input_obj);
  }

  go() {
    window.parent.location.href = this.questionSet.thank_you_screen.button_link;
  }

  submitForm() {
    console.log(this.questionSet, this.cnt);
    if (this.questionSet.questions[this.cnt].answer_type == 'checkbox') {
      this.questionSet.questions[this.cnt].answer = this.answerSet;
    }
    if (this.questionSet.questions[this.cnt].answer === undefined) {
      this.questionSet.questions[this.cnt].answer = "";
    }


    let userInfo = {};
    for (let questionType of this.questionSet.questions) {
      if (questionType.answer_type == 'tel') {
        userInfo['phone'] = questionType.answer;
      }

      if (questionType.answer_type == 'email') {
        userInfo['email'] = questionType.answer;
      }
    }
    console.log(userInfo);
    this.interactiveformService.saveUserInfo(userInfo)
      .then(
        () => {
          console.log('Data  successfully saved!');
        },
        error => {
          console.error(error);
        }
      );



    this.interactiveformService.submitForm(this.responseRef, this.questionSet.questions)
      .then(
        () => {
          console.log('Response added');

          if (dataLayer.length > 0) {
            dataLayer.push({ 'event': 'interactive-form-submitted' });

            if ((this.source == 'google adwords' || this.source == "") && this.questionSet.thank_you_screen.tracking_code !== undefined) {
              if (this.questionSet.thank_you_screen.tracking_code.adwords !== undefined) {
                if (this.questionSet.thank_you_screen.tracking_code.adwords.enabled) {
                  console.log("AdWords tracking event fired.");
                  dataLayer.push({ 'event': 'interactive-form-test-submission', 'interactive-form-name': this.questionSet.thank_you_screen.tracking_code.adwords.form_name });
                  //gtag('event', 'conversion', {'send_to': `AW-${this.questionSet.thank_you_screen.tracking_code.adwords.conversion_id}/` + this.questionSet.thank_you_screen.tracking_code.adwords.conversion_label});
                  this.source = 'google adwords';
                }
              }
            }

            else if (this.source == 'Facebook' && this.questionSet.thank_you_screen.tracking_code !== undefined) {
              if (this.questionSet.thank_you_screen.tracking_code.facebook !== undefined) {
                if (this.questionSet.thank_you_screen.tracking_code.facebook.enabled) {
                  console.log("Facebook tracking event fired.");
                  /*
                  fbq(
                    'track',
                    this.questionSet.thank_you_screen.tracking_code.facebook.tracking_event,
                    this.questionSet.thank_you_screen.tracking_code.facebook.custom_data
                  );
                  */
                  dataLayer.push({ 'event': 'interactive-form-fb-event', 'interactive-form-name': this.questionSet.thank_you_screen.tracking_code.facebook.form_name });
                }
              }
            }
          }

          this.interactiveformService.sendEmail(this.questionSet.name, this.questionSet.questions, this.questionSet.form_settings.email);
          this.sendToHubspot(this.questionSet.name, this.questionSet.questions, this.responseRef.id, this.source);

          if (this.questionSet.form_settings.is_seo) {
            console.log('This is an SEO form');
            // Create an mktgbot account
            let seo_obj = {
              'valid': true,
            };
            seo_obj['value'] = {
              'url': this.questionSet.questions[0].answer,
              'location': this.questionSet.questions[1].answer,
              'first_name': this.questionSet.questions[2].answer,
              'last_name': this.questionSet.questions[3].answer,
              'cell_no': this.questionSet.questions[4].answer,
              'email': this.questionSet.questions[5].answer,
              'password': 'aesurg2019'
            };

            this.afService.signUpWithEmail(seo_obj);

            // Call Python service to get reports
            this.interactiveformService.getSeoResults(seo_obj['value']['url'], seo_obj['value']['location'], seo_obj['value']['email']);

            // Email the reports
          }

          this.showThankYou = true;
          this.showQuestions = false;
        },
        error => {
          console.error(error);
        }
      );
  }
}
