<!-- Target Audience, Business Lifecycle and Modal -->

<mat-card class="info-cards">
  <mat-card-title class="card-title">Target Audience</mat-card-title>
  <mat-card-subtitle>Please provide some details about your target audience</mat-card-subtitle>
  <mat-card-content>
    <form #targetForm="ngForm" novalidate>
      <div class="row p-2">
        <p class="col-sm-6">Choose your target country</p>
        <mat-radio-group class="example-radio-group" name="chosenCountry" [(ngModel)]="chosenCountry">
          <mat-radio-button class="example-radio-button  col-sm-4" *ngFor="let country of countries" [value]="country">
            {{ country }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="row p-2">
          <mat-slide-toggle class="col-sm" name="gender.male" [checked]="gender.male" [(ngModel)]="gender.male">Male</mat-slide-toggle>
          <mat-slide-toggle class="col-sm" name="gender.female" [checked]="gender.female" [(ngModel)]="gender.female">Female</mat-slide-toggle>
          <label class="col-sm">Age</label>
          <input class="col-sm" type="number" id="ageMin" name="ageMin" min=18 max=65  [(ngModel)]="age.min"/> to
          <input class="col-sm" type="number" id="ageMax" name="ageMax" min=18 max=65  [(ngModel)]="age.max"/>
          <mat-error class="ml-auto col-sm" *ngIf="age.min < 18">
            Minimum age cannot be less than 18
          </mat-error>
          <mat-error class="ml-auto col-sm" *ngIf="age.max > 65">
            Maximum age cannot be greater than 65
          </mat-error>
          <mat-error class="ml-auto col-sm" *ngIf="age.min > age.max">
            Minimum age cannot be greater than maximum age
          </mat-error>
      </div>

      <div class="row p-2">
        <label class="col-sm-12">What is your business model?</label>
        <mat-slide-toggle class="col-sm" name="businessModel.b2b" [checked]="businessModel.b2b" [(ngModel)]="businessModel.b2b">B2B</mat-slide-toggle>
        <mat-slide-toggle class="col-sm" name="businessModel.b2c" [checked]="businessModel.b2c" [(ngModel)]="businessModel.b2c">B2C</mat-slide-toggle>
      </div>
      <div class="row p-2">
        <label class="col-sm-12">How do your customers interact with you?</label>
        <mat-slide-toggle class="col-sm" name="interaction.online" [checked]="interaction.online" [(ngModel)]="interaction.online">Online</mat-slide-toggle>
        <mat-slide-toggle class="col-sm" name="interaction.offline" [checked]="interaction.offline" [(ngModel)]="interaction.offline">Offline</mat-slide-toggle>
      </div>
      <mat-card-actions align="center">
        <button mat-raised-button (click)="next()" id="setTargetingButton" color="accent">Set These</button>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>
