import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { NavigationState } from '../_models/index';
import { NavigationService, AdWordsService, TimePeriodService, AccountService, ConversionValueService } from '../_services/index';
// import { DecimalPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'app-ad-words-campaign',
  templateUrl: './ad-words-campaign.component.html',
  styleUrls: ['./ad-words-campaign.component.scss']
})
export class AdWordsCampaignComponent implements OnInit {

  acc: any;
  isAllow: boolean;
  acc_name: string;
  campaign: any;
  cost_per_conversion: any;
  timeperiod: any;
  isReady: boolean;
  adwordsCredentials: any;

  url: UrlSegment[];
  campaign_conversion_value: any;

  constructor(
    private navigationService: NavigationService,
    private adwordsService: AdWordsService,
    private route: ActivatedRoute,
    private router: Router,
    private timePeriodService: TimePeriodService,
    private accountService: AccountService,
    private conversionValueService: ConversionValueService
  ) {
    this.isReady = false;
    this.accountService.accChange$.subscribe(
      accChange => {
        this.router.navigateByUrl('/websites');
        /*
        this.acc = this.accountService.getCurrentAccDetails();
        this.adwordsCredentials = this.accountService.getLinkedAccountCredentials('adwords');
      let state = navigationService.getCurrentNavigationState();
      let regExp = /^\/adwords-campaign/;
      if(regExp.test(state.url)) {
        this.router.navigateByUrl('/adwords');
      }
      */
      });

    this.timePeriodService.timePeriod$.subscribe(
      timeperiod => {
        let state = navigationService.getCurrentNavigationState();
        let regExp = /^\/adwords-campaign/;
        if (regExp.test(state.url)) {
          this.timeperiod = timeperiod;
          this.route.url.subscribe(
            url => {
              this.url = url;
              this.campaign = this.adwordsService.getCampaign(this.url[1].path, this.timeperiod, this.acc_name);
              this.campaign = this.campaign['@attributes'];
              this.isReady = true;
            }
          );
        }
      }
    );
  }

  ngOnInit() {
    this.isReady = false;
    this.timeperiod = this.timePeriodService.getCurrentTimeperiod();
    this.acc = this.accountService.getCurrentAccDetails();
    this.adwordsCredentials = this.accountService.getLinkedAccountCredentials('adwords');
    this.acc_name = this.acc.name;

    if (!this.acc.adwords_factor) {
      this.acc.adwords_factor = 1;
    }

    if (!this.acc.account_currency) {
      this.acc.account_currency = 'INR';
    }

    this.route.url.subscribe(
      url => {
        this.url = url;
        this.campaign = this.adwordsService.getCampaign(this.url[1].path, this.timeperiod, this.acc_name);
        this.campaign = this.campaign['@attributes'];
        this.cost_per_conversion = (((this.campaign.costConv) / 1000000) * this.acc.adwords_factor);
        this.conversionValueService.sendCampaignDetails({ campaign_id: this.campaign.campaignID, cost_per_conversion: this.cost_per_conversion, provider: "adwords" });
        this.conversionValueService.getCampaignConvValue()
          .subscribe(
            aw_campaign_document => {
              if (aw_campaign_document == null) {
                this.campaign_conversion_value = -1;
              }
              else {
                this.campaign_conversion_value = aw_campaign_document['conversion_value'];
              }
            });
        this.isReady = true;
        this.navigationService.updateState(new NavigationState(true, this.campaign.campaign, "AdWords", "/adwords", "/adwords-campaign/" + this.url[1].path));
      }
    );
  }
}
