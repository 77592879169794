<div>
  <div id="target_welcome_screen" class="text-center">
    <h1 class="welcome_heading">{{ form?.welcome_screen?.headline_text }}</h1>
    <img class="top-space welcome_image" src="{{form?.welcome_screen?.image}}" />
    <div class="welcome_short_desc top-space" [innerHTML]="form?.welcome_screen?.short_description"></div>
    <button class="top-space large-button" mat-raised-button>{{ form?.welcome_screen?.button_text }}</button>
  </div>

  <div #ques_section *ngFor="let selection of form.questions; let ind = index;" id="target{{ind}}"
    class="question-section">
    <div class="content" style="padding:20px;">
      <h3 class="question">{{ selection?.question }}<span *ngIf="selection?.required"> *</span></h3>
      <img class="top-space welcome_image centerImage" *ngIf="selection?.image" src="{{selection?.image}}" />
      <div class="top-space ques_short_desc" [innerHTML]="selection?.short_description"></div>
      <div>
        <span *ngIf="selection?.answer_type == 'name'; then name"></span>
        <span *ngIf="selection?.answer_type == 'text'; then text"></span>
        <span *ngIf="selection?.answer_type == 'textarea'; then textarea"></span>
        <span *ngIf="selection?.answer_type == 'radio'; then radio"></span>
        <span *ngIf="selection?.answer_type == 'checkbox'; then checkbox"></span>
        <span *ngIf="selection?.answer_type == 'dropdown'; then dropdown"></span>
        <span *ngIf="selection?.answer_type == 'email'; then email"></span>
        <span *ngIf="selection?.answer_type == 'tel'; then cell_no"></span>
        <span *ngIf="selection?.answer_type == 'declaration'; then declaration"></span>
        <span *ngIf="selection?.answer_type == 'file'; then file"></span>
        <span *ngIf="selection?.answer_type == 'calculator'; then calculator"></span>
        <span *ngIf="selection?.answer_type == 'rating'; then rating"></span>
        <span *ngIf="selection?.answer_type == 'multi_rating'; then multi_rating"></span>
      </div>
      <ng-template #rating>
        <p-rating [(ngModel)]="ratingValue" [cancel]="false"></p-rating>
      </ng-template>

      <ng-template #name>
        <mat-form-field class="full-width">
          <input matInput style="font-size: 20px;" name="first_name" placeholder="First name" />
          <!-- <input matInput style="font-size: 20px;" name="first_name" placeholder="First name"
            [(ngModel)]="selection?.names[0]" /> -->
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput style="font-size: 20px;" name="last_name" placeholder="Last name" />
        </mat-form-field>
      </ng-template>

      <ng-template #text>
        <mat-form-field class="full-width">
          <input matInput type="text" style="font-size: 20px;" name="text" [(ngModel)]="selection.answer" />
        </mat-form-field>
      </ng-template>

      <ng-template #textarea>
        <mat-form-field class="full-width">
          <textarea matInput name="textarea" [(ngModel)]="selection.answer"></textarea>
        </mat-form-field>
      </ng-template>

      <ng-template #multi_rating>
        <div *ngFor="let rate of selection?.rating" style="padding: 2%;">
          <input matInput style="font-size: 22px;" name="rating_field" [value]="rate.question"
            style="padding-bottom:1%;" />
          <p-rating [ngModelOptions]="{standalone: true}" [cancel]="false"></p-rating>
        </div>
      </ng-template>

      <ng-template #radio>
        <mat-radio-group name="radio" [(ngModel)]="selection.answer">
          <div *ngFor="let choice of selection?.choices">
            <mat-radio-button [value]="choice">{{ choice }}</mat-radio-button>
          </div>
        </mat-radio-group>
      </ng-template>
      <ng-template #checkbox>
        <div *ngFor="let choice of selection?.choices; let ind = index;">
          <mat-checkbox name="checkbox">{{ choice }}</mat-checkbox>
        </div>
      </ng-template>
      <ng-template #dropdown>
        <mat-form-field class="container">
          <mat-select placeholder="Choose..." [(ngModel)]="selection.answer" name="dropdown">
            <mat-option *ngFor="let choice of selection?.choices" [value]="choice"
              style="width: 100%; font-size: 10px;">{{ choice }}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
      <ng-template #cell_no>
        <mat-form-field class="full-width">
          <input matInput id="cell_no" name="cell_no" [(ngModel)]="selection.answer" #cell_no="ngModel"
            pattern="[0-9]{10}" />
        </mat-form-field>
        <div *ngIf="cell_no.invalid && (cell_no.dirty || cell_no.touched) && cell_no.errors.pattern"
          class="alert alert-danger">
          Please enter valid mobile number.
        </div>
      </ng-template>
      <ng-template #email>
        <mat-form-field class="full-width">
          <input matInput id="email" name="email" [(ngModel)]="selection.answer" #email="ngModel"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" />
        </mat-form-field>
        <div *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.pattern" class="alert alert-danger">
          Email format should be
          <small><b>joe@abc.com</b></small>
        </div>
      </ng-template>
      <ng-template #declaration>
        <mat-checkbox name="checkbox" [(ngModel)]="selection.answer" [value]="selection?.choices[0]">
          {{ selection?.choices[0] }}</mat-checkbox>
      </ng-template>
      <ng-template #file>
        <input *ngIf="!isUrl" #file_input type="file" (change)="getImage($event)">
        <div *ngIf="isUrl">
          <img style="height: 100px; width: 100px;" src="{{downloadURL || selection?.answer}}" alt="image" />
          <i class="material-icons" (click)="deleteFile(downloadURL)">delete</i>
        </div>
        <div *ngIf="showProgress" class="progress">
          <div class="progress-bar progress-bar-animated bg-primary" mode="determinate"
            [style.width]="(uploadProgress) + '%'">{{uploadProgress | number:'1.0-0'}}% Complete
          </div>
        </div>
      </ng-template>
      <ng-template #calculator>
        <p>
          <span style="font-size: 1.5em;" *ngIf="selection.calculation.format === 'number'">
            {{ 1000 | number:'1.0-2' }}
          </span>
          <span style="font-size: 1.5em;" *ngIf="selection.calculation.format === 'currency'">
            {{ 1000 | currency:accCurrency:'symbol':'1.0-2' }}
          </span>
          <sup><i style="font-size: 1.5em;" class="material-icons"
              matTooltip="In your actual form your users will see the result of the calculation."
              matTooltipPosition="after">
              help_outline
            </i></sup>
        </p>
      </ng-template>
    </div>
  </div>

  <div id="target_thankyou_screen" class="text-center" data-wow-delay="0.1s">
    <h1 class="welcome_heading">{{ form?.thank_you_screen?.headline_text }}</h1>
    <img class="top-space welcome_image" src="{{form?.thank_you_screen?.image}}" />
    <div class="welcome_short_desc top-space" [innerHTML]="form?.thank_you_screen?.short_description"></div>
    <button mat-raised-button class="large-button">{{ form?.thank_you_screen?.button_text }}</button>
  </div>
</div>