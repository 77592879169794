<!-- <div style="display: flex; overflow-x: overlay"> -->
<div class="container-fluid">

  <div *ngIf="offerData.length==0; else cardTemplate" style="align-items: center; min-height: 350px;" class="row">
    <!-- <div class="col-md-4 col-4 col-xs-4"></div> -->
    <div class="" style="width: 100%;text-align: center;">
      <img alt="Card" class="image" src="assets/images/NoMarketingNew.png">
      <p style="text-align: canter;">
        No {{typeOffer}} Available
      </p>
    </div>
  </div>

  <ng-template #cardTemplate>
    <!-- <div id="card-container">

      <div class="card-block plan" *ngFor="let offer of offerData,let i=index">
        <p-card [style]="{
            width: '220px',height:'260px',
            'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'
            }" styleClass="p-card-shadow">
          <p-header>
            <img alt="Card" class="image" [src]="offer.template.previewTemplateUrl">
          </p-header>
          <p-footer style="padding: 0px;margin-top: 1em;">
            <div class="row">
              <div class="col-md-9" style=" display:block; opacity: 1;text-align: center; font-weight: 400;">
                {{offer.offerName.length<25?offer.offerName:offer.offerName.substring(0, 25)+'...'}} </div>
                  <div class="col-md-3" style="text-align: center;">
                    <i class="material-icons" id="card_icon" *ngIf="offer.event !=='past'"
                      (click)="editOffer(offer)">edit</i>
                    <i class="material-icons" id="card_icon" *ngIf="offer.event=='upcoming'"
                      (click)="deleteOffer(offer,i)">delete_forever</i>
                    <i class="material-icons" id="card_icon" *ngIf="offer.event =='past'"
                      (click)="editOffer(offer)">edit</i>
                  </div>
              </div>
          </p-footer>
        </p-card>
      </div>
    </div> -->

    <div class="row" *ngIf="offerData" style="padding-top: 15px;">
      <div class="col-md-12" style="margin-top: 20px;">
        <div id="card-container">
          <div *ngFor="let offer of offerData,let i=index">
            <div style="margin: 5px;">
              <p-card [style]="{height:'300px',width:'300px',
                          'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'
                          }" styleClass="p-card-shadow">
                <p-card-content>
                  <img [src]="offer.previewTemplateUrl" style="height: 250px;width: 100%;">
                </p-card-content>
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-6">
                    <div
                      style="text-align: center;margin-top: 12px;white-space: nowrap;overflow: hidden; text-overflow: ellipsis; ">
                      {{offer.offerName.length<45?offer.offerName:offer.offerName.substring(0, 45)+'...'}} </div>
                    </div>

                    <div class="col-md-4">
                      <mat-slide-toggle *ngIf="offer.event =='current'" class="example-margin" [color]="primary"
                        [checked]="offer.isActive" [(ngModel)]="offer.isActive"
                        (change)="changeOfferStatus(offer.offerId,$event.checked)">
                      </mat-slide-toggle>
                      <mat-icon aria-hidden="false" aria-label="Example home icon"
                        style="text-align: center;margin-top: 12px;padding-left: 5px;" matTooltip='Delete Offer'
                        *ngIf="offer.event=='upcoming'" (click)="deleteOffer(offer,i)">delete</mat-icon>
                      <mat-icon aria-hidden="false" aria-label="Example home icon"
                        style="text-align: center;margin-top: 12px;margin-left: 17px;" matTooltip='Edit Offer'
                        *ngIf="offer.event !=='past'" (click)="editOffer(offer)">edit
                      </mat-icon>
                      <mat-icon aria-hidden="false" aria-label="Example home icon"
                        style="text-align: center;margin-top: 12px;margin-left: 17px;" matTooltip='Edit Offer'
                        *ngIf="offer.event=='past'" (click)="editOffer(offer)">edit
                      </mat-icon>

                    </div>
                  </div>
              </p-card>
            </div>
          </div>
        </div>
      </div>
    </div>





  </ng-template>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>