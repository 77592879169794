import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from "@angular/platform-browser";

export interface DialogData {
  link: string;
  title: string;
  calendars: any;
}
@Component({
  selector: 'app-schedule-new-appointment',
  templateUrl: './schedule-new-appointment.component.html',
  styleUrls: ['./schedule-new-appointment.component.scss']
})

export class ScheduleNewAppointmentComponent implements OnInit {
  link: any;
  title: any;
  @HostListener('window:message',['$event'])
    onMessage(event){
      this.receiveMessage(event);
    }
  constructor(
    public dialogRef: MatDialogRef<ScheduleNewAppointmentComponent>,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(data.link);
    this.title = data.title;
  }
  ngOnInit(): void {
    // window.addEventListener("message", this.receiveMessage, false);
  }
  close() {
    this.dialogRef.close();
  }

  receiveMessage = (event: any) => {

    console.log(event.data)

    const message = JSON.parse(event.data)
    console.log(message)
    if (message.eventType == 'CLOSE')
      this.close()
  }


}
