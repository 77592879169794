import { Component, Input, OnInit } from '@angular/core';
import {AccountSetupDialog} from '../signup/signup.component';
import { DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-processing',
  templateUrl: './account-processing.component.html',
  styleUrls: ['./account-processing.component.scss'],
})
export class AccountProcessingComponent implements OnInit {
  dialogRef: DynamicDialogRef;

  constructor(
  public dialogService: DialogService,
  private router: Router,
  ) { }

  @Input() process:boolean;
  accId:string = sessionStorage.getItem('current_acc_id');

  ngOnInit() {
    if(this.process == true){
      this.dialogRef = this.dialogService.open(AccountSetupDialog, {
        height: "520px",
        width: "800px",
        closable: false,
        header: "Account Setup",
        data: { accId: this.accId,state:'acceptAck'}
      });
      this.dialogRef.onClose.subscribe(event => {
        this.router.navigateByUrl('/');
      });
    }
  }

}
