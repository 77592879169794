<div class="ui-g" *ngIf="isLoading() else loaded">
  <!-- <mat-spinner></mat-spinner> -->
</div>
<ng-template class="ui-g" #loaded>
  <div class="scrolling-wrapper-flexbox">
    <div class='flex-container'>
      <div style="margin-left: 0;">
        <p>Booked: {{totalCount - rescheduleCount}}</p>
      </div>
      <div>
        <p>Completed: {{completedCount}}</p>
      </div>
      <div>
        <p>No-shows: {{noShowsCount}}</p>
      </div>
      <div>
        <p>Cancelled: {{cancelledCount}}</p>
      </div>
      <div>
        <p>Rescheduled: {{rescheduleCount}}</p>
      </div>
      <div style="margin-right: 0;">
        <p>Upcoming: {{upcomingCount}}</p>
      </div>
    </div>
    <!-- <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi">
          {{upcomingCount}}
        </div>
        <div class="metric-caption kpi">upcoming</div>
      </mat-card-content>
    </mat-card> -->
  </div>
  <!-- <mat-divider></mat-divider> -->
</ng-template>