// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDESCcJfBOKIPXA0Sxt3VEV1GXyTcVSzQU",
    authDomain: "mktgbot-181017.firebaseapp.com",
    databaseURL: "https://mktgbot-181017.firebaseio.com",
    projectId: "mktgbot-181017",
    storageBucket: "mktgbot-181017.appspot.com",
    messagingSenderId: "797002870076",
  },
  apptURL: "https://appointmentdev.herokuapp.com",
  serverURL: "https://mktg-bot-dev-server.herokuapp.com",
  pythonServerUrl: "https://mktg-seo-service-dev.herokuapp.com",
  // pythonServerUrl:"http://localhost:5000",
  importserver: "http://localhost:5000",
  localServer: "http://127.0.0.1:5000",
  contacts: "dev_contacts",
  interactiveFormURL: "https://interactive-form-staging.herokuapp.com",
  patientIntakeFormUrl: "https://patient-forms-dev.herokuapp.com",
  demoAccId: "UakeKoWZqGdjWiezZzBC",
  cloudFunctionServerUrl: "https://us-central1-mktgbot-181017.cloudfunctions.net",
  /*  firebaseloginconfig: {
      provider: AuthProviders.password,
      method: AuthMethods.password
      https://import-server.herokuapp.com/

    }*/
  dialogflow: {
    angularBot: "https://oauth2.googleapis.com/token",
    "mktgbot-181017-email": "dialogflow-iykntn@mktgbot-181017.iam.gserviceaccount.com",
    "mktgbot-181017-key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCW/auLYWueIHR0\nxTe++LFNmf/DrbVKor1xzDX13MEFWES/TKIB4cXfTLGAfh+hcV7lGrr19fsNKnSZ\nr1+fRTjaWFdIcU2B3mr/eMNhwV6llSCG53xmEmt+YmbaDAT5hhUtE83VPrzzm7Ej\nEuoWCeeP6Rf8CBX8ZYw6ge2GjzsiKUsALfh6o9LFftr3MGwnS+OBgbdo5SuknDms\nqFwO9rFjohQjVtYU//KC4qzyDCIAjuV+KIbbEDusBFm18m6gKoVQTWU9Y92mKHwe\nGefiC6bmyFmIU3hwPIvSgUAATTmNJ1EKkRhgh3pn8Sf4ysxGSMOyUC42kHoKJfnc\nK9ofHPH/AgMBAAECggEAAJJFK+nadma5ChgU9ewK/7gtd5gYr4eeH+gd7ChBsrt6\nxx6LHma0UPwBT/MqO3oqWcOYIYYkxQ/8EPjQ69FfPbRH4EZNfMhSr1TNqHkK2qWN\n0fTeS6SWM4w7x2d0PV18hwckKEJshJ96NB/GMv8mmSzQomuFaccYmMAVHgU8vAxa\ndr2fUHSKjDBxWLKrC5C/p+b0Hfw0ZKAoD8MlsCBqnJofGTbwqGXQLAYd1L6Ytgxc\nmP10yNIb5WONN5O3s09zx4GgqmhRyJJbqZKQTHkjz80glK9KJjlJLA/pypvHwnwx\neOTRc8BVHjyG2J63q5VRWS7b/LknfhdD8wF7ZlW/gQKBgQDQVjQfAzdDlSq3Bhr7\ntKZUrXp1JHM24FZXu62YhVVC1xrwssx52zkPaXoUMeeg+3GfGU3pbKMqSoEXeCIC\nnTPj1n19+mRWKjpyBbiSkhOX8tWQDPXDClH/YRRX/6CpFpkzy+ckg8riqf+3fS3v\n8FJ4IFiQH/Nc+weroDLqDX+JGQKBgQC5iNrTMWEzmJi/quAG6pn5guIT4QQldUEL\nUMOW3ir+A4NRi1bhCc6On03oYLvGjEPVg8QjVstLtKnb6kTH2ow3MiqQhAu4HNDl\nAW/q86UvA37HnIgz1fuwNn+jVcLk1ZJJisBOHPWAcewxXRhumyLAuwsOAMpPYvsq\n24b4hAr+1wKBgDwy/IKNklVz79DM8dUTgRBsxDq/Go+byTc2otW0iUt0DnPIpm77\nmoRKjRzywqRwo4c+b5Xob0lUF/MpImKw/AJ0wvQ4+u8u6o5x+sBXd1PrtiwWopkG\nDWWGrrMyyUCyp/ggjhyt/Fz8lEh34mM3hQzzA8Q5hz+xxaig9LVOyzBZAoGAaLIX\neZGl3i3AFHhiTsTX+nqnBLy3UEPoXnBGzr2z2P82fS956SdVjhnLJtRfYd0I8lWW\nurJhqeja6SQ7h+OFuCfw6Pf6fPyVzISRGS5AClWYlx93pHidvIQkZpEgl20FTcak\nWe/xgXfH5ts3zEJyy/tAc7hycXXTcg3LDjXE1qUCgYBH9ATChLEXWsA28euFTkie\nq3UqnjYZdLM+vBENWaZfMcIzbsMkAt7VnQIyZlM0pgftnDq8sX8CBJ0NhrYC3RWM\nIY1cDINs85pu8VM2vaHH6Ad6sYaim2EfUke0cVCCRGZyHc9chjJQQSgPiM3Cvm2i\nrvprVFDb1DWrJ4dnWGHRIw==\n-----END PRIVATE KEY-----\n",
    "bookappointment-mmjj-key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDesZXqh3V2Yqx8\nHsvL/onkLOyy/FSXQS/JpHUUqKb/gENnPxAgX0zdGv4zek/FSH3mG+duWXus87Zj\nK1b2l4i1zMUZRGao0kjfdvjCbt3DBex1Of5zH7YwJYN0gI8ZgXvEbehSj62obPWO\nmRWCtCdWZovM8bv83a6IpGp/aptNhORCffKDSO1Xm81syPD+IX7itybdDUaSHKdW\nz1tET+MLD3x/dH3X/otGMJ44gCKyOrUvYBxH7MQOXv0m4YXRMRxyN0rf6+2na5yq\nT9gyYPup3l/Fjy9/FfmEARJOMGsbV0ZJuHEiF9nXpIKq46gh8W71KWZsxmNYUo+o\nlbCivSbpAgMBAAECggEABI9840GMSdysvGmyqMkrRF3DcYyI/5ykyDMxQTFEOzLX\n4ladZeggxFd1rFLTdhkWc4cYz57VFG/QZ+Eryaz13B7fcNQAp9MJGeqjHs7kL98h\n7BTxCB8L6u9/cu/5bwJbFur7q4DJ/Z2BnriRIZAIHGRDFDw0toPnZkhZaFy8Otgd\nJl9+w3ycRJ+W8n16yM81zPcq5oqGykzFxIsjAgVSSeXpk9xcM3fvIDz7BIAniPw/\nE9mQolOiazZ7/FyMnO1cXO/1M6d8jF/cvkKvx03nSMh0StepyErBfSLYv9u+5XFR\nCIo/9sj4Q3nQMgrhk5mfoTPKFqfOUdd1x61X5JuEewKBgQD0TJWWJIKgmiG+WEvu\nfxhCLHiO+gnwKLk+60et1B/ImhCFTATEJIFBPQ54IpWaowMQ2JEROH1U5NEqmj2A\nHZguJhuzfccrAPQaUtX0ELOkxnxlZf61aav8Uwnqe7A/pP/WB6RRZfriQKXdzRSP\nvA7FKERzlk1aeJi5OOzNOseoAwKBgQDpXBdP45ntFCfjv3nrSwX1vE3seSxwQ2L8\next+Xgb2OfgV2XC0IlcDeSgRSNpFSCHcztYSbLBy8C1M4tFxMcBw6cE7+384YiqC\nGtZ2VVackJF9j8WViVecSYC2V0gR6YTXByTc+PyJPLQ8MbLY3teK8A+dcypr4wrY\nDKryI5cPowKBgCePw2WmEfVaIH7Ho7xCnrYQp5FX0oYuwhj7VwdCY8dDk8tSTS9K\nThg3gAFacGmzXkq7F3r3b+xwhaLliXIW2M8YYsIFa1x+cglVQsqcbcsQTZLKGp5c\nN3rMI73yO8+Cs9+AK073OdKr73BkxqCkLqwk4jjprSsqfmj4W/i8PRrrAoGBAIrH\nkLmbEsySgxKHqfsLd/TnUrRCb/InsTOUBwrE4oEGgoqD8Om/zl2rbO+yktRHDJRj\nkZ4Nc3Y3bclezs3xl4/PBHMmo0cl4j/TtB1VrReZRl0U5p2Cu06fy+MkzyCP7sdu\nYTblAFKhflKZ/9HH3QmssKyjc+QLd0R7bCJqlgjfAoGBAKY19sILHiu4IdixiSfo\nreURMssoizyfo9UssFL8utS+4lZQbxSkgRAvlxAEc976NG6YkC5R81+8AEAxDGFO\nc83wJaMVzIVf79AmcONlDJ3S+vLNWPUzE9m77jtesWHTFfG0HS5vb9/hzNn6G6Xd\nvsMVc+4+/JYen/9xiLb+0nB1\n-----END PRIVATE KEY-----\n",
    "bookappointment-mmjj-email": "dialogflow-miqngn@bookappointment-mmjj.iam.gserviceaccount.com",
    "mktbot-179201-email": "dialogflow-937@mktbot-179201.iam.gserviceaccount.com",
    "mktbot-179201-key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQD1ctEGBhLJLX2z\nftXolIHPzONsBc3e6n/GAAuU5JQm1Gz488pOIWN0q0zINg4vdCGNJDguU5nGGYSM\nusY5wdKHmaEJnI+aQeTsoZun37HU1gy9d1B/AMoOKSJvJ5vYAaciXjjm97cdMwlJ\nMwkJziECzvwg0/AUYq6M+FbpL4sTJBbx/FULeFc2jt9hsIILpXBJokGKjg7bPRle\nCCvqgKaQ52QqeRsKvgPBmm2ugR+UL+XLd9lXZCAG14vTdIoO3zOXV+xFxtgm5yqn\ntm2jyYe1TBYvrvNutGP+GnKNycJdSq+/1p2IKbBjtUBNx0jSLIu8UiCF9rnMEdyL\n1xG3YkHzAgMBAAECggEAE/5i1X1IKXgZrbXuwc/bFSzI98MvRnHOr7fQObQ290TF\nja/6MkwY+Gd9zS9OD4KDuyYQp4Fq3M62U+pg/FJ0DlSu4AQPVI76kWYGsnGP4FkB\nV6uw9G+Bhg7Gvcw5rypPTYbkVrgmA+xk3MAaA+D1Im/u7NOm0lpJgrU2Cy+ywf+9\nQVyizi8x8o17PnBcjrWxIiP4wjlhjOiNnD2tK5XokGz/WzuVWERpeNG7xkeAThOu\nqXQkComOpZOAUv356p6dnE8xxq3MoisVZlsq5EtJaaIRsVbiwJrkFfm+8xIaCMLZ\nD4JfI51/ZNegvTSx1dFVcnCAOVEJcqoZmfh1WV1I9QKBgQD9qtr7+xAbEqyP4c83\nJ5Zq+fkCtllAjDy/SstWtKZMk7cVk4FN2p4/TwN+KyxZSj7mQoji82U0f2aW7wYF\nUO3ojP+fRYCNwYKR2jZ7QsTlz4EheEgd0y/Djcyp9AIAS5pSHg6jxnqWJT1GjXeH\nACfsGuNaM1tIQKb7Tx4b2S+zrwKBgQD3tJ0JOuTcIBM7oGlWQJ78HLSXXaJqHqjD\niO0EuEJCecsD/KgG7mQ1p6PtL8hpFl4zcCajA8vXNt9HTQEFX2ywxwcKFoV6eJaD\nZPU0joR3dmvrnBB9WXSHdhcewg8PWlH6TZP2fxHpDSW7Ok785gfS4TzGea1FApRr\nOFsZmhKy/QKBgBnXEb+hb56SZGmzwvwHWFdFZ4xTLvxYTMffwp7ugBJE6d2UzRM1\nNfGVBvIPS9E8svOZy3/y81hJshWl+6MG/3U1B8Udu161W6un4uzjfFM8tBbIve/r\n123AQBz/8kQ9b1b2IN+mFE6/NGK/fnGOHoL6qjZCDQZrL5diE9saYLWZAoGBAK6g\nW9z38gPlcOmmStyNrgdaTU4wqqFZf+xpiX68T6oBpsfmxKYuReM1jfFWsFi1di+Z\nLwPZV0kf3ASuGrwo670scqAABJW0kZeMAvnMR0LY73goi89y0x30eJQshuVx7T0i\nl9888el0ZVc64hiUKovWUjwx558xFGUKU98WqXwZAoGBAJ0rSUEgnFwGverMZqZ6\nABPa9xTlp2U+K2L8z4ra/jMlHeg358CR7dslnW0tLk93oxCjm9TQQaRDQsUuIHAa\nLlI3PoYvLw2ybGHqIisQ3f29ofaXuLEMimggnTcFYBBKm4KNWc8lsqpm7XJyumkQ\nhbL7J5P6rr35Xd49OzPGgl/e\n-----END PRIVATE KEY-----\n"
  },

  campaignURL: 'https://mktg-campaign-dev.herokuapp.com'
};

//var secondaryApp = firebase.initializeApp(environment.firebase, "Secondary");
