<div class="p-col-12 p-md-6 p-lg-4" style="padding:1em 2em;">
    <span style="padding-right:10px;">Enter value :</span>
    <p-inputMask mask="**.**%" [(ngModel)]="enteredValue" placeholder="99.99%" id="FormControlInput"
        (change)="updateTable()">
    </p-inputMask>
</div>
<div class="container-fluid view">
    <div class="mat-elevation-z8" id="pTable">
        <p-table [value]="dataSourceRank" [columns]="displayedColumns" #console [globalFilterFields]="filterColumns"
            [paginatorShow] [showCurrentPageReport]="true" sortMode="multiple" [autoLayout]="true"
            [resizableColumns]="true">

            <ng-template pTemplate="caption">
                <div style="text-align: left; padding:10px">
                    <input class="filterInput" type="text" pInputText size="50" placeholder="Search"
                        (input)="console.filterGlobal($event.target.value ,'contains')" style="width:100%">
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th [class]="col.class" *ngFor="let col of displayedColumns"
                        [pSortableColumnDisabled]="disableSortColumns.includes(col.value)" [pSortableColumn]="col.value"
                        pResizableColumn>
                        {{col.header|titlecase}}
                        <p-sortIcon [field]="col.value" *ngIf="!disableSortColumns.includes(col.value)"
                            [field]="col.value">
                        </p-sortIcon>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-data>
                <tr>
                    <td class="page"><a href="{{data.page}}" target="_blank">{{data.displayUrl}}</a></td>
                    <td class="mid">{{data.deltaImpressionDiff}}</td>
                    <td class="mid">{{data.deltaImpressionDiffPercentage |number:'1.2-2'}} %</td>
                    <td class="mid">{{data.deltaImpressionSharePercentage |number:'1.2-2'}} %</td>
                    <td class="mid">{{data.lastTotalImpressionShare |number:'1.2-2'}} %</td>
                    <td class="mid">{{data.prevTotalImpressionShare |number:'1.2-2'}} %</td>
                    <td class="mid">{{data.deltaPositionDiff |number:'1.2-2'}}</td>
                    <td class="mid">{{data.lastWeekPositionCount |number:'1.2-2'}}</td>
                    <td class="mid">{{data.prevWeekPositionCount |number:'1.2-2'}}</td>
                    <td class="mid">{{data.lastWeekClickCount}}</td>
                    <td class="mid">{{data.prevWeekClickCount}}</td>
                    <td class="mid">{{data.lastWeekImpressionsCount}}</td>
                    <td class="mid">{{data.prevWeekImpressionsCount}}</td>
                    <td class="mid">{{data.lastWeekCtrCount |number:'1.2-2'}} %</td>
                    <td class="mid">{{data.prevWeekCtrCount |number:'1.2-2'}} %</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>