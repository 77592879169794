<div class="container-fluid view">
    <div class="row text-center" id="subHeader1">
      <div class="col-md-4" style="margin: auto;">
        <div class="searchContainer_new">
          <!-- <i class="fa fa-search searchIcon"></i>
          <input class="searchBox" [disabled]="'true'" type="text" name="search" placeholder="Search Contacts"> -->
          <!-- (input)="dt.filterGlobal($event.target.value, 'contains')" -->
        </div>
      </div>
      <div class="col-md-4" style="margin: 2.5% auto;">
        <div id="pageTitle" class="center">Organic Rankings</div>
      </div>
      <div class="col-md-4" style="display: flex;flex-direction: row-reverse;">
        <button *ngIf="!showSettings && !showHistory" mat-mini-fab id="button" class="format" (click)="showSettings=true" matTooltip="Edit Configurations">
          <i class="material-icons">settings</i>
        </button>
        <button *ngIf="showSettings || showHistory" mat-mini-fab id="button" class="format"  (click)="showSettings=false;showHistory= false" matTooltip="Close">
          <i class="material-icons">close</i>
        </button>
         <button *ngIf="!showSettings && !showHistory" mat-mini-fab id="button" class="format" (click)="showHistory = true"
          matTooltip="History">
          <i class="material-icons add">history</i>
        </button>
        <button *ngIf="!showSettings && !showHistory" mat-raised-button id="button" class="format"
          (click)="processOrganicRanks()" [disabled]="!enableGetOrganicRank" [matTooltip]="processingOrganicRanks?'Already started processing please wait':'Get Local Ranks'">Get Organic Ranks
        </button>
      </div>
      <div *ngIf="showSettings" style="width:100%;">
        <app-organic-rank-configuration (close)="closeSettings($event)"></app-organic-rank-configuration>
      </div>
      <div *ngIf="showHistory" style="width:100%;">
        <app-organic-rank-history [websiteUrl]="websiteUrl" (close)="closeHistory($event)">
        </app-organic-rank-history>
      </div>
      <div *ngIf="!showSettings && !showHistory" style=" width: 100%;">
        <div class="row">
          <div class="col">
            <mat-tab-group [selectedIndex]="0">
              <div class="text-center">
                <mat-tab label="Rankings" class="tabWidth" [selectedIndex]="1">
                  <app-organic-ranks [configurations]="resultConfig" [organicRanks]="organicRanks" [status]="status" [loading]="loading" >
                  </app-organic-ranks>
                </mat-tab>
                <mat-tab label="Rankings Analysis" class="tabWidth" [selectedIndex]="2">
                  <app-organic-rank-analysis [loading]="loading && rankAnaysisLoading" [organicRankAnalysis]="organicRankAnalysis">
                  </app-organic-rank-analysis>
                </mat-tab>
              </div>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
