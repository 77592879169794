<div *ngIf="!goPrevious">
  <div class="col">
    <div class="row-md-3">
      <div class="row">
        <div class="col-6" *ngIf="data.email">
          <div class="col">
            <div class="row-md-4" >
              Email
            </div>
            <hr>
            <div class="row-md-4">
              <div class="row">
                <div class="col-2">
                  <b>Subject</b>
                </div>
                <div class="col-10">
                  {{data.emailSubject}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-2">
                  <b>Message</b>
                </div>
                <div class="col-10">
                  {{data.emailBody}}
                </div>
              </div>
            </div>
            <div class="row-md-4">
            </div>
          </div>
        </div>
        <div class="col-6" *ngIf="data.sms">
          <div class="col">
            <div class="row-md-6">
              SMS
            </div>
            <hr>
            <div class="row-md-6">
              <div class="row">
                <div class="col-2">
                  <b>Message</b>
                </div>
                <div class="col-10">
                  {{data.smsBody}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br>
    <br>

    <div class="row-md-3">
      <div class="mat-elevation-z8" style="margin:10px;">
        <p-table [value]="forms">
          <ng-template pTemplate="caption">
            <div style="display: flex;">
              Forms
            </div>
          </ng-template>
          <ng-template pTemplate="body" let-forms let-index>
            <tr>
              <td style="width: 25%;border: 0px;height:50px;">
                <input readonly=true class="myinput" hover="forms.sr_no" matInput [value]="forms.sr_no">
              </td>
              <td style="width: 75%;border: 0px;height:50px;">
                <input readonly=true class="myinput " hover="forms.name" matInput [value]="forms.name">
              </td>
              <!-- <td style="width: 25%;border: 0px;height:50px;">
                <input readonly=true #tooltip="matTooltip" matTooltipPosition="above" [matTooltip]="contact.email"
                  class="myinput" matInput placeholder="Enter" [value]="contact.email"
                  (change)="updateValue($event.target.value, 'email', contact)" [disabled]="contact.active==false">
              </td>
              <td style="width: 25%;border: 0px;height:50px;">
                <input readonly=true class="myinput" matInput placeholder="Enter" [value]="contact.phone"
                  (change)="updateValue($event.target.value, 'phone', contact)" [disabled]="contact.active==false">
              </td> -->
            </tr>

          </ng-template>

        </p-table>
      </div>
    </div>
    <br>

    <div class="row-md-3">
      <div class="mat-elevation-z8" style="margin:10px;">
        <p-table [value]="contact">
          <ng-template pTemplate="caption">
            <div style="display: flex;">
              Contacts
            </div>
          </ng-template>
          <ng-template pTemplate="body" let-contact let-index>
            <tr [ngStyle]="contact.active==false &&{'background-color':'#E7E7E7'} &&{'height':'100px'} ">

              <td style="width: 20%;border: 0px;height:50px;">
                <input readonly=true class="myinput" hover="contact.first_name" matInput 
                  [value]="contact.first_name" (change)="updateValue($event.target.value, 'first_name', contact)"
                  [disabled]="contact.active==false">
              </td>
              <td style="width: 20%;border: 0px;height:50px;">
                <input readonly=true class="myinput " matInput  [value]="contact.last_name"
                  (change)="updateValue($event.target.value, 'last_name', contact)" [disabled]="contact.active==false">
              </td>
              <td style="width: 30%;border: 0px;height:50px;"  *ngIf="data.email">
                <input readonly=true #tooltip="matTooltip" matTooltipPosition="above" [matTooltip]="contact.email"
                  class="myinput" matInput  [value]="contact.email"
                  (change)="updateValue($event.target.value, 'email', contact)" [disabled]="contact.active==false">
              </td>
              <td style="width: 5%;border: 0px;height:50px;" *ngIf="data.email">
                <mat-icon class="material-icons"  tooltipPosition="top" pTooltip="Email is correct" *ngIf="validateEmail(contact.email)" [ngStyle]="{'color':'Green'}">email</mat-icon>
                <mat-icon class="material-icons" tooltipPosition="top" *ngIf="contact.email==='' else Go"  pTooltip="Email for this contact is not present,it will be skipped" [ngStyle]="{'color':'Red'}">email</mat-icon>           
                <ng-template #Go><mat-icon class="material-icons" tooltipPosition="top"  pTooltip="Email for this contact is not valid,it will be skipped" *ngIf="!validateEmail(contact.email)" [ngStyle]="{'color':'Red'}">email</mat-icon></ng-template>
              </td>
              <td style="width: 20%;border: 0px;height:50px;" *ngIf="data.sms">
                <input readonly=true class="myinput" matInput [value]="contact.phone"
                  (change)="updateValue($event.target.value, 'phone', contact)" [disabled]="contact.active==false">
              </td> 
              <td style="width: 5%;border: 0px;height:50px;" *ngIf="data.sms">
                <mat-icon class="material-icons"  tooltipPosition="top" *ngIf="allnumeric(contact.phone) && contact.phone !== '' && ((contact.phone).length == 10)  else Go" [ngStyle]="{'color':'Green'}" pTooltip="Phone Number is correct">message</mat-icon>
                <ng-template #Go><mat-icon class="material-icons" tooltipPosition="top"  *ngIf="contact.phone === '' else GoNext" [ngStyle]="{'color':'Red'}" pTooltip="Phone Number for this contact is not present,it will be skipped">message</mat-icon></ng-template>
                <ng-template #GoNext><mat-icon class="material-icons" tooltipPosition="top"  *ngIf="!allnumeric(contact.phone) else GoNext2" [ngStyle]="{'color':'Red'}" pTooltip="Phone Number for this contact is not correct,it will be skipped">message</mat-icon></ng-template>
                <ng-template #GoNext2><mat-icon class="material-icons" tooltipPosition="top"  *ngIf="((contact.phone).length != 10) else Golast" [ngStyle]="{'color':'Red'}" pTooltip="Phone Number for this contact is not 10 digits,it will be skipped">message</mat-icon></ng-template>
                <!-- <ng-template #Golast><mat-icon class="material-icons" tooltipPosition="top"  [ngStyle]="{'color':'Red'}" pTooltip="Phone Number for this contact is greater then 10 digits,it will be skipped">message</mat-icon></ng-template> -->
              </td>
            </tr>
          </ng-template>

        </p-table>
      </div>
    </div>
    <br>
    <!-- <div class="row-md-3">
      <div class="row text-center" *ngIf="showConfirm else loading" style="padding-top: 50px;">
        <div class="col-md-6">
          <button mat-raised-button color="primary" (click)="previous()">Previous</button>
        </div>
        <div class="col-md-6">
          <button mat-raised-button color="primary" (click)="save()">Confirm and Send</button>
        </div>

      </div>

      <ng-template *ngIf="!goLast else done" #loading>
        <div class="center">
          <mat-spinner></mat-spinner>
        </div>
      </ng-template>

      <ng-template #done>
        <div class="text-center" style="font-size: 20px;padding: 100px;">
          Form Sending Job is added in the queue and it will send a message soon ......
        </div>
      </ng-template>

    </div> -->
  </div>
</div>

<!-- <div *ngIf="goPrevious">
  <div *ngIf="(data.form) == 'survey'">
    <app-survey-form [previousData]="data"></app-survey-form>
  </div>
  <div *ngIf="(data.form) == 'patient'">
    <app-patient-form [previousData]="data"></app-patient-form>
  </div>
</div> -->