import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '../_services/account.service';
import { MessageService } from '../_services/message.service';

@Component({
  selector: 'app-algolia-contact-search',
  templateUrl: './algolia-contact-search.component.html',
  styleUrls: ['./algolia-contact-search.component.scss']
})
export class AlgoliaContactSearchComponent implements OnInit {
  @Output() sendContactEmittor = new EventEmitter<any>();
  @Input() inputText;
  @Input() selectedContacts;
  twilioCredentials: any;
  algoliaContactList: any;
  isFirstScreen: boolean = true;
  loading: boolean = false;
  previousSelectedContacts: any = [];
  isSelectedStatus: any = [];
  noRecordFound: boolean;
  constructor(
    private accountService: AccountService,
    public dialog: MatDialog,
    public messageService: MessageService
  ) { }

  ngOnChanges(changes: SimpleChange) {
    // console.log(changes);
    if (changes['inputText']) {
      if (changes['inputText']['firstChange']) {
        if (changes['inputText']['currentValue']) {
          this.inputText = changes['inputText']['currentValue'];
          this.getTwilioCredentials().then(resp => {
            console.log("on change", this.inputText);
            this.fetchContactByPagingAlgolia();
          });
        }
      }
    }
    if (changes['selectedContacts']) {
      if (changes['selectedContacts']['firstChange']) {
        if (changes['selectedContacts']['currentValue']) {
          this.selectedContacts = changes['selectedContacts']['currentValue'];
          console.log(this.selectedContacts);
        }
      }
    }

  }

  ngOnInit(): void {
    this.getTwilioCredentials().then(resp => {
      if (this.inputText == undefined)
        this.inputText = "";
      console.log("on init", this.inputText);
      this.fetchContactByPagingAlgolia();
    }
    )
  }

  async getTwilioCredentials() {
    this.twilioCredentials = await this.accountService.getTwilioCredentials("twilio")
  }

  fetchContactByPagingAlgolia() {
    this.loading = true;
    this.isSelectedStatus = [];
    if (this.twilioCredentials !== undefined) {
      this.messageService.fetchContactByPagingAlgolia(this.inputText).then((resp: any) => {
        this.algoliaContactList = resp.hits;
        if (this.algoliaContactList.length > 0)
          this.noRecordFound = false;
        else
          this.noRecordFound = true;

        for (var i = 0; i < this.algoliaContactList.length; i++) {
          let index = this.selectedContacts.findIndex(ele => (JSON.stringify(ele) === JSON.stringify(this.algoliaContactList[i])));
          if (index >= 0) {
            this.isSelectedStatus.push({ 'isSelected': true });
          }
          else {
            this.isSelectedStatus.push({ 'isSelected': false });
          }
        }
        this.loading = false;
      })
    }
    else {
      console.log("Not found");
      this.loading = false;
    }
  }
  selectContact(i, data) {
    console.log(data);
    this.isSelectedStatus[i]['isSelected'] = !this.isSelectedStatus[i]['isSelected'];
    if (this.isSelectedStatus[i]['isSelected']) {
      this.selectedContacts.push(data);
      console.log("checked")
    }
    if (!this.isSelectedStatus[i]['isSelected']) {
      console.log("not checked")
      let index = this.selectedContacts.findIndex(ele => (JSON.stringify(ele) === JSON.stringify(data)));
      console.log(index);
      this.selectedContacts.splice(index, 1);
    }
    let dataToSend = {
      "inputText": this.inputText,
      "selectedContacts": JSON.stringify(this.selectedContacts)
    }
    console.log(this.selectedContacts);
    this.sendContactEmittor.emit(dataToSend);
  }

  getContacts(i, event, data) {

    console.log(data);
    if (event.checked) {
      this.selectedContacts.push(data);
      console.log("checked")
    }
    if (!event.checked) {
      console.log("not checked")
      let index = this.selectedContacts.findIndex(ele => (JSON.stringify(ele) === JSON.stringify(data)));
      console.log(index);
      this.selectedContacts.splice(index, 1);
    }
    let dataToSend = {
      "inputText": this.inputText,
      "selectedContacts": JSON.stringify(this.selectedContacts)
    }
    console.log(this.selectedContacts);
    this.sendContactEmittor.emit(dataToSend);
    //(change)="getContacts(i,$event,content)"
    //[checked] = "isSelectedStatus[i]['isSelected']"
  }

  Next() {
    let dataToSend = {
      "inputText": this.inputText,
      "selectedContacts": this.selectedContacts
    }
    this.sendContactEmittor.emit(dataToSend);
  }
  back() {
    this.isFirstScreen = true;
  }
  getSendSource(event) {
    // console.log(event)
  }

}
