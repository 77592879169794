<div class="row">
  <div class="col-md-4">
    <div id="pageTitle">Messages</div>
    <div class="bg-gray px-4 py-2 bg-light">
      <div class="searchContainer">
        <i class="fa fa-search searchIcon"></i>
        <input class="searchBox" type="text" name="search" placeholder="Search..." [(ngModel)]="searchText"
          class="form-control search">
        <i *ngIf="searchText?.length > 0" class="fa fa-close searchIcon" (click)="clear()"></i>
      </div>
    </div>
    <div>
      <app-chatbot-list [contactlist]="contactlist" [hasMoreRecords]="hasMoreRecords"
        (rejectEmiter)="rejectedRequest($event)" (getMessagesOfSelctedContact)="getMessagesOfSelctedContact($event)"
        (newContacts)="fetchNextContacts()">
      </app-chatbot-list>
    </div>
  </div>

  <div class="col-md-8">
    <div *ngIf="!isReady">
      <img src="../../assets/images/new-msg.jpg" id="img">
      <h1>Please select contact to start conversation</h1>
    </div>
    <div *ngIf="isReady">
      <app-chat-bot [contactDetailsObject]="userDetails">
      </app-chat-bot>
    </div>
  </div>
</div>