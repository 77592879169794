<div class="container-fluid view">
  <div class="spinner-container" *ngIf="!flag">
    <mat-spinner></mat-spinner>
  </div>

  <div class="row" id="subHeader1">
    <div class="col-md-3" style="margin: auto;">
      <div class="searchContainer">
        <i class="fa fa-search searchIcon"></i>
        <input matInput class="searchBox" type="text" name="search" (keyup)="applyFilter($event.target.value)"
          placeholder="Search">
      </div>
    </div>

    <div class="col-md-6" style="margin: auto;">
      <div id="pageTitle" class="center">All Accounts</div>
    </div>

    <div class="col-md-3" style="margin: auto;">
      <div id="action-btn">
        <button id="button" mat-mini-fab class="format" (click)="hideForm()" matTooltip="Add new account">
          <i class="material-icons add">add</i>
        </button>
      </div>
    </div>
  </div>

  <div id="subHeader2">
    <!-- Add account form -->
    <div *ngIf="isOpened">
      <form style="text-align:center" #formData='ngForm' (ngSubmit)="addAccount(formData)" name='myForm'>
        <div class="row">
          <div class="col-md-5">
            <!-- <label> Name </label> -->
            <mat-form-field style="width:100%" class="example-full-width">
              <input matInput name="name" [(ngModel)]="name" placeholder="Name" required />
            </mat-form-field>
          </div>
          <div class="col-md-5">
            <!-- <label> Website url </label> -->
            <mat-form-field style="width:100%" class="example-full-width">
              <input matInput name="url" [(ngModel)]="url" placeholder="Website url" required />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <center>
              <button mat-raised-button id="button" type="Add" [disabled]="!formData.valid">Add</button>
            </center>
          </div>
        </div>
      </form>
    </div>

    <mat-table class="contacts-table mat-elevation-z8" [dataSource]="dataSource" style="margin-top: 20px; width:100%">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Account Name</mat-header-cell>
        <mat-cell *matCellDef="let accData">
          <a (click)="accChange(accData.id)">{{accData.name}}</a>
        </mat-cell>
      </ng-container>

      <!-- Url Column -->
      <ng-container matColumnDef="url">
        <mat-header-cell *matHeaderCellDef>Website URL</mat-header-cell>
        <mat-cell *matCellDef="let accData">{{accData.url}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let accData; columns: displayedColumns"></mat-row>

    </mat-table>
  </div>
</div>