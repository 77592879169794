<!-- <div class="pageDiv2"> -->
<div *ngIf="loading; else loaded">
  <div style="height: fit-content;width:fit-content;margin: auto;">
    <mat-spinner></mat-spinner>
  </div>
</div>
<ng-template #loaded>
  <div *ngIf="targetkwResults.length >0 ; else noKwResults">
    <p-table [value]="targetkwResults" dataKey="keyword">
      <ng-template pTemplate="header">
        <tr style="text-align: center;">
          <th style="width: 3rem"></th>
          <th>Keyword</th>
          <th pSortableColumn="rank">Rank <p-sortIcon field="rank"></p-sortIcon>
          </th>
          <th pSortableColumn="search_volume.search_volume_total">Search Volume<p-sortIcon
              field="search_volume.search_volume_total"></p-sortIcon>
          </th>
          <th>Competition</th>
          <th>CPC</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-expanded='expanded'>
        <tr>
          <td style="text-align: center; align-items: center;padding:0;">
            <!-- <i [pRowToggler]="rowData" style="padding: 0px 0.5px; font-weight: 100;" class="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i> -->
            <button type="button" pButton pRipple [pRowToggler]="rowData"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" style="margin:auto;"></button>
          </td>
          <td>{{rowData.keyword}}</td>
          <td>{{rowData.rank}}</td>
          <td>{{rowData.search_volume?.search_volume_total}}</td>
          <td>{{rowData?.search_volume?.competition_total | number:'1.0-4'}}</td>
          <td>{{rowData?.search_volume?.cpc_total | number:'1.0-4'}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-rankings>
        <tr>
          <td colspan="6">
            <div class="p-p-3">
              <p-table [value]="rankings.localResults">
                <ng-template pTemplate="header">
        <tr>
          <td colspan="3">Name</td>
          <td>Rank</td>
          <td>Ratings</td>
          <td>Total Review</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-domainInfo datakey="rank">
        <tr>
          <td colspan="3">{{domainInfo.name}} <i *ngIf="domainInfo.website" class="fas fa-external-link-alt openlink"
              matTooltip="Open link" (click)="openLink(domainInfo.website)"></i>
          </td>
          <td>{{domainInfo.rank}}</td>
          <td>{{domainInfo.rating}}</td>
          <td>{{domainInfo.user_ratings_total}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">There are no competitors for this keyword </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  </td>
  </tr>
</ng-template>
</p-table>
</div>
<ng-template #noKwResults>
  You don't have keywords to rank.
</ng-template>
</ng-template>
<!-- </div> -->