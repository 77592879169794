import { Component, Input, OnInit } from '@angular/core';
import { Welcome_screen } from '../../_services/form';
import { FormBuilderService } from '../../_services/form-builder.service';
import { PreviewComponent } from '../preview/preview.component';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  @Input()
  welcome_screen: Welcome_screen;

  @Input() previewRef: PreviewComponent;
  useCustom: boolean = false;
  constructor(
    private formBuilderService: FormBuilderService
  ) { }

  ngOnInit() {

  }

  onFocus(welcome_screen: string) {
    this.previewRef.scroll(welcome_screen);
  }
}
