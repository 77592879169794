<div class="row" id="subHeader1">
  <div id="pageTitle">
    <a [routerLink]="['/main']"> <i class="pi pi-chevron-left"></i>Campaigns</a>
  </div>

  <div id="subPageTitle">
    Facebook Campaigns
  </div>
</div>

<div class="container-fluid view">
  <div *ngIf="!isReady">
    <!--Fetching your campaigns ...-->
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
  <div *ngIf="isReady">
    <!---Spend,Conversion,ROI--->
    <div class="scrolling-wrapper-flexbox">
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi">
            {{((report?.summary?.spend) * acc?.facebook_factor) | currency: acc?.account_currency :'symbol':'1.0-0' }}
          </div>
          <div class="metric-caption kpi"> Spend</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi"> {{totalConversions}} </div>
          <div class="metric-caption kpi"> Conversions</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi">
            {{(((report?.summary?.spend) * acc?.facebook_factor)/totalConversions) | currency:acc?.account_currency:'symbol':'1.0-1'}}
          </div>
          <div class="metric-caption kpi"> Cost per conversion</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>

          <div class="metric kpi">{{fb_totalConvValue | currency: acc?.account_currency :'symbol':'1.0-0'}}</div>

          <div class="metric-caption kpi"> Conversion Value </div>

        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>

          <div class="metric kpi" *ngIf="report?.summary?.spend != 0; else zero_spend">
            {{fb_totalConvValue / ((report?.summary?.spend) * acc?.facebook_factor) | number:'1.0-1'}}X</div>
          <ng-template #zero_spend>
            <div class="metric kpi">0X</div>
          </ng-template>
          <div class="metric-caption kpi"> ROI</div>
        </mat-card-content>
      </mat-card>
    </div>
    <!---Engagements------>
    <div class="row">
      <div class="col">
        <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
          <mat-card-header>
            <mat-card-title class="">
              <h6>Engagement</h6>
            </mat-card-title>
            <mat-card-subtitle>
              <div class="row">
                <div class="col">Website visits and behavior of users coming from Facebook.</div>
              </div>
              <mat-divider></mat-divider>

            </mat-card-subtitle>
          </mat-card-header>
          <center>
            <div *ngIf="!isReady">
              <!--Fetching your campaigns ...-->
              <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
          </center>
          <mat-card-content>
            <div class="row">
              <div class="col">
                <ul>
                  <li>Total {{users | number}} quality users acquired from Facebook marketing with {{sessions | number}}
                    visits to the website</li>
                  <li>Spending on an average {{duration}} mins per visit and browsing {{ppv | number:'1.0-2'}} pages per
                    visit.</li>
                </ul>
              </div>
            </div>

            <div class="scrolling-wrapper-flexbox">
              <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
                <mat-card-header>
                  <mat-card-title class="metric-caption"></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="metric-caption"> Users </div>
                  <div class="metric"> {{users | number}} </div>
                </mat-card-content>
              </mat-card>
              <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
                <mat-card-header>
                  <mat-card-title class="metric-caption"></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="metric-caption"> Sessions </div>
                  <div class="metric"> {{sessions | number}} </div>
                </mat-card-content>
              </mat-card>
              <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
                <mat-card-header>
                  <mat-card-title class="metric-caption"></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="metric-caption"> Bounce Rate </div>
                  <div class="metric"> {{bounce_rate | number:'1.0-2'}}% </div>
                </mat-card-content>
              </mat-card>
              <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
                <mat-card-header>
                  <mat-card-title class="metric-caption"></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="metric-caption"> Pages/Session </div>
                  <div class="metric"> {{ppv | number:'1.0-2'}} </div>
                </mat-card-content>
              </mat-card>
              <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
                <mat-card-header>
                  <mat-card-title class="metric-caption"></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="metric-caption"> Avg. Session Duration </div>
                  <div class="metric"> {{duration}} </div>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="">
              Campaigns
            </mat-card-title>
          </mat-card-header>
          <center>
            <div *ngIf="!isReady">
              <!--Fetching your campaigns ...-->
              <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
          </center>
          <mat-card-content>

            <div>
              <mat-list role="list">
                <mat-divider></mat-divider>

                <div *ngFor="let campaign of report['data'];let i=index;">
                  <mat-list-item role="listitem">
                    <h6>{{ campaign?.campaign_name }}</h6>
                    <span class="spacer"></span>
                    <a [routerLink]="['/fb-campaign', campaign.campaign_id]">
                      <mat-icon>chevron_right</mat-icon>
                    </a>
                  </mat-list-item>
                  <div class="row desktop-view">
                    <div class="col-2 metric">
                      {{(campaign.spend * acc?.facebook_factor) | currency: acc?.account_currency:'symbol':'1.0-0' }}
                    </div>
                    <div class="col-2 metric"> {{facebookService.getTotalConversions(campaign) | number:'1.0-0' }}</div>
                    <div class="col-2 metric"
                      *ngIf="facebookService.getTotalConversions(campaign)>0; else zero_conversions_desktop">
                      {{((campaign?.spend) * acc?.facebook_factor) / facebookService.getTotalConversions(campaign) | currency:acc?.account_currency:'symbol':'1.0-0'}}
                    </div>
                    <div class="col-2 metric" *ngIf="campaign.conversion_value > -1; else conv_value_notSet_desktop">
                      {{campaign.conversion_value | currency: acc?.account_currency :'symbol':'1.0-0'}}</div>
                    <div class="col-2 metric" *ngIf="campaign.conversion_value > -1; else conv_value_notSet_desktop">
                      {{campaign.conversion_value / (campaign.spend * acc?.facebook_factor) | number:'1.0-1'}}X</div>
                    <ng-template #zero_conversions_desktop>
                      <div class="col-2 metric"> {{0 | currency:acc?.account_currency:'symbol':'1.0-0'}}</div>
                    </ng-template>
                    <ng-template #conv_value_notSet_desktop>
                      <div class="col-2 metric">?</div>
                    </ng-template>
                  </div>


                  <div class="row desktop-view">
                    <div class="col-2 metric-caption"> Spend</div>
                    <div class="col-2 metric-caption"> Conversions</div>
                    <div class="col-2 metric-caption"> Cost per conversion</div>
                    <div class="col-2 metric-caption"> Conversion value</div>
                    <div class="col-2 metric-caption"> ROI</div>
                  </div>

                  <!-- for mobile view-->

                  <div class="row mobile-view">
                    <div class="col-2 metric">
                      {{(campaign.spend * acc?.facebook_factor) | currency: acc?.account_currency:'symbol':'1.0-0' }}
                    </div>
                    <div class="col-2 metric txtspace">
                      {{facebookService.getTotalConversions(campaign) | number:'1.0-0' }}</div>
                    <div class="col-2 metric txtspace"
                      *ngIf="facebookService.getTotalConversions(campaign)>0; else zero_conversions_mobile">
                      {{((campaign?.spend) * acc?.facebook_factor) / facebookService.getTotalConversions(campaign) | currency:acc?.account_currency:'symbol':'1.0-2'}}
                    </div>
                    <ng-template #zero_conversions_mobile>
                      <div class="col-2 metric txtspace"> {{0 | currency:acc?.account_currency:'symbol':'1.0-2'}}</div>
                    </ng-template>
                  </div>
                  <div class="row mobile-view">
                    <div class="col-2 metric-caption"> Spend</div>
                    <div class="col-2 metric-caption txtspace">Conversions</div>
                    <div class="col-2 metric-caption txtspace">Cost per conversion</div>
                  </div>
                  <div class="row mobile-view">
                    <div class="col-2 metric" *ngIf="campaign.conversion_value > -1; else conv_value_notSet_desktop">
                      {{campaign.conversion_value | currency: acc.account_currency :'symbol':'1.0-0'}}</div>
                    <div class="col-2 metric txtspace"
                      *ngIf="campaign.conversion_value > -1; else conv_value_notSet_mobile">
                      {{campaign.conversion_value / (campaign.spend * acc.facebook_factor) | number:'1.0-1'}}X</div>
                    <ng-template #conv_value_notSet_mobile>
                      <div class="col-2 metric txtspace">?</div>
                    </ng-template>
                  </div>
                  <div class="row mobile-view">
                    <div class="col-2 metric-caption"> Conversion value</div>
                    <div class="col-2 metric-caption txtspace"> ROI</div>
                  </div>

                  <mat-list-item>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </div>
              </mat-list>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>