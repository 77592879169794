import { Injectable } from "@angular/core";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { AccountService } from "./account.service";
import { Subject } from "rxjs";
import { AngularFirestore, docChanges } from "angularfire2/firestore";
import * as moment from "moment";
import { AnimationKeyframesSequenceMetadata } from '@angular/animations';
import { Calendar } from 'primeng/calendar';

@Injectable()
export class AppointmentService {
  private appointmentsSource = new Subject<any>();
  appointments$ = this.appointmentsSource.asObservable();

  private apptSource = new Subject<any>();
  appointment$ = this.apptSource.asObservable();

  public allAppointments: any;

  providersArray = [];
  providerstatus: any;
  providersCount: number;
  provider: any;

  selectedCalendar = [];
  selectedLocation = [];
  selectedProvider = [];
  selectedSource = [];
  selectedStatus = [];

  constructor(
    private accountService: AccountService,
  ) {
    this.accountService.accChange$.subscribe(
      acc => {
        let docId = sessionStorage.getItem("current_acc_id");
        firebase.firestore().collection('accounts').doc(docId)
          .collection('appointments').onSnapshot(
            appointmentSnap => {
              appointmentSnap.docChanges().forEach(
                docChange => {
                  this.apptSource.next(docChange);
                });
            });
      });

    //  this.filterProvider();
    firebase.firestore().collection('accounts').doc('${sessionStorage.getItem("current_acc_id")}')
      .collection('appointments').onSnapshot(
        appointmentSnap => {
          appointmentSnap.docChanges().forEach(
            docChange => {
              this.apptSource.next(docChange);
            });
        });
  }

  async fetchAppointments(after_date: any, before_date: any) {
    let appointments = [];
    this.updateAppointments(appointments);
    let apptsDocsSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("appointments")
      .where(
        "appt_start",
        ">",
        firebase.firestore.Timestamp.fromDate(after_date)
      )
      .where(
        "appt_start",
        "<",
        firebase.firestore.Timestamp.fromDate(before_date)
      )
      // .where(
      //   "calendarReference", "in", [firebase.firestore().collection("accounts").doc("g8Uc7ZDmIxa5SXbzJYP0").collection("calendars").doc('5Gy98pMJBPwEbTzOrkpu'), firebase.firestore().collection("accounts").doc("g8Uc7ZDmIxa5SXbzJYP0").collection("calendars").doc('MYXRXqT5pJQejO0GQBlU'), firebase.firestore().collection("accounts").doc("g8Uc7ZDmIxa5SXbzJYP0").collection("calendars").doc('DNmLHFA3Mr0VePw5woXP')]
      // )
      // // .where("status", "==", "completed")
      // .where("status", "in", ["completed", "cancelled", "rescheduled"])
      // .where("provider_name","in",["Dr. Bobby Spence","Dr. Balvant Arora"])
      .orderBy("appt_start")
      .get();

    let unresolvedAppts = [];
    apptsDocsSnapshot.forEach(apptDoc => {
      let appointment = apptDoc.data();
      appointment.booked_on = appointment.booked_on.toDate();
      appointment.appt_start = appointment.appt_start.toDate();
      appointment.appt_end = appointment.appt_end.toDate();
      if (appointment.new_patient === true) {
        appointment.new_patient = 'Yes';
      }
      else if (appointment.new_patient === false) {
        appointment.new_patient = 'No';
      }
      appointment.ref = apptDoc.ref;
      if (appointment.contact)
        unresolvedAppts.push(this.getContactAndCalendar(appointment));
    });
    let requestedApptSnapShot = await firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("requested_appointment")
      .where(
        "appt_start",
        ">",
        firebase.firestore.Timestamp.fromDate(after_date)
      )
      .where(
        "appt_start",
        "<",
        firebase.firestore.Timestamp.fromDate(before_date)
      )

      .orderBy("appt_start")
      .get();

    requestedApptSnapShot.forEach(apptDoc => {
      let appointment = apptDoc.data();
      appointment.booked_on = appointment.booked_on.toDate();
      appointment.appt_start = appointment.appt_start.toDate();
      appointment.appt_end = appointment.appt_end.toDate();
      if (appointment.new_patient === true) {
        appointment.new_patient = 'Yes';
      }
      else if (appointment.new_patient === false) {
        appointment.new_patient = 'No';
      }
      appointment.ref = apptDoc.ref;
      if (appointment.contact)
        unresolvedAppts.push(this.getContactAndCalendar(appointment));
    });

    appointments = await Promise.all(unresolvedAppts);

    this.allAppointments = appointments;
    this.updateAppointments(appointments);
  }

  async getLocations() {
    return await firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("locations").get();
  }
  async getCurrentAccountDetails() {
    let apptsDocsSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("providersDetails")
      .get();
    // for(doc of apptsDocsSnapshot.data()){
    // }
    // this.providersArray.push

    // for(document of apptsDocsSnapshot.data()){
    //}
    return apptsDocsSnapshot;
  }
  applyFilters(selectedCalendar, selectedLocation, selectedProvider, selectedSource, selectedStatus) {
    this.selectedCalendar = selectedCalendar;
    this.selectedLocation = selectedLocation;
    this.selectedProvider = selectedProvider;
    this.selectedSource = selectedSource;
    this.selectedStatus = selectedStatus;
    let appointments = this.allAppointments.filter((appt) => {
      return (((selectedStatus.length == 0) || (selectedStatus.indexOf(appt.status) != -1))
        && ((selectedProvider.length == 0) || (selectedProvider.indexOf(appt.provider_name) != -1))
        && ((selectedSource.length == 0) || (selectedSource.indexOf(appt.source) != -1))
        && ((selectedLocation.length == 0) || (selectedLocation.indexOf(appt.location) != -1))
        && ((selectedCalendar.length == 0) || (selectedCalendar.indexOf(appt.slug) != -1)));
    });
    this.updateAppointments(appointments);
  }
  clearFilters() {
    this.selectedCalendar = [];
    this.selectedLocation = [];
    this.selectedProvider = [];
    this.selectedSource = [];
    this.selectedStatus = [];
    this.updateAppointments(this.allAppointments);
  }
  async getContactAndCalendar(appointment: any) {
    let contact;
    if (appointment['contact'] && appointment['contact'] != null) {
      let contactDoc = await appointment.contact.get();
      if (contactDoc.exists) {
        appointment.contactId = contactDoc.id;
        contact = contactDoc.data();
        appointment.first_name = contact['first_name'] != undefined ? contact['first_name'] : '';
        appointment.last_name = contact['last_name'] !== undefined ? contact['last_name'] : '';
        appointment.email = contact['email'] != undefined ? contact['email'] : '';
        appointment.phone = contact['phone'] != undefined ? contact['phone'] : '';
      } else {
        appointment.first_name = '';
        appointment.last_name = '';
        appointment.email = '';
        appointment.phone = '';
      }
    }
    else {
      appointment.first_name = '';
      appointment.last_name = '';
      appointment.email = '';
      appointment.phone = '';
    }
    if (appointment.calendarReference && appointment.calendarReference != null) {
      let refDoc = await appointment.calendarReference.get();
      if (refDoc.exists) {
        let cal = refDoc.data();
        appointment.slug = cal.slug;
      }
    }

    return appointment;
  }

  // async getCalendarSlug(appointment: any) {
  //   let refDoc = await appointment.calendarReference.get();
  //   let cal = refDoc.data();
  //   appointment.slug = cal.slug;
  //   return appointment;
  // }

  getCalendars() {
    return firebase.firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection("calendars").get();
  }

  updateAppointments(appointments_master: any) {
    this.appointmentsSource.next(appointments_master);
  }

  saveAppointmentStatus(ref: any, status: any) {
    return ref.update({ status: status });
  }

  saveAppointmentValue(ref: any, value: any) {
    return ref.update({ value: value });
  }

  saveAppointmentInsurance(ref: any, insurance: any) {
    return ref.update({ insurance: insurance });
  }

  saveAppointmentNotes(ref: any, notes: any) {
    return ref.update({ notes: notes });
  }

  filterAppointmentOnProvider(selectedProvider) {
    let providersCalendar = this.allAppointments.filter(appointment =>
      selectedProvider.includes(appointment.provider_name)
    );
    this.updateAppointments(providersCalendar);
  }

  /*
  * This function listens for the 'added' event and then adds the new appointment in the existing array

  getRealTimeData(after_date: any, before_date: any) {
   let appointments = [];
   let appointment = {};
   firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('appointments')
           .where('appt_start', '>', firebase.firestore.Timestamp.fromDate(after_date))
           .where('appt_start', '<', firebase.firestore.Timestamp.fromDate(before_date))
           .orderBy('appt_start')
           .onSnapshot(apptsDocsSnapshot => {

            apptsDocsSnapshot.docChanges().forEach(
              change => {
                if(change.type === 'added') {
                  console.log('Added ' + change.doc.id);
                  appointment = change.doc.data();
                  appointment.booked_on = appointment.booked_on.toDate();
                  appointment.appt_start = appointment.appt_start.toDate();
                  appointment.appt_end = appointment.appt_end.toDate();
                  appointment.ref = change.doc.ref;
                  this.getContact(appointment)
                  .then(
                    appointment => {
                        appointments.push(appointment);
                        this.updateAppointments(appointments);
                    }
                  );
                }
              });
         });
       }
  */
  /*
  async getRealTimeData(after_date: any, before_date: any) {
   let appointments = [];
   let unresolvedAppts = [];
   firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('appointments')
           .where('appt_start', '>', firebase.firestore.Timestamp.fromDate(after_date))
           .where('appt_start', '<', firebase.firestore.Timestamp.fromDate(before_date))
           .orderBy('appt_start')
           //.get();
          .onSnapshot(apptsDocsSnapshot => {

            apptsDocsSnapshot.forEach(
              apptDoc => {
                 let appointment = apptDoc.data();
                 appointment.booked_on = appointment.booked_on.toDate();
                 appointment.appt_start = appointment.appt_start.toDate();
                 appointment.appt_end = appointment.appt_end.toDate();
                 appointment.ref = apptDoc.ref;
                 unresolvedAppts.push(this.getContact(appointment));
                }
              );
              this.updateAppointments(appointments);
           });
           //appointments = await Promise.all(unresolvedAppts);





   /*
     firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('appointments').doc('TocfkO3MRY50IL8p5XDF')
     .onSnapshot(doc => {
        console.log(doc.data());
        console.log("Current data: ", doc.data());
    });
  }*/
  async checkCalendarExist() {
    let docSnap = await firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem('current_acc_id')}`).collection('calendars').get();
    if (docSnap.empty) {
      return false;
    }
    else {
      return true;
    }
  }

  /**
     * This function gets the all appointmnets of the contact   */
  async fetchAppointmentsOfContact(contactRef) {
    let contactAppts = [];
    let apptsDocsSnapshot = await firebase.firestore().collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("appointments")
      .where("contact", "==", contactRef)
      .orderBy("appt_start", "desc")
      .get();
    apptsDocsSnapshot.forEach(apptDoc => {
      let appointment = apptDoc.data();
      appointment.booked_on = appointment.booked_on.toDate();
      appointment.appt_start = appointment.appt_start.toDate();
      appointment.appt_end = appointment.appt_end.toDate();
      if (appointment.new_patient === true) {
        appointment.new_patient = 'Yes';
      }
      else if (appointment.new_patient === false) {
        appointment.new_patient = 'No';
      }
      appointment.ref = apptDoc.ref;
      contactAppts.push(appointment);
      // contactAppts.push(this.getCalendarDetails(appointment));
    });
    // let appointments = await Promise.all(contactAppts);
    return contactAppts;
  }

  getCalendarDetails(apptDetails) {
    // let calInfo = apptDetails.calendarReference.get();
    // apptDetails['calName'] = calInfo['name'];
    return apptDetails;
  }
}