import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AccountService } from "../_services/index";

@Component({
  selector: 'app-mat-drawer-container-menu',
  templateUrl: './mat-drawer-container-menu.component.html',
  styleUrls: ['./mat-drawer-container-menu.component.scss'],
})
export class MatDrawerContainerMenuComponent implements OnInit {

  @Input() drawer;
  isExpanded = false;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  mini: boolean = false;
  lock: boolean = false;
  user: any;
  disable: boolean = true;
  selectedIndex: number = -1;
  menuList: any =[];

  constructor(
    private accService: AccountService,
  ) {

    this.accService.menuChange$.subscribe(menu=>{
    this.menuList = menu;
    // console.log(this.menuList);
    });

   }

  ngOnInit() {
  }

  mouseenter() {
    this.isExpanded = true;
  }

  toggle(menuItem) {
    // console.log(menuItem)
    if (menuItem.isExpanded == undefined)
      this.drawer.toggle();
    else
      menuItem.isExpanded = !menuItem.isExpanded;
  }

  mouseleave() {
    this.isExpanded = false;
  }
  selectListItem(i) {
    let k = this.selectedIndex;
    this.menuList[this.selectedIndex].selected = !this.menuList[this.selectedIndex].selected;
    this.menuList[i].selected = !this.menuList[i].selected;
    this.selectedIndex = i;
  }

}
