import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../app/_services/account.service';
@Component({
  selector: 'app-sign-up-loading',
  templateUrl: './sign-up-loading.component.html',
  styleUrls: ['./sign-up-loading.component.scss']
})
export class SignUpLoadingComponent implements OnInit {
  activityStatus: string = "ACCOUNT_CREATION"
  constructor(private website: AccountService) {
    this.website.accountBuildet$.subscribe(res => {
      if (res.activityStatus) {
        this.activityStatus = res.activityStatus
      }
    })
  }

  ngOnInit(): void {
  }

}
