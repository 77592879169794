import { Component, OnInit, ViewChild, Input } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { NavigationState } from "../../_models/index";
import { NavigationService } from "../../_services/index";

interface Appt {
  appt_date: string;
  time_slot: string;
  status: string;
  days: number;
}

@Component({
  selector: 'app-appointment-nudge',
  templateUrl: './appointment-nudge.component.html',
  styleUrls: ['./appointment-nudge.component.scss']
})


export class AppointmentNudgeComponent implements OnInit {

  apps: Appt[] = [
    {
      "appt_date": 'Sat Mar 14 2020 19:30:00 GMT+0530 (India Standard Time)',
      "time_slot": '10:00 AM - 11:00 AM',
      "status": 'booked',
      "days": 2
    },
    {
      "appt_date": "14-Jan-2020",
      "time_slot": "8:00-9:00",
      "status": "booked",
      "days": 3
    }
  ];

  displayedColumns: string[] = [
    "appointment_date",
    "time_slot",
    "status",
    "message"
  ];

  appointments: Appt[] = [];
  dataSource = new MatTableDataSource();
  //navigationService: any;
  constructor(
    private navigationService: NavigationService
  ) {
  }

  @ViewChild(MatPaginator)
  set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }
  @ViewChild(MatSort)
  set sort(value1: MatSort) {
    this.dataSource.sort = value1;
  }

  ngOnInit() {
    /*this.navigationService.updateState(
      new NavigationState(false, "Nudge", "", "#", "/nudgeappointments")
    );*/

    firebase.firestore().collection("accounts").doc("g8Uc7ZDmIxa5SXbzJYP0").collection("appointments")
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let now = moment(new Date());
          let appt = {
            appt_date: `${doc.data().appt_start.toDate()}`,
            time_slot: `${doc.data().slot}`,
            status: `${doc.data().status}`,
            days: Math.floor(moment.duration(now.diff(`${doc.data().appt_start.toDate()}`)).asDays())
          }
          this.appointments.push(appt);

        });
        this.dataSource = new MatTableDataSource(this.appointments);
      });
  }
  getDays(appt_start) {
    let now = moment(new Date());
    return Math.floor(moment.duration(now.diff(appt_start)).asDays());
  }
}
