<div class="alert alert-danger" role="alert" *ngIf="isError" id="invalidJsonError" style="text-align: center;">
    Invalid json object
</div>
<div class="row" style="background-color: none;">
    <div style="margin:5px auto 10px;font-size: 20px;font-weight: 500;">ChatBot Settings</div>
</div>
<div class="row" id="main">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChanged($event)" #tabGroup>
        <mat-tab label="Theme" id="noScrolling" [disabled]='!isThemeDataAvailabel'>
            <div class="row" *ngIf="themeLoaded else loading">
                <div class="col-md-8">
                    <div class="tabs">
                        <div class="row" *ngFor="let obj of colorSetting" style="padding:5px 5px;">
                            <div class="col-md-5">
                                {{obj.name}}
                            </div>
                            <div class="col-md-2">
                                :
                            </div>
                            <div class="col-md-5">
                                <!-- <input ejs-colorpicker type="color" id="colorpicker" value={{obj.defaultColor}} /> -->
                                <input class="input" [(colorPicker)]="obj.defaultColor"
                                    [style.background]="obj.defaultColor" [cpPosition]="'right'"
                                    [cpDialogDisplay]=" 'popup' "
                                    (colorPickerChange)="openColorPicker('colorPickerOpen', $event)" readonly />
                            </div>
                        </div>
                    </div>
                    <div id="saveTheme" *ngIf="allColors">
                        <button class="button" mat-stroked-button color="primary"
                            (click)="saveTheme(colorSetting)">Save</button>
                    </div>
                </div>
                <div class="col-md-4">
                    <app-chatbot-preview [colors]='colorSetting'></app-chatbot-preview>
                </div>
            </div>
            <ng-template #loading id="spinner">
                <!-- <mat-spinner></mat-spinner> -->
                <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
            </ng-template>
        </mat-tab>
        <mat-tab label="General">
            <div *ngIf="isGeneralDataAvailabel">
                <div id="submitChatBotSetting" *ngIf="genralDataLoaded">
                    <button class="button" mat-stroked-button color="primary"
                        (click)="updateChatBotSettingObject()">Submit</button>
                </div>
                <ng-template #loading id="spinner">
                    <!-- <mat-spinner></mat-spinner> -->
                    <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                </ng-template>
                <div *ngIf="genralDataLoaded else loading">
                    <json-editor [options]="generalJsonEditorOption" [data]="chatBotSettingData"
                        (change)="getData($event)" #generalEditor></json-editor>
                </div>
            </div>
            <div *ngIf="!isGeneralDataAvailabel">
                <button class="button" mat-stroked-button color="primary" (click)="addNewRecords()"
                    [disabled]="newRecordDisabled"> Click here to
                    create new record</button>
            </div>
            <!-- <div class="tabs">
                    <div class="row" *ngFor="let name of generelSetting" style="padding: 8px;">
                        <div class="col-md-4">
                            {{name}} 
                        </div>
                        <div class="col-md-3">
                            :
                        </div>
                        <div class="col-md-5">

                        </div>
                    </div>
                </div>
                <div class="row" style="padding: 8px;">
                    <div class="col-md-4">
                        Mode
                    </div>
                    <div class="col-md-3">
                        :
                    </div>
                    <div class="col-md-5">
                        <p-dropdown [options]="modes" placeholder="Select Mode" optionLabel="name" [showClear]="true"
                            [style]="{'height':'2.0rem','padding-left':'1px'}">
                        </p-dropdown>
                    </div>
                </div>
                <div class="row" style="padding: 8px;">
                    <div class="col-md-4">
                        Welcome Message
                    </div>
                    <div class="col-md-3">
                        :
                    </div>
                    <div class="col-md-5">
                        <input matInput type="text" placeholder="welcome message">
                    </div>
                </div>
                <div class="row" style="padding: 8px;">
                    <div class="col-md-4">
                        Welcome Buttons
                    </div>
                    <div class="col-md-1">
                        :
                    </div>
                    <div class="col-md-7">
                        <p-chips [(ngModel)]="values1"></p-chips>
                        <mat-icon aria-hidden="false" aria-label="" (click)=showChips()> add</mat-icon>

                    </div>
                </div>  -->
        </mat-tab>
        <mat-tab label="Data">
            <div class="tabs" style="height: 450px;">
                <div *ngIf="showList else editor">
                    <div class="row" style="padding:8px;">
                        <div class="col-md-10" style="font-size: 26px;">
                            ChatBot Data

                        </div>
                        <div class="col-md-2" style="font-size: 26px;">
                            Actions
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="padding:8px;">
                        <div class="col-md-10">
                        </div>
                        <div class="col-md-2">
                            <button class="button" mat-stroked-button color="primary"
                                (click)="showList = false;data={};updateFlag = false">Add
                                New</button>
                            <!-- <i class="fas fa-plus" (click)="showList = false;data={};updateFlag = false"
                            matTooltip="Add New"></i> -->
                            <!-- <button mat-mini-fab class="format" (click)="showList = false;data={};updateFlag = false"
                                matTooltip="Add Object">
                                <i class="material-icons add">add</i>
                            </button> -->
                        </div>
                    </div>

                    <div class="row list" *ngFor=" let obj of chatBotData ;let i = index" style="padding:8px;">
                        <div class="col-md-10" style="margin: auto 0px;">
                            {{obj.type | titlecase}}
                        </div>
                        <div class="col-md-2" style="padding-right:103px;">
                            <!-- <button class="button" mat-stroked-button color="primary"
                                    (click)="editObj(obj,i)">Edit</button> -->
                            <!-- <i class="fas fa-edit" (click)="editObj(obj,i)" matTooltip="Edit Object"></i> -->
                            <button mat-mini-fab class="format" (click)="editObj(obj,i)" matTooltip="Edit Object">
                                <i class="material-icons edit">edit</i>
                            </button>
                        </div>
                    </div>
                </div>
                <ng-template #editor>
                    <div class="row" style="padding: 5px;">
                        <div style="margin:0px 10px 5px auto ;">
                            <button [disabled]="btnDisabled" style="margin-right: 5px;" class="button"
                                *ngIf="updateFlag" mat-stroked-button color="primary"
                                (click)="updateObject()">Save</button>
                            <button [disabled]="btnDisabled" style="margin-right: 5px;" class="button"
                                *ngIf="!updateFlag" mat-stroked-button color="primary"
                                (click)="addNewObject()">Save</button>
                            <button style="margin-left: 5px;" class="button" mat-stroked-button color="primary"
                                (click)="showList = true">Cancel</button>
                        </div>
                    </div>

                    <json-editor [options]="options" [data]="data" (change)="getData($event)" #dataEditor>
                    </json-editor>

                </ng-template>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>