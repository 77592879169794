import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import {TableModule} from 'primeng/table'

@Component({
  selector: 'app-organic-ranks',
  templateUrl: './organic-ranks.component.html',
  styleUrls: ['./organic-ranks.component.scss']
})
export class OrganicRanksComponent implements OnInit, OnChanges {
  @Input() organicRanks: any;
  @Input() loading: boolean = true;
  @Input() configurations:any;
  @Input() status;
  @ViewChild('dt1', { static: false }) dt1: TableModule;
  
  organicRankCopy:any=[];
  searchFields: any = ["keyword_data.keyword"];
  ranksExist: boolean = true;
  totalRecords:number = 0;
  rows: number = 20;
  first: number = 0;
  show:boolean;
  cardSelection = [
    {field:'is_new',selected:false},
    {field:'is_up',selected:false},
    {field:'is_down',selected:false},
    {field:'is_lost',selected:false},

  ]
  positionArr=[
    {field:'pos_1', name:'Position 1',selected:false,low:0,high:1},
    {field:'pos_2_3', name:'Position 2-3',selected:false,low:2,high:3},
    {field:'pos_4_10', name:'Position 4-10',selected:false,low:4,high:10},
    {field:'pos_11_20', name:'Position 11-20',selected:false,low:11,high:20},
    {field:'pos_21_30', name:'Position 21-30',selected:false,low:21,high:30},
    {field:'pos_31_40', name:'Position 31-40',selected:false,low:31,high:40},
    {field:'pos_41_50', name:'Position 41-50',selected:false,low:41,high:50},
    {field:'pos_51_60', name:'Position 51-60',selected:false,low:51,high:60},
    {field:'pos_61_70', name:'Position 61-70',selected:false,low:61,high:70},
    {field:'pos_71_80', name:'Position 71-80',selected:false,low:71,high:80},
    {field:'pos_81_90', name:'Position 81-90',selected:false,low:81,high:90},
    {field:'pos_91_100', name:'Position 91-100',selected:false,low:91,high:100},
]
  rowsOptions = [20, 50, 100];
  cols = [
    { field: 'keyword_data.keyword', header: 'Keyword' },
    { field: 'ranked_serp_element.serp_item.rank_group', header: 'Position' },
    { field: 'keyword_data.keyword_info.search_volume', header: 'Monthly SV' },
    { field: 'keyword_data.keyword_info.cpc', header: 'CPC' },
    { field: 'keyword_data.keyword_info.competition', header: 'Competition' },
    { field: 'ranked_serp_element.serp_item.etv', header: 'Traffic Cost' },
    { field: 'ranked_serp_element.serp_item.url', header: 'URL' }
  ];
  moment:any=moment;
  created_on = "Tue, 03 Aug 2021 13:15:36 GMT";
  createdOn:Date;
  keyword_status = {
    "is_up":0,
    "is_down" : 0,
    "is_new":0,
    "is_lost":0,
    "no_change":0
  }
  showOldRecord:boolean=false;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.configurations){
      console.log(this.configurations);
      if(typeof(this.configurations['created_on']) === 'string')
          this.createdOn =  moment(this.configurations['created_on']).toDate()
      else{
        if('created_on' in this.configurations)
          this.createdOn = this.configurations['created_on'].toDate()
      }
    }
    if ('organicRanks' in changes) {
      if(this.organicRanks)
      {
          if (this.organicRanks.length > 0) {
            this.positionArr.forEach(element=>{
              element.selected = false;
            });
            this.cardSelection.forEach(element=>{
              element.selected = false;
            })

            this.ranksExist = true;
            this.totalRecords = this.organicRanks.length;
            this.organicRankCopy = this.organicRanks.filter(ele=>ele);
            this.keyword_status = {
              "is_up":0,
              "is_down" : 0,
              "is_new":0,
              "is_lost":0,
              "no_change":0
            }
            let chkIndex = this.organicRankCopy.findIndex(element=>element['rank_group_analysis']);
            if(chkIndex>=0)
              this.showOldRecord = false
            else
              this.showOldRecord = true;
            console.log("Old record present",this.showOldRecord);
            console.log(this.organicRankCopy,this.showOldRecord);
            if(!this.showOldRecord){
              this.keyword_status['is_up'] = (this.organicRankCopy.filter(element=>{
                  if('rank_group_analysis' in element && 'is_up' in element['rank_group_analysis']){
                    return element['rank_group_analysis']['is_up']
                  }
                }
              )).length
              this.keyword_status['is_down'] = (this.organicRankCopy.filter(element=>{
                if('rank_group_analysis' in element && 'is_down' in element['rank_group_analysis']){
                  return element['rank_group_analysis']['is_down']
                }
              }
              )).length
              this.keyword_status['is_new'] = (this.organicRankCopy.filter(element=>{
                if('rank_group_analysis' in element && 'is_new' in element['rank_group_analysis']){
                  return element['rank_group_analysis']['is_new']
                }
              }
              )).length
              this.keyword_status['is_lost'] = (this.organicRankCopy.filter(element=>{
                if('rank_group_analysis' in element && 'is_lost' in element){
                  return element['rank_group_analysis']['is_lost']
                }
              }
              )).length
              this.keyword_status['no_change'] = (this.organicRankCopy.filter(element=>{
                if('rank_group_analysis' in element && 'no_change' in element){
                  return element['rank_group_analysis']['no_change']
                }
              }
              )).length
              console.log(this.keyword_status);
            }
            else{
              // this.keyword_status['is_up'] = this.configurations?.metrics.organic.is_up;
              // this.keyword_status['is_down'] = this.configurations?.metrics.organic.is_down;
              // this.keyword_status['is_new'] = this.configurations?.metrics.organic.is_new;
              // this.keyword_status['is_lost'] = this.configurations?.metrics.organic.is_lost;
              // this.keyword_status['no_change'] = this.configurations?.metrics.organic.no_change;
              console.log(this.keyword_status);
            }
            this.show = true; 
          }
          else {
            this.ranksExist = false;
          }
      }
    }
  }

  ngOnInit(): void {
  }

  newTab(url) {
    window.open(url);
  }
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.organicRanks ? this.first === (this.organicRanks.length - this.rows) : true;
  }

  isFirstPage(): boolean {
    return this.organicRanks ? this.first === 0 : true;
  }
  checkRankChange(value){
    if(value.hasOwnProperty('previous_rank_absolute')){
      return value.is_up || value.is_down;
    }
    else{
      return false;
    }
  }

  async getPositionwiseData(data,selectedIndex){
    this.cardSelection.forEach(element=>{
      element.selected = false;
    })
    this.dt1['_first'] = 0;
    this.positionArr[selectedIndex]['selected'] = !this.positionArr[selectedIndex]['selected']
    this.positionArr.forEach((element,index)=>{
        if(index != selectedIndex){
            this.positionArr[index]['selected'] = false;
        }
    });
    if(this.positionArr[selectedIndex]['selected']){
      this.show = false;;
      let high = this.positionArr[selectedIndex]['high'];
      let low = this.positionArr[selectedIndex]['low'];
      this.organicRanks = [];
      await Promise.all(
        this.organicRankCopy.map(element=>{
            // if(element.ranked_serp_element.serp_item.rank_absolute<=high && element.ranked_serp_element.serp_item.rank_absolute>=low){
            if(element.ranked_serp_element.serp_item.rank_group<=high && element.ranked_serp_element.serp_item.rank_group>=low){
              this.organicRanks.push(element);
            }
        })).then(resp=>{
          this.show = true;
          console.log(this.organicRanks.length);

        })
      this.totalRecords = this.organicRanks.length;
      
    }
    else{
      this.organicRanks = this.organicRankCopy.filter(ele=>ele);
      this.totalRecords = this.organicRanks.length;
    }
  }

  async getCardData(data,selectedIndex){
    this.positionArr.forEach(element=>{
      element.selected = false;
    });
    this.cardSelection[selectedIndex]['selected'] = !this.cardSelection[selectedIndex]['selected'];
    this.cardSelection.forEach((element,index)=>{
      if(index != selectedIndex){
          this.cardSelection[index]['selected'] = false;
      }
    });
    if(this.cardSelection[selectedIndex]['selected']){
      this.show = false;;
      this.organicRanks = [];
      await Promise.all(
        this.organicRankCopy.map(element=>{
            // console.log(element.ranked_serp_element.serp_item.rank_changes[(this.cardSelection[selectedIndex]['field'])]);
            // if(element.ranked_serp_element.serp_item.rank_changes[(this.cardSelection[selectedIndex]['field'])]){
            if('rank_group_analysis' in element){
              if(element.rank_group_analysis[(this.cardSelection[selectedIndex]['field'])]){
                this.organicRanks.push(element);
              }
            }
            // else{
            //   if(element.ranked_serp_element.serp_item.rank_changes[(this.cardSelection[selectedIndex]['field'])]){
            //     this.organicRanks.push(element);
            //   }
            // }
            
        })).then(resp=>{
          this.show = true;
          console.log(this.organicRanks.length);
        })
      this.totalRecords = this.organicRanks.length;
      
    }
    else{
      this.organicRanks = this.organicRankCopy.filter(ele=>ele);
      this.totalRecords = this.organicRanks.length;
    }
  }
}
