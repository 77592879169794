<div style="background: #f3f3f3ff;">
  <div class="contentDiv" *ngIf="showOffers;else noShow" >

      <div class="row text-center" *ngIf="pastOfferData?.length <= 0 && currentOfferData?.length<=0 && upcommingOfferData?.length <= 0" >
          <img src="assets/images/NoMarketing.PNG" alt="">
          <div> No Marketing Offers Created</div>
      </div>

    <div class="pt-3 " *ngIf="pastOfferData.length > 0 || currentOfferData.length > 0 || upcommingOfferData.length > 0">
        <div class="margin-auto">
                <mat-tab-group >
                    <div class="row" style="text-align: center;">
                      <mat-tab  label="Active Offers" class="tabWidth" [selectedIndex]="1">
                        <app-marketting-offer-card  [typeOffer]="'Active Offers'" [offerData]="currentOfferData" (offerAction)="actionOnOffer($event)" ></app-marketting-offer-card>
                      </mat-tab>
                      <mat-tab  label="Upcoming Offers" class="tabWidth" [selectedIndex]="2">
                        <div >
                          <app-marketting-offer-card (offerAction)="actionOnOffer($event)" [typeOffer]="'Upcoming Offers'" [offerData]="upcommingOfferData" ></app-marketting-offer-card>
                        </div>
                      </mat-tab>
                      <mat-tab  label="Past Offers" class="tabWidth" [selectedIndex]="3">
                        <div >
                          <app-marketting-offer-card [typeOffer]="'Past Offers'" (offerAction)="actionOnOffer($event)" [offerData]="pastOfferData" ></app-marketting-offer-card>
                        </div>
                      </mat-tab>
                    </div>
                </mat-tab-group>
        </div>
    </div>
  </div>
  <ng-template #noShow>
    <div class="contentDiv">
      Loading ...
    </div>
  </ng-template>
</div>
