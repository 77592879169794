import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-organic-rank-analysis',
  templateUrl: './organic-rank-analysis.component.html',
  styleUrls: ['./organic-rank-analysis.component.scss']
})
export class OrganicRankAnalysisComponent implements OnInit {
  @Input() organicRankAnalysis: any;
  datepipe: DatePipe = new DatePipe('en-US')
  allDocs:any=[];
  @Input() loading:boolean;
  loadingForSetup:boolean;
  columns: any=[];
  dataExists:boolean;
  rows: number = 10;
  first: number = 0;
  totalRecords:number = 0;
  cols=[];
  searchFields: any = [{"field":"keyword"}];
  

  constructor() { }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('organicRankAnalysis' in changes) {
      let organicRankAnalysis = changes.organicRankAnalysis.currentValue;
      if(organicRankAnalysis){
        if(organicRankAnalysis.length>0){
          this.cols = [];
          this.cols.push({header:'keyword',field:'keyword',sortField:'rank0'});
          this.cols.push({header:'SEO Info',field:'SEO Info',sortField:'rank1'});
          let index = 0;
          this.organicRankAnalysis[0]['previousRanks'].forEach((element,index) => {
            let obj = {
              header:this.datepipe.transform(element['date'].toDate(), 'MMM d, yyyy'),
              field:this.datepipe.transform(element['date'].toDate(), 'MMM d, yyyy'),
              sortField:'rank'+(index+2),
            }
            this.cols.push(obj);
          });
          this.searchFields = this.cols.map(ele=>ele.No)
          this.totalRecords - this.organicRankAnalysis.length;
          this.setOrganicInfo(organicRankAnalysis);
        }
        else{
        }
      }
    }
  }

  ngOnInit(): void {
  }

  async setOrganicInfo(organicRankAnalysis:any){
    this.organicRankAnalysis = this.organicRankAnalysis.sort((ele1,ele2)=>{
        if(ele1.search_volume == 'NA' || ele1.search_volume == NaN)
        {
          if(0<ele2.search_volume)
            return 1;
          else
            return -1;
        }
        if(ele2.search_volume == 'NA' || ele1.search_volume == NaN){
          if(ele1.search_volume<0)
            return 1;
          else
            return -1;
        }
        if(ele1.search_volume<ele2.search_volume)
          return 1;
        else
          return -1;
    });
    this.organicRankAnalysis.forEach(organicRank=>{
        organicRank['previousRanks'].forEach((element,index) => {
            organicRank[this.cols[index]['sortField']] = element['rank']
          })
    });
  } 


  getBlurData(data){
    console.log(data);
  }

}
