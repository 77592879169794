import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AfService } from '../_services/index';
import { tap, map, take } from 'rxjs/operators';
import { AlertService } from '../_services/index';
import { Router } from '@angular/router';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(public af: AfService,
              private alertService:AlertService,
              private router:Router,
              ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //return true;
    return this.af.user$.pipe(
    take(1),
    map(user => user && user.roles.admin ? true : false),
    tap(isAdmin => {
      if(!isAdmin){
        console.error("Access Denied - Admins only allowed");
        this.alertService.error('You must login first', true);
        this.router.navigateByUrl('/login');
      }
    })
);
  }
  }
