import { Component, OnInit, AfterViewInit, Input, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { FormBuilderService } from '../../_services/form-builder.service';
import { AccountService } from '../../_services/account.service';
import { Form } from '../../_services/form';
import scrollIntoView from 'scroll-into-view-if-needed';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {
  @Input()
  form: Form;
  target: any;
  @ViewChildren('ques_section') quesSections: QueryList<ElementRef>;
  currentQuesCount: number = 0;
  accCurrency: string;
  ratingValue: number = 0;

  constructor(
    private formBuilderService: FormBuilderService,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.currentQuesCount = this.form.questions.length;
    this.accCurrency = this.accountService.getCurrentAccDetails().account_currency;
  }

  ngAfterViewInit() {
    console.log(this.quesSections);
    this.quesSections.changes.subscribe(changes => {
      console.log(changes);
      if (changes.length - this.currentQuesCount === 1) {
        this.scroll(this.currentQuesCount.toString());
      }
      this.currentQuesCount = changes.length;
      console.log(this.currentQuesCount);
    });
  }

  scroll(dest: string) {
    let node = document.getElementById('target' + dest);
    try {
      scrollIntoView(node, {
        behavior: 'auto',
        scrollMode: 'if-needed'
      });
      console.log('Scrolled!');
    } catch (error) {
      //node.scrollIntoView(false);
      console.log('Error!');
    }
  }



}
