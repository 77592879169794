import { Component, OnInit } from "@angular/core";
import {
  FacebookService,
  AccountService,
  ConversionValueService,
  NavigationService,
} from "../_services/index";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { HttpClient, HttpParams } from "@angular/common/http";
import { FormControl, FormBuilder, FormGroup } from "@angular/forms";
import { NavigationState } from "../_models/index";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  cost_per_conversion: any;
  campaign_details: any;
  isAllow: boolean;
  conv_value: number;
  acc: any;
  conversion_value: number = 0;
  /*my comment
  acc_name:any;
  doc:any;
  analyticsAcc:any = [];
  selectedAnalyticsAcc:any =[];
  selectedProperty:any =[];
  selectedView:any;
  fb_accounts: any;
  adwords_accounts: any;
  email_lists: any;
  selectedFBAcc: any;
  selectedAdwordsAcc : any;
  selectedEmailList: any;
  showAdwordsAccounts: boolean = false;
  showFBAccounts: boolean = false;
  showAnalyticsAccounts:boolean = false;
  showEmailLists: boolean = false;
  my comment*/
  conversion_value_field = new FormControl(0);
  /*my comment
facebookCredentials:any;
adwordsCredentials:any;
emailCredentials:any;
analyticsCredentials:any;
details: any;
step = 0;
my comment*/
  constructor(
    private navigationService: NavigationService,
    private http: HttpClient,
    private fb: FormBuilder,
    //      private facebookService:FacebookService,
    private accountService: AccountService,
    private conversionValueService: ConversionValueService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.accountService.accChange$.subscribe((accChange) => {
      this.router.navigateByUrl("/websites");
      /*
        this.acc = this.accountService.getCurrentAccDetails();
        this.facebookCredentials = this.accountService.getLinkedAccountCredentials('facebook');
        this.adwordsCredentials = this.accountService.getLinkedAccountCredentials('adwords');
        this.analyticsCredentials = this.accountService.getLinkedAccountCredentials('analytics');
        console.log('Account is changed:' + this.acc.name);
        */
    });
  }

  setConversionValue() {
    /*
if (this.conversion_value > 0 && this.conversion_value == Math.floor(this.conversion_value)){
    this.conversionValueService.saveConversionValue(this.conversion_value);
}
*/
    if (
      this.conversion_value_field.value > 0 &&
      this.conversion_value_field.value ==
        Math.floor(this.conversion_value_field.value)
    ) {
      this.conversionValueService.saveConversionValue(
        this.conversion_value_field.value
      );
    }
  }
  /*my comment
setStep(index: number) {
   this.step = index;
 }

 nextStep() {
   this.step++;
 }

 prevStep() {
   this.step--;
 }

linkFacebookAcc() {
    this.accountService.linkFacebookAcc();
  }

  linkAdwordsAcc() {
    this.accountService.linkAdwordsAcc();
  }


  linkAnalyticsAcc() {
    this.accountService.linkAnalyticsAcc();
  }

  linkMailChimpAcc() {
    this.accountService.linkMailChimpAcc();
  }

  saveFBAcc() {
    this.accountService.saveFBCredentialsToAccount(this.selectedFBAcc);
    this.showFBAccounts = false;
  }

  saveView(){
  this.accountService.saveAnalyticsCredentialsToAccount(this.selectedView);
  this.showAnalyticsAccounts = false;
}

saveAdwordsAcc(){
  this.accountService.saveAdwordsCredentialsToAccount(this.selectedAdwordsAcc);
  this.showAdwordsAccounts = false;
}

saveEmailAcc() {
  this.accountService.saveMailChimpCredentialsToAccount(this.selectedEmailList);
  this.showEmailLists = false;
}

  unlinkFBAcc() {
    this.accountService.unlinkFBAcc();
  }

  unlinkGoogleAccs(refresh_token: string) {
    this.accountService.unlinkGoogleAccs(refresh_token);
  }

  editFBAcc() {
    this.accountService.getFBAdAccounts(this.facebookCredentials.facebook_access_token)
                       .subscribe(
                         accounts => {
                           this.fb_accounts = accounts['data'];
                           this.selectedFBAcc = {
                             'account_id': this.acc.facebook_account_id,
                             'account_name': this.acc.facebook_account_name
                           };
                           this.showFBAccounts = true;
                         }
                       );
  }

  editAdwordsAcc() {
    this.accountService.getAdwordsAccounts(this.adwordsCredentials.adwords_refresh_token)
                       .subscribe(
                         accounts => {
                           this.adwords_accounts = accounts['accounts'];
                           this.selectedAdwordsAcc = {
                             'account_id': this.acc.adwords_customer_id,
                             'account_name': this.acc.adwords_account_name
                           };
                           this.showAdwordsAccounts = true;
                         }
                       );
  }

  editAnalyticsAcc(){
    this.accountService.getAnalyticsAccounts(this.analyticsCredentials.analytics_refresh_token)
                        .subscribe(analyticsAcc => {
                          this.analyticsAcc = analyticsAcc['Accounts'];
                          this.showAnalyticsAccounts = true;
                        });

  }

  editMailChimpAcc() {
    this.accountService.getMailChimpLists(this.acc.mailchimp_access_token, this.acc.mailchimp_dc)
                       .subscribe(
                         lists => {
                           this.email_lists = lists['lists'];
                           this.showEmailLists = true;
                         }
                       );
  }
my comment*/
  ngOnInit() {
    //sub-header
    this.navigationService.updateState(
      new NavigationState(true, "Settings", "", "", "/settings")
    );
    // ------------------

    this.acc = this.accountService.getCurrentAccDetails();
    //this.facebookCredentials = this.accountService.getLinkedAccountCredentials('facebook');
    //this.adwordsCredentials = this.accountService.getLinkedAccountCredentials('adwords');
    //this.analyticsCredentials = this.accountService.getLinkedAccountCredentials('analytics');

    if (!this.acc.account_currency) {
      this.acc.account_currency = "INR";
    }

    let state = this.navigationService.getCurrentNavigationState();

    let regExpAwCamp = /^\/adwords-campaign/;
    let regExpFbCamp = /^\/fb-campaign/;
    if (
      state &&
      (regExpAwCamp.test(state.url) || regExpFbCamp.test(state.url))
    ) {
      this.isAllow = true;
      this.campaign_details = this.conversionValueService.getCurrentCampaignDetails();
      this.cost_per_conversion = this.campaign_details.cost_per_conversion;
      this.conversion_value = this.campaign_details.conversion_value;

      this.conversionValueService
        .getCampaignConvValue()
        .subscribe((document) => {
          if (document != null) {
            this.conversion_value = document["conversion_value"];
            this.conversion_value_field.setValue(this.conversion_value);
          }
        });
    } else {
      this.isAllow = false;
    }

    /*my comment
     this.route.queryParams.subscribe(
       params => {
        if(params['adwords_refresh_token']) {
          this.accountService.getAdwordsAccounts(params['adwords_refresh_token'])
                            .subscribe(data => {
                                      this.adwords_accounts = data['accounts'];
                                      this.showAdwordsAccounts = true;
                                      });
        }

        if(params['fb_access_token']) {
          this.accountService.getFBAdAccounts(params['fb_access_token'])
                             .subscribe(
                               accounts => {
                                 this.fb_accounts = accounts['data'];
                                 this.showFBAccounts = true;
                               }
                             );
        }

        if(params['analytics_refresh_token']){
           this.accountService.getAnalyticsAccounts(params['analytics_refresh_token'])
              .subscribe( analyticsAcc =>{
                this.analyticsAcc = analyticsAcc['Accounts'];
                this.showAnalyticsAccounts =true;
        });
      }

      /*
      if(params['mailchimp_access_token']) {
        console.log('MailChimp access token: ' + params['mailchimp_access_token']);
        console.log('MailChimp DC: ' + params['mailchimp_dc']);

        this.acc['mailchimp_access_token'] = params['mailchimp_access_token'];
        this.acc['mailchimp_dc'] = params['mailchimp_dc'];

        this.accountService.getMailChimpLists(params['mailchimp_access_token'], params['mailchimp_dc'])
                           .subscribe(
                             lists => {
                               this.email_lists = lists['lists'];
                               this.showEmailLists = true;
                             }
                           );
      }
      */
    /*my comment
      },
      error => {
        console.error(error);
}
    );
    my comment*/
  }
}
