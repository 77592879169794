<p-scrollPanel [style]="{width: '100%', height: '75vh'}">
  <div class="message-box">
    <div>
      <!-- <div *ngIf="chatListReady==false">
        <mat-progress-spinner mode="indeterminate" style="margin:0 auto;"></mat-progress-spinner>
      </div> -->
      <div class="">
        <div class="list-group">
          <a class="list-group-item list-group-item-action flex-column align-items-start"
            *ngFor="let last_message of allContacts" (click)="sendPhone(last_message)">
            <div class="row">
              <div class="col-2">
                <ngx-avatar name={{last_message?.first_name}}></ngx-avatar>
              </div>
              <div class="col-10" id="name" style="padding-left: 8%;">
                <div class="row">
                  <div class="col-8">
                    {{last_message?.first_name}} {{last_message?.last_name}}
                  </div>
                  <div class="col-4">
                    <small class="small font-weight-bold">
                      {{last_message.lastMessages?.date.seconds * 1000 | date:'MMM d yyyy'}}
                    </small>
                  </div>
                </div>
                <!-- <p class="mb-0 text-small" id="msg"
                  *ngIf="last_message.lastMessages?.type=== 'incoming' && last_message.lastMessages?.unread === false"
                  [innerHTML]="last_message.lastMessages?.message | slice:0:40">
                </p>
                <p style="padding: 0px;"
                  *ngIf="last_message.lastMessages?.type=== 'incoming' && last_message.lastMessages?.unread === true">
                  <b [innerHTML]="last_message.lastMessages?.message | slice:0:40">
                  </b>
                </p>

                <p class=" mb-0 text-small" id="msg" *ngIf="last_message.lastMessages?.type=== 'outgoing'"
                  [innerHTML]="last_message.lastMessages?.message | slice:0:40">
                  <span class="material-icons" style="float:right;font-size: 18px;padding-right: 10%;">done_all</span>
                </p> -->
              </div>
            </div>
          </a>
        </div>

        <div *ngIf="hasMoreRecords==true" style="text-align: center;"><button mat-button (click)="fetchContact()">Show
            More</button></div>
        <div *ngIf="hasMoreRecords==false" style="text-align: center;">No more records to show</div>

      </div>
    </div>
  </div>
</p-scrollPanel>