import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as moment from 'moment';
import algoliasearch from "algoliasearch/lite";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

const searchClient = algoliasearch(
  'I0OTY0C5ZB',
  '25abaa049834414cfa024ce599de178c'
);
const algoliaContacts = searchClient.initIndex(environment.contacts);


@Injectable({
  providedIn: 'root'
})
export class RewardCampaignService {
  rewardPoints: number = 0;
  contactRef: any;
  enableEmailSms: any;
  isOnValue: boolean;
  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar
  ) {

  }

  async getRewardCampaigns() {
    return await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('loyalty_program_config').get();
  }

  async getOnRewardCampaigns() {
    return await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('loyalty_program_config').where('isOn', '==', true).get();
  }

  async updateRewardToggle(isOnValue: boolean, rewardDoc: any) {
    this.isOnValue = isOnValue;
    let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('loyalty_program_config').where('title', '==', rewardDoc.title).get();
    console.log(snapshot);
    if (!snapshot.empty) {
      console.log(snapshot);
      console.log(snapshot.docs[0].data());
      snapshot.docs[0].ref.update({ 'isOn': this.isOnValue });
      if (this.isOnValue === true) {
        this._snackBar.open("Loyalty reward turned ON.", "",
          {
            duration: 3000,
            verticalPosition: 'bottom',
            panelClass: ['mat-snack-bar-container']
          }
        );
      }
      else {
        this._snackBar.open("Loyalty reward turned OFF.", "",
          {
            duration: 3000,
            verticalPosition: 'bottom',
            panelClass: ['mat-snack-bar-container']
          }
        );
      }
    }
    else {
      return {}
    }
  }

  async updateRewardOption(reward: any, points: any) {
    // console.log(reward, points, this.isOnValue);
    let rewardPoints = Number(points);
    // console.log(typeof (rewardPoints));
    let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('loyalty_program_config').where('title', '==', reward.title).get();
    //  console.log(snapshot);
    if (!snapshot.empty) {
      console.log(snapshot);
      console.log(snapshot.docs[0].data());
      /* let updatedReward = {
         "createdOn": reward.createdOn,
         "icon": reward.icon,
         "isOn": reward.isOn,
         "points": rewardPoints,
         "shortDescription": reward.shortDescription,
         "title": reward.title
       }*/
      snapshot.docs[0].ref.update({ "points": rewardPoints });
      this._snackBar.open("Loyalty Points updated", "",
        {
          duration: 3000,
          verticalPosition: 'bottom',
          panelClass: ['mat-snack-bar-container']
        }
      );
    }
    else {
      return {}
    }
  }

  async addRewardTransaction(rewardList: any, contactRef: any) {
    console.log(rewardList, contactRef);
    console.log(rewardList);
    for (let i = 0; i < rewardList.length; i++) {
      console.log(rewardList[i]);
      let grantedReward = {
        "contact": contactRef,
        "icon": rewardList[i].icon,
        "points": rewardList[i].points,
        "shortDescription": rewardList[i].shortDescription,
        "status": 'GRANTED',
        "title": rewardList[i].title,
        "createdOn": moment().toDate()
      }
      console.log(grantedReward);
      let collectionRef = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('loyaltyProgram').add(grantedReward);
    }
    console.log(rewardList);



  }

  async getContactDetails(contactId: any) {
    let collectionRef = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection("contacts").doc(`${contactId}`).get();
    return collectionRef;
  }

  async getContactRewards(contact: any) {
    let contactRef = await contact;
    console.log(contactRef['ref']);
    this.contactRef = contactRef['ref'];
    let contactReference = contactRef['ref'];
    return await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('loyaltyProgram').where('contact', '==', contactReference).get();
  }

  async redeemRewardPoints(rewardData: any, rewardPoints: any) {
    console.log("redeem points", rewardData, rewardPoints, this.contactRef);
    let redeemedReward =
    {
      points: Number(rewardPoints),
      status: 'REDEEMED',
      createdOn: moment().toDate(),
      contact: this.contactRef
    };
    console.log(redeemedReward);
    let collectionRef = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('loyaltyProgram').add(redeemedReward);
  }

  async fetchContactByPagingAlgolia(searchText: any) {
    let resp = await algoliaContacts.search(searchText, {
      filters: `_tags: '${sessionStorage.getItem('current_acc_id')}' AND active: 'true'`,
      hitsPerPage: 20,
      page: 0,
      attributesToHighlight: [],
      cacheable: false
    })
    return resp;
  }

  async sendLoyaltyProgramRewards(contactId: any) {
    let loyaltyConfigurations = await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('settings')
      .where('type', '==', 'loyalty-message-configuration')
      .get();

    loyaltyConfigurations.forEach(doc => {
      this.enableEmailSms = doc.data();
      console.log(this.enableEmailSms);
      console.log(doc.data());
    })
    console.log(contactId);
    let accountId = sessionStorage.getItem('current_acc_id');
    console.log(accountId);
    let rewardObj = {
      "accountId": accountId,
      "contactId": contactId
    }
    console.log(rewardObj);
    if (this.enableEmailSms.enableEmail === true || this.enableEmailSms.enableSms) {
      this.http.post(`${environment.cloudFunctionServerUrl}/sendLoyaltyProgramRewards`, rewardObj).subscribe(resp => {
        //this.http.post(`http://localhost:5001/mktgbot-181017/us-central1/sendLoyaltyProgramRewards`, rewardObj).subscribe(resp => {
        if (resp) {
          console.log(resp);
          console.log("Sent successfully!");
        } else {
          console.log("Could not send");
        }
      });
    }
    else {
      console.log("Email and sms is disabled");
    }
  }

  async getLoyaltyMessageConfigurations() {
    return (await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('settings')
      .where('type', '==', 'loyalty-message-configuration')
      .get());
  }


  async updateLoyaltyMessageConfiguration(data, docId) {
    return await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('settings')
      .doc(docId)
      .update(data);

  }

  async addLoyaltyMessageConfiguration(data) {
    return (await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('settings')
      .add(data))
  }



}
