import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationService } from '../../_services/pagination.service';

@Component({
  selector: 'app-infinite-scrolling-trial',
  templateUrl: './infinite-scrolling-trial.component.html',
  styleUrls: ['./infinite-scrolling-trial.component.scss']
})
export class InfiniteScrollingTrialComponent implements OnInit {

  constructor(public page: PaginationService) {}

  ngOnInit() {
    this.page.init('bic-collection', 'created_on', { reverse: false, prepend: false });
  }

  scrollHandler(e) {
    console.log(e);
    if (e === 'bottom') {
      this.page.more();
    }

    // if (e === 'top') {
    //   this.page.more()
    // }
  }

}
