import { Component, OnInit } from '@angular/core';
import { DataChannelsService } from '../_services/data-channels.service'
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { ActivatedRoute, Params, Router } from "@angular/router";
import * as moment from 'moment';



@Component({
  selector: 'app-data-channels',
  templateUrl: './data-channels.component.html',
  styleUrls: ['./data-channels.component.scss']
})
export class DataChannelsComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  isAccountCardsShow: boolean = true;
  isGmbSelected: boolean = false;
  isAdwordsSelected: boolean = false;
  isAnalyticsSelected: boolean = false;
  isfacebookSelected: boolean = false;
  accountsInformation: any = { "title": "" };
  linkedAccounts: any = [];
  gmbCredentilas: any;
  fbCredentials: any;
  analyticsCredentials: any;
  adwordsCredentials: any;
  gmbAccountUnlinkProcessStart: boolean = false;
  analyticsAccountUnlinkProcessStart: boolean = false;
  adwordsAccountUnlinkProcessStart: boolean = false;
  accountConfigurationDetails: any = {};
  accountDetails: any;
  isAllready: boolean = true;
  gmbrefreshToken: string;
  analyticsRefreshToken: string;
  isDataLoaded: boolean = true;
  moment: any = moment;
  isAGmbError: boolean = false;
  isAnalyticsError: boolean = false;
  gmbAccountconnected: boolean;
  gmailCredentials: any;
  gmailRefreshToken: any;
  gmailAccountUnlinkProcessStart: boolean = false;
  constructor(
    private dataChannelService: DataChannelsService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.dataChannelService.getLinkedAccountDetailsByRealTime();
  }

  ngOnInit(): void {
    this.dataChannelService.notification$.subscribe(async (data) => {
      if (data) {
        this.getLinkedAccountDetails();
        this.gmbAccountconnected = true;
      }
      else {
        this.getLinkedAccountDetails();
      }
    })
    this.getLinkedAccountDetails();
    this.getRouteAccountInfo();
  }

  gmbAccountInfo() {

  }

  getRouteAccountInfo() {
    this.route.queryParams.subscribe(
      async (params) => {
        //console.log(params);
        if (params["error"] == "gmb_permission_denied") {
          //console.log("error", params["error"]);
          this.isAGmbError = true;
          setTimeout(() => {
            this.isAGmbError = false
          }, 10000);
          //console.log(this.isAGmbError);
        }

        if (params["error"] == "analytics_permission_denied") {
          //console.log("error", params["error"]);
          this.isAnalyticsError = true;
          setTimeout(() => {
            this.isAnalyticsError = false
          }, 10000);
        }

        if (params["adwords_refresh_token"]) {
          //console.log(params['adwords_refresh_token']);
          this.dataChannelService
            .getAdwordsAccounts(params["adwords_refresh_token"])
            .subscribe((data) => {
              //console.log(data);
            });
        }

        else if (params["fb_access_token"]) {
          //console.log(params['fb_access_token'])
        }

        else if (params["analytics_refresh_token"]) {
          //console.log(params['analytics_refresh_token']);
          this.analyticsRefreshToken = params['analytics_refresh_token'];
          this.isAllready = false;
          this.dataChannelService.getAnalyticsAccounts(params["analytics_refresh_token"], "https://www.googleapis.com/analytics/v3/management/accounts")
            .subscribe((analyticsAcc) => {
              //console.log(analyticsAcc);
              this.accountDetails = analyticsAcc;
              this.accountsInformation['title'] = "Google Analytics";
              this.accountConfigurationDetails = {
                "accountsInformation": this.accountsInformation,
                "analyticsAccount": this.accountDetails['items'],
                "type": "analytics",
                "screen": "operation",
                "isFirstTime": "Yes",
                "analyticsRefreshToken": this.analyticsRefreshToken
              };
              this.isAccountCardsShow = false;
              this.isAllready = true;
            });
        }

        else if (params["gmb_refresh_token"]) {
          if (!this.gmbAccountconnected) {
            this.gmbrefreshToken = params["gmb_refresh_token"];
            this.isAllready = false;
            this.dataChannelService.getGmbAccounts(params['gmb_refresh_token'])
              .subscribe((gmbAccount) => {
                this.accountDetails = gmbAccount;
                this.accountsInformation['title'] = "Google My Bussiness";
                this.accountConfigurationDetails = {
                  "accountsInformation": this.accountsInformation,
                  "accountDetails": this.accountDetails,
                  "type": "gmb",
                  "screen": "operation",
                  "isFirstTime": "Yes",
                  "gmb_refresh_token": this.gmbrefreshToken
                };
                this.isAccountCardsShow = false;
                this.isAllready = true;
              })
          }
          else {
            this.isAccountCardsShow = true;
            this.isAllready = true;

          }
        }
        else if (params["gmail_refresh_token"]) {
          this.gmailRefreshToken = params["gmail_refresh_token"];
          //    if (!this.gmbAccountconnected) {
          //      this.gmailRefreshToken = params["gmail_refresh_token"];
          //   this.isAllready = false;
          //   this.dataChannelService.getGmbAccounts(params['gmail_refresh_token'])
          //     .subscribe((gmbAccount) => {
          //       this.accountDetails = gmbAccount;
          //       this.accountsInformation['title'] = "Gmail";
          //       this.accountConfigurationDetails = {
          //         "accountsInformation": this.accountsInformation,
          //         "accountDetails": this.accountDetails,
          //         "type": "gmb",
          //         "screen": "operation",
          //         "isFirstTime": "Yes",
          //         "gmb_refresh_token": this.gmbrefreshToken
          //       };
          //       this.isAccountCardsShow = false;
          //       this.isAllready = true;
          //     })
          // }
          console.log(this.gmailRefreshToken)
          let data = {
            gmail_refresh_token: this.gmailRefreshToken,
            type: "gmail",
            added_on: moment().toDate()

          }
          this.gmailAccountUnlinkProcessStart = true;
          await this.dataChannelService.saveGmailInfo(data);
          this.getLinkedAccountDetails()
          this.gmailAccountUnlinkProcessStart = false;

        }
      },
      (error) => {
        console.error(error);
        this.openSnackBar(error['error']);
      }
    );
  }

  async getLinkedAccountDetails() {
    this.linkedAccounts = [];
    this.gmbCredentilas = null;
    this.fbCredentials = null;
    this.analyticsCredentials = null;
    this.adwordsCredentials = null
    this.gmailCredentials = null;
    this.dataChannelService.getLinkedAccountDetails().then(resp => {
      this.linkedAccounts = resp.docs.map(data => data.data());
      // console.log(this.linkedAccounts)
      if (this.linkedAccounts.findIndex(value => value.type == 'gmb') >= 0)
        this.gmbCredentilas = this.linkedAccounts.filter(value => value.type == 'gmb');

      if (this.linkedAccounts.findIndex(value => value.type == 'facebook') >= 0)
        this.fbCredentials = this.linkedAccounts.filter(value => value.type == 'facebook');

      if (this.linkedAccounts.findIndex(value => value.type == 'analytics') >= 0)
        this.analyticsCredentials = this.linkedAccounts.filter(value => value.type == 'analytics');

      if (this.linkedAccounts.findIndex(value => value.type == "adwords") >= 0)
        this.adwordsCredentials = this.linkedAccounts.filter(value => value.type == "adwords");
      // for gmail account
      if (this.linkedAccounts.findIndex(value => value.type == "gmail") >= 0)
        this.gmailCredentials = this.linkedAccounts.filter(value => value.type == "gmail");
      console.log(this.gmailCredentials);
      // console.log("gmb", this.gmbCredentilas);
      //console.log("analytics", this.analyticsCredentials);
      if (this.gmbCredentilas) {
        this.gmbrefreshToken = this.gmbCredentilas[0]['gmb_refresh_token']
      }
    })


    // this.linkedAccounts =  (await this.dataChannelService.getLinkedAccountDetails()).docs.map(data=>data.data());
  }

  disconnectGmbAccount() {

  }

  async unlinkAccounts(account) {
    if (account == 'gmail') {
      this.gmailAccountUnlinkProcessStart = true;
      this.gmailCredentials = null;
      this.dataChannelService.deleteAccount(account)
      setTimeout(() => {
        this.gmailAccountUnlinkProcessStart = false;
        this.openSnackBar("Gmail account disconnected successfully");
      }, 3000);

      // (await this.dataChannelService.deleteSchedularJob()).subscribe(
      //   (resp) => {
      //     this.dataChannelService.deleteAccount(account);
      //     this.gmbAccountUnlinkProcessStart = false;
      //     this.getLinkedAccountDetails();
      //     this.openSnackBar("GMB account disconnected successfully");
      //   });

    }
    if (account == 'gmb') {
      this.gmbAccountUnlinkProcessStart = true;
      this.gmbCredentilas = null;
      (await this.dataChannelService.deleteSchedularJob()).subscribe(
        (resp) => {
          this.dataChannelService.deleteAccount(account);
          this.gmbAccountUnlinkProcessStart = false;
          this.getLinkedAccountDetails();
          this.openSnackBar("GMB account disconnected successfully");
        });

    }
    if (account == 'analytics') {
      this.analyticsAccountUnlinkProcessStart = true
      this.analyticsCredentials = null;
      this.dataChannelService.deleteAccount(account);
      setTimeout(() => {
        this.getLinkedAccountDetails();
        this.openSnackBar("Analytics account disconnected successfully");
        this.analyticsAccountUnlinkProcessStart = false;
      }, 3000);
    }
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 4000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  linkAccounts(account) {
    this.dataChannelService.linkMyGMBAccount(account);
  }

  configureAccounts(account) {
    if (account == 'gmb') {
      this.isAllready = false;
      this.dataChannelService.getGmbAccounts(this.gmbCredentilas[0]['gmb_refresh_token'])
        .subscribe((gmbAccount) => {
          this.accountDetails = gmbAccount;
          this.accountsInformation['title'] = "Google My Business";
          this.accountConfigurationDetails = {
            "accountsInformation": this.accountsInformation,
            "accountDetails": this.accountDetails,
            "selectedAccounts": this.gmbCredentilas[0]['gmb_info'],
            "type": "gmb",
            "screen": "configure",
          };
          this.isAccountCardsShow = false;
          this.isAllready = true;
        })
    }
    else if (account == 'adwords') {
      this.accountsInformation['title'] = "Google Adwords";
    }
    else if (account == 'analytics') {
      this.isAllready = false;
      this.dataChannelService.getAnalyticsAccounts(this.analyticsCredentials[0]['analytics_refresh_token'], "https://www.googleapis.com/analytics/v3/management/accounts")
        .subscribe((analyticsAcc) => {
          //console.log(analyticsAcc);
          this.accountDetails = analyticsAcc;
          this.accountsInformation['title'] = "Google Analytics";
          this.accountConfigurationDetails = {
            "accountsInformation": this.accountsInformation,
            "analyticsAccount": this.accountDetails['items'],
            "selectedAccounts": this.analyticsCredentials[0]['analytics_info'],
            "type": "analytics",
            "screen": "configure",
            "analyticsRefreshToken": this.analyticsCredentials[0]['analytics_refresh_token']
          };
          this.isAccountCardsShow = false;
          this.isAllready = true;
        });
    }
    else if (account == 'facebook') {
      this.accountsInformation['title'] = "facebook";
    }
    this.isAccountCardsShow = false;
  }

  back() {
    this.isAccountCardsShow = true;
  }

  async saveAccountInformation(data) {

    if (data['type'] == 'gmb') {
      let gmb_info = [];
      for (let i = 0; i < data['gmbData'].length; i++) {
        gmb_info.push(data['gmbData'][i]['name'])
      }

      let gmb_refresh_token = data['gmb_refresh_token'];

      this.isDataLoaded = false;
      let dataToBeStored = {
        "gmb_refresh_token": data['gmb_refresh_token'],
        "gmb_info": data['gmbData'],
        "type": "gmb",
        "added_on": new Date()
      };

      let snapShot = (await this.dataChannelService.saveGmbInformation(dataToBeStored, gmb_info));
      if (snapShot) {
        snapShot.subscribe(resp => {
          console.log(resp);
          if (resp) {
            this.dataChannelService.updateGmbDetails(resp.name);
            this.getLinkedAccountDetails();
            this.isAccountCardsShow = true;
            this.isDataLoaded = true;
          }
          else {
            this.isDataLoaded = true;
            this.getLinkedAccountDetails();
            this.isAccountCardsShow = true;
          }
        });
      }
      else {
        this.isDataLoaded = true;
        this.getLinkedAccountDetails();
        this.isAccountCardsShow = true;
      }
      this.dataChannelService.reviewNotification();
    }
    if (data['type'] == 'analytics') {
      //console.log(data);
      data['analyticsdata']['added_on'] = new Date();
      this.isDataLoaded = false;
      this.dataChannelService.saveAnalyticsData(data['analyticsdata']).then(resp => {
        //console.log(resp);
        this.getLinkedAccountDetails();
        this.isAccountCardsShow = true;
        this.isDataLoaded = true;
      })
    }
  }

  async saveEditedinformation(data) {
    if (data['type'] == 'gmb') {
      this.isDataLoaded = false;
      let dataToBeStored = {
        "gmb_info": data['gmbData'],
        "updated_on": new Date()
      };
      //console.log(dataToBeStored);
      this.dataChannelService.editGmbInformation(dataToBeStored).then(resp => {
        this.getLinkedAccountDetails();
        this.isAccountCardsShow = true;
        this.isDataLoaded = true;
      })
    }
    else if (data['type'] == 'analytics') {
      this.isDataLoaded = false;
      let dataToBeStored = {
        "analytics_info": data['analyticsdata']['analytics_info'],
        "updated_on": new Date()
      }
      this.dataChannelService.editAnalyticsInformation(dataToBeStored).then(resp => {
        this.getLinkedAccountDetails();
        this.isAccountCardsShow = true;
        this.isDataLoaded = true;
      })
    }
  }

  editGmbAcc(data) {
    if (data['type'] == 'gmb') {
      this.isDataLoaded = false;
      //console.log(this.gmbCredentilas);
      this.dataChannelService.getGmbAccounts(this.gmbCredentilas[0]['gmb_refresh_token'])
        .subscribe((gmbAccount) => {
          this.accountDetails = gmbAccount;
          this.accountsInformation['title'] = "Google My Bussiness";
          this.accountConfigurationDetails = {
            "accountsInformation": this.accountsInformation,
            "accountDetails": this.accountDetails,
            "type": "gmb",
            "screen": "operation",
            "isFirstTime": "No"
          };
          this.isDataLoaded = true;
        })
    }
    else if (data['type'] == 'analytics') {
      this.dataChannelService.getAnalyticsAccounts(data['analyticsRefreshToken'], "https://www.googleapis.com/analytics/v3/management/accounts")
        .subscribe((analyticsAcc) => {
          //console.log(analyticsAcc);
          this.accountDetails = analyticsAcc;
          this.accountsInformation['title'] = "Google My Bussiness";
          this.accountConfigurationDetails = {
            "accountsInformation": this.accountsInformation,
            "analyticsAccount": this.accountDetails['items'],
            "type": "analytics",
            "screen": "operation",
            "isFirstTime": "No",
            "analyticsRefreshToken": this.accountConfigurationDetails['accountDetails'][0]['analytics_refresh_token']
          };
        });
    }
  }

  analyticsAccountConfiguration(data) {
    //console.log(data);
    if (data['type'] == 'propertise') {
      this.dataChannelService.getAnalyticsAccounts(data['analyticsRefreshToken'], data['endpoint'])
        .subscribe((analyticsAcc) => {
          //console.log(analyticsAcc);
          this.accountDetails = analyticsAcc;
          this.accountsInformation['title'] = "Google My Bussiness";
          this.accountConfigurationDetails = {
            "accountsInformation": this.accountsInformation,
            "analyticsPropertise": this.accountDetails['items'],
            "type": "analytics",
            "screen": data['screen'],
            "isFirstTime": data['isFirstTime'],
            "analyticsRefreshToken": data['analyticsRefreshToken']
          };
        });
    }
    else if (data['type'] == 'view') {
      this.dataChannelService.getAnalyticsAccounts(data['analyticsRefreshToken'], data['endpoint'])
        .subscribe((analyticsAcc) => {
          //console.log(analyticsAcc);
          this.accountDetails = analyticsAcc;
          this.accountsInformation['title'] = "Google My Bussiness";
          this.accountConfigurationDetails = {
            "accountsInformation": this.accountsInformation,
            "analyticsViews": this.accountDetails['items'],
            "type": "analytics",
            "screen": data['screen'],
            "isFirstTime": data['isFirstTime'],
            "analyticsRefreshToken": data['analyticsRefreshToken']
          };
        });
    }
  }

}
