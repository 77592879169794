import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocialMediaService } from '../../_services/social-media.service';
@Component({
  selector: 'app-delete-template',
  templateUrl: './delete-template.component.html',
  styleUrls: ['./delete-template.component.scss']
})
export class DeleteTemplateComponent implements OnInit {
  template: any;
  disableBtnFlag: boolean = false;
  popupHeader: string;
  type: string;
  nameError: boolean = false;
  templateNames: any;
  imageUrl: string;
  templateName: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private socialMediaService: SocialMediaService,
    public matdialogRef: MatDialogRef<DeleteTemplateComponent>
  ) { }

  ngOnInit(): void {
    this.type = this.data.type;
    this.popupHeader = this.data.popupHeader
    if (this.type == 'delete') {
      // console.log(this.data)
      this.template = this.data.data;
      // console.log(this.template);
      this.templateNames = this.data.templateNames;
      console.log(this.templateNames);
      this.templateName = this.template.name;
      this.imageUrl = this.template.previewTemplateUrl;
    } else {
      console.log(this.data.data);
      this.templateName = this.data.data.type;
      this.imageUrl = this.data.data.postUrl;
    }

  }
  async deleteTemplate(template) {
    this.disableBtnFlag = true;
    console.log(template);
    let resp = await this.socialMediaService.deleteTemplate(template);
    console.log(resp);

    this.matdialogRef.close(template.name);
    this.disableBtnFlag = false;

  }
  cancelDelete() {
    this.matdialogRef.close();
  }
  async updateTemplate(template) {
    console.log(template);
    this.disableBtnFlag = true;
    let resp = await this.socialMediaService.updateTemplate(template);
    this.disableBtnFlag = false;
    this.matdialogRef.close();
  }

  onSearchChange(event) {
    // console.log(event);
    if (this.templateNames.includes(this.template.name)) {
      this.nameError = true;
    } else {
      this.nameError = false;
    }
  }
  async deletePost(imageUrl) {
    this.disableBtnFlag = true
    await this.socialMediaService.deletePost(imageUrl);
    this.matdialogRef.close(this.templateName);
    this.disableBtnFlag = false;
  }
}
