import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { marketingData } from "../../assets/data/marketingData";
import { MarketingOffersService } from "../_services/marketing-offers.service";
import * as moment from "moment";
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NavigationState } from '../_models';
import { NavigationService } from "../_services/navigation.service";
import { Offer } from "../_models/interfaces";
import tr from 'date-fns/locale/tr';

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { PrimeNGConfig } from 'primeng/api';


@Pipe({ name: 'sanitizeHtml' })
export class SanitizeHtmlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }

}


@Component({
  selector: 'app-create-marketting-offer',
  templateUrl: './create-marketing-offer.component.html',
  styleUrls: ['./create-marketing-offer.component.scss'],

})

export class CreateMarketingOfferComponent implements OnInit {

  @Output() offerSaved = new EventEmitter<any>();
  @Input() offerDetails: any;
  @Input() update: boolean;

  offer: Offer = {
    offerName: null,
    offerDescription: null,
    startDate: null,
    startTime: '',
    endDate: null,
    endTime: '',
    termCondition: '',
    offerType: '',
    flatDiscount: {
      discountAmount: null,
      discountPercentage: null
    },
    packageDiscount: {
      minimumQuantity: null,
      discount: null
    },
    buyXgetY: {
      buyQuantity: null,
      freeQuantity: null
    },
    trigger: {
      type: '',
      value: ''
    },

    target: [
      { condition: 'is', rule: '*', },
    ],
    template: {},
    isActive: true
  }

  fileToUpload: FileList;

  minDateStart: Date = new Date();
  maxDateStart: Date;
  minDateEnd: Date;
  startTime: string;
  endTime: string;
  selectedOffer: any;
  offerTypes = [{
    name: "Flat Discount",
    propertyName: "flatDiscount",
    disable: false,
    selected: false,
    value: 'FLAT_DISCOUNT'
  },
  {
    name: "Package Discount",
    propertyName: "packageDiscount",
    disable: false,
    selected: false,
    value: 'PACKAGE_DISCOUNT',
  },
  {
    name: "Buy X  & GET Y free",
    propertyName: "buyXgetY",
    disable: false,
    selected: false,
    value: 'BUY_X_GET_Y_FREE',
  },
  {
    name: "Free Trial",
    propertyName: "flatDiscount",
    selected: false,
    disable: true
  },
  {
    name: "Free Consultation",
    propertyName: "flatDiscount",
    selected: false,
    disable: true
  },
  {
    name: "Cross Promotion",
    propertyName: "flatDiscount",
    selected: false,
    disable: true
  }
  ]
  @ViewChild('offerForm') createForm: NgForm;
  showPreviewFlag: boolean = false
  template: any;
  filteredOfferTemplates = []
  OfferTemplates = [];
  previewTemplate: any;
  targetAuxilaryVerb = [{ verb: 'is' }, { verb: 'contains' }, { verb: 'begins with' }, { verb: 'except' }]



  triggerObj = {
    onScroll: 'scrollPercentge',
    inactiveMode: 'milliSeconds',
    afterPageLoad: 'milliSeconds',
    exitIntent: 'exitIntent'
  }

  isLoading: boolean = false
  submited: boolean = false
  loading: boolean = true
  constructor(
    private router: Router,
    private offerService: MarketingOffersService,
    private navigationService: NavigationService,
    public dialog: MatDialog,
    public primengConfig: PrimeNGConfig
  ) { }


  async ngOnInit() {
    this.primengConfig.ripple = true
    this.navigationService.updateState(
      new NavigationState(false, "", "", "#", "/marketing-offers")
    );
    if (this.update) {
      let templateReference = await this.offerDetails.templateReference.get();
      let templateData = templateReference.data()
      this.offer.offerName = this.offerDetails.offerName;
      this.offer.offerDescription = this.offerDetails.offerDescription;
      this.offer.startDate = this.offerDetails.startDate;
      this.offer.startTime = this.offerDetails.startTime
      this.offer.endDate = this.offerDetails.endDate;
      this.offer.endTime = this.offerDetails.endTime;
      this.offer.termCondition = this.offerDetails.termCondition;
      this.offer.imageUrl = this.offerDetails.imageUrl;
      this.offer.target = this.offerDetails.target
      this.offer.trigger = this.offerDetails.trigger
      this.offer.template = templateData
      this.offer.isActive = this.offerDetails.isActive
      this.offer.template.templateReference = this.offerDetails.templateReference
      if (this.offerDetails.flatDiscount) {
        this.offer.flatDiscount = this.offerDetails.flatDiscount;
        this.offer.offerType = this.offerDetails.offerType;
        let i = this.offerTypes.findIndex(x => (x.name == 'Flat Discount'));
        this.selectedOffer = this.offerTypes[i];
        this.offerTypes[i].selected = true;
      }
      if (this.offerDetails.packageDiscount) {
        this.offer.packageDiscount = this.offerDetails.packageDiscount;
        this.offer.offerType = this.offerDetails.offerType;
        let i = this.offerTypes.findIndex(x => (x.name == 'Package Discount'));
        this.selectedOffer = this.offerTypes[i];
        this.offerTypes[i].selected = true;
      }
      if (this.offerDetails.buyXgetY) {
        this.offer.buyXgetY = this.offerDetails.buyXgetY;
        this.offer.offerType = this.offerDetails.offerType;
        let i = this.offerTypes.findIndex(x => (x.name == 'Buy X  & GET Y free'));
        this.selectedOffer = this.offerTypes[i];
        this.offerTypes[i].selected = true;
      }
    }


    this.getAccountOfferTemplate();
  }
  async getAccountOfferTemplate() {

    let OfferTemplateSnapShot = await this.offerService.getOfferTemplate();

    for (const documentSnapshot of OfferTemplateSnapShot.docs) {
      let data = documentSnapshot.data()
      data.templateReference = documentSnapshot.ref
      this.OfferTemplates.push(data);
    }

    if (this.update) {
      this.filterOffersArray(this.offerDetails.offerType)
    }
    this.loading = false
    console.log("OfferTemplate", this.OfferTemplates)
  }


  onSubmit(formData: NgForm) {

  }

  async createOfferObject(formData) {
    let data = formData;
    let startDate = moment(`${data.startDate.toLocaleDateString()} ${data.startTime}`).toDate();
    let eDate = moment(`${data.endDate.toLocaleDateString()} ${data.endTime}`).toDate();
    const result = await this.offerService.getOfferTemplatePrview(this.getOfferPreviewObj());
    this.previewTemplate = result.parseHtml

    let object
    object = {
      offerName: data.offerName,
      startDate: startDate,
      endDate: eDate,
      startTime: this.offer.startTime,
      endTime: this.offer.endTime,
      offerDescription: data.offerDescription !== undefined ? data.offerDescription : '',//this.createForm.value.offerDescription,
      termCondition: data.termCondition !== undefined ? data.termCondition : '',
      offerType: this.selectedOffer.value,
      templateReference: this.offer.template.templateReference,
      previewTemplateUrl: this.offer.template.previewTemplateUrl,
      trigger: this.offer.trigger,
      htmlTemplate: this.previewTemplate,
      isActive: this.offer.isActive
    }
    let targetRule = []

    for (let i = 0; i < this.offer.target.length; i++) {
      if (this.offer.target[i].condition.verb != undefined) {
        let obj = {
          condition: this.offer.target[i].condition.verb,
          rule: this.offer.target[i].rule
        }
        targetRule.push(obj)
      }
      else {
        targetRule.push(this.offer.target[i])
      }
    }
    object['target'] = targetRule;

    if (this.selectedOffer.name == "Flat Discount") {
      object['flatDiscount'] = {
        discountAmount: data.flatDiscount.discountAmount !== undefined ? data.flatDiscount.discountAmount : '',
        discountPercentage: data.flatDiscount.discountPercentage !== undefined ? data.flatDiscount.discountPercentage : '',
      };
    }
    if (this.selectedOffer.name == "Package Discount") {
      object['packageDiscount'] = {
        minimumQuantity: data.packageDiscount.minQuantity !== undefined ? data.packageDiscount.minQuantity : '',
        discount: data.packageDiscount.discount !== undefined ? data.packageDiscount.discount : ''
      };
    }
    if (this.selectedOffer.name == "Buy X  & GET Y free") {
      object['buyXgetY'] = {
        buyQuantity: data.buyXgetY.buyQuantity !== undefined ? data.buyXgetY.buyQuantity : '',
        freeQuantity: data.buyXgetY.freeQuantity !== undefined ? data.buyXgetY.freeQuantity : ''
      };
    }
    return object;


  }

  closeCreateModel() {
    this.router.navigate(['marketing-offers'])
  }
  enableSelected(data) {
    this.offerTypes.forEach(ele => { ele.selected = false })
    data.selected = true
    this.selectedOffer = data
    this.filterOffersArray(data.value)
  }
  changedFromDate(event) {
    this.minDateEnd = new Date(event);
  }
  changedToDate(event) {
    this.maxDateStart = new Date(event);
  }

  checkFlatDiscountValid() {
    if (this.selectedOffer.name == "Flat Discount" && this.createForm !== undefined) {
      let data = this.createForm !== undefined ? this.createForm.value : {};
      if (data.flatDiscount) {
        if (Object.values(data.flatDiscount).every(x => (x === null || x === ''))) {
          return false
        }
        else {
          return true
        }
      }
      else {
        return false
      }
    }
    else {
      return true;
    }
  }
  diselectOfferAmount() {
    let fGroup = this.createForm.controls['flatDiscount'] as FormGroup;
    fGroup.controls['offerAmount'].setValue('');
  }
  diselectOfferDiscount() {
    let fGroup = this.createForm.controls['flatDiscount'] as FormGroup;
    fGroup.controls['offerDiscount'].setValue('');
  }


  onFileSelected(data) {
    if (data.target.files) {
      this.fileToUpload = data.target.files;
      const reader = new FileReader();
      reader.readAsDataURL(data.target.files[0]);
      reader.onload = () => {
        this.offer.imageUrl = reader.result as string;
      }

    }
  }

  getOfferPreviewObj() {
    // this.showPreviewFlag = true

    let triggerValue = this.triggerObj[this.offer.trigger.type] == 'milliSeconds' ? this.offer.trigger.value * 1000 : this.offer.trigger.value
    console.log(this.createForm.value)
    let obj = {
      // template: this.OfferTemplates[0].template,
      template: this.offer.template.template,
      offerData: this.createForm.value
    }
    obj.offerData.javascriptFunction = `<script>window.addEventListener("message", event => { 
             let data = JSON.parse(event.data);
            window.parent.postMessage(JSON.stringify({ eventType: 'OPEN',offerIframeId: data.id,${this.offer.trigger.type}:true,${this.triggerObj[this.offer.trigger.type]}:${this.offer.trigger.value ? triggerValue : true}}), "*"); 
      });</script>`

    return obj
  }

  async showPreview() {
    this.isLoading = true;
    let lodingTemplate = `<!DOCTYPE html>
    <html>
    <head>
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <style>
            .loader {
              text-align: center;
                border: 10px solid #f3f3f3;
                border-radius: 50%;
                border-top: 10px solid #3498db;
                width: 50px;
                height: 50px;
                margin: auto;
                -webkit-animation: spin 1s linear infinite;
                /* Safari */
                animation: spin 1s linear infinite;
            }
    
            /* Safari */
            @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }
    
                100% {
                    -webkit-transform: rotate(360deg);
                }
            }
    
            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }
    
                100% {
                    transform: rotate(360deg);
                }
            }
        </style>
    </head>
    
    <body style=" text-align: center;">
    
        <h2>Privew is loading please wait...</h2>
    
        <div class="loader"></div>
    
    </body>
    
    </html>`
    var myWindow = window.open('', '_blank', 'left=20,top=20,width=1024,height=640,toolbar=0,resizable=0,location=0');
    myWindow.document.write(lodingTemplate);
    this.offerService.getOfferTemplatePrview(this.getOfferPreviewObj()).then(resp => {
      this.previewTemplate = resp.parseHtml
      this.isLoading = false
      myWindow.document.body.innerHTML = this.previewTemplate
      // myWindow.document.write(this.previewTemplate)

    })


  }

  addCondition() {
    this.offer.target.push({ condition: 'is', rule: '', },)
  }
  deleteCondition(i) {
    this.offer.target.splice(i, 1);
  }

  saveOffer() {
    this.submited = true
    console.log(this.offer)
    this.createOfferObject(this.offer).then(resp => {
      let object = resp;
      if (this.update == false) {
        object['createdOn'] = new Date();
        object['updatedOn'] = new Date();
        this.offerService.createNewOffer(object);
      }
      else {
        object['updatedOn'] = new Date();
        let field = object.offerType !== this.offerDetails.offerType ? this.offerDetails.offerType : '';
        this.offerService.updateOffer(this.offerDetails.offerId, object, field);
      }
      this.offerSaved.emit({ result: 'offerSaved' });
    });
  }

  radioButtonValueChanged() {
    this.offer.trigger.value = ''
  }

  filterOffersArray(offerType) {
    this.filteredOfferTemplates = []
    this.filteredOfferTemplates = this.OfferTemplates.filter(function (el) {
      return el.offerType == offerType
    });
    if (!this.update)
      this.offer.template = this.filteredOfferTemplates[0]
  }

}

