import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ContactService } from "../../_services/contact.service";
import { AccountService } from "../../_services/account.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { COUNTRY_CODES } from "../../_constants/country_phone_codes";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogData } from '../contacts-list.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormControl, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-merge-contact',
  templateUrl: './merge-contact.component.html',
  styleUrls: ['./merge-contact.component.scss']
})
export class MergeContactComponent implements OnInit {
  filterColumns=[];
  rows = 50;
  first_name: string = "";
  last_name: string = "";
  phone: string = "";
  email: string = "";
  msg: string;
  contactDoc: any;
  showForm: boolean = false;
  countryCodes: any = COUNTRY_CODES;
  countryCode: any;
  acc: any;
  sendTxtReminders: boolean = true;
  status: string = "";
  mergeButtonEnable: boolean = true;
  columns: any[];
  phoneData = [];
  emailData = [];
  selectedPhoneContacts = [];
  selectedEmailContacts = [];
  selectedValues = [];
  country: string = "+91";
  loading: boolean;
  enableDetailsOfMerged: boolean = true;
  newContact = [];
  selectedContacts = [];
  activeArray: any;
  firstNameArray: any;
  lastNameArray: any;
  emailArray: any;
  phoneArray: any;
  isPhonePrimary: boolean;
  isEmailPrimary: boolean;
  disabledPhoneSelection: boolean = true;
  phoneIndex: number = 0;
  primaryPhone: number;
  primaryEmail: string;
  send_text_reminders: boolean;
  displayedColumns: string[] = ['position', 'Country Code', 'Phone Number', 'Primary', 'Action'];
  // countries: string[] = ['+91(india)', '+44(United Kingdom)', '+1(United States)']
  // countries: Countries[] = [
  //   { value: "+91(india)", viewValue: "+91(india)" },
  //   { value: "+44(United Kingdom)", viewValue: "+44(United Kingdom)" },
  //   { value: "+1(United States)", viewValue: "+1(United States)" },
  // ];
  selectedValue: string;
  mergedContactData: any;
  Columns: any[] = [
    { header: "First Name", value: "first_name", class: "mid" },
    { header: "Last Name ", value: "last_name", class: "mid" },
    { header: "Email", value: "email", class: "mid" },
    { header: "Phone", value: "phone", class: "mid" },
    { header: "Active", value: "active", class: "mid" },
  ];

  disableSortColumns: string[] = ['first_name', 'last_name', 'email', 'phone', 'active'];

  isMergedAppointment: boolean = false;
  isMergedNotes: boolean = false;

  formCantrol_1 = new FormControl('', [Validators.compose(
    [Validators.required, Validators.pattern('^[a-zA-Z]*')]
  )]);

  formCantrol_2 = new FormControl('', [Validators.compose(
    [Validators.required, Validators.pattern('^[a-zA-Z]*')]
  )]);
  phone_control = new FormControl('', Validators.required);
  email_control = new FormControl('', Validators.required);
  countryCode_control = new FormControl('', Validators.required);
  @ViewChild(MatAutocompleteModule, { static: true }) matAutocomplete: MatAutocompleteModule;

  selectedCar2: string = '+91(india)';
  codes: SelectItem[];
  items: SelectItem[];
  item: string;

  constructor(
    private contactService: ContactService,
    private accountService: AccountService,
    public snackBar: MatSnackBar,
    public contactdialogRef: MatDialogRef<MergeContactComponent>,
    @Inject(MAT_DIALOG_DATA) public dataToMerged: DialogData
  ) {
    console.log(dataToMerged);

    this.items = [];
    for (let i = 0; i < 10000; i++) {
      this.items.push({ label: 'Item ' + i, value: 'Item ' + i });
    }

    this.codes = [
      { label: '+91(india)', value: '+91(india)' },
      { label: '+44(United Kingdom)', value: '+44(United Kingdom)' },
      { label: '+1(United States)', value: '+1(United States)' }
    ];

  }


  ngOnInit() {


    this.columns = [];
    this.first_name = "";
    this.last_name = "";
    this.email = "";
    this.phone = "";
    this.firstNameArray = [];
    this.lastNameArray = [];
    this.emailArray = [];
    this.phoneArray = [];

    let filteredEmail = [];
    let filteredPhone = [];

    let contacts = [];
    contacts = this.dataToMerged['contacts'];
    console.log(contacts);

    for (var i = 0; i < contacts.length; i++) {
      filteredPhone = this.phoneData.filter(a => a.phone == contacts[i].phone);
      if (filteredPhone.length == 0) {
        this.phoneData.push({
          index: i + 1,
          countryCode: "+91",
          phone: contacts[i].phone,
          primary: false
        });
      }

      filteredEmail = this.emailData.filter(a => a.email == contacts[i].email);
      if (filteredEmail.length == 0) {
        this.emailData.push({
          index: i + 1,
          email: contacts[i].email,
          primary: false
        });
      }
    }
    //primary phone
    if (this.primaryPhone == undefined) {
      this.primaryPhone = this.phoneData[0];
      this.makePhonePrimary(this.primaryPhone);
    }

    // primary email
    if (this.primaryEmail == undefined) {
      this.primaryEmail = this.emailData[0];
      this.makePhonePrimary(this.primaryEmail);
    }

    console.log(this.phoneData);
    console.log(this.emailData);

    this.firstNameArray = contacts.map(value => value.first_name);
    this.firstNameArray = [...new Set(this.firstNameArray)];
    if (this.firstNameArray.length == 1)
      this.first_name = this.firstNameArray[0];
    else
      this.first_name = "";

    this.lastNameArray = contacts.map(value => value.last_name);
    this.lastNameArray = [...new Set(this.lastNameArray)];
    if (this.lastNameArray.length == 1)
      this.last_name = this.lastNameArray[0];
    else
      this.last_name = "";

    // this.activeArray = contacts.map(value => value.active);
    this.selectedContacts = contacts;

  }

  newPhoneInfoRow() {
    this.phoneData.push({
      index: this.phoneData.length + 1,
      countryCode: "",
      phone: "",
      primary: true
    });
  }

  newEmailInfoRow() {
    this.emailData.push({
      index: this.emailData.length + 1,
      email: "",
      primary: true
    });
  }

  selectPhoneRow() {
    console.log(this.selectedPhoneContacts);
  }

  selectEmailRow() {
    console.log(this.selectedEmailContacts);
  }

  deletePhoneInfo(delPhoneInfo) {
    console.log(delPhoneInfo);
    const index = this.phoneData.indexOf(delPhoneInfo);
    if (index > -1) {
      this.phoneData.splice(index, 1);
    }
    // array = [2, 9]
    console.log(this.phoneData)
  }

  deleteEmailInfo(delEmailInfo) {
    console.log(delEmailInfo);
    const index = this.emailData.indexOf(delEmailInfo);
    if (index > -1) {
      this.emailData.splice(index, 1);
    }
    // array = [2, 9]
    console.log(this.phoneData)
  }

  close() {
    this.contactdialogRef.close();
  }

  mergedAppointmnet(event) {
    this.isMergedAppointment = event.checked;
    console.log(this.isMergedAppointment);
  }

  makePhonePrimary(phoneInfo) {
    for (var i = 0; i < this.phoneData.length; i++) {
      if (this.phoneData[i] == phoneInfo) {
        this.phoneData[i].primary = true;
        this.phone = this.phoneData[i].phone;
      }
      else {
        this.phoneData[i].primary = false;
      }
    }
  }

  makeEmailPrimary(emailInfo) {
    for (var i = 0; i < this.emailData.length; i++) {
      if (this.emailData[i] == emailInfo) {
        this.emailData[i].primary = true;
        this.email = this.emailData[i].email;
      }
      else {
        this.emailData[i].primary = false;
      }
    }
  }

  mergedNotes(event) {
    this.isMergedNotes = event.checked;
    console.log(this.isMergedNotes);
  }

  saveContact(contactData) {
    this.loading = true;
    this.enableDetailsOfMerged = false;
    // contactData.value['countryCode'] = "IN";
    // contactData.value['full_phone'] = "+919876567676";
    // contactData.value['sendTxtReminders'] = this.sendTxtReminders;
    // "countryCode": contactData.countryCode,
    contactData.value['first_name'] = this.first_name;
    contactData.value['last_name'] = this.last_name;
    contactData.value['email'] = this.email;
    contactData.value['phone'] = this.phone;
    contactData.value['emailInfo'] = this.emailData;
    contactData.value['phoneInfo'] = this.phoneData;
    contactData.value['created_on'] = new Date();
    contactData.value['source'] = "import";
    contactData.value['active'] = "true";
    console.log(contactData.value);

    this.contactService.saveMergedContact(this.isMergedAppointment, this.isMergedNotes, this.dataToMerged, contactData.value).then((contactData) => {
      if (contactData !== undefined) {
        console.log(contactData);
        this.mergedContactData = contactData;
        console.log(this.mergedContactData);
        this.loading = false;

        //this.contactService.mergeContactAndAppointmentsUsingTransaction(this.dataToMerged, docRef);
        // this.contactdialogRef.close();
        // let snackBarRef = this.snackBar.open("Contact saved!", "", {
        //   duration: 3000,
        // });
      }
      // else {
      // this.contactdialogRef.close();
      // let snackBarRef = this.snackBar.open("Contact updated!", "", {
      //   duration: 3000,
      // });
    });
  }

  // saveSendTextReminders(send_text_reminders: any, contact_ref: any) {
  //   console.log(send_text_reminders, contact_ref);
  // this.contactService.saveSendTextReminders(send_text_reminders, contact_ref);
  // }
}
