<div class="row" id="subHeader1">
  <div id="pageTitle" style="text-align: center;">Connected Accounts</div>
</div>

<div class="container-fluid view">
  <p class="text-center">Connect your Google, Facebook and Google Analytics accounts to see your data.</p>
  <div class="info-cards">
    <div>
      <div class="row" *ngIf="facebookCredentials; else connect_facebook">
        <div class="col-3">Facebook</div>
        <div class="col-5 mobile">{{facebookCredentials?.name}}<i class="fa fa-pencil" (click)="editFBAcc()"></i></div>
        <div class="col-4"><button mat-raised-button id="button" (click)="unlinkFBAcc()"
            [hidden]="showFBAccounts">Disconnect</button></div>
      </div>
      <ng-template #connect_facebook>
        <div class="row">
          <div class="col-3">Facebook</div>
          <div class="col-5"></div>
          <div class="col-4 connect"><button mat-raised-button id="button" (click)="linkFacebookAcc()"
              [hidden]="showFBAccounts">Connect</button></div>
        </div>
      </ng-template>
      <mat-card class="metric-card" *ngIf="showFBAccounts">
        <mat-card-header>
          <mat-card-title class="metric-caption">Select the account to link</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-radio-group class="example-radio-group" name="selectedFBAcc" [(ngModel)]="selectedFBAcc"
            (change)="saveFBAcc()">
            <mat-radio-button class="example-radio-button  col-sm" *ngFor="let account of fb_accounts"
              [value]="{'account_id': account.id, 'account_name': account.name}">
              {{ account.name }}
            </mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>
      <br>
      <mat-divider class="btn-space"></mat-divider>
      <br>

      <div class="row" *ngIf="adwordsCredentials; else connect_adwords">
        <div class="col-3">Adwords</div>
        <div class="col-5 mobile">{{adwordsCredentials?.adwords_account_name}}<i class="fa fa-pencil"
            (click)="editAdwordsAcc()"></i></div>
        <div class="col-4"><button mat-raised-button id="button"
            (click)="unlinkGoogleAdwordsAccs(adwordsCredentials?.adwords_refresh_token)"
            [hidden]="showAdwordsAccounts">Disconnect</button></div>
      </div>
      <ng-template #connect_adwords>
        <div class="row">
          <div class="col-3">Adwords</div>
          <div class="col-5"></div>
          <div class="col-4 connect"><button mat-raised-button id="button" (click)="linkAdwordsAcc()"
              [hidden]="showAdwordsAccounts">Connect</button></div>
        </div>
      </ng-template>
      <mat-card class="metric-card" *ngIf="showAdwordsAccounts">
        <mat-card-header>
          <mat-card-title class="metric-caption">Select the account to link</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-radio-group class="example-radio-group" name="selectedAdwordsAcc" [(ngModel)]="selectedAdwordsAcc"
            (change)="saveAdwordsAcc()">
            <mat-radio-button class="example-radio-button  col-sm" *ngFor="let account of adwords_accounts"
              [value]="{account_id:account.id,account_name:account.name}">
              {{ account.name }}
            </mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>

      <br>
      <mat-divider class="btn-space"></mat-divider>
      <br>
      <div class="row" *ngIf="analyticsCredentials; else connect_analytics">
        <div class="col-3">Analytics</div>
        <div class="col-5 mobile">Account details<i class="fa fa-pencil" id="icon" (click)="editAnalyticsAccount()"></i>
        </div>
        <div class="col-4"><button mat-raised-button id="button"
            (click)="unlinkGoogleAnalyticsAccs(analyticsCredentials?.analytics_refresh_token)"
            [hidden]="showAnalyticsAccounts">Disconnect</button></div>
      </div>
      <div class="row" *ngIf="analyticsCredentials">
        <div class="col-3"></div>
        <div class="col-sm-1 mobile">Name:</div>
        <div class="col-sm-5">{{analyticsCredentials?.analytics_account_name}}</div>
      </div>
      <div class="row" *ngIf="analyticsCredentials">
        <div class="col-3"></div>
        <div class="col-sm-1 mobile">Property:</div>
        <div class="col-sm-5">{{analyticsCredentials?.analytics_property_name}}</div>
      </div>
      <div class="row" *ngIf="analyticsCredentials">
        <div class="col-3"></div>
        <div class="col-sm-1 mobile">View:</div>
        <div class="col-sm-5">{{analyticsCredentials?.analytics_view_name}} {{analyticsCredentials?.ga_view_id}}</div>
      </div>
      <mat-card *ngIf="showAnalyticsAccounts">
        <div class="row">
          <div class="col-md-4">
            <mat-form-field class="container">
              <mat-select placeholder="Choose account" [(ngModel)]="selectedAnalyticsAcc" name="account">
                <mat-option *ngFor="let account of analyticsAcc" [value]="account">
                  {{account?.name}}
                  {{account?.id}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="container">
              <mat-select placeholder="Choose properties" [(ngModel)]="selectedProperty" name="account">
                <mat-option *ngFor="let property of selectedAnalyticsAcc.properties" [value]="property">
                  {{property?.name}}
                  {{property?.id}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="container">
              <mat-select placeholder="Choose views" [(ngModel)]="selectedView" (selectionChange)="saveView()"
                name="account">
                <mat-option *ngFor="let views of selectedProperty?.views"
                  [value]="{account_name:selectedAnalyticsAcc.name,property_name:selectedProperty.name,view_id:views?.ViewId,view_name:views?.name}">
                  {{views?.name}}
                  {{views?.ViewId}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card>

      <ng-template #connect_analytics>
        <div class="row">
          <div class="col-3">Analytics</div>
          <div class="col-5"></div>
          <div class="col-4 connect"><button mat-raised-button id="button" (click)="linkAnalyticsAcc()"
              [hidden]="showAnalyticsAccounts">Connect</button>
          </div>
        </div>
      </ng-template>

      <br>
      <mat-divider class="btn-space"></mat-divider>
      <br>
      <div class="row" *ngIf="gmbCredentials; else connect_gmb">
        <div class="col-2">
          Google My Bussiness
        </div>
        <div class="col-6 mobile" >
          <div class="col">
            <div class="row-6" style="text-align: center;">
              <i class="fas fa-plus-circle" style="font-size: 30px;color: #7e84f2;cursor: pointer;" pTooltip="Add account"  (click)="editGmbAcc()"></i>
            </div>
            <div class="row-6" *ngFor="let content of gmbAccounts">
              <hr>
              <div class="row" *ngIf="gmbCredentials">
                <div class="col-10">
                  {{content.locationName}}
                </div>
                <div class="col-2">
                  <i class="fas fa-trash" style="font-size: 20px;color: #7e84f2;cursor: pointer;" pTooltip="Delete account" (click)="deleteGmbAccount(content)"></i>
                </div>
              </div>
            </div>
            <hr>
            <br>
          </div>
        </div>
        <div class="col-4">
          <button mat-raised-button id="button" color="primary"
            (click)="unlinkGmbAccount(gmbCredentials.gmb_refresh_token)" [hidden]="showGmbAccounts">Disconnect</button>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-2">
        </div>
        <div class="col-6 mobile">
          <div class="col">
            <div class="row">
              <div *ngIf="isDataLoaded else loading" style="width: 100%;">
                <mat-card *ngIf="showGmbAccounts">
                  <div class="row" *ngIf="gmbLocationData else noGmbAccount">
                    <div class="col-md-11" *ngIf="gmbLocationData.length>0">
                      <mat-form-field class="container">
                        <mat-select placeholder="Choose account" [(ngModel)]="selectedGmbAccounts" name="account"
                          (selectionChange)="saveGmbAccount()">
                          <mat-option *ngFor="let location of gmbLocationData;let i =index" [value]="location">
                            {{location['locationName']}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-11" *ngIf="gmbLocationData.length==0">
                      All account selected
                    </div>
                    <div class="col-md-1" style="text-align: center;">
                     <i class="far fa-times-circle" style="font-size: 20px;color: #7e84f2;cursor: pointer;" pTooltip="Close" color="primary"(click)="close()"></i>
                    </div>
                  </div>
                  <ng-template #noGmbAccount>
                      <div style="text-align: center;" class="row">
                        <div class="col-md-10">
                          Sorry,we couldn't find an Google My Bussiness Account...
                        </div>
                        <div class="col-md-1">
                          <i class="far fa-times-circle" style="font-size: 20px;color: #7e84f2;cursor: pointer;" pTooltip="Close" color="primary"(click)="close()"></i>
                        </div>
                      </div>
                  </ng-template>
                </mat-card>
              </div>
              <div *ngIf="!allset" style="text-align: center;width: 100%;">
                <div id="gmbLoad">
                  <i class="pi pi-spin pi-spinner" id="icon"></i>
                </div>
              </div>
              <br>
              <div *ngIf="unlinkGmbStart" style="text-align: center;width: 100%;">
                <div id="gmbLoad">
                  <i class="pi pi-spin pi-spinner" id="icon"></i>
                </div>
              </div>
              <br>
              <ng-template #loading>
                <div id="spinnerDiv" style="text-align: center;">
                  <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-2">

        </div>
      </div>
      <br>
      <ng-template #connect_gmb>
        <div class="row">
          <div class="col-3">Google My Bussiness</div>
          <div class="col-5"></div>
          <div class="col-4 connect"><button mat-raised-button id="button" [hidden]="showGmbAccounts" (click)="linkMyGMBAccount()">Connect</button>
          </div>
        </div>
      </ng-template>
      <br>
    </div>
  </div>
</div>