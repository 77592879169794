import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MarketingPlanService, ApiService } from '../../_services/index';

@Component({
  selector: 'app-targeting',
  templateUrl: './targeting.component.html',
  styleUrls: ['./targeting.component.scss']
})
export class TargetingComponent implements OnInit {
  @Output() confirmTargetingValid = new EventEmitter<any>();

  countries: any = ["US", "IN"];
  chosenCountry: any = "";
  gender: any = {male: false, female: false};
  genderResp: any = {male: 0, female: 0};
  age: any = {min: 18, max: 65};
  businessModel: any = {b2b: false, b2c: false};
  businessResp: any = {b2b: 0, b2c: 0};
  interaction: any = {online: false, offline: false};
  interactionResp: any = {online: 0, offline: 0};
 public carSelected: {one:false,two:false};
  constructor(
    private apiService:ApiService,
    private marketingPlanService: MarketingPlanService
  ) {
    marketingPlanService.markets$.subscribe( info => this.chosenCountry = info.country1 );
    console.log("Chosen country");
    console.log(this.chosenCountry);
    marketingPlanService.targeting$.subscribe(
      info => {
        /*
         * Convert tinyint values obtained from backend (PHP) into boolean values
        */
        this.gender.male = info.gender.male === "1" ? true : false;
        this.gender.female = info.gender.female === "1" ? true : false;
        this.age = info.age;
        this.businessModel.b2b = info.businessModel.b2b === "1" ? true : false;
        this.businessModel.b2c = info.businessModel.b2c === "1" ? true : false;
        this.interaction.online = info.interaction.online === "1" ? true : false;
        this.interaction.offline = info.interaction.offline === "1" ? true : false;


        if (info.gender.male === null)
          this.confirmTargetingValid.emit(false);
        else
          this.confirmTargetingValid.emit("Fetched");
      }
    );
  }

  next() {
    console.log(this.chosenCountry);
    //this.country = "IN";
    /*
     * Need to send back boolean data in 0 and 1
    */
    this.genderResp.male = this.gender.male === true ? 1 : 0;
    this.genderResp.female = this.gender.female === true ? 1 : 0;
    this.businessResp.b2b = this.businessModel.b2b === true ? 1 : 0;
    this.businessResp.b2c = this.businessModel.b2c === true ? 1 : 0;
    this.interactionResp.online = this.interaction.online === true ? 1 : 0;
    this.interactionResp.offline = this.interaction.offline === true ? 1 : 0;

    let selection = {"country": this.chosenCountry, "gender": this.genderResp, "age": this.age, "businessModel": this.businessResp, "interaction": this.interactionResp};
    this.confirmTargetingValid.emit(selection);
  }
  ngOnInit() {
  }

}
