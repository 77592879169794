import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from "moment-timezone";
import { HttpClient } from '@angular/common/http';
import { COUNTRY_CODES } from '../../_constants/country_phone_codes';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from '../../_interface/options';
import { AccountService } from '../../../app/_services/account.service';
import { AfService } from "../../_services/index";
@Component({
  selector: 'app-new-sign-up',
  templateUrl: './new-sign-up.component.html',
  styleUrls: ['./new-sign-up.component.scss']
})
export class NewSignUpComponent implements OnInit {
  userForm: FormGroup
  countryCodes: any = COUNTRY_CODES;
  countryCode: any = this.countryCodes.find((x) =>
    x.timezones.includes(moment.tz.guess())
  ).code;

  countries = []
  searchedCountries: any[];
  specialties: any = [
    { value: "DENTIST", label: "I'm a Dentist" },
    { value: "PLASTIC_SURGEON", label: "I'm a Plastic Surgeon" },
  ]
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  @ViewChild('addressInput') addressInput: ElementRef;
  placeId: string
  options: Options = {
    input: "",
    types: ['establishment'],
    location: '',
    componentRestrictions: null,
    bounds: null,
    fields: [],
    strictBounds: false,
    origin: null
  };
  practiceInfo: any = {}
  source: string = "instagram"
  website: string
  clinicLogo: string = `https://www.shutterstock.com/image-vector/abstract-logo-flower-nature-energy-260nw-1643790667.jpg`
  doctorImage: string = `https://storage.googleapis.com/mktgbot-website-assets/Web-Automation-Images/doctor%20(1).jpg`
  showVerification: boolean;
  loading: boolean
  setUpDone: boolean
  passwordForm: FormGroup;
  hidePwd: boolean = true;
  hideConfirmPwd: boolean = true;
  passwordVerification: boolean
  constructor(private fb: FormBuilder,
    private http: HttpClient,
    public AfService: AfService,
    private accountService: AccountService) {
    this.userForm = this.fb.group({
      specialty: ['', Validators.required],
      practiceName: ['', Validators.required],
      countryCode: [this.countryCode, Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$")]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      degree: ['', Validators.required],
    })
    this.passwordForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    }, this.checkPasswordMatch);
  }

  ngOnInit(): void {
    this.getCountries()
  }

  getCountries() {
    return this.http.get<any>('assets/countries.json')
      .toPromise()
      .then(res => <any[]>res.data)
      .then(data => {
        this.countries = data;
        this.searchedCountries = data
      });
  }
  searchCountry(event) {
    let val = event.target.value.toLowerCase();
    this.searchedCountries = this.countries.filter((option) => {
      return option.name.toLowerCase().includes(val) || option.dial_code.toLowerCase().includes(val);
    });
  }

  slectedCountry(countryCode) {
    this.userForm.controls['countryCode'].patchValue(countryCode);
  }

  get form() {
    return this.userForm.controls
  }
  handleAddressChange(event) {
    this.website = event.website ? event.website : "",
      this.practiceInfo = {}
    this.userForm.patchValue({
      practiceName: event.name
    })

    this.practiceInfo = {
      placeId: event.place_id,
      clinic: event.name,
      address: event.formatted_address,
      mapLink: event.url ? event.url : '',
      officeHours: event.opening_hours && event.opening_hours.weekday_text && event.opening_hours.weekday_text.length > 0 ? event.opening_hours.weekday_text : ["Monday: 11:00 AM – 7:00 PM", "Tuesday: 11:00 AM – 7:00 PM", "Wednesday: 11:00 AM – 7:00 PM", "Thursday: 11:00 AM – 7:00 PM", "Friday: 11:00 AM – 7:00 PM", "Saturday: 11:00 AM – 7:00 PM", "Sunday: Closed"],
    }
  }

  next() {
    this.showVerification = true
  }

  checkOtpVerificationStatus(status) {
    if (status.action == 'otpVerification' && status.verified == true) {
      this.showVerification = false;
      this.passwordVerification = true
    }
    else {
      if (status.action == 'changeEmail') {
        this.showVerification = false;
      }
    }
  }


  async submit() {

    let accId = await this.accountService.getAccountCreationId(this.practiceInfo.placeId)
    let accData = {
      practice: this.practiceInfo,
      source: this.source,
      phone: `${this.userForm.value.countryCode + this.userForm.value.phone}`,
      degree: this.userForm.value.degree,
      email: this.userForm.value.email,
      image: this.doctorImage,
      name: `${this.userForm.value.firstName} ${this.userForm.value.lastName}`,
      specialty: this.userForm.value.specialty,
      websiteUrl: this.website,
      clinicLogo: this.clinicLogo,
      password: this.passwordForm.value.password,
      accountId: accId,
      timezone: moment.tz.guess()
    }

    this.accountService.createAccountWithSignUp(accData)
      .subscribe(async (resp) => {

        let formData = {
          value: {
            email: this.userForm.value.email,
            password: this.passwordForm.value.password
          },
          valid:true
        }
        await this.AfService.loginWithEmail(formData);
        this.setUpDone = true
        console.log('Set Up Done Succesfully');
      })
  }

  checkPasswordMatch(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  passwordConfirm(data) {
    this.passwordVerification = false
    this.loading = true
    this.submit()
    console.log(data.value)
  }

}
