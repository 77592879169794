import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "angularfire2/firestore";
import { AfService, AlertService } from "../_services/index";
import { User } from "../_models/interfaces";
import { AngularFireAuth } from "angularfire2/auth";

@Component({
  selector: "app-new-login",
  templateUrl: "./new-login.component.html",
  styleUrls: ["./new-login.component.scss"],
})
export class NewLoginComponent implements OnInit {
  email: string;
  password: string;
  state: string = "";
  error: any;
  user: any;
  showVerificationMsg: boolean;
  processing: boolean;

  constructor(
    private router: Router,
    public AfService: AfService,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private alertService: AlertService
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.processing = true;
        this.user = user;
        this.handleUserAuthentication();
      }
    });
  }

  loginWithEmail(formData) {
    this.AfService.loginWithEmail(formData);
  }

  ngOnInit() {
    this.showVerificationMsg = false;
    this.processing = true;
    this.user = this.afAuth.auth.currentUser;
    this.handleUserAuthentication();
  }

  handleUserAuthentication() {
    if (this.user) {
      // FB always gives emailVerified as false, but we will log the user in anyway
      //if(this.user.emailVerified || this.user.providerData[0].providerId === 'facebook.com') {
      /*
      if(this.user.emailVerified) {
        this.router.navigateByUrl('/websites');
      }
      else {
        this.processing = false;
        this.alertService.reset('');
        this.showVerificationMsg = true;
        this.afAuth.auth.signOut();
      }
      */
      this.router.navigateByUrl("/websites");
    } else {
      /*
       * 1. Void: There was no redirect operation called. All we did was to navigate
       *    to the login component. So we should stop the spinner.
       * 2. Error: The user is null, but we were trying to log in using some provider
       *    such as FB. In this case, we should display the error that led to not
       *    getting a user and stop the spinner.
       */

      this.afAuth.auth.getRedirectResult().then(
        () => {
          this.processing = false;
        },
        (error) => {
          console.error(error.message);
          this.processing = false;
        }
      );
    }
  }

  resendVerification() {
    this.user.sendEmailVerification().then(
      (success) => {
        this.showVerificationMsg = false;
        this.alertService.success(
          "The verification email was re-sent successfully to '" +
            this.user.email +
            "'."
        );
      },
      (error) => {
        this.alertService.error(error.message);
      }
    );
  }

  login() {
    this.AfService.loginWithGoogle();
  }

  loginWithFacebook() {
    this.AfService.loginWithFacebook();
  }

  logout() {
    this.AfService.logout();
  }
}
