import { Component, OnInit } from '@angular/core';
import { OrganicRankingService } from "../_services/organic-ranking.service";
import { NavigationState } from '../_models/navigation-state';
import { AccountService } from '../_services/account.service';
import { NavigationService } from '../_services/navigation.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { timeout} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-organic-rankings',
  templateUrl: './organic-rankings.component.html',
  styleUrls: ['./organic-rankings.component.scss']
})
export class OrganicRankingsComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  showSettings:boolean = false;
  showHistory: boolean=false;
  loading:boolean= true;
  accountInfo:any;
  currentConfig:any;
  resultConfig:any;
  processingResponse:any;
  organicRanks =[];
  organicRankAnalysis = [];
  accountDetails: any;
  websiteUrl:string;
  processingOrganicRanks:boolean=false;
  enableGetOrganicRank:boolean=true;
  moment:any=moment;
  status:string;
  created_on = "Tue, 03 Aug 2021 13:15:36 GMT";
  rankAnaysisLoading:boolean = false;

  constructor(
    private organicRankService:OrganicRankingService ,
    private navigationService: NavigationService,
    private accountService: AccountService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.accountDetails = this.accountService.getCurrentAccDetails();
    this.getOrganicRankConfiguration();
  }

  getOrganicRankConfiguration(){
    this.loading = true;
    this.organicRankService.getOrganicRankConfiguration().then(snap=>{
      if(snap.empty){
        this.loading = false;
        this.resultConfig = undefined;
        this.organicRankAnalysis=[];
        this.organicRanks=[];
        this.showSettings = true;
      }
      else{
        this.currentConfig = snap.docs[0].data();
        this.websiteUrl = this.currentConfig['websiteUrl'];
        this.organicRankService.getOrganicRanks(this.currentConfig['websiteUrl']).then(resp=>{
          console.log(resp);
          if(Object.keys(resp).length > 0){
            this.resultConfig = resp;
            this.organicRanks=resp['organicRanks'].sort(function(a,b){
              return a?.ranked_serp_element?.serp_item?.rank_absolute - b?.ranked_serp_element?.serp_item?.rank_absolute;
            });;
            this.organicRankAnalysis = resp['rankAnalysis'];
          }
          else{
            this.organicRanks =[];
            this.organicRankAnalysis=[];
          }
          this.loading = false;
        })
      }
    });
  }

  closeSettings(event) {
    console.log("close settings");
    this.showSettings = false;
    this.showHistory = false;
  }

  closeHistory(docInfo) {
    console.log("close history");
    this.showSettings = false;
    this.showHistory = false;
    // console.log(docInfo);
    this.organicRankAnalysis = [];
    if(docInfo.ref){
      this.loading = true;
      this.organicRankService.getOrganicRanksOfDoc(docInfo).then(resp=>{
        // console.log("details of document are", resp);
        console.log(resp);
        this.resultConfig = resp;
        this.organicRanks=resp['organicRanks'].sort(function(a,b){
          return a?.ranked_serp_element?.serp_item?.rank_absolute - b?.ranked_serp_element?.serp_item?.rank_absolute;
        });;
        this.organicRankAnalysis = resp['rankAnalysis'];
        this.loading = false;
      });
    }
  }
  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  processOrganicRanks(){
    this.processingOrganicRanks=true;
    this.enableGetOrganicRank = false;
    this.loading = true;
    this.rankAnaysisLoading = true;
    // this.organicRanks = [];
    // this.organicRankAnalysis = [];
    this.processingResponse = undefined;
    this.organicRankService.processOrganicRankings().then(async resp=>{
      this.processingOrganicRanks = false;
      this.enableGetOrganicRank = true;
      console.log(resp);
      // this.processingResponse = resp['response'];
      if(resp['status']=="success"){
        this.organicRanks = resp['response'];
        this.organicRankService.getPreviousRankAnalysis().then(resp=>{
          this.organicRankService.previousOrganicRankObservable$.subscribe(data=>{
            // console.log(data);
            // this.organicRanks = data['organicRanks'];
            this.organicRankAnalysis = data['rankAnalysis'];
            // this.processingResponse['rankAnalysis'] = this.organicRankAnalysis;
            this.processingResponse = data;
            this.resultConfig= this.processingResponse;
            this.loading = false;
            this.rankAnaysisLoading = false;
          })
        })
      }
      else if (resp['status']=='no-data'){
        this.resultConfig= this.processingResponse;
        this.organicRanks = [];
        this.organicRankAnalysis = [];
        this.loading =false;
      }
      else{
        this.loading = false;
      }
    },
    err=>{
      console.log(err);
      this.loading = false;
    });
  }

}
