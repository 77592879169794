<div *ngIf="apptDetails.length>0 ; else elseBlock">
  <p-table [value]="apptDetails" [paginator]="true" [rows]="rows" [(first)]="first" [showCurrentPageReport]="true"
    currentPageReportTemplate="{first} - {last} of {totalRecords}">
    <!-- <div *ngFor="let appointment of apptDetails"> -->
    <ng-template pTemplate="body" let-appointment>
      <tr>
        <div class="media">
          <div class="mr-3 apptDiv">
            <div class="textBelowBtn">
              <button class="btn" id="button">{{appointment.appt_start|date:'dd'}}</button>
            </div>
            <div class="textBelowBtn">
              {{appointment.appt_start|date:'MMM yyyy'}}
            </div>
            <br>
            <a style="border-bottom: 1px solid grey;" placement="right" [ngbPopover]="popContent"
              [popoverTitle]="popTitle">
              Details
            </a>

            <ng-template #popTitle><b></b></ng-template>
            <ng-template #popContent>
              {{appointment.recurringApptDetailReference? 'Follow up visit': ''}}<br>
              {{appointment.location}}<br>
              Booked on {{appointment.booked_on | date: 'MMM d, yyyy'}}
              on {{appointment.source | titlecase}} <br>Note: {{appointment.notes}}
            </ng-template>

          </div>

          <div class="media-body apptDiv">
            <div style="text-align: center;">
              <p class="mt-0"> {{appointment.appt_start|date:'h:mm a'}} - {{appointment.appt_end|date:'h:mm a'}} </p>
            </div>
            <div class="apptSubDiv">
              {{appointment.status | titlecase}}
            </div>
            <div class="apptSubDiv">
              <app-nudge-message [data]="appointment"></app-nudge-message>
            </div>
            <div class="icon apptSubDiv">

              <div *ngIf="getDays(appointment.appt_start) < 1">
                <i class="fa fa-check" [class.disabled]="true" matTooltip="Completed"
                  (click)="changeAppointmentStatus('completed',appointment)"></i>

                <i class="fa fa-eye-slash" [class.disabled]="true" matTooltip="No show"
                  (click)="changeAppointmentStatus('no-show',appointment)"></i>

                <i class="fa fa-times" [class.disabled]="appointment.status !== 'booked'" matTooltip="Cancel"
                  (click)="changeAppointmentStatus('cancel',appointment)"></i>

                <i class="fa fa-repeat"
                  [class.disabled]="appointment.status !== 'booked' && appointment.status !== 'no-show'"
                  aria-hidden="true" matTooltip="Reschedule"
                  (click)="changeAppointmentStatus('reschedule',appointment)"></i>
              </div>

              <div *ngIf="getDays(appointment.appt_start) >= 1">
                <i class="fa fa-check" [class.disabled]="appointment.status !== 'booked'" matTooltip="Completed"
                  (click)="changeAppointmentStatus('completed',appointment)"></i>

                <i class="fa fa-eye-slash" [class.disabled]="appointment.status !== 'booked'" matTooltip="No show"
                  (click)="changeAppointmentStatus('no-show',appointment)"></i>

                <i class="fa fa-times" [class.disabled]="true" matTooltip="Cancel"
                  (click)="changeAppointmentStatus('cancel',appointmegetDaysnt)"></i>

                <i class="fa fa-repeat" [class.disabled]="true" aria-hidden="true" matTooltip="Reschedule"
                  (click)="changeAppointmentStatus('reschedule',appointment)"></i>
              </div>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </tr>
    </ng-template>
    <!-- </div> -->
  </p-table>
</div>
<ng-template #elseBlock>
  <div class="textBelowBtn">No Appointments</div>
</ng-template>

<script>
  $('.popover-dismiss').popover({
    trigger: 'focus'
  })
</script>