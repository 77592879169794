import { Component, OnInit, Output } from '@angular/core';
import { Input } from '@angular/core';
import { Emitter } from '@fullcalendar/common';
import * as EventEmitter from 'events';
import { StarRatingComponent } from 'ng-starrating';

@Component({
  selector: 'app-reviews-card',
  templateUrl: './reviews-card.component.html',
  styleUrls: ['./reviews-card.component.scss']
})
export class ReviewsCardComponent implements OnInit {
  //here reviews coming from reviewscompont(container)
  @Input() reviews: any;
  val3: number = 4;
  show: boolean = false;
  showShortDesciption = true;
  selectedCity1: string = '';
  cities: any = [];
  content: any;
  //@Output() pagingEmiter = new EventEmitter<any>();
  // this.pagingEmiter.emit(this.selectedLanguage);
  constructor() { }
  ngOnInit(): void {
    // console.log(this.reviews)
  }
  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    alert(`Old Value:${$event.oldValue},
      New Value: ${$event.newValue},
      Checked Color: ${$event.starRating.checkedcolor},
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }


  showMoreContent(content) {
    content.show = !content.show;
  }
}
