import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {SignupService, AfService,AlertService } from '../_services/index';
import { AngularFireAuth } from 'angularfire2/auth';
import { ErrorStateMatcher } from "@angular/material/core";
import firebase from 'firebase/app';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormControl, FormGroup, Validators, NgForm, FormGroupDirective } from '@angular/forms';

export interface DialogData {
  accId: string,
  state:string;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-try-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [DialogService],
})
export class EmailSignupComponent implements OnInit {

  user: any;
  error: any;
  first_name: string;
  last_name: string;
  practiceName: string;
  cell_no: string;
  url: string;
  email: string;
  password: string;
  verifyPassword: string;
  showVerification: boolean = false;
  showPassword: boolean = false;
  processing:boolean=false;
  userDetails: any;
  passwordForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  dialogRef: DynamicDialogRef;
  hidePwd:boolean=true;
  hideConfirmPwd:boolean=true;

  constructor(
  private router: Router,
  public AfService: AfService,
  public afAuth: AngularFireAuth,
  private signupService:SignupService,
  public dialogService: DialogService,
  private alertService:AlertService,
) { }

  async verifyEmail(formData) {
    let email = formData.value.email;
    this.userDetails = formData.value;
    let userDetails = await this.signupService.checkEmailAlreadyPresent(email);
    if(userDetails.newUser==false){
        this.router.navigateByUrl('/');
      this.alertService.error("Email already exist. Please Login");
    }
    else {
      this.showVerification = true;
    }

  }

  signUpWithEmail(formData) {
    this.processing=true;
    this.userDetails.password = formData.value.password;
    this.userDetails.email =this.userDetails.email.toLowerCase();
    formData.value.email = this.userDetails.email;
    this.AfService.createUserAndSetDemoData(this.userDetails).then(resp=>{
      sessionStorage.setItem('current_acc_id',resp.accId);
      this.AfService.loginWithEmail(formData);
    });
  }
  openDialog(accId) {
    this.dialogRef = this.dialogService.open(AccountSetupDialog, {
      height: "520px",
      width: "800px",
      closable: false,
      header: "Account Setup",
      data: { accId: accId,state:'new'}
    });
    this.dialogRef.onClose.subscribe(event => {
      this.router.navigateByUrl('/');
    });
  }

  ngOnInit() {
    this.user = this.afAuth.auth.currentUser;
    if (this.user) {
      if (this.user.emailVerified) {
        this.router.navigateByUrl('/websites');
      }
      else {
        /*
         * An unverified user is trying to access the signup page, so send him back to
         * login page so that he can re-verify his account if he wants to.
        */
        this.router.navigateByUrl('/login');
      }
    }
    this.passwordForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    }, this.checkPasswordMatch);
  }
  checkOtpVerificationStatus(status) {
    if (status.action == 'otpVerification' && status.verified == true) {
      this.showPassword = true;
      this.showVerification = false;
    }
    else {
      if (status.action == 'changeEmail') {
        this.showPassword = false;
        this.showVerification = false;
      }
    }
  }

  checkPasswordMatch(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true }
  }
  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}

@Component({
  selector: "account-setup-dialog",
  templateUrl: "./dialog-box.html",
})
export class AccountSetupDialog {
  accId: string;
  state:string;
  isDisabled: boolean = true;
  observer;
  products = [
    {status:'incomplete',displayName:'Providers',records:0 },
    {status:'incomplete',displayName:'Locations',records:0 },
    {status:'incomplete',displayName:'Calendars',records:0 },
    {status:'incomplete',displayName:'Contacts',records:0 },
    {status:'incomplete',displayName:'Appointments',records:0 },
    {status:'incomplete',displayName:'Enquiry Forms',records:0 },
    {status:'incomplete',displayName:'Enquiry Form Responses',records:0 },
    {status:'incomplete',displayName:'Survey Forms',records:0 },
    {status:'incomplete',displayName:'Survey Form Responses',records:0 },
    {status:'incomplete',displayName:'Patient Forms',records:0 },
    {status:'incomplete',displayName:'Patient Form Responses',records:0 },
    {status:'incomplete',displayName:'Messages',records:0 },
  ];
  completedPer: number = 0;
  acknowledge: boolean = false;
  constructor(
    private signupService: SignupService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    this.accId = config.data.accId;
    this.state = config.data.state;
    this.completedPer = 0;
    firebase.firestore().collection("accounts").doc(this.accId).collection("trackCollections").get()
    .then(snap=>{
      let data = snap.docs[0].data().records;
      this.products = [...data];
      this.completedPer = Math.floor(this.products.filter(a => a.status == 'completed').length / this.products.length * 100);
      if(this.completedPer!==100){
        this.observer = firebase.firestore().collection("accounts").doc(this.accId).collection("trackCollections").onSnapshot(snap => {
            snap.docChanges().forEach(change => {
              let docData = snap.docs[0].data();
              this.products = [...docData.records];
              this.completedPer = Math.floor(this.products.filter(a => a.status == 'completed').length / this.products.length * 100);
              if (Object.values(docData.records).every(a =>{return a['status']=='completed'})) {
                this.unsubscribeChange();
              }
            })
          });
        }
      });
  }

  unsubscribeChange() {
    this.observer();
  }
  acceptAcknowledge() {
    firebase.firestore().collection('accounts').doc(this.accId).update({ acknowledged: true });
    this.ref.close('');
  }
}
