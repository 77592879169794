<div class="container"  accesskey="">

  <div class="col-sm-12 mx-auto" id="form-border">
   <div class="reset-block">
     <form  name="form" #f="ngForm" (ngSubmit)="f.form.valid" novalidate>
      <div  [hidden]="submitted">
        <h2  class="mb-4 settings_header"><i class="fa fa-gear prefix grey-text settings"></i>Profile settings</h2>
        <label for="login_id">Login ID</label>
          <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !login_id.valid }">
            <input type="text" class="form-control" id="login_id" [(ngModel)]="currentUser.login_id" name="login_id"  #login_id="ngModel" required>
            <small *ngIf = "login_id.hasError('required')" class="help-block">Login id can't be empty</small>
          </div>

          <label for="email">Email</label>
          <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
            <input type="email" class="form-control" id="email" [(ngModel)]="currentUser.email" name="email"  #email="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
            <small *ngIf = "email.hasError('pattern')" class="help-block">Enter Valid Email</small>
            <small *ngIf = "email.hasError('required')" class="help-block">Email can't be empty</small>
          </div>

          <label for="cell_no">Cell No</label>
          <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !cell_no.valid }">
            <input type="tel" class="form-control" id="cell_no" [(ngModel)]="currentUser.cell_no" name="cell_no"  #cell_no="ngModel" pattern="[789][0-9]{9}" required>
            <small *ngIf = "cell_no.hasError('pattern')" class="help-block">Enter Valid Mobile number</small>
            <small *ngIf = "cell_no.hasError('required')" class="help-block">Cell no can't be empty</small>
          </div>

         <div class="row">
          <div class="md-form form-group save_button">
              <button [disabled]="loading ||  !f.form.valid" (click)="editProfile()" class="btn btn-primary">Save Details</button>
              <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          <!--    <button class="btn btn-secondary" (click)="onCancel()">Cancel</button>-->
          </div>

          <div class="md-form form-group reset_button"  [hidden]="submitted">
              <button [disabled]="loading"   (click)="onSubmit()" class="btn btn-secondary">Reset Password</button>
          </div>
        </div>
      </div>

      <div [hidden]="!submitted" class="col-sm-12">
        <h2  class="mb-4 settings_header">Reset Password</h2>
        <label for="password">old Password</label>
        <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
            <input type="password" class="form-control" id="password" [(ngModel)]="model.password" name="password"  #password="ngModel" minlength="6">
            <small *ngIf = "password.hasError('minlength')" class="help-block">Password should contain min 6 characters</small>
        </div>

        <label for="newassword">New password</label>
        <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !newPassword.valid }">
           <input type="password" class="form-control" id="newPassword"  [(ngModel)]="model.newPassword" name="newPassword"  #newPassword="ngModel" minlength="6">
           <small *ngIf = "newPassword.hasError('minlength')" class="help-block">Password should contain min 6 characters</small>
        </div>

        <label for="verifyPassword">Confirm Password</label>
        <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !verifyPassword.valid }">
            <input type="password" class="form-control" id="verifyPassword" [(ngModel)]="model.verifyPassword" name="verifyPassword"  #verifyPassword="ngModel" minlength="6">
            <small *ngIf = "verifyPassword.hasError('minlength')" class="help-block">Password should contain min 6 characters</small>
        </div>

        <div class="md-form form-group">
           <button [disabled]="loading || !f.form.valid" (click)="changePassword()" class="btn btn-primary">Change Password</button>
           <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
      </div>

     </form>
   </div>
 </div>
