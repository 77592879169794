<div class="container-fluid" style="background: #f3f3f3ff;">
    <center>
        <h1>Create Post</h1>
    </center>
    <mat-card>
        <div>
            <form #offerForm="ngForm" novalidate class="padding-30">
                <div class="row">           
                    <div class="col-md-12">
                        <label for="topic">Topic</label>
                        <input type="text" id="topic" class="form-control" required name="topic">
                    </div>
                </div>
               
                <div class="row">
                    <div class="col-md-6">
                        <label for="contentTheme">Content Theme</label>
                        <input type="text" id="contentTheme" class="form-control" required name="contentTheme">
                    </div>
                    <div class="col-md-3">
                        <label for="date">Date</label>
                        <input type="text" id="date" class="form-control" required name="date">
                    </div>
                    <div class="col-md-3">
                        <label for="time">Time</label>
                        <input type="text" id="time" class="form-control" required name="time">
                    </div>
                </div>
                <div  class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="headline">Headline</label>
                        <textarea id="headline" type="text" rows="2" pInputTextarea></textarea>
                    </div>
                </div>
                <div  class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="socialMediaCopy">Social Media Copy</label>
                        <textarea id="socialMediaCopy" type="text" rows="5" pInputTextarea></textarea>
                    </div>
                </div>
                <div  class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="tags">Tags</label>
                        <textarea id="tags" type="text" rows="3" pInputTextarea></textarea>
                    </div>
                </div>  
            </form>
        </div>
    </mat-card>
</div>