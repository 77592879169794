import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AngularFireAuth } from "angularfire2/auth";
import { AlertService } from "../_services/index";

@Component({
  selector: "app-verification",
  templateUrl: "./verification.component.html",
  styleUrls: ["./verification.component.scss"],
})
export class VerificationComponent implements OnInit {
  mode: string;
  code: string;
  heading: string;
  msg: string;
  loading:boolean=true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.mode = params["mode"];
      this.code = params["oobCode"];

      this.handleRequest();
    });
  }

  handleRequest() {
    switch (this.mode) {
      case "resetPassword":
        this.handlePasswordReset();
        break;
      case "verifyEmail":
        this.loading=false;
        this.handleEmailVerification();
        break;
      default:
        this.loading=false;
        console.error("An unknown verification is being requested.");
        break;
    }
  }

  handlePasswordReset() {
    this.afAuth.auth.verifyPasswordResetCode(this.code).then(
      (email) => {
        this.heading = "Password Reset can proceed further";
        this.msg = "Your code was valid";
        this.router.navigate(['/reset-password', this.code]);
        // this.router.navigate([
        //   { outlets: { loggedOut: `reset-password/${this.code}` } },
        // ]);
      },
      (error) => {

        this.heading = "There was an error while resetting your password";
        this.loading = false;
        switch (error.code) {
          case "auth/expired-action-code":
            this.msg = "The password reset link has expired.";
            break;
          case "auth/invalid-action-code":
            this.msg =
              "The password reset code is either invalid or has already been used.";
            break;
          case "auth/user-disabled":
            this.msg = "Your user account is disabled.";
            break;
          case "auth/user-not-found":
            this.msg = "Your user account has been deleted.";
            break;
          default:
            this.msg = "An unknown error has occurred.";
            break;
        }
      }
    );
  }

  handleEmailVerification() {
    this.afAuth.auth.applyActionCode(this.code).then(
      () => {
        this.heading = "Email Verified";
        this.msg = "You can now login to mktgbot.chat using this email.";
      },
      (error) => {
        this.heading = "Email Verification Failed";
        switch (error.code) {
          case "auth/expired-action-code":
            this.msg = "The verification link has expired.";
            break;
          case "auth/invalid-action-code":
            this.msg =
              "The verification code is either invalid or has already been used.";
            break;
          case "auth/user-disabled":
            this.msg = "Your user account is disabled.";
            break;
          case "auth/user-not-found":
            this.msg = "Your user account has been deleted.";
            break;
          default:
            this.msg = "An unknown error has occurred.";
            break;
        }
      }
    );
  }
}
