import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COUNTRY_CODES } from "../../_constants/country_phone_codes";
import { DialogData } from '../contacts-list.component';
import { ContactService } from "../../_services/contact.service";

@Component({
  selector: 'app-merge-contacts',
  templateUrl: './merge-contacts.component.html',
  styleUrls: ['./merge-contacts.component.scss']
})
export class MergeContactsComponent {
  disableSelect = new FormControl(false);
  countryCodes: any = COUNTRY_CODES;
  countryCode: any;
  selectedContacts: any[] = [];
  emailData: any = []; // to get unique email address
  phoneData: any = []; //to get unique phone numbers
  firstNameArray: any;
  first_name: any;
  lastNameArray: any;
  last_name: any;
  primaryPhone: string;
  primaryEmail: string;
  enableDetailsOfMerged: boolean = true;
  loading: boolean;
  isMergedAppointment: boolean = true;
  isMergedNotes: boolean = true;
  mergedContactData: any;
  send_text_reminders: boolean = true;
  selectedContactsRef: any = [];
  columns: any[] = [
    { header: "First Name", value: "first_name", class: "mid" },
    { header: "Last Name ", value: "last_name", class: "mid" },
    { header: "Email", value: "email", class: "mid" },
    { header: "Phone", value: "phone", class: "mid" },
    { header: "Active", value: "active", class: "mid" },
  ];


  firstNameControl = new FormControl('', [Validators.compose(
    [Validators.required, Validators.pattern('^[a-zA-Z]*')]
  )]);

  lastNameControl = new FormControl('', [Validators.compose(
    [Validators.required, Validators.pattern('^[a-zA-Z]*')]
  )]);
  phone_control = new FormControl('', Validators.required);
  email_control = new FormControl('', Validators.required);
  countryCode_control = new FormControl('', Validators.required);

  constructor(
    private contactService: ContactService,
    public contactdialogRef: MatDialogRef<MergeContactsComponent>,
    @Inject(MAT_DIALOG_DATA) public dataToMerged: DialogData,
  ) {
    console.log(this.dataToMerged);
    this.selectedContacts = this.dataToMerged['contacts'];
    this.selectedContacts.forEach((contact, i) => {
      this.selectedContactsRef.push(contact.ref);
      let filteredPhone = this.phoneData.filter(obj => obj.phone == contact.phone);
      if (filteredPhone.length == 0) {
        this.phoneData.push({
          index: i + 1,
          countryCode: contact.countryCode,
          phone: contact.phone,
          primary: false
        });
      }
      let filteredEmail = this.emailData.filter(obj => obj.email == contact.email);
      if (filteredEmail.length == 0) {
        this.emailData.push({
          index: i + 1,
          email: contact.email.toLowerCase(),
          primary: false
        });
      }
    });

    this.firstNameArray = this.selectedContacts.map(value => value.first_name);
    this.firstNameArray = [...new Set(this.firstNameArray)];
    if (this.firstNameArray.length == 1) {
      this.first_name = this.firstNameArray[0];
    }
    else {
      this.first_name = "";
    }

    this.lastNameArray = this.selectedContacts.map(value => value.last_name);
    this.lastNameArray = [...new Set(this.lastNameArray)];
    if (this.lastNameArray.length == 1) {
      this.last_name = this.lastNameArray[0];
    }
    else {
      this.last_name = "";
    }

    //primary phone
    if (this.primaryPhone === undefined) {
      this.makePhonePrimary(this.phoneData[0]);
    }

    // primary email
    if (this.primaryEmail == undefined) {
      this.makeEmailPrimary(this.emailData[0]);
    }
  }

  //for closing the dialog box
  close() {
    this.contactdialogRef.close();
  }

  makePhonePrimary(phoneInfo) {
    for (var i = 0; i < this.phoneData.length; i++) {
      if (this.phoneData[i] == phoneInfo) {
        console.log(i);
        this.phoneData[i].primary = true;
        this.primaryPhone = this.phoneData[i];
      }
      else {
        this.phoneData[i].primary = false;
      }
    }
  }
  makeEmailPrimary(emailInfo) {
    console.log(this.emailData);
    for (var i = 0; i < this.emailData.length; i++) {
      if (this.emailData[i] == emailInfo) {
        this.emailData[i].primary = true;
        this.primaryEmail = this.emailData[i].email;
      }
      else {
        this.emailData[i].primary = false;
      }
    }
  }

  saveContact(contactData) {
    this.loading = true;
    this.enableDetailsOfMerged = false;
    this.emailData.map(x => delete x.index);
    this.phoneData.map(x => delete x.index);
    contactData.value['first_name'] = this.first_name;
    contactData.value['last_name'] = this.last_name;
    contactData.value['email'] = this.primaryEmail;
    contactData.value['phone'] = this.primaryPhone['phone'];
    contactData.value['countryCode'] = this.primaryPhone['countryCode'];
    contactData.value['emailInfo'] = this.emailData;
    contactData.value['phoneInfo'] = this.phoneData;
    contactData.value['created_on'] = new Date();
    contactData.value['source'] = "import";
    contactData.value['active'] = "true";
    contactData.value['mergedContactsReference'] = this.selectedContactsRef;
    contactData.value["full_phone"] = this.primaryPhone['countryCode'] + this.primaryPhone['phone'];
    contactData.value['sendTxtReminders'] = this.send_text_reminders;
    // contactData.value['primaryEmail'] = this.primaryEmail;
    // contactData.value['primaryPhone'] = this.primaryPhone;
    // console.log(contactData.value);

    this.contactService.saveMergedContact(this.isMergedAppointment, this.isMergedNotes, this.dataToMerged, contactData.value).then((contactData) => {
      if (contactData !== undefined) {
        console.log(contactData);
        this.mergedContactData = contactData;
        console.log(this.mergedContactData);
        this.loading = false;

        //this.contactService.mergeContactAndAppointmentsUsingTransaction(this.dataToMerged, docRef);
        // this.contactdialogRef.close();
        // let snackBarRef = this.snackBar.open("Contact saved!", "", {
        //   duration: 3000,
        // });
      }
      // else {
      // this.contactdialogRef.close();
      // let snackBarRef = this.snackBar.open("Contact updated!", "", {
      //   duration: 3000,
      // });
    }, err => {
      console.log(err);
    });
  }
  deletePhoneInfo(delPhoneInfo) {
    const index = this.phoneData.indexOf(delPhoneInfo);
    if (index > -1) {
      this.phoneData.splice(index, 1);
      if (this.phoneData.length == 1 || delPhoneInfo.primary) {
        this.makePhonePrimary(this.phoneData[0]);
      }
    }
  }
  deleteEmailInfo(delEmailInfo) {
    const index = this.emailData.indexOf(delEmailInfo);
    if (index > -1) {
      this.emailData.splice(index, 1);
      if (this.emailData.length == 1 || delEmailInfo.primary) {
        this, this.makeEmailPrimary(this.emailData[0]);
      }
    }
  }

  newPhoneInfoRow() {
    this.phoneData.push({
      index: this.phoneData.length + 1,
      countryCode: "",
      phone: "",
      primary: false
    });
  }
  newEmailInfoRow() {
    this.emailData.push({
      index: this.emailData.length + 1,
      email: "",
      primary: false
    });
  }
  setCountryCode(value, i) {
    this.phoneData[i].countryCode = value;
  }

  mergedAppointmnet(event) {
    this.isMergedAppointment = event.checked;
  }
  mergedNotes(event) {
    this.isMergedNotes = event.checked;
  }

}
