<div class="row text-center" id="subHeader1">
    <div class="col-md-3" style="margin: auto;">
        <div class="searchContainer">
            <i class="fa fa-search searchIcon"></i>
            <input class="searchBox" type="text" name="search" placeholder="Search Form" (keyup)="searchForm($event)">
        </div>
    </div>

    <div class="col-md-6" style="margin: auto;">
        <div id="pageTitle" class="center">Enquiry Forms</div>
    </div>

    <div class="col-md-3">
        <button id="button" mat-mini-fab class="format" (click)="createForm()" matTooltip="Add Enquiry Form">
            <i class="material-icons add">add</i>
        </button>
    </div>
</div>

<div id="subHeader2">
    <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex">
        <mat-tab label="List of forms" [selectedIndex]="1">
            <app-all-forms [forms]="forms"></app-all-forms>
        </mat-tab>
        <mat-tab label="Form responses" [selectedIndex]="2">
            <div class="container-fluid view">
                <div class="row">
                    <div class="col">
                        <div *ngIf="isShow; else notShow">
                            <mat-card class="metric-card">
                                <mat-card-content>
                                    <mat-list role="list">
                                        <table class="noborder">
                                            <tr>
                                                <th>Name</th>
                                                <th class="text_center">Total Responses</th>
                                                <th>Submitted</th>
                                                <th>Initiated</th>
                                            </tr>
                                            <mat-divider></mat-divider>
                                            <tr *ngFor="let form of forms">
                                                <td>{{form.name}}</td>
                                                <td class="text_center">{{form.responses}}</td>
                                                <td class="text_center">{{form.total_submitted.length}}</td>
                                                <td class="text_center">{{form.total_initiated.length}}</td>

                                                <td>
                                                    <a [routerLink]="['/form-details', form.id]"
                                                        [queryParams]="{ count: form.responses }"
                                                        [class.disabled]="!form.responses">
                                                        <mat-icon>chevron_right</mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </mat-list>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <ng-template #notShow>
                            <h6>No Forms</h6>
                        </ng-template>
                    </div>
                </div>
            </div>
        </mat-tab>

    </mat-tab-group>
</div>