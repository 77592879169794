 <div class="container-fluid" *ngIf="firstScreen">
  <div style="text-align: center; color: #5b6ebf;">
    <a href="/"><img src="../../assets/images/mktg-doctor-logo-new.png" class="logo" /></a>
  </div>

  <form #formData="ngForm" (ngSubmit)="sendOtp()">
    <div>
      <h1 style="text-align: center;">Phone number</h1>
      <div class="text-center">

        <p-dropdown [options]="codes" [style]="{'minWidth':'12%'}" [(ngModel)]="selectedCode" id="countryCode"
          placeholder="Select Country Code" optionLabel="name" [ngModelOptions]="{standalone: true}"
          [formControl]="countryCodeValidation"></p-dropdown>

        <p-inputMask mask="9999999999" [(ngModel)]="phone_no" name="phone_no" placeholder="9999999999"
          [formControl]="phoneValidation"> </p-inputMask>

        <div>
          <mat-error *ngIf="countryCodeValidation.hasError('required') && countryCodeValidation.invalid
          && (countryCodeValidation.dirty || countryCodeValidation.touched)">
            Please select country code</mat-error>
          <mat-error
            *ngIf="phoneValidation.hasError('required') && phoneValidation.invalid && (phoneValidation.dirty || phoneValidation.touched)">
            Phone number is required</mat-error>
        </div>
      </div>
    </div>
    <div style="padding-top: 5%;" class="text-center">
      <button type="submit" class="btn btn-primary" id="button" [disabled]="!formData.valid || !phone_no">Send
        OTP</button>
    </div>
  </form>
</div>

<div class="container-fluid" *ngIf="!firstScreen">
  <div style="height: 30px;width: 30px;">
    <p-dialog [header]="dialogData.header" closable="false" [(visible)]="dialogData.show"  [baseZIndex]="100">
      <p *ngIf="dialogData.value!=='invalidOtp'"> verification code sent to {{value}}  </p><br>
      <p *ngIf="dialogData.value=='invalidOtp'"> Please enter valid verification code </p><br>
      <div style="text-align: center;">
        <p-button (click)="dialogData.show=false" label="OK" styleClass="p-button-text"></p-button>
      </div>
    </p-dialog>
  </div>

  <div class="text-center">
    <!-- <div style="text-align: center; color: #5b6ebf;">
      <a href="/"><img src="../../assets/images/mktg-doctor-logo-new.png" class="logo" /></a>
    </div> -->
    <!-- <div ngIf="screenName=='phone'">
      <h1 style="text-align: center;">Phone number</h1>
      <p-inputMask [disabled]="true" [(ngModel)]="full_phone" name="full_phone" mask="+9999999999"
        placeholder="+9999999999">
      </p-inputMask>
    </div> -->
    <div>
      <h1 style="text-align: center;">Verify {{screenName|titlecase}} </h1>
      <input [disabled]="true" [(ngModel)]="value" name="full_phone" pInputText class="form-control">
    </div>

    <p>Please enter the verification code</p>
    <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config">
    </ng-otp-input>

    <div style="padding-top: 5%;" class="text-center">
      <button type="submit" class="btn btn-primary" id="button" (click)="verifyOTP()"
        [disabled]="!ngOtpInput">Verify</button>
    </div>

    <p>Did not receive the verification code?<br>
      <b (click)="resendOtp()"><a>Resend verification code</a></b>
    </p>
    <p>Entered wrong {{screenName}}? <br>
      <b (click)="changeEmail()"><a>Change {{screenName|titlecase}}</a></b>
    </p>
  </div>
</div>
