<div class="view">
  <button mat-mini-fab class="format"  style="margin: 15px;" [routerLink]="['/contacts']"  matTooltip="Contacts" style="float: right;"><i class="material-icons">person</i></button>
  <div *ngIf="!isReady">
    <center>
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p style="text-align: center;">Loading...</p>
    </center>
  </div>

  <div *ngIf="isReady">
   <div *ngIf="import_contacts_response?.length == 0">
    <p class="no-history">No Import History</p>

  </div>

  <div *ngIf="import_contacts_response?.length > 0">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >

    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let element"> {{element.createdOn|date:'MMM d, y, h:m a'}} </td>
    </ng-container>


      <ng-container matColumnDef="File Name">
        <th mat-header-cell *matHeaderCellDef> File Name </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.filename !== undefined;else novalue">{{element.filename}}</span>
        </td>
      </ng-container>


    <ng-container matColumnDef="Link">
      <th mat-header-cell *matHeaderCellDef> Link </th>
      <td mat-cell *matCellDef="let element"><a href="{{element.downloadUrl}}">Download file</a></td>
    </ng-container>


      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> {{element.status | titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="Total Contacts">
        <th mat-header-cell *matHeaderCellDef> Total Contacts </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.details !== undefined;else novalue">
            <span *ngIf="element.details.total !== undefined;else novalue">{{element.details.total}}</span>
          </span>
        </td>

      </ng-container>

      <ng-container  matColumnDef="Added">
        <th mat-header-cell *matHeaderCellDef>Added</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.details !== undefined;else novalue">
            <span *ngIf="element.details.added !== undefined;else novalue">{{element.details.added}}</span>
          </span>
        </td>
      </ng-container>


      <ng-container matColumnDef="Duplicates">
        <th mat-header-cell *matHeaderCellDef>Duplicates</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.details !== undefined;else novalue">
            <span *ngIf="element.details.duplicates !== undefined;else novalue">{{element.details.duplicates}}</span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Updated">
        <th mat-header-cell *matHeaderCellDef>Updated</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.details !== undefined;else novalue">
            <span *ngIf="element.details.updated !== undefined;else novalue">{{element.details.updated}}</span>
          </span>
        </td>
      </ng-container>

      <ng-container  matColumnDef="Error">
        <th mat-header-cell *matHeaderCellDef class="wider-cell">Error</th>
        <td mat-cell *matCellDef="let element" class="wider-cell">
            <span *ngIf="element.error !== undefined;else novalue">{{element.error}}</span>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <ng-template #novalue>
    <span>----</span>
    </ng-template>
  </div>
  </div>
</div>