<div class="calendar">


</div>
<div class=" row">
    <div class="col-md-12">
        <app-appointment-stats></app-appointment-stats>
    </div>
</div>
<div class="" style="padding:0 2% 1% 2%;" (window:resize)="onResize($event)">
    <!-- <div style="text-align: center;">
        <h3>{{title}}</h3>
    </div> -->

    <div class="desktop-only" data-html="true">
        <!-- <p-fullCalendar #fullCalendar [events]="appointmentEvent" [options]="options1">
        </p-fullCalendar> -->
        <full-calendar #calendar [events]="appointmentEvent" [options]='options1'></full-calendar>
    </div>
    <div class="mobile-view">
        <div style="text-align: center;">
            <h5>{{title}}</h5>
        </div>
        <full-calendar #calendar2 [events]="appointmentEvent" [options]="options2"></full-calendar>
    </div>
</div>