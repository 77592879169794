<div class="container-fluid">
    <div id="card-container">
        <!--<div class="row scrolling-wrapper-flexbox">-->
        <div *ngFor="let form of sample_forms" class="card-block plan">
            <mat-card>
                <img class="image" *ngIf="form.data.welcome_screen.image !== ''" mat-card-image
                    src={{form.data.welcome_screen.image}}>
                <mat-card-actions>
                    <a [routerLink]="['/interactive-form', acc_slug, form.slug]">
                        <h4 class="card-title">
                            <p align="center">{{form.data.name}}</p>
                        </h4>
                    </a>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>