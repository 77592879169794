import { Component, OnInit, Inject, ViewChild, ViewEncapsulation, HostListener } from "@angular/core";
import { Subject } from "rxjs";
import { AppointmentService, AccountService } from "../_services/index";
import { Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { DomSanitizer } from "@angular/platform-browser";
import * as Chart from "chart.js";
import { environment } from "../../environments/environment";
import { ContactService } from "../_services/index";
import { ProvidersService } from "../_services/providers.service";
// import { Calendar } from '@fullcalendar/core'; // include this line
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';
export interface AppointmentData {
  date: any;
  slot: string;
  name: string;
  email: string;
  phone: any;
  booked_on: any;
  source: string;
  patientType: string;
  location: string;
}

import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";
import * as moment from 'moment';
import { Table } from 'primeng/table';
import { MatInput } from '@angular/material/input';
import { ScheduleNewAppointmentComponent } from './schedule-new-appointment/schedule-new-appointment.component';
import { RecurringAppointmentDetailsComponent } from "../recurring-appointment-details/recurring-appointment-details.component";
//import { AppointmentStatsComponent } from '../appointment-stats/appointment-stats.component';
export interface DialogData {
  link: string;
  title: string;
}

export interface Insurance {
  value: string;
}

export interface Value {
  value: string;
}

@Component({
  selector: "app-appointments",
  templateUrl: "./appointments.component.html",
  styleUrls: ["./appointments.component.scss"],
})
export class AppointmentsComponent implements OnInit {
  // events: any[] = [
  //   {
  //     "id": 1,
  //     "title": "All Day Event",
  //     "start": "2017-02-01"
  //   },
  //   {
  //     "id": 2,
  //     "title": "Long Event",
  //     "start": "2017-02-07",
  //     "end": "2017-02-10"
  //   }
  // ];
  // options = {
  //   plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
  //   //defaultDate: '2017-02-01',
  //   header: {
  //     left: 'prev,next',
  //     center: 'title',
  //     right: 'dayGridMonth,timeGridWeek,timeGridDay'
  //   },
  //   editable: true
  // };
  appointments: any;
  timeperiod: any;
  calendarEvent: any = [];
  isShow: boolean = false;
  providerCount: number;
  //loading: boolean = true;
  insurance: Insurance[] = [
    { value: "yes" },
    { value: "no" },
    { value: "medicaid" },
  ];

  rows: number = 20;
  first: number = 0;
  filterColumns: string[] = ['status', 'first_name', 'email', 'phone', 'source', 'provider', 'location', 'last_name'];
  value: Value[] = [{ value: "high" }, { value: "medium" }, { value: "low" }];
  disableSortColumns: string[] = ['status', 'phone', 'notes', 'insurance', 'value'];

  displayedColumns: any[] = [
    { header: "Appointment Date", field: "appt_start", class: "mid" },
    // {header:"Appointment Time",field:"slot",class:"mid"},
    { header: "Status", field: "status", class: "emailStatus" },
    { header: "Booked on", field: "booked_on", class: "mid" },
    { header: "New Patient", field: "new_patient", class: "mid" },
    { header: "Name", field: "first_name", class: "mid" },
    { header: "Email", field: "email", class: "emailStatus" },
    { header: "Phone", field: "phone", class: "mid" },
    { header: "Notes", field: "notes", class: "mid" },
    { header: "Provider", field: "provider_name", class: "mid" },
    { header: "Location", field: "location", class: "mid" },
    { header: "Source", field: "source", class: "mid" },
    { header: "Insurance", field: "insurance", class: "mid" },
    { header: "Value", field: "value", class: "mid" },
  ];

  // displayedColumns: string[] = ['date', 'slot', 'name', 'email', 'phone','provider','status','insurance', 'value', 'notes', 'booked_on', 'source','new patient','location'];
  dataSource = new MatTableDataSource();

  // @ViewChild(MatPaginator, { static: false })
  // set paginator(value: MatPaginator) {
  //   this.dataSource.paginator = value;
  // }

  // @ViewChild(MatSort, { static: false })
  // set sort(value1: MatSort) {
  //   this.dataSource.sort = value1;
  // }

  status_pie: any;
  /*booked_appts :any = [];
cancelled_appts :any = [];
noShow_appts : any = [];
rescheduled_appts : any =[];
completed_appts :any =[];

percent_booked : number;
percent_rescheduled : number;
percent_cancelled :number;
percent_noShow : number;
percent_completed : number;
*/
  booked_cnt: number = 0;
  cancelled_cnt: number = 0;
  rescheduled_cnt: number = 0;
  noShow_cnt: number = 0;
  completed_cnt: number = 0;
  showChart: boolean = false;
  searchText: string = "";
  showBookAppt: boolean = false;
  link: string = "";
  providerType: any = [];
  calendarType: any = [];
  sourceType: any = [{ source: 'Google', value: 'google' }, { source: 'Facebook', value: 'facebook' }, { source: 'Website', value: 'website' }, { source: 'Import', value: 'import' }];
  locationType: any = [];
  newPatientType: any = [{ source: "Yes", value: 'yes' }, { source: 'No', value: 'no' }];
  statusType: any = [{ status: 'Booked', value: 'booked' },
  { status: 'Completed', value: 'completed' },
  { status: 'No-shows', value: 'no-show' },
  { status: 'Cancelled', value: 'cancelled' },
  { status: 'Rescheduled', value: 'rescheduled' }];
  selectedCalendar: any = [];
  selectedProvider: any = [];
  selectedSource: any = [];
  selectedStatus: any = [];
  selectedLocation: any = [];
  @ViewChild('appt') appt: Table;
  constructor(
    private navigationService: NavigationService,

    private appointmentService: AppointmentService,
    private accountService: AccountService,
    private router: Router,
    public snackBar: MatSnackBar,
    private contactService: ContactService,
    private ProvidersService: ProvidersService,
    public dialog: MatDialog
  ) {
    // const name = Calendar.name;
    this.accountService.accChange$.subscribe((accChange) => {
      this.router.navigateByUrl("/websites");
    });
    this.ProvidersService.getProviderNameArray().then((snapshot) => {
      if (snapshot.empty) {
        this.providerCount = 0;
      }
      appointmentService.appointments$.subscribe((appointments) => {
        if (this.searchText.length == 0) {
          this.appointments = appointments;
          // this.applyFilter("");
          //  this.countStatus();
          //  this.paintPieChart();
          this.isShow = true;
          // if (this.appointments.length === 0) {
          //   this.isShow = false;
          //   this.dataSource.filter = "";
          // } else {
          //   this.dataSource = new MatTableDataSource(this.appointments);
          //   this.isShow = true;
          //   this.dataSource.filter = "";
          // }
        }
        this.showBookAppt = true;
      });
      this.removeColumnIfCountZero(this.providerCount);
    });
  }

  ngOnInit() {
    //sub-header
    this.navigationService.updateState(
      new NavigationState(false, "", "", "#", "/appointments")
    );
    this.appointmentService.getCalendars().then((cals) => {
      cals.forEach((cal) => {
        let myCal = {
          doc: cal.ref,
          slug: cal.get('slug')
        }
        this.calendarType.push(myCal);
      })
    });
    this.appointmentService.getCurrentAccountDetails().then((snapshot) => {
      snapshot.forEach((doc1) => {
        // this.providerstatus = doc1.data()["calendar_link"];
        // let providerName = doc1.data()["linked_calendar"];
        // if (this.providerstatus) {
        //   let providerName = doc1.data()["name"];
        this.providerType.push(doc1.data()["name"]);
      });
    });

    this.appointmentService.getLocations().then((snapshot) => {
      snapshot.forEach((doc) => {
        this.locationType.push(doc.data());
      });
    });
    // ------------------
  }

  ngAfterViewInit() { }

  filterAppointments(dataTable: Table) {
    console.log('dataTable:', dataTable);
    if (this.searchText.length > 0 && dataTable.filteredValue != null) {
      // dataTable.totalRecords = dataTable.filteredValue.length;
      this.appointmentService.updateAppointments(dataTable.filteredValue);
    } else {
      // if (this.appointmentService.selectedCalendar.length == 0 && this.appointmentService.selectedLocation.length == 0 &&
      //   this.appointmentService.selectedProvider.length == 0 && this.appointmentService.selectedSource.length == 0 &&
      //   this.appointmentService.selectedStatus.length == 0) {
      //   // dataTable.totalRecords = dataTable.value.length;
      //   this.appointmentService.updateAppointments(this.appointmentService.allAppointments);
      // } else {
      this.appointmentService.updateAppointments(dataTable.value);
      // }

    }
  }

  applyFilters() {
    // this.blankSearch.emit(true);
    // console.log(this.appointments);
    this.appt.filterGlobal('', 'contains');
    this.appointmentService.selectedCalendar = this.selectedCalendar;
    this.appointmentService.selectedLocation = this.selectedLocation;
    this.appointmentService.selectedProvider = this.selectedProvider;
    this.appointmentService.selectedSource = this.selectedSource;
    this.appointmentService.selectedStatus = this.selectedStatus;
    this.searchText = "";
    this.appointmentService
      .applyFilters(this.selectedCalendar, this.selectedLocation, this.selectedProvider, this.selectedSource, this.selectedStatus);
  }
  clearFilters() {
    this.selectedCalendar = [];
    this.selectedLocation = [];
    this.selectedProvider = [];
    this.selectedSource = [];
    this.selectedStatus = [];
    this.appt.filterGlobal('', 'contains');
    this.searchText = "";
    this.appointmentService.clearFilters();
  }

  calendarViewChange() {
    this.appointmentService.updateAppointments([]);
    if (this.searchText.length > 0)
      this.appt.filterGlobal('', 'contains');
    this.searchText = "";
    this.selectedCalendar = [];
    this.selectedLocation = [];
    this.selectedProvider = [];
    this.selectedSource = [];
    this.selectedStatus = [];
  }

  blankSearch(event) {
    console.log(event);
    // if (event)
    //   this.searchText = '';
  }

  scheduleNewAppointment() {
    let accName = this.accountService.getCurrentAccDetails().account_slug;
    let link = `${environment.apptURL}/account/${accName}`;
    //console.log(this.link);
    const dialogRef = this.dialog.open(ScheduleNewAppointmentComponent, {
      panelClass: "custom-dialog-container",
      height: "680px",
      width: "1230px",
      data: { link: link, title: "Schedule New Appointment" },
      maxWidth: '100vw'
    });
    dialogRef.afterClosed().subscribe((closed) => {
      console.log("closed");
    });
  }

  removeColumnIfCountZero(providerCount) {
    if (this.providerCount === 0) {
      let indexOfProvider = this.displayedColumns.indexOf("provider");
      this.displayedColumns.splice(indexOfProvider, 1);
    }
  }

  countStatus() {
    this.status_pie = [];
    this.booked_cnt = 0;
    this.rescheduled_cnt = 0;
    this.cancelled_cnt = 0;
    this.noShow_cnt = 0;
    this.completed_cnt = 0;

    for (let i = 0; i < this.appointments.length; i++) {
      switch (this.appointments[i].status) {
        case "booked":
          this.booked_cnt += 1;
          //  this.booked_appts.push(this.appointments[i].status);
          //this.percent_booked = Math.round(((this.booked_appts.length/this.appointments.length) * 100));
          break;
        case "rescheduled":
          this.rescheduled_cnt += 1;
          // this.rescheduled_appts.push(this.appointments[i].status);
          // this.percent_rescheduled = Math.round(((this.rescheduled_appts.length/this.appointments.length) * 100));
          break;
        case "cancelled":
          this.cancelled_cnt += 1;
          //  this.cancelled_appts.push(this.appointments[i].status);
          //  this.percent_cancelled = Math.round(((this.cancelled_appts.length/this.appointments.length) * 100));
          break;
        case "no-show":
          this.noShow_cnt += 1;
          //this.noShow_appts.push(this.appointments[i].status);
          //  this.percent_noShow = Math.round(((this.noShow_appts.length/ this.appointments.length) * 100));
          break;
        case "completed":
          this.completed_cnt += 1;
          //this.completed_appts.push(this.appointments[i].status);
          //  this.percent_completed = Math.round(((this.completed_appts.length/this.appointments.length) * 100));
          break;
      }
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /**Filter for prime ng table */
  reset() {
    this.first = 0;
  }
  next() {
    this.first = this.first + this.rows;
  }
  prev() {
    this.first = this.first - this.rows;
  }
  isLastPage(): boolean {
    return this.first === this.appointments.length - this.rows;
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }
  /**Filter for prime ng table end*/

  saveValue(appointment: any, value: any) {
    this.appointmentService.saveAppointmentValue(appointment.ref, value);
  }

  saveInsurance(appointment: any, insurance: any) {
    this.appointmentService.saveAppointmentInsurance(
      appointment.ref,
      insurance
    );
  }

  saveNotes(appointment: any, notes: any) {
    this.appointmentService.saveAppointmentNotes(appointment.ref, notes);
  }

  updateStatus(appointment: any, status: string) {
    console.log("appointment ref:", appointment.ref);
    this.appointmentService
      .saveAppointmentStatus(appointment.ref, status)
      .then(() => {
        if (status === "completed") {
          appointment.contact.update({ last_visited: appointment.appt_end });
          appointment.status = status;
          this.snackBar.open(
            "The appointment has been marked as 'Completed'.",
            "",
            { duration: 2000 }
          );
          this.appointmentService.updateAppointments(this.appointments);
        } else if (status === "no-show") {
          appointment.status = status;
          this.snackBar.open(
            "The appointment has been marked as 'No-show'.",
            "",
            { duration: 2000 }
          );
          this.appointmentService.updateAppointments(this.appointments);
        }
      });
  }
  getDays(appt_start) {
    let now = moment(new Date());
    return Math.floor(moment.duration(now.diff(appt_start)).asDays());
  }
  cancelStatus(appointment: any) {
    if (appointment.recurringApptDetailReference) {
      let data = {
        name: `${appointment.first_name} ${appointment.last_name}`,
        appt_start: appointment.appt_start,
        slot: appointment.slot,
        clinic_name: appointment.location,
        status: appointment.status,
        notes: appointment.notes,
        appt: appointment,
        operationFlag: 'cancel'
      };
      this.openRecuringApptDetailsDialog(data, 'Recurring Appointment Details')
    }

    else {
      let cancel_link = `${environment.apptURL}/cancel/${appointment.reschedule_doc}`;
      this.openDialog(cancel_link, appointment, "CANCEL APPOINTMENT");
    }
  }

  rescheduleStatus(appointment: any) {
    if (appointment.recurringApptDetailReference) {
      let data = {
        name: `${appointment.first_name} ${appointment.last_name}`,
        appt_start: appointment.appt_start,
        slot: appointment.slot,
        clinic_name: appointment.location,
        status: appointment.status,
        notes: appointment.notes,
        appt: appointment,
        operationFlag: 'reschedule'
      };
      this.openRecuringApptDetailsDialog(data, 'Recurring Appointment Details')
    }
    else {
      let reschedule_link = `${environment.apptURL}/reschedule/${appointment.reschedule_doc}`;
      this.openDialog(reschedule_link, appointment, "RESCHEDULE APPOINTMENT");
    }
  }

  paintPieChart() {
    if (this.appointments.length > 0) {
      this.status_pie = new Chart("canvas", {
        type: "pie",
        data: {
          datasets: [
            {
              fill: true,
              backgroundColor: [
                "#1e90ff",
                "#e3bc08",
                "#a7a7a7",
                "#33cc33",
                "#ad2121",
              ],
              //  data: [this.percent_booked, this.percent_rescheduled, this.percent_cancelled, this.percent_completed, this.percent_noShow]
              data: [
                this.booked_cnt,
                this.rescheduled_cnt,
                this.cancelled_cnt,
                this.completed_cnt,
                this.noShow_cnt,
              ],
            },
          ],
          labels: [
            "Booked",
            "Rescehduled",
            "Cancelled",
            "Completed",
            "No-show",
          ],
        },
        options: {
          responsive: true,
          title: {
            display: true,
            position: "top",
            text: "Appointments",
            fontSize: 18,
            fontColor: "#111",
          },
          legend: {
            position: "top",
          },
          rotation: -0.5 * Math.PI,
          cutoutPercentage: 0,
        },
      });
      /*if(this.appointments.length == 0){
      let meta = this.status_pie && this.status_pie.data && this.status_pie.data.datasets[0]._meta;
      console.log(meta);
      for (let i in meta) {
        if (meta[i].controller) {
          meta[i].controller.chart.destroy();
          console.log("chart destroyed");
        }
      }
    }*/
      /*console.log(this.status_pie);
      let meta = this.status_pie && this.status_pie.data && this.status_pie.data.datasets[0]._meta;
      console.log(meta);
      for (let i in meta) {
        if (meta[i].controller) {
          meta[i].controller.chart.destroy();
          console.log("chart destroyed");
        }
      }*/
    } else {
      this.status_pie.push(new Chart("canvas", {
        type: "pie",
        data: {
          datasets: [
            {
              label: "No data",
              backgroundColor: ["#D3D3D3"],
              data: [100],
            },
          ],
          labels: ["No data"],
        },
        options: {
          events: [],
          hover: { mode: null },
        },
      }));
    }
  }

  openRecuringApptDetailsDialog(data: any, title: string) {
    const dialogRef = this.dialog.open(RecurringAppointmentDetailsComponent, {
      panelClass: "custom-dialog-container",
      disableClose: true,
      height: "680px",
      width: "1230px",
      maxWidth: "100vw",
      data: { apptDetails: data, title: title },
    });
  }

  openDialog(link: any, appointment: any, title: any) {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      panelClass: "custom-dialog-container",
      height: "680px",
      width: "1230px",
      maxWidth: "100vw",
      data: { link: link, title: title },
    });

    dialogRef.afterClosed().subscribe((closed) => {
      appointment.ref.get().then((doc) => {
        let status = doc.data().status;
        if (status === "rescheduled") {
          appointment.status = status;
          this.snackBar.open(
            "The appointment has been marked as 'Rescheduled'.",
            "",
            { duration: 2000 }
          );
          this.appointmentService.updateAppointments(this.appointments);
        } else if (status === "cancelled") {
          appointment.status = status;
          this.snackBar.open(
            "The appointment has been marked as 'Cancelled'.",
            "",
            { duration: 2000 }
          );
          this.appointmentService.updateAppointments(this.appointments);
        }
      });
    });
  }


  // getDays(appt_start){
  //   let now = moment(new Date());
  //   return Math.floor(moment.duration(now.diff(appt_start)).asDays());
  //   //console.log("differnce: ",Math.floor(moment.duration(now.diff(appt_start)).asDays()));
  // }
}

@Component({
  selector: "cancel-appointment-dialog",
  templateUrl: "cancel-appointment-dialog.html",
  styleUrls: ["./cancel-appointment-dialog.scss"],
})
export class DialogOverviewExampleDialog {
  link: any;
  title: any;
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    this.receiveMessage(event);
  }

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

    // window.addEventListener("message", this.receiveMessage, false);
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(data.link);
    this.title = data.title;
  }

  close() {
    this.dialogRef.close();
  }
  receiveMessage = (event: any) => {
    console.log(event.data)
    const message = JSON.parse(event.data)
    console.log(message)
    if (message.eventType == 'CLOSE')
      this.close()
  }
}