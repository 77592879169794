<div *ngIf="page==0"><i class="material-icons icon-btn" style="float: right;font-size: 3em;color:white"
        (click)="close()">close</i>
</div>
<div [ngSwitch]="page">
    <div *ngSwitchCase="1">
        <iframe width="100%" height="660px" frameborder="0" [src]="link"></iframe>
    </div>
    <div *ngSwitchCase="2">
        <iframe width="100%" height="660px" frameborder="0" [src]="link"></iframe>
    </div>
    <div *ngSwitchDefault>
        <h4 mat-dialog-title style="text-align: center;
    font-size: 1.25rem;
    background: #3f51b5;
    color: white;
    padding-top: 0.5em;
    padding-bottom: 0.8em ">{{name}}</h4>
        <div style="padding: 10px;">
            <div class="row">
                <i class="material-icons icon-btn">event</i>
                <div>
                    <p>{{date| date:"longDate"}}</p>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row">
                <i class="material-icons icon-btn">watch_later</i>
                <div>
                    <p>{{slot}}</p>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row">
                <i class="material-icons icon-btn">location_on</i>
                <div>
                    <p>{{clinic_name}}</p>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div style="padding-top: 10px;">
                <div class="ui-g" style="font-size: 12px; color: #7e7b7b;">Status</div>
                <div style="margin: 0px;">
                    <p>{{status | titlecase}}</p>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div style="padding-top: 10px;">
                <div class="ui-g" style="font-size: 12px; color: #7e7b7b;">Service Requested</div>
                <div class="" style="margin:0px;">
                    <p>{{service}}</p>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div style="padding-top: 10px;">
                <div class="ui-g" style="font-size: 12px; color: #7e7b7b;">New or Existing Patient</div>
                <div class="">
                    <p>{{new_patient}}</p>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div style="padding-top: 10px;">
                <div class="ui-g" style="font-size: 12px; color: #7e7b7b;">Notes</div>
                <div class="" style="max-height: 40px; overflow-y:auto ">
                    <!-- <input type="text" #el (keyup)="getNotes($event)" value="notes"> -->
                    <p #el [contentEditable]="true" (keyup)="getNotes($event)" [focuMe]=false>{{notes}}</p>
                </div>
            </div>
            <mat-divider></mat-divider>

            <mat-divider></mat-divider>
            <div *ngIf="status == 'booked' else showReviesAnsSendSmsButtons">
                <!-- <div><button></button></div> -->

                <div *ngIf="isApptPassed(date) else notPassed">
                    <div class="row">
                        <div *ngIf="isTwillioSet" class="col-md-3 text-center">
                            <button mat-raised-button (click)="openDialogForMessage()">Send SMS</button>
                        </div>
                        <div class="col-md-3 text-center">
                            <button mat-raised-button (click)="sendReviewInvite()">Send Review Request</button>
                        </div>

                        <div class="col-md-3 text-center">
                            <button mat-raised-button style="background-color:rgb(245, 206, 100);"
                                (click)="status='no-show'; statusChanged=true; statusUpdate()">No Show</button>
                        </div>
                        <div class="col-md-3 text-center">
                            <button mat-raised-button style="background-color:rgba(136, 185, 80, 0.829);"
                                (click)="status='completed'; statusChanged=true; statusUpdate()">Completed</button>
                        </div>
                    </div>
                </div>
                <ng-template #notPassed>

                    <div class="row">
                        <div *ngIf="isTwillioSet" class="col-md-3 text-center">
                            <button mat-raised-button (click)="openDialogForMessage()">Send SMS</button>
                        </div>
                        <div class="col-md-3 text-center">
                            <button mat-raised-button (click)="sendReviewInvite()">Send Review Request</button>
                        </div>
                        <div class="col-md-3 text-center">
                            <button mat-raised-button style="background-color:rgb(241, 49, 35);"
                                (click)="status='cancelled'; statusChanged=true; page = 1; cancelStatus()">Cancel</button>
                        </div>
                        <div class="col-md-3 text-center">
                            <button mat-raised-button style="background-color:rgb(72, 176, 245);"
                                (click)="status='rescheduled'; statusChanged=true; page = 2; rescheduleStatus()">Reshcedule</button>
                        </div>
                    </div>
                </ng-template>
            </div>

            <ng-template #showReviesAnsSendSmsButtons>
                <div class="row">
                    <div *ngIf="isTwillioSet" class="col-md-3 text-center">
                        <button mat-raised-button (click)="openDialogForMessage()">Send SMS</button>
                    </div>
                    <div class="col-md-3 text-center">
                        <button mat-raised-button (click)="sendReviewInvite()">Send Review Request</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>