import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService{

  constructor(
    private http: HttpClient
  ) { }

  getOnpageSummary(webUrl : string){

    return this.http.get(`${environment.pythonServerUrl}/on-page?url=${webUrl}`);

    /*
    console.log(webUrl);
    if(webUrl === 'aestheticsmedispa.in'){
    return this.http.get('../../assets/json/aesthetics-onpage-summary.json');
  }
  else if(webUrl ==='panditclinic.com'){
    return this.http.get('../../assets/json/pandit-clinic-onpage-summary.json');
  }
  else{
    return this.http.get('../../assets/json/pandit-clinic-onpage-summary-v2.json');
  }
  */

}

getRankedKeywords(webUrl : string,countryCode : string){
  console.log(countryCode);
  console.log(webUrl);
  let header = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'json'
    })
  };
  let data = {
    url:webUrl,
    country:countryCode,
    language:'en'
  }
  console.log(data);
  // return this.http.post(`${environment.cloudFunctionServerUrl}/rankedKeywords`,data,header);
  return this.http.get(`${environment.cloudFunctionServerUrl}/rankedKeywords?url=${webUrl}&country=${countryCode}&language=en`);
  // return this.http.get(`${environment.pythonServerUrl}/ranked-keywords?url=${webUrl}&country_code=${countryCode}&language=en`);

  /*
  console.log(webUrl);
  if(webUrl === 'aestheticsmedispa.in'){
  return this.http.get('../../assets/json/aesthetics-ranked-keywords-v2.json');
}
else {
  return this.http.get('../../assets/json/aesthetics-ranked-keywords-v3.json');
}
*/
}


getSeoResults(url: string, country: string, email: string) {
  //this.http.get(`https://mktg-seo-service.herokuapp.com?url=${url}&country=${country}`)
  this.http.get(`${environment.pythonServerUrl}/seo-results?url=${url}&country=${country}&email=${email}`)
           .subscribe(
             success => {
               console.log(success);
               // Make a call to the email function
              // this.emailSeoReport(success["report"], url, email);
             },
             error => {
               console.error(error);
             }
           );
}

emailSeoReport(report: any, url: string, email: string) {
  (firebase.functions().httpsCallable('sendSeoReport'))({ 'report': report, "url": url, "email": email })
  .then(
    result => {
      console.log(result);
    },
    error => {
      console.error(error);
    }
);
}

getLastCrawledOn(url : string){
  console.log(url);
  return firebase.firestore().collection('seo')
    .where('url','==', url)
      .get();

}
}
