import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { NavigationState } from '../_models/index';
import { NavigationService, FacebookService, TimePeriodService, AccountService, ConversionValueService } from '../_services/index';
import { DecimalPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'app-fb-campaign',
  templateUrl: './fb-campaign.component.html',
  styleUrls: ['./fb-campaign.component.scss']
})
export class FBCampaignComponent implements OnInit {
  tp = 8;
  timeperiod: any;
  campaign: any;
  campaign_conversion_value: any;
  gender_insights: any = [];
  age_insights: any = [];
  url: UrlSegment[];
  isReady: boolean;
  isAllow: boolean;
  cpc: number;
  conversions: any;
  acc: any;
  cost_per_conversion: number;
  facebookCredentials: any;

  constructor(
    private navigationService: NavigationService,
    public facebookService: FacebookService,
    private route: ActivatedRoute,
    private timePeriodService: TimePeriodService,
    private accountService: AccountService,
    private conversionValueService: ConversionValueService,
    private router: Router
  ) {
    this.isReady = false;

    this.accountService.accChange$.subscribe(accChange => {
      this.router.navigateByUrl('/websites');
      /*
      this.acc = this.accountService.getCurrentAccDetails();
      this.facebookCredentials = this.accountService.getLinkedAccountCredentials('facebook');
      let state = navigationService.getCurrentNavigationState();
      let regExp = /^\/fb-campaign/;
      if(regExp.test(state.url)) {
        this.router.navigateByUrl('/facebook');
      }
      */
    });

    this.timePeriodService.timePeriod$.subscribe(
      timeperiod => {
        this.timeperiod = timeperiod;
        let state = navigationService.getCurrentNavigationState();
        let regExp = /^\/fb-campaign/;
        //let regExp = /^\/facebook/;
        if (regExp.test(state.url)) {
          this.loadData();
        }
      }
    );
  }

  ngOnInit() {
    this.acc = this.accountService.getCurrentAccDetails();
    this.facebookCredentials = this.accountService.getLinkedAccountCredentials('facebook');
    this.timeperiod = this.timePeriodService.getCurrentTimeperiod();
    this.loadData();
  }

  loadData() {
    let timestamp;
    if (!this.acc.facebook_factor) {
      this.acc.facebook_factor = 1;
    }

    if (!this.acc.account_currency) {
      this.acc.account_currency = 'INR';
    }

    this.route.url.subscribe(url => {
      this.url = url;

      let record = JSON.parse(sessionStorage.getItem('FB-campaign:' + this.url[1].path));
      let record_gender = JSON.parse(sessionStorage.getItem('FB-campaign-gender:' + this.url[1].path));
      let record_age = JSON.parse(sessionStorage.getItem('FB-campaign-age:' + this.url[1].path));
      if (record !== null) {
        if (
          (new DatePipe('en-US')).transform(this.timeperiod.start, 'yyyy-MM-dd') === record.campaign_results.date_start &&
          (new DatePipe('en-US')).transform(this.timeperiod.end, 'yyyy-MM-dd') === record.campaign_results.date_stop
        ) {
          if (Date.now() - record.creation_timestamp < 300000) {
            console.log('We have a fresh record for this time period. Fetched from localStorage...');
            this.gender_insights = record_gender.gender_results;
            this.age_insights = record_age.age_results;
            this.campaign = record.campaign_results;
            this.conversions = this.facebookService.getTotalConversions(this.campaign);
            if (this.conversions > 0) {
              this.cost_per_conversion = (this.campaign.spend * this.acc.facebook_factor) / this.conversions;
            }
            else {
              this.cost_per_conversion = 0;
            }
            this.conversionValueService.sendCampaignDetails(
              { campaign_id: this.campaign.campaign_id, cost_per_conversion: this.cost_per_conversion, provider: "facebook" }
            );
            this.conversionValueService.getCampaignConvValue().subscribe(fb_campaign_document => {
              if (fb_campaign_document != null) {
                this.campaign_conversion_value = fb_campaign_document['conversion_value'];
              }
              else {
                this.campaign_conversion_value = -1;
              }
            });
            this.isReady = true;
            this.navigationService.updateState(new NavigationState(true, this.campaign.campaign_name, "Facebook", "/facebook", "/fb-campaign/" + this.url[1].path));
          }
          else {
            timestamp = Date.now();
            console.log('We have an old record for this time period. Called API and updated record...');
            this.facebookService.getCampaignInsightsByGender(this.url[1].path, this.facebookCredentials.facebook_access_token, this.timeperiod)
              .then(campaign => {
                this.gender_insights = campaign.data;
                this.isReady = true;
                sessionStorage.setItem('FB-campaign-gender:' + this.url[1].path, JSON.stringify({ "creation_timestamp": timestamp, "gender_results": this.gender_insights }));
              },
                error => { console.error(error); }
              );

            this.facebookService.getCampaignInsightsByAge(this.url[1].path, this.facebookCredentials.facebook_access_token, this.timeperiod)
              .then(campaign => {
                this.age_insights = campaign.data;
                this.isReady = true;
                sessionStorage.setItem('FB-campaign-age:' + this.url[1].path, JSON.stringify({ "creation_timestamp": timestamp, "age_results": this.age_insights }));
              },
                error => { console.error(error); }
              );

            this.facebookService.getCampaignInsights(this.url[1].path, this.facebookCredentials.facebook_access_token, this.timeperiod)
              .then(campaign => {
                this.campaign = campaign.data[0];
                this.conversions = this.facebookService.getTotalConversions(this.campaign);
                this.cost_per_conversion = (this.campaign.spend * this.acc.facebook_factor) / this.conversions;
                if (this.conversions > 0) {
                  this.cost_per_conversion = (this.campaign.spend * this.acc.facebook_factor) / this.conversions;
                }
                else {
                  this.cost_per_conversion = 0;
                }
                this.conversionValueService.sendCampaignDetails({ campaign_id: this.campaign.campaign_id, cost_per_conversion: this.cost_per_conversion, provider: "facebook" });
                this.conversionValueService.getCampaignConvValue()
                  .subscribe(
                    fb_campaign_document => {

                      if (fb_campaign_document != null) {

                        this.campaign_conversion_value = fb_campaign_document['conversion_value'];

                      }
                      else {
                        this.campaign_conversion_value = -1;

                      }

                    });
                this.navigationService.updateState(new NavigationState(true, this.campaign.campaign_name, "Facebook", "/facebook", "/fb-campaign/" + this.url[1].path));
                this.isReady = true;
                sessionStorage.setItem('FB-campaign:' + this.url[1].path, JSON.stringify({ "creation_timestamp": timestamp, "campaign_results": this.campaign }));
              });
          }
        }
        else {
          let timestamp;
          console.log('We do not have a record for this time period. Called API and added record...');
          this.facebookService.getCampaignInsightsByGender(this.url[1].path, this.facebookCredentials.facebook_access_token, this.timeperiod)
            .then(campaign => {
              this.gender_insights = campaign.data;
              this.isReady = true;
              sessionStorage.setItem('FB-campaign-gender:' + this.url[1].path, JSON.stringify({ "creation_timestamp": timestamp, "gender_results": this.gender_insights }));
            },
              error => { console.error(error); }
            );
          this.facebookService.getCampaignInsightsByAge(this.url[1].path, this.facebookCredentials.facebook_access_token, this.timeperiod)
            .then(campaign => {
              this.age_insights = campaign.data;
              this.isReady = true;
              sessionStorage.setItem('FB-campaign-age:' + this.url[1].path, JSON.stringify({ "creation_timestamp": timestamp, "age_results": this.age_insights }));
            },
              error => { console.error(error); }
            );

          this.facebookService.getCampaignInsights(this.url[1].path, this.facebookCredentials.facebook_access_token, this.timeperiod)
            .then(campaign => {
              this.campaign = campaign.data[0];

              this.conversions = this.facebookService.getTotalConversions(this.campaign);
              this.cost_per_conversion = (this.campaign.spend * this.acc.facebook_factor) / this.conversions;
              if (this.conversions > 0) {
                this.cost_per_conversion = (this.campaign.spend * this.acc.facebook_factor) / this.conversions;
              }
              else {
                this.cost_per_conversion = 0;
              }
              this.conversionValueService.sendCampaignDetails({ campaign_id: this.campaign.campaign_id, cost_per_conversion: this.cost_per_conversion, provider: "facebook" });
              this.conversionValueService.getCampaignConvValue()
                .subscribe(
                  fb_campaign_document => {

                    if (fb_campaign_document != null) {
                      this.campaign_conversion_value = fb_campaign_document['conversion_value'];

                    }
                    else {
                      this.campaign_conversion_value = -1;

                    }

                  });
              this.navigationService.updateState(new NavigationState(true, this.campaign.campaign_name, "Facebook", "/facebook", "/fb-campaign/" + this.url[1].path));
              this.isReady = true;
              sessionStorage.setItem('FB-campaign:' + this.url[1].path, JSON.stringify({ "creation_timestamp": timestamp, "campaign_results": this.campaign }));
            });
        }
      }
      else {
        let timestamp;
        console.log('We do not have a record for FB campaigns. Called API and added record...');
        this.facebookService.getCampaignInsightsByGender(this.url[1].path, this.facebookCredentials.facebook_access_token, this.timeperiod)
          .then(campaign => {
            this.gender_insights = campaign.data;
            console.log(campaign.data)
            this.isReady = true;
            sessionStorage.setItem('FB-campaign-gender:' + this.url[1].path, JSON.stringify({ "creation_timestamp": timestamp, "gender_results": this.gender_insights }));
          },
            error => { console.error(error); }
          );

        this.facebookService.getCampaignInsightsByAge(this.url[1].path, this.facebookCredentials.facebook_access_token, this.timeperiod)
          .then(campaign => {
            this.age_insights = campaign.data;
            this.isReady = true;
            sessionStorage.setItem('FB-campaign-age:' + this.url[1].path, JSON.stringify({ "creation_timestamp": timestamp, "age_results": this.age_insights }));
          },
            error => { console.error(error); }
          );

        this.facebookService.getCampaignInsights(this.url[1].path, this.facebookCredentials.facebook_access_token, this.timeperiod)
          .then(campaign => {
            this.campaign = campaign.data[0];

            this.conversions = this.facebookService.getTotalConversions(this.campaign);
            this.cost_per_conversion = (this.campaign.spend * this.acc.facebook_factor) / this.conversions;
            if (this.conversions > 0) {
              this.cost_per_conversion = (this.campaign.spend * this.acc.facebook_factor) / this.conversions;
            }
            else {
              this.cost_per_conversion = 0;
            }
            this.conversionValueService.sendCampaignDetails({ campaign_id: this.campaign.campaign_id, cost_per_conversion: this.cost_per_conversion, provider: "facebook" });
            this.conversionValueService.getCampaignConvValue()
              .subscribe(
                fb_campaign_document => {
                  if (fb_campaign_document != null) {
                    this.campaign_conversion_value = fb_campaign_document['conversion_value'];
                  }
                  else {
                    this.campaign_conversion_value = -1;
                  }
                });
            this.navigationService.updateState(new NavigationState(true, this.campaign.campaign_name, "Facebook", "/facebook", "/fb-campaign/" + this.url[1].path));
            this.isReady = true;
            sessionStorage.setItem('FB-campaign:' + this.url[1].path, JSON.stringify({ "creation_timestamp": timestamp, "campaign_results": this.campaign }));
          });
      }
    });
  }

  getIndexForGender(field: string, genderObj: any): number {
    let ind = -1;
    if (genderObj && genderObj.actions) {
      ind = genderObj.actions.findIndex(x => x.action_type == field);
    }
    return ind;
  }

  getIndexForAge(field: string, ageObj: any): number {
    let ind = -1;
    if (ageObj && ageObj.actions) {
      ind = ageObj.actions.findIndex(x => x.action_type == field);
    }
    return ind;
  }

  getVideoIndex(param: string, field: string): number {
    let ind = -1;
    if (this.campaign) {
      ind = this.campaign[field].findIndex(x => x.action_type == param);
    }
    return ind;
  }

  getTotalConversionsGender(genderObj: any) {
    if (this.getIndexForGender('offsite_conversion.fb_pixel_custom', genderObj) > -1 && this.getIndexForGender('offsite_conversion.fb_pixel_lead', genderObj) > -1) {
      return (+genderObj.actions[this.getIndexForGender('offsite_conversion.fb_pixel_lead', genderObj)].value) + (+genderObj.actions[this.getIndexForGender('offsite_conversion.fb_pixel_custom', genderObj)].value);
    }
    else if (this.getIndexForGender('offsite_conversion.fb_pixel_lead', genderObj) > -1) {
      return genderObj.actions[this.getIndexForGender('offsite_conversion.fb_pixel_lead', genderObj)].value;
    }
    else if (this.getIndexForGender('offsite_conversion.fb_pixel_custom', genderObj) > -1) {
      return genderObj.actions[this.getIndexForGender('offsite_conversion.fb_pixel_custom', genderObj)].value;
    }
    else {
      return 0;
    }
  }

  getTotalConversionsGenderCost(genderObj: any) {
    if (this.getIndexForGender('offsite_conversion.fb_pixel_custom', genderObj) > -1 && this.getIndexForGender('offsite_conversion.fb_pixel_lead', genderObj) > -1) {
      return ((this.campaign.spend / ((+genderObj.actions[this.getIndexForGender('offsite_conversion.fb_pixel_lead', genderObj)].value) + (+genderObj.actions[this.getIndexForGender('offsite_conversion.fb_pixel_custom', genderObj)].value))) * this.acc.facebook_factor);
    }
    else if (this.getIndexForGender('offsite_conversion.fb_pixel_lead', genderObj) > -1) {
      return ((this.campaign.spend / genderObj.actions[this.getIndexForGender('offsite_conversion.fb_pixel_lead', genderObj)].value) * this.acc.facebook_factor);
    }
    else if (this.getIndexForGender('offsite_conversion.fb_pixel_custom', genderObj) > -1) {
      return ((this.campaign.spend / genderObj.actions[this.getIndexForGender('offsite_conversion.fb_pixel_custom', genderObj)].value) * this.acc.facebook_factor);
    }
    else {
      return 0;
    }
  }

  getTotalConversionsAge(ageObj: any) {
    if (this.getIndexForAge('offsite_conversion.fb_pixel_custom', ageObj) > -1 && this.getIndexForAge('offsite_conversion.fb_pixel_lead', ageObj) > -1) {
      return (+ageObj.actions[this.getIndexForAge('offsite_conversion.fb_pixel_lead', ageObj)].value) + (+ageObj.actions[this.getIndexForAge('offsite_conversion.fb_pixel_custom', ageObj)].value);
    }
    else if (this.getIndexForAge('offsite_conversion.fb_pixel_lead', ageObj) > -1) {
      return ageObj.actions[this.getIndexForAge('offsite_conversion.fb_pixel_lead', ageObj)].value;
    }
    else if (this.getIndexForAge('offsite_conversion.fb_pixel_custom', ageObj) > -1) {
      return ageObj.actions[this.getIndexForAge('offsite_conversion.fb_pixel_custom', ageObj)].value;
    }
    else {
      return 0;
    }
  }

  getTotalConversionsAgeCost(ageObj: any) {
    if (this.getIndexForAge('offsite_conversion.fb_pixel_custom', ageObj) > -1 && this.getIndexForAge('offsite_conversion.fb_pixel_lead', ageObj) > -1) {
      return ((this.campaign.spend / ((+ageObj.actions[this.getIndexForGender('offsite_conversion.fb_pixel_lead', ageObj)].value) + (+ageObj.actions[this.getIndexForAge('offsite_conversion.fb_pixel_custom', ageObj)].value))) * this.acc.facebook_factor);
    }
    else if (this.getIndexForAge('offsite_conversion.fb_pixel_lead', ageObj) > -1) {
      return ((this.campaign.spend / ageObj.actions[this.getIndexForAge('offsite_conversion.fb_pixel_lead', ageObj)].value) * this.acc.facebook_factor);
    }
    else if (this.getIndexForAge('offsite_conversion.fb_pixel_custom', ageObj) > -1) {
      return ((this.campaign.spend / ageObj.actions[this.getIndexForAge('offsite_conversion.fb_pixel_custom', ageObj)].value) * this.acc.facebook_factor);
    }
    else {
      return 0;
    }
  }
}
