import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import { AccountService } from "../_services/account.service";
import * as moment from 'moment';
import { NONE_TYPE } from '@angular/compiler';
import { Subject } from 'rxjs';
import {environment} from "../../environments/environment";
let psl = require('psl');
@Injectable({
  providedIn: 'root'
})
export class LocalRankingService {

  private seoDocRef;
  private isLocalRankProcessing = new Subject<any>();
  processingLocalRank$ = this.isLocalRankProcessing.asObservable();
  localRanksObservable: any;


  constructor(
    private http: HttpClient,
    private accService: AccountService,
  ) {

  }

  async getLocalPackResults(websiteUrl) {
    let url = new URL(websiteUrl);
    let pslResp = psl.get(url.host)
    // let pslResp = psl.get(websiteUrl)
    let domain = pslResp;
    // console.log("in get local pack results :", domain)
    const seoDocSnap = await firebase.firestore().collection('seo')
      .where('url', '==', domain)
      .limit(1)
      .get();
    if (seoDocSnap.empty) {
      return {}
    }
    else {
      let seoRef = seoDocSnap.docs[0].ref
      this.seoDocRef = seoRef;
      this.pushProcessingDocResult();
      this.addListnerForProcessingDoc();
      let localResultsSnap = await seoRef.collection('localRanks')
        .orderBy('created_on', 'desc')
        .where('status', '==', 'completed')
        .get()
      if (localResultsSnap.empty) {
        return {}
      }
      else {
        let localResRef = localResultsSnap.docs[0].ref
        let localPackDetails = localResultsSnap.docs[0].data();
        // let localPackSnap = await localResRef.collection('localPackRankedKeywords').get();
        let targetKwSnap = await localResRef.collection('targetKeywordResults').get();
        let competitorSnap = await localResRef.collection('competitors').get();
        let rankAnalysisSnap = await localResRef.collection('prevRankAnalysis').get();
        // let unresolvedLocalResults = []
        let unresolvedTargetResults = [];
        let unresolvedCompetitors = [];
        let unresolvedRankAnalysis =[];
        rankAnalysisSnap.forEach(doc => {
          let data = doc.data();
          unresolvedRankAnalysis.push(data);
        });
        competitorSnap.forEach(doc => {
          let data = doc.data();
          // data.avgRank = data.rankSum / data.occuranceCount;
          if (data.domain == domain)
            unresolvedCompetitors.unshift(data);
          else
            unresolvedCompetitors.push(data);
        })
        targetKwSnap.forEach(doc => {
          let data = doc.data();
          // console.log(data['localResults'],data['keyword']);
          let domainInfo = data['localResults'].filter(x => x['domain'] == domain)
          // console.log(domainInfo);
          data['rank'] = domainInfo.length > 0 ? domainInfo[0]['rank'] : null
          data['ratings'] = domainInfo.length > 0 ? domainInfo[0]['rating'] : null;
          data['totalReviews'] = domainInfo.length > 0 ? domainInfo[0]['user_ratings_total'] : null;
          // console.log(data);
          unresolvedTargetResults.push(data);
        })
        // let localResults = await Promise.all(unresolvedLocalResults);
        let targetKeywordRes = await Promise.all(unresolvedTargetResults);
        let competitorsAnalysis = await Promise.all(unresolvedCompetitors);
        let rankAnalysis = await Promise.all(unresolvedRankAnalysis);
        // localPackDetails['localResults'] = localResults;
        localPackDetails['targetKeywordsRanks'] = targetKeywordRes;
        localPackDetails['competitorsAnalysis'] = competitorsAnalysis;
        localPackDetails['previousRankAnalysis'] = rankAnalysis
        console.log(rankAnalysis);
        return localPackDetails;
      }
    }
    // return {} ;
  }

  addListnerForProcessingDoc(){
    if(this.seoDocRef){
      this.localRanksObservable =  this.seoDocRef.collection('localRanks').onSnapshot(
      rankSnap => {
        rankSnap.docChanges().forEach(
          change => {
            // console.log(change['type']);
            this.pushProcessingDocResult();
          });
      });
    }
  }

  async pushProcessingDocResult(){
    // console.log("in processing observable", this.seoDocRef);
    if(this.seoDocRef!==undefined)
    {
      let docSnap = await this.seoDocRef.collection('localRanks').orderBy('created_on', 'desc').limit(1).get();
      // console.log(docSnap);
      if(docSnap.empty){
        this.isLocalRankProcessing.next(false);
      }
      else{
        let docData = docSnap.docs[0].data();
        // console.log(docData['status']);
        if(docData['status']=='processing'){
          this.isLocalRankProcessing.next(true);
        }
        else{
          this.isLocalRankProcessing.next(false);
        }
    }
  }
  }


  async getPreviousLocalRankDocs(websiteUrl) {
    let url = new URL(websiteUrl);
    let pslResp = psl.get(url.host)
    let domain = pslResp;
    // console.log("in get local pack results :", domain)
    const seoDocSnap = await firebase.firestore().collection('seo')
      .where('url', '==', domain)
      .limit(1)
      .get();
    if (seoDocSnap.empty) {
      return []
    }
    else {
      let seoRef = seoDocSnap.docs[0].ref
      let localResultsSnap = await seoRef.collection('localRanks')
        .orderBy('created_on', 'desc')
        .get()
      if (localResultsSnap.empty) {
        return []
      }
      else {
        let data = [];
        localResultsSnap.docs.map(doc => {
          let obj = doc.data();
          obj.id = doc.id;
          obj.ref = doc.ref;
          data.push(obj);
        });
        return data;
      }
    }
  }
  async getRankingDataFromDoc(obj,websiteUrl) {
    let docRef = obj.ref;
    let url = new URL(websiteUrl);
    let pslResp = psl.get(url.host)
    // let pslResp = psl.get(websiteUrl)
    let domain = pslResp;
    // let domain = obj.localpackRankingInfo.target;
    // let localPackSnap = await docRef.collection('localPackRankedKeywords').get();
    let targetKwSnap = await docRef.collection('targetKeywordResults').get();
    let competitorSnap = await docRef.collection('competitors').get();
    let rankAnalysisSnap = await docRef.collection('prevRankAnalysis').get();
    // let unresolvedLocalResults = []
    let unresolvedTargetResults = []
    let unresolvedCompetitors = []
    let unresolvedRankAnalysis =[];
    // localPackSnap.forEach(doc => {
    //   let data = doc.data();
    //   unresolvedLocalResults.push(data);
    // })
    competitorSnap.forEach(doc => {
      let data = doc.data();
      data.avgRank = data.rankSum / data.occuranceCount;
      if (data.domain == domain)
        unresolvedCompetitors.unshift(data);
      else
        unresolvedCompetitors.push(data);
    })
    targetKwSnap.forEach(doc => {
      let data = doc.data();
      console.log(data)
      let domainInfo = data['localResults'].filter(x => x['domain'] == domain)
      data['rank'] = domainInfo.length > 0 ? domainInfo[0]['rank'] : null
      data['ratings'] = domainInfo.length > 0 ? domainInfo[0]['rating'] : null;
      data['totalReviews'] = domainInfo.length > 0 ? domainInfo[0]['user_ratings_total'] : null;
      unresolvedTargetResults.push(data);
    });
    rankAnalysisSnap.forEach(doc => {
      let data = doc.data();
      unresolvedRankAnalysis.push(data);
    });
    // let localResults = await Promise.all(unresolvedLocalResults);
    let targetKeywordRes = await Promise.all(unresolvedTargetResults);
    let competitorsAnalysis = await Promise.all(unresolvedCompetitors);
    let rankAnalysis = await Promise.all(unresolvedRankAnalysis);
    // obj['localResults'] = localResults;
    obj['targetKeywordsRanks'] = targetKeywordRes;
    obj['competitorsAnalysis'] = competitorsAnalysis;
    obj['previousRankAnalysis'] = rankAnalysis;
    return obj;
  }

  async updateLocalRankConfigurations(data, task) {
    // console.log(data);
    let accId = sessionStorage.getItem('current_acc_id');
    let accSnap = firebase.firestore().collection('accounts').doc(accId);
    // console.log(accDetails);
    // data['locations'] = ["Pune,Maharashtra,India"]
    // data['countryName'] = 'India'
    if (task != 'none') {
      let obj = {
        accountId: accId,
        accountName: (await accSnap.get()).data().name,
        timezone: data.timezone,
        schedule: data.schedule,
        task: task,
      }
      this.http.post(`${environment.cloudFunctionServerUrl}/localRanksCloudScheduler`, obj).subscribe(resp => {
        console.log(resp);
      });
    }
    let docSnap = await accSnap.collection('settings').where("type", '==', 'localRankConfig').get();
    if (docSnap.empty) {
      data['type'] = 'localRankConfig';
      data['created_on'] = moment().toDate();
      return await accSnap.collection('settings').add(data);
    }
    else {
      // console.log("updating configurations");
      data['updated_on'] = moment().toDate();
      return await docSnap.docs[0].ref.update(data);
    }
  }

  async processLocalRanks() {
    // let accDetails = this.accService.getCurrentAccDetails();
    // console.log(data);
    // console.log(accDetails);
    let accId = sessionStorage.getItem('current_acc_id');
    let data = { accId: accId };
   return this.http.post(`${environment.cloudFunctionServerUrl}/setLocalRankTask`, data).toPromise();
  }

  getCountries() {
    return this.http.get<any>('assets/countries.json')
      .toPromise()
      .then(res => <any[]>res.data)
      .then(data => {
        return data;
      });
  }

  async getAddressDetails() {
    let accId = sessionStorage.getItem('current_acc_id');
    return await firebase.firestore().collection('accounts').doc(accId)
      .collection('settings').where('type', '==', 'localRankConfig').get();
  }

  unsubscribeLocalRanksListner(){
    if(this.localRanksObservable)
      {this.localRanksObservable.unsubscribe;}
  }
}
