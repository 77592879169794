import { Component, OnInit } from '@angular/core';
import { ImportHistoryService } from '../../_services/import-history.service';
import { MatTableDataSource } from '@angular/material/table';
import { Route } from '@angular/router';

@Component({
  selector: 'app-import-history',
  templateUrl: './import-history.component.html',
  styleUrls: ['./import-history.component.scss']
})
export class ImportHistoryComponent implements OnInit {
  import_contact_result:any;
  isReady:boolean=false;
  import_contacts_response=[];
  import_log:any;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['Date','File Name', 'Link', 'Status','Total Contacts','Added','Duplicates','Updated','Error'];

  constructor(
    private importHistoryService:ImportHistoryService,
  ) { }

  ngOnInit() {

    this.isReady = false;
    let account_id = sessionStorage.getItem('current_acc_id');
    // let  account_id='SUSh9iiU8Ty8BNz1uFtp';
    console.log('Current account id is --->',account_id);
    this.importHistoryService.get_response_from_importlogs(account_id)
        .then(
          importcontacts_snapshot => {
            console.log(importcontacts_snapshot);
            importcontacts_snapshot.forEach(
                docsnapshot=>{
                  this.import_log=docsnapshot.data();
                  this.import_log.createdOn=this.import_log.createdOn.toDate();
                  this.import_contacts_response.push(this.import_log);
                  this.dataSource = new MatTableDataSource(this.import_contacts_response);
                }
              );
              this.isReady = true;
              },
          error => {
            console.error(error);
            this.isReady = true;
          }
        );
  }
}




