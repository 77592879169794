import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid'
// import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { SocialMediaCalenderService } from './social-media-calender.service';
@Component({
  selector: 'app-social-media-calender',
  templateUrl: './social-media-calender.component.html',
  styleUrls: ['./social-media-calender.component.scss']
})
export class SocialMediaCalenderComponent implements OnInit {
  @ViewChild('calendar') fullCalendar: FullCalendarComponent;
  @Output() clearFilters = new EventEmitter<any>();
  createEvent: boolean = false;
  calendarApi: Calendar;
  appointmentEvent: any[];
  calendarOptions: CalendarOptions = {
    selectable: true,
    plugins: [dayGridPlugin, interactionPlugin, listPlugin],
    initialView: 'dayGridMonth',
    contentHeight: 600,
    headerToolbar: {
      left: 'Prev,Today,Next',
      center: 'title',
      right: 'DayGridMonth,TimeGridWeek,TimeGridDay,ListWeek'
    },

    customButtons: {
      Prev: {
        text: 'Prev',
        // icon: 'left-single-arrow',
        click: () => {
          this.clearFilters.emit("clear");
          this.fullCalendar.getApi().prev();
        }
      },
      Next: {
        text: 'Next',
        click: () => {
          this.clearFilters.emit("clear");
          this.fullCalendar.getApi().next();
        }
      },
      Today: {
        text: 'Today',
        click: () => {
          this.clearFilters.emit("clear");
          this.fullCalendar.getApi().today();
        }
      },
      DayGridMonth: {
        text: 'Month',
        click: () => {
          this.clearFilters.emit("clear");
          this.fullCalendar.getApi().changeView('dayGridMonth');
        }
      },
      TimeGridWeek: {
        text: 'Week',
        click: () => {
          this.clearFilters.emit("clear");
          this.fullCalendar.getApi().changeView('timeGridWeek');
        }
      },
      TimeGridDay: {
        text: 'Day',
        click: () => {
          this.clearFilters.emit("clear");
          this.fullCalendar.getApi().changeView('timeGridDay');
        }
      },
      ListWeek: {
        text: 'List',
        click: () => {
          this.clearFilters.emit("clear");
          this.fullCalendar.getApi().changeView('listWeek');
        }
      }
    },
    dateClick: this.handleDateClick.bind(this), // bind is important!
    droppable: true,
    editable: true,
  };


  constructor(private socialMediaCalenderService: SocialMediaCalenderService) { }

  ngOnInit(): void {
    // var calendarEl = document.getElementById('calendar');
    // this.calendar = new Calendar(calendarEl, {
    //   plugins: [listPlugin],
    //   initialView: 'listWeek'
    // });
    // this.calendar.render();
    this.getEvents();

  }
  getEvents() {
    this.calendarOptions.events = this.socialMediaCalenderService.getEvents()
  }

  handleDateClick(arg) {
    console.log(arg);
    var ev = window.prompt("Enter Event Title");
    console.log(ev);
    var event = {
      title: ev,
      date: arg.date,
    }
    this.socialMediaCalenderService.addEvent(event);
    this.getEvents();
    console.log(this.calendarOptions.events);
  }
  createPost() {
    this.createEvent = true;
  }

}
