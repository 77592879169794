<!--Website URL code
<div class="container">
  <div class="row justify-content-center">
  <div class="card">
      <div class="card-block">


          <div class="text-center">
              <h3 class="animated fadeIn mb-2">What is your website URL?</h3>

          </div>

          <div class="md-form">
            <input type="textarea" id="form2" class="form-control" placeholder="Enter URL">
        </div>


          <div class="text-center">
              <button class="btn btn-deep-purple">Save</button>
          </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row justify-content-center">
<a class="typeform-share button" href="https://aarti23.typeform.com/to/Jgrs1m?plan_id={{plan_id}}" data-mode="popup" style="display:inline-block;text-decoration:none;background-color:#267DDD;color:white;cursor:pointer;font-family:Helvetica,Arial,sans-serif;font-size:20px;line-height:50px;text-align:center;margin:0;height:50px;padding:0px 33px;border-radius:25px;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-weight:bold;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;" target="_self">Continue</a> <script> (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm_share", b="https://embed.typeform.com/"; if(!gi.call(d,id)){ js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })() </script>
</div>
</div>
-->
<a class="typeform-share button" href="https://harshallimaye.typeform.com/to/IxbRlJ" data-mode="drawer_left" style="display:inline-block;text-decoration:none;background-color:#267DDD;color:white;cursor:pointer;font-family:Helvetica,Arial,sans-serif;font-size:20px;line-height:50px;text-align:center;margin:0;height:50px;padding:0px 33px;border-radius:25px;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-weight:bold;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;" target="_self">Launch me </a> 
