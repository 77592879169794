import { MessageService } from './../_services/message.service';
import { Component, OnInit, OnChanges, Input, SimpleChange, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit, OnChanges {
  @Input() contactlist: any[];
  @Input() hasMoreRecords: boolean;
  // @Input() chatListReady: boolean;
  @Output() getMessagesOfSelctedContact = new EventEmitter<any>();
  @Output() newContacts = new EventEmitter<any>();
  allContacts = [];

  constructor(private messageService: MessageService) { }
  // isReady: boolean = false;

  ngOnInit() {
    this.allContacts = this.contactlist;
    // this.isReady = true;
    // console.log(this.allContacts);
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('contactlist' in changes) {
      this.allContacts = changes.contactlist.currentValue;
      // console.log(this.allContacts);
    }
  }

  sendPhone(last_message) {
    // let obj = { phone: phone, docid: docid, first_name: first_name, last_name: last_name, unread: unread };
    // console.log(phone);
    // this.getMessagesOfSelctedContact.emit(obj);
    console.log(last_message);
    this.getMessagesOfSelctedContact.emit(last_message);
  }

  fetchContact() {
    // console.log("fetch next contacts");
    this.newContacts.emit("");
  }
}
