/*
 * Unused, imported in legacy code
 */

export class Tactics {
  cpm: number;
  cpc: number;
  ctr: number;
  clicks: number;
  tactics: [
    {
      cpm: number;
      cpc: number;
      ctr: number;
      clicks: number;
    },
    {
      cpm: number;
      cpc: number;
      ctr: number;
      clicks: number;
    }
  ];
}
