<!--The whole content below can be removed with the new code.-->
<mat-tab-group>
  <mat-tab label="Tab 1">

    <form class="example-form">

      <mat-form-field class="example-full-width">
        <span matPrefix>+1 &nbsp;</span>
        <input type="tel" matInput placeholder="Telephone">
        <mat-icon matSuffix>mode_edit</mat-icon>
      </mat-form-field>

    </form>

  </mat-tab>
  <mat-tab label="Tab 2">Content 2</mat-tab>
</mat-tab-group>
