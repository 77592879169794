<!-- <form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label [ngStyle]="{'color': color}">{{label}}</mat-label>
    <span *ngIf="isNumber" matPrefix>+91 &nbsp;</span>
    <input matInput placeholder={{placeholder}} (keyup)="onKey($event)">
    <mat-hint *ngIf="isNumber else goEmail" [ngStyle]="{'color': color}">Enter Phone Number<strong></strong> </mat-hint>
    <ng-template  #goEmail><mat-hint [ngStyle]="{'color': color}"><strong>Enter Email Id</strong> </mat-hint></ng-template>
   <mat-hint *ngIf="isValidNumber" style="color: red;">Enter Valid Phone Number<strong></strong> </mat-hint> 
  </mat-form-field>
</form>  -->

<form class="example-form">
  <mat-form-field class="example-full-width">
   
    <span *ngIf="isNumber" matPrefix>+91 &nbsp;</span>
    
    <!-- <mat-label>{{lebel}}</mat-label> -->

    <input [(ngModel)]="renderInput" name="inputvalue" [placeholder]="lebel" matInput [formControl]="emailFormControl"  [maxLength]="maxlength"  (keyup)="onKey($event)">


    <mat-error *ngIf="emailFormControl.hasError('email') && isEmail">
      Please enter a valid email address
    </mat-error>

    <mat-error *ngIf="emailFormControl.errors && isNumber">
      Please enter a valid phone Number
    </mat-error>


  </mat-form-field>
</form>
