import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-message-presenter',
  templateUrl: './message-presenter.component.html',
  styleUrls: ['./message-presenter.component.scss']
})
export class MessagePresenterComponent implements OnInit {
  @Input() payload: any;
  messages: any;
  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.messages = this.payload.data;
    console.log(this.messages);
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
