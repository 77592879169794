<div *ngIf="loading; else loaded">
    <div style="height: fit-content;width:fit-content;margin: auto;">
        <mat-spinner></mat-spinner>
    </div>
</div>
<ng-template #loaded>
    <div *ngIf="allDocs?.length >0 ; else noResults">
        <p-table [value]="allDocs" currentPageReportTemplate="{first} - {last} of {totalRecords}" rowExpandMode="single" #dt
        [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50]">
            <ng-template pTemplate="header">
                <tr>
                    <th style ="width: 8rem;">Date</th>
                    <th style ="width: 12rem;">Website</th>
                    <th style ="width: 6rem;">Country</th>
                    <th style ="width: 5rem;">Status</th>
                    <th style ="width: 5rem;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex>
                <tr>
                    <td>{{rowData.created_on?(rowData.created_on.toDate()| date: 'MMM d, yyyy hh:mm a'):''}}</td>
                    <td id="websiteUrl">{{rowData.websiteUrl}}</td>
                    <td>{{rowData.country.name |titlecase}}</td>
                    <td>{{rowData.status |titlecase}}</td>
                    <td class="text-center">
                        <button mat-raised-button color="primary" *ngIf="rowData?.status =='completed' || rowData?.status =='no-data'"
                            [ngStyle]="{'margin':'auto','white-space': 'nowrap'}" (click)="showRanking(rowData)">View Report
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #noResults style="height: 100%;width: 100%;">
        <div id="noHistory">You don't have any history.</div>
    </ng-template>
</ng-template>
