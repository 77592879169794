import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { NavigationService } from '../_services';
import { SignupService } from "../_services/signup.service";
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { NavigationState } from '../_models';
interface countryCode {
  name: string;
  code: any;
}
@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OTPVerificationComponent implements OnInit, OnChanges {
  codes: countryCode[];
  selectedCode: countryCode;
  twilio_from: string;
  account_sid: string;
  auth_token: string;
  twilio_to: any = [];
  messageObj: any;
  displayMsg: string;
  randomOTP: any;
  service_sid: any;
  contact_ids: any = [];
  otp: any;
  phone_no: any;
  full_phone: any;
  firstScreen: boolean = true;
  invalidOtp: boolean = false;
  dialogData: any = {
    show: false,
    header: '',
    value: ''
  }
  twilioCreds:any;

  @Input() value: string;
  @Input() screenName: string;
  @Output() otpVerificationStatus = new EventEmitter<any>();

  constructor(
    private signupService: SignupService,
    private navigationService: NavigationService,
  ) {

    this.codes = [
      { name: '+1(United States)', code: '+1' },
      { name: '+91(india)', code: '+91' },
      { name: '+44(United Kingdom)', code: '+44' },
    ];

  }

  phoneValidation = new FormControl('', Validators.required);
  otpValidation = new FormControl('', Validators.required);
  countryCodeValidation = new FormControl('', Validators.required);

  @ViewChild('ngOtpInput') ngOtpInputRef: any;



  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '30px',
      'height': '30px'
    }
  };

  ngOnChanges() {
    this.firstScreen = false;
  }

  ngOnInit() {
    this.signupService.getTwilioCredentials().then(resp=>{
      this.twilioCreds = resp;
      this.sendOtp();
    });
  }


  sendOtp() {
    this.randomOTP = this.signupService.generateOtp();
    if (this.screenName == 'email') {
      this.signupService.sendEmailVerificationOtp(this.value, this.randomOTP);
    }
    else {
      this.full_phone=this.value;
      this.twilio_to.push(this.full_phone);
      this.messageObj = {
        from: this.twilioCreds.from,
        to: this.twilio_to,
        acc_sid: this.twilioCreds.account_sid,
        auth_token: this.twilioCreds.auth_token,
        service_sid: this.twilioCreds.notify_service_sid,
        msg: this.randomOTP + ' is One time password (OTP) for your Mktg.doctor account. Please do not share this. If not requested, please inform us.',
        contact_ids: [],
      };
      this.signupService.sendSms(this.messageObj);
      this.firstScreen = false;
    }
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  verifyOTP() {
    if (this.otp == this.randomOTP) {
      this.otpVerificationStatus.emit({ action: 'otpVerification', verified: true });
    }
    else {
      this.otp = "";
      this.otpVerificationStatus.emit({ action: 'otpVerification', verified: false });
      this.dialogData = {
        show: true,
        header: 'Invalid OTP',
        value: 'invalidOtp'
      }
    }
  }

  resendOtp() {
    this.otp = '';
    this.ngOtpInputRef.setValue(this.otp);
    this.sendOtp();
    this.invalidOtp = true;
    this.dialogData = {
      show: true,
      header: 'Resend OTP',
      value: ''
    }
  }
  changeEmail() {
    this.otp = "";
    this.ngOtpInputRef.setValue(this.otp);
    this.otpVerificationStatus.emit({ action: 'changeEmail', verified: false });
  }

}
