<mat-card class="info-cards">
  <mat-card-title class="card-title">Business Lifecycle</mat-card-title>
  <div class="row p-2">
    <div class="col">
      <mat-radio-group class="example-radio-group" [(ngModel)]="lifecycle">
        <mat-radio-button class="example-radio-button col-sm-6" *ngFor="let choice of stages" (click)="getObjective(choice)" [value]="choice">
          {{choice}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="row p-2">
    <div class="col objective">
      <h5 class="card-title">Recommended Marketing Objectives</h5>
      <h6 *ngIf="lifecycle">{{ lifecycle }}: {{ objective }}</h6>
    </div>
  </div>
  <mat-card-actions align="center">
    <button mat-raised-button (click)="next()" id="setLifecycleButton" color="accent">Set This</button>
  </mat-card-actions>
</mat-card>

<!-- Button toggle which doesn't work at the moment
<mat-button-toggle-group>
  <mat-button-toggle name="lifecycle" value="Startup" [(ngModel)]="lifecycle">
    Startup
  </mat-button-toggle>
  <mat-button-toggle name="lifecycle" value="Growth" [(ngModel)]="lifecycle">
    Growth
  </mat-button-toggle>
  <mat-button-toggle name="lifecycle" value="Matured" [(ngModel)]="lifecycle">
    Matured
  </mat-button-toggle>
  <mat-button-toggle name="lifecycle" value="Decline" [(ngModel)]="lifecycle">
    Decline
  </mat-button-toggle>
</mat-button-toggle-group>
<p>{{ lifecycle }}</p>
</div>
-->
