import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import { LocalRankingService } from "../../_services/local-ranking.service";
import { AccountService } from "../../_services/account.service";

@Component({
  selector: 'app-local-ranks',
  templateUrl: './local-ranks.component.html',
  styleUrls: ['./local-ranks.component.scss']
})
export class LocalRanksComponent implements OnInit, OnChanges {
  accDetails: any;
  cols = [
    { 'name': 'Keyword', 'key': 'keyword' },
    { 'name': 'Rank', 'key': 'rank' },
    { 'name': 'Ratings', 'key': 'ratings' },
    { 'name': 'Search volume', 'key': "search_volume['search_volume_total']" },
    { 'name': 'score', 'key': 'score' }
  ]
  @Input() targetkwResults: any;
  @Input() gotData: boolean;
  loading = true;
  constructor(
    private localRankService: LocalRankingService,
    private accService: AccountService,

  ) { }

  ngOnInit(): void {
    this.accDetails = this.accService.getCurrentAccDetails();
    // console.log(this.targetkwResults);

  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    // console.log(this.targetkwResults);
    if ('gotData' in changes) {
      this.loading = changes.gotData.currentValue;
    }
  }

  openLink(url) {
    // console.l  og(url);
    window.open(url);
  }
}
