<!-- ---------sub header starts-------- -->
<div class="row text-center" id="subHeader1">

  <div class="col-md-3" style="margin: auto;">
    <div class="searchContainer_new">
      <i class="fa fa-search searchIcon"></i>
      <input class="searchBox" type="text" name="search" placeholder="Search Contacts"
        (input)="dt.filterGlobal($event.target.value, 'contains')">
    </div>
  </div>

  <div class="col-md-4" style="margin: auto;">
    <div id="pageTitle" class="center">Contacts</div>
  </div>

  <div class="col-md-5">
    <button mat-mini-fab id="button" class="format" (click)="openDialogForContact()" matTooltip="Add Contact">
      <i class="material-icons add">add</i>
    </button>

    <button mat-mini-fab id="button" class="format" (click)="showImportSection()" matTooltip="Import Contacts">
      <i class="material-icons">save_alt</i>
    </button>

    <button mat-mini-fab id="button" class="format" *ngIf="showImportHistory" (click)="toggleImportHistory()"
      matTooltip="Contacts">
      <i class="material-icons">person</i>
    </button>

    <button mat-mini-fab id="button" class="format" [routerLink]="['/import-history']" (click)="toggleImportHistory()"
      matTooltip="Import History">
      <i class="material-icons">history</i>
    </button>

    <button mat-mini-fab id="button" class="format" (click)="dt.exportCSV()" matTooltip="Export CSV-File">
      <i class="fas fa-file-csv"></i>
    </button>

    <!-- <button mat-mini-fab id="button"  class="format" (click)="exportExcel(dt)" matTooltip="Export Excel-File">
        <i class="far fa-file-excel"></i>
      </button> -->

    <button *ngIf="selectedContacts.length > 1 && twilio_from !== undefined" mat-mini-fab id="button" class="format"
      (click)="bulkMessage()" matTooltip="Send Message To Multiple Contacts">
      <i class="material-icons">message</i>
    </button>

    <button *ngIf="SurveyFormstatus" mat-mini-fab id="button" class="format" (click)="openDialogForSurveyForm()"
      matTooltip="Send Survey Form">
      <span class="material-icons">
        fact_check
      </span>
    </button>

    <button *ngIf="showSendPatientForm" mat-mini-fab id="button" class="format" (click)="openDialogToSendPatientForm()"
      matTooltip="Send Patient Form">
      <i class="material-icons">article</i>
    </button>

    <button *ngIf="showMerged" mat-mini-fab id="button" class="format" (click)="openDialogForMergeTheContact()"
      matTooltip="Merge Contact">
      <span class="material-icons">
        merge_type
      </span>
    </button>
  </div>
</div>

<!-- ---------sub header2 starts-------- -->

<div class="row" id="subHeader2">
  <div class="col-md-12">
    <mat-slide-toggle class="formatToggle" (change)="showEnableContactsOnly()" name="enableContacts"
      [(ngModel)]="checked">
      {{ checked ? "All contacts" : "Only active contacts" }}
    </mat-slide-toggle>
  </div>
</div>

<div class="row contact-import" *ngIf="toImport">
  <div class="col-12 sub-section">
    <strong>Import Contacts</strong>
    <i class="material-icons icon-btn closed-icon" (click)="hideImportSection()">close</i>
  </div>
  <div class="col-12 sub-section">
    <input type="file" name="fileInput" id="fileInput" class="form-control inputfile" (change)="checkFileSelection()"
      #input />
    <label for="fileInput">Choose a file
      <i class="material-icons icon-btn choose-file">insert_drive_file</i></label>
    <span class="import-file-name">{{ importFileName }}</span>
    <i class="material-icons icon-btn clear-file" matTooltip="Clear File" (click)="resetFile()">delete</i>
  </div>

  <div class="col-12 sub-section">
    <button mat-raised-button (click)="parseFile()" [disabled]="!importFileSelected">
      Import
    </button>
  </div>

  <div class="col-12 sub-section" *ngIf="displayProgress && !importComplete">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <br />
  <div class="col-12 sub-section" *ngIf="importComplete">
    {{ importContactResult }}
  </div>
</div>

<!-- --------------table for mobile view------------- -->
<!-- <div *ngIf="mobiletableView" class="mobile-view">
  <app-responsive-div [tableData]="tableData" [dt]="dt"></app-responsive-div>
</div> -->

<!-- --------------table for desktop view------------- -->

<div id="content">
  <div class="mat-elevation-z8" id="pTable">
    <p-table [value]="contacts" [columns]="columns" #dt [globalFilterFields]="filterColumns" selectionMode="multiple"
      [(selection)]="selectedContacts" [paginator]="true" [rows]="rows" [(first)]="first" [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} - {last} of {totalRecords}" [rowsPerPageOptions]="[10,20,50]"
      [resizableColumns]="true" [autoLayout]="true" sortMode="multiple">

      <ng-template pTemplate="header">
        <tr>
          <th>
            <p-tableHeaderCheckbox (click)="selectRow()"></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-contact let-index>
        <tr [ngStyle]="contact.active==false &&{'background-color':'#E7E7E7'}">
          <td class="mid">
            <p-tableCheckbox style="font-weight: 100;" [disabled]="contact.active==false" [value]="contact"
              [index]="index" (click)="selectRow()" #e>
            </p-tableCheckbox>&nbsp;
            <a [routerLink]="['/contacts',contact.ref.id]"><i style="padding: 0px 0.5px; font-weight: 100;"
                class="pi pi-chevron-right"></i></a>
          </td>
          <td class="mid">
            <input class="myinput" hover="contact.first_name" matInput placeholder="Enter" [value]="contact.first_name"
              (change)="updateValue($event.target.value, 'first_name', contact)" [disabled]="contact.active==false">
          </td>
          <td class="mid">
            <input class="myinput" matInput placeholder="Enter" [value]="contact.last_name"
              (change)="updateValue($event.target.value, 'last_name', contact)" [disabled]="contact.active==false">
          </td>
          <td class="email">
            <input #tooltip="matTooltip" matTooltipPosition="above" [matTooltip]="contact.email" class="myinput"
              matInput placeholder="Enter" [value]="contact.email"
              (change)="updateValue($event.target.value?.toLowerCase(), 'email', contact)"
              [disabled]="contact.active==false">
          </td>
          <td class="mid">
            <input class="myinput" matInput placeholder="Enter" [value]="contact.phone"
              (change)="updateValue($event.target.value, 'phone', contact)" [disabled]="contact.active==false">
          </td>
          <td class="mid">
            <input class="myinput" matInput placeholder="MM-DD-YYYY" [value]="contact.birthday| date:'MM/dd/yyyy'"
              #birthDateRef (change)="updateBirthDate(contact.ref,$event.target.value,contact,birthDateRef)"
              [disabled]="contact.active==false" title="Enter a date in this format MM/DD/YYYY">
          </td>
          <td class="mid">
            <input class="myinput" matInput [matDatepicker]="picker" [(ngModel)]="contact.last_visited" [max]="maxDate"
              placeholder="Choose a date" (dateChange)="updateLastVisited(contact.ref, contact.last_visited)"
              [disabled]="contact.active==false" (click)="_openCalendar(picker)" #elementToFocus>
            <mat-datepicker #picker disabled="false" [opened]="false"
              (closed)="_closeCalendar($event, contact.ref, contact.last_visited)"
              [disabled]="contact['active'] === false">
            </mat-datepicker>
          </td>
          <td class="mid">
            <input class="myinput" matInput placeholder="Enter" [value]="contact.created_on|date:'MMM d, yyyy'"
              [disabled]="contact.active==false" readonly>
          </td>
        </tr>
      </ng-template>

    </p-table>
    <div *ngIf="showImportHistory" style="margin-top: 20px;">
      <app-import-history></app-import-history>
    </div>
  </div>
</div>

<div *ngIf="surveyEnable">
  <app-survey-form></app-survey-form>
</div>