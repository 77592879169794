import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-nudge-message',
  templateUrl: './nudge-message.component.html',
  styleUrls: ['./nudge-message.component.scss']
})
export class NudgeMessageComponent implements OnInit {
  @Input() data:any;

  constructor() { }

  ngOnInit() {
  }
  getDays(appt_start){
    let now = moment(new Date());
    return Math.floor(moment.duration(now.diff(appt_start)).asDays());
  }
}
