<!--Mask-->
<div class="view">
  <div class="container-fluid">
    <div class="row" id="home">
      <div id="my-calendar" class="col-lg-6">
        <h1 class="h1-responsive wow fadeInLeft" data-wow-delay="0.1s">
           Meet “mktgbot”.
        </h1>
        <h2 class="h2-responsive wow fadeInLeft" data-wow-delay="0.3s">
        Personal digital marketing assistant for businesses just like yours.
        </h2>
        <br>
        <h4 class="h4-responsive">BOOK AN APPOINTMENT WITH ME</h4>
        <br>
        <!-- Calendar -->
        <iframe width="100%" height="420px" frameborder="0" src="https://interactive-appointments.herokuapp.com/calendar/harshal-limaye/harshal-calendar/"></iframe>
        <!-- <a class="btn btn-primary btn-lg wow fadeInLeft" data-wow-delay="0.7s" (click)="createUser()">Get Started</a> -->
      </div>
      <div class="col-lg-6">
        <p class="wow fadeInRight" data-wow-delay="0.75s">
          Powered with AI and an easy to use interface, mktgbot helps you activate and manage cross channel digital marketing campaigns and maximize ROI.
          <br/> Just like a good financial advisor working hard for better returns on your investments, mktgbot understands the challenges and opportunities for your business giving you the best returns on your marketing investment.
        </p>
        <hr class="hr-dark wow fadeInLeft">
        <h1 class="h1-responsive"> YOUR PERSONAL MARKETING ASSISTANT </h1>
        <div class="row">
          <div class="row scrolling-wrapper-flexbox">
  <!--            <div class="col">
              <img height="400px" src="../../assets/images/mktgbot-mac-ss.svg">
            </div>-->
            <div class="col">
              <img height="400px" src="../../assets/images/mobile-ss-1.svg">
            </div>
            <div class="col">
              <img height="400px" src="../../assets/images/mobile-ss-3.svg">
            </div>
            <div class="col">
              <img height="400px" src="../../assets/images/mobile-ss-4.svg">
            </div>
            <div class="col">
              <img height="400px" src="../../assets/images/mobile-ss-2.svg">
            </div>
          </div>
          <em class="format-center"> Swipe or scroll for more ... </em>
        </div>
    </div>
  </div>
    <!--<div class="row">
          <div class="col-lg-6">
            <div class="embed-responsive embed-responsive-16by9 wow fadeInRight">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/0dxTwOhkqqk" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-lg-6">
          </div>
        </div>-->
        <hr class="hr-dark">

        <h2 class="format-center"> Data powers digital marketing. </h2>
        <br />
        <h5 class="format-center">How do we use data to find you customers?</h5>

        <div class="row">
          <div class="col-sm col-md-4">
            <p> Facebook and Google are very sophisticated platforms with advanced targeting capabilities. How are you using these platforms to your advantage? mktgbot's algorithms discover the pockets and opportunities that leverage the power of these platforms
              to best benefit your business.</p>
          </div>
          <div class="col-sm col-md-4">
            <p> mktgbot leverages public domain data, for example images from social media, or posts and tweets, and provides refined targting capabilities to reach the ideal customers of your business.</p>
          </div>
          <div class="col-sm col-md-4">
            <p> mktgbot analyzes your data, such as, web analytics, social analytics and data generated from your sales and marketing systems, to further optimize your marketing spend and maximize return on investment.</p>
          </div>
        </div>

    <hr class="hr-dark">
    <h3 class="format-center"> Get the results you've never seen before </h3>
    <center><p> Think of "mktgbot" as a personal assistant or an advisor for all the digital marketing needs of your business.</p></center>
    <br />
    <div class="row">
      <div class="col-md-6 col-xs-6 col-lg-6">
        <mat-card class="metric-card">
          <mat-card-title>
            <span class="format-center"><mat-icon class="my-icons">looks_one</mat-icon></span>
          </mat-card-title>
          <mat-card-subtitle>
            <h4 class="format-center"> Complete Visibility  </h4>
          </mat-card-subtitle>
          <mat-card-content>
            <h4 class="format-center">Do you ever ask yourself, where are your marketing $ spent?</h4>
            <p>See the total marketing spends and performance of your Facebook, Google and email marketing campaigns, in real-time, on mktgbot dashboard</p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-6 col-xs-6 col-lg-6">
        <mat-card class="metric-card">
          <mat-card-title>
            <span class="format-center"><mat-icon class="my-icons">looks_two</mat-icon></span>
          </mat-card-title>
          <mat-card-subtitle>
            <h4 class="format-center"> Take Control  </h4>
          </mat-card-subtitle>
          <mat-card-content>
            <h4 class="format-center"> Being in control does not mean you're on your own.  </h4>
            <p>The mktgbot AI analyzes your campaigns and competitors to automatically generate optimization tips and new campaign ideas, personalized for your business. You can Accept, Reject or Snooze every tip with a click of a button.</p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-6 col-xs-6 col-lg-6">
        <mat-card class="metric-card">
          <mat-card-title>
            <span class="format-center"><mat-icon class="my-icons">looks_3</mat-icon></span>
          </mat-card-title>
          <mat-card-subtitle>
            <h4 class="format-center"> Return On Investment </h4>
          </mat-card-subtitle>
          <mat-card-content>
            <h4 class="format-center"> Is digital marketing really working for you? How well? </h4>
            <p> mktgbot automatically links leads generated and revenue booked to your marketing campaigns to show you the true return-on-investment on your marketing spend.</p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-6 col-xs-6 col-lg-6">
        <mat-card class="metric-card">
          <mat-card-title>
            <span class="format-center"><mat-icon class="my-icons">looks_4</mat-icon></span>
          </mat-card-title>
          <mat-card-subtitle>
            <h4 class="format-center"> Performance </h4>
          </mat-card-subtitle>
          <mat-card-content>
            <h4 class="format-center"> Why mktgbot outperforms? </h4>
            <p> While most agencies will optimize your campaigns once a month, mktgbot AI machines optimize your campaigns continuously. This results in fewer to none lost opportunities and an exponentially improved performance.</p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <br />
    <br />
    <div class="row">
      <div class="col-12">
        <center><p> Digital marketing is important for the sustained growth of your business. That is the reason we created "mktgbot". An AI powered digital marketing platform just for your business, where we'd like to think of AI as art + intelligence.</p></center>

        <h4 class="format-center">Digital marketing is complicated, period.</h4>
        <br />
        <h3 class="format-center"> Simplify your online advertising with mktgbot.</h3>
        <br />
        <!--
        <span class="format-center"><button mat-raised-button color="primary" class="large-button" (click)="scrollToAppointment()"> BOOK APPOINTMENT</button></span>
        -->
      </div>
    </div>
    <hr class="hr-dark">

    <h2 class="format-center"> Book Appointments Online </h2>
    <br />
    <h5 class="format-center">Add a beautiful appointment calendar to your website in minutes and customize it to your practice.</h5><br>
    <div class="row">
      <div class="col-sm col-md-6">
        <center><img height="250px" src="../../assets/images/calendar-screens.PNG"></center>
      </div>
      <div class="col-sm col-md-6">
        <ul class="demo-list">
          <li>Pick the days of the week you are open.</li>
          <li>Holidays your office is closed.</li>
          <li>Color and theme to match your branding.</li>
          <li>Same day appointments, or pick your own lead times.</li>
          <li>Customizable time slots.</li>
        </ul>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm col-md-6">
        <h5 class="format-center">Email is sent to you with details about the patient </h5>
        <center><img height="200px" src="../../assets/images/owner-email-screen.PNG"></center><br>
        <ul class="demo-list">
          <li>Name, email and phone number of the patient.</li>
          <li>Appointment date and time.</li>
          <li>Source from where the appointment was booked for example your website, or from your Facebook ad campaign.</li>
        </ul>
      </div>
      <div class="col-sm col-md-6">
        <h5 class="format-center">Appointment email confirmation is sent to the patient </h5>
        <center><img height="200px" src="../../assets/images/user-email-screen.PNG"></center><br>
        <ul class="demo-list">
          <li>Appointment Date and Time</li>
          <li>Your clinic address and directions</li>
          <li>Color and theme to match your branding.</li>
          <li>Your clinic contact information..</li>
        </ul>
      </div>
    </div>
  </div>
</div>
