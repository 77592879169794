<!-- Basic Business info card-->
<mat-card class="info-cards">
  <mat-card-title> Let's build your marketing plan </mat-card-title>
  <mat-card-subtitle> Answer a few questions about your product or service to help mktgbt build your digital marketing plan </mat-card-subtitle>
  <mat-card-content>
    <form id="urlForm" (ngSubmit)="urlForm.valid" [formGroup]="urlForm" novalidate>
    <!--  <div class="row justify-content-left question">-->
    <div class="form-group">
      <mat-form-field class="example-full-width py-2">
          <input matInput placeholder="Your website URL" formControlName="url"  max="30"  pattern="[a-z.://_%+-]+[a-z0-9.-]+\.[a-z]{1,2}.+$" required>

          <mat-hint align="end">If you need a website, click here.
          </mat-hint>
          <mat-error *ngIf="urlForm.get('url').hasError('pattern')">
            Hmm, url doesn't look right
          </mat-error>


      </mat-form-field>
      <mat-form-field class="example-full-width py-2">
        <input matInput placeholder="Your product or service" formControlName="product"  max="30"  pattern=".+?(?:[\s'].+?){0,}" required>
        <mat-hint align="end">Describe your product in two words.
        </mat-hint>
        <mat-error *ngIf="urlForm.get('product').hasError('pattern')">
           Please ,Describe your product or service in one word or more
        </mat-error>

      </mat-form-field>

      <mat-select class="example-full-width py-2" placeholder="Select product category" id="category" name="category" formControlName="category">
        <mat-option *ngFor="let category of categories" [value]="category">{{category}}
        </mat-option>
        <mat-error *ngIf="urlForm.get('category').invalid && (urlForm.get('category').dirty || urlForm.get('category').touched)">
          Must select product category.
        </mat-error>
      </mat-select>


      <mat-card-actions align="">
        <button mat-raised-button (click)="next()" [disabled]="urlForm.invalid" id="getCompetitorButton" color="accent">OK</button>
      </mat-card-actions>
    </div>
    </form>
  </mat-card-content>
</mat-card>
<!-- Basic Business info card -->
