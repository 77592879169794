<div class="contanier-fluid view">

<div *ngIf="!showForm">
  <h4>New contact</h4>
  <div class="close" (click)="close()">X</div>
  <!--<p>Please enter the correct details to create a new contact</p>-->

  <!--<div class="row">
    <div class="col-md-6">
      <h4>Contact Form</h4>
      </div>
  </div>
  <p>Please enter the correct details to create a new contact</p>-->
  <form #contactData="ngForm" (ngSubmit)="saveContact(contactData)" name='contactForm'>
  <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <input matInput name="first_name" [(ngModel)]="first_name" placeholder="First Name" required />
            </mat-form-field>
          </div>
  </div>
  <div class="row ">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <input matInput name="last_name" [(ngModel)]="last_name" placeholder="Last Name" required />
            </mat-form-field>
          </div>
  </div>
  <div class="row">

    <div class="col-xs-4 col-md-2">
      <mat-form-field>
        <mat-select name="countryCode" placeholder="Country code" [(ngModel)]="countryCode">
            <mat-option *ngFor="let country of countryCodes" [value]="country.code">
              {{ country.code }} ({{country.name}})
            </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-xs-8 col-md-10">
    <!--<div class="col-md-6">-->
      <mat-form-field class="example-full-width">
        <input matInput id="phone_no" #phone_no="ngModel" name="phone_no" [(ngModel)]="phone" pattern="[0-9]{10}" placeholder="Mobile Number" required />
        </mat-form-field>
            <div *ngIf="phone_no.invalid && phone_no.errors.pattern"
                 class="alert-danger">
              Please enter a valid mobile number.
            </div>
        </div>
  </div>
  <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <input matInput name="email" [(ngModel)]="email_id" placeholder="Email" pattern="" />
            </mat-form-field>
          <!--  <div *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.pattern"
                 class="alert alert-danger">
              Email format should be
              <small><b>joe@abc.com</b></small>
            </div>-->
          </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <mat-slide-toggle name= "sendTxtReminders" [(ngModel)]="sendTxtReminders">{{sendTxtReminders == true ?'Send SMS/text messages' : 'Do not send SMS/text messages'}}</mat-slide-toggle>
    </div>
  </div>
  <small><em>*All fields marked * are required.</em></small>
  <div class="row">
        <div class="col-md-12 text-center">
          <button mat-raised-button color="primary" type="submit" [disabled]="!contactData.valid">Save contact</button>
        </div>
  </div>

  </form>
</div>
<div *ngIf="showForm">
  <p class="msg">{{msg}}</p>
  <div class="close" (click)="close()">X</div>
</div>
</div>
