<div class="row text-center" id="subHeader1">
  <!-- <div class="col-md-3" style="margin: auto;">
      <div *ngIf="showList searchContainer_new" class="input-icons" style="width: 100%;">
          <i  class="material-icons icon">search</i>
          <input placeholder="  Search marketing offers" class="input-field" type="text">
       </div>
    </div> -->
  <div class="col-md-3" style="margin: auto;">
    <div class="searchContainer">
      <i class="fa fa-search searchIcon"></i>
      <input class="searchBox" type="text" name="search" placeholder="Search offer" (keyup)="searchForm($event)">
    </div>
  </div>

  <div class="col-md-6" style="margin: auto;">
    <!-- <span style="text-transform: uppercase;text-align: center;padding:5px;"> -->
    <div id="pageTitle" class="center">Marketing Offers</div>
    <!-- </span> -->
  </div>

  <div class="col-md-3" style="margin: auto;">
    <button id="button" *ngIf="showList" mat-mini-fab class="format" (click)="openCreateModel()"
      matTooltip="Add Marketing Offer">
      <i class="material-icons add">add</i>
    </button>

    <button id="button" *ngIf="!showList" mat-mini-fab class="format" (click)="closeCreateModel()"
      matTooltip="Close Marketing Offer">
      <i class="material-icons add">close</i>
    </button>
  </div>

</div>

<div *ngIf="!showList">
  <app-create-marketting-offer [offerDetails]="offerDetail" (offerSaved)="messageFromCreateOffer($event)"
    [update]="emitUpdateOffer"></app-create-marketting-offer>
</div>
<div *ngIf="showList">
  <app-marketing-offer-lists [currentOfferData]="currentOfferDataFiltered" [pastOfferData]="pastOfferDataFiltered"
    [upcommingOfferData]="upcommingOfferDataFiltered" [showOffers]="showOffers" (editOffer)="editSelectedOffer($event)">
  </app-marketing-offer-lists>
</div>