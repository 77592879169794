<div class="container-fluid" *ngIf="forms.length != 0 else noform">
  <interactive-app-navigation [showIcons]="showIcons" [state]="state" style="display: none;">
  </interactive-app-navigation>
  <!-- <div class="gear-icon">
    <i class="material-icons" (click)="openDialog()">settings</i>
  </div> -->
  <div id="card-container">
    <!-- <div class=" plan" *ngIf="state.url != '/patient-intake-forms'">
      <mat-card>
        <p align="center"> Create a form</p>
        <p align="center">
          <button class="m-3" mat-fab (click)="createForm()"><i class="material-icons add">add</i>
          </button>
        </p>
      </mat-card>
    </div> -->
    <div *ngFor="let form of forms" class="card-block plan">
      <div class="col-md-3">
        <mat-card>
          <img class="image" *ngIf="!showIcons && form.data?.welcome_screen.image !== ''" mat-card-image
            src={{form.data?.welcome_screen.image}}>
          <a [routerLink]="['/form-design',state.url,form.id]" *ngIf="showIcons">
            <img class="image" *ngIf="form.data?.welcome_screen.image !== ''" mat-card-image
              src={{form.data?.welcome_screen.image}}>
          </a>
          <mat-card-actions *ngIf="showIcons">
            <a [routerLink]="['/form-design',state.url,form.id]">
              <h4 class="card-title" id="card_icon">{{form.data.name}}</h4>
            </a>
            <!-- <a [routerLink]="['/interactive-form',acc.account_slug,form.slug]">
            <i class="material-icons">remove_red_eye</i>
          </a> -->
            <a>
              <i class="material-icons" id="card_icon"
                (click)="openDialogForCopy(form.data,acc.account_slug,form.slug,form.data.name)"
                matTooltip="Copy Form">content_copy</i>
            </a>
            <a>
              <i class="material-icons" id="card_icon"
                (click)="openDialogForPreview(acc.account_slug,form.data.slug,form.data.name)"
                matTooltip="Preview Form">remove_red_eye</i>
            </a>
            <a [routerLink]="['/form-design',state.url,form.id]">
              <i class="material-icons" id="card_icon" matTooltip="Edit Form">edit</i>
            </a>
            <!-- <a><i class="material-icons" (click)="deleteForm(form.id)">delete_forever</i></a> -->
            <a><i class="material-icons" id="card_icon" (click)="openDialogForDelete(form.id)"
                matTooltip="Delete Form">delete_forever</i></a>
            <a *ngIf="state.url == '/enquiry-forms'">
              <span id="card_icon" class="material-icons" style="font-weight: 800;"
                (click)="openDialogToEmbedCode(acc.account_slug,form.data.slug)" matTooltip="Embed Form">code</span>
            </a>
          </mat-card-actions>

          <mat-card-actions *ngIf="!showIcons">
            <a [routerLink]="['/form-design',state.url,form.id]">
              <h4 class="card-title" id="card_icon">{{form.data.name}}</h4>
            </a>
            <a>
              <i class="material-icons" id="card_icon"
                (click)="openDialogForPreview(acc.account_slug,form.slug,form.data.name)"
                matTooltip="Preview Form">remove_red_eye</i>
            </a>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<ng-template #noform>
    <center>No Forms</center>
</ng-template>