import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { ChatBotService } from '../../_services/chat-bot.service';
@Component({
  selector: 'app-chatbot-preview',
  templateUrl: './chatbot-preview.component.html',
  styleUrls: ['./chatbot-preview.component.scss']
})
export class ChatbotPreviewComponent implements OnInit {
  @Input() colors: any;
  general: any;
  theme: any;
  showMenuBox: boolean = false;
  mode: string;
  formValue: string = '';
  messages: any = [];
  active: number;
  typing: any = {
    displayType: 'TYPING',
    sentBy: "BOT",
    data: 'Typing...'
  }
  reply: any = {
    displayType: 'TEXT',
    sentBy: "BOT",
    data: 'Hi, this is a dummy response...'
  }
  welcomeButtons = [
    {
      name: 'Button1',
      showType: 'BOTH',
      type: 'Firebase'
    },
    {
      name: 'Button2',
      showType: 'BOTH',
      type: 'Firebase'
    }
  ];
  buttonName: string;
  showWelcomeButtons: any;
  showMenu: any;
  menuMessage: any;
  welcomeMessage: any;
  header: any;

  menu = {
    "data": [
      {
        "data": [
          {
            "displayType": "MENU",
            "name": "Submenu 1"
          },
          {
            "name": "Submenu 2",
            "displayType": "MENU",
          }
        ],
        "displayType": "MENU",
        "name": "Menu Item 1"
      },
      {
        "data": [
          {
            "displayType": "MENU",
            "name": "Submenu 3"
          },
          {
            "name": "Submenu 4",
            "displayType": "MENU",
          }],

        "displayType": "MENU",
        "name": "Menu Item 2"
      },
    ],
    "displayType": "MENU",
  }
  constructor(
    private chatbotService: ChatBotService
  ) {
  }

  ngOnInit(): void {
    this.chatbotService.getChatBotSettingData().then(snap => {
      let data = snap.docs[0].data();
      this.showMenu = data.enableMenu;
      this.menuMessage = data.menuMessage;
      this.mode = data.mode;
      this.welcomeMessage = data.welcomeMessage;
      this.showWelcomeButtons = data.enableWelcomeButtons;
      this.header = data.header;

    })
    console.log(this.colors);
    this.colors.forEach(element => {
      document.documentElement.style.setProperty(element.value, element.defaultColor);
    });
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    console.log("changes:", changes);
    if ('colors' in changes) {
      changes.colors.currentValue.forEach(element => {
        document.documentElement.style.setProperty(element.value, element.defaultColor);
      });
    }
  }

  sendMessage(msg: string, responseType: string) {

    if (msg) {
      let message: any = {
        date: new Date(),
        displayType: "TEXT",
        sentBy: "USER",
        data: msg,
        type: 'incoming',
        unread: true,
        responseType: responseType,
      };
      console.log("sending messsage");
      this.formValue = '';
      this.messages.push(message);
      this.messages.push(this.typing);
      setTimeout(() => {
        this.messages.push(this.reply);
      }, 1000);
    }
  }
  nextMenu(item) {
    let message: any = {
      date: new Date(),
      displayType: "TEXT",
      sentBy: "USER",
      data: item.name,
      type: 'incoming',
      unread: true
    };
    console.log("sending messsage");
    this.messages.push(message);
    let resp: any = {
      date: new Date(),
      displayType: item.displayType,
      sentBy: "BOT",
      data: item.data,
      type: 'outgoing',
      unread: true
    };
    this.messages.push(resp);
  }
}
