import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MarketingPlanService, ApiService } from '../../_services/index';

@Component({
  selector: 'app-markets',
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.scss']
})
export class MarketsComponent implements OnInit {

  @Output() confirmMarketsValid = new EventEmitter<any>();
  isReady: boolean = false;
  disableDIV:boolean = false;

  country: Array<any>;
  info: any;
  public chartData:Array<any> = [];
  public chartLabels:Array<string> = [];
  public selected_countries:Array<string> = [];
  public limit:number = 18;

  public chartType:string = 'doughnut';
  public chartColors:Array<any> = [{
      hoverBorderColor: ['rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)'],
      hoverBorderWidth: 0,
      backgroundColor: ["#F7464A", "#46BFBD", "#FDB45C", "#949FB1", "#4D5360"],
      hoverBackgroundColor: ["#FF5A5E", "#5AD3D1", "#FFC870", "#A8B3C5","#616774"]
  }];

  public chartOptions:any = {
      responsive: true
  };

  public chartClicked(e: any): void {
      console.log(e);
  }

  public chartHovered(e: any): void {
      console.log(e);
  }

  constructor(
    private apiService: ApiService,
    private marketingPlanService: MarketingPlanService
  ) {
      marketingPlanService.markets$.subscribe( info => this.info = info );
    }

  show(url: string) {
    this.isReady = false;
    setTimeout( () => { this.isReady = true; }, 3000 );
    //console.log(url);
    this.fetchLabels();
    this.fetchGeoTraffic();
  }

  setVisits(dataa){
    this.chartData = dataa;
    //console.log(this.chartData);
    this.selected_countries = [];
    this.country = [];
    for(let i=0;i<this.chartLabels.length;i++)
    {
      if(this.chartData[i]>this.limit)
      {
        this.selected_countries.push(this.chartLabels[i]);
        this.country.push(true);
      }
    }
  }

  setLabels(country) {
    this.chartLabels = country;
    //console.log(this.chartLabels);
  }

  fetchLabels() {
    this.apiService.fetchGeoTraffic()
                   .then(response => this.setLabels(response.countries));
  }

  fetchGeoTraffic() {
    this.apiService.fetchGeoTraffic()
                   .then(response => this.setVisits(response.visits));
  }


  getData() {
    this.apiService.fetchGeoTraffic()
                   .then(response => this.chartData = response.visits)
                   .then(() => {
                     this.selected_countries = [];
                     this.country = [];
                     for(let i=0;i<this.chartLabels.length;i++)
                     {
                       if(this.chartData[i] > this.limit) {
                         this.selected_countries.push(this.chartLabels[i]);
                         if(this.selected_countries[i] === this.info.country1 || this.selected_countries[i] === this.info.country2) {
                           this.country[i] = true;
                         }
                         else {
                           this.country[i] = false;
                         }
                       }
                     }
                   }
                 );
  }


  next() {
    let countries = [];
    for (let i = 0; i < this.selected_countries.length; i ++) {
      if(this.country[i] == true) {
        countries.push(this.selected_countries[i]);
      }
    }
    let selection = {"country1": countries[0] || "", "country2": countries[1] || ""};
    this.confirmMarketsValid.emit(selection);
  }

  ngOnInit() {
    this.isReady = true;
    this.fetchLabels();
    this.getData();
    this.disableDIV=true;
  }

}
