<div class="view">
	<div *ngIf="!isReady">
		<center>
			<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
		</center>
	</div>
	<div *ngIf="isReady">
		<div *ngIf="!taskCompleted">
			<center>{{responce}}</center>
		</div>
		<div *ngIf="taskCompleted">
			<div class="row" *ngIf="location!=''">
				<div class="col-3" style="text-align: left;">Country : {{country}}</div>
				<div class="col-5" style="text-align: center;">Location : {{location}}</div>
				<div class="col-4" style="text-align:right;">Date : {{taskDate}}</div>
			</div>

			<div class="row" *ngIf="location==''">
				<div class="col-6" style="text-align: left;">Country : {{country}}</div>
				<div class="col-6" style="text-align:right;">Date : {{taskDate}}</div>
			</div>

			<div class="row-6">
				<center>
					<img [src]='url' class="imageStyle">
				</center>
			</div>

			<center>
				<div *ngIf="!contactsFetched">
					<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
					<p class="spinnerStyle">Loading...</p>
				</div>
			</center>

			<div class='row-6' *ngIf="contactsFetched" style="padding: 10px"
				class="mat-elevation-z8;container-fluid view">

				<button class="btn" (click)="downloadCsv()" [routerLink]="" type="submit">Download</button>

				<mat-form-field style="width:100% ; padding: 2px">
					<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
				</mat-form-field>

				<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort style="width: 100%;"
					matSortDirection="desc" matSortDisableClear>

					<ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
						<container-element>
							<span>
								<th mat-header-cell *matHeaderCellDef mat-sort-header>
									<span>{{column | removeUnderscore | titlecase}} </span>
								</th>
							</span>
							<span>
								<td mat-cell *matCellDef="let dataSource">
									<span *ngIf="column=='key' || column=='seed keyword' ; else elseTemp" >{{ dataSource[column]}}</span>
									<ng-template #elseTemp>
                    <span style="text-align: center; display: block;">{{ dataSource[column] |number:'1.0-2'}}
                    </span>
                    </ng-template>
								</td>
							</span>
						</container-element>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
				<mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
					showFirstLastButtons></mat-paginator>
			</div>
		</div>
	</div>
</div>
