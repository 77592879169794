import { Component, OnInit } from '@angular/core';
import { TimePeriodService } from '../_services/index';

//import { Daterangepicker } from 'ng2-daterangepicker';
//import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as moment from 'moment';


@Component({
  selector: 'time-period',
  templateUrl: './time-period.component.html',
  styleUrls: ['./time-period.component.scss']
})
export class TimePeriodComponent implements OnInit {
  alwaysShowCalendars: boolean;
  showRangeLabelOnInput:boolean;
  showCustomRangeLabel:boolean;
  public dateInput: any;
  public singleDate: any;
  public start:any;
  public end:any;
  selected:any;
  ranges:any;
  selectedLabel:any;
  showsingledate:any;
  rangeFlag: boolean = true;


  constructor(
    private timePeriodService: TimePeriodService,
    //private daterangepickerOptions: DaterangepickerConfig
  ) {
      this.alwaysShowCalendars=true;
      this.showRangeLabelOnInput=true;
      this.showCustomRangeLabel=true;
      this.start=moment().subtract(1,'month');
      this.end=moment();

      this.ranges={ 'Today': [moment(), moment()],
             //'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
             'Last 7 Days': [moment().subtract(6, 'days'), moment()],
             'Last 30 Days': [moment().subtract(1,'month'), moment()],
             //'This Month': [moment().startOf('month'), moment().endOf('month')],
             //'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
             'Last 3 Months': [moment().subtract(3, 'month'), moment()],
             'Last 6 Months': [moment().subtract(6, 'month'), moment()],
             'Last 12 Months': [moment().subtract(12, 'month'), moment()],

          };

    // this.daterangepickerOptions.settings = {
    //       locale: { format: 'YYYY-MM-DD' },

    //       alwaysShowCalendars: false,
    //       showCustomRangeLabel:false,
    //       buttonClasses:['btn btn-sm'],
    //       applyClass:'btn-indigo',
    //       cancelClass:'btn-pink',
    //       ranges: {
    //          'Today': [moment(), moment()],
    //          //'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    //          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    //          'Last 30 days': [moment().subtract(1, 'month'), moment()],
    //          //'This Month': [moment().startOf('month'), moment().endOf('month')],
    //          //'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    //          'Last 3 Months': [moment().subtract(3, 'month'), moment()],
    //          'Last 6 Months': [moment().subtract(6, 'month'), moment()],
    //          'Last 12 Months': [moment().subtract(12, 'month'), moment()],
    //       }
    //   };

    // this.singleDate = Date.now();

  }

  ngOnInit() {
      this.selectedLabel='Last Month';
      this.selected={start:this.start,end:this.end};
      let current_timeperiod = this.timePeriodService.getCurrentTimeperiod();
      if(current_timeperiod) {
        this.dateInput = current_timeperiod;
      }
      else {
        this.dateInput = this.timePeriodService.getDefaultTimeperiod();
        this.timePeriodService.updateTimePeriod(this.dateInput);
      }
  }
  //  change(selected:any,dateInput:any)
  // {
  //   // dateInput.start=moment(selected.start._d);
  //   // dateInput.end=moment(selected.end._d);

  selectedDate(selected: any) {
        // dateInput.start = value.start;
        // dateInput.end = value.end;
        this.timePeriodService.updateTimePeriod(this.selected);

  }
  rangeClicked(range) {
      this.selectedLabel=range.label;
      this.rangeFlag = true;
  }

  datesUpdated(date) {
    if(this.rangeFlag === false) {
      this.selectedLabel = 'Custom';
    }
    this.rangeFlag = false;

  }


}
