import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from "@angular/forms";
// import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocalRankingService } from "../../_services/local-ranking.service";

declare var google: any;
@Component({
  selector: 'app-set-localrank-task',
  templateUrl: './set-localrank-task.component.html',
  styleUrls: ['./set-localrank-task.component.scss']
})
export class SetLocalrankTaskComponent implements OnInit {
  @Output() close = new EventEmitter<any>();
  map: any;
  options: any = {};
  overlays: any[] = [];
  locations: string;
  countries = [];
  targetKw = '';
  details = {
    address: '',
    countryRegion: {},
    coordinates: {
      lat: 0,
      lng: 0
    },
    locations: [],
    radius: 1000,
    targetKw: []
  };
  city = '';
  cityOptions = {
  }
  selectedCity: any;
  geocoder = new google.maps.Geocoder;
  // {
  //   'name': 'Daily',
  //   'value': '0 0 * * *'
  // },
  schedules = [
    {
      'name': 'Weekly',
      'value': '0 0 * * 0'
    },
    {
      'name': 'Monthly',
      'value': '0 0 1 * *'
    }
  ];
  timezones = [
    {
      'name': 'Asia/Kolkata',
      'value': 'Asia/Kolkata'
    },
    {
      'name': 'America/New_York',
      'value': 'America/New_York'
    }
  ];
  timezone: string;
  cloudSchedulerEnabled = false;
  schedule: string;
  checked = false;
  task: string;
  updated: boolean = false;

  constructor(
    private localRankService: LocalRankingService,
    // private dialogRef: DynamicDialogRef,
  ) { }

  async ngOnInit() {
    this.localRankService.getCountries().then(countries => {
      this.countries = countries;
    })
    this.addMarkerAndCircle();
    let snap = await this.localRankService.getAddressDetails();
    if (!snap.empty) {
      let data = snap.docs[0].data();
      this.cloudSchedulerEnabled = data.enableScheduler ? true : false;
      this.checked = this.cloudSchedulerEnabled;
      this.schedule = data.schedule ? data.schedule : '0 0 * * *';
      this.timezone = data.timezone ? data.timezone : 'Asia/Kolkata';
      this.details.address = data.address;
      this.details.countryRegion = data.countryRegion;
      this.details.coordinates = data.coordinates;
      this.details.locations = data.locations;
      this.details.radius = data.radius;
      data.targetKw.forEach(kw => {
        this.targetKw = this.targetKw + kw + '\n'
      });
      this.targetKw = this.targetKw.trim();
    }
    this.addMarkerAndCircle();
  }

  comparer(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.code === o2.code : o2 === o2;
  }

  handleAddressSelect(place) {
    // console.log(place);
    this.details.address = place.name + ", " + place.formatted_address;
    this.details.coordinates.lat = place.geometry.location.lat();
    this.details.coordinates.lng = place.geometry.location.lng();
    this.addMarkerAndCircle();
  }

  handleCitySelect(place) {
    // console.log(place);
    this.selectedCity = place;
    this.city = place.formatted_address;
  }

  onLocationSelect(event) {
    // console.log(event);
    let selectedPos = event.latLng
    this.details.coordinates.lat = selectedPos.lat();
    this.details.coordinates.lng = selectedPos.lng();
    this.getLocationName(selectedPos);
    this.addMarkerAndCircle()
  }

  onOverlaySelect(event) {
    let title = event.overlay;
    // console.log(event);
    let newloc = event.originalEvent.latLng;
    this.details.coordinates.lat = newloc.lat();
    this.details.coordinates.lng = newloc.lng();
    this.getLocationName(newloc);
    this.addMarkerAndCircle()
  }

  setMap(event) {
    this.map = event.map;
  }

  addMarkerAndCircle() {
    // console.log(this.map);
    // console.log("coordinates:", this.details.coordinates);
    this.options = {
      center: new google.maps.LatLng(Number(this.details.coordinates.lat), Number(this.details.coordinates.lng)),
      zoom: 12
    };
    this.overlays = [
      new google.maps.Marker({ position: new google.maps.LatLng(this.details.coordinates.lat, this.details.coordinates.lng) }),
      new google.maps.Circle({ center: this.details.coordinates, fillColor: '#1976D2', fillOpacity: 0.35, strokeWeight: 1, radius: this.details.radius })
    ];
    if (this.map)
      this.map.setCenter({
        lat: this.details.coordinates.lat,
        lng: this.details.coordinates.lng
      });
  }

  getLocationName(selectedPos) {
    this.geocoder.geocode({ location: selectedPos }, resp => {
      if (resp[0]) { this.details.address = resp[0].formatted_address }
    });
  }
  getLocationCordinates() {
    // this.geocoder
  }

  updateLocalRankConfigs(form) {
    let data = form.value;
    // console.log(data);
    this.task = this.cloudSchedulerEnabled ? (this.checked ? (this.updated ? 'update' : 'none') : 'delete') : this.checked ? 'create' : 'none';
    this.details.targetKw = (data.targetKw.split(/[\n,]+/).map(e => e.trim())).filter(x=>x!="" && x!=NaN);
    // console.log(this.task);
    if (this.task == 'create') {
      this.details['enableScheduler'] = this.checked;
      this.details['schedule'] = this.schedule;
      this.details['timezone'] = this.timezone;
    }
    else if (this.task == 'update') {
      this.details['schedule'] = this.schedule;
      this.details['timezone'] = this.timezone;
    }
    else if (this.task == 'delete') {
      this.details['enableScheduler'] = this.checked;
    }
    // console.log(this.details);
    this.localRankService.updateLocalRankConfigurations(this.details, this.task).then(resp => {
      console.log("document added");
    },
      err => {
        console.log(err);
      })
    this.close.emit(false);
  }

  getCountry(countryObj) {
    // console.log(countryObj);
    this.details.countryRegion = countryObj;
    // this.details.countryName = countryObj['name'];
    // this.details.region = countryObj['code'];
  }

  addLocation(city) {
    let cityName = '';
    let length = this.selectedCity.address_components.length;
    if (city) {
      this.selectedCity.address_components.forEach(address => {
        if (length < 4)
          cityName = cityName + address.long_name.trim() + ',';
        else if (address.types[0] != 'administrative_area_level_2' && address.types[0] != 'postal_code')
          cityName = cityName + address.long_name.trim() + ',';
      });
      // console.log(cityName);
      this.details.locations.push(cityName.slice(0, -1));
    } this.city = "";
  }

  deleteLocation(index) {
    this.details.locations.splice(index, 1);
  }
  enableScheduler(event) {
    console.log(event, this.cloudSchedulerEnabled);
  }
  changeSchedule(event) {
    this.updated = true;
    this.schedule = event;
  }
  changeTimezone(event) {
    this.timezone = event;
  }
}
