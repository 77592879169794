<div class="contanier-fluid view">

  <div *ngIf="!showForm">
    <h4>New contact</h4>
    <div class="close" (click)="close()">X</div>
    <form #contactData="ngForm" (ngSubmit)="saveContact(contactData)" name='contactForm'>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput name="first_name" [(ngModel)]="first_name" placeholder="First Name" required />
          </mat-form-field>
          <!-- <div *ngIf="first_name.invalid && (first_name.dirty || first_name.touched) && first_name.errors.required"
            class="alert alert-danger">
            First name is required
          </div> -->
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput name="last_name" [(ngModel)]="last_name" placeholder="Last Name" required />
          </mat-form-field>
          <!-- <mat-error *ngIf="last_name.invalid && last_name.errors.pattern" class="alert-danger">
            Please enter last name
          </mat-error> -->
        </div>
      </div>

      <div class="row" id="rowStyle">
        <div class="col-xs-4 col-md-2">
          <mat-form-field>
            <mat-select name="countryCode" placeholder="Country code" [(ngModel)]="countryCode" required>
              <mat-option *ngFor="let country of countryCodes" [value]="country.code">
                {{ country.code }} ({{country.name}})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-xs-8 col-md-10">
          <mat-form-field class="example-full-width">
            <input matInput id="phone_no" #phone_no="ngModel" name="phone_no" [(ngModel)]="phone" pattern="[0-9]{10}"
              placeholder="Mobile Number" required />
          </mat-form-field>

          <div *ngIf="phone_no.invalid && phone_no.errors.pattern" class="alert-danger">
            Please enter a valid mobile number.
          </div>
          <div *ngIf="phone_no.invalid && (phone_no.dirty || phone_no.touched) && phone_no.errors.required"
            class="alert-danger">
            Phone is required
          </div>
        </div>
      </div>
      <div class="row" id="rowStyle">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput name="email" [(ngModel)]="email_id" placeholder="Email" #email="ngModel"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" size="30" required />
          </mat-form-field>
          <div *ngIf="email.invalid && email.errors.pattern" class="alert-danger">
            Please enter valid email.
          </div>
          <div *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.required" class="alert-danger">
            Email is required
          </div>
        </div>
      </div>
      <div class="row" id="rowStyle" style="padding-top:2%;">
        <div class="col-md-6">
          <mat-slide-toggle name="sendTxtReminders" [(ngModel)]="sendTxtReminders">
            {{sendTxtReminders == true ?'Send SMS/text messages' : 'Do not send SMS/text messages'}}</mat-slide-toggle>
          <br>
          <mat-slide-toggle name="sendEmailReminders" [(ngModel)]="sendEmailReminders">
            {{sendEmailReminders == true ?'Send Emails' : 'Do not send Emails'}}</mat-slide-toggle>
          <br>
          <small><em>*All fields marked * are required.</em></small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <button mat-raised-button id="button" type="submit" [disabled]="!contactData.valid || clicked"
            (click)="saveContact(contactData);clicked=true;">
            Save contact
          </button>
        </div>
      </div>

    </form>
  </div>
  <div *ngIf="showForm">
    <p class="msg">{{msg}}</p>
    <div class="close" (click)="close()">X</div>
  </div>
</div>