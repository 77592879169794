<div class="items" *ngIf="!loading" style="width: 100%;height: 100%;">
    <form #form="ngForm" (ngSubmit)="updateOrganicRankConfiguration(form)" [formGroup]="organicRankFormGroup">
        <div class="row">
            <div class="col-md-1">
            </div>
            <div class="col-md-10">
                <div class="row">
                    <mat-form-field class="example-full-width">
                        <mat-label>Website URL</mat-label>
                        <input matInput placeholder="Enter website url" class="form-control" [(ngModel)]="websiteUrl" formControlName="url" name="websiteUrl" type="url" required>
                        <mat-error *ngIf="organicRankFormGroup.get('url').hasError('required')">Url required</mat-error>
                        <mat-error *ngIf="organicRankFormGroup.get('url').hasError('pattern')">Invalid url</mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="example-full-width">
                        <mat-label>Country</mat-label>
                        <mat-select name="selectedCountry" [(ngModel)]="selectedCountry"
                          (selectionChange)="getCountry($event.value)" formControlName="cntry">
                          <mat-option *ngFor="let country of countries" [value]="country.name">{{country['name']}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="organicRankFormGroup.get('cntry').hasError('required')">Country required</mat-error>
                    </mat-form-field>
                </div>
                <div class="row" *ngIf="checked">
                    <mat-label  style="font-size: 1.1em;" id="example-radio-group-label">Select how frequently you want to get rankings</mat-label>
                </div>
                <div class="row" *ngIf="checked">
                    <mat-radio-group aria-labelledby="example-radio-group-label" name="schedule" [(ngModel)]="schedule" formControlName="schedularValidation" class="example-radio-group" (change)="getSchedularInfo($event)" required>
                        <mat-radio-button class="example-radio-button" *ngFor="let frequency of schedularInfo" [value]="frequency.value" [checked]="frequency.checked">
                            {{frequency.name}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <!-- <mat-error *ngIf="schedularValidation.hasError('required')">Schedular required</mat-error> -->
                </div>
                <div class="row" style="text-align: start;margin:10px -15px;">
                    <div class="col-md-6" style="padding-left: 0px;">
                      <div style="margin-top:20px;">
                        <mat-slide-toggle name="enableSchedule" color="primary" labelPosition='before'
                          [checked]="cloudSchedulerEnabled" (toggleChange)="checked =!checked;" (change)="toggle($event)">
                          Organic Rank Automation :
                        </mat-slide-toggle>
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="checked">
                        <mat-form-field class="example-full-width">
                            <mat-label for="timezone">Timezone</mat-label>
                                <mat-select name="timezone" [(ngModel)]="timezone"
                                    (selectionChange)="changeTimezone($event.value)" formControlName="timeValidation">
                                <mat-option *ngFor="let timezone of timezones" [value]="timezone.value">{{timezone['name']}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="organicRankFormGroup.get('timeValidation').hasError('required')">Timezone required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div style="text-align: center;width: 100%;margin-top: 5%;">
                        <button mat-raised-button color="primary" class="text-center" type="submit"
                        [disabled]="!form.form.valid"
                        >{{label}}</button>
                    </div>
                </div>
            </div>
            <div class="col-md-1">

            </div>
        </div>
    </form>
</div>
<div class="items" *ngIf="loading">
    <div style="text-align: center;padding-top: 10%;">
        <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
    </div>
</div>

