import { Injectable } from '@angular/core';
import { DocumentReference } from 'angularfire2/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as moment from 'moment';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MarketingOffersService {


  private uploadPath: string = "marketingOfferImages";
  private uploadTask: firebase.storage.UploadTask;
  constructor(private http: HttpClient,
  ) { }

  // add new merketing offer
  async createNewOffer(offer) {
    let accId = sessionStorage.getItem('current_acc_id');
    const docSnap = await firebase.firestore().collection('accounts').doc(accId).collection('marketing-offers').add(offer)
  }

  //get  current Offers
  async getCurrentOffers(accId) {
    let currentTime = moment().toDate();
    // firebase.firestore.Timestamp.fromDate(currentTime)
    const offersSnap = await firebase.firestore().collection('accounts').doc(accId).collection('marketing-offers')
      .where('startDate', '<=', currentTime)
      .get();
    let currentOffers = [];
    await Promise.all(offersSnap.docs.map(async offerSnap => {
      let offer = offerSnap.data();
      offer['startDate'] = offer.startDate.toDate();
      offer['endDate'] = offer.endDate.toDate();
      offer['event'] = 'current';
      offer['offerId'] = offerSnap.id;
      if (moment(currentTime).isBefore(offer.endDate)) {
        currentOffers.push(offer)
      }
    })
    );
    return currentOffers;
  }

  async getPastOffers(accId) {
    let currentTime = moment().toDate()
    const offersSnap = await firebase.firestore().collection('accounts').doc(accId).collection('marketing-offers')
      .where('endDate', '<', currentTime)
      .get();
    let pastOffers = []
    await Promise.all(offersSnap.docs.map(async offerSnap => {
      let offer = offerSnap.data();
      offer['startDate'] = offer.startDate.toDate();
      offer['endDate'] = offer.endDate.toDate();
      offer['event'] = 'past';
      offer['offerId'] = offerSnap.id;
      pastOffers.push(offer);
    }));
    return pastOffers;
  }

  async getUpcomingOffers(accId) {
    let currentTime = moment().toDate()
    const offersSnap = await firebase.firestore().collection('accounts').doc(accId).collection('marketing-offers')
      .where('startDate', '>', currentTime)
      .get();
    let upcomingOffers = [];
    await Promise.all(offersSnap.docs.map(async offerSnap => {
      let offer = offerSnap.data();
      offer['startDate'] = offer.startDate.toDate();
      offer['endDate'] = offer.endDate.toDate();
      offer['event'] = 'upcoming';
      offer['offerId'] = offerSnap.id;
      upcomingOffers.push(offer);
    }));
    return upcomingOffers;
  }

  async deleteOffer(offerId) {
    let accId = sessionStorage.getItem('current_acc_id');
    let docSnap = firebase.firestore().collection('accounts').doc(accId).collection('marketing-offers').doc(offerId).delete();
  }

  async updateOffer(offerId, data, fieldToDelete) {
    let accId = sessionStorage.getItem('current_acc_id');
    console.log(fieldToDelete);
    try {
      let docRef = firebase.firestore().collection('accounts').doc(accId).collection('marketing-offers').doc(offerId);
      if (fieldToDelete !== '') {
        let obj = {}
        obj[fieldToDelete] = firebase.firestore.FieldValue.delete();
        docRef.update(obj);
        console.log(`deleted field`);
      }
      let docSnap = docRef.update(data);
      console.log(`updated data`);
      return docSnap;
    }
    catch (error) {
      return error;
    }
  }


  uploadFileToFirease(data) {
    console.log("in file upload ");
    var n = `image_` + Date.now();
    const file = data[0];
    const storageRef = firebase.storage().ref();
    this.uploadTask = storageRef.child(`${this.uploadPath}/${n}`).put(file);
    //   this.progressOfFileUpload();
    // }
    // progressOfFileUpload(){
    console.log("snapshot progress");
    return new Promise((resolve, reject) => {
      this.uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          // console.log(snapshot);
          console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        (error) => {
          console.log(error);
          reject(error.message);
        },
        () => {
          console.log("file url");
          this.uploadTask.snapshot.ref.getDownloadURL().then(url => {
            resolve(url);
          });
        })
    });
  }

  async createMarketingOfferTempalte(documetReference) {
    let docSnapshot = await documetReference.get();
    let offerData = docSnapshot.data()
    console.log(offerData)

    let accId = sessionStorage.getItem('current_acc_id');
    const docSnap = await firebase.firestore().collection('accounts').doc(accId).collection('marketing-offer-template').where('offerType', '==', offerData.offerType)
      .get();
    let docSnapData = docSnap.docs[0].data()
    let template = docSnapData.template.replace(/\b(?:offerName|offerDescription|terms_condition)\b/gi, matched => offerData[matched]);

    let data = {
      template: template,
      startDate: offerData.startDate.toDate(),
      endDate: offerData.endDate.toDate()
    }
    await firebase.firestore().collection('accounts').doc(accId).collection('marketing-offer-template').add(data)
  }

  async getOfferTemplatePrview(offerData) {
    console.log(offerData)
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    console.log(offerData)
    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/parseEjsTemplate`, offerData, header).toPromise();
  }

  async getOfferTemplate() {
    let accId = sessionStorage.getItem('current_acc_id');
    const docSnap = await firebase.firestore().collection('accounts').doc(accId).collection('image_template').where('type', '==', 'OFFER')
      .get();
    return docSnap
  }
}
