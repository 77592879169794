import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  Output,
  EventEmitter,
  Inject,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { RecurringAppointmentDetailsComponent } from "../recurring-appointment-details/recurring-appointment-details.component";
import * as moment from 'moment';
export interface DialogData { }

@Component({
  selector: "app-contact-appointments",
  templateUrl: "./contact-appointments.component.html",
  styleUrls: ["./contact-appointments.component.scss"],
})
export class ContactAppointmentsComponent implements OnChanges, OnInit {
  @Input() contact: any;
  @Input() appointments: any;
  @Output() onStatusChange = new EventEmitter<any>();
  contactDetails: any;
  apptDetails = [];
  first: number = 0;
  rows = 20;

  constructor(public dialog: MatDialog) { }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ("contact" in changes) {
      this.contactDetails = changes.contact.currentValue;
    }
    if ("appointments" in changes) {
      this.apptDetails = changes.appointments.currentValue;
    }
  }
  ngOnInit() {
    // console.log(this.contact);
    // console.log(this.appointments);
  }
  getDays(appt_start) {
    let now = moment(new Date());
    return Math.floor(moment.duration(now.diff(appt_start)).asDays());
  }
  changeAppointmentStatus(action, appointment) {
    if (appointment.recurringApptDetailReference) {
      let data = {
        name: `${appointment.contact_info.first_name} ${appointment.contact_info.last_name}`,
        appt_start: appointment.appt_start,
        slot: appointment.slot,
        clinic_name: appointment.location,
        status: appointment.status,
        notes: appointment.notes,
        appt: appointment,
        operationFlag: action
      };
      const dialogRef = this.dialog.open(RecurringAppointmentDetailsComponent, {
        panelClass: "custom-dialog-container",
        disableClose: true,
        height: "680px",
        width: "1300px",
        data: { apptDetails: data, title: "Recurring Appointment" },
      });
    }
    else {
      let details = {
        appt: appointment,
        action: action,
      };
      this.onStatusChange.emit(details);
    }
  }

  //  dialog
  openDialog(appointment) {
    const dialogRef = this.dialog.open(AppointmentDetailsDialog, {
      width: "300px",
      data: appointment,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      // this.appointment.notes = result;
      //  this.note = result;
      // this.data.push(result);
    });
  }
}

@Component({
  selector: "appointment-details-dialog",
  templateUrl: "appointment-details-dialog.html",
})
export class AppointmentDetailsDialog {
  apptData: any;
  constructor(
    public dialogRef: MatDialogRef<AppointmentDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.apptData = data;
  }

  close(): void {
    this.dialogRef.close();
  }

  savenote(note) {
    alert("note is saved!");
    // this.ordersService.updatenote(note);
  }
}
