<div class="row container-fluid justify-content-center">
  <div class="info-cards">
    <mat-card>
      <mat-card-title>
        <center>
          <h2>Reset Password</h2>
        </center>
      </mat-card-title>
      <mat-card-content>
        <form name="form" (ngSubmit)="f.form.valid && resetPassword()" #f="ngForm" novalidate [hidden]="!submitted">
            <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !newPassword.valid }">
                <mat-icon>lock outline</mat-icon>
                <input type="password" class="form-control" name="newPassword" [(ngModel)]="model.newPassword" #newPassword="ngModel" placeholder="Enter new password" minlength="6" required />
                <small *ngIf="f.submitted && newPassword.hasError('required')" class="help-block">Password is required</small>
                <small *ngIf = "newPassword.hasError('minlength')" class="help-block">Password should contain min 6 characters</small>
            </div>

            <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !verifyPassword.valid }">
                <mat-icon>lock outline</mat-icon>
                <input type="password" class="form-control" name="verifyPassword" [(ngModel)]="model.verifyPassword" #verifyPassword="ngModel"  placeholder="Confirm your password"  minlength="6" required />
                <small *ngIf="f.submitted && verifyPassword.hasError('required')" class="help-block">Verify Password is required</small>
                <small *ngIf = "verifyPassword.hasError('minlength')" class="help-block">Password should contain min 6 characters</small>
            </div>
            <div>
              <center>
                <button mat-raised-button color="primary" type="submit">Reset Password</button>
              </center>
            </div>
       </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
