<div class="container-fluid view">
  <!-- <button color="primary" (click)="download()"> Download </button> -->

  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
      <mat-spinner></mat-spinner>
  </div>

  <button mat-button (click)="bulkAction()">Bulk Action</button>

  <mat-table class="responses-table mat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="created_on" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let response">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(response) : null"
                      [checked]="selection.isSelected(response)"
                      [aria-label]="checkboxLabel(response)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created_on">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</mat-header-cell>
      <mat-cell *matCellDef="let response">{{ response.created_on | date:'MMM d, y h:mm a'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="first_name">
      <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
      <mat-cell *matCellDef="let response">{{ response.first_name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <mat-header-cell *matHeaderCellDef>Last Name</mat-header-cell>
      <mat-cell *matCellDef="let response">{{ response.last_name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef>Address</mat-header-cell>
      <mat-cell *matCellDef="let response">{{ response.address }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="apt">
      <mat-header-cell *matHeaderCellDef>Apt</mat-header-cell>
      <mat-cell *matCellDef="let response">{{ response.apt }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="city">
      <mat-header-cell *matHeaderCellDef>City</mat-header-cell>
      <mat-cell *matCellDef="let response">{{ response.city }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="state">
      <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
      <mat-cell *matCellDef="let response">{{ response.state }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="zip">
      <mat-header-cell *matHeaderCellDef>Zip</mat-header-cell>
      <mat-cell *matCellDef="let response">{{ response.zip }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="samples_chosen">
      <mat-header-cell *matHeaderCellDef>Sample</mat-header-cell>
      <mat-cell *matCellDef="let response">{{ response.samples_chosen }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
      <mat-cell *matCellDef="let response">{{ response.email }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="email_opt_in">
      <mat-header-cell *matHeaderCellDef>Email Opt-In</mat-header-cell>
      <mat-cell *matCellDef="let response">{{ response.email_opt_in }}</mat-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>

</div>
