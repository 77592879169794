import { Component, OnInit, OnDestroy } from '@angular/core';
import { SmsCampaignService } from "../_services/sms-campaign.service";
import { ContactService } from "../_services/contact.service";
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-sms-campaign',
  templateUrl: './sms-campaign.component.html',
  styleUrls: ['./sms-campaign.component.scss']
})
export class SmsCampaignComponent implements OnInit, OnDestroy {
  showCampaigns: boolean = true;
  showCampaignDetails: boolean = false;
  showSettings: boolean = false;
  searchTerm: string;
  action: string = "add";
  columns = ["campaign Name", "Details", "Created On", "status"]

  smsCampaignConfigSet: boolean = false;
  campaignDetails: {};
  campaignsList: any[] = [];
  campaignsListCopy: any[] = [];
  addedNewCampaign: boolean;
  selectedCampaign: any;
  campaignContacts: any[];
  loading: boolean;
  isConfigurationSet: boolean = true;
  campSubscription: Subscription;
  quickList = []
  titleloading: boolean = true
  constructor(
    private smsCampaignService: SmsCampaignService,
    private route: ActivatedRoute
  ) {
    this.campSubscription = this.smsCampaignService.smsCampaignChange$.subscribe(campaigns => {
      this.campaignsList = campaigns
      console.log(campaigns);
    });
  }

  async ngOnInit(): Promise<void> {

    this.loading = true;
    this.quickList = await this.smsCampaignService.getQuicklist();
    await this.smsCampaignService.checkSmsConfigSet()
    this.smsCampaignConfigSet = this.smsCampaignService.smsCampaignConfigSet;
    console.log(this.smsCampaignConfigSet);

    if (this.smsCampaignConfigSet) {
      this.isConfigurationSet = true;
      this.route.url.subscribe(url => {
        if (url[0].path == "quick-list") {
          this.openCreateModel()
        }
      })
      this.titleloading = false
      this.smsCampaignService.getSmsCampaigns().then(campList => {
        this.campaignsList = campList;
        this.campaignsListCopy = campList.slice();
        console.log(this.campaignsList);
        this.loading = false;
      })
    }
    else {
      this.isConfigurationSet = false;
      this.campaignsList = [];
      this.loading = false;
      console.log(this.campaignsList);
    }
    console.log(this.smsCampaignConfigSet);
  }

  getInput(event) {
    let s = event.target.value;
    this.searchTerm = event.target.value;
    this.searchCampaign();
  }
  searchCampaign() {
    this.campaignsList.filter(e => e.name === this.searchTerm);
  }
  openCreateModel() {
    this.campaignDetails = {};
    this.action = 'add';
    this.showCampaigns = false;
  }
  closeCreateModel() {
    this.showCampaigns = true;
  }
  addCampaign(data) {
    console.log(data, data['showCampaigns'], data['campaignAdded']);
    if (data.hasOwnProperty('campaignAdded')) {
      this.addedNewCampaign = data['campaignAdded'];
    }
    if (data.hasOwnProperty('showCampaigns')) {
      if (data['showCampaigns']) {
        this.closeCampaignDetails();
      }
      this.smsCampaignService.getSmsCampaigns().then(campList => {
        this.campaignsList = campList;
        this.campaignsListCopy = campList.filter(element => element);
        console.log(this.campaignsList);
        this.loading = false;
      })
    }
  }

  selectedCampaignDetails(campaignInfo) {
    console.log(campaignInfo);
    this.showCampaigns = false;
    this.smsCampaignService.getCampaignContacts(campaignInfo.campaignRef).then(resp => {
      this.campaignContacts = resp;
      campaignInfo['contacts'] = this.campaignContacts;
      this.selectedCampaign = campaignInfo;
      this.action = campaignInfo.status == "DRAFT" ? "edit" : "details";
      this.showCampaignDetails = true;
      console.log(campaignInfo);
    },
      err => {
        console.log(err);
      });
  }
  closeCampaignDetails() {
    this.showCampaignDetails = false;
    this.showCampaigns = true;
  }

  filterCampaignData(value: string) {
    value = value.toLocaleLowerCase();
    if (value) {
      console.log(value);
      let tempArray = [];
      tempArray = this.campaignsListCopy.map(element => {
        let tempString = element?.name + ' ' + element?.description + ' ' + element?.status
        let camplaignData = (JSON.stringify(tempString));
        if (camplaignData.toLocaleLowerCase().indexOf(value) > -1) {
          return element;
        }
      });
      this.campaignsList = tempArray.filter(element => element);
    }
    else {
      console.log("No Value")
      this.campaignsList = this.campaignsListCopy.filter(element => element);
    }
  }
  closeSettings() {
    this.showSettings = false;
  }

  ngOnDestroy() {
    this.campSubscription.unsubscribe();
  }

}
