export const COUNTRY_CODES = [
  {
    "timezones": [
      "Asia/Kolkata",
      "Asia/Calcutta"
    ],
    "name": "India",
    "code": "+91"
  },
  {
    "timezones": [
      "Europe/London"
    ],
    "name": "United Kingdom",
    "code": "+44"
  },
  {
    "timezones": [
      "America/Los_Angeles",
      "America/Denver",
      "America/Chicago",
      "America/New_York"
    ],
    "name": "United States",
    "code": "+1"
  },
  {
    "timezones": [
      "Asia/Dubai"
    ],
    "name": "United Arab Emirates",
    "code": "+971"
  }
];
