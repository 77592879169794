/*
 * Used in legacy code
 */

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
//import { toPromise } from 'rxjs';
import { Observable ,  Subject } from 'rxjs';
import { Http } from '@angular/http';
import { Allplans } from '../legacy/all-plans/all-plans';
import { Questions } from '../legacy/forms/forms';
import {UserService} from './user.service';
import { environment } from '../../environments/environment';

@Injectable()
export class MarketingPlanService {

  private basicInfoSource = new Subject<any>();
  basicInfo$ = this.basicInfoSource.asObservable();
  private competitorsSource = new Subject<any>();
  competitors$ = this.competitorsSource.asObservable();
  private marketsSource = new Subject<any>();
  markets$ = this.marketsSource.asObservable();
  private targetingSource = new Subject<any>();
  targeting$ = this.targetingSource.asObservable();
  private lifecycleSource = new Subject<any>();
  lifecycle$ = this.lifecycleSource.asObservable();

  private specificsSource = new Subject<any>();
  specifics$ = this.specificsSource.asObservable();


constructor(private http: Http,private userService:UserService) { }
  //planName : string;

  /*getPlan(id: number){
  return this.http.get('json/mktgplan.json')
          .map( response => <Allplans>response.json().data);

}
*/
setBasicInfo(info: any) {
  this.basicInfoSource.next(info);
}

setCompetitors(info: any) {
  this.competitorsSource.next(info);
}

setMarkets(info: any) {
  this.marketsSource.next(info);
}

setLifecycle(info: any) {
  this.lifecycleSource.next(info);
}

setTargeting(info: any) {
  this.targetingSource.next(info);
}

setSpecifics(info: any){
  this.specificsSource.next(info);
}

getAllPlans(uuid: string) : Promise<Allplans[]> {
//getAllPlans(uuid: string) {
  return this.http.post(`${environment.serverURL}/getallplans.php`, uuid)
    //return this.http.get('json/mktgplan.json')
    .toPromise()
    .then( response => <Allplans[]>response.json());
}

/*
setPlanForUser(uuid){

  let savedPlan=localStorage.getItem('plan');
  let plan=JSON.parse(savedPlan);
  let plan_id=plan.id;

  let input={"uuid":uuid,"plan_id":plan_id};
  return this.http.post('https://mktgbot-server.herokuapp.com/setPlan.php',input);
}
*/
getWhatForm(){
return this.http.get('assets/json/whatForm.json').pipe(
map( response => <Questions[]>response.json().questions));
}

createPlan(uuid: string) : Promise<any>
{
  return this.http.post(`${environment.serverURL}/createNewplan.php`, uuid)
    .toPromise();
}

saveName(planName: string) : Promise<any> {
    let plan= JSON.parse(localStorage.getItem('plan'));
    let id = plan.id;
    let body = {"id":id, "plan_name": planName};
    return this.http.post(`${environment.serverURL}/savePlan.php`, JSON.stringify(body))
      .toPromise();
  }

  submitForm(form:any){
  //let body=JSON.stringify(form);
  //console.log(form);
  return this.http.post(`${environment.serverURL}/saveForm.php`, JSON.stringify(form));

  }

  getPlanDetails(id: number, planName: string) {
    //return [{'id': id, 'planName': planName}];
    return this.http.post(`${environment.serverURL}/getPlanDetails.php`, id).pipe(
                    map( response => response.json() ));
  }

  saveBusinessInfo(info: any) {
    let plan_id = this.getPlanId();
    let input = {"plan_id": plan_id, "info": info};
    return this.http.post(`${environment.serverURL}/saveBusinessInfo.php`, JSON.stringify(input));
  }

  saveTargetingInfo(info: any) {
    let plan_id = this.getPlanId();
    let input = {"plan_id": plan_id, "info": info};
    return this.http.post(`${environment.serverURL}/saveTargetingInfo.php`, JSON.stringify(input));
  }

  getPlanId() {
    let plan = JSON.parse(localStorage.getItem('plan'));
    return plan.id;
  }

}
