import { Component, OnInit, ViewChild } from "@angular/core";
import { AccountService, ContactService } from "../../_services/index";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
// import { AllModules } from "@ag-grid-enterprise/all-modules";

@Component({
  selector: "app-ag-grid",
  templateUrl: "./ag-grid.component.html",
  styleUrls: ["./ag-grid.component.scss"],
})
export class AgGridComponent implements OnInit {
  @ViewChild("agGrid") agGrid: AgGridAngular;

  title = "app";
  twilio_from = "+919921562316";
  selected = [1, 2];
  private rowSelection = "multiple";
  private detailCellRendererParams;
  // public modules= AllModules;

  private columnDefs = [
    {
      headerName: "First Name",
      field: "first_name",
      sortable: true,
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Last Name",
      field: "last_name",
      sortable: true,
      filter: true,
    },
    { headerName: "Email", field: "email", sortable: true, filter: true },
    { headerName: "Phone", field: "phone", sortable: true, filter: true },
    {
      headerName: "Send Text Reminders",
      field: "send_text_reminders",
      sortable: true,
      filter: true,
    },
    { headerName: "Birthday", field: "birthday", sortable: true, filter: true },
    {
      headerName: "Last Visited",
      field: "last_visited",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Created On",
      field: "created_on",
      sortable: true,
      filter: true,
    },
  ];

  rowData: any;
  contacts: any = [];
  expandedElement: any = [];
  acc: any;
  isShow: boolean;
  // { make: 'Toyota', model: 'Celica', price: 35000 },
  // { make: 'Ford', model: 'Mondeo', price: 32000 },
  // { make: 'Porsche', model: 'Boxter', price: 72000 }

  constructor(
    private accountService: AccountService,
    private contactService: ContactService,
    private router: Router
  ) {
    this.accountService.accChange$.subscribe((accChange) => {
      // this.contactService.resetPageEndpoints();
      this.router.navigateByUrl("/websites");
    });

    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [{ field: "first_name" }],
        onFirstDataRendered: function (params) {
          params.api.sizeColumnsToFit();
        },
      },
      getDetailRowData: function (params) {
        params.successCallback(params.data.callRecords);
      },
    };
  }

  onGridReady(val) {
    console.log("grid ready");
  }

  ngOnInit() {
    this.contactService.returnContacts().then((contacts) => {
      this.contacts = contacts;
      if (this.contacts.length === 0) {
        this.isShow = false;
        this.rowData = [];
      } else {
        // this.expandedElement = this.contacts;
        this.rowData = this.contacts.filter(
          (contactObj) =>
            contactObj["active"] === undefined || contactObj["active"] === true
        );
        this.isShow = true;
      }
      this.acc = this.accountService.getCurrentAccDetails();
    });
    // this.selected = this.agGrid.api.getSelectedNodes();
  }

  onRowClicked(params) {
    params.node.setExpanded(!params.node.expanded);
  }

  bulkMessage() {
    //this.openDialogForMessage()
    // this.openDialogForMessage(this.selection.selected);
    console.log(this.agGrid.api.getSelectedNodes());
  }
}
