<div>
    <div>
        <div class="row">
            <div class="col-md-4" style="margin:1px auto 10px;font-size: 10px;font-weight: 500;text-align: left;">
            </div>
            <div class="col-md-4" style="margin:1px auto 10px;font-size: 20px;font-weight: 500;text-align: center;">
                {{accountsInformation['title']}}
            </div>
            <div class="col-md-4" style="margin:1px auto 10px;font-size: 10px;font-weight: 500">
            </div>
        </div>
    </div>
    <div *ngIf="accountConfigurationDetails['type'] == 'gmb'" style="width: 100%;height: 100%;padding: 0px;"
        class="row">
        <div class="col-4">

        </div>
        <div class="col-4">
            <div>
                <div *ngIf="gmbAccountsDetails else noGmbAccount">
                    <div *ngIf="gmbAccountsDetails.length>0">
                        Configure your account
                        <hr>
                        <ul *ngFor="let location of gmbAccountsDetails;let i =index">
                            <mat-checkbox (change)="selectAccount($event,location,i)" [checked]="location['checked']">
                                {{location['title']}}
                            </mat-checkbox>
                        </ul>
                        <hr>
                        <div class="row">
                            <div class="col-md-6" style="text-align: left;">
                                <button mat-raised-button id="button" color="primary" (click)="editAccountInformation()"
                                    [disabled]="gmbAccounts.length<=0">Update</button>
                            </div>
                            <div class="col-md-6" style="text-align: right;">
                                <button mat-raised-button id="button" color="primary" (click)="back()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #noGmbAccount>
                    <div style="text-align: center;" class="row">
                        Sorry,we couldn't find an Google My Bussiness Account...
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="col-4">

        </div>
    </div>
    <div *ngIf="accountConfigurationDetails['type'] == 'analytics'" style="width: 100%;height: 100%;padding: 0px;">
        <div class="row">
            <div class="col-md-4">
                <mat-form-field class="container">
                    <mat-select placeholder="Choose account" [(ngModel)]="selectedAnalyticsAcc"
                        (selectionChange)="analyticsAccountConfiguration('propertise')" name="account">
                        <mat-option *ngFor="let account of analyticsAccount" [value]="account">
                            {{account?.name}}
                            {{account?.id}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="container" *ngIf="!waitTillPropertiseFetch else loading">
                    <mat-select placeholder="Choose properties" [(ngModel)]="selectedProperty" name="account"
                        (selectionChange)="analyticsAccountConfiguration('view')">
                        <mat-option *ngFor="let property of analyticsPropertise" [value]="property">
                            {{property?.name}}
                            {{property?.id}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-template #loading>
                    <div id="spinnerDiv" style="text-align: center;">
                        <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                    </div>
                </ng-template>
            </div>
            <div class="col-md-4" *ngIf="!waitTillViewsFetch else loading">
                <mat-form-field class="container">
                    <mat-select placeholder="Choose views" [(ngModel)]="selectedView"
                        (selectionChange)="analyticsAccountConfiguration('save')" name="account">
                        <mat-option *ngFor="let views of analyticsViews" [value]="views">
                            {{views?.name}}
                            {{views?.id}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-template #loading>
                    <div id="spinnerDiv" style="text-align: center;">
                        <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div style="margin-left: 20%;margin-top: 5%;width: 50%;">
                <div class="row">
                    <div class="col-md-6" style="text-align: left;">
                        <button mat-raised-button id="button" color="primary" (click)="editAccountInformation()"
                            [disabled]="!saveAnalytics">Update</button>
                    </div>
                    <div class="col-md-6" style="text-align: right;">
                        <button mat-raised-button id="button" color="primary" (click)="back()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div style="width: 100%;height: 100%;padding: 0px;">
            <div style="width: 100%;" class="row">
                <div style="width: 100%;text-align: left;" class="col-md-6">
                    <button mat-raised-button id="editInfobutton" color="primary" (click)="back()">Cancel</button>
                </div>
                <div style="width: 100%;text-align: right;" class="col-md-6">
                    <button mat-raised-button id="editInfobutton" color="primary" (click)="addAnotherAccount()">Edit account</button>
                </div>
            </div>
        </div>
        <div style="margin-left: 20%;margin-top: 5%;width: 50%;" >
            <hr>
            <div class="row">
                <div class="col-6" >
                    Accounts :
                </div>
                <div class="col-6">
                    {{accountDetails[0]['analytics_info']['account']}}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6" >
                    Propertise :
                </div> 
                <div class="col-6">
                    {{accountDetails[0]['analytics_info']['property']}}
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6" >
                    Views :
                </div>
                <div class="col-6">
                    {{accountDetails[0]['analytics_info']['view']}}
                </div>
            </div>
            <hr>
        </div> -->
    </div>