import { Component, OnInit } from '@angular/core';
import { ApiService, AlertService, UserService } from '../../_services/index';
import { DeviceBreakout } from '../../_models/index';

@Component({
  selector: 'app-traffic-split-by-device',
  templateUrl: './traffic-split-by-device.component.html',
  styleUrls: ['./traffic-split-by-device.component.scss']
})
export class TrafficSplitByDeviceComponent implements OnInit {
public chartData: Array<any> = [];
chart:DeviceBreakout;
constructor(private apiService:ApiService){}

  ngOnInit(){
    this.fetchDevice();
  }

  setData(chart) {
    this.chartData = [ chart.desktop, chart.mobile ];
  }

  fetchDevice()
    {
    this.apiService.fetchDevice()
    .then(chart=>
                {
                  this.chart=chart;
                  this.setData(this.chart);
                }
          );
   }

  public chartType:string = 'doughnut';

  public chartLabels:Array<any> = ['Desktop', 'Mobile'];

  public chartColors:Array<any> = [{
      hoverBorderColor: ['rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)'],
      hoverBorderWidth: 0,
      backgroundColor: ["#F7464A", "#46BFBD", "#FDB45C", "#949FB1", "#4D5360"],
      hoverBackgroundColor: ["#FF5A5E", "#5AD3D1", "#FFC870", "#A8B3C5","#616774"]
  }];

  public chartOptions:any = {
      responsive: true
  };

  public chartClicked(e: any): void {
      console.log(e);
  }

  public chartHovered(e: any): void {
      console.log(e);
  }
}
