import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() payload: any;
  messages: any;
  constructor() {
  }

  ngOnInit(): void {
    this.messages = this.payload.data;
    console.log(this.messages);
  }
  // click(message) {
  //   console.log(message);
  //   if (message.name == 'YES')
  //     this.chatService.startLiveChat();
  // }

}
