import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'jquery';
import { ReviewsService } from '../../_services/reviews.service';
import * as moment from 'moment';
import { SendReviewInviteDialogComponent } from '../send-review-invite-dialog/send-review-invite-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewsConfigurationComponent } from '../reviews-configuration/reviews-configuration.component'

interface City {
  name: string,
  code: string
}
@Component({
  selector: 'app-reviews-operation',
  templateUrl: './reviews-operation.component.html',
  styleUrls: ['./reviews-operation.component.scss']
})
export class ReviewsOperationComponent implements OnInit {
  moment: any = moment;
  reviews: any = [];
  nextReviews: any = [];
  reviewData: any;
  rows = 20;
  first = 0;
  content: any;
  totalReviews: number = 0;
  totalCountFlag: boolean = true;
  displayTooltip: boolean = false;
  copiedString: any = "Click here to write a review."
  reviewsSource: any = [
    { "name": "google", "key": "Google" },
    { "name": "facebook", "key": "Facebook" }
  ];
  reviewTimeSortTime: any = [
    { "type": "Yesterday" },
    { "type": "Past 7 days" },
    { "type": "Past month" },
    // { "type": "past 2 month" },
    // { "type": "past 3 month" },
    // { "type": "past 6 month" },
    // { "type": "This Year" },
    // { "type": "custom" },
  ];
  reviewSortOnRating: any = [
    { "type": "Recent reviews" },
    { "type": "Oldest reviews" },
    // {"type":"Highest rated"},
    // {"type":"Lowest rated"},
  ]
  selectedReviewSource: any = null;
  selectedReviewRecent: any = null;
  selectedReviewTime: any = null;
  selectedLocation: any = null;
  pagingInfo: any = {};
  fromDate: Date;
  toDate: Date;
  selectedReviewTimeData: any;
  isReviewReady: boolean = false;
  isReady: boolean = false;
  noReviews: boolean = false;
  isAccountExists: boolean;
  allSet: boolean = false;
  notSet: boolean;
  backgroundFlag: boolean = false;
  gmbLocationData: any = [];
  fetchReview: boolean = true;
  disabled: boolean = true;
  isReviewExists: boolean = true;
  popupLoder: boolean = false;
  totalReview: number = 0
  averageRating: number = 0
  isDisabled: boolean = false;
  openSendReviewInvitePopup: boolean = false;
  tooltip: string = "Copy Message";
  locationLinks: any = [];
  selectedLocationReviewLink: string;
  copiedMessage: string = "Click here to write a review."
  smsBody: string = "Click here to write a review."
  popup: boolean = true;
  dialogData: any;
  pattern: string = "#link#";
  smsBodySingleLocation: string;
  isMessageCopied: boolean;
  constructor(
    private reviewsService: ReviewsService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,

  ) {
  }
  ngOnInit() {
    this.getGmbLocationInfo();
    //this.reviewMetaData();
    this.pagingInfo['limit'] = 20;
    this.pagingInfo['offset'] = 0;
    this.checkExist();
    this.getReivews();
    this.reviewMetaData();
  }
  async getSettingsData() {
    let data = await this.reviewsService.getReviewConfiguration();
    if (data.docs.length > 0) {
      this.smsBodySingleLocation = data.docs[0].data().smsBody;
      this.smsBody = data.docs[0].data().smsBody;
    }
    if (this.locationLinks.length > 0) {
      if (this.smsBodySingleLocation.match(this.pattern)) {
        this.copiedString = this.smsBodySingleLocation.replace(this.pattern, this.locationLinks[0].newReviewUrl);
      } else {
        this.copiedString = `${this.smsBodySingleLocation}\n\n${this.locationLinks[0].newReviewUrl}`;
      }
      // this.copiedString = `${this.smsBodySingleLocation}\n\n${this.locationLinks[0].newReviewUrl}`;
    }
    // console.log(this.copiedString);
  }
  async getLocationLink() {
    let link = await this.reviewsService.getLink();

    if (link.docs.length > 0) {
      let data = link.docs[0].data()
      for (let i = 0; i < data.gmb_info.length; i++) {
        this.locationLinks.push(data.gmb_info[i]);
      }
    }
    // console.log(this.locationLinks);
  }
  copy() {
    return this.copiedString;
  }
  locationLink(obj) {
    this.copiedMessage = null;
    if (this.smsBody.match(this.pattern)) {
      this.copiedMessage = this.smsBody.replace(this.pattern, obj.newReviewUrl);
    } else {
      this.copiedMessage = `${this.smsBody}\n\n${obj.newReviewUrl}`;
    }
    // this.copiedMessage = `${this.smsBody}\n\n${obj.newReviewUrl}`;
    this.popup = false;
    this.isMessageCopied = true;
    return this.copiedMessage;
  }
  copyMessage() {
    this.tooltip = "Copied";
  }
  mouseEnter() {

  }
  async checkExist() {
    this.noReviews = (await (await this.reviewsService.checkReviewsExists()).get()).empty;
    this.isAccountExists = await (await (await this.reviewsService.checkGmbAccountExists()).get()).exists;

  }
  async getReivews() {
    //console.log("reviews exists", this.noReviews);
    if (!this.noReviews) {
      let response = await this.reviewsService.getOverallReviews(this.pagingInfo);
      //console.log(response);
      if (response['ref'] != undefined) {
        this.nextReviews = await response['ref'].docs.map(doc => {
          let obj = doc.data();
          obj['documentId'] = doc.id;
          obj['docRef'] = doc.ref;
          return obj;
        });
        // console.log(this.nextReviews);
        for (let i = 0; i < this.nextReviews.length; i++) {
          this.reviews.push(this.nextReviews[i]);
        }
        //console.log(this.reviews);
        if (this.reviews.length < 20) {
          this.fetchReview = false;
        }
        this.totalReviews = await response['totalReviews'];
        this.isReviewExists = true;
      }
      else {
        //console.log('no more reviews..................');
        this.fetchReview = false;
        this.totalCountFlag = true;
        this.isReviewExists = false;
      }
      this.isReviewReady = true;
      this.isReady = true
    }
    else {
      this.isReviewReady = false;
    }
    this.allSet = true;
  }

  showMoreContent(content) {
    content.show = !content.show;
  }
  async reviewMetaData() {

    this.getLocationLink();
    this.getSettingsData();
    let data = await this.reviewsService.getReviewMetaData();
    //console.log(data);
    this.averageRating = data.overallAverageRating;
    this.totalReview = data.overallReviewCount;
  }
  paginate(event) {
    //console.log(event);
    this.rows = event.rows;
    this.pagingInfo['limit'] = this.rows;
    this.pagingInfo['offset'] = this.rows * event.page;
    this.getReivews();
  }

  async getDropdownValue(event, type) {
    //console.log(event, type);
    if (type == 'source') {
      if (event != null)
        this.pagingInfo['source'] = event.name;
      else {
        delete this.pagingInfo['source'];
        this.selectedReviewSource = null;
      }
    }
    else if (type == 'time') {
      if (event != null) {
        this.selectedReviewTimeData = this.selectedReviewTime['type'].toLowerCase();
        let monthOrDays;
        if ((this.selectedReviewTimeData.match(/\d+/) != null))
          monthOrDays = this.selectedReviewTimeData.match(/\d+/)[0];
        else
          monthOrDays = 1;

        if (this.selectedReviewTimeData.includes('past') && this.selectedReviewTimeData.includes('days')) {
          this.toDate = moment().toDate();
          this.fromDate = moment().subtract(monthOrDays, 'days').toDate();

        }
        else if (this.selectedReviewTimeData.includes('past') && this.selectedReviewTimeData.includes('month')) {
          this.fromDate = moment().subtract(monthOrDays, 'month').startOf('month').toDate();
          this.toDate = moment().subtract(0, 'month').endOf('month').toDate();
        }
        else if (this.selectedReviewTimeData.includes('yesterday')) {
          this.fromDate = moment().subtract(1, 'days').startOf('day').toDate()
          this.toDate = moment().subtract(1, 'days').endOf('day').toDate();
        }
        else if (this.selectedReviewTimeData.includes('year')) {
          this.toDate = moment().subtract(1, 'year').toDate();
          this.fromDate = moment().subtract(0, 'year').toDate();
        }

        if (this.fromDate && this.toDate) {
          this.pagingInfo['compareTime'] = {
            'from': this.fromDate,
            'to': this.toDate
          }
        }
      }
      else {
        delete this.pagingInfo['compareTime'];
        this.selectedReviewTimeData = null;
      }
    }
    else if (type == 'location') {
      if (event != null) {
        this.pagingInfo['locationPath'] = this.selectedLocation['name'];
      }
      else {
        delete this.pagingInfo['locationPath'];
        this.selectedLocation = null;
      }
    }
    else if (type == 'recent') {
      if (event != null) {
        //console.log(this.selectedReviewRecent);
        if (this.selectedReviewRecent['type'] == "Recent reviews") {
          this.pagingInfo['sortType'] = 'desc';
        }
        if (this.selectedReviewRecent['type'] == "Oldest reviews") {
          this.pagingInfo['sortType'] = 'asc';
        }
        else if (type == 'recent') {
          //console.log(this.selectedReviewRecent);
          if (this.selectedReviewRecent['type'] == "Recent reviews") {
            this.pagingInfo['sortType'] = 'desc';
          }
          if (this.selectedReviewRecent['type'] == "Oldest reviews") {
            this.pagingInfo['sortType'] = 'asc';
          }
        }
      }
      else {
        delete this.pagingInfo['sortType'];
        this.selectedReviewRecent = null;
      }
    }
    if (this.selectedReviewSource != null || this.selectedReviewRecent != null || this.selectedReviewTime != null || this.selectedLocation != null) {
      this.disabled = false;
    }
    if (this.selectedReviewSource == null && this.selectedReviewRecent == null && this.selectedReviewTime == null && this.selectedLocation == null) {
      this.clearReviews();
    }
  }

  loadData(value) {
    let OffSet = value['offset'];
    this.fetchReview = true
    if (OffSet != this.pagingInfo['offset']) {
      this.pagingInfo['limit'] = value['limit'];
      this.pagingInfo['offset'] = value['offset'];
      this.getReivews();
      //console.log(this.pagingInfo['limit']);
      //console.log(this.pagingInfo['offset']);
    }
    // //console.log(value);
  }

  async getGmbLocationInfo() {
    let snapshot  = await this.reviewsService.checkGmbAccountExists();
    if((await snapshot.get()).exists)
      this.gmbLocationData = await (await (snapshot).get()).data()['gmb_info'];
    //console.log(this.gmbLocationData);
  }

  filterReviews() {
    delete this.pagingInfo['limit'];
    delete this.pagingInfo['offset'];
    this.pagingInfo['limit'] = 20;
    this.pagingInfo['offset'] = 0;
    this.isReady = false;
    this.totalCountFlag = false;
    this.reviews = [];
    this.nextReviews = [];
    this.getReivews();
  }

  clearReviews() {
    this.pagingInfo = {};
    this.isReady = false;
    this.reviews = [];
    this.nextReviews = [];
    this.selectedReviewSource = null;
    this.selectedReviewRecent = null;
    this.selectedReviewTime = null;
    this.selectedLocation = null;
    this.disabled = true;
    this.totalCountFlag = true;
    this.pagingInfo['limit'] = 20;
    this.pagingInfo['offset'] = 0;
    this.getReivews();
  }

  sendReviewInvite() {
    const dialogRef = this.dialog.open(SendReviewInviteDialogComponent, {
      width: "1500px",
      height: '550px',
      data: {
      },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDialogForPatientConfiguration() {
    const dialogRef = this.dialog.open(ReviewsConfigurationComponent, {
      width: "1500px",
      height: '550px',
      data: {
      },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log("dialog data are :", result);
      if (result.saveFlag) {
        this.smsBody = result.smsBody;
        console.log(result)
        this.smsBodySingleLocation = result.smsBody;
        if (this.locationLinks.length > 0) {
          if (this.smsBodySingleLocation.match(this.pattern)) {
            this.copiedString = this.smsBodySingleLocation.replace(this.pattern, this.locationLinks[0].newReviewUrl);
          } else {
            this.copiedString = `${this.smsBodySingleLocation}\n\n${this.locationLinks[0].newReviewUrl}`;
          }
        }
      }
      let resp = await this.getSettingsData();
    });
  }


}
