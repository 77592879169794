<mat-card class="card">
    <mat-card-header style="width: 100%;margin: 0px;">
        <div class="row" style="width: 100%;">
            <div class="col-4">
            </div>
            <div class="col-4">
                <div style="text-align: center;padding-top: 10px;color: white;font-size: 20px;">Send Review Request
                </div>
            </div>
            <div class="col-4">
                <div style="text-align: right;padding-top: 10px;padding-right: 0px;">
                    <i class="pi pi-times" style="font-size: 1rem;cursor: pointer;font-size: 20px;" mat-dialog-close>
                    </i>
                </div>
            </div>
        </div>
    </mat-card-header>
    <mat-card-content style="margin: 0px;width: 100%;">
        <div *ngIf="isFirstScreen" style="width: 100%;">
            <app-algolia-contact-search (sendContactEmittor)="getSelectedContacts($event)" [inputText]="inputText"
                [selectedContacts]="selectedContacts"></app-algolia-contact-search>
        </div>
        <div *ngIf="isSecondScreen" style="width: 100%;">
            <div style="margin: 5px 0px 5px 9px;font-size: 17px;font-weight: 500;">Selected Contacts</div>
            <div *ngIf="sendBySmsAndEmail.length>=0" style="margin: 5px 20px 20px 9px">
                <p-table [value]="selectedContacts" class="table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngFor="let col of displayedColumns;let i= index">{{col.header}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-content let-index let-rowIndex="rowIndex">
                        <tr>
                            <td>{{content['first_name']+' '+content['last_name']}}</td>
                            <td>{{content['full_phone']}}</td>
                            <td>{{content['email']}}</td>
                            <td style="text-align: center;">
                                <mat-checkbox class="example-margin" (change)="getSendSource($event,'email',index)"
                                    [checked]="sendBySmsAndEmail[rowIndex]['sendByEmail']"></mat-checkbox>
                            </td>
                            <td *ngIf="twilio" style="text-align: center;">
                                <mat-checkbox class="example-margin" (change)="getSendSource($event,'sms',index)"
                                    [checked]="sendBySmsAndEmail[rowIndex]['sendBySms']"></mat-checkbox>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div *ngIf="isThirdScreen" style="width: 100%;margin: 0px 5px 0px 5px;">
            <div class="row" style="margin: 0px 5px 0px 5px;">
                <div class="col" style="padding: 5px;">
                    <div style="margin-top: 5px;margin-bottom: 5px;">Select location to send the link for write a review
                    </div>
                    <ul *ngFor="let location of gmbAccountsDetails;let i =index">
                        <mat-radio-button [checked]="location['isSelected']"
                            (change)="locationSelection($event,location)">
                            {{location['locationName']}}
                        </mat-radio-button>
                    </ul>
                </div>
            </div>
            <div class="row" style="margin: 0px 5px 0px 5px;">
                <div class="col-md-6" *ngIf="showEmail">
                    <div class="row" style="margin-top: 20px;margin-right: 10px;">
                        Email Subject
                        <input type="text" pInputText placeholder="Enter email subject" [(ngModel)]="emailSubject"
                            required>
                    </div>
                    <div class="row" style="margin-top: 11px;margin-right: 10px;">
                        Email Body
                        <p-editor [readOnly]="readOnly" [(ngModel)]="emailBody" placeholder="Enter email body"
                            [style]="{'height':'140px'}" (onTextChange)="onTextChange($event)"></p-editor>

                        <!-- <textarea rows="7" cols="30" pInputTextarea placeholder="Enter email body" autoResize="autoResize" [(ngModel)]="emailBody" class="inputText" required></textarea> -->
                    </div>
                </div>
                <!-- <div class="col-md-6" *ngIf="!showEmail">
                    No selection for send email
                </div> -->
                <div class="col-md-6" *ngIf="showSms">
                    <div *ngIf="twilio" class="row" style="margin-top: 20px;margin-right: 10px;">
                        SMS Body
                        <textarea rows="11" cols="30" pInputTextarea placeholder="Enter sms body"
                            autoResize="autoResize" [(ngModel)]="smsBody" class="inputText" required></textarea>
                        <!-- <p-editor [readOnly]="readOnly" [(ngModel)]="smsBody" placeholder="Enter sms body"
                            [style]="{'height':'140px'}"></p-editor> -->

                    </div>
                </div>
                <!-- <div class="col-md-6" *ngIf="!showSms">
                    No selection for send sms
                </div> -->
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions style="width: 100%;margin: 0px;" *ngIf="!saveConfirm">
        <div style="text-align: right" *ngIf="isFirstScreen">
            <button mat-raised-button color="primary" style="text-align: right;margin: 9px 15px;"
                (click)="Next('first')" [disabled]="selectedContacts.length==0">Next</button>
        </div>
        <div *ngIf="isSecondScreen" class="row">
            <div class="col-md-6" style="text-align: left">
                <button *ngIf="!dilogData.fromScreen" mat-raised-button color="primary"
                    style="margin: 9px 15px 0px 9px;" (click)="Back('second')">Back</button>
            </div>
            <div class="col-md-6" style="text-align: right">
                <button mat-raised-button color="primary" style="margin: 9px 15px;" (click)="Next('second')"
                    [disabled]="sendDisable">Next</button>
            </div>
        </div>
        <div *ngIf="isThirdScreen" class="row">

            <div class="col-md-6" style="text-align: left">
                <button mat-raised-button color="primary" style="margin: 9px 15px 0px 9px;"
                    (click)="Back('third')">Back</button>
            </div>
            <div class="col-md-6" style="text-align: right">
                <button mat-raised-button color="primary" style="margin: 9px 15px;" (click)="SendReviewInvite()"
                    [disabled]="disabledBtn || smsBody == '' || emailSubject=='' || isSendInviteBthDisabled">Send</button>
            </div>
        </div>
    </mat-card-actions>
    <mat-card-actions style="width: 100%;" *ngIf="saveConfirm">
        <!-- <div style="text-align: center;text-align: center;">
            <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
        </div>
    </mat-card-actions>
    <mat-card-actions *ngIf="messageSendStatus" style="width: 100%;text-align: center;">
        <div style="text-align: center;">
            <b>Review request sent successfully</b>
        </div> -->
        <div style="text-align: center;">
            <b>Review request will be sent soon, you can close the dialog.</b>
        </div>
    </mat-card-actions>
</mat-card>