<div *ngIf="!isReady">
    <mat-progress-spinner mode="indeterminate" style="margin:0 auto;"></mat-progress-spinner>
</div>

<div class="container">
    <div class="card">
        <div class=" card-header msg_head">
            <div class="row d-flex bd-highlight">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-2" style=" display:inline-block;padding-left: -4em; ">
                            <ngx-avatar name={{userDetails.first_name}}></ngx-avatar>
                        </div>
                        <div class="col-md-10">
                            <span style="font-size: 16px;">{{userDetails.first_name|titlecase}}
                                {{userDetails.last_name|titlecase}}</span><br>
                            <span style="font-size: 12px;">{{phone}}</span>
                            <span style="font-size: 12px;padding-left: 20px;">{{email}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-8">
                        </div>
                        <div class="col-md-4" style="padding:10px">
                            <div *ngIf="showEndButton">
                                <button mat-stroked-button color="primary" (click)='endLiveChat()'>End</button>
                            </div>
                            <!-- <ng-template #start>
                                <button mat-stroked-button color="primary" (click)='startLiveChat()'>Start</button>
                            </ng-template> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isReady">
            <div *ngIf="allMessages?.length == 0" style="text-align: center; height: 28em;"> No Messages</div>

            <!-- <div *ngIf="allMessages?.length > 0" style="height:90vh; overflow-y: auto;width: 98%;"> -->
            <div id="msgBox" #msgBox class="scrolling-wrapper-flexbox"
                [ngStyle]="showEndButton?{'height':'68vh'}:{'height':'78vh'}">
                <div id="msgs">
                    <div *ngIf='showMsg' [ngClass]="{'from': true }">
                        <div class="d-flex justify-content-end">
                            <div class="message from">
                                Hi! How can I assist you today?
                            </div>
                        </div>
                        <div style="padding: 1%;" class="d-flex justify-content-end">
                            <div *ngFor="let button of welcomeButtons" class="tab">
                                {{button.name}}</div>
                        </div>
                        <div *ngIf="showMenu == 'OUTER' || showMenu == 'BOTH' " class="d-flex justify-content-end">
                            <div class="message from" [innerHTML]="menuMessage">
                            </div>
                        </div>
                        <!-- <div class="welcomeMessage" *ngIf="showMenu == 'OUTER' || showMenu == 'BOTH' "
                            [innerHTML]="menuMessage"></div> -->
                        <div *ngIf="showMenu == 'OUTER' || showMenu == 'BOTH' " class="d-flex justify-content-end">
                            <div style="padding: 1%;" [ngClass]="{'from': true}">
                                <app-menu [payload]="items"></app-menu>
                            </div>
                        </div>
                    </div>
                    <!-- <ng-container *ngFor="let last = last;let message of messages | async"> -->
                    <div #msg *ngFor="let message of messages;let last = last;let i = index">

                        <div *ngIf="message.displayType =='MENU' ">
                            <div class="d-flex justify-content-end" style="padding: 1%;"
                                [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')}">
                                <app-menu [payload]="message"></app-menu>
                            </div>
                        </div>
                        <div *ngIf="message.displayType =='CUSTOM'">
                            <app-message-presenter [payload]="message"></app-message-presenter>
                        </div>
                        <div *ngIf="message.sentBy === 'AGENT'"
                            style="margin: 0;padding: 2px;position: relative;text-align: center;">
                            <div class="" style="display: flex;justify-content: center;color: rgb(146, 143, 143);">
                                <hr style="width:100%">
                                <p style="font-weight: bold;white-space: nowrap; margin:auto 10px;font-size: 11px;"
                                    [innerHTML]="message.data">
                                    <!-- {{message.data}} -->
                                </p>
                                <hr style="width: 100%">
                            </div>
                        </div>
                        <div *ngIf="message.sentBy === 'USER'">
                            <!-- <div> -->
                            <div class="message d-flex justify-content-start"
                                [ngClass]="{'to': message.sentBy === 'USER' }" [innerHTML]="message.data">
                            </div>
                        </div>
                        <div *ngIf="message.displayType=='TYPING' && last">
                            <div class="message d-flex justify-content-end"
                                [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR') }"
                                [innerHTML]="message.data">
                                <!-- {{ message.data }} -->
                            </div>
                        </div>
                        <div *ngIf="message.displayType=='BUTTON'&&  (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')"
                            class="d-flex justify-content-end" style="padding: 1%;">
                            <div [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')}">
                                <app-button [payload]="message"></app-button>
                            </div>
                        </div>
                        <div
                            *ngIf="message.displayType=='CARD' &&  (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')">
                            <app-card-carousel [payload]="message">
                            </app-card-carousel>
                        </div>
                        <div
                            *ngIf="message.displayType=='IFRAME' &&  (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')">
                            <iframe width="300" height="200" frameborder="1" [src]="transform(message.data)"></iframe>
                        </div>
                        <div
                            *ngIf="message.displayType=='TEXT' &&  (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')">
                            <div class="message d-flex justify-content-end"
                                [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR') }"
                                [innerHTML]="message.data">
                            </div>
                        </div>
                        {{scrollToBottom()}}
                    </div>
                    <!-- </ng-container> -->
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
    <div *ngIf="showEndButton">
        <div class="card-footer">
            <div class="input-group">
                <textarea name="" class="form-control type_msg" style="border-radius: 2em;" [(ngModel)]="formValue"
                    (keyup.enter)="sendMessage(formValue)" placeholder="Type your message...">
        </textarea>
                <i class="fa fa-paper-plane" aria-hidden="true" id="sendIcon" (click)="sendMessage(formValue)"></i>
            </div>
        </div>
    </div>
</div>