import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { Subject, Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccountService } from './account.service';
import { AlertService } from './alert.service';
import { FormService } from '../_services/form.service';
import { environment } from '../../environments/environment';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { data } from 'jquery';

@Injectable()
export class ContactService {

  private startingDocs: any = [];
  private endingDocs: any = [];
  public pagesFetched: number = 0;
  public pageSize: number;
  public sortCol: string;
  public sortDirection: any;
  private filter: firebase.firestore.Query;
  private contactsSource = new Subject<any>();
  contacts$ = this.contactsSource.asObservable();

  private eventTask = new Subject<any>();
  task$ = this.eventTask.asObservable();

  ref = firebase.storage().ref('excel');

  private notesSource = new Subject<any>();
  notes$ = this.notesSource.asObservable();

  private smsSource = new Subject<any>();
  sms$ = this.smsSource.asObservable();

  private changePhoneSource = new Subject<any>();
  phone$ = this.changePhoneSource.asObservable();

  private sendSMSUpdation = new Subject<any>();
  sendSMSNotification$ = this.sendSMSUpdation.asObservable();

  createdOn: any;
  contactRef: any;
  availableContact: any;
  contactDoc: any;
  isUrl: boolean = false;
  showProgress: boolean = false;
  filterdArr: any = [];
  mergedNotes = [];
  // db = firebase.firestore();

  private contactsCollection: AngularFirestoreCollection;
  contacts: any;
  current_acc_id: string;
  contactsObservable: Subscription;
  updateValue: any;
  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private alertService: AlertService,
    private afs: AngularFirestore,
  ) {
    let accId = sessionStorage.getItem('current_acc_id');
    this.contactsCollection = afs.collection('accounts').doc(accId).collection('contacts');
    this.contactsObservable = this.contactsCollection.valueChanges().subscribe(
      _actions =>// actions.map(act =>
      {
        this.contacts = this.getContacts();
        this.updateContacts(this.contacts);
      });

    this.accountService.accChange$.subscribe(
      _accChange => {
        this.contactsObservable.unsubscribe();
        let accId = sessionStorage.getItem('current_acc_id');
        this.contactsCollection = afs.collection('accounts').doc(accId).collection('contacts');
        this.contactsObservable = this.contactsCollection.valueChanges().subscribe(
          _actions =>// actions.map(act =>
          {
            this.contacts = this.getContacts();
            this.updateContacts(this.contacts);

          });

      });
  }

  messageNotification(smsObject) {
    smsObject.date = firebase.firestore.Timestamp.now();
    // when sms sent to a contact it will update in the activities array f activity component and display.
    this.sendSMSUpdation.next(smsObject);
  }

  async getContactsCount() {
    let contactsSnapshot = await this.filter.get();
    return contactsSnapshot.size;
  }

  filterDataSource(Name) {
    firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts')
      .get()
      .then(
        contactSnapshot => {
          this.filterdArr = [];
          contactSnapshot.forEach(
            docsnapshot => {
              let doc = docsnapshot.data();
              if (doc.first_name.trim().toLowerCase() === Name) {
                this.filterdArr.push(doc);
                return this.filterdArr;
              }
              else if (doc.last_name.trim().toLowerCase() === Name) {

                this.filterdArr.push(doc);
                return this.filterdArr;
              }
              else {
                return this.filterdArr;
              }
            });
        });
  }

  // setDocumentForNotifications(doc_id){
  //   this.filter = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts').doc(doc_id);
  // }

  setFilters(filters: any) {
    let collectionRef = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts');
    this.filter = collectionRef;

    let operator;
    let value;

    filters.forEach(
      filter => {
        if (filter.selectedOperator !== undefined) {
          if (filter.datatype === 'boolean') {
            operator = <firebase.firestore.WhereFilterOp>'==';
            value = filter.selectedOperator.value;
          }
          else {
            operator = <firebase.firestore.WhereFilterOp>filter.selectedOperator.value;

            if (filter.datatype === 'date') {
              value = firebase.firestore.Timestamp.fromDate(moment(filter.selectedValue).toDate());
            }

            else {
              value = filter.selectedValue;
            }
          }

          this.filter = this.filter.where(filter.field.columnName, operator, value);
        }
      }
    );

    /*
    filters.forEach(
      filter => {
        filter.operator = <firebase.firestore.WhereFilterOp> filter.operator;
        if(filter.value instanceof Date)
          filter.value = firebase.firestore.Timestamp.fromDate(filter.value);
        this.filter = this.filter.where(filter.column, filter.operator, filter.value);
      }
    );
    */

  }

  getPaginatedContactNotification(doc_id) {
    return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts').doc(doc_id).get();
  }

  changePrimaryProvider(contact, provider) {
    contact.ref.update({ providerReference: provider['providerReference'] });
  }


  getPaginatedContacts(pageIndex: number, pageSize: number) {
    if (this.pagesFetched === 0) {
      this.pageSize = pageSize;
      /*
      return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts')
        .orderBy(sortCol, this.sortDirection)
        .limit(pageSize)
        .get();
      */

      return this.filter
        .orderBy(this.sortCol, this.sortDirection)
        .limit(pageSize)
        .get();
    }
    // If it's a page that has already been fetched
    else if (pageIndex < this.pagesFetched) {
      /*
      return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts')
        .orderBy(sortCol, this.sortDirection)
        .startAt(this.startingDocs[pageIndex])
        .endAt(this.endingDocs[pageIndex])
        .get();
      */

      return this.filter
        .orderBy(this.sortCol, this.sortDirection)
        .startAt(this.startingDocs[pageIndex])
        .endAt(this.endingDocs[pageIndex])
        .get();
    }
    // If it's a new page
    else {

      /*
      return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts')
        .orderBy(sortCol, this.sortDirection)
        .startAfter(this.endingDocs[pageIndex - 1])
        .limit(pageSize)
        .get();
      */

      return this.filter
        .orderBy(this.sortCol, this.sortDirection)
        .startAfter(this.endingDocs[pageIndex - 1])
        .limit(pageSize)
        .get();
    }
  }

  setPageEndpoints(start: any, end: any) {
    this.startingDocs.push(start);
    this.endingDocs.push(end);
    this.pagesFetched += 1;
  }

  resetPageEndpoints() {
    this.startingDocs = [];
    this.endingDocs = [];
    this.pagesFetched = 0;
  }

  returnContacts() {
    if (this.current_acc_id === sessionStorage.getItem('current_acc_id')) {
      return this.contacts;
    }
    else {
      this.contacts = this.getContacts();
      return this.contacts
    }
  }

  triggeringContacts() {
    let db = firebase.firestore();
    let doc = db.collection('contacts');

    let observer = doc.onSnapshot(_docSnapshot => {
      // console.log(`Received doc snapshot: ${docSnapshot}`);
      // ...
    }, err => {
      console.log(`Encountered error: ${err}`);
    });

    // this.user$ = afAuth.authState.pipe(switchMap(user => {
    // if (user) {
    // let trigger = this.afs.doc(`contacts`).valueChanges();
    // console.log(trigger);

    // }

  }
  async getAllTask(contactRef) {
    let allTask = [];
    let docRef = await firebase.firestore()
      .collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .get();

    docRef.ref.collection('activity')
      .where('contactRef', '==', contactRef)
      .orderBy("createdOn", "desc")
      .onSnapshot(snap => {
        allTask = [];
        snap.docs.map(doc => {
          allTask.push(doc.data());
          // console.log(doc.data());
          console.log(doc.id);
        })
        this.eventTask.next(allTask);
      })
  }
  async getContacts() {
    let contacts = [];
    this.current_acc_id = sessionStorage.getItem('current_acc_id');
    let contactsDocsSnapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts')
      .orderBy("created_on", "desc")
      .get();



    let unresolvedContacts = [];
    contactsDocsSnapshot.forEach(
      contactDoc => {
        let contact = contactDoc.data();
        contact.ref = contactDoc.ref;
        if (contact.last_visited) {
          //contact.last_visited = moment(contact.last_visited.toDate()).format('MMM D, Y h:mm a');
          contact.last_visited = contact.last_visited.toDate('MMM d, yyyy');//
        }
        if (contact.birthday && typeof (contact.birthday) !== "string") {
          // console.log(typeof(contact.birthday));
          //contact.last_visited = moment(contact.last_visited.toDate()).format('MMM D, Y h:mm a');
          contact.birthday = contact.birthday.toDate('MMM d, yyyy');//
        }
        if (contact.active === undefined) {
          contact.active = true;
        }

        if (contact.created_on) {
          //             contact.created_on = moment(contact.created_on.toDate()).format('MMM D, Y h:mm a');
          contact.created_on = contact.created_on.toDate();
        }

        if (contact.providerReference !== undefined) {
          this.getProviderData(contact.providerReference).then(
            data => {
              contact['providerData'] = data;

            });
        }
        if (contact.providerReference === undefined) {
          contact['providerData'] = {};
          contact.providerData.name = '';
        }
        contacts.push(contact);

        // unresolvedContacts.push(contact);
        // unresolvedContacts.push(this.getAppointments(contact));
      }
    );


    // contacts = await Promise.all(unresolvedContacts);
    // this.updateContacts(contacts);
    return contacts;
  }

  //Gets provider details for a contact.
  async getProviderData(providerRef) {
    // console.log('provderRef', providerRef);
    // let doc = await providerRef.get();
    // let doc = await providerRef.get();
    let providerId = providerRef.id;
    let doc = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem("current_acc_id")}`).collection('providersDetails').doc(providerId).get();
    return doc.data();
  }

  async getProviderNameArray() {
    let providerDocsSnapshot = await firebase.firestore().collection('accounts').
      doc(`${sessionStorage.getItem('current_acc_id')}`).collection('providersDetails').get();
    return providerDocsSnapshot;
  }

  async getContact(doc_id) {
    try {
      let contactDocsSnapshot = await firebase.firestore().collection('accounts').
        doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts').doc(doc_id).get();
      let data = contactDocsSnapshot.data();
      data['created_on'] = data['created_on'] ? data['created_on'].toDate() : undefined;
      data['last_visited'] = data['last_visited'] ? data['last_visited'].toDate() : undefined;
      data['birthday'] = data['birthday'] ? data['birthday'].toDate() : undefined;
      data['ref'] = contactDocsSnapshot.ref;
      return data;
    }
    catch (error) {
      console.log(error);
    }
  }

  async getAppointments(contact: any) {
    contact.appointments = [];
    let apptsQuerySnapShot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('appointments')
      .where('contact', '==', contact.ref)
      .orderBy("booked_on", "desc")
      .get();

    apptsQuerySnapShot.forEach(apptsDoc => {
      let appointment = apptsDoc.data();
      appointment.ref = apptsDoc.ref;
      appointment.booked_on = appointment.booked_on.toDate();
      appointment.appt_start = appointment.appt_start.toDate();
      appointment.appt_end = appointment.appt_end.toDate();
      contact.appointments.push(appointment);
    });
    return contact;
  }

  updateContacts(contacts_master: any) {
    this.contactsSource.next(contacts_master);
  }

  updateNotes(note: any) {
    this.notesSource.next(note);
  }

  updateRowVisibleStatus(contact) {
    if (contact.active === undefined || false)
      contact.active = true;
    else
      contact.ref.update({ "active": contact.active }).then(() => {
        // console.log("updated the row status");
      },
        error => {
          console.error(error);
        });
  }

  async getPhoneNumberLanguage(full_phone) {
    if (full_phone) {
      let snap = await firebase.firestore().collection(`accounts/${sessionStorage.getItem('current_acc_id')}/messages`).doc(full_phone).get();
      if (snap.exists)
        return snap.data();
      else {
        let obj = {
          "full_phone": full_phone,
          "targetLanguage": 'en'
        }
        await snap.ref.set(obj);
        return obj;
      }
    }
    else
      return null;
  }

  async updatePhoneNumberLanguage(language, contact) {
    // let contact = await contact_ref.get();
    let contactSnapshot = await firebase.firestore().collection(`accounts/${sessionStorage.getItem('current_acc_id')}/messages`).doc(contact["full_phone"]).get();
    if (contactSnapshot.exists)
      await firebase.firestore().collection(`accounts/${sessionStorage.getItem('current_acc_id')}/messages`).doc(contact["full_phone"]).update({ targetLanguage: language })
    else
      await firebase.firestore().collection(`accounts/${sessionStorage.getItem('current_acc_id')}/messages`).doc(contact["full_phone"]).set({ full_phone: contact["full_phone"], targetLanguage: language })
  }

  updateLastVisitedDate(contact_ref, refLast_visited) {
    contact_ref.update({ "last_visited": refLast_visited }).then(() => {
      console.log("contact has got updated");
    },
      error => {
        console.error(error);
      });
  }


  updateBirthDate(contact_ref, birthDate) {
    let dob = moment(birthDate).format('MMMM, DD');
    contact_ref.update({ "birthday": moment(birthDate).toDate(), "dob": dob }).then(() => {
      console.log("contact has got updated");
    },
      error => {
        console.error(error);
      });
  }


  /*updateRowVisible(contact_ref){
    //this.contact_ref.rowVisible="true";
    contact_ref.update({"rowVisible":true}).then(() =>{
      console.log("Row visibility updated to true"+contact_ref.rowVisible);
    },
    error=>{
      console.error(error);
    });
  }*/

  updateActivity(phone: any) {
    this.changePhoneSource.next(phone);
  }

  saveAppointmentNotes(ref: any, notes: any) {
    return ref.update({ "notes": notes });
  }


  saveAppointmentBilledAmount(ref: any, billed_amount: any) {
    return ref.update({ "billed_amount": billed_amount });
  }

  saveSendTextReminders(send_text_reminders: any, ref: any) {
    return ref.update({ "send_text_reminders": send_text_reminders });

  }


  uploadFile(fileName: any, form_name: string) {
    const id = Math.random().toString(36).substring(2);
    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef.child(`contacts/${form_name}/${id}.${fileName.name}`).put(fileName);
    return uploadTask;
  }

  async store_csv_to_storage(selectedFile) {
    let added_docs = [];
    const id = Math.random().toString(36).substring(2);
    let storageRef = firebase.storage().ref();
    let uploadTaskSnapshot = await storageRef.child(`contacts/${id}.${selectedFile.name}`).put(selectedFile);

    let downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
    return downloadURL;

    /*
    return uploadTask.on('state_changed',
          snapshot =>  {
            this.snapshot = snapshot;
            this.uploadProgress = (this.snapshot.bytesTransferred / this.snapshot.totalBytes) * 100;
          },
          error => {
            console.error(error);
          },
          ()=>{
            let path = uploadTask.snapshot.metadata.fullPath;
            return uploadTask.snapshot.ref.getDownloadURL().then
                  (
                    downloadURL =>{
                        this.isUrl = true;
                        this.downloadURL = downloadURL;
                        this.showProgress = false;

                        return this.createImportLog()
                            .then(
                              docRef => {
                                console.log('Import log created');
                                return docRef;
                                // Call the Flask app and pass it docRef.id
                              },
                              error => {
                                console.error('There was an error while creating the import log. ' + error);
                              }
                            );
                        //let res=this.downloadURL;
                        //return res;
                      // return this.http.get(`${'http://127.0.0.1:5000/home'}`);
                  });
                    // added_docs.push(downloadURL);
            });
            // return added_docs;
      */
  }

  async createImportLog(downloadURL: string, filename: string) {
    let import_log = {
      downloadUrl: downloadURL,
      filename: filename,
      accountId: sessionStorage.getItem('current_acc_id'),
      createdOn: moment().toDate(),
      status: 'to import'
    };

    let docRef = await firebase.firestore().collection('import_logs').add(import_log);
    return docRef;
  }

  async saveContactFromCSVFile(contact: any) {

    let added_docs = [];
    let updated_docs = [];
    let all_docs = {};
    let flag_same_contact_exists = false;
    let statusMsg = "";

    let contactRef = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts')
      .where('full_phone', '==', contact.full_phone)
      .get();// check this phone is exist or not
    // if phone is not exist
    if (contactRef.empty === false) {
      contactRef.forEach(
        contactDoc => {
          let filtercontact = contactDoc.data(); // trace each info of contact if doc is more than 1
          if (filtercontact.first_name === contact.first_name) {
            contactDoc.ref.update({ 'email': contact.email, 'last_visited': contact.last_visited, 'last_name': contact.last_name }); // update all optional data
            flag_same_contact_exists = true;
            statusMsg = `${contact.first_name} ${contact.last_name} was updated.`;
          }
        }
      );
      if (!flag_same_contact_exists) {
        let addStatus = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts').add(contact);
        statusMsg = `${contact.first_name} ${contact.last_name} was added.`;
        /*
        .then(success =>
            {
              console.log('contact added');
              statusMsg = `${contact.first_name} ${contact.last_name} was added.`;
            },
            error =>
            {
              console.error(error);
              statusMsg = `${contact.first_name} ${contact.last_name} was not added.`;
            });
        */
      }
    }

    else {
      let emailcontactRef = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts')
        .where('email', '==', contact.email)
        .get();// check this phone is exist or not

      if (emailcontactRef.empty === false) {
        emailcontactRef.forEach(
          emailDoc => {
            let filteredContact = emailDoc.data();
            if (filteredContact.first_name === contact.first_name) {
              emailDoc.ref.update({ 'last_name': contact.last_name, 'full_phone': contact.full_phone, 'phone': contact.phone, 'last_visited': contact.last_visited });
              flag_same_contact_exists = true;
              statusMsg = `${contact.first_name} ${contact.last_name} was updated.`;
            }
          });

        if (!flag_same_contact_exists) {
          let addStatus = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts').add(contact)
          statusMsg = `${contact.first_name} ${contact.last_name} was added.`;
          /*
          .then(success =>
              {
                console.log('contact added');
                statusMsg = `${contact.first_name} ${contact.last_name} was added.`;
              },
          error =>
            {
              console.error(error);
              statusMsg = `${contact.first_name} ${contact.last_name} was not added.`;
            }
        );
        */
        }

      }
      else {
        let addStatus = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts').add(contact)
        statusMsg = `${contact.first_name} ${contact.last_name} was added.`;
        /*
        .then(success =>
            {
              console.log('contact added');
              statusMsg = `${contact.first_name} ${contact.last_name} was added.`;
            },
        error =>
          {
            console.error(error);
            statusMsg = `${contact.first_name} ${contact.last_name} was not added.`;
          }
      );
      */
      }
    }
    console.log(statusMsg);
    return statusMsg;
    //return all_docs;
  }
  //}

  /*
  saveContactFromCSVFile(contact: any) {
    return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts').add(contact);
  }
  */

  /*
  async saveContactFromCSVFile(contacts: any){

          let added_docs = [];
          let updated_docs = [];
          let all_docs = {};
          for(let i=0;i<contacts.length;i++){
          let contactRef = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts')
          .where('email', '==', contacts[i].email)
          .where('first_name', '==', contacts[i].first_name)
          .where('last_name', '==', contacts[i].last_name)
          .get();

              //if(contactRef.empty === true || contacts[i].email === ''){
              if(contactRef.empty === true){
                console.log(`Add: ${contacts[i].first_name} ${contacts[i].last_name} - ${contacts[i].email} | ${contacts[i].phone} | ${contacts[i].send_automated_sms} | ${contacts[i].last_sms_sent}`);
                  contacts[i].countryCode = this.accountService.getCurrentAccDetails().countryCode;
                  contacts[i].send_text_reminders = true;
                  contacts[i].full_phone = this.accountService.getCurrentAccDetails().countryCode + contacts[i].phone;
                  contacts[i].created_on = moment().toDate();
                  added_docs.push(contacts[i]);
                  firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts').add(contacts[i]);
              }
              else{
                console.log(`Update: ${contacts[i].first_name} ${contacts[i].last_name} - ${contacts[i].email} | ${contacts[i].phone} | ${contacts[i].send_automated_sms} | ${contacts[i].last_sms_sent}`);
                  updated_docs.push(contacts[i]);
                  contactRef.docs[0].ref.update(contacts[i]);
              }
             console.log(`Added/updated contact #${i + 1}`);
      }
      all_docs = {added_docs, updated_docs};
      return all_docs;
  }
  */



  updateEditedContact(contact: any, column: string, enteredValue: string) {
    let contactObj = {};
    contactObj[column] = enteredValue;
    if (column == 'phone') {
      contactObj['full_phone'] = contact['countryCode'] + enteredValue;
      this.updateActivity(contactObj['full_phone']);
    }
    contactObj['updated_on'] = moment().toDate();
    console.log(contactObj);
    return contact.ref.update(contactObj);
  }

  mergeContactAndAppointmentsUsingTransaction(dataToMerge, docRef) {
    // firebase.firestore().runTransaction(async (tranaction) => {
    //   for (var i = 0; i < dataToMerge.contacts.length; i++) {
    //     let docSnapShot = await dataToMerge.contacts[i].ref.get();
    //     let contactRef = docSnapShot.ref;
    //     let notesSnapshot = await contactRef.collection('notes').get();
    //     notesSnapshot.docs.forEach(doc => {
    //       console.log(doc);
    //       tranaction.set(docRef.collection("notes").doc(), doc.data())
    //       // what should we do with notes of old contacts
    //     })
    //     console.log("contact => ", docSnapShot.id)
    //     let appointmentSnapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('appointments').where("contact", "==", contactRef).get();
    //     if (appointmentSnapshot.docs.length > 0) {
    //       appointmentSnapshot.docs.forEach(doc => {
    //         tranaction.update(doc.ref, { "contact": docRef })
    //       })
    //     }
    //   }
    // }).then(resp => {
    //   console.log("Success")
    // }).catch(error => {
    //   console.log("error")
    // })
  }

  // mergedAppointmentAndNotes(dataToMerge, docRef) {
  //   let newDocId;
  //   let mergedContactId = [];
  //   const mergedContactsNotes = [];
  //   let tmpAray = [];


  //   let contactRef = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts');
  //   let collectionRef = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('appointments');

  //   firebase.firestore().runTransaction(async (Transaction) => {


  //     try {


  //       for (var i = 0; i < dataToMerge.contacts.length; i++) {
  //         contactRef
  //           .where(
  //             "status",
  //             "==",
  //             "MERGED"
  //           )
  //           .where(
  //             "first_name",
  //             "==",
  //             dataToMerge.contacts[i].first_name
  //           )
  //           .where(
  //             "last_name",
  //             "==",
  //             dataToMerge.contacts[i].last_name
  //           )
  //           .where(
  //             "email",
  //             "==",
  //             dataToMerge.contacts[i].email
  //           )
  //           .get()
  //           .then(docSnapShot => {
  //             docSnapShot.forEach(data => {
  //               try {
  //                 contactRef.doc(data.id).collection("notes")
  //                   .get()
  //                   .then(getNote => {
  //                     getNote.forEach(eachNote => {
  //                       // docRef.collection('notes').add( // );
  //                       let element = {
  //                         date: eachNote.get('date'),
  //                         note: eachNote.get('note'),
  //                         type: eachNote.get('type')
  //                       }

  //                       // docRef.collection('notes').add( // );

  //                       Transaction.set(docRef.collection('notes').doc(String(eachNote.id)), { element });
  //                     })
  //                   })
  //               }
  //               catch (error) {
  //                 console.log(error);
  //               }
  //             })
  //           })
  //       }


  //       contactRef
  //         .where(
  //           "status",
  //           "==",
  //           "MERGED"
  //         )
  //         .get()
  //         .then(docSnapShot => {
  //           docSnapShot.forEach(data => {
  //             try {
  //               mergedContactId.push(data.id);

  //             }
  //             catch (error) {
  //               console.log(error);
  //             }
  //           })
  //         });



  //       collectionRef
  //         .get()
  //         .then(docSnapShot => {
  //           docSnapShot.forEach(data => {

  //             (data.get('contact'))
  //               .get()
  //               .then(contactData => {

  //                 for (var i = 0; i < mergedContactId.length; i++) {
  //                   if (mergedContactId[i] == contactData.id) {
  //                     // data.ref.update({"contacts":docRef});
  //                     Transaction.update(data.ref, { "contact": docRef });
  //                   }
  //                 }

  //               })

  //           })
  //         })

  //     }
  //     catch (e) {
  //       console.log('Transaction failure:', e);
  //     }

  //   })

  // }

  saveMergedContact(isMergedAppointment, isMergedNotes, dataToMerge: any, contactData: any) {

    // let newContact = contactData;
    let newContact = {
      "first_name": contactData.first_name,
      "last_name": contactData.last_name,
      "phone": contactData.phone,
      "email": contactData.email,
      "countryCode": contactData.countryCode,
      "created_on": moment().toDate(),
      "full_phone": contactData.full_phone,
      "source": "import",
      "send_text_reminders": contactData.sendTxtReminders,
      "mergedContactsReference": contactData.mergedContactsReference,
      "active": true,
      "emailInfo": contactData.emailInfo,
      "phoneInfo": contactData.phoneInfo,
    }

    return new Promise((resolve, reject) => {
      firebase.firestore().runTransaction(async (tranaction) => {
        let data: any = {
          appointmentCount: 0,
          notesCount: 0,
        }
        let docRef = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts').add(newContact);
        let snapshot = await docRef.get();
        data.newContact = snapshot.data();
        data.newContact.created_on = data.newContact.created_on.toDate();
        data.newContact.ref = snapshot.ref;

        for (var i = 0; i < dataToMerge.contacts.length; i++) {
          let docSnapShot = await dataToMerge.contacts[i].ref.get();
          let contactRef = dataToMerge.contacts[i].ref;
          tranaction.update(contactRef, { "status": "MERGED", "active": false })
          if (isMergedNotes) {
            let notesSnapshot = await contactRef.collection('notes').get();
            notesSnapshot.docs.forEach(doc => {
              console.log(doc);
              tranaction.set(docRef.collection("notes").doc(), doc.data())
              data.notesCount++;
              // what should we do with notes of old contacts
            })
          }
          console.log("contact => ", docSnapShot.id);
          if (isMergedAppointment) {
            let appointmentSnapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('appointments').where("contact", "==", contactRef).get();
            if (appointmentSnapshot.docs.length > 0) {
              appointmentSnapshot.docs.forEach(doc => {
                tranaction.update(doc.ref, { "contact": docRef })
                data.appointmentCount++;
              })
            }
          }
        }
        return data;
      }).then(resp => {
        console.log(resp);
        resolve(resp)
      }).catch(error => {
        reject(error)
      })
    })


  }

  saveNewContact(contactData: any) {

    let newContact = {
      "first_name": contactData.first_name,
      "last_name": contactData.last_name,
      "phone": contactData.phone_no,
      "email": contactData.email,
      "countryCode": contactData.countryCode,
      "created_on": moment().toDate(),
      "full_phone": contactData.full_phone,
      "source": "import",
      "send_text_reminders": contactData.sendTxtReminders,
      "send_email_reminders": contactData.sendEmailReminders,
      "active": true
    }

    return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts')
      .where('email', '==', contactData.email)
      .get()
      .then(snapshot => {

        if (snapshot.empty === true || contactData.email == "") {

          return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts').add(newContact)
            .then(
              contactRef => {
                this.contactRef = contactRef;
                console.log("In");
                return this.contactRef;
              },
              error => {
                console.error(error);
                this.alertService.error("There is some problem while adding the contact.");
              }
            );

        }
        else {
          this.availableContact = snapshot.docs[0].ref;
          this.contactDoc = this.availableContact.id;
          return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('contacts').doc(this.contactDoc).update({ first_name: contactData.first_name, last_name: contactData.last_name, phone: contactData.phone_no, full_phone: contactData.full_phone, countryCode: contactData.countryCode, send_text_reminders: contactData.sendTxtReminders, updated_on: moment().toDate() });
        }
      });

  }

  sendServeyEmail(emailObj: any) {
    let body = `<p>Hi,</p>
          <br><p><b>${emailObj['url']}</b>is URL for your Mktg.doctor account.</p>
          <br>`
    firebase.functions().httpsCallable('sendEmail')({
      "to_email": emailObj['email'],
      "subject": "Survey Form",
      "body": body,
      "from": "appointments@nhansmedia.com"
    }).then(resp => {
      console.log(resp);
    },
      err => {
        console.log(err);
      })
  }

  sendSurveyInformation(surveyFormInfo: any) {
    console.log("servey form info", surveyFormInfo);
    return (firebase.functions().httpsCallable('sendSurveyInformation'))(surveyFormInfo).then(resp => {
      // console.log("service resp:", resp);
      return { 'status': 'sent', 'resp': resp };
    }, err => {
      // console.log("service err:", err);
      return { 'status': 'error', 'resp': err };
    });

  }


  async sendSms(messageObj: any) {
    messageObj['acc_id'] = sessionStorage.getItem('current_acc_id');
    console.log("messageObj: ", messageObj);
    this.http.post(`${environment.cloudFunctionServerUrl}/sendSmsV2`, messageObj).subscribe(resp => {
      console.log(resp);
    });

  }
  sendEmail(mailObj) {
    // let body = `<p>Hi,</p>
    //       <br><p><b>${link}</b> is One time password (OTP) for your Mktg.doctor account.Please do not share this.If not requested, please inform us.</p>
    //       <br>`
    firebase.functions().httpsCallable('sendEmail')(mailObj).then(resp => {
      console.log("resp:", resp);
    },
      err => {
        console.log("err:", err);
      });
  }

  sendPatientForms(data) {
    data['acc_id'] = `${sessionStorage.getItem('current_acc_id')}`;
    console.log("data: ", data);
    let header = {
      headers: new HttpHeaders({
        // 'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      })
    };
    let body = data;
    console.log(body);
    return firebase.functions()
      .httpsCallable('sendPatientForms')(data).then(resp => {
        // console.log("service resp:", resp);
        return { 'status': 'sent', 'resp': resp };
      }, err => {
        // console.log("service err:", err);
        return { 'status': 'error', 'resp': err };
      });
    // return this.http.post("http://localhost:5001/mktgbot-181017/us-central1/sendPatientForms", { data: body }, header).toPromise();
  }
  /*fetchContact(contactRef : any){
    console.log(contactRef);
    let contact = contactRef.get().then(contactsDocSnapshot=>{
         let contactDoc = contactsDocSnapshot.data();
    });
  }*/

  fetchSMSLogs(contactObj: any) {
    return (firebase.functions().httpsCallable('fetchSmsLogs'))(contactObj);
    /*  .then(
        result => {
      console.log(result);
      contact.sms_logs = result.data;
        /* let dates = [];
       for(let i=0; i<= contact.sms_logs['outgoing_msgs'].length-1; i++){
         let date = (contact.sms_logs['outgoing_msgs'][i].date).toString();
         let dateObj = Date.parse(date);
         dates.push(dateObj);

        }
        console.log(dates);*/
    /*  })
      .catch(error => {
        console.log(error);
        this.alertService.error(error.message);
      });*/
  }

  addNoteForContact(contact_ref: any, noteData: any) {

    let newNote = {
      "note": noteData,
      "date": moment().toDate(),
      "type": 'note'
    }
    contact_ref.contact.collection('notes').add(newNote)
      .then(noteRef => {
        newNote['id'] = noteRef.id;
        this.updateNotes(newNote);
      });
  }

  fetchNotesForContact(contact_ref: any) {
    return contact_ref.collection('notes')
      .orderBy("date", "desc")
      .get();
  }

  fetchFormActivitieForContact(contact_ref: any) {
    return contact_ref.collection('activities')
      .orderBy("date", "desc")
      .get();
  }

  updateEditedNote(contact_ref: any, changedNote: string, id: string) {
    return contact_ref.collection('notes').doc(id).update({ 'note': changedNote });
  }

  importContacts(id: string) {
    return this.http.get(`${environment.importserver}/import-contacts?import_log_id=${id}`);
  }

  async updateContactsToActive(accId: string) {
    firebase.initializeApp({
      apiKey: "AIzaSyCYSZZUmLcaP3Pz6C70A4Uu9kAniE9tWwM",
      authDomain: "mktg-bot2.firebaseapp.com",
      databaseURL: "https://mktg-bot2.firebaseio.com",
      projectId: "mktg-bot2",
      storageBucket: "mktg-bot2.appspot.com",
      messagingSenderId: "1089334441394"
    }, 'prod');

    let contactsDocsSnapshot = await firebase.app('prod').firestore().collection('accounts').doc(accId).collection('contacts')
      .get();

    let unresolvedContacts = [];
    let count = 0;

    contactsDocsSnapshot.forEach(
      contactDoc => {
        let isActive = contactDoc.get('active');
        let contactRef = contactDoc.ref;
        if (isActive === undefined) {
          count += 1;
          unresolvedContacts.push(this.updateContactToActive(contactRef, count));
        }
      });

    let updates = await Promise.all(unresolvedContacts);
  }

  async updateContactToActive(contactRef: any, count: number) {
    let updatedContact = await contactRef.update({ 'active': true });

    if (updatedContact === undefined) {
      console.log(`Contact ${count} updated`);
    }
    else {
      console.error(updatedContact);
    }

    return updatedContact;
  }
  async getFormInfo(contactId) {
    const conatctRef = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).
      collection("contacts").doc(contactId);
    let result = await firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem('current_acc_id')}`).
      collection("form_response").where('contact_ref', '==', conatctRef).get()
    return result
  }

  async getPatientFormDetails(contactId) {
    const conatctRef = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).
      collection("contacts").doc(contactId);
    let result = await firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem('current_acc_id')}`).
      collection("patient_form_details").where('contact_ref', '==', conatctRef).get()
    return result
  }

}
