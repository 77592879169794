import { Component, OnInit } from '@angular/core';
import scrollIntoView from 'scroll-into-view-if-needed';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollToAppointment() {
   console.log('Scrolling...');
  let node = document.getElementById('my-calendar');
  console.log(node);
  try {
    scrollIntoView(node, {
        behavior: 'smooth',
       scrollMode: 'if-needed',
        });
  }
  catch(error) {
    console.error(error);
  }
}

}
