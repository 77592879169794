<div *ngIf="reviews.length>0 " style="overflow: hidden;">
    <div class="reviewDiv" *ngFor="let content of reviews">
        <hr>
        <div class="row" style="padding:0px;">
            <div class="col-1 hidePadding" *ngIf="content!=undefined">
                <img src={{content.reviewer.profilePhotoUrl}} style="height: 50px;width: 50px;">
            </div>
            <div class="col-11 hidePadding" id="authorInfo" style="padding-top: 0%;">
                <div>
                    {{(content['reviewer']['displayName'])}}
                </div><br>
                <div style="margin-top: -1em;">
                    <p-rating [ngModel]="(content['starRating'])" readonly="true" stars="5" [cancel]="false">
                    </p-rating>
                    <b style="padding-top:8px;margin-top: -2em;">{{(content['createTime'].toDate())}} </b>
                </div>
            </div>
        </div>
        <div *ngIf="content['comment']">

            <div class="contentText" [class.show]="content.show" style="padding-top: 10px;">
                {{(content['comment'])}}
            </div>
            <div *ngIf="content.comment.length > 250" style="text-align: center;font-weight: 500;font-size: 16px;">
                <a (click)="showMoreContent(content)" id="readMoreBtn">{{ content.show ? 'Read Less': 'Read
                    More'}}</a>
            </div>
        </div>
        <div class="row" style="padding-bottom: 5px;">
            <div class="col-md-8">
            </div>
            <div class="col-md-2">
                <!-- <p-dropdown [options]="cities" [(ngModel)]="selectedCity1" placeholder="Actions" optionLabel="name"
                    [showClear]="true" autoWidth="false" [style]="{'width':'8rem','height':'2.3rem'}">
                </p-dropdown> -->
            </div>
            <div class="col-md-2">
                <button mat-raised-button>Reply</button>
            </div>
        </div>
        <!-- <hr> -->
    </div>
</div>