<!--Mask-->
<div class="view">
  <div class="container-fluid">
    <div class="row" id="home">
      <div class="col-lg-6">
        <h1 class="h1-responsive wow fadeInLeft" data-wow-delay="0.1s">
           Meet “mktgbot”.
        </h1>
        <h2 class="h2-responsive wow fadeInLeft" data-wow-delay="0.3s">
        Personal digital marketing assistant for businesses just like yours.
        </h2>
        <div class="row">
          <div class="row scrolling-wrapper-flexbox">
<!--            <div class="col">
              <img height="400px" src="../../assets/images/mktgbot-mac-ss.svg">
            </div>-->
            <div class="col">
              <img height="400px" src="../../assets/images/mobile-ss-1.svg">
            </div>
            <div class="col">
              <img height="400px" src="../../assets/images/mobile-ss-3.svg">
            </div>
            <div class="col">
              <img height="400px" src="../../assets/images/mobile-ss-4.svg">
            </div>
            <div class="col">
              <img height="400px" src="../../assets/images/mobile-ss-2.svg">
            </div>
          </div>
        </div>
        <em class="format-center"> Swipe or scroll for more ... </em>
    </div>
      <div class="col-lg-6">
        <p class="wow fadeInRight" data-wow-delay="0.75s">
          Powered with AI and an easy to use interface, mktgbot helps you activate and manage cross channel digital marketing campaigns and maximize ROI.
          <br/> Just like a good financial advisor working hard for better returns on your investments, mktgbot understands the challenges and opportunities for your business giving you the best returns on your marketing investment.
        </p>
        <hr class="hr-dark wow fadeInLeft">
        <h1> YOUR PERSONAL MARKETING ASSISTANT </h1>
        <br>
        <!-- <a class="btn btn-primary btn-lg wow fadeInLeft" data-wow-delay="0.7s" (click)="createUser()">Get Started</a> -->
        <span class=""><button mat-raised-button color="primary" class="large-button" [routerLink]="['/signup']"> Get Started </button></span>
      </div>
    </div>
    <!--<div class="row">
          <div class="col-lg-6">
            <div class="embed-responsive embed-responsive-16by9 wow fadeInRight">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/0dxTwOhkqqk" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-lg-6">
          </div>
        </div>-->
    <hr class="hr-dark">
    <h3 class="format-center"> The mktgbot platform </h3>
    <br />
    <div class="row">
      <div class="col-md-4 col-xs-4 col-lg-4">
        <mat-card class="metric-card">
          <mat-card-title>
            <span class="format-center"><mat-icon class="my-icons">looks_one</mat-icon></span>
          </mat-card-title>
          <mat-card-subtitle>
            <h4 class="format-center"> Performance  </h4>
          </mat-card-subtitle>
          <mat-card-content>
            <p>Shows how your facebook, google, and email campaigns are performing and optimizes them.</p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-4 col-xs-4 col-lg-4">
        <mat-card class="metric-card">
          <mat-card-title>
            <span class="format-center"><mat-icon class="my-icons">looks_two</mat-icon></span>
          </mat-card-title>
          <mat-card-subtitle>
            <h4 class="format-center"> Recommendations  </h4>
          </mat-card-subtitle>
          <mat-card-content>
            <p>Generates tips to maximize campaign performance and new campaign ideas personalized for your business. Powered by the mktgbot AI algo.</p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-4 col-xs-4 col-lg-4">
        <mat-card class="metric-card">
          <mat-card-title>
            <span class="format-center"><mat-icon class="my-icons">looks_3</mat-icon></span>
          </mat-card-title>
          <mat-card-subtitle>
            <h4 class="format-center"> ROI </h4>
          </mat-card-subtitle>
          <mat-card-content>
            <p> Shows you the return on investment by automatically tracing back leads generated and revenue booked to the marketing campaigns.</p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <br />
    <br />
    <div class="row">
      <div class="col-12">
        <p> Everything you always wanted to do in digital marketing, such as, email marketing, paid search, social marketing and SEO, mktgbot will do it for you. And do it very well… Simplify digital marketing and online advertising and get the results you
          have never seen before.</p>

        <h4 class="format-center">Digital marketing is complicated, period.</h4>
        <br />
        <h3 class="format-center"> Simplify your online advertising with mktgbot.</h3>
        <br />
        <span class="format-center"><button mat-raised-button color="primary" class="large-button" [routerLink]="['/signup']"> Signup for a Demo Account</button></span>
      </div>
    </div>
    <hr class="hr-dark">

    <h2 class="format-center"> Data powers digital marketing. </h2>
    <br />
    <h5 class="format-center">How do we use data to find you customers?</h5>

    <div class="row">
      <div class="col-sm col-md-4">
        <p> Facebook and Google are very sophisticated platforms with advanced targeting capabilities. How are you using these platforms to your advantage? mktgbot's algorithms discover the pockets and opportunities that leverage the power of these platforms
          to best benefit your business.</p>
      </div>
      <div class="col-sm col-md-4">
        <p> mktgbot leverages public domain data, for example images from social media, or posts and tweets, and provides refined targting capabilities to reach the ideal customers of your business.</p>
      </div>
      <div class="col-sm col-md-4">
        <p> mktgbot analyzes your data, such as, web analytics, social analytics and data generated from your sales and marketing systems, to further optimize your marketing spend and maximize return on investment.</p>
      </div>
    </div>
  </div>
</div>
