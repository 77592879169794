/*
 * This is the Practice Details component.
 * Should be renamed.
 */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { AccountService } from "../_services/index";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "angularfire2/firestore";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormArray,
  Validators,
} from "@angular/forms";
//import { Location, Appearance} from '@angular-material-extensions/google-maps-autocomplete';
//import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: "app-add-account",
  templateUrl: "./add-account.component.html",
  styleUrls: ["./add-account.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddAccountComponent implements OnInit {
  acc: any;
  fetchedAccountData: any;
  PracticeName: string;
  name: string;
  url: string;
  email: string;
  contactNo: string;
  facebookLink: string;
  instagramLink: string;
  youtubeLink: string;
  // locationAutocomplete = [];
  showRemoveButton: boolean = false;
  showRemoveButtonLocation: boolean = false;
  showDropdown: boolean = false;
  dataRemoved: boolean = false;
  dataRemovedLocation: boolean = false;
  result: any;
  countryCode: string;
  formArray = [];
  arrayInputs = [];
  arrayLocation = [];
  reference = [];
  calendarsSlug = []; //
  slugWithReference = [];
  showSlug: boolean = false;
  showEnableCalendar: boolean = true;
  objProviders = {};
  // configureNewCal:boolean;

  docForm = this.fb.group({
    name: ["", Validators.required],
    primaryName: "",
    primaryEmailId: "",
    primaryPhone: [""],
    url: ["", Validators.required],
    facebookPageLink: "",
    instagramLink: "",
    youtubeLink: "",
    locationArray: this.fb.array([]),
    doctorInformation: this.fb.array([]),
  });

  config: any = {
    //appearance: Appearance.OUTLINE,
    placeholderText: "Please enter the address",
    country: "",
    requiredErrorText: "The input is required",
    invalidErrorText: "The address is not valid",
  };

  constructor(
    private navigationService: NavigationService,

    public afs: AngularFirestore,
    private accountService: AccountService,
    private router: Router,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    //sub-header
    this.navigationService.updateState(
      new NavigationState(false, "Practice Settings", "", "#", "/add-account")
    );
    // ------------------
    this.acc = this.accountService.getCurrentAccDetails();

    this.accountService.fetchAccountData().then(
      (result) => {
        this.fetchedAccountData = result;
        this.PracticeName = this.fetchedAccountData.name;
        if ("primaryContact" in this.fetchedAccountData) {
          this.name = this.fetchedAccountData.primaryContact.name;
          // this.docForm.patchValue({name:this.PracticeName});
          this.email = this.fetchedAccountData.primaryContact.email;
          this.contactNo = this.fetchedAccountData.primaryContact.contactNo;
        } else {
          this.email = "";
          this.contactNo = "";
          this.name = "";
        }
        if ("url" in this.fetchedAccountData) {
          this.url = this.fetchedAccountData.url;
        } else {
          this.url = "";
        }
        if ("socialMediaLinks" in this.fetchedAccountData) {
          this.facebookLink = this.fetchedAccountData.socialMediaLinks.facebookLink;
          this.instagramLink = this.fetchedAccountData.socialMediaLinks.instagramLink;
          this.youtubeLink = this.fetchedAccountData.socialMediaLinks.youtubeLink;
        } else {
          this.facebookLink = "";
          this.instagramLink = "";
          this.youtubeLink = "";
        }
        if ("countryCode" in this.fetchedAccountData) {
          this.countryCode = this.fetchedAccountData.countryCode;
        } else {
          this.countryCode = "";
        }
        // Upload prepopulates values
        this.docForm.patchValue({
          name: this.PracticeName,
          primaryName: this.name,
          primaryEmailId: this.email,
          primaryPhone: this.contactNo,
          url: this.url,
          facebookPageLink: this.facebookLink,
          instagramLink: this.instagramLink,
          youtubeLink: this.youtubeLink,
          // location:[this.locationAutocomplete,Validators.required],
          // locationArray: this.fb.array([]),
          // doctorInformation: this.fb.array([]),
        });
        // Upload prepopulates values for staff and updating array
        if ("providerInfo" in this.fetchedAccountData) {
          if (this.fetchedAccountData.providerInfo.length > 0) {
            let len = this.fetchedAccountData.providerInfo.length;
            for (let i = 0; i < len; i++) {
              if ("calendar_link" in this.fetchedAccountData.providerInfo[i]) {
                this.objProviders = {
                  name: [this.fetchedAccountData.providerInfo[i].name],
                  email: [
                    this.fetchedAccountData.providerInfo[i].email,
                    [Validators.email],
                  ],
                  phone: [this.fetchedAccountData.providerInfo[i].phone],
                  speciality: [
                    this.fetchedAccountData.providerInfo[i].speciality,
                  ],
                  linked_calendar: this.fetchedAccountData.providerInfo[i]
                    .linked_calendar,
                  providerId: [
                    this.fetchedAccountData.providerInfo[i].providerId,
                  ],
                  providerRef: [
                    this.fetchedAccountData.providerInfo[i].providerRef,
                  ],
                  slugArray: [this.calendarsSlug], //[this.fetchedAccountData.providerInfo[i].slug],                                  // all calender slug from calendar collection required for dropdown
                  calendar_link: [
                    this.fetchedAccountData.providerInfo[i].calendar_link,
                  ],
                  slug: [
                    this.fetchedAccountData.providerInfo[i].calendar_link,
                  ].filter((calendar) => calendar.slug),
                  configureNewCal: undefined,
                };
              } else {
                this.objProviders = {
                  name: [this.fetchedAccountData.providerInfo[i].name],
                  email: [
                    this.fetchedAccountData.providerInfo[i].email,
                    [Validators.email],
                  ],
                  phone: [this.fetchedAccountData.providerInfo[i].phone],
                  speciality: [
                    this.fetchedAccountData.providerInfo[i].speciality,
                  ],
                  linked_calendar: this.fetchedAccountData.providerInfo[i]
                    .linked_calendar,
                  providerId: [
                    this.fetchedAccountData.providerInfo[i].providerId,
                  ],
                  providerRef: [
                    this.fetchedAccountData.providerInfo[i].providerRef,
                  ],
                  slugArray: [this.calendarsSlug], //[this.fetchedAccountData.providerInfo[i].slug],                                  // all calender slug from calendar collection required for dropdown
                  calendar_link: [
                    this.fetchedAccountData.providerInfo[i].calendar_link,
                  ],
                  slug: "", //[this.fetchedAccountData.providerInfo[i].calendar_link].filter(calendar => calendar.slug),
                  configureNewCal: undefined,
                };
              }
              this.arrayInputs.push(this.objProviders);
            }
            this.setArrayInputs(this.arrayInputs);
          } else {
            this.arrayInputs = [
              {
                name: [""],
                email: ["", [Validators.email]],
                phone: [""],
                speciality: "",
                linked_calendar: false,
                providerId: "",
                providerRef: "",
                slugArray: "",
                slug: "",
                calendar_link: [],
                configureNewCal: undefined,
              },
            ];
            this.setArrayInputs(this.arrayInputs);
          }
        } else {
          this.arrayInputs = [
            {
              name: [""],
              email: ["", [Validators.email]],
              phone: [""],
              speciality: "",
              linked_calendar: false,
              providerId: "",
              providerRef: "",
              slugArray: "",
              slug: "",
              calendar_link: [],
              configureNewCal: undefined,
            },
          ];
          this.setArrayInputs(this.arrayInputs);
        }
        if ("location" in this.fetchedAccountData) {
          if (typeof this.fetchedAccountData.location === "string") {
            this.fetchedAccountData.location = [
              { locationName: this.fetchedAccountData.location },
            ];
          }
          if (
            this.fetchedAccountData.location.length > 0 &&
            this.fetchedAccountData.location !== null
          ) {
            let len = this.fetchedAccountData.location.length;
            for (let i = 0; i < len; i++)
              if (this.fetchedAccountData.location[i] !== null) {
                this.arrayLocation.push({
                  locationName: [
                    this.fetchedAccountData.location[i].locationName,
                    [Validators.required],
                  ],
                });
              }
            this.setArrayInputsLocation(this.arrayLocation);
          } else if (this.fetchedAccountData.location !== null) {
            this.arrayLocation = [{ locationName: ["", Validators.required] }];
            this.setArrayInputsLocation(this.arrayLocation);
          }
        } else {
          this.arrayLocation = [{ locationName: ["", Validators.required] }];
          this.setArrayInputsLocation(this.arrayLocation);
        }
        console.log(this.docForm.value, this.docForm.valid);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  setArrayInputsLocation(arrayInp) {
    const arrayLC = arrayInp.map((address) => this.fb.group(address));
    const formArray = this.fb.array(arrayLC);
    this.docForm.setControl("locationArray", formArray);
  }

  setArrayInputs(arrayInputs) {
    const arrayFG = arrayInputs.map((address) => this.fb.group(address));
    const formArray = this.fb.array(arrayFG);
    this.docForm.setControl("doctorInformation", formArray);
  }

  addLocation() {
    (this.docForm.get("locationArray") as FormArray).push(
      this.fb.group({
        locationName: "",
      })
    );
    this.showRemoveButtonLocation = true;
  }

  removeLocation(index) {
    this.showRemoveButtonLocation = false;
    this.fetchedAccountData.location.splice(index, 1);
    this.docForm.controls.locationArray["controls"].splice(index, 1);
    this.dataRemovedLocation = true;
  }

  addInput() {
    this.showSlug = false;
    this.showRemoveButton = true;
    (this.docForm.get("doctorInformation") as FormArray).push(
      this.fb.group({
        name: "",
        email: "",
        phone: "",
        speciality: "",
        linked_calendar: ["", Validators.required],
        providerId: "",
        providerRef: "",
        slugArray: ["", Validators.required],
        slug: "",
        calendar_link: "",
        configureNewCal: undefined,
      })
    );
  }

  removeInput(index) {
    this.showRemoveButton = false;
    if (this.fetchedAccountData.providerInfo[index] !== undefined) {
      let removeDocRef = this.fetchedAccountData.providerInfo[index].providerId;
      this.accountService.accremoveDocument(removeDocRef);
      this.fetchedAccountData.providerInfo.splice(index, 1);
      this.docForm.controls.doctorInformation["controls"].splice(index, 1);
    } else {
      this.fetchedAccountData.providerInfo.splice(index, 1);
      this.docForm.controls.doctorInformation["controls"].splice(index, 1);
    }
    this.dataRemoved = true;
  }

  // This updateAccountInfo() function create object of all values which are getting from FORM and store into database
  updateAccountInfo() {
    this.result = this.docForm.value;
    console.log(this.result);
    // let slug = `${this.result.name}`.toLowerCase();
    // if(this.dataRemoved == true){
    //     this.result.doctorInformation = this.fetchedAccountData.staff;          // if data removed then update latest array
    // let slug = `${this.result.name}`.replace(/[\s+/+_]/g, '-').toLowerCase();
    let slug = `${this.result.name}`
      .replace(/(\.\s|\s|\.|_)/g, "-")
      .toLowerCase();
    if (this.dataRemoved === true) {
      this.result.doctorInformation = this.fetchedAccountData.providerInfo; // if data removed then update latest array
      this.dataRemoved = false;
    }
    if (this.dataRemovedLocation === true) {
      this.result.locationArray = this.fetchedAccountData.location;
      this.dataRemovedLocation = false;
    }
    let finalData = {
      isDemo: false,
      account_slug: slug,
      name: this.result.name,
      form_settings: {
        email: this.result.primaryEmailId,
        hubspot_form_id: "",
        hubspot_id: "",
      },
      primaryContact: {
        name: this.result.primaryName,
        email: this.result.primaryEmailId,
        contactNo: this.result.primaryPhone,
      },
      url: this.result.url,
      socialMediaLinks: {
        facebookLink: this.result.facebookPageLink,
        instagramLink: this.result.instagramLink,
        youtubeLink: this.result.youtubeLink,
      },
      location: this.result.locationArray,
      countryCode: this.countryCode,
    };

    let providersDetails = {
      staff: this.result.doctorInformation,
    };

    this.accountService
      .updateAccountInformation(finalData, providersDetails)
      .then(
        (response) => {
          this.snackBar.open("Account information updated!", "", {
            duration: 4000,
            verticalPosition: "top",
            panelClass: ["red-snackbar"],
          });
          this.router.navigateByUrl("/websites");
        },
        (error) => {
          console.log(error);
        }
      );
  }

  removeSlug() {
    this.showSlug = false;
    this.showDropdown = false;
  }

  addProviderForAccount(event, index) {
    let providersDetails = this.docForm.controls.doctorInformation["controls"][
      index
    ].value;
    let providersInfo = {
      name: providersDetails.name,
      email: providersDetails.email,
      phone: providersDetails.phone,
      speciality: providersDetails.speciality,
    };
    let provId = providersDetails.providerId;
    if (provId === "") {
      providersInfo["linked_calendar"] = providersDetails.configureNewCal;
      providersInfo["calendar_link"] = [];
      this.accountService.addProvider(providersInfo).then((docRef) => {
        let docId = docRef.id;
        this.docForm.controls.doctorInformation["controls"][index][
          "controls"
        ].providerId.patchValue(docId);
        this.docForm.controls.doctorInformation["controls"][index][
          "controls"
        ].providerRef.patchValue(docRef);
        if (providersDetails.configureNewCal === "true") {
          this.router.navigate(["/configure-calendar"], {
            queryParams: { id: docId },
          });
        }
      });
    } else {
      let docId = providersDetails.providerId;
      if (
        providersDetails.linked_calendar === "false" &&
        providersDetails.configureNewCal === "true"
      ) {
        providersInfo["linked_calendar"] = true;
      }
      this.accountService
        .updateProvider(provId, providersInfo)
        .then((docRef) => {
          if (providersDetails.configureNewCal === "true") {
            this.router.navigate(["/configure-calendar"], {
              queryParams: { id: docId },
            });
          }
        });
    }
  }

  changeCalendar(newValue, index) {
    let result = this.slugWithReference.find((i) => i["slug"] === newValue);
    let ref = [];
    ref.push(result["ref"]);
    this.docForm.value.doctorInformation[index].calendar_link = ref; // Storing ref to particular provider
    this.docForm.value.doctorInformation[index].slug = result["slug"];
  }

  /*
  onAutocompleteSelected(result: PlaceResult) {
    if (result.address_components) {
      for (let i = 0; i < result.address_components.length; i++) {
        if ("country" == result.address_components[i].types[0]) {
          this.countryCode = result.address_components[i].short_name; //Return CountryCode from placeresult.
        }
      }
    }
    this.arrayLocation.push({ locationName: [result.formatted_address] });
    const arrayFG = this.arrayLocation.map((address) => this.fb.group(address));
    const formArray = this.fb.array(arrayFG);
    this.docForm.value.locationArray = formArray.value; // Update docForm at locationArray
  }
  */

  /*
  onLocationSelected(location: Location) {
    console.log("onLocationSelected: ", location);
  }
  */
}
