<div id="inputId">
    <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input type="text" pInputText [(ngModel)]="inputText" (ngModelChange)="fetchContactByPagingAlgolia()"
            placeholder="Name or phone number or email" />
    </span>
</div>
<br>
<div id="inputId" *ngIf="!loading && !noRecordFound">
    <div id="content" *ngFor="let content of algoliaContactList;let i=index" class="row"
        [class.rowActive]="isSelectedStatus[i]['isSelected']" (click)="selectContact(i,content)"
        style="cursor: pointer;">
        <!-- <div class="row" [class.rowActive]="isSelectedStatus[i]['isSelected']"  (click)="selectContact(i,content)"> -->
        <div class="col-md-1">
            <mat-checkbox class="checkBox" [checked]="isSelectedStatus[i]['isSelected']"></mat-checkbox>
        </div>
        <div class="col-md-3">
            {{content['first_name']}}&nbsp;{{content['last_name']}}
        </div>
        <div class="col-md-4">
            {{content['full_phone']}}
        </div>
        <div class="col-md-4">
            {{content['email']}}
        </div>
        <!-- </div> -->
    </div>
</div>
<div id="inputId" *ngIf="noRecordFound">
    <div style="text-align: center;">
        <b>No Record Found</b>
    </div>
</div>

<div id="inputId" *ngIf="loading && !noRecordFound">
    <div style="text-align: center;">
        <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
    </div>
</div>
<br>
<!-- <div style="text-align: right;">
    <button mat-raised-button color="primary" (click)="Next()" [disabled]="selectedContacts.length==0">Next</button>
</div> -->