import { Component, OnInit } from "@angular/core";
import { ConfigurationService } from "../configuration.service";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import * as moment from "moment";
import { Time } from "@angular/common";
import { AccountService } from "../_services/account.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgbDatepickerConfig, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { COUNTRY_CODES } from '../_constants/country_phone_codes';
import firebase from 'firebase';

@Component({
  selector: "app-calender-config",
  templateUrl: "./calender-config.component.html",
  styleUrls: ["./calender-config.component.scss"],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CalenderConfigComponent implements OnInit {
  // Rule#1 define your datamodel as private so no one can change it.
  // Define public methods so others can change the values.
  config: any;
  selection: string;

  start_date: Date = new Date();
  end_date: Date = new Date();
  paymentState: boolean = false;

  customOption: string = 'customOption';

  dataPickerState: boolean = false;
  getPaymentUrl: string = "";
  picker1: Date;

  currencyCode: string = "";
  paymentAmount: string = "";
  paymentTimeout: any;
  displayLink: string = "";
  redirectLink: string = "";
  redirectLinkText: string = "";



  amount: string = "";
  // paymentTimeout :number = 0;

  slug: string;
  address: string;
  map_link: string;
  phone: number;
  name: string;
  TempArray: any = [];
  length_of_array: number;
  ontouch: boolean = true;
  ref: any;
  input: string;
  email: string;
  block_date: any;
  iden: number;
  flag: number = 0;
  address_status: boolean;
  terms_conditions: boolean = false;
  payments: boolean = false;
  terms_and_conditions_info: string;
  payment_info: string;
  checkboxOptions: any = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  SelectDays: any = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  selectCalender = ["dental-teeth-whitening-calendar", "dental-calendar"];
  calenderName: string;
  toggleDays: any = [
    { selected: true, day: "0" },
    { selected: true, day: "1" },
    { selected: true, day: "2" },
    { selected: true, day: "3" },
    { selected: true, day: "4" },
    { selected: true, day: "5" },
    { selected: true, day: "6" },
  ];
  timezones: any = [
    "Chicago (UTC−06:00/UTC−05:00)",
    "India (UTC+05:30)",
    "Los_Angeles (UTC-08:00/UTC-07:00)",
    "New York (UTC-04:00/UTC-05:00)",
  ];
  tzSelected: any = [
    { name: "Chicago (UTC−06:00/UTC−05:00)", tzname: "America/Chicago" },
    { name: "India (UTC+05:30)", tzname: "Asia/Kolkata" },
    {
      name: "Los_Angeles (UTC-08:00/UTC-07:00)",
      tzname: "America/Los_Angeles",
    },
    { name: "New York (UTC-04:00/UTC-05:00)", tzname: "America/New_York" },
  ];
  selectedTimezone: string;
  NewArray: any = [];
  selectedDays: any = [];
  provided: any = [];
  Blocked_Date = [];
  temp: String;
  enable: boolean;
  service_enable: boolean;
  block_enable: boolean;
  TimeSlot: any = [];
  Slot: any = [];
  day: number = 0;
  start: string;
  message: string;
  date: Date;
  form_fields_value: any;
  end: string;
  payment_amount: string;
  calenders: any = [];
  Reference: any = [];
  selectedDate: any;
  lengthOfTimeslots: number;
  minDate: any;
  address_details: any;
  maxDate: any;
  myFilter: any;
  timeslots: any;
  show_message: boolean;
  show_address: boolean;
  title: string = "";
  AccountName: string;
  showMapInfo: boolean = false;
  todaysDate: string = new Date().toLocaleDateString();
  iconStatus: boolean = false;
  CalendarState: boolean = true;
  fromDate: any;
  toDate: any;
  events: string[] = [];
  disabledDates: any = [];
  provided_start: any = [];
  Blocked_Date_first: any = [];
  filterDays_first: any = [];
  min: any;
  max: any;

  termsAndCondtions: boolean;
  // paymentsInfo:boolean;
  terms_and_condition_info: string;
  payments_info: string;
  message_field: string;
  start_first: Time;
  end_first: Time;
  maxAamount: string;
  minAamount: string;
  calenderName_first: string;
  selectedTimezone_first: string;
  service_enable_first: boolean;
  getAllCalendar = [];
  getAllCalendarName: any = [];
  NewString: string = "";
  selectedCalenderName: string;
  msg_title: string;

  //disabledSave:boolean;

  disableSave: boolean = false;
  disableField: boolean = false;
  validationLeadRolling = false;
  alert_service: any;
  AlterCalendarName: string;
  CalenderNameList: any = [];

  constructor(
    private navigationService: NavigationService,

    private configurationService: ConfigurationService,
    private accountService: AccountService,
    private router: Router,

    // what do these services do? and why are they defined as public?
    public calendar: NgbCalendar,
    public config1: NgbDatepickerConfig,
    private _snackBar: MatSnackBar
  ) {
    // On Account Change, navigate to Websites.
    this.accountService.accChange$.subscribe((accChange) => {
      this.router.navigateByUrl("/websites");
    });
  }

  ngOnInit() {
    //sub-header
    this.navigationService.updateState(
      new NavigationState(
        false,
        "Configure Calendar",
        "",
        "#",
        "/configure-calendar"
      )
    );
    // ------------------
    this.AccountName = this.accountService.getCurrentAccDetails().name;

    this.configurationService.getAccount().then((accountsSnapshot) => {
      if (!accountsSnapshot.empty) {
        accountsSnapshot.forEach((docSnapshot) => {
          let doc = docSnapshot.data();
          this.ref = docSnapshot.ref;
          this.Reference.push(this.ref);
          this.calenders.push(doc);
        });
        this.getAllCalendar = this.calenders;
        this.config = this.calenders[0];

        if (this.config['paymentDetails'] != undefined) {
          this.currencyCode = this.config['paymentDetails']['currency_code'];
          this.paymentAmount = this.config['paymentDetails']['amount'];
          this.paymentTimeout = Math.floor((parseInt(this.config['paymentDetails']['paymentTimeoutInSeconds'])) / 60);
          this.paymentState = this.config.payments;
        }

        if (this.config['redirectDetails'] != undefined) {
          this.displayLink = this.config['redirectDetails']['displayLink'];
          this.redirectLink = this.config['redirectDetails']['redirectLink'];
          this.redirectLinkText = this.config['redirectDetails']['redirectLinkText'];
        }

        if (this.config['timeLimit'] != undefined) {
          this.dataPickerState = this.config['timeLimit']['enabled'];
          this.start_date = new Date((this.config['timeLimit']['startDate']).toDate());
          this.end_date = new Date((this.config['timeLimit']['endDate']).toDate());
          console.log(this.start_date);
          console.log(this.end_date);
        }
        else {
          this.dataPickerState = false;
        }

        console.log(this.config);
        this.minDate = moment().add(
          this.config["minDate"]["key"],
          this.config["minDate"]["amount"]
        );

        this.maxDate = moment().add(
          this.config["maxDate"]["key"],
          this.config["maxDate"]["amount"]
        );
        this.myFilter = (d: Date): boolean => {
          const day = moment(d).day();
          return (
            !this.config["filterDays"].includes(day) &&
            !this.config["blocked_dates"].includes(moment(d).format("Y-MM-DD"))
          );
        };
        this.timeslots = this.config["timeslots"][this.day];

        this.check();

        this.ref = this.Reference[0];
      }
      if (this.config !== undefined) {
        if (this.config.form_fields) {
          this.show_address = this.config.form_fields.address;
          this.show_message = this.config.form_fields.message.show;
          if (this.config.form_fields.message.title !== "") {
            this.title = this.config.form_fields.message.title;
          }
        } else {
          this.show_address = false;
          this.show_message = false;
          this.title = "";
        }
      }
    });
  }

  saveFormFields() {
    // this.form_fields_value = {
    //   address: this.show_address,
    //   message: {
    //     show: this.show_message,
    //     title: this.title,
    //   },
    // };
  }

  savePaymentInformation() {

    console.log(this.config);

    this.config.payments = this.paymentState;

    if (this.currencyCode != undefined && this.paymentAmount != undefined && this.paymentAmount != undefined &&
      this.paymentState != undefined && this.redirectLinkText != undefined && this.redirectLink != undefined &&
      this.displayLink != undefined && this.dataPickerState != undefined && this.start_date != undefined && this.end_date != undefined) {
      if (this.config['paymentDetails'] != undefined) {
        this.config['paymentDetails']['currency_code'] = this.currencyCode;

        this.config['paymentDetails']['amount'] = this.paymentAmount;
        this.config['paymentDetails']['paymentTimeoutInSeconds'] = this.paymentTimeout * 60;

        this.config.payments = this.paymentState;
      }


      if (this.config['redirectDetails'] != undefined) {
        this.config['redirectDetails']['displayLink'] = this.displayLink;
        this.config['redirectDetails']['redirectLink'] = this.redirectLink;
        this.config['redirectDetails']['redirectLinkText'] = this.redirectLinkText;
      }


      if (this.config['timeLimit'] != undefined) {
        console.log(new Date(this.start_date));
        this.config['timeLimit']['enabled'] = this.dataPickerState;
        this.config['timeLimit']['startDate'] = firebase.firestore.Timestamp.fromDate(new Date(this.start_date));
        this.config['timeLimit']['endDate'] = firebase.firestore.Timestamp.fromDate(new Date(this.end_date));
      }

    }

  }

  save() {
    console.log("-in save--");
    this.NewArray = this.toggleDays;
    this.config.filterDays = [];
    for (var i = 0; i < this.NewArray.length; i++) {
      if (this.NewArray[i].selected == false) {
        this.config.filterDays.push(this.NewArray[i].day);
      }
    }
  }

  slugValidation() {
    var count = 1;
    this.disableSave = false;
    for (var i = 0; i < this.calenders.length; i++) {
      if (this.calenderName == this.calenders[i].slug) {
        count++;
      }
    }
    //console.log(count);
    if (count >= 2) {
      this._snackBar.open(`'${this.calenderName}' already exists!`, "", {
        duration: 5000,
        verticalPosition: "top",
        panelClass: "mat-snack-bar-container-error",
      });
      this.disableSave = true;
    } else {
      this.disableSave = false;

      /*  for( var i = 0; i < this.calenders.length; i++)
        {
           if ( this.calenders[i].slug== this.AlterCalendarName)
           {
             this.calenders[i].slug=this.calenderName;
             break;
           }
        }*/
      console.log("+++++++++++++++++");

      console.log(this.calenders);
    }
  }
  check() {
    var newStartTime, newEndTime;
    var NewArrayHour = [];
    var NewArray = [];
    var checkTimeslot = [];
    var results;
    var arr = [];
    var count = 0;
    this.disableField = false;
    this.toggleDays = [
      { selected: true, day: 0 },
      { selected: true, day: 1 },
      { selected: true, day: 2 },
      { selected: true, day: 3 },
      { selected: true, day: 4 },
      { selected: true, day: 5 },
      { selected: true, day: 6 },
    ];

    for (var i = 0; i < this.config.filterDays.length; i++) {
      this.toggleDays[this.config.filterDays[i]].selected = false;
    }
    for (var i = 0; i < this.toggleDays.length; i++) {
      if (this.toggleDays[i].selected == false) {
        count++;
      }
    }
    if (count == 7) {
      this.disableField = true;
    }

    this.disabledDates = [];
    this.min = this.minDate.toDate();
    this.max = this.maxDate.toDate();
    this.selectedTimezone = this.config.timezone;

    this.calenderName = this.config.slug;
    this.selectedCalenderName = this.config.slug;
    this.AlterCalendarName = this.calenderName;
    this.enable = this.config.services.enabled;
    this.provided = this.config.services.provided;
    this.Blocked_Date = this.config.blocked_dates;
    this.TimeSlot = this.config.timeslots;

    if (this.config.timeslots == undefined) {
      this.start = this.config.timeslots[this.day].slots[0].start;
      this.end = this.config.timeslots[this.day].slots[0].end;
    }

    if (this.config['paymentDetails'] != undefined) {
      this.currencyCode = this.config['paymentDetails']['currency_code'];
      this.paymentAmount = this.config['paymentDetails']['amount'];
      this.paymentTimeout = Math.floor((parseInt(this.config['paymentDetails']['paymentTimeoutInSeconds'])) / 60);
      this.paymentState = this.config.payments;
    }

    if (this.config['redirectDetails'] != undefined) {
      this.displayLink = this.config['redirectDetails']['displayLink'];
      this.redirectLink = this.config['redirectDetails']['redirectLink'];
      this.redirectLinkText = this.config['redirectDetails']['redirectLinkText'];
    }

    if (this.config['timeLimit'] != undefined) {
      this.dataPickerState = this.config['timeLimit']['enabled'];
      this.start_date = new Date((this.config['timeLimit']['startDate']).toDate());
      this.end_date = new Date((this.config['timeLimit']['endDate']).toDate());
      console.log(this.start_date);
      console.log(this.end_date);
    }
    else {
      this.dataPickerState = false;
    }

    this.Slot = this.config.timeslots[this.day].slots;
    this.service_enable = this.config.services.enabled;
    this.config.timeslots[this.day].slots = this.SortTimeSlot();

    this.myFilter = (d: Date): boolean => {
      const day = moment(d).day();
      return (
        !this.config["filterDays"].includes(day) &&
        !this.config["blocked_dates"].includes(moment(d).format("Y-MM-DD"))
      );
    };
  }

  ResetChanges() {
    this.ref.get().then((calendarSnapshot) => {
      this.calenders = [];
      this.config = calendarSnapshot.data();
      this.minDate = moment().add(
        this.config["minDate"]["key"],
        this.config["minDate"]["amount"]
      );

      this.maxDate = moment().add(
        this.config["maxDate"]["key"],
        this.config["maxDate"]["amount"]
      );
      this.myFilter = (d: Date): boolean => {
        const day = moment(d).day();
        // Filter out certain days.
        return (
          !this.config["filterDays"].includes(day) &&
          !this.config["blocked_dates"].includes(moment(d).format("Y-MM-DD"))
        );
      };
      this.timeslots = this.config["timeslots"][this.day];
      this.calenders = this.getAllCalendar;
      this.disableSave = false;
      this.validationLeadRolling = false;
      this.check();
      this._snackBar.open("Calendar reset!", "", {
        duration: 2000,
        verticalPosition: "top",
        panelClass: "mat-snack-bar-container",
      });
    });

    if (this.config['paymentDetails'] != undefined) {
      this.currencyCode = this.config['paymentDetails']['currency_code'];
      this.paymentAmount = this.config['paymentDetails']['amount'];
      this.paymentTimeout = Math.floor((parseInt(this.config['paymentDetails']['paymentTimeoutInSeconds'])) / 60);
      this.paymentState = this.config.payments;
    }

    if (this.config['redirectDetails'] != undefined) {
      this.displayLink = this.config['redirectDetails']['displayLink'];
      this.redirectLink = this.config['redirectDetails']['redirectLink'];
      this.redirectLinkText = this.config['redirectDetails']['redirectLinkText'];
    }

    if (this.config['timeLimit'] != undefined) {
      this.dataPickerState = this.config['timeLimit']['enabled'];
      this.start_date = new Date((this.config['timeLimit']['startDate']).toDate());
      this.end_date = new Date((this.config['timeLimit']['endDate']).toDate());
      console.log(this.start_date);
      console.log(this.end_date);
    }
    else {
      this.dataPickerState = false;
    }
  }

  ShowMap() {
    this.showMapInfo = true;
    this.iconStatus = true;
    return this.iconStatus;
  }

  ShowDate(event) {
    this.block_date = event;
  }

  getCalenderName() {
    for (var i = 0; i < this.calenders.length; i++) {
      if (this.calenders[i].slug === this.selectedCalenderName) {
        console.log(this.selectedCalenderName);
        this.config = this.calenders[i];
        console.log(this.config);

        if (this.config.form_fields !== undefined) {
          this.show_address = this.config.form_fields.address;
          this.show_message = this.config.form_fields.message.show;
          // this.title = this.config.form_fields.message.title;
          if (this.config.form_fields.message.title !== "") {
            this.title = this.config.form_fields.message.title;
          }
        } else {
          this.show_address = false;
          this.show_message = false;
          this.title = "";
        }

        this.ref = this.Reference[i];
        //this.calenders=this.getAllCalendar;
        this.disableSave = false;
        this.minDate = moment().add(
          this.config["minDate"]["key"],
          this.config["minDate"]["amount"]
        );

        this.maxDate = moment().add(
          this.config["maxDate"]["key"],
          this.config["maxDate"]["amount"]
        );
        this.myFilter = (d: Date): boolean => {
          const day = moment(d).day();
          // Filter out certain days.
          return (
            !this.config["filterDays"].includes(day) &&
            !this.config["blocked_dates"].includes(moment(d).format("Y-MM-DD"))
          );
        };
        this.timeslots = this.config["timeslots"][this.day];
        this.validationLeadRolling = false;
        this.check();
        break;
      }
    }
  }
  service() {
    this.TempArray = this.provided;
    this.flag = 0;
    for (var i = 0; i < this.TempArray.length; i++) {
      if (this.TempArray[i] == this.input) {
        this.flag = 1;
        break;
      }
    }
    if (this.flag == 0 && this.input != undefined && this.input != "")
      this.provided.push(this.input);
    if (this.input == undefined) {
      this._snackBar.open("Cannot add blank service", "", {
        duration: 2000,
        verticalPosition: "top",
        panelClass: "mat-snack-bar-container-error",
      });
    }
    if (this.flag == 1) {
      //window.alert("Service " + this.input + " already present");
      this._snackBar.open("Service " + this.input + " already present", "", {
        duration: 2000,
        verticalPosition: "top",
        panelClass: "mat-snack-bar-container-error",
      });
    }
    console.log(this.provided);
    if (
      this.provided.length >= 2 &&
      this.config.services.provided.length >= 2
    ) {
      this.enable = false;
    } else {
      this.enable = true;
    }
    console.log(this.service_enable);

    this.config.services.provided = this.provided;
  }
  changedServiceEnabled(event) {
    console.log(event);
    this.config.services.enabled = event.checked;
  }

  Remove_All_services() {
    this.provided = [];
    this.config.services.provided = [];
    this.enable = true;
    this.service_enable = true;
  }

  Blocked_Dates_Function() {
    this.TempArray = this.Blocked_Date;

    this.flag = 0;
    //this.temp=this.block_date.toLocaleDateString();
    this.temp = moment(new Date(this.block_date)).format("Y-MM-DD");

    for (var i = 0; i < this.TempArray.length; i++) {
      if (this.TempArray[i] == this.temp) {
        this.flag = 1;
        break;
      }
    }
    if (this.flag == 0 && this.temp != undefined && this.temp != undefined) {
      //this.Blocked_Date.push(this.temp);
      this.config.blocked_dates.push(this.temp);
    }

    if (this.temp == undefined)
      window.alert("Blocked date should not be blank");
    if (this.flag == 1) window.alert("Date " + this.temp + " already present");
    if (this.Blocked_Date.length >= 2 || this.config.blocked_dates.length >= 2)
      this.block_enable = false;
    this.changeCalenderConstarint("myFilter");
  }

  changeTimezone() {
    /*for (var i = 0; i < this.tzSelected.length; i++)
    {
      if(this.selectedTimezone==this.tzSelected[i].name)
      {
        this.config.timezone=this.tzSelected[i].tzname;
        return;
      }
    }*/
    this.config.timezone = this.selectedTimezone;
    //console.log(this.config.timezone);
  }

  changeTimeslot() {
    this.config.timeslots[this.day].slots = this.SortTimeSlot();
    if (
      this.config.timeslots[this.day].slots !== undefined &&
      this.config.timeslots[this.day].slots.length >= 0
    ) {
      this.start = this.config.timeslots[this.day].slots[0].start;
      this.end = this.config.timeslots[this.day].slots[0].end;
    }
    this.timeslots = [];
    this.timeslots = this.config.timeslots[this.day];
  }

  SortTimeSlot() {
    this.config.timeslots[this.day].slots.sort((a, b) => {
      let startA = moment(a.start, "h:mm a");
      let startB = moment(b.start, "h:mm a");

      if (startA.isBefore(startB, "minute")) {
        return -1;
      } else if (startA.isSame(startB, "minute")) {
        return 0;
      } else {
        return 1;
      }
    });

    return this.config.timeslots[this.day].slots;
    /*
    var newStartTime,newEndTime;
   var NewArrayHour=[];
   var NewArray=[];
   var checkTimeslot=[];
   var results;
   var arr=[];
    for(var i=0;i<this.config.timeslots[this.day].slots.length;i++)
              {
                let checkTimeslot={
                      start: moment(this.config.timeslots[this.day].slots[i].start,"h:mm a"),
                      end: moment(this.config.timeslots[this.day].slots[i].end,"h:mm a")
                    };
                NewArray.push(checkTimeslot.start);
                //NewArrayHour.push(checkTimeslot.end);

              }
              var Temp=[];
              NewArray=NewArray.sort();
              for(var i=0;i<NewArray.length;i++)
              {

                  for(var j=0;j<NewArray.length;j++)
                  {
                      newStartTime=this.config.timeslots[this.day].slots[j].start;
                      var newStr1 = newStartTime.substr(0, newStartTime.length-3);
                      newEndTime=NewArray[i].format("h:mm a");
                      var newStr2 = newEndTime.substr(0, newEndTime.length-3);
                      //console.log(newStr1,newStr2);
                      if(newStr1===newStr2)
                      {
                          Temp.push({"end":this.config.timeslots[this.day].slots[j].end,"start":this.config.timeslots[this.day].slots[j].start});
                           break;
                      }
                  }
              }
              this.config.timeslots[this.day].slots=[];
              this.config.timeslots[this.day].slots=Temp;
              return this.config.timeslots[this.day].slots;
      */
  }
  Add_Time_Slot() {
    this.flag = 0;
    let newTimeslot = {
      start: moment(this.start, "h:mm a"),
      end: moment(this.end, "h:mm a"),
    };

    // Change this code to also work when there is no slots array.
    for (var i = 0; i < this.config.timeslots[this.day].slots.length; i++) {
      let checkTimeslot = {
        start: moment(this.config.timeslots[this.day].slots[i].start, "h:mm a"),
        end: moment(this.config.timeslots[this.day].slots[i].end, "h:mm a"),
      };
      if (
        newTimeslot.start.isSame(checkTimeslot.start, "minute") &&
        newTimeslot.end.isSame(checkTimeslot.end, "minute")
      ) {
        // console.log("second");
        this.flag = 2;
        break;
      }

      //if(newTimeslot.start.isAfter(checkTimeslot.start,"minute") && newTimeslot.start.isBefore(checkTimeslot.end,"minute"))
      if (
        newTimeslot.start.isBetween(
          checkTimeslot.start,
          checkTimeslot.end,
          "minute",
          "[)"
        )
      ) {
        console.log("start ");
        this.flag = 1;
        break;
      }

      //if(newTimeslot.end.isAfter(checkTimeslot.start,"minute") && newTimeslot.end.isBefore(checkTimeslot.end,"minute"))
      if (
        newTimeslot.end.isBetween(
          checkTimeslot.start,
          checkTimeslot.end,
          "minute",
          "(]"
        )
      ) {
        console.log("Third");
        this.flag = 1;
        break;
      }

      if (
        newTimeslot.start.isBefore(checkTimeslot.start, "minute") &&
        newTimeslot.end.isAfter(checkTimeslot.end, "minute")
      ) {
        console.log("Fourth");
        this.flag = 1;
        break;
      }
    }
    if (this.flag == 0) {
      if (newTimeslot.start.isBefore(newTimeslot.end, "minute")) {
        this.config.timeslots[this.day].slots.push({
          end: this.end,
          start: this.start,
        });
        console.log("slots booked successfully");
      } else {
        console.error("Invalid slot");
        this._snackBar.open("Invalid slot", "", {
          duration: 2000,
          verticalPosition: "top",
          panelClass: "mat-snack-bar-container-error",
        });
      }
    } else if (this.flag === 1) {
      this._snackBar.open("This slot overlaps with another slot", "", {
        duration: 2000,
        verticalPosition: "top",
        panelClass: "mat-snack-bar-container-error",
      });
    } else {
      this._snackBar.open("Slot already set", "", {
        duration: 2000,
        verticalPosition: "top",
        panelClass: "mat-snack-bar-container-error",
      });
      console.log("Slots already booked");
    }

    this.config.timeslots[this.day].slots = this.SortTimeSlot();
  }

  Remove_All_Blocked_Date() {
    this.Blocked_Date = [];
    this.block_enable = true;
  }

  //dateFilter = (date: Date) => date.getDay() !=0 &&  date.getDay() !=6
  Remove_Time_Slots(id) {
    this.TempArray = [];
    for (var i = 0; i < this.config.timeslots[this.day].slots.length; i++) {
      if (i != id) {
        this.TempArray.push(this.config.timeslots[this.day].slots[i]);
      }
    }
    this.config.timeslots[this.day].slots = [];
    this.config.timeslots[this.day].slots = this.TempArray;
    //this.config.blocked_dates = this.TempArray;
  }

  Remove_Services(id) {
    this.TempArray = [];
    for (var i = 0; i < this.provided.length; i++) {
      if (i != id) {
        this.TempArray.push(this.provided[i]);
      }
    }
    this.provided = [];
    this.provided = this.TempArray;
    this.config.services.provided = this.TempArray;
  }

  Remove_Blocked_Dates(id) {
    this.TempArray = [];
    for (var i = 0; i < this.Blocked_Date.length; i++) {
      if (i != id) {
        this.TempArray.push(this.Blocked_Date[i]);
      }
    }
    this.Blocked_Date = [];
    this.Blocked_Date = this.TempArray;
    this.config.blocked_dates = this.TempArray;
    this.changeCalenderConstarint("myFilter");
  }

  changeCalenderConstarint(constraint: string) {
    this.flag = 0;
    this.validationLeadRolling = false;
    switch (constraint) {
      case "minDate":
        this.minDate = moment().add(
          this.config.minDate.key,
          this.config.minDate.amount
        );
        this.min = this.minDate.toDate();
        this.flag = 1;
        break;

      case "maxDate":
        this.maxDate = moment().add(
          this.config.maxDate.key,
          this.config.maxDate.amount
        );
        this.flag = 1;
        this.max = this.maxDate.toDate();
        break;

      case "myFilter":
        this.save();
        var count = 0;
        this.disableField = false;
        this.myFilter = (d: Date): boolean => {
          const day = moment(d).day();
          return (
            !this.config["filterDays"].includes(day) &&
            !this.config["blocked_dates"].includes(moment(d).format("Y-MM-DD"))
          );
        };

        for (var i = 0; i < this.toggleDays.length; i++) {
          if (this.toggleDays[i].selected === false) {
            count++;
          }
        }
        if (count == 7) {
          this.disableField = true;
        }
        break;

      case "timeslots":
        this.timeslots = this.config["timeslots"][this.day];
        break;
      default:
        break;
    }
    if (this.flag == 1) {
      if (this.config.minDate.key >= 7 * this.config.maxDate.key) {
        this.validationLeadRolling = true;
        this._snackBar.open("Invalid Lead time", "", {
          duration: 5000,
          verticalPosition: "top",
          panelClass: ["mat-snack-bar-container-error"],
        });
      } else {
        this.validationLeadRolling = false;
        this.flag = 0;
      }
    }
  }

  show() {
    if (this.disableSave == false && this.validationLeadRolling == false) {
      this.save();
      this.savePaymentInformation();
      this.form_fields_value = {
        address: this.show_address,
        message: {
          show: this.show_message,
          title: this.title,
        },
      };
      this.config["form_fields"] = this.form_fields_value;

      console.log(this.config.slug);

      this.configurationService.setData(this.config, this.ref);

      this._snackBar.open("Calendar updated!", "", {
        duration: 4000,
        verticalPosition: "top",
        panelClass: ["mat-snack-bar-container"],
      });
    } else {
      if (this.disableSave == true) {
        this._snackBar.open(`'${this.calenderName}' already exists!`, "", {
          duration: 10000,
          verticalPosition: "top",
          panelClass: ["mat-snack-bar-container-error"],
        });
      }
      if (this.validationLeadRolling == true) {
        this._snackBar.open("Invalid rolling period", "", {
          duration: 10000,
          verticalPosition: "top",
          panelClass: ["mat-snack-bar-container-error"],
        });
      }
    }
  }
  onSelect(event) {
    console.log(event);
  }

  onChangeOfToggle(event) {
    this.dataPickerState = event.checked;
  }

  getUrl(event) {
    console.log(event.value);
  }

  choose_start_date() {
    console.log(this.start_date);
  }

  choose_end_date() {
    console.log(this.end_date);
  }
}
