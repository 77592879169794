<p-carousel [value]="messages" [numVisible]="1" [numScroll]="1" [circular]="false"
    [responsiveOptions]="responsiveOptions" [class.hidePaginator]="messages.length==1">
    <ng-template let-product pTemplate="item">
        <div class="product-item">
            <div class="product-item-content">
                <div class="p-mb-3">
                </div>
                <div>
                    <span *ngIf="product?.image">
                        <img class="image" src={{product?.image}}>
                    </span>
                    <h4 class="p-mb-1" [innerHTML]="product.name"></h4>
                    <h6><a *ngIf="product.shortDescription" class="showMoreLink"
                            role="button">{{payload.extended.linkText}}</a></h6>
                    <button *ngIf="product.url" style="width: 90%; margin-top: 10px;"
                        class="text-center">{{payload.extended.buttonText}}</button>
                </div>
            </div>
        </div>
    </ng-template>
</p-carousel>