<div mat-dialog-content class="box">
    <h1><p align="center">Settings</p></h1>
    <mat-form-field class="example-full-width">
        <input matInput [(ngModel)]="data.email" placeholder="Enter email ID to collect responses" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
    </mat-form-field>

    <mat-checkbox name="{{applyhubspot}}" [(ngModel)]="applyhubspot">Connect HubSpot account</mat-checkbox>
    <div *ngIf="applyhubspot">
        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="data.hubspot_id" placeholder="Enter Portal ID">
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="data.hubspot_form_id" placeholder="Enter Form ID">
        </mat-form-field>

    </div>
    <mat-checkbox name="{{applyToAll}}" [(ngModel)]="applyToAll">Apply to all forms</mat-checkbox>
    <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="data" cdkFocusInitial (click)="saveSettings()">Save</button>
        <button mat-button (click)="onNoClick()">Cancel</button>
    </div>
</div>
