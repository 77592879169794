<div class="row">
<button *ngIf="selected.length > 1 && twilio_from !== undefined" mat-mini-fab class="format" (click)="bulkMessage()" matTooltip="Send Message To Multiple  Contacts"><i class="material-icons">message</i></button>
</div>
<div *ngIf="contacts.length > 0 ;else NoContacts "  >
<ag-grid-angular
	#agGrid
    style="width: 100%; height: 500px;"
    class="ag-theme-balham"
    [rowData]="rowData "
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    pagination="true"
    (gridReady)="onGridReady($event)"
    [masterDetail]="true"
    [detailCellRendererParams]="detailCellRendererParams"
    (rowClicked) = "onRowClicked($event)"
    >
</ag-grid-angular> -->
</div>
<ng-template #NoContacts>
	No Contacts
</ng-template>
