
import {switchMap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { TipsService, AlertService, AccountService } from '../_services/index';
import { Location } from '@angular/common';

@Component({
  selector: 'app-tip-details',
  templateUrl: './tip-details.component.html',
  styleUrls: ['./tip-details.component.scss']
})
export class TipDetailsComponent implements OnInit {
isButtonvisible:boolean;
  tip: any;
  now = Date.now();
//  date = new Date();
acc:any;
hasTip:boolean;
currentAd:number = 0;
selectedIndex:any;

  constructor(
    private tipsService: TipsService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
    private location: Location
  ) {
          this.accountService.accChange$.subscribe( accChange =>{
            this.router.navigateByUrl('/websites');
            /*
            let regExp = /^\/tip-details/;
            if(regExp.test(this.location.path())) {
              this.router.navigateByUrl('/tips');
            }
            */
        });
   }

  ngOnInit() {
     this.acc = this.accountService.getCurrentAccDetails();
     this.route.params.pipe(
          switchMap((params: Params) => this.tipsService.fetchTipDetails(params['id'], '/tips/' + this.acc.name)))
          .subscribe( tip => {
            this.tip = tip[0];
          });
          this.route.queryParams
              .subscribe(params => {
                // Defaults to 0 if no query param provided.
                this.selectedIndex = +params['selectedIndex'];
              });
}

accept() {
    this.tipsService.setStatus(this.tip.id, 'Accepted', this.acc.name);
    this.alertService.success("You've accepted this tip. We will now act on it!");
    this.selectedIndex = 1;
  }

  decline() {
    this.tipsService.setStatus(this.tip.id, 'Declined', this.acc.name);
    this.alertService.success("You've declined this tip.");
    this.selectedIndex = 1;
  }

  snooze() {
    this.tipsService.setStatus(this.tip.id, 'Snoozed', this.acc.name);
    this.alertService.success("You've snoozed this tip.");
  }

  prevAd() {
    if(this.currentAd == 0) {
      this.currentAd = this.tip.ads.length-1;
    }
    else
    this.currentAd -= 1;
  }
  nextAd() {
    if(this.currentAd == this.tip.ads.length-1) {
      this.currentAd = 0;
    }
    else
    this.currentAd += 1;
  }

}
