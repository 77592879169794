import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { saveAs } from 'file-saver';
import { timeout } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  constructor(
    private http: HttpClient,
  ) { }

  async getReviews() {
    // let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
    //   .collection('reviews').where('type', '!=', 'metaData').get();
    let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('reviews').orderBy('createTime', 'desc').get()
    // console.log(snapshot.docs.map(doc => doc.data()));
    return snapshot;
  }
  async getReviewMetaData() {
    let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('reviews').where('type', '==', 'metaData').get();
    //console.log(snapshot.docs.map(doc => doc.data()));
    if (!snapshot.empty)
      return snapshot.docs[0].data();
    else
      return {}
  }

  async checkReviewsExists() {
    let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('reviews');
    return snapshot;
  }

  async checkGmbAccountExists() {
    let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('linked_accounts').doc('gmb_accounts');
    return snapshot;
  }

  async getGmbAccountInfo() {
    let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('linked_accounts').doc('gmb_accounts').get();
    return snapshot;
  }

  async replyToReview(obj) {
    let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('linked_accounts').doc('gmb_accounts').get();
    let accRef = await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('reviews')
      .where('reviewId', '==', obj.reviewId).get();
    accRef.docs[0].ref
    accRef.docs[0].ref.update({ 'reviewReply': { 'comment': obj.replyByOwner } });
    let refresh_token = snapshot.data().gmb_refresh_token;
    // console.log(refresh_token);
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json',

      })
    };
    let data = {
      "refresh_token": refresh_token,
      "comment": obj.replyByOwner,
      "name": obj.name
      //"name": "accounts/106451236101766219358/locations/4026410454796600933/reviews/AbFvOqk1ZSM3eu10W0hDw_lxlMNduzmO5OqYSuNV1avQODqJHWx42OPKYnmeG6uyoZJ43BqfJ2MLbQ",
    };
    console.log(data);
    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/replyToReview`, data, header);
  }
  async deleteReply(obj) {
    let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('linked_accounts').doc('gmb_accounts').get();
    let accRef = await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('reviews')
      .where('reviewId', '==', obj.reviewId).get();
    accRef.docs[0].ref
    let refresh_token = snapshot.data().gmb_refresh_token;
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json',

      })
    };
    let data = {
      'name': obj.name,
      'refresh_token': refresh_token
    }
    accRef.docs[0].ref.update({ reviewReply: firebase.firestore.FieldValue.delete() });
    // console.log(data);
    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/deleteReply`, data, header);
  }
  async getOverallReviews(pagingInfo) {
    // console.log(pagingInfo);
    let ref: any;
    let sortType;
    let size: number;
    if ("sortType" in pagingInfo)
      sortType = pagingInfo['sortType'];
    else
      sortType = 'desc';
    let reviewDocReference = await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('reviews')
      .orderBy("createTime", sortType);
    if ("source" in pagingInfo) {
      reviewDocReference = reviewDocReference.where('source', '==', pagingInfo['source']);
    }
    if ('compareTime' in pagingInfo) {
      reviewDocReference = reviewDocReference.where("createTime", ">=", pagingInfo['compareTime']['from']).where("createTime", "<=", pagingInfo['compareTime']['to'])
    }
    if ('locationPath' in pagingInfo) {
      reviewDocReference = reviewDocReference.where('locationPath', "==", pagingInfo['locationPath']);
    }
    size = await (await reviewDocReference.get()).size;
    if ("limit" in pagingInfo && "offset" in pagingInfo) {
      let lastIndex = (await reviewDocReference.get()).docs[pagingInfo['offset']];
      if (lastIndex != undefined)
        ref = await (reviewDocReference.startAt(lastIndex).limit(pagingInfo['limit']).get());
    }
    else if ("limit" in pagingInfo) {
      ref = (await reviewDocReference.limit(pagingInfo['limit']).get());
    }
    else {
      ref = await reviewDocReference.get();
    }
    return { "ref": ref, "totalReviews": size }
  }

  sendEmailAndSms(sendEmailInfo, sendBySmsAndEmail) {
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    for (var i = 0; i < sendEmailInfo['selectedContacts'].length; i++) {
      sendEmailInfo['selectedContacts'][i]['sendByEmail'] = sendBySmsAndEmail[i]['sendByEmail']
      sendEmailInfo['selectedContacts'][i]['sendBySms'] = sendBySmsAndEmail[i]['sendBySms']
    }
    // console.log(sendEmailInfo);
    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/dynamicSendEmailAndSms`, { "data": sendEmailInfo }, header);

    // firebase.functions().httpsCallable('dynamicSendEmailAndSms')(sendEmailInfo).then(
    //   result => {
    //     console.log('Email sent to user successfully!');
    //   },
    //   error => {
    //     console.error(error);
    //   }
    // );
  }

  async getCurrentAccountDetails() {
    return await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).get();
  }

  async getReviewConfiguration() {
    return (await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('settings')
      .where('type', '==', 'review-configuration')
      .get());
  }
  async getLink() {
    return (await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('linked_accounts').where('type', '==', 'gmb').get())
  }
  async updateReviewConfiguration(data, docId) {
    return await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('settings')
      .doc(docId)
      .update(data);

  }

  async addReviewConfiguration(data) {
    return (await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('settings')
      .add(data))
  }

  getImageUrlForSocialMediaPost(reviewData: any, postComment: string, displayName: string, additionlSettings: any, selectedTemplate: any) {
    // console.log(additionlSettings);
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    let data = {
      "accountId": `${sessionStorage.getItem('current_acc_id')}`,
      "documentId": reviewData.documentId,
      "template": selectedTemplate.template,
      "data": {
        "rating": reviewData.starRating,
        "name": displayName,
        "comment": postComment,
        "imageUrl": reviewData.reviewer.profilePhotoUrl,
      },
      "options": {
        "zoom": additionlSettings.zoom ? additionlSettings.zoom : 2,
        "width": additionlSettings.width ? additionlSettings.width : 1200,
        "height": additionlSettings.height ? additionlSettings.height : 1200,
        "quality": 100
      }
    };
    let url = `${environment.cloudFunctionServerUrl}/htmlToImage`;
    return this.http.post<any>(url, data, header).pipe(timeout(30000))
  }

  async downloadFile(newUrl: string, fileName: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    this.http.get(newUrl, { headers: headers, responseType: 'blob' }).subscribe(resp => {
      var blob = new Blob([resp], { type: 'application/pdf' });
      saveAs(blob, fileName);
    });
  }

  async getReviewTemplates() {
    let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).
      collection('image_template').where('type', '==', 'REVIEW').get()
    return snapshot;
  }

  async deleteUrlFromStorage(url: string) {
    await firebase.storage().refFromURL(url).delete().then(() => {
      console.log("Deleted successfully");
    })
  }

}
