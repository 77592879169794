/*
 * Imported and/or used in legacy code
 */

export class User {
    _id:number;
    uuid: string;
    userName: string;
    email: string;
    login_id:string;
    isAnonymous:boolean;
    isVerified:boolean;
    cell_no:string;
    password: string;
    newPassword: string;
    verifyPassword: string;
}
