<div class="row" style="background-color:white">
    <div class="col-md-4">

    </div>
    <div class="col-md-4">
        <div style="margin:12px auto 10px;font-size: 20px;font-weight: 500;text-align: center;">
            Templates
        </div>
    </div>
    <div class="col-md-2" style="text-align: center;margin-top: 5px;">
    </div>
    <div class="col-md-2" style="text-align: center;margin-top: 5px;">
        <button id="button" mat-mini-fab class="format" style="text-align: center;margin-top: 10px;"
            (click)="openDailogForAddPost()" matTooltip="Add New Template">
            <i class="material-icons add">add</i>
        </button>
    </div>
    <div class="col-md-1" style="text-align: center;margin-top: 5px;">
        <!-- <button *ngIf="settingFlag" mat-mini-fab id="button" class="format" color="primary"
            matTooltip="Template Configuration">
            <span class="material-icons" style="cursor: pointer;text-align: right;" color="primary"
                (click)="openConfig()">settings</span>
        </button> -->
    </div>
</div>


<div *ngIf="noTemplates" style="margin-top: 100px;text-align: center;">
    <h6>No templates available </h6>
</div>
<div class="row" *ngIf="templateData" style="padding-top: 15px;">
    <div class="col-md-12" style="margin-top: 20px;">
        <div id="card-container">
            <div *ngFor="let template of templateData">
                <div style="margin: 5px;">
                    <p-card [style]="{height:'300px',width:'300px',
                        'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'
                        }" styleClass="p-card-shadow">
                        <p-card-content>
                            <img [src]="template.previewTemplateUrl" style="height: 250px;width: 100%;">
                        </p-card-content>
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-6">
                                <div
                                    style="text-align: center;margin-top: 12px;white-space: nowrap;overflow: hidden; text-overflow: ellipsis; ">
                                    {{template.name}}
                                </div>
                            </div>

                            <div class="col-md-4">
                                <mat-icon aria-hidden="false" aria-label="Example home icon"
                                    style="text-align: center;margin-top: 12px;padding-left: 5px;"
                                    matTooltip='Delete Template' (click)="deleteTemplate(template)">delete</mat-icon>
                                <mat-icon aria-hidden="false" aria-label="Example home icon"
                                    style="text-align: center;margin-top: 12px;margin-left: 17px;"
                                    matTooltip='Edit Template' [disabled]="true" (click)="editTemplate(template)">edit
                                </mat-icon>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!templateData" style="padding-top: 0%;">
    <div style="text-align: center;">
        <i class="pi pi-spin pi-spinner" id="spinIcon1"></i>
    </div>
</div>