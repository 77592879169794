import { Component, OnInit } from '@angular/core';
import { Questions } from './forms';
import { Router } from '@angular/router';
import { MarketingPlanService, AlertService, UserService } from '../../_services/index';


@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {

questions: Questions[] = [];
first:boolean = false;
last:boolean = false;
currentQuestionNumber;
questionAnswered ;
model:any =[];


constructor(private router: Router,
  private marketingPlanService:MarketingPlanService,private alertService:AlertService){
  this.currentQuestionNumber = 0;
  this.questionAnswered =0;
  }

  ngOnInit() {
  this.marketingPlanService.getWhatForm()
   .subscribe(questions => this.questions = questions);
  }

  Submit(){
      this.last=true;
      this.marketingPlanService.submitForm(this.model)
          .subscribe(
              data => {
                  this.alertService.success('Data added successfully..');
                //this.router.navigate(['/new-plan', 1]);
              },
              error => {
                  this.alertService.error("Couldn't Add Data.");

              });
       }



  setGoToNextTrue(index){
            if(this.model[index]!= null ){
                 this.questionAnswered++;
                 //window.scrollBy(0,100);
                }
             this.currentQuestionNumber++;
             //window.scrollBy(0,250);
             document.body.scrollBy(0, 250);
    }


  setGoToNextFalse(){
          this.currentQuestionNumber--;
          //window.scrollBy(-500,0);
          document.body.scrollBy(0, -500);
  }
/*
  submit(){
  console.log(this.model);
  }*/


/*Checkbox value print*/
  getValue(value){
  this.questionAnswered++;
       let k =JSON.stringify(value);
       //console.log(k);
}


}
