import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NgForm, Validators,FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { OrganicRankingService } from "../../_services/organic-ranking.service";

@Component({
  selector: 'app-organic-rank-configuration',
  templateUrl: './organic-rank-configuration.component.html',
  styleUrls: ['./organic-rank-configuration.component.scss']
})
export class OrganicRankConfigurationComponent implements OnInit {
  @ViewChild('form', { static: true }) ngForm: NgForm;
  @Output() close = new EventEmitter<any>();
  // schedularInfo :any = [{name:'Every first day of month',value:""}, {name:'Every start and mid of the month', value:""}];
  configurationData  = {};
  showCustomFrequency:boolean;
  selectedFrequency:string="";
  customFrequency:string="";
  // country:string="";
  // myreg = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
  // , Validators.pattern(this.pattern)
  // pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  // pattern="[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}(.[a-z]{2,4})?\b(/[-a-zA-Z0-9@:%_+.~#?&//=]*)?"
  pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  organicRankFormGroup = new FormGroup({
    url : new FormControl('', [Validators.required, Validators.pattern(this.pattern)]),
    cntry : new FormControl('', [Validators.required]),
  })
  // url = new FormControl('', [Validators.required, Validators.pattern(this.pattern)]);
  // cntry = new FormControl('', [Validators.required]);
  // schedularValidation= new FormControl('', [Validators.required]);
  // timeValidation = new FormControl('', [Validators.required]);
  countries: any[];
  loading:boolean;
  schedularInfo = [
    {
      'value': '0 0 1 * *',
      'name':"Every first day of month",
      'checked':false
    },
    {
      'name': 'Every start and mid of the month',
      'value': '0 0 1,15 * *',
      'checked':false
    }
  ];
  timezones = [
    {
      'name': 'Asia/Kolkata',
      'value': 'Asia/Kolkata'
    },
    {
      'name': 'America/New_York',
      'value': 'America/New_York'
    }
  ];
  timezone: string;
  cloudSchedulerEnabled = false;
  schedule: string;
  checked:boolean= false;
  task: string;
  updated: boolean = false;
  websiteUrl:string="";
  label :string ="Save";
  selectedCountry:string;
  inValid: boolean;
  dataSet: boolean;
  statusOfCloudSchedular: boolean;

  constructor(
    private organicRankService: OrganicRankingService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.organicRankService.getCountries().then(countries => {
      this.countries = countries;
    });
    this.getOrganicRankConfiguration();
  }

  async getOrganicRankConfiguration(){
    if(!(await this.organicRankService.getOrganicRankConfiguration()).empty){
        let organicRankConfigData = (await this.organicRankService.getOrganicRankConfiguration()).docs[0].data();
        this.selectedCountry = organicRankConfigData.country.name;
        this.statusOfCloudSchedular = organicRankConfigData.enableScheduler ? true : false;
        // this.selectedCountry = this.countries[this.countries.findIndex(ele=>(ele.name === organicRankConfigData.country.name))];
        // console.log(this.selectedCountry);
        this.websiteUrl = organicRankConfigData.websiteUrl;
        this.cloudSchedulerEnabled = organicRankConfigData.enableScheduler ? true : false;
        // this.schedule = organicRankConfigData.schedule ? organicRankConfigData.schedule : '* * 1 * *';
        // this.timezone = organicRankConfigData.timezone ? organicRankConfigData.timezone : 'Asia/Kolkata';
        this.schedule = organicRankConfigData.schedule;
        this.timezone = organicRankConfigData.timezone;
        this.checked = this.cloudSchedulerEnabled;
        if(this.checked){
          this.organicRankFormGroup.setControl("schedularValidation" ,new FormControl('', [Validators.required]))
          this.organicRankFormGroup.setControl("timeValidation" ,new FormControl('', [Validators.required]))
        }
        this.loading = false;
        for(var i=0;i<this.schedularInfo.length;i++){
            if(this.schedularInfo[i]['value'] === this.schedule){
              this.schedularInfo[i]['checked'] = true;
            }
            else{
              this.schedularInfo[i]['checked'] = false;
            }
        }
        this.label = "Update";
        this.dataSet = true;
    }
    else{
      this.loading = false;
      this.label = "Save";
      this.dataSet = false;
    }
  }

  getSchedularInfo(event){
    this.schedule = event.value;
    this.updated = true;
  }

  getCountry(countryObj) {
    this.selectedCountry = countryObj
    // console.log(this.selectedCountry);
  }

  comparer(o1: any, o2: any): boolean {
      return o1 && o2 ? o1.code === o2.code : o2 === o2;
  }

  updateOrganicRankConfiguration(form){
    this.task = this.cloudSchedulerEnabled ? (this.checked ? (this.updated ? 'update' : 'none') : 'delete') : this.checked ? 'create' : 'none';
    this.configurationData['websiteUrl'] = this.websiteUrl;
    this.configurationData['country'] = this.countries[this.countries.findIndex(ele=>(ele.name === this.selectedCountry))];
    if (this.task == 'create') {
      this.configurationData['enableScheduler'] = this.checked;
      this.configurationData['schedule'] = this.schedule;
      this.configurationData['timezone'] = this.timezone;
    }
    else if (this.task == 'update') {
      this.configurationData['schedule'] = this.schedule;
      this.configurationData['timezone'] = this.timezone;
    }
    else if (this.task == 'delete') {
      this.configurationData['enableScheduler'] = this.checked;
      this.configurationData['timezone'] = this.timezone;
      this.configurationData['schedule'] = this.schedule;
    }
    // console.log(this.configurationData);
    this.organicRankService.updateOrganicRankConfigurations(this.configurationData,this.task).then(resp => {
      // console.log("document added");

    },
    err => {
      console.log(err);
    });
    this.close.emit(false);
  }

  changeSchedule(event) {
    this.updated = true;
    this.schedule = event;
  }

  changeTimezone(event) {
    this.timezone = event;
    this.updated = true;
  }

  enableScheduler(event) {
    // console.log(event, this.cloudSchedulerEnabled);
  }

  toggle(event: MatSlideToggleChange) {
    if(event.checked){
      this.organicRankFormGroup.setControl("schedularValidation" ,new FormControl('', [Validators.required]))
      this.organicRankFormGroup.setControl("timeValidation" ,new FormControl('', [Validators.required]))
    }
    else{
      this.organicRankFormGroup.removeControl("schedularValidation");
      this.organicRankFormGroup.removeControl("timeValidation");
    }
    if(!this.dataSet){
      this.timezone = "";
      this.schedule = "";
    }
    if(!this.statusOfCloudSchedular){
      this.timezone = "";
      this.schedule = "";
      for(var i=0;i<this.schedularInfo.length;i++){
          this.schedularInfo[i]['checked'] = false;
      }
    }
  }

}
