<div *ngIf="formExists else notExists">

  <div *ngIf="isStartScreen">

    <h1 style="margin:5px 10px;padding-left: 30px;font-size: 20px;">Select the Survey form</h1>
    <br>
    <div class="container-fluid" *ngIf="loaded else loading;" style="padding: 0 15px 15px 15px;">
      <div class="scrolling-wrapper-flexbox">
        <div *ngFor="let form of forms">
          <mat-card (click)="selectedForm(form)" matBadgeSize="large" matBadge="done" matBadgePosition="before"
            [matBadgeHidden]="form.selected">

            <!-- <div class="col"> -->


            <img class="image" *ngIf="form.data?.welcome_screen.image !== ''" mat-card-image
              src={{form.data.welcome_screen.image}}>

            <!-- <div class="row" style="height: 50px; padding: 0px;" > -->
            <mat-card-actions *ngIf="showIcons">
              <!-- <a [routerLink]="['/form-design',state.url,form.id]"> -->
              <h4 class="card-title" id="card_icon">
                {{ ((form.data.name).length>10)? ((form.data.name) | slice:0:20)+'..':(form.data.name) }}
              </h4>
              <!-- </a> -->
            </mat-card-actions>
            <!-- </div> -->

            <!-- <div class="row" style=" padding: 0px;">             -->
            <mat-card-actions>
              <!-- <center>
                      <button pButton name="form" type="button" *ngIf="form.selected else selected" label="select"
                        (click)="selectedForm(form)">
                      </button>
                    </center> -->
              <!-- <ng-template *ngIf="form.selected"> -->
              <!-- <center>
                          <span class="material-icons" style="color: blue;">
                            check_box
                          </span>
                        </center> -->
              <!-- </ng-template> -->
            </mat-card-actions>
            <!-- </div>   -->

            <!-- </div> -->

          </mat-card>
        </div>
      </div>

      <!-- <div class="col-md" style="padding-left: 0px;">
          <p style="font-size: 20px; margin:5px 10px;">Send Via</p>
          <div class="col-md-12">
            <mat-checkbox style="margin:5px 10px;font-size:20px;"  [(ngModel)]="emailSendStatus" (change)="emailSend($event)">Email
            </mat-checkbox>
          </div>
          <div class="col-md-12">
            <mat-checkbox style="margin:5px 10px;font-size: 20px;"  [disabled]="!linked_accounts" [(ngModel)]="smsSendStatus"(change)="smsSend($event)">SMS
            </mat-checkbox>
          </div>
        </div> -->
      <br>
      <br>

      <div style="padding-left: 0px;">
        <app-email-sms-capture-dialogs [data]="dialogInfo" (newItemEvent)=" getSmsEmailStatus($event)">
        </app-email-sms-capture-dialogs>
      </div>

      <div class="row">
        <div class="col-md text-center" style="padding-top: 20px;">
          <button mat-raised-button  (click)="next()" [disabled]="disabled">Next</button>
        </div>
      </div>

      <!-- <div class="col-md text-center" style="padding-top: 100px;">
          <button mat-raised-button  (click)="next()" [disabled]="isFormValid">Next</button>
        </div> -->
    </div>
  </div>


  <div class="col-md" *ngIf="!isStartScreen">
    <div class="col">

      <div class="row-10">
        <app-display-email-sms [data]="dialogInfo"></app-display-email-sms>
      </div>

      <div class="row-2">
        <div class="row text-center" *ngIf="showConfirm" style="padding-top: 50px;">
          <div class="col-md-6">
            <button mat-raised-button (click)="previous()">Previous</button>
          </div>
          <div class="col-md-6">
            <button mat-raised-button (click)="sendServey()">Confirm and Send</button>
          </div>

        </div>

        <ng-template *ngIf="!goLast">
          <div>
            <mat-spinner></mat-spinner>
          </div>
        </ng-template>

        <!-- <ng-template #done>
          <div class="text-center" style="font-size: 20px;padding: 100px;">
            Form Sending Job is added in the queue and it will send a message soon ......
          </div>
        </ng-template> -->

      </div>

    </div>
  </div>

  <ng-template #loading>
    <div class="center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>

</div>


<ng-template #notExists>
  <div class="center" style="font-size: 20px;">
    No forms to send.
  </div>
</ng-template>

<div *ngIf="goLast" id="content">
  <div class="text-center" style="font-size: 20px;padding: 20px;">
    <span style="background-color:#7E84F2;color:white;padding: 1% 2% 1.5%;border-radius: 10px;">
      {{displayMsg}}
    </span>
  </div>
</div>
