import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Inject,
  TemplateRef,
  OnInit,
} from "@angular/core";
import { TitleCasePipe } from "@angular/common";
import {
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  startOfMonth,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from "date-fns";
import { Subject, from } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from "angular-calendar";
import { AppointmentService } from "../_services/appointment.service";
import { AccountService } from "../_services/account.service";
import { AppointmentStatsService } from "../_services/appointment-stats.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../environments/environment";
import { AppointmentDetailComponent } from "./../appointment-detail/appointment-detail.component";
import { ScheduleNewAppointmentComponent } from "./schedule-new-appointment/schedule-new-appointment.component";
import { AppointmentDetailsComponent } from "./appointment-details/appointment-details.component";
import * as moment from "moment";
import firebase from 'firebase';
import { Calendar } from '@fullcalendar/core'; // include this line
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { FullCalendar, FullCalendarModule } from 'primeng/fullcalendar';
import { getValueInRange } from '@ng-bootstrap/ng-bootstrap/util/util';

const colors: any = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
  green: {
    primary: "#33cc33",
    secondary: "#E3F4D0",
  },
  theme_pink: {
    primary: "#ff4081",
    secondary: "#ffdae0",
  },
  theme_blue: {
    primary: "#3f51b5",
  },
  orange: {
    primary: "#FF8C00",
  },
  gray: {
    primary: "#a7a7a7",
    secondary: "#e7e7e7",
  },
};
export interface DialogData {
  link: string;
  title: string;
  calendars: any;
  appointmentDetails: any;
}

@Component({
  selector: "appointments-calendar",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./appointment-calendar.component.html",
  styleUrls: ["./appointments.component.scss"],
})

export class AppointmentCalendarComponent implements OnInit {
  @ViewChild("modalContent")
  modalContent: TemplateRef<any>;
  @ViewChild('fullCalendar', { static: true }) calendar: FullCalendar;
  appointmentEvent: CalendarEvent[] = [];
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  startDate: Date = moment(this.viewDate).startOf(this.view).toDate();
  endDate: Date = moment(this.viewDate).endOf(this.view).toDate();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  calendars: any = [];
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = false;
  // providersArray = [];
  // selectedProvider = [];
  // providerstatus: any;
  // providersCount;
  appointmentDetails: any = [];
  // allProvidersValue;
  // providers: any = [];
  // allProviders = [];
  // allCheckBoxValue: boolean = true;
  // isDisabled: boolean;
  accName: string;
  displayCalendars: boolean = false;
  actions: CalendarEventAction[] = [];
  link: string;
  selected: string;
  showBookAppt: boolean = false;
  calenderName: string;
  appointmentStats: any = [];
  calendarType: any = [];
  selectedCalendar: any = [];
  isCalendarSelected: boolean = false;
  before_date: Date = new Date();

  after_date: Date = new Date();
  appointments: any;
  events: any[] = [
    {
      "id": 1,
      "title": "All Day Event",
      "start": "2020-09-05"
    },
    {
      "id": 2,
      "title": "Long Event",
      "start": "2020-09-05",
      "end": "2020-09-07"
    }
  ];
  options: any;
  options1: any;
  options2: any;
  minTime: string;
  maxTime: string;
  timezone: string;
  //loading: boolean = true;
  constructor(
    private modal: NgbModal,
    private appointmentService: AppointmentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private accountService: AccountService,
    public appointmentStatsService: AppointmentStatsService) {
    //let snapshot = firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem("current_acc_id")}`).collection("calendar").where('slug', 'in', [this.selectedCalendar]).get();
    // console.log("snapshot", snapshot);
    const name = Calendar.name;
    this.getTimeZone();
    // appointmentService.getCalendars().then((cals) => {
    //   cals.forEach((cal) => {
    //     let myCal = {
    //       doc: cal.ref.path,
    //       slug: cal.get('slug')
    //     }
    //     //console.log(myCal);
    //     this.calendarType.push(myCal);
    //   })
    // });
    appointmentService.appointments$.subscribe((appointments) => {
      this.appointments = appointments;
      this.appointmentEvent = [];
      appointments.forEach((appointment) => {
        //console.log("Appointmnets:", this.appointments);
        if (!(appointment.status === "rescheduled")) {
          this.minTime = moment(appointment.appt_start).format("HH:mm");
          console.log("minTime:", this.minTime);
          let myEvent: any = {
            appointment: appointment,
            start: appointment.appt_start,
            end: appointment.appt_end,
            // provider:appointment.provider_name,
            // name: appointment.first_name + ' ' + appointment.last_name,
            // location:appointment.location,
            // status:appointment.status,
            // date:appointment.date,
            // time:appointment.slot,
            // calendar:appointment.calendarReference,
            title: `${appointment.first_name} ${appointment.last_name},
                 ${appointment.slot}`,
            //   (${new TitleCasePipe().transform(appointment.status)})
            //   ${appointment.location}
            //   Booked on ${moment(appointment.booked_on).format("MMM D, YYYY")} on
            //   ${new TitleCasePipe().transform(appointment.source)}.
            //   Note: ${appointment.notes}`,
            color: this.getColor(appointment.status),
            allDay: false,
          };
          this.appointmentEvent.push(myEvent);
        }
        this.options1.minTime = this.minTime;
      });
      this.refresh.next();
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        //this.appointmentEvent = events;
      }
    }
  }

  getTimeZone() {
    // this.appointmentService.getTimeZone().then(resp => {
    //   if (resp.timezone) {
    //     this.timezone = resp.timezone;
    //   } else {
    //     this.timezone = 'Asia/kolkata';
    //   }
    //   console.log(this.timezone);
    // })

  }
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent("Dropped or resized", event);
    this.refresh.next();
  }

  handleEvent(action: string, event: any): void {
    //let accName = this.accountService.getCurrentAccDetails().account_slug;
    //let name = this.accountService.getCurrentAccDetails();
    let data = {
      name: `${event.appointment.first_name} ${event.appointment.last_name}`,
      appt_start: event.appointment.appt_start,
      slot: event.appointment.slot,
      clinic_name: event.appointment.location,
      // city: event.appointment.contact_info.address.city,
      status: event.appointment.status,
      service: event.appointment.services,
      new_patient: event.appointment.new_patient,
      notes: event.appointment.notes,
      appt: event.appointment
    };

    //this.link = `${environment.apptURL}/account/${accName}`;
    //console.log(this.link);
    this.openApptDetailsDialog(data, "Appointment Details");
    //this.modalData = { event, action };
    // this.getAppointmentDetails(event);
    //let eventValue = event;
    // this.link = eventValue;
    // this.getAppointmentDetails(event);
    //this.getAppointmentDetails(this.appointmentDetails);
    //  this.modal.open(this.modalContent, { size: 'lg' });
  }

  // getAppointmentDetails(event){
  //   this.calendars=[];
  //   console.log(event);
  //   this.calendars.push(event);
  //   console.log(this.calendars);
  // }

  openAppointmentDetailsDialogBox;


  openDialogAppointmentDetails(link: any, title: string) {
    const dialogRef = this.dialog.open(AppointmentDetailsComponent, {
      panelClass: "custom-dialog-container",
      height: "520px",
      width: "300px",
      data: { link: link, title: title, timezone: this.timezone },
    });

    // openDialogAppointmentDetails(appointmentDetails) {
    //   const dialogRef = this.dialog.open(AppointmentDetailsComponent, {
    //     panelClass: "custom-dialog-container",
    //     height: "520px",
    //     width: "300px",
    //     data: appointmentDetails
    //   });

    dialogRef.afterClosed().subscribe((closed) => {
      console.log("closed");
    });
  }

  getAppointmentDetails(value) {
    // this.displayCalendars=true;
    this.link = value;
    this.openDialogAppointmentDetails(this.link, "Appointment Details");
    // this.openDialogAppointmentDetails(this.appointmentDetails);
  }

  fetchAppointments(startDate, endDate) {
    this.appointmentService.fetchAppointments(
      startDate, endDate
    );
  }

  fetchAppointmentsFromCalendar(startDate, endDate, selectedCalendar) {

  }

  updateViewDate() {
    this.activeDayIsOpen = false;
    this.appointmentEvent = [];
    this.startDate = moment(this.viewDate).startOf(this.view).toDate();
    this.endDate = moment(this.viewDate).endOf(this.view).toDate();
    this.fetchAppointments(this.startDate, this.endDate);
  }

  getColor(status: string) {
    let color = colors.blue;
    switch (status) {
      case "booked":
        color = colors.blue;
        break;
      case "completed":
        color = colors.green;
        break;
      case "no-show":
        color = colors.red;
        break;
      case "rescheduled":
        color = colors.yellow;
        break;
      case "cancelled":
        color = colors.gray;
        break;
    }
    return color.primary;
  }

  // OnChangeProvider(provider, event, index) {
  //   if (event.checked === true) {
  //     if (provider === "All") {
  //       this.appointmentService.filterAppointmentOnProvider(this.allProviders);
  //     }
  //     if (!this.selectedProvider.includes(provider)) {
  //       this.selectedProvider.push(provider);
  //       this.appointmentService.filterAppointmentOnProvider(
  //         this.selectedProvider
  //       );
  //     } else {
  //       this.appointmentService.filterAppointmentOnProvider(
  //         this.selectedProvider
  //       );
  //     }
  //   } else if (event.checked === false) {
  //     let providerIndex = this.selectedProvider.indexOf(provider);
  //     this.selectedProvider.splice(providerIndex, 1);
  //     this.appointmentService.filterAppointmentOnProvider(
  //       this.selectedProvider
  //     );
  //   }
  // }

  // OnSelectingAllProvider($event, allProvidersValue, allCheckBoxValue) {
  //   if ($event.checked === true) {
  //     let sizeOfProviders = this.providersArray.length;
  //     for (let i = 0; i < sizeOfProviders; i++) {
  //       this.providers[i] = true;
  //     }
  //     // this.allCheckBoxValue=false;

  //     this.allProviders = this.providersArray;
  //     this.isDisabled = true;
  //     // this.selectedProvider.push(this.allProviders);
  //     this.appointmentService.filterAppointmentOnProvider(this.allProviders);
  //   } else if ($event.checked === false) {
  //     this.allCheckBoxValue = true;
  //     this.isDisabled = false;
  //     let sizeOfProviders = this.providersArray.length;
  //     for (let i = 0; i < sizeOfProviders; i++) {
  //       this.providers[i] = false;
  //     }

  //     this.allProviders = [];
  //     this.appointmentService.filterAppointmentOnProvider(this.allProviders);
  //   }
  // }

  ngOnInit() {
    // this.options = {
    //   plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    //   //defaultDate: '2017-02-01',
    //   header: {
    //     left: 'prev,next',
    //     center: 'title',
    //     right: 'dayGridMonth,timeGridWeek,timeGridDay'
    //   },
    //   editable: true,
    //   eventClick: (eventClickEvent) => {
    //     this.handleEvent('Clicked', eventClickEvent.event._def.extendedProps);
    //     //console.log(eventClickEvent.event.id);
    //   },

    // };

    this.options1 = {
      //timeZone: 'UTC',
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
      defaultView: 'timeGridWeek',
      //defaultDate: "2020-09-07",
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
      },
      displayEventTime: false,
      eventLimit: true,
      // eventRender: function (view) {
      //   minTime: this.minTime;
      // },
      noEventsMessage: "No appointments to show.",
      minTime: '06:00',
      maxTime: '24:00',
      //scrollTime: '09:30:00',
      // visibleRange: function (view) {
      //   if (view.view.type === 'timeGridWeek') {
      //     console.log(view.view.type, "true");
      //     return ({ start: '2020-09-07', end: '2020-09-11' });
      //   }

      // },
      // visibleRange: {
      //   start: '2020-09-07',
      //   end: '2020-09-11'
      // },
      hiddenDays: [],
      events: (start) => {
        //console.log("dates:", start.start, start.end);
        //   this.appointmentService.fetchAp pointments(start.start, start.end, []);
      },
      datesRender: (view, element) => {
        console.log(view.view.currentStart, view.view.currentEnd);
        this.appointmentService.fetchAppointments(view.view.currentStart, view.view.currentEnd);
      },
      datesDestroy: (view, element) => {
        //this.appointmentService.fetchAppointments(view.view.activeStart, view.view.activeEnd, []);
      },
      eventClick: (eventClickEvent) => {
        this.handleEvent('Clicked', eventClickEvent.event._def.extendedProps);
      },
      fixedWeekCount: false,
      editable: true
    };

    this.options2 = {
      timeZone: 'UTC',
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
      defaultView: 'listDay',
      // views: {
      //   listDay: { buttonText: 'Day' },
      //   listWeek: { buttonText: 'Week' },
      //   listMonth: { buttonText: 'Month' },
      //   listYear: { buttonText: 'Year' }
      // },
      header: {
        left: 'prev,next',
        center: '',
        right: 'title'
      },
      events: (start) => {
        console.log("options2 event", start.currentStart, start.currentEnd);
        //   this.appointmentService.fetchAppointments(start.start, start.end, []);
      },
      datesRender: (view, element) => {
        console.log("options2", view.view.currentStart, view.view.currentEnd);
        this.appointmentService.fetchAppointments(view.view.currentStart, view.view.currentEnd);
      },
      datesDestroy: (view, element) => {
        //this.appointmentService.fetchAppointments(view.view.activeStart, view.view.activeEnd, []);
      },
      eventClick: (eventClickEvent) => {
        this.handleEvent('Clicked', eventClickEvent.event._def.extendedProps);
      },
      fixedWeekCount: false,
      editable: true
    };

    this.appointmentService.checkCalendarExist().then((isCalendar) => {
      this.showBookAppt = isCalendar;
    });
    // this.viewDate = new Date();
    // this.fetchAppointments(this.startDate, this.endDate);

    // this.appointmentService.getCurrentAccountDetails().then((snapshot) => {
    //   this.providersCount = snapshot.size;
    //   snapshot.forEach((doc1) => {
    //     this.providerstatus = doc1.data()["calendar_link"];
    //     // this.providersCount=this.providerstatus.

    //     let providerName = doc1.data()["linked_calendar"];
    //     if (this.providerstatus) {
    //       let providerName = doc1.data()["name"];
    //       this.providersArray.push(doc1.data()["name"]);
    //     }
    //   });

    //   this.allProviders = this.providersArray;
    //   let sizeOfProviders = this.providersArray.length;
    //   for (let i = 0; i < sizeOfProviders; i++) {
    //     if (this.allCheckBoxValue === true) {
    //       this.providers.push(true);
    //       this.isDisabled = true;
    //     } else {
    //       this.providers.push(false);
    //       this.isDisabled = false;
    //     }
    //   }
    // });
  }

  ngAfterViewInit() {
    this.bindEvents();
  }

  bindEvents() {
    //let prevButton = this.fullCalendar.el.nativeElement.getElementsByClassName("fc-prev-button");
    //let nextButton = this.fullCalendar.el.nativeElement.getElementsByClassName("fc-next-button");
    // let monthButton = this.fullCalendar.el.nativeElement.getElementsByClassName("fc-month-button");
    // let weekButton = this.fullCalendar.el.nativeElement.getElementsByClassName("fc-agendaWeek-button");
    // let dayButton = this.fullCalendar.el.nativeElement.getElementsByClassName("fc-agendaDay-button");
    // nextButton[0].addEventListener('click', () => {
    //   console.log("nextClicked")
    // });
    // prevButton[0].addEventListener('click', () => {
    //   console.log("prevClicked");
    // });
    // monthButton[0].addEventListener('click', () => {
    //   console.log("monthClick")
    // });
    // weekButton[0].addEventListener('click', () => {
    //   console.log("weekClick")
    // });
    // dayButton[0].addEventListener('click', () => {
    //   console.log("dayClick")
    // });
  }

  unBindEvents() {
    // let prevButton = this.fullCalendar.el.nativeElement.getElementsByClassName("fc-prev-button");
    // let nextButton = txhis.fullCalendar.el.nativeElement.getElementsByClassName("fc-next-button");
    // nextButton[0].removeEventListener('click', () => {
    //   console.log("nextClick");
    // });
    // prevButton[0].removeEventListener('click', () => {
    //   console.log("prevClickasd");
    // });
  }

  ngOnDestroy() {
    this.unBindEvents();
  }

  scheduleNewAppointment() {
    let accName = this.accountService.getCurrentAccDetails().account_slug;
    this.link = `${environment.apptURL}/account/${accName}`;
    //console.log(this.link);
    this.openDialog(this.link, "Schedule New Appointment");
  }

  openApptDetailsDialog(data: any, title: string) {
    console.log("test...........");
    const dialogRef = this.dialog.open(AppointmentDetailComponent, {
      panelClass: "custom-dialog-container",
      disableClose: true,
      height: "550px",
      width: "400px",
      data: { apptDetails: data, title: title },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //if (result.edited) {
      //this.appointmentService.updateStatusAndNotes(this.appointments, result);
      let appointment = result.appointment;
      let status = result.status;
      let notes = result.notes;
      if (result.statusChanged) {
        if (status === "completed" || status === "no-show") {
          this.appointmentService.saveAppointmentStatus(appointment.ref, status)
            .then(() => {
              if (status === "completed")
                appointment.contact.update({ last_visited: appointment.appt_end });
              appointment.status = status;
              this.appointmentService.updateAppointments(this.appointments);
              this.snackBar.open(
                "The appointment has been marked as '" + (new TitleCasePipe().transform(status)) + "'.",
                "",
                { duration: 2000 }
              );
            });
        }
        else if (status === "cancelled" || status === "rescheduled") {
          appointment.ref.get().then((doc) => {
            let status = doc.data().status;
            console.log(status, appointment.status);
            let statusChanged = status === appointment.status ? false : true;
            if (statusChanged) {
              appointment.status = status;
              this.appointmentService.updateAppointments(this.appointments);
              this.snackBar.open(
                "The appointment has been marked as '" + (new TitleCasePipe().transform(result.status)) + "'.",
                "",
                { duration: 2000 }
              );
            }
          });
        }
      }
      if (result.notesEdited)
        this.appointmentService.saveAppointmentNotes(appointment.ref, notes)
          .then(() => {
            appointment.notes = notes;
            this.appointmentService.updateAppointments(this.appointments);
          });
      //}
    });
  }

  openDialog(link: string, title: string) {
    const dialogRef = this.dialog.open(ScheduleNewAppointmentComponent, {
      panelClass: "custom-dialog-container",
      height: "520px",
      width: "400px",
      data: { link: link, title: title },
    });

    dialogRef.afterClosed().subscribe((closed) => {
      console.log("closed");
    });
  }
  calendarSelected() {
    this.isCalendarSelected = true;
    this.updateViewDate();
  }
  clickNext() {

  }
  // getAppointments(startDate, endDate) {
  //   let apps = this.appointmentStatsService.fetchAppointments(startDate, endDate);
  //   apps.then((querySnapshot) => {
  //     querySnapshot.forEach((doc) => {
  //       console.log(doc.data().appt_start.toDate());
  //     })
  //   });
  //   //console.log("apps: ", apps);
  // }
}
