/*
 * Used in a test component
 * Was trying to perform CRUD operations on lists/sub-lists of contacts
 */

import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { Subject } from 'rxjs';
import { AngularFirestore } from 'angularfire2/firestore';
import * as _ from 'lodash';
import * as moment from 'moment';

import { HttpClient } from '@angular/common/http';
import { AccountService } from './account.service';
import { AlertService } from './alert.service';
import { FormService } from '../_services/form.service';
import { environment } from '../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ContactListService {
	ref:any;
	listReference:any=[];
	listContactReference:any=[];
	ListCollection=[];
	ListNameCollection=[];
	ListName=[];
	Reference=[];
	newlistref=[];

  constructor(
  	private http: HttpClient,
  	  private _snackBar: MatSnackBar
  	) { }


	async createList(listName,contactref)
	{

		let newlist={
			name:listName,
			created_on:moment().toDate()

		}
		this.ListName.push(listName);
		let listref=await firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists').add(newlist);

		this.addsubcollection(listref,contactref);
	}


	async addsubcollection(listref,contactref)
	{

		for(var i=0;i<contactref.length;i++)
		{
			let listContact=await listref.collection('listContacts').add(
							{
				 				contactRef:contactref[i],
								added_on:moment().toDate()
			 				});
			//console.log("Addedd Succfully");
			//console.log(listContact);


		}
		this._snackBar.open("List Create Successfully","",
                                          {
                                             duration: 4000,
                                             verticalPosition: 'top',
                                             panelClass: ['mat-snack-bar-container']
                                           }
                            );
	}

	// async showallcontact(listContact)
	// {
	// 	let listData=listContact.id;
	// 	//console.log(listData);
	// 	this.newlistref.push(listData);
	// 	//console.log(this.newlistref);
	// 	return this.newlistref;
	// }
	ShowList()
	{
		this.ListNameCollection=[];
		let db=firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists').get();
		console.log(db);
				db.then(
                                accountsSnapshot => {
                                  if(!accountsSnapshot.empty)
                                  {
                                    accountsSnapshot.forEach(
                                      docSnapshot => {

                                        let doc=docSnapshot.data();
                                        this.ref=docSnapshot.ref;
                                        this.Reference.push(this.ref)
                                        this.ListCollection.push(doc);
                                        this.ListNameCollection.push(doc.name)
                                                   }
                                                           );
                                    }
                                    else
                                    {
                                    	this._snackBar.open("No list","",
                                          {
                                             duration: 4000,
                                             verticalPosition: 'top',
                                             panelClass: ['mat-snack-bar-container']
                                           }
                            );
                                    }
                                             });


		return this.ListNameCollection;

	}


	async addtolist(listName,contactref)
	{
		let db=await firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists');
		let listdoc= await firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists').where('name','==',listName).get();
		listdoc.forEach(
			listDoc=>{
				for(var i=0;i<contactref.length;i++)
				{
					let addlist= db.doc(listDoc.id).collection('listContacts').add(
									{
						 				contactRef:contactref[i],
										added_on:moment().toDate()
					 				});
					// console.log("Addedd Succfully");



				}
			})

			console.log("Addedd Succfully");

	}

	/*async AddList(listName,contactref)
	{
		let newref=await firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists');
		let queryRef = newref.where('name' ,'==',listName);
		let query=queryRef.get().then(snapshot => {
													snapshot.forEach(doc => {
      												//console.log(doc.id, '=>', doc.data());
      												for(var i=0;i<contactref.length;i++)
														{
	  															newref.doc(doc.id).collection('listContacts').add(
																{
													 				contactRef:contactref[i],
																	added_on:moment().toDate()
												 				});
	      												}
    												});
												  }
									);
		 this._snackBar.open("Added-Successfully","",
                                          {
                                             duration: 10000,
                                             verticalPosition: 'top',
                                             panelClass: ['mat-snack-bar-container']
                                           }
                            );
	}*/


	async deleteFromList()
	{
		let unresolvedAppts = [];
		let newref=firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists');
		let query=newref.get().then(
									snapshot => {
													snapshot.forEach(doc =>
													{
														let collectionRef;
      													collectionRef=newref.doc(doc.id).collection('listContacts');
      													collectionRef.get().then(
      																			snapshotforsubcollection=>{
      																						snapshotforsubcollection.forEach(doc=>{
      																								//this.listContactReference.push(doc.ref);
      																								collectionRef.doc(doc.id).delete();
      																								//this.listContactReference.push(collectionRef.doc(doc.id));
      																								//doc.ref.remove();
      																						})	;
      																			}
      																			);
      													newref.doc(doc.id).delete();
    												});
												  }

									);
		 this._snackBar.open("Remove from Subcollection Successfully","",
                                          {
                                             duration: 10000,
                                             verticalPosition: 'top',
                                             panelClass: ['mat-snack-bar-container']
                                           }
                            );/*
        this.listReference=[];
		let newref=await firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists').get();
        newref.forEach(collectionDoc =>
		{
			let ref=collectionDoc.ref;
			this.getInnerCollectionData(ref);
			ref.delete();
		});

		var arr=[];

		for( var i = 0; i < arr.length; i++)
		{
			  arr.pop();
			  this.ListNameCollection.pop();
		}

		 this._snackBar.open("Remove List Successfully","",
                                          {
                                             duration: 10000,
                                             verticalPosition: 'top',
                                             panelClass: ['mat-snack-bar-container']
                                           }
                            );*/

	}


	async deleteContactFromList(listName)
	{
		this.listContactReference=[];
		let newref=await firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists').where('name' ,'==',listName).get();
		newref.forEach(collectionDoc =>
		{
			let ref=collectionDoc.ref;
			this.getInnerCollectionData(ref);
			ref.delete();
		});
		var arr=[];
		arr=this.ListNameCollection;
		for( var i = 0; i < arr.length; i++)
		{
			   if ( arr[i] === listName)
			   {
			     arr.splice(i, 1);
			   }
		}
		this.ListNameCollection=arr;
		this._snackBar.open("ListConatct Removed","",
                                          {
                                             duration: 10000,
                                             verticalPosition: 'top',
                                             panelClass: ['mat-snack-bar-container']
                                           }
                            );

	}

	ShowContactFromList(listName)
	{
		 var i=0;
		 let listContactCreatedOn=[];
		 let outerCollref=firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists').where('name' ,'==',listName);
		 let innerCollRef=outerCollref.get().then(snapshot => {
								snapshot.forEach(doc =>
								{
									let collectionRef;
										collectionRef=doc.ref.collection('listContacts');
										collectionRef.get().then(
																snapshotforsubcollection=>{
																			snapshotforsubcollection.forEach(doc=>{
																				//listContactCreatedOn[i]=doc.data();
																				let var1=doc.data().contactRef;
																				var1.get().then(doc=>{
																						let dataForContact=doc.data();
																						listContactCreatedOn[i]=dataForContact.first_name;
																						i++;
																				});
																			})	;
																}
																);
									}

								);
							  }	);
		  return listContactCreatedOn;
	}



	/*async ShowContactFromList(listName)
	{
		var i=0;
		let listContactCreatedOn=[];
		this.listContactReference=[];
		let newref=await firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists').where('name' ,'==',listName).get();
        newref.forEach(collectionDoc =>
		{
			let subCollectionRef;
			let ref=collectionDoc.ref;
			this.listContactReference=this.getInnerCollectionData(ref);
			/*subCollectionRef=ref.collection('listContacts').get();
			subCollectionRef.forEach(doc=>{
				listContactCreatedOn[i]=doc.data().created_on;
				i++;
			});

		});
		console.log("*****************");
		console.log(this.listContactReference);
	}*/
	async getInnerCollectionData(ref)
   {
   		let subCollectionRef;
   		this.listContactReference=[];
   		var array=[]
   		var i=0;
   		this.listContactReference=array;
		subCollectionRef=await ref.collection('listContacts').get();
		subCollectionRef.forEach(doc=>{
			this.listContactReference[i]=doc.ref;
			console.log(doc.data());
			i++;
		});
		for(var i=0;i<this.listContactReference.length;i++)
		{
			await this.listContactReference[i].delete();
		}
		ref.delete();

   }
   /*showContactList(listContactReference)
   {
   	 var listContactCreatedOn=[];
   	 console.log(listContactReference);
			for(var i=0;i<listContactReference.length;i++)
			{
				listContactCreatedOn[i]=listContactReference[i].added_on;
			}
			return listContactCreatedOn;
   }*/
   async deleteList(listContactReference)
	{
		for(var i=0;i<listContactReference.length;i++)
		{
			await listContactReference[i].delete();
		}
		console.log("2");
	}

	RemoveDocument(id,listName)
	{
		var i=0;
		let outerCollref=firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists').where('name' ,'==',listName);
		 let innerCollRef=outerCollref.get().then(snapshot => {
								snapshot.forEach(doc =>
								{
									let collectionRef;
										collectionRef=doc.ref.collection('listContacts');
										collectionRef.get().then(
																snapshotforsubcollection=>{
																			snapshotforsubcollection.forEach(doc=>{
																				if(i==id)
																				{
																					doc.ref.delete();
																				}
																				i++;
																			})	;
																}
																);
									}

								);
							  }	);
	}

	/*async deleteContactFromList(listName)
	{
		this.GetListContactReference(listName);
		this.listContactReference=[];
		let newref=await firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists');
		let queryRef = newref.where('name' ,'==',listName);
		let query=queryRef.get().then(snapshot => {
													snapshot.forEach(doc =>
													{
														let collectionRef;
      													collectionRef=newref.doc(doc.id).collection('listContacts');
      													collectionRef.get().then(
      																			snapshotforsubcollection=>{
      																						snapshotforsubcollection.forEach(doc=>{
      																								//this.listContactReference.push(doc.ref);
      																								collectionRef.doc(doc.id).delete();
      																								//this.listContactReference.push(collectionRef.doc(doc.id));
      																								//doc.ref.remove();
      																						})	;
      																			}
      																			);
    												});
												  }
									);
		//console.log("In getListContactReference");
		console.log(this.listContactReference);
		//this.deleteContactFromList(this.listContactReference);
		 this._snackBar.open("List Removed ","",
                                          {
                                             duration: 10000,
                                             verticalPosition: 'top',
                                             panelClass: ['mat-snack-bar-container']
                                           }
                            );

	}*/

	/*async deleteContactFromList(listContactReference)
	{
		console.log(listContactReference);
		for(var i=0;i<5;i++)
		{
				console.log(i);
				if(listContactReference[i]!=undefined)
				{
					console.log(i);
				listContactReference[i].delete();
				}

		}
	}*/



	getContactreference()
	{
			 //let acc_id=sessionStorage.getItem("current_acc_id");
		var array= firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('contacts')
							.limit(10).get();
			  return array;
	}

	getListReference()
	{
		this.listReference=[];
		var array=[];
		var i=0;
		let newref=firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('lists');
		let query=newref.get().then(
									accountsSnapshot => {
		                                  if(!accountsSnapshot.empty) {
		                                    accountsSnapshot.forEach(
		                                      docSnapshot => {
		                                      					array.push(docSnapshot.ref);
		                                      					this.listReference[i]=docSnapshot.ref;

		                                                   	 }
		                                                           );
		                                                               }

		                                                }

									);
		console.log(this.listReference);
		return this.listReference;
	}



}

