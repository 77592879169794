<div class="row header" style="height: 10%">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <div style="text-align: center;width: 100%;">
            <b>{{popupHeader}}</b>
        </div>
    </div>
    <div class="col-md-2">
        <div *ngIf="type != 'CREATE' " style="width: 100%;text-align: right;padding-right: 5px;" [disabled]="true">
            <i *ngIf="!popupLoder" class="pi pi-times" matDialogClose style="font-size: 1.5rem;cursor: pointer;"></i>
        </div>
        <div *ngIf="type == 'CREATE' " style="width: 100%;text-align: right;padding-right: 5px;" [disabled]="true">
            <i class="pi pi-times" matDialogClose style="font-size: 1.5rem;cursor: pointer;"></i>
        </div>
    </div>
</div>
<div *ngIf=" contentType.length == 0" style="padding-top: 0%;">
    <div style="text-align: center;">
        <i class="pi pi-spin pi-spinner" id="spinIcon1"></i>
    </div>
</div>
<div style="overflow-x: hidden;overflow-y:hidden;" *ngIf=" contentType.length > 0">
    <div>
        <div class="alert alert-danger" style="margin-bottom: 10px;" role="alert" id="all" *ngIf="TemplateFlag">
            No template available for generate post!
            <div style="float: right;">
                <a (click)="addTemplate()">Click here to create template</a>
            </div>
        </div>
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChanged($event);">

            <mat-tab label="URL" *ngIf="(type == 'add') ||  url && type == 'update' || type == 'CREATE' ">
                <div class="row" style="margin: 2px;" *ngIf="type != 'CREATE' ">
                    <span style="margin-left: 20px;margin-right: 20px;">Enter template url:</span><br>
                    <input type="text" pInputText placeholder="Enter url to make social media post template"
                        [(ngModel)]="url" name="url" required style="width: 100%;margin-left: 1.2%;
                        margin-right: 1.2%;">
                </div>
                <div class="row" style="margin: 2px;" *ngIf="type == 'CREATE' ">
                    <span [disabled]="TemplateFlag" style="margin-left: 20px;margin-right: 20px;">Enter url:</span><br>
                    <input type="text" [disabled]="TemplateFlag" pInputText
                        placeholder="Enter url to make social media post" [(ngModel)]="url" name="url" required style="width: 100%;margin-left: 1.2%;
                        margin-right: 1.2%;">
                </div>
            </mat-tab>
            <mat-tab label="CODE" *ngIf="(type == 'add') ||  code && type == 'update' || type != 'CREATE' ">
                <div style="padding-top: 3px;" *ngIf="type != 'CREATE' ">
                    <span style="margin: 0px 20px 0px 20px">Paste template code:</span><br>
                    <textarea pInputTextarea [(ngModel)]="code" placeholder="Paste code" resizable="false"
                        class="form-control" style="width: 95%;height: 150px;margin: 0px 20px 0px 20px;"></textarea>
                </div>
                <!-- <div style="padding-top: 3px;" *ngIf="type == 'CREATE' ">
                    <span style="margin: 0px 20px 0px 20px">Paste url code:</span><br>
                    <textarea pInputTextarea [(ngModel)]="code" placeholder="Paste code" resizable="false"
                        class="form-control" style="width: 95%;height: 150px;margin: 0px 20px 0px 20px;"></textarea>
                </div> -->
            </mat-tab>

        </mat-tab-group>
    </div>
    <div style="margin-left:3.1em;">
        <mat-radio-group aria-label="Select an option" *ngIf="type == 'CREATE' ">
            <mat-radio-button value="default" (change)="radioChange($event)" checked>Default Template</mat-radio-button>
            <mat-radio-button style="margin-left: 1em;" value="specific" (change)="radioChange($event)">Specific
                Template</mat-radio-button>
        </mat-radio-group>
    </div>
    <div style="width: auto;margin-left: 3em;margin-right: 0em;"
        *ngIf="type == 'CREATE' &&  this.templateMode == 'specific' ">
        <div style="text-align: left;margin-top: 20px;"><b id="example-radio-group-label">Select
                template</b>
        </div>
        <div style="height: auto;margin-top: 2%;">
            <p-dropdown [options]="templates" [(ngModel)]="selectedTemplate" [style]="{'width':'95%'}"
                (onChange)="getSelectedTemplate($event)" optionLabel="name"></p-dropdown>
        </div>
    </div>

    <div class="row" style="margin: 0px;margin-bottom: 18px;" *ngIf="type != 'CREATE' ">
        <span style="margin-left: 59px;margin-right: 59px">Enter template name:</span><br>
        <input type="text" (input)="onSearchChange($event.target.value)" pInputText placeholder="Enter template name"
            [(ngModel)]="templateName" name="templateName" required
            style="width: 100%;margin-left: 59px;margin-right: 59px;">
        <!-- <mat-error *ngIf="socialMediaPostFormGroup.get('reviewerName').hasError('required')"> Name should not be blank -->
        <!-- </mat-error> -->
        <mat-error *ngIf="nameError" style="margin-left: 59px;"> Duplicate Name
        </mat-error>
    </div>

    <div class="row" style="width: 100%;margin-left: 61px;margin-right: 20px;">
        <div class="col-md-3" *ngIf="type != 'CREATE' " style="text-align: left;padding-left: 0px;">
            <span>Select content type</span>
            <br>
            <p-dropdown *ngIf="contentType.length >0" [options]="contentType" placeholder="Select content type"
                appendTo="body" scrollHeight="200" optionLabel="type" [showClear]="false" [(ngModel)]="selectedType"
                [placeholder]="selectedType.type">
            </p-dropdown>
        </div>
        <div class="col-md-3" *ngIf="type != 'CREATE' ">
            <div *ngIf="selectedType">
                <span *ngIf="selectedType.type=='REVIEW'">
                    <span>Enter review comment limit:</span><br>
                    <input type="number" pInputText placeholder="Comment Limit" [(ngModel)]="commentLimit"
                        name="commentLimit" required style="width: 200px;">
                </span>
                <span *ngIf="selectedType.type=='OFFER'">
                    <span>Select Offer type</span>
                    <br>
                    <p-dropdown [options]="offerTypes" [(ngModel)]="offerType" [placeholder]="offerType.name"
                        appendTo="body" scrollHeight="200" optionLabel="name" [showClear]="false">
                    </p-dropdown>
                </span>
            </div>
        </div>
        <div class="col-md-6">
            <div *ngIf="type == 'add' " style="width: 100%;text-align: center;margin: 24px;">
                <button mat-raised-button
                    [disabled]="(!selectedType || !url || !submitBtnFlag || !templateName || !commentLimit || nameError) && (!selectedType || !code || !submitBtnFlag || !templateName || !commentLimit ||nameError)"
                    (click)="submit()"><i *ngIf="popupLoder" class="pi pi-spin pi-spinner"
                        style="margin-right: 10px;"></i>Add</button>
            </div>
            <div *ngIf="type == 'update' " style="width: 100%;text-align: center;margin: 24px;">
                <button mat-raised-button
                    [disabled]="(!selectedType || !url || !submitBtnFlag || !templateName || !commentLimit || nameError) && (!selectedType || !code || !submitBtnFlag || !templateName || !commentLimit ||nameError)"
                    (click)="submit()"><i *ngIf="popupLoder" class="pi pi-spin pi-spinner"
                        style="margin-right: 10px;"></i>Update</button>
            </div>
        </div>
    </div>
    <div class="row" style="width: 100%;height: 10%;">
        <div class="col-md-2">

        </div>
        <div class="col-md-8">

            <!-- <div *ngIf="popupLoder" style="padding-top: 0%;">
                <div style="text-align: center;">
                    <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                </div>
            </div> -->
            <div style="text-align: center;">
                <h6 *ngIf="successMsg && type == 'add' ">Template Added Successfuly.</h6>
                <h6 *ngIf="successMsg && type == 'update' ">Template Updated Successfuly.</h6>
                <h6 *ngIf="successMsg && type == 'CREATE' ">Post Generated Successfuly.</h6>
                <h5 *ngIf="errorMsg">Something is wrong please try again.</h5>
                <h6 *ngIf="popupLoder  && type == 'CREATE'">Posts will be generate soon, you can close the dialog.</h6>
            </div>
        </div>
        <div class="col-md-2">

        </div>
    </div>
    <div *ngIf="type == 'CREATE' " style="width: 100%;text-align: center;margin-top: 30px;">
        <button mat-raised-button [disabled]="!url || popupLoder" (click)="generatePosts()">
            <i *ngIf="popupLoder" class="pi pi-spin pi-spinner" style="margin-right: 10px;"></i>Generate
            Posts</button>
        <!-- <p-button [disabled]="!url || popupLoder" (click)="generateTemplates()" label="Generate Posts"
            icon="pi pi-search" [loading]="loading[0]" (onClick)="load(0)"></p-button> -->
    </div>
</div>