<div class="row container-fluid justify-content-center">
  <div *ngIf="!loading;else elseBlock"  class="info-cards">
    <mat-card>
      <mat-card-title>
        <div style="text-align: center;">
          <h2 class="login_header">{{ heading }}</h2></div>
      </mat-card-title>
      <mat-card-content>
        <p class="verification-msg">{{ msg }}</p>
        <div style="text-align: center;">
          <p>
            Go back to <a [routerLink]="['/']"><b>Login</b></a>
          </p>
          <p>
            Send another password reset link - <a [routerLink]="['/forgot-password']"><b>Reset Password</b></a>
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <ng-template #elseBlock>
    <div style="margin-top: 20%;" id="spinnerDiv">
      <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
    </div>
  </ng-template>
</div>
