<div class="row header" style="height: 10%">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <div style="text-align: center;width: 100%;">
            <b>{{popupHeader}}</b>
        </div>
    </div>
    <div class="col-md-2">
        <div style="width: 100%;text-align: right;padding-right: 5px;">
            <i class="pi pi-times" (click)="closeDialog()" style="font-size: 1.5rem;cursor: pointer;"></i>
        </div>
    </div>
</div>
<div class="alert alert-danger" style="margin-bottom: 10px;" role="alert" id="all" *ngIf="!templatesUrl">
    No template available for generate post!
    <div style="float: right;">
        <!-- <a (click)="addTemplate()">Click here to create template</a> -->
    </div>
</div>
<div *ngIf="!postData" style="padding-top: 200px;">
    <div style="text-align: center;">
        <i class="pi pi-spin pi-spinner" id="spinIcon1"></i>
    </div>
</div>
<form #postForm="ngForm">
    <div *ngIf="postData">
        <div class="row">
            <div class="col-md-6" *ngIf="editFlag">

                <div style="text-align: left;margin: 20px 20px 0px 20px;font-size: 15px;">
                    <b id="example-radio-group-label">Page Url
                        :</b> <span class="material-icons" style="margin-top:5px;cursor: pointer;"
                        (click)="openPageUrl(postData.pageUrl)">
                        link
                    </span>
                </div>
                <div>
                    <input type="text" pInputText placeholder="Page Url" style="margin: 20px 20px 0px 20px;width: 95%;"
                        [(ngModel)]="postData.pageUrl" [readonly]="true" name="pageUrl">
                </div>

                <div *ngFor="let obj of ejsTemplateContent;let i=index">
                    <div>
                        <span *ngIf="obj.editType == 'SINGLE_LINE'; then text"></span>
                        <span *ngIf="obj.editType == 'MULTI_LINE'; then textarea"></span>
                    </div>

                    <ng-template #text>
                        <div style="text-align: left;margin: 20px 20px 0px 20px;font-size: 15px;"><b
                                id="example-radio-group-label">{{obj.key | titlecase}} :</b></div>
                        <input type="text" pInputText placeholder="Update question" [(ngModel)]="postData[obj?.key]"
                            name={{postData[obj?.key]}} [readonly]="true" required
                            style="width: 95%;margin:20px 20px 0px 20px">
                    </ng-template>
                    <ng-template #textarea>
                        <div style="text-align: left;margin: 20px 20px 0px 20px;font-size: 15px;"><b
                                id="example-radio-group-label">{{obj.key | titlecase}}:</b></div>
                        <textarea pInputTextarea [(ngModel)]="postData[obj?.key]" placeholder="Update answer"
                            name={{postData[obj?.key]}} resizable="false" class="form-control" [readonly]="true"
                            required style="width: 95%;height: 150px;margin: 20px 20px 0px 20px;"></textarea>
                    </ng-template>
                </div>

                <button mat-raised-button (click)="editPost()" [disabled]="updateBtn"
                    style="text-align: left;margin: 20px 20px 0px 20px;font-size: 15px;"
                    [disabled]="!templatesUrl || !postForm.valid">
                    Edit
                </button>
            </div>
            <div class="col-md-6" *ngIf="editFlag">
                <div style="text-align: center;margin-top: 5%">
                    <b>Post Preview</b>
                </div>
                <div style="text-align: center;margin-top: 5%">
                    <img [src]="post.postUrl" style="height:300px;width: 300px;" (load)="imageLoadingStatus($event)">
                </div>
            </div>
            <div class="col-md-6" *ngIf="!editFlag">
                <ng-container>
                    <div>
                        <div style="text-align: left;margin: 20px 20px 0px 20px;font-size: 15px;">
                            <b id="example-radio-group-label">Page Url
                                :</b> <span class="material-icons" style="margin-top:5px;cursor: pointer;"
                                (click)="openPageUrl(postData.pageUrl)">
                                link
                            </span>
                        </div>
                        <div>
                            <input type="text" pInputText placeholder="Page Url"
                                style="margin: 20px 20px 0px 20px;width: 95%;" [(ngModel)]="postData.pageUrl"
                                [readonly]="true" name="pageUrl">
                        </div>
                        <div *ngFor="let obj of ejsTemplateContent;let i=index">

                            <div>
                                <span *ngIf="obj.editType == 'SINGLE_LINE'; then text"></span>
                                <span *ngIf="obj.editType == 'MULTI_LINE'; then textarea"></span>
                            </div>

                            <ng-template #text>
                                <div style="text-align: left;margin: 20px 20px 0px 20px;font-size: 15px;"><b
                                        id="example-radio-group-label">{{obj.key | titlecase}} :</b></div>
                                <input type="text" pInputText placeholder="Update question"
                                    [(ngModel)]="postData[obj?.key]" name={{postData[obj?.key]}} required
                                    style="width: 95%;margin:20px 20px 0px 20px">
                            </ng-template>
                            <ng-template #textarea>
                                <div style="text-align: left;margin: 20px 20px 0px 20px;font-size: 15px;"><b
                                        id="example-radio-group-label">{{obj.key | titlecase}}:</b></div>
                                <textarea pInputTextarea [(ngModel)]="postData[obj?.key]" placeholder="Update answer"
                                    name={{postData[obj?.key]}} resizable="false" class="form-control" required
                                    style="width: 95%;height: 150px;margin: 20px 20px 0px 20px;"></textarea>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>
                <div>
                    <button mat-raised-button (click)="updatePost()" [disabled]="updateBtn"
                        style="text-align: left;margin: 20px 20px 0px 20px;font-size: 15px;"
                        [disabled]="!templatesUrl || !postForm.valid"><i *ngIf="updateBtn" class="pi pi-spin pi-spinner"
                            style="margin-right: 10px;"></i>Generate
                        Post
                    </button>
                </div>
                <!-- <div *ngIf="updateBtn" style="padding-top: 0%;margin-top: 0px;">
                    <div style="text-align: center;">
                        <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                    </div>
                </div> -->
            </div>
            <div class="col-md-6" *ngIf="!editFlag">
                <div style="width: 100%;">
                    <div style="text-align: left;margin-top: 20px;"><b id="example-radio-group-label">Select
                            template</b>
                    </div>
                    <div style="height: auto;margin-top: 2%;">
                        <p-dropdown [options]="templates" [(ngModel)]="selectedTemplate" [style]="{'width':'95%'}"
                            (onChange)="getSelectedTemplate($event)" optionLabel="name"></p-dropdown>
                    </div>
                </div>
                <div style="text-align: center;margin-top: 5%">
                    <b>Template Preview</b>
                </div>
                <div style="text-align: center;margin-top: 5%" *ngIf="templatesUrl">
                    <img [src]="templatesUrl" style="height:300px;width: 300px;" (load)="imageLoadingStatus($event)">
                </div>
            </div>
        </div>

    </div>
</form>