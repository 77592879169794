<!--<div *ngIf="isReady">-->
<!--<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">OK</button>
  </div>
</ng-template>

-->
<!-- <app-appointment-stats [value]="appointmentStats"></app-appointment-stats> -->
<!-- <div class="ui-g" *ngIf="loading else loaded">
  <mat-spinner></mat-spinner>
</div> -->

<div class="calendar">
  <div>
    <div id="pageTitle"> Appointments </div>
    <div class="searchContainer">
      <i class="fa fa-search searchIcon"></i>
      <input class="searchBox" type="text" name="search" placeholder="Search appointment">
    </div>
  </div>
  <div class="row text-center">
    <div class="col-md-4" style="margin: auto;">
      <!-- <h4>Appointments</h4> -->
      <div id="pageTitle"> Appointments </div>
      <div class="searchContainer">
        <i class="fa fa-search searchIcon"></i>
        <input class="searchBox" type="text" name="search" placeholder="Search appointment">
      </div>
    </div>

    <div class="col-md-6" style="margin: auto;">
      <div class="row">
        <div class="col-md-4" style="background-color:white;">
          <div class="btn-group desktop-only">
            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="updateViewDate()">
              Previous
            </div>
            <!-- class="btn btn-outline-secondary" -->
            <div class="btn btn-primary" mwlCalendarToday [(viewDate)]="viewDate" (click)="updateViewDate()">
              Today
            </div>
            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="updateViewDate()">
              Next
            </div>
          </div>
          <!---Arrows--->
          <div class="btn-group mobile-view">
            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="updateViewDate()">
              <i class="material-icons icon-btn">arrow_back</i>
            </div>
            <div class="btn btn-primary" mwlCalendarToday [(viewDate)]="viewDate" (click)="updateViewDate()">
              Today
            </div>
            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="updateViewDate()">
              <i class="material-icons">arrow_forward</i>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <h4>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h4>
        </div>
        <div class="col-md-4">
          <div class="btn-group">
            <div class="btn btn-primary" (click)="view = CalendarView.Month; updateViewDate()"
              [class.active]="view === CalendarView.Month">
              Month
            </div>
            <div class="btn btn-primary" (click)="view = CalendarView.Week; updateViewDate()" [class.active]="
              view===CalendarView.Week">
              Week
            </div>
            <div class="btn btn-primary" (click)="view = CalendarView.Day; updateViewDate()"
              [class.active]="view === CalendarView.Day">
              Day
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <button class="btn-primary" style="margin: 10px;" mat-raised-button color="primary"
        (click)="scheduleNewAppointment()" [disabled]="!showBookAppt">Make New Appointment</button>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-9" style="margin: auto;">
      <ng-container *ngIf="providersCount>0" style="margin: auto;">
        <mat-checkbox (change)="OnSelectingAllProvider($event,allProvidersValue)" [checked]="allCheckBoxValue"
          [ngModel]="allCheckBoxValue" name="allProviders" style="margin-left:35px;">All</mat-checkbox>
        <mat-checkbox *ngFor="let provider of providersArray ; let i=index " name="{{i}}-name"
          (change)="OnChangeProvider(provider,$event,i)" [checked]="false" [(ngModel)]="providers[i]"
          [disabled]="isDisabled" style="margin-left:35px;"> {{provider}}</mat-checkbox>
      </ng-container>
    </div>
    <div class="col-md-3">
      <mat-form-field style="width: 100%; font-size: 15px;">
        <mat-label>Select your calender</mat-label>
        <mat-select [(value)]="selectedCalendar" (selectionChange)="calendarSelected()" multiple>
          <mat-option *ngFor="let calendar of calendarType" [value]="calendar.slug" style="font-size: 15px;">
            {{calendar.slug}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div> -->
</div>
<!-- <div class="row">
  <div class="col-md-12">
    <app-appointment-stats [value]="appointmentStats"></app-appointment-stats>
  </div>
</div> -->
<!-- <div [ngSwitch]="view"> -->
<!-- <div *ngIf="view==CalendarView.Month" style=" margin-bottom:15px;" class="col-md-4">
    <i class="material-icons"
      style="font-size:60px;margin-left:10px;margin-top:55px; cursor: pointer; position: absolute;"
      title="Make Appointment" (click)="scheduleNewAppointment()" [disabled]="!showBookAppt">add_circle</i>
  </div> -->
<!-- <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="appointmentEvent"
    [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="appointmentEvent"
    [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="appointmentEvent"
    [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-day-view> -->
<!-- </div> -->
<div class="">
  <div class="desktop-only">
    <p-fullCalendar #fullCalendar [events]="appointmentEvent" [options]="options1">
    </p-fullCalendar>
  </div>
  <div class="mobile-view">
    <p-fullCalendar #fullCalendar [events]="appointmentEvent" [options]="options2">
    </p-fullCalendar>
  </div>
</div>
<br>