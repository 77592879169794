<div class="container" style="height: 100%;">
  <div style="text-align: left;">
    <p>
      Setting up your account, hang in there<br>
    </p>
  </div>
  <div style="text-align: center;">
    <p-progressBar [value]="completedPer"></p-progressBar>
  </div><br>
  <div style="padding: 5px;height:60%; overflow-y: auto; ">
    <p-table [value]="products" >
    <ng-template pTemplate="body" let-product>
        <tr >
          <td style="width: 70%; border: 0px ;">
            {{product.displayName|titlecase}}
            <span *ngIf="product.status=='incomplete';else demoDataLogs">: Creating demo {{product.displayName}}</span>
            <ng-template #demoDataLogs>: Created {{product.records}} demo {{product.displayName}}</ng-template>
           </td>
          <td style="width: 30%;text-align: center;border: 0px ;">{{product.status|titlecase }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div style="margin: 5px;">
    <p>
      <p-checkbox [disabled]="this.completedPer!=100" name="acknowledge" value="val1" [(ngModel)]="acknowledge">
      </p-checkbox>
      I acknowledge that this is just dummy data created for testing
    </p>
  </div>
  <div>
    <button pButton type="button" label="I Agree" [disabled]="!acknowledge || this.completedPer!=100"
      (click)="acceptAcknowledge()"></button>
  </div>
</div>
