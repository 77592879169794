<div class="contanier-fluid view" *ngIf="enableDetailsOfMerged">
  <div>
    <h4>Merge Contact</h4>
    <br><br>
    <div class="close" (click)="close()">X</div>
    <form #contactData="ngForm" (ngSubmit)="saveContact(contactData)" name='contactForm'>

      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width cursor">
            <input type="text" placeholder="Enter First Name" aria-label="First Name" matInput
              [formControl]="formCantrol_1" [matAutocomplete]="auto1" name="first_name" [(ngModel)]="first_name">
            <mat-autocomplete #auto1="matAutocomplete">
              <mat-option *ngFor="let first_name of firstNameArray" [value]="first_name">
                {{first_name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input type="text" placeholder="Enter Last Name" aria-label="Last Name" matInput
              [formControl]="formCantrol_2" [matAutocomplete]="auto2" name="last_name" [(ngModel)]="last_name">
            <mat-autocomplete #auto2="matAutocomplete">
              <mat-option *ngFor="let last_name of lastNameArray" [value]="last_name">
                {{last_name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <br>

      <div class="row">
        <div class="col">
          <h6>Phone</h6>
          <p-table #dt1 [value]="phoneData" [(selection)]="selectedPhoneContacts" [rows]="rows" [scrollable]='true'
            selectionMode="multiple">
            <!-- <ng-template pTemplate="header">
                <tr>
                    <th style="width: 5em; padding:5px 5px; ">
                      <p-tableHeaderCheckbox [disabled]="disabledPhoneSelection"></p-tableHeaderCheckbox>
                    </th>
                    <th style="width:20px">No.</th>
                    <th style="width:20px">Country  </th>
                    <th style="width:20px">phone</th>
                    <th style="width:20px">Select Primary</th>
                    <th style="width:20px">Actions</th>
                </tr>
            </ng-template> -->
            <ng-template pTemplate="body" let-phoneInfo>
              <tr>
                <!-- <td style="width: 5em; padding:5px 5px; box-shadow: none;">
                      <p-tableCheckbox style="font-weight: 100;" [value]="phoneData" [disabled]="disabledPhoneSelection"  [index]="index" (click)="selectPhoneRow()">
                      </p-tableCheckbox>&nbsp;
                    </td> -->

                <td style="width:20px">
                  {{phoneInfo.index}}
                </td>

                <td style="width:20px">
                  <!-- <input type="text"
                        placeholder="Enter Country Code"
                        aria-label="First Name"
                        matInput
                        [value]=[phoneData.countryCode]> -->
                  <!-- <p-dropdown [options]="cities" [(ngModel)]="phoneInfo.countryCode" placeholder="Select"
                    optionLabel="name" [ngModelOptions]="{standalone: true}">
                  </p-dropdown> -->

                  <p-dropdown [options]="codes" [(ngModel)]="selectedCar2" name="Country Code">
                    <ng-template let-item pTemplate="selectedItem">
                      <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
                    </ng-template>
                    <ng-template let-code pTemplate="item">
                      <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                        <div style="font-size:14px;float:right;margin-top:4px">{{code.label}}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <!-- <mat-form-field>
                    <mat-label>Country</mat-label>
                    <mat-select [(ngModel)]="phoneInfo.countryCode" [formControl]="countryCode_control">
                      <mat-option *ngFor="let country of countries" [value]="country">
                        {{country}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->

                <td style="width:20px">
                  <input type="text" placeholder="Enter Phone" aria-label="First Name" matInput
                    [formControl]="phone_control" [value]=[phoneInfo.phone]>
                  <!-- <p-inputMask [style]="{ width: '100%' }" mask="(999) 999-9999" [(ngModel)]="phoneInfo.phone"
                    name="Enter Phone" placeholder="(999) 999-9999" [formControl]="phone_control"></p-inputMask> -->
                </td>

                <td style="width:20px">
                  <button pButton type="button" *ngIf="!phoneInfo.primary else showPrimary" label="Make Primary"
                    (click)="makePhonePrimary(phoneInfo)">
                  </button>
                  <ng-template #showPrimary>
                    Primary
                  </ng-template>
                  <!-- <mat-checkbox name="phoneData.primary" [(ngModel)]="phoneData.primary" style="padding-left: 30%;" (change)="makePhonePrimary($event,phoneData)" #e></mat-checkbox> -->
                </td>
                <td style="width:20px">
                  <div *ngIf="phoneData.length > 1">
                    <i class="material-icons add" [value]="phoneData" style="padding-left: 30%;"
                      (click)="deletePhoneInfo(phoneInfo)">delete</i>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <br>
        </div>
      </div>
      <button pButton type="button" icon="pi pi-plus" label="Add" (click)="newPhoneInfoRow()"></button>
      <br>
      <br>

      <div class="row">
        <div class="col">
          <h6>Email</h6>
          <p-table [value]="emailData" [(selection)]="selectedEmailContacts" [rows]="rows">
            <!-- <ng-template pTemplate="header">
                  <tr>
                      <th style="width: 5em; padding:5px 5px; ">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </th>
                      <th style="width:10px">No.</th>
                      <th style="width:50px">Email</th>
                      <th style="width:20px">Select Primary</th>
                      <th style="width:20px">Actions</th>
                  </tr>
              </ng-template> -->
            <ng-template pTemplate="body" let-emailInfo>
              <tr>

                <!-- <td style="width: 5em; padding:5px 5px; box-shadow: none;">
                        <p-tableCheckbox style="font-weight: 100;" [value]="emailData" [index]="index" (click)="selectEmailRow()">
                        </p-tableCheckbox>&nbsp;
                      </td> -->
                <td style="width:10px">
                  {{emailInfo.index}}
                </td>

                <td style="width:50px">
                  <input type="text" placeholder="Enter Email Id" aria-label="First Name" matInput
                    [formControl]="email_control" [value]=[emailInfo.email]
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" size="10">
                </td>

                <td style="width:20px">
                  <button pButton type="button" *ngIf="!emailInfo.primary else showPrimary" label="Make Primary"
                    (click)="makeEmailPrimary(emailInfo)">
                  </button>
                  <ng-template #showPrimary>
                    Primary
                  </ng-template>
                  <!-- <mat-checkbox name="phoneData.primary" [(ngModel)]="phoneData.primary" style="padding-left: 30%;" (change)="makePhonePrimary($event,phoneData)" #e></mat-checkbox> -->
                </td>

                <td style="width:20px">
                  <div *ngIf="emailData.length > 1">
                    <i class="material-icons add" style="padding-left: 30%;" [value]="emailInfo"
                      (click)="deleteEmailInfo(emailInfo)">delete</i>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <br>
      <button pButton type="button" icon="pi pi-plus" label="Add" (click)="newEmailInfoRow()"></button>
      <br>
      <br>

      <div class="col">
        <div style="padding-top: 10px;padding-bottom: 10px;">
          <span style="display: inline;padding-right: 10px;">Send Text Reminder</span>
          <mat-slide-toggle name="send_text_reminders" [(ngModel)]="send_text_reminders">
          </mat-slide-toggle>
        </div>
        <div class="row-6">
          <mat-checkbox name="isMergedAppointment" [(ngModel)]="isMergedAppointment"
            (change)="mergedAppointmnet($event)">Merge Appointment</mat-checkbox>
        </div>
        <div class="row-6">
          <mat-checkbox name="isMergedNotes" [(ngModel)]="isMergedNotes" (change)="mergedNotes($event)">Merge Notes
          </mat-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-center">
          <button mat-raised-button type="submit" [disabled]="!contactData.valid">Merge contact</button>
        </div>
      </div>
    </form>
  </div>
</div>


<div *ngIf="loading else loaded">
  <mat-spinner></mat-spinner>
</div>

<ng-template #loaded>
  <div *ngIf="mergedContactData!=undefined">
    <h4>Merged Contact</h4>
    <div style="padding-bottom: 5%;">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{selectedContacts.length}} Contacts Merged into new contact with
            {{mergedContactData?.appointmentCount}} appointments and {{mergedContactData?.notesCount}} notes.
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="container-fluid view" *ngIf="selectedContacts!=undefined">
            <div class="mat-elevation-z8" style="margin:20px;">
              <p-table [value]="selectedContacts" [columns]="Columns" #console [globalFilterFields]="filterColumns"
                [paginatorShow] [showCurrentPageReport]="true" sortMode="multiple" [autoLayout]="true"
                [resizableColumns]="true">

                <ng-template pTemplate="header">
                  <tr>
                    <th [class]="col.class" *ngFor="let col of Columns"
                      [pSortableColumnDisabled]="disableSortColumns.includes(col.value)" [pSortableColumn]="col.value"
                      pResizableColumn>
                      {{col.header|titlecase}}
                      <p-sortIcon [field]="col.value" *ngIf="!disableSortColumns.includes(col.value)"
                        [field]="col.value">
                      </p-sortIcon>
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-newData>
                  <tr>
                    <td class="mid">{{newData.first_name}}</td>
                    <td class="mid">{{newData.last_name}}</td>
                    <td class="mid">{{newData.email}}</td>
                    <td class="mid">{{newData.phone}}</td>
                    <td class="mid">{{newData.active}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Newly Created Contact</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <table class="table" *ngIf="mergedContactData!=undefined">
            <tbody>
              <tr>
                <th scope="row">First Name</th>
                <td>{{mergedContactData.newContact.first_name}}</td>
              </tr>

              <tr>
                <th scope="row">Last Name</th>
                <td>{{mergedContactData.newContact.last_name}}</td>
              </tr>

              <tr>
                <th scope="row">Email</th>
                <td>{{mergedContactData.newContact.email}}</td>
              </tr>

              <tr>
                <th scope="row">Phone</th>
                <td>{{mergedContactData.newContact.phone}}</td>
              </tr>
              <!-- <tr>
                <th scope="row">Text Reminder</th>
                <td> -->
              <!-- <mat-slide-toggle [disabled]="newContact['active'] === false" name="send_text_reminders"
                [(ngModel)]="newContact['send_text_reminders']"
                (change)="updateValue(newContact['send_text_reminders'],'send_text_reminders',newContact['ref'])">
              </mat-slide-toggle> -->
              <!-- </td>
              </tr> -->

              <!-- <tr>
                <th scope="row">Last Visited</th>
                <td>{{mergedContactData.newContact.lastvisited}} </td>
              </tr> -->

              <tr>
                <th scope="row">Contact Created On</th>
                <td>{{ mergedContactData.created_on| date: "MMM d, yyyy" }}</td>
              </tr>
              <tr>
                <th scope="row">Active</th>
                <td> {{mergedContactData.newContact.active}}</td>
                <!-- <mat-slide-toggle class="formatToggle" name="greyRow" [(ngModel)]="newContact.active"
                (change)="updateValue(newContact.active,'active',newContact)"></mat-slide-toggle> -->
              </tr>
            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>