import { Component, OnInit, Input, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-ranking-on-first-page',
  templateUrl: './ranking-on-first-page.component.html',
  styleUrls: ['./ranking-on-first-page.component.scss']
})
export class RankingOnFirstPageComponent implements OnInit {
  @Input() data: any;
  @Input() dataSourceRank: any[];
  filterColumns: any = [];
  dataSource: any = [];
  enteredValue: string = "01.00";
  disableSortColumns: string[] = ['page', 'page', 'deltaImpressionDiff', 'deltaImpressionDiffPercentage', 'deltaImpressionSharePercentage',
    'lastTotalImpressionShare', 'prevTotalImpressionShare', 'deltaPositionDiff', 'lastWeekClickCount', 'prevWeekClickCount', 'lastWeekImpressionsCount',
    'prevWeekImpressionsCount', 'lastWeekCtrCount', 'prevWeekCtrCount', 'lastWeekPositionCount', 'prevWeekPositionCount'];

  displayedColumns: any[] = [
    { header: "Page", value: "page", class: "page" },
    { header: "Delta Impression ", value: "deltaImpressionDiff", class: "mid" },
    { header: "Delta Impression %", value: "deltaImpressionDiffPercentage", class: "mid" },
    { header: "Delta Share %", value: "deltaImpressionSharePercentage", class: "mid" },
    { header: "Last Impression Share %", value: "lastTotalImpressionShare", class: "mid" },
    { header: "Previous Impression Share %", value: "prevTotalImpressionShare", class: "mid" },
    { header: "Delta Position Difference", value: "deltaPositionDiff", class: "mid" },
    { header: "Last 7 Days Position ", value: "lastWeekPositionCount", class: "mid" },
    { header: "Previous 7 Days Position ", value: "prevWeekPositionCount", class: "mid" },
    { header: "Last 7 Days Clicks ", value: "lastWeekClickCount", class: "mid" },
    { header: "Previous 7 Days Clicks ", value: "prevWeekClickCount", class: "mid" },
    { header: "Last 7 Days Impressions ", value: "lastWeekImpressionsCount", class: "mid" },
    { header: "Previous 7 Days Impressions ", value: "prevWeekImpressionsCount", class: "mid" },
    { header: "Last 7 Days CTR ", value: "lastWeekCtrCount", class: "mid" },
    { header: "Previous 7 Days CTR ", value: "prevWeekCtrCount", class: "mid" },
  ];

  constructor() { }

  ngOnInit() {
    this.filterColumns = ['page', 'deltaImpressionDiff', 'deltaImpressionDiffpercentage', 'deltaImpressionSharePercentage',
      'lastTotalImpressionShare', 'prevTotalImpressionShare', 'deltaPositionDiff', 'lastWeekPositionCount', 'prevWeekPositionCount', 'lastWeekClickCount',
      'prevWeekClickCount', 'lastWeekImpressionsCount', 'prevWeekImpressionsCount', 'lastWeekCtrCount', 'prevWeekCtrCount'];
    this.dataSource = this.dataSourceRank;
    console.log(this.dataSource);

  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('dataSourceRank' in changes) {
      this.dataSource = changes.dataSourceRank.currentValue;
    }
  }

  updateTable() {
    let RankedImpressionAnalysis = []
    let value = this.enteredValue;
    value = value.replace("%", "");
    this.data.impressionAnalysis.forEach(element => {
      if (element.lastWeekPositionCount < 10 && element.lastWeekPositionCount > 0 && element.lastTotalImpressionShare > value) {
        RankedImpressionAnalysis.push(element)
      }
    });
    RankedImpressionAnalysis.sort((a, b) => {
      return b.lastTotalImpressionShare - a.lastTotalImpressionShare;
    })
    this.dataSourceRank = RankedImpressionAnalysis;
  }

}
