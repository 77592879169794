<div *ngIf="showVerificationMsg" class="alert alert-danger">
  Your email address is unverified. If you'd like to get the verification email
  again,
  <a (click)="resendVerification()"><strong><u>click here</u></strong></a>.
</div>

<section>
  <div class="row">
    <div class="col-md-5">
      <div class="container">
        <div style="text-align: center; color: #5b6ebf;">
          <a href="/"><img src="../../assets/images/mktg-doctor-logo-new.png" class="logo" /></a>
        </div>
        <h1 style="text-align: center;">Login</h1>

        <form #formData="ngForm" (ngSubmit)="loginWithEmail(formData)">
          <div class="form-group">
            <input type="email" class="form-control" placeholder="Email Address" (ngModel)="(email)" name="email"
              required />
          </div>

          <div class="form-group">
            <input type="password" class="form-control" placeholder="Password" (ngModel)="(password)" name="password"
              required />
          </div>

          <button type="submit" class="btn btn-primary" [disabled]="!formData.valid">
            Log In
          </button>
          <p>
            <a [routerLink]="['/forgot-password']">Forgot Password?</a>
            <!-- <a [routerLink]="[{ outlets: { loggedOut: ['forgot-password'] } }]">Forgot Password?</a> -->
          </p>
          <p>
            Don’t have an account?<a [routerLink]="['/signup']">&nbsp;<b>Sign Up</b></a>
            <!-- Don’t have an account?<a [routerLink]="[{ outlets: { loggedOut: ['signup'] } }]">&nbsp;<b>Sign
                Up</b></a> -->
          </p>
          <p>Questions? <a href="#">Contact Support</a></p>
        </form>
      </div>
    </div>
    <div class="col-md-7">
      <img src="../../assets/images/login.jpg" alt="image" class="image1" />
    </div>
  </div>
</section>
<!-- <div>
  <label for="email">Email</label>
  <input id="email" data-inputmask="'alias': 'email'" />
</div>
<div>
  <label for="phone">Phone</label>

  <input id="phone" type="text" placeholder="999-999-999" />
</div>

<script>
  $(":input").inputmask();
  $("#phone").inputmask({ mask: "(999) 999-9999" });
</script> -->
