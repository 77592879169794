import {
  Component,
  OnInit,
  Output,
  OnDestroy,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { AfService, AccountService } from "../_services/index";
import { User } from "../_models/interfaces";
import { MENU_ARRAY } from "../_constants/default-menu";
import { NotificationService } from "../_services/notification.service";
import { MatSidenavContainer } from "@angular/material/sidenav";
import * as moment from "moment";
import { Event as NavigationEvent } from "@angular/router";
import { filter } from "rxjs/operators";
import { NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";



@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Output() Onchange = new EventEmitter<any>();

  opened: boolean = false;

  // toggleSidebar() {
  //   this.notifiedvalue=this.notificationService.fetchNotification();
  //  this.Onchange.emit(this.notifiedvalue);

  // }
  // @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;
  user: User;
  accounts: any[] = [];
  isLoggedIn: boolean;
  selectedAccount: string;
  account: string;
  acc: number;
  fromArray: any = [];
  msgArray: any = [];
  notifiedvalue: any = [];
  notification: number;
  accountDetails: any;
  menu_grps_3: any = [];
  id: number;
  list_of_3: any = [0, 1, 2];
  duration: any;
  timediff: any = [];
  disable: boolean = false;
  previousUrl: string;
  notificationSub: Subscription;
  routeList = ["/allAccounts", "/add-account", "/target-keywords-Details"];
  constructor(
    public router: Router,
    public afService: AfService,
    private accountService: AccountService,
    public notificationService: NotificationService
  ) {
    router.events
      .pipe(
        filter((event: NavigationEvent) => {
          return event instanceof NavigationStart;
        })
      )
      .subscribe((event: NavigationStart) => {

        // console.group("NavigationStart Event");
        //  console.log( "navigation id:", event.id );
        //  console.log( "route:", event.url );
        this.previousUrl = event.url.split("?")[0];
        localStorage.setItem('previous_url', this.previousUrl);
        //  console.log( "trigger:", event.navigationTrigger );
        if (event.restoredState) {
          console.warn(
            "restoring navigation id:",
            event.restoredState.navigationId
          );
        }
        else {
          // console.log(this.previousUrl);
        }
        console.groupEnd();
      });

    this.notificationSub = this.notificationService.notification$.subscribe((count) => {
      // console.log(count);
      this.notification = count;
      //this.notifiedvalue=[];
      // console.log("notification subscirber in header");
      this.notifiedvalue = this.notificationService.fetchNotification();
      //this.Onchange.emit(this.notifiedvalue);
      // console.log(this.notifiedvalue);
      // console.log( this.notifiedvalue.length);
      // this.changeStatus(this.notifiedvalue,this.id)
    });
    this.accountService.accChange$.subscribe((accChange) => {
      if (localStorage.getItem('previous_url') === "/account") {
        // this.router.navigateByUrl(this.previousUrl);
        this.router.navigateByUrl("/account");
      }
      else if (localStorage.getItem('previous_url') === "/data-channels") {
        // this.router.navigateByUrl(this.previousUrl);
        this.router.navigateByUrl("/data-channels");
      }
      else {
        // console.log(`${sessionStorage.getItem('current_acc_id')}`);
        this.router.navigateByUrl("/websites");
      }
      // console.log(`${sessionStorage.getItem('current_acc_id')}`);
      // this.router.navigateByUrl('/websites');
      this.accountDetails = this.accountService.getCurrentAccDetails();
      this.acc = this.user?.accounts.findIndex(
        (x) => x === sessionStorage.getItem("current_acc_id")
      );
      if (this.accountDetails.menu === undefined) {
        this.accountDetails.menu = MENU_ARRAY;
        this.notificationService.setNotificationCount(
          "/notifications/" + this.accountDetails.account_slug
        );
      }
    });
  }
  //arraynames=['harshal','aarti','aditiya']
  accChange(acc_id: string) {
    this.router.navigateByUrl("/websites");
    //  this.account = acc_name;
    this.acc = this.user.accounts.findIndex((x) => x === acc_id);
    this.accountService.updateAcc(acc_id);
  }

  logout() {
    this.afService.logout().then(resp => {
      this.router.navigate(["/"]);
      // console.log(resp);
      sessionStorage.clear();
    })
  }
  messageTime(notifiedvalue) {
    for (var i = 0; i < notifiedvalue.length; i++) {
      if (notifiedvalue[i].createdOn) {
        //console.log(notifiedvalue[i].createdOn)
        let current_time = moment();
        let message_time = notifiedvalue[i].createdOn;
        //console.log(current_time,message_time);
        this.duration = moment.duration(current_time.diff(message_time));
        //console.log(current_time.milliseconds())
        this.timediff.push(this.duration.humanize());
      }
    }
  }

  changeStatus(notifiedvalue, id) {
    // console.log(notifiedvalue[id].from);
    //console.log(notifiedvalue);
    this.notificationService.updateNotificationStatus(
      this.accountDetails.account_slug,
      notifiedvalue[id].id
    );
    this.notificationService.setNotificationCount(
      "/notifications/" + this.accountDetails.account_slug
    );
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.navigate(["/contacts", notifiedvalue[id].contactId, 'msg']);
  }
  clearNotification(notifiedvalue, id, event) {
    event.stopPropagation();
    this.notificationService.clearNotification(
      this.accountDetails.account_slug,
      notifiedvalue[id].id
    );
    //this.notificationService.setNotificationCount('/notifications/'+this.accountDetails.account_slug);
  }
  clearAllNotification(event) {
    event.stopPropagation();
    this.notificationService.clearAllNotification(
      this.accountDetails.account_slug
    );
    //this.notificationService.setNotificationCount('/notifications/'+this.accountDetails.account_slug);
  }

  openMenuForMobile() {
    this.Onchange.emit("success");
  }

  ngOnInit() {
    // this.sidenavContainer.scrollable.elementScrolled().subscribe(() =>  this.notifiedvalue  /* react to scrolling */);
    //this.messageTime(this.notifiedvalue)
    //this.acc = this.accountService.getCurrentAccDetails();
    // console.log(`${sessionStorage.getItem('current_acc_id')}`);

    // this.notificationService.setNotificationCount('/notifications/dental-designer');

    this.accountDetails = {};
    this.accountDetails.menu = [];
    this.afService.user$.subscribe((user) => {
      this.user = user;
      if (user) {
        this.accounts = this.accountService.getAccNames();
        this.user.accounts = this.accountService.getAccs();
        this.acc = 0;
        this.disable = this.user.roles.admin == true ? false : true;
        // this.notificationService.setNotificationCount('/notifications/'+this.accounts[this.acc]);

        if (sessionStorage.getItem("current_acc_id")) {
          this.acc = user.accounts.findIndex(
            (x) => x === sessionStorage.getItem("current_acc_id")
          );
          //this.account = sessionStorage.getItem('current_acc_id');
        } else {
          this.acc = 0;
          //this.account = user.accounts[0];
        }
      }
    });
  }
  ngOnDestroy() {
    this.notificationSub.unsubscribe();
  }
}
