import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import { NavigationState } from '../_models/index';
@Injectable()
export class NavigationService {

  state: NavigationState;

  private stateSource = new Subject<NavigationState>();
  state$ = this.stateSource.asObservable();

  updateState(state: NavigationState) {
    this.stateSource.next(state);
    this.state = state;
  }

  getCurrentNavigationState() {
    return this.state;
  }

  constructor() { }
}
