import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MarketingPlanService, ApiService } from '../../_services/index';


@Component({
  selector: 'app-lifecycle',
  templateUrl: './lifecycle.component.html',
  styleUrls: ['./lifecycle.component.scss']
})
export class LifecycleComponent implements OnInit {
  @Output() confirmLifecycleValid = new EventEmitter<any>();

  stages = ["Startup", "Growth", "Matured", "Decline"];
  lifecycle: any = "";
  objective: string = "";
  mktgObj: string = "";

  constructor(
    private apiService: ApiService,
    private marketingPlanService: MarketingPlanService
  ) {
    marketingPlanService.lifecycle$.subscribe(
      info => {
        this.lifecycle = info.lifecycle;
        this.mktgObj = info.objective;
        this.getObjective(this.lifecycle);
        if (info.lifecycle === null)
          this.confirmLifecycleValid.emit(false);
        else
          this.confirmLifecycleValid.emit("Fetched");
      }
    );
   }

  /*
   * Code to get the appropriate Marketing Objective based on business lifecycle
  */
  getObjective(choice) {
    switch(choice) {
      case 'Startup': this.objective = "Drive traffic, views and engagement to create sustained interest. Create a brand.";
                      this.mktgObj = "REACH";
                      //this.lifecycleChoice = {startup: true, growth: false, matured: false, decline: false};
                      break;
      case 'Growth': this.objective = "Customer acquisition. Drive down Cost Per Acquisition.";
                     this.mktgObj = "REACH";
                     //this.lifecycleChoice = {startup: false, growth: true, matured: false, decline: false};
                      break;
      case 'Matured': this.objective = "Profitability and ROI.";
                      this.mktgObj = "LINK_CLICKS";
                      //this.lifecycleChoice = {startup: false, growth: false, matured: true, decline: false};
                      break;
      case 'Decline': this.objective = "Loyalty, retention and new customers.";
                      this.mktgObj = "LINK_CLICKS";
                      //this.lifecycleChoice = {startup: false, growth: false, matured: false, decline: true};
                      break;
      default: this.objective = "-- Objective to be determined --";
                break;
    }
  }

  next() {
    let selection = {"lifecycle": this.lifecycle, "objective": this.mktgObj};
    this.confirmLifecycleValid.emit(selection);
  }

  ngOnInit() {
  }

}
