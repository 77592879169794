import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

import { AngularFirestore } from 'angularfire2/firestore';

@Injectable()
export class FormService {

  constructor(
    public afs: AngularFirestore
  ) { }

  getForms() {
    //return firebase.firestore().collection('interactive_forms').where("account_id", "==", `${sessionStorage.getItem('current_acc_id')}`)
    return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('forms')
      .get();
  }

  getSatisfactoryForms() {
    let formData = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('survey_forms').get();
    console.log(formData);
    return formData;
  }

  getSatisfactoryFormResponse(form_id: string, form_ref: any) {
    console.log(form_ref.id);
    let response = this.afs.collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('form_response', ref => ref.where("type", "==", "SURVEY_FORM").where("form_ref", "==", form_ref)).valueChanges();
    // return this.afs.collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('form_response').where("type", "==", "SURVEY_FORMS").get();
    console.log(response);
    return response;
  }

  getFormResponses(form_id: string) {
    let form_ref = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection("forms").doc(form_id);
    //return this.afs.collection('interactive_forms').doc(`${form_id}`).collection('form_response').valueChanges();
    // return this.afs.collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('forms').doc(`${form_id}`).collection('form_response').valueChanges();
    return this.afs.collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('form_response',ref=>ref.where("type", "==", "ENQUIRY_FORM").where("form_ref","==",form_ref)).valueChanges();

  }

  uploadFile(fileName: any, form_name: string) {
    const id = Math.random().toString(36).substring(2);
    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef.child(`contacts/${form_name}/${id}.${fileName.name}`).put(fileName);
    return uploadTask;

  }


  // getFormResponsesByLimit(form_id: string, pageLimit: number) {
  //   return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('forms').doc(`${form_id}`).collection('form_response')
  //     .limit(pageLimit)
  //     .get();
  // }
  getFormResponsesByLimit(form_id: string) {
    console.log(form_id);
    let form_ref = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection("forms").doc(form_id);
    console.log(`${sessionStorage.getItem('current_acc_id')}`);
    return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
    .collection('form_response').where("type","==","ENQUIRY_FORM").where("form_ref","==",form_ref).orderBy("initiated", "desc").get();
    // return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('forms')
    //   .doc(`${form_id}`).collection('form_response').orderBy("initiated", "desc").get();
  }
  getSatisfactoryFormResponsesByLimit(form_id: string) {
    let form_ref = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection("survey_forms").doc(form_id);
    //return firebase.firestore().collection('interacti ve_forms').doc(`${form_id}`).collection('form_response')
    // return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('survey_forms')
    //   .doc(`${form_id}`).collection('form_response').orderBy("initiated", "desc").get();
    console.log(`${sessionStorage.getItem('current_acc_id')}`);
    return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('form_response').where("type", "==", "SURVEY_FORM").where("form_ref", "==", form_ref).orderBy("initiated", "desc").get();
  }
  // getFormDummyData() {
  //   let formData = firebase.firestore().collection('accounts').doc(`GuwocYxEiOZMRS5SBPYJ`).collection('forms')
  //     .doc(`Lijc0HvnESxt95kFmNjG`).collection('form_response').doc(`1BxiSpKjlVXUGmVIFXF3`).get();
  //   console.log(formData);
  //   return formData;
  // }
}
