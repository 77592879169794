<p>Please Wait..We are Creating & Personalizing your account.</p>

<mat-spinner style="margin: auto;"></mat-spinner>

<br>
<ng-container [ngSwitch]="activityStatus">
    <ng-container *ngSwitchCase="'ACCOUNT_CREATION'">
        Creating your account...
    </ng-container>
    <ng-container *ngSwitchCase="'ADDING_CALENDAR'">
        Creating calendar for online appointment...
    </ng-container>
    <ng-container *ngSwitchCase="'ADDING_CAMPAIGNS'">
        Personalizing Email and SMS campaign...
    </ng-container>
    <ng-container *ngSwitchCase="'PARSING_CAMPAIGNS'">
        Adding final touches...
    </ng-container>
    <ng-container *ngSwitchCase="'COMPLETED'">
        Congratulations Your Set is ready to grow your practice.
    </ng-container>
</ng-container>