<div class="container" style="margin:20px;">
  <div class="text-center" *ngIf="showWelcomeScreen">
    <h1 class="welcome_heading">{{ questionSet?.welcome_screen?.headline_text }}</h1>
    <img class="top-space welcome_image" src="{{questionSet?.welcome_screen?.image}}" />
    <div class="welcome_short_desc top-space" [innerHTML]="questionSet?.welcome_screen?.short_description"></div>
    <button class="top-space large-button" mat-raised-button color="primary"
      (click)="next()">{{ questionSet?.welcome_screen?.button_text }}</button>
  </div>

  <div class="text-center centered" *ngIf="showProgressConfirmation">
    <p class="welcome_short_desc">You had already started filling this form.</p>
    <p class="welcome_short_desc">Would you like to continue from where you left off or start over?</p>
    <button mat-raised-button (click)="startOver(false)" color="primary"
      class="large-button next_button spaced-out-button">Continue</button>
    <button mat-raised-button (click)="startOver(true)" color="gray"
      class="large-button next_button spaced-out-button">Start Over</button>
  </div>

  <div class="text-center centered" *ngIf="showSubmissionConfirmation">
    <p class="welcome_short_desc">You have already submitted this form.</p>
    <p class="welcome_short_desc">Would you like to resubmit it?</p>
    <button mat-raised-button (click)="resubmitForm(true)" color="primary"
      class="large-button next_button spaced-out-button">Yes</button>
    <button mat-raised-button (click)="resubmitForm(false)" color="gray"
      class="large-button next_button spaced-out-button">No</button>
  </div>

  <div *ngIf="showQuestions">
    <div class="progressbar_space">
      <mat-progress-bar [matTooltip]="tooltip" mode="determinate" name="progressbar" [value]="totalQuestions">
      </mat-progress-bar>
      <div style="margin-top: 8px; text-align: center;">{{ tooltip }}</div>
    </div>
    <form name="form" #responseForm="ngForm" novalidate>
      <div class="content wow fadeInRight" data-wow-delay="0.1s" style="padding:20px;">
        <h3 class="question">{{ parsedQuestion }}<span *ngIf="questionSet?.questions[cnt]?.required"> *</span></h3>
        <img class="top-space welcome_image centerImage" *ngIf="questionSet?.questions[cnt]?.image"
          src="{{questionSet?.questions[cnt]?.image}}" />
        <div class="top-space ques_short_desc" [innerHTML]="this.parsedDescription"></div>
        <div>
          <span *ngIf="questionSet?.questions[cnt]?.answer_type == 'text'; then text"></span>
          <span *ngIf="questionSet?.questions[cnt]?.answer_type == 'textarea'; then textarea"></span>
          <span *ngIf="questionSet?.questions[cnt]?.answer_type == 'radio'; then radio"></span>
          <span *ngIf="questionSet?.questions[cnt]?.answer_type == 'checkbox'; then checkbox"></span>
          <span *ngIf="questionSet?.questions[cnt]?.answer_type == 'dropdown'; then dropdown"></span>
          <span *ngIf="questionSet?.questions[cnt]?.answer_type == 'email'; then email"></span>
          <span *ngIf="questionSet?.questions[cnt]?.answer_type == 'tel'; then cell_no"></span>
          <span *ngIf="questionSet?.questions[cnt]?.answer_type == 'url'; then url"></span>
          <span *ngIf="questionSet?.questions[cnt]?.answer_type == 'declaration'; then declaration"></span>
          <span *ngIf="questionSet?.questions[cnt]?.answer_type == 'file'; then file"></span>
          <span *ngIf="questionSet?.questions[cnt]?.answer_type == 'calculator'; then calculator"></span>
        </div>

        <ng-template #text>
          <mat-form-field class="full-width">
            <input matInput type="text" style="font-size: 20px" name="text"
              [(ngModel)]="questionSet.questions[cnt].answer" />
          </mat-form-field>
        </ng-template>
        <ng-template #textarea>
          <mat-form-field class="full-width">
            <textarea matInput name="textarea" [(ngModel)]="questionSet.questions[cnt].answer"></textarea>
          </mat-form-field>
        </ng-template>
        <ng-template #radio>
          <mat-radio-group name="radio" [(ngModel)]="questionSet.questions[cnt].answer">
            <div *ngFor="let choice of questionSet?.questions[cnt]?.choices">
              <mat-radio-button [value]="choice">{{ choice }}</mat-radio-button>
            </div>
          </mat-radio-group>
        </ng-template>
        <ng-template #checkbox>
          <div *ngFor="let choice of questionSet?.questions[cnt]?.choices; let ind = index;">
            <mat-checkbox name="{{ answerSet[ind] }}" [(ngModel)]="answerSet[ind]">{{ choice }}</mat-checkbox>
          </div>
        </ng-template>
        <ng-template #url>
          <mat-form-field class="full-width">
            <input matInput id="url" type="url" name="url" [(ngModel)]="questionSet.questions[cnt].answer"
              pattern="^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$" #url="ngModel" />
            <!-- Pattern is
              ^ Should start with
              ((https?|ftp|smtp)://)? may or maynot contain any of these protocols
              (www.)? may or may not have www.
              [a-z0-9]+(.[a-z]+) url and domain and also subdomain if any upto 2 levels
              (/[a-zA-Z0-9#]+/?)*/? can contain path to files but not necessary. last may contain a /
              $ should end there.

              pattern supports : http://www.sample.com
                                https://www.sample.com
                                http://www.sample.com/xyz
                                www.sample.com
                                www.sample.com/xyz/#/xyz
                                sample.com
            -->
          </mat-form-field>
          <!-- <mat-error *ngIf="url.invalid && (url.dirty || url.touched) && url.errors.pattern">
         Url must be in these formats:
         'sample.com',
         'www.sample.com',
         'http://www.sample.com',
         'https://www.sample.com',
         'http://www.sample.com/xyz',
         'www.sample.com/xyz/#/xyz',
       </mat-error> -->

        </ng-template>
        <ng-template #dropdown>
          <mat-form-field class="container">
            <mat-select placeholder="Choose..." [(ngModel)]="questionSet.questions[cnt].answer" name="dropdown">
              <mat-option *ngFor="let choice of questionSet?.questions[cnt]?.choices" [value]="choice"
                style="width:100%; font-size: 10px;">{{ choice }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
        <ng-template #cell_no>
          <mat-form-field class="full-width">
            <input matInput id="cell_no" name="cell_no" [(ngModel)]="questionSet.questions[cnt].answer"
              #cell_no="ngModel" pattern="[0-9]{10}" />
          </mat-form-field>
          <!-- <div *ngIf="cell_no.invalid && (cell_no.dirty || cell_no.touched) && cell_no.errors.pattern"
      class="alert alert-danger">
       Please enter valid mobile number.
     </div> -->
        </ng-template>
        <ng-template #email>
          <mat-form-field class="full-width">
            <input matInput id="email" name="email" [(ngModel)]="questionSet.questions[cnt].answer" #email="ngModel"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$" />
          </mat-form-field>
          <!-- <div *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.pattern"
      class="alert alert-danger">
        Email format should be
        <small><b>joe@abc.com</b></small>
      </div> -->
        </ng-template>
        <ng-template #declaration>
          <mat-checkbox name="checkbox" [(ngModel)]="questionSet.questions[cnt].answer"
            [value]="questionSet?.questions[cnt]?.choices[0]"> {{ questionSet?.questions[cnt]?.choices[0] }}
          </mat-checkbox>
        </ng-template>
        <ng-template #file>
          <input *ngIf="!isUrl" #file_input type="file" accept="image/*" (change)="getImage($event)">
          <div *ngIf="isUrl">
            <img style="height: 100px; width: 100px;" src="{{downloadURL || questionSet?.questions[cnt]?.answer}}"
              alt="image" />
            <!--<button mat-raised-button class="preview_btn" (click)="deleteFile(downloadURL)">Delete</button>-->
            <i class="material-icons" (click)="deleteFile(downloadURL)">delete</i>
          </div>
          <div *ngIf="showProgress" class="progress">
            <div class="progress-bar progress-bar-animated bg-primary" mode="determinate"
              [style.width]="(uploadProgress) + '%'">{{uploadProgress | number:'1.0-0'}}% Complete
            </div>
          </div>
        </ng-template>
        <ng-template #calculator>
          <p>
            <span style="font-size: 1.5em;" *ngIf="questionSet.questions[cnt].calculation.format === 'number'">
              {{ getResult(questionSet.questions[cnt]) | number:'1.0-2' }}
            </span>
            <span style="font-size: 1.5em;" *ngIf="questionSet.questions[cnt].calculation.format === 'currency'">
              {{ getResult(questionSet.questions[cnt]) | currency:acc_settings.account_currency:'symbol':'1.0-2' }}
            </span>
          </p>
        </ng-template>
      </div>
      <button mat-raised-button color="primary" class="large-button next_button" style="float:right" (click)="next()"
        [disabled]="!responseForm.form.valid || (questionSet.questions[cnt]?.required && (questionSet?.questions[cnt]?.answer === undefined || questionSet?.questions[cnt]?.answer === '' || questionSet?.questions[cnt]?.answer === false)) || showProgress">
        <span *ngIf="cnt < total - 1">Next</span>
        <span *ngIf="cnt == total - 1">Submit</span>
      </button>
      <button mat-raised-button color="primary" class="large-button next_button" style="float:left;"
        (click)="previous()" [disabled]="showProgress">Previous</button>
    </form>
  </div>
  <div class="text-center wow fadeInRight" data-wow-delay="0.1s" *ngIf="showThankYou">
    <h1 class="welcome_heading">{{ questionSet?.thank_you_screen?.headline_text }}</h1>
    <!--<h1 class="font-weight-bold welcome_heading">{{ questionSet?.thank_you_screen?.headline_text }}</h1>-->
    <img class="top-space welcome_image" src="{{questionSet?.thank_you_screen?.image}}" />
    <div class="welcome_short_desc top-space" [innerHTML]="questionSet?.thank_you_screen?.short_description"></div>
    <button mat-raised-button class="large-button" color="primary"
      (click)="go()">{{ questionSet?.thank_you_screen?.button_text }}</button>
  </div>
</div>
