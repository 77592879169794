/*
 * Written with the intention to fetch/maintain value of each
 * Facebook/AdWords conversion.
 * Not in use functionally.
 */

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AccountService } from './account.service';
import { Subject ,  Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ConversionValueService {
acc:any;
campaign_details:any;
document_copy:any;
fb_conversion_values:any;
fb_campaign_conversion_value:any;

  constructor(
    public afs: AngularFirestore,
    private accountService: AccountService
  ) {}

   sendCampaignDetails(campaign_details:any){
     this.campaign_details = campaign_details;
   }

   getCurrentCampaignDetails(): any {
     return this.campaign_details;
   }

saveConversionValue(conversion_value:any) {
  this.acc = this.accountService.getCurrentAccDetails();
  //console.log(this.acc);
  var valueRef : AngularFirestoreDocument<any>;
  const data = {};
  if(this.campaign_details.provider == "facebook"){
  valueRef = this.afs.doc(`account/${this.acc.name}/conversion_values/fb_${this.campaign_details.campaign_id}`);
  data["type"]="facebook";
  }
  else{
  valueRef = this.afs.doc(`account/${this.acc.name}/conversion_values/aw_${this.campaign_details.campaign_id}`);
    data["type"]="adwords";
  }
  data["campaign_id"] = this.campaign_details.campaign_id;
  data["conversion_value"] = conversion_value;
  var result = valueRef.set(data, { merge:true });
}

getCampaignConvValue() {
  let acc = this.accountService.getCurrentAccDetails();
  if(this.campaign_details.provider == "facebook"){
 return this.afs.doc(`account/${acc.name}/conversion_values/fb_${this.campaign_details.campaign_id}`).valueChanges();
}
else{
  return this.afs.doc(`account/${acc.name}/conversion_values/aw_${this.campaign_details.campaign_id}`).valueChanges();
}
}

getAllConvValuesForService(provider:string){
  let acc = this.accountService.getCurrentAccDetails();
if(provider == "facebook"){
var ref = this.afs.collection(`account/${acc.name}/conversion_values`, ref => ref.where("type", "==", "facebook")).valueChanges();
}
else{
var ref = this.afs.collection(`account/${acc.name}/conversion_values`, ref => ref.where("type", "==", "adwords")).valueChanges();
}
return ref;
}
}
