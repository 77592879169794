<div class="row" id="subHeader1">
  <div id="pageTitle">
    <a [routerLink]="['/main']"> <i class="pi pi-chevron-left"></i>Campaigns</a>
  </div>

  <div id="subPageTitle">
    Email Campaigns
  </div>
</div>

<div class="container-fluid view">
  <center>
    <div *ngIf="!isReady">
      <!--Fetching your campaigns ...-->
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
  </center>

  <div class="row">
    <div class="scrolling-wrapper-flexbox">
      <div class="col">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric"> {{totals?.recipients | number }} </div>
            <div class="metric-caption"> Recipients</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col">

        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <div class="metric"> {{totals?.delivered | number }} </div>
            <div class="metric-caption"> Delivered</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col">

        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <div class="metric" *ngIf="totals.delivered > 0; else NoDeliveries">
              {{ totals?.delivered / totals?.recipients | percent:'1.1-1' }} </div>
            <div class="metric-caption"> Delivery rate</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric"> {{totals?.unsubscribed | number }} </div>
            <div class="metric-caption"> Unsubscribes</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric"> {{totals?.bounced | number }} </div>
            <div class="metric-caption"> Bounced</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric" *ngIf="totals.delivered > 0; else NoDeliveries">
              {{totals?.bounced / totals?.recipients | percent:'1.1-1' }} </div>
            <div class="metric-caption"> Bounce Rate</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric"> {{totals?.opens | number}} </div>
            <div class="metric-caption"> Opens </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric" *ngIf="totals.delivered > 0; else NoDeliveries">
              {{totals?.opens / totals?.delivered | percent:'1.1-1'}} </div>
            <div class="metric-caption"> Open rate </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric"> {{totals?.clicks | number}} </div>
            <div class="metric-caption"> Clicks</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric" *ngIf="totals.delivered > 0; else NoDeliveries">
              {{totals?.clicks / totals?.delivered | percent:'1.1-1'}} </div>
            <div class="metric-caption"> Click rate </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric" *ngIf="totals.delivered > 0; else NoDeliveries">
              {{totals?.clicks / totals?.opens | percent:'1.1-1'}} </div>
            <div class="metric-caption"> Click to open ratio </div>
          </mat-card-content>
        </mat-card>
      </div>
      <ng-template #NoDeliveries>
        <div class="metric"> 0 </div>
      </ng-template>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title>
            <h6> Reach </h6>
            <span class="col metric kpi"> {{lists?.list_count| number}} </span>
          </mat-card-title>
          <mat-card-subtitle>
            <div class="row">
              <div class="col-12"> Number of people you can reach by email.</div>
            </div>
            <mat-divider></mat-divider>
          </mat-card-subtitle>
        </mat-card-header>
        <center>
          <div *ngIf="!isListsReady">
            <!--Fetching your campaigns ...-->
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
          </div>
        </center>
        <mat-card-content>
          <div class=row>
            <div class="col">
              Your reach is the sum of all subscribers in your lists
            </div>
          </div>
          <table class="table-outline">
            <tr>
              <th>Segments</th>
              <th>Subscribers</th>
            </tr>
            <tr *ngFor="let list of lists?.segments">
              <td>{{ list?.name }}</td>
              <td>{{ list?.member_count }}</td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="">
            <h6>Performance</h6>
          </mat-card-title>
          <mat-card-subtitle>
            <div class="row">
              <div class="col">Total clicks to website from email.</div>
            </div>
            <mat-divider></mat-divider>
          </mat-card-subtitle>

        </mat-card-header>
        <center>
          <div *ngIf="!isReady">
            <!--Fetching your campaigns ...-->
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
          </div>
        </center>
        <mat-card-content>
          <div class="row">
            <div class="col col-md-8 offset-md-2">
              <div *ngIf="performance_graph;else noChart">
                <canvas id="canvas">{{ performance_graph }}</canvas>
              </div>
              <ng-template #noChart>
                <div class="col metric">
                  No data to show for this time period
                </div>
              </ng-template>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-card class="metric-card" [class.disable-channel]="!acc.ga_view_id">
        <mat-card-header>
          <mat-card-title class="">
            <h6>Engagement</h6>
          </mat-card-title>
          <mat-card-subtitle>
            <div class="row">
              <div class="col">Website visits and behavior of users coming from email.</div>
            </div>
            <mat-divider></mat-divider>

          </mat-card-subtitle>
        </mat-card-header>
        <center>
          <div *ngIf="!isReady">
            <!--Fetching your campaigns ...-->
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
          </div>
        </center>
        <mat-card-content>
          <div class="row">
            <div class="col">
              <ul>
                <li>Total {{users | number}} quality users acquired from email marketing with {{sessions | number}}
                  visits to the website</li>
                <li>Spending on an average {{duration}} mins per visit and browsing {{ppv | number:'1.0-2'}} pages per
                  visit.</li>
              </ul>
            </div>
          </div>

          <div class="row" [class.disable-channel]="!acc.ga_view_id">
            <div class="scrolling-wrapper-flexbox">
              <div class="col-sm">
                <mat-card class="metric-card">
                  <mat-card-header>
                    <mat-card-title class="metric-caption"></mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="metric-caption"> Users </div>
                    <div class="metric"> {{users | number}} </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="col-sm">
                <mat-card class="metric-card">
                  <mat-card-header>
                    <mat-card-title class="metric-caption"></mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="metric-caption"> Sessions </div>
                    <div class="metric"> {{sessions | number}} </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="col-sm">
                <mat-card class="metric-card">
                  <mat-card-header>
                    <mat-card-title class="metric-caption"></mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="metric-caption"> Bounce Rate </div>
                    <div class="metric"> {{bounce_rate | number:'1.0-2'}}% </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="col-sm">

                <mat-card class="metric-card">
                  <mat-card-header>
                    <mat-card-title class="metric-caption"></mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="metric-caption"> Pages/Session </div>
                    <div class="metric"> {{ppv | number:'1.0-2'}} </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="col-sm">

                <mat-card class="metric-card">
                  <mat-card-header>
                    <mat-card-title class="metric-caption"></mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="metric-caption"> Avg. Session Duration </div>
                    <div class="metric"> {{duration}} </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </div>

        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="">
            Campaigns
          </mat-card-title>
        </mat-card-header>
        <center>
          <div *ngIf="!isReady">
            <!--Fetching your campaigns ...-->
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
          </div>
        </center>

        <mat-card-content>
          <div *ngIf="isReady">
            <mat-list role="list">
              <mat-divider></mat-divider>
              <div *ngIf="email_report.length!== 0;else noCampaigns">
                <div *ngFor="let campaign of email_report">

                  <mat-list-item role="listitem">
                    <h6>{{ campaign?.name}} </h6>
                    <span class="spacer"></span>

                    <a [routerLink]="['/email-campaign', campaign.id]">
                      <mat-icon class="modal-link">chevron_right</mat-icon>
                    </a>
                  </mat-list-item>
                  <div class="row desktop-view">
                    <div class="col metric"> {{campaign?.details.Recipients | number }}</div>
                    <div class="col metric"> {{campaign?.details.Recipients - campaign?.details.Bounced | number }}
                    </div>
                    <div class="col metric"> {{ campaign?.details.Bounced }}</div>
                    <div class="col metric">
                      {{(campaign?.details.Bounced / campaign?.details.Recipients) | percent:'1.1-1' }}</div>
                    <div class="col metric"> {{campaign?.details.UniqueOpened | number }}</div>
                    <div class="col metric">
                      {{(campaign?.details.UniqueOpened / (campaign?.details.Recipients - campaign?.details.Bounced)) | percent:'1.1-1' }}
                    </div>
                    <div class="col metric"> {{campaign?.details.Clicks | number }}</div>
                    <div class="col metric">
                      {{(campaign?.details.Clicks / (campaign?.details.Recipients - campaign?.details.Bounced)) | percent:'1.1-1' }}
                    </div>
                  </div>
                  <div class="row desktop-view">
                    <div class="col metric-caption"> Recipients </div>
                    <div class="col metric-caption"> Delivered</div>
                    <div class="col metric-caption"> Bounced</div>
                    <div class="col metric-caption"> Bounce rate</div>
                    <div class="col metric-caption"> Opens</div>
                    <div class="col metric-caption"> Open Rate</div>
                    <div class="col metric-caption"> Clicks</div>
                    <div class="col metric-caption"> Click rate</div>
                  </div>

                  <div class="row mobile-view">
                    <div class="col-3 col-sm">
                      <div class="metric"> {{campaign?.details.Recipients | number }}</div>
                      <div class="col col-sm metric-caption"> Recipients </div>
                    </div>
                    <div class="col-3 col-sm">
                      <div class="metric"> {{campaign?.details.Recipients - campaign?.details.Bounced | number }}</div>
                      <div class="col col-sm metric-caption"> Delivered</div>
                    </div>

                    <div class="col-3 col-sm">
                      <div class="metric"> {{campaign?.details.Bounced}}</div>
                      <div class="col metric-caption"> Bounced</div>
                    </div>
                    <div class="col-3 col-sm">
                      <div class="metric">
                        {{(campaign?.details.Bounced / campaign?.details.Recipients) | percent:'1.1-1' }}</div>
                      <div class="col metric-caption"> Bounce rate</div>
                    </div>
                    <div class="col-3 col-sm">
                      <div class="metric"> {{campaign?.details.UniqueOpened | number }}</div>
                      <div class="col metric-caption"> Opens</div>
                    </div>
                    <div class="col-3 col-sm">
                      <div class="metric">
                        {{(campaign?.details.UniqueOpened / (campaign?.details.Recipients - campaign?.details.Bounced)) | percent:'1.1-1' }}
                      </div>
                      <div class="col metric-caption"> Open Rate</div>
                    </div>
                    <div class="col-3 col-sm">
                      <div class="metric"> {{campaign?.details.Clicks | number }}</div>
                      <div class="col metric-caption"> Clicks</div>
                    </div>
                    <div class="col-3 col-sm">
                      <div class="metric">
                        {{(campaign?.details.Clicks / (campaign?.details.Recipients - campaign?.details.Bounced)) | percent:'1.1-1' }}
                      </div>
                      <div class="col metric-caption"> Click rate</div>
                    </div>
                  </div>
                  <mat-list-item>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </div>
              </div>
              <!--ng for-->
            </mat-list>
            <ng-template #noCampaigns>
              <div class="col col-md-8 offset-md-2 metric">
                No campaigns to show for this time period
              </div>
            </ng-template>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>