import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-app-error',
  templateUrl: './app-error.component.html',
  styleUrls: ['./app-error.component.scss']
})
export class AppErrorComponent implements OnInit {
name: any;
  constructor(public afAuth: AngularFireAuth,private router: Router) {
    this.afAuth.authState.subscribe(afAuth => {
      if(afAuth) {
        this.name = afAuth;
      }
    });
   }


  logout() {
     //this.afAuth.auth.logout();
     this.afAuth.auth.signOut();
     this.router.navigateByUrl('/login');
  }

  ngOnInit() {
  }

}
