<div *ngIf="loading || loadingForSetup; else loaded">
    <div style="height: fit-content;width:fit-content;margin: auto;">
        <mat-spinner></mat-spinner>
    </div>
</div>
<ng-template #loaded>
    <div *ngIf="organicRankAnalysis?.length >0 ; else noResults">
        <p-table [value]="organicRankAnalysis" [columns]="cols"  currentPageReportTemplate="{first} - {last} of {totalRecords}" #dt
        [globalFilterFields]="['keyword']" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50]">
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto" style="width: 100%;">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value,'contains')" placeholder="Search keyword" style="width: 100%;border: none;"  class="form-control"/>
                </span>
            </div>
          </ng-template>
            <ng-template pTemplate="header">
                <!-- <tr>
                    <th *ngFor="let content of columns;let i = index" [pSortableColumn]="col" [ngStyle]="(i>=2)?{'width': '3rem'}:{'width': '7rem'}">
                        <div *ngIf="i>=2 else showOtherHeader" style="text-align: center;">
                            {{content |titlecase}}<p-sortIcon [field]="content" *ngIf="content != 'Keyword' || content !='Info'"></p-sortIcon>
                        </div>
                        <ng-template #showOtherHeader>
                            <div>{{content}}</div>
                        </ng-template>
                    </th>
                </tr> -->
                <tr>
                    <!-- <th>Keyword</th>
                    <th>SEO Info</th> -->
                    <th *ngFor="let col of cols" [pSortableColumn]="col.sortField" style="text-align: center;">
                      {{col.header}}<p-sortIcon [field]="col.sortField"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-columns>
                <!-- <tr>
                    <td *ngFor="let data of columns">
                        <div *ngIf="(data != 'Info') else show">
                            <div *ngIf="data == 'Keyword'">
                                {{rowData[data]}}
                            </div>
                            <div *ngIf="data != 'Keyword'" style="text-align: center;">
                                {{rowData[data]}}
                            </div>
                        </div>
                        <ng-template #show>
                            Competition - {{(rowData[data]["competition"]).toString()| slice:0:4}}<br>
                            CPC - {{(rowData[data]["cpc"]).toString()| slice:0:4}}<br>
                            SV - {{(rowData[data]["search_volume"]).toString() | slice:0:4}}<br>
                        </ng-template>
                    </td>
                </tr> -->
                <tr>
                    <td>{{data?.keyword}}</td>
                    <td>
                      <tr><td>SV </td> <td> : {{(data?.search_volume)?(data?.search_volume):'0'}} </td></tr>
                      <tr><td>CPC</td> <td> : {{(data?.cpc)?((data?.cpc) | number:'1.0-2'):'0'}}</td></tr>
                      <tr><td>Cmpt</td> <td>: {{(data?.competition)?((data?.competition) |number:'1.0-2'):'0'}}</td></tr>
                    </td>
                    <!-- <td [matTooltip]="getTooltipInfo(data)" matTooltipClass="allow-cr">{{data?.keyword}}</td> -->
                    <td *ngFor="let prevRank of data['previousRanks']">
                      <span style="font-size:small" *ngIf="prevRank?.current==false;else elseBlock"> 
                       <div style="text-align: center;"> Not available</div>
                      </span>
                      <ng-template #elseBlock style="text-align: center;">
                        <span [ngStyle]="{'font-size': (prevRank?.rank!== null ) ? 'medium' : 'small'}">
                            <div style="text-align: center;">{{prevRank?.rank_group!== null ? prevRank?.rank_group: 'Not ranking' }}</div>
                        </span>
                      </ng-template>
                    </td>
                  </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #noResults style="height: 100%;width: 100%;">
        <div id="noData" *ngIf="!dataExists">You don't have organic ranking analysis..</div>
    </ng-template>
</ng-template>