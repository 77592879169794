<div class="container-fluid">
  <center>
    <div *ngIf="!isReady">
      <!--Fetching your campaigns ...-->
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
  </center>

  <!-- -----------sub header---------- -->
  <div class="row" id="subHeader1">
    <div id="pageTitle">
      <a [routerLink]="['/fb-campaign']"> <i class="pi pi-chevron-left"></i>AdWords Campaigns
      </a>
    </div>

    <div id="subPageTitle">
      {{campaign.campaign_name}}
    </div>
  </div>
  <!-- ------------subheader ends--------- -->

  <div class="scrolling-wrapper-flexbox">
    <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi">
          {{((campaign?.spend) * acc?.facebook_factor) | currency: acc?.account_currency :'symbol':'1.0-0' }} </div>
        <div class="metric-caption kpi"> Spend</div>
      </mat-card-content>
    </mat-card>

    <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi" *ngIf="campaign_conversion_value > -1; else noConversionValue">
          {{(campaign_conversion_value * facebookService.getTotalConversions(campaign)) | currency: acc.account_currency :'symbol':'1.0-0'}}
        </div>
        <ng-template #noConversionValue>
          <div class="metric kpi">?</div>
        </ng-template>
        <div class="metric-caption kpi"> Conversion Value </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi" *ngIf="campaign_conversion_value > -1; else noConversionValue">
          {{((campaign_conversion_value * facebookService.getTotalConversions(campaign)) / ((campaign?.spend) * acc?.facebook_factor)) | number:'1.0-1'}}X
        </div>
        <div class="metric-caption kpi"> ROI</div>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-divider></mat-divider>

  <div class="row">
    <div class="col-0 triangle-bottomleft">
    </div>
    <div class="col">
      <center>
        <div *ngIf="!isReady">
          <!--Fetching your campaigns ...-->
          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
      </center>
      <table border="0" width=100%>
        <tr>
          <td width="50%">
            <div> Reach </div>
          </td>
          <td width="50%">
            <span class="metric-value fbfont">{{campaign?.reach | number}}</span>
          </td>
        </tr>
        <tr>
          <td width="50%">
            Impressions
          </td>
          <td width="50%">
            <span class="metric-value fbfont">{{campaign?.impressions | number}}</span>
          </td>
        </tr>
      </table>

      <mat-divider> </mat-divider>
      <table border="0" width=100%>
        <tr>
          <td width="50%">
            <div class=""> Post Reactions </div>
          </td>
          <td width="50%">
            <span class="metric-value fbfont"
              *ngIf="facebookService.getIndex('post_reaction',campaign) > -1; else noVal">{{ campaign.actions[(facebookService.getIndex('post_reaction',campaign))].value | number}}</span>
          </td>
        </tr>
        <tr>
          <td width="50%">
            Post Comments
          </td>
          <td width="50%">
            <span class="metric-value fbfont"
              *ngIf="facebookService.getIndex('comment',campaign) > -1; else noVal">{{ campaign.actions[(facebookService.getIndex('comment',campaign))].value | number}}</span>
          </td>
        </tr>
        <tr>
          <td width="50%">
            Post Shares
          </td>
          <td width="50%">
            <span class="metric-value fbfont"
              *ngIf="facebookService.getIndex('post',campaign) > -1; else noVal">{{ campaign.actions[(facebookService.getIndex('post',campaign))].value | number }}</span>
          </td>
        </tr>
        <tr>
          <td width="50%">
            Link Clicks
          </td>
          <td width="50%">
            <span class="metric-value fbfont"
              *ngIf="facebookService.getIndex('link_click',campaign) > -1; else noVal">{{ campaign.actions[facebookService.getIndex('link_click',campaign)].value | number}}</span>
          </td>
        </tr>
        <tr>
          <td width="50%">
            Page Likes
          </td>
          <td width="50%">
            <span class="metric-value fbfont"
              *ngIf="facebookService.getIndex('like',campaign) > -1; else noVal">{{ campaign.actions[facebookService.getIndex('like',campaign)].value | number}}</span>
          </td>
        </tr>
      </table>
      <span *ngIf="campaign?.objective == 'VIDEO_VIEWS'">
        <mat-divider></mat-divider>
        <table border="0" width=100%>
          <tr>
            <td width="50%">
              Average time of video watched
            </td>
            <td width="50%">
              <span class="metric-value"
                *ngIf="getVideoIndex('video_view', 'video_avg_time_watched_actions') > -1; else noVal">{{ campaign.video_avg_time_watched_actions[getVideoIndex('video_view', 'video_avg_time_watched_actions')].value }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Video watches at 25%
            </td>
            <td width="50%">
              <span class="metric-value"
                *ngIf="getVideoIndex('video_view', 'video_p25_watched_actions') > -1; else noVal">{{ campaign.video_p25_watched_actions[getVideoIndex('video_view', 'video_p25_watched_actions')].value }}</span>
              <span class="video_watch_cost">(Cost per watch: <span
                  *ngIf="getVideoIndex('video_view', 'video_p25_watched_actions') > -1; else noVal"><strong>{{ ((campaign.spend / campaign.video_p25_watched_actions[getVideoIndex('video_view', 'video_p25_watched_actions')].value) * acc.facebook_factor) | currency:acc.account_currency:'symbol':'1.0-2' }}</strong></span>)</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Video watches at 50%
            </td>
            <td width="50%">
              <span class="metric-value"
                *ngIf="getVideoIndex('video_view', 'video_p50_watched_actions') > -1; else noVal">{{ campaign.video_p50_watched_actions[getVideoIndex('video_view', 'video_p50_watched_actions')].value }}</span>
              <span class="video_watch_cost">(Cost per watch: <span
                  *ngIf="getVideoIndex('video_view', 'video_p50_watched_actions') > -1; else noVal"><strong>{{ ((campaign.spend / campaign.video_p50_watched_actions[getVideoIndex('video_view', 'video_p50_watched_actions')].value) * acc.facebook_factor) | currency:acc.account_currency:'symbol':'1.0-2' }}</strong></span>)</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Video watches at 75%
            </td>
            <td width="50%">
              <span class="metric-value"
                *ngIf="getVideoIndex('video_view', 'video_p75_watched_actions') > -1; else noVal">{{ campaign.video_p75_watched_actions[getVideoIndex('video_view', 'video_p75_watched_actions')].value }}</span>
              <span class="video_watch_cost">(Cost per watch: <span
                  *ngIf="getVideoIndex('video_view', 'video_p75_watched_actions') > -1; else noVal"><strong>{{ ((campaign.spend / campaign.video_p75_watched_actions[getVideoIndex('video_view', 'video_p75_watched_actions')].value) * acc.facebook_factor) | currency:acc.account_currency:'symbol':'1.0-2' }}</strong></span>)</span>

            </td>
          </tr>
          <tr>
            <td width="50%">
              Video watches at 100%
            </td>
            <td width="50%">
              <span class="metric-value"
                *ngIf="getVideoIndex('video_view', 'video_p100_watched_actions') > -1; else noVal">{{ campaign.video_p100_watched_actions[getVideoIndex('video_view', 'video_p100_watched_actions')].value }}</span>
              <span class="video_watch_cost">(Cost per watch: <span
                  *ngIf="getVideoIndex('video_view', 'video_p100_watched_actions') > -1; else noVal"><strong>{{ ((campaign.spend / campaign.video_p100_watched_actions[getVideoIndex('video_view', 'video_p100_watched_actions')].value) * acc.facebook_factor) | currency:acc.account_currency:'symbol':'1.0-2' }}</strong></span>)</span>
            </td>
          </tr>
        </table>
      </span>
      <ng-template #noVal>
        <div class="metric-value">--</div>
      </ng-template>
      <ng-template #noValKPI>
        <div class="col metric kpi">--</div>
      </ng-template>
      <mat-divider> </mat-divider>
      <span *ngIf="campaign?.objective == 'LEAD_GENERATION'; then leads">
      </span>
      <span *ngIf="campaign?.objective == 'CONVERSIONS'; then conversions">
      </span>
      <span *ngIf="campaign?.objective == 'VIDEO_VIEWS'; then video_views">
      </span>
      <ng-template #leads>
        <div class="col metric kpi" *ngIf="facebookService.getIndex('leadgen.other',campaign) > -1; else noValKPI">
          {{ campaign.actions[facebookService.getIndex('leadgen.other',campaign)].value | number}}</div>
        <div class="col metric-caption kpi">Leads</div>
        <div class="col metric kpi" *ngIf="facebookService.getIndex('leadgen.other',campaign) > -1; else noValKPI">
          {{((campaign.cost_per_action_type[facebookService.getIndex('leadgen.other',campaign)].value) * acc?.facebook_factor) | currency:acc?.account_currency:'symbol':'1.0-2'}}
        </div>
        <div class="col metric-caption kpi">Cost per lead</div>
      </ng-template>
      <ng-template #conversions>
        <div class="col metric kpi"
          *ngIf="facebookService.getIndex('offsite_conversion.fb_pixel_lead',campaign) > -1; else noValKPI">
          {{facebookService.getTotalConversions(campaign) | number}}</div>
        <div class="col metric-caption kpi">Conversions</div>
        <div class="col metric kpi"
          *ngIf="facebookService.getIndex('offsite_conversion.fb_pixel_lead',campaign) > -1; else noValKPI">
          {{cost_per_conversion | currency:acc?.account_currency:'symbol':'1.0-0'}}</div>
        <div class="col metric-caption kpi">Cost per conversion</div>
      </ng-template>
      <ng-template #video_views>
        <!--
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="col metric kpi" *ngIf="facebookService.getIndex('video_view',campaign) > -1; else noValKPI"> {{campaign.actions[facebookService.getIndex('video_view',campaign)].value | number}}</div>
            <div class="col metric-caption kpi">Three-second Video Views</div>
            <div class="col metric kpi" *ngIf="facebookService.getIndex('video_view',campaign) > -1; else noValKPI"> {{((campaign.cost_per_action_type[facebookService.getIndex('video_view',campaign)].value) * acc?.facebook_factor) | currency:acc?.account_currency:'symbol':'1.0-2'}}</div>
            <div class="col metric-caption kpi">Cost per Three-second video view</div>
          </div>
          <div class="col-12 col-md-6">
            <div class="col metric kpi" *ngIf="getVideoIndex('video_view', 'video_10_sec_watched_actions') > -1; else noValKPI"> {{(campaign.video_10_sec_watched_actions[getVideoIndex('video_view', 'video_10_sec_watched_actions')].value) | number}}</div>
            <div class="col metric-caption kpi">Ten-second Video Views</div>
            <div class="col metric kpi" *ngIf="getVideoIndex('video_view', 'cost_per_10_sec_video_view') > -1; else noValKPI"> {{((campaign.cost_per_10_sec_video_view[getVideoIndex('video_view', 'cost_per_10_sec_video_view')].value) * acc?.facebook_factor) | currency:acc?.account_currency:'symbol':'1.0-2'}}</div>
            <div class="col metric-caption kpi">Cost per Ten-second video view</div>
          </div>
        </div>
      -->
      </ng-template>
    </div>
    <div class="col-0 triangle-bottomright">
    </div>
  </div>
  <mat-divider></mat-divider>

  <div class="row">
    <div class="col justify-content-center">
      Gender
      <mat-card class="metric-card">
        <div *ngFor="let gender_type of gender_insights; let i = index;">
          <mat-card-header>
            <mat-card-title class="metric-caption">
              {{ gender_type?.gender == 'female' ? 'Women':(gender_type?.gender == 'male' ? 'Men':'Unspecified') | titlecase }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <center>
              <div *ngIf="!isReady">
                <!--Fetching your campaigns ...-->
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
              </div>
            </center>
            <p>
              <mat-progress-bar mode="determinate" value="{{ (gender_type?.reach / campaign?.reach) * 100 }}">
              </mat-progress-bar>
            </p>
            <table border=0 width=100%>
              <span *ngIf="campaign?.objective == 'LEAD_GENERATION'; then gender_leads">
              </span>
              <span *ngIf="campaign?.objective == 'CONVERSIONS'; then conversions_gender">
              </span>
              <span *ngIf="campaign?.objective == 'VIDEO_VIEWS'; then video_views_gender">
              </span>
              <ng-template #gender_leads>
                <tr>
                  <td width=50%>
                    Leads
                  </td>
                  <td width=50%>
                    <span class="metric"
                      *ngIf="getIndexForGender('leadgen.other', gender_insights[i]) > -1; else noVal">{{ gender_type.actions[getIndexForGender('leadgen.other', gender_insights[i])].value | number }}</span>
                  </td>
                </tr>
                <tr>
                  <td width=50%>
                    Cost per Lead
                  </td>
                  <td width=50%>
                    <span
                      *ngIf="getIndexForGender('leadgen.other', gender_insights[i]) > -1; else noVal">{{ ((gender_type.cost_per_action_type[getIndexForGender('leadgen.other', gender_insights[i])].value) * acc?.facebook_factor) | currency:acc?.account_currency:'symbol':'1.2-2' }}</span>
                  </td>
                </tr>
              </ng-template>
              <ng-template #conversions_gender>
                <tr>
                  <td width=50%>
                    Conversions
                  </td>
                  <td width=50%>
                    <span class="metric"
                      *ngIf="getIndexForGender('offsite_conversion.fb_pixel_lead', gender_insights[i]) > -1 || getIndexForGender('offsite_conversion.fb_pixel_custom', gender_insights[i]) > -1; else noVal">{{ getTotalConversionsGender(gender_type) | number }}</span>
                  </td>
                </tr>
                <tr>
                  <td width=50%>
                    Cost per Conversion
                  </td>
                  <td width=50%>
                    <span class="metric"
                      *ngIf="getIndexForGender('offsite_conversion.fb_pixel_lead', gender_insights[i]) > -1 || getIndexForGender('offsite_conversion.fb_pixel_custom', gender_insights[i]) > -1; else noVal">{{ getTotalConversionsGenderCost(gender_type) | currency:acc?.account_currency:'symbol':'1.2-2' }}</span>
                  </td>
                </tr>
              </ng-template>

              <ng-template #video_views_gender>
                <!--
                <tr>
                  <td width=50%>
                    Three-second video views
                  </td>
                  <td width=50%>
                    <span class="metric"
                      *ngIf="getIndexForGender('video_view', gender_insights[i]) > -1; else noVal">{{ gender_type.actions[getIndexForGender('video_view', gender_insights[i])].value | number }}</span>
                  </td>
                </tr>
                <tr>
                  <td width=50%>
                    Cost per Three-second video view
                  </td>
                  <td width=50%>
                    <span
                      *ngIf="getIndexForGender('video_view', gender_insights[i]) > -1; else noVal">{{ ((gender_type.cost_per_action_type[getIndexForGender('video_view', gender_insights[i])].value) * acc?.facebook_factor) | currency:acc?.account_currency:'symbol':'1.2-2' }}</span>
                  </td>
                </tr>
              -->
              </ng-template>
              <tr>
                <td width=50%>
                  Reached
                </td>
                <td width=50%>
                  {{ gender_type?.reach | number }}
                </td>
              </tr>
            </table>
            <mat-divider></mat-divider>
          </mat-card-content>

        </div>
      </mat-card>
    </div>
    <div class="col">
      Age
      <mat-card class="metric-card">
        <div *ngFor="let age_type of age_insights; let i = index;">
          <mat-card-header>
            <mat-card-title class="metric-caption">
              {{ age_type.age }}
            </mat-card-title>
          </mat-card-header>
          <center>
            <div *ngIf="!isReady">
              <!--Fetching your campaigns ...-->
              <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
          </center>
          <mat-card-content>
            <p>
              <mat-progress-bar mode="determinate" value="{{ (age_type?.reach / campaign?.reach) * 100 }}">
              </mat-progress-bar>
            </p>
            <table border=0 width=100%>
              <span *ngIf="campaign?.objective == 'LEAD_GENERATION'; then age_leads">
              </span>
              <span *ngIf="campaign?.objective == 'CONVERSIONS'; then conversions_age">
              </span>
              <span *ngIf="campaign?.objective == 'VIDEO_VIEWS'; then video_views_age">
              </span>
              <ng-template #age_leads>
                <tr>
                  <td width=50%>
                    Leads
                  </td>
                  <td width=50%>
                    <span class="metric"
                      *ngIf="getIndexForAge('leadgen.other', age_insights[i]) > -1; else noVal">{{ age_type.actions[getIndexForAge('leadgen.other', age_insights[i])].value | number }}</span>
                  </td>
                </tr>
                <tr>
                  <td width=50%>
                    Cost per Lead
                  </td>
                  <td width=50%>
                    <span
                      *ngIf="getIndexForAge('leadgen.other', age_insights[i]) > -1; else noVal">{{ ((age_type.cost_per_action_type[getIndexForAge('leadgen.other', age_insights[i])].value)* acc?.facebook_factor) | currency:acc?.account_currency:'symbol':'1.2-2' }}</span>
                  </td>
                </tr>
              </ng-template>
              <ng-template #conversions_age>
                <tr>
                  <td width=50%>
                    Conversions
                  </td>
                  <td width=50%>
                    <span class="metric"
                      *ngIf="getIndexForAge('offsite_conversion.fb_pixel_lead', age_insights[i]) > -1 || getIndexForAge('offsite_conversion.fb_pixel_custom', age_insights[i]) > -1; else noVal">{{ getTotalConversionsAge(age_type) | number }}</span>
                  </td>
                </tr>
                <tr>
                  <td width=50%>
                    Cost per Conversion
                  </td>
                  <td width=50%>
                    <span class="metric"
                      *ngIf="getIndexForAge('offsite_conversion.fb_pixel_lead', age_insights[i]) > -1 || getIndexForAge('offsite_conversion.fb_pixel_custom', age_insights[i]) > -1; else noVal">{{ getTotalConversionsAgeCost(age_type) | currency:acc?.account_currency:'symbol':'1.2-2' }}</span>
                  </td>
                </tr>
              </ng-template>
              <ng-template #video_views_age>
                <!--
                <tr>
                  <td width=50%>
                    Three-second video views
                  </td>
                  <td width=50%>
                    <span class="metric"
                      *ngIf="getIndexForAge('video_view', age_insights[i]) > -1; else noVal">{{ age_type.actions[getIndexForAge('video_view', age_insights[i])].value | number }}</span>
                  </td>
                </tr>
                <tr>
                  <td width=50%>
                    Cost per Three-second video view
                  </td>
                  <td width=50%>
                    <span
                      *ngIf="getIndexForAge('video_view', age_insights[i]) > -1; else noVal">{{ ((age_type.cost_per_action_type[getIndexForAge('video_view', age_insights[i])].value)* acc?.facebook_factor) | currency:acc?.account_currency:'symbol':'1.2-2' }}</span>
                  </td>
                </tr>
              -->
              </ng-template>
              <tr>
                <td width=50%>
                  Reached
                </td>
                <td width=50%>
                  {{ age_type?.reach | number }}
                </td>
              </tr>
            </table>
            <mat-divider></mat-divider>
          </mat-card-content>
        </div>
      </mat-card>
    </div>
  </div>