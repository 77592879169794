import { Component, OnInit } from '@angular/core';
import { NavigationState } from "../_models/index";
import { NavigationService, FormService } from "../_services/index";
import { AccountService } from '../_services/account.service';
import { FormBuilderService } from '../_services/form-builder.service';
import { ActivatedRoute, NavigationStart, Router, UrlSegment } from "@angular/router";
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-satisfactory-form',
  templateUrl: './satisfactory-form.component.html',
  styleUrls: ['./satisfactory-form.component.scss']
})
export class SatisfactoryFormComponent implements OnInit {
  forms: any = [];
  allForms: any = [];
  isShow: boolean;
  state: any;
  selectedIndex: number = 0;
  url: UrlSegment[];

  constructor(
    private accountService: AccountService,
    private navigationService: NavigationService,
    private formService: FormService,
    private formBuilderService: FormBuilderService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.state = this.navigationService.getCurrentNavigationState();

    let navigationState = this.router.getCurrentNavigation();
    if (navigationState && navigationState.extras.state && navigationState.extras.state.tab) {
      console.log(navigationState.extras.state);
      this.selectedIndex = 2;
    }
  }

  ngOnInit() {
    this.state = this.navigationService.getCurrentNavigationState();
    console.log(history.state.data);
    this.route.url.subscribe((url) => {
      console.log(url);
      this.url = url;
      console.log(this.url[0], this.url[1]);
      // if (this.url[1]) {
      //   this.selectedIndex = 2;
      // }
    });

    this.navigationService.updateState(
      new NavigationState(false, "Survey Forms", "", "#", "/survey-forms")
    );
    this.state = this.navigationService.getCurrentNavigationState();
    console.log(this.state);

    this.forms = [];
    this.formService.getSatisfactoryForms().then((querySnapshot) => {
      if (querySnapshot.empty === false) {
        this.isShow = true;
        querySnapshot.forEach((doc) => {
          console.log(doc.data());
          let form = {
            id: "",
            name: "",
            responses: 0,
            total_initiated: [],
            total_submitted: [],
          };
          form.id = doc.id;
          let ref = doc.ref
          console.log(form.id);
          form.name = doc.data().name;
          form['data'] = doc.data();

          this.formService.getSatisfactoryFormResponse(form.id,ref).subscribe((total_responses) => {
            console.log(total_responses.length);
            form.responses = total_responses.length;
            form.total_initiated = total_responses.filter(
              (x) => x["status"] == "initiated"
            );
            form.total_submitted = total_responses.filter(
              (x) => x["status"] == "submitted"
            );
            this.forms.push(form);
            console.log(this.forms);
          });
        });
      } else {
        this.isShow = false;
      }
      this.allForms = this.forms;
    });

  }

  createForm() {
    this.formBuilderService.createForm(this.state.url);
  }
  searchForm(event) {
    let s = event.target.value;
    this.forms = this.allForms.filter((form) => {
      return form.name.toLowerCase().indexOf(s.toLowerCase()) != -1;
    });
  }
}
