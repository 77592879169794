import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ReviewsService } from '../../_services/reviews.service';


@Component({
  selector: 'app-reviews-configuration',
  templateUrl: './reviews-configuration.component.html',
  styleUrls: ['./reviews-configuration.component.scss']
})
export class ReviewsConfigurationComponent implements OnInit {

  @ViewChild('form', { static: true }) ngForm: NgForm;
  displayMsg: string = 'Updating patient form configuration is in progress....';
  emailFrom: string = "write review<themktgdoctorteam@mktg.doctor>";
  smsFrom: string = "";
  emailSubject: string = "Write a review";
  emailBody: string = "Write a review by click on below link ";
  smsBody: string = "Write a review by click on below link ";
  inValid: boolean = false;
  confirmation: boolean;
  loading: boolean;
  loadingForConfiguration: boolean;
  docId: string;
  disabledBtn: boolean;
  saveFunction: boolean = false;

  constructor(
    public reviewService: ReviewsService,
    public dialogRef: MatDialogRef<ReviewsConfigurationComponent>
  ) { }

  ngOnInit(): void {
    this.getPatientFormConfiguration();
  }

  async getPatientFormConfiguration() {
    this.reviewService.getReviewConfiguration().then(async (data) => {
      if (!data.empty) {
        this.docId = data.docs[0].id;
        //console.log(this.docId);
        let configuration = data.docs[0].data();
        if (configuration) {
          if ("smsBody" in configuration)
            this.smsBody = configuration['smsBody'];
          if ("emailBody" in configuration)
            this.emailBody = configuration['emailBody'];
          if ("emailSubject" in configuration)
            this.emailSubject = configuration['emailSubject'];
        }
      }
    })
  }
  close() {
    let obj = {
      smsBody: this.smsBody,
      saveFlag: this.saveFunction
    }
    this.dialogRef.close(obj);
  }
  Save() {
    this.saveFunction = true;
    this.confirmation = true;
    this.loading = true;
    let reviewConfigurationData = {
      smsBody: this.smsBody,
      emailBody: this.emailBody,
      emailSubject: this.emailSubject,
      type: 'review-configuration'
    };
    if (this.docId) {
      this.displayMsg = 'Updating review configuration is in progress....';
      this.reviewService.updateReviewConfiguration(reviewConfigurationData, this.docId).then(resp => {
        this.loading = false;
        this.displayMsg = `Review configuration updated successfully`;
        setTimeout(() => {
          this.confirmation = false;
        }, 3000);
      });
    }
    else {
      this.displayMsg = 'Adding review configuration is in progress....';
      this.reviewService.addReviewConfiguration(reviewConfigurationData).then(async (resp) => {
        if (resp.id) {
          this.loading = false;
          this.docId = resp.id
          this.displayMsg = `Review configuration added successfully`;
          setTimeout(() => {
            this.confirmation = false;
          }, 3000);
        }
      });
    }
  }

  onTextChange(event) {
    this.saveFunction = false
    // //console.log(event);
    if (event['htmlValue']) {
      this.emailBody = event['htmlValue'];
      this.disabledBtn = false;
    }
    else {
      this.emailBody = "";
      this.disabledBtn = true;
    }
    // this.disabledStatus();
  }
  onTextChangeOfSms(event) {
    if (event['htmlValue']) {
      this.smsBody = event['htmlValue'];
      this.disabledBtn = false;
    }
    else {
      this.smsBody = "";
      this.disabledBtn = true;
    }
  }

}
