import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ElementRef, ViewChild, NgModule } from "@angular/core";
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
} from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import {MatChipInputEvent} from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { FetchSeedKWService } from "../_services/fetch-seed-kw.service";
import { TargetKWService } from "../_services/target-kw.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
//import {Location, Appearance} from '@angular-material-extensions/google-maps-autocomplete';
//import PlaceResult = google.maps.places.PlaceResult;
import { AccountService } from "../_services/index";
import { CustomValidators } from "./validatorsForChiplist";

@Component({
  selector: "app-target-keywords",
  templateUrl: "./target-keywords.component.html",
  styleUrls: ["./target-keywords.component.scss"],
})
export class TargetKeywordsComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredSeedKw: Observable<string[]>;
  seedKeywords: any = [];
  newKw: any = [];
  allSeedKws: any = [];
  arrayOfKeywords: any = [];
  selectedChips: any[] = [];
  selectedKW: any[] = [];

  helperKWCtrl = new FormControl();
  filteredHelperKw: Observable<string[]>;
  helperKeywords: any = [];
  newHelperKw: any = [];
  allHelperKws: any = [];
  arrayOfHelperKeywords: any = [];
  // selectedHelperChips: any[] = [];
  selectedHelperKW: any[] = [];

  dateOnSetTask: any;
  configForm = new FormGroup({
    seedKeywords: new FormControl([]),
  });
  // helperList: FormArray;

  location: any;
  countryCode: any;
  countryName: string;
  locationAutocomplete: string;
  countryCodeList: any = [];
  selected: string;
  // statusOfDocument:any{} = {};
  config: any = {
    //appearance: Appearance.OUTLINE,
    placeholderText: "Please enter the address",
    country: "",
    requiredErrorText: "The input is required",
    invalidErrorText: "The address is not valid",
  };

  form = this.fb.group({
    location: "",
    countryName: "",
  });

  seedKWCtrl: any;

  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild("auto1") matAutocomplete1: MatAutocomplete;

  @ViewChild("seedKWInput") seedKWInput: ElementRef<
    HTMLInputElement
  >;
  @ViewChild("helperKWInput") helperKWInput: ElementRef<
    HTMLInputElement
  >;

  @Output() selectedTabChange = new EventEmitter();
  constructor(
    private fetchSeedKWService: FetchSeedKWService,
    private targetKWService: TargetKWService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private accountService: AccountService
  ) {
    //this.fetchSeedKWService.getPrepopulateArray();
    /*    this.filteredSeedKw = this.configForm.value.seedKWCtrl.valueChanges.pipe(
      startWith(null),
      map((seedKeyword: string | null) =>
        seedKeyword ? this._filter(seedKeyword) : this.allSeedKws.slice()
      )
    );

    this.filteredHelperKw = this.helperKWCtrl.valueChanges.pipe(
      startWith(null),
      map((helperKeyword: string | null) => helperKeyword ? this._filterhelpers(helperKeyword) : this.allHelperKws.slice()));
*/
  }

  ngOnInit() {
    //this.fetchSeedKWService.setSeedKWInDB();
    this.seedKeywords = [];
    this.helperKeywords = [];

    // this.seedList = this.fb.array([],Validators.minLength(1));
    // this.helperList=this.fb.array([],Validators.minLength(1));
    this.configForm = this.fb.group({
      seedKeywords: [this.seedKeywords, [CustomValidators.validateRequired]],
    });
    this.configForm.controls["seedKeywords"].setValue(this.seedKeywords);

    this.fetchSeedKWService.getPrepopulateArrays1().then((result) => {
      if (result !== {}) {
        this.seedKeywords = result["seedKeywords"];
        this.helperKeywords = result["helperKeywords"];
        this.dateOnSetTask = result["taskSetOn"];
        if ("countryCode" && "location" in result) {
          this.countryCode = result["countryCode"]; //Retrive countrycode,location from db
          this.location = result["location"];
        }
      }
      //   this.configForm = this.fb.group({
      //   seedKWCtrl : "",
      //   helperKWCtrl : "",
      // seedList:this.seedList,
      // helperList:this.helperList
      //   });
    });

    this.accountService.getCountryCodes().then(
      (response) => {
        response.forEach((doc) => {
          this.countryCodeList.push(doc.data());
        });
        console.log(this.countryCodeList, this.countryCode);
        let result = this.countryCodeList.find(
          (i) => i.countryCode === this.countryCode
        ); // here finding country name from countrycode
        console.log(result);
        if (result != undefined) {
          // this.countryCode = [result.countryCode.toLowerCase()];
          this.countryCode = result.countryCode;
          this.countryName = result.countryName;
          this.selected = result.countryName;
          console.log(this.countryCode);

          // this.form = this.fb.group({                                           // populates data to form
          //       location : this.location,
          //       countryName : this.countryName,
          //   });
        } else {
          this.countryCode = "";
          this.countryName = "";
          this.selected = "";

          this.form = this.fb.group({
            // populates data to form
            location: "",
            countryName: "",
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.fetchSeedKWService.getSeedKWList().then((data) => {
      this.arrayOfKeywords = data;
      console.log(this.arrayOfKeywords);
      this.allSeedKws = this.arrayOfKeywords;
    });

    this.fetchSeedKWService.getHelperKWList().then((data) => {
      this.arrayOfHelperKeywords = data;

      //console.log(this.arrayOfHelperKeywords);
      this.allHelperKws = this.arrayOfHelperKeywords;
    });
  }

  changeData(newValue) {
    console.log(newValue);
    console.log(this.countryCodeList);
    let result = this.countryCodeList.find((i) => i.countryName === newValue);
    console.log(result);
    this.selected = result.countryName;
    this.countryCode = result.countryCode;
    console.log("this.selected->", this.selected);
    console.log("this.countryCode", this.countryCode);
  }

  changeSelected(parameter: string, query: string) {
    let index = this.selectedChips.indexOf(query);
    if (index >= 0) {
      this.selectedChips.splice(index, 1);
    } else {
      this.selectedChips.push(query);
    }
    //console.log("this. selectedChips " + this.selectedChips  );
  }

  // changeSelectedHelper(parameter: string, query: string) {
  //   let index = this.selectedHelperChips.indexOf(query);
  //   if (index >= 0) {
  //       this.selectedHelperChips.splice(index, 1);
  //   } else {
  //       this.selectedHelperChips.push(query);
  //   }
  //   console.log("this. selectedChips " + this.selectedHelperChips  );
  // }

  add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if (value.trim() !== "") {
      this.configForm.controls["seedKeywords"].setErrors(null);
      const tempSeeds = this.configForm.controls["seedKeywords"].value;
      this.seedKeywords.push(value);
      this.selectedKW = this.seedKeywords;
      tempSeeds.push(value.trim());
      this.configForm.controls["seedKeywords"].setValue(tempSeeds);
      if (this.configForm.controls["seedKeywords"].valid) {
        this.configForm.controls["seedKeywords"].markAsDirty();
        console.log("required field no error");
        input.value = "";
      } else {
        console.log("required field error");
      }
      // console.log(this.selectedKW);
    } else {
      this.configForm.controls["seedKeywords"].updateValueAndValidity();
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
    // this.configForm.value.seedKWCtrl.setValue(null);
  }

  addHelper(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.helperKeywords.push(value);
      this.selectedHelperKW = this.helperKeywords;
      // console.log(this.selectedHelperKW);
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
    // this.configForm.value.helperKWCtrl.setValue(null);
  }

  remove(seedKeyword, indx): void {
    let controller = this.configForm.controls["seedKeywords"];
    // let index = this
    if (indx > -1) {
      this.seedKeywords.splice(indx, 1);
    }
    controller.updateValueAndValidity();
    controller.markAsDirty();
  }

  removeHelper(helperKeyword, indx): void {
    this.helperKeywords.splice(indx, 1);
  }

  submitAndGetResults() {
    this.configForm.controls["seedKeywords"].markAsTouched();
    if (this.configForm.valid) {
      this.newKw = this.seedKeywords.filter(
        (item) => this.arrayOfKeywords.indexOf(item) < 0
      );
      this.fetchSeedKWService.updateNewSeedKW(this.newKw, this.arrayOfKeywords);
      this.fetchSeedKWService.getSeedKWList().then((data) => {
        this.arrayOfKeywords = data;
        this.allSeedKws = this.arrayOfKeywords;
      });
      this.newKw = "";

      console.log("on submit:", this.seedKeywords);
    } else {
      console.log("invalid form");
    }
    /* this.newHelperKw = this.helperKeywords.filter(item => this.arrayOfHelperKeywords.indexOf(item) < 0);

    this.fetchSeedKWService.updateNewHelperKW(
      this.newHelperKw,
      this.arrayOfHelperKeywords
    );
    // console.log(this.newHelperKw);
    this.fetchSeedKWService.getHelperKWList().then((data) => {
      this.arrayOfHelperKeywords = data;
      // console.log(this.arrayOfHelperKeywords);
      this.allHelperKws = this.arrayOfHelperKeywords;
      });
      this.newHelperKw = "";*/
    /*this.targetKWService.setTask(this.seedKeywords,this.helperKeywords,this.countryCode,this.location).then(
        reponse =>{
        this.snackBar.open(reponse['result'],'',{
          duration:4000,
            verticalPosition: 'top',
            panelClass: ['red-snackbar']
          });
        this.selectedTabChange.emit(0);
        // this.router.navigate(['/targetKwResult']);
        // this.router.navigate(['/target-keywords-Details'],{queryParams:{selectedIndex :}});
        console.log("task is set");
      });
    console.log(this.location);
  }

  selectedSeedKW(event: MatAutocompleteSelectedEvent): void {
    this.seedKeywords.push(event.option.value);
    // console.log(event.option.value);
    // console.log(this.seedKeywords);
    this.seedKWInput.nativeElement.value = "";
    this.newKw = this.seedKeywords.filter(
      (item) => this.arrayOfKeywords.indexOf(item) < 0
    );
    // console.log(this.newKw);
    this.newKw.push(this.selectedKW);
    this.seedKWCtrl.setValue(null);
  }

  selectedhelperKW(event: MatAutocompleteSelectedEvent): void {
    this.helperKeywords.push(event.option.value);
    // console.log(event.option.value);
    // console.log(this.helperKeywords);
    this.helperKWInput.nativeElement.value = "";
    this.newHelperKw = this.helperKeywords.filter(
      (item) => this.arrayOfHelperKeywords.indexOf(item) < 0
    );
    //console.log(this.newHelperKw);
    this.newHelperKw.push(this.selectedHelperKW);
    this.helperKWCtrl.setValue(null);
  }

  private _filter(value: any): string[] {
    return this.allSeedKws.filter(
      (seedKeyword) =>
        seedKeyword.toLowerCase().indexOf(value.toLowerCase()) === 0
    );
  }

  private _filterhelpers(value: any): string[] {
    return this.allHelperKws.filter(
      (helperKeyword) =>
        helperKeyword.toLowerCase().indexOf(value.toLowerCase()) === 0
    );
  }

  /*
  onAutocompleteSelected(result: PlaceResult) {
    console.log("onAddressSelected: ", result);
    if (result.address_components) {
      for (let i = 0; i < result.address_components.length; i++) {
        console.log(result.address_components[i].types);
        if ("country" == result.address_components[i].types[0]) {
          console.log(result.address_components[i].short_name);
          this.countryCode = result.address_components[i].short_name; //Return CountryCode from placeresult.
        }
      }
    }
    this.locationAutocomplete = result.formatted_address;
  }
  */

    /*
    onLocationSelected(location: Location) {
      console.log('onLocationSelected: ', location);
      console.log(this.locationAutocomplete);

  }
  */
  }

  selectedSeedKW(event) {
    console.log(event);
  }

  selectedhelperKW($event) {
    console.log(event);
  }
}
