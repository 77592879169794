<div class="mat-elevation-z8" id="pTable" *ngIf="show">
  <p-table [columns]="displayedColumns" [value]="formResponseGroup" dataKey="id"
    currentPageReportTemplate="{first} - {last} of {totalRecords}" rowExpandMode="single" #dt
    [globalFilterFields]="searchFields" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10,25,50]" sortMode="multiple" [multiSortMeta]="[{field: 'createdOn', order: -1}]"
    selectionMode="multiple">
    <!-- <ng-template pTemplate="caption">
          <div class="ui-helper-clearfix" style="text-align: left">
          </div>
          <div style="text-align: left;">
            <input class="filterInput" type="text" pInputText size="50" placeholder="Search "
              (input)="dt.filterGlobal($event.target.value, 'contains')">
          </div>
        </ng-template> -->
    <ng-template pTemplate="header">
      <tr>
        <th style="width:3em;"></th>
        <th *ngFor="let col of displayedColumns;let i=index" [pSortableColumn]="col.value" [style]="col.style">
          {{col.header| titlecase}}
          <p-sortIcon [field]="col.value" *ngIf="!disableSortColumns.includes(col.value)"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-expanded='expanded' let-index="rowIndex">
      <tr>
        <td>
          <a href="#" [pRowToggler]="rowData" (click)="getForms(rowData)">
            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
          </a>
        </td>
        <td>{{rowData['first_name'] | titlecase}}</td>
        <td>{{rowData['last_name'] | titlecase}}</td>
        <!-- <td>{{rowData['createdOn']?(rowData.createdOn.toDate()| date: 'dd/MM/yyyy hh:mm a'):''}}</td>
        <td>{{rowData['submittedOn']?(rowData.submittedOn.toDate()| date: 'dd/MM/yyyy hh:mm a'):''}}</td> -->
        <td>{{rowData['createdOn']?(rowData.createdOn.toDate()| date: 'MMM d, yyyy hh:mm a'):''}}</td>
        <td>{{rowData['submittedOn']?(rowData.submittedOn.toDate()| date: 'MMM d, yyyy hh:mm a'):''}}</td>
        <td>{{rowData.status |titlecase}}</td>
        <td>
          <div class="row">
            <div class="col-md-6" style="text-align: right;">
              <span class="material-icons" style="font-size: 1.5rem;cursor: pointer;text-align: left"
                (click)="copyForm(rowData)" matTooltip="Copy patient form">file_copy</span>
              <!-- <i class="fas fa-copy" style="font-size: 1.5rem;cursor: pointer;text-align: left;color: #7e84f2;" (click)="copyForm(rowData)" matTooltip="Copy Patient Form"></i> -->
            </div>
            <div class="col-md-6">
              <i class="fas fa-share-square" style="font-size: 1.5rem;cursor: pointer;text-align: right"
                (click)="resendForm(rowData)" matTooltip="Resend patient form"></i>
            </div>
          </div>
        </td>

      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-data let-rowData let-index="rowIndex">
      <tr *ngIf="formDetails.length>0 && isDataAvailabel">
        <td style="width: 100%;padding: 0%;background-color:white" colspan="7">
          <div class="row" style="margin: 10px;">
            <div *ngIf="rowData['contactDetails']['objectID']">
              <p style="margin-left: 14px;padding: 0px;">This form is linked to
                <a
                  [routerLink]="['/contacts',rowData.contactDetails.objectID?rowData.contactDetails.objectID:'','forms']">
                  {{rowData.contactDetails.first_name| titlecase}} {{ rowData.contactDetails.last_name|titlecase}}.
                </a>
              </p>
            </div>
            <p-table [value]="formDetails">
              <ng-template pTemplate="header">
                <tr
                  style="border-top: none !important;border-left: none !important;border-right: none !important;border-width: 0.1px">
                  <th
                    style="border-top: none !important;border-left: none !important;border-right: none !important;;border-width: 0.1px"
                    pSortableColumn="id">Form Name</th>
                  <th pSortableColumn="customer"
                    style="text-align: center; width: 10rem;border-top: none !important;border-left: none !important;border-right: none !important;border-width: 0.1px">
                    Status</th>
                  <th pSortableColumn="date"
                    style="text-align: center;width: 15rem;border-top: none !important;border-left: none !important;border-right: none !important;border-width: 0.1px">
                    Action</th>
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-content>
                  <tr
                    style="border-top: none !important;border-left: none !important;border-right: none !important;border-width: 0.1px">
                    <td
                      style="border-top: none !important;border-left: none !important;border-right: none !important;border-width: 0.1px">
                      {{content.form_name}}</td>
                    <td
                      style="text-align: center;border-top: none !important;border-left: none !important;border-right: none !important;border-width: 0.1px">
                      <span [class]="content.status.toLowerCase().replace(' ','')" style="border-radius: 10px;text-align: center">
                        <b style="margin:10px">{{content.status |titlecase}}</b>
                      </span>
                    </td>
                    <td
                      style="text-align: center;border-top: none !important;border-left: none !important;border-right: none !important;border-width: 0.1px">
                      <p-button [ngStyle]="{'padding':'6px'}" label="Download PDF" [disabled]="content['pdfUrl']==undefined"
                        (onClick)="downloadPdf(content.pdfUrl,content.form_name+' '+rowData.contactDetails.first_name+' '+rowData.contactDetails.last_name)">
                      </p-button>
                    </td>

                  </tr>
                </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    <tr *ngIf="formDetails.length<=0 && isDataAvailabel">
      <td colspan="7">
        <div style="text-align: center;width: 100%;">
          <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
        </div>
      </td>
    </tr>
    <tr *ngIf="!isDataAvailabel">
      <td colspan="7">
        <div style="text-align: center;width: 100%;">
          No Record Found
        </div>
      </td>
    </tr>
</ng-template>
</p-table>
</div>
<div class="mat-elevation-z8" id="pTable" *ngIf="!show">
  <div style="text-align: center;">
    <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
  </div>
</div>