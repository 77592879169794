import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { saveAs } from 'file-saver';
import { timeout } from "rxjs/operators";
import { convertToObject } from 'typescript';
import { DocumentRef } from '@agm/core/lib/utils/browser-globals';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {

  constructor(
    private http: HttpClient,
  ) { }
  async getAllTemplates() {
    return await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('image_template').get();

  }
  async ejsTemplateContent() {
    return firebase.firestore().collection('generalSettings').where('type', '==', 'TEMPLATE_PARSING').get()


  }
  async addNewSocialMediaPostTemplate(obj) {
    let docId = '';
    if (obj.previewImageUrl) {
      let docRef = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
        .collection('image_template').where('previewTemplateUrl', '==', obj.previewImageUrl).get();
      docId = docRef.docs[0].id;
      console.log(docRef.docs[0].id)
    } else {

    }
    let accountId = `${sessionStorage.getItem('current_acc_id')}`;
    // console.log("account id is-", accountId);

    let body: any = {};
    body = {
      type: obj.type,
      name: obj.templateName,
      url: obj.url,
      code: obj.code,
      accountId: accountId,
      commentLimit: obj.commentLimit ? obj.commentLimit : undefined,
      offerType: obj.offerType ? obj.offerType : undefined,
      documentId: docId
    }




    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    console.log(body);
    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/dyanamicTemplateSocialMediaPost`, body, header);
  }
  async getAllPost() {
    // await firebase.firestore().collection("accounts").doc('10vljdAgTku44mqO8GC9').collection('patient_forms').doc('4dqEYLn59dN6vnPGMbr0').update(data1);
    return await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('social_media_posts').get();

  }
  async getPostId(postUrl) {
    let snap = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('social_media_posts').where('postUrl', '==', postUrl).get();
    let obj = {
      data: snap.docs[0].data(),
      docid: snap.docs[0].id
    }
    return obj;
  }
  async deleteTemplate(template) {
    let docRef = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('image_template').where('previewTemplateUrl', '==', template.previewTemplateUrl).get();
    await docRef.docs[0].ref.delete()
    return ({ status: "success" });
  }
  async deletePost(postUrl) {
    let docRef = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('social_media_posts').where('postUrl', '==', postUrl).get();
    await docRef.docs[0].ref.delete()
    return ({ status: "success" });
  }
  async updateTemplate(template: any) {
    let docRef = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('image_template').where('previewTemplateUrl', '==', template.previewTemplateUrl).get();
    await docRef.docs[0].ref.update({ template: template.template, name: template.name, commentLimit: template.commentLimit });

  }
  async generatePost(url: string, previewTemplateUrl: string) {
    let accountId = `${sessionStorage.getItem('current_acc_id')}`;
    if (previewTemplateUrl == undefined) {
      previewTemplateUrl = 'default';
    }
    let body = {
      url: url,
      accountId: accountId,
      previewTemplateUrl: previewTemplateUrl
    }
    console.log("generate function body->", body)
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/generatePost`, body, header);

  }

  async downloadPost(newUrl: string, fileName: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    this.http.get(newUrl, { headers: headers, responseType: 'blob' }).subscribe(resp => {
      var blob = new Blob([resp], { type: 'application/pdf' });
      saveAs(blob, fileName);
    });
  }

  async postUpdate(postDocId: string, postData: any, template: any) {

    let accountId = `${sessionStorage.getItem('current_acc_id')}`;
    console.log(template);
    let body = {
      docId: postDocId,
      accountId: accountId,
      template: template[0].template,
      content: postData
    }
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    console.log(body);
    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/createSocialMediaPost`, body, header);

    // await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
    //   .collection('social_media_posts').doc(postDocId).update(postData);
    // return { 'status': 'success' };
  }
}
