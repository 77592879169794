<div class="row" id="subHeader1">
  <div id="pageTitle">
    <a [routerLink]="['/main']"> <i class="pi pi-chevron-left"></i>Campaigns</a>
  </div>
  <div id="subPageTitle">
    AdWords Campaigns
  </div>
</div>

<div class="container-fluid view">
  <div class="format-center" *ngIf="!isReady">
    <!--Fetching your campaigns ...-->
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
  <div *ngIf="isReady">
    <!---Spend,Conversion,ROI--->
    <div class="scrolling-wrapper-flexbox">
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi">
            {{total_spend * acc?.adwords_factor| currency: acc?.account_currency:"symbol":"1.0-0"}}
          </div>
          <div class="metric-caption kpi">Spend</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi">{{ totalConversions }}</div>
          <div class="metric-caption kpi">Conversions</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi" *ngIf="total_spend != 0; else zero_spend">
            {{(total_spend * acc?.adwords_factor) / totalConversions| currency: acc?.account_currency:"symbol":"1.0-1"}}
          </div>
          <ng-template #zero_spend>
            <div class="metric kpi">0</div>
          </ng-template>
          <div class="metric-caption kpi">Cost per conversion</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi">
            {{aw_totalConvValue| currency: acc.account_currency:"symbol":"1.0-0"}}
          </div>
          <div class="metric-caption kpi">Conversion Value</div>
        </mat-card-content>
      </mat-card>
      <mat-card class="metric-card">
        <mat-card-header>
          <mat-card-title class="metric-caption"></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="metric kpi" *ngIf="total_spend != 0; else zero_spend">
            {{aw_totalConvValue / (total_spend * acc?.adwords_factor)| number: "1.0-1"}}X
          </div>
          <ng-template #zero_spend>
            <div class="metric kpi">0X</div>
          </ng-template>
          <div class="metric-caption kpi">ROI</div>
        </mat-card-content>
      </mat-card>
    </div>
    <!---Engagements------>

    <div class="row">
      <div class="col">
        <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
          <mat-card-header>
            <mat-card-title class="">
              <h6>Engagement</h6>
            </mat-card-title>
            <mat-card-subtitle>
              <div class="row">
                <div class="col">
                  Website visits and behavior of users coming from AdWords.
                </div>
              </div>
              <mat-divider></mat-divider>
            </mat-card-subtitle>
          </mat-card-header>
          <center>
            <div *ngIf="!isReady">
              <!--Fetching your campaigns ...-->
              <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
          </center>
          <mat-card-content>
            <ul>
              <li>
                Total {{ users | number }} quality users acquired from AdWords
                marketing with {{ sessions | number }} visits to the website
              </li>
              <li>
                Spending on an average {{ duration }} mins per visit and
                browsing {{ ppv | number: "1.0-2" }} pages per visit.
              </li>
            </ul>
            <div class="scrolling-wrapper-flexbox">
              <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
                <mat-card-header>
                  <mat-card-title class="metric-caption kpi"></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="metric kpi">{{ users | number }}</div>
                  <div class="metric-caption kpi">Users</div>
                </mat-card-content>
              </mat-card>
              <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
                <mat-card-header>
                  <mat-card-title class="metric-caption kpi"></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="metric kpi">{{ sessions | number }}</div>
                  <div class="metric-caption kpi">Sessions</div>
                </mat-card-content>
              </mat-card>
              <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
                <mat-card-header>
                  <mat-card-title class="metric-caption"></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="metric kpi">
                    {{ bounce_rate | number: "1.0-2" }}%
                  </div>
                  <div class="metric-caption kpi">Bounce Rate</div>
                </mat-card-content>
              </mat-card>
              <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
                <mat-card-header>
                  <mat-card-title class="metric-caption"></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="metric kpi">{{ ppv | number: "1.0-2" }}</div>
                  <div class="metric-caption kpi">Pages/Session</div>
                </mat-card-content>
              </mat-card>
              <mat-card class="metric-card" [class.disable-channel]="!hasAnalyticsAccess">
                <mat-card-header>
                  <mat-card-title class="metric-caption"></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="metric kpi">{{ duration }}</div>
                  <div class="metric-caption kpi">Avg. Session Duration</div>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="">
              Campaigns (Active)
            </mat-card-title>
          </mat-card-header>
          <center>
            <div *ngIf="!isReady">
              <!--Fetching your campaigns ...-->
              <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
          </center>
          <mat-card-content>
            <div>
              <mat-list role="list">
                <mat-divider></mat-divider>

                <div *ngFor="let campaign of campaigns">
                  <mat-list-item role="listitem">
                    <h6>{{ campaign["@attributes"]?.campaign }}</h6>
                    <span class="spacer"></span>
                    <a [routerLink]="['/adwords-campaign', campaign['@attributes']?.campaignID]">
                      <mat-icon>chevron_right</mat-icon>
                    </a>
                  </mat-list-item>
                  <div class="row desktop-view">
                    <div class="col-2 metric">
                      {{
                        campaign["@attributes"]?.cost * acc?.adwords_factor
                          | currency: acc?.account_currency:"symbol":"1.0-0"
                      }}
                    </div>
                    <div class="col-2 metric ad_clicks">
                      {{ campaign["@attributes"]?.clicks | number: "1.0-0" }}
                    </div>
                    <div class="col-2 metric">
                      {{
                        (+campaign["@attributes"]?.avgCPC / 1000000) *
                          acc?.adwords_factor
                          | currency: acc?.account_currency:"symbol":"1.0-0"
                      }}
                    </div>
                    <div class="col-2 metric">
                      {{
                        campaign["@attributes"]?.conversions | number: "1.0-0"
                      }}
                    </div>
                    <div class="col-2 metric" *ngIf="
                        campaign.conversion_value > -1;
                        else desktop_conv_value_notSet
                      ">
                      {{
                        campaign.conversion_value
                          | currency: acc.account_currency:"symbol":"1.0-0"
                      }}
                    </div>
                    <ng-template #desktop_conv_value_notSet>
                      <div class="col-2 metric">?</div>
                    </ng-template>
                  </div>
                  <div class="row desktop-view">
                    <div class="col-2 metric-caption">Spend</div>
                    <div class="col-2 metric-caption">Clicks</div>
                    <div class="col-2 metric-caption">CPC</div>
                    <div class="col-2 metric-caption">Conversions</div>
                    <div class="col-2 metric-caption">Conversion Value</div>
                  </div>

                  <!--for mobile view-->
                  <div class="row mobile-view">
                    <div class="col-2 metric">
                      {{
                        campaign["@attributes"]?.cost * acc.adwords_factor
                          | currency: acc.account_currency:"symbol":"1.0-0"
                      }}
                    </div>
                    <div class="col-2 metric txtspace">
                      {{ campaign["@attributes"]?.clicks | number: "1.0-0" }}
                    </div>
                    <div class="col-2 metric txtspace">
                      {{
                        (+campaign["@attributes"]?.avgCPC / 1000000) *
                          acc.adwords_factor
                          | currency: acc.account_currency:"symbol":"1.0-0"
                      }}
                    </div>
                  </div>

                  <div class="row mobile-view">
                    <div class="col-2 metric-caption">Spend</div>
                    <div class="col-2 metric-caption txtspace">Clicks</div>
                    <div class="col-2 metric-caption txtspace">CPC</div>
                  </div>
                  <div class="row mobile-view">
                    <div class="col-2 metric  top_space">
                      {{
                        campaign["@attributes"]?.conversions | number: "1.0-0"
                      }}
                    </div>
                    <div class="col-2 metric txtspace  top_space" *ngIf="
                        campaign.conversion_value > -1;
                        else mobile_conv_value_notSet
                      ">
                      {{
                        campaign.conversion_value
                          | currency: acc.account_currency:"symbol":"1.0-0"
                      }}
                    </div>
                    <ng-template #mobile_conv_value_notSet>
                      <div class="col-2 metric txtspace  top_space">?</div>
                    </ng-template>
                  </div>
                  <div class="row mobile-view">
                    <div class="col-2 metric-caption">Conversions</div>
                    <div class="col-2 metric-caption txtspace">
                      Conversion Value
                    </div>
                  </div>
                  <mat-list-item>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </div>
              </mat-list>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>