import { Component, EventEmitter, Input, OnInit,Output } from '@angular/core';
import { marketingData } from '../../assets/data/marketingData';
import {Router} from "@angular/router";
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {MarketingOffersService} from "../_services/marketing-offers.service";
import {NavigationService} from "../_services/navigation.service";
import { NavigationState } from "../_models/index";

@Component({
  selector: 'app-marketing-offer-lists',
  templateUrl: './marketing-offer-lists.component.html',
  styleUrls: ['./marketing-offer-lists.component.scss']
})
export class MarketingOfferListsComponent implements OnInit {

  marketingTemplate:boolean = false;
  enableCreate : boolean = false;
  offerData = []
  // currentOfferData = []
  // pastOfferData = []
  // upcommingOfferData = []
  modalRef: NgbModalRef;
  // showOffers:boolean=false;
  @Output() editOffer = new EventEmitter<any>();
  @Input() showOffers;
  @Input() currentOfferData;
  @Input() pastOfferData;
  @Input() upcommingOfferData;

  constructor(
    private router: Router,
    private offersService: MarketingOffersService,
    private navigationService:NavigationService,
  ) { }

  ngOnInit() {

    this.navigationService.updateState(
      new NavigationState(false, "", "", "#", "/marketing-offers")
    );
    let accID = sessionStorage.getItem('current_acc_id');

    console.log(this.currentOfferData);
    console.log(this.pastOfferData);
    console.log(this.upcommingOfferData)

    if(this.currentOfferData || this.pastOfferData || this.upcommingOfferData)
    {    
        this.offersService.getCurrentOffers(accID).then(offers=>{
            this.currentOfferData = offers;
          this.offersService.getPastOffers(accID).then(pastOffers=>{
            this.pastOfferData = pastOffers
          });
          this.offersService.getUpcomingOffers(accID).then(comingOffer=>{
            this.upcommingOfferData = comingOffer;
          });
          this.showOffers=true;
        });
    }
  }

  actionOnOffer(action){
    if(action.type=='delete'){
      if(action.eventType=='past'){
        let offer = this.pastOfferData[action.index]
        let i = this.pastOfferData.splice(action.index,1);
        this.pastOfferData = [...this.pastOfferData];
        this.offersService.deleteOffer(offer.offerId);
      }
      if(action.eventType=='upcoming'){
        let offer = this.upcommingOfferData[action.index]
        let i = this.upcommingOfferData.splice(action.index,1);
        this.upcommingOfferData = [...this.upcommingOfferData];
        this.offersService.deleteOffer(offer.offerId);
      }else
      if(action.eventType=='current'){
        let offer = this.currentOfferData[action.index]
        let i = this.currentOfferData.splice(action.index,1);
        this.currentOfferData = [...this.currentOfferData];
        this.offersService.deleteOffer(offer.offerId);
      }
    }
    else if(action.type=='edit'){
        this.editOffer.emit(action);
    }
  }

}
