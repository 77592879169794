import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {SmsCampaignService} from "../../_services/sms-campaign.service";
@Component({
  selector: 'app-sms-campaign-configuration',
  templateUrl: './sms-campaign-configuration.component.html',
  styleUrls: ['./sms-campaign-configuration.component.scss']
})
export class SmsCampaignConfigurationComponent implements OnInit,OnChanges {
  loading:boolean=false;
  label:string="Save";
  pattern=/^\+([0-9]{1,3})\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  codes=[
    {name:'IN',code:'+91'},
    {name:'US',code:'+1'}];
  selectedCode:any;
  @Output() close = new EventEmitter<any>();
  @Input() configExist;
  // accSid:string;
  // authToken:string;
  // notifyServiceId:string;
  // twilioNo:number;
  configFormGroup = new FormGroup({
    twilioNo : new FormControl('', [Validators.required, Validators.pattern(this.pattern)]),
    // code : new FormControl('', [Validators.required]),
    accSid : new FormControl('', [Validators.required,Validators.minLength(34)]),
    authToken : new FormControl('', [Validators.required]),
    notifyServiceId : new FormControl('', [Validators.required]),
  })

  constructor(
    private smsCampaignService: SmsCampaignService,
  ) { }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }){
    if('configExist' in changes){
      if(changes.configExist.currentValue==true){
        let confData = this.smsCampaignService.getSmsConfigurations()
        console.log(confData);
        this.label="Update";
        this.configFormGroup.setValue({
          twilioNo :confData['twilio_from'],
          // code :confData['countryCode'],
          accSid :confData['account_sid'],
          authToken :confData['auth_token'],
          notifyServiceId :confData['notify_service_sid'],
        })
      }
    }
  }

  ngOnInit(): void {
  }
  updateConfiguration(form){
    console.log(this.selectedCode);
    console.log(this.configFormGroup.value);
    let formVal = this.configFormGroup.value;
    this.smsCampaignService.updateTwilioConfigurations(formVal).then(resp=>{
    },
    err=>{
      console.log(err);
    })
    this.close.emit("close");
  }

}
