export class Tip {
  _id: string;
  type: string;
  objective:string;
  channel: string;
  icon:string;
  age:string;
  title: string;
  short_desc: string;
  long_desc: string;
  image1: string;
  image2: string;
  image3: string;
  image4: string;
  image5: string;
/*  image:[
    {image1:string},
    {image2:string}
  ];*/
  budget: number;
  target_gender:string;
  target_age:string;
  target_location:string;
  target_notes:string;
  status:string;
  timestamp:string;
}
