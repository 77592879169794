import { Component, OnInit,Input } from '@angular/core';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { AlertService,UserService } from '../_services/index';

import { User } from '../_models/index';
import {Location} from '@angular/common';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'editProfile.html',
    styleUrls: ['./editProfile.component.scss']

})

export class EditProfileComponent {
   model: any = {};
    loading = false;
    returnUrl: string;
    user:User;
    token: string;
    submitted: boolean = false;
    currentUser: User;
    users: User[] = [];


    constructor(
      private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private alertService: AlertService,
        private location: Location )
        {
          this.currentUser = this.userService.getUser();
      }


      Back(){
      this.location.back();
      }


      ngOnInit() {
          //this.loadAllUsers();
          // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

      }



editProfile()
    {
       this.loading = true;
       this.userService.editProfile(this.currentUser)
        .subscribe(
            data => {
            this.alertService.success('Your profile updated successfully..',true);
            localStorage.setItem('user',data.text());
          // this.router.navigate([this.returnUrl]);
            this.Back();

            },
            error => {
                this.alertService.error("Couldn't update,Try again");
                this.loading = false;

            });

    }

    changePassword(){
    this.loading = true;

    if(this.model.newPassword===this.model.verifyPassword)
     {
        this.userService.changePassword(this.model, this.currentUser.uuid)
        .subscribe(
            data => {
                this.alertService.success('Your password updated successfully..',true);
                //this.router.navigate([this.returnUrl]);
                this.Back();
         },
         error => {
             this.alertService.error("Couldn't update,Try again");
             this.loading = false;
         });
         this.loading = false;

    }
    else
   {
     this.alertService.error("Password did not match.Please reenter");
   }

}


    onSubmit()
    {
    this.submitted=true;
    }

  }
