
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import * as moment from 'moment';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ReviewsService } from '../../_services/reviews.service';
@Component({
  selector: 'app-reply-review-dialog',
  templateUrl: './reply-review-dialog.component.html',
  styleUrls: ['./reply-review-dialog.component.scss']
})
export class ReplyReviewDialogComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  updateReplyData: any;
  popupHeader: string;
  popupPlaceholder: string;
  snakbarMessage: string;
  hidden: string = "display"
  moment: any = moment;
  replyFlag: boolean = false;
  updateFlag: boolean = false;
  btnFlag: boolean = true;
  update: boolean = false;
  submitFlag: boolean = false;
  displayModal: boolean = false;
  deleteFlag: boolean = false;
  popup: boolean = false;
  closeIcon: boolean = true;
  replyByOwner: string;
  popupLoder: boolean = false;
  //updateReplyData: any = {}
  btnDisable: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private reviewsService: ReviewsService,
    private _snackBar: MatSnackBar,
    public matdialogRef: MatDialogRef<ReplyReviewDialogComponent>) { }

  ngOnInit(): void {
    //console.log(this.data)
    this.updateReplyData = this.data.updateReplyData
    this.popupHeader = this.data.popupHeader
    this.popupPlaceholder = this.data.popupPlaceholder
    this.snakbarMessage = this.data.snakbarMessage
  }
  showMoreContent(content) {
    content.display = !content.display;
  }
  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  cancelbtn(content) {
    this.displayModal = false
    this.deleteFlag = false;
    content.updateReplyData = '';
    content.replyByOwner = '';
  }
  async submitReply(content, key) {
    //console.log(content);
    this.btnDisable = true
    this.popupLoder = true;
    this.hidden = "displayNone";
    content.replyFlag = false;
    this.closeIcon = false;
    let name = content.name;
    let reviewId = content.reviewId;
    //console.log(content.replyByOwner);

    let data = { "replyByOwner": content.replyByOwner, "name": name, 'reviewId': reviewId };
    let resp = await (await this.reviewsService.replyToReview(data)).subscribe(async resp => {
      //console.log(resp);
      if (resp['status'] == 'success') {
        this.popupLoder = false;
        //console.log(this.popupLoder);
        if (!this.popupLoder) {
          this.updateReplyData['reviewReply'] = {};
          this.updateReplyData['reviewReply']['comment'] = content.replyByOwner;
          this.openSnackBar(this.snakbarMessage);
          this.closeIcon = true;
          this.hidden = "display";
          this.matdialogRef.close();
        }
        content.replyByOwner = '';
        content.update = false;
      }
    }
    )
  }
  async deleteReplyOfReview(content) {
    let reviewId = content.reviewId;
    let name = content.name;
    this.btnDisable = true;
    this.popupLoder = true;
    this.hidden = "displayNone";
    this.closeIcon = false;
    let data = { "replyByOwner": content.replyByOwner, "name": name, 'reviewId': reviewId };
    //console.log("delete.")
    let resp = await (await this.reviewsService.deleteReply(data)).subscribe(resp => {
      //console.log(resp);
      if (resp['status'] == 'success') {
        this.popupLoder = false;
        this.matdialogRef.close();
        this.closeIcon = false;
        this.update = false;
        this.deleteFlag = false;
        content.reviewReply = '';
        this.hidden = "display";
        this.openSnackBar("Reply deleted successfully");
      }
    })
  }

}