import { Component, OnInit } from "@angular/core";
import { ConfigurationService } from "../../configuration.service";
import { SelectControlValueAccessor } from "@angular/forms";
//import { checkAndUpdateBinding } from '@angular/core/src/view/util';
import { isNumber } from "util";
import { validateHorizontalPosition } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import * as moment from "moment";
import { Time } from "@angular/common";
import { config } from "rxjs";
import { AccountService } from "../../_services/account.service";
import { AlertService } from "../../_services/alert.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ViewEncapsulation } from "@angular/core";
import { ChangeDetectionStrategy, ViewChild, TemplateRef } from "@angular/core";
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from "date-fns";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from "angular-calendar";
import { ElementRef } from "@angular/core";
import { MatDatepicker } from "@angular/material/datepicker";
import {
  NgbDatepickerConfig,
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-configure-calendar",
  templateUrl: "./configure-calendar.component.html",
  styleUrls: ["./configure-calendar.component.scss"],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class ConfigureCalendarComponent implements OnInit {
  config: any;
  slug: string;
  address: string;
  map_link: string;
  phone: number;
  name: string;
  TempArray: any = [];
  length_of_array: number;
  ontouch: boolean = true;
  ref: any;
  input: string;
  email: string;
  block_date: any;
  iden: number;
  flag: number = 0;
  checkboxOptions: any = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  SelectDays: any = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  // selectCalender=["dental-teeth-whitening-calendar","dental-calendar"];
  calenderName: string;
  toggleDays: any = [
    { selected: true, day: "0" },
    { selected: true, day: "1" },
    { selected: true, day: "2" },
    { selected: true, day: "3" },
    { selected: true, day: "4" },
    { selected: true, day: "5" },
    { selected: true, day: "6" },
  ];
  timezones: any = [
    "Chicago (UTC−06:00/UTC−05:00)",
    "India (UTC+05:30)",
    "Los_Angeles (UTC-08:00/UTC-07:00)",
    "New York (UTC-04:00/UTC-05:00)",
  ];
  tzSelected: any = [
    { name: "Chicago (UTC−06:00/UTC−05:00)", tzname: "America/Chicago" },
    { name: "India (UTC+05:30)", tzname: "Asia/Kolkata" },
    {
      name: "Los_Angeles (UTC-08:00/UTC-07:00)",
      tzname: "America/Los_Angeles",
    },
    { name: "New York (UTC-04:00/UTC-05:00)", tzname: "America/New_York" },
  ];
  selectedTimezone: string;
  NewArray: any = [];
  selectedDays: any = [];
  provided: any = [];
  Blocked_Date = [];
  temp: String;
  enable: boolean;
  service_enable: boolean;
  block_enable: boolean;
  TimeSlot: any = [];
  Slot: any = [];
  day: number = 0;
  start: string;
  date: Date;
  end: string;
  amount: string;
  calenders: any = [];
  Reference: any = [];
  selectedDate: any;
  lengthOfTimeslots: number;
  minDate: any;
  maxDate: any;
  myFilter: any;
  timeslots: any;
  get_curr_names: any;
  AccountName: string;
  showMapInfo: boolean = false;
  todaysDate: string = new Date().toLocaleDateString();
  iconStatus: boolean = false;
  CalendarState: boolean = true;
  fromDate: any;
  toDate: any;
  events: string[] = [];
  disabledDates: any = [];
  provided_start: any = [];
  Blocked_Date_first: any = [];
  filterDays_first: any = [];
  min: any;
  max: any;
  message: string;
  start_first: Time;
  end_first: Time;
  maxAamount: string;
  minAamount: string;
  calenderName_first: string;
  selectedTimezone_first: string;
  service_enable_first: boolean;
  getAllCalendar = [];
  getAllCalendarName: any = [];
  NewString: string = "";
  selectedCalenderName: string;

  disableSave: boolean = false;
  disableField: boolean = false;
  validationLeadRolling = false;
  alert_service: any;
  AlterCalendarName: string;
  CalenderNameList: any = [];
  providerRef: any;

  save() {
    this.NewArray = this.toggleDays;
    this.config.filterDays = [];
    for (var i = 0; i < this.NewArray.length; i++) {
      if (this.NewArray[i].selected == false) {
        this.config.filterDays.push(this.NewArray[i].day);
      }
    }
  }

  slugValidation() {
    console.log(this.calenders);
    var count = 1;
    this.disableSave = false;
    for (var i = 0; i < this.calenders.length; i++) {
      if (this.calenderName == this.calenders[i].slug) {
        count++;
      }
    }
    if (count >= 2) {
      this._snackBar.open(`'${this.calenderName}' already exists!`, "", {
        duration: 5000,
        verticalPosition: "top",
        panelClass: "mat-snack-bar-container-error",
      });
      this.disableSave = true;
    } else {
      this.disableSave = false;
    }
  }

  onSelect(value) {}
  check() {
    var newStartTime, newEndTime;
    var NewArrayHour = [];
    var NewArray = [];
    var checkTimeslot = [];
    var results;
    var arr = [];
    var count = 0;
    this.disableField = false;
    this.toggleDays = [
      { selected: true, day: 0 },
      { selected: true, day: 1 },
      { selected: true, day: 2 },
      { selected: true, day: 3 },
      { selected: true, day: 4 },
      { selected: true, day: 5 },
      { selected: true, day: 6 },
    ];

    for (var i = 0; i < this.config.filterDays.length; i++) {
      this.toggleDays[this.config.filterDays[i]].selected = false;
    }
    for (var i = 0; i < this.toggleDays.length; i++) {
      if (this.toggleDays[i].selected == false) {
        count++;
      }
    }
    if (count == 7) {
      this.disableField = true;
    }
    this.disabledDates = [];
    this.min = this.minDate.toDate();
    this.max = this.maxDate.toDate();
    this.selectedTimezone = this.config.timezone;
    this.selectedCalenderName = this.config.slug;
    this.AlterCalendarName = this.calenderName;
    this.enable = this.config.services.enabled;
    this.provided = this.config.services.provided;
    this.Blocked_Date = this.config.blocked_dates;
    this.TimeSlot = this.config.timeslots;
    if (this.config.timeslots[this.day].slots.length > 0) {
      this.start = this.config.timeslots[this.day].slots[0].start;
      this.end = this.config.timeslots[this.day].slots[0].end;
    }
    console.log("date: ", moment());
    this.Slot = this.config.timeslots[this.day].slots;
    this.service_enable = this.config.services.enabled;

    this.config.timeslots[this.day].slots = this.SortTimeSlot();

    this.myFilter = (d: Date): boolean => {
      const day = moment(d).day();
      return (
        !this.config["filterDays"].includes(day) &&
        !this.config["blocked_dates"].includes(moment(d).format("Y-MM-DD"))
      );
    };
  }

  constructor(
    private configurationService: ConfigurationService,
    private get_curr_account: AccountService,
    public calendar: NgbCalendar,
    public config1: NgbDatepickerConfig,
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) //public alert_service:AlertService
  {
    this.get_curr_account.accChange$.subscribe((accChange) => {
      this.router.navigateByUrl("/websites");
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((queryPrams) => {
      this.providerRef = queryPrams.id;
    });

    this.configurationService.getAccount().then((accountsSnapshot) => {
      if (!accountsSnapshot.empty) {
        accountsSnapshot.forEach((docSnapshot) => {
          let doc = docSnapshot.data();
          this.ref = docSnapshot.ref;
          this.Reference.push(this.ref);
          this.calenders.push(doc);
        });
      }
    });
    this.get_curr_names = this.get_curr_account.getCurrentAccDetails();
    this.AccountName = this.get_curr_names.name;

    this.config = {
      blocked_dates: [],
      filterDays: [],
      location: { address: "", map_link: "", name: "", phone: "" },
      maxDate: { amount: "weeks", key: 2 },
      message: "",
      minDate: { amount: "days", key: 0 },
      owner_email: "",
      services: { enabled: false, provided: [] },
      slug: "",

      timeslots: [
        { day: "Sunday", slots: [] },
        { day: "Monday", slots: [] },
        { day: "Tuesday", slots: [] },
        { day: "Wednesday", slots: [] },
        { day: "Thursday", slots: [] },
        { day: "Friday", slots: [] },
        { day: "Saturday", slots: [] },
      ],
      timezone: "",
    };
    this.myFilter = (d: Date): boolean => {
      const day = moment(d).day();
      return (
        !this.config["filterDays"].includes(day) &&
        !this.config["blocked_dates"].includes(moment(d).format("Y-MM-DD"))
      );
    };
    this.timeslots = this.config["timeslots"][this.day];
    this.minDate = moment().add(
      this.config["minDate"]["key"],
      this.config["minDate"]["amount"]
    );
    this.maxDate = moment().add(
      this.config["maxDate"]["key"],
      this.config["maxDate"]["amount"]
    );
    this.check();
  }

  ResetChanges() {
    this.config = {
      blocked_dates: [],
      filterDays: [],
      location: { address: "", map_link: "", name: "", phone: "" },
      maxDate: { amount: "weeks", key: 2 },
      message: "",
      minDate: { amount: "days", key: 0 },
      owner_email: "",
      services: { enabled: false, provided: [] },
      slug: "",

      timeslots: [
        { day: "Sunday", slots: [] },
        { day: "Monday", slots: [] },
        { day: "Tuesday", slots: [] },
        { day: "Wednesday", slots: [] },
        { day: "Thursday", slots: [] },
        { day: "Friday", slots: [] },
        { day: "Saturday", slots: [] },
      ],
      timezone: "",
    };

    this.minDate = moment().add(
      this.config["minDate"]["key"],
      this.config["minDate"]["amount"]
    );

    this.maxDate = moment().add(
      this.config["maxDate"]["key"],
      this.config["maxDate"]["amount"]
    );
    this.myFilter = (d: Date): boolean => {
      const day = moment(d).day();
      // Filter out certain days.
      return (
        !this.config["filterDays"].includes(day) &&
        !this.config["blocked_dates"].includes(moment(d).format("Y-MM-DD"))
      );
    };
    this.timeslots = this.config["timeslots"][this.day];
    this.disableSave = false;
    this.validationLeadRolling = false;
    this.check();
    this._snackBar.open("Calendar reset!", "", {
      duration: 2000,
      verticalPosition: "top",
      panelClass: "mat-snack-bar-container",
    });
  }

  ShowMap() {
    this.showMapInfo = true;
    this.iconStatus = true;
    return this.iconStatus;
  }

  ShowDate(event) {
    this.block_date = event;
  }

  service() {
    this.TempArray = this.provided;
    this.flag = 0;
    for (var i = 0; i < this.TempArray.length; i++) {
      if (this.TempArray[i] == this.input) {
        this.flag = 1;
        break;
      }
    }
    if (this.flag == 0 && this.input != undefined && this.input != "")
      this.provided.push(this.input);
    if (this.input == undefined) {
      this._snackBar.open("Cannot add blank service", "", {
        duration: 2000,
        verticalPosition: "top",
        panelClass: "mat-snack-bar-container-error",
      });
    }
    if (this.flag == 1) {
      //window.alert("Service " + this.input + " already present");
      this._snackBar.open("Service " + this.input + " already present", "", {
        duration: 2000,
        verticalPosition: "top",
        panelClass: "mat-snack-bar-container-error",
      });
    }
    console.log(this.provided);
    if (
      this.provided.length >= 2 &&
      this.config.services.provided.length >= 2
    ) {
      this.enable = false;
    } else {
      this.enable = true;
    }
    console.log(this.service_enable);

    this.config.services.provided = this.provided;
  }
  changedServiceEnabled(event) {
    console.log(event);
    this.config.services.enabled = event.checked;
  }

  Remove_All_services() {
    this.provided = [];
    this.config.services.provided = [];
    this.enable = true;
    this.service_enable = true;
  }

  Blocked_Dates_Function() {
    this.TempArray = this.Blocked_Date;

    this.flag = 0;
    //this.temp=this.block_date.toLocaleDateString();
    this.temp = moment(new Date(this.block_date)).format("Y-MM-DD");

    for (var i = 0; i < this.TempArray.length; i++) {
      if (this.TempArray[i] == this.temp) {
        this.flag = 1;
        break;
      }
    }
    if (this.flag == 0 && this.temp != undefined && this.temp != undefined) {
      //this.Blocked_Date.push(this.temp);
      this.config.blocked_dates.push(this.temp);
    }

    if (this.temp == undefined)
      window.alert("Blocked date should not be blank");
    if (this.flag == 1) window.alert("Date " + this.temp + " already present");
    if (this.Blocked_Date.length >= 2 || this.config.blocked_dates.length >= 2)
      this.block_enable = false;
    this.changeCalenderConstarint("myFilter");
  }

  changeTimezone() {
    this.config.timezone = this.selectedTimezone;
  }

  changeTimeslot() {
    this.config.timeslots[this.day].slots = this.SortTimeSlot();
    if (this.config.timeslots[this.day].slots.length > 0) {
      this.start = this.config.timeslots[this.day].slots[0].start;
      this.end = this.config.timeslots[this.day].slots[0].end;
    }
    this.timeslots = [];
    this.timeslots = this.config.timeslots[this.day];
  }

  SortTimeSlot() {
    this.config.timeslots[this.day].slots.sort((a, b) => {
      let startA = moment(a.start, "h:mm a");
      let startB = moment(b.start, "h:mm a");

      if (startA.isBefore(startB, "minute")) {
        return -1;
      } else if (startA.isSame(startB, "minute")) {
        return 0;
      } else {
        return 1;
      }
    });
    return this.config.timeslots[this.day].slots;
  }
  Add_Time_Slot() {
    this.flag = 0;
    var newStartTime, newEndTime;
    var NewArrayHour = [];
    var NewArray = [];
    var checkTimeslot = [];
    var results;
    var arr = [];

    let newTimeslot = {
      start: moment(this.start, "h:mm a"),
      end: moment(this.end, "h:mm a"),
    };
    for (var i = 0; i < this.config.timeslots[this.day].slots.length; i++) {
      let checkTimeslot = {
        start: moment(this.config.timeslots[this.day].slots[i].start, "h:mm a"),
        end: moment(this.config.timeslots[this.day].slots[i].end, "h:mm a"),
      };
      if (
        newTimeslot.start.isSame(checkTimeslot.start, "minute") &&
        newTimeslot.end.isSame(checkTimeslot.end, "minute")
      ) {
        // console.log("second");
        this.flag = 2;
        break;
      }

      //if(newTimeslot.start.isAfter(checkTimeslot.start,"minute") && newTimeslot.start.isBefore(checkTimeslot.end,"minute"))
      if (
        newTimeslot.start.isBetween(
          checkTimeslot.start,
          checkTimeslot.end,
          "minute",
          "[)"
        )
      ) {
        this.flag = 1;
        break;
      }

      //if(newTimeslot.end.isAfter(checkTimeslot.start,"minute") && newTimeslot.end.isBefore(checkTimeslot.end,"minute"))
      if (
        newTimeslot.end.isBetween(
          checkTimeslot.start,
          checkTimeslot.end,
          "minute",
          "(]"
        )
      ) {
        this.flag = 1;
        break;
      }

      if (
        newTimeslot.start.isBefore(checkTimeslot.start, "minute") &&
        newTimeslot.end.isAfter(checkTimeslot.end, "minute")
      ) {
        this.flag = 1;
        break;
      }
    }
    if (this.flag == 0) {
      if (newTimeslot.start.isBefore(newTimeslot.end, "minute")) {
        this.config.timeslots[this.day].slots.push({
          end: this.end,
          start: this.start,
        });
      } else {
        this._snackBar.open("Invalid slot", "", {
          duration: 2000,
          verticalPosition: "top",
          panelClass: "mat-snack-bar-container-error",
        });
      }
    } else if (this.flag === 1) {
      this._snackBar.open("This slot overlaps with another slot", "", {
        duration: 2000,
        verticalPosition: "top",
        panelClass: "mat-snack-bar-container-error",
      });
    } else {
      this._snackBar.open("Slot already set", "", {
        duration: 2000,
        verticalPosition: "top",
        panelClass: "mat-snack-bar-container-error",
      });
    }

    this.config.timeslots[this.day].slots = this.SortTimeSlot();
  }

  Remove_All_Blocked_Date() {
    this.Blocked_Date = [];
    this.block_enable = true;
  }

  //dateFilter = (date: Date) => date.getDay() !=0 &&  date.getDay() !=6
  Remove_Time_Slots(id) {
    this.TempArray = [];
    for (var i = 0; i < this.config.timeslots[this.day].slots.length; i++) {
      if (i != id) {
        this.TempArray.push(this.config.timeslots[this.day].slots[i]);
      }
    }
    this.config.timeslots[this.day].slots = [];
    this.config.timeslots[this.day].slots = this.TempArray;
    //this.config.blocked_dates = this.TempArray;
  }

  Remove_Services(id) {
    this.TempArray = [];
    for (var i = 0; i < this.provided.length; i++) {
      if (i != id) {
        this.TempArray.push(this.provided[i]);
      }
    }
    this.provided = [];
    this.provided = this.TempArray;
    this.config.services.provided = this.TempArray;
  }

  Remove_Blocked_Dates(id) {
    this.TempArray = [];
    for (var i = 0; i < this.Blocked_Date.length; i++) {
      if (i != id) {
        this.TempArray.push(this.Blocked_Date[i]);
      }
    }
    this.Blocked_Date = [];
    this.Blocked_Date = this.TempArray;
    this.config.blocked_dates = this.TempArray;
    this.changeCalenderConstarint("myFilter");
  }

  changeCalenderConstarint(constraint: string) {
    this.flag = 0;
    this.validationLeadRolling = false;
    switch (constraint) {
      case "minDate":
        this.minDate = moment().add(
          this.config.minDate.key,
          this.config.minDate.amount
        );
        this.min = this.minDate.toDate();
        this.flag = 1;
        break;

      case "maxDate":
        this.maxDate = moment().add(
          this.config.maxDate.key,
          this.config.maxDate.amount
        );
        this.flag = 1;
        this.max = this.maxDate.toDate();
        break;

      case "myFilter":
        this.save();
        var count = 0;
        this.disableField = false;
        this.myFilter = (d: Date): boolean => {
          const day = moment(d).day();
          return (
            !this.config["filterDays"].includes(day) &&
            !this.config["blocked_dates"].includes(moment(d).format("Y-MM-DD"))
          );
        };

        for (var i = 0; i < this.toggleDays.length; i++) {
          if (this.toggleDays[i].selected === false) {
            count++;
          }
        }
        if (count == 7) {
          this.disableField = true;
        }
        break;

      case "timeslots":
        this.timeslots = this.config["timeslots"][this.day];
        break;
      default:
        break;
    }
    if (this.flag == 1) {
      if (this.config.minDate.key >= 7 * this.config.maxDate.key) {
        this.validationLeadRolling = true;
        this._snackBar.open("Invalid Lead time", "", {
          duration: 5000,
          verticalPosition: "top",
          panelClass: ["mat-snack-bar-container-error"],
        });
      } else {
        this.validationLeadRolling = false;
        this.flag = 0;
      }
    }
  }

  saveCalendar() {
    this.slugValidation();

    if (this.disableSave == false && this.validationLeadRolling == false) {
      this.save();
      this.config.slug = this.calenderName;
      this.configurationService
        .addCalendar(this.config, this.providerRef)
        .then((response) => {
          this._snackBar.open("Calendar Saved!", "", {
            duration: 4000,
            verticalPosition: "top",
            panelClass: ["mat-snack-bar-container"],
          });
          this.router.navigateByUrl("/add-account");
        });

      // this.ResetChanges();
    } else {
      if (this.disableSave == true) {
        this._snackBar.open(`'${this.calenderName}' already exists!`, "", {
          duration: 10000,
          verticalPosition: "top",
          panelClass: ["mat-snack-bar-container-error"],
        });
      }
      if (this.validationLeadRolling == true) {
        this._snackBar.open("Invalid rolling period", "", {
          duration: 10000,
          verticalPosition: "top",
          panelClass: ["mat-snack-bar-container-error"],
        });
      }
    }
  }
}
