<div appSortableList (sort)="sort($event)" class="container-fluid scrolling-wrapper-inner-flexbox">
  <div class="sticky">
    <button mat-raised-button class="top-space large-button" (click)="select.open()">
      Add Section
      <mat-select #select [(ngModel)]="selectedInput" (selectionChange)="addSection()" name="addSection">
        <mat-option type="button" *ngFor="let input of answerTypes" [value]="input.value" style="font-size: 14px;">
          {{ input.label }}</mat-option>
      </mat-select>
    </button>
  </div>

  <ul>
    <li appSortable #ques_section *ngFor="let question_section of questions; let ind = index;" id="{{ind}}">
      <mat-card (click)="onFocus(ind)" class="metric-card">
        <p class="question-type" (click)="storeCurrAnsType(question_section.answer_type)">
          Q.{{ind + 1}} {{ getAnswerLabel(question_section.answer_type) | titlecase }}
          <i class="material-icons icon-btn ques-type-arrow">arrow_drop_down</i>
          <i class="material-icons icon-btn close-icon" (click)="deleteQues(question_section)">close</i>
        </p>
        <mat-form-field *ngIf="showAnsTypes">
          <mat-select [(ngModel)]="question_section.answer_type" (selectionChange)="changeAnsType(question_section)"
            name="changeAnsType">
            <mat-option *ngFor="let answer_type of answerTypes" [value]="answer_type.value">{{ answer_type.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="question-field">
          <p>Question</p>
          <mat-form-field class="full-width">
            <input matInput type="text" name="question" [(ngModel)]="question_section.question" required />
          </mat-form-field>
        </div>

        <span *ngIf="isExpanded[ind]">
          <!-- --describe question--- -->
          <div class="question-field">
            <p>Describe this question <small>(Optional)</small></p>
            <mat-form-field class="full-width">
              <textarea matInput name="short_description" [(ngModel)]="question_section.short_description"></textarea>
            </mat-form-field>
          </div>

          <!-- --file upload--- -->
          <div class="question-field" *ngIf="question_section.answer_type !== 'calculator'">
            <app-file-upload [section]="question_section"></app-file-upload>
          </div>

          <!-- --options--- -->
          <div class="question-field" *ngIf="showOptions(question_section)">
            <div class="row">
              <div class="col-md-4 options">
                <p>Options</p>
                <div *ngFor="let choice of question_section.choices; let ch_ind = index; trackBy: trackByFn">
                  <i class="material-icons icon-btn"
                    (click)="deleteChoice(question_section, choice)">remove_circle_outline</i>
                  <mat-form-field>
                    <input matInput type="text" name="choice" [(ngModel)]="questions[ind].choices[ch_ind]" />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4 options" *ngIf="question_section.answer_type == 'radio'">
                <p>Jump To</p>
                <div *ngFor="let choice of question_section.choices; let jump_ind = index">
                  <mat-form-field>
                    <mat-select placeholder="--Select Question--" [(ngModel)]="questions[ind].jumps[jump_ind]"
                      name="dropdown">
                      <mat-option *ngFor="let question_label of questions | slice:(ind + 1); let dropdown_ind = index;"
                        [value]="dropdown_ind + ind + 1">{{ question_label.question }}</mat-option>
                      <mat-option [value]="-1">Thank you screen</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4 options" *ngIf="question_section.answer_type == 'radio' ||
               question_section.answer_type == 'dropdown' ||
                question_section.answer_type == 'checkbox'">
                <p>Value</p>
                <div *ngFor="let choice of question_section.choices; let value_ind = index">
                  <mat-form-field>
                    <input matInput type="number" name="value{{ value_ind }}"
                      [(ngModel)]="questions[ind].values[value_ind]" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <i class="material-icons icon-btn" (click)="addChoice(question_section)">add_circle</i>
          </div>

          <!-- -multi rating-- -->
          <div class="question-field" *ngIf="question_section.answer_type == 'multi_rating'">
            <div *ngFor="let rate of question_section.rating; let ind = index; trackBy: trackByFn">
              <i class="material-icons icon-btn"
                (click)="deleteRatingParameter(question_section,ind)">remove_circle_outline</i>
              <mat-form-field>
                <input matInput type="text" name="rating" [(ngModel)]="rate.question" />
              </mat-form-field>
            </div>
            <i class="material-icons icon-btn" (click)="addRatingParameter(question_section)">add_circle</i>
          </div>
          <!-- ---- -->

          <div class="question-field" *ngIf="question_section.answer_type == 'calculator'">
            <p>Function</p>
            <div class="row">
              <div class="col-md-6 col-md-offset-6">
                <select placeholder="--Select Operand--" [(ngModel)]="question_section.calculation.function[0]"
                  name="dropdown">
                  <option *ngFor="let question_label of questions | slice:0:-1; let q_cnt = index;"
                    [value]="makeVariable(q_cnt)">Q. {{ question_label.question }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"
                *ngFor="let part of question_section.calculation.function | slice:1; let p_id = index;">
                <i *ngIf="p_id % 2 === 0" class="material-icons icon-btn"
                  (click)="deleteFuncParts(question_section, p_id + 1)">remove_circle_outline</i>
                <div *ngIf="p_id % 2 === 0">
                  <select placeholder="--Select Operation--"
                    [(ngModel)]="question_section.calculation.function[p_id + 1]" name="dropdown">
                    <option *ngFor="let operator of operations" [value]="operator.value">{{ operator.label }}</option>
                  </select>
                </div>
                <div *ngIf="p_id % 2 !== 0">
                  <select placeholder="--Select Operand--" [(ngModel)]="question_section.calculation.function[p_id + 1]"
                    name="dropdown">
                    <option [value]="0">-Constant-</option>
                    <option *ngFor="let question_label of questions | slice:0:-1; let q_cnt = index;"
                      [value]="makeVariable(q_cnt)">Q. {{ question_label.question }}</option>
                  </select>
                </div>
                <mat-form-field *ngIf="p_id % 2 !== 0 && isConstant(question_section.calculation.function[p_id + 1])">
                  <input matInput #constant type="number" name="const{{ p_id + 1 }}"
                    [(ngModel)]="question_section.calculation.function[p_id + 1]" />
                </mat-form-field>
              </div>
            </div>
            <i class="material-icons icon-btn" (click)="addFuncParts(question_section)">add_circle</i>
            <p style="margin-top: 20px;">Format</p>
            <mat-radio-group name="radio" [(ngModel)]="question_section.calculation.format">
              <div>
                <mat-radio-button value="number">Number</mat-radio-button>
              </div>
              <div>
                <mat-radio-button value="currency">Currency</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div class="question-field" *ngIf="question_section.answer_type !== 'radio'">
            <p>Jump To</p>
            <mat-form-field>
              <mat-select placeholder="--Select Question--" [(ngModel)]="questions[ind].jumps[0]" name="dropdown">
                <mat-option *ngFor="let question_label of questions | slice:(ind + 1); let dropdown_ind = index;"
                  [value]="dropdown_ind + ind + 1">{{ question_label.question }}</mat-option>
                <mat-option [value]="-1">Thank you screen</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="question-field" *ngIf="question_section.answer_type !== 'calculator'">
            <mat-checkbox name="{{ question_section.required }}" [(ngModel)]="question_section.required">Required
            </mat-checkbox>
          </div>
        </span>

        <!-- This is the helper -->
        <div *appDraggableHelper>
          <mat-card id="{{ind}}" class="metric-card">
            <p class="question-type">
              Q.{{ind + 1}} {{ getAnswerLabel(question_section.answer_type) | titlecase }}
              <i class="material-icons icon-btn close-icon">close</i>
            </p>
            <div class="question-field">
              <p>Question</p>
              <mat-form-field class="full-width">
                <input matInput type="text" name="question" [(ngModel)]="question_section.question" required />
              </mat-form-field>
            </div>
            <!--
       <div class="question-field">
         <p>Describe this question <small>(Optional)</small></p>
         <mat-form-field class="full-width">
           <textarea matInput name="short_description" [(ngModel)]="question_section.short_description"></textarea>
         </mat-form-field>
       </div>
      <div class="question-field">
        <app-file-upload [section]="question_section"></app-file-upload>
       </div>
       <div class="question-field">
         <div *ngIf="showOptions(question_section)">
           <div class="row">
             <div class="col-md-6">
               <p>Options</p>
               <div *ngFor="let choice of question_section.choices; let ch_ind = index">
                 <i class="material-icons icon-btn">remove_circle_outline</i>
                 <mat-form-field>
                   <input matInput type="text" name="{{ choice }}" [(ngModel)]="questions[ind].choices[ch_ind]" />
                 </mat-form-field>
               </div>
             </div>
             <div class="col-md-6" *ngIf="question_section.answer_type == 'radio'">
               <p>Jump To</p>
               <div *ngFor="let choice of question_section.choices; let jump_ind = index">
                 <mat-form-field>
                   <mat-select placeholder="--Select Question--" [(ngModel)]="questions[ind].jumps[jump_ind]" name="dropdown">
                     <mat-option *ngFor="let question_label of questions | slice:(ind + 1); let dropdown_ind = index;" [value]="dropdown_ind + ind + 1">{{ question_label.question }}</mat-option>
                    </mat-select>
                 </mat-form-field>
               </div>
             </div>
           </div>
           <i class="material-icons icon-btn">add_circle</i>
         </div>
       </div>
       <div class="question-field">
         <mat-checkbox name="{{ question_section.required }}" [(ngModel)]="question_section.required">Required</mat-checkbox>
       </div>
     -->
          </mat-card>
        </div>
        <div *ngIf="isExpanded[ind]; then up_arrow else down_arrow">
        </div>
        <ng-template #up_arrow><i class="material-icons expansion_indicator">arrow_upward</i></ng-template>
        <ng-template #down_arrow><i class="material-icons expansion_indicator">arrow_downward</i></ng-template>
      </mat-card>
    </li>
  </ul>
</div>
