<div class="col">
<!-- <button mat-button color="" class="form-control"  name="daterange">
  <form>
      <div class="form-group">
        <div class="input-group"  daterangepicker [options]="{startDate:dateInput.start, endDate:dateInput.end }" (selected)="selectedDate($event, dateInput)" >
           <span>
            {{ dateInput.start | date }} - {{ dateInput.end| date }}
          </span>
          <i class="fa fa-calendar"></i>
        </div>
      </div>
    </form>
</button> -->

  <label class="label">{{selectedLabel}}</label>&nbsp;
      <mat-form-field >
    <input matInput
            class="input"
            ngxDaterangepickerMd
            [locale]="{format: 'MMM DD YYYY'}"
            [(ngModel)]="selected"
            startKey="start"
            endKey="end"
            [ranges]="ranges"
            [alwaysShowCalendars]="true"
            [showClearButton]="false"
            [showRangeLabelOnInput]="false"
            (rangeClicked)="rangeClicked($event)"
            (datesUpdated)="datesUpdated($event)"
            [showCustomRangeLabel]="true"
          (change)=selectedDate($event)>
  </mat-form-field>
   <mat-icon class="icon-btn" >calendar_today</mat-icon>
</div>
          