import { Component, OnInit } from "@angular/core";
import { AccountService } from "../_services/index";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Event as NavigationEvent } from "@angular/router";
import { filter } from "rxjs/operators";
import { NavigationStart } from "@angular/router";
import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  acc: any;
  analyticsAcc: any = [];
  gmbAcc : any = [];
  selectedAnalyticsAcc: any = [];
  selectedProperty: any = [];
  selectedView: any;
  fb_accounts: any;
  gmb_accounts:any;
  adwords_accounts: any;
  email_lists: any;
  selectedFBAcc: any;
  selectedAdwordsAcc: any;
  selectedEmailList: any;
  selectedGmbAccounts:any;
  showAdwordsAccounts: boolean = false;
  showFBAccounts: boolean = false;
  showAnalyticsAccounts: boolean = false;
  showEmailLists: boolean = false;
  showGmbAccounts : boolean = false;
  facebookCredentials: any;
  adwordsCredentials: any;
  emailCredentials: any;
  analyticsCredentials: any;
  gmbCredentials:any;
  showData :boolean = false;
  gmbAccountId: any = [];
  gmbAccountName : any = [];
  gmbAccountUrl : any = [];
  selectedGmbId:any;
  selectedGmbUrl:any;
  gmbLocationData:any[]=[];
  step = 0;
  isDataLoaded:boolean=true;
  data:any=[1,2,3,4];
  gmbAccounts:any= [];
  allset:boolean=true;
  connect_gmb:boolean;
  unlinkGmbStart: boolean = false;
  errorInfo:object={
    error: null, error_description:null
  }
  gmbAccountLocationData: any =undefined;
  constructor(
    private navigationService: NavigationService,
    private _snackBar: MatSnackBar,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.accountService.accChange$.subscribe((accChange) => {
      // this.router.navigateByUrl('/account');
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  linkFacebookAcc() {
    this.accountService.linkFacebookAcc();
  }

  linkAdwordsAcc() {
    this.accountService.linkAdwordsAcc();
  }

  linkAnalyticsAcc() {
    this.accountService.linkAnalyticsAcc();
  }

  linkMailChimpAcc() {
    this.accountService.linkMailChimpAcc();
  }

  linkMyGMBAccount() {
    this.accountService.linkMyGMBAccount();
  }

  // saveGmbAccount(){
  //   this.gmbCredentials=  this.accountService.saveGmbCredential(this.selectedGmbAccounts);
  //   console.log(this.gmbCredentials);
  //   this.showGmbAccounts = false;
  // }


  saveFBAcc() {
    this.facebookCredentials = this.accountService.saveFBCredentialsToAccount(this.selectedFBAcc);
    this.showFBAccounts = false;
  }

  saveView() {
    this.analyticsCredentials = this.accountService.saveAnalyticsCredentialsToAccount(this.selectedView);
    console.log(this.analyticsCredentials);
    this.showAnalyticsAccounts = false;
  }

  saveAdwordsAcc() {
    this.adwordsCredentials= this.accountService.saveAdwordsCredentialsToAccount(
      this.selectedAdwordsAcc
    );
    this.showAdwordsAccounts = false;
  }

  saveEmailAcc() {
    this.accountService.saveMailChimpCredentialsToAccount(
      this.selectedEmailList
    );
    this.showEmailLists = false;
  }

  unlinkFBAcc() {
    this.accountService.unlinkFBAcc();
    this.showFBAccounts = false;
    this.facebookCredentials = undefined;
  }

  unlinkGoogleAnalyticsAccs(refresh_token: string) {
    this.accountService.unlinkGoogleAccs(refresh_token,'analytics');
    this.showAnalyticsAccounts = false;
    this.analyticsCredentials = undefined;
  }

  unlinkGoogleAdwordsAccs(refresh_token: string)
  {
    this.accountService.unlinkGoogleAccs(refresh_token,"adwords");
    this.showAdwordsAccounts = false;
    this.adwordsCredentials =undefined;
  }

  unlinkGmbAccount(refresh_token: string){
    this.unlinkGmbStart = true;
    // this.accountService.unlinkGmbAccount(refresh_token,"gmb").subscribe((res) => {
    //   console.log(res)

    // },
    // error=>{
    //   console.log(error);
    // });
    let res={};
    setTimeout(()=>{                           //<<<---using ()=> syntax
      this.accountService.deleteAccount(res,'gmb');
      this.unlinkGmbStart = false;
    }, 3000);

    this.showGmbAccounts =false;
    this.gmbCredentials = undefined;
  }

  editFBAcc() {
    this.accountService
      .getFBAdAccounts(this.facebookCredentials.facebook_access_token)
      .subscribe((accounts) => {
        this.fb_accounts = accounts["data"];
        this.selectedFBAcc = {
          account_id: this.acc.facebook_account_id,
          account_name: this.acc.facebook_account_name,
        };
        this.showFBAccounts = true;
      });
  }

  editAdwordsAcc() {
    this.accountService
      .getAdwordsAccounts(this.adwordsCredentials.adwords_refresh_token)
      .subscribe((accounts) => {
        this.adwords_accounts = accounts["accounts"];
        this.selectedAdwordsAcc = {
          account_id: this.acc.adwords_customer_id,
          account_name: this.acc.adwords_account_name,
        };
        this.showAdwordsAccounts = true;
      });
  }

  editGmbAcc() {
    this.isDataLoaded = false;
    this.showGmbAccounts = false;
    this.selectedGmbAccounts = {};
    if(this.gmbAccountLocationData == undefined){ 
      console.log("first");
      this.accountService
        .getGmbAccounts(this.gmbCredentials['gmb_refresh_token'])
        .subscribe((gmbAcc) => {
          this.gmbAccountLocationData = gmbAcc['locations'];
          let locationName = this.gmbAccounts.map(element=>element['locationName']);
          this.gmbLocationData = this.gmbAccountLocationData.filter(f => !locationName.includes(f['locationName']) );
          this.showGmbAccounts = true;
          this.isDataLoaded = true;
        });
    }
    else{
      console.log("not first");
        setTimeout(()=>{           
            let locationName = this.gmbAccounts.map(element=>element['locationName']);
            this.gmbLocationData = this.gmbAccountLocationData.filter(f => !locationName.includes(f['locationName']) );     
            this.showGmbAccounts = true;
            this.isDataLoaded = true;
        }, 1000);
    }
  }

  saveGmbAccount(){
    this.gmbAccounts.push(
      {
        "locationName":this.selectedGmbAccounts['locationName'],
        "name":this.selectedGmbAccounts['name']
      }
    );
    this.accountService.saveGmbCredential(this.gmbAccounts);
    this.gmbCredentials = this.accountService.getGmbAccountCredential('gmb');
    this.showGmbAccounts = false;
  }

  deleteGmbAccount(account){
    this.gmbAccounts.splice(this.gmbAccounts.indexOf(account), 1);
    this.accountService.saveGmbCredential(this.gmbAccounts);
    this.gmbCredentials = this.accountService.getGmbAccountCredential('gmb');
    this.isDataLoaded = true;
    this.showGmbAccounts = false;
    console.log(this.gmbCredentials);
  }

  editAnalyticsAccount() {
    this.accountService
      .getAnalyticsAccounts(this.analyticsCredentials.analytics_refresh_token)
      .subscribe((analyticsAcc) => {
        this.analyticsAcc = analyticsAcc["Accounts"];
        this.showAnalyticsAccounts = true;
      });
  }

  editMailChimpAcc() {
    this.accountService
      .getMailChimpLists(this.acc.mailchimp_access_token, this.acc.mailchimp_dc)
      .subscribe((lists) => {
        this.email_lists = lists["lists"];
        this.showEmailLists = true;
      });
  }
  ngOnInit() {
    //sub-header
    this.navigationService.updateState(
      new NavigationState(false, "Connected Accounts", "", "#", "/account")
    );

    this.acc = this.accountService.getCurrentAccDetails();

    this.accountService.check().then(chk=>{

    console.log(chk);

    this.facebookCredentials = this.accountService.getLinkedAccountCredentials(
      "facebook"
    ),  
    
    this.adwordsCredentials = this.accountService.getLinkedAccountCredentials(
      "adwords"
    ),

    this.analyticsCredentials = this.accountService.getLinkedAccountCredentials(
      "analytics"
    ),

    this.gmbCredentials = this.accountService.getLinkedAccountCredentials(
      "gmb"
    )
    console.log(this.gmbCredentials);
    if(this.gmbCredentials){
      this.gmbAccounts = this.gmbCredentials['gmb_info'];
    }
     } );


    this.route.queryParams.subscribe(
      (params) => {
        if (params["adwords_refresh_token"]) {
          console.log(params['adwords_refresh_token'])
          this.accountService
            .getAdwordsAccounts(params["adwords_refresh_token"])
            .subscribe((data) => {
              this.adwords_accounts = data["accounts"];
              this.showAdwordsAccounts = true;
            });
        }

        if (params["fb_access_token"]) {
          console.log(params['fb_access_token'])
          this.accountService
            .getFBAdAccounts(params["fb_access_token"])
            .subscribe((accounts) => {
              this.gmb_accounts = accounts["data"];
              this.showFBAccounts = true;
            });
        }

        if (params["analytics_refresh_token"]) {
          console.log(params['analytics_refresh_token'])
          this.accountService
            .getAnalyticsAccounts(params["analytics_refresh_token"])
            .subscribe((analyticsAcc) => {
              this.analyticsAcc = analyticsAcc["Accounts"];
              console.log(this.analyticsAcc);
              this.showAnalyticsAccounts = true;
            });
        }

        if (params["gmb_refresh_token"]) {
          this.allset = false;
          console.log(params['gmb_refresh_token'])
          if(params['gmb_refresh_token']){
            localStorage.setItem('gmb_refresh_token',params["gmb_refresh_token"]);
            this.accountService
              .getGmbAccounts(params["gmb_refresh_token"])
              .subscribe((gmbAccount) => {
                this.gmbLocationData = gmbAccount['locations'];
                if(this.gmbLocationData){
                  this.gmbAccountLocationData = gmbAccount['locations'];
                  this.accountService.storedGmbInitialInfo(params["gmb_refresh_token"]);
                }
                this.showGmbAccounts = true;
                this.allset = true;
              });
          }
        }
      },
      (error) => {
        console.error(error);
        this.openSnackBar(error['error']);
      }
    );
  }
  close(){
    this.isDataLoaded = true;
    this.showGmbAccounts = false;
  } 

  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 4000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  selectCardOfAccount(type){
    console.log(type);
  }

}
