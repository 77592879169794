/*
 * Currently unused
 * Is the model for custom filters in the Contacts component
 */

export interface Filter {
	field: Field;
	operators: Array<Operator>;
	datatype: string;
	selectedOperator? : Operator;
	selectedValue? : any;
}

export interface Field {
	label: string;
	columnName: string;
};

export interface Operator {
	label: string;
	value: any;
}


/*export interface FilterApplied {
	field: Field;
	operator: Operator;
	value: any;
}*/
