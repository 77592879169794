import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router, NavigationEnd } from "@angular/router";
import { NavigationState } from "../_models/index";
import {
  NavigationService,
  TipsService,
  TimePeriodService,
  FacebookService,
  AdWordsService,
  AccountService,
} from "../_services/index";
import { DatePipe } from "@angular/common";
import { Subject } from "rxjs";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  timeperiod: any;
  total_spend: number = 15000;
  value: number = 27000;
  email_spend = 5000;
  isReady: boolean;
  fb_spend;
  adwords_spend;
  hidesetFlag: boolean = false;

  acc: any;

  hasEmailAccess: boolean;
  hasFBAccess: boolean;
  hasAdWordsAccess: boolean;
  total_conversion_value: any;

  adwordsCredentials: any;
  facebookCredentials: any;
  emailCredentials: any;

  adwordsCampaigns: any;
  totalAdwordsConversions: number = 0;

  facebookCampaigns: any;
  fb_conversion: number;
  totalFacebookConversions: number = 0;

  totalConversion: number = 0;
  smsCredentials: any;
  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private tipsService: TipsService,
    private timePeriodService: TimePeriodService,
    private facebookService: FacebookService,
    private adwordsService: AdWordsService,
    private accountService: AccountService
  ) {
    this.navigationService.updateState(
      new NavigationState(false, "Campaigns", "", "#", "/main")
    );

    this.accountService.accChange$.subscribe((accChange) => {
      //this.router.navigateByUrl('/main');
      let state = this.navigationService.getCurrentNavigationState();
      if (state.url === "/main") {
        console.log("The account changed in the main component.");
        this.acc = this.accountService.getCurrentAccDetails();
        this.facebookCredentials = this.accountService.getLinkedAccountCredentials(
          "facebook"
        );
        this.adwordsCredentials = this.accountService.getLinkedAccountCredentials(
          "adwords"
        );
        this.emailCredentials = this.accountService.getLinkedAccountCredentials(
          "email"
        );
        this.smsCredentials = this.accountService.getLinkedAccountCredentials(
          "twilio"
        );
        this.loadData();
      }
    });
    this.timePeriodService.timePeriod$.subscribe((timeperiod) => {
      let state = this.navigationService.getCurrentNavigationState();
      if (state.url === "/main") {
        this.isReady = false;
        this.timeperiod = timeperiod;
        this.loadData();
      }
    });
  }

  ngOnInit() {
    this.isReady = false;
    this.acc = this.accountService.getCurrentAccDetails();
    console.log(this.acc);

    this.timeperiod = this.timePeriodService.getCurrentTimeperiod();
    this.facebookCredentials = this.accountService.getLinkedAccountCredentials(
      "facebook"
    );
    this.adwordsCredentials = this.accountService.getLinkedAccountCredentials(
      "adwords"
    );
    this.emailCredentials = this.accountService.getLinkedAccountCredentials(
      "email"
    );
    this.loadData();
  }

  loadData() {
    this.hasEmailAccess = this.emailCredentials ? true : false;
    this.hasFBAccess = this.facebookCredentials ? true : false;
    this.hasAdWordsAccess = this.adwordsCredentials ? true : false;

    if (!this.acc.facebook_factor) {
      this.acc.facebook_factor = 1;
    }

    if (!this.acc.adwords_factor) {
      this.acc.adwords_factor = 1;
    }

    if (!this.acc.account_currency) {
      this.acc.account_currency = "INR";
    }

    if (!this.hasEmailAccess) {
      this.email_spend = 0;
    } else {
      this.email_spend = 5000;
    }

    let cust_id;
    let refreshToken;
    if (this.adwordsCredentials) {
      cust_id = this.adwordsCredentials.adwords_cust_id;
      refreshToken = this.adwordsCredentials.adwords_refresh_token;
      this.hasAdWordsAccess = true;
    } else {
      this.hasAdWordsAccess = false;
    }

    //FB caching
    if (this.hasFBAccess) {
      // We can fetch FB data from this account
      let record = JSON.parse(
        sessionStorage.getItem("FB-spend-" + this.acc.name)
      );
      if (record !== null) {
        if (record.results.data.length == 1) {
          if (
            new DatePipe("en-US").transform(this.timeperiod.start, "yyyy-MM-dd") === record.results.data[0].date_start &&
            new DatePipe("en-US").transform(this.timeperiod.end, "yyyy-MM-dd") === record.results.data[0].date_stop) {
            if (Date.now() - record.creation_timestamp < 300000) {
              console.log(
                "We have a fresh record for this time period. Fetched from localStorage..."
              );
              this.fb_spend = record.results.data.length == 1 ? record.results.data[0].spend : 0;
              this.getFacebookConversions();
              this.isReady = true;
            } else {
              console.log(
                "We have an old record for this time period. Called API and updated record..."
              );
              this.facebookService.getAccountSpend(
                this.timeperiod,
                this.facebookCredentials.facebook_account_id,
                this.facebookCredentials.facebook_access_token
              )
                .then((info) => {
                  //isReady = false;
                  this.fb_spend = info.data.length == 1 ? info.data[0].spend : 0;
                  this.isReady = true;
                  sessionStorage.setItem(
                    "FB-spend-" + this.acc.name,
                    JSON.stringify({
                      creation_timestamp: Date.now(),
                      results: info,
                    })
                  );
                  this.getFacebookConversions();
                });
            }
          }
          else {
            console.log("We do not have a record for this time period. Called API and added record...");
            this.facebookService.getAccountSpend(
              this.timeperiod,
              this.facebookCredentials.facebook_account_id,
              this.facebookCredentials.facebook_access_token
            )
              .then((info) => {
                this.fb_spend = info.data.length == 1 ? info.data[0].spend : 0;
                this.isReady = true;
                sessionStorage.setItem(
                  "FB-spend-" + this.acc.name,
                  JSON.stringify({
                    creation_timestamp: Date.now(),
                    results: info,
                  })
                );
                this.getFacebookConversions();
              });
          }
        } else {
          console.log("We do not have a record for this time period. Called API and added record...");
          this.facebookService.getAccountSpend(
            this.timeperiod,
            this.facebookCredentials.facebook_account_id,
            this.facebookCredentials.facebook_access_token
          )
            .then((info) => {
              this.fb_spend = info.data.length == 1 ? info.data[0].spend : 0;
              this.isReady = true;
              sessionStorage.setItem(
                "FB-spend-" + this.acc.name,
                JSON.stringify({
                  creation_timestamp: Date.now(),
                  results: info,
                })
              );
              this.getFacebookConversions();
            });
        }
      } else {
        console.log("We do not have a record for FB spend. Called API and added record...");

        this.facebookService.getAccountSpend(
            this.timeperiod,
            this.facebookCredentials.facebook_account_id,
            this.facebookCredentials.facebook_access_token
          )
          .then((info) => {
            this.fb_spend = info.data.length == 1 ? info.data[0].spend : 0;
            this.isReady = true;
            sessionStorage.setItem(
              "FB-spend-" + this.acc.name,
              JSON.stringify({ creation_timestamp: Date.now(), results: info })
            );
            this.getFacebookConversions();
          });
      }
    } else {
      // Since we don't have access to FB account, the FB spend will be 0.
      this.fb_spend = 0;
    }

    if (this.hasAdWordsAccess) {
      // We can fetch AdWords data from this account
      //Adwords caching
      let adwords_record = JSON.parse(
        sessionStorage.getItem("adwords-spend-" + this.acc.name)
      );
      if (adwords_record !== null) {
        let adwords_date = adwords_record.results["date-range"]["@attributes"].date.split("-");

        if (
          new DatePipe("en-US").transform(this.timeperiod.start, "MMM d, y") === adwords_date[0] &&
          new DatePipe("en-US").transform(this.timeperiod.end, "MMM d, y") === adwords_date[1]
        ) {
          if (Date.now() - adwords_record.creation_timestamp < 300000) {
            console.log("We have a fresh record for this time period. Fetched from localStorage...");
            this.adwords_spend = +adwords_record.results.table.row["@attributes"].cost / 1000000;
            this.getAdwordsConversions();
            this.isReady = true;
          } else {
            console.log("We have an old record for this time period. Called API and updated record...");

            this.adwordsService.getAccountSpend(cust_id, refreshToken, this.timeperiod).subscribe((spend) => {
                this.adwords_spend = spend;
                this.adwords_spend = +this.adwords_spend.table.row["@attributes"].cost / 1000000;
                this.isReady = true;
                sessionStorage.setItem(
                  "adwords-spend-" + this.acc.name,
                  JSON.stringify({
                    creation_timestamp: Date.now(),
                    results: spend,
                  })
                );
                this.getAdwordsConversions();
              });
          }
        } else {
          console.log("We do not have a record for this time period. Called API and added record...");

          this.adwordsService.getAccountSpend(cust_id, refreshToken, this.timeperiod).subscribe((spend) => {
              this.adwords_spend = spend;
              this.adwords_spend = +this.adwords_spend.table.row["@attributes"].cost / 1000000;
              this.isReady = true;
              sessionStorage.setItem(
                "adwords-spend-" + this.acc.name,
                JSON.stringify({
                  creation_timestamp: Date.now(),
                  results: spend,
                })
              );
              this.getAdwordsConversions();
            });
        }
      } else {
        console.log("We do not have a record for AdWords spend. Called API and added record...");

        this.adwordsService.getAccountSpend(cust_id, refreshToken, this.timeperiod).subscribe((spend) => {
          this.adwords_spend = spend;
          this.adwords_spend = +this.adwords_spend.table.row["@attributes"].cost / 1000000;
          this.isReady = true;
          sessionStorage.setItem(
            "adwords-spend-" + this.acc.name,
            JSON.stringify({ creation_timestamp: Date.now(), results: spend })
          );
          this.getAdwordsConversions();
        });
      }
    } else {
      // Since we don't have access to the AdWords account, the AdWords spend will be 0.
      this.adwords_spend = 0;
      this.isReady = true;
    }
  }

  getAdwordsConversions() {
    this.totalAdwordsConversions = 0;
    this.adwordsService.getCampaigns(
        this.adwordsCredentials.adwords_cust_id,
        this.adwordsCredentials.adwords_refresh_token,
        this.timeperiod
      )
      .subscribe((campaigns) => {
        this.adwordsCampaigns = campaigns["table"].row;
        for (let i = 0; i < this.adwordsCampaigns.length; i++) {
          this.totalAdwordsConversions += +this.adwordsCampaigns[i]["@attributes"]["conversions"];
          console.log(this.totalAdwordsConversions);
        }
      });
  }

  getFacebookConversions() {
    this.facebookService.getFacebookInsights(
        this.facebookCredentials.facebook_access_token,
        this.facebookCredentials.facebook_account_id,
        this.timeperiod
      )
      .then((res) => {
        this.facebookCampaigns = res;
        for (let i = 0; i < this.facebookCampaigns["data"].length; i++) {
          this.fb_conversion = this.facebookService.getTotalConversions(
            this.facebookCampaigns["data"][i]
          );
          this.totalFacebookConversions += +this.fb_conversion;
          console.log(this.totalFacebookConversions);
        }
      });
  }
}
