<div class="container-fluid view">
<!--<div id="content" #content>
  <h1>HTML to PDF conversion</h1>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fermentum turpis sit amet metus consequat egestas. Aenean eget eros quis odio interdum luctus ut non nisl. Etiam pharetra non elit ac venenatis. Fusce tempor id magna vitae interdum. Aenean non ligula nec dolor viverra varius. Vivamus ut laoreet dui, vehicula tristique metus. Nunc id fringilla sem. Integer arcu quam, lacinia ut neque nec, dignissim lacinia tellus. Suspendisse id nisl felis. Vestibulum finibus neque quis turpis accumsan, porttitor interdum velit ullamcorper. In convallis tortor ligula, in pretium velit suscipit ac. Vivamus at sodales nisl. Aliquam erat volutpat.
  </p>
</div>-->
<div id="content" #content>
  <mat-card>
    <div class="alert alert-info">
        <strong>Html To PDF Conversion - Angular 6</strong>
    </div>
    <div>
      <input type="button" value="CPTURE" (click)="captureScreen()"/>
    </div>
  </mat-card>
</div>
<div >
  <mat-card>
    <table id="contentToConvert">
        <tr>
          <th>Column1</th>
          <th>Column2</th>
          <th>Column3</th>
        </tr>
        <tr>
          <td>Row 1</td>
          <td>Row 1</td>
          <td>Row 1</td>
        </tr>
        <tr>
          <td>Row 2</td>
          <td>Row 2</td>
          <td>Row 2</td>
        </tr>
        <tr>
          <td>Row 3</td>
          <td>Row 3</td>
          <td>Row 3</td>
        </tr>
        <tr>
          <td>Row 4</td>
          <td>Row 4</td>
          <td>Row 4</td>
        </tr>
        <tr>
          <td>Row 5</td>
          <td>Row 5</td>
          <td>Row 5</td>
        </tr>
        <tr>
          <td>Row 6</td>
          <td>Row 6</td>
          <td>Row 6</td>
        </tr>
      </table>

  </mat-card>

<!--<button mat-raised-button color="primary" (click)="generatePdf()">Convert to PDF</button>-->
</div>
