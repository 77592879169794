import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { LocalRankingService } from "../../_services/local-ranking.service";

@Component({
  selector: 'app-local-rank-history',
  templateUrl: './local-rank-history.component.html',
  styleUrls: ['./local-rank-history.component.scss']
})
export class LocalRankHistoryComponent implements OnInit {

  @Output() close = new EventEmitter<any>();
  @Input() previousResultsDocs: any;
  @Input() accountDetails: any;
  accDetails: any;
  loading: boolean = true;
  allDocs: any;
  constructor(private localRankService: LocalRankingService) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('accountDetails' in changes) {
      this.loading = true;
      let acc = changes.accountDetails.currentValue;
      this.localRankService.getPreviousLocalRankDocs(acc['url']).then(resp => {
        this.allDocs = resp;
        // console.log(this.allDocs);
        this.loading = false;
      })
    }
  }

  showRanking(data) {
    this.close.emit(data);
  }

}
