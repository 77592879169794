import { switchMap } from "rxjs/operators";
import { Component, OnInit, ViewChild, Input } from "@angular/core";
//import { ModalDirective } from 'angular-bootstrap-md';
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
  MarketingPlanService,
  AlertService,
  ApiService,
  UserService,
} from "../../_services/index";
import {
  NgForm,
  NgModel,
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.scss"],
})
export class PlanComponent implements OnInit {
  targetingFlag: boolean = false;
  lifecycleFlag: boolean = false;
  basicInfo: any;
  competitorsInfo: any;
  marketsInfo: any;
  targetingInfo: any;
  lifecycleInfo: any;
  //hideProposal: boolean = true;
  planName: string;
  urlForm: FormGroup;

  urlData: any;
  competitorData: any;
  model: any = {
    id: 0,
    planName: "",
    url: "www.sunatomic.com",
    product: "solar panels",
  };

  //@ViewChild('demoRegister') public demoRegister: ModalDirective;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private marketingPlanService: MarketingPlanService,
    private alertService: AlertService,
    private apiService: ApiService,
    private userService: UserService,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  createForm() {
    this.urlForm = this.fb.group({
      url: ["www.sunatomic.com", Validators.required],
      product: ["solar panels", Validators.required],
      category: ["", Validators.required],
    });
  }

  savedSpecifics(flag: boolean) {
    //this.summary.saveSpecifics();
    let user = this.userService.getUser();
    if (user.isAnonymous === true) {
      //this.demoRegister.show();
    }
  }

  savePlanName() {
    this.marketingPlanService.saveName(this.model.planName).then(
      (data) => {
        //console.log(data);
        localStorage.setItem("plan", data.text());
      },
      (error) => {
        this.alertService.error("Failed to save plan name ");
      }
    );
    //console.log(plan);
  }

  readURLForm() {
    if (this.urlForm.dirty) {
      this.urlData = this.urlForm.value;
      //this.fetchCompetitors();
      this.ngOnChanges();
    }
  }

  back() {
    this.router.navigate(["/all-plans"]);
  }

  ngOnChanges() {
    this.urlForm.reset({
      url: this.urlData.url,
      product: this.urlData.product,
      category: this.urlData.category,
    });
  }

  confirmBasicValid(basicInfo: any) {
    this.basicInfo = basicInfo;
    /*
     * Since we aren't fetching any competitors right now, setting them to ""
     * Saving all the basic business info
     */
    let input = {
      basic: this.basicInfo,
      competitors: { competitor1: "", competitor2: "", competitor3: "" },
    };
    this.marketingPlanService.saveBusinessInfo(input).subscribe(
      (data) => {
        console.log("Insert successful - Business!");
      },
      (error) => {
        this.alertService.error("Failed to save business info");
      }
    );
  }

  confirmCompetitorsValid(competitorsInfo: any) {
    this.competitorsInfo = competitorsInfo;
    /*
    let input = {"basic": this.basicInfo, "competitors": this.competitorsInfo};
    this.marketingPlanService.saveBusinessInfo(input)
        .subscribe(
          data => { console.log("Insert successful - Business!"); },
          error => { this.alertService.error('Failed to save business info'); }
        );
    */
  }

  confirmMarketsValid(markets: any) {
    //this.marketsInfo = markets;
  }

  confirmTargetingValid(targeting: any) {
    if (targeting) {
      if (targeting != "Fetched") {
        this.targetingInfo = targeting;
        this.marketsInfo = {
          country1: this.targetingInfo.country,
          country2: "",
        };
        console.log(this.marketsInfo);
      }
      this.targetingFlag = true;
    }
  }

  confirmLifecycleValid(lifecycle: any) {
    if (lifecycle) {
      if (lifecycle != "Fetched") {
        this.lifecycleInfo = lifecycle;
        let input = {
          markets: this.marketsInfo,
          targeting: this.targetingInfo,
          lifecycle: this.lifecycleInfo,
        };
        /*
         * Should send all inputs required to fetch tactics (estimates)
         */
        let user = JSON.parse(localStorage.getItem("user"));
        let uuid = user.uuid;
        //console.log(this.targetingInfo.gender);
        let gender =
          this.targetingInfo.gender.male === 1
            ? this.targetingInfo.gender.female === 1
              ? [1, 2]
              : [1]
            : [2];
        let planID = this.marketingPlanService.getPlanId();
        this.apiService.setTacticsInput({
          plan_id: planID,
          seed: this.basicInfo.product,
          targeting_specs: {
            uuid: uuid,
            objective: this.lifecycleInfo.objective,
            targeting_info: {
              country: this.marketsInfo.country1,
              age: {
                min: this.targetingInfo.age.min,
                max: this.targetingInfo.age.max,
              },
              gender: gender,
            },
          },
        });
        this.marketingPlanService.saveTargetingInfo(input).subscribe(
          (data) => {
            console.log("Insert successful - Targeting!");
          },
          (error) => {
            this.alertService.error("Failed to save business info");
          }
        );
      }
      this.lifecycleFlag = true;
    }
  }

  hideProposal() {
    if (this.targetingFlag && this.lifecycleFlag) {
      return false;
    } else {
      return true;
    }
  }

  setPlan(id: number, name: string) {
    this.model.id = id;
    this.model.planName = name;
    localStorage.setItem(
      "plan",
      '{"id": ' + this.model.id + ', "planName": "' + this.model.planName + '"}'
    );
  }

  ngOnInit() {
    //this.checkForSavedAccount();
    this.route.params
      .pipe(
        switchMap((params: Params) =>
          this.marketingPlanService.getPlanDetails(
            +params["id"],
            params["name"]
          )
        )
      )
      .subscribe((details) => {
        this.setPlan(details.plan_id, details.plan_name);
        //this.model.id = details.plan_id;
        //this.model.planName = details.plan_name;
        this.marketingPlanService.setBasicInfo(details.business);
        this.marketingPlanService.setCompetitors(details.competitors);
        // Add code to set markets here.
        this.marketingPlanService.setMarkets(details.markets);
        this.marketingPlanService.setTargeting(details.targeting);
        this.marketingPlanService.setLifecycle(details.lifecycle);
        this.marketingPlanService.setSpecifics(details.specifics);

        /*
         * Should send all inputs required, if present, to fetch tactics (estimates)
         */
        if (details.business.product !== null) {
          let user = JSON.parse(localStorage.getItem("user"));
          let uuid = user.uuid;
          let gender =
            details.targeting.gender.male === "1"
              ? details.targeting.gender.female === "1"
                ? [1, 2]
                : [1]
              : [2];
          console.log(details.markets.country1);
          this.apiService.setTacticsInput({
            plan_id: details.plan_id,
            seed: details.business.product,
            targeting_specs: {
              uuid: uuid,
              objective: details.lifecycle.objective,
              targeting_info: {
                country: details.markets.country1,
                age: {
                  min: details.targeting.age.min,
                  max: details.targeting.age.max,
                },
                gender: gender,
              },
            },
          });
        }
      });
    /*
    this.marketingPlanService.setBasicInfo({"url": "nhansmedia.com", "product": "marketing platform", "category": "Apparel"});
    this.marketingPlanService.setCompetitors({"competitor1": "amazon.com", "competitor2": "snapdeal.com", "competitor3": "flipkart.com"});
    this.marketingPlanService.setMarkets({"country1": "Japan", "country2": ""});
    this.marketingPlanService.setLifecycle({"lifecycle": "Matured", "objective": "LINK_CLICKS"});
    this.marketingPlanService.setTargeting({"gender": {"male": true, "female": false}, "age": {"min": 25, "max": 55}, "businessModel": {"b2b": false, "b2c": true}, "interaction": {"online": true, "offline": false}});
    */
  }
}
