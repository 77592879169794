import { Component, OnInit, Inject } from "@angular/core";
import { NavigationState } from "../_models/index";
import { AccountService, FormService, NavigationService } from "../_services/index";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ViewChild } from '@angular/core';
import {
  MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { FormBuilderService } from '../_services/form-builder.service';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute, NavigationStart, Router, UrlSegment } from "@angular/router";
import { filter } from 'rxjs/operators';

export interface EmbedDialogData {
  formUrl: string;
}

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  forms: any = [];
  allForms: any = [];
  isShow: boolean;
  state: any;
  selectedIndex: number = 0;
  url: UrlSegment[];

  constructor(
    private formService: FormService,
    private accountService: AccountService,
    private navigationService: NavigationService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilderService: FormBuilderService,
    public dialog: MatDialog,
    private dialogService: DialogService,
  ) {
    this.accountService.accChange$.subscribe((accChange) => {
      this.router.navigateByUrl("/websites");
    });
  }

  ngOnInit() {
    // this.formService.getFormDummyData().then(
    //   res => {
    //     console.log(res.data());

    //     let dummy = JSON.stringify(res.data());
    //     console.log(dummy);
    //   }
    // )

    this.route.url.subscribe((url) => {
      console.log(url);
      this.url = url;
      console.log(this.url[0]);
      console.log(this.url[1]);
      if (this.url[1]) {
        this.selectedIndex = 2;
      }
    });
    this.navigationService.updateState(
      new NavigationState(false, "Enquiry Forms", "", "", "/enquiry-forms")
    );
    this.state = this.navigationService.getCurrentNavigationState();
    console.log(this.state);

    this.forms = [];
    this.formService.getForms().then((querySnapshot) => {
      if (querySnapshot.empty === false) {
        this.isShow = true;
        querySnapshot.forEach((doc) => {
          let form = {
            id: "",
            name: "",
            responses: 0,
            total_initiated: [],
            total_submitted: [],
          };
          form.id = doc.id;
          form.name = doc.data().name;
          form['data'] = doc.data();
          this.formService
            .getFormResponses(form.id)
            .subscribe((total_responses) => {

              form.responses = total_responses.length;
              form.total_initiated = total_responses.filter(
                (x) => x["status"] == "initiated"
              );
              form.total_submitted = total_responses.filter(
                (x) => x["status"] == "submitted"
              );
              this.forms.push(form);
            });
        });
      } else {
        this.isShow = false;
      }
      this.allForms = this.forms;
    });
  }

  createForm() {
    console.log(this.state.url);
    this.formBuilderService.createForm(this.state.url);
  }

  searchForm(event) {
    let s = event.target.value;
    this.forms = this.allForms.filter((form) => {
      return form.name.toLowerCase().indexOf(s.toLowerCase()) != -1;
    });
  }
  copyForm() {
    // const ref = this.dialogService.open(SurveyFormComponent, {
    //   header: 'Copy Enquiry Form',
    //   width: "1100px",
    //   contentStyle: { "height": "800px" },
    //   showHeader: true,
    //   closable: true
    // })

    // ref.onClose.subscribe((closed) => {

    // })

    // ref.onDestroy.subscribe((destroy) => {

    // })
  }
}

// -------Embed enquiry form Dialog----

@Component({
  selector: 'embed-enquiry-form-dialog',
  templateUrl: 'embed-enquiry-form-dialog.html',
  styleUrls: ["./form.component.scss"],

})
export class EmbedEnquiryFormDialog {

  formUrl: any;
  width: number = 600;
  height: number = 400;
  source: any;
  category: any;
  selectedRadio: any = 'code';
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';


  @ViewChild('myname') iframeUrl;

  constructor(
    private _snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<EmbedEnquiryFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data: EmbedDialogData) {
    this.formUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.formUrl);
    console.log(this.formUrl);
  }

  radioChange(e) {
    console.log(this.selectedRadio);
  }

  copyToClipboard() {
    let input = document.createElement("input");
    let url = "";
    if (this.source && !this.category) {
      url = this.data.formUrl + '?source=' + this.source;
    }
    else if (!this.source && this.category) {
      url = this.data.formUrl + '?category=' + this.category;
    }
    else if (this.source && this.category) {
      url = this.data.formUrl + '?source=' + this.source + '&category=' + this.category;
    }
    else {
      url = this.data.formUrl;
    }


    if (this.selectedRadio == 'code') {
      input.value = `<iframe width="${this.width}" height="${this.height}" style="border:0;" frameborder="0" src="${url}" allowfullscreen></iframe>`;
    }
    else if (this.selectedRadio == 'url') {
      input.value = url;
    }

    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);

    this._snackBar.open('Copied', 'ok', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }


  close() {
    this.dialogRef.close();
  }
}
