<div class="scrolling-wrapper-flexbox" style="justify-content:center;">
  <mat-card class="metric-card">
    <mat-card-header>
      <mat-card-title class="metric-caption">Total Impressions</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="content">
        <div class="row">
          <div class="col-7">
            <p>{{totalAnalysis?.lastWeekImpressions}}</p>
            <span>Last 7 days</span>
            <p>{{totalAnalysis?.prevWeekImpressions}}</p>
            <span>Previous 7 days</span>
          </div>
          <div class="col-5">
            <p> {{totalAnalysis?.deltaImpressionDiff}} </p>
            <p>{{totalAnalysis?.deltaImpressionPercentage |number:'1.2-2'}}%</p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="metric-card">
    <mat-card-header>
      <mat-card-title class="metric-caption">Total Clicks</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="content">
        <div class="row">
          <div class="col-7">
            <p>{{totalAnalysis?.lastWeekClicks}} </p>
            <span>Last 7 days</span>
            <p>{{totalAnalysis?.prevWeekClicks}}</p>
            <span>Previous 7 days</span>
          </div>
          <div class="col-5">
            <p>{{totalAnalysis?.deltaClicksDiff}}</p>
            <p>{{totalAnalysis?.deltaClicksPercentage |number:'1.2-2'}}%</p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="metric-card">
    <mat-card-header>
      <mat-card-title class="metric-caption">Avaerage Postion</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div class="content">
        <div class="row">
          <div class="col-7">
            <p>{{totalAnalysis?.lastWeekPosition |number:'1.2-2'}}</p>
            <span>Last 7 days</span>
            <p>{{totalAnalysis?.prevWeekPosition|number:'1.2-2'}}</p>
            <span>Previous 7 days</span>
          </div>
          <div class="col-5">
            <p>{{totalAnalysis?.deltaPositionDiff|number:'1.2-2'}}</p>
            <p>{{totalAnalysis?.deltaPositionPercentage |number:'1.2-2'}}%</p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="metric-card">
    <mat-card-header>
      <mat-card-title class="metric-caption">Average CTR</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="content">
        <div class="row">
          <div class="col-7">
            <p>{{totalAnalysis?.lastWeekCtr|number:'1.2-2'}}</p>
            <span>Last 7 days</span>
            <p>{{totalAnalysis?.prevWeekCtr|number:'1.2-2'}}</p>
            <span>Previous 7 days</span>
          </div>
          <div class="col-5">
            <p>{{totalAnalysis?.deltaCtrDiff|number:'1.2-2'}}</p>
            <p>{{totalAnalysis?.deltaCtrPercentage|number:'1.2-2'}}%</p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

</div>