import { Component, OnInit,OnDestroy ,Inject} from '@angular/core';
import {ContactListService} from '../../_services/contact-list.service';
import { TimePeriodService } from '../../_services/index';
import { Daterangepicker } from 'ng2-daterangepicker';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as moment from 'moment';
import { AccountService } from '../../_services/account.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { environment } from '../../../environments/environment';
import {NotificationService } from '../../_services/notification.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
// import {SetTargetKWTaskService} from '../_services/set-target-kwtask.service';

export interface DialogData
{
  animal: string;
  name: string;
}


@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent implements OnDestroy,OnInit {

  notifiedValue=[];


  public dateInput: any;
  public singleDate: any;
contactIconStatus:boolean=true;
listName:string;
showListData:string;
created_on:any;
ListCollection:any=[];
ListNameCollection:any=[];
showListEnable:boolean=false;
deleteListEnable:boolean=false;
showContactButtonStatus=false;
Enable:boolean=true;
listContactCreatedOn=[];
ref:any;
listreference:any=[];
contactref:any=[];

listref:any=[];
newlistref:any=[];
//listContact:any=[];
color:any='primary';
ContactInfo=[1,2,3];
assignColor:string;
primary:string='primary';
displayedColumns: string[] = ['position'];
mode:string;
value:number=0;
contactCount=0;

bufferValue:number;
get_curr_names:string;
AccountName:string;
db:any;
accent:string;
notification:number;
selectedName="Dental-Designer";
newContactNotifyArray=[];
colors=['#CD5C5C','#FF69B4','#FF7F50','#FFFF00','#EE82EE','#ADFF2F','#00FFFF','#B8860B','#000000'];
seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];
popupForm:boolean=false;
name:string;
animal:string;
notificationSubscription : Subscription;


  constructor(public ContactListService:ContactListService,
              private timePeriodService: TimePeriodService,
              private daterangepickerOptions: DaterangepickerConfig,
            private get_curr_account :AccountService ,
            // private setTask:SetTargetKWTaskService,
            private getNotification:NotificationService){

         this.notificationSubscription =  this.getNotification.notification$.subscribe( count => {
          this.notification = count;
          console.log(this.notification);
          this.getNotification.setNotificationCount('/notifications/dental-designer')
          this.notifiedValue=this.getNotification.fetchNotification();
          console.log(this.notifiedValue);
          // this.getValue(this.notifiedValue);
          // this.getNotification.setContactRef();
      });


    // this.daterangepickerOptions.settings = {
    //       locale: { format: 'YYYY-MM-DD' },

    //       alwaysShowCalendars:true,
    //       showCustomRangeLabel:false,
    //       buttonClasses:['btn btn-sm'],
    //       applyClass:'btn-indigo',
    //       cancelClass:'btn-pink',
    //       ranges: {
    //          'Today': [moment(), moment()],
    //          //'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    //          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    //          'Last 30 days': [moment().subtract(1, 'month'), moment()],
    //          //'This Month': [moment().startOf('month'), moment().endOf('month')],
    //          //'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    //          'Last 3 Months': [moment().subtract(3, 'month'), moment()],
    //          'Last 6 Months': [moment().subtract(6, 'month'), moment()],
    //          'Last 12 Months': [moment().subtract(12, 'month'), moment()],
    //       }
    //   };

    // this.singleDate = Date.now();

  }
  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
    this.notificationSubscription.unsubscribe();
  }


 // openDialog(): void {
 //    // this.popupForm=true;
 //    const dialogRef = this.dialog.open(AddToContactDemoComponent, {
 //                                                          width: '500px',
 //                                                          height:'500px',
 //                                                          data: {name: this.name, animal: this.animal}
 //                                                          });

 //    dialogRef.afterClosed().subscribe(result => {
 //                                    console.log('The dialog was closed');
 //                                      });
 //  }

 //  onNoClick()
 //  {
 //    this.contactIconStatus=true;
 //  }

  // getValue(array)
  // {
  //   for(var i=0;i<array.length;i++)
  //   {
  //     let docId=array[i].contactId;
  //     if(docId!=undefined)
  //     {
  //       let name=""
  //       firebase.firestore().collection('accounts').doc('SUSh9iiU8Ty8BNz1uFtp').collection('contacts').doc(docId)
  //       .get().then(
  //         accountsSnapshot=>{
  //           this.AccountName=accountsSnapshot.data().first_name+' '+accountsSnapshot.data().last_name;
  //           this.setNotificationName();
  //           this.contactCount++;
  //       });


  //     }
  //   }
  // }
  setNotificationName()
  {

    this.notifiedValue[this.contactCount]['name']=this.AccountName;
    console.log(this.notifiedValue);
  }



   //  var acc = this.get_curr_account.getCurrentAccDetails();

   //  // //this.getNotification.fetchNotification()
   //  //      .subscribe( notifications => {

   //  //       this.notifiedValue=notifications
   //  //       this.getValue(this.notifiedValue)

   //  //     } );

   //  this.getNotification.setNotificationCount('/notifications/dental-designer')



   //  let current_timeperiod = this.timePeriodService.getCurrentTimeperiod();

   //    if(current_timeperiod) {
   //      this.dateInput = current_timeperiod;
   //    }
   //    else {
   //      this.dateInput = this.timePeriodService.getDefaultTimeperiod();
   //      this.timePeriodService.updateTimePeriod(this.dateInput);
   //    }
   //    this.db=firebase.firestore().collection('accounts');
   //            this.db.doc('SUSh9iiU8Ty8BNz1uFtp').get().then(
   //              accountsSnapshot => {
   //              // console.log(accountsSnapshot.data().color);
   //              this.primary=accountsSnapshot.data().color;
   //              this.accent=accountsSnapshot.data().accent;
   //              this.setCSSValue();
   //              }
   //            );

  	// this.ContactListService.getContactreference()
   //                           .then(
   //                              accountsSnapshot => {
   //                                  accountsSnapshot.forEach(
   //                                    docSnapshot => {
   //                                      this.ref=docSnapshot.ref;
   //                                      this.contactref.push(this.ref);

   //                                     }
   //                                  );
   //                              });


   //  }
   selectedDate(value: any, dateInput: any) {
        dateInput.start = value.start;
        dateInput.end = value.end;
        this.timePeriodService.updateTimePeriod(this.dateInput);
  }


  // constructor(public ContactListService:ContactListService ,
  //           private get_curr_account :AccountService) {}

  ngOnInit() {

              // let helpers=['cost','surgery'];
              // let keywords=['rhinoplasty'];
              // let status='inprogress'
              // let taskSetOn=moment();
              // this.setTask.setValue(helpers,keywords,status,taskSetOn);
              this.db=firebase.firestore().collection('accounts');
              this.db.doc('SUSh9iiU8Ty8BNz1uFtp').get().then(
                accountsSnapshot => {
                console.log(accountsSnapshot.data().color);
                this.primary=accountsSnapshot.data().color;
                this.accent=accountsSnapshot.data().accent;
                this.setCSSValue();
                }
              );
  	          this.ContactListService.getContactreference()
                                             .then(
                                                accountsSnapshot => {
                                                    accountsSnapshot.forEach(
                                                      docSnapshot => {
                                                        this.ref=docSnapshot.ref;
                                                        this.contactref.push(this.ref);
                                                       }

                                                    );
                                                });

    }

  CheckColor()
  {
    if(this.selectedName=="Dental-Designer")
    {
         this.db.doc('SUSh9iiU8Ty8BNz1uFtp').get().then(
                accountsSnapshot => {
                // console.log(accountsSnapshot.data().color);
                this.primary=accountsSnapshot.data().color;
                this.accent=accountsSnapshot.data().accent;
                this.setCSSValue();
                }
              );
    }
    else
    {
         this.db.doc('fldUz4DKIMg4UNcnY9QZ').get().then(
                accountsSnapshot => {
                // console.log(accountsSnapshot.data().color);
                this.primary=accountsSnapshot.data().color;
                this.accent=accountsSnapshot.data().accent;
                this.setCSSValue();
                }
              );
    }
  }


  CreateList(listName)
  {
  	this.ContactListService.createList(listName,this.contactref);
  }

  ShowList()
  {
  	this.ListNameCollection=[];
  	this.ListNameCollection=this.ContactListService.ShowList();
  	this.showListEnable=true;

  }

  slider(value)
  {
    this.value=value;
    // console.log("Hello");
    // console.log(this.primary);
    if(this.value <=50)
    {
      this.primary='warn';
      // console.log("<=50");
    }
    else
    {
      this.primary='primary';
      // console.log("51>");
    }
  }



  getStyles()
  {
    if(this.assignColor=='blue')
    {
      let styles = {
      'background-color':'#14F3E4',
      'font-weight': 'bold',
      'font-size':'10px',
      };
      return styles;
    }
    if(this.assignColor=='red')
    {
      let styles = {
      'background-color':'red',
      'font-weight': 'bold',
      'font-size':'50px'
      };
       return styles;
    }

  }
  C1()
  {
    this.primary=this.colors[0];
    this.accent=this.colors[0];
    this.setCSSValue();
  }
  C2()
  {
    this.primary=this.colors[1];
    this.accent=this.colors[1];
    this.setCSSValue();
  }
  C3()
  {
    this.primary=this.colors[2];
    this.accent=this.colors[2];
    this.setCSSValue();
  }
  C4()
  {
    this.primary=this.colors[3];
    this.accent=this.colors[3];
    this.setCSSValue();
  }
  C5()
  {
    this.primary=this.colors[4];
    this.accent=this.colors[4];
    this.setCSSValue();
  }
   C6()
  {
    this.primary=this.colors[5];
    this.accent=this.colors[5];
    this.setCSSValue();
  }
   C7()
  {
    this.primary=this.colors[6];
    this.accent=this.colors[6];
    this.setCSSValue();
  }
   C8()
  {
    this.primary=this.colors[7];
    this.accent=this.colors[7];
    this.setCSSValue();
  }
   C9()
  {
    this.primary=this.colors[8];
    this.accent=this.colors[8];
    this.setCSSValue();
  }



  getRadioButtonStyle()
  {
           let mat={
        'background-color':'orange!important'
        }
    return mat;
  }

  AddToList(listName)
  {
  	//this.ContactListService.AddList(listName,this.contactref);
  }

  deleteContactFromList(listName)
  {
  	this.deleteListEnable=true;
  	this.ContactListService.deleteContactFromList(listName);
  }
  deleteFromList()
  {
  	var arr=[]
  	this.ListNameCollection=[];
  	this.ListNameCollection=arr;
  	this.ContactListService.deleteFromList();
  }

  ShowContactFromList(listName)
  {
  	this.showListData=listName;
  	this.listContactCreatedOn==[];
  	this.showContactButtonStatus=true;
  	this.listContactCreatedOn=this.ContactListService.ShowContactFromList(listName);
  	// console.log(listName);
  }
  RemoveDocument(id,listName)
  {
  		// console.log(id,listName);
   		var arr=[];
		arr=this.listContactCreatedOn;
		for( var i = 0; i < arr.length; i++)
		{
			   if ( i === id)
			   {
			     arr.splice(i, 1);
			     break;
			   }
		}
		this.listContactCreatedOn=[];
		this.listContactCreatedOn=arr;
		this.ContactListService.RemoveDocument(id,listName);
  }

  setCSSValue()
  {
    // console.log("primary"+this.primary);
    // console.log("accent"+this.accent);
   /*let para = document.querySelector('c');
   let compStyles = window.getComputedStyle(para);
   compStyles.setProperty('--c','red');
  let compStyles = window.getComputedStyle(para);
   para.compStyles.setProperty('--c', '#000');
   let para = document.querySelector('c');
   var bodyStyles = window.getComputedStyle(para);
   var color = bodyStyles.getPropertyValue('--c');
   console.log("**********");
   console.log(color);*/
  // para.setProperty('--c',this.assign);
   // var declaration = document.styleSheets[0];
    //console.log(declaration);
   // var isImportant = declaration.getPropertyPriority('--c') === 'important';
  //var bodyStyles = window.getComputedStyle(document.c);
  //var fooBar = bodyStyles.getPropertyValue('--c');
  // let para = document.querySelector('p');
  // let compStyles = window.getComputedStyle(para);
  // console.log(para);
  // console.log(compStyles);
  // compStyles.setProperty('background-color','red');
    var myDiv = document.getElementById("myDiv");
    myDiv.style.setProperty('--primary',this.primary,null);

    var my = document.getElementById("my");
    my.style.setProperty('--accent',this.accent,null);

  }


  add(listName)
  {

  	this.ContactListService.addtolist(listName,this.contactref)
  	//this.ContactListService.addtolist(this.listName,this.contactref);

  }


}
