import { Component, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { th } from 'date-fns/locale';
import { type } from 'os';
import { ChatBotService } from '../../_services/chat-bot.service';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-chatbot-settings',
  templateUrl: './chatbot-settings.component.html',
  styleUrls: ['./chatbot-settings.component.scss']
})
export class ChatbotSettingsComponent implements OnInit {
  @ViewChild('dataEditor') dataEditor: JsonEditorComponent;
  @ViewChild('generalEditor') generalEditor: JsonEditorComponent;
  @ViewChild('tabGroup') tabGroup: MatTabsModule;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  options = new JsonEditorOptions();
  generalJsonEditorOption = new JsonEditorOptions();
  colorSetting: any[] = [];
  generelSetting: any;
  allColors: any;
  modes: any;
  color1: string = '#2883e9';
  showList: boolean = true;
  updateFlag: boolean = false;
  index: number;
  chatBotData: any[] = [];
  data = {}
  docId: string;
  colorThemeObject = {
    'background': null,
    'botBackground': null,
    'botText': null,
    'menuColor': null,
    'menuHoverColor': null,
    'tabColor': null,
    'userBackground': null,
    'theme': null,
    'userText': null
  }
  docRef: any;
  chatBotSettingData: any;
  chatBotSettingRef: any;
  loaded: boolean = false;
  btnDisabled: boolean = false;
  themeLoaded: boolean = false;
  genralDataLoaded: boolean = false;
  isThemeDataAvailabel: boolean = false;
  isGeneralDataAvailabel: boolean = true;
  isError: boolean = false;
  newRecordDisabled: boolean = false;

  constructor(
    private chatbotService: ChatBotService,
    private _snackBar: MatSnackBar,
  ) {

    this.options.mode = 'code';
    this.options.statusBar = false;
    this.options.search = false;
    this.options.enableSort = false;
    this.options.enableTransform = false;
    this.options.escapeUnicode = false;
    this.options.onError = (error) => {
      console.log(error);
    };

    this.generalJsonEditorOption.mode = 'code';
    this.generalJsonEditorOption.statusBar = false;
    this.generalJsonEditorOption.search = false;
    this.generalJsonEditorOption.enableSort = false;
    this.generalJsonEditorOption.enableTransform = false;
    this.generalJsonEditorOption.escapeUnicode = false;
    this.generalJsonEditorOption.onError = (error) => {
      console.log(error);
    };

    // this.options.navigationBar = false;
    // this.options.indentation = 4;
    // this.options.modes = ['code', 'text', 'tree', 'view'];

    //this.options.onChange = () => console.log(this.editor.get());

    this.modes = [
      { name: 'BOT' },
      { name: 'HYBRID', },
      { name: 'NO-CHAT', },
    ];
    this.getThemeInfo()
  }

  getThemeInfo() {
    this.generelSetting = ['Header', 'Mode', 'Welcome Message', 'Welcome Button', 'Show Welcome Button', 'Show Menu'];
    this.chatbotService.getWelcomeMessage().then(snap => {
      if (!snap.empty) {
        this.docRef = snap.docs[0].ref;
        this.docId = snap.docs[0].id;
        console.log(this.docId);
        this.allColors = snap.docs[0].data().colors;
        if (this.allColors) {
          this.colorSetting = [
            { name: 'Theme', value: '--theme-color-preview', defaultColor: this.allColors.theme, key: 'theme' },
            { name: 'Background', value: '--background-color-preview', defaultColor: this.allColors.background, key: 'background' },
            { name: 'Button', value: '--tab-color-preview', defaultColor: this.allColors.tabColor, key: 'tabColor' },
            { name: 'Menu Color', value: '--menu-color-preview', defaultColor: this.allColors.menuColor, key: 'menuColor' },
            { name: 'Menu Hover Color', value: '--menuHover-color-preview', defaultColor: this.allColors.menuHoverColor, key: 'menuHoverColor' },
            { name: 'Bot Message', value: '--bot-text-color-preview', defaultColor: this.allColors.botText, key: 'botText' },
            { name: 'Bot Message Background', value: '--bot-color-preview', defaultColor: this.allColors.botBackground, key: 'botBackground' },
            { name: 'User Message', value: '--user-text-color-preview', defaultColor: this.allColors.userText, key: 'userText' },
            { name: 'User Message Background', value: '--user-color-preview', defaultColor: this.allColors.userBackground, key: 'userBackground' }
          ];
          this.colorSetting.forEach(element => {
            document.documentElement.style.setProperty(element.value, element.defaultColor);
          });
        }
        this.themeLoaded = true;
        this.isGeneralDataAvailabel = true;
        console.log(this.generalEditor);
      }
      else {
        this.isGeneralDataAvailabel = false;
        this.isThemeDataAvailabel = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChange) {
    console.log("changes:", changes);
  }

  async ngOnInit(): Promise<void> {
    this.chatBotData = await this.chatbotService.getChatBotData();
    this.getChatBotSettingData();
    console.log(this.tabGroup);
  }
  async getChatBotSettingData() {

    let chatBotSettingDataSnapshot = await this.chatbotService.getChatBotSettingData()
      .then(snapshot => {
        if (!snapshot.empty) {
          this.chatBotSettingData = snapshot.docs[0].data();
          this.chatBotSettingRef = snapshot.docs[0].ref;
          this.genralDataLoaded = true;
          this.isThemeDataAvailabel = true
          this.isGeneralDataAvailabel = true;
          console.log(this.generalEditor);
        }
        else {
          console.log("No data");
          this.tabGroup['selectedIndex'] = 1;
          this.isThemeDataAvailabel = false;
          this.isGeneralDataAvailabel = false;
        }
      });
  }
  public onChangeColor(color: string): void {
    console.log('Color changed:', color);
  }

  saveTheme(theme) {
    for (var i = 0; i < theme.length; i++) {
      this.colorThemeObject[theme[i]['key']] = theme[i]['defaultColor']
    }
    this.chatbotService.saveTheme(this.docRef, this.colorThemeObject).then(resp => {
      this.openSnackBar("Data updated successfully");
    },
      err => {
        this.openSnackBar("Something went wrong");
      });
  }

  openColorPicker(str, event) {
    console.log(event, str, this.colorSetting);
    this.colorSetting = this.colorSetting.slice();
  }
  showChips() {
    console.log("add chips.........")
  }
  async addNewObject() {
    let obj;
    try {
      obj = this.dataEditor.get()
      console.log(obj);
      this.chatbotService.addData(obj).then(async resp => {
        this.chatBotData = await this.chatbotService.getChatBotData();
        this.openSnackBar("New object added successfully");
      });
      console.log("new object adding........")
      this.showList = true;
    }
    catch (err) {
      this.isError = true;
      this.scrollToTop();
      setTimeout('$("#invalidJsonError").hide()', 4000);
    }

  }
  async updateObject() {
    let obj;
    try {
      obj = this.dataEditor.get();
      this.chatbotService.updateObject(obj).then(async resp => {
        this.openSnackBar("Data updated successfully");
        this.chatBotData = await this.chatbotService.getChatBotData();
      });
    }
    catch (err) {
      this.isError = true;
      this.scrollToTop();
      setTimeout('$("#invalidJsonError").hide()', 4000);
    }
    console.log("updating old object........");
    this.showList = true;
  }

  updateChatBotSettingObject() {
    let jsonObject;
    try {
      jsonObject = this.generalEditor.get();
      this.chatbotService.updateChatBotSettingObject(this.chatBotSettingRef, jsonObject).then(sucess => {
        this.openSnackBar("Data added successfully");
      },
        err => {
          this.openSnackBar("Something went wrong");
        });
    }
    catch (err) {
      this.isError = true;
      this.scrollToTop()
      setTimeout('$("#invalidJsonError").hide()', 4000);
    }

    this.chatbotService.updateChatBotSettingObject(this.chatBotSettingRef, jsonObject).then(resp => {
      this.openSnackBar("Data added successfully");
    },
      err => {
        // this.openSnackBar("Something went wrong");
      });
  }

  editObj(obj, index) {
    this.index = index;
    this.updateFlag = true;
    this.showList = false;
    this.data = obj;
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 4000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  async onTabChanged(event) {
    this.chatBotSettingData = null;
    this.themeLoaded = false;
    this.genralDataLoaded = false;
    if (event['tab']['textLabel'] == "Theme") {
      this.getThemeInfo()
    }
    else if (event['tab']['textLabel'] == "General") {
      this.getChatBotSettingData();
    }
    else if (event['tab']['textLabel'] == "Data") {
      this.showList = true;
      //  this.chatBotData = await this.chatbotService.getChatBotData();
    }
  }

  addNewRecords() {
    this.chatbotService.addNewChatData().then(resp => {
      this.openSnackBar("Data added successfully");
      this.getChatBotSettingData();
    },
      err => {
        this.openSnackBar("Something went wrong");
      });
  }

  getData(event) {
    console.log(event);
    this.isError = false;
    this.generalJsonEditorOption.onError = (error) => {
      console.log(error);
    };

  }
  scrollToTop(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


}

