import {
  Component,
  OnInit,
  Input
} from "@angular/core";
import { AppointmentService } from "../_services/appointment.service";
import * as moment from "moment";
import { AppointmentsComponent } from "../appointments/appointments.component"

@Component({
  selector: 'app-appointment-stats',
  templateUrl: './appointment-stats.component.html',
  styleUrls: ['./appointment-stats.component.scss']
})

export class AppointmentStatsComponent implements OnInit {
  loading: boolean = true;
  totalCount: number = 0;
  noShowsCount: number = 0;
  rescheduleCount: number = 0;
  cancelledCount: number = 0;
  completedCount: number = 0;
  bookedCount: number = 0;
  upcomingCount: number = 0;

  @Input() value: any;

  constructor(private appointmentService: AppointmentService) {
    this.appointmentService.appointments$.subscribe((appointments) => {
      this.loading = true;
      this.value = appointments;
      this.totalCount = 0;
      this.noShowsCount = 0;
      this.rescheduleCount = 0;
      this.cancelledCount = 0;
      this.completedCount = 0;
      this.bookedCount = 0;
      this.upcomingCount = 0;
      console.log(this.value.size);
      this.value.forEach(doc => {
        this.computeOverallCount(doc.status);
        if (doc.status === "booked")
          this.getUpcomingAppointmentCount(doc.appt_start);
      });
      this.loading = false;
      console.log(this.totalCount);
      console.log(this.bookedCount,this.completedCount,this.noShowsCount,this.cancelledCount,this.rescheduleCount,this.upcomingCount);
    });
  }

  ngOnInit() {
  }
  computeOverallCount(status: string) {
    if (status === "rescheduled") {
      this.rescheduleCount++;
    } else if (status === "booked") {
      this.bookedCount++;
    } else if (status === "completed") {
      this.completedCount++;
    } else if (status === "no-show") {
      this.noShowsCount++;
    } else if (status === "cancelled") {
      this.cancelledCount++;
    }
    this.totalCount++;
  }

  getUpcomingAppointmentCount(apptStart: any) {
    let currentTime = moment();
    if (apptStart > currentTime) {
      this.upcomingCount++;
    }
  }
  isLoading() {
    return this.loading;
  }
}
