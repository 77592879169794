import { Component, OnInit,OnDestroy } from '@angular/core';
import { NavigationService } from "../_services/navigation.service";
import { NavigationState } from "../_models/index";
import { DialogService } from 'primeng/dynamicdialog';
import { LocalRankingService } from "../_services/local-ranking.service";
import { AccountService } from "../_services/account.service";
import { MatSnackBar,MatSnackBarHorizontalPosition,MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockLike } from 'typescript';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-local-rankings',
  templateUrl: './local-rankings.component.html',
  // providers: [DialogService],
  styleUrls: ['./local-rankings.component.scss']
})
export class LocalRankingsComponent implements OnInit,OnDestroy {
  localPackResultDetails: any;
  localPackResults = [];
  rankAnalysis=[];
  accountDetails: any;
  targetKeywordsRanks: any = [];
  showSettings: boolean = false;
  loading: boolean = true;
  showHistory: boolean = false;
  enableGetLocalRank : boolean=true;
  horizontalPosition: MatSnackBarHorizontalPosition ='center';
  verticalPosition: MatSnackBarVerticalPosition='top';
  processingLocalRanks : boolean =false;
  subscriberInfo: Subscription;
  constructor(
    private navigationService: NavigationService,
    // private dialogService: DialogService,
    private localRankService: LocalRankingService,
    private accountService: AccountService,
    private _snackBar: MatSnackBar,
  ) {
   this.subscriberInfo = this.localRankService.processingLocalRank$.subscribe(status=>{
      this.processingLocalRanks = status;
      this.enableGetLocalRank = !status;
      // console.log(status);
    })
  }

  async ngOnInit() {
    this.navigationService.updateState(
      new NavigationState(false, "", "", "#", "/local-rank")
      );
      this.accountDetails = this.accountService.getCurrentAccDetails();
      if (this.accountDetails['url']) {
        this.localRankService.getLocalPackResults(this.accountDetails['url']).then(resp => {
          if (Object.keys(resp).length > 0) {
            this.localPackResultDetails = resp;
            this.localPackResults = resp['localResults'];
            this.rankAnalysis = resp['previousRankAnalysis'].sort(function(a,b){
              return b?.search_volume?.search_volume_total - a?.search_volume?.search_volume_total;
            });
            this.targetKeywordsRanks = resp['targetKeywordsRanks'].sort(function(a,b){
              return b?.search_volume?.search_volume_total - a?.search_volume?.search_volume_total;
            });
            // console.log(resp);
            // console.log(this.targetKeywordsRanks);
        }
        else {
          this.localPackResults = [];
          this.localPackResultDetails = {};
          this.targetKeywordsRanks = [];
        }
        this.loading = false;
      });
    }
    else {
      this.localPackResults = [];
      this.localPackResultDetails = {};
      this.targetKeywordsRanks = [];
      this.loading = false;
    }
  }

  // openDialogSettingTask() {
  //   const dialogRef = this.dialogService.open(SetLocalrankTaskComponent, {
  //     header: 'Add Local Rankings Task',
  //     width: "800px",
  //     height: "800px"
  //   });
  //   dialogRef.onClose.subscribe((closed) => {
  //     console.log("closed");
  //   });
  // }
  processLocalRanks() {
    this.enableGetLocalRank =false;
    this.localRankService.processLocalRanks().then(resp=>{
      // console.log(resp);
    this.enableGetLocalRank =true;
    if(resp['status']!=="error"){
        this.openSnackBar("Started processing local ranks");
      }
      else{
        this.openSnackBar("Error in processing local ranks");
      }
    }) ;
  }
  //snackbar
  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  closeSettings(event) {
    // console.log(event);
    this.showSettings = false;
    this.showHistory = false;
  }

  closeHistory(event) {
    // console.log(event);
    this.showSettings = false;
    this.showHistory = false;
    if (event) {
      if (event) {
        this.loading = true;
        this.localRankService.getRankingDataFromDoc(event,this.accountDetails['url']).then(resp => {
          if (Object.keys(resp).length > 0) {
            // console.log(resp);
            this.localPackResultDetails = resp;
            this.localPackResults = resp['localResults'];
            this.targetKeywordsRanks = resp['targetKeywordsRanks'].sort(function(a,b){
              return b?.search_volume?.search_volume_total - a?.search_volume?.search_volume_total;
            });;
            this.rankAnalysis = resp['previousRankAnalysis'].sort(function(a,b){
              return b?.search_volume?.search_volume_total - a?.search_volume?.search_volume_total;
            });

          }
          else {
            this.localPackResults = [];
            this.localPackResultDetails = {};
            this.targetKeywordsRanks = [];
            this.rankAnalysis =[];
          }
          this.loading = false;
        });
      }
      else {
        this.loading = false;
        this.localPackResults = [];
        this.localPackResultDetails = {};
        this.targetKeywordsRanks = [];
      }
    }
  }
  ngOnDestroy(){
    this.subscriberInfo.unsubscribe();
    this.localRankService.unsubscribeLocalRanksListner();
  }
}
