<div class="row text-center" id="subHeader1">
  <div class="col-md-3" style="margin: auto;">
    <div class="searchContainer" *ngIf="hideSettings">
      <i class="fa fa-search searchIcon"></i>
      <input class="searchBox" type="text" name="search" placeholder="Search" [(ngModel)]="searchTerm"
        (keyup)="getInput($event)">
    </div>
  </div>
  <div class="col-md-6" *ngIf="hideSettings" style="margin: auto;">
    <div id="pageTitle" class="center">Patient Intake Forms</div>
  </div>
  <div class="col-md-6" *ngIf="!hideSettings" style="margin: auto;">
    <div id="pageTitle" class="center">Patient Form Configuration</div>
  </div>
  <div class="col-md-3">
    <div *ngIf="hideSettings else settings">
      <button mat-mini-fab id="button" class="format" color="primary" (click)="hideSettings=false;"
        matTooltip="Patient form configuration">
        <span class="material-icons" style="cursor: pointer;" color="primary">settings</span>
      </button>
      <button mat-mini-fab id="button" class="format" (click)="openDialogForSendPatientForm()"
        matTooltip="Send patient form">
        <i class="pi pi-send" style="font-size: 1rem"></i>
      </button>
    </div>
    <ng-template #settings>
      <button mat-mini-fab id="button" class="format" color="primary" (click)="hideSettings=true;" matTooltip="Close">
        <span class="material-icons" style="cursor: pointer;" color="primary">close</span>
      </button>
    </ng-template>
  </div>
</div>

<div id="subHeader2" *ngIf="hideSettings">
  <div *ngIf="legacyFormResponseExist && accountSlug == 'dental-designer'" style="text-align: left;margin-left: 13px;">
    <button mat-raised-button color="primary" (click)="showLegacyForm()">{{label}}</button>
  </div>
  <div *ngIf="!showLegacyFormResponses">
    <app-patient-form-responses [searchTerm]="searchTerm"></app-patient-form-responses>
  </div>
  <div *ngIf="formReadyNew && showLegacyFormResponses">
    <div class="mat-elevation-z8" id="pTable" *ngIf="dataSource.length>0">
      <p-table [columns]="columns" [value]="dataSource" #dt [globalFilterFields]="searchFields" [dataKey]="datakey"
        selectionMode="multiple" [paginator]="true" [rows]="rows" [(first)]="first" [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} of {totalRecords}" [rowsPerPageOptions]="[10,20,50]"
        [resizableColumns]="true" [autoLayout]="true" sortMode="multiple"  [multiSortMeta]="[{field: 'submitted', order: -1}]">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width:3em;"></th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.value" [ngStyle]="{'display':col.display}">
              {{col.header| titlecase}}
              <p-sortIcon [field]="col.value"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-expanded="expanded">
          <tr>
            <td style="width:3em;">
              <a href="#" [pRowToggler]="rowData">
                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
              </a>
            </td>
            <td class="mid" *ngFor="let col of columns" [ngStyle]="{'display':col.display}">
              <span *ngIf="col.value=='submitted';else elseblock">
                {{rowData[col.value]|date: 'dd/MM/yyyy hh:mm a'}}
              </span>
              <ng-template #elseblock>
                {{rowData[col.value]|titlecase}}
              </ng-template>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns" let-index="rowIndex">
          <tr>
            <td [attr.colspan]="columns.length">
              <div class="full-width" style="font-size:16px;padding-bottom: 1%;">
                <p-button [ngStyle]="{'padding':'6px 6px 6px 0px'}"
                  [disabled]="rowData['status'] == 'accepted' || rowData['status']=='rejected' " label="Accept"
                  (onClick)="openDialog(rowData,'accept',index)"></p-button>
                <p-button [ngStyle]="{'padding':'6px'}" label="Reject"
                  [disabled]="rowData['status'] == 'accepted' || rowData['status']=='rejected' "
                  (onClick)="openDialog(rowData,'reject',index)"></p-button>
                <p-button [ngStyle]="{'padding':'6px'}" label="Download PDF" [disabled]="rowData['pdfUrl']==undefined"
                  (onClick)="downloadPdf(rowData.pdfUrl)"></p-button>
              </div>
              <div *ngIf="rowData.contact && rowData.contact_ref==undefined">
                <p>We found a contact
                  <a [routerLink]="['/contacts',rowData.contact.id]" routerLinkActive="router-link-active">
                    {{rowData.contact.first_name | titlecase}} {{ rowData.contact.last_name | titlecase}}.
                  </a> Would you like to
                  <a style="border:none;background: none;" (click)="linkContact(rowData,index)">
                    link</a>
                  the contact?
                </p>
              </div>
              <div *ngIf="rowData.contact && rowData.contact_ref!==undefined">
                <p>This form is linked to
                  <a [routerLink]="['/contacts',rowData.contact.id,'forms']">
                    {{rowData.contact.first_name| titlecase}} {{ rowData.contact.last_name|titlecase}}.
                  </a>
                </p>
              </div>
              <div class="full-width" style="font-size:16px;">
                <p-tabView>
                  <p-tabPanel [header]="section.name | titlecase"
                    [headerStyle]="{'width':'max-content','overflow':'hidden'}"
                    *ngFor="let section of rowData['response']; let i = index" [selected]="i == 0">
                    <div class="panelHeight">
                      <ng-container *ngFor="let question of section.questions">
                        <div class="p-grid">
                          <span style="font-size:16px;font-weight:550;">{{ question.question}} &nbsp;</span>
                          <span *ngIf="question.answer_type!=='label'"> : &nbsp;</span>
                          <span *ngIf="question.answer_type=='signature'">
                            <span> <img [src]=question.answer width="100px" height="100px"> </span>
                          </span>
                          <span *ngIf="question.answer_type=='checkbox'">
                            <ng-container *ngFor="let option of question.answer; index as i;">
                              <span *ngIf="option">
                                {{question.choices[i]}}
                              </span>
                            </ng-container>
                          </span>
                          <span *ngIf="question.answer_type !='signature' && question.answer_type !='checkbox' ">
                            <span>{{ question.answer}}</span>
                          </span>
                        </div>
                      </ng-container>
                    </div>
                  </p-tabPanel>
                </p-tabView>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div *ngIf="dataSource.length<=0">
      <div style="text-align: center;">
        No record found
      </div>
    </div>
  </div>
  <div *ngIf="!formReadyNew && showLegacyFormResponses">
    <div style="text-align: center;">
      <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
    </div>
  </div>
</div>
<div *ngIf="!hideSettings">
  <app-patient-form-configuration>
  </app-patient-form-configuration>
</div>