import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm, NgModel, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { CompetitorsComponent } from '../competitors/competitors.component';
import { MarketsComponent } from '../markets/markets.component';
import { MarketingPlanService } from '../../_services/index';

@Component({
  selector: 'app-business-basic-info',
  templateUrl: './business-basic-info.component.html',
  styleUrls: ['./business-basic-info.component.scss']
})
export class BusinessBasicInfoComponent implements OnInit {
  @Input()
  competitors:CompetitorsComponent;
  @Input() markets: MarketsComponent;
  @Output() confirmBasicValid = new EventEmitter<any>();
  urlForm: FormGroup;
  categories = [
    "Apparel",
    "Arts & Entertainment",
    "Autos and Vehicles",
    "Beauty & Personal Care",
    "Business & Industrial",
    "Computers & Consumer Electronics",
    "Dining & Nightlife",
    "Family & Community",
    "Finance",
    "Food & Groceries",
    "Health",
    "Hobbies, Games & Leisure",
    "Home & Garden",
    "Internet & Telecom",
    "Jobs & Education",
    "Law & Government",
    "Mobile Apps",
    "News, Books & Publications",
    "Occasions & Gifts",
    "Real Estate",
    "Retailers & General Merchandise",
    "Sports & Fitness",
    "Travel & Tourism"
  ];

arrayOfNumbers: number[] = [100, 200, 300, 400, 500];
  constructor (
  private fb: FormBuilder,
  private marketingPlanService: MarketingPlanService
  ) {
  this.createForm();
    marketingPlanService.basicInfo$.subscribe(
      info => {
        this.urlForm.get('url').setValue(info.url);
        this.urlForm.get('product').setValue(info.product);
        this.urlForm.get('category').setValue(info.category);
      }
    );
  }

  ngOnInit() {
  }

  createForm() {
    this.urlForm = this.fb.group({
      url: ['www.sunatomic.com', Validators.required],
      product: ['solar panels', Validators.required],
      category: ['', Validators.required]
    });

  }
  next() {
  this.competitors.show(this.urlForm.get('url').value);
  this.markets.show(this.urlForm.get('url').value);
  this.urlForm.markAsPristine();
  this.confirmBasicValid.emit(this.urlForm.value);
  }

  hide(event)
  {
  console.log(event);
  }



}
