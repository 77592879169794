<div class="row header" style="height: 13%">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <div style="text-align: center;width: 100%;">
            <b>{{popupHeader}}</b>
        </div>
    </div>
    <div class="col-md-2">
        <div style="width: 100%;text-align: right;padding-right: 5px;">
            <i *ngIf="!disableBtnFlag" class="pi pi-times" matDialogClose
                style="font-size: 1.5rem;cursor: pointer;"></i>
        </div>
    </div>
</div>
<div style="overflow-x: hidden;" *ngIf="type == 'delete' || type == 'DELETE_POST' ">
    <div class="row">
        <div class="col-md-12">
            <div id="card-container">
                <div style="margin: 10px;text-align: center;">
                    <img [src]="imageUrl" style="height: 250px;width: 250px;">
                    <div style="text-align: center;margin-top: 10px;">
                        {{templateName}}
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="type == 'delete' || type == 'DELETE_POST' ">
    <div class="col-md-2" *ngIf="type == 'delete' ">
        <button mat-raised-button [disabled]="disableBtnFlag" (click)="deleteTemplate(template)"
            style="margin-left:10px ;">Yes</button>
    </div>
    <div class="col-md-2" *ngIf="type == 'DELETE_POST' ">
        <button mat-raised-button [disabled]="disableBtnFlag" (click)="deletePost(imageUrl)"
            style="margin-left:10px;">Yes</button>
    </div>
    <div class="col-md-8">
        <div *ngIf="disableBtnFlag" style="padding-top: 0%;margin-top: 10px;">
            <div style="text-align: center;">
                <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
            </div>
        </div>
    </div>
    <div class="col-md-2">
        <button mat-raised-button [disabled]="disableBtnFlag" (click)="cancelDelete()">No</button>
    </div>

</div>
<div style="overflow-x: hidden;" *ngIf="type == 'update' ">
    <div style="padding-top: 3px;">
        <textarea pInputTextarea [(ngModel)]="template.template" placeholder="Update Code" resizable="false"
            class="form-control" style="width: 95%;height: 210px;margin: 0px 20px 0px 20px;"></textarea>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-md-6">
            <span style="padding-left: 20px;">
                Template Name:
            </span>
            <input type="text" pInputText placeholder="Template Name" (input)="onSearchChange(event)"
                style="margin: 10px 20px 5px 20px;" [(ngModel)]="template.name" />
            <mat-error *ngIf="nameError" style="margin-left: 3.3em;"> Duplicate Name
            </mat-error>
        </div>
        <div class="col-md-6">
            <span style="margin: 10px 0px 0px 0px;">
                Comment Limit:
            </span>
            <input type="number" pInputText placeholder="Comment Limit" style="margin: 10px 0px 0px 0px;"
                [(ngModel)]="template.commentLimit" />
        </div>
    </div>
    <div class="row" style="margin: 10px 20px 10px 20px;">
        <div class="col-md-2" style="text-align: center;padding-left: 0px;">
            <button mat-raised-button [disabled]="disableBtnFlag || !template.template || !template.name ||nameError"
                (click)="updateTemplate(template)">Update</button>
        </div>
        <div class="col-md-8">
            <div *ngIf="disableBtnFlag" style="padding-top: 0%;margin-top: 10px;">
                <div style="text-align: center;">
                    <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                </div>
            </div>
        </div>
        <div class="col-md-2" style="text-align: center;padding-right: 0px;">

            <button mat-raised-button [disabled]="disableBtnFlag" (click)="cancelDelete()">Cancel</button>
        </div>

    </div>
</div>