<div *ngIf="loading; else loaded">
  <div style="height: fit-content;width:fit-content;margin: auto;">
      <mat-spinner></mat-spinner>
  </div>
</div>
<ng-template #loaded>
  <div *ngIf="prevRankAnalysis?.length >0 ; else noResults">
     <p-table [columns]="cols" [value]="prevRankAnalysis" 
     [scrollable]="true" scrollHeight="230px">
      <ng-template pTemplate="header" let-columns>
        <tr>
            <th>Keyword</th>
            <th>SEO Info</th>
            <th *ngFor="let col of columns">
              {{col|date}}
            </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-columns>
        <tr>
          <td>{{data?.keyword}}</td>
          <td>
            <tr><td>SV </td> <td> : {{data?.search_volume?.search_volume_total}} </td></tr>
            <tr><td>CPC</td> <td> : {{data?.search_volume?.cpc_total | number:'1.0-2' }}</td></tr>
            <tr><td>Cmpt</td> <td>: {{data?.search_volume?.competition_total |number:'1.0-2' }}</td></tr>
          </td>
          <!-- <td [matTooltip]="getTooltipInfo(data)" matTooltipClass="allow-cr">{{data?.keyword}}</td> -->
          <td *ngFor="let prevRank of data['previousRanks']">
            <span style="font-size:small;" *ngIf="prevRank?.current==false;else elseBlock"> 
              Not available
            </span>
            <ng-template #elseBlock>
              <span [ngStyle]="{'font-size': (prevRank?.rank!== null ) ? 'medium' : 'small'}">
                {{prevRank?.rank!== null ? prevRank?.rank : 'Not ranking' }}
              </span>
            </ng-template>
          </td>
        </tr>
      </ng-template>
     </p-table>
</div>
<ng-template #noResults>
  Currently don't have rank analysis
</ng-template>
</ng-template>
