import { Component, OnInit, SimpleChange } from '@angular/core';
import { ChatBotService } from './../_services/chat-bot.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-chatbot-message',
  templateUrl: './chatbot-message.component.html',
  styleUrls: ['./chatbot-message.component.scss'],
  providers: [ChatBotService]
})
export class ChatbotMessageComponent implements OnInit {
  [x: string]: any;
  contactlist: any[];
  startAfter: any;
  hasMoreRecords: boolean;
  chatbotSubscription: any;
  isReady: boolean = false;
  searchText: string = "";
  selectedDocid: any;
  userDetails: any;
  rejectedRequests = []
  constructor(
    private chatbotService: ChatBotService,
    private route: ActivatedRoute,
  ) {
    chatbotService.getTheme();
    this.chatbotSubscription = chatbotService.chatbot$.subscribe(pagingInfo => {
      this.contactlist = [];
      if (pagingInfo.list && pagingInfo.list.length > 0) {
        pagingInfo.list.forEach(contact => {
          if (this.rejectedRequests.indexOf(contact.id) != -1) {
            console.log(contact.id, this.rejectedRequests);
            contact.status = 'REJECTED';
          }
          this.contactlist.push(contact);
          if (this.selectedDocid == contact.id) {
            this.getMessagesOfSelctedContact(contact);
          }
        });
        console.log(this.contactlist);

        this.startAfter = pagingInfo.startAfter;
        this.hasMoreRecords = true;
      } else {
        this.hasMoreRecords = false;
      }
    });

  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

  }
  ngOnInit(): void {
    // firestore.collection('accounts').where('account_slug', '==', urlParts[2]).get().then((querySnap) => {
    //   querySnap.docs[0].ref.collection('settings').where('type', '==', 'chat-bot').get().then((snap) => {
    //     let data = snap.docs[0].data();
    //     let theme = data.colors.theme ? data.colors.theme : '#1b2246';
    //     let botBackground = data.colors.botBackground ? data.colors.botBackground : '#e5e4e9';
    //     let userBackground = data.colors.userBackground ? data.colors.userBackground : '#7e84f2';
    //     let botText = data.colors.botText ? data.colors.botText : '#363636';
    //     let userText = data.colors.userText ? data.colors.userText : 'white';
    //     let bgImage = data.themeBackground ? data.themeBackground : '';
    //     let bgColor = data.colors.backgroundColor ? data.colors.backgroundColor : '';
    //     document.documentElement.style.setProperty('--theme-color', theme);
    //     document.documentElement.style.setProperty('--bot-color', botBackground);
    //     document.documentElement.style.setProperty('--user-color', userBackground);
    //     document.documentElement.style.setProperty('--user-text-color', userText);
    //     document.documentElement.style.setProperty('--bot-text-color', botText);
    //     document.documentElement.style.setProperty('--background-image', `url(${bgImage})`);
    //     document.documentElement.style.setProperty('--background-color', bgColor);
    // this.route.queryParams.subscribe(params => {
    //   this.getData(params['id'])
    // });

    this.route.params.subscribe(params => {
      if (params['id'])
        this.getData(params['id'])
    });
  }
  rejectedRequest(rejectedId) {
    console.log(rejectedId);
    this.rejectedRequests.push(rejectedId);
  }
  async getData(id) {
    console.log(id);
    let contactDetailsObject = (await this.chatbotService.getSelectedMessages(id)).data();
    contactDetailsObject.id = id;
    console.log(contactDetailsObject);
    this.getMessagesOfSelctedContact(contactDetailsObject);
  }

  async getMessagesOfSelctedContact(contactDetailsObject) {
    this.selectedDocid = contactDetailsObject.id;
    this.userDetails = contactDetailsObject;
    this.isReady = true;
    console.log(contactDetailsObject);
    if (contactDetailsObject.lastMessages.unread) {
      this.chatbotService.updateReadMessage(contactDetailsObject.id);
    }
  }

  fetchNextContacts() {
    // console.log("next contacts fetched");
    this.chatbotService.getLastMessages('chat-bot', this.startAfter).then(snapshot => {
      // console.log(snapshot);
      if (snapshot.list && snapshot.list.length > 0) {
        snapshot.list.forEach(contact => {
          this.contactlist.push(contact);
          // this.chatListReady = true;
        });
        this.startAfter = snapshot.startAfter;
      }
      else {
        this.hasMoreRecords = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.chatbotSubscription)
      this.chatbotSubscription.unsubscribe();
  }
  clear() {
    this.searchText = "";
  }

}
