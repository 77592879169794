import { NgModule, Component, OnInit } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {FormControl} from '@angular/forms';

// @NgModule({ exports: [MatSlideToggleModule]})

@Component({
  selector: 'app-example',
  templateUrl: './example.component.html',
  styleUrls: ['./example.component.scss']
})


export class ExampleComponent implements OnInit {
  myControl: FormControl = new FormControl();

  constructor() { }

  ngOnInit() {
  }


}
