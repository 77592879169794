import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class FetchSeedKWService {

  constructor() { }

  setSeedKWInDB(){
  	let database = firebase.firestore().collection('suggestions').add({'type':'seed keyword','keywords':['laser treatment','slimming']});
  }



async updateNewSeedKW(newKw,seedKeywords){
  if(newKw){
    let mergedArray = Array.from(new Set(seedKeywords.concat(newKw)));

    let suggetionRef = firebase.firestore().collection('suggestions').doc('iuANEe3xA3UkJYbk0663');
    let transaction = firebase.firestore().runTransaction(t => {
      return t.get(suggetionRef)
        .then(doc => {
           let setCity = suggetionRef.set({
           keywords:mergedArray,
           type:"seed keywords"
          });
          t.update(suggetionRef,{type:'seed keywords'});
        });
    }).then(result => {
      console.log('Transaction success!');
    }).catch(err => {
      console.log('Transaction failure:', err);
    });
 }
}

async updateNewHelperKW(newHelperKw,helperKeywords){
  if(newHelperKw){
    let mergedArray = Array.from(new Set(helperKeywords.concat(newHelperKw)));
    let helperRef = firebase.firestore().collection('suggestions').doc('gJCbCAAgvmGdd9v01637');
    let transaction = firebase.firestore().runTransaction(t => {
      return t.get(helperRef)
        .then(doc => {
           let setHelper = helperRef.set({
           keywords:mergedArray,
           type:"helper keywords"
          });
          t.update(helperRef,{type:'helper keywords'});
        });
    }).then(result => {
      console.log('Transaction success!');
    }).catch(err => {
      console.log('Transaction failure:', err);
    });
 }
}

async getPrepopulateArray(){
  let fetchedData = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).get()
   .then(
      accountDetails=> {
        let accountName = accountDetails.data().name;
        return accountName;
      });
   return fetchedData
}

async getPrepopulateArrays1(){
  let onMonthBefore = moment().subtract(5,'months').toDate();
   let fetchedData = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('targetKW')
                        .where("taskSetOn",">=",onMonthBefore)
                        .orderBy("taskSetOn","desc").limit(1).get()
   let result={};
   fetchedData.forEach(
    doc=>{
      let docData = doc.data();
      result["seedKeywords"] = docData['seedKeyword'];
      result["helperKeywords"] = docData['helpers'];
      result["taskSetOn"] = docData['taskSetOn'].toDate();
      if('countryCode' && 'location' in doc.data() ){
          result["countryCode"]= doc.data()['countryCode'];
          result["location"]  = doc.data()['location'];
      }
    });
      return result;
}

 async getSeedKWList(){
  	let seedKw = await firebase.firestore().collection('suggestions')
                  .where("type","==","seed keywords").get();
  	let seedKeywords = [];
  	seedKw.forEach(
  		doc => {
  			seedKeywords = doc.data()['keywords'];
  		});
  	return seedKeywords;
  }

  async getHelperKWList(){
    let helperSnap = await firebase.firestore().collection('suggestions')
                  .where("type","==","helper keywords").get();
    let helperKw =[];
    helperSnap.forEach(
      doc => {
      helperKw = doc.data()["keywords"]
      });
      return helperKw;
  }
}
