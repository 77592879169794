<!-- new-signup -->
<section>
  <div class="row" style="padding: 2px; margin: auto;" *ngIf="processing">
    <!-- <p-progressSpinner ></p-progressSpinner> -->
    Processing ....
  </div>
  <div [ngClass]="{'spinner_overlay':processing}" class="row">
    <div class="col-md-5">
      <div *ngIf="showVerification && !showPassword" class="container">
        <app-otp-verification [screenName]="'email'" [value]="email"
          (otpVerificationStatus)="checkOtpVerificationStatus($event)"></app-otp-verification>
      </div>
      <div class="container" *ngIf="!showVerification && showPassword">
        <div class="space">
          <h1>Enter Password</h1>
        </div>
        <div class="space">
          <form [formGroup]="passwordForm" (ngSubmit)="signUpWithEmail(passwordForm)">
            <div class="space">
              <!-- <mat-form-field> -->
              <h3 class="first">Password</h3>
              <div class="input-group">
                <input pPassword class="form-control" [type]="hidePwd? 'password':'text'" matInput
                  placeholder="New password" formControlName="password" required>
                <span class="input-group-addon addToInput">
                  <mat-icon matSuffix (click)="hidePwd=!hidePwd">{{hidePwd?'visibility': 'visibility_off'}} </mat-icon>
                </span>
              </div>
              <mat-error
                *ngIf="passwordForm.hasError('required', 'password') && (passwordForm.dirty||passwordForm.touched)">
                Please enter your new password
              </mat-error>
              <!-- </mat-form-field> -->
            </div>
            <div class="space">
              <!-- <mat-form-field> -->
              <h3 class="first">Confirm Password</h3>
              <div class="input-group">
                <input class="form-control" [type]="hideConfirmPwd? 'password':'text'" pInputText type="password"
                  matInput placeholder="Confirm password" formControlName="confirmPassword"
                  [errorStateMatcher]="matcher">
                <span class="input-group-addon addToInput">
                  <mat-icon matSuffix (click)="hideConfirmPwd=!hideConfirmPwd">
                    {{hideConfirmPwd? 'visibility': 'visibility_off'}} </mat-icon>
                </span>
              </div>
              <mat-error *ngIf="passwordForm.hasError('notSame')  &&( passwordForm.dirty|| passwordForm.dirty)">
                Passwords do not match
              </mat-error>
              <!-- </mat-form-field> -->
            </div>
            <div style="text-align: center;">
              <button mat-raised-button type="submit" [disabled]="!passwordForm.valid">Register</button>
            </div>
          </form>
        </div>
      </div>
      <div class="container" *ngIf="!showVerification && !showPassword">
        <div class="space" style="text-align: center; color: #7E84F2;">
          <a href="/"><img src="../../assets/images/mktg-doctor-logo-new.png" class="logo" /></a>
        </div>
        <div class="space">
          <h1>Sign Up</h1>
          <em>Get access to a demo account instantly. Sign-up using the form
            below.</em>
        </div>

        <div class="space">
          <form #formData="ngForm" (ngSubmit)="verifyEmail(formData)" name="myForm">
            <div class="space">
              <h3 class="first">First Name</h3>
              <input class="form-control" id="fname"
                onkeyup="this.value =  this.value.substring(0,1).toUpperCase()+this.value.substring(1).toLowerCase()"
                name="first_name" [(ngModel)]="first_name" placeholder="First Name" type="text" size="30" pInputText
                required />
            </div>
            <div class="space">
              <h3 class="first">Last Name</h3>
              <input class="form-control" id="lname"
                onkeyup="this.value =  this.value.substring(0,1).toUpperCase()+this.value.substring(1).toLowerCase()"
                name="last_name" [(ngModel)]="last_name" placeholder="Last Name" type="text" size="30" pInputText
                required />
            </div>
            <div class="space">
              <h3 class="first">Cell no.</h3>
              <p-inputMask [style]="{ width: '100%' }" mask="(999) 999-9999" [(ngModel)]="cell_no" name="cell_no"
                placeholder="(999) 999-9999"></p-inputMask>
            </div>
            <div class="space">
              <h3 class="first">Email</h3>

              <input id="email" type="email" class="form-control" name="email" [(ngModel)]="email" placeholder="Email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" size="30" pInputText required />
            </div>
            <button type="submit" class="btn btn-primary" [disabled]="!formData.valid">
              Next
            </button>
          </form>
        </div>
        <p>
          Already have an account? <a [routerLink]="['/']"><b>Login</b></a>
        </p>
      </div>
    </div>
    <div class="col-md-7 sign-up-hero">
    </div>
  </div>
</section>