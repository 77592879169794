<div class="view">
	<div *ngIf="dateOnSetTask!==undefined" style="float: right">
		<label>Last updated : {{dateOnSetTask | date:'MMM d, yyyy' }}</label>
	</div>
	<!-- <mat-label>Seed Keywords</mat-label> -->

	<div>
		<!-- <mat-label>Seed Keywords</mat-label> -->
		<mat-form-field class="example-chip-list">
			<!-- <mat-placeholder class="placeholder">Seed Keywords</mat-placeholder> -->

			<mat-chip-list #chipList1>
				<mat-chip *ngFor="let seedKeyword of seedKeywords;let indx=index;" [selectable]="selectable"
					[removable]="removable" [selected]="seedKeyword.state" (removed)="remove(seedKeyword,indx)"
					[value]="seedKeyword"
					(click)="seedKeyword.state=!seedKeyword.state;changeSelected('s', seedKeyword.viewValue)">
					{{seedKeyword.viewValue}}
					{{seedKeyword}}
					<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
				</mat-chip>

				<!-- <label>Seed Keywords</label> -->
				<input matInput placeholder="Seed keywords" id="seedKW" #seedKWInput [formControl]="seedKWCtrl"
					[matAutocomplete]="auto" [matChipInputFor]="chipList1"
					[matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
					(matChipInputTokenEnd)="add($event)">
			</mat-chip-list>

			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedSeedKW($event)">
				<mat-option *ngFor="let seedKeyword of filteredSeedKw | async" [value]="seedKeyword">
					{{seedKeyword}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>


	<div>
		<!-- <mat-label>Helper Keywords</mat-label> -->
		<mat-form-field class="example-chip-list">
			<!-- <mat-placeholder class="placeholder" appearance="legacy">Helper Keywords</mat-placeholder> -->

			<mat-chip-list #chipList>

				<mat-chip *ngFor="let helperKeyword of helperKeywords;let indx=index;" [selectable]="selectable"
					[removable]="removable" [selected]="helperKeyword.state"
					(removed)="removeHelper(helperKeyword,indx)" [value]="helperKeyword"
					(click)="helperKeyword.state=!helperKeyword.state;changeSelected('s', helperKeyword.viewValue)">
					{{helperKeyword.viewValue}}
					{{helperKeyword}}
					<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
				</mat-chip>

				<!-- <label>Helper Keywords</label> -->
				<input matInput placeholder="Helper keywords" id="helperKW" #helperKWInput [formControl]="helperKWCtrl"
					[matAutocomplete]="auto1" [matChipInputFor]="chipList"
					[matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
					(matChipInputTokenEnd)="addHelper($event)">


			</mat-chip-list>
			<!-- placeholder="Helper Keywords"  -->

			<mat-autocomplete #auto1="matAutocomplete" (optionSelected)="selectedhelperKW($event)">
				<mat-option *ngFor="let helperKeyword of filteredHelperKw | async" [value]="helperKeyword">
					{{helperKeyword}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>

	<!-- <div  [formGroup]="form" fxLayout="column">
		<div class="row">
				<div class="col-md-6" >
						<mat-form-field class="example-full-width">
							<input  placeholder="Country" matInput name="countryName" formControlName="countryName" />
							</mat-form-field>
				</div>

			<div class="col-md-6"	>
			<mat-form-field class="example-full-width">
				<input  matInput

							 placeholder = "Location"
							 matValidateAddress
							 matGoogleMapsAutocomplete
							 #matGoogleMapsAutocomplete="matGoogleMapsAutocomplete"
							 [country]="us"
							 (onAutocompleteSelected)="onAutocompleteSelected($event)"
							 (onLocationSelected)="onLocationSelected($event)"
							 name="location"
							 formControlName="location"
							 required>
				<mat-error *ngIf="matGoogleMapsAutocomplete.addressSearchControl.hasError('required')">
					{{config.requiredErrorText}}
				</mat-error>
				<mat-error *ngIf="matGoogleMapsAutocomplete.addressSearchControl.hasError('validateAddress')">
					{{config.invalidErrorText}}
				</mat-error>
			</mat-form-field>
			</div>
		</div>
</div> -->

	<div>
		<button mat-raised-button (click)="submitAndGetResults()"> Get Results </button>
	</div>



</div>