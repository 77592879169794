<div class="container-fluid view">
<section id="plans">
  <!--Panel-->
  <!--<div class="col-sm card card-block plan">
    <h4 class="card-title"></h4>
    <p class="card-text">Create a new plan.
    </p>
  -->
  <div class="row justify-content-left">
     <div class="col-sm card card-block plan">
       <p> Create a plan. </p>
       <div class="flex-row">
         <button class="m-3" mat-fab (click)="createPlan()"><i class="material-icons add">add</i></button>
       </div>
     </div>
  </div>
  <!--<div class="arrow">
      <i class="fa fa-arrow-up justify-content-end" aria-hidden="true"></i>
  </div>
  <h1>Click here for new campaign</h1>-->
<!--  </div>-->
  <!--/.Panel-->
  <div class="row justify-content-left">
  <div *ngFor="let plan of allplans">
    <div class="col-sm card card-block plan">
      <a [routerLink]="['/plan', plan.id, plan.name]"><h4 class="card-title">{{ plan.name }}</h4></a>
      <p class="card-text"> quick example text to build on the card title and make up the bulk of the card's content.
      </p>
      <div class="flex-row">
          <a class="card-link"><i class="fa fa-trash-o fa-1x delete_icon" aria-hidden="true"></i></a>
     </div>
    </div>
  </div>
  </div>
</section>
</div>
