<button mat-mini-fab class="format" matTooltip="Custom Filters" (click)="hideFilterSection()"><i class="fa fa-filter" style="padding:0px 20px 0px 10px; margin-top:5px;"></i></button>

<mat-form-field class="example-chip-list" style="width:90%;">
  <mat-chip-list #chipList>
  	<span *ngFor="let filter of filters">
     <mat-chip *ngIf="filter.selectedOperator!=undefined && filter.selectedValue!=undefined"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(filter)"
      (matChipInputTokenEnd)="addFilter()">
      {{filter.field.label }} {{filter.selectedOperator.label}} {{filter.selectedValue | date:'MMM d, yyyy'}}
       <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
     </mat-chip>
    </span>
   </mat-chip-list>
</mat-form-field>

 <div class="contact-import" *ngIf="customFilter">
    <div class="col-md-12 sub-section">
      <strong>Custom Filters</strong>
    </div>

    <div *ngFor="let filter of filters;let i=index" class="mat-form-field-select row">
       <div class="col-md-4 col-sm-12 filter_label">
          <mat-label >{{filter.field.label}}</mat-label>
       </div>

    <div class="col-md-4 col-6">
          <mat-form-field  class="mat-form-field_style">
            <!-- *ngIf="filter.datatype==='boolean'" [ngClass]="{width:'100%'}"
              [ngClass]="filter.datatype==='boolean'" style="width:100%" -->
            <mat-select   [(ngModel)]="filter.selectedOperator" matNativeControl required (selectionChange)="showOperatorLabel(filter)">
             <mat-option *ngFor="let operator of filter.operators" [value]="operator">{{operator.label}}
             </mat-option>

            </mat-select>
          </mat-form-field>
    </div>
     <div class="col-md-4 col-6"  >
      <span *ngIf="filter.datatype==='date'" >
        <mat-form-field  class="mat-datepicker_styling">
          <input matInput [matDatepicker]="picker2" [(ngModel)]="filter.selectedValue" name="dateValue"
           [max]="maxDate" placeholder="Choose a date">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </span>

    </div><br>
   </div>
    
    <div class="col-sm-12 sub-section customFilterApplyButton">
      <button mat-raised-button color="primary" (click)="next($event)">Apply</button>
    </div>
   
   
   </div>

