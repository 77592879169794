<div class="row header" style="height: 10%">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <div style="text-align: center;width: 100%;">
            <b>Social Media Post</b>
        </div>
    </div>
    <div class="col-md-2">
        <div style="width: 100%;text-align: right;">
            <i class="pi pi-times" matDialogClose style="font-size: 1.5rem;cursor: pointer;"></i>
        </div>
    </div>
</div>
<div style="width: 100%;height: 100%" *ngIf="!loadingForAll">
    <div style="height: 80%;width: 100%;margin:1% 0% 0% 0%;overflow: hidden;overflow:auto;" class="row leftSide">
        <div class="col-md-6">
            <div class="row" style="height: auto">
                <div class="col-2" *ngIf="reviewData">
                    <img src={{reviewData.reviewer.profilePhotoUrl}} style="height: 50px;width: 50px;">
                </div>
                <div class="col-10" style="padding-top: 0%;padding-left: 0%;">
                    <div *ngIf="reviewData.source == 'google' && !updateComment">
                        <img id="googleLogo" src="assets/images/googlelogo.png" alt="image">
                        {{reviewerName}}
                    </div>
                    <div *ngIf="reviewData.source == 'google' && updateComment" [formGroup]="socialMediaPostFormGroup">
                        <img id="googleLogo" src="assets/images/googlelogo.png" alt="image">
                        <input type="text" pInputText name="reviewer" class="form-control-1" formControlName="reviewerName" style="margin: 6px;" [(ngModel)]="reviewerName"/>
                        <mat-error *ngIf="socialMediaPostFormGroup.get('reviewerName').hasError('required')"> Name should not be blank</mat-error>
                    </div>
                    <br>
                    <div class="row" style="margin-top: -1em;">
                        <div style="padding-left: 15px;">
                            <p-rating [ngModel]="(reviewData['starRating'])" readonly="true" stars="5" [cancel]="false">
                            </p-rating>
        
                        </div>
                        <div style="padding-left: 10px;font-size:12px;margin-top: 3px">
                            {{(moment(reviewData['createTime'].toDate()).format('ll'))}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="width: 100%;height:auto;padding: 10px;margin: 0px;word-wrap: break-word;display: inline-block;" *ngIf="!updateComment ">
                {{comment}}
            </div>
            <div class="row" style="width: 100%;height:200px;padding: 10px;margin: 0px;overflow: hidden;" *ngIf="updateComment" [formGroup]="socialMediaPostFormGroup">
                <textarea pInputTextarea [(ngModel)]="comment" resizable="false" class="form-control" name="comment" formControlName="commentFormControl" style="width: 100%;height:95%" (input)="getCommentText()"></textarea>
                <mat-error *ngIf="socialMediaPostFormGroup.get('commentFormControl').hasError('required')">Comment should not be blank</mat-error>
                <div class="card" style="width: 100%;">
                    <p-progressBar [value]="commentCharLimit" [ngClass]="(commentCharLimit<=90)?'normal':'high'"></p-progressBar>
                </div>
            </div>
            <div class="row" style="width: 100%;padding: 10px;margin: 0px" *ngIf="updateComment">
                <mat-accordion style="width: 100%;">
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Additional Settings</mat-panel-title>  
                        </mat-expansion-panel-header>
                        <mat-action-row>
                            <div class="row" [formGroup]="socialMediaPostFormGroup">
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Height</mat-label>
                                        <input type="number"  matInput placeholder="Enter height" class="form-control" name="height" formControlName="height" [(ngModel)]="additionlSettings.height" required>
                                        <mat-error *ngIf="socialMediaPostFormGroup.get('height').hasError('required')">Height should not be blank</mat-error>
                                        <mat-error *ngIf="socialMediaPostFormGroup.get('height').hasError('pattern')">Enter digit only</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>width</mat-label>
                                        <input type="number"  matInput placeholder="Enter width" class="form-control" name="width" formControlName="width" [(ngModel)]="additionlSettings.width" required>
                                        <mat-error *ngIf="socialMediaPostFormGroup.get('width').hasError('required')">Width should not be blank</mat-error>
                                        <mat-error *ngIf="socialMediaPostFormGroup.get('width').hasError('pattern')">Enter digit only</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Zoom</mat-label>
                                        <input matInput placeholder="Enter zoom value" class="form-control" name="zoom" formControlName="zoom" type="number" [(ngModel)]="additionlSettings.zoom" required>
                                        <mat-error *ngIf="socialMediaPostFormGroup.get('zoom').hasError('required')">Zoom should not be blank</mat-error>
                                        <mat-error *ngIf="socialMediaPostFormGroup.get('zoom').hasError('pattern')">Enter digit only</mat-error>
                                    </mat-form-field>     
                                </div>
                            </div>
                        </mat-action-row>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div class="row" style="height: auto">
                <div class="col-md-6" *ngIf="!updateComment">
                    <div style="width: 100%">
                        <button mat-raised-button (click)="updateCommentForPost()" [disabled]="!isTemplateExists || showPreviewLoading">Edit</button>
                    </div>
                </div>

                <div class="col-md-6"  *ngIf="updateComment">
                    <div style="width: 100%">
                        <button mat-raised-button [disabled]="loading" (click)="generateUrl()"
                        [disabled]="socialMediaPostFormGroup.get('height').hasError('pattern') ||
                        socialMediaPostFormGroup.get('height').hasError('required') ||
                        socialMediaPostFormGroup.get('width').hasError('pattern') ||
                        socialMediaPostFormGroup.get('width').hasError('required') ||
                        socialMediaPostFormGroup.get('zoom').hasError('pattern') ||
                        socialMediaPostFormGroup.get('zoom').hasError('required') ||
                        socialMediaPostFormGroup.get('commentFormControl').hasError('required') ||
                        socialMediaPostFormGroup.get('reviewerName').hasError('required') ||
                        !selectedTemplate
                        " style="margin-left: 9px;"
                        >Generate</button>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="updateComment">
                    <div style="width: 100%;text-align:right;margin-left: -10px;">
                        <button mat-raised-button [disabled]="loading" (click)="reset()">Reset</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row" style="margin:0%;justify-content: center;height: 100%;" *ngIf="!loading">
                <div *ngIf="(!isErrorOccure) && !showPreviewOfTemplate" style="margin:0%;justify-content: center;height: 100%;">
                    <img src={{postUrl}} style="height:400px;width: 400px;" [hidden]="showPreviewLoading" *ngIf="isTemplateExists" (load)="imageLoadingStatus($event)">
                    <div *ngIf="!postUrl"style="width: 100%;height: 100%;">
                        <div *ngIf="!isTemplateExists && !selectmultipleTemplate" style="width: 100%;height: 100%;margin: 5%;">
                            <b>Template not exists</b>
                        </div>
                        <div style="text-align: center;margin-top: 0%;height: 100%;" *ngIf="isTemplateExists && !selectmultipleTemplate && !updateComment">
                            <div><b>Image is not generated</b></div><br>
                            <button mat-raised-button *ngIf="isTemplateExists && !selectmultipleTemplate" (click)="updateCommentForPost()"
                            >Click here to generate image</button>
                        </div>
                    </div>
                    <div *ngIf="showPreviewLoading" style="height: 100%;padding-top:100px;">
                        <i class="pi pi-spin pi-spinner" style="margin:0% 8% 0% 8%" id="spinIcon"></i>
                    </div>
                </div>
                <div *ngIf="(!isErrorOccure) && showPreviewOfTemplate" style="width: 100%;">
                    <div style="width: 100%;" *ngIf="selectmultipleTemplate && updateComment && (templateData.length>=2)">
                        <div style="text-align: left;"><b id="example-radio-group-label">Select template</b></div>
                        <div style="height: auto;margin-top: 2%;">
                            <p-dropdown [options]="templateData" [(ngModel)]="selectedTemplate" (onChange)="getSelectedTemplate($event)" optionLabel="name"></p-dropdown>
                        </div>
                    </div>
                    <div style="text-align: center;margin-top: 5%" *ngIf="selectmultipleTemplate && updateComment">
                        <b>Preview</b>
                    </div>
                    <div style="text-align: center;margin-top: 5%" *ngIf="selectmultipleTemplate && updateComment">
                        <img src={{previewTemplate}} style="height:300px;width: 300px;" [hidden]="showPreviewLoading" (load)="imageLoadingStatus($event)">
                        <div *ngIf="showPreviewLoading">
                                <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                        </div>
                    </div>
                </div>
                <div *ngIf="isErrorOccure" style="width: 100%;margin-top: 25%;height: 100%;text-align: center;">
                    <button mat-raised-button *ngIf="timeoutError" (click)="generateUrl()"
                    [disabled]="socialMediaPostFormGroup.get('height').hasError('pattern') ||
                    socialMediaPostFormGroup.get('height').hasError('required') ||
                    socialMediaPostFormGroup.get('width').hasError('pattern') ||
                    socialMediaPostFormGroup.get('width').hasError('required') ||
                    socialMediaPostFormGroup.get('zoom').hasError('pattern') ||
                    socialMediaPostFormGroup.get('zoom').hasError('required') ||
                    socialMediaPostFormGroup.get('commentFormControl').hasError('required') ||
                    socialMediaPostFormGroup.get('reviewerName').hasError('required') 
                    "
                    >Something went wrong try again</button>
                    <b *ngIf="errorFound">Something went wrong</b>
                </div>

                <div *ngIf="timeoutError" style="width: 100%;margin-top: 25%;height: 100%;text-align: center;">
                </div>
            </div>
            <div class="row" style="margin:0% 8% 0% 8%" *ngIf="loading">
                <div style="text-align: center;width: 100%;margin-top: 25%;">
                    <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="width: 100%;height: 10%" *ngIf="!updateComment">
        <div class="col-11"></div>
        <div class="col-1" *ngIf="!updateComment">
            <div style="width: 100%;text-align: right;margin-left: -38%;"><button mat-raised-button  (click)="downloadImage()" [disabled]="!postUrl || !isTemplateExists || showPreviewLoading">Download</button></div>
        </div>
    </div>
    <div class="row" style="width: 100%;height: 10%" *ngIf="updateComment">
        <div class="col-11"></div>
        <div class="col-1">
            <div style="width: 100%;text-align: right;"><button [disabled]="loading || !isTemplateExists" mat-raised-button (click)="back()">Back</button></div>
        </div> 
    </div>
</div>
<div style="width: 100%;height: 100%;text-align: center;justify-content: center;margin-top: 20%;" *ngIf="loadingForAll">
    <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
</div>
