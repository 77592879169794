import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import { RewardCampaignService } from '../_services/reward-campaign.service';
import { AlertService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { type } from 'os';

@Component({
  selector: 'app-reward-details',
  templateUrl: './reward-details.component.html',
  styleUrls: ['./reward-details.component.scss']
})
export class RewardDetailsComponent implements OnInit, OnChanges, OnDestroy {
  //  @Input() contact;
  contact: any;
  contactDetails: any;
  rewardData: any = [];
  rewardDocs: any = [];
  reward: any = [];
  rewardList: any = [];
  rewards: any = [];
  totalRewardsEarned: number = 0;
  checked: boolean;
  contactRewards: any;
  rewardPoints: any = [];
  redeemedPoints;
  redeemedPoint;
  redeemedArray: any = [];
  redeemedList: any = [];
  today = new Date();
  grantingReward: boolean = false;
  redeemingReward: boolean = false;
  disableRedeem: boolean;
  redeemedData: any;
  showMessage: boolean;
  message: string;
  sub: any;
  contactId: any;
  result: any;
  createdOn: any;
  selectedItem: any;
  constructor(
    private rewardCampaignService: RewardCampaignService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  async ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('contact' in changes) {
      this.contact = changes.contact.currentValue;
      console.log(this.contact);
      if (this.contact) {
        await this.getContactRewards(this.contact);
      }

    }
  }

  async ngOnInit(): Promise<void> {
    this.sub = this.route.queryParams.subscribe(params => {
      let id = params["contact"];
      this.contactId = id;

      if (id) {
        console.log(id);
        this.rewardCampaignService.getContactDetails(id).then(async doc => {
          this.contact = doc;
          this.contactDetails = doc.data();
          console.log(this.contact);
          await this.getContactRewards(this.contact);
        })
      }

    });

  }


  // Prevent memory leaks
  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  getContactRewards(contact: any) {
    if (this.contact) {
      this.rewardCampaignService.getContactRewards(contact).then(rewards => {
        let rewardData = rewards.docs.map(doc => doc.data());
        console.log(rewardData);
        this.rewardPoints = rewardData;
        console.log(this.rewardPoints);
        if (this.rewardPoints.length == 0) {
          this.showMessage = true;
          this.message = `Would you like to grant rewards to ${this.contactDetails.first_name} ${this.contactDetails.last_name}. Click on Grant reward.`
        }
        else {
          this.showMessage = false;
        }
        /*   this.redeemedData = rewardData[0].redeemedPoints;
           console.log(this.redeemedData);*/
        this.rewardPoints = this.rewardPoints.sort((a, b) => {
          console.log(a, b);
          return b.createdOn - a.createdOn;
        });
        console.log(this.rewardPoints);
        this.rewardPoints.forEach(element => {
          if (element.status == 'GRANTED') {
            this.totalRewardsEarned += Number(element.points);
            console.log(this.totalRewardsEarned);
          }
          if (element.status == 'REDEEMED') {
            this.totalRewardsEarned -= Number(element.points);
            console.log(this.totalRewardsEarned);
          }
        });
        this.result = this.rewardPoints.reduce((r, a) => {
          let date = moment(a.createdOn.toDate()).format("MMM DD, yyyy")
          r[date] = r[date] || [];
          r[date].push(a);
          return r;
        }, {});
        this.result = Object.entries(this.result).map((key) => {
          let obj: any = {};
          obj.date = moment(key[0]).toDate();
          obj.dateStr = key[0];
          obj.rewards = key[1]
          return obj;
        });
        this.result = this.result.sort((a, b) => {
          console.log(a, b);
          return b.createdOn - a.createdOn;
        });
        // this.result = this.result.reverse();
        console.log(this.result)
      });
    }



  }

  async getReward() {
    this.rewardCampaignService.getOnRewardCampaigns().then(rewardSnapshot => {
      console.log(rewardSnapshot.docs);
      this.rewardDocs = rewardSnapshot.docs.map(doc => doc.data());
      console.log(this.rewardDocs);
    });
  }

  selectedReward(reward: any) {
    this.selectedItem = reward;
    this.rewards = [reward];
    console.log(this.rewards);
  }

  grantReward() {
    console.log("Grant reward");
    let rewards = this.rewards[0];
    console.log(rewards);
    this.rewards[0]['createdOn'] = new Date();
    this.createdOn = moment(this.rewards[0]['createdOn']).format("MMM DD, yyyy");
    console.log(this.result);
    if (this.result.length == 0) {
      console.log("Date not matching. Adding reward to list");
      this.rewardList.push(this.rewards[0]);
      this.showMessage = false;
      for (let i = 0; i < this.rewards.length; i++) {
        this.totalRewardsEarned += Number(this.rewards[i].points);
        console.log(this.totalRewardsEarned);
      }
      console.log("Reward added", this.rewardList);
      this.rewardList = this.rewardList.sort((a, b) => {
        return b.createdOn - a.createdOn;
      })
      this.grantingReward = true;
      this.rewardCampaignService.sendLoyaltyProgramRewards(this.contactId);
      this.rewardCampaignService.addRewardTransaction(this.rewards, this.contact.ref);
    }
    else {
      console.log(this.result);
      for (let i = 0; i < this.result.length; i++) {
        if (this.createdOn !== this.result[i].dateStr) {
          console.log("Date not matching. Adding reward to list");
          this.rewardList.push(this.rewards[0]);
          this.showMessage = false;
          for (let i = 0; i < this.rewards.length; i++) {
            this.totalRewardsEarned += Number(this.rewards[i].points);
            console.log(this.totalRewardsEarned);
          }
          console.log("Reward added", this.rewardList);
          /*   this.result = this.result.rewards.sort((a, b) => {
               return b.createdOn - a.createdOn;
             })*/
          console.log(this.result);
          this.grantingReward = true;
          this.rewardCampaignService.sendLoyaltyProgramRewards(this.contactId);
          this.rewardCampaignService.addRewardTransaction(this.rewards, this.contact.ref);
        }
        else {
          console.log("Date matched. Adding reward to result");
          console.log(typeof (this.createdOn), typeof (this.result[i].dateStr));
          console.log(this.result);
          this.result[i].rewards.splice(0, 0, this.rewards[0]);
          console.log(this.result);
          this.grantingReward = false;
          for (let i = 0; i < this.rewards.length; i++) {
            this.totalRewardsEarned += Number(this.rewards[i].points);
            console.log(this.totalRewardsEarned);
          }
          this.rewardCampaignService.addRewardTransaction(this.rewards, this.contact.ref);
          this.rewardCampaignService.sendLoyaltyProgramRewards(this.contactId);

        }
      }
    }
  }

  redeemRewards(redeemedPoint: any) {
    console.log(this.rewardList);
    if (redeemedPoint > this.totalRewardsEarned) {
      console.log("You cannot redeem more than earned");
      this.totalRewardsEarned = this.totalRewardsEarned;
    }
    else {
      this.redeemedArray = [{ redeemedPoint }];
      console.log(this.redeemedArray);
      this.redeemedArray[0]['createdOn'] = new Date();
      console.log(this.redeemedArray[0]['createdOn']);
      this.createdOn = moment(this.redeemedArray[0]['createdOn']).format("MMM DD, yyyy");
      console.log(this.createdOn);
      if (this.result.length !== 0) {
        for (let i = 0; i < this.result.length; i++) {
          if (this.createdOn === this.result[i].dateStr) {
            console.log("Date matched. Adding redeemed points to to result");
            this.result[i].rewards.splice(0, 0, this.redeemedArray[0]);
            this.totalRewardsEarned -= redeemedPoint;
            this.redeemedPoints = redeemedPoint;
            this.redeemedPoint = 0;
            console.log(this.rewards, this.rewardPoints);
            this.rewardCampaignService.redeemRewardPoints(this.rewards, redeemedPoint);
            this.rewardCampaignService.sendLoyaltyProgramRewards(this.contactId);
          }
        }
      }
      else {
        this.createdOn = moment(this.redeemedArray[0]['createdOn']).format("MMM DD, yyyy");
        this.rewardList.push(this.redeemedArray[0]);
        this.rewardList = this.rewardList.sort((a, b) => {
          return b.createdOn - a.createdOn;
        })
        this.grantingReward = true;
        this.totalRewardsEarned -= redeemedPoint;
        this.redeemedPoints = redeemedPoint;
        this.redeemedPoint = 0;
        this.rewardCampaignService.redeemRewardPoints(this.rewardPoints, redeemedPoint);
        this.rewardCampaignService.sendLoyaltyProgramRewards(this.contactId);
      }

    }

  }

}
