import { Component, OnChanges, Input, SimpleChange } from '@angular/core';
import { MessageService } from './../_services/message.service';

@Component({
  selector: 'app-contact-message-log',
  templateUrl: './contact-message-log.component.html',
  styleUrls: ['./contact-message-log.component.scss']
})
export class ContactMessageLogComponent implements OnChanges {
  @Input() messages: any;
  // @Input() contactId: any;
  allMessages = [];
  docId: any;
  constructor(
    private messageService: MessageService,
  ) { }

  async ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('messages' in changes) {
      this.allMessages = changes.messages.currentValue;
      console.log(this.allMessages);
    }
    // if ('contactId' in changes) {
    //   this.docId = changes.contactId.currentValue;
    //   console.log(this.docId);
    //   this.allMessages = (await this.messageService.fetchSmsLogs(this.docId)).list;
    // }
  }

}
