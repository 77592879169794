<div *ngIf="loadingForConfiguration" style="margin-top: 20px;">
    <div style="text-align: center">
        <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
    </div>
</div>
<div style="background-color: white;margin: 10px 5px 0px;padding: 5px;">
    <form #form="ngForm" style="height: 100%;width: 100%;">
        <div class="row" style="margin: 5px;" *ngIf="!loadingForConfiguration">
            <div class="row" style="margin: 2px;">
                <div class="col-md-6">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="flexCheckChecked1" [value]="enableEmail"
                            [(ngModel)]="enableEmail" (change)="enableEmailSend($event)"
                            [ngModelOptions]="{standalone: true}" />Enable email
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="flexCheckChecked1" [value]="enableSms"
                            [(ngModel)]="enableSms" (change)="enableSmsSend($event)"
                            [ngModelOptions]="{standalone: true}" />Enable Sms
                    </div>
                </div>
            </div>
            <div class="col-md-6" id="EmailSection" #emailSection [class.disabled]="!enableEmail">
                <div class="row" style="margin: 2px;">
                    <span style="padding: 5px 0px 5px 0px;;font-size: 1rem;">Email Subject</span>
                    <textarea rows="6" cols="30" pInputTextarea placeholder="Enter email subject"
                        [(ngModel)]="emailSubject" name="emailSubject" required style="width: 100%;"></textarea>
                </div>
                <div class="row" style="margin: 2px;">
                    <span style="padding: 5px 0px 5px 0px;;font-size: 1rem;">Email Body</span>
                    <p-editor [(ngModel)]="emailBody" (onTextChange)="onTextChange($event)" name="emailBody"
                        placeholder="Enter email body" [style]="{'height':'180px'}">
                    </p-editor>
                </div>
                <!--<div class="row" style="margin: 2px;">
                    <span style="padding: 5px 0px 5px 0px;font-size: 1rem;">Enter Email Body.</span>
                    <textarea rows="12" cols="30" pInputTextarea [(ngModel)]="emailBody"
                        (onTextChange)="onTextChange($event)" name="emailBody"
                        placeholder="Enter email body"></textarea>
                </div>-->
            </div>
            <div class="col-md-6" id="SmsSection" #smsSection [class.disabled]="!enableSms">
                <div class="row" style="margin: 2px;">
                    <span style="padding: 5px 0px 5px 0px;font-size: 1rem;">Grant SMS</span>
                    <textarea rows="6" cols="30" pInputTextarea placeholder="Enter sms body" style="width: 100%;"
                        autoResize="autoResize" [(ngModel)]="smsBody" name="smsBody" class="inputText"
                        required></textarea>
                </div>
                <div class="row" style="margin: 2px;">
                    <span style="padding: 5px 0px 5px 0px;font-size: 1rem;">Redeem SMS</span>
                    <textarea rows="6" cols="30" pInputTextarea placeholder="Enter sms body" style="width: 100%;"
                        autoResize="autoResize" [(ngModel)]="smsBodyRedeemed" name="smsBodyRedeemed" class="inputText"
                        required></textarea>
                </div>
            </div>
        </div>
    </form>
    <mat-card-actions *ngIf="!confirmation">
        <div style="text-align: right;margin:9px 12px;" *ngIf="!loadingForConfiguration">
            <button mat-raised-button color="primary" (click)="Save()"
                [disabled]="form.invalid || inValid">Save</button>
        </div>
    </mat-card-actions>

    <mat-card-actions *ngIf="confirmation">
        <div style="text-align: center;" *ngIf="loading">
            <b>{{displayMsg}}</b>
        </div>
        <div style="text-align: center;" *ngIf="!loading">
            <b>{{displayMsg}}</b>
        </div>
    </mat-card-actions>
</div>