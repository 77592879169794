import { Component, HostListener, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { environment } from "../../environments/environment";
import { CreateContactMessageDialog } from '../contact-id/contact-id.component';
import { SendReviewInviteDialogComponent } from '../reviews-management/send-review-invite-dialog/send-review-invite-dialog.component';
import { AccountService } from '../_services';
import { ReviewsService } from '../_services/reviews.service';

export interface DialogData {
  apptDetails: any,
  title: string;
}
@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss']
})
export class AppointmentDetailComponent {
  page: number = 0;
  name: string;
  date: any;
  slot: string;
  clinic_name: string;
  city: string;
  status: string;
  service: string;
  new_patient: string;
  notes: string;
  appt: any;
  title: any;
  notesEdited: boolean = false;
  updateNotes: boolean = true;
  statusChanged: boolean = false;
  editedNote: string;
  result: any;
  link: any;
  isTwillioSet: boolean = false
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    this.receiveMessage(event);
  }
  constructor(
    public dialogRef: MatDialogRef<AppointmentDetailComponent>,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private reviewService: ReviewsService
  ) {
    this.name = data.apptDetails.name;
    this.date = data.apptDetails.appt_start;
    this.slot = data.apptDetails.slot;
    this.clinic_name = data.apptDetails.clinic_name;
    this.city = data.apptDetails.city;
    this.status = data.apptDetails.status;
    this.service = data.apptDetails.service;
    this.new_patient = data.apptDetails.new_patient;
    this.notes = data.apptDetails.notes;
    this.appt = data.apptDetails.appt;
  }
  close() {
    if (this.notesEdited) {
      this.notes = this.editedNote;
    }
    this.result = {
      //edited: true,
      appointment: this.appt,
      status: this.status,
      notes: this.notes,
      notesEdited: this.notesEdited,
      statusChanged: this.statusChanged
    };

    this.dialogRef.close(this.result);
  }

  async ngOnInit() {
    let accInfo = (await this.reviewService.getCurrentAccountDetails()).data();;
    if ("twilio_from" in accInfo) {
      this.isTwillioSet = true
    }
    // window.addEventListener("message", this.receiveMessage, false);
  }


  getNotes(event: any) {
    this.notesEdited = true;
    this.editedNote = event.target.textContent;
  }

  isApptPassed(date: any) {
    let now = moment(new Date());
    if (date < now)
      return true;
    else
      return false;
  }

  statusUpdate() {
    if (this.notesEdited)
      this.notes = this.editedNote;
    //console.log("ref", this.appt.ref);
    // firebase.firestore().doc(this.appt.ref.path).update({
    //   status: this.status,
    //   notes: this.notes
    // });
    this.result = {
      //edited: true,
      appointment: this.appt,
      status: this.status,
      notes: this.notes,
      notesEdited: this.notesEdited,
      statusChanged: this.statusChanged
    }
    this.dialogRef.close(this.result);
  }

  cancelStatus() {
    let cancel_link = `${environment.apptURL}/cancel/${this.appt.reschedule_doc}`;
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(cancel_link);
  }
  rescheduleStatus() {
    let reschedule_link = `${environment.apptURL}/reschedule/${this.appt.reschedule_doc}`;
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(reschedule_link);
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  receiveMessage = (event: any) => {
    console.log(event.data)
    const message = JSON.parse(event.data)
    console.log(message)
    if (message.eventType == 'CLOSE')
      this.close()
  }

  async openDialogForMessage() {
    let selectedContactsSnapShot = await this.data.apptDetails.appt.contact.get()
    let selectedContacts = selectedContactsSnapShot.data()
    selectedContacts.ref = selectedContactsSnapShot.ref
    const messagedialogRef = this.dialog.open(CreateContactMessageDialog, {
      height: "600px",
      width: "900px",
      //data: { contact:contact }
      data: { contacts: [selectedContacts] },
    });
  }

  async sendReviewInvite() {
    let selectedContactsSnapShot = await this.data.apptDetails.appt.contact.get()
    let selectedContacts = selectedContactsSnapShot.data()
    selectedContacts.objectID = selectedContactsSnapShot.id
    const dialogRef = this.dialog.open(SendReviewInviteDialogComponent, {
      width: "1500px",
      height: '550px',
      data: {
        fromScreen: 'appointment-detail',
        selectedContacts: [selectedContacts]
      },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}