import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { AccountService } from "./account.service";
import { TimePeriodService } from "./time-period.service";
import { Subject } from "rxjs";

@Injectable()
export class FacebookService {
  acc: any;
  conversion: any;
  timeperiod: any;

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private timePeriodService: TimePeriodService
  ) {}

  formatDate(timeperiod: any): any {
    let formattedDate = { since: "", until: "" };

    formattedDate.since = new DatePipe("en-US").transform(
      timeperiod.start,
      "yyyy-MM-dd"
    );
    formattedDate.until = new DatePipe("en-US").transform(
      timeperiod.end,
      "yyyy-MM-dd"
    );

    return formattedDate;
  }

  getAccountSpend(
    timeperiod: any,
    acc_id: string,
    access_token: string
  ): Promise<any> {
    let timeperiod_formatted = this.formatDate(timeperiod);

    const data = new HttpParams()
      .set(
        "time_range",
        JSON.stringify({
          since: timeperiod_formatted.since,
          until: timeperiod_formatted.until,
        })
      )
      .set("level", "account")
      .set("fields", "spend")
      .set("access_token", access_token);

    return this.http
      .get("https://graph.facebook.com/v7.0/" + acc_id + "/insights", {
        params: data,
      })
      .toPromise();
  }

  getFacebookInsights(
    access_token: string,
    account_id: string,
    timeperiod: any
  ): Promise<any> {
    let timeperiod_formatted = this.formatDate(timeperiod);

    const data = new HttpParams()
      .set("default_summary", "true")
      .set(
        "time_range",
        JSON.stringify({
          since: timeperiod_formatted.since,
          until: timeperiod_formatted.until,
        })
      )
      .set("level", "campaign")
      .set(
        "fields",
        "account_id,account_name,campaign_id,campaign_name,account_currency,impressions,reach,spend,clicks,cost_per_action_type,actions"
      )
      .set("access_token", access_token);

    return this.http
      .get("https://graph.facebook.com/v7.0/" + account_id + "/insights", {
        params: data,
      })
      .toPromise();
  }

  getCampaignInsights(
    id: string,
    access_token: string,
    timeperiod: any
  ): Promise<any> {
    let timeperiod_formatted = this.formatDate(timeperiod);

    const data = new HttpParams()
      .set(
        "time_range",
        JSON.stringify({
          since: timeperiod_formatted.since,
          until: timeperiod_formatted.until,
        })
      )
      /*
      .set(
        "fields",
        "campaign_id,campaign_name,account_currency,action_values,actions,impressions,reach,spend,clicks,cost_per_action_type,cost_per_10_sec_video_view,cpc,cpm,ctr,objective,video_avg_time_watched_actions,video_p25_watched_actions,video_p50_watched_actions,video_p75_watched_actions,video_p100_watched_actions,video_10_sec_watched_actions"
      )
      */
      .set(
        "fields",
        "campaign_id,campaign_name,account_currency,action_values,actions,impressions,reach,spend,clicks,cost_per_action_type,cpc,cpm,ctr,objective,video_avg_time_watched_actions,video_p25_watched_actions,video_p50_watched_actions,video_p75_watched_actions,video_p100_watched_actions"
      )
      .set("access_token", access_token);
    return this.http
      .get("https://graph.facebook.com/v7.0/" + id + "/insights", {
        params: data,
      })
      .toPromise();
  }

  getCampaignInsightsByGender(
    id: string,
    access_token: string,
    timeperiod: any
  ): Promise<any> {
    let timeperiod_formatted = this.formatDate(timeperiod);

    const data = new HttpParams()
      .set(
        "time_range",
        JSON.stringify({
          since: timeperiod_formatted.since,
          until: timeperiod_formatted.until,
        })
      )
      .set("breakdowns", "gender")
      .set(
        "fields",
        "action_values,actions,impressions,reach,spend,clicks,cost_per_action_type,cpc,cpm,ctr,objective"
      )
      .set("access_token", access_token);
    return this.http
      .get("https://graph.facebook.com/v7.0/" + id + "/insights", {
        params: data,
      })
      .toPromise();
  }

  getCampaignInsightsByAge(
    id: string,
    access_token: string,
    timeperiod: any
  ): Promise<any> {
    let timeperiod_formatted = this.formatDate(timeperiod);

    const data = new HttpParams()
      .set(
        "time_range",
        JSON.stringify({
          since: timeperiod_formatted.since,
          until: timeperiod_formatted.until,
        })
      )
      .set("breakdowns", "age")
      .set(
        "fields",
        "action_values,actions,impressions,reach,spend,clicks,cost_per_action_type,cpc,cpm,ctr,objective"
      )
      .set("access_token", access_token);

    return this.http
      .get("https://graph.facebook.com/v7.0/" + id + "/insights", {
        params: data,
      })
      .toPromise();
  }

  getIndex(field: string, campaign: any): number {
    let ind = -1;
    if (campaign && campaign.actions) {
      ind = campaign.actions.findIndex((x) => x.action_type == field);
    }
    return ind;
  }

  getTotalConversions(campaign: any) {
    if (
      this.getIndex("offsite_conversion.fb_pixel_custom", campaign) > -1 &&
      this.getIndex("offsite_conversion.fb_pixel_lead", campaign) > -1
    ) {
      this.conversion =
        +campaign.actions[
          this.getIndex("offsite_conversion.fb_pixel_lead", campaign)
        ].value +
        +campaign.actions[
          this.getIndex("offsite_conversion.fb_pixel_custom", campaign)
        ].value;
      return this.conversion;
    } else if (
      this.getIndex("offsite_conversion.fb_pixel_lead", campaign) > -1
    ) {
      this.conversion =
        campaign.actions[
          this.getIndex("offsite_conversion.fb_pixel_lead", campaign)
        ].value;
      return this.conversion;
    } else if (
      this.getIndex("offsite_conversion.fb_pixel_custom", campaign) > -1
    ) {
      this.conversion =
        campaign.actions[
          this.getIndex("offsite_conversion.fb_pixel_custom", campaign)
        ].value;
      return this.conversion;
    } else {
      return 0;
    }
  }
}
