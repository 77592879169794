import { Component, OnInit, OnDestroy, Inject, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientFormService } from "../_services/patient-form.service";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { FilterUtils } from "primeng/utils";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";
import { Subscription } from 'rxjs';
import { ExportDataService } from '../_services/export-data.service';
import { Questions } from '../legacy/forms/forms';
import { FormBuilderService } from '../_services/form-builder.service';
import { AccountService } from '../_services/account.service';
import { SendPatientFormsComponent } from "./send-patient-forms/send-patient-forms.component"
import { PatientFormConfigurationComponent } from "./patient-form-configuration/patient-form-configuration.component"


export interface DialogData {
  action: string;
  contact: any;
}

@Component({
  selector: "app-patient-form-details",
  templateUrl: "./patient-form-details.component.html",
  styleUrls: ["./patient-form-details.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          transform: "translate3d(0, 0, 0)",
        })
      ),
      state(
        "out",
        style({
          transform: "translate3d(100%, 0, 0)",
        })
      ),
      transition("in => out", animate("400ms ease-in-out")),
      transition("out => in", animate("400ms ease-in-out")),
    ]),
  ],
})

export class PatientFormDetailsComponent implements OnInit, OnDestroy {
  formResponses: any[] = [];
  formReady: boolean = false;
  dataSource: any = [];
  displayAllData: any = [];
  columns: any = [];
  pageSize: number = 10;
  pageSizeOption: number[] = [10, 20, 50];
  expandedElement: any;
  rows: number = 20;
  first: number = 0;
  datakey: string;
  searchFields: any = [];
  isEmpty: boolean = false;
  patientFormSubscription: Subscription;
  formSubscription: Subscription;
  actionTaken: string;
  forms = [];
  acc: any;
  allForms: any[];
  acc_ref: any;
  isFormResponses: boolean;
  formResponseGroup: any = [];
  showLegacyFormResponses: boolean;
  legacyFormResponseExist: boolean;
  label: string = "Show legacy form responses";
  searchTerm: any;
  dataSourceOriginal: any = [];
  formReadyNew: boolean;
  hideSettings: boolean = true;
  accountSlug: any;

  constructor(
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private patientFormService: PatientFormService,
    public dialog: MatDialog,
    private zone: NgZone,
    private exportData: ExportDataService,
    private formBuilderService: FormBuilderService,
    private accountService: AccountService,
  ) {

    //subscribe to patient forms
    this.patientFormSubscription = this.patientFormService.patientForms$.subscribe(
      updatedResponses => {
        /** To emit change detection for prime ng table  used NgZone.run method  */
        this.zone.run(() => {
          if (updatedResponses.type == "modified") {
            let data = this.formatFormResponse(updatedResponses.value.data());
            data['id'] = updatedResponses.value.ref.parent.parent.id + '_' + updatedResponses.value.id;
            data['formRef'] = updatedResponses.value.ref;
            let i = this.dataSource.findIndex(x => x.id == data.id);
            if (i !== -1) {
              data['id'] = this.dataSource[i].id;
              this.dataSource[i] = data;
              this.dataSource = [...this.dataSource];
              //console.log("updated form resonse @", i);
            }
            else {
              this.dataSource = [...this.dataSource, data];
              //console.log("added new form response");
            }
          }
        });

      });

    FilterUtils["custom-equals"] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === "") {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return value.toString() === filter.toString();
    };

    this.acc_ref = this.patientFormService.getCurrentAccountRef();
  }

  formatFormResponse(formdata: any) {
    let data = formdata;
    // format the submitted form response for display purpose.
    if (data["status"] == "submitted") {
      data["status"] = "completed";
    } else if (data["status"] == "initiated") {
      data["status"] = "not completed";
    }
    if ('contact_ref' in data) {
      this.patientFormService.getcontactDetails(data.contact_ref).then(
        contactInfo => {
          data['contact'] = contactInfo;
        });
    }
    data["submitted"] =
      data.submitted !== undefined
        ? data["submitted"].toDate("MMM d, yyyy")
        : undefined;
    let identifierSec = data.response.filter(
      (section) => section.type == "identifier"
    );
    if (identifierSec[0]) {
      for (let que of identifierSec[0].questions) {
        if (que.answer_type == "name") {
          data[que.question] = que.answer;
        }
        if (que.answer_type == "email") {
          data['Email'] = que.answer;
        }
      }
    }
    return data;
  }

  async getCurrentAccountDetails(){
    this.accountSlug =await (await this.acc_ref.get()).data()['account_slug'];
    //console.log(this.accountSlug);
  }

  ngOnInit() {
    this.acc_ref = this.patientFormService.getCurrentAccountRef();
    this.getCurrentAccountDetails();
    //sub-header
    this.navigationService.updateState(
      new NavigationState(false, "Patient Intake Forms", "", "", "/patient-intake-forms")
    );
    // ------------------
    //get patient forms from the service.
    this.patientFormService.checkLegacyFormResponses().then(async (formResponses) => {
      if (formResponses.empty)
        this.legacyFormResponseExist = false;
      else
        this.legacyFormResponseExist = true;
    })
    this.patientFormService.getFormResponses().then((formResponses) => {
      // this.formResponses = formResponses;
      if (formResponses.length === 0) {
        this.isEmpty = true;
      } else {
        for (let i = 0; i < formResponses.length; i++) {
          if (formResponses[i].response) {
            this.setColumns(formResponses[i]);
            break;
          }
        }
        formResponses.forEach((res) => {
          if (res.response) {
            this.formResponses.push(res);
            let obj = {};
            obj["status"] = res.status;
            obj["response"] = res.response;
            obj["form_name"] = res.form_name;
            obj["id"] = res.id;
            obj["submitted"] = res.submitted;
            obj["formRef"] = res.formRef;
            obj["pdfUrl"] = res.pdfUrl;
            // if ('contact_ref' in res) {
            //   obj["contact_ref"] = res.contact_ref;
            //   this.patientFormService.getcontactDetails(res.contact_ref).then(
            //     contactInfo => {
            //       obj['contact'] = contactInfo;
            //     }
            //   );
            // }

            if ('contact_ref' in res) {
              obj["contact_ref"] = res.contact_ref;
              this.patientFormService.getcontactDetails(res.contact_ref).then(
                contactInfo => {
                  obj['contact'] = contactInfo;
                }
              );
            }

            res["response"].forEach((sec) => {
              if (sec.type === "identifier") {
                let contactInfo = {};
                for (let que of sec.questions) {
                  if (que.answer_type === "name") {
                    obj[que.question] = que.answer;
                    if (que.question == 'First Name') {
                      contactInfo['first_name'] = que.answer;
                    }
                    else if (que.question == 'Last Name') {
                      contactInfo['last_name'] = que.answer;
                    }
                  }
                  if (que.answer_type === 'email') {
                    obj['Email'] = que.answer;
                    contactInfo['email'] = que.answer;
                  }
                }
                if (res.status == 'accepted') {
                  this.patientFormService.getMatchingContact(contactInfo).then(contact => {
                    if (Object.keys(contact).length > 0) {
                      obj['contact'] = contact;
                    }
                    this.displayAllData.push(obj); // Object of Question and Answer.
                  });
                }
                else {
                  this.displayAllData.push(obj); // Object of Question and Answer.
                }
              }
            });
          }
        });
        this.formReady = true;
        this.formReadyNew = true;
        this.dataSource = this.displayAllData; // Assigned all data to datasource
        this.dataSourceOriginal = this.displayAllData.filter(ele => ele);
        // this.forms = this.dataSource;
        //console.log(this.dataSourceOriginal);
      }
    });
    this.getFormData();
  }

  getFormData() {
    this.forms = [];
    this.formBuilderService.getFormData("patient_forms").then(res => {
      res.forEach(doc => {
        let form = { id: '', slug: '', data: {} };
        form.data = doc.data();
        form.id = doc.id;
        form.slug = doc.data().slug;
        this.forms.push(form);
      });
      this.allForms = this.forms;
    });
  }

  getInput(event) {
    let s = event.target.value;
    this.searchTerm = event.target.value;
    this.searchFormNew();
  }

  searchFormNew() {
    // //console.log(this.searchTerm);
    if (this.searchTerm != "") {
      this.dataSource = this.dataSourceOriginal.filter((form) => {
        let formObject = {
          "First Name": form['First Name'],
          "Last Name": form['Last Name'],
          "status": form['status'] ? form['status'] : '',
          "form_name": form['form_name'],
          "Patient's Full Name": form["Patient's Full Name"] ? form["Patient's Full Name"] : ''
        };
        if (!this.columns.some(function (el) { return el.header === 'First Name' }))
          delete formObject['First Name'];
        if (!this.columns.some(function (el) { return el.header === 'Last Name' }))
          delete formObject['Last Name'];
        if (!this.columns.some(function (el) { return el.header === "Patient's Full Name" }))
          delete formObject["Patient's Full Name"];
        return ((JSON.stringify(formObject)).toLowerCase().includes(this.searchTerm.toLowerCase()) == true)
      });
    }
    else {
      this.dataSource = this.dataSourceOriginal.filter(element => element);
    }

  }

  searchForm(event) {
    let s = event.target.value;
    this.searchTerm = event.target.value;
    // //console.log(this.allForms);
    this.forms = this.allForms.filter((form) => {
      return form.data.name.toLowerCase().indexOf(s.toLowerCase()) != -1;
    });
  }

  setColumns(formResponse) {
    // //console.log(formResponse,formResponse.response);
    let rowHeading = formResponse.response.filter(
      (section) => section.type == "identifier"
    );
    this.datakey = "id"; //rowHeading[0].questions[0].question;
    if (rowHeading[0]) {
      for (let que of rowHeading[0].questions) {
        if (que.answer_type === "name") {
          this.columns.push({
            header: que.question,
            value: que.question,
            display: "table-cell",
            class: "mid"
          });
          this.searchFields.push(que.question);
        }
      }
    }
    this.columns.push({
      header: "Form Name",
      value: "form_name",
      display: "table-cell",
      class: "mid"
    });
    this.columns.push({
      header: "Status",
      value: "status",
      display: "table-cell",
      class: "mid"
    });
    this.columns.push({
      header: "Status", value: "id", display: "none", class: "mid"

    });

    this.columns.push({
      header: "Submission Date",
      value: "submitted",
      display: "table-cell",
      class: "mid"
    });
  }

  downloadPdf(url) {
    var link = window.document.createElement("a");
    link.setAttribute("href", url);
    //link.setAttribute("download", "");
    link.click();
    window.document.removeChild(link);
  }

  showLegacyForm() {
    // this.searchTerm = "";
    if (this.searchTerm)
      this.searchFormNew();
    this.showLegacyFormResponses = !this.showLegacyFormResponses;
    if (this.showLegacyFormResponses) {
      this.label = "Hide legacy form responses";
    }
    else {
      this.label = "Show legacy form responses";
    }
  }

  formatData(value) {
    // let demo = { 'name': 'vipul' };
    // if (demo['age'] == undefined) {
    //   //console.log('true');
    //   demo['age'] = [];
    // }
    // //console.log("test", demo['age']);
    // //console.log(value);
    let data: any = {};
    for (let i = 0; i < value.length; i++) {
      let temp = {};
      let responses = value[i].response;
      for (let j = 0; j < responses.length; j++) {
        let questions = responses[j].questions
        for (let k = 0; k < questions.length; k++) {
          if (questions[k].answer_type != 'signature' && questions[k].answer_type != 'label')
            temp[questions[k].question] = questions[k].answer;
        }
      }
      // temp['Form Name'] = value[i].form_name;
      temp['Status'] = value[i].status;
      temp['Submission Date'] = value[i].submitted;
      if (data[value[i].form_name] == undefined)
        data[value[i].form_name] = [];
      data[value[i].form_name].push(temp);
    }
    // //console.log('data:', data);
    return data;
  }
  exportExcel(dt) {
    let data = this.formatData(dt.value);
    let keys = Object.keys(data);
    let exceldata = [];
    for (let i = 0; i < keys.length; i++) {
      exceldata.push({ name: keys[i].slice(0, 30), data: data[keys[i]] });
    }
    // //console.log(exceldata);
    this.exportData.downloadExcel(exceldata, 'Patient_forms');
  }

  openDialog(contact, action, id): void {
    const dialogRef = this.dialog.open(DialogOverview, {
      width: "250px",
      data: { contact: contact, action: action },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // //console.log("The dialog was closed", result);
      if (result == "accept") {
        this.acceptForm(contact, id);
      } else if (result == "reject") {
        this.rejectForm(contact, id);
      } else {
        //console.log("Canceled the status update");
      }
    });
  }

  linkContact(formData, id) {
    this.patientFormService.linkContact(this.acc_ref, formData.formRef, formData.contact.contact_ref).then(
      snap => {
        //console.log("form linked to the contact @", id);
      },
      err => {
        //console.log(err);
      }
    );
  }
  getContactDetailsForForm(contactInfo, id) {
    this.patientFormService.getMatchingContact(contactInfo).then(
      contact => {
        if (Object.keys(contact).length > 0) {
          this.dataSource[id]['contact'] = contact;
        }
      },
      err => {
        //console.log(err);
      });
  }

  acceptForm(form, id) {
    this.patientFormService.acceptPatientForm(this.acc_ref, form.formRef).then(
      snap => {
        // //console.log('form accepted');
        let contactInfo = {
          'first_name': form['First Name'],
          'last_name': form['Last Name'],
          'email': form['Email']
        };
        this.getContactDetailsForForm(contactInfo, id);
      },
      err => {
        alert("Error : Unable to accept the form. Please Try again later.")
        // //console.log(err);
      });
  }

  rejectForm(form, id) {
    this.patientFormService.rejectPatientForm(this.acc_ref, form.formRef).then(
      snap => {
        // //console.log('form rejected');
      },
      err => {
        alert("Error : Unable to reject the form. Please Try again later.")
        // //console.log(err);
      });
  }
  exportCSV(dt) {

  }
  ngOnDestroy() {
    this.patientFormSubscription.unsubscribe();
  }
  /**Filter for prime ng table */
  reset() {
    this.first = 0;
  }
  next() {
    this.first = this.first + this.rows;
  }
  prev() {
    this.first = this.first - this.rows;
  }
  isLastPage(): boolean {
    return this.first === this.dataSource.length - this.rows;
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }
  getSelectedTab(tab) {
    if (tab.selectedIndex == 2) {
      this.isFormResponses = true;
    }
    else {
      this.isFormResponses = false;
    }
  }

  openDialogForPatientConfiguration() {
    const dialogRef = this.dialog.open(PatientFormConfigurationComponent, {
      width: "1500px",
      height: '550px',
      data: {
        dialogTitle: "Send Patient Form",
        screenType: "algoliaSearch",
        type: "new"
      },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDialogForSendPatientForm() {
    const dialogRef = this.dialog.open(SendPatientFormsComponent, {
      width: "1500px",
      height: '550px',
      data: {
        dialogTitle: "Send Patient Form",
        screenType: "algoliaSearch",
        type: "new"
      },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  /**Filter for prime ng table end*/
}

@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "accept-rejectForm.html",
})
export class DialogOverview {
  action: string;
  contactDetails: any;
  status: string;

  constructor(
    public dialogRef: MatDialogRef<DialogOverview>,
    private patientFormService: PatientFormService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.action = data["action"];
    this.contactDetails = data["contact"];
  }

  onNoClick(): void {
    this.dialogRef.close("no");
  }

}
