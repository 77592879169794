<div class="alert alert-danger" role="alert" *ngIf="isAGmbError" style="text-align: center;">
  Error while connecting Google My Bussiness account
</div>

<div class="alert alert-danger" role="alert" *ngIf="isAnalyticsError" style="text-align: center;">
  Error while connecting Analytics account
</div>

<div style="background-color: none;" class="row text-center" id="subHeader1">
  <div style="margin:2px auto 10px;font-size: 20px;font-weight: 500;">Accounts</div>
</div>

<div *ngIf="isAllready else notReady">
  <div *ngIf="isAccountCardsShow" id="subHeader2">
    <div>
      <p class="text-center">Connect your Google My Bussiness,Google Analytics,Google Adwords and Facebook accounts to
        see your data.</p>
    </div>
    <div class="row">
      <div class="col-md-4" style="display: flex;justify-content: center;margin-bottom: 2%;">
        <p-card [style]="{height:'150px',width:'300px',
            'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'
            }" styleClass="p-card-shadow">
          <p-header>
            <div style="padding-top: 1%;">
              <b>Google My Business</b>
            </div>
          </p-header>
          <p-card-content>
            <div class="row" style="height: 100%;">
              <div class="col-2" style="text-align: center;">
                <img id="gmbLogo" src="assets/images/gmb.png" alt="image">
              </div>
              <div class="col-10">
                <div class="col" style="font-size: 13px;">
                  <div class="row">
                    <div class="col-4">Status:</div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;" *ngIf="gmbCredentilas">Connected
                    </div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;" *ngIf="!gmbCredentilas">Not connected
                    </div>
                  </div>
                  <div class="row" *ngIf="gmbCredentilas">
                    <div class="col-4">Added:</div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;">
                      {{moment(gmbCredentilas[0]['added_on'].toDate()).format('lll')}}</div>
                  </div>
                </div>
              </div>
            </div>
          </p-card-content>
          <p-footer>
            <div *ngIf="!gmbAccountUnlinkProcessStart  else loading" style="margin-bottom: 0.5%;margin-left: 0px;">
              <div *ngIf="gmbCredentilas" class="row">
                <div class="col-6">
                  <button mat-raised-button id="button" color="primary"
                    (click)="unlinkAccounts('gmb')">Disconnect</button>
                </div>
                <div class="col-6">
                  <button mat-raised-button id="button" color="primary"
                    (click)="configureAccounts('gmb')">Configure</button>
                </div>
              </div>
              <div *ngIf="!gmbCredentilas" style="text-align: center;width:100%">
                <button mat-raised-button id="connectButton" color="primary"
                  (click)="linkAccounts('gmb')">Connect</button>
              </div>
            </div>
            <ng-template #loading style="height: 100%;margin-top:10%">
              <div id="gmbSpinnerDiv" style="text-align: center" [class.check]="gmbAccountUnlinkProcessStart">
                <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
              </div>
            </ng-template>
          </p-footer>
        </p-card>
      </div>
      <div class="col-md-4" style="display: flex;justify-content: center;margin-bottom: 2%;">
        <p-card [style]="{height:'150px',width:'300px',
                  'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'
                  }" styleClass="p-card-shadow">
          <p-header>
            <div style="padding-top: 1%;">
              <b>Gmail</b>
            </div>
          </p-header>
          <p-card-content>
            <div class="row" style="height: 100%;">
              <div class="col-2" style="text-align: center;">
                <img id="gmailLogo" src="assets/images/gmail_logo2.png" alt="image">
              </div>
              <div class="col-10">
                <div class="col" style="font-size: 13px;">
                  <div class="row">
                    <div class="col-4">Status:</div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;" *ngIf="gmailCredentials">Connected
                    </div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;" *ngIf="!gmailCredentials">Not
                      connected
                    </div>
                  </div>
                  <div class="row" *ngIf="gmailCredentials">
                    <div class="col-4">Added:</div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;">
                      {{moment(gmailCredentials[0]['added_on'].toDate()).format('lll')}}</div>
                  </div>
                </div>
              </div>
            </div>
          </p-card-content>
          <p-footer>
            <div *ngIf="!gmailAccountUnlinkProcessStart  else loading" style="margin-bottom: 0.5%;margin-left: 0px;">
              <div *ngIf="gmailCredentials" style="text-align: center;width:100%">
                <button mat-raised-button id="connectButton" color="primary"
                  (click)="unlinkAccounts('gmail')">Disconnect</button>
              </div>
              <div *ngIf="!gmailCredentials" style="text-align: center;width:100%">
                <button mat-raised-button id="connectButton" color="primary"
                  (click)="linkAccounts('gmail')">Connect</button>
              </div>
            </div>
            <ng-template #loading style="height: 100%;margin-top:10%">
              <div id="gmbSpinnerDiv" style="text-align: center" [class.check]="gmailAccountUnlinkProcessStart">
                <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
              </div>
            </ng-template>
          </p-footer>
        </p-card>
      </div>

      <div class="col-md-4" style="display: flex;justify-content: center;margin-bottom: 2%">
        <p-card [style]="{height:'150px',width:'300px',
        'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'
        }" styleClass="p-card-shadow">
          <p-header>
            <div style="padding-top: 1%;">
              <b>Google Analytics</b>
            </div>
          </p-header>
          <p-card-content>
            <div class="row" style="height: 100%;">
              <div class="col-2" style="text-align: center;">
                <img id="googleLogo" src="assets/images/analytics.png" alt="image">
              </div>
              <div class="col-10">
                <div class="col" style="font-size: 13px;">
                  <div class="row">
                    <div class="col-4">Status:</div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;" *ngIf="analyticsCredentials">
                      Connected</div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;" *ngIf="!analyticsCredentials">Not
                      connected</div>
                  </div>
                  <div class="row" *ngIf="analyticsCredentials">
                    <div class="col-4">Added:</div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;">
                      {{moment(analyticsCredentials[0]['added_on'].toDate()).format('lll')}}</div>
                  </div>
                </div>
              </div>
            </div>
          </p-card-content>
          <p-footer>
            <div *ngIf="!analyticsAccountUnlinkProcessStart else loading" style="margin-bottom: 0.5%;margin-left: 0px;">
              <div *ngIf="analyticsCredentials" class="row">
                <div class="col-6">
                  <button mat-raised-button id="button" color="primary"
                    (click)="unlinkAccounts('analytics')">Disconnect</button>
                </div>
                <div class="col-6">
                  <button mat-raised-button id="button" color="primary"
                    (click)="configureAccounts('analytics')">Configure</button>
                </div>
              </div>
              <div *ngIf="!analyticsCredentials" style="text-align: center;width:100%">
                <button mat-raised-button id="connectButton" color="primary"
                  (click)="linkAccounts('analytics')">Connect</button>
              </div>
            </div>
            <ng-template #loading style="height: 100%;margin-top:10%">
              <div id="spinnerDiv" style="text-align: center" [class.check]="analyticsAccountUnlinkProcessStart">
                <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
              </div>
            </ng-template>
          </p-footer>
        </p-card>
      </div>

      <div class="col-md-4" style="display: flex;justify-content: center;margin-bottom: 2%;">
        <p-card [style]="{height:'150px',width:'300px',
          'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'
          }" styleClass="p-card-shadow">
          <p-header>
            <div style="padding-top: 1%;">
              <b>Google Adwords</b>
            </div>
          </p-header>
          <p-card-content>
            <div class="row" style="height: 100%;">
              <div class="col-2" style="text-align: center;">
                <img id="googleLogo" src="assets/images/adwords.jpg" alt="image">
              </div>
              <div class="col-10" style="text-align: center;">
                <!-- <div class="col" style="padding: 0px;font-size: 13px;">
                  <div class="row">
                    <div class="col-4">Status:</div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;" *ngIf="adwordsCredentials">Connected</div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;" *ngIf="!adwordsCredentials">Not connected</div>
                  </div>
                  <div class="row" *ngIf="adwordsCredentials">
                    <div class="col-4">Added:</div>
                    <div class="col-8" style="padding-left: 0px;text-align: left;">{{moment(adwordsCredentials['added_on']).fromNow()}}</div>
                  </div>
                </div> -->
                Coming Soon
              </div>
            </div>
          </p-card-content>
          <p-footer>
            <!-- <div class="row" *ngIf="!adwordsAccountUnlinkProcessStart else loading" style="margin-bottom: 0.5%;margin-left: 0px;">
              <div *ngIf="accountDetails" class="row">
                <div class="col-6">
                  <button mat-raised-button id="button" color="primary" (click)="unlinkAccounts('adwords')">Disconnect</button>
                </div>
                <div class="col-6">
                  <button mat-raised-button id="button" color="primary" (click)="configureAccounts('adwords')">Configure</button>
                </div>
              </div>
              <div *ngIf="!adwordsCredentials" style="text-align: center;width:100%">
                <button mat-raised-button id="connectButton" color="primary" (click)="linkAccounts('adwords')">Connect</button>
              </div>
            </div>
            <ng-template #loading>
              <div id="spinnerDiv" style="text-align: center">
                <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
              </div>
            </ng-template> -->
          </p-footer>
        </p-card>
      </div>
    </div>

    <div style="margin-top: 2%;" class="row">
      <div class="col-md-4" style="display: flex;justify-content: center;margin-bottom: 2%;">
        <p-card [style]="{height:'150px',width:'300px',
        'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'
        }" styleClass="p-card-shadow">

          <p-header>
            <div style="padding-top: 1%;">
              <b>Facebook</b>
            </div>
          </p-header>
          <p-card-content>
            <div class="row" style="height: 100%;">
              <div class="col-2" style="text-align: center;">
                <img id="googleLogo" src="assets/images/facebook.jpg" alt="image">
              </div>
              <div class="col-10" style="text-align: center;">
                <!-- <button mat-raised-button id="button" color="primary">Disconnect</button> -->
                <!-- <div class="col" style="padding: 0px;font-size: 13px;">
                      <div class="row">
                        <div class="col-4">Status:</div>
                        <div class="col-8" style="padding-left: 0px;text-align: left;">Connected</div>
                      </div>
                      <div class="row">
                        <div class="col-4">Added:</div>
                        <div class="col-8" style="padding-left: 0px;text-align: left;">a month ago</div>
                      </div>
                    </div> -->
                Coming Soon
              </div>
            </div>
          </p-card-content>
          <p-footer>
            <!-- <div class="row">
                <div class="col-6">
                  <button mat-raised-button id="button" color="primary" (click)="linkAccounts('facebook')" *ngIf="!fbCredentials" [disabled]="true">Connect</button>
                  <button mat-raised-button id="button" color="primary" (click)="unlinkAccounts('facebook')" *ngIf="fbCredentials" [disabled]="true">Disconnect</button>
                </div>
                <div class="col-6">
                  <button mat-raised-button id="button" color="primary" (click)="configureAccounts('facebook')" [disabled]="true">Configure</button>
                </div>
              </div> -->
          </p-footer>
        </p-card>
      </div>
    </div>
  </div>
  <div *ngIf="!isAccountCardsShow" style="height: 100%;width: 100%;padding: 0%;margin: 5px;">
    <div *ngIf="isDataLoaded else notLoaded" style="height: 100%;width: 100%;padding: 0%;">
      <app-data-channels-configuration *ngIf="accountConfigurationDetails['screen'] == 'operation'"
        [accountConfigurationDetails]="accountConfigurationDetails" (editInfoEmittor)="saveEditedinformation($event)"
        (backEmittor)="back()" (editEmittor)="editGmbAcc($event)"
        (analyticsEmittor)="analyticsAccountConfiguration($event)" (saveEmittor)="saveAccountInformation($event)">
      </app-data-channels-configuration>

      <app-data-channels-information *ngIf="accountConfigurationDetails['screen'] == 'configure'"
        [accountConfigurationDetails]="accountConfigurationDetails" (editInfoEmittor)="saveEditedinformation($event)"
        (backEmittor)="back()" (editEmittor)="editGmbAcc($event)"
        (analyticsEmittor)="analyticsAccountConfiguration($event)" (saveEmittor)="saveAccountInformation($event)">
      </app-data-channels-information>
    </div>
    <ng-template #notLoaded style="height: 100%;margin-top:10%">
      <div id="spinnerDiv" style="text-align: center;" [class.process]="!isDataLoaded">
        <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
      </div>
    </ng-template>
  </div>
</div>
<ng-template #notReady style="height: 100%;margin-top:10%">
  <div id="spinnerDiv" style="text-align: center;" [class.process]="!isDataLoaded">
    <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
  </div>
</ng-template>