import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class TimePeriodService {
  timeperiod: any;
  private timePeriodSource = new Subject<any>();
  timePeriod$ = this.timePeriodSource.asObservable();

  constructor() {
    this.timeperiod = this.getDefaultTimeperiod();
  }

  updateTimePeriod(timePeriod: any) {
    this.timePeriodSource.next(timePeriod);
    this.timeperiod = timePeriod;
  }

  getDefaultTimeperiod(): any {
    return {
      start:moment().subtract(1,'month'),
      end: moment()
    };

  }


  getCurrentTimeperiod(): any {
    return this.timeperiod;

  }
}
