<div class="container-fluid view">
  <div class="row">
    <div class="col-sm-12">
      <h5 class="text-center" style="font-weight: 400;">SEO audit</h5>
    </div>
    <div class="col-sm-12">
      <p>SEO audit checks your website for 60+ parameters, defines and displays all found flaws, so you can easily fix
        them.
        The full list of parameters that your website is checked for you can find in a table for your website URL.</p>
    </div>
  </div>

  <div *ngIf="showGetResults; else showResults">
    <center>
      <button mat-raised-button class="text-center" color="primary" (click)="getUrl()">Get Results</button>
    </center>
  </div>
  <ng-template #showResults>
    <center>
      <div *ngIf="isLoading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <p styles="text-align : center;">Loading...</p>
      </div>
    </center>
    <!--<p>Last crawled on : {{last_crawled_on | date:'MMM d, y h:mm a'  }}</p>-->
    <div *ngIf="showSummary">
      <div class="scrolling-wrapper-flexbox">
        <div *ngIf="secureConnection; else notSecure">
          <mat-card class="metric-card">
            <mat-card-header>
              <mat-card-title class="metric-caption"></mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="metric kpi"> <i class="material-icons">https</i> </div>
              <div class="metric-caption kpi"><strong>Your website is secure</strong></div>
            </mat-card-content>
          </mat-card>
        </div>
        <ng-template #notSecure>
          <mat-card class="metric-card">
            <mat-card-header>
              <mat-card-title class="metric-caption"></mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="metric kpi"> <i class="material-icons">lock_open</i> </div>
              <div class="metric-caption kpi"><strong>Your website is not secure</strong></div>
            </mat-card-content>
          </mat-card>
        </ng-template>
        <div *ngIf="robot_txt_present; else noRobotTxt">
          <mat-card class="metric-card">
            <mat-card-header>
              <mat-card-title class="metric-caption"></mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="metric kpi"> <i class="material-icons">check</i> </div>
              <div class="metric-caption kpi"><strong>Robot.txt is present</strong></div>
            </mat-card-content>
          </mat-card>

        </div>
        <ng-template #noRobotTxt>
          <mat-card class="metric-card">
            <mat-card-header>
              <mat-card-title class="metric-caption"></mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="metric kpi"> <i class="material-icons">close</i></div>
              <div class="metric-caption kpi"><strong>No robot.txt</strong></div>
            </mat-card-content>
          </mat-card>
        </ng-template>
        <div *ngIf="site_map_present; else noSiteMap">
          <mat-card class="metric-card">
            <mat-card-header>
              <mat-card-title class="metric-caption"></mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="metric kpi"> <i class="material-icons">grid_on</i> </div>
              <div class="metric-caption kpi"><strong>Sitemap is present</strong></div>
            </mat-card-content>
          </mat-card>

        </div>
        <ng-template #noSiteMap>
          <mat-card class="metric-card">
            <mat-card-header>
              <mat-card-title class="metric-caption"></mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="metric kpi"> <i class="material-icons">grid_off</i> </div>
              <div class="metric-caption kpi"><strong>No Sitemap</strong></div>
            </mat-card-content>
          </mat-card>
        </ng-template>
      </div>

    </div>

    <div class="table1" *ngIf="showSummary">


      <div class="row">
        <div class="col-sm-6">
          <mat-form-field style="width:60%;">
            <input matInput (keyup)="applyFilterForTable1($event.target.value)" placeholder="Filter">
          </mat-form-field>
          <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">

            <ng-container matColumnDef="key">
              <th mat-header-cell *matHeaderCellDef>Summary parameters</th>
              <td mat-cell *matCellDef="let element"> {{element.key}} </td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef> Value </th>
              <td mat-cell *matCellDef="let element"> {{element.value}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let rows; columns:displayedColumns1;"></tr>
          </table>
        </div>
        <div class="col-sm-6">
          <mat-form-field style="width:60%;">
            <input matInput (keyup)="applyFilterForTable2($event.target.value)" placeholder="Filter">
          </mat-form-field>
          <table mat-table [dataSource]="dataSource2" class="mat-elevation-z8">

            <ng-container matColumnDef="key">
              <th mat-header-cell *matHeaderCellDef>Summary parameters</th>
              <td mat-cell *matCellDef="let element"> {{element.key}} </td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef> Value </th>
              <td mat-cell *matCellDef="let element"> {{element.value}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let rows; columns:displayedColumns2;"></tr>
          </table>

        </div>

      </div>




    </div>
    <div *ngIf="showError">
      <p class="text-center">{{errorMessage}}</p>
      <div *ngIf="showRefreshButton">
        <center>
          <button mat-raised-button class="text-center" color="primary" (click)="getUrl()">Refresh</button>
        </center>
      </div>
    </div>
  </ng-template>
</div>