<div class="container-fluid">
    <mat-card (click)="onFocus('_thankyou_screen')" class="metric-card">
        <form name="form" #responseForm="ngForm" novalidate>
            <p style="font-size: 18px; font-weight: 500;">Thank You Screen </p>
                <mat-checkbox name="useCustom" [(ngModel)]="useCustom"> Use custom</mat-checkbox>
                <div *ngIf="useCustom">
                    <mat-form-field class="example-full-width newline">
                        <input matInput name="headline_text" [(ngModel)]="thank_you_screen.headline_text" placeholder="Headline Text" required />
                    </mat-form-field>

                    <mat-form-field class="example-full-width newline">
                        <textarea matInput name = "short_description" [(ngModel)]="thank_you_screen.short_description" placeholder="Description (Here you can provide html also)" required></textarea>
                    </mat-form-field>

                    <mat-form-field class="example-full-width newline">
                        <input matInput name="button_text" [(ngModel)]="thank_you_screen.button_text" placeholder="Button Text" required />
                    </mat-form-field>

                    <mat-form-field class="example-full-width newline">
                        <input matInput name="button_link" [(ngModel)]="thank_you_screen.button_link" placeholder="Button Link" required />
                    </mat-form-field>
                      <app-file-upload [section]="thank_you_screen"></app-file-upload>
                    <mat-checkbox name="useTracking" [(ngModel)]="useTracking"> Use Tracking</mat-checkbox>
                    <div *ngIf="useTracking">
                        <mat-checkbox  class="newline"  name="{{this.thank_you_screen.tracking_code.adwords.enabled}}" [(ngModel)]="this.thank_you_screen.tracking_code.adwords.enabled"> Adwords</mat-checkbox>
                        <div *ngIf="this.thank_you_screen.tracking_code.adwords.enabled">
                            <mat-form-field class="example-full-width newline">
                                <input matInput name="conversion_id" [(ngModel)]="thank_you_screen.tracking_code.adwords.conversion_id" placeholder="Conversion ID" required />
                            </mat-form-field>

                            <mat-form-field class="example-full-width newline">
                                <input matInput name="conversion_label" [(ngModel)]="thank_you_screen.tracking_code.adwords.conversion_label" placeholder="Conversion Label" required />
                            </mat-form-field>
                            <mat-form-field class="example-full-width newline">
                                <input matInput name="form_name" [(ngModel)]="thank_you_screen.tracking_code.adwords.form_name" placeholder="Form Name" required />
                            </mat-form-field>
                        </div>
                        <mat-checkbox  class="newline" name="{{this.thank_you_screen.tracking_code.facebook.enabled}}" [(ngModel)]="this.thank_you_screen.tracking_code.facebook.enabled"> Facebook</mat-checkbox>
                        <div *ngIf="this.thank_you_screen.tracking_code.facebook.enabled">
                            <mat-form-field class="example-full-width newline">
                                <input matInput name="content_name" [(ngModel)]="thank_you_screen.tracking_code.facebook.custom_data.content_name" placeholder="Content Name" required />
                            </mat-form-field>

                            <mat-form-field class="example-full-width newline">
                                <input matInput name="conversion_label" [(ngModel)]="thank_you_screen.tracking_code.facebook.custom_data.content_category" placeholder="Content Category" required />
                            </mat-form-field>
                            <mat-form-field class="example-full-width newline">
                                <input matInput name="form_name" [(ngModel)]="thank_you_screen.tracking_code.facebook.form_name" placeholder="Form Name" required />
                            </mat-form-field>
                        </div>
                    </div>
            </div>
        </form>
    </mat-card>
</div>
