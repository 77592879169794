<div class="contanier-fluid view">

<h4 style="text-align : center;"><strong>Add a note</strong></h4>
<div class="close" (click)="close()">X</div>

<mat-form-field>
  <textarea matInput placeholder="Start typing your note..." [(ngModel)]="createdNote">{{createdNote}}</textarea>
</mat-form-field>

<button mat-raised-button color="primary" class="confirm" (click)="addNote()" [disabled]="!createdNote">Save note</button>
<button mat-raised-button color="primary" (click)="close()">Cancel</button>

</div>

