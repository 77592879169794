<div style="background-color: #f4f4f4;">
    <div style="background-color: white;color: black;padding: 20px;text-align: center;">
        <div class="container">
            <div class="row">
                <div class="col" style="justify-content: center;align-items: center;display: flex;">
                    <div class="page-heading" style="text-align: left;">
                        <h4 style="font-family: roboto, Helvetica neue, sans-serif;font-size: 20px;">Reward Campaigns
                        </h4>
                    </div>
                </div>
                <div class="col-md-3">
                    <div *ngIf="hideSettings else settings">
                        <button mat-mini-fab id="button" class="format" color="primary" (click)="hideSettings=false;"
                            matTooltip="Patient form configuration">
                            <span class="material-icons" style="cursor: pointer;" color="primary">settings</span>
                        </button>
                    </div>
                    <ng-template #settings>
                        <button mat-mini-fab id="button" class="format" color="primary" (click)="hideSettings=true;"
                            matTooltip="Close">
                            <span class="material-icons" style="cursor: pointer;" color="primary">close</span>
                        </button>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="rewardDocs.length === 0" style="padding-top: 20%;color:#7e84f2;">
        <div style="text-align: center;">
            <i class="pi pi-spin pi-spinner" style="font-size: 50px;" id="spinIcon1"></i>
        </div>
    </div>
    <div style="padding: 20px;" *ngIf="hideSettings">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-xl-4" *ngFor="let reward of rewardDocs;let i = index;">
                    <div class="card reward-card">
                        <div class="card-body">
                            <div class="container">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-sm-3 col-md-3">
                                        <div></div><i class="{{reward.icon}} reward-icon"></i>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="action-div">
                                            <h6>{{reward.title}}</h6>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="action-div">
                                            <div class=" form-check form-switch" style="padding-left:80px;">
                                                <input #flexSwitchCheckChecked class="form-check-input" type="checkbox"
                                                    role="switch" id="flexSwitchCheckChecked" [checked]="reward.isOn"
                                                    (change)="updateRewardToggle(flexSwitchCheckChecked.checked, reward)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <p style="margin-bottom: 5px;font-size: 12px;">{{reward.shortDescription}}
                                        </p>
                                    </div>
                                </div>
                                <div class="row d-flex justify-content-center">
                                    <div class="col-md-9">
                                        <div>
                                            <input id="points" name="points" #points="ngModel" type="number"
                                                class="form-control" placeholder="{{reward.points}}"
                                                style="width: 50%;font-size: 13px;" [value]="reward.points"
                                                [(ngModel)]="reward.points"
                                                [disabled]="selectedReward !== i || disabled"
                                                (change)="updateRewardValue($event.target.value, reward)" name="points"
                                                minlength="1" required />
                                            <div *ngIf="points.invalid && (points.dirty || points.touched)"
                                                class="text-danger">
                                                <div *ngIf="points.errors.required">
                                                    <p><small>Please enter some loyalty points</small></p>
                                                </div>
                                                <div *ngIf="points.errors.minlength">
                                                    <p><small>Please enter some loyalty points</small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div>
                                            <button *ngIf="selectedReward !== i" class="btn btn-primary small-button"
                                                role="button" (click)="editValues(i, reward)">Edit</button>
                                            <!--updateRewardValue(enteredValue: number, isOnValue: boolean, rewardDoc: any)-->
                                            <button [class.disabled]="points.invalid" *ngIf="selectedReward === i"
                                                class="btn btn-primary small-button" role="button"
                                                (click)="updateRewardOption(flexSwitchCheckChecked.checked, reward)">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div *ngIf="!hideSettings">
    <app-reward-message-configuration></app-reward-message-configuration>
</div>