import { Component, OnInit, Input } from '@angular/core';
import { AccountService, ContactService, AlertService } from '../../_services/index';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-smslogs',
  templateUrl: './smslogs.component.html',
  styleUrls: ['./smslogs.component.scss']
})
export class SmslogsComponent implements OnInit {
  @Input()
  contact : any;
  SMSFetched : boolean = true;
  twilio_credentials : any;
  outgoing_msgs : string;
  sms_array : any;
  //sent_message : any = [];
  //received_message : string;
  message : any =[];
  msg_status : string;
  outIcon : boolean = false;
  inIcon : boolean = false;
  noSms : boolean = false;
  constructor(
    private accountService: AccountService,
    private contactService:ContactService,
    private alertService: AlertService,
    private router : Router
  ) {
  //  console.log('SMS logs component constructor');
   }

  ngOnInit() {
      this.fetchSMSLogs();
  }

  fetchSMSLogs(){
    this.SMSFetched = false;
    this.twilio_credentials = this.accountService.getLinkedAccountCredentials('twilio');
    let contactObj = {
      'phone' : this.contact.full_phone,
      'acc_sid' : this.twilio_credentials.account_sid,
      'auth_token': this.twilio_credentials.auth_token
    }
      let sms_record = JSON.parse(sessionStorage.getItem('sms-logs' + this.contact.full_phone));
      console.log(sms_record);
      if(sms_record !== null){
        if(Date.now() - sms_record.creation_timestamp < 600000) { //10 minutes
        console.log('We have a fresh record. Fetching from localStorage.');
        this.sms_array = sms_record.logs;
        this.SMSFetched = true;
        if(this.sms_array.length === 0){
          this.SMSFetched = true;
          this.noSms = true;
        }
        console.log(this.sms_array);
        }
        else
        {
          console.log('We have an old record. Called twilio API and updated record.');
          this.contactService.fetchSMSLogs(contactObj).then(smsData =>{
              this.sms_array = smsData.data;
              this.sms_array.sort((a,b)=>{
                return <any>new Date(b.date) - <any>new Date(a.date);
              });
              console.log(this.sms_array);
              this.SMSFetched = true;
              if(this.sms_array.length === 0){
                this.SMSFetched = true;
                this.noSms = true;
              }
              sessionStorage.setItem('sms-logs' + this.contact.full_phone, JSON.stringify({"creation_timestamp": Date.now(), "logs": this.sms_array}));
            })
            .catch(error => {
              console.log(error);
              this.alertService.error(error.message);
            });
        }
      }
      else{
        console.log('No record found. Calling Twilio API to fetch SMS logs.');
        this.contactService.fetchSMSLogs(contactObj).then(smsData =>{
            this.sms_array = smsData.data;
            this.sms_array.sort((a,b)=>{
              return <any>new Date(b.date) - <any>new Date(a.date);
            });
            console.log(this.sms_array);
            this.SMSFetched = true;
            if(this.sms_array.length === 0){
              this.SMSFetched = true;
              this.noSms = true;
            }
            sessionStorage.setItem('sms-logs' + this.contact.full_phone , JSON.stringify({"creation_timestamp": Date.now(), "logs": this.sms_array}));
          })
          .catch(error => {
            console.log(error);
            this.alertService.error(error.message);
          });
      }
  /*  this.contactService.fetchSMSLogs(contactObj).then(smsData =>{
      this.sms_array = smsData.data;
      this.SMSFetched = true;
      if(this.sms_array.length === 0){
        this.SMSFetched = true;
        this.noSms = true;
      }
      sessionStorage.setItem('sms-logs-' + this.contact.first_name, JSON.stringify({"creation_timestamp": Date.now(), "logs": this.sms_array}));
    })
    .catch(error => {
      console.log(error);
      this.alertService.error(error.message);
    });*/

  }

}
