<div class="row container-fluid justify-content-center">
  <div class="col-md-8 col-sm-12">
    <mat-card class="info-cards">
      <mat-card-title>
        <center>
          <h3 class="login_header">Website audit</h3>
        </center>
      </mat-card-title>
      <center>
        <em>Sign up for a free website audit in less than 15 minutes.</em>
      </center>
      <mat-card-subtitle>
      </mat-card-subtitle>
      <mat-card-content>
        <form #formData='ngForm' (ngSubmit)="signUpWithEmail(formData)" name='myForm'>
          <mat-form-field class="example-full-width">
            <input matInput name="first_name" [(ngModel)]="first_name" placeholder="First Name" required />
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput name="last_name" [(ngModel)]="last_name" placeholder="Last Name" required />
          </mat-form-field>
          <!--
          <label>Choose location</label>
          <mat-radio-group name = "location" [(ngModel)]="location" required>
            <div *ngFor="let location of locations">
              <mat-radio-button [value]="location">{{ location }}</mat-radio-button>
          </div>
          </mat-radio-group>
          -->

          <mat-form-field class="example-full-width">
            <mat-select class="options" name="location" [(ngModel)]="location" placeholder="Choose location" required>
              <mat-option *ngFor="let location of locations" [value]="location">{{location}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input type="url" matInput name="url" [(ngModel)]="url" placeholder="Website URL" required />
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput name="cell_no" [(ngModel)]="cell_no" placeholder="Cell Number" required />
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <!-- <input matInput name="email" [(ngModel)]="email" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required /> -->
            <input type="email" matInput name="email" [(ngModel)]="email" placeholder="Email" required />
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput type="password" name="password" [(ngModel)]="password" placeholder="Password" required />
          </mat-form-field>
          <div>
            <center>
              <button mat-raised-button color="primary" type="submit" [disabled]="!formData.valid">SignUp</button>
            </center>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
