import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddNewPostDailogComponent } from '../add-new-post-dailog/add-new-post-dailog.component';
import { SocialMediaService } from '../../_services/social-media.service';
import { DeleteTemplateComponent } from '../delete-template/delete-template.component';
@Component({
  selector: 'app-social-media-post-config',
  templateUrl: './social-media-post-config.component.html',
  styleUrls: ['./social-media-post-config.component.scss']
})
export class SocialMediaPostConfigComponent implements OnInit {
  templates: any;
  templateData: any;
  noTemplates: boolean = false;
  templateNames = [];

  @Output() getTemplateEmitter = new EventEmitter<any>();
  @Output() backBtnEmitter = new EventEmitter<any>();
  constructor(
    private socialMediaService: SocialMediaService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getTemplateData();
  }
  async getTemplateData() {
    let snapshot = await this.socialMediaService.getAllTemplates();
    console.log(snapshot.docs.map(doc => doc.data()));
    this.templateData = snapshot.docs.map(doc => doc.data())
    console.log(this.templateData);
    this.templateNames = []
    for (let i = 0; i < this.templateData.length; i++) {
      this.templateNames.push(this.templateData[i].name)
    }
    console.log(this.templateNames);

    if (this.templateData.length == 0) {
      console.log("no template");
      this.noTemplates = true;
    } else {
      this.noTemplates = false;
    }
  }
  openDailogForAddPost() {
    console.log("add new post");
    const dialogRef = this.dialog.open(AddNewPostDailogComponent, {
      width: '1200px',
      disableClose: true,
      height: '580px',
      data: {
        data: this.templateNames,
        popupHeader: "Add Social Media Template",
        templateNames: this.templateNames,
        type: "add"
      },
      panelClass: 'custom-dialog-container'

    });
    dialogRef.afterClosed().subscribe(async resp => {
      if (resp.status == 'success') {
        this.getTemplateData();
        this.getTemplateEmitter.emit();
      }
    });
  }
  backBtn() {
    this.backBtnEmitter.emit();
  }
  deleteTemplate(template) {
    console.log("delete template..");
    console.log(template);
    const dialogRef = this.dialog.open(DeleteTemplateComponent, {
      width: '600px',
      disableClose: true,
      height: '400px',
      data: {
        data: template,
        templateNames: this.templateNames,
        popupHeader: "Are you sure you want to delete this Template?",
        type: "delete"
      },
      panelClass: 'custom-dialog-container'

    });
    dialogRef.afterClosed().subscribe(async resp => {
      this.getTemplateData();
      this.getTemplateEmitter.emit()
      console.log(resp);
    });
  }
  editTemplate(obj: any) {
    console.log(obj);
    let template = Object.assign({}, obj);
    const dialogRef = this.dialog.open(AddNewPostDailogComponent, {
      width: '1200px',
      disableClose: true,
      height: '580px',
      data: {
        data: template,
        templateNames: this.templateNames,
        popupHeader: "Update Template",
        type: "update"
      },
      panelClass: 'custom-dialog-container'

    });
    dialogRef.afterClosed().subscribe(async resp => {
      this.getTemplateData();
      console.log(resp);
    });
  }

}
