<div *ngIf="contactDetails!== undefined; else elseBlock">
  <table class="table">
    <tbody>
      <tr>
        <th scope="row">First Name</th>
        <td>
          <input class="myinput" matInput placeholder="Enter" [value]="contactDetails['first_name']"
            (change)="updateValue($event.target.value, 'first_name', contactDetails)"
            [disabled]="!contactDetails['active']" />
        </td>
      </tr>

      <tr>
        <th scope="row">Last Name</th>
        <td>
          <input class="myinput" matInput placeholder="Enter" [value]="contactDetails['last_name']"
            (change)="updateValue($event.target.value,'last_name', contactDetails)"
            [disabled]="!contactDetails['active']" />
        </td>
      </tr>

      <tr>
        <th scope="row">Email</th>
        <td>
          <input class="myinput" matInput placeholder="Enter" [value]="contactDetails['email']"
            (change)="updateValue($event.target.value, 'email', contactDetails)"
            [disabled]="!contactDetails['active']" />
        </td>
      </tr>

      <tr>
        <th scope="row">Phone</th>
        <td>
          <input class="myinput" matInput placeholder="Enter" [value]="contactDetails['phone']"
            (change)="updateValue($event.target.value, 'phone', contactDetails)"
            [disabled]="!contactDetails['active']" />
        </td>
      </tr>
      <tr>
        <th scope="row">Text Reminder</th>
        <td>
          <mat-slide-toggle [disabled]="!contactDetails['active']" name="send_text_reminders"
            [(ngModel)]="contactDetails['send_text_reminders']" style="vertical-align: middle;"
            (change)="updateValue(contactDetails['send_text_reminders'],'send_text_reminders',contactDetails)">
          </mat-slide-toggle>
          <!-- (change)="saveSendTextReminders(contactDetails['send_text_reminders'],contactDetails['ref'])"> -->

        </td>
      </tr>
      <tr>
        <th scope="row">Email Reminder</th>
        <td>
          <mat-slide-toggle [disabled]="!contactDetails['active']" name="send_email_reminders"
            [(ngModel)]="contactDetails['send_email_reminders']" style="vertical-align: middle;"
            (change)="updateValue(contactDetails['send_email_reminders'],'send_email_reminders',contactDetails)">
          </mat-slide-toggle>

        </td>
      </tr>
      <tr>
        <th scope="row">Date of Birth</th>
        <td>
          <input #birthDateRef class="myinput" matInput placeholder="MM-DD-YYYY"
            [ngModel]="contactDetails['birthday'] | date:'MM/dd/yyyy'"
            (change)="updateBirthDate($event.target.value, contactDetails,birthDateRef)"
            [disabled]="!contactDetails['active']" />
        </td>
      </tr>
      <tr>
        <th scope="row">Last Visited</th>
        <td>
          <input class="myinput" matInput [matDatepicker]="picker" [disabled]="!contactDetails['active']"
            [(ngModel)]="contactDetails.last_visited" [max]="maxDate"
            (dateChange)="updateValue(contactDetails.last_visited,'last_visited',contactDetails)"
            placeholder="Choose a date" (click)="_openCalendar(picker)" #elementToFocus />
          <mat-datepicker #picker="matDatepicker" disabled="false" [opened]="false"
            (closed)="_closeCalendar($event,contactDetails.ref,contactDetails.last_visited)"
            [disabled]="!contactDetails['active']">
          </mat-datepicker>
        </td>
      </tr>

      <tr>
        <th scope="row">Contact Created On</th>
        <td>{{ contactDetails.created_on | date: "MMM d, yyyy" }}</td>
      </tr>
      <tr>
        <th scope="row">Active</th>
        <td>
          <!-- (change)="updateRowVisible(contactDetails, contact_ref)"> -->

          <mat-slide-toggle class="formatToggle" name="greyRow" [(ngModel)]="contactDetails.active"
            (change)="updateValue(contactDetails.active,'active',contactDetails)"></mat-slide-toggle>
        </td>
      </tr>
      <tr *ngIf="languages.length>0">
        <th scope="row">Language</th>
        <td>
          <mat-form-field style="width:120px;padding:0px;">
            <mat-select [(value)]="contactDetails.targetLanguage"
              (selectionChange)="updateValue(contactDetails['targetLanguage'],'targetLanguage',contactDetails)">
              <mat-option *ngFor="let code of languages" [value]="code.value">
                {{code.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #elseBlock>
  <!-- No Contact -->
</ng-template>