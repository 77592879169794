import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { config } from 'process';
// import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { ContactService } from '../../_services/contact.service';
import { FormBuilderService } from '../../_services/form-builder.service';
import { environment } from '../../../environments/environment';
import { AccountService } from '../../_services/account.service';

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss']
})
export class PatientFormComponent implements OnInit {

  constructor(
    private formBuilderService: FormBuilderService,
    private accountService: AccountService,
    public config: DynamicDialogConfig,
    private contactService: ContactService,
  ) { }
  selectedForms: any = [];
  // selectedTypes: any = [];
  type: any = { "email": false, "sms": false };
  selectedContactsId: any = [];
  forms: any = [];
  loaded = false;
  formExists = true;
  contacts: any = this.config.data.contacts;
  disableSms: boolean;
  @Input() isFormValid: boolean = true;
  selectedFormData: any = [];
  linked_accounts: any = [];
  isStartScreen: boolean = true;
  emailSubject: string = "Patient Form";
  emailBody: string = "";
  smsBody: string = "";
  dialogInfo: any = [];
  goLast: boolean = false;
  emailSmsContent:any;
  showConfirm: boolean= true;
  goPrevious: boolean=false;
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() previousData: any;
  container: HTMLElement;
  disabled:boolean = true;

  displayMsg:string='Form Sending Job is added in the queue and it will send a message soon....';



  ngOnInit() {

    this.dialogInfo = {
      emailSubject: "Patient Form Link",
      emailBody: "Click  patient form link for more details",
      smsBody: "Patient Form  Link for you"
    }

    // //console.log(this.previousData);
    let accRef = this.accountService.getCurrentAccDetails();
    if (accRef.twilio_from)
      this.disableSms = false;
    else
      this.disableSms = true;
    // //console.log("accRef:", accRef.twilio_from);
    this.forms = [];
    this.formBuilderService.getFormData("patient_forms")
      .then(res => {
        // //console.log(res);
        res.forEach(doc => {
          let form = { id: '', slug: '', data: {} };
          form.data = doc.data();
          form.id = doc.id;
          form.slug = doc.data().slug;
          this.forms.push(form);
        });
        if (this.forms.length) {
          this.loaded = true;
          // if (this.forms.length == 1)
          this.selectedForms = [this.forms[0].id];
          this.selectedFormData = [this.forms[0].data.name];
          // this.loaded = true;
          // if (this.previousData) {
          //   this.selectedForms = this.previousData['selectedForms'];
          //   //console.log("prev:", this.selectedForms);
          //   // let index = this.forms.findIndex(value=>value.id === this.selectedForms.id);
          //   // this.selectedForms = this.forms[index];
          //   // this.selectForm(this.selectedForms);
          // }
          // else if (this.forms.length == 1) {
          //   this.selectedForms = [this.forms[0].id];
          //   this.selectedFormData = [this.forms[0].data.name];
          //   // this.selectForm(this.selectedForms);
          // }
        }
        else
          this.formExists = false;
      });
  }
  selectForm(form) {
    if (this.selectedForms.indexOf(form.id) == -1) {
      this.selectedForms.push(form.id);
      this.selectedFormData.push(form.data.name);
    }
    else {
      this.selectedForms.splice(this.selectedForms.indexOf(form.id), 1);
      this.selectedFormData.splice(this.selectedFormData.indexOf(form.data.name), 1);
    }
    this.dialogInfo['selectedForms'] = this.selectedForms;
    this.dialogInfo['formNames'] = this.selectedFormData;
  }
  selectSmsEmail(event) {
    this.type[event.source.value] = !this.type[event.source.value];
    // //console.log("types:", this.type);
  }

  getSmsEmailStatus(data) {
    //console.log(data);
    let dialofInfo = JSON.parse(data);
    if(dialofInfo['emailSubject'] && dialofInfo['emailBody'])
    {      
    this.emailSubject = dialofInfo['emailSubject'];
    this.emailBody = dialofInfo['emailBody'];
    }
    if(dialofInfo['smsBody'])
      this.smsBody = dialofInfo['smsBody'];
    dialofInfo['contact'] = this.contacts;
    dialofInfo['selectedForms'] = this.selectedForms;
    dialofInfo['formNames'] = this.selectedFormData;
    dialofInfo['form'] = "patient"
    if((dialofInfo['email'] || dialofInfo['sms']))
        this.disabled = false;
    else
      this.disabled = true;
    this.dialogInfo = dialofInfo;
  }

  next()
  {
    this.isStartScreen = false;
    //console.log(this.selectedForms);
    //console.log(this.selectedFormData);
  }

  async sendForms() {
    this.showConfirm =false;
    this.goLast = true;
    const types = {
      sms: this.dialogInfo['sms'],
      email: this.dialogInfo['email']
    }
    // //console.log("forms to send:", this.selectedForms);
    // //console.log("type:", this.type);
    // //console.log("contacts:", this.contacts);

    for (let i = 0; i < this.contacts.length; i++) {
      this.selectedContactsId.push(this.contacts[i].ref.id);
      // //console.log(this.selectedContactsId);
    }
    let url = environment.patientIntakeFormUrl + '/patient-form/';
    let data = {
      contacts: this.selectedContactsId,
      forms: this.selectedForms,
      types: types,
      url: url,
      emailBody: this.emailBody,
      smsBody: this.smsBody,
      emailSubject: this.emailSubject,
      task:"send"
    };
    // //console.log(data);
    this.contactService.sendPatientForms(data).then(response=>{
      this.container = document.getElementById("content");
      this.container.scrollTop = this.container.scrollHeight;
      this.goLast = true;
      //console.log(response)
      if(response.status=="sent")
      {
        this.displayMsg = `Message Sent.`;
      }
      else if(response.status=="error")
      {
        this.displayMsg = `The message can't be sent right now. Please try again later.`;
      }
    });

  }

  previous() {
    this.isStartScreen = true;
    //console.log(this.dialogInfo);
  }

}
