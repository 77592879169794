import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaCalenderService {
  public events: any = [
    { title: 'event 1', date: '2020-12-20T01:30:00+05:30' },
    { title: 'event 2', date: '2020-12-22', duration: '25:00' }, {
      id: 'a', title: 'my event', start: '2020-12-22', end: '2020-12-24', url: 'https://mail.google.com/mail/u/0/#inbox',
      eventColor: '#378006',
      eventBackgroundColor: 'red'
    }, {
      title: 'BCH237',
      start: '2020-12-24T01:30',
      allDay:true,
      extendedProps: {
        department: 'BioChemistry'
      },
      description: 'Lecture',
      
    },

  ];

  constructor() { }
  addEvent(event) {
    this.events.push(event)
  }
  getEvents() {
    return this.events.slice();
  }


}
