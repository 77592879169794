

<form name="form" (ngSubmit)="whatform.form.valid" #whatform="ngForm" novalidate>

 <div class="container-fluid">
   <div class="question col-sm-8" *ngFor="let question of questions;let i=index;let first = first; let last = last;">
<!--     <div  [hidden]="currentQuestionNumber!== i && currentQuestionNumber < i">-->

        <div class="container-fluid">
           <h5 class="font-weight-bold font-italic">{{question.question}}</h5>
            <div class="col-sm-12">
                <div class="form-group" [ngClass]="{ 'has-error': whatform.submitted && !question.valid }">
                 <div *ngIf="question.answer == 'text'">
                 <input type="text" class="form-control" id="question" [(ngModel)]="model[i]" name="question{{i+1}}" minlength="5" maxlength="30" tabindex="1" #question="ngModel" required>
                <!--Validation-->
                 <div *ngIf="question.errors && (question.dirty || question.touched)" class="alert alert-danger">
                 <div [hidden]="!question.errors.required">* This is required</div>
                 <div [hidden]="!question.errors.minlength">it must be at least 5 characters long.</div>
                 <div [hidden]="!question.errors.maxlength">it cannot be more than 30 characters long.</div>
               </div>

                 </div>
               </div>

                 <div *ngIf="question.answer == 'textarea'">
                   <textarea type="text" class="md-textarea form-control" id="question.que_id" [(ngModel)]="model[i]" name="question{{i+1}}" tabindex="1"></textarea>
                 <!--<input type="text" id="question.que_id" name="question.que_id" [(ngModel)]="model[i]" name="question{{i}}" class="form-control">-->
                 </div>

             <div class="form-group radio" *ngIf="question.answer == 'radio'">
        <!--<input type="radio" value="{{question.options[0]}}"> {{question.options[0]}}-->
               <div *ngFor="let option of question.options">
                 <input id="question.que_id" name="question{{i+1}}" type="radio" [(ngModel)]="model[i]" value="{{option}}" tabindex="1"> {{option}}
              </div>
            </div>

      <!--      <div *ngIf="question.answer == 'checkbox'">
             <div *ngFor="let checkbox of question.checkboxs">
             <input #check (change)="getValue(check.value)" type="checkbox" id="question.que_id" name="question{{i+1}}" [value]="checkbox" [(ngModel)]="model[i]" tabindex="1">{{checkbox}}
            </div>
          </div>-->
          <div *ngIf="question.answer == 'checkbox'">
          <div *ngFor="let checkbox of question.checkboxs;let j = index">

          <input #check type="checkbox" name="question{{i+1}}" [checked]='question.checkboxs[j].checked' [value]="checkbox" (change)="question.checkboxs[j].checked = $event.target.checked && getValue(check.value)" tabindex="1">{{checkbox}}
          </div>
          </div>

           <div *ngIf="question.answer == 'dropdown'">
              <select name="question{{i+1}}" [(ngModel)]="model[i]" tabindex="1">
              <option *ngFor="let categories of question.category" [value]="categories">{{categories}}</option>
              </select>
          </div>
      <!--  </div>-->
       <div class="row">
        <div class="col-8 text-center">
          <button type="submit" class="btn btn-default waves-light" (click)="setGoToNextTrue(i)" ripple-radius>Ok</button>

            <div class="text" (keyup.enter)="setGoToNextTrue(i)">Press Enter</div>
          <!--  <div class="btn btn-default" *ngIf="last" (click)="Submit()"> Submit </div>-->
        </div>
        </div>
      <!--<div class="text" *ngIf="last"><h4>This is last question. Please submit your form.</h4></div>-->
</div>

<div class="row"></div>

</div>
   </div>
  </div>
</form>

<!--Panel for progress status and Up-Down buttons-->
<div class="jumbotron grey lighten-3">
  <div class="row">
    <div class="col-4">
     <h4 *ngIf="first" class="font-weight-bold">{{questionAnswered}} out of {{questions.length}} Completed</h4>
     <h4 *ngIf="!first" class="font-weight-bold">{{questionAnswered}} out of {{questions.length}} Completed</h4>
    </div>
    <div class="col-4">
      <button type="submit" class="btn btn-default" [disabled]="!whatform.form.valid" (click)="Submit()">Submit</button>
    </div>
    <div class="col-4">
    <i class="fa fa-toggle-up float-right" (click)="setGoToNextFalse()" style="font-size:36px;" aria-hidden="true"></i>
    <i class="fa fa-toggle-down float-right" (click)="setGoToNextTrue(1)" style="font-size:36px;" aria-hidden="true"></i>
 </div>
</div>
</div>




 <p>Form value: {{ whatform.value | json}}</p>
