/*
 * Used in legacy code
 */

import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { User } from '../_models/index';
//import { toPromise } from 'rxjs';
import { Observable ,  Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class UserService {

  // Observable boolean source
  private isLoggedInSource = new Subject<boolean>();

  // Observable boolean stream
  isLoggedIn$ = this.isLoggedInSource.asObservable();

  constructor(private http: Http) { }

  // Service message command for Login/Logout toggling
  toggleLogin(flag: boolean) {
    this.isLoggedInSource.next(flag);
  }


  getToken(token:string){
    return [token];
  }

  showForm(token:string){
    return this.http.post(`${environment.serverURL}/link-activation.php`,token);
  }

  // To obtain the user set - anonymous or registered
  getUser() {
    //let anonymousUser = JSON.parse(localStorage.getItem('anonymousUser'));
    //let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //return anonymousUser ? anonymousUser : currentUser;

    return JSON.parse(localStorage.getItem('user'));
  }

  // To register a new user (Save Account)
  create(user: User) {
    let savedUser = this.getUser();
    let uuid = savedUser.uuid;
    let body = {"uuid": uuid,"login_id": user.login_id,"email":user.email, "cell_no":user.cell_no, "password":user.password};

    return this.http.post(`${environment.serverURL}/register.php`, JSON.stringify(body));
  }

  // To create a new anonymous user
  createUser() : Promise<any> {

    return this.http.post(`${environment.serverURL}/createUser.php`, "")
      .toPromise();
  }

  forgotPassword(user: User) {
    let body = JSON.stringify(user);
    return this.http.post(`${environment.serverURL}/forgotPassword.php`,body);
  }

  resetPassword(user: User) {
    let body = JSON.stringify(user);
    return this.http.post(`${environment.serverURL}/resetPassword.php`,body);
  }

   editProfile(user: User) {
     //let currentUser = JSON.parse(localStorage.getItem('currentUser'));
     //let uuid=currentUser.uuid;
     let body={"uuid" :user.uuid, "login_id":user.login_id, "email":user.email,"cell_no":user.cell_no};

      return this.http.post(`${environment.serverURL}/editProfile.php`,JSON.stringify(body));


    }

    changePassword(passwords: any, uuid: string)
    {
    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //  let uuid=currentUser.uuid;
    //  let password=currentUser.password;
      let body={"uuid" :uuid, "password":passwords.password, "newPassword":passwords.newPassword,"verifyPassword":passwords.verifyPassword};
      //console.log(body);

      return this.http.post(`${environment.serverURL}/changePassword.php`,JSON.stringify(body));
    }

    verifyMail(uuid:string){
       return this.http.post(`${environment.serverURL}/verifyMail.php`,uuid);
     }
     getVerifyToken(uuid:string){
      return [uuid];
     }


  /*
   * The flag that detects whether the 'New Plan' component has been redirected to from any other
   * component or from a typeform
  */
  getFlag(flag: number): number[] {
    return [flag];
  }

  // private helper methods
  private jwt() {
    // create authorization header with jwt token
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      let headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
      return new RequestOptions({ headers: headers });
    }

  }

}
