import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { count } from 'console';
import { SocialMediaService } from './../_services/social-media.service';
import { AddNewPostDailogComponent } from './add-new-post-dailog/add-new-post-dailog.component';
import { DeleteTemplateComponent } from './delete-template/delete-template.component';
import { UpdatePostComponent } from './update-post/update-post.component';
@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit {
  postConfig: boolean = false;
  templateData: any;
  settingFlag: boolean = true;
  allPost: any;
  allPostflag: boolean = true;
  noFaqTemplate: boolean = true;
  ejsTemplateContent: any;
  faqTemplates: any = []
  constructor(
    public dialog: MatDialog,
    private socialMediaService: SocialMediaService,
  ) {
    this.getAllPost();
    this.getTemplateData();
    this.generalSettingObj();
  }

  ngOnInit(): void {


  }
  async generalSettingObj() {
    let snap = await this.socialMediaService.ejsTemplateContent();
    this.ejsTemplateContent = snap.docs[0].data();
  }
  async getAllPost() {
    let snap = await this.socialMediaService.getAllPost();
    this.allPost = snap.docs.map(doc => doc.data());
  }
  async getTemplateData() {
    let snapshot = await this.socialMediaService.getAllTemplates();
    console.log(snapshot.docs.map(doc => doc.data()));
    this.templateData = snapshot.docs.map(doc => doc.data())
    let count = 0;
    this.templateData.forEach(template => {
      if (template.type == 'FAQ') {
        count++;
        this.faqTemplates.push(template)
      }
      // console.log(this.faqTemplates);
    });
    if (count > 0) {
      this.noFaqTemplate = false;
    }

  }
  loadData(event) {
    console.log("emmiter called")
    this.getTemplateData();
  }
  openConfig() {
    this.postConfig = true;
    this.settingFlag = false;
    this.allPostflag = false;
  }
  backBtn(event) {
    this.postConfig = false;
    this.settingFlag = true;
    this.allPostflag = true;
  }
  async generatePost() {
    console.log("generate posts");
    const dialogRef = this.dialog.open(AddNewPostDailogComponent, {
      width: '1200px',
      disableClose: true,
      height: '580px',
      data: {
        // data: this.templateNames,
        popupHeader: "Generate Social Media Post",
        templates: this.faqTemplates,
        // templateNames: this.templateNames,
        type: "CREATE",
        TemplateFlag: this.noFaqTemplate
      },
      panelClass: 'custom-dialog-container'

    });

    dialogRef.componentInstance.addPost.subscribe(async (obj: { url: string; template: any; }) => {
      console.log(obj);
      (await this.socialMediaService.generatePost(obj.url, obj.template)).subscribe(resp => {
        if (resp.status == 'success') {
          console.log("post are generated..");
          this.getAllPost();
          setTimeout(() => {
            // this.dialogRef.close({ status: "success" });
          }, 2000);
        } else {
        }
        dialogRef.close();
      })
    })
  }
  deletePost(post) {
    // this.socialMediaService.deletePost(post);
    const dialogRef = this.dialog.open(DeleteTemplateComponent, {
      width: '600px',
      disableClose: true,
      height: '400px',
      data: {
        data: post,
        popupHeader: "Are you sure you want to delete this post?",
        type: "DELETE_POST"
      },
      panelClass: 'custom-dialog-container'

    });
    dialogRef.afterClosed().subscribe(async resp => {
      this.getAllPost();
      console.log(resp);
    });
  }
  downloadPost(post) {
    var d = new Date();
    var n = d.getTime();
    let fileName = n + " " + "faq.png";
    this.socialMediaService.downloadPost(post.postUrl, fileName);
  }
  // getTemplate($event) {
  //   console.log("get new template")
  //   this.getTemplateData();
  // }
  updatePost(post) {
    console.log("update post");
    const dialogRef = this.dialog.open(UpdatePostComponent, {
      width: '1200px',
      disableClose: true,
      height: '580px',
      data: {
        post: post,
        popupHeader: "Edit Social Media Post",
        templateData: this.templateData,
        templateObj: this.ejsTemplateContent,
        // templateNames: this.templateNames,
        type: "UPDATE",
      },
      panelClass: 'custom-dialog-container'

    });
    dialogRef.afterClosed().subscribe(async resp => {
      if (resp.status == 'success') {
        this.getAllPost();
      }
    });
  }
  getTemplate(event) {
    this.getTemplateData();
  }
}


