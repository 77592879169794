import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-processing',
  templateUrl: './signup-processing.component.html',
  styleUrls: ['./signup-processing.component.scss']
})
export class SignupProcessingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
