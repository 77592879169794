/*
 * The test data source for BIC responses
 */

import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable ,  BehaviorSubject ,  of } from "rxjs";
import { CollectedFormsService } from "./collected-forms.service";
import { catchError, finalize } from "rxjs/operators";

export class ResponsesDataSource implements DataSource<any> {

    public responses: any = [];

    private responsesSubject = new BehaviorSubject<any[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private collectedFormsService: CollectedFormsService) {

    }

    getResponses(sortDirection: string, pageIndex: number, pageSize: number) {

        this.loadingSubject.next(true)

        if(this.collectedFormsService.pageSize !== undefined && this.collectedFormsService.pageSize !== pageSize) {
            this.collectedFormsService.resetPageEndpoints();
        }

        if(this.collectedFormsService.sortDirection !== sortDirection) {
            this.collectedFormsService.sortDirection = sortDirection;
            this.collectedFormsService.resetPageEndpoints();
        }

        this.collectedFormsService.getResponses(pageIndex, pageSize)
        .then(
            responses_snapshot => {
                let docsCount = responses_snapshot.size;

                this.responses = [];

                responses_snapshot.forEach(
                    response_snapshot => {
                        let response = response_snapshot.data();
                        response.email_opt_in = response.email_opt_in === true ? 'Yes' : 'No';
                        response.created_on = response.created_on.toDate();
                        this.responses.push(response);
                    }
                );

                // If this is the first time we have fetched this page
                if(pageIndex === this.collectedFormsService.pagesFetched) {
                    this.collectedFormsService.setPageEndpoints(
                        responses_snapshot.docs[0],
                        responses_snapshot.docs[docsCount - 1]
                    );
                }

                this.loadingSubject.next(false);

                this.responsesSubject.next(this.responses);

                console.log("Responses fetched!");
            },
            error => {
                this.loadingSubject.next(true);
                this.responsesSubject.next([]);
                console.error(error);
            }
        );

    }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        console.log("Connecting data source");
        return this.responsesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.responsesSubject.complete();
        this.loadingSubject.complete();
    }

}
