import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'jquery';
import { ReviewsService } from './../_services/reviews.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import * as moment from 'moment';


interface City {
  name: string,
  code: string
}
@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  moment: any = moment;
  reviews: any = [];
  reviewData: any;
  rows = 20;
  first = 0;
  content: any;
  hardcodedrating: number = 3.5;
  totalReviews: number = 0;
  reviewsSource: any = [
    { "name": "google" },
    { "name": "facebook" }
  ];
  reviewTimeSortTime: any = [
    { "type": "All Time" },
    { "type": "Yesterday" },
    { "type": "Past 7 days" },
    { "type": "past month" },
    { "type": "past 2 month" },
    { "type": "past 3 month" },
    { "type": "past 6 month" },
    { "type": "This Year" },
    { "type": "custom" },
  ];
  reviewSortOnRating:any = [
    {"type":"Recent reviews"},
    {"type":"Oldest reviews"},
    // {"type":"Highest rated"},
    // {"type":"Lowest rated"},
  ]
  selectedReviewSource: string = "";
  selectedReviewAction: string = "";
  selectedReviewRecent: string = "";
  selectedReviewTime: string = "";
  pagingInfo: any = {};
  fromDate: Date;
  toDate: Date;
  selectedReviewTimeData: any;
  isReviewReady: boolean = false;
  isReady: boolean = false;
  noReviews: boolean = false;
  constructor(
    private reviewsService: ReviewsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }
  ngOnInit() {

    this.pagingInfo['limit'] = 10;
    this.pagingInfo['offset'] = 0;
    // this.reviewMetaData();
    this.getReivews();
  }
  async getReivews() {


    //console.log(strToNum)
    let reviews = this.reviewsService.getReviews();
    // console.log(this.pagingInfo);
    // let reviews = this.reviewsService.getReviews();
    // console.log((await reviews).docs.map(doc => doc.data()));
    // this.reviews = await (await reviews).docs.map(doc => doc.data());
    // console.log(this.reviews);
    let response = await this.reviewsService.getOverallReviews(this.pagingInfo);
    if (response['ref']) {
      this.reviews = await response['ref'].docs.map(doc => doc.data())
      this.totalReviews = await response['totalReviews'];
      this.isReviewReady = true;
      this.isReady = true;
      console.log(this.totalReviews);
      // console.log(this.totalReviews);
    }
    else{
      this.noReviews= true
    }
  }
  // async reviewMetaData() {
  //   let data = await this.reviewsService.getReviewMetaData();
  //   console.log(data.data());
  //   this.reviewData = data.data();

  // }
  //Here we taking review from the firebase.
  showMoreContent(content) {
    content.show = !content.show;
  }
  paginate(event) {
    console.log(event);
    this.rows = event.rows;
    this.pagingInfo['limit'] = this.rows;
    this.pagingInfo['offset'] = this.rows * event.page;
    this.getReivews();
  }

  async getDropdownValue(event, type) {
    this.isReady = false;
    console.log(event, type);
    if (type == 'source') {
      this.pagingInfo['source'] = event.name;

    }
    else if (type == 'time') {
      this.selectedReviewTimeData = this.selectedReviewTime['type'].toLowerCase();
      let monthOrDays;
      if ((this.selectedReviewTimeData.match(/\d+/) != null))
        monthOrDays = this.selectedReviewTimeData.match(/\d+/)[0];
      else
        monthOrDays = 1;

      if (this.selectedReviewTimeData.includes('past') && this.selectedReviewTimeData.includes('days')) {
        this.toDate = moment().toDate();
        this.fromDate = moment().subtract(monthOrDays, 'days').toDate();

      }
      else if (this.selectedReviewTimeData.includes('past') && this.selectedReviewTimeData.includes('month')) {
        this.fromDate = moment().subtract(monthOrDays, 'month').startOf('month').toDate();
        this.toDate = moment().subtract(0, 'month').endOf('month').toDate();
      }
      else if (this.selectedReviewTimeData.includes('yesterday')) {
        this.fromDate = moment().subtract(1, 'days').startOf('day').toDate()
        this.toDate = moment().subtract(1, 'days').endOf('day').toDate();
      }
      else if (this.selectedReviewTimeData.includes('year')) {
        this.toDate = moment().subtract(1, 'year').toDate();
        this.fromDate = moment().subtract(0, 'year').toDate();
      }
      if (type != "All Time") {
        console.log(this.fromDate, this.toDate);
        this.pagingInfo['compareTime'] = {
          'from': this.fromDate,
          'to': this.toDate
        }
      }

    }
    else if (type == 'action') {
      // if(this.selectedReviewAction == "Recent reviews"){
      //     this.pagingInfo['sortType'] = 'asc';
      // }
      // if(this.selectedReviewAction == "Oldest reviews"){
      //   this.pagingInfo['sortType'] = 'desc';
      // }
      // if(this.selectedReviewAction == "Highest rated"){
      //   this.pagingInfo['rating'] = 'highest';
      // }
      // if(this.selectedReviewAction == "Lowest rated"){
      //   this.pagingInfo['rating'] = 'lowest';
      // }
    }
    else if (type == 'recent') {
      console.log(this.selectedReviewRecent);
      if (this.selectedReviewRecent['type'] == "Recent reviews") {
        this.pagingInfo['sortType'] = 'desc';
      }
      if (this.selectedReviewRecent['type'] == "Oldest reviews") {
        this.pagingInfo['sortType'] = 'asc';
      }
      else if(type == 'recent'){
        console.log(this.selectedReviewRecent);
        if(this.selectedReviewRecent['type'] == "Recent reviews"){
          this.pagingInfo['sortType'] = 'desc';
        }
        if(this.selectedReviewRecent['type'] == "Oldest reviews"){
          this.pagingInfo['sortType'] = 'asc';
        }
        // if(this.selectedReviewRecent['type'] == "Highest rated"){
        //   this.pagingInfo['rating'] = 'highest';
        // }
        // if(this.selectedReviewRecent['type'] == "Lowest rated"){
        //   this.pagingInfo['rating'] = 'lowest';
        // }
      }
    }
    this.getReivews();
  }

}
