<div id="msgBox" #msgBox class="scrolling-wrapper-flexbox" [scrollTop]="msgBox.scrollHeight">
    <div id="msgs">
        <div #msg *ngFor="let message of messages ;let last = last;let i = index">
            <div *ngIf="message.displayType =='MENU'">
                <div class="d-flex justify-content-end" style="padding: 1%;"
                    [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')}">
                    <app-menu [payload]="message"></app-menu>
                </div>
            </div>
            <!-- <div *ngIf="message.displayType =='CUSTOM'">
                <app-message-presenter [payload]="message"></app-message-presenter>
            </div> -->
            <div *ngIf="message.sentBy === 'AGENT'"
                style="margin: 0;padding: 2px;position: relative;text-align: center;">
                <div class="" style="display: flex;justify-content: center;color: rgb(146, 143, 143);">
                    <hr style="width:100%">
                    <p style="font-weight: bold;white-space: nowrap; margin:auto 10px;font-size: 11px;"
                        [innerHTML]="message.data">
                        <!-- {{message.data}} -->
                    </p>
                    <hr style="width: 100%">
                </div>
            </div>
            <div *ngIf="message.sentBy === 'USER'">
                <div class="message d-flex justify-content-end" [ngClass]="{'to': message.sentBy === 'USER' }"
                    [innerHTML]="message.data">
                </div>
            </div>
            <!-- <div *ngIf="message.displayType =='TYPING' && last">
                <div class="message d-flex justify-content-end"
                    [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR') }">
                    {{ message.data }}
                </div>
            </div> -->
            <div *ngIf="message.displayType=='BUTTON' && (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')">
                <div class="d-flex justify-content-end" style="padding: 1%;"
                    [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')}">
                    <app-button [payload]="message"></app-button>
                </div>
            </div>
            <div *ngIf="message.displayType=='CARD' && (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')">
                <app-card-carousel [payload]="message">
                </app-card-carousel>
            </div>
            <div *ngIf="message.displayType=='IFRAME' && (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')">
                <iframe width="300" height="200" frameborder="1" [src]="transform(message.data)"></iframe>
            </div>
            <div *ngIf="message.displayType=='TEXT' && (message.sentBy === 'BOT' || message.sentBy =='DOCTOR')"
                class="d-flex justify-content-end">
                <div class="message " [ngClass]="{'from': (message.sentBy === 'BOT' || message.sentBy =='DOCTOR') }"
                    [innerHTML]="message.data">
                </div>
            </div>
        </div>
        <!-- </ng-container> -->
    </div>
</div>