<div class="container-fluid view">
  <div *ngIf="!confirmSend">
    <h4 style="text-align : center;"><strong>Send SMS</strong></h4>
    <div class="close" (click)="close()">X</div>
    <div class="msg">
      <p><strong>To :
          <span *ngFor="let recipient_number of twilio_to">
            {{recipient_number}},
          </span>
        </strong></p>
      <p style="padding-bottom : 0px;"><strong>Message</strong></p>
    </div>
    <!--<div class="row">
    <mat-list>
      <mat-list-item><p><strong>To : {{sendTo}} ({{twilio_to}})</strong></p></mat-list-item>
      <mat-list-item><p><strong>Message :</strong></p></mat-list-item>
    </mat-list>
<!--<div class="col-md-8"><p></p></div>-->
    <!--</div>-->
    <div *ngIf="showTemplates" class="templates">
      <p> Choose a predefined template or <a (click)="customText()"><u style="color:blue;">write your own custom
            message</u></a></p>
      <div class="col-md-12 text-center">
        <mat-form-field>
          <mat-select name="msg" [(ngModel)]="selectedMsg" placeholder="Select">
            <mat-option class="options" *ngFor="let msg of messages" [value]="msg">{{msg}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12 text-center">
        <div *ngIf="selectedMsg">
          <mat-form-field>
            <textarea matInput #myText [(ngModel)]="selectedMsg" required>{{selectedMsg}}</textarea>
            <!--#myText (click)="getCaretPos(myText)" (keyup)="getCaretPos(myText)"-->
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="customTextBox" class="customMsg">
      <p>Write your own custom message or <a (click)="showTemplate()"><u style="color:blue;">choose a predefined
            template</u></a></p>
      <mat-form-field style="padding: 0;">
        <textarea matInput placeholder="Enter your message here" [(ngModel)]="selectedMsg"></textarea>
      </mat-form-field>
      <!-- <div *ngIf="languages.length" style="margin-top: -10px;">
        Select language
        <mat-form-field style="width:120px;padding:0px;margin:0 0 0 20px">
          <mat-label>Language</mat-label>
          <mat-select [(value)]="selectedLanguage" (selectionChange)="selectLanguage($event)">
            <mat-option *ngFor="let code of languages" [value]="code.value">
              {{code.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>

    <div class="col-md-6">
      <div class="row" *ngIf="languages.length" style="margin-top: -10px;">
        Select language
        <mat-form-field style="width:120px;padding:0px;margin:0 0 0 20px">
          <mat-label>Language</mat-label>
          <mat-select [(value)]="selectedLanguage" (selectionChange)="selectLanguage($event)">
            <mat-option *ngFor="let code of languages" [value]="code.value">
              {{code.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-checkbox name="sendLink" [(ngModel)]="sendLink">Send calendar link</mat-checkbox>
        <div *ngIf="sendLink">
          <mat-radio-group [(ngModel)]="calendarLink">
            <mat-radio-button [value]="link" *ngFor="let link of links">{{link}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row">
        <mat-checkbox name="Date" [(ngModel)]="apptDate" [disabled]="isApptDate">Include Date & time</mat-checkbox>
        <div *ngIf="apptDate">
          <div class="row">
            <!--<mat-form-field style="width:50%;padding-left:5px;">
        <input matInput placeholder="Select Date" type="date" name="date" value="seldate" [(ngModel)]="selectedDate"/>
      </mat-form-field>-->
            <mat-form-field class="dateFields">
              <input matInput [(ngModel)]="selectedDate" [min]="minDate" [matDatepicker]="picker"
                placeholder="Choose a date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false" [opened]=false></mat-datepicker>
            </mat-form-field>
            <!--  <mat-form-field class="dateFields"><!--style="width:30%;padding-left:5px;padding-right:5px;">-->
            <!--  <input matInput placeholder="Select start-time" type="time" name="appt" min="8:00" max="20:00" step="1800" [(ngModel)]="startTime">
      </mat-form-field>-->
            <mat-form-field class="dateFields">
              <mat-select name="workingTime" [(ngModel)]="startTime" placeholder="Choose a time"
                (click)="populateTime()">
                <mat-option class="options" *ngFor="let workingTime of workingTimes;" [value]="workingTime">
                  {{workingTime}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!--<div class="row">
    <mat-form-field style="width:30%;padding-left:5px;padding-right:5px;">
      <input matInput placeholder="Select start-time" type="time" name="appt" [(ngModel)]="startTime">
      </mat-form-field>
      <mat-form-field style="width:30%;padding-left:5px;">
      <input matInput placeholder="Select end-time" type="time" name="appt" [(ngModel)]="endTime">
    </mat-form-field>
    </div>-->
          <button mat-raised-button color="primary" (click)="insertDate(selectedMsg, selectedDate, startTime)">Add date
            & time</button>
        </div>
      </div>
      <!--
  <div class="row">
    <mat-checkbox name="Date" [(ngModel)]="isApptDate" [disabled]="apptDate">Include appointment date & time</mat-checkbox>
    <div *ngIf="isApptDate">
      <div *ngIf="isAppt; else NoAppt">
        <p>This will include the latest booked appointment schedule.</p>
        <p>{{dateOfAppt}} {{timeOfAppt}}</p>
        <button mat-raised-button color="primary" (click)="insertDate(selectedMsg, dateOfAppt, timeOfAppt)">Add</button>
      </div>
    <ng-template #NoAppt>
      <p>No appointments to select appointment time</p>
    </ng-template>
    </div>
  </div>
  -->
      <div class="row">
        <mat-checkbox name="optOut" [(ngModel)]="optOut">Send option to opt-out from SMS</mat-checkbox>
      </div>
      <div class="row" ng-if="googleLink">
        <mat-checkbox name="googleReview" [(ngModel)]="googleLink">Send Google review link </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <button mat-raised-button color="primary" type="submit" (click)="confirm()"
          [disabled]="!selectedMsg">Next</button>
      </div>
    </div>
  </div>

  <!---Confirmation window ------>
  <div *ngIf="confirmSend" class="view">
    <h4 style="text-align : center;"><strong>Review SMS</strong></h4>
    <div class="close" (click)="close()">X</div>
    <div class="msg" style="margin-top : 80px;">
      <p><strong>To :
          <span *ngFor="let recipient_number of twilio_to">
            {{recipient_number}},
          </span>
        </strong></p>

      <p class="border-box">{{displayMsg}}</p>
      <p *ngIf="selectedLanguage!='en'" style="color: red;font-size: 10px;margin:-7px 0 3px 10px;padding: 0;">
        Message will be sent in {{selectedLanguageName}}</p>
      <!--  <p *ngIf="sendLink">Selected link : {{calendarLink}}</p>-->
      <div class="col-md-12 text-center">
        <button class="confirm" mat-raised-button color="primary" type="submit" (click)="sendMessage()">Send</button>
        <button mat-raised-button color="primary" (click)="goBackToSendSMS()">Change</button>
      </div>
    </div>
  </div>
  <!---Confirmation window ------>
</div>