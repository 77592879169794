import { Component, OnInit } from "@angular/core";
import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";

@Component({
  selector: "app-sub-header",
  templateUrl: "./sub-header.component.html",
  styleUrls: ["./sub-header.component.scss"],
})
export class SubHeaderComponent implements OnInit {
  state: NavigationState;

  constructor(private navigationService: NavigationService) {
    navigationService.state$.subscribe((state) => {
      this.state = state;
    });
  }

  ngOnInit() {
    this.state = new NavigationState(false, "", "", "#", "/main");
  }
}
