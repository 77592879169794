<div class="close" (click)="close()">X</div>
<div class="contanier-fluid view" *ngIf="enableDetailsOfMerged">
  <h4>Merge Contact</h4>
  <br><br>
  <form #contactData="ngForm" (ngSubmit)="saveContact(contactData)" name='contactForm'>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="example-full-width cursor">
          <input type="text" placeholder="Enter First Name" aria-label="First Name" matInput class="form-control"
            id="name" required [matAutocomplete]="auto1" name="first_name" [(ngModel)]="first_name" #name="ngModel">
          <!-- <input type="text" placeholder="Enter First Name" aria-label="First Name" matInput
              [formControl]="firstNameControl" [matAutocomplete]="auto1" name="first_name" [(ngModel)]="first_name"> -->
          <mat-autocomplete #auto1="matAutocomplete">
            <mat-option *ngFor="let first_name of firstNameArray" [value]="first_name">
              {{first_name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <input type="text" placeholder="Enter Last Name" aria-label="Last Name" matInput class="form-control"
            [matAutocomplete]="auto2" name="last_name" [(ngModel)]="last_name" id="lastName" required>
          <mat-autocomplete #auto2="matAutocomplete">
            <mat-option *ngFor="let last_name of lastNameArray" [value]="last_name">
              {{last_name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <br>

    <div class="row">
      <div class="col">
        <h6>Phone</h6>
        <p-table #dt1 [value]="phoneData" [scrollable]='true'>
          <ng-template pTemplate="body" let-phoneInfo let-i="rowIndex">
            <tr>
              <td style="width:30%" id="table-data">
                <mat-form-field class="example-full-width">
                  <mat-label>Country</mat-label>
                  <mat-select class="form-control" [id]="'countryCode_'+i" required [(ngModel)]="phoneInfo.countryCode"
                    [name]="'countryCode_'+i" placeholder="Country code" [value]="phoneInfo.countryCode"
                    (selectionChange)="setCountryCode($event.value,i)">
                    <mat-option *ngFor="let country of countryCodes" [value]="country.code">
                      {{ country.code }} ({{country.name}})
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td style="width:40%" id="table-data">
                <mat-form-field class="example-full-width">
                  <input class="form-control" [id]="'phone_'+i" required type="text" placeholder="Enter Phone"
                    aria-label="Phone" matInput [(ngModel)]="phoneInfo.phone" [name]="'phone_'+i" pattern="^[0-9]{10}$">
                </mat-form-field>
              </td>

              <td style="width:20%" id="table-data">
                <button pButton type="button" *ngIf="!phoneInfo.primary else showPrimary" label="Make Primary"
                  (click)="makePhonePrimary(phoneInfo)">
                </button>
                <ng-template #showPrimary>
                  Primary
                </ng-template>
                <!-- <mat-checkbox name="phoneData.primary" [(ngModel)]="phoneData.primary" style="padding-left: 30%;" (change)="makePhonePrimary($event,phoneData)" #e></mat-checkbox> -->
              </td>
              <td style="width:10%" id="table-data">
                <div *ngIf="phoneData.length > 1">
                  <i class="material-icons add" [value]="phoneData" style="padding-left: 30%;cursor: pointer;"
                    (click)="deletePhoneInfo(phoneInfo)">delete</i>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <br>
      </div>
    </div>
    <button pButton type="button" icon="pi pi-plus" label="Add Phone" (click)="newPhoneInfoRow()"></button>
    <br>
    <br>

    <div class="row">
      <div class="col">
        <h6>Email</h6>
        <p-table [value]="emailData">
          <ng-template pTemplate="body" let-emailInfo let-i="rowIndex">
            <tr>
              <td style="width:70%" id="table-data">
                <mat-form-field class="example-full-width">
                  <input type="text" placeholder="Enter Email Id" aria-label="email" matInput class="form-control"
                    [id]="'email_'+i" required [(ngModel)]="emailInfo.email" [name]="'email_'+i"
                    [value]="emailInfo.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
                </mat-form-field>
              </td>

              <td style="width:20%" id="table-data">
                <button pButton type="button" *ngIf="!emailInfo.primary else showPrimary" label="Make Primary"
                  (click)="makeEmailPrimary(emailInfo)">
                </button>
                <ng-template #showPrimary>
                  Primary
                </ng-template>
              </td>

              <td style="width:10%" id="table-data">
                <div *ngIf="emailData.length > 1">
                  <i class="material-icons add" style="padding-left: 30%;cursor: pointer;" [value]="emailInfo"
                    (click)="deleteEmailInfo(emailInfo)">delete</i>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <br>
    <button pButton type="button" icon="pi pi-plus" label="Add Email" (click)="newEmailInfoRow()"></button>
    <br>
    <br>

    <div class="col">
      <div style="padding-top: 10px;padding-bottom: 10px;">
        <span style="display: inline;padding-right: 10px;">Send Text Reminder</span>
        <mat-slide-toggle name="send_text_reminders" [(ngModel)]="send_text_reminders">
        </mat-slide-toggle>
      </div>
      <div class="row-6">
        <mat-checkbox name="isMergedAppointment" [(ngModel)]="isMergedAppointment" (change)="mergedAppointmnet($event)">
          Merge Appointment</mat-checkbox>
      </div>
      <div class="row-6">
        <mat-checkbox name="isMergedNotes" [(ngModel)]="isMergedNotes" (change)="mergedNotes($event)">Merge Notes
        </mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <button mat-raised-button type="submit" [disabled]="!contactData.valid">Merge contact</button>
      </div>
    </div>
  </form>
</div>

<div *ngIf="loading else loaded" style="text-align: center;">
  <mat-spinner class="center" mode="indeterminate"></mat-spinner>
  <span>Merging Contacts...</span>
</div>


<ng-template #loaded>
  <div *ngIf="mergedContactData!=undefined">
    <h4>Merged Contact</h4>
    <div style="padding-bottom: 5%;">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{selectedContacts.length}} Contacts Merged into new contact with
            {{mergedContactData?.appointmentCount}} appointments and {{mergedContactData?.notesCount}} notes.
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="container-fluid view" *ngIf="selectedContacts!=undefined" style="min-height: auto;">
            <div class="mat-elevation-z8" style="margin:20px;">
              <p-table [value]="selectedContacts" [columns]="columns" #console [autoLayout]="true"
                [resizableColumns]="true">

                <ng-template pTemplate="header">
                  <tr>
                    <th [class]="col.class" *ngFor="let col of columns" pResizableColumn>
                      {{col.header|titlecase}}
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-newData>
                  <tr>
                    <td class="mid">{{newData.first_name}}</td>
                    <td class="mid">{{newData.last_name}}</td>
                    <td class="mid">{{newData.email}}</td>
                    <td class="mid">{{newData.phone}}</td>
                    <td class="mid">{{newData.active?'Yes':'No'}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div>
      <mat-card>
        <mat-card-header>
          <mat-card-title>New Contact</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <table class="table" *ngIf="mergedContactData!=undefined">
            <tbody>
              <tr>
                <th scope="row">First Name</th>
                <td>{{mergedContactData.newContact.first_name}}</td>
              </tr>

              <tr>
                <th scope="row">Last Name</th>
                <td>{{mergedContactData.newContact.last_name}}</td>
              </tr>

              <tr>
                <th scope="row">Email</th>
                <td>{{mergedContactData.newContact.email}}</td>
              </tr>

              <tr>
                <th scope="row">Phone</th>
                <td>{{mergedContactData.newContact.phone}}</td>
              </tr>
              <tr>
                <th scope="row">Text Reminder</th>
                <td>
                  {{mergedContactData.newContact.send_text_reminders == true? 'Yes':'No'}}
                </td>
              </tr>

              <tr>
                <th scope="row">Last Visited</th>
                <td>{{mergedContactData.newContact.lastvisited|date}} </td>
              </tr>

              <tr>
                <th scope="row">Contact Created On</th>
                <td>{{ mergedContactData.newContact.created_on| date: "MMM d, yyyy" }}</td>
              </tr>
              <tr>
                <th scope="row">Active</th>
                <td> {{mergedContactData.newContact.active ==true ? 'Yes':'No'}}</td>
              </tr>
            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>