/*
 * Used in legacy code
 */

import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable ,  Subject } from 'rxjs';
import { map } from 'rxjs/operators';
//import { toPromise } from 'rxjs';
import {TrafficSummary } from '../_models/index';
import {DeviceBreakout} from '../_models/index';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {

    site: any;
    private tacticsSource = new Subject<any>();
    tactics$ = this.tacticsSource.asObservable();

    constructor(private http: Http,
    private router: Router) { }

    setTacticsInput(input: any) {
      this.tacticsSource.next(input);
    }

   fetchTactics(input: any):Promise<any> {

      //return this.http.get('assets/json/tactics0.json')
        //.map( response => response.json() );
      /*
      let user = JSON.parse(localStorage.getItem('user'));
      let uuid = user.uuid;
      let req = JSON.stringify({"seed": "solar panels", "targeting_specs": {"uuid": uuid, "objective": "REACH", "targeting_info": {"country": "US", "age": {"min": 18, "max": 50}, "gender": [1, 2]}}});
      */
      let req = JSON.stringify(input);
      return this.http.post(`${environment.serverURL}/get-marketing-estimates.php`, req)
      .toPromise()
      .then(response => response.json());
    }

    saveSpecifics(input: any) {
      let req = JSON.stringify(input);
      return this.http.post(`${environment.serverURL}/saveDurationSpend.php`, req);
    }

    fetchSummary(url: string)
      {
         return this.http.post(`${environment.serverURL}/traffic_summary_response.php`, url).pipe(
              map( response => <TrafficSummary>response.json()));
      }

      fetchCompetitors() {
        return this.http.get('assets/json/competitors.json').pipe(
                        map( response => response.json().competitors ));
      }

      fetchSites()
      {
      return this.http.get(`${environment.serverURL}/similar_sites_response.php`).pipe(
           map( response => response.json()));
      }

      fetchDevice():Promise<any>
        {
           return this.http.get(`${environment.serverURL}/device_response.php`)
           .toPromise()
           .then(response =><DeviceBreakout>response.json());
                //.map( response => response.json());
        }

      fetchTrends():Promise<any>
        {
            return this.http.get(`${environment.serverURL}/traffic_trends_response.php`)
            .toPromise()
            .then(response => response.json());

        }

//---------------------------for doughnut chart
      fetchGeoTraffic():Promise<any>
        {
            return this.http.get(`${environment.serverURL}/geo_response.php`)
            .toPromise()
            .then(response => response.json());
        }

  //---------------------------for trial bar chart

        fetchTrendsfortrialbarchart()
          {
          return this.http.get('assets/json/trial.json').pipe(
          map( response => response.json() ));

          }
  //---------------------------for line chart

           fetchTrendsforlinechart()
             {
             return this.http.get('assets/json/competitors1.json').pipe(
             map( response => response.json() ));

             }
  //close for line chart
}
