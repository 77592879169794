<!-- <div class="row" style="background-color: none;">
    <div style="margin:5px auto 10px;font-size: 20px;font-weight: 500;">Reviews</div>
</div>
<app-reviews-operation></app-reviews-operation> -->

<!-- <div class="container-fluid view"> -->
<!-- <div class="row" id="subHeader1">
    <div style="margin:5px auto 10px;font-size: 20px;font-weight: 500;text-align: center;">Reviews</div>
</div>
<div class="row" id="subHeader2" style="width: 100%;">
    <app-reviews-operation></app-reviews-operation>
</div> -->
<!-- </div> -->

<app-reviews-operation></app-reviews-operation>