import { Component, Input, OnInit, Output, EventEmitter, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-data-channels-information',
  templateUrl: './data-channels-information.component.html',
  styleUrls: ['./data-channels-information.component.scss']
})
export class DataChannelsInformationComponent implements OnInit {

  @Input() accountConfigurationDetails;
  @Output() backEmittor = new EventEmitter<any>();
  @Output() saveEmittor = new EventEmitter<any>();
  @Output() editEmittor = new EventEmitter<any>();
  @Output() editInfoEmittor = new EventEmitter<any>();
  @Output() analyticsEmittor = new EventEmitter<any>();

  selectedGmbAccounts: any = []
  gmbAccounts: any = [];
  accountDetails: any = [];
  accountsInformation: any = [];
  gmbAccountsDetails: any = [];
  isSave: boolean = false;
  analyticsObject: any = {
    "analytics_info": {
      "account": null,
      "property": null,
      "view": null
    }
  };
  waitTillAccountFetch: boolean = false;
  waitTillPropertiseFetch: boolean = false;
  waitTillViewsFetch: boolean = false;
  selectedAnalyticsAcc: any;
  selectedProperty: any;
  selectedView: any;
  analyticsData: any = [];
  analyticsAccount: any = [];
  analyticsPropertise: any = [];
  analyticsViews: any = [];
  saveAnalytics: boolean = false;

  ngOnChanges(changes: SimpleChange) {
    this.accountConfigurationDetails = changes['accountConfigurationDetails']['currentValue'];
    if ('analyticsAccount' in this.accountConfigurationDetails) {
      this.analyticsAccount = this.accountConfigurationDetails['analyticsAccount'];
    }
    else if ('analyticsPropertise' in this.accountConfigurationDetails) {
      this.analyticsPropertise = this.accountConfigurationDetails['analyticsPropertise'];
      this.waitTillPropertiseFetch = false;
    }
    else if ('analyticsViews' in this.accountConfigurationDetails) {
      this.analyticsViews = this.accountConfigurationDetails['analyticsViews'];
      this.waitTillViewsFetch = false;
    }
    //console.log(this.accountConfigurationDetails);
  }

  constructor() { }

  ngOnInit(): void {
    this.accountsInformation = this.accountConfigurationDetails['accountsInformation'];
    if (this.accountConfigurationDetails['type'] == "gmb") {
      //console.log(this.accountConfigurationDetails);
      this.selectedGmbAccounts = this.accountConfigurationDetails['selectedAccounts'];
      for (var i = 0; i < this.accountConfigurationDetails['accountDetails']['locations'].length; i++) {
        if (this.selectedGmbAccounts.findIndex(ele => ele['name'] == this.accountConfigurationDetails['accountDetails']['locations'][i]['name'] && ele['locationName'] == this.accountConfigurationDetails['accountDetails']['locations'][i]['title']) >= 0) {
          this.accountConfigurationDetails['accountDetails']['locations'][i]['checked'] = true;
        }
        else {
          this.accountConfigurationDetails['accountDetails']['locations'][i]['checked'] = false;
        }
      }
      this.gmbAccountsDetails = this.accountConfigurationDetails['accountDetails']['locations'];
      this.accountDetails = this.accountConfigurationDetails['accountDetails'];
      this.gmbAccounts = this.accountConfigurationDetails['selectedAccounts'].filter(ele => ele);
      //console.log(this.gmbAccountsDetails);
      //console.log(this.gmbAccounts);

    }
    else if (this.accountConfigurationDetails['type'] == "analytics") {
      this.accountDetails = this.accountConfigurationDetails['accountDetails'];

      //console.log(this.accountConfigurationDetails);
      if ('analyticsAccount' in this.accountConfigurationDetails) {
        this.analyticsAccount = this.accountConfigurationDetails['analyticsAccount'];
      }
      else if ('analyticsPropertise' in this.accountConfigurationDetails) {
        this.analyticsPropertise = this.accountConfigurationDetails['analyticsPropertise'];
      }
      else if ('analyticsViews' in this.accountConfigurationDetails) {
        this.analyticsViews = this.accountConfigurationDetails['analyticsViews'];
      }
    }
  }

  deleteGmbAccount(account, index) {
    this.gmbAccounts.splice(this.gmbAccounts.indexOf(account), 1);
    this.isSave = true;
  }

  saveGmbInformation() {
    this.saveEmittor.emit(this.gmbAccounts);
  }

  addAnotherAccount() {
    this.accountConfigurationDetails['screen'] = "operation";
    let editAccountObject = {
      "analyticsRefreshToken": null,
      "type": null
    }
    if (this.accountConfigurationDetails['type'] == "gmb") {
      editAccountObject["gmb_refresh_token"] = this.accountConfigurationDetails['accountDetails'][0]['gmb_refresh_token'];
      editAccountObject["type"] = this.accountConfigurationDetails['type']
    }
    if (this.accountConfigurationDetails['type'] == "analytics") {
      editAccountObject["analyticsRefreshToken"] = this.accountConfigurationDetails['accountDetails'][0]['analytics_refresh_token'];
      editAccountObject["type"] = this.accountConfigurationDetails['type']
    }
    this.editEmittor.emit(editAccountObject);
  }

  editAccountInformation() {
    let body;
    if (this.accountConfigurationDetails['type'] == 'gmb') {
      body = {
        "type": 'gmb',
        "gmbData": this.gmbAccounts
      };
    }
    else if (this.accountConfigurationDetails['type'] == 'analytics') {
      body = {
        "type": 'analytics',
        "analyticsdata": this.analyticsObject
      };
    }
    this.editInfoEmittor.emit(body);
  }

  analyticsAccountConfiguration(type) {
    if (type == 'propertise') {
      let body = {
        "type": "propertise",
        "endpoint": this.selectedAnalyticsAcc['childLink']['href'],
        "analyticsRefreshToken": this.accountConfigurationDetails['analyticsRefreshToken'],
        "isFirstTime": this.accountConfigurationDetails['isFirstTime'],
        "screen": "configure"
      }
      this.analyticsObject['analytics_info']['account'] = this.selectedAnalyticsAcc['name'] + ' ' + this.selectedAnalyticsAcc['id'];
      this.analyticsEmittor.emit(body);
      this.waitTillPropertiseFetch = true;
    }
    else if (type == 'view') {
      let body = {
        "type": "view",
        "endpoint": this.selectedProperty['childLink']['href'],
        "analyticsRefreshToken": this.accountConfigurationDetails['analyticsRefreshToken'],
        "isFirstTime": this.accountConfigurationDetails['isFirstTime'],
        "screen": "configure"
      }
      this.analyticsObject['analytics_info']['property'] = this.selectedProperty['name'] + ' ' + this.selectedProperty['id'];
      this.analyticsEmittor.emit(body);
      this.waitTillViewsFetch = true;
    }
    else if (type == 'save') {
      //console.log(this.selectedView);
      this.analyticsObject['analytics_info']['view'] = this.selectedView['name'] + ' ' + this.selectedView['id'];
      this.saveAnalytics = true;
    }
    //console.log(this.analyticsObject);
  }

  selectAccount(event, location, index) {
    if (event.checked == true) {
      this.gmbAccounts.push(
        {
          "locationName": location['title'],
          "name": location['name'],
          "newReviewUrl": location["metadata"]["newReviewUri"]
        }
      );
    }
    if (event.checked == false) {
      this.gmbAccounts.forEach((value, index) => {
        if (value.name == location['name']) this.gmbAccounts.splice(index, 1);
      });
    }
    //console.log(this.gmbAccounts);
  }

  saveAccount() {
    if (this.accountConfigurationDetails['type'] == 'gmb') {
      let body = {
        "type": 'gmb',
        "gmbData": this.gmbAccounts,
        "gmb_refresh_token": this.accountConfigurationDetails['gmb_refresh_token']
      };
      this.saveEmittor.emit(body);
    }
    else if (this.accountConfigurationDetails['type'] == 'analytics') {
      this.analyticsObject['type'] = "analytics";
      this.analyticsObject['analytics_refresh_token'] = this.accountConfigurationDetails['analyticsRefreshToken'];
      let body = {
        "type": 'analytics',
        "analyticsdata": this.analyticsObject
      };
      this.saveEmittor.emit(body);
    }
  }

  close() {
    this.backEmittor.emit('successs');
  }

  back() {
    this.backEmittor.emit('success');
  }

}
