<div class="row" id="subHeader1">
	<div id="pageTitle" style="text-align: center;">Target Keyword</div>
</div>
<div>
	<mat-tab-group [(selectedIndex)]="selectedIndex" style="background-color: white;">
		<mat-tab label="Results" class="tabWidth" [selectedIndex]="1">
			<app-target-kw-result></app-target-kw-result>
		</mat-tab>

		<mat-tab label="Configuration" class="tabWidth" [selectedIndex]="2">
			<app-target-keywords (selectedTabChange)="checkTabChanged($event)"></app-target-keywords>
		</mat-tab>
	</mat-tab-group>
</div>