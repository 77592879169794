
<!-- <ng-container *ngIf="user;else logOutTemplate"> -->
  <ng-container >
  <div>
    <app-header *ngIf="user" (Onchange)="getChangedValue($event)"></app-header>
    <!-- content body -->
    <mat-sidenav-container   class="example-container " autosize>
      <app-material-side-nav *ngIf="user" class="desktop-only"></app-material-side-nav>
      <mat-drawer *ngIf="user" #drawer class="example-sidenav mobile-view" mode="side">
        <app-mat-drawer-container-menu *ngIf="user" [drawer]="drawer"></app-mat-drawer-container-menu>
      </mat-drawer>
      <div class="example-sidenav-content">
        <div [ngClass]="user?'marginMain':'marginLogout'" id="main">
          <alert></alert>
          <div>
            <!-- <app-navigation *ngIf="
                  router.url !=='/all-forms' &&
                  router.url !=='/mix-input' &&
                  router.url !== '/appointment-stats' &&
                  router.url !== '/appointments' &&
                  router.url !== '/nudgeappointments' &&
                  router.url !== '/contacts' &&
                  router.url !== '/websites' &&
                  router.url !== '/on-page' &&
                  router.url !== '/ranked-keywords' &&
                  router.url !== '/enquiry-forms' &&
                  router.url !== '/account' &&
                  router.url !== '/data-channels' &&
                  router.url !== '/calendar-config' &&
                  router.url !== '/import-history' &&
                  !router.url.includes('/contacts') &&
                  !router.url.includes('/chatbot-message') &&
                  !router.url.includes('/chatbot-settings') &&
                  router.url !== '/target-keywords' &&
                  router.url !== '/allAccounts' &&
                  router.url !== '/add-account' &&
                  router.url !== '/targetKwResult' &&
                  router.url !== '/target-keywords-Details' &&
                  router.url !== '/patient-intake-forms' &&
                  router.url !== '/message' &&
                  router.url !== '/chatbot-message' &&
                  router.url !== '/chatbot-settings' &&
                  router.url !== '/search-console' &&
                  router.url !== '/otp-verification' &&
                  router.url !== '/survey-forms' &&
                  router.url !== '/samples' &&
                  router.url !== '/all-forms' &&
                  router.url !== '/form-upload' &&
                  router.url !== '/marketing-offers'&&
                  router.url !== '/create-offer'&&
                  router.url !== '/interactive-form' &&
                  !router.url.includes('/form-design') &&
                  !router.url.includes('/form-details') &&
                  router.url !== '/preview' &&
                  router.url !== '/data-studio-report' &&
                  !router.url.includes('/enquiry-forms') &&
                  !router.url.includes('/survey-forms') &&
                  !router.url.includes('/reviews')&&
                  router.url !== '/local-rank'
                ">
            </app-navigation> -->

            <!-- <time-period *ngIf="
                  router.url !=='/all-forms' &&
                  router.url !='/mix-input' &&
                  router.url !='/appointment-stats' &&
                  router.url != '/tips' &&
                  router.url != '/settings' &&
                  router.url != '/on-page' &&
                  router.url !== '/ranked-keywords' &&
                  router.url !== '/websites' &&
                  router.url !== '/enquiry-forms' &&
                  router.url !== '/account' &&
                  router.url !== '/data-channels' &&
                  router.url != '/contacts' &&
                  router.url != '/appointments' &&
                  router.url !== '/nudgeappointments' &&
                  router.url !== '/calendar-config' &&
                  router.url !== '/import-history' &&
                  !router.url.includes('/tip-details') &&
                  !router.url.includes('/contacts') &&
                  !router.url.includes('/chatbot-message') &&
                   !router.url.includes('/chatbot-settings') &&
                  router.url !== '/target-keywords' &&
                  router.url !== '/allAccounts' &&
                  router.url !== '/add-account' &&
                  router.url !== '/targetKwResult' &&
                  router.url !== '/target-keywords-Details' &&
                  router.url !== '/patient-intake-forms' &&
                  router.url !== '/message' &&
                  router.url !== '/chatbot-message' &&
                   router.url !== '/chatbot-settings' &&
                  router.url !== '/search-console'  &&
                  router.url !== '/otp-verification'  &&
                  router.url !== '/survey-forms' &&
                  router.url !== '/samples' &&
                  router.url !== '/all-forms' &&
                  router.url !== '/form-upload' &&
                  !router.url.includes('/form-design') &&
                  !router.url.includes('/form-details') &&
                   router.url !== '/interactive-form' &&
                   router.url !== '/marketing-offers'&&
                  router.url !== '/create-offer'&&
                  router.url !== '/preview' &&
                  router.url !== '/data-studio-report' &&
                  !router.url.includes('/enquiry-forms') &&
                  !router.url.includes('/survey-forms') &&
                  !router.url.includes('/reviews')&&
                  router.url !=='/local-rank'
                  ">
            </time-period> -->
            <div class="view">
              <!--  <p-toast key={{key}} #toast (onClose)="onReject()" [baseZIndex]="5000" position="bottom-center" preventDuplicates="true">
                  <ng-template let-message pTemplate="message">
                    <div class="p-flex p-flex-column" style="flex: 1">
                      <div class="p-text-center">
                        <h4>{{message.detail}}</h4>
                        <p>Incoming Message</p>
                      </div>
                      <div class="col-6">
                        <button type="button" pButton (click)="onReject()" label="Reject"
                          class="p-button-secondary"></button>
                      </div>
                    </div>
                  </ng-template>
                </p-toast> -->
              <!-- </div> -->
              <!-- <app-chat-bot *ngIf="showAcceptMessage" [allMessages]="allMessages" [name]="name" [selectedDocId]="selectedDocId"></app-chat-bot> -->
              <!-- <p-toast position="bottom-center" key="bc"></p-toast> -->
              <!-- <router-outlet (activate)="onActivate($event)"></router-outlet> -->
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-container>
    <app-footer *ngIf="user"></app-footer>
  </div>
</ng-container>
<!-- <ng-template #logOutTemplate>
  <ng-container>
    <alert></alert>
    <router-outlet #loggedOut name="loggedOut"></router-outlet>
  </ng-container>
</ng-template> -->
<!-- -------------------------------------------------------- -->
