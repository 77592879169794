<div class="modal fade" role="dialog" tabindex="-1" data-bs-backdrop="false" id="grantRewardModal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Grant Reward Points</h5><button type="button" class="btn-close"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6 col-lg-4" *ngFor="let reward of rewardDocs">
                        <div class="card card-focus" (click)="selectedReward(reward)"
                            [ngClass]="{ 'highlight': reward == selectedItem }" style="height:7rem;">
                            <input type="radio" [value]="reward" [(ngModel)]="selectedItem"
                                (change)="selectedReward(reward)" />
                            <div class="card-body text-center" styles="cursor:pointer;">
                                <span class="single-reward">{{reward?.points}}</span>
                                <div>
                                    <div>
                                        <h6 class="text-muted mb-2 reward-name" styles="cursor:pointer;">
                                            {{reward?.title}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer"><button class="btn btn-light" type="button"
                    data-bs-dismiss="modal">Cancel</button><button class="btn btn-primary" role="button"
                    (click)="grantReward()" data-bs-dismiss="modal">Grant
                    Reward</button></div>
        </div>
    </div>
</div>
<div class="modal fade" role="dialog" tabindex="-1" data-bs-backdrop="false" id="redeemRewardModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Redeem Reward Points</h5><button type="button" class="btn-close"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>You can redeem {{totalRewardsEarned}} points</p>
                <input type="number" class="form-control" [(ngModel)]="redeemedPoint" placeholder="Enter points here">
                <div *ngIf="redeemedPoint > totalRewardsEarned" style="color: red;font-style: italic;"><small>You cannot
                        redeem more than total earned</small></div>
                <div *ngIf="redeemedPoint == 0" style="color: red;font-style: italic;">
                    <small>Would you
                        like to redeem more than 0 points?</small>
                </div>

            </div>
            <div class="modal-footer"><button class="btn btn-light" type="button"
                    data-bs-dismiss="modal">Cancel</button><button class="btn btn-primary button" role="button"
                    (click)="redeemRewards(redeemedPoint)" data-bs-dismiss="modal"
                    [disabled]="redeemedPoint > totalRewardsEarned || redeemedPoint == 0 || redeemedPoint == ''">Redeem
                    Reward</button></div>
        </div>
    </div>
</div>


<div class="row text-center" id="subHeader1">
    <div class="col-md-3" style="margin: auto;">
        <div id="pageTitle">
            <a [routerLink]="['/rewards']"><i class="pi pi-chevron-left" style="margin: auto;"></i>Grant Reward</a>
        </div>
    </div>
    <!----<div class="col-md-3" style="margin: auto;">
        <a [routerLink]="['/rewards']"><i class="pi pi-chevron-left" style="margin: auto;"></i>Grant Reward</a>
    </div>-->
    <div class="col-md-5">
        <h4 style="margin: 10px;">Reward Details</h4>
    </div>
    <div class="col-md-4"></div>
</div>
<div *ngIf="!contact" style="padding-top: 20%;color:#7e84f2;">
    <div style="text-align: center;">
        <i class="pi pi-spin pi-spinner" style="font-size: 50px;" id="spinIcon1"></i>
    </div>
</div>

<div class="container" *ngIf="contact">
    <div class="card" id="total-reward-card">
        <div class="card-body" id="total-rewards">
            <div class="sub-header-wrapper">
                <div>
                    <div class="row">
                        <div class="col-md-6" style="padding-bottom: 15px;">
                            <div class="userName"><i class="fa fa-user-circle-o"
                                    style="padding-right: 5px;color: #7e84f2;"></i><span>{{contactDetails?.first_name}}
                                    {{contactDetails?.last_name}}</span>
                            </div>
                            <p class="userDetails">{{contactDetails?.full_phone}}</p>
                            <p class="userDetails">{{contactDetails?.email}}</p>
                        </div>
                        <div class="col-md-6">
                            <h4><span class="reward-point-score">{{totalRewardsEarned}}</span> Points</h4>
                            <h6 class="text-muted mb-2">Total Rewards Earned</h6><button
                                class="btn btn-primary small-button" type="button" data-bs-target="#redeemRewardModal"
                                data-bs-toggle="modal" [disabled]="totalRewardsEarned === 0">Redeem Reward</button>
                            <button class="btn btn-primary small-button" type="button"
                                data-bs-target="#grantRewardModal" data-bs-toggle="modal" (click)="getReward()">Grant
                                Reward</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showMessage === true">
        <div class="row text-center text-muted">
            <h6>{{message}}</h6>
        </div>
    </div>
    <div *ngIf="grantingReward === true">
        <div class="timeline">
            <div *ngIf="grantingReward === true; else redeemed;" style="position: absolute;left: -23px;"><i
                    class="fa fa-circle" style="color: #7e84f2;padding-right: 10px;"></i><span>{{ createdOn }}</span>
            </div>
            <div class="row" style="margin-top: 30px;">
                <div class="col-md-4" *ngFor="let reward of rewardList">
                    <div class="card" *ngIf="reward.title">
                        <div class="card-body">
                            <div><i class="{{reward?.icon}} reward-icon-small"></i>
                                <h6 class="text-muted mb-2 reward-name">{{reward?.title}}<br></h6>
                                <p class="single-reward-div">Points earned:&nbsp;<span
                                        class="single-reward">{{reward?.points}}</span></p>
                            </div>

                            <h6 class="text-muted float-right" styles="font-size:6px;">
                                <small>{{reward?.createdOn | date:'h:mm a'}}</small>
                            </h6>
                        </div>
                    </div>
                    <div class="card" *ngIf="reward.redeemedPoint">
                        <div class="card-body">
                            <div><i class="fa fa-caret-square-o-down reward-icon-small"></i>
                                <h6 class="text-muted mb-2 reward-name">Points redeemed<br></h6>
                                <p class="single-reward-div">Points used:&nbsp;<span
                                        class="single-reward">-{{reward?.redeemedPoint}}</span>
                                </p>
                            </div>
                            <h6 class="text-muted float-right" styles="font-size:6px;">
                                <small>{{reward?.createdOn | date:'h:mm a'}}</small>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<div *ngIf="redeemingReward">
        <div class="timeline">
            <div class="row" style="margin-top: 15px;">
                <div class="col-md-4" *ngFor="let redeem of redeemedList">
                    <div class="card">
                        <div class="card-body">
                            <div><i class="fa fa-caret-square-o-down reward-icon-small"></i>
                                <h6 class="text-muted mb-2 reward-name">Points redeemed<br></h6>
                                <p class="single-reward-div">Points used:&nbsp;<span
                                        class="single-reward">-{{redeem?.redeemedPoint}}</span></p>
                            </div>
                            <h6 class="text-muted float-right" styles="font-size:6px;">
                                <small>{{redeem?.createdOn | date:'h:mm a'}}</small>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
    <div *ngIf="rewardPoints.length !== 0">
        <div class="timeline" *ngFor="let obj of result">
            <div style="position: absolute;left: -23px;"><i class="fa fa-circle"
                    style="color: #7e84f2;padding-right: 10px;"></i><span>{{obj.dateStr}}</span></div>
            <div class="row" style="margin-top: 30px;">
                <div class="col-md-4" *ngFor="let reward of obj.rewards">
                    <div class="card" *ngIf="!reward.status">
                        <div class="card-body" *ngIf="reward?.title">
                            <div><i class="{{reward?.icon}} reward-icon-small"></i>
                                <h6 class="text-muted mb-2 reward-name">{{reward?.title}}<br></h6>
                                <p class="single-reward-div">Points earned:&nbsp;<span
                                        class="single-reward">{{reward?.points}}</span></p>
                            </div>

                            <h6 class="text-muted float-right" styles="font-size:6px;">
                                <small>{{reward?.createdOn | date:'h:mm a'}}</small>
                            </h6>
                        </div>
                        <div class="card-body" *ngIf="reward?.redeemedPoint">
                            <div><i class="fa fa-caret-square-o-down reward-icon-small"></i>
                                <h6 class="text-muted mb-2 reward-name">Points Redeemed<br></h6>
                                <p class="single-reward-div">Points used:&nbsp;<span
                                        class="single-reward">-{{reward?.redeemedPoint}}</span></p>
                            </div>

                            <h6 class="text-muted float-right" styles="font-size:6px;">
                                <small>{{reward?.createdOn | date:'h:mm a'}}</small>
                            </h6>
                        </div>
                    </div>
                    <div class="card" *ngIf="reward?.status === 'GRANTED'">
                        <div class="card-body">
                            <div><i class="{{reward?.icon}} reward-icon-small"></i>
                                <h6 class="text-muted mb-2 reward-name">{{reward?.title}}<br></h6>
                                <p class="single-reward-div">Points earned:&nbsp;<span
                                        class="single-reward">{{reward?.points}}</span></p>
                            </div>

                            <h6 class="text-muted float-right" styles="font-size:6px;">
                                <small>{{reward?.createdOn.toDate() | date:'h:mm a'}}</small>
                            </h6>
                        </div>
                    </div>
                    <div class="card" *ngIf="reward?.status === 'REDEEMED'">
                        <div class="card-body">
                            <div><i class="fa fa-caret-square-o-down reward-icon-small"></i>
                                <h6 class="text-muted mb-2 reward-name">Points Redeemed<br></h6>
                                <p class="single-reward-div">Points used:&nbsp;<span
                                        class="single-reward">-{{reward?.points}}</span></p>
                            </div>

                            <h6 class="text-muted float-right" styles="font-size:6px;">
                                <small>{{reward?.createdOn.toDate() | date:'h:mm a'}}</small>
                            </h6>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


</div>