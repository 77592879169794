<!-- <div>
  <div *ngIf="contactIconStatus==true">
    <div class="row">
      <div class="col-6">
        <mat-card style="height: 0px;width: 200px">Add Contact</mat-card>
      </div>
      <div class="col-6">
        <mat-icon color="primary" (click)="openDialog()">add_circle</mat-icon>
      </div>
    </div>
  </div>
</div> -->


  <div  *ngIf="popupForm==true">
    <h1 mat-dialog-title>Hi {{data.name}}</h1>
    <div mat-dialog-content>
        <p>What's your favorite animal?</p>
        <mat-form-field>
          <input matInput [(ngModel)]="data.animal">
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
          <button mat-button (click)="onNoClick()">No Thanks</button>
          <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button>
    </div>
  </div>

  
<form>
  <mat-form-field >
          <mat-label>Select your Account</mat-label>
          <mat-select  [(value)]="selectedName" (selectionChange)="CheckColor()">
            <mat-option value="Dental-Designer">Dental-Designer</mat-option>
            <mat-option value="Nikita">Nikita</mat-option>
          </mat-select>
  </mat-form-field>
<br>
  <div id="myDiv">
    
    <mat-chip-list>
      <mat-chip [color]="assignColor">Papadum</mat-chip>
      <mat-chip>Naan</mat-chip>
      <mat-chip>Dal</mat-chip>
    </mat-chip-list><br><br><br>

    <form class="example-form">
    <button mat-button>Click me!</button><br><br><br>

    <mat-checkbox>Check me!</mat-checkbox><br><br><br>

    <mat-slide-toggle>Slide me!</mat-slide-toggle><br><br><br>

    <mat-tab-group>
        <mat-tab label="First"> Content 1 </mat-tab>
        <mat-tab label="Second"> Content 2 </mat-tab>
        <mat-tab label="Third"> Content 3 </mat-tab>
    </mat-tab-group><br><br><br>

    <mat-slider class="example-margin" [(ngModel)]="value"  name="value" ></mat-slider>
    </form>
  </div>

  <div id="my">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar><br><br><br>
    <section class="example-section">
        <mat-progress-bar
            class="example-margin"
            [mode]="mode"
            [value]="value"
            [bufferValue]="bufferValue">
        </mat-progress-bar>
      </section><br><br><br>

    <label id="example-radio-group-label">Pick your favorite season</label><br>
    
      <mat-radio-group aria-labelledby="example-radio-group-label"  >
        <mat-radio-button  *ngFor="let season of seasons"  [value]="season">
          {{season}}
        </mat-radio-button>
      </mat-radio-group>
  </div>
<br>
<button  mat-raised-button (click)="C1()">1</button>&nbsp;
<button  mat-raised-button (click)="C2()">2</button>&nbsp;
<button  mat-raised-button (click)="C3()">3</button>&nbsp;
<button  mat-raised-button (click)="C4()">4</button>&nbsp;
<button  mat-raised-button (click)="C5()">5</button>&nbsp;
<button  mat-raised-button (click)="C6()">6</button>&nbsp;
<button  mat-raised-button (click)="C7()">7</button>&nbsp;
<button  mat-raised-button (click)="C8()">8</button>&nbsp;
<button  mat-raised-button (click)="C9()">9</button>&nbsp;

<!-- </form>
<form class="example-form">
  <div class="row-4">
    <mat-form-field class="example-full-width">
    <input matInput placeholder="Enter list name" [(ngModel)]="listName" name="listname">
    </mat-form-field>
  </div>
  <br>
  <div class="row-4" *ngFor="let option of ListNameCollection;let i=index">
    <div class="row">
      <div class="col-3">
        {{ListNameCollection[i]}}
      </div>
      <div class="col-3">
          <button  mat-raised-button (click)="AddToList(ListNameCollection[i])">Add</button>
      </div>
      <div class="col-3">
         <button  mat-raised-button (click)="ShowContactFromList(ListNameCollection[i])">Data</button>
      </div>
      <div class="col-3">
           <div *ngIf="showContactButtonStatus==true && ListNameCollection[i]==showListData">
            <div *ngFor="let option of listContactCreatedOn;let i=index" class="row">
    <div class="col-6"  style="margin:2px;padding:2px"> 
      {{listContactCreatedOn[i]}}
    </div>       
    <div class="col-6"  style="margin:2px;padding:2px"> 
      <mat-icon   color="primary"   (click)="RemoveDocument(i,ListNameCollection[i])" class="icon-btn">remove_circle</mat-icon>
    </div>
            </div>
      </div>
          
      </div>
    </div>
  
  </div>
  <br>
  <div class="row-4">
  <button  mat-raised-button (click)="CreateList(listName)" type="submit">Create</button>&nbsp;
  <button  mat-raised-button (click)="ShowList()" type="submit">ShowList</button>&nbsp;
  <button  mat-raised-button (click)="deleteFromList()" type="submit">DeleteAllList</button>
	</div><br>
<button mat-button>Click me!</button>
<mat-progress-bar mode="indeterminate"  ></mat-progress-bar><br>
<mat-checkbox >Check me!</mat-checkbox>
<br>
</form>
<div class="col"> 
<button mat-button name="daterange">
  <form>
      <div class="form-group">
        <div class="input-group" daterangepicker [options]="{startDate:dateInput.start, endDate:dateInput.end }" (selected)="selectedDate($event, dateInput)" >
           <span>
            {{ dateInput.start | date }} - {{ dateInput.end| date }}
          </span>
          <i class="fa fa-calendar"></i>
        </div>
      </div>
    </form>
</button>
</div>
 -->

