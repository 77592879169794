import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { FormBuilderService } from '../../_services/form-builder.service';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AccountService } from '../../_services/account.service';
import { NavigationService } from '../../_services';
// import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormControl, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { MatBadgeModule } from '@angular/material/badge';
import { ContactService } from "../../_services/contact.service";
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss']
})
export class SurveyFormComponent implements OnInit {

  types = { sms: false, email: false };
  SurveyFormstatus: boolean = false;
  surveyEnable: boolean = false;
  emailSendStatus: boolean = false;
  smsSendStatus: boolean = false;
  formSlug: string;
  forms: any = [];
  acc: any;
  state: any;
  showIcons: any;
  selectedContactsData: any = [];
  ServeyFormExists: boolean;
  color: string = "";
  isOpen: boolean = false;
  goLast: boolean = false;
  selectedFormData: any = [];
  formExists = true;
  loaded = false;
  linked_accounts: any = [];
  isStartScreen: boolean = true;
  emailSubject: string = "Survey Form";
  emailBody: string = "";
  smsBody: string = "";
  dialogInfo: any = [];
  isFormValid: any;
  emailSmsContent: any;
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() previousData: any;
  showConfirm: boolean = true;
  goPrevious: boolean = false;
  // goLast : boolean = false;
  container: HTMLElement;
  disabled: boolean;
  displayMsg: string = "Form Sending Job is added in the queue and it will send a message soon....";

  constructor(
    private formBuilderService: FormBuilderService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private accountService: AccountService,
    private navigationService: NavigationService,
    private contactService: ContactService
  ) { }
  // ngAfterViewInit(): void {
  //   this.container = document.getElementById("msgContainer");
  //   this.container.scrollTop = this.container.scrollHeight;
  //   throw new Error('Method not implemented.');
  // }

  ngOnInit() {

    this.dialogInfo = {
      emailSubject: "Survey Form Link",
      emailBody: "Click survey form for more details",
      smsBody: "Survey form link for you"
    }
    // console.log(this.previousData);
    this.forms = [];
    this.acc = this.accountService.getCurrentAccDetails();
    this.linked_accounts = this.accountService.getLinkedAccountCredentials('twilio')
    this.showIcons = true;
    this.formSlug = "survey_forms";
    this.surveyEnable = true;
    this.selectedContactsData = this.config.data;
    this.formBuilderService.getFormData(this.formSlug).then(res => {
      res.forEach(doc => {
        let form = { id: '', slug: '', data: {}, selected: true, ref: {} };
        form.data = doc.data();
        form.id = doc.id;
        form.slug = doc.data().slug;
        form.ref = doc.ref;
        this.forms.push(form);
      });
      if (this.forms.length) {
        this.loaded = true;
        if (this.previousData) {
          this.selectedFormData = this.previousData['selectedFormData'];
          let index = this.forms.findIndex(value => value.id === this.selectedFormData.id);
          this.selectedFormData = this.forms[index];
          this.selectedForm(this.selectedFormData);
        }
        else {
          this.selectedFormData = this.forms[0];
          this.selectedForm(this.selectedFormData);
        }
      }
      else {
        this.formExists = false;
      }
    })

  }


  next() {
    this.isStartScreen = false;
  }

  getSmsEmailStatus(data) {
    // console.log(data);
    let dialofInfo = JSON.parse(data);
    this.emailSubject = dialofInfo['emailSubject'];
    this.emailBody = dialofInfo['emailBody'];
    this.smsBody = dialofInfo['smsBody'];
    dialofInfo['contact'] = this.selectedContactsData;
    dialofInfo['selectedFormData'] = this.selectedFormData;
    dialofInfo['formNames'] = [this.selectedFormData.data.name];
    dialofInfo['form'] = "survey"
    this.dialogInfo = dialofInfo;
    if ((dialofInfo['email'] || dialofInfo['sms']) && dialofInfo['selectedForms'] != []) {
      this.disabled = false;
    }
    else {
      this.disabled = true;
    }
    // console.log('dialogInfo:', this.dialogInfo);
  }


  async sendServey() {

    this.showConfirm = false;
    let contacts = [];
    let forms;
    let acc_id = sessionStorage.getItem('current_acc_id');
    let url = "";
    const types = {
      sms: this.dialogInfo['sms'],
      email: this.dialogInfo['email']
    }
    url = environment.interactiveFormURL + '/survey-forms/';
    forms = this.selectedFormData.ref.id;
    for (var i = 0; i < this.selectedContactsData.length; i++) {
      contacts.push((this.selectedContactsData[i]).ref.id);
    }
    let serveyFormInfo = {
      contacts: contacts,
      forms: forms,
      types: types,
      url: url,
      acc_id: acc_id,
      emailBody: this.emailBody,
      smsBody: this.smsBody,
      emailSubject: this.emailSubject
    }
    this.goLast = true;
    this.contactService.sendSurveyInformation(serveyFormInfo).then(response => {
      this.container = document.getElementById("content");
      this.container.scrollTop = this.container.scrollHeight;
      console.log(response)
      if (response.status == 'sent') {
        this.displayMsg = `Message Sent.`;
      }
      else if (response.status == 'error') {
        this.displayMsg = `The message can't be sent right now. Please try again later.`;
      }
    });

  }


  selectedForm(data) {
    // console.log(data);
    this.selectedFormData = data;
    this.isOpen = true;
    for (var i = 0; i < this.forms.length; i++) {
      if (this.forms[i] == data) {
        this.forms[i].selected = false;
      }
      else {
        this.forms[i].selected = true;
      }
    }
    // console.log(this.forms);
  }


  previous() {
    this.isStartScreen = true;
  }

}
