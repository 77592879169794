<div class="container-fluid view">
  <!-- <button color="primary" (click)="download()"> Download </button> -->

  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
      <mat-spinner></mat-spinner>
  </div>

  <!-- <div *ngIf="dataSource.notLoading$ | async"> -->
    <button mat-button (click)="bulkAction()">Bulk Action</button>

    <mat-table class="contacts-table mat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="created_on" matSortDirection="desc" matSortDisableClear>

      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let contact">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(contact) : null"
                        [checked]="selection.isSelected(contact)"
                        [aria-label]="checkboxLabel(contact)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="created_on">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Created On</mat-header-cell>
        <mat-cell *matCellDef="let contact">{{contact.created_on | date:'MMM d, yyyy'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="first_name">
        <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
        <mat-cell *matCellDef="let contact">
          <input class="myinput" matInput placeholder="Enter" [value]="contact['first_name']" [disabled]="contact['active']===false">
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="last_name">
        <mat-header-cell *matHeaderCellDef>Last Name</mat-header-cell>
        <mat-cell *matCellDef="let contact">
          <input class="myinput" matInput placeholder="Enter" [value]="contact['last_name']" [disabled]="contact['active']===false">
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
        <mat-cell *matCellDef="let contact">
          <input class="myinput" matInput placeholder="Enter" [value]="contact['email']" [disabled]="contact['active']===false">
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef>Phone</mat-header-cell>
        <mat-cell *matCellDef="let contact">
          <input class="myinput" matInput placeholder="Enter" [value]="contact['phone']" [disabled]="contact['active']===false">
        </mat-cell>
      </ng-container>
        
<!--       <ng-container matColumnDef="provider">
        <mat-header-cell *matHeaderCellDef>Provider</mat-header-cell>
        <mat-cell *matCellDef="let contact">
          <input  class="myinput" matInput placeholder="Enter"  [value]="contact['providerReference']">
        </mat-cell>
      </ng-container> 
 -->
      <ng-container matColumnDef="send_text_reminders">
        <mat-header-cell *matHeaderCellDef>Send Text Reminders</mat-header-cell>
        <mat-cell *matCellDef="let contact">
          <mat-slide-toggle   [disabled]="contact['active']===false"  name="send_text_reminders"  [(ngModel)]="contact['send_text_reminders']" style="vertical-align : middle;">
          </mat-slide-toggle>

          <i *ngIf="contact['send_text_reminders'] == true && contact['active']!==false" disabled matTooltip="Send SMS Now" class="material-icons" style="vertical-align : middle;padding-left:20px;cursor : pointer;">message</i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="last_visited">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Last Visited</mat-header-cell>
        <mat-cell *matCellDef="let contact">
          <mat-form-field style="width:70px; margin-top:10px;">
            <input matInput [matDatepicker]="picker" style="font-size:12px; font-weight:200;"
                     [disabled]="contact['active']===false"
                     [(ngModel)]= contact.last_visited>

              <mat-datepicker #picker="matDatepicker" disabled="false" [opened]=false [disabled]="contact['active']===false "></mat-datepicker>
            </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
        <mat-cell *matCellDef="let contact">
          <mat-slide-toggle class="formatToggle" name="greyRow" [(ngModel)]="contact.active"></mat-slide-toggle>
        </mat-cell>
      </ng-container>

      <!--
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let contact" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="contact == expandedElement ? 'expanded' : 'collapsed' ">
            <div class="expanded-element-description">
              <div *ngIf="contact == expandedElement">
                The expanded contact is {{ contact.first_name }} {{ contact.last_name }}
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>
    -->

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let contact; columns: displayedColumns;"></mat-row>
      <!--
      <mat-row *matRowDef="let contact; columns: displayedColumns" [class.example-expanded-row]="expandedElement === contact" (click)="expandedElement = expandedElement === contact ? null : contact"></mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="element-detail-row"></mat-row>
    -->
    </mat-table>

    <mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
<!--  </div> -->

</div>
