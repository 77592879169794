/*
 * Imported in afService.
 * Not really used functionally.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class HubspotService {

  constructor(
    private http: HttpClient
  ) { }

  addEmailContact(contact: any) {
    this.http.post(`${environment.serverURL}/hubspot-add-contact.php`, JSON.stringify(contact))
             .subscribe(
               res => console.log(res)
             );
  }

  addGoogleContact(contact: any) {
    let name = contact.displayName.split(' ');
    let contact_obj = {
      'first_name': name[0],
      'last_name': name[1],
      'email': contact.email,
      'cell_no': contact.phoneNumber,
      'url': ''
    };

    this.http.post(`${environment.serverURL}/hubspot-add-contact.php`, JSON.stringify(contact_obj))
             .subscribe(
               res => console.log(res)
             );
  }

  searchContact(email: string) {
    return this.http.post(`${environment.serverURL}/hubspot-search-contact.php`, email);
  }
}
