<!--<button mat-raised-button class="format" (click)="fetchSMSLogs()"> Show SMS Logs </button>-->
<!--<p>SMS Logs</p>-->
<center>
<div *ngIf="!SMSFetched">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <p styles="text-align : center;">Loading...</p>
</div>
</center>

<div *ngIf="SMSFetched">
        <div *ngFor="let sms of sms_array">
          <div class="desktop-only">
          <div class="row">
          <div class="col-sm-2">
                <p class="text-center" style="vertical-align:middle;">
                <i *ngIf="(sms?.direction === 'outbound-api') || (sms?.direction === 'outbound-reply')" class="material-icons" style="vertical-align : middle;font-size:1.5rem;" matTooltip="Sent Message">message<sup><i class="material-icons" style="font-size:1rem;">call_made</i></sup></i>
                <i *ngIf="sms?.direction === 'inbound'" class="material-icons" style="vertical-align : middle;font-size:1.5rem;">message<sup><i class="material-icons" style="font-size:1rem;" matTooltip="Received message">call_received</i></sup></i>
                </p>
                <p class="text-center extraInfo">{{sms.date | date:'MMM d, y h:mm a' }}</p>
                <p class="text-center extraInfo">{{sms.status | titlecase}}</p>
          </div>
          <div class="col-sm-10">
            <p>{{sms.msg}}</p>
          </div>
          </div>
          </div>

         <div class="mobile-view">
          <div class="row">
          <div class="col-sm-4">
                <p class="text-center" style="vertical-align:middle;">
                <i *ngIf="(sms?.direction === 'outbound-api') || (sms?.direction === 'outbound-reply')" class="material-icons" style="vertical-align : middle;font-size:1rem;" matTooltip="Sent Message">message<sup><i class="material-icons" style="font-size:1rem;">call_made</i></sup></i>
                <i *ngIf="sms?.direction === 'inbound'" class="material-icons" style="vertical-align : middle;font-size:1rem;">message<sup><i class="material-icons" style="font-size:1rem;" matTooltip="Received Message">call_received</i></sup></i>
                </p>
                <!--<p>{{sms.msg}}</p>-->
                <p class="text-center extraInfo">{{sms.date | date:'MMM d, y h:mm a' }}</p>
                <p class="text-center extraInfo">{{sms.status | titlecase}}</p>
          </div>
          <div class="col-sm-4">
            <p>{{sms.msg}}</p>
          </div>
          </div>
          </div>
          <mat-divider></mat-divider>
          </div>
  </div>
  <div *ngIf="noSms">
  <div class="col-sm-12"><p>No SMS history for this contact.</p></div>
  </div>
