<div class="row" style="background-color:white">
    <div class="col-md-4">

    </div>
    <div class="col-md-4">
        <div *ngIf="!postConfig" style="margin:12px auto 10px;font-size: 20px;font-weight: 500;text-align: center;">
            Social Media Post
        </div>
        <div *ngIf="postConfig" style="margin:12px auto 10px;font-size: 20px;font-weight: 500;text-align: center;">
            Social Media Template
        </div>
    </div>
    <div class="col-md-2" style="text-align: center;margin-top: 5px;">
    </div>
    <div class="col-md-2" style="text-align: center;margin-top: 5px;">
        <button *ngIf="settingFlag" mat-mini-fab id="button" class="format" color="primary" matTooltip="Generate Post">
            <span class="material-icons" style="cursor: pointer;" color="primary" (click)="generatePost()">add</span>
        </button>
    </div>
    <div class="col-md-1" style="text-align: center;margin-top: 5px;">
        <!-- <button *ngIf="settingFlag" mat-mini-fab id="button" class="format" color="primary"
            matTooltip="Template Configuration">
            <span class="material-icons" style="cursor: pointer;text-align: right;" color="primary"
                (click)="openConfig()">settings</span>
        </button> -->
    </div>
</div>
<div class="row" style="padding-top: 15px;" *ngIf="allPost?.length   && allPostflag">
    <div class="col-md-12" style="margin-top: 20px;">
        <div id="card-container">
            <div *ngFor="let post of allPost">
                <div style="margin: 5px;">
                    <p-card [style]="{height:'300px',width:'300px',
                        'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'
                        }" styleClass="p-card-shadow">
                        <p-card-content>
                            <img [src]="post.postUrl" style="height: 250px;width: 100%;">
                        </p-card-content>
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-4">
                                <div style="text-align: center;margin-top: 12px;">
                                    {{post.type}}
                                </div>
                            </div>

                            <div class="col-md-6">
                                <mat-icon aria-hidden="false" aria-label="Example home icon"
                                    style="text-align: center;margin-top: 12px;" matTooltip='Update Post'
                                    (click)="updatePost(post)">
                                    edit</mat-icon>
                                <mat-icon aria-hidden="false" aria-label="Example home icon"
                                    style="text-align: center;margin-top: 12px;margin-left: 17px;"
                                    matTooltip='Delete Post' (click)="deletePost(post)">delete</mat-icon>
                                <mat-icon aria-hidden="false" aria-label="Example home icon"
                                    style="text-align: center;margin-top: 12px;margin-left: 17px;"
                                    matTooltip='Download Post' (click)="downloadPost(post)"><span
                                        class="material-icons-outlined">
                                        file_download
                                    </span>
                                </mat-icon>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>
        </div>
    </div>

</div>

<div *ngIf="allPost?.length == 0 &&  allPostflag" style="padding-top: 50px;text-align: center;">
    <h6>No post available</h6>
</div>