<p-scrollPanel [style]="{width: '100%', height: '91vh',padding:'0px'}">
    <div class="message-box p-scrollpanel-content">
        <!-- <div *ngIf="chatListReady==false">
          <mat-progress-spinner mode="indeterminate" style="margin:0 auto;"></mat-progress-spinner>
        </div> -->
        <div class="">
            <div class="list-group">
                <a class="list-group-item list-group-item-action flex-column align-items-start selected"
                    *ngFor="let contact of allContacts;let i=index" [class.list-group-item]="active === i"
                    (click)="onClick(i)" (click)="sendPhone(contact)" style="padding: 10px;border-radius: 5px;;">
                    <!-- <mat-drawer-container [hasBackdrop]="false">
                            <mat-drawer #drawer mode="over" opened='true'>I'm a drawer</mat-drawer>
                            <mat-drawer-content> -->
                    <div class="row">
                        <div class="col-2">
                            <ngx-avatar name={{contact?.first_name}}></ngx-avatar>
                        </div>
                        <div class="col-10" id="name" style="padding-left: 4%;">
                            <div class="row">
                                <div class="col-8">
                                    {{contact?.first_name}} {{contact?.last_name}}
                                </div>
                                <div class="col-4">
                                    <small class="small font-weight-bold">
                                        {{contact.lastMessages?.date.seconds * 1000 | date:'MMM d yyyy'}}

                                        <!-- {{contact.lastMessages?.date.toDate() | date: 'MMM d yyyy'}} -->
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-9" style="padding: 5px;">
                                    <p class="mb-0 text-small" id="msg"
                                        *ngIf="contact.lastMessages?.type=== 'incoming' && contact.lastMessages?.unread === false"
                                        [innerHTML]="contact.lastMessages?.message | slice:0:30">

                                        <!-- {{contact.lastMessages?.message | slice:0:30}} -->
                                    </p>
                                    <p style="padding: 0px;"
                                        *ngIf="contact.lastMessages?.type=== 'incoming' && contact.lastMessages?.unread === true">
                                        <b [innerHTML]="contact.lastMessages?.message | slice:0:30">
                                            <!-- {{contact.lastMessages?.message | slice:0:30}} -->
                                        </b>
                                    </p>
                                    <span>
                                        <p class=" mb-0 text-small" id="msg"
                                            *ngIf="contact.lastMessages?.type=== 'outgoing'"
                                            [innerHTML]="contact.lastMessages?.message | slice:0:30">
                                            <!-- {{contact.lastMessages?.message | slice:0:30}} -->
                                        </p>
                                        <span *ngIf="contact.lastMessages?.type=== 'outgoing'" class="material-icons"
                                            style="float:right;font-size: 18px;padding-right: 10%  ;margin-top:-19px ;">done_all</span>
                                    </span>
                                </div>
                                <div class="col-md-3" style="margin:auto;padding:0px 5px;">
                                    <div class="row" *ngIf="contact.status == 'REQUESTED'">
                                        {{ringtone(contact)}}
                                        <i class="far fa-check-circle"
                                            style="color: rgb(28, 224, 28); font-size: 35px;margin:3px;"
                                            (click)='acceptLiveChat(contact);'>
                                        </i>

                                        <i class="far fa-times-circle" style="color: red;font-size: 35px;margin:3px;"
                                            (click)='rejectLiveChat(contact);$event.stopPropagation();'>

                                        </i>


                                    </div>
                                    <div *ngIf="contact.status =='ACCEPTED'" style="font-size: 13px;color:blue">
                                        {{contact.agentName}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </mat-drawer-content>
                        </mat-drawer-container> -->
                </a>
            </div>

            <div *ngIf="hasMoreRecords==true" style="text-align: center;"><button mat-button
                    (click)="fetchContact()">Show
                    More</button></div>
            <div *ngIf="hasMoreRecords==false" style="text-align: center;">No more records to show</div>

        </div>
    </div>
</p-scrollPanel>