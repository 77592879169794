<div class="contanier-fluid view">
<mat-card>
  <mat-card-header>
    <mat-card-title><h4>Contact Form</h4></mat-card-title>
    <mat-card-subtitle>Please enter the correct details to create a new contact</mat-card-subtitle>
  </mat-card-header>
  <!--<div class="row">
    <div class="col-md-6">
      <h4>Contact Form</h4>
      </div>
  </div>
  <p>Please enter the correct details to create a new contact</p>-->
  <form #contactData="ngForm" (ngSubmit)="saveContact(contactData)" name='contactForm'>
  <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <input matInput name="first_name" [(ngModel)]="first_name" placeholder="First Name" required />
            </mat-form-field>
          </div>
  </div>
  <div class="row ">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <input matInput name="last_name" [(ngModel)]="last_name" placeholder="Last Name" required />
            </mat-form-field>
          </div>
  </div>
  <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <input matInput name="phone_no" [(ngModel)]="phone" pattern="" placeholder="Phone Number" required />
            </mat-form-field>
            <!--<div *ngIf="phone_no.invalid && (phone_no.dirty || phone_no.touched) && phone_no.errors.pattern"
                 class="alert alert-danger">
              Please enter a valid mobile number.
            </div>-->
          </div>
  </div>
  <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <input matInput name="email" [(ngModel)]="email_id" placeholder="Email" pattern="" required />
            </mat-form-field>
          <!--  <div *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.pattern"
                 class="alert alert-danger">
              Email format should be
              <small><b>joe@abc.com</b></small>
            </div>-->
          </div>
  </div>
  <small><em>*All fields are necessary.</em></small>
  <div class="row">
        <div class="col-md-12 text-center">
          <button mat-raised-button color="primary" type="submit" [disabled]="!contactData.valid">Save contact</button>
        </div>
  </div>
  </form>
</mat-card>

</div>
