import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { te } from 'date-fns/locale';
import { contains } from 'jquery';
import { async } from 'rxjs';
import { convertToObject } from 'typescript';
import { SocialMediaService } from '../../_services/social-media.service';

@Component({
  selector: 'app-add-new-post-dailog',
  templateUrl: './add-new-post-dailog.component.html',
  styleUrls: ['./add-new-post-dailog.component.scss']
})
export class AddNewPostDailogComponent implements OnInit {
  url: string = '';
  code: string = '';
  templateName: string = '';
  selectedType: any = {};
  contentType = [];
  submitBtnFlag: boolean = true;
  successMsg: boolean = false;
  popupLoder: boolean = false;
  errorMsg: boolean = false;
  commentLimit: number = 200;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  templateNames: any;
  nameError: boolean = false;
  popupHeader: string;
  type: string;
  previewIamgeUrl: string = '';
  TemplateFlag: boolean = false;
  templates: any;
  templateMode: string = '';
  selectedTemplate: string;
  offerTypes = [{

    name: "Flat Discount",
    value: "FLAT_DISCOUNT",
    propertyName: "flatDiscount",
  },
  {
    name: "Package Discount",
    value: "PACKAGE_DISCOUNT",
    propertyName: "packageDiscount",
  },
  {
    name: "Buy X  & GET Y free",
    value: "BUY_X_GET_Y_FREE",
    propertyName: "buyXgetY",
  },

  ]
  offerType: any;
  addPost = new EventEmitter();
  // @Output() fetchNewTemplateEmitter = new EventEmitter<any>();
  constructor(
    private socialMediaService: SocialMediaService,
    public dialogRef: MatDialogRef<AddNewPostDailogComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.offerType = this.offerTypes[0]
  }

  ngOnInit(): void {
    let template = this.data.data;
    // let template = this.data.template;

    // console.log(template);

    this.popupHeader = this.data.popupHeader
    if (this.data.type == 'update') {
      this.previewIamgeUrl = template.previewTemplateUrl;
      this.url = template.templateUrl;
      this.code = template.templateCode;
      this.selectedType.type = template.type;
      this.templateName = template.name
      template.commentLimit ? this.commentLimit = template.commentLimit : '';
      if (template.offerType) {
        this.offerType = this.offerTypes.filter(function (el) {
          return el.value == template.offerType
        });
      }
    } else if (this.data.type == 'CREATE') {
      // this.templates = this.data.data.template
      // console.log(this.templates);
      this.templates = this.data.templates;
      console.log(this.data.templates);
    }
    this.type = this.data.type;
    this.templateNames = this.data.templateNames;
    this.TemplateFlag = this.data.TemplateFlag;
    console.log(this.TemplateFlag);
    this.getTemplateContentFromSetting()

  }
  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  onTabChanged(event) {
    console.log(event)

    // this.selectedType = '';
    this.code = '';
    this.url = '';
  }
  async getTemplateContentFromSetting() {
    let snap = await this.socialMediaService.ejsTemplateContent();
    let data = snap.docs[0].data();
    console.log(data);
    for (let key in data) {
      if (key != 'type') {
        let obj = { type: key.toUpperCase() }
        this.contentType.push(obj);
      }

    }
    if (this.data.type != 'update')
      this.selectedType.type = 'FAQ';

    console.log(this.contentType);
  }
  async submit() {
    this.submitBtnFlag = false;
    this.popupLoder = true;
    let obj: any = {};
    obj.previewImageUrl = this.previewIamgeUrl
    obj.type = this.selectedType.type
    obj.url = this.url
    obj.code = this.code
    obj.templateName = this.templateName
    if (this.selectedType.type == "OFFER")
      obj.offerType = this.offerType.value
    else
      obj.commentLimit = this.commentLimit
    console.log();
    await (await this.socialMediaService.addNewSocialMediaPostTemplate(obj)).subscribe(async resp => {
      console.log(resp)
      if (resp.status == 'success') {
        console.log("Template Added successfuly.")
        this.popupLoder = false;
        this.successMsg = true;

        setTimeout(() => {
          this.dialogRef.close({ status: "success" });
        }, 1000);
      }
      else {
        this.popupLoder = false
        this.errorMsg = true;
        // setTimeout(() => {
        //   this.dialogRef.close({ status: "error" });
        // }, 3000);
      }
    })


    // console.log("url submitted.........")
  }
  onSearchChange(event) {
    // console.log(event);
    console.log(this.templateNames);
    if (this.templateNames.includes(this.templateName)) {
      this.nameError = true;
    } else {
      this.nameError = false;
    }
  }
  async generatePosts() {
    this.popupLoder = true;
    let obj = {
      url: this.url,
      template: this.selectedTemplate
    }
    this.addPost.emit(obj);
  }
  addTemplate() {
    console.log("addd")
    this.TemplateFlag = false;
    this.type = 'add';
    this.popupHeader = 'Add Social Media Template';
  }
  radioChange(event) {
    console.log(event.value);
    this.templateMode = event.value;
    if (event.value == 'specific') {

    }
    else {

    }
  }
  getSelectedTemplate(event) {
    console.log(event.value);
    this.selectedTemplate = event.value.previewTemplateUrl;
  }
}
