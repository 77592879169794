import { Component, OnInit } from '@angular/core';
import { ContactService } from "../../_services/contact.service";
import { AccountService } from "../../_services/account.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { COUNTRY_CODES } from "../../_constants/country_phone_codes";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent {
  clicked: boolean = false;
  first_name: string = "";
  last_name: string = "";
  phone: string = "";
  email_id: string = "";
  msg: string;
  contactDoc: any;
  showForm: boolean = false;
  countryCodes: any = COUNTRY_CODES;
  countryCode: any;
  acc: any;
  sendTxtReminders: boolean = true;
  sendEmailReminders: boolean = true;

  constructor(
    private contactService: ContactService,
    private accountService: AccountService,
    public snackBar: MatSnackBar,
    public contactdialogRef: MatDialogRef<ContactDialogComponent>,
  ) {
    this.acc = this.accountService.getCurrentAccDetails();
    this.countryCode = this.acc.countryCode;
  }

  saveContact(contactData) {
    contactData.value.email = contactData.value.email.trim().toLowerCase();
    let stripPhone = contactData.value.phone_no.replace(/[^\d]/g, "");
    contactData.value.phone_no = stripPhone;
    this.sendTxtReminders = contactData.value.sendTxtReminders;
    this.sendEmailReminders = contactData.value.sendEmailReminders;
    contactData.value.countryCode = this.countryCode;
    contactData.value.full_phone = this.countryCode + stripPhone;
    this.contactService.saveNewContact(contactData.value).then((docRef) => {
      if (docRef !== undefined) {
        this.contactdialogRef.close();
        let snackBarRef = this.snackBar.open("Contact saved!", "", {
          duration: 3000,
        });
      } else {
        this.contactdialogRef.close();
        let snackBarRef = this.snackBar.open("Contact updated!", "", {
          duration: 3000,
        });
      }
    });
  }

  close() {
    this.contactdialogRef.close();
  }

}
