<h4>  These are available in premium version</h4>


<!--<div class="disable-whole-div__myDIV">

  <div class="disable-whole-div__cover" ></div>-->
  <div>

    <mat-card class="info-cards" [disabled]="disableDIV">
      <mat-card-title class="card-title">Competitive Research and Analysis</mat-card-title>
    <div *ngIf="!isReady">
      Analyzing ...
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="isReady">
        <form [formGroup]="competitorForm" (ngSubmit)="competitorForm.valid" novalidate>
          <mat-card-subtitle>mktbot has found some of your competitors. Please confirm.</mat-card-subtitle>
              <div class="form-group">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="competitor1" max="30" pattern="[a-z0-9.://_%+-]+[a-z0-9.-]+\.[a-z]{1,2}.+$">
                  <mat-error *ngIf="competitorForm.get('competitor1').hasError('pattern')">
                    Hmm, url doesn't look right
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                  <input matInput formControlName="competitor2" max="30" pattern="[a-z0-9.://_%+-]+[a-z0-9.-]+\.[a-z]{1,2}.+$">
                  <mat-error *ngIf="competitorForm.get('competitor2').hasError('pattern')">
                    Hmm, url doesn't look right
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                  <input matInput formControlName="competitor3" max="30" pattern="[a-z0-9.://_%+-]+[a-z0-9.-]+\.[a-z]{1,2}.+$">
                  <mat-error *ngIf="competitorForm.get('competitor3').hasError('pattern')">
                    Hmm, url doesn't look right
                  </mat-error>
                </mat-form-field>

              <div class="row justify-content-center">
                <button mat-raised-button (click)="next()" [disabled]="competitorForm.invalid" id="getCompetitiveAnalysis" color="accent">Analyze</button>
              </div>
              </div>
        </form>

    <!-- Competitive Analysis card-->
    <div [hidden]="competitorForm.invalid  || competitorForm.dirty">
      <mat-tab-group selectedIndex="0">
        <mat-tab label="Traffic">
          <p>Your website traffic vs. traffic on your competitors websites (average)</p>
    <!--for line chart-------------------------------->
                    <div style="display: block">
                      <canvas mdbChart
                        [chartType]="chartType"
                        [datasets]="chartData"
                        [labels]="chartLabels"
                        [colors]="chartColors"
                        [options]="chartOptions"
                        [legend]="true"
                        (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)">
                      </canvas>
                    </div>

        </mat-tab>
        <mat-tab label="Marketing Spend">
          <p>Your spend vs. Competitors</p>
    <!-- for bar chart-------------------------------->
                    <div class=bar style="display: block">
                      <canvas mdbChart
                        [chartType]="chartType1"
                        [datasets]="chartData1"
                        [labels]="chartLabels1"
                        [colors]="chartColors1"
                        [options]="chartOptions1"
                        [legend]="true"
                        (chartHover)="chartHovered1($event)"
                        (chartClick)="chartClicked1($event)">
                      </canvas>
                    </div>
           </mat-tab>
          </mat-tab-group>
         </div>
        </div>
       </mat-card>
    </div>
