export interface DialogData {
  stateUrl: any;
  hubspot_id: string;
  hubspot_form_id: string;
  email: string;
}

export interface Form {
  name: string;
  slug: string;
  questions?: Array<Question>;
  welcome_screen: Welcome_screen;
  thank_you_screen: Thank_you_screen;
  form_settings: Form_settings;
}

export interface Question {
  names?: Array<number>;
  rating?: Array<any>;
  multi_rating?: Array<any>;
  question: string;
  image?: string;
  short_description?: string;
  answer_type: string;
  choices?: Array<string>;
  jumps?: Array<number>;
  jumped_from?: number;
  required: boolean;
  answer?: any;
  answer_value?: number;
  uploadedImagePath?: string;
  values?: Array<number>;
  calculation?: {
    function: Array<any>;
    format: string;
  };
}


export interface Welcome_screen {
  headline_text: string;
  short_description: string;
  button_text: string;
  image?: string;
}

export interface Thank_you_screen {
  headline_text: string;
  short_description: string;
  button_text: string;
  button_link: string;
  image?: string;
  tracking_code: Tracking_code;
}

export interface Tracking_code {
  adwords?: Adwords_tracking_code;
  facebook?: Facebook_tracking_code;
}

export interface Adwords_tracking_code {
  form_name?: string;
  enabled: boolean;
  conversion_id?: string;
  conversion_label?: string;
}

export interface Facebook_tracking_code {
  tracking_event: string;
  custom_data: any;
  enabled: boolean;
  form_name?: string;
}

export interface Form_settings {
  email: string,
  hubspot_form_id: string,
  hubspot_id: string,
  is_seo?: boolean
}

export const ANSWER_TYPES: any = [
  { "label": "Name", "value": "name" },
  { "label": "Single line text", "value": "text" },
  { "label": "Multiple line text", "value": "textarea" },
  { "label": "Dropdown list", "value": "dropdown" },
  { "label": "Radio button", "value": "radio" },
  { "label": "Multiple select", "value": "checkbox" },
  { "label": "File", "value": "file" },
  { "label": "Phone number", "value": "tel" },
  { "label": "Email", "value": "email" },
  { "label": "Statement", "value": "declaration" },
  { "label": "Calculator", "value": "calculator" },
  { "label": "URL", "value": "url" },
  { "label": "Rating", "value": "rating" },
  { "label": "Multi-Rating", "value": "multi_rating" },
];
