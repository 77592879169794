<!-- <mat-toolbar>
  <mat-toolbar-row>
    <button mat-button [routerLink]="['/']"><mat-icon> format_italic </mat-icon><span>Interactive Forms</span></button>
     <span class="space"></span>
     <mat-form-field *ngIf="user">
       <mat-select [(ngModel)]="selectedAccount" (selectionChange)="accChange(selectedAccount)">
         <mat-option *ngFor="let account of accounts; let i = index" [value]="user.accounts[i]">
           {{ account }}
         </mat-option>
       </mat-select>
     </mat-form-field> -->
<!-- This fills the remaining space of the current row -->
<span class="space"></span>
<div class="navigation" *ngIf="showIcons">
  <!-- <button mat-button id="example" [routerLink]="['/samples']"><span>Click here to see
      Examples</span></button> -->
  <!-- <div *ngIf="!user">
    <button mat-button [routerLink]="['/login']"><span>Sign In</span></button>
  </div> -->
  <!-- <div *ngIf="user">
    <button mat-button [routerLink]="['/all-forms']"><span>My Forms</span></button>
    <button mat-button (click)="afService.logout()">
      <span>Sign Out</span>
    </button>
  </div> -->
  <!-- <button *ngIf="!user" mat-button [routerLink]="['/signup']"><span>Try For Free</span></button> -->
</div>
<!-- </mat-toolbar-row>
</mat-toolbar> -->