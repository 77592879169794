import { Component, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { AccountService } from '../../_services/account.service';
import { ContactService } from "../../_services/contact.service";
import { Output, EventEmitter } from '@angular/core';
import { GalleriaThumbnails } from 'primeng/galleria';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-email-sms-capture-dialogs',
  templateUrl: './email-sms-capture-dialogs.component.html',
  styleUrls: ['./email-sms-capture-dialogs.component.scss']
})
export class EmailSmsCaptureDialogsComponent implements OnInit {

  emailSubject: string = "Your Form Link";
  emailBody: string = "Click  Form Link for more details";
  smsBody: string = "Form Link for you";
  SurveyFormstatus: boolean = false;
  surveyEnable: boolean = false;
  emailSendStatus: boolean = false;
  smsSendStatus: boolean = false;
  disabled: boolean = true;
  linked_accounts = [];
  types = { sms: false, email: false };
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() data;
  @Input() emailSmsContent;
  @ViewChild('form', { static: true }) ngForm: NgForm;

  constructor(
    private contactService: ContactService,
    private accountService: AccountService
  ) { }

  ngOnInit() {

    console.log(this.types);
    console.log(this.data);
    this.linked_accounts = this.accountService.getLinkedAccountCredentials('twilio');
    if (this.data) {
      this.types = { sms: this.data['sms'], email: this.data['email'] };
      this.emailBody = this.data['emailBody'];
      this.smsBody = this.data['smsBody'];
      this.emailSubject = this.data['emailSubject']

      if (!this.types['email'] && !this.types['sms']) {
        this.disabled = true;
      }
      else {
        this.disabled = false;
      }
    }

    let formChangesSubscription = this.ngForm.form.valueChanges.subscribe(x => {
      if(!x['email'])
      {
        x['emailBody'] = this.emailBody;
        x['emailSubject'] = this.emailSubject;
      }
      if(!x['sms'])
      {
        x['smsBody'] = this.smsBody;
      }
      this.newItemEvent.emit(JSON.stringify(x));
    })

  }


  emailSmsCheck(event) {

    if ((event.source.name == 'email')) {
      this.types['email'] = event.checked;
    }

    if ((event.source.name == 'sms')) {
      this.types['sms'] = event.checked;
    }

    if (!this.types['email'] && !this.types['sms']) {
      this.disabled = true;
    }
    else {
      this.disabled = false;
    }
  }

  passValueToDialog() {
    let content = {
      sms: this.types['sms'],
      email: this.types['email'],
      emailBody: this.emailBody,
      smsBody: this.smsBody,
      emailSubject: this.emailSubject

    }
    this.newItemEvent.emit(JSON.stringify(content));
  }

  next() {

    let content = {
      sms: this.types['sms'],
      email: this.types['email'],
      emailBody: this.emailBody,
      smsBody: this.smsBody,
      emailSubject: this.emailSubject,
      linked_accounts: this.linked_accounts
    }
    this.newItemEvent.emit(JSON.stringify(content));

  }

  check()
  {
    let content = {
      sms: this.types['sms'],
      email: this.types['email'],
      emailBody: this.emailBody,
      smsBody: this.smsBody,
      emailSubject: this.emailSubject,
      linked_accounts: this.linked_accounts
    }
    console.log(content);
  }

}
