import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-search-console-stats',
  templateUrl: './search-console-stats.component.html',
  styleUrls: ['./search-console-stats.component.scss']
})
export class SearchConsoleStatsComponent implements OnInit {
  @Input() totalAnalysis: any;

  constructor() { }

  ngOnInit() {

  }

}
