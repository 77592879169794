<div class="content" scrollable (scrollPosition)="scrollHandler($event)">
  <!--
  <div *ngFor="let response of page.data | async">
    <p>{{ response.first_name }} {{ response.last_name }}</p>
    <p>{{ response.address }}</p>
  </div>
-->
	<table>
		<tr>
			<th>Submitted On</th>
			<th>First Name</th>
			<th>Last Name</th>
			<th>Sample</th>
			<th>Address</th>
			<th>Apt</th>
			<th>City</th>
			<th>State</th>
			<th>Zip</th>
			<th>Email</th>
			<th>Email Opt-In</th>
		</tr>
		<tr *ngFor="let response of page.data | async">
			<td>{{ response.created_on.toDate() | date:'MMM d, y h:mm a' }}</td>
			<td>{{ response.first_name }}</td>
			<td>{{ response.last_name }}</td>
			<td>{{ response.samples_chosen }}</td>
			<td>{{ response.address }}</td>
			<td>{{ response.apt }}</td>
			<td>{{ response.city }}</td>
			<td>{{ response.state }}</td>
			<td>{{ response.zip }}</td>
			<td>{{ response.email }}</td>
			<td>{{ response.email_opt_in === true ? 'Yes' : 'No' }}</td>
		</tr>
	</table>
  	<p class="tag is-warning" *ngIf="page.done | async">No more records</p>
  <!-- <loading-spinner  *ngIf="page.loading | async"></loading-spinner> -->
</div>
