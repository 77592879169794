/*
 * Used in a test component 'Collected Forms'
 * Was trying server side pagination for BIC forms
 */

import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';



@Injectable({
  providedIn: 'root'
})
export class CollectedFormsService {

  private startingDocs: any = [];
  private endingDocs: any = [];
  public pagesFetched: number = 0;
  public pageSize: number;
  public sortDirection: any;

  constructor() { }

  getAllResponses() {
  	return firebase.firestore().collection('bic-collection')
  				   .get();
  }

  getResponses(pageIndex: number, pageSize: number) {
    if(this.pagesFetched === 0) {
      this.pageSize = pageSize;
      return firebase.firestore().collection('bic-collection')
        .orderBy('created_on', this.sortDirection)
        .limit(pageSize)
        .get();
    }
    // If it's a page that has already been fetched
    else if(pageIndex < this.pagesFetched) {
      return firebase.firestore().collection('bic-collection')
        .orderBy('created_on', this.sortDirection)
        .startAt(this.startingDocs[pageIndex])
        .endAt(this.endingDocs[pageIndex])
        .get();
    }
    // If it's a new page
    else {
      return firebase.firestore().collection('bic-collection')
        .orderBy('created_on', this.sortDirection)
        .startAfter(this.endingDocs[pageIndex - 1])
        .limit(pageSize)
        .get();
    }
  }

  setPageEndpoints(start: any, end: any) {
    this.startingDocs.push(start);
    this.endingDocs.push(end);
    this.pagesFetched += 1;
  }

  resetPageEndpoints() {
    this.startingDocs = [];
    this.endingDocs = [];
    this.pagesFetched = 0;
  }
}
