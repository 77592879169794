<div class="container-fluid">
    <mat-card (click)="onFocus('_welcome_screen')" class="metric-card">
        <form name="form" #responseForm="ngForm" novalidate>
            <p style="font-size: 18px; font-weight: 500;">Welcome Screen </p>
            <mat-checkbox class="newline" name="{{useCustom}}" [(ngModel)]="useCustom"> Use custom</mat-checkbox>
            <div *ngIf="useCustom">
            <mat-form-field class="example-full-width newline">
              <input matInput name="headline_text" [(ngModel)]="welcome_screen.headline_text" placeholder="Heading Text" required />
            </mat-form-field>
            <mat-form-field class="example-full-width newline">
             <textarea matInput name = "short_description" [(ngModel)]="welcome_screen.short_description" placeholder="Description (Here you can provide html also)" required></textarea>
            </mat-form-field>
            <br>
            <app-file-upload [section]="welcome_screen"></app-file-upload>
            <br>
            <mat-form-field class="example-full-width">
              <input matInput name="button_text" [(ngModel)]="welcome_screen.button_text" placeholder="Button Text" required />
            </mat-form-field>
        </div>
        </form>
    </mat-card>
</div>
