import { Component, OnInit, Input, ViewChild, AfterViewInit, ViewChildren } from "@angular/core";
import { Output, EventEmitter, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, ActivationStart, Router, RouterOutlet } from "@angular/router";
import { NotificationService } from "./_services/notification.service";
import { AfService, AccountService } from "./_services/index";
import { MatDrawer } from '@angular/material/sidenav';
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { ifStmt } from "@angular/compiler/src/output/output_ast";

import { AngularFireAuth } from "@angular/fire/auth";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [MessageService]
})
export class AppComponent implements OnInit, AfterViewInit {

  @Input() change: any = [];
  audio = new Audio('../assets/ringtone/notification.mp3');
  opened: boolean = false;
  accountDetails: any;
  title = "app";
  testArray: any = [
    "priyanka",
    "tejashree",
    "harshal",
    "nikita",
    "nehal",
    "anand",
    "arti",
  ];
  user: any = null;
  public mini = true;
  lock: boolean = false;
  showSidebar: boolean = false;


  @ViewChild(RouterOutlet, { static: false }) outlet: RouterOutlet;
  @ViewChild('drawer') public drawer: MatDrawer;
  @ViewChild('toast', { static: false }) toast: ToastModule;

  liveChatInfo: any;
  showAcceptMessage: boolean = false;
  allMessages: any;
  name: any;
  liveChatRef: any;
  selectedDocId: any;
  link: string;
  existingQueue: any = [];
  settingRef: any;
  previousCount: number = 0;
  currUserRef: any;
  docId: any;
  showToast: boolean = false;
  isLoggedIn:boolean;

  constructor(
    public router: Router,
    public afService: AfService,
    private accountService: AccountService,
    public notificationService: NotificationService,
    public afAuth: AngularFireAuth,
    private messageService: MessageService,
    private route: ActivatedRoute,
  ) {
    this.afService.user$.subscribe((user) => {
      this.user = user;
      // console.log(this.user);
      if(!user){
        console.log("No user");
        // this.router.navigate(['/']);
        // sessionStorage.clear();
    }

    });
    this.accountService.checkLiveChat();
    this.router.events.subscribe(e => {
      // console.log("router event",e);
      if (this.outlet != undefined) {
        if (e instanceof ActivationStart && e.snapshot.outlet == this.outlet['name']) {
          this.outlet.deactivate();
        }
      }
    });

    this.opened = false;


    this.accountService.accChange$.subscribe((accChange) => {
      // console.log("acc change occur",accChange);
      this.accountDetails = this.accountService.getCurrentAccDetails();
      this.notificationService.setNotificationCount(
        "/notifications/" + this.accountDetails.account_slug
      );
    });

  }
  ngAfterViewInit(): void {
    // console.log(this.toast);
    // console.log(this.toast_children);
    // this.accountService.closeToast$.subscribe(closeToast=>{
    //   console.log(this.toast);
    //   if(this.toast)
    //     if(this.key == this.toast['key'])
    //       this.toast['messageService']['clear'];
    // })

    // this.accountService.notification$.subscribe(async (liveChatRef) => {
    //   this.existingQueue = await (liveChatRef.data()).liveChatQueue;
    //   // console.log(this.existingQueue);
    //   this.liveChatRef = this.existingQueue[0];
    //   this.settingRef = liveChatRef.ref;
    //   this.showToast = false;
    //   console.log(this.toast);
    //   this.getNotification(this.liveChatRef)
    // });
  }
  ngOnInit(): void {
    // this.messageService.add({ key: 'bc', severity: 'info', summary: 'Sticky', detail: 'Message Content', sticky: true });
  }
  onActivate() {
    document.body.scrollTop = 0;
    // console.log(this.outlet);
    // console.log(`new outlet ${event}`);
  }
  // async getNotification(liveChatRef) {
  //   // console.log('active');
  //   this.liveChatInfo = await (await liveChatRef.get()).data();
  //   // console.log(this.liveChatInfo);
  //   this.key = this.liveChatInfo['first_name'];
  //   // console.log(this.key);
  //   let detail = {
  //     key: `${this.key}`,
  //     // key:"chat",
  //     severity: 'info',
  //     summary: 'Sticky',
  //     detail: this.liveChatInfo["first_name"],
  //     sticky: false,
  //     life: 45000
  //   };
  //   if(this.toast_children)
  //     this.toast_children['first'].messageService.add(detail)
  //     // this.toast_children['first']['messageService']['add'](detail);
  //   console.log(detail);
  //   console.log(this.toast);
  //   // this.messageService.add(detail);
  //   this.audio.loop = true;
  //   this.audio.play();
  // }
  // async onConfirm() {
  //   console.log("Accepted")
  //   let docId = this.liveChatRef.id;
  //   this.currUserRef = this.accountService.getUserRef();
  //   // console.log(docId)
  //   this.liveChatRef.update({
  //     status: 'ACCEPTED',
  //     liveChat:true,
  //     agentRef:this.currUserRef
  //   });
  //   this.router.navigate(["/chatbot-message", { 'id': docId }]);
  //   // this.messageService.clear();
  //   this.audio.loop = false;
  //   this.audio.pause();
  //   this.existingQueue.shift();
  //   // console.log(this.existingQueue);
  //   this.settingRef.update({
  //     "liveChatQueue": this.existingQueue
  //   })
  // }

  // onReject() {
  //   // this.liveChatRef.update({
  //   //   status:'NO_AGENT'
  //   // });
  //   // console.log("Reject");
  //   // this.messageService.clear();
  //   this.audio.loop = false;
  //   this.audio.pause();
  // }

  getChangedValue(value) {
    this.opened = true;
    this.change = value;
    this.drawer.toggle();
    // console.log(this.change);
  }
  changeStatus(change, id) {
    this.notificationService.updateNotificationStatus(
      this.accountDetails.account_slug,
      change[id].id
    );
    this.notificationService.setNotificationCount(
      "/notifications/" + this.accountDetails.account_slug
    );
    this.router.navigate(["contacts", change[id].contactId]);
  }
  clearNotification(change, id) {
    this.notificationService.clearNotification(
      this.accountDetails.account_slug,
      this.change[id].id
    );
    this.notificationService.setNotificationCount(
      "/notifications/" + this.accountDetails.account_slug
    );
  }
  clearAllNotification() {
    this.notificationService.clearAllNotification(
      this.accountDetails.account_slug
    );
  }

  // -------------new side menu-------

  unlockMenu() {
    document.getElementById("mySidebar").style.width = "250px";
    this.lock = false;
    this.mini = true;
  }

  lockMenu() {
    document.getElementById("mySidebar").style.width = "250px";
    this.lock = true;
    this.mini = false;
  }
  // keepOpen() {
  //   document.getElementById("mySidebar").style.width = "250px";
  //   this.lock = !this.lock;
  //   this.mini = false;
  // }

  toggleSidebar() {
    if (!this.lock) {

      if (window.matchMedia("(max-width: 760px)") || window.matchMedia("(max-width: 996px)") || window.matchMedia("(max-width: 1200px)")) {
        if (this.mini) {
          document.getElementById("mySidebar").style.width = "250px";
          document.getElementById("main").style.marginLeft = "250px";
          this.mini = false;
        } else {
          document.getElementById("mySidebar").style.width = "5%";
          document.getElementById("main").style.marginLeft = "5%";
          this.mini = true;
        }
      }
      else if (window.matchMedia("(max-width: 700px)") || window.matchMedia("(max-width: 600px)") || window.matchMedia("(min-width: 600px)") || window.matchMedia("(min-width: 320px)")) {
        document.getElementById("mySidebar").style.width = "0px";
        document.getElementById("main").style.marginLeft = "0px";
        // document.getElementById("main").style.display = "none";
      }
    }
  }
  showSidenav() {
    this.showSidebar = true;
  }
}
