import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import * as moment from 'moment';
import 'firebase/firestore';
import 'firebase/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
// import { AngularFirestore} from 'angularfire2/firestore';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AccountService } from './account.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class PatientFormService {

  formResponses: any = [];
  private formsSource = new Subject<any>();
  patientForms$ = this.formsSource.asObservable();
  acc_ref: any;
  patientFormsData: any = [];
  patientFormsDataForSpecificContact: any = [];
  surveyFormData:any=[]

  private formsResponseSourceForSpecificContact = new BehaviorSubject<any>(this.patientFormsDataForSpecificContact);
  patientFormsResponseForSpecificContact$ = this.formsResponseSourceForSpecificContact.asObservable();

  private formsResponseSource = new BehaviorSubject<any>(this.patientFormsData);
  patientFormsResponse$ = this.formsResponseSource.asObservable();

  private serveyformsResponseSource = new BehaviorSubject<any>(this.surveyFormData);
  surveyFormsResponse$ = this.serveyformsResponseSource.asObservable();

  private patientFormCollection: AngularFirestoreCollection;
  private patientFormCollectionForSpecificContact: AngularFirestoreCollection;
  private serveyFormCollection: AngularFirestoreCollection;


  contacts: any;
  current_acc_id: string;
  patientObservable: Subscription;
  patientObservableForSpecificContact: Subscription;
  surveyFormObservable:Subscription;

  updateValue: any;

  constructor(
    public afs: AngularFirestore,
    private accountService: AccountService,
    private http: HttpClient
  ) {
    let docId = sessionStorage.getItem('current_acc_id');
    this.acc_ref = firebase.firestore().collection('accounts').doc(docId);
    /**
     * to subscribe on snapshot change of perticular document
     */
    firebase.firestore().collection('accounts').doc(docId)
      .collection('patient_forms').get().then(
        patientFormsSnapshots => {
          patientFormsSnapshots.forEach(formDocument => {
            let formName = formDocument.get("form_name");
            firebase.firestore().collection('accounts').doc(docId).collection('form_response')// .collection('patient_forms').doc(formDocument.id).collection('form_response')
              .onSnapshot(docs => {
                docs.docChanges().forEach(
                  change => {
                    if (change.type == "modified") {
                      let data = change.doc.data();
                      let docRef = change.doc.ref;
                      data['formRef'] = docRef;
                      this.formsSource.next({ type: 'modified', value: change.doc });
                    }
                  });
              })
          });
        });

    this.getPatientFormsData();

    this.accountService.accChange$.subscribe(
      _accChange => {
        this.getPatientFormsData();
     });

  }

  async downloadFile(newUrl: string, fileName: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    this.http.get(newUrl, { headers: headers, responseType: 'blob' }).subscribe(resp => {
      var blob = new Blob([resp], { type: 'application/pdf' });
      console.log(blob);
      saveAs(blob, fileName);
    });
  }

  getPatientFormsData() {
    let accId = sessionStorage.getItem('current_acc_id');
    this.patientFormCollection = this.afs.collection('accounts').doc(accId).collection('patient_form_details');
    this.patientObservable = this.patientFormCollection.valueChanges().subscribe(
      _actions =>// actions.map(act =>
      {
        this.patientFormsData = this.getPatientFormResponseByGroup();
        this.updatePatientForms(this.patientFormsData);
      });
  }

  updatePatientForms(data: any) {
    this.formsResponseSource.next(data);
  }

  async getFormsDataByContact(contactId) {
    let accId = sessionStorage.getItem('current_acc_id');
    let contactCollectionRef = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection("contacts")
    const conatctRef= contactCollectionRef.doc(contactId);
    this.serveyFormCollection = this.afs.collection('accounts').doc(accId).collection('form_response',ref=>(ref.where('contact_ref', '==', conatctRef).where("type","==","SURVEY_FORM")));
    this.surveyFormObservable = this.serveyFormCollection.valueChanges().subscribe(
    _actions =>
    {
      this.surveyFormData = this.getSurveyFormResponses(conatctRef);
      this.updateSurveyForm(this.surveyFormData);
    });
    
    this.patientFormCollectionForSpecificContact = this.afs.collection('accounts').doc(accId).collection('patient_form_details',ref=>(ref.where('contact_ref', '==', conatctRef)));
    this.patientObservableForSpecificContact = this.patientFormCollectionForSpecificContact.valueChanges().subscribe(
      _actions =>
      {
        this.patientFormsDataForSpecificContact = this.getPatientFormResponseByGroupForSpecificContact(conatctRef);
        this.updatePatientFormsByContact(this.patientFormsDataForSpecificContact);
      });
    
  }

  updatePatientFormsByContact(data: any) {
    this.formsResponseSourceForSpecificContact.next(data);
  }

  async getPatientFormResponseByGroup() {
    let patientFormSnap = await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('patient_form_details')
      .get();
    let patientFormData = [];
    patientFormSnap.forEach(data => {
      let patientFormDataObject = data.data();
      patientFormDataObject['id'] = data.id;
      patientFormData.push(patientFormDataObject);
    })
    patientFormData.forEach(async content => {
      if (content['contact_ref']) {
        content['contactDetails'] = await (await content['contact_ref'].get()).data();
        if (content['contactDetails']) {
          content['first_name'] = content['contactDetails']['first_name'];
          content['last_name'] = content['contactDetails']['last_name'];
          content['contactDetails']['objectID'] = (await content['contact_ref'].id);
        }
      }
    });
    return patientFormData;
  }

  
  async getPatientFormResponseByGroupForSpecificContact(conatctRef) {
    let patientFormSnap = await firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem('current_acc_id')}`).
                          collection("patient_form_details").where('contact_ref', '==', conatctRef).get()
    let patientFormData = [];
    patientFormSnap.forEach(data => {
      let patientFormDataObject = data.data();
      patientFormDataObject['id'] = data.id;
      patientFormData.push(patientFormDataObject);
    })

    patientFormData.forEach(async content => {
      content['type'] = 'PATIENT_FORM';
      if (content['contact_ref']) {
        content['contactDetails'] = await (await content['contact_ref'].get()).data();
        if (content['contactDetails']) {
          content['first_name'] = content['contactDetails']['first_name'];
          content['last_name'] = content['contactDetails']['last_name'];
          content['contactDetails']['objectID'] = (await content['contact_ref'].id);
        }
      }
    });
    return patientFormData;
  }

  async getSurveyFormResponses(conatctRef){
    let surveyFormSnap = await firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem('current_acc_id')}`).
    collection("form_response").where('contact_ref', '==', conatctRef).where("type","==","SURVEY_FORM").get()
    let surveyFormData = [];
    surveyFormSnap.forEach(async data => {
    let surveyFormDataObject =await data.data();
    surveyFormDataObject['id'] = data.id;
    surveyFormDataObject['createdOn'] = surveyFormDataObject['initiated'];
    surveyFormDataObject['submittedOn'] = surveyFormDataObject['submitted'];

    surveyFormData.push(surveyFormDataObject);
    });
    surveyFormData.forEach(async content => {
    content['type'] = 'SURVEY_FORM';
      if (content['contact_ref']) {
        content['contactDetails'] = await (await content['contact_ref'].get()).data();
      if (content['contactDetails']) {
        content['first_name'] = content['contactDetails']['first_name'];
        content['last_name'] = content['contactDetails']['last_name'];
        content['contactDetails']['objectID'] = (await content['contact_ref'].id);
      }
    }
    });
    console.log(surveyFormData);
    return surveyFormData;
  }

  updateSurveyForm(data){
    this.serveyformsResponseSource.next(data)
  }

  getCurrentAccountRef() {
    let docId = sessionStorage.getItem('current_acc_id');
    this.acc_ref = firebase.firestore().collection('accounts').doc(docId);
    // console.log(this.acc_ref.id);
    return this.acc_ref;
  }

  sortRoutes(array) //sort an array in place
  {
    array.sort(
      (a, b) => {
        let startA = moment(a.submitted);
        let startB = moment(b.submitted);

        if (startA.isBefore(startB, 'day')) {
          return 1;
        }
        else if (startA.isSame(startB, 'day')) {
          return 0;
        }
        else {
          return -1;
        }
      }
    );
  }

  async getFormResponseById(formRef) {
    // console.log(formRef.formName);
    let formSanpshot = await this.acc_ref.collection("form_response").where("type", "==", "PATIENT_FORM").where("form_ref", "==", formRef).get();
    let formResponses = []
    formSanpshot.forEach(responseSnap => {
      let data = responseSnap.data();
      data['formRef'] = responseSnap.ref;
      if (data['status'] == "submitted") {
        data['status'] = "completed";
      }
      else if (data['status'] == "initiated") {
        data['status'] = "not completed";
      }
      data['id'] = formRef.id + '_' + responseSnap.id;
      data['submitted'] = data['submitted'] !== undefined ? data['submitted'].toDate('MMM d, yyyy') : undefined;
      formResponses.push(data)
    })
    return formResponses;
  }

  async checkLegacyFormResponses() {
    return await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection("form_response")
      .where("type", "==", "PATIENT_FORM")
      .limit(1)
      .get()
  }

  async getFormResponses() {
    //return this.afs.collection('interactive_forms').doc(`${form_id}`).collection('form_response').valueChanges();
    let formsSnapshots = await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('patient_forms')
      .get();
    let unresolvedResponses = []
    if (formsSnapshots.empty) {
      return []
    }

    formsSnapshots.forEach(formDoc => {
      let formResponse = formDoc.ref;
      //  unresolvedResponses.push(this.getFormResponseById(formResponse));
      unresolvedResponses.push(this.getFormResponseById(formResponse));
    });
    //  console.log(unresolvedResponses);
    let formResponses = await Promise.all(unresolvedResponses);
    //  console.log(formResponses);
    this.formResponses = formResponses.reduce((acc, val) => acc.concat(val), []);
    //  console.log(formResponses);
    this.sortRoutes(this.formResponses);
    //  console.log(this.formResponses);
    return this.formResponses;
  }

  async acceptPatientForm(acc_ref, formRef) {
    // let snap = await formRef.update({'status':'accepted'});
    let snap = await acc_ref.update({ 'status': 'accepted' });
    return snap;
  }

  async rejectPatientForm(acc_ref, formRef) {
    // let snap = await formRef.update({'status':'rejected'});
    let snap = await acc_ref.update({ 'status': 'rejected' });
    return snap;
  }

  async getMatchingContact(contactInfo: any) {
    let contactSnap = await firebase.firestore().collection("accounts")
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('contacts')
      .where("first_name", "==", contactInfo.first_name)
      .where("last_name", "==", contactInfo.last_name)
      .where("email", "==", contactInfo.email).get();
    let contactDetails = contactSnap.docs.map(docs => docs);
    if (contactDetails.length > 0) {
      let contactData = contactDetails[0].data();
      contactData['contact_ref'] = contactDetails[0].ref;
      contactData['id'] = contactDetails[0].id;
      return contactData;
    }
    else {
      return {};
    }
  }

  async linkContact(acc_ref, formRef, contactRef) {
    let formSnap = await acc_ref.update({ "contact_ref": contactRef });
    return contactRef;
  }

  async getcontactDetails(contactRef) {
    let contactSnap = await contactRef.get();
    let data = contactSnap.data();
    data['id'] = contactRef.id;
    return data;
  }

  async getFormDetails(patientFormRef) {
    let patientFormSnap = await patientFormRef.get();
    if (patientFormSnap.exists) {
      let data = await patientFormSnap.data();
      data['id'] = patientFormSnap.id;
      return data;
    }
    else {
      return null
    }
  }


  async getPatientFormConfiguration() {
    return (await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('settings')
      .where('type', '==', 'patient-form-configuration')
      .get());
  }

  async updatePatientFormConfiguration(data, docId) {
    return await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('settings')
      .doc(docId)
      .update(data);

  }

  async addPatientFormConfiguration(data) {
    return (await firebase.firestore().collection('accounts')
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection('settings')
      .add(data))
  }

}
