<table class="table table-responsive">
  <thead>
    <tr>
     <th>Similar site</th>
     <th>Score</th>
    </tr>
   </thead>

   <tbody>
     <tr *ngFor="let site of sites">
       <td>{{site.url}}</td>
       <td>{{site.score}}</td>
     </tr>
   </tbody>
 </table>
<br><br>
