/*
 * The component to which the user is taken
 * when they click on a notification
 */

import { ActivatedRoute, Router, UrlSegment } from "@angular/router";
import { AccountService, ContactService } from "../_services/index";
import { ContactsDataSource } from "../_services/contacts.datasource";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDatepicker } from "@angular/material/datepicker";
import { AlertService } from "../_services/alert.service";
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Inject,
  ViewEncapsulation,
  ElementRef,
  Input,
} from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
//import { ContactsComponent } from '../contacts/contacts.component';
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../environments/environment";
import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";
import { MessageService } from "./../_services/message.service";

export interface DialogData {
  contact: any;
}

@Component({
  selector: "app-contact-id",
  templateUrl: "./contact-id.component.html",
  styleUrls: ["./contact-id.component.scss"],
})
//export class CreateMessageDialog {}
export class ContactIdComponent implements OnInit {
  columnsToDisplay: string[] = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "send_text_reminders",
    "last_visited",
    "created_on",
    "active",
  ];
  // 'phone',
  dataSource: ContactsDataSource;
  pageSize: any;
  expandedElement: any;
  url: UrlSegment[];
  twilio_from: string;
  acc: any;
  isShow: boolean;
  count: number;
  contactDetails: any;
  //contactsCompObj = new ContactsComponent();

  @ViewChild("elementToFocus") _input: ElementRef;

  // contact id as input from parent component
  @Input() contactId: string;

  constructor(
    private navigationService: NavigationService,

    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactService,
    private alertService: AlertService,
    //public messagedialogRef: MatDialogRef<CreateMessageDialog>,
    public dialog: MatDialog,
    private accountService: AccountService //public contactsComponent :ContactsComponent,
  ) { }

  ngOnInit() {

    //sub-header
    this.navigationService.updateState(
      new NavigationState(true, "Contact Details", "Contacts", "/contacts", "/contact-id")
    );
    // ------------------

    this.acc = this.accountService.getCurrentAccDetails();
    //this.currentAcc = true;
    this.twilio_from = this.acc.twilio_from;


    this.route.url.subscribe((url) => {
      this.url = url;
      let doc_id = url[1].path;
      console.log(doc_id);

      //this.contactService.setFilters(this.filters);
      this.dataSource = new ContactsDataSource(this.contactService);
      this.isShow = true;
      this.dataSource.getContactForNotification(doc_id);
      console.log(this.dataSource);
    });


    this.dataSource = new ContactsDataSource(this.contactService);
    this.route.url.subscribe(
      url => {
        this.url = url;
        let doc_id = url[1].path;
        console.log(doc_id);
        //this.contactService.getContacts(sortCol, sortDirection, pageIndex, pageSize);
        this.dataSource.getContact(doc_id);
        //this.dataSource.getContacts(sortCol, sortDirection, pageIndex, pageSize);
        console.log(this.dataSource);

        //console.log("this is contacts: ,",this.getContacts());
      });
  }

  // getContacts() {
  //   	this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
  //   }

  updateValue(enteredValue: string, column: string, contact: any) {
    contact.full_phone = contact.countryCode + enteredValue;

    this.contactService.updateEditedContact(contact, column, enteredValue);
  }

  saveSendTextReminders(send_text_reminders: any, contact_ref: any) {
    //console.log(contact_);
    this.contactService.saveSendTextReminders(send_text_reminders, contact_ref);
  }

  singleMessage(contact: any) {
    this.openDialogForMessage([contact]);
    console.log(contact);
  }

  updateLastVisited(contact_ref, refLast_visited) {
    this.contactService.updateLastVisitedDate(contact_ref, refLast_visited);
    //this.contactService.updateLastVisitedDate.refLast_visited'last_visited'.format('MMM d, yyyy');
  }

  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
    //this.contactService.updateLastVisitedDate(contact_ref,refLast_visited);
    //setTimeout(() => this._input.nativeElement.focus());
  }

  _closeCalendar(e, contact_ref, refLast_visited) {
    //this.contactService.updateLastVisitedDate(contact_ref,refLast_visited);
    setTimeout(() => this._input.nativeElement.blur());
  }

  updateRowVisible(contact) {
    this.contactService.updateRowVisibleStatus(contact);
  }

  openDialogForMessage(selectedContacts: any) {
    this.alertService.reset("");
    const messagedialogRef = this.dialog.open(CreateContactMessageDialog, {
      height: "600px",
      width: "900px",
      //data: { contact:contact }
      data: { contacts: selectedContacts },
    });
  }
}

@Component({
  selector: "create-contact-message-dialog",
  templateUrl: "../contacts/create-message-dialog.html",
  styleUrls: ["../contacts/contacts.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateContactMessageDialog {
  textMsg: string;
  selectedMsg: string;
  messages: any = []; /*= ["Crown is ready. Please book an appointment.",
"We now have availability on Monday evening, please call to schedule",
"We have received your medical records, please call to schedule",
"We would like to change your appointment because of ...."];*/
  showTemplates: boolean = true;
  customTextBox: boolean = false;
  contactRef: any;
  contactDetails: any;
  sendTo: string;
  twilio_from: string;
  twilio_credentials: any;
  account_sid: string;
  auth_token: string;
  twilio_to: any;
  sendLink: boolean = false;
  links: any = []; // = ["https://interactive-appointments.herokuapp.com/dental-designer/dental-calendar"];
  calendarLink: string;
  calendar_slug: string;
  acc_slug: string;
  optOut: boolean = false;
  googleLink: boolean = false;
  googleReview: string;
  optOutMsg: string =
    "If you would like to opt out of receiving texts from us, please reply STOP.";
  apptDate: boolean = false;
  selectedDate: any;
  startTime: any;
  endTime: any;
  dateItems: any = {};
  matchedString: any;
  minDate: any = new Date();

  workingTimes: any = [];
  confirmSend: boolean = false;
  messageObj: any;
  displayMsg: string;
  defaultTime: any;
  new_sms_array: any;
  fetch_logs: boolean = false;
  contactAppt: any;
  isApptDate: boolean = false;
  isAppt: boolean;
  dateOfAppt: any;
  timeOfAppt: any;
  service_sid: string;
  contact_ids: any = [];
  languages: any;
  sourceLanguage: any;
  selectedLanguage: any;
  selectedLanguageName: any;
  constructor(
    private contactService: ContactService,
    private accountService: AccountService,
    private alertService: AlertService,
    public snackBar: MatSnackBar,
    private messageService: MessageService,
    public messagedialogRef: MatDialogRef<CreateContactMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public contactData: DialogData
  ) {
    //this.contactRef = contactData['contactRef'];
    /*
      this.contactAppt = contactData['contact'].appointments;
      if(this.contactAppt.length == 0){
        console.log("No appts");
        this.isAppt = false;
      }
      else{
        this.isAppt = true;
        console.log("appts present");
        let bookedAppt = [];
        this.contactAppt.forEach(contact=>{
          if(contact.status === 'booked'){
            bookedAppt.push(contact);
            bookedAppt.sort((a,b)=>{
              return <any>new Date(b.date) - <any>new Date(a.date);
            });
            this.dateOfAppt = bookedAppt[0].date;
            this.timeOfAppt = bookedAppt[0].slot;
          }
        });
      }
      */

    /*this.contactRef.get()
                .then(contact =>{
                  //this.contactDetails = contact.data();
                  //this.sendTo = this.contactDetails.first_name + ' ' + this.contactDetails.last_name;
                  this.twilio_to = this.contactDetails.full_phone;
                  let acc = this.accountService.getCurrentAccDetails();
                  this.acc_slug = acc.account_slug;
                  this.fetchCalendars();
                  this.fetchCalendarGroups();
                  this.messages = acc.text_msg_templates;
                  this.twilio_from = acc.twilio_from;
                  this.twilio_credentials = this.accountService.getLinkedAccountCredentials('twilio');
                  this.account_sid = this.twilio_credentials.account_sid;
                  this.auth_token = this.twilio_credentials.auth_token;
                        });*/
    let acc = this.accountService.getCurrentAccDetails();
    this.languages = acc.languages ? acc.languages : [];
    this.sourceLanguage = acc.languageCode ? acc.languageCode : 'en';
    this.acc_slug = acc.account_slug;
    this.fetchCalendars();
    this.fetchCalendarGroups();
    this.messages = acc.text_msg_templates;
    this.twilio_from = acc.twilio_from;
    this.twilio_credentials = this.accountService.getLinkedAccountCredentials(
      "twilio"
    );
    this.account_sid = this.twilio_credentials.account_sid;
    this.auth_token = this.twilio_credentials.auth_token;
    this.service_sid = this.twilio_credentials.notify_service_sid;
    this.twilio_to = [];
    contactData["contacts"].forEach(async (contactObj) => {
      if (contactObj.active === undefined || contactObj.active === true)
        this.twilio_to.push(contactObj.full_phone);
      this.contact_ids.push(contactObj.ref.id);
      this.selectedLanguage = await this.messageService.getTargetLanguage(contactObj.full_phone);
      this.languages.forEach(language => {
        if (language.value == this.selectedLanguage)
          this.selectedLanguageName = language.name;
      })
    });
  }

  fetchCalendars() {
    this.accountService.fetchAccountCalendars().then((calendar) => {
      calendar.forEach((cal) => {
        let link = `${environment.apptURL}/calendar/${this.acc_slug}/${cal}`;
        this.links.push(link);
        //console.log("MAX DATE"+this.maxDate);
      });
      this.calendarLink = this.links[0]
    });
  }

  fetchCalendarGroups() {
    this.accountService.fetchAccountCalendarGroups().then((calendarGroup) => {
      calendarGroup.forEach((calendar) => {
        let link = `${environment.apptURL}/calendar-groups/${this.acc_slug}/${calendar}`;
        this.links.push(link);
      });
    });
  }
  selectLanguage(event) {
    this.languages.forEach(language => {
      if (language.value == event.value)
        this.selectedLanguageName = language.name;
    })
    console.log(this.twilio_to);
    this.messageService.changeTargetLanguage(this.twilio_to[0], this.selectedLanguage);
  }
  /*appendLink(msg :string, link:string){
this.selectedMsg = msg + link ;
/*
if(this.showTemplates == true){
this.selectedMsg = msg + link ;
}
if(this.customTextBox == true){
this.textMsg = msg + link;
}*/
  //}

  showTemplate() {
    this.customTextBox = false;
    this.selectedMsg = "";
    this.apptDate = false;
    this.isApptDate = false;
    this.optOut = false;
    this.sendLink = false;
    this.showTemplates = true;
  }

  customText() {
    this.selectedMsg = "";
    this.sendLink = false;
    this.optOut = false;
    this.apptDate = false;
    this.isApptDate = false;
    this.showTemplates = false;
    this.customTextBox = true;
  }

  confirm() {
    this.confirmSend = true;
    /*  if(this.selectedMsg == undefined){
    this.displayMsg = "";
  }*/
    this.displayMsg = this.selectedMsg;
    if (this.sendLink == true) {
      this.displayMsg += " " + this.calendarLink + " .";
    }
    if (this.optOut == true) {
      this.displayMsg += " " + this.optOutMsg;
    }

    this.googleReview = this.accountService.getCurrentAccDetails().googleReviewLink;
    //console.log(this.googleReview.googleReviewLink)
    if (this.googleLink == true) {
      this.displayMsg += " " + this.googleReview + " ";
    }
  }

  goBackToSendSMS() {
    this.confirmSend = false;
  }

  sendMessage() {
    this.messageObj = {
      targetLanguage: this.selectedLanguage,
      sourceLanguage: this.sourceLanguage,
      from: this.twilio_from,
      to: this.twilio_to,
      acc_sid: this.account_sid,
      auth_token: this.auth_token,
      service_sid: this.service_sid,
      sourceMsg: this.displayMsg,
      contact_ids: this.contact_ids
    };
    let msgObject = {
      direction: "outbound-api",
      sourceMsg: this.displayMsg,
      status: "sent",
      type: "message",
    };
    console.log(this.messageObj);
    this.messagedialogRef.close();
    this.contactService.sendSms(this.messageObj);
    this.contactService.messageNotification(msgObject);
    let snackBarRef = this.snackBar.open("SMS has been sent successfully", "", {
      duration: 4000,
    });
    /*setTimeout(()=>{
    this.fetchSMSLogsAgain();
    console.log("Fetched SMS again!");
  }, 15000);*/
    //this.fetchSMSLogsAgain();
  }

  fetchSMSLogsAgain() {
    let contactObj = {
      phone: this.twilio_to,
      acc_sid: this.account_sid,
      auth_token: this.auth_token,
    };
    this.contactService
      .fetchSMSLogs(contactObj)
      .then((smsData) => {
        let activities = smsData.data;
        //  console.log(this.new_sms_array);
        //let act = sessionStorage.getItem('activities' + this.twilio_to);
        //  sessionStorage.setItem('activities' + this.twilio_to, JSON.stringify({"creation_timestamp": Date.now(), "logs": activities}));
        //  console.log("Session storage updated.");
      })
      .catch((error) => {
        console.log(error);
        this.alertService.error(error.message);
      });
  }
  /*sendMessage(){
  if(msg == undefined){
    this.selectedMsg = "";
  }
  if(this.calendarLink !== undefined){
    this.selectedMsg = msg + ' '+ this.calendarLink + ' .';
  }
  if(this.optOut == true){
    this.selectedMsg = msg +' '+this.optOutMsg;
  }
  this.messageObj = {
    'from' : this.twilio_from,
    'to' : this.twilio_to,
    'acc_sid' : this.account_sid,
    'auth_token':this.auth_token,
    'msg': this.selectedMsg
  }
  console.log(msg);
  console.log(this.messageObj);
  //this.confirmSend = true;
  this.contactService.sendSms(this.messageObj);
  this.messagedialogRef.close();
}*/

  // insertDate(msg, date, startTime){
  //   let stringDate = moment(date).toDate();
  //   console.log("this is converted date"+this.stringDate);
  //   this.dateItems['date'] = stringDate;
  //   let time = moment(startTime, 'h:mm a').format('h:mm a');
  //   //this.dateItems['time'] = time;

  //   if(startTime == undefined){
  //     this.populateTime();
  //     time = this.defaultTime;
  //     this.dateItems['time'] = time;
  //   }
  //   else{
  //     this.dateItems['time'] = time;
  //   }

  //     let curlyBraces = /{{[0-9a-zA-Z]+?}}/g;
  //     if(this.selectedMsg.match(curlyBraces) === null){
  //       this.selectedMsg = this.selectedMsg + stringDate + ' ' + time;
  //     }
  //     while(this.matchedString = curlyBraces.exec(msg)){
  //       let dateStrings = this.matchedString[0].slice(2, -2);
  //       for(let i = 0; i< dateStrings.length; i++){
  //         if(dateStrings == 'Date'){
  //           this.selectedMsg = this.selectedMsg.replace(this.matchedString[0], this.dateItems['date']);
  //         }
  //         if(dateStrings == 'Time'){
  //           this.selectedMsg = this.selectedMsg.replace(this.matchedString[0], this.dateItems['time']);
  //         }
  //       }
  //     }
  // }

  insertDate(msg, date, startTime) {
    let stringDate = moment(date).format("MMM DD, YYYY");
    this.dateItems["date"] = stringDate;
    let time = moment(startTime, "h:mm a").format("h:mm a");
    //this.dateItems['time'] = time;

    if (startTime == undefined) {
      this.populateTime();
      time = this.defaultTime;
      this.dateItems["time"] = time;
    } else {
      this.dateItems["time"] = time;
    }

    let curlyBraces = /{{[0-9a-zA-Z]+?}}/g;
    if (this.selectedMsg.match(curlyBraces) === null) {
      this.selectedMsg = this.selectedMsg + stringDate + " " + time;
    }
    while ((this.matchedString = curlyBraces.exec(msg))) {
      let dateStrings = this.matchedString[0].slice(2, -2);
      for (let i = 0; i < dateStrings.length; i++) {
        if (dateStrings == "Date") {
          this.selectedMsg = this.selectedMsg.replace(
            this.matchedString[0],
            this.dateItems["date"]
          );
        }
        if (dateStrings == "Time") {
          this.selectedMsg = this.selectedMsg.replace(
            this.matchedString[0],
            this.dateItems["time"]
          );
        }
      }
    }
  }

  populateTime() {
    this.workingTimes = [];
    let halfHours = ["00", "30"];
    for (let i = 8; i < 21; i++) {
      for (let j = 0; j < 2; j++) {
        //  console.log(moment((i + ":" + halfHours[j]), 'h:mm a').format('h:mm a'));
        let workingTime = moment(i + ":" + halfHours[j], "h:mm a").format(
          "h:mm A"
        );
        if (i < 10) {
          workingTime = "0" + workingTime;
        }
        this.workingTimes.push(workingTime);
      }
    }
    this.defaultTime = this.workingTimes[0];
    //  console.log(this.workingTimes);
  }

  close() {
    this.messagedialogRef.close();
  }
}
