<!-- <app-filters [filters]="filters" (filteredArray)="setFilters($event)"></app-filters> -->

<div class="container-fluid view">
  <div class="row">
    <div class="col-md-12">
      <button mat-mini-fab class="format" (click)="openDialogForContact()" matTooltip="Add New Contact">
        <i class="material-icons add">add</i>
      </button>

      <button mat-mini-fab class="format" (click)="showImportSection()" matTooltip="Import Contacts">
        <i class="material-icons">save_alt</i>
      </button>

      <mat-slide-toggle class="formatToggle" (change)="showEnableContactsOnly()" name="enableContacts"
        [(ngModel)]="checked">{{ checked ? "All contacts" : "Only active contacts" }}
      </mat-slide-toggle>
      <!--
      <mat-form-field>
        <input matInput placeholder="Filter" (change)="filteredDataSource()" [(ngModel)]="Name" name="Name" >
      </mat-form-field> -->
      <!--<i class="material-icons">import_contacts</i></button>-->

      <button *ngIf="selection.selected.length > 1 && twilio_from !== undefined" mat-mini-fab class="format"
        (click)="bulkMessage()" matTooltip="Send Message To Multiple Contacts">
        <i class="material-icons">message</i>
      </button>

      <button mat-mini-fab class="format" [routerLink]="['/import-history']" (click)="toggleImportHistory()"
        matTooltip="Import History" style="float: right;">
        <i class="material-icons">history</i>
      </button>

      <button mat-mini-fab class="format" *ngIf="showImportHistory" (click)="toggleImportHistory()"
        matTooltip="Contacts" style="float: right;">
        <!-- matSortActive="last_visited" matSortDirection="desc" matSortDisableClear -->
        <i class="material-icons">person</i>
      </button>
      <!-- *ngIf="showContacts"
       -->
    </div>
  </div>
  <div class="row contact-import" *ngIf="toImport">
    <div class="col-12 sub-section">
      <strong>Import Contacts</strong>

      <i class="material-icons icon-btn closed-icon" (click)="hideImportSection()">close</i>
    </div>
    <div class="col-12 sub-section">
      <!--
      <input class="form-control" type='file' id='fileInput' (change)="checkFileSelection()" #input>
    -->
      <input type="file" name="fileInput" id="fileInput" class="form-control inputfile" (change)="checkFileSelection()"
        #input />
      <label for="fileInput">Choose a file
        <i class="material-icons icon-btn choose-file">insert_drive_file</i></label>
      <span class="import-file-name">{{ importFileName }}</span>
      <i class="material-icons icon-btn clear-file" matTooltip="Clear file" (click)="resetFile()">delete</i>
    </div>

    <div class="col-12 sub-section">
      <button mat-raised-button color="primary" (click)="parseFile()" [disabled]="!importFileSelected">
        Import
      </button>
    </div>

    <div class="col-12 sub-section" *ngIf="displayProgress && !importComplete">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <br />
    <div class="col-12 sub-section" *ngIf="importComplete">
      {{ importContactResult }}
    </div>
  </div>

  <div *ngIf="showContacts">
    <!--  <center>
      <div *ngIf="!contactsFetched || !currentAcc">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <p style="text-align: center;">Loading...</p>
    </div>
  </center> -->
    <!-- <div *ngIf="contactsFetched && currentAcc"> -->

    <!-- <div *ngIf="dataSource.notLoading$ | async"> -->
    <!-- <div *ngIf="isShow; else notShow"> -->
    <div>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" />
      </mat-form-field>

      <p *ngIf="selection.hasValue() && isAllSelected()">
        All {{ filteredRowsCount }} contacts on this page are selected. Select
        all {{ allRowsCount }} contacts.
      </p>

      <!-- <button mat-mini-fab class="format" matTooltip="Custom filters" (click)="hideCustomFilterSection()"><i class="fa fa-filter" style="padding:0px 5px 0px 10px;"></i></button>
   -->
      <!-- <mat-form-field class="example-chip-list">
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let  keyword of keywords"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(keyword)">{{keyword}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
   -->
      <!-- <input
        placeholder=""
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
        disabled> -->
      <!--
    </mat-chip-list>
  </mat-form-field>
   -->

      <!--  <div class="row contact-import" *ngIf="customFilter">
      <div class="col-12 sub-section">
        <strong>Custom Filters</strong>

   -->
      <!-- <i class="material-icons icon-btn closed-icon" (click)="hideCustomFilterSection()">close</i> -->

      <!-- </div> -->

      <!--  <div class="col-md-12">
          <mat-label class="custom_filter_labels_last_visited">Last visited</mat-label>
            <mat-form-field  class="mat-form-field_style">
              <mat-select [formControl]="operatorsForVisitedOn"  (selectionChange)="showOperatorSelectedDateLastVisited($event.value)" matNativeControl required>
               <mat-option *ngFor="let operator of operators" [value]="operator">{{operator}} </mat-option>
      -->

      <!-- <mat-option value="is_same_as">is same as</mat-option>
                <mat-option value="greater_than">greater than</mat-option>
                <mat-option value="less_than">less than</mat-option> -->

      <!--             </mat-select>
            </mat-form-field>

          <mat-form-field   class="mat-datepicker_styling">
            <input matInput [matDatepicker]="picker2" [(ngModel)]="dateVisited"   name="dateVisited"
            (dateChange)='showCreatedOnAndLastVisited()' [max]="maxDate" placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
      </div>
   -->

      <!--     <br>
   -->
      <!--           <div class="col-md-12">
            <mat-label class="custom_filter_labels_last_visited">Created on</mat-label>
             <mat-form-field class="mat-form-field_style">
                <mat-select [formControl]="operatorsForCreatedOn" (selectionChange)="showOperatorSelectedDateCreatedOn($event.value)" >
                   <mat-option *ngFor="let operator of operators" [value]="operator"> {{operator}} </mat-option>
   -->

      <!-- <mat-option value="greater_than">is same as</mat-option>
                  <mat-option value="greater_than">greater than</mat-option>
                  <mat-option value="less_than">less than</mat-option> -->

      <!--               </mat-select>
              </mat-form-field>
              <mat-form-field   class="mat-datepicker_styling">
                <input matInput [matDatepicker]="picker1"  [(ngModel)]="dateCreated" name="dateCreated" (dateChange)='showCreatedOnAndLastVisited()'  [max]="maxDate" placeholder="Choose a date">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
   -->
      <!-- </div> -->
      <!-- <br>
     <div class="col-12 sub-section customFilterApplyButton">
        <button mat-raised-button color="primary"  (click)="customFilterFunctionality()" >Apply</button>
      </div>


     </div> -->
      <!-- customFilterFunctionality()  customFilterPredicateFunction()-->

      <!-- <button mat-button (click)="bulkMessage()">Bulk Message</button> -->
      <div class="mat-elevation-z8" style="margin:20px;">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortActive="last_visited"
          matSortDirection="desc" matSortDisableClear>
          <!-- <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay"  > -->
          <!-- <container-element [ngSwitch]="column">{{filteredDataSource}} -->
          <!-- <span *ngIf="column !== 'select'; else checkboxCol"> -->
          <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              First Name
            </th>
            <td mat-cell *matCellDef="let contact" class="mid">
              <!-- <span *ngSwitchCase="'first_name'"> -->
              <input class="myinput" matInput placeholder="Enter" [value]="contact['first_name']" (change)="
                  updateValue($event.target.value, 'first_name', contact)
                " [disabled]="contact['active'] === false" />
            </td>
          </ng-container>
          <!-- </span> -->

          <ng-container matColumnDef="last_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
            <td mat-cell *matCellDef="let contact" class="mid">
              <!-- <span *ngSwitchCase="'last_name'"> -->
              <input class="myinput" matInput placeholder="Enter" [value]="contact['last_name']" (change)="
                  updateValue($event.target.value, 'last_name', contact)
                " [disabled]="contact['active'] === false" />
            </td>
            <!-- </span> -->
          </ng-container>

          <!-- <span *ngSwitchCase="'email'"> -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let contact" class="mid">
              <input class="myinput" matInput placeholder="Enter" [value]="contact['email']"
                (change)="updateValue($event.target.value, 'email', contact)"
                [disabled]="contact['active'] === false" />
            </td>
          </ng-container>
          <!-- </span> -->

          <!-- <span *ngSwitchCase="'phone'"> -->
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
            <td mat-cell *matCellDef="let contact" class="mid">
              <input class="myinput" matInput placeholder="Enter" [value]="contact['phone']"
                (change)="updateValue($event.target.value, 'phone', contact)"
                [disabled]="contact['active'] === false" />
            </td>
          </ng-container>
          <!-- </span> -->

          <!-- <ng-cotainer *ngIf="providerCount>0"> -->
          <!-- <span > -->
          <!-- <span  *ngSwitchCase="'primary provider'"  > -->
          <ng-container matColumnDef="primary provider">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Primary Provider
            </th>
            <td mat-cell *matCellDef="let contact" class="mid">
              <mat-form-field *ngIf="contact.providerData !== undefined">
                <mat-select name="provider" (selectionChange)="
                    changePrimaryProvider(contact.providerData.name, contact)
                  " [(ngModel)]="contact.providerData.name">
                  <!-- <mat-option [value]=undefined||null selected hidden></mat-option> -->
                  <mat-option *ngFor="let provider of providerArray" [value]="provider.providerName">
                    {{ provider.providerName | titlecase }}
                  </mat-option>
                  <!-- <mat-option [value]=''></mat-option> -->
                </mat-select>
                <!-- <ng-template #noProvider>{{ 'none' }}</ng-template> -->
              </mat-form-field>
              <!--  <input  class="myinput" matInput placeholder="Enter" *ngIf="contact.providerData !== undefined" [value]="contact.providerData.name"> -->
              <!-- </span> -->
            </td>
          </ng-container>
          <!-- </span> -->
          <!-- </ng-container> -->

          <!-- <span *ngSwitchCase="'send_text_reminders'"> -->
          <ng-container matColumnDef="send_text_reminders">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Send Text Reminders
            </th>
            <td mat-cell *matCellDef="let contact" class="mid">
              <mat-slide-toggle [disabled]="contact['active'] === false" name="send_text_reminders"
                [(ngModel)]="contact['send_text_reminders']" style="vertical-align : middle;" (change)="
                  saveSendTextReminders(
                    contact['send_text_reminders'],
                    contact['ref']
                  )
                ">
              </mat-slide-toggle>

              <i *ngIf="
                  contact['send_text_reminders'] == true &&
                  twilio_from !== undefined &&
                  contact['active'] !== false
                " disabled matTooltip="Send SMS now" class="material-icons"
                style="vertical-align : middle;padding-left:20px;cursor : pointer;"
                (click)="singleMessage(contact)">message</i>
            </td>
          </ng-container>

          <!-- </span> -->

          <!-- <span *ngSwitchCase="'last_visited'" class="matCalender "> -->

          <!--                           <input matInput [matDatepicker]="dp" [max]="maxDate"
                                 [(ngModel)]="contact.last_visited"  placeholder="choose a date"(dateChange)="updateLastVisited(contact.ref,contact.last_visited)" [(ngModel)]="contact.last_visited" [disabled]="contact['active']===false ">
                                 <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                 <mat-datepicker #dp></mat-datepicker> -->

          <!--                            {{contact.last_visited | date:'MMM d, yyyy'}}

                                  <input [disabled]="contact['active']===false" matInput [(ngModel)]="contact.last_visited" [max]="maxDate" [matDatepicker]="picker" (dateChange)="updateLastVisited(contact.ref,contact.last_visited)" style="width:1px;visibility: hidden;">
                                  <mat-datepicker-toggle [disabled]="contact['active']===false" matSuffix [for]="picker" ></mat-datepicker-toggle >
                                  <mat-datepicker   #picker disabled="false" [opened]=false  ></mat-datepicker>
                              -->

          <ng-container matColumnDef="last_visited">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Last Visited
            </th>
            <td mat-cell *matCellDef="let contact" class="mid">
              <mat-form-field style="width:70px; margin-top:10px;">
                <input matInput [matDatepicker]="picker" style="font-size:12px; font-weight:200;"
                  [disabled]="contact['active'] === false" [(ngModel)]="contact.last_visited" [max]="maxDate"
                  (dateChange)="
                    updateLastVisited(contact.ref, contact.last_visited)
                  " placeholder="Choose a date" (click)="_openCalendar(picker)" #elementToFocus />

                <mat-datepicker #picker disabled="false" [opened]="false"
                  (closed)="_closeCalendar($event, contact_ref, refLast_visited)"
                  [disabled]="contact['active'] === false">
                </mat-datepicker>
              </mat-form-field>
            </td>
          </ng-container>

          <!--{{contact.last_visited | date:'MMM d, yyyy'}}   -->
          <!-- <app-date-picker
                                      [(ngModel)]="contact.last_visited"
                                      format="YYYY/MM/DD HH:mm:ss"
                                    ></app-date-picker> -->

          <!-- </span> -->

          <!-- <span *ngSwitchCase="'birthday'" class="matCalender "> -->
          <ng-container matColumnDef="birthday">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Birthday </th>
            <td mat-cell *matCellDef="let contact" class="mid">
              <!-- <mat-form-field style="width:70px; margin-top:10px;">
                                   <input matInput [matDatepicker]="picker" style="font-size:12px; font-weight:200;"
                                         [disabled]="contact['active']===false"
                                         [(ngModel)]= contact.birthday
                                         [max]="maxDate"
                                         (dateChange)="updateBirthDate(contact.ref,contact.birthday)"
                                         placeholder="Choose a date"
                                         (click)="_openCalendar(picker)" #elementToFocus
                                         [formCOntrol]="date">

                                  <mat-datepicker #picker="matDatepicker" disabled="false" [opened]=false (closed)="_closeCalendar(e,contact_ref,refLast_visited)" [disabled]="contact['active']===false "></mat-datepicker>
                                </mat-form-field> -->
              <input class="myinput" matInput type="text" name="input" placeholder="MM-DD-YYYY" required
                [value]="contact.birthday | date:'MM/dd/yyyy' "
                pattern="((?:0\[1-9\]|1\[0-9\]|2\[0-9\])|(?:(?!02)(?:0\[1-9\]|1\[0-2\]) /(?:30))|(?:(?:0\[13578\]|1\[02\])-31)/?:19|20)\[0-9\]{2}-(?:(?:0\[1-9\]|1\[0-2\]))"
                (ngModel)="contact.birthday" (change)="updateBirthDate(contact.ref,$event.target.value,contact)"
                title="Enter a date in this format MM/DD/YYYY" />


              <span *ngIf="contact.invalidBirthDate">
                Invalid Date
              </span>
            </td>
          </ng-container>
          <!-- </span> -->

          <!-- <span *ngSwitchCase="'created_on'"> -->
          <ng-container matColumnDef="created_on">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Created On
            </th>
            <td mat-cell *matCellDef="let contact" class="mid">
              {{ contact.created_on | date: "MMM d, yyyy" }}
            </td>
          </ng-container>
          <!-- </span> -->

          <!-- <ng-template #othercolumn> -->
          <!-- <input class="myinput" matInput placeholder="Enter" [value]="contact[column]" (change)="updateValue($event.target.value, column, contact)" [disabled]="contact['active']===false"> -->
          <!--</mat-form-field>
                          </ng-template> -->

          <!-- <span  *ngSwitchCase="'active'"> -->
          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
            <td mat-cell *matCellDef="let contact" class="mid">
              <mat-slide-toggle class="formatToggle" name="greyRow" [(ngModel)]="contact.active"
                (change)="updateRowVisible(contact, contact_ref)"></mat-slide-toggle>
            </td>
          </ng-container>
          <!--
                                   *ngIf="contact['active']===true" [checked]="checked" -->

          <!--  <i class="fa fa-trash" (click)="updateRowVisible(contact,contact_ref)"
                          aria-hidden="true"></i>
           -->
          <!--                         <button mat-button (click)="updateRowVisible(contact_ref)"> Delete</button>
           -->
          <!-- </span> -->
          <!--     <span *ngSwitchDefault>
                              <input class="myinput" matInput placeholder="Enter" [value]="contact[column]" (change)="updateValue($event.target.value, column, contact)" [disabled]="contact['active']===false">

                           </span>
   -->
          <!-- </td> -->
          <!-- </span> -->
          <!-- </container-element> -->

          <!-- <ng-template #checkboxCol> -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="
                  $event ? masterToggle() : null && contact['active'] === false
                " [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell class="mid" *matCellDef="let response">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(response) : null"
                [checked]="selection.isSelected(response) && response.active" [disabled]="response.active === false">
                <!-- ;isSomeSelected()  -->
                <!--
                                       [indeterminate]="contact[disabled]===true" -->
                <!-- [aria-label]="checkboxLabel(response)"> -->
              </mat-checkbox>
            </td>
            <!-- </ng-template> -->
          </ng-container>

          <!--
                <div class="form-group">
                  <label for="full_phone">numberSelected:</label>
                  <div *ngFor="let fp of full_phone">

                </div>
              -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let contact" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="
                  contact == expandedElement ? 'expanded' : 'collapsed'
                ">
                <div class="example-element-description">
                  <!--<app-activities *ngIf="expandedElement" [contact]="contact"></app-activities>-->
                  <div *ngIf="contact == expandedElement">
                    <!--<app-contact-notes [contact]="contact['ref']"></app-contact-notes>
                          <app-smslogs [contact]="contact"></app-smslogs>-->
                    <applet> </applet>
                    <app-activities [contact]="contact"></app-activities>
                    <!--<app-fetch-appointments [contact]="contact['appointments']"></app-fetch-appointments>-->
                  </div>

                  <!--  <mat-list role="list">

                              <mat-list-item role="listitem" class="space" *ngFor="let appointment of contact.appointments">
                                  <div class="row">
                                      <div class="col-4">
                                          <div [ngSwitch]="appointment.source">
                                              <div class="img_container" *ngSwitchCase="'website'">
                                                  <i class="fa fa-globe"></i>
                                              </div>
                                              <div class="img_container" *ngSwitchCase="'facebook'">
                                                  <i class="fa fa-facebook"></i>
                                              </div>
                                              <div class="img_container" *ngSwitchCase="'google'">
                                                  <i class="fa fa-google"></i>
                                              </div>
                                          </div>
                                          <div class="all-text">{{appointment.appt_start | date:'MMM d'}}</div>

                                          <div class="status">{{appointment.status | titlecase}}</div>
                                      </div>
                                      <div class="col-5">
                                          <div class="all-text notes">
                                              <mat-form-field>
                                                  <textarea matInput placeholder="Notes" name="notes" [(ngModel)]="appointment.notes" (change)="saveNotes(appointment.ref, appointment.notes)">{{appointment.notes}}</textarea>
                                              </mat-form-field>
                                          </div>
                                      </div>
                                      <div class="col-3">
                                          <div class="all-text value">
                                              <mat-form-field>
                                                  <span matPrefix>&#36;&nbsp;</span>
                                                  <!--<input matInput placeholder="Billed Amount" name="value" [ngModel]="appointment.value | currency:'USD':'symbol':'1.0-0'" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="appointment.value=$event; saveValue(appointment.ref, appointment.value, $event)">-->
                  <!--<input matInput placeholder="Billed Amount in $" name="value" [ngModel]="appointment.value" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="appointment.value=currencyInputChanged($event, appointment.ref)">-->

                  <!--    <input matInput [(ngModel)]="appointment.billed_amount" maxlength="" placeholder="Billed Amount" (change)="saveBilledAmount(appointment.ref, appointment.billed_amount)">

                                                  <!--<input matInput type="text" name="txtamount" [(ngModel)]="appointment.amount"
                                                      (blur)="transformAmount($event, appointment)" [(value)]="appointment.amount" (change)="saveCurr(appointment.ref, appointment.amount)">-->
                  <!--      </mat-form-field>

                                                <!-- <input matInput [(ngModel)]="appointment.billed_amount" maxlength="" placeholder="Billed Amount" (change)="saveBilledAmount(appointment.ref, appointment.billed_amount)">

                                                  <!--<input matInput type="text" name="txtamount" [(ngModel)]="appointment.amount"
                                                      (blur)="transformAmount($event, appointment)" [(value)]="appointment.amount" (change)="saveCurr(appointment.ref, appointment.amount)">-->
                  <!--  </mat-form-field>

                                          </div>
                                      </div>
                                  </div>
                                  </mat-list-item>
                                  <div class="space"></div>
                              </mat-list>-->
                </div>
              </div>
            </td>
          </ng-container>

          <!--             <ng-container matColumnDef="excluded">
                <th mat-header-cell *matHeaderCellDef> Excluded </th>
                <td mat-cell *matCellDef="let element"> {{element.rowVisible}} </td>
              </ng-container>
   -->

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let contact; columns: columnsToDisplay" [ngStyle]="
              contact.active === false && { 'background-color': '#E7E7E7' }
            " class="example-element-row" [class.example-expanded-row]="expandedElement === contact" (click)="
              expandedElement = expandedElement === contact ? null : contact
            ">
            <mat-cell *matCellDef="let contact" [style.display]="'none'">
              {{ contact.last_visited }}
            </mat-cell>
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

          <!-- <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

                  [ngStyle]="contact.rowVisible && {'background-color:grey'}"

              <tr mat-row *matRowDef="let contact; columns: columnsToDisplay;"
                  class="example-element-row"
                  [class.example-expanded-row]="expandedElement === contact"
                  (click)="!rowVisible && selection.toggle(row)"
                  [ngstyle]="row.rowVisible && {'background':'lightgrey'}"
                  (click)="expandedElement = expandedElement === contact ? null : contact">
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
        </table>
        <!--
  <br><br><br>
  <input currencyMask [(ngModel)]="amount" [options]="{ precision: 0, thousands: '.', decimal: ',' }" maxlength="5" (blur)="transformAmount($event)" [(value)]="amount">
  <h1>{{formattedAmount}}</h1>
  -->
        <!--
  <br><br><br>
  <input type="text" name="txtamount" [(ngModel)]="amount"
          (blur)="transformAmount($event)" [(value)]="amount"/>
   <br/>
   <br/>
   <div>
      Formatted Amount on loosing focus: {{ formattedAmount }}
   </div>
  -->
        <!--<br>
   <div>
           <label for="c1">Currency</label>
           <input type="number" value="1000" min="0" step="0.01" data-number-to-fixed="2" data-number-stepfactor="100" class="currency" id="c1" />
       </div>-->

        <!-- <mat-paginator [pageSizeOptions]="[20, 10, 50, 100]"></mat-paginator> -->
        <mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
      </div>
    </div>
    <!-- </div>  -->
    <!-- <ng-template #notShow>
    <br><p class="no-contacts">No Contacts</p>
</ng-template> -->
  </div>
  <div *ngIf="showImportHistory" style="margin-top: 20px;">
    <app-import-history></app-import-history>
  </div>
</div>