import {FormControl} from '@angular/forms';

export class CustomValidators {

	static validateRequired(formCtrl: FormControl)
	{
		let isRequired = null;
		if(formCtrl.value.length === 0)
		{
			isRequired = {required:true};
			return isRequired;
		}
		else{
			return isRequired;
		}
	}

}