import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from '../../environments/environment';

@Injectable()
export class AdWordsService {

  temp_campaigns: any;

  constructor(
    private http: HttpClient
  ) { }

  formatDate(timeperiod: any) {
    let timeperiod_formatted = {'start': '', 'end': ''};

    timeperiod_formatted.start = (new DatePipe('en-US')).transform(timeperiod.start, 'yyyyMMdd');
    timeperiod_formatted.end = (new DatePipe('en-US')).transform(timeperiod.end, 'yyyyMMdd');

    return timeperiod_formatted;

  }

  getAccountSpend(cust_id: string, refreshToken: string, timeperiod: any) {
    let timeperiod_formatted = this.formatDate(timeperiod);
    return this.http.post(`${environment.serverURL}/adwords-account-report.php`, JSON.stringify({"cust_id": cust_id, "refreshToken": refreshToken, "timeperiod": timeperiod_formatted}));
  }

  getCampaigns(cust_id: string, refreshToken: string, timeperiod: any) {
    let timeperiod_formatted = this.formatDate(timeperiod);
    return this.http.post(`${environment.serverURL}/adwords-campaign-report.php`, JSON.stringify({"cust_id": cust_id, "refreshToken": refreshToken, "timeperiod": timeperiod_formatted}));
  }

  getCampaign(id: string, timeperiod: any, acc_name: string) {
    let obj = JSON.parse(sessionStorage.getItem('adwords-all-' + acc_name));
    return obj.results.find(x => x['@attributes'].campaignID === id);
  }


}
