<div class="container-fluid view">
  <div class="row text-center" id="subHeader1">
    <div class="col-md-4" style="margin: auto;">
      <div class="searchContainer_new">
        <!-- <i class="fa fa-search searchIcon"></i>
        <input class="searchBox" [disabled]="'true'" type="text" name="search" placeholder="Search Contacts"> -->
        <!-- (input)="dt.filterGlobal($event.target.value, 'contains')" -->
      </div>
    </div>
    <div class="col-md-4" style="margin: 2.5% auto;">
      <div id="pageTitle" class="center">Local Search Rankings</div>
    </div>
    <div class="col-md-4" style="display: flex;flex-direction: row-reverse;">
      <button *ngIf="!showSettings && !showHistory" mat-mini-fab id="button" class="format" (click)="showSettings=true"
        matTooltip="Edit Configurations">
        <i class="material-icons">settings</i>
      </button>
      <button *ngIf="showSettings || showHistory" mat-mini-fab id="button" class="format"
        (click)="showSettings=false;showHistory= false" matTooltip="Close">
        <i class="material-icons">close</i>
      </button>
      <button *ngIf="!showSettings && !showHistory" mat-mini-fab id="button" class="format" (click)="showHistory = true"
        matTooltip="History">
        <i class="material-icons add">history</i>
      </button>
      <button *ngIf="!showSettings && !showHistory" mat-raised-button id="button" class="format"
        (click)="processLocalRanks()" [disabled]="!enableGetLocalRank" [matTooltip]="processingLocalRanks?'Already started processing please wait':'Get Local Ranks'">Get Local Ranks
      </button>
    </div>
    <div *ngIf="showSettings" style="width:100%;">
      <app-set-localrank-task (close)="closeSettings($event)"></app-set-localrank-task>
    </div>
    <div *ngIf="showHistory" style="width:100%;">
      <app-local-rank-history [accountDetails]="accountDetails" (close)="closeHistory($event)">
      </app-local-rank-history>
    </div>
    <div *ngIf="!showSettings && !showHistory" style=" width: 100%;">
      <div class="row">
        <div class="col">
          <mat-tab-group [selectedIndex]="0">
            <div class="text-center">
              <mat-tab label="Overall Analysis" class="tabWidth" [selectedIndex]="1">
                <app-local-rank-analysis [currentResultDetails]="localPackResultDetails" [gotData]="loading">
                </app-local-rank-analysis>
              </mat-tab>
              <mat-tab label="Your Local Rankings" class="tabWidth" [selectedIndex]="2">
                <app-local-search-rankings [localPackResults]="targetKeywordsRanks" [accountDetails]='accountDetails'
                  [gotData]="loading">
                </app-local-search-rankings>
              </mat-tab>
              <mat-tab label="Competitor Rankings" class="tabWidth" [selectedIndex]="3">
                <app-local-ranks [targetkwResults]="targetKeywordsRanks" [gotData]="loading"></app-local-ranks>
              </mat-tab>
              <mat-tab label="Track Rankings" class="tabWidth" [selectedIndex]="4">
                <app-track-local-ranks [prevRankAnalysis]="rankAnalysis" [loading]="loading" ></app-track-local-ranks>
              </mat-tab>
            </div>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
