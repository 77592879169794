<style>
  ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex,
  ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex:focus {
    border-radius: 3px;
    padding: 0 4%;
    border: 1px solid grey;
    background-color: white !important;
  }

  ::ng-deep .mat-form-field-wrapper {
    padding-left: 1em;
    padding-bottom: 0 !important;
  }
</style>

<div class="row" id="subHeader1">
  <div id="pageTitle">Search Console</div>
</div>

<div *ngIf="!isReady">
  <mat-progress-spinner mode="indeterminate" style="margin:0 auto;"></mat-progress-spinner>
</div>
<div *ngIf="isReady && noData" id="noData">No data available for this account
</div>
<div *ngIf="isReady && !noData">
  <div>
    <div id="inputBox">
      <mat-label id="date" style="padding-left: 11%;">Date</mat-label>
      <mat-form-field appearance="fill">
        <mat-select [(ngModel)]="selectedValue" (selectionChange)="ShowData(selectedValue)">
          <mat-option *ngFor="let current_date of searchConsoleData" [value]="current_date">
            {{current_date.createdOn | date: "MMM d, yyyy"}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div id="inputBox" style="padding-left: 5%;">
      <mat-label id="date">Last 7 Days</mat-label>
      <div id="date_div">
        <div class="default_date">{{data.nextStartDate}} to {{data.nextEndDate}}</div>
      </div>
    </div>
    <div id="inputBox" style="padding-left: 5%;">
      <mat-label id="date">Previous 7 Days</mat-label>
      <div id="date_div">
        <div class="default_date">{{data.prevStartDate}} to {{data.prevEndDate}}</div>
      </div>
    </div>

    <div class="refresh" (click)="fetchLatestAnalysis()"><span class="material-icons">refresh</span></div>
  </div>
  <app-search-console-stats [totalAnalysis]="totalAnalysis"></app-search-console-stats>

  <mat-tab-group>
    <mat-tab label="TOP IMPRESSION GAINERS">
      <app-top-impression-gainer [data]="data" [dataSourceGain]="dataSourceGain">
      </app-top-impression-gainer>
    </mat-tab>
    <mat-tab label="TOP IMPRESSION LOSERS">
      <app-top-impression-loser [data]="data" [dataSourceLoss]="dataSourceLoss">
      </app-top-impression-loser>
    </mat-tab>
    <mat-tab label="RANKING ON FIRST PAGE">
      <app-ranking-on-first-page [data]="data" [dataSourceRank]="dataSourceRank"></app-ranking-on-first-page>
    </mat-tab>
    <mat-tab label="DROPPING FROM FIRST PAGE">
      <app-dropping-from-first-page [data]="data" [dataSourceDrop]="dataSourceDrop"></app-dropping-from-first-page>
    </mat-tab>
  </mat-tab-group>
</div>