
<!--
<form name="form" #trafficform="ngForm">
  <label for="url">URL</label>
  <input type="text" id="url" name="url" [(ngModel)]="model.url" />
  <button type="submit" (click)="fetchSummary()">Enter</button>
</form>

<form name="fbform" #fbform="ngForm">
  <label for="country">Country</label>
  <input type="text" id="country" name="country" [(ngModel)]="model.country" />

  <label for="gender">Gender</label>
  <input type="radio" id="gender" name="gender" [(ngModel)]="model.gender" value="male">Male
  <input type="radio" id="gender" name="gender" [(ngModel)]="model.gender" value="female">Female
  <input type="radio" id="gender" name="gender" [(ngModel)]="model.gender" value="both">Both<br>

  <label for="age">Age between</label>
  <input type="number" id="ageMin" name="ageMin" [(ngModel)]="model.ageMin" /> and
  <input type="number" id="ageMax" name="ageMax" [(ngModel)]="model.ageMax" /><br>

  <label for="objective">Objective</label>
  <input type="radio" id="objective" name="objective" [(ngModel)]="model.objective" value="REACH">REACH
  <input type="radio" id="objective" name="objective" [(ngModel)]="model.objective" value="LINK_CLICKS">LINK_ CLICKS<br>
  <button type="submit" (click)="fetchFBEstimates()">Get</button>
</form>
-->

  <section id="plan-summary">
    <!--
    <div class="row">
      <div class="col-sm">
        <h5 class="divider-new"> Marketing Plan Summary </h5>
      </div>
    </div>

    <div class="row">
      <div class="col-sm">


      </div>
    </div>
`   -->`

    <div class="container col-sm-12">
      <div class="row ">
        <!--
        <div class="col-sm-4">
        <input (change)="savePlanName()" [(ngModel)]="planName" type="text" class="form-control" placeholder="Untitled Plan" maxlength="50">
        </div>
      -->
        <div class="col-sm-3">
          <h6>Duration
             <section class="example-section">
               <label class="duration">3 months</label>
               <mat-slide-toggle  name="months" [checked]="months" [(ngModel)]="months" (change)="testChange($event)">6 months</mat-slide-toggle>
           </section>
         </h6>
       </div>


          <div class="col-sm-5">
            <h6>Budget</h6>
            <div class="range-field">
              <input type="range" [(ngModel)]="budget" class="range" name="range" min="1000" max="5000" value="1000" step="1000" onchange="range.value=value">
              <!--<input *ngIf="a!=3 && a!=6" type="range" [(ngModel)]="b" name="range" min="1000" max="5000" value="1000" step="1000" onchange="range.value=value"/>
              <input  *ngIf="a==6" type="range" [(ngModel)]="b" name="range" min="5000" max="25000" value="5000" step="5000" onchange="range.value=value"/>-->
              <label>{{budget | currency}}</label><h6>per month</h6>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <div *ngIf="!isReady">
        Loading your data....
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

  <div class="row" *ngIf="isReady">
    <div class="col-6 col-md-3">
      <h4 class="total_spend">{{duration * budget | currency}}</h4>
        <h6>Total Spend</h6>
    </div>
    <div class="col-6 col-md-3">
      <h4 class="clicks">{{tactics.clicks * ((duration * budget) / 1000) }}</h4>
        <h6>Clicks</h6>
    </div>

    <div class="col-6 col-md-3">
      <h4 class="impressions">{{ (tactics.impressions * ((duration * budget) / 1000)) }}</h4>
        <h6>Impressions</h6>
    </div>
    <div class="col-6 col-md-3">
      <h4 class="ctr">{{ tactics.ctr * 100 }}%</h4>
        <h6>CTR</h6>
    </div>
  </div>

  <hr>
  <div class="row" *ngIf="isReady">
    <div class="col-6 col-md-3">
      <h4 class="cpc">{{ tactics.cpc | currency }}</h4>
        <h6>CPC</h6>
    </div>
    <div class="col-6 col-md-3">
      <h4 class="cr">{{ tactics.cr * 100 | number }}%</h4>
        <h6 >CR</h6>
    </div>
    <div class="col-6 col-md-3">
      <h4  class="conversions">{{ tactics.conversions * ((duration * budget) / 1000) }}</h4>
        <h6>Conversions</h6>
    </div>
    <div class="col-6 col-md-3">
      <h4 class="cpa">{{ (budget * duration)  / (tactics.conversions * ((duration * budget) / 1000)) | currency }}</h4>
        <h6>CPA</h6>
    </div>
  </div>
 </section>

<!-- Breakdown of traffic estimates -->

<div class="col-sm" *ngIf="isReady">
  <div class="card card-block tactics-breakdown" >
    <h5 class="card-title">Estimates Breakdown</h5>
    <table class="table table-responsive">
      <thead>
        <tr>
         <th>Platform</th>
         <th>Allocation (%)</th>
         <th>Spend ($)</th>
         <th>Clicks</th>
         <th>Impressions</th>
         <th>CTR</th>
         <th>CPC</th>
         <th>CR</th>
         <th>Conversions</th>
         <th>CPA</th>
       </tr>
      </thead>
    <tbody>
      <tr *ngFor="let platform of tactics?.breakdown; let i = index">
       <td>{{ platform.name }}</td>
       <td>
         <input type="text" value="{{platform.allocation}}" [(ngModel)]="display[i]" (change)="callData(i)" >
         <!--{{ platform.allocation * 100 }}%</td>-->
       </td>
       <td>{{ allocation[i] * budget * duration }}</td>
       <td>{{ platform.clicks * ((duration * budget) / 1000) }}</td>
       <td>{{ platform.impressions * ((duration * budget) / 1000) | number }}</td>
       <td>{{ platform.ctr * 100 }}%</td>
       <td>{{ platform.cpc | currency }}</td>
       <td>{{ platform.cr * 100 | number }}%</td>
       <td>{{ platform.conversions * ((duration * budget) / 1000) | number }}</td>
       <td>{{ ((budget * allocation[i]) * duration  ) / (platform.conversions * ((duration * budget) / 1000)) | currency }}</td>
      </tr>
     </tbody>
    </table>

  </div>
</div>
<div class="row justify-content-end">
  <button  mat-raised-button class="m-2" id="savePlan" color="accent" type="button"  data-toggle="modal" data-target="#register" (click)="saveSpecifics()">Save Plan</button>
  <button mat-raised-button class="m-2" id="emailPlan" color="primary">Email Plan</button>
</div>

<!--Traffic summary and competitive analysis-->

<!--
<div class="row">
  <div class="col-sm">
    <h5 class="divider-new">Competitive Analysis </h5>
  </div>
</div>

<div class="row">
  <div class="col-sm">
    <div class="card card-block traffic-panel">
      <h4 class="card-title" >Traffic Summary</h4>
      <div class="row">
        <div class="col-sm">
          <h6>Global Rank:</h6>
        </div>
        <div class="col-sm">
          <h4>{{traffic?.rank}}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <h6>Estimated Visits:</h6>
        </div>
        <div class="col-sm">
          <h4>{{traffic?.visits}}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <h6>Pages per visit:</h6>
        </div>
        <div class="col-sm">
          <h4>{{traffic?.pages_per_visit}}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <h6>Avg duration:</h6>
        </div>
        <div class="col-sm">
          <h4>{{traffic?.avg_duration}}<span>sec</span></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <h6>Bounce Rate:</h6>
        </div>
        <div class="col-sm">
          <h4>{{traffic?.bounce_rate}}<span>%</span></h4>
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm">
    <div class="card card-block traffic-trends">
      <h4 class="card-title">Traffic Trends </h4>
      <app-traffic-trends></app-traffic-trends>
    </div>
  </div>

  <div class="col-sm">
    <div class="card card-block device-breakout">
      <h4 class="card-title"> Device Breakout (in %)</h4>
      <app-traffic-split-by-device></app-traffic-split-by-device>
    </div>
  </div>

  <div class="col-sm">
    <div class="card card-block geo-traffic">
      <h4 class="card-title"> Geo Traffic (in %)</h4>
      <app-traffic-geo></app-traffic-geo>
    </div>
  </div>

  <div class="col-sm">
    <div class="card card-block similar-sites">
      <h4 class="card-title"> Similar Sites</h4>
      <app-similar-sites></app-similar-sites>
    </div>
  </div>
</div>
-->
<!--
<div class="container">
  <div class="col-8 mx-auto">
    <h5 class="h5-responsive"><strong>For a Detailed Marketing Plan, </strong><a href="#">Contact Us</a></h5>
  </div>
</div>
-->
