<div class="close" (click)="close()">X</div>
<center><h4 mat-dialog-title>Import Contacts</h4></center>

<div mat-dialog-content>
  <input class="form-control" type='file' (change)='convertFile(input)' id='fileInput' #input>
</div>

<div *ngIf="displayProgress && !isReady">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<br>
<div *ngIf="isReady">
    {{ import_contact_result }}
</div>
<!--
<div *ngIf="getting_result">
  <div *ngIf=" import_contact_result.Status ">
    <p>Total contacts       :{{ import_contact_result.Total }} </p>
    <p>Total duplicates     :{{ import_contact_result.Total_Duplicates }}</p>
    <p>Newly added contacts :{{ import_contact_result.New_added_contacts }}</p>
    <p>Updated contacts     : {{ import_contact_result.Updated_Contacts }} </p>
  </div>
  <div *ngIf="import_contact_result.Status != 'True' ">
    <p [innerHTML]="import_contact_result.Error"></p>

  </div>

</div>
-->

<p *ngFor="let status_msg of status_msgs">
    {{ status_msg }}
</p>

<table>
    <div *ngIf="showTitle">
        <h5><b>{{ all_docs.added_docs.length }} contact(s) added</b></h5>
        <h8>These contacts are not in your list, so they have been added.</h8>
        <br>
        <!--<div *ngIf="all_docs.added_docs.length !== 0; else notShow">-->
        <div *ngIf="all_docs.added_docs.length !== 0;">
            <br>
            <tr>
                <th style="border:none;padding: 0px 15px;">First Name</th>
                <th style="border:none;padding: 0px 15px;">last Name</th>
                <th style="border:none;padding: 0px 15px;">Email</th>
            </tr>

    <tr *ngFor="let doc of all_docs.added_docs">
        <td style="padding: 0px 15px;">{{doc.first_name}}</td>
        <td style="padding: 0px 15px;">{{doc.last_name}}</td>
        <td style="padding: 0px 15px;">{{doc.email}}</td>
    </tr>
    </div>
    <h5><b><br>{{ all_docs.updated_docs.length }} contact(s) updated </b></h5>
    <h8>These contacts are already in your list, so they have been updated.</h8>

    <br>
    <!--<div *ngIf="all_docs.updated_docs.length !== 0; else notShow">-->
    <div *ngIf="all_docs.updated_docs.length !== 0;">
    <br>
    <tr>
        <th style="border:none;padding: 0px 15px;">First Name</th>
        <th style="border:none;padding: 0px 15px;">last Name</th>
        <th style="border:none;padding: 0px 15px;">Email</th>
    </tr>
    <tr *ngFor="let doc of all_docs.updated_docs">
        <td style="padding: 0px 15px;">{{doc.first_name}}</td>
        <td style="padding: 0px 15px;">{{doc.last_name}}</td>
        <td style="padding: 0px 15px;">{{doc.email}}</td>
    </tr>
    </div>
    <!--<h5><b><br>Unchanged</b></h5>
    <h8>These contacts are already in your list, and all the uploaded information was identical, so they have not been changed.</h8>
    <br>
    <div *ngIf="all_docs.not_changed_docs.length !== 0; else notShow">
    <br>
    <tr *ngFor="let doc of all_docs.not_changed_docs">
        <td class="space">{{doc.first_name}}</td>
        <td>{{doc.last_name}}</td>
        <td>{{doc.email}}</td>
    </tr>
    </div>-->
    </div>
    <!--<ng-template #notShow>
        <br>
        <h8>No Contacts</h8>
    </ng-template>-->
</table>
