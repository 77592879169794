<div class="row container-fluid justify-content-center view">
  <div class="row" id="inline" *ngIf="isAllow">
    <mat-form-field class="example-full-width">
      <span matPrefix *ngIf="acc.account_currency == 'INR'; else currency_usd">
        &#8377;&nbsp;
      </span>
      <!-- <input matInput placeholder="Value for each conversion" [(ngModel)]="conversion_value" [formControl]="conversion_value_field" (change)="setConversionValue()" pattern="[1-9]\d{0,5}"> -->
      <input matInput placeholder="Value for each conversion" [formControl]="conversion_value_field"
        (change)="setConversionValue()" pattern="[1-9]\d{0,5}">
      <mat-error *ngIf="conversion_value_field.hasError('pattern')">
        Conversion value should not be zero or negative.
      </mat-error>
      <ng-template #currency_usd>
        <span matPrefix>&#36;&nbsp;</span>
      </ng-template>

    </mat-form-field>
  </div>
  <!-- my comment
<div class="info-cards">
  <div *ngIf = "!acc.isDemo">
    <div class="row" *ngIf = "facebookCredentials; else connect_facebook">
      <div class="col-3">Facebook</div>
      <div class="col-5 mobile">{{facebookCredentials?.name}}<i class="fa fa-pencil" (click)="editFBAcc()"></i></div>
      <div class="col-4"><button mat-raised-button class="btn" color="primary" (click)="unlinkFBAcc()" [hidden]="showFBAccounts">Disconnect</button></div>
    </div>
    <ng-template #connect_facebook>
      <div class="row">
        <div class="col-3">Facebook</div>
        <div class="col-5"></div>
        <div class="col-4 connect"><button mat-raised-button class="btn" color="primary" (click)="linkFacebookAcc()" [hidden]="showFBAccounts">Connect</button></div>
      </div>
    </ng-template>
    <mat-card class="metric-card" *ngIf="showFBAccounts">
      <mat-card-header>
        <mat-card-title class="metric-caption">Select the account to link</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-radio-group class="example-radio-group" name="selectedFBAcc" [(ngModel)]="selectedFBAcc" (change)="saveFBAcc()">
          <mat-radio-button class="example-radio-button  col-sm" *ngFor="let account of fb_accounts" [value]="{'account_id': account.id, 'account_name': account.name}">
            {{ account.name }}
          </mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
    <mat-divider class="btn-space"></mat-divider>
    <div class="row" *ngIf = "adwordsCredentials; else connect_adwords">
      <div class="col-3">Adwords</div>
      <div class="col-5 mobile">{{adwordsCredentials?.adwords_account_name}}<i class="fa fa-pencil" (click)="editAdwordsAcc()"></i></div>
      <div class="col-4"><button mat-raised-button class="btn" color="primary" (click)="unlinkGoogleAccs(adwordsCredentials?.adwords_refresh_token)" [hidden]="showAdwordsAccounts">Disconnect</button></div>
    </div>
    <ng-template #connect_adwords>
    <div class="row">
      <div class="col-3">Adwords</div>
      <div class="col-5"></div>
      <div class="col-4 connect"><button mat-raised-button class="btn" color="primary" (click)="linkAdwordsAcc()" [hidden]="showAdwordsAccounts">Connect</button></div>
    </div>
    </ng-template>

    <mat-card class="metric-card" *ngIf="showAdwordsAccounts">
      <mat-card-header>
        <mat-card-title class="metric-caption">Select the account to link</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-radio-group class="example-radio-group" name="selectedAdwordsAcc" [(ngModel)]="selectedAdwordsAcc" (change)="saveAdwordsAcc()">
          <mat-radio-button class="example-radio-button  col-sm" *ngFor="let account of adwords_accounts" [value]="{account_id:account.id,account_name:account.name}">
            {{ account.name }}
          </mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>

    <mat-divider class="btn-space"></mat-divider>

    <div class="row" *ngIf="analyticsCredentials; else connect_analytics">
    <div class="col-3">Analytics</div>
      <div class="col-5 mobile">Account details<i class="fa fa-pencil" (click)="editAnalyticsAcc()"></i>
      </div>
      <div class="col-4"><button mat-raised-button class="btn" color="primary" (click)="unlinkGoogleAccs(analyticsCredentials?.analytics_refresh_token)" [hidden]="showAnalyticsAccounts">Disconnect</button></div>
  </div>
  <div class="row">
    <div class="col-3"></div>
    <div class="col-sm-1 mobile">Name:</div>
    <div class="col-sm-5">{{analyticsCredentials?.analytics_account_name}}</div>
  </div>
  <div class="row">
    <div class="col-3"></div>
    <div class="col-sm-1 mobile">Property:</div>
    <div class="col-sm-5">{{analyticsCredentials?.analytics_property_name}}</div>
  </div>
  <div class="row">
    <div class="col-3"></div>
    <div class="col-sm-1 mobile">View:</div>
    <div class="col-sm-5">{{analyticsCredentials?.analytics_view_name}}  {{analyticsCredentials?.ga_view_id}}</div>
  </div>
my comment-->
  <!--Dropdown-->
  <!--my comment
    <mat-card *ngIf="showAnalyticsAccounts">
      <div class="row">
    <div class="col-md-4">
   <mat-form-field class="container">
    <mat-select placeholder="Choose account" [(ngModel)]="selectedAnalyticsAcc" name="account">
     <mat-option *ngFor="let account of analyticsAcc" [value]="account">
      {{account?.name}}
      {{account?.id}}
    </mat-option>
  </mat-select>
  </mat-form-field>
  </div>
  <div class="col-md-4">
  <mat-form-field class="container">
   <mat-select placeholder="Choose properties" [(ngModel)]="selectedProperty"  name="account">
    <mat-option *ngFor="let property of selectedAnalyticsAcc.properties" [value]="property">
     {{property?.name}}
     {{property?.id}}
   </mat-option>
  </mat-select>
  </mat-form-field>
</div>
<div class="col-md-4">
  <mat-form-field class="container">
   <mat-select placeholder="Choose views" [(ngModel)]="selectedView" (change)="saveView()" name="account">
    <mat-option *ngFor="let views of selectedProperty?.views" [value]="{account_name:selectedAnalyticsAcc.name,property_name:selectedProperty.name,view_id:views?.ViewId,view_name:views?.name}">
     {{views?.name}}
     {{views?.ViewId}}
   </mat-option>
  </mat-select>
  </mat-form-field>

</div>
</div>
  </mat-card>
  <!--
  <mat-divider class="btn-space"></mat-divider>
  <div class="row" *ngIf = "acc.mailchimp_list_id; else connect_email">
    <div class="col-3">Email</div>
    <div class="col-5 mobile">{{acc.mailchimp_list_name}}<i class="fa fa-pencil" (click)="editMailChimpAcc()"></i></div>
    <div class="col-3"><button mat-raised-button class="btn" color="primary" (click)="unlinkMailChimpAcc()" [hidden]="showEmailLists">Disconnect</button></div>
  </div>
  <ng-template #connect_email>
    <div class="row">
      <div class="col-3">Email</div>
      <div class="col-5"></div>
      <div class="col-4 connect"><button mat-raised-button class="btn" color="primary" (click)="linkMailChimpAcc()" [hidden]="showEmailLists">Connect</button></div>
    </div>
  </ng-template>
  <mat-card class="metric-card" *ngIf="showEmailLists">
    <mat-card-header>
      <mat-card-title class="metric-caption">Select the list to link</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-radio-group class="example-radio-group" name="selectedEmailList" [(ngModel)]="selectedEmailList" (change)="saveEmailAcc()">
        <mat-radio-button class="example-radio-button  col-sm" *ngFor="let list of email_lists" [value]="{'list_id': list.id, 'list_name': list.name}">
          {{ list.name }}
        </mat-radio-button>
      </mat-radio-group>
    </mat-card-content>
  </mat-card>

</div>
-->
  <!--my comment
    <mat-divider class="btn-space"></mat-divider>
  <ng-template #connect_analytics>
  <div class="row">
    <div class="col-3">Analytics</div>
    <div class="col-5"></div>
    <div class="col-4 connect"><button mat-raised-button class="btn" color="primary" (click)="linkAnalyticsAcc()" [hidden]="showAnalyticsAccounts">Connect</button>
    </div>
  </div>
  </ng-template>
</div>
</div>
my comment-->
</div>
