<div class="row container-fluid justify-content-center">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <mat-tab-group>
        <mat-tab label="Manage Tips">

            <mat-card class="info-cards">
            <mat-card-title>
              <center><h2 class="login_header">Create Your Tip Here </h2></center>
            </mat-card-title>
            <mat-card-content>
              <!--<form #tipData='ngForm' class="example-form" (ngSubmit)="tipSubmit(tipData)">-->
              <form class="example-form" (ngSubmit)="AddTip()">

                <mat-form-field class="example-full-width">
                  <input matInput name="title" [(ngModel)]="tip.title" placeholder="Title" required />
                </mat-form-field>

                <mat-form-field class="example-full-width">
                  <input matInput name="type" [(ngModel)]="tip.type" placeholder="Type" required />
                </mat-form-field>

                <mat-form-field class="example-full-width">
                  <input matInput name="objective" [(ngModel)]="tip.objective" placeholder="Objective" required />
                </mat-form-field>

                <mat-form-field>
                  <mat-select placeholder="Select Channel" [(value)]="tip.channel" required >
                    <mat-option *ngFor="let channel of channels" [value]="channel.value">
                      {{ channel.viewValue }}
                  </mat-option>
                  </mat-select>
                </mat-form-field>

          <!--      <mat-form-field class="example-full-width">
                  <input matInput name="channel" [(ngModel)]="tip.channel" placeholder="channel" required />
                </mat-form-field>-->
                <br>
                <!--<mat-form-field>
                  <textarea matInput name="short_desc" [(ngModel)]="tip.short_desc" placeholder="Short Description" matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="2" required></textarea>
                </mat-form-field>-->

                <mat-form-field>
                  <textarea matInput name="short_desc" [(ngModel)]="tip.short_desc" placeholder="Short Description" required></textarea>
                </mat-form-field>
                <br>
                <mat-form-field>
                  <textarea matInput name="long_desc" [(ngModel)]="tip.long_desc" placeholder="Long Description" required></textarea>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <input matInput name="image1" [(ngModel)]="tip.image1" placeholder="Path of the image1" required />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <input matInput name="image2" [(ngModel)]="tip.image2" placeholder="Path of the image2" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <input matInput name="image3" [(ngModel)]="tip.image3" placeholder="Path of the image3" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <input matInput name="image4" [(ngModel)]="tip.image4" placeholder="Path of the image4" />
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <input matInput name="image5" [(ngModel)]="tip.image5" placeholder="Path of the image5" />
                </mat-form-field>

                <!--<mat-form-field class="example-full-width">
                  <input matInput name="image2" [(ngModel)]="tip.image" placeholder="Path of the image" required />
                </mat-form-field>-->

                <mat-form-field class="example-full-width">
                  <input matInput name="budget" [(ngModel)]="tip.budget" placeholder="Budget" required />
                </mat-form-field>

               <mat-form-field>
                  <mat-select placeholder="Select Target Gender" [(value)]="tip.target_gender" required >
                    <mat-option *ngFor="let gender of genders" [value]="gender.value">
                      {{ gender.value }}
                   </mat-option>
                  </mat-select>
                </mat-form-field>

              <!--  <mat-form-field class="example-full-width">
                  <input matInput name="gender" [(ngModel)]="tip.target_gender" placeholder="Target Gender" required />
                </mat-form-field>

                <mat-form-field class="example-full-width">
                  <input matInput name="target_age" [(ngModel)]="tip.target_age" placeholder="Target Age" required />
                </mat-form-field>-->
  <!--      <div>
        <label>Target Gender :</label>
                <mat-checkbox>Men</mat-checkbox>
                <mat-checkbox>Women</mat-checkbox>
                <mat-checkbox>Both</mat-checkbox>
              </div>-->

                <mat-form-field class="example-full-width">
                  <input matInput name="target_location" [(ngModel)]="tip.target_location" placeholder="Target Location" required />
                </mat-form-field>

                <mat-form-field class="example-full-width">
                  <input matInput name="target_notes" [(ngModel)]="tip.target_notes" placeholder="Target Notes" required />
                </mat-form-field>

              <!--  <mat-form-field>
                  <mat-select name="" placeholder="Select Age Group" [(value)]="tip.target_age" required>
                    <mat-option *ngFor="let age_group of age_groups" [value]="age_group.value">
                      {{ age_group.viewValue }}

                   </mat-option>
                  </mat-select>
                </mat-form-field>-->
                <mat-form-field class="example-full-width">
                  <input matInput name="target_age" [(ngModel)]="tip.target_age" placeholder="Target Age" required />
                </mat-form-field>

                <div>
                  <center>
                    <button mat-raised-button color="primary" type="submit">Submit</button>
          <!--  <button mat-raised-button (click)="onCancel()" color="">Cancel</button>-->
          <!--<button mat-raised-button type="cancel" color="">Cancel</button>-->
                  </center>
                </div>
        </form>
        </mat-card-content>
        </mat-card>
        </mat-tab>
        <mat-tab label="Manage Users">
    </mat-tab>
  </mat-tab-group>
  </div>
  </div>
</div>
