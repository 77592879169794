import { MessageService } from './../_services/message.service';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { NavigationState } from '../_models';
import { NavigationService, AccountService } from '../_services';
import { ContactService } from "../_services/contact.service";
import { Route, ActivatedRoute } from '@angular/router';
import * as moment from "moment";
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Adwords_tracking_code } from '../_services/form';
import firebase from 'firebase/app';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  providers: [MessageService]
})
export class MessageComponent implements OnInit, OnDestroy {
  contactlist: any = [];
  algoliaContactList: any = [];
  messages: any = [];
  twilio_credentials: any = {};
  twilio_from: string;
  account_sid: string;
  auth_token: string;
  twilio_to: any;
  messageObj: any;
  displayMsg: string;
  service_sid: string;
  contact_ids: any = [];
  name: any = {};
  startAfter: any = [];
  msgStartAfter: any = [];
  hasMoreRecords: boolean = true;
  hasMoreMessages: boolean = true;
  lastMsgSubscription: Subscription;
  search_hide: boolean = false;
  contactSubscription: Subscription;
  last_index: any;
  searchText: string = "";
  isReady: boolean = false;
  chatListReady: boolean = false;
  providerObj: any;
  dataContact: any = [];
  languages: any;
  targetLanguage: string;
  sourceLanguage: string;
  selectedLanguage: any;
  acc: any;
  chatMessageLoading: boolean = false;

  constructor(
    private navigationService: NavigationService,
    private messageService: MessageService,
    private contactService: ContactService,
    private accountService: AccountService, public dialog: MatDialog
  ) {
  }


  async ngOnInit() {
    this.navigationService.updateState(
      new NavigationState(false, "", "", "", "/message")
    );
    let acc = await this.accountService.getCurrentAccDetails();
    this.languages = acc.languages ? acc.languages : [];
    this.sourceLanguage = acc.languageCode ? acc.languageCode : 'en';
    this.messages = acc.text_msg_templates;
    // this.twilio_credentials = await this.accountService.getLinkedAccountCredentials(
    //   "twilio"
    // );
    this.twilio_credentials = await this.accountService.getTwilioCredentials("twilio");
    if (this.twilio_credentials) {
      this.account_sid = this.twilio_credentials.account_sid;
      this.auth_token = this.twilio_credentials.auth_token;
      this.service_sid = this.twilio_credentials.notify_service_sid;
      this.twilio_to = [];
      this.twilio_from = acc.twilio_from;
      this.contactSubscription = this.messageService.contact$.subscribe(pagingInfo => {
        this.contactlist = [];
        if (pagingInfo.list && pagingInfo.list.length > 0) {
          pagingInfo.list.forEach(contact => {
            this.contactlist.push(contact);
          });
          this.startAfter = pagingInfo.startAfter;
          this.hasMoreRecords = true;
        } else {
          this.hasMoreRecords = false;
        }

        if (this.contact_ids[0]) {
          this.contactlist.forEach(element => {
            if (this.contact_ids[0] == element.id) {
              // let last_index = this.messages.get(this.messages.size() - 1);
              if (element.lastMessages.message != this.messages[this.messages.length - 1]) {
                //this.messages = [];
                if (this.twilio_credentials !== undefined) {
                  // let contactObj = {
                  //   phone: element.full_phone,
                  //   acc_sid: this.twilio_credentials.account_sid,
                  //   auth_token: this.twilio_credentials.auth_token,
                  // };
                  this.messageService.fetchSmsLogs(this.contact_ids[0]).then(resp => {
                    this.msgStartAfter = resp.startAfter;
                    this.messages = resp.list;
                  });
                  // this.contactService.fetchSMSLogs(contactObj).then((smsData) => {
                  //   smsData.data.sort((a, b) => {
                  //     return <any>new Date(a.date) - <any>new Date(b.date);
                  //   });
                  //   this.messages = smsData.data;
                  //   // console.log(this.messages);
                  // });
                }
              }
            }
            if (element.lastMessages.unread) {
              this.messageService.updateReadMessage(element.id);
            }
          });
        }
      });
    }
    else {
      this.messageService.getDummyContacts().then(snapshot => {
        let contactData = [];
        snapshot.forEach(doc => {
          let document = doc.data();
          document['id'] = doc.id;
          contactData.push(document);
          // console.log(contactData);
        });
        this.contactlist = contactData;
      })
      this.dialog.open(DummyMessageDialog);
    }
    // this.messageService.getJson().subscribe(
    //   res => {
    //     console.log(res);
    //     this.messageService.addDoc(res);
    //   });
    // this.messageService.getLastMessages().then(snapshot => {
    //   snapshot.forEach(doc => {
    //     let document = doc.data();
    //     document['docid'] = doc.id;
    //     console.log(doc.id, '=>', doc.data());
    //     this.contactlist.push(document);
    //   })
    // }

    // this.messageService.getLastMessages(this.startAfter).then(snapshot => {
    //   console.log(snapshot);
    //   if (snapshot.list && snapshot.list.length > 0) {
    //     snapshot.list.forEach(contact => {
    //       this.contactlist.push(contact);
    //     });
    //     this.startAfter = snapshot.startAfter;
    //   }
    //   else {
    //     this.hasMoreRecords = false;
    //   }
    // });
  }


  fetchNextContacts() {
    // console.log("next contacts fetched");
    this.messageService.getLastMessages('contacts', this.startAfter).then(snapshot => {
      // console.log(snapshot);
      if (snapshot.list && snapshot.list.length > 0) {
        snapshot.list.forEach(contact => {
          this.contactlist.push(contact);
          // this.chatListReady = true;
        });
        this.startAfter = snapshot.startAfter;
      }
      else {
        this.hasMoreRecords = false;
      }
    });
  }

  fetchNextMessages() {
    if (this.hasMoreMessages)
      this.messageService.fetchNextSmsLogs(this.contact_ids[0], this.msgStartAfter).then(resp => {
        if (resp.list && resp.list.length > 0) {
          this.msgStartAfter = resp.startAfter;
          let list = resp.list.reverse();
          console.log(list);
          this.messages = list.concat(this.messages);
          this.hasMoreMessages = true;
        }
        else {
          this.hasMoreMessages = false;
        }
      });
  }

  getMessagesOfSelctedContact(contactDetailsObject) {
    this.chatMessageLoading = true;
    console.log(contactDetailsObject);
    let id = contactDetailsObject.id;
    if (contactDetailsObject.objectID)
      id = contactDetailsObject.objectID;
    this.targetLanguage = contactDetailsObject.targetLanguage;
    console.log(this.twilio_credentials);
    if (this.twilio_credentials) {
      console.log(this.twilio_credentials);
      this.messages = [];
      this.contact_ids = [];
      this.twilio_to = [];
      this.isReady = true;
      if (this.twilio_credentials !== undefined) {
        let contactObj = {
          phone: contactDetailsObject.full_phone,
          acc_sid: this.twilio_credentials.account_sid,
          auth_token: this.twilio_credentials.auth_token,
        };
        this.twilio_to.push(contactObj.phone);
        this.contact_ids.push(id);
        this.name = { firstName: contactDetailsObject.first_name, lastName: contactDetailsObject.last_name };

        this.messageService.fetchSmsLogs(this.contact_ids[0]).then(resp => {
          if (resp.list && resp.list.length > 0) {
            this.msgStartAfter = resp.startAfter;
            this.messages = resp.list;
            this.hasMoreMessages = true;
          }
          else {
            this.hasMoreMessages = false;
          }
          this.chatMessageLoading = false;
        });
        // this.contactService.fetchSMSLogs(contactObj).then((smsData) => {
        //   let sms_array = smsData.data;
        //   sms_array.forEach((sms) => {
        //     console.log(sms);
        //     this.messages.push(sms);
        //     this.isReady = true;
        //   });
        //   this.messages.sort((a, b) => {
        //     return <any>moment(a.date) - <any>moment(b.date);
        //     // return <any>new Date(a.date) - <any>new Date(b.date);
        //   });
        //   // console.log(this.messages);
        // });
      }
      // if (contactDetailsObject.lastMessages.unread) {
      //   this.messageService.updateReadMessage(contactDetailsObject.id);
      // }
    }
    else {
      this.messageService.getaDummyMessages().then(res => {
        let allMessagesData = [];
        res.forEach(doc => {
          let document = doc.data();
          console.log(document);
          document['docId'] = doc.id;
          allMessagesData.push(document);
          console.log(allMessagesData);
        });
        console.log(allMessagesData);
        console.log(allMessagesData[0]?.allMessages);
        this.messages = allMessagesData[0]?.allMessages;
        // this.messages.res;
        this.name = { firstName: contactDetailsObject.first_name, lastName: contactDetailsObject.last_name };
        this.isReady = true;
      })
    }
  }

  fetchContactByPagingAlgolia() {
    console.log("twilio contact search triggered!");
    if (this.twilio_credentials !== undefined) {
      this.messageService.fetchContactByPagingAlgolia(this.searchText).then((resp: any) => {
        this.algoliaContactList = resp.hits;
      })
    }
    else {
      console.log("dummy contact search triggered!");
      // console.log(this.contactlist, this.searchText);
      const filtered = this.contactlist.filter(o => {
        return Object.keys(o).some(k => {
          if (typeof o[k] === 'string')
            return o[k].toLowerCase().includes(this.searchText.toLowerCase());
        });
      });
      // console.log(filtered);
      this.algoliaContactList = filtered;
      // console.log(this.algoliaContactList);
    }
  }


  newMessage(message) {
    console.log(this.selectedLanguage);
    this.messageObj = {
      targetLanguage: this.selectedLanguage,
      sourceLanguage: this.sourceLanguage,
      from: this.twilio_from,
      to: this.twilio_to,
      acc_sid: this.account_sid,
      auth_token: this.auth_token,
      service_sid: this.service_sid,
      sourceMsg: message,
      contact_ids: this.contact_ids,
      direction: "outbound-api"
    };
    let msgObject = {
      direction: "outbound-api",
      sourceMsg: message,
      status: "sent",
      type: "message",
    };
    console.log(this.messageObj);
    this.contactService.sendSms(this.messageObj);
    this.contactService.messageNotification(msgObject);
    this.messageObj.date = firebase.firestore.Timestamp.now();
    this.messages.push(this.messageObj);
    this.searchText = "";
  }
  async selectLanguage(event) {
    this.selectedLanguage = event;
    console.log(this.twilio_to, this.selectedLanguage);
    await this.messageService.changeTargetLanguage(this.twilio_to[0], this.selectedLanguage);
  }

  ngOnDestroy() {
    if (this.contactSubscription)
      this.contactSubscription.unsubscribe();
  }

  clear() {
    this.searchText = "";
  }
}


@Component({
  selector: 'dummy-message-dialog',
  templateUrl: 'dummy-message-dialog.html',
})
export class DummyMessageDialog { }
