import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Inject,
  ViewEncapsulation,
  ElementRef,
} from "@angular/core";
import { AccountService, ContactService } from "../_services/index";
import { Router } from "@angular/router";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import * as moment from "moment";
import { Subject, Observable, merge } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Pipe, PipeTransform } from "@angular/core";
//import { CurrencyPipe } from '@angular/common';
import { MatSnackBar } from "@angular/material/snack-bar";
import { COUNTRY_CODES } from "../_constants/country_phone_codes";
import { environment } from "../../environments/environment";
import { AlertService } from "../_services/alert.service";
import { SelectionModel } from "@angular/cdk/collections";
import { FormService } from "../_services/form.service";
import { ProvidersService } from "../_services/providers.service";
import { HttpClient } from "@angular/common/http";

import { MatDatepicker } from "@angular/material/datepicker";
import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { DatePipe } from "@angular/common";
import firebase from "firebase/app";

import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";

import { map, startWith, tap } from "rxjs/operators";
import { Filter } from "../_models/filter.model";

import { ContactsDataSource } from "../_services/contacts.datasource";


export const MY_FORMATS = {
  parse: {
    dateInput: "ll",
  },
  display: {
    dateInput: "ll",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MMM YYYY",
  },
};

export interface DialogData {
  contact: any;
}

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  // templateUrl:'./testMattableLoad.html',
  styleUrls: ["./contacts.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],

  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class ContactsComponent implements OnInit {
  //, AfterViewInit
  contacts: any = [];
  isShow: boolean = false;
  contactsFetched: boolean = false;
  columnsToDisplay: string[] = [
    "select",
    "first_name",
    "last_name",
    "email",
    "phone",
    "send_text_reminders",
    "birthday",
    "last_visited",
    "created_on",
    "active",
  ];
  dataSource = new MatTableDataSource();
  // dataSource: ContactsDataSource;
  expandedElement: any | null;
  maxDate: any = new Date();
  notes: string;
  providerArray: any = [];
  providerObject: any = {};
  providerCount: number;
  providerArrayLength: number;
  //date = new FormControl(moment());
  invalidBirthDate: boolean = false;

  //
  acc: any;
  //countryCode : string;
  //amount:any;
  //formattedAmount: string = '';
  send_text_reminders: boolean;
  // providerArray = ['Dr. Madhuri','Dr. Ashish'];
  twilio_from: string;
  currentAcc: boolean = false;
  twilio_credentials: any;
  selection = new SelectionModel<any>(true, []);

  filteredRowsCount: number;
  allRowsCount: number;
  toImport: boolean = false;
  customFilter: boolean = false;
  importFileName: string = "";
  importFileSelected: boolean = false;
  importComplete: boolean = false;
  displayProgress: boolean = false;
  importContactResult: string = "";

  checked: boolean = false;
  dateCreated: any;
  dateVisited: any;
  operatorsForCreatedOn = new FormControl();
  operatorsForVisitedOn = new FormControl();

  operators: string[] = ["is same as", "greater than", "less than"];

  //value : string;

  pipe: DatePipe;
  //isActive:boolean = false;

  showContacts: boolean = true;
  showImportHistory: boolean = false;
  Name: string;
  data: any;
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredValue: any;
  keywords = [];
  //acc_sid : any;
  //auth_token : any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  /*
set paginator(value: MatPaginator) {
this.dataSource.paginator = value;
}*/

  @ViewChild(MatSort) sort: MatSort;
  /*
set sort(value1:MatSort){
this.dataSource.sort = value1;
}*/

  @ViewChild("input")
  fileInput: ElementRef;

  @ViewChild("elementToFocus")
  _input: ElementRef;

  filters: Array<Filter> = [
    {
      field: {
        label: "Last Visited",
        columnName: "last_visited",
      },
      operators: [
        {
          label: "is same as",
          value: "==",
        },
        {
          label: "is before",
          value: "<",
        },
        {
          label: "is after",
          value: ">",
        },
      ],
      datatype: "date",
    },
    {
      field: {
        label: "Send Text Reminders",
        columnName: "send_text_reminders",
      },
      operators: [
        {
          label: "is enabled",
          value: true,
        },
        {
          label: "is disabled",
          value: false,
        },
      ],
      datatype: "boolean",
    },
    {
      field: {
        label: "Active Contact: ",
        columnName: "active",
      },
      operators: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      datatype: "boolean",
      selectedOperator: {
        label: "Yes",
        value: true,
      },
      selectedValue: "",
    },
    {
      field: {
        label: "first Name",
        columnName: "first_name",
      },
      operators: [
        {
          label: "is same as",
          value: "==",
        },
      ],
      datatype: "string",
      selectedValue: "",
    },
  ];

  pageSize: number = 20;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  count: number = 0;

  constructor(

    private accountService: AccountService,
    private contactService: ContactService,
    private router: Router,
    public dialog: MatDialog,
    private alertService: AlertService,
    private providersService: ProvidersService //private currencyPipe:CurrencyPipe
  ) {
    this.accountService.accChange$.subscribe((accChange) => {
      // this.contactService.resetPageEndpoints();
      this.router.navigateByUrl("/websites");
    });

    this.contactService.contacts$.subscribe((contacts) => {
      contacts.then((contacts) => {
        this.contacts = contacts;
        if (this.contacts.length === 0) {
          this.isShow = false;
        } else {
          this.expandedElement = this.contacts;
          this.dataSource = new MatTableDataSource(this.contacts);
          this.dataSource.data = this.dataSource.data.filter(
            (contactObj) =>
              contactObj["active"] === undefined ||
              contactObj["active"] === true
          );
          this.isShow = true;
        }
        this.acc = this.accountService.getCurrentAccDetails();
        // this.twilio_from = this.acc.twilio_from;
        // this.currentAcc = true;
        this.contactsFetched = true;
        this.count = this.contacts.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    });

    /*
                          let acc = this.accountService.getCurrentAccDetails();
                          this.twilio_from = acc.twilio_from;
                          */
  }

  ngOnInit() {
    this.providersService
      .getProviderNameArray()
      .then((snapshot) => {
        if (snapshot.empty) {
          this.providerCount = 0;
        }
        snapshot.forEach((doc) => {
          let providerArray = [];
          let providerName = doc.data()["name"];
          let providerReference = doc.ref;
          this.providerObject = { providerName, providerReference };
          this.providerArray.push(this.providerObject);
          this.providerCount = this.providerArray.length;
          // console.log(doc.data());
        });
        // this.removeColumnIfCountZero(this.providerCount);
        this.addColumnOfProvider(this.providerCount);
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });

    this.contactsFetched = false;
    this.currentAcc = false;
    // this.contactService.getContacts().then(
    this.contactService.returnContacts().then((contacts) => {
      this.contacts = contacts;
      if (this.contacts.length === 0) {
        this.isShow = false;
      } else {
        this.expandedElement = this.contacts;
        this.dataSource = new MatTableDataSource(this.contacts);
        // console.log("contacts assigned to dataSource",this.dataSource.data);
        this.dataSource.data = this.dataSource.data.filter(
          (contactObj) =>
            contactObj["active"] === undefined || contactObj["active"] === true
        );
        this.isShow = true;
      }
      this.acc = this.accountService.getCurrentAccDetails();
      this.contactsFetched = true;
      this.count = this.contacts.length;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });

    this.acc = this.accountService.getCurrentAccDetails();
    this.currentAcc = true;
    this.twilio_from = this.acc.twilio_from;

    //this.checked=true;

    //this.contactService.updateContactsToActive('tIT9fFK3flNU5bvdNt4w');

    //this.contactService.setFilters([{column: 'active', operator: '==', value: true}]);
    // this.contactService.setFilters(this.filters);
    // this.dataSource = new ContactsDataSource(this.contactService);
    /*  this.contactService.getContactsCount()
        .then(
          count => {
            this.count = count;
            if(this.count > 0) {
              this.isShow = true;
              // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
              this.dataSource = this.contactService.getContacts();
              console.log(this.dataSource);
            }
            else {
              this.isShow = false;
              this.dataSource = this.contactService.getContacts();
              // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
            }
          }
        );*/
  }

  // ngAfterViewInit() {
  /*this.sort.sortChange.subscribe(
      () => {
        // this.paginator.pageIndex = 0;
      }
    );

  this.paginator.page.subscribe(
    () => {
      if(this.pageSize !== this.paginator.pageSize) {
        this.pageSize = this.paginator.pageSize;
        this.paginator.pageIndex = 0;
      }
    }
  );

  merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.getContacts())
      )
      .subscribe();*/
  // }

  getContacts() {
    /*this.dataSource.getContacts(
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );*/
  }

  addColumnOfProvider(providerCount) {
    if (this.providerCount > 0) {
      this.columnsToDisplay.splice(5, 0, "primary provider");
    }
  }

  removeColumnIfCountZero(providerCount) {
    if (this.providerCount === 0) {
      let indexOfProvider = this.columnsToDisplay.indexOf("primary provider");
      this.columnsToDisplay.splice(indexOfProvider, 1);
    }
  }

  changePrimaryProvider(providerName, contact) {
    for (let provider of this.providerArray) {
      if (provider.providerName === providerName) {
        this.contactService.changePrimaryProvider(contact, provider);
      }
    }
  }

  // disableMatInputField(){
  //   if(contact['disabled']===true)
  // }

  applyFilter(filterValue: string) {
    // console.log(Event);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filteredDataSource() {
    this.contactService.resetPageEndpoints();
    this.paginator.pageIndex = 0;
    let activeFilterIndex = this.filters.findIndex(
      (x) => x.field.columnName === "first_name"
    );
    this.filters[activeFilterIndex].selectedValue = this.Name;
    this.contactService.setFilters(this.filters);
    //this.contactService.filterDataSource(this.Name);

    this.contactService.getContactsCount().then((count) => {
      this.count = count;
      if (this.count > 0) {
        this.isShow = true;
        // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
      } else {
        this.isShow = false;
        // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
      }
    });
  }

  saveNotes(appointment_ref: any, notes: any) {
    this.contactService.saveAppointmentNotes(appointment_ref, notes);
  }

  saveSendTextReminders(send_text_reminders: any, contact_ref: any) {
    this.contactService.saveSendTextReminders(send_text_reminders, contact_ref);
  }

  updateLastVisited(contact_ref, refLast_visited) {
    this.contactService.updateLastVisitedDate(contact_ref, refLast_visited);
    //this.contactService.updateLastVisitedDate.refLast_visited'last_visited'.format('MMM d, yyyy');
  }

  updateBirthDate(contact_ref, birthDate, contact) {
    // this.contactService.updateBirthDate(contact_ref, birthDate);
    console.log("selected date", birthDate);

    let date =
      moment(birthDate, "MM-DD-YYYY", true).isValid() ||
      moment(birthDate, "MM/DD/YYYY", true).isValid() ||
      moment(birthDate, "M-D-YYYY", true).isValid() ||
      moment(birthDate, "M/D/YYYY", true).isValid();
    if (date) {
      console.log(typeof birthDate, birthDate, date);
      this.invalidBirthDate = false;
      contact.invalidBirthDate = false;
      this.contactService.updateBirthDate(contact_ref, birthDate);
    } else {
      this.invalidBirthDate = true;
      contact.invalidBirthDate = true;
      console.log(typeof birthDate);
    }
    // this.invalidBirthDate = false;
    console.log("selected date", birthDate);
    // this.contactService.updateLastVisitedDate(contact_ref,refLast_visited);
    //this.contactService.updateLastVisitedDate.refLast_visited'last_visited'.format('MMM d, yyyy');
  }
  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
    //this.contactService.updateLastVisitedDate(contact_ref,refLast_visited);
    //setTimeout(() => this._input.nativeElement.focus());
  }

  _closeCalendar(e, contact_ref, refLast_visited) {
    //this.contactService.updateLastVisitedDate(contact_ref,refLast_visited);
    setTimeout(() => this._input.nativeElement.blur());
  }

  updateRowVisible(contact) {
    this.contactService.updateRowVisibleStatus(contact);
  }

  /*saveValue(appointment_ref: any, value: any){
this.contactService.saveAppointmentValue(appointment_ref, value);
}*/

  saveBilledAmount(appointment_ref: any, billed_amount: any) {
    this.contactService.saveAppointmentBilledAmount(
      appointment_ref,
      billed_amount
    );
  }

  updateValue(enteredValue: string, column: string, contact: any) {
    contact.full_phone = contact.countryCode + enteredValue;

    this.contactService.updateEditedContact(contact, column, enteredValue);
  }

  openDialog() {
    const dialogRef = this.dialog.open(ContactsDialog, {
      height: "520px",
      width: "800px",
    });
    dialogRef.afterClosed().subscribe((closed) => {
      //this.contactService.resetPageEndpoints();
      //this.getContacts();

      this.contactsFetched = false;
      this.contactService.getContacts().then((doc) => {
        this.contactsFetched = true;
      });
    });
  }

  showImportSection() {
    this.toImport = true;
  }

  showCustomFilter() {
    this.customFilter = true;
  }

  hideImportSection() {
    this.importFileSelected = false;
    this.importComplete = false;
    this.displayProgress = false;
    this.importContactResult = "";
    this.toImport = false;
    //this.getContacts();

    this.contactsFetched = false;
    this.contactService.getContacts().then((doc) => {
      this.contactsFetched = true;
    });
  }

  setFilters(filteredArray) {
    console.log(filteredArray);

    this.filters = filteredArray;

    this.contactService.setFilters(this.filters);

    this.contactService.resetPageEndpoints();

    this.paginator.pageIndex = 0;

    this.contactService.getContactsCount().then((count) => {
      this.count = count;
      if (this.count > 0) {
        this.isShow = true;
        // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
      } else {
        this.isShow = false;
        // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
      }
    });
  }

  hideCustomFilterSection() {
    if (this.customFilter) {
      this.customFilter = false;
    } else this.customFilter = !this.customFilter;
  }

  showOperatorSelectedDateCreatedOn(value) {
    return this.operatorsForCreatedOn["value"];
  }

  showOperatorSelectedDateLastVisited(value) {
    return this.operatorsForVisitedOn["value"];
  }

  // customFilterFunctionality(){
  //   // console.log("before both is same");
  //   // console.log(if((this.showOperatorSelectedDateLastVisited(this.value) ==='is same') && (this.showOperatorSelectedDateCreatedOn(this.value) ==='is same'))){
  //   // //   console.log("after both is same");
  //   if(this.showOperatorSelectedDateLastVisited(this.value) === 'is same'){
  //      this.dataSource.data=this.dataSource.data.filter(contactObj=>moment(contactObj['last_visited']).isSame(this.dateVisited))
  //     //  this.dataSource.data=this.dataSource.data.filter(contactObj=>moment(contactObj['created_on']).isSame(this.dateCreated));
  //     console.log(this.dataSource.data);
  //    // console.log('the visited on is : is same as'+ this.dateVisited.format("MMM DD, YYYY") + this.dataSource.data );
  //   }
  //   else if(this.showOperatorSelectedDateLastVisited(this.value) ==='is after'){
  //     this.dataSource.data=this.dataSource.data.filter(contactObj=>moment(contactObj['last_visited']).isAfter(this.dateVisited));
  //   //  console.log('the visited on is : is greater than'+ this.dateVisited.format("MMM DD, YYYY") + this.dataSource.data);
  //   }
  //   else{
  //     this.dataSource.data=this.dataSource.data.filter(contactObj=>moment(contactObj['last_visited']).isBefore(this.dateVisited));
  //   //  console.log('the visited on is : is less than'+ this.dateVisited.format("MMM DD, YYYY") + this.dataSource.data);
  //   }

  //      if(this.showOperatorSelectedDateCreatedOn(this.value) ==='is same'){
  //     this.dataSource.data=this.dataSource.data.filter(contactObj=>moment(contactObj['created_on']).isSame(this.dateCreated));
  //   //  console.log('the created on is : is same as'+ this.dateCreated.format("MMM DD, YYYY") + this.dataSource.data );
  //   }
  //   else if(this.showOperatorSelectedDateCreatedOn(this.value) === 'is after'){
  //     this.dataSource.data=this.dataSource.data.filter(contactObj=>moment(contactObj['created_on']).isAfter(this.dateCreated));
  //    // console.log('the created on is : is greater than'+ this.dateCreated.format("MMM DD, YYYY") + this.dataSource.data);
  //   }
  //   else{
  //     this.dataSource.data=this.dataSource.data.filter(contactObj=>moment(contactObj['created_on']).isBefore(this.dateCreated));
  //  //   console.log('the created on is : is less than'+ this.dateCreated.format("MMM DD, YYYY") + this.dataSource.data);
  //   }

  // // this.add(this.MatChipInputEvent);
  // }

  add(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;
    this.keywords = [
      "Created date" +
      " " +
      this.showOperatorSelectedDateCreatedOn(value) +
      " " +
      this.dateCreated.format("MMM DD, YYYY"),
      "Last visited date" +
      " " +
      this.showOperatorSelectedDateLastVisited(value) +
      " " +
      this.dateVisited.format("MMM DD, YYYY"),
    ];

    if ((value || "").trim()) {
      this.keywords.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(keyword: any) {
    let index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);
      this.dataSource = new MatTableDataSource(this.contacts);
      // this.getContacts();
    }
  }

  checkFileSelection() {
    this.importComplete = false;
    this.displayProgress = false;

    if (this.fileInput.nativeElement.value !== "") {
      this.importFileName = this.fileInput.nativeElement.files[0].name;
      this.importFileSelected = true;
    } else {
      this.importFileName = "";
      this.importFileSelected = false;
    }
  }

  parseFile() {
    this.displayProgress = true;
    this.importFileSelected = false;

    const reader = new FileReader();

    let fileLength = this.fileInput.nativeElement.files[0].size;
    let fileType = this.fileInput.nativeElement.files[0].type;

    if (fileLength === 0) {
      this.importContactResult = `"${this.importFileName}" is empty. No contacts to import!`;
      this.importComplete = true;
      this.importFileName = "";
      this.fileInput.nativeElement.value = "";
      this.importFileSelected = false;
      this.displayProgress = false;
    } else if (fileType !== "text/csv") {
      this.importContactResult = `"${this.importFileName}" has an invalid file type. Please import a CSV file.`;
      this.importComplete = true;
      this.importFileName = "";
      this.fileInput.nativeElement.value = "";
      this.importFileSelected = false;
      this.displayProgress = false;
    } else {
      this.uploadFile();
    }
  }

  uploadFile() {
    this.contactService
      .store_csv_to_storage(this.fileInput.nativeElement.files[0])
      .then(
        (fileURL) => {
          let downloadURL = fileURL;
          this.contactService
            .createImportLog(downloadURL, this.importFileName)
            .then(
              (importLogRef) => {
                console.log(importLogRef.id);
                // Make a call to the Flask app here and pass it importLogRef.id;
                this.contactService.importContacts(importLogRef.id).subscribe(
                  (results) => {
                    this.importContactResult = `"${this.importFileName}" is being imported. You can check the status of the import by accessing "Import History".`;
                    this.importComplete = true;
                    this.importFileName = "";
                    this.fileInput.nativeElement.value = "";
                    this.importFileSelected = false;
                    this.displayProgress = false;
                  },
                  (error) => {
                    console.error(error);
                    this.importContactResult = `There was an error importing "${this.importFileName}": ${error}.`;
                    this.importComplete = true;
                    this.importFileName = "";
                    this.fileInput.nativeElement.value = "";
                    this.importFileSelected = false;
                    this.displayProgress = false;
                  }
                );
              },
              (error) => {
                console.error(error);
                this.importContactResult = `There was an error importing "${this.importFileName}": ${error}.`;
                this.importComplete = true;
                this.importFileName = "";
                this.fileInput.nativeElement.value = "";
                this.importFileSelected = false;
                this.displayProgress = false;
              }
            );
        },
        (error) => {
          console.error(error);
          this.importContactResult = `There was an error importing "${this.importFileName}": ${error}.`;
          this.importComplete = true;
          this.importFileName = "";
          this.fileInput.nativeElement.value = "";
          this.importFileSelected = false;
          this.displayProgress = false;
        }
      );
  }

  resetFile() {
    this.importFileName = "";
    this.fileInput.nativeElement.value = "";
    this.importContactResult = "";
    this.importFileSelected = false;
    this.displayProgress = false;
    this.importComplete = false;
  }

  toggleImportHistory() {
    console.log("Toggle import history called");
    this.showContacts = !this.showContacts;
    this.showImportHistory = !this.showImportHistory;

    if (this.showContacts) {
      this.contactService.resetPageEndpoints();
      //this.getContacts();

      this.contactsFetched = false;
      this.contactService.getContacts().then((doc) => {
        this.contactsFetched = true;
      });
    }
  }

  openDialogForContact() {
    const contactdialogRef = this.dialog.open(CreateContactDialog, {
      height: "520px",
      width: "800px",
    });

    // contactdialogRef.afterClosed()
    //     .subscribe(
    //       closed => {
    //         this.contactService.resetPageEndpoints();
    //         this.contactService.getContactsCount()
    //             .then(
    //               count => {
    //                 this.count = count;
    //                   if(this.count > 0) {
    //                     this.isShow = true;
    //                     // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
    //                   }
    //                   else {
    //                     this.isShow = false;
    //                     // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
    //                   }
    //                 }
    //               );
    /*
          this.contactsFetched = false;
          this.contactService.getContacts()
          .then(doc => {
            this.contactsFetched = true;
          });
          */
    //});
  }

  /*openDialogForMessage(contactRef : any, contact:any){
  this.alertService.reset('');
  const messagedialogRef = this.dialog.open(CreateMessageDialog, {
    height: '600px',
    width: '900px',
    data: { contactRef : contactRef, contact:contact}
  });
  }
  */
  openDialogForMessage(selectedContacts: any) {
    this.alertService.reset("");
    const messagedialogRef = this.dialog.open(CreateMessageDialog, {
      height: "600px",
      width: "900px",
      //data: { contactRef : contactRef, contact:contact}
      data: { contacts: selectedContacts },
    });
  }

  fetchSMSLogs(contact: any) {
    console.log("Fetching logs!");
    this.twilio_credentials = this.accountService.getLinkedAccountCredentials(
      "twilio"
    );
    let contactObj = {
      phone: contact.full_phone,
      acc_sid: this.twilio_credentials.account_sid,
      auth_token: this.twilio_credentials.auth_token,
    };
    //  console.log(contactObj);
    //  this.contactService.fetchSMSLogs(contactObj)
  }

  isAllSelected(numRows: number) {
    const numSelected = this.selection.selected.length;
    return numSelected === numRows;
  }

  // activeUserToggle(changeEvent: MatSlideToggleChange) {
  //     if (changeEvent.checked) {
  //       this.dataSource.filterPredicate =
  //         (user: User, filter: string) => user.status.indexOf(filter) != -1;
  //     } else {
  //       this.dataSource.data = this.users;
  //     }
  //   }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    //this.isAllSelected(this.allRowsCount) ?
    //this.selection.clear() :
    // this.dataSource.contacts.forEach(row => this.selection.select(row));

    //this.disabledRowCount = this.dataSource.disabled.length;

    this.filteredRowsCount = this.dataSource.filteredData.length;
    this.allRowsCount = this.dataSource.data.length;
    //console(this.disabledRowCount);
    if (this.filteredRowsCount < this.allRowsCount) {
      this.isAllSelected(this.filteredRowsCount)
        ? this.selection.clear()
        : this.dataSource.filteredData.forEach((row) =>
          this.selection.select(row)
        );
    } else {
      this.isAllSelected(this.allRowsCount)
        ? this.selection.clear()
        : this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  masterToggleRow() {
    if (this.checked) {
      //this.dataSource.data=this.dataSource.data.filter(contactObj=>contactObj['disabled']===undefined || contactObj['disabled']===false);
      console.log("updated all disabled contacts");
    } else {
      //this.dataSource=new MatTableDataSource(this.contacts);
      console.log("updated all disabled contacts");
    }
  }

  showEnableContactsOnly() {
    //this.checked=true;
    //this.makeUndefinedTrue();
    console.log("before if stmt" + this.checked);
    if (this.checked === false) {
      // console.log("before if stmt"+this.checked)
      // /* Filter out enabled contacts */
      // this.paginator.pageIndex = 0;
      // this.contactService.resetPageEndpoints();
      // let activeFilterIndex = this.filters.findIndex(x => x.field.columnName === 'active');
      // this.filters[activeFilterIndex].selectedOperator = {
      //   label: 'Yes',
      //   value: true
      // };
      // this.filters[activeFilterIndex].selectedValue = '';
      // this.contactService.setFilters(this.filters);
      // this.contactService.getContactsCount()
      // .then(
      //   count => {
      //     this.count = count;
      //     if(this.count > 0) {
      //       this.isShow = true;

      //       // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
      //     }
      //     else {
      //       this.isShow = false;
      //       // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
      //     }
      //   }
      // );

      this.dataSource.data = this.dataSource.data.filter(
        (contactObj) =>
          contactObj["active"] === undefined || contactObj["active"] === true
      );
      //console.log("if executed")
    } else {
      /* Show all contacts i.e. remove the 'only active contacts' filter */
      // this.paginator.pageIndex = 0;
      // this.contactService.resetPageEndpoints();
      // let activeFilterIndex = this.filters.findIndex(x => x.field.columnName === 'active');
      // this.filters[activeFilterIndex].selectedOperator = undefined;
      // this.filters[activeFilterIndex].selectedValue = undefined;
      // this.contactService.setFilters(this.filters);
      // this.contactService.getContactsCount()
      // .then(
      //   count => {
      //     this.count = count;
      //     if(this.count > 0) {
      //       this.isShow = true;
      //       // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
      //     }
      //     else {
      //       this.isShow = false;
      //       // this.dataSource.getContacts('last_visited', 'desc', 0, this.pageSize);
      //     }
      //   }
      // );

      this.dataSource = new MatTableDataSource(this.contacts);
    }
    this.count = this.contacts.length;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  // makeUndefinedTrue(){
  //   this.dataSource.data=this.dataSource.data.filter(contactObj=>contactObj['active']===undefined)
  //   this.dataSource.data.forEach(contact => {
  //     if(contact['active']===undefined)
  //       contact['active']=true;
  //       this.contactService.makeUndefinedTrue(contact);
  //   }
  //   );

  //   console.log(this.dataSource.data);
  // }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    //this.filteredRowsCount = this.dataSource.filteredData.length;
    this.filteredRowsCount = 0; // Temporarily, until we figure out how to filter (search)

    //this.allRowsCount = this.dataSource.data.length;
    // this.allRowsCount = this.dataSource.contacts.length;

    const numRows =
      this.filteredRowsCount < this.allRowsCount
        ? this.filteredRowsCount
        : this.allRowsCount;
    if (!row) {
      return `${this.isAllSelected(numRows) ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1
      }`;
  }

  bulkMessage() {
    //this.openDialogForMessage()
    this.openDialogForMessage(this.selection.selected);
  }

  singleMessage(contact: any) {
    this.openDialogForMessage([contact]);
  }
}

@Pipe({ name: "removeUnderscore" })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any): any {
    return value.replace(/_/g, " ");
  }
}

@Component({
  selector: "dialogdialog",
  templateUrl: "dialog-box.html",
})
export class ContactsDialog {
  all_docs = {};
  showTitle: boolean = false;
  getting_result: boolean = false;
  isReady: boolean = false;
  displayProgress: boolean = false;
  status_msgs: any = [];
  apioutput: string;
  showProgress: boolean = false;
  snapshot: any;
  uploadProgress: number;
  downloadURL: string;
  isUrl: boolean = false;
  import_contact_result: any;

  constructor(
    private formService: FormService,
    private contactService: ContactService,
    public http: HttpClient,
    private accountService: AccountService,
    public dialogRef: MatDialogRef<ContactsDialog>
  ) { }
  // public http: HttpClient

  onUpload(selectedFile: File) {
    // let filename='Contact';
    // let uploadTask = this.formService.uploadFile(selectedFile,filename);
    // this.showProgress = true;
    // uploadTask.on('state_changed',
    //       snapshot =>  {
    //         this.snapshot = snapshot;
    //         this.uploadProgress = (this.snapshot.bytesTransferred / this.snapshot.totalBytes) * 100;
    //         console.log(this.uploadProgress);
    //         console.log('Control in csv uploading');
    //       },
    //       error => {
    //         // upload failed
    //         console.error(error);
    //       },
    //       ()=>{
    //         let path = uploadTask.snapshot.metadata.fullPath;
    //         console.log(path);
    //         console.log(uploadTask);
    //         uploadTask.snapshot.ref.getDownloadURL().then(downloadURL =>{
    //         this.isUrl = true;
    //         this.downloadURL = downloadURL;
    //         this.showProgress = false;
    //         console.log('Download url -->',this.downloadURL);
    //
    //         return this.http.get(`${'http://127.0.0.1:5000/home'}`);
    //
    //         });
    //       }

    this.contactService.store_csv_to_storage(selectedFile).then(
      (downloadURL) => {
        this.downloadURL = downloadURL;

        /*
         * This function is no longer in use.
         * The second argument has to be the name of the file that you are importing.
         */
        this.contactService.createImportLog(this.downloadURL, "").then(
          (importLogRef) => {
            // Make a call to the Flask app here and pass it importLogRef.id;
            this.contactService.importContacts(importLogRef.id).subscribe(
              (results) => {
                this.import_contact_result =
                  "Your import is in progress. You can check its status by accessing 'Import History'.";
                //this.getting_result=true;
                this.isReady = true;
              },
              (error) => {
                console.error(error);
                this.import_contact_result = `There was an error importing your file: ${error}.`;
                this.isReady = true;
              }
            );
            // this.isReady = true;
          },
          (error) => {
            console.error(error);
            this.import_contact_result = `There was an error importing your file: ${error}.`;
            this.isReady = true;
          }
        );
      },
      (error) => {
        console.error(error);
        this.import_contact_result = `There was an error importing your file: ${error}.`;
        this.isReady = true;
        //console.error(`Could not add ${obj['first_name']} ${obj['last_name']} because ${error.message}`);
      }
    );
  }

  csvJSON(csvText) {
    let contacts = [];
    let lines = csvText.split("\n"); //split each row including headings
    for (let i = 1; i < lines.length; i++) {
      let obj = {};
      let currentline = lines[i].split(",");
      obj["first_name"] = currentline[0];
      obj["last_name"] = currentline[1];
      obj["email"] = currentline[2];
      obj["phone"] = currentline[3];
      obj["last_visited"] =
        currentline[4] !== "" ? moment(currentline[4], "M/D/Y").toDate() : "";
      obj["send_automated_sms"] = currentline[5] === "TRUE";
      obj["last_sms_sent"] =
        currentline[6] !== "" ? moment(currentline[6], "M/D/Y").toDate() : "";
      obj["source"] = "import";
      obj["created_on"] = moment().toDate();
      obj["phone"] = obj["phone"].replace(/[^\d]/g, "");
      obj[
        "countryCode"
      ] = this.accountService.getCurrentAccDetails().countryCode;
      obj["send_text_reminders"] = true;
      obj["full_phone"] =
        this.accountService.getCurrentAccDetails().countryCode + obj["phone"];
      //obj['rowVisible'] = true;

      this.contactService.saveContactFromCSVFile(obj).then(
        (status_msg) => {
          this.status_msgs.push(status_msg);
          console.log(status_msg);
          //console.log(`Added ${obj['first_name']} ${obj['last_name']}`);
        },
        (error) => {
          console.error(error);
          //console.error(`Could not add ${obj['first_name']} ${obj['last_name']} because ${error.message}`);
        }
      );
      //contacts.push(obj);
      //console.log(contacts);
    }
    // this.displayProgress = false;
    this.isReady = true;

    /*
  this.contactService.saveContactFromCSVFile(contacts)
  .then(all_docs => {
    this.all_docs = all_docs;
    this.showTitle = true;
    this.isReady = true;
  });
  */
  }

  convertFile(input) {
    this.displayProgress = true;
    const reader = new FileReader();
    // reader.readAsText(input.files[0]); //input.files[0] gives file name, last modified date, file size, file type etc.
    // let data=input.files[0].val();
    var vidFileLength = input.files[0].size;
    let status_msg = "";

    if (vidFileLength === 0) {
      this.import_contact_result = `The file is empty. No contacts to import!`;
      this.isReady = true;
      // alert("Empty file selected.");
      /*
      status_msg=' File is empty.';

      this.status_msgs.push(status_msg);
      this.displayProgress = false;
      */
    } else {
      this.onUpload(input.files[0]);
    }
    // reader.onload = () => {
    //   let text = reader.result;
    //   this.csvJSON(text);
    // };
  }

  close() {
    this.dialogRef.close();
  }
}

@Component({
  selector: "create-contact-dialog",
  templateUrl: "./create-contact-dialog.html",
  styleUrls: ["./contacts.component.scss"],
})
export class CreateContactDialog {
  first_name: string = "";
  last_name: string = "";
  phone: string = "";
  email_id: string = "";
  msg: string;
  contactDoc: any;
  showForm: boolean = false;
  countryCodes: any = COUNTRY_CODES;
  countryCode: any;
  acc: any;
  sendTxtReminders: boolean = true;

  constructor(
    private contactService: ContactService,
    private accountService: AccountService,
    public snackBar: MatSnackBar,
    public contactdialogRef: MatDialogRef<CreateContactDialog>
  ) {
    this.acc = this.accountService.getCurrentAccDetails();
    this.countryCode = this.acc.countryCode;
  }

  saveContact(contactData) {
    contactData.value.email = contactData.value.email.trim();
    let stripPhone = contactData.value.phone_no.replace(/[^\d]/g, "");
    contactData.value.phone_no = stripPhone;
    this.sendTxtReminders = contactData.value.sendTxtReminders;
    contactData.value.countryCode = this.countryCode;
    contactData.value.full_phone = this.countryCode + stripPhone;
    this.contactService.saveNewContact(contactData.value).then((docRef) => {
      if (docRef !== undefined) {
        //this.showForm = true;
        //this.msg = "Contact saved!";
        this.contactdialogRef.close();
        let snackBarRef = this.snackBar.open("Contact saved!", "", {
          duration: 3000,
        });
        // this.close();
      } else {
        // this.showForm = true;
        //this.msg = "Found old record! Contact updated.";
        this.contactdialogRef.close();
        let snackBarRef = this.snackBar.open("Contact updated!", "", {
          duration: 3000,
        });
      }
    });
  }

  close() {
    this.contactdialogRef.close();
  }
}

@Component({
  selector: "create-message-dialog",
  templateUrl: "./create-message-dialog.html",
  styleUrls: ["./contacts.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateMessageDialog {
  textMsg: string;
  selectedMsg: string;
  messages: any = []; /*= ["Crown is ready. Please book an appointment.",
"We now have availability on Monday evening, please call to schedule",
"We have received your medical records, please call to schedule",
"We would like to change your appointment because of ...."];*/
  showTemplates: boolean = true;
  customTextBox: boolean = false;
  contactRef: any;
  contactDetails: any;
  sendTo: string;
  twilio_from: string;
  twilio_credentials: any;
  account_sid: string;
  auth_token: string;
  twilio_to: any;
  sendLink: boolean = false;
  links: any = []; // = ["https://interactive-appointments.herokuapp.com/dental-designer/dental-calendar"];
  calendarLink: string;
  calendar_slug: string;
  acc_slug: string;
  optOut: boolean = false;
  googleLink: boolean = false;
  googleReview: string;
  optOutMsg: string =
    "If you would like to opt out of receiving texts from us, please reply STOP.";
  apptDate: boolean = false;
  selectedDate: any;
  startTime: any;
  endTime: any;
  dateItems: any = {};
  matchedString: any;
  minDate: any = new Date();

  workingTimes: any = [];
  confirmSend: boolean = false;
  messageObj: any;
  displayMsg: string;
  defaultTime: any;
  new_sms_array: any;
  fetch_logs: boolean = false;
  contactAppt: any;
  isApptDate: boolean = false;
  isAppt: boolean;
  dateOfAppt: any;
  timeOfAppt: any;
  service_sid: string;

  constructor(
    private contactService: ContactService,
    private accountService: AccountService,
    private alertService: AlertService,
    public snackBar: MatSnackBar,
    public messagedialogRef: MatDialogRef<CreateMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public contactData: DialogData
  ) {
    //this.contactRef = contactData['contactRef'];
    /*
      this.contactAppt = contactData['contact'].appointments;
      if(this.contactAppt.length == 0){
        console.log("No appts");
        this.isAppt = false;
      }
      else{
        this.isAppt = true;
        console.log("appts present");
        let bookedAppt = [];
        this.contactAppt.forEach(contact=>{
          if(contact.status === 'booked'){
            bookedAppt.push(contact);
            bookedAppt.sort((a,b)=>{
              return <any>new Date(b.date) - <any>new Date(a.date);
            });
            this.dateOfAppt = bookedAppt[0].date;
            this.timeOfAppt = bookedAppt[0].slot;
          }
        });
      }
      */

    /*this.contactRef.get()
                .then(contact =>{
                  //this.contactDetails = contact.data();
                  //this.sendTo = this.contactDetails.first_name + ' ' + this.contactDetails.last_name;
                  this.twilio_to = this.contactDetails.full_phone;
                  let acc = this.accountService.getCurrentAccDetails();
                  this.acc_slug = acc.account_slug;
                  this.fetchCalendars();
                  this.fetchCalendarGroups();
                  this.messages = acc.text_msg_templates;
                  this.twilio_from = acc.twilio_from;
                  this.twilio_credentials = this.accountService.getLinkedAccountCredentials('twilio');
                  this.account_sid = this.twilio_credentials.account_sid;
                  this.auth_token = this.twilio_credentials.auth_token;
                        });*/
    let acc = this.accountService.getCurrentAccDetails();
    this.acc_slug = acc.account_slug;
    this.fetchCalendars();
    this.fetchCalendarGroups();
    this.messages = acc.text_msg_templates;
    this.twilio_from = acc.twilio_from;
    this.twilio_credentials = this.accountService.getLinkedAccountCredentials(
      "twilio"
    );
    this.account_sid = this.twilio_credentials.account_sid;
    this.auth_token = this.twilio_credentials.auth_token;
    this.service_sid = this.twilio_credentials.notify_service_sid;
    this.twilio_to = [];
    contactData["contacts"].forEach((contactObj) => {
      if (contactObj.active === undefined || contactObj.active === true)
        this.twilio_to.push(contactObj.full_phone);
    });
  }

  fetchCalendars() {
    this.accountService.fetchAccountCalendars().then((calendar) => {
      calendar.forEach((cal) => {
        let link = `${environment.apptURL}/calendar/${this.acc_slug}/${cal}`;
        this.links.push(link);
        //console.log("MAX DATE"+this.maxDate);
      });
    });
  }

  fetchCalendarGroups() {
    this.accountService.fetchAccountCalendarGroups().then((calendarGroup) => {
      calendarGroup.forEach((calendar) => {
        let link = `${environment.apptURL}/calendar-groups/${this.acc_slug}/${calendar}`;
        this.links.push(link);
      });
    });
  }

  /*appendLink(msg :string, link:string){
this.selectedMsg = msg + link ;
/*
if(this.showTemplates == true){
this.selectedMsg = msg + link ;
}
if(this.customTextBox == true){
this.textMsg = msg + link;
}*/
  //}

  showTemplate() {
    this.customTextBox = false;
    this.selectedMsg = "";
    this.apptDate = false;
    this.isApptDate = false;
    this.optOut = false;
    this.sendLink = false;
    this.showTemplates = true;
  }

  customText() {
    this.selectedMsg = "";
    this.sendLink = false;
    this.optOut = false;
    this.apptDate = false;
    this.isApptDate = false;
    this.showTemplates = false;
    this.customTextBox = true;
  }

  confirm() {
    this.confirmSend = true;
    /*  if(this.selectedMsg == undefined){
    this.displayMsg = "";
  }*/
    this.displayMsg = this.selectedMsg;
    if (this.sendLink == true) {
      this.displayMsg += " " + this.calendarLink + " .";
    }
    if (this.optOut == true) {
      this.displayMsg += " " + this.optOutMsg;
    }

    this.googleReview = this.accountService.getCurrentAccDetails().googleReviewLink;
    //console.log(this.googleReview.googleReviewLink)
    if (this.googleLink == true) {
      this.displayMsg += " " + this.googleReview + " ";
    }
  }

  goBackToSendSMS() {
    this.confirmSend = false;
  }

  sendMessage() {
    this.messageObj = {
      from: this.twilio_from,
      to: this.twilio_to,
      acc_sid: this.account_sid,
      auth_token: this.auth_token,
      service_sid: this.service_sid,
      msg: this.displayMsg,
    };

    //console.log(this.messageObj.msg);
    this.contactService.sendSms(this.messageObj);
    this.messagedialogRef.close();
    // this.messageObj['direction']='outbound-api';
    // this.messageObj['date']=moment().toDate();
    // this.messageObj['status']='delivered';
    let msgObject = {
      direction: "outbound-api",
      sourceMsg: this.displayMsg,
      status: "sent",
      type: "message",
    };
    console.log(this.messageObj);
    //his.messageObj['msg'] =
    this.contactService.messageNotification(msgObject);
    let snackBarRef = this.snackBar.open("SMS has been sent successfully", "", {
      duration: 4000,
    });
    /*setTimeout(()=>{
    this.fetchSMSLogsAgain();
    console.log("Fetched SMS again!");
  }, 15000);*/
    //this.fetchSMSLogsAgain();
  }

  fetchSMSLogsAgain() {
    let contactObj = {
      phone: this.twilio_to,
      acc_sid: this.account_sid,
      auth_token: this.auth_token,
    };
    this.contactService
      .fetchSMSLogs(contactObj)
      .then((smsData) => {
        let activities = smsData.data;
        //  console.log(this.new_sms_array);
        //let act = sessionStorage.getItem('activities' + this.twilio_to);
        //  sessionStorage.setItem('activities' + this.twilio_to, JSON.stringify({"creation_timestamp": Date.now(), "logs": activities}));
        //  console.log("Session storage updated.");
      })
      .catch((error) => {
        console.log(error);
        this.alertService.error(error.message);
      });
  }
  /*sendMessage(){
  if(msg == undefined){
    this.selectedMsg = "";
  }
  if(this.calendarLink !== undefined){
    this.selectedMsg = msg + ' '+ this.calendarLink + ' .';
  }
  if(this.optOut == true){
    this.selectedMsg = msg +' '+this.optOutMsg;
  }
  this.messageObj = {
    'from' : this.twilio_from,
    'to' : this.twilio_to,
    'acc_sid' : this.account_sid,
    'auth_token':this.auth_token,
    'msg': this.selectedMsg
  }
  console.log(msg);
  console.log(this.messageObj);
  //this.confirmSend = true;
  this.contactService.sendSms(this.messageObj);
  this.messagedialogRef.close();
}*/

  // insertDate(msg, date, startTime){
  //   let stringDate = moment(date).toDate();
  //   console.log("this is converted date"+this.stringDate);
  //   this.dateItems['date'] = stringDate;
  //   let time = moment(startTime, 'h:mm a').format('h:mm a');
  //   //this.dateItems['time'] = time;

  //   if(startTime == undefined){
  //     this.populateTime();
  //     time = this.defaultTime;
  //     this.dateItems['time'] = time;
  //   }
  //   else{
  //     this.dateItems['time'] = time;
  //   }

  //     let curlyBraces = /{{[0-9a-zA-Z]+?}}/g;
  //     if(this.selectedMsg.match(curlyBraces) === null){
  //       this.selectedMsg = this.selectedMsg + stringDate + ' ' + time;
  //     }
  //     while(this.matchedString = curlyBraces.exec(msg)){
  //       let dateStrings = this.matchedString[0].slice(2, -2);
  //       for(let i = 0; i< dateStrings.length; i++){
  //         if(dateStrings == 'Date'){
  //           this.selectedMsg = this.selectedMsg.replace(this.matchedString[0], this.dateItems['date']);
  //         }
  //         if(dateStrings == 'Time'){
  //           this.selectedMsg = this.selectedMsg.replace(this.matchedString[0], this.dateItems['time']);
  //         }
  //       }
  //     }
  // }

  insertDate(msg, date, startTime) {
    let stringDate = moment(date).format("MMM DD, YYYY");
    this.dateItems["date"] = stringDate;
    let time = moment(startTime, "h:mm a").format("h:mm a");
    //this.dateItems['time'] = time;

    if (startTime == undefined) {
      this.populateTime();
      time = this.defaultTime;
      this.dateItems["time"] = time;
    } else {
      this.dateItems["time"] = time;
    }

    let curlyBraces = /{{[0-9a-zA-Z]+?}}/g;
    if (this.selectedMsg.match(curlyBraces) === null) {
      this.selectedMsg = this.selectedMsg + stringDate + " " + time;
    }
    while ((this.matchedString = curlyBraces.exec(msg))) {
      let dateStrings = this.matchedString[0].slice(2, -2);
      for (let i = 0; i < dateStrings.length; i++) {
        if (dateStrings == "Date") {
          this.selectedMsg = this.selectedMsg.replace(
            this.matchedString[0],
            this.dateItems["date"]
          );
        }
        if (dateStrings == "Time") {
          this.selectedMsg = this.selectedMsg.replace(
            this.matchedString[0],
            this.dateItems["time"]
          );
        }
      }
    }
  }

  populateTime() {
    this.workingTimes = [];
    let halfHours = ["00", "30"];
    for (let i = 8; i < 21; i++) {
      for (let j = 0; j < 2; j++) {
        //  console.log(moment((i + ":" + halfHours[j]), 'h:mm a').format('h:mm a'));
        let workingTime = moment(i + ":" + halfHours[j], "h:mm a").format(
          "h:mm A"
        );
        if (i < 10) {
          workingTime = "0" + workingTime;
        }
        this.workingTimes.push(workingTime);
      }
    }
    this.defaultTime = this.workingTimes[0];
    //  console.log(this.workingTimes);
  }

  close() {
    this.messagedialogRef.close();
  }
}
