import {
  Component,
  OnInit,
  OnChanges,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { SeoService } from "../_services/seo-service.service";
import { MatTableDataSource } from "@angular/material/table";
import { AccountService } from "../_services/index";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as moment from "moment";

@Component({
  selector: "app-onpage",
  templateUrl: "./onpage.component.html",
  styleUrls: ["./onpage.component.scss"],
})
export class OnPageComponent implements OnInit, OnChanges {
  @Input()
  website: any;

  isLoading: boolean = false;
  webUrl: string;
  showSummary: boolean = false;
  OnpageSummary: any;
  dataSource1 = new MatTableDataSource();
  dataSource2 = new MatTableDataSource();
  showError: boolean = false;
  summaryArray1: any = [];
  summaryArray2: any = [];
  displayedColumns1: string[] = ["key", "value"];
  displayedColumns2: string[] = ["key", "value"];
  errorMessage: string;
  //website:string;
  acc: any;
  showRefreshButton: boolean = false;
  @ViewChild("content") content: ElementRef;

  secureConnection: boolean = false;
  ssl_params: any;
  site_map_present: boolean = false;
  robot_txt_present: boolean = false;
  showGetResults: boolean = true;
  last_crawled_on: any;
  constructor(
    private seoService: SeoService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    /*this.acc = this.accountService.getCurrentAccDetails();
    console.log(this.acc);
    this.website = this.acc.url;
    console.log(this.website);*/
    //this.getUrl();
  }

  ngOnChanges() {
    this.showGetResults = true;
    //this.getUrl();
  }

  getUrl() {
    this.showGetResults = false;
    this.isLoading = true;
    this.showSummary = false;
    this.summaryArray1 = [];
    this.summaryArray2 = [];

    this.secureConnection = false;
    this.site_map_present = false;
    this.robot_txt_present = false;
    this.showError = false;
    //this.seoService.getOnpageSummary(this.webUrl)
    /*  this.seoService.getLastCrawledOn(this.website)
    .then(docSnapshot=>{
      console.log(docSnapshot);
      docSnapshot.forEach(doc=>{
        let urlDoc = doc.data();
        console.log(urlDoc);
        this.last_crawled_on = urlDoc.onPageTaskSetOn.toDate();//.toString();
        this.last_crawled_on = moment(this.last_crawled_on).format( 'MMM D, YYYY h:mm A');
        console.log('moment:' + this.last_crawled_on);
      });
    });*/
    //this.last_crawled_on= moment('2017-10-12T08:14:56.766+00:00').format('MMM D, YYYY h:mm A');
    this.seoService.getOnpageSummary(this.website).subscribe(
      (res) => {
        this.OnpageSummary = res[0].summary;
        if (this.OnpageSummary[0].ssl == true) {
          this.secureConnection = true;
          this.ssl_params = {
            "SSL certificate expiration": this.OnpageSummary[0]
              .ssl_certificate_expiration,
            ssl_certificate_hash_algorithm: this.OnpageSummary[0]
              .ssl_certificate_hash_algorithm,
            ssl_certificate_issuer: this.OnpageSummary[0]
              .ssl_certificate_issuer,
            ssl_certificate_subject: this.OnpageSummary[0]
              .ssl_certificate_subject,
            ssl_certificate_valid: this.OnpageSummary[0].ssl_certificate_valid,
            ssl_certificate_x509_version: this.OnpageSummary[0]
              .ssl_certificate_x509_version,
          };
        }
        if (this.OnpageSummary[0].have_robots == true) {
          this.robot_txt_present = true;
        }
        if (this.OnpageSummary[0].have_sitemap == true) {
          this.site_map_present = true;
        } else {
        }
        //Crawl time calculations
        let crawl_start = new Date(this.OnpageSummary[0].crawl_start).getTime();
        let crawl_end = new Date(this.OnpageSummary[0].crawl_end).getTime();
        let crawl = crawl_end - crawl_start;
        let crawl_time = this.millisecondsToTime(crawl);
        let table1 = {
          Domain: this.OnpageSummary[0].domain,
          IP: this.OnpageSummary[0].ip,
          "Last crawled on": moment(this.OnpageSummary[0].crawl_end).format(
            "MMM D, YYYY h:mm A"
          ),
          "Pages crawled": this.OnpageSummary[0].pages_total,
          "Crawl time": crawl_time,
          "Page errors": this.OnpageSummary[0].pages_broken,
          "Content management system": this.OnpageSummary[0].cms,
          "Content readability score":
            1 -
            this.OnpageSummary[0].content_readability_bad /
              res[0].crawl_max_pages,
          "SEO friendly URL": this.OnpageSummary[0].seo_friendly_url,
          "SEO non-friendly URL": this.OnpageSummary[0].seo_non_friendly_url,
          "Website loading performance":
            this.OnpageSummary[0].time_load_high /
            this.OnpageSummary[0].pages_total,
        };

        Object.keys(table1).forEach((key) => {
          let summaryResult1 = {
            key: key,
            value: table1[key],
          };
          this.summaryArray1.push(summaryResult1);
        });
        this.dataSource1 = new MatTableDataSource(this.summaryArray1);

        let table2 = {
          "Missing H1 tags": this.OnpageSummary[0].absent_h1_tags,
          "Duplicate Meta Descriptions": this.OnpageSummary[0]
            .duplicate_meta_descriptions,
          "Duplicate pages": this.OnpageSummary[0].title_duplicate_tag, //Not sure
          "Missing favicon": this.OnpageSummary[0].favicon_invalid, //Not sure
          "Missing Image alt text": this.OnpageSummary[0].images_invalid_alt,
          "Missing Image Titles": this.OnpageSummary[0].images_invalid_title,
          "Links Broken": this.OnpageSummary[0].links_broken,
          "Links External": this.OnpageSummary[0].links_external,
          "Link Internal": this.OnpageSummary[0].links_internal,
          "Missing Meta Description": this.OnpageSummary[0]
            .meta_description_empty,
          "Missing Titles": this.OnpageSummary[0].title_empty,
          "Titles Too Long": this.OnpageSummary[0].title_long,
          "Titles Too Short": this.OnpageSummary[0].title_short,
        };

        Object.keys(table2).forEach((key) => {
          let summaryResult2 = {
            key: key,
            value: table2[key],
          };
          this.summaryArray2.push(summaryResult2);
        });
        this.dataSource2 = new MatTableDataSource(this.summaryArray2);

        this.isLoading = false;
        this.showSummary = true;
        this.showError = false;
      },
      (error) => {
        this.showSummary = false;
        this.isLoading = false;
        this.secureConnection = false;
        this.site_map_present = false;
        this.robot_txt_present = false;
        this.showError = true;
        this.errorMessage = error.error["message"];
        if (error.error["crawl_start"] !== undefined) {
          let crawl_duration = moment(error.error["crawl_start"]).fromNow();
          this.errorMessage += `Crawl started ${crawl_duration}.`;
        }
        if (
          this.errorMessage === "We are preparing your website for crawling" ||
          this.errorMessage.includes("Your website crawl is in progress")
        ) {
          this.showRefreshButton = true;
        } else {
          this.showRefreshButton = false;
        }
      }
    );
  }

  applyFilterForTable1(filterValue: string) {
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  applyFilterForTable2(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  millisecondsToTime(milli) {
    let milliseconds = milli % 1000;
    let seconds = Math.floor((milli / 1000) % 60);
    let minutes = Math.floor((milli / (60 * 1000)) % 60);

    //return minutes + "min" + seconds + "sec" + milliseconds + "ms";
    return `${minutes} min ${seconds} sec ${milliseconds} ms`;
    //return minutes + ":" + seconds + ":" + milliseconds;
  }
}
