import { Injectable } from '@angular/core';
import * as xlsx from 'xlsx';
import * as filesaver from 'file-saver';
import * as jspdf from 'jspdf';
import 'jspdf-autotable';

// @Injectable()
export class ExportDataService {
    constructor() {
    }

    downloadExcel(exceldata, filename) {
        let sheets = {};
        let sheetnames = [];
        // for (let i = 0; i < exceldata.length; i++) {
        //     let ws = xlsx.utils.table_to_sheet(exceldata[i].data);
        //     // let ws = xlsx.utils.json_to_sheet(exceldata[i].data);
        //     sheets[exceldata[i].name] = ws;
        //     sheetnames.push(exceldata[i].name);
        // }
        // // const worksheet = xlsx.utils.json_to_sheet(exceldata);
        // const workbook = { Sheets: sheets, SheetNames: sheetnames };
        // const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        // let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        // let EXCEL_EXTENSION = '.xlsx';
        // let data: Blob = new Blob([excelBuffer], {
        //     type: EXCEL_TYPE
        // });
        // filesaver.saveAs(data, filename + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    downloadCSV() {

    }

    downloadPDF(products) {
        // let cols = [
        //     { field: 'code', header: 'Code' },
        //     { field: 'name', header: 'Name' },
        //     { field: 'category', header: 'Category' },
        //     { field: 'quantity', header: 'Quantity' }
        // ];
        // let exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
        // console.log("Export :", exportColumns);
        // const doc = new jspdf('l', 'mm', [305, 250]);
        // doc.autoTable(exportColumns, products);
        // doc.save('products.pdf');
        // import("jspdf").then(jsPDF => {
        //     import("jspdf-autotable").then(x => {
        //         const doc = new jsPDF.default(0, 0);
        //         doc.autoTable(this.exportColumns, this.products);
        //         doc.save('products.pdf');
        //     })
        // })
    }

}
