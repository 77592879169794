<div class="row text-center contactHead" id="subHeader1">
    <div class="col-md-3" style="margin: auto;">
        <div class="searchClass">
            <i class="fa fa-search searchIcon"></i>
            <input class="searchBox" type="text" [(ngModel)]="searchWord" (ngModelChange)="fetchContactByAlgolia()"
                placeholder="Search contact" />
        </div>
    </div>

    <div class="col-md-5">
        <h4 style="margin: 10px;">Contacts</h4>
    </div>
    <div class="col-md-4"></div>
</div>

<div *ngIf="!algoliaContactList" style="padding-top: 20%;color:#7e84f2;">
    <div style="text-align: center;">
        <i class="pi pi-spin pi-spinner" style="font-size: 50px;" id="spinIcon1"></i>
    </div>
</div>
<div id="responsive-table" *ngIf="algoliaContactList">
    <div class="container" id="mobile-view">
        <div class="row">
            <div class="col-md-3">
                <div class="card log-card" *ngFor="let contact of algoliaContactList">
                    <div class="card-body">
                        <div><span class="log-details-title">Name:</span><span
                                class="log-details-data">{{contact?.first_name}} {{contact?.last_name}}</span>
                        </div>
                        <div><span class="log-details-title">Phone:</span><span
                                class="log-details-data">{{contact?.full_phone}}</span></div>
                        <div><span class="log-details-title">Email:</span><span>{{contact?.email}}</span></div><button
                            class="btn btn-primary small-button" type="button"
                            (click)="getRewardDetails($event,contact)">View
                            Rewards&nbsp;<i class="fa fa-angle-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" id="desktop-view">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="left-title">Name</th>
                        <th class="left-title">Phone</th>
                        <th class="left-title">Email</th>
                        <th class="left-title">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let contact of algoliaContactList">
                        <td class="left-title">{{contact?.first_name}} {{contact?.last_name}}</td>
                        <td class="left-title">{{contact?.full_phone}}</td>
                        <td class="left-title">{{contact?.email}}</td>
                        <td class="log-details-data confirmation left-title"><button
                                class="btn btn-primary small-button" type="button"
                                (click)="getRewardDetails(contact)">View Rewards&nbsp;<i
                                    class="fa fa-angle-right"></i></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>