<!-- <div class="col-sm-10 mx-auto" id="form-border">-->
<div class="container-fluid view">
<div class="row">
  <div class="col-md-6">
<mat-card class="metric-card">
    <h2 class="mb-4 register_header">Register</h2>
          <form name="form" (ngSubmit)="f.form.valid && register()" #f="ngForm"  novalidate>
                <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !login_id.valid }">
                   <i class="fa fa-user prefix grey-text user"></i>
                   <input type="text" class="form-control" name="first_name" [(ngModel)]="model.login_id" #login_id="ngModel" placeholder="Enter First Name" required />
                   <!--<small *ngIf="f.submitted && first_name.hasError('required')" class="help-block">First Name is required</small>-->
                </div>
                <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !login_id.valid }">
                   <i class="fa fa-user prefix grey-text user"></i>
                   <input type="text" class="form-control" name="last_name" [(ngModel)]="model.login_id" #login_id="ngModel" placeholder="Enter Last Name" required />
                   <!--<small *ngIf="f.submitted && last_name.hasError('required')" class="help-block">Last Name is required</small>-->
                </div>

                <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                  <i class="fa fa-envelope prefix grey-text email"></i>
                    <input type="email" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" placeholder="Enter Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required />
                    <small *ngIf="f.submitted && email.hasError('required')" class="help-block">Email is required</small>
                    <small *ngIf = "email.hasError('pattern')" class="help-block">Enter Valid Email</small>
                </div>

                <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !cell_no.valid }">
                  <i class="fa fa-mobile-phone prefix grey-text phone"></i>
                    <input type="tel" class="form-control" name="cell_no" [(ngModel)]="model.cell_no" #cell_no="ngModel" placeholder="Enter Cell Number" pattern="[789][0-9]{9}" required />
                    <small *ngIf="f.submitted && cell_no.hasError('required')" class="help-block">Cell No is required</small>
                    <small *ngIf = "cell_no.hasError('pattern')" class="help-block">Enter Valid Mobile number</small>
                </div>

                <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <i class="fa fa-lock prefix grey-text password"></i>
                    <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" minlength="6" placeholder="Enter Password" required />
                    <small *ngIf="f.submitted && password.hasError('required')" class="help-block">Password is required</small>
                    <small *ngIf = "password.hasError('minlength')" class="help-block">Password should contain min 6 characters</small>
                </div>

              <div>
                  <button [disabled]="loading" class="btn btn-primary">Save Account</button>
                  <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              <!--    <button class="btn btn-secondary" (click)="onCancel()">Cancel</button>-->
                  <p>Already an member? <a [routerLink]="['/login']">Login</a></p>
              </div>
      </form>
<!--</div>-->
</mat-card>

</div>
</div>
</div>
