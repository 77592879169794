import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentReference,
} from "angularfire2/firestore";
import { AfService } from "./providers/af.service";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

import { environment } from "../../environments/environment";
import * as moment from "moment";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { User, Account } from "../_models/interfaces";
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';

import 'firebase/firestore';
import 'firebase/functions';
import { AnyARecord } from "dns";
import { BASIC_MENU, Admin_Menu } from "../_constants/appMenuItems";


@Injectable()
export class AccountService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  user: User;
  acc: any; // Used in admin component; an unused component
  linked_accounts: any[] = [];
  acc_details: Account = {
    name: "",
    account_slug: "",
    countryCode: "",
    isDemo: false,
  };
  acc_menu: any[] = [];
  acc_names: string[] = [];

  accInfo: Account[] = []; // This contains data of all accounts, so should be named accordingly
  allProviders: any[] = [];
  private accChangeSource = new Subject<boolean>();
  accChange$ = this.accChangeSource.asObservable();

  private accMenuChange = new Subject<any>();
  menuChange$ = this.accMenuChange.asObservable();

  private notificationSource = new Subject<any>();
  notification$ = this.notificationSource.asObservable();

  private closeToastSource = new Subject<any>();
  closeToast$ = this.closeToastSource.asObservable();

  existingQueue: any;
  private accountStatus = new Subject<any>();
  accountBuildet$ = this.accountStatus.asObservable();

  constructor(
    private http: HttpClient,
    public afs: AngularFirestore,
    private afService: AfService,
    private snackBar: MatSnackBar,
    private _snackBar: MatSnackBar,

  ) {
    afService.user$.subscribe((user) => {
      if (user) {
        this.user = user;
        // console.log(this.user.roles);
        this.acc_names = [];
        this.accInfo = [];
        if (this.user.roles.admin) {
          this.user.accounts = [];
          firebase
            .firestore()
            .collection("accounts").orderBy('name', 'asc')
            .get()
            .then((docsSnapshot) => {
              docsSnapshot.forEach((docSnapshot) => {
                let accountData = docSnapshot.data();
                this.user.accounts.push(docSnapshot.id);
                this.acc_names.push(accountData.name);
                accountData["id"] = docSnapshot.id;
                this.accInfo.push(<Account>accountData);
                /*
                            this.user.accounts.push(docSnapshot.id);
                            this.acc_names.push(docSnapshot.data().name);
                            this.accInfo.push(docSnapshot.data());*/
              });
              if (sessionStorage.getItem("current_acc_id")) {
                this.updateAcc(sessionStorage.getItem("current_acc_id"));
              } else {
                this.updateAcc(user.accounts[0]);
              }
              //this.updateAcc(docsSnapshot.docs[0].id);
            });
        } else {
          let acc_count = this.user.accounts.length;
          // console.log(acc_count);
          for (let i = 0; i < acc_count; i++) {
            firebase
              .firestore()
              .collection("accounts")
              .doc(`${user.accounts[i]}`)
              .get()
              .then((docSnapshot) => {
                let userAccInfo = docSnapshot.data();
                // console.log(docSnapshot.id,accDetails.name);
                this.acc_names.push(userAccInfo.name);
                this.accInfo.push(<Account>userAccInfo);
              });
          }
          let sessionStorageAccID = sessionStorage.getItem("current_acc_id")
          let i = this.user.accounts.findIndex(x => x == sessionStorageAccID)
          if (i != -1) {
            this.updateAcc(sessionStorage.getItem("current_acc_id"));
          } else {
            this.updateAcc(user.accounts[0]);
          }
          //this.updateAcc(user.accounts[0]);
          /*
            if(sessionStorage.getItem('current_acc_id')) {
              this.updateAcc(sessionStorage.getItem('current_acc_id'));
            /*
            this.getAccountDetails(sessionStorage.getItem('current_acc_id'));
            this.getLinkedAccounts(sessionStorage.getItem('current_acc_id'))
                .subscribe(
                  linked_accounts => {
                    this.linked_accounts = linked_accounts;
                  }
                );
              */
          /*
            }
            else {
              this.updateAcc(user.accounts[0]);
              /*
              this.getAccountDetails(user.accounts[0]);
              sessionStorage.setItem('current_acc_id', user.accounts[0]);
              this.getLinkedAccounts(user.accounts[0])
                  .subscribe(
                    linked_accounts => {
                      this.linked_accounts = linked_accounts;
                    }
                  );
              */
          /*
            }
            */
        }
      }
    });
  }

  getUserRef() {
    // console.log(`${localStorage.getItem("current_user_id")}`);
    return firebase
      .firestore()
      .collection("users")
      .doc(`${localStorage.getItem("current_user_id")}`);
  }

  async getMenuSettings(acc_id) {
    let menuSnap = await firebase.firestore().collection('accounts').doc(acc_id).collection('settings').where("type", "==", "menu").get();
    let menu = [];
    if (menuSnap.empty) {
      menu = BASIC_MENU;
      let adminList = menu.filter(a => a.label == 'Admin');
      if (this.user.roles.admin && adminList.length == 0) {
        // if(adminList.length==0){
        menu.push(Admin_Menu)
        // }
      }
      this.accMenuChange.next(menu);
    }
    else {
      let menu = menuSnap.docs[0].get('menuList');
      let adminList = menu.filter(a => a.label == 'Admin');
      if (this.user.roles.admin && adminList.length == 0) {
        // if(adminList.length==0){
        menu.push(Admin_Menu)
        // }
        // menu.push(Admin_Menu);
      }
      this.accMenuChange.next(menu);
    }
  }
  updateAcc(acc_id: any) {
    this.getAccountDetails(acc_id);
    this.getMenuSettings(acc_id);
    let response = this.getLinkedAccounts(acc_id).subscribe((linked_accounts) => {
      this.linked_accounts = linked_accounts;
      this.indicateAccountChange();
    });
    sessionStorage.setItem("current_acc_id", acc_id);
    return this.linked_accounts;
  }


  async check() {
    // let acc_id = this.agetCurrentAcc();
    try {

      let ref = await firebase
        .firestore()
        .collection("accounts")
        .doc(sessionStorage.getItem("current_acc_id"))
        .collection('linked_accounts');

      (await ref.get()).docs.map(data => {
        // console.log(data.data());
        this.linked_accounts.push(data.data())
      })

      const res = ref.get().then(acc => {
        acc.forEach(
          (getAcc) => {
            ref.doc(getAcc.id).get().then(acc => {
              // console.log(typeof (acc));
            });
          });
      });

      return res;
    }
    catch (err) {
      console.log(err);
    }

  }

  updateWebsiteUrl(newUrl: string) {
    return firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .update({ url: newUrl });
  }

  indicateAccountChange() {
    this.accChangeSource.next(true);
  }

  // Used in admin component, an unused component
  getCurrentAcc() {
    return this.acc;
  }
  getAccountDetails(acc_id: string) {
    this.afs
      .doc(`accounts/${acc_id}`)
      .valueChanges()
      .subscribe((acc_details) => {
        this.acc_details = <Account>acc_details;
        //  this.accountName = this.acc_details.name;
      });
  }

  async fetchAccountCalendars() {
    let calendars = [];
    let calendarSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("calendars")
      .get();
    calendarSnapshot.forEach((calendarDoc) => {
      let calendar = calendarDoc.data().slug;
      calendars.push(calendar);
    });
    return calendars;
  }

  async fetchCalendarInfo() {
    let calendars = [];
    let calendarSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("calendars")
      .get();
    return calendarSnapshot;
  }

  async fetchAccountCalendarGroups() {
    let calendarGroups = [];
    let calendarGroupSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("calendar-groups")
      .get();
    calendarGroupSnapshot.forEach((calendarGroupDoc) => {
      let calendarGroup = calendarGroupDoc.data().slug;
      calendarGroups.push(calendarGroup);
    });
    return calendarGroups;

  }

  getCurrentAccDetails() {
    return this.acc_details;
  }

  getLinkedAccounts(acc_id: string) {
    return this.afs
      .collection(`accounts/${acc_id}/linked_accounts`)
      .valueChanges();
  }

  getLinkedAccountCredentials(account: string) {
    console.log(this.linked_accounts);
    let ind = this.linked_accounts.findIndex((x) => x.type === account);
    return this.linked_accounts[ind];
  }

  async getGmbAccountCredential(account: string) {

    console.log(sessionStorage.getItem("current_acc_id"));
    let data = (await firebase.firestore().collection(`accounts/${sessionStorage.getItem("current_acc_id")}/linked_accounts`).where('type', '==', account).get()).docs.map(data => data.data());
    return data;
  }

  saveAccount(currency: any, account_name: any) {
    const data = {
      isDemo: false,
      name: account_name,
      account_currency: currency,
    };
    this.afs
      .collection(`accounts`)
      .add(data)
      .then((obj) => {
        this.user.accounts.push(obj.id);
        let docRef: AngularFirestoreDocument<any> = this.afs.doc(
          `users/${this.user.uid}`
        );
        var result = docRef.set(this.user, { merge: true });
        sessionStorage.setItem("current_acc_id", obj.id);
      });
  }

  async saveNewAccount(name: any, url: any) {
    const data = {
      name: name,
      url: url,
      created_on: moment().toDate(),
    };

    let fetchedData = await firebase
      .firestore()
      .collection("accounts")
      .where("url", "==", url)
      .get();

    if (fetchedData.size > 0) {
      return fetchedData.size;
    } else {
      this.afs
        .collection(`accounts`)
        .add(data)
        .then((obj) => {
          this.user.accounts.push(obj.id);
          let docRef: AngularFirestoreDocument<any> = this.afs.doc(
            `users/${this.user.uid}`
          );
          var result = docRef.set(this.user, { merge: true });
          sessionStorage.setItem("current_acc_id", obj.id);
          this.snackBar.open("Account added!", "", {
            duration: 4000,
            verticalPosition: "top",
            panelClass: ["red-snackbar"],
          });
        });
    }
  }

  getAccNames() {
    return this.acc_names;
  }

  getAccs() {
    return this.user.accounts;
  }

  getAllAccountInfo() {
    return this.accInfo;
  }

  linkAdwordsAcc() {
    window.location.href =
      "https://accounts.google.com/o/oauth2/v2/auth" +
      "?client_id=475733262323-em9tppvd4bjrdnfvf38q46j7vq8eak0o.apps.googleusercontent.com" +
      `&redirect_uri=${environment.serverURL}/connect-adwords-account.php` +
      "&response_type=code" +
      "&access_type=offline" +
      "&scope=https://www.googleapis.com/auth/adwords";
  }

  getAdwordsAccounts(adwords_refresh_token: string) {
    localStorage.setItem("adwords_refresh_token", adwords_refresh_token);
    return this.http.post(
      `${environment.serverURL}/adwords-get-accounts.php`,
      adwords_refresh_token
    );
  }

  linkFacebookAcc() {
    window.location.href =
      "https://www.facebook.com/v7.0/dialog/oauth" +
      "?client_id=472149173150657" +
      `&redirect_uri=${environment.serverURL}/connect-facebook-account.php` +
      "&state=state123abc" +
      "&response_type=code" +
      "&scope=ads_management";
  }

  getFBAdAccounts(access_token: string) {
    const data = new HttpParams()
      .set("fields", "id,name")
      .set("access_token", access_token);
    localStorage.setItem("fb_access_token", access_token);

    return this.http.get("https://graph.facebook.com/v7.0/me/adaccounts", {
      params: data,
    });
  }

  linkAnalyticsAcc() {
    window.location.href = `${environment.serverURL}/connect-analytics.php`;
  }


  getAnalyticsAccounts(analytics_refresh_token: string) {
    localStorage.setItem("analytics_refresh_token", analytics_refresh_token);
    return this.http.post(
      `${environment.serverURL}/analytics-get-accounts.php`,
      analytics_refresh_token
    );
  }

  linkMyGMBAccount() {
    // window.location.href = `http://localhost/mktg-bot-server/web/connect-gmb-account.php`;
    window.location.href = `https://us-central1-mktgbot-181017.cloudfunctions.net/gmbReviewsConfiguration?root=${window.location.href}`;
    // window.location.href = "http://localhost:5001/mktgbot-181017/us-central1/gmbReviewsConfiguration?root="+window.location.href ;
  }

  getGmbAccounts(gmb_refresh_token: string) {
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json'
      })
    };
    let data = {
      "refresh_token": gmb_refresh_token
    }
    return this.http.post<any>(`https://us-central1-mktgbot-181017.cloudfunctions.net/getGmbAccounts`, JSON.stringify(data), header);
  }
  storedGmbInitialInfo(gmb_refresh_token) {
    let docRef: AngularFirestoreDocument<any> = this.afs.doc(
      `accounts/${sessionStorage.getItem("current_acc_id")}/linked_accounts/gmb_accounts`
    );
    let dataToBeStored = {
      "gmb_refresh_token": gmb_refresh_token,
      "gmb_info": [],
      "type": "gmb",
    }
    var result = docRef.set(dataToBeStored, { merge: true });
    return dataToBeStored;
  }

  async saveGmbCredential(gmbData) {

    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'responseType': 'json',
      })
    };

    let docRef: AngularFirestoreDocument<any> = this.afs.doc(
      `accounts/${sessionStorage.getItem("current_acc_id")}/linked_accounts/gmb_accounts`
    );
    var result = docRef.update({ 'gmb_info': gmbData });
    console.log(result);

    let accountDocRef: AngularFirestoreDocument<any> = this.afs.doc(
      `accounts/${sessionStorage.getItem("current_acc_id")}`
    );
    accountDocRef.update({ 'gmb_info': gmbData });

    docRef.get().toPromise().then(async resp => {
      if (resp.data().schedule_job_name == undefined) {
        await firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem("current_acc_id")}`).get().then(
          resp => {
            let result = resp.data();
            let body = {
              "account_slug": result.account_slug,
              "description": result.name,
              "timezone": result.timezone ? result.timezone : "America/New_York"
            }

            console.log(body);

            this.http.post<any>(`https://us-central1-mktgbot-181017.cloudfunctions.net/createSchedulerJobForGmbAccount`, body, header).subscribe(
              (resp) => {
                console.log(resp)
                docRef.update({ 'schedule_job_name': resp.name });
              })
          })
      }
      else {
        let body = {
          'accSlug': await (await firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem("current_acc_id")}`).get()).data()['account_slug']
        }
        console.log(body);
        this.http.post<any>(`https://us-central1-mktgbot-181017.cloudfunctions.net/googleReviewsManagement`, body, header).subscribe(
          (resp) => {
            console.log(resp)
            // docRef.update({ 'schedule_job_name': resp.name });
          })
      }
    })
  }

  linkMailChimpAcc() {
    window.location.href =
      "https://login.mailchimp.com/oauth2/authorize" +
      "?response_type=code" +
      "&client_id=593621253035" +
      "&redirect_uri=http://127.0.0.1:8000/connect-email-account.php";
  }

  getMailChimpLists(access_token: string, dc: string) {
    return this.http.post(
      "http://localhost:8000/mailchimp-lists-for-account.php",
      JSON.stringify({ access_token: access_token, dc: dc })
    );
  }

  saveFBCredentialsToAccount(fb_account: any) {
    let facebookToken = localStorage.getItem("fb_access_token");
    let acc_id = sessionStorage.getItem("current_acc_id");
    let docRef: AngularFirestoreDocument<any> = this.afs.doc(
      `accounts/${acc_id}/linked_accounts/Facebook_accounts`
    );
    const data = {
      facebook_access_token: facebookToken,
      facebook_account_id: fb_account.account_id,
      name: fb_account.account_name,
      type: "facebook",
    };
    var result = docRef.set(data, { merge: true });
    localStorage.removeItem("fb_access_token");
    return data;
  }

  saveAdwordsCredentialsToAccount(adwords_account: any) {
    let adwordsToken = localStorage.getItem("adwords_refresh_token");
    let acc_id = sessionStorage.getItem("current_acc_id");
    let docRef: AngularFirestoreDocument<any> = this.afs.doc(
      `accounts/${acc_id}/linked_accounts/Adwords_accounts`
    );
    const data = {
      adwords_refresh_token: adwordsToken,
      adwords_cust_id: adwords_account.account_id,
      adwords_account_name: adwords_account.account_name,
      type: "adwords",
    };
    var result = docRef.set(data, { merge: true });
    localStorage.removeItem("adwords_refresh_token");
    return data;
  }

  saveMailChimpCredentialsToAccount(list: any) {
    let ind = +sessionStorage.getItem("current_acc_index");
    this.user.accounts[ind]["mailchimp_list_id"] = list.list_id;
    this.user.accounts[ind]["mailchimp_list_name"] = list.list_name;

    let docRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${this.user.uid}`
    );

    var result = docRef.set(this.user, { merge: true });
  }

  saveAnalyticsCredentialsToAccount(analytics_acc: any) {
    let analyticsToken = localStorage.getItem("analytics_refresh_token");
    let acc_id = sessionStorage.getItem("current_acc_id");
    let docRef: AngularFirestoreDocument<any> = this.afs.doc(
      `accounts/${acc_id}/linked_accounts/Analytics_accounts`
    );
    const data = {
      analytics_refresh_token: analyticsToken,
      ga_view_id: analytics_acc.view_id,
      analytics_account_name: analytics_acc.account_name,
      analytics_property_name: analytics_acc.property_name,
      analytics_view_name: analytics_acc.view_name,
      type: "analytics",
    };
    var result = docRef.set(data, { merge: true });
    localStorage.removeItem("analytics_refresh_token");
    return data;
  }



  async saveGmbCredentialsToAccount(name, id, url) {

    let gmbToken = localStorage.getItem("gmb_refresh_token");
    let acc_id = sessionStorage.getItem("current_acc_id");

    let docRef: AngularFirestoreDocument<any> = this.afs.doc(
      `accounts/${acc_id}/linked_accounts/gmb_accounts`
    );
    const data = {
      gmb_refresh_token: gmbToken,
      gmb_account_name: name,
      gmb_id: id,
      gmb_url: url,
      type: "gmb",
    };

    var result = docRef.set(data, { merge: true });
    localStorage.removeItem("gmb_refresh_token");
    return data;
  }

  async unlinkFBAcc() {
    let fb_credentials = await this.getLinkedAccountCredentials("facebook");
    const data = new HttpParams().set(
      "access_token",
      fb_credentials.facebook_access_token
    );

    this.http
      .delete("https://graph.facebook.com/v7.0/me/permissions/ads_management", {
        params: data,
      })
      .subscribe(
        (success) => {
          let acc_id = sessionStorage.getItem("current_acc_id");
          let DisconnectFB = this.afs.doc(
            `accounts/${acc_id}/linked_accounts/Facebook_accounts`
          );
          DisconnectFB.delete();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  deleteAccount(res, type) {
    let acc_id = sessionStorage.getItem("current_acc_id");
    if (Object.keys(res).length === 0) {
      // if(type == "adwords"){
      let DisconnectAdwords = this.afs.doc(
        `accounts/${acc_id}/linked_accounts/Adwords_accounts`
      );
      DisconnectAdwords.delete();
      // }

      // if(type == "analytics"){
      let DisconnectAnalytics = this.afs.doc(
        `accounts/${acc_id}/linked_accounts/Analytics_accounts`
      );
      DisconnectAnalytics.delete();
      // }

      if (type == "gmb") {
        let DiscoonectGmb = this.afs.doc(
          `accounts/${acc_id}/linked_accounts/gmb_accounts`
        );
        DiscoonectGmb.get().toPromise().then(resp => {
          let header = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'responseType': 'json',
            })
          };

          let body = {
            job_name: resp.data().schedule_job_name
          }
          DiscoonectGmb.delete();
          this.openSnackBar("Gmb account unlink successfully");
          console.log(body);
          this.http.post<any>(`https://us-central1-mktgbot-181017.cloudfunctions.net/deleteJobSchedulerOfGmbAccount`, body, header).subscribe(
            (resp) => {
              console.log(resp)
            })
        })
      }

    }
    else {
      if (res['error']) {
        this.openSnackBar(res['error_description']);
      }
    }
    return true;
  }

  unlinkGoogleAccs(refresh_token: string, type: string) {
    console.log(type, refresh_token);
    this.http
      .post(
        `${environment.serverURL}/revoke-google-refresh-token.php`,
        refresh_token
      )
      .subscribe((res) => {
        this.deleteAccount(res, type);
      },
        (error) => {
          console.log(error);
        });
  }

  unlinkGmbAccount(refresh_token: string, type: string) {
    let acc_id = sessionStorage.getItem("current_acc_id");
    console.log(type, refresh_token);
    return this.http
      .post(
        `${environment.serverURL}/revoke-google-refresh-token.php`,
        refresh_token
      )
    // .toPromise();
  }


  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 4000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  // unlinkGmbAccount() {
  //   let acc_id = sessionStorage.getItem("current_acc_id");
  //   let DisconnectGMB = this.afs.doc(
  //     `accounts/${acc_id}/linked_accounts/gmb_accounts`
  //   );
  //   DisconnectGMB.delete();
  // }

  accremoveDocument(ref) {
    let result = firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("providersDetails")
      .doc(ref)
      .delete();
  }

  async fetchAccountData() {
    let accountsSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .get();

    let providersSnap = await firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("providersDetails")
      .get();
    this.allProviders = [];
    providersSnap.forEach((snapshot) => {
      let docData = snapshot.data();
      docData["providerRef"] = snapshot.ref;
      docData["providerId"] = snapshot.id;
      if ("calendar_link" in docData && docData["calendar_link"].length > 0) {
        let calArray = [];
        docData["calendar_link"].forEach((calRef) => {
          calRef.get().then((calDoc) => {
            let calData = calDoc.data();
            let calInfo = {
              slug: calData.slug,
              calRef: calRef,
            };
            calArray.push(calInfo);
          });
          // calData = calData.doc();
        });
        docData["calendar_link"] = calArray;
        this.allProviders.push(docData);
      } else {
        docData["calendar_link"] = [];
        this.allProviders.push(docData);
      }
    });
    let accountInfo = accountsSnapshot.data();
    accountInfo["providerInfo"] = this.allProviders;

    return accountInfo;
  }

  async getCountryCodes() {
    let result = await firebase.firestore().collection("countries").get();
    return result;
  }

  addProvider(providersDetails) {
    return firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("providersDetails")
      .add(providersDetails);
  }

  updateProvider(id, object) {
    let result = firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .collection("providersDetails")
      .doc(id)
      .update(object);
    return result;
  }

  getProviderArray(resultDetails) {
    let array = [];
    resultDetails.forEach((doc) => {
      array.push(doc.data());
    });
    return array;
  }

  async getSlugValue(linkArray) {
    let ans = [];
    try {
      for (let i = 0; i < linkArray.length; i++) {
        let data = await linkArray[i].get().then((doc) => {
          let slugVal = doc.data().slug;
          ans.push(slugVal);
        });
      }
      return ans;
    } catch (error) {
      return ans;
    }
  }

  updateAccountInformation(accountInfo: any, providersDetails: any) {
    let getaccdetails = this.getCurrentAccDetails();
    let clonedObj = Object.assign(getaccdetails, accountInfo);
    return firebase
      .firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem("current_acc_id")}`)
      .update(clonedObj);
  }

  // check provider already exist then update else add new provider.
  async addProviderForAccount(providerInfo) {
    let accId = sessionStorage.getItem("current_acc_id");
    let provider = this.allProviders.filter(
      (provider) => provider.email === providerInfo.email
    );
    if (provider.length > 0) {
      return provider[0].providerRef;
    } else {
      const snap = await firebase
        .firestore()
        .collection("accounts")
        .doc(accId)
        .collection("providersDetails")
        .add(providerInfo);
      providerInfo["providerRef"] = snap;
      this.allProviders.push(providerInfo);
      return snap;
    }
  }

  // async addLiveChatToQueue()
  // {
  //   console.log(sessionStorage.getItem("current_acc_id"));
  //   let ref =await  firebase.firestore().collection('accounts')
  //     .doc(`${sessionStorage.getItem("current_acc_id")}`).collection('settings').doc("Msb4kJZ6m1G0rBleHnUa")
  //   ref.get().then(data=>{
  //     let existinChat = data.data()["liveChatQueue"];
  //     existinChat[existinChat.length]="annad"
  //     ref.update({
  //       "liveChatQueue":existinChat
  //     })
  //   })
  //     // .update({
  //     //   liveChatQueue:""
  //     // })
  // }

  async checkLiveChat() {
    console.log(sessionStorage.getItem("current_acc_id"));
    //   firebase.firestore().collection('accounts')
    //     .doc(`${sessionStorage.getItem("current_acc_id")}`).collection('settings')
    //     .where('type', '==', 'chat-bot').onSnapshot(async snapshot => {
    //       // if(snapshot!= undefined)
    //       // {
    //       //   let data = await(await snapshot.docs[0].data().liveChatQueue[0].get()).data();
    //       //   console.log(status);
    //       //   console.log(snapshot.docs[0].data().liveChatQueue);
    //       this.existingQueue = await (snapshot.docs[0].data()).liveChatQueue;
    //       if (this.existingQueue.length) {
    //         this.notificationSource.next(snapshot.docs[0]);
    //       }
    //       // this.closeToastSource.next(this.existingQueue.length);
    //     })
  }

  checkDemoDataAcknowledged(acc) {
    if (acc.isDemo == true) {
      if (acc.acknowledged) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  async getTwilioCredentials(account: string) {
    let acc_id = sessionStorage.getItem("current_acc_id");
    await this.getAccountDetails(acc_id);
    return firebase.firestore()
      .collection(`accounts/${acc_id}/linked_accounts`).where('type', '==', account).get().then(resp => {
        if (resp.docs[0])
          return resp.docs[0].data();
        else
          return null;
      });
  }

  async getAccountCreationId(placeId) {
    let accountId
    let accExistSnapShot = await firebase.firestore().collection("accounts").where("account_slug", "==", placeId).get();
    if (accExistSnapShot.docs.length > 0) {
      accountId = accExistSnapShot.docs[0].id
      await accExistSnapShot.docs[0].ref.update({ activityStatus: "ACCOUNT_CREATION" })
    }
    else {
      let docSnapShot = await firebase.firestore().collection("accounts").add({ activityStatus: "ACCOUNT_CREATION" })
      accountId = docSnapShot.id
    }
    this.addActivityStatusListener(accountId)
    return accountId
  }

  createAccountWithSignUp(accData) {

    // return this.http.post(`${environment.cloudFunctionServerUrl}/createAccountWithSignUp`, accData)

    return this.http.post(`${environment.cloudFunctionServerUrl}/createAccountWithSignUp`, accData)

    //   return this.http.post(`${environment.cloudFunctionServerUrl}/createDynamicTask`, {
    //   url: `${environment.cloudFunctionServerUrl}/createAccountWithSignUp`,
    //   payload: accData,
    //   updateAtSeconds: Date.now() / 1000 + 5
    // })
  }

  addActivityStatusListener(documentId) {
    const doc = firebase.firestore().collection("accounts").doc(documentId);
    const observer = doc.onSnapshot(async docSnapshot => {
      this.accountStatus.next({ activityStatus: docSnapshot.get('activityStatus') })
      if (docSnapshot.get('activityStatus') == 'COMPLETED') {
        observer()
      }
      console.log(docSnapshot.data())
    })
  }
}