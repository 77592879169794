<!-- <div class="ui-g" *ngIf="false else loaded"></div>
<ng-template #loaded>
  <appointments-calendar></appointments-calendar>
</ng-template> -->
<!-- <p-fullCalendar [events]="events" [options]="options"></p-fullCalendar> -->

<!-- <appointments-calendar></appointments-calendar> -->
<div class="row text-center" id="subHeader1">
  <div class="col-md-3" style="margin: auto;">
    <div class="searchContainer">
      <i class="fa fa-search searchIcon"></i>
      <input class="searchBox" type="text" name="search" placeholder="Search appointment" [(ngModel)]="searchText"
        (input)="appt.filterGlobal($event.target.value, 'contains')" [disabled]="!showBookAppt">
    </div>
  </div>
  <div class="col-md-6" style="margin: 2.5% auto;">
    <div id="pageTitle" class="center" style="font-size: 20px; float:left;"> Appointments </div>
  </div>
  <div class="col-md-3">
    <button mat-raised-button [disabled]="!showBookAppt" (click)='scheduleNewAppointment()' style="margin: 10px;">Make
      New Appointment</button>
  </div>
</div>

<div class="row">
  <!-- <div class="col-md-9" style="margin: auto;">
      <ng-container *ngIf="providersCount>0" style="margin: auto;">
          <mat-checkbox (change)="OnSelectingAllProvider($event,allProvidersValue)" [checked]="allCheckBoxValue"
              [ngModel]="allCheckBoxValue" name="allProviders" style="margin-left:35px;">All</mat-checkbox>
          <mat-checkbox *ngFor="let provider of providersArray ; let i=index " name="{{i}}-name"
              (change)="OnChangeProvider(provider,$event,i)" [checked]="false" [(ngModel)]="providers[i]"
              [disabled]="isDisabled" style="margin-left:35px;"> {{provider}}</mat-checkbox>
      </ng-container>
  </div> -->
  <div class="col-md-2">
    <mat-form-field style="width: 100%; font-size: 15px;">
      <mat-label>Location</mat-label>
      <mat-select [(value)]="selectedLocation" multiple>
        <mat-option *ngFor="let location of locationType" [value]="location.name" style="font-size: 15px;">
          {{location.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-2">
    <mat-form-field style="width: 100%; font-size: 15px;">
      <mat-label>Providers</mat-label>
      <mat-select [(value)]="selectedProvider" multiple>
        <mat-option *ngFor="let provider of providerType" [value]="provider" style="font-size: 15px;">
          {{provider}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-2">
    <mat-form-field style="width: 100%; font-size: 15px;">
      <mat-label>Calendar</mat-label>
      <mat-select [(value)]="selectedCalendar" multiple>
        <mat-option *ngFor="let calendar of calendarType" [value]="calendar.slug" style="font-size: 15px;">
          {{calendar.slug}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-2">
    <mat-form-field style="width: 100%; font-size: 15px;">
      <mat-label>Status</mat-label>
      <mat-select [(value)]="selectedStatus" multiple>
        <mat-option *ngFor="let status of statusType" [value]="status.value" style="font-size: 15px;">
          {{status.status}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-2">
    <mat-form-field style="width: 100%; font-size: 15px;">
      <mat-label>Source</mat-label>
      <mat-select [(value)]="selectedSource" multiple>
        <mat-option *ngFor="let source of sourceType" [value]="source.value" style="font-size: 15px;">
          {{source.source}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- <div class="col-md-1" style="margin:auto 0px;" class="desktop">
      <button mat-raised-button [disabled]="!showBookAppt" (click)='applyFilters()'
          style="margin:auto;">Apply</button>
  </div>
  <div class="col-md-1" style="margin:auto 0px;padding-left:2%" class="desktop">
      <button mat-raised-button [disabled]="!showBookAppt" (click)='clearFilters()'
          style="margin:auto;">Clear</button>
  </div> -->
  <!-- ------>
  <div class="col-md-2" class="mobile" style="margin: auto;">
    <span style="margin:auto 0px; display:contents;padding-right: 2%;">
      <button mat-raised-button [disabled]="!showBookAppt" (click)='applyFilters()' style="margin:auto;">Apply</button>
    </span>
    &nbsp;
    <span style="margin:auto 0px;display:contents;">
      <button mat-raised-button [disabled]="!showBookAppt" (click)='clearFilters()' style="margin:auto;">Clear</button>
    </span>
  </div>
  <!-- ---------- -->
</div>

<app-appointment-full-calendar (clearFilters)="calendarViewChange()"></app-appointment-full-calendar>
<!--<div id="chartContainer" style="height: 370px; width: 100%;"></div>-->
<!--<div class="chart-container">
  <canvas id="canvas"> {{status_pie}}</canvas>
</div>-->
<!--
<div class="col col-md-8 offset-md-2">
  <div class="chart-container">
  <canvas id="canvas">{{ status_pie }}</canvas>
  </div>
</div>
-->
<!--<div style="display: block">
  <canvas #canvas baseChart
          [data]="pieChartData"
          [labels]="pieChartLabels"
          [chartType]="pieChartType"></canvas>
</div>-->

<div class="container-fluid view">
  <div *ngIf="isShow; else notShow">
    <div class="mat-elevation-z8">
      <p-table [value]="appointments" [columns]="displayedColumns" #appt [globalFilterFields]="filterColumns"
        (onFilter)="filterAppointments(appt)" [paginator]="true" [rows]="rows" [(first)]="first" sortMode="multiple"
        [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} of {totalRecords}"
        [resizableColumns]="true" [autoLayout]="true" [rowsPerPageOpappttions]="[10,20,50]">
        <ng-template pTemplate="caption">
          <div style="text-align: left">
            <input class="filterInput" type="text" pInputText size="50" placeholder="Filter" [(ngModel)]="searchText"
              (input)="appt.filterGlobal($event.target.value, 'contains')">
          </div>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th [class]="col.class" *ngFor="let col of displayedColumns"
              [pSortableColumnDisabled]="disableSortColumns.includes(col.field)" [pSortableColumn]="col.field"
              pResizableColumn>
              {{col.header}}
              <p-sortIcon *ngIf="!disableSortColumns.includes(col.field)" [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-appointment let-index>
          <tr>
            <td class="mid">
              <div *ngIf="appointment.recurringApptDetailReference" class="FollowUpVisitBadge">Follow-Up</div>
              <div *ngIf="appointment.status=='requested'" class="FollowUpVisitBadge">Requested</div>
              {{appointment.appt_start | date:'MMM d, yyyy'}}<br>
              {{appointment.appt_start | date:'h:mm a'}} - {{appointment.appt_end | date:'h:mm a'}}<br>
              <!-- pass data object with variable name 'data' -->
              <app-nudge-message [data]="appointment"></app-nudge-message>
            </td>
            <!-- <td class="mid">
          {{appointment.appt_start | date:'h:mm a'}} - {{appointment.appt_end | date:'h:mm a'}}
        </td> -->
            <td class="emailStatus">
              {{appointment.status | titlecase}}
              <br>
              <div class="icon">
                <div *ngIf="getDays(appointment.appt_start) >= 1">
                  <i class="fa fa-check" [class.disabled]="appointment.status !== 'booked'" matTooltip="Completed"
                    (click)="updateStatus(appointment, 'completed')"></i>

                  <i class="fa fa-eye-slash" [class.disabled]="appointment.status !== 'booked'" matTooltip="No show"
                    (click)="updateStatus(appointment, 'no-show')"></i>

                  <i class="fa fa-times" [class.disabled]="true" matTooltip="Cancel"
                    (click)="cancelStatus(appointment)"></i>

                  <i class="fa fa-repeat" [class.disabled]="true" aria-hidden="true" matTooltip="Reschedule"
                    (click)="rescheduleStatus(appointment)"></i>
                </div>
                <div *ngIf="getDays(appointment.appt_start) < 1">
                  <i class="fa fa-check" [class.disabled]="true" matTooltip="Completed"
                    (click)="updateStatus(appointment, 'completed')"></i>

                  <i class="fa fa-eye-slash" [class.disabled]="true" matTooltip="No show"
                    (click)="updateStatus(appointment, 'no-show')"></i>

                  <i class="fa fa-times" [class.disabled]="appointment.status !== 'booked'" matTooltip="Cancel"
                    (click)="cancelStatus(appointment)"></i>

                  <i class="fa fa-repeat"
                    [class.disabled]="appointment.status !== 'booked' && appointment.status !== 'no-show' "
                    aria-hidden="true" matTooltip="Reschedule" (click)="rescheduleStatus(appointment)"></i>
                </div>
              </div>
            </td>
            <td class="mid">
              {{appointment.booked_on | date:'MMM d, yyyy h:mm a'}}
            </td>
            <td class="mid">
              <ng-template *ngIf="appointment.new_patient === undefined; then noPatientType else PatientType">
              </ng-template>
              <ng-template #noPatientType> - </ng-template>
              <ng-template #PatientType> {{appointment.new_patient}} </ng-template>
            </td>
            <td class="mid">
              <a [routerLink]="['/contacts',appointment.contactId ,'appointment']">{{appointment.first_name}}
                {{ appointment.last_name }}
              </a>
            </td>
            <td class="emailStatus">
              {{appointment.email}}
            </td>
            <td class="mid">
              {{appointment.phone}}
            </td>
            <td class="mid">
              <mat-form-field style="width:150px;">
                <textarea matInput name="notes" [(ngModel)]="appointment.notes"
                  (change)="saveNotes(appointment, appointment.notes)">{{appointment.notes}}</textarea>
              </mat-form-field>
            </td>
            <td class="mid">
              {{appointment.provider_name}}
            </td>
            <td class="mid">
              {{appointment.location}}
            </td>
            <td class="mid">
              {{appointment.source}}
            </td>
            <td class="mid">
              <mat-form-field>
                <mat-select name="insurance" [(ngModel)]="appointment.insurance"
                  (selectionChange)="saveInsurance(appointment, appointment.insurance)">
                  <mat-option *ngFor="let insurance of insurance" [value]="insurance.value">
                    {{insurance.value | titlecase}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td class="mid">
              <mat-form-field>
                <mat-select name="value" [(ngModel)]="appointment.value"
                  (selectionChange)="saveValue(appointment, appointment.value)">
                  <mat-option *ngFor="let value of value" [value]="value.value">
                    {{value.value | titlecase}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <ng-template #notShow>
    <h6>No Appointments</h6>
  </ng-template>
</div>

<!-- <app-appointment-nudge name="hello vipul"></app-asppointment-nudge> -->