<!-- <div>
    <center>
        <h1>Social Media Calender</h1>
    </center>
    <button align="end" id="button" mat-mini-fab class="format" matTooltip="Add Event" (click)="createPost()">
        <i class="material-icons add">add</i>
    </button>
</div> -->
<div class="row text-center" id="subHeader1">
    <div class="col-md-3" style="margin: auto;">

    </div>
    <div class="col-md-6" style="margin: auto;">
        <div id="pageTitle" class="center"><h1>Social Media Calender</h1></div>
    </div>
    <div class="col-md-3" style="margin: auto;">
        <button align="end" id="button" mat-mini-fab class="format" matTooltip="Add Event" (click)="createPost()">
            <i class="material-icons add">add</i>
        </button>
    </div>

</div>

<full-calendar *ngIf="!createEvent" #calendar [options]="calendarOptions" id="calendar"></full-calendar>
<app-create-post *ngIf="createEvent"></app-create-post>