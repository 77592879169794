<!-- new-signup -->
<div style="text-align:center;padding-top: 5em;" *ngIf="loading;else go">
    <div *ngIf="setUpDone;else spinner">
        <p>Your account has been set up succesfully. </p>
        <div hidden="true">
            <app-login></app-login>
        </div>
    </div>
    <ng-template #spinner>
        <app-sign-up-loading></app-sign-up-loading>
    </ng-template>
</div>

<ng-template #go>
    <section>
        <div class="row">
            <div class="col-md-5">
                <div *ngIf="passwordVerification" class="space">
                    <br>
                    <h3 class="first">Set Passoword to your Account</h3>
                    <br>
                    <form [formGroup]="passwordForm" (ngSubmit)="passwordConfirm(passwordForm)">
                        <div class="space">
                            <!-- <mat-form-field> -->
                            <span class="label">Password</span>
                            <div class="input-group">
                                <input pPassword class="form-control" [type]="hidePwd? 'password':'text'" matInput
                                    autocomplete="new-password" placeholder="New password" formControlName="password"
                                    required>
                                <span class="addToInput">
                                    <mat-icon matSuffix (click)="hidePwd=!hidePwd">{{hidePwd?'visibility':
                                        'visibility_off'}} </mat-icon>
                                </span>
                            </div>
                            <mat-error
                                *ngIf="passwordForm.hasError('required', 'password') && (passwordForm.dirty||passwordForm.touched)">
                                Please enter your new password
                            </mat-error>
                            <!-- </mat-form-field> -->
                        </div>
                        <div class="space">
                            <!-- <mat-form-field> -->
                            <span class="label">Confirm Password</span>
                            <div class="input-group">
                                <input class="form-control" [type]="hideConfirmPwd? 'password':'text'" pInputText
                                    autocomplete="new-password" type="password" matInput placeholder="Confirm password"
                                    formControlName="confirmPassword" [errorStateMatcher]="matcher">
                                <span class="addToInput">
                                    <mat-icon matSuffix (click)="hideConfirmPwd=!hideConfirmPwd">
                                        {{hideConfirmPwd? 'visibility': 'visibility_off'}} </mat-icon>
                                </span>
                            </div>
                            <mat-error
                                *ngIf="passwordForm.hasError('notSame')  &&( passwordForm.dirty|| passwordForm.dirty)">
                                Passwords do not match
                            </mat-error>
                            <!-- </mat-form-field> -->
                        </div>
                        <br>
                        <div style="text-align: center;">
                            <button mat-raised-button type="submit" [disabled]="!passwordForm.valid">Register</button>
                        </div>
                    </form>
                </div>
                <div *ngIf="showVerification" class="container">
                    <app-otp-verification [screenName]="'email'" [value]="form['email'].value"
                        (otpVerificationStatus)="checkOtpVerificationStatus($event)"></app-otp-verification>
                </div>
                <div *ngIf="!showVerification && !passwordVerification" class="container">
                    <div class="space" style="text-align: center; color: #7E84F2;">
                        <a href="/"><img src="../../assets/images/mktg-doctor-logo-new.png" class="logo" /></a>
                    </div>
                    <div class="space">
                        <h1>Sign Up</h1>
                        <em>Get access to a demo account instantly. Sign-up using the form
                            below.</em>
                    </div>

                    <div class="space">
                        <form [formGroup]="userForm">

                            <div class="space">
                                <span class="label">Select Specialty</span>
                                <br>
                                <mat-radio-group aria-label="Select an option" formControlName="specialty"
                                    name="specialty" required>
                                    <ng-container *ngFor="let specilty of specialties">
                                        <mat-radio-button [value]="specilty.value">{{specilty.label}}
                                        </mat-radio-button>
                                        <br>
                                    </ng-container>
                                </mat-radio-group>
                            </div>


                            <div class="space">
                                <span class="label">Find practice</span>
                                <input class="form-control" id="practiceName" formControlName="practiceName"
                                    placeholder="Enter practice name" ngx-google-places-autocomplete #addressInput
                                    [options]='options' #placesRef="ngx-places"
                                    (onAddressChange)="handleAddressChange($event)" pInputText required />
                                <div *ngIf="form['practiceName'].invalid && (form['practiceName'].dirty || form['practiceName'].touched)"
                                    class="error">
                                    Please enter practice name</div>
                            </div>
                            <div class="space">
                                <span class="label">Degree</span>
                                <input class="form-control" id="degree" formControlName="degree" pInputText
                                    placeholder="Enter Degree" required />
                                <div *ngIf="form['degree'].invalid && (form['degree'].dirty || form['degree'].touched)"
                                    class="error">Enter degree</div>
                            </div>

                            <div class="space">
                                <span class="label">First Name</span>
                                <input class="form-control" id="firstName" formControlName="firstName" pInputText
                                    placeholder="Enter first name" required />
                                <div *ngIf="form['firstName'].invalid && (form['firstName'].dirty || form['firstName'].touched)"
                                    class="error">Enter first name</div>
                            </div>
                            <div class="space">
                                <span class="label">Last Name</span>
                                <input class="form-control" id="lastName" formControlName="lastName" pInputText
                                    placeholder="Enter last name" required />
                                <div *ngIf="form['lastName'].invalid && (form['lastName'].dirty || form['lastName'].touched)"
                                    class="error">Enter last name</div>
                            </div>
                            <div class="space">
                                <span class="label">Email</span>
                                <input class="form-control" id="email" formControlName="email" placeholder="Enter email"
                                    pInputText required />
                                <div *ngIf="form['email'].invalid && (form['email'].dirty || form['email'].touched)"
                                    class="error">
                                    Please enter valid email</div>
                            </div>

                            <div class="space">


                                <span class="label">Phone Number</span>
                                <div style="display: flex;justify-content: end;align-items: center;">

                                    <div class="phone" style="position: relative;width: 30%;
                                    margin-right: 10px;">
                                        <input class="form-control" type="text" formControlName="countryCode"
                                            autocomplete="new-password" [matMenuTriggerFor]="belowMenu"
                                            #countryCodeSearch required pInputText>
                                        <div style="   top: 0.5em;
                                position: absolute;
                                right: 0;; margin-left: -10px;">

                                            <span class="material-icons" style="cursor: pointer;color: #8a8a8a;"
                                                (click)="countryCodeSearch.click()">
                                                keyboard_arrow_down</span>
                                        </div>
                                        <div style="max-height: 200px;overflow: hidden;">
                                            <mat-menu #belowMenu="matMenu" yPosition="below" autoActiveFirstOption>
                                                <div id="w-node-_8308320a-a396-9646-7eb0-6fa2c9047ba0-b57daefc"
                                                    style="margin-left: 1em;margin-right: 1em;">
                                                    <input type="text" class="w-input" data-name="Email" #searchInput1
                                                        (blur)="searchInput1.focus()"
                                                        placeholder="Search by Country / Code"
                                                        (keyup)="searchCountry($event)"
                                                        (click)="$event.stopPropagation()">
                                                </div>
                                                <div style="height: 200px;
                                overflow-y: auto;">
                                                    <span *ngFor="let country of searchedCountries"
                                                        (click)="slectedCountry(country.dial_code)"
                                                        mat-menu-item>{{country.name}}
                                                        {{country.dial_code}}</span>
                                                </div>
                                            </mat-menu>
                                        </div>

                                    </div>


                                    <input class="form-control" type="text" formControlName="phone"
                                        placeholder="Phone *" id="Phone" pInputText>

                                </div>
                                <div *ngIf="form['phone'].invalid && (form['phone'].dirty || form['phone'].touched)"
                                    class="error">
                                    Please enter valid phone number
                                </div>
                            </div>

                        </form>
                    </div>
                    <div class="space" style="text-align: center;">
                        <button type="submit" class="btn btn-primary" (click)="next()" [disabled]="!userForm.valid">
                            Next
                        </button>
                    </div>

                    <p class="text-center">
                        Already have an account? <a [routerLink]="['/']"><b>Login</b></a>
                    </p>
                </div>
            </div>
            <div class="col-md-7 sign-up-hero">
            </div>
        </div>
    </section>
</ng-template>



<!-- <div>


<div>
    <div>
        <p class="question">Select your Specialty</p>
        <div class="wrapper">
            <div class="options-wrapper">
                <label class="radio-button-field w-radio" *ngFor="let x of specialties"
                    [ngClass]="form['speciality'].value==x.value ? 'checked' : ''">
                    <img *ngIf="form['speciality'].value==x.value ? 'checked' : ''" loading="lazy"
                        src="./../../assets/images/black-arrow.svg" alt="" class="check-icon">
                    <input type="radio" formControlName="speciality" [value]="x.value"
                        data-name="specialty"
                        class="w-form-formradioinput radio-button w-radio-input">
                    <span class="radio-button-label w-form-label" for="radio">{{x.label}}</span>
                </label>
            </div>
            <div class="div-block-3"
                *ngIf="form['speciality'].value &&  form['speciality'].value=='OTHER'">
                <h3 class="heading-2">Other Specialties Coming Soon. Join the Waitlist</h3>
                <p class="form-title">Please Enter Your Information (* fields are required)</p>
                <div class="div-block-4">
                    <div class="form-wrap">
                        <input type="text" class="input-field w-input" formControlName="firstName"
                            maxlength="256" name="First-Name" data-name="First Name"
                            placeholder="Enter First Name *" id="First-Name-2" required="true">
                        <div *ngIf="form['firstName'].invalid && (form['firstName'].dirty || form['firstName'].touched)"
                            class="error">Enter your First name</div>
                    </div>
                    <div class="form-wrap">
                        <input type="text" class="input-field w-input" formControlName="lastName"
                            name="Last-Name" data-name="Last Name" placeholder="Enter Last Name *"
                            id="Last-Name">
                        <div *ngIf="form['lastName'].invalid && (form['lastName'].dirty || form['lastName'].touched)"
                            class="error">Enter your Last name</div>
                    </div>
                    <div class="form-wrap">
                        <input type="text" class="input-field w-input"
                            formControlName="other_specialty" name="Specialty-Name"
                            data-name="Specialty Name" placeholder="Enter Specialty *"
                            id="Practice-Name">
                        <div *ngIf="form['other_specialty'].invalid && (form['other_specialty'].dirty || form['other_specialty'].touched)"
                            class="error">Enter your specialty </div>
                    </div>
                    <div class="form-wrap">
                        <input type="text" class="input-field w-input"
                            formControlName="practiceName" name="Practice-Name"
                            data-name="Practice Name" placeholder="Enter Practice Name *"
                            id="Practice-Name">
                        <div *ngIf="form['practiceName'].invalid && (form['practiceName'].dirty || form['practiceName'].touched)"
                            class="error">Enter your Practice name</div>
                    </div>
                    <div class="form-wrap">
                        <input type="text" class="input-field w-input" formControlName="email"
                            name="Email" data-name="Email" placeholder="Email *" id="Email">
                        <div *ngIf="form['email'].invalid && (form['email'].dirty || form['email'].touched)"
                            class="error">
                            Please enter valid Email</div>
                    </div>

                    <div class="form-wrap">
                        <div class="phone" style="position: relative;">
                            <input type="text" class="select-field w-select"
                                formControlName="countryCode" autocomplete="new-password"
                                [matMenuTriggerFor]="belowMenu" #countryCodeSearch required>
                            <div style="   top: 0.5em;
                        position: absolute;
                        left: 13%; margin-left: -10px;">

                                <span class="material-icons" style="cursor: pointer;color: #8a8a8a;"
                                    (click)="countryCodeSearch.click()">
                                    keyboard_arrow_down</span>
                            </div>
                            <div style="max-height: 200px;overflow: hidden;">
                                <mat-menu #belowMenu="matMenu" yPosition="below"
                                    autoActiveFirstOption>
                                    <div id="w-node-_8308320a-a396-9646-7eb0-6fa2c9047ba0-b57daefc"
                                        style="margin-left: 1em;margin-right: 1em;">
                                        <input type="text" class="w-input" data-name="Email"
                                            #searchInput1 (blur)="searchInput1.focus()"
                                            placeholder="Search by Country / Code"
                                            (keyup)="searchCountry($event)"
                                            (click)="$event.stopPropagation()">
                                    </div>
                                    <div style="height: 200px;
                        overflow-y: auto;">
                                        <span *ngFor="let country of searchedCountries"
                                            (click)="slectedCountry(country.dial_code)"
                                            mat-menu-item>{{country.name}}
                                            {{country.dial_code}}</span>
                                    </div>
                                </mat-menu>
                            </div>
                            <input type="text" class="input-field w-input" maxlength="256"
                                formControlName="phone" data-name="Phone" placeholder="Phone *"
                                id="Phone">
                        </div>
                        <div *ngIf="form['phone'].invalid && (form['phone'].dirty || form['phone'].touched)"
                            class="error">
                            Please enter valid Phone number
                        </div>
                    </div>
                </div>
                <button (click)="otherSpecialitysubmit()" data-wait="Please wait..."
                    style="margin: 15px;" class="button w-button"
                    [disabled]="!form['firstName'].valid || !form['lastName'].valid || !form['practiceName'].valid
           ||!form['email'].valid || !form['countryCode'].valid ||!form['phone'].valid || !form['other_specialty'].valid  ">
                    Submit
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="form['speciality'].value &&  form['speciality'].value!='OTHER'">
        <div class="question-block">
            <p class="question black" style="margin-top: 0;">What is your degree?</p>
            <div class="wrapper">
                <div class="form-wrap degree"><select id="field-4" name="field-4"
                        data-name="Field 4" class="select-field full w-select"
                        formControlName="degree">
                        <option *ngFor="let degree of degrees" [value]="degree">{{degree}}</option>
                    </select></div>
            </div>
        </div>



        <div class="question-block">
            <div *ngIf="services.length==0;else servicesList">
                <span>Services are loading... </span>
                <mat-spinner style="margin: auto;" class="custom-spinner" [diameter]="50">
                </mat-spinner>
            </div>
            <ng-template #servicesList>
                <p class="question black" style="margin-top: 0;">We’ll build these pages for your
                    website</p>
                <p class="question-description">Uncheck the pages you don’t want</p>
                <div class="wrapper">
                    <div class="service-options-wrapper">
                        <label class="w-checkbox checkbox-field checkbox_field checked"
                            *ngFor="let service of services"
                            [style.border-color]="!service.checked? '#e3e8e4' : '#ffce31'">
                            <img loading="lazy" src="./../../assets/images/black-arrow.svg" alt=""
                                class="check-icon" [style.display]="service.checked?'block':'none'">
                            <input type="checkbox" name="Eyelid-Surgery-3" id="Eyelid-Surgery-3"
                                [(ngModel)]="service.checked" [ngModelOptions]="{standalone: true}"
                                data-name="Eyelid Surgery 3" class="w-checkbox-input checkbox">
                            <span for="Eyelid-Surgery-3"
                                class="checkbox-title-2 w-form-label">{{service.name}}</span>
                        </label>
                    </div>
                    <div *ngIf="validateServices()" class="error">
                        Please Select Servies</div>
                </div>
            </ng-template>
        </div>
        <div class="div-block-11">
            <button (click)="next()" data-wait="Please wait..." class="button w-button"
                [disabled]="!form['speciality'].valid ||!form['degree'].valid || validateServices()">
                Continue
            </button>
        </div>
    </div>
</div>

<div class="section-2">

    <div class="question-block">
        <div *ngIf="form['googleList'].value=='YES'">
            <p class="question">Find your Google Listing</p>
            <p class="question-description">Why do we need this? We will fetch the details on your
                Google
                listing and automatically sync them to your website.</p>
            <div class="wrapper">
                <div class="div-block-3">
                    <div class="listed-on-google">
                        <div class="form-wrap">
                            <input type="text" class="input-field w-input"
                                name="Search-Your-Business"
                                data-name=" Please enter your practice name/Google Listing name"
                                placeholder="Search Your Practice" id="Search-Your-Business"
                                formControlName="practiceName" ngx-google-places-autocomplete
                                matInput #addressInput [options]='options' #placesRef="ngx-places"
                                (onAddressChange)="handleAddressChange($event)" />
                            <div *ngIf="form['practiceName'].invalid && (form['practiceName'].dirty || form['practiceName'].touched)"
                                class="error">
                                Please Enter Practice Name</div>
                        </div>
                        <div class="text-block-3"><span id="content1"> Don’t have a Google
                                Listing? </span> <a
                                style="cursor: pointer;text-decoration: underline;"
                                (click)="gmbAccountYesOrNo(form['googleList'].value)">Click Here</a>
                        </div>
                    </div>
                    <button (click)="prev()" value="Back" class="previous-button w-button">
                        Back
                    </button>
                    <button (click)="next()" data-wait="Please wait..." class="button w-button"
                        [disabled]="!form['practiceName'].valid">
                        Continue
                    </button>
                </div>
            </div>
        </div>
        <div class="div-block-3" *ngIf="form['googleList'].value!='YES'">
            <p class="question">Don’t have a Google Listing?</p>
            <p class="question-description">No problem, enter your details below</p>
            <p class="form-title">Please Enter Your Information (* fields are required)</p>
            <div class="not-listed-on-google-form">
                <div class="form-wrap">
                    <input type="text" class="input-field w-input" formControlName="practiceName"
                        data-name="Business Name 2" placeholder="Business Name *"
                        id="Business-Name-2">
                    <div *ngIf="form['practiceName'].invalid && (form['practiceName'].dirty || form['practiceName'].touched)"
                        class="error">
                        Please Enter Practice Name</div>
                </div>
                <div class="form-wrap">
                    <input type="text" class="input-field w-input" formControlName="website"
                        data-name="Website 2" placeholder="Website" id="Website-2">

                </div>
                <div class="form-wrap">
                    <input type="text" formControlName="address" class="input-field w-input"
                        name="Address-2" data-name="Address 2" placeholder="Address *"
                        id="Address-2">
                    <div *ngIf="form['address'].invalid && (form['address'].dirty || form['address'].touched)"
                        class="error">
                        Please Enter Address</div>
                </div>
                <div class="form-wrap">
                    <input type="text" formControlName="city" class="input-field w-input"
                        name="Address-2" data-name="Address 2" placeholder="City *" id="Address-2">
                    <div *ngIf="form['city'].invalid && (form['city'].dirty || form['city'].touched)"
                        class="error">
                        Please Enter City Name</div>
                </div>
                <div class="form-wrap">
                    <input type="text" formControlName="state" class="input-field w-input"
                        name="Address-2" data-name="Address 2" placeholder="State *" id="Address-2">
                    <div *ngIf="form['state'].invalid && (form['state'].dirty || form['state'].touched)"
                        class="error">
                        Please Enter State Name</div>
                </div>
            </div>
            <div class="text-block-3" style="padding-bottom: 25px;">Have a Google Listing? <a
                    style="cursor: pointer;text-decoration: underline;"
                    (click)="gmbAccountYesOrNo(form['googleList'].value)">Click Here</a>
            </div>
            <button (click)="prev()" value="Back" class="previous-button w-button">
                Back
            </button>
            <button (click)="next()" data-wait="Please wait..." class="button w-button"
                [disabled]="!form['practiceName'].valid">
                Continue
            </button>
        </div>

    </div>
</div>


<div class="question-block">
    <p class="question">Contact Information</p>
    <p class="question-description">Where should we send you the link to your new website?</p>
    <div class="wrapper">
        <div class="div-block-3">
            <p class="form-title">Please Enter Your Information (* fields are Required)</p>
            <div class="div-block-4">
                <div class="form-wrap">
                    <input type="text" class="input-field w-input" formControlName="firstName"
                        maxlength="256" name="First-Name" data-name="First Name"
                        placeholder="Enter First Name *" id="First-Name-2" required="true">
                    <div *ngIf="form['firstName'].invalid && (form['firstName'].dirty || form['firstName'].touched)"
                        class="error">
                        Please Enter First Name</div>
                </div>
                <div class="form-wrap">
                    <input type="text" class="input-field w-input" maxlength="256"
                        formControlName="lastName" name="Last-Name" data-name="Last Name"
                        placeholder="Enter Last Name *" id="Last-Name">
                    <div *ngIf="form['lastName'].invalid && (form['lastName'].dirty || form['lastName'].touched)"
                        class="error">
                        Please Enter Last Name</div>
                </div>
                <div class="form-wrap">
                    <div class="phone" style="position: relative;">
                        <input type="text" class="select-field w-select"
                            formControlName="countryCode" autocomplete="new-password"
                            [matMenuTriggerFor]="belowMenu" #countryCodeSearch required>
                        <div style="   top: 0.5em;
                    position: absolute;
                    left: 13%; margin-left: -10px;">

                            <span class="material-icons" style="cursor: pointer;color: #8a8a8a;"
                                (click)="countryCodeSearch.click()">
                                keyboard_arrow_down</span>
                        </div>
                        <div style="max-height: 200px;overflow: hidden;">
                            <mat-menu #belowMenu="matMenu" yPosition="below" autoActiveFirstOption>
                                <div id="w-node-_8308320a-a396-9646-7eb0-6fa2c9047ba0-b57daefc"
                                    style="margin-left: 1em;margin-right: 1em;">
                                    <input type="text" class="w-input" data-name="Email"
                                        #searchInput1 (blur)="searchInput1.focus()"
                                        placeholder="Search by Country / Code"
                                        (keyup)="searchCountry($event)"
                                        (click)="$event.stopPropagation()">
                                </div>
                                <div style="height: 200px;
                    overflow-y: auto;">
                                    <span *ngFor="let country of searchedCountries"
                                        (click)="slectedCountry(country.dial_code)"
                                        mat-menu-item>{{country.name}}
                                        {{country.dial_code}}</span>
                                </div>
                            </mat-menu>
                        </div>
                        <input type="text" class="input-field w-input" maxlength="256"
                            formControlName="phone" data-name="Phone" placeholder="Phone"
                            id="Phone">
                    </div>
                    <div *ngIf="form['phone'].invalid && (form['phone'].dirty || form['phone'].touched)"
                        class="error">
                        Please enter valid Phone number
                    </div>
                </div>


                <div class="form-wrap">
                    <input type="text" class="input-field w-input" maxlength="256"
                        formControlName="email" name="Email" data-name="Email" placeholder="Email *"
                        id="Email">
                    <div *ngIf="form['email'].invalid && (form['email'].dirty || form['email'].touched)"
                        class="error">
                        Please enter valid Email</div>
                </div>

            </div>


        </div>
    </div>
</div>
</div> -->