<div class="container-fluid">
<div class="row justify-content-center">
<div class="col-4 justify-content-sm-center">
  <section id="typeforms">
    <!--Typeforms -->
    <div class="container">
      <div class="row justify-content-md-center">
        <a [routerLink]="['/why']" class="btn btn-primary circle" data-toggle="tooltip" data-placement="bottom" title="Your marketing objectives and KPIs" ripple-radius>What</a>
      </div>
      <div class="row justify-content-md-center">
        <a [routerLink]="['/why']" class="btn btn-primary circle" data-toggle="tooltip" data-placement="bottom" title="Your marketing objectives and KPIs" ripple-radius>Why</a>
      </div>
      <div class="row justify-content-md-center">
        <a [routerLink]="['/who']" class="btn btn-primary circle" data-toggle="tooltip" data-placement="bottom" title="Who is your target customer?" ripple-radius>Who</a>
      </div>
    </div>
  </section>
</div>
<div class="col-8 justify-content-sm-center">
  <app-summary-plan width="50%"></app-summary-plan>
</div>
</div>
</div>
