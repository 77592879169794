/*
 * Imported and/or used in legacy code
 */

export class TrafficSummary {
  rank: number;
  url: string;
  time_period: Date;
  visits:number;
  pages_per_visit: number;
  avg_duration: number;
  bounce_rate: number;
  similar_sites: string;
  country: string;
}
