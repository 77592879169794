<div *ngIf="allMessages.length==0" style="text-align: center;"> No Messages</div>
<div *ngIf="allMessages.length>0">
  <div *ngFor="let message of allMessages">
    <div class="row">
      <div class="col-sm-2">
        <p style="vertical-align:middle;">
          <i *ngIf="(message?.direction === 'outbound-api') || (message?.direction === 'outbound-reply')"
            class="material-icons" style="vertical-align : middle;font-size:1.5rem;"
            matTooltip="Sent Message">message<sup><i class="material-icons"
                style="font-size:1rem;">call_made</i></sup></i>
          <i *ngIf="message?.direction === 'inbound'" class="material-icons"
            style="vertical-align : middle;font-size:1.5rem;">message<sup><i class="material-icons"
                style="font-size:1rem;" matTooltip="Received Message">call_received</i></sup></i>
        </p>
        <p class="extraInfo">{{message.date.toDate() | date:'MMM d, y h:mm a' }}</p>
        <!-- <p class="extraInfo">{{message.status | titlecase}}</p> -->
      </div>
      <div class="col-sm-10">
        <p>{{message.sourceMsg}}</p>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>