import { switchMap } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UserService } from "../../_services/user.service";
import { MarketingPlanService, AlertService } from "../../_services/index";
import { ViewChild } from "@angular/core";
//import { ModalDirective } from 'angular-bootstrap-md';

@Component({
  selector: "app-new-plan",
  templateUrl: "./new-plan.component.html",
  styleUrls: ["./new-plan.component.scss"],
})
export class NewPlanComponent implements OnInit {
  model: any;
  flag: number;
  planName: string;
  //showPopUp: boolean = false;

  /*
@ViewChild('autoShownModal') public autoShownModal:ModalDirective;
 public isModalShown:boolean = false;
*/

  constructor(
    private userService: UserService,
    private marketingPlanService: MarketingPlanService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  public showModal(): void {
    //this.isModalShown = true;
  }

  public hideModal(): void {
    //this.autoShownModal.hide();
  }

  public onHidden(): void {
    //this.isModalShown = false;
  }

  savePlanName() {
    this.marketingPlanService.saveName(this.planName).then(
      (data) => {
        //console.log(data);
        localStorage.setItem("newPlan", data.text());
      },
      (error) => {
        this.alertService.error("Failed to save plan name ");
      }
    );
    //console.log(plan);
  }

  checkForSaveAccount() {
    let user = this.userService.getUser();
    if (this.flag == 1 && user.isAnonymous === true) {
      //this.isModalShown = true;
      //this.showPopUp = true;
      //this.router.navigate(['/register']);
    }
  }

  /*Back(){
  this.router.navigate(['/all-plans']);
  }*/

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) => this.userService.getFlag(+params["flag"]))
      )
      .subscribe((flag) => (this.flag = flag));
    this.checkForSaveAccount();
  }
}
