import { Component, OnInit, Inject, Input } from '@angular/core';
import firebase from 'firebase';
import { FormBuilderService } from '../../_services/form-builder.service';
import { AccountService } from '../../_services/account.service';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../_services/form';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationService } from '../../_services';
import { InteractiveFormComponent } from '../interactive-form/interactive-form.component';
import { environment } from '../../../environments/environment'
import { DomSanitizer } from '@angular/platform-browser';
import { EmbedEnquiryFormDialog } from '../../form/form.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { CopyFormsComponent } from '../copy-forms/copy-forms.component';

export interface previewDialogData {
  formName: any;
  formUrl: string;
  formSlug: string;
}

@Component({
  selector: 'app-all-forms',
  templateUrl: './all-forms.component.html',
  styleUrls: ['./all-forms.component.css'],
  providers: [DialogService],
})

export class AllFormsComponent implements OnInit {
  @Input() forms: any;
  // forms: any = [];
  patientForms: any = [];
  acc_settings: any;
  result: any;
  acc: any;
  acc_slug: string;
  formId: string;
  state: any;
  showIcons: boolean;
  formUrl: any;
  formName: any;
  formSlug: any;
  constructor(
    private router: Router,
    private formBuilderService: FormBuilderService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private navigationService: NavigationService,
    private dialogService: DialogService,
  ) {
    this.accountService.accChange$.subscribe(
      accChange => {
        this.acc = this.accountService.getCurrentAccDetails();
        // this.getForms(this.state.url);

        this.state = this.navigationService.getCurrentNavigationState();
        if (this.state.url === "/enquiry-forms") {
          this.formSlug = "forms";
          // this.getFormData();
        }
        else if (this.state.url === "/patient-intake-forms") {
          this.formSlug = "patient_forms";
          // this.getFormData();
        }
        else if (this.state.url === "/survey-forms") {
          this.formSlug = "survey_forms";
          // this.getFormData();
        }
      });

    console.log(this.forms);
  }

  ngOnInit() {
    this.acc = this.accountService.getCurrentAccDetails();
    this.state = this.navigationService.getCurrentNavigationState();
    // console.log(this.state, this.state.url);
    this.showIcons = true;

    console.log(this.forms);

    if (this.state.url === "/enquiry-forms") {
      this.formSlug = "forms";
      // this.getFormData();
    }
    else if (this.state.url === "/patient-intake-forms") {
      this.formSlug = "patient_forms";
      // this.getFormData();
      this.showIcons = false;
    }
    else if (this.state.url === "/survey-forms") {
      this.formSlug = "survey_forms";
      // this.getFormData();
    }
    this.getFormData();
    // else {
    //   this.getForms(this.state.url);
    // }
  }

  getFormData() {
    this.forms = [];
    this.formBuilderService.getFormData(this.formSlug).then(res => {
      res.forEach(doc => {
        let form = { id: '', slug: '', data: {} };
        form.data = doc.data();
        form.id = doc.id;
        form.slug = doc.data().slug;
        this.forms.push(form);
      });
    });
  }

  // getForms(selctedSlug) {
  //   this.forms = [];
  //   this.formBuilderService.getForms(selctedSlug).then(querySnapshot => {
  //     this.acc = this.accountService.getCurrentAccDetails();
  //     querySnapshot.forEach(doc => {
  //       let form = { id: '', slug: '', data: {} };
  //       form.data = doc.data();
  //       form.id = doc.id;
  //       form.slug = doc.data().slug;
  //       this.forms.push(form);
  //     });
  //   });
  // }

  createForm() {
    this.formBuilderService.createForm(this.state.url);
  }

  deleteForm(form_id: any) {
    if (confirm('Are you sure you want to delete this form?')) {
      this.formBuilderService.deleteForm(form_id, this.formSlug)
        .then(form => {
          this.forms = this.forms.filter(x => x.id !== form_id);
          alert('Form deleted!');
          console.log("doc deleted and data removed");
        });
      this.getFormData();
    }
  }

  openDialogForCopy(form, accountSlug, formSlug, formName) {
    console.log(form);

    if (this.state.url === "/enquiry-forms") {
      this.formUrl = `${environment.interactiveFormURL}/interactive-form/${accountSlug}/${formSlug}`;
      console.log(this.formUrl);
    }
    else if (this.state.url === "/patient-intake-forms") {
      this.formUrl = `${environment.patientIntakeFormUrl}/form/${accountSlug}/${formSlug}`;
      console.log(this.formUrl);
    }
    else if (this.state.url === "/survey-forms") {
      this.formUrl = `${environment.interactiveFormURL}/survey-forms/${accountSlug}/${formSlug}`;
      console.log(this.formUrl);
    }

    const ref = this.dialogService.open(CopyFormsComponent, {
      data: { selectedForm: form, allForm: this.forms, formUrl: this.formUrl, formSlug: formSlug, formName: formName, type: this.state.url },
      header: 'Copy Form',
      width: "400px",
      contentStyle: { "height": "250px" },
      showHeader: true,
      closable: true
    });

    // ref.onClose.subscribe((closed) => {
    //   this.getFormData();
    // });

    ref.onClose.subscribe((closed) => {
      this.getFormData();
    });

    // ref.onDestroy.subscribe((destroy) => {
    //   this.getFormData();
    // });
  }

  openDialogForPreview(accountSlug, formSlug, formName) {
    console.log(formName);

    if (this.state.url === "/enquiry-forms") {
      this.formUrl = `${environment.interactiveFormURL}/interactive-form/${accountSlug}/${formSlug}`;
      console.log(this.formUrl);
    }
    else if (this.state.url === "/patient-intake-forms") {
      this.formUrl = `${environment.patientIntakeFormUrl}/form/${accountSlug}/${formSlug}`;
      console.log(this.formUrl);
    }
    else if (this.state.url === "/survey-forms") {
      this.formUrl = `${environment.interactiveFormURL}/survey-forms/${accountSlug}/${formSlug}`;
      console.log(this.formUrl);
    }

    let previewdialogRef = this.dialog.open(PreviewDialogBox, {
      panelClass: "custom-form-preview-dialog-container",
      width: '800px',
      height: '650px',
      data: { formUrl: this.formUrl, formSlug: formSlug, formName: formName },

    });
  }

  openDialog() {
    this.acc_settings = this.accountService.getCurrentAccDetails();
    if (this.acc_settings.form_settings === undefined) {
      this.acc_settings.form_settings = {};
      this.acc_settings.form_settings.hubspot_id = "";
      this.acc_settings.form_settings.hubspot_form_id = "";
      this.acc_settings.form_settings.email = "";
    }
    const dialogRef = this.dialog.open(DialogBox, {
      width: '350px',
      height: '65%',
      data: { hubspot_id: this.acc_settings.form_settings.hubspot_id, hubspot_form_id: this.acc_settings.form_settings.hubspot_form_id, email: this.acc_settings.form_settings.email, stateUrl: this.state.url }
    });
  }

  openDialogForDelete(form_id: any) {
    //  let forms = this.formBuilderService.getForm(form_id);
    console.log(form_id);
    const deletedialogRef = this.dialog.open(DeleteDialogBox, {
      width: '350px',
      height: '30%',
      data: { form_id: form_id, form_type: this.formSlug }
    });
    deletedialogRef.afterClosed().subscribe(resp => {
      this.getFormData();
    });
    //this.deleteForm(form_id);
  }

  openDialogToEmbedCode(accountSlug, formSlug) {
    if (this.state.url === "/enquiry-forms") {
      this.formUrl = `${environment.interactiveFormURL}/interactive-form/${accountSlug}/${formSlug}`;
      console.log(this.formUrl);
    }
    const dialogRef = this.dialog.open(EmbedEnquiryFormDialog, {
      width: '850px',
      height: '450px',
      data: { formUrl: this.formUrl }
    });
  }
}

@Component({
  selector: 'preview-dialog-box',
  templateUrl: './preview-dialog-box.html',
  //styleUrls: ['./all-forms.component.css']
})
export class PreviewDialogBox {
  formUrl: any;
  title: any;
  constructor(
    // private formBuilderService: FormBuilderService,
    // private accountService: AccountService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DialogBox>,
    @Inject(MAT_DIALOG_DATA) public data: previewDialogData) {
    console.log(data);
    console.log(data.formUrl);
    this.formUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.formUrl);
    console.log(this.formUrl);
    this.title = data.formName;
    console.log(this.formUrl, this.title);
  }

  close(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'dialog-box-content',
  templateUrl: './dialog-box-content.html',
  styleUrls: ['./all-forms.component.css']
})
export class DialogBox {
  applyhubspot: boolean = false;
  applyToAll: boolean = false;

  constructor(
    private formBuilderService: FormBuilderService,
    private accountService: AccountService,
    public dialogRef: MatDialogRef<DialogBox>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    console.log(data);
    if (data.hubspot_id !== '' || data.hubspot_form_id !== '') {
      this.applyhubspot = true;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveSettings() {
    this.formBuilderService.setSettings(this.data, this.applyToAll, this.data.stateUrl);
  }
}


@Component({
  selector: 'delete-dialog-box-content',
  templateUrl: './delete-dialog-box-content.html',
  styleUrls: ['./all-forms.component.css']
})
export class DeleteDialogBox {
  forms: any = [];
  formid: string;
  formType: string;
  //isFormId : boolean = false;
  constructor(
    private formBuilderService: FormBuilderService,
    private accountService: AccountService,
    public deletedialogRef: MatDialogRef<DeleteDialogBox>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private formId: DialogData) {
    console.log(formId);
    this.formid = this.formId['form_id'];
    this.formType = this.formId['form_type'];
  }

  close(): void {
    this.deletedialogRef.close();
  }


  deleteForm() {
    console.log(this.formid);
    this.formBuilderService.deleteForm(this.formid, this.formType)
      .then(() => {
        this.forms = this.forms.filter(x => x.id !== this.formid);
        this.openSnackBar();
        console.log("doc deleted and data removed");
        //console.log(this.formId);
      },
        error => {
          console.error(error);
        }
      );

  }

  openSnackBar() {
    this.snackBar.open('Form Deleted', '', {
      duration: 2000
    });
  }


}
