<mat-divider></mat-divider>
<center>
  <div *ngIf="!isReady">
    <!--Fetching your campaigns ...-->
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</center>

<!-- -----------sub header---------- -->
<div class="row" id="subHeader1">
  <div id="pageTitle">
    <a [routerLink]="['/email-campaign']"> <i class="pi pi-chevron-left"></i>Email Campaigns
    </a>
  </div>

  <div id="subPageTitle">
    {{campaign.name}}
  </div>
</div>
<!-- ------------subheader ends--------- -->

<div class="row">
  <div class="col-0 triangle-bottomleft">
  </div>
  <div class="col">
    <table border="0" width=100%>
      <tr>
        <td width="50%">
          <div> Recipients </div>
        </td>
        <td width="50%">
          <span class="metric-value">{{campaign?.details?.Recipients | number}}</span>
        </td>
      </tr>
      <tr>
        <td width="50%">
          <div> Delivered </div>
        </td>
        <td width="50%">
          <span class="metric-value">{{campaign?.details?.Recipients - campaign?.details?.Bounced | number}}</span>
        </td>
      </tr>
      <tr>
        <td width="50%">
          <div> Delivery Rate </div>
        </td>
        <td width="50%">
          <span class="metric-value"
            *ngIf="(campaign?.details?.Recipients - campaign?.details?.Bounced) > 0; else NoDeliveries">
            {{((campaign?.details?.Recipients - campaign?.details?.Bounced) / campaign?.details?.Recipients) | percent:'1.1-1'}}
          </span>
        </td>
      </tr>
      <tr>
        <td width="50%">
          Bounces
        </td>
        <td width="50%">
          <span class="metric-value">{{campaign?.details?.Bounced | number}}</span>
        </td>
      </tr>
      <tr>
      <tr>
        <td width="50%">
          Bounce Rate
        </td>
        <td width="50%">
          <span class="metric-value"
            *ngIf="(campaign?.details?.Recipients - campaign?.details?.Bounced) > 0; else NoDeliveries">
            {{(campaign?.details?.Bounced / campaign?.details?.Recipients)  | percent:'1.1-1'}}
          </span>
        </td>
      </tr>
      <tr>
        <td width="50%">
          Unsubscribes
        </td>
        <td width="50%">
          <span class="metric-value">{{campaign?.details?.Unsubscribed | number}}</span>
        </td>
      </tr>
    </table>
    <mat-divider> </mat-divider>
    <table border="0" width=100%>
      <tr>
        <td width="50%">
          Total Opens
        </td>
        <td width="50%">
          <span class="metric-value">{{ campaign?.details?.TotalOpened | number}}</span>
        </td>
      </tr>
      <tr>
        <td width="50%">
          Unique Opens
        </td>
        <td width="50%">
          <span class="metric-value">{{ campaign?.details?.UniqueOpened | number}}</span>
        </td>
      </tr>
      <tr>
        <td width="50%">
          Open Rate
        </td>
        <td width="50%">
          <span class="metric-value"
            *ngIf="(campaign?.details?.Recipients - campaign?.details?.Bounced) > 0; else NoDeliveries">
            {{ (campaign?.details?.UniqueOpened / (campaign?.details?.Recipients - campaign?.details?.Bounced)) | percent:'1.1-1'}}
          </span>
        </td>
      </tr>
      <tr>
        <td width="50%">
          <div class=""> Clicks </div>
        </td>
        <td width="50%">
          <span class="metric-value">{{ campaign?.details?.Clicks | number}}</span>
        </td>
      </tr>
      <tr>
        <td width="50%">
          <div class=""> Click Rate </div>
        </td>
        <td width="50%">
          <span class="metric-value"
            *ngIf="(campaign?.details?.Recipients - campaign?.details?.Bounced) > 0; else NoDeliveries">
            {{ (campaign?.details?.Clicks / (campaign?.details?.Recipients - campaign?.details?.Bounced)) | percent:'1.1-1'}}
          </span>
        </td>
      </tr>
      <tr>
        <td width="50%">
          <div class=""> Click to Open Ratio </div>
        </td>
        <td width="50%">
          <span
            class="metric-value">{{ (campaign?.details?.Clicks / campaign?.details?.UniqueOpened) | percent:'1.1-1' }}</span>
        </td>
      </tr>
      <ng-template #NoDeliveries>
        <div class="metric-value"> 0 </div>
      </ng-template>
    </table>

    <mat-divider></mat-divider>
  </div>
  <div class="col-0 triangle-bottomright">
  </div>
</div>
<!--
<div class="col-sm-12">
   <table class="table-outline">
    <tr>
      <th>Top countries</th>
      <th>clicks</th>
    </tr>
    <tr *ngFor="let country of campaign?.details?.Countries">
      <td>{{ country?.name }}</td>
      <td>{{ country?.count }}</td>
    </tr>
  </table>
</div>

<div class="col-sm-12">
<table class="table-outline">
 <tr>
   <th>Most popular links</th>
   <th>clicks</th>
 </tr>
 <tr *ngFor="let link of campaign?.details?.url">
   <td>{{ link?.url }}</td>
   <td>{{ link?.count }}</td>
 </tr>
</table>
</div>

-->





<div class="col">
  <p>This is how this email appeared in your recipient's inbox</p>
  <table>
    <tr>
      <th class="th-set">
        <strong>{{ acc.name }}</strong>
      </th>
      <th class="th-set">
        {{ campaign?.subject }}
      </th>
    </tr>
  </table>
</div>
<mat-divider></mat-divider>