<div style="margin-top: 5px;">
    <div *ngFor="let item of items ;let i=index" [class.selectMenu]="active === i" class="row menu" id="menuEvent">
        <div style="display: table;width: 100%;">
            <div *ngIf="item.icon" style="display: table-cell;width: 15%;">
                <img [src]="item.icon" width="40px">
            </div>
            <div [innerHTML]="item.name" style="display:table-cell;padding-left: 10px;width: 85%;">
            </div>
        </div>
    </div>
</div>