<div class="disable-whole-div__myDIV">
  <div class="disable-whole-div__cover"></div>

    <mat-card class="info-cards" [disabled]="disableDIV">
      <mat-card-title class="card-title">Target Markets</mat-card-title>
      <div *ngIf="!isReady">
        Analyzing your Geo Traffic...
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div *ngIf="isReady">
        <div class="row p-2 justify-content-center">
          <div class="col">
            <!--Doughnut Chart for Geo Traffic-->
            <p>Traffic breakup by country</p>
            <div style="display: block">
              <canvas mdbChart
              [chartType]="chartType"
              [data]="chartData"
              [labels]="chartLabels"
              [colors]="chartColors"
              [options]="chartOptions"
              [legend]="true"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)">
            </canvas>
            </div>
          </div>
        </div>
        <div class="row p-2">
          <div class="col country_select">
          <p>Based on your traffic breakout by country, we have selected your primary geographic market(s), please confirm:</p>
          <div class="form-group" *ngFor="let list of selected_countries; let i = index">
            <mat-checkbox class="example-margin" name="list" [(ngModel)]="country[i]">{{list}}</mat-checkbox>
            <!--
            <input class="d-inline-block" type="checkbox"  id="checkbox101">
            <label for="checkbox101">{{list}}</label>
          -->
          </div>
          <mat-card-actions align="center">
                <button mat-raised-button (click)="next()" id="setMarketsButton" color="accent">Set These</button>
    <!--        <button mat-raised-button [disabled]="country[0] == false && country[1] == false" (click)="next()" id="setMarketsButton" color="accent">Set These</button> -->
          </mat-card-actions>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
