import { Component, OnInit } from '@angular/core';
import { Router, } from "@angular/router";

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.scss']
})
export class OutletComponent implements OnInit {

  outlet:any;
  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  // onActivate(event) {
  //   document.body.scrollTop = 0;
  //   console.log(this.outlet);
  //   console.log(`new outlet ${event}`);
  // }

}
