<div class="row" id="subHeader1">
  <div id="pageTitle" style="text-align: center;">Configure Calendar</div>
</div>

<div class="view">
  <mat-card *ngIf="config!==undefined">
    <div>
      <form #configForm="ngForm" novalidate style="border:10ch">
        <mat-card>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field class="first-matcard-width dropdown">
                <mat-label>Select your calender</mat-label>
                <mat-select [(value)]="selectedCalenderName" (selectionChange)="getCalenderName()">
                  <mat-option *ngFor="let calender of calenders;let i=index" [value]="calenders[i].slug">
                    {{calender.slug}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="first-matcard-width dropdown">
                <mat-label>Timezone</mat-label>
                <!--<mat-select  [(value)]="selectedTimezone"
								 name="selectedTimezone"
								 (selectionChange)="changeTimezone()">
					<mat-option  *ngFor="let timezone of timezones;let i=index" [value]="timezones[i]">{{timezone}}</mat-option>
					</mat-select>-->
                <mat-select [(value)]="selectedTimezone" (selectionChange)="changeTimezone()">
                  <mat-option *ngFor="let timezone of tzSelected;let i=index" [value]="tzSelected[i].tzname">
                    {{timezone.name}}</mat-option>

                </mat-select>
              </mat-form-field>
              <!--<p>selected timezone:{{selectedTimezone}}</p>-->
            </div>


          </div><br>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field class="first-matcard-width"><input matInput placeholder='Calendar name' name="SlugEle"
                  [(ngModel)]="calenderName" (change)="slugValidation()"
                  matTooltip="Unique ID of calendar {{config.slug}}" class="example-full-width" required>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="first-matcard-width"><input matInput placeholder='Email Associated With calendar'
                  name="MsgEle" [(ngModel)]="config.owner_email" type="email"
                  matTooltip="Email Associated With Calendar: {{config.owner_email}}" required></mat-form-field><br>
            </div>

          </div>
        </mat-card>
        <mat-card class="fixedPosition">
          <div class="row">
            <div class="col-6">
              <mat-calendar [selected]="myFilter" [minDate]="min" [maxDate]="max" (selectedChange)="onSelect($event)"
                [dateFilter]="myFilter" style="height: 200px;width: 300px">
              </mat-calendar>


              <!--	<ngb-datepicker #dp [(ngModel)]="block_date" name="model" [minDate]="minDate" [markDisabled]="isDisabled" [maxDate]="maxDate"    outsideDays="hidden"></ngb-datepicker>
					<ng-template #t let-date let-focused="focused">
					 <span class="custom-day"
					       [class.focused]="focused"
					       [class.range]="isRange(date)"
					       [class.faded]="isHovered(date) || isInside(date)"
					       (mouseenter)="hoveredDate = date"
					       (mouseleave)="hoveredDate = null">
					   {{ date.day }}
					 </span>
					 </ng-template>
					<mat-form-field>
					<input matInput
							[min]="minDate"
             				[max]="maxDate"

             				[matDatepickerFilter]="myFilter"
             				[matDatepicker]="picker"
             				placeholder="Pick a date"
				            disabled>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker disabled="false" [opened]=true></mat-datepicker>
					</mat-form-field>-->
            </div>

            <div class="col-md-6 calander-space">
              <div class="row-3">
                <b>The Days on which you are open :</b><br><br>
                <mat-checkbox labelPosition='before' *ngFor="let option of checkboxOptions; let i = index"
                  [name]="checkboxOptions[i]" [(ngModel)]="toggleDays[i].selected" style="margin: 11px"
                  (change)="changeCalenderConstarint('myFilter')" required>{{option}}</mat-checkbox>
              </div><br>
              <div class="row-3">
                <div class="col-6" *ngIf="disableField==false">
                  <mat-form-field>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepickerFilter]="myFilter"
                      [(ngModel)]="block_date" name="block_date" [matDatepicker]="picker"
                      placeholder="Pick a date you want to block" (dateChange)="Blocked_Dates_Function()" disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                  </mat-form-field>

                </div>


              </div><br>
              <div class="row-3 addvirticalscroll">
                <div *ngFor="let option of Blocked_Date;let i =index" class="row">
                  <div class="col-6">{{option}}</div>
                  <div class="col-6">
                    <mat-icon (click)="Remove_Blocked_Dates(i)" class="icon-btn">remove_circle
                    </mat-icon>
                  </div>
                </div>
              </div><br>
              <div class="row-3">
                <div class="row">
                  <div class="col-6">
                    <!-- /// -->

                    <!-- <div class="col-6"> -->
                    <div *ngIf="disableField==false">
                      <div class="row">
                        <div class="col-sd-6">
                          <mat-form-field><input matInput placeholder="Lead Time" name="minDate" matTooltip="Lead time"
                              [(ngModel)]="config.minDate.key" (change)="changeCalenderConstarint('minDate')" required>
                          </mat-form-field>&nbsp;&nbsp;
                        </div>
                        <div class="col-sd-6">

                          <mat-label>Days</mat-label>

                          <!--<mat-select [(value)]="config.minDate.amount" name="xyz" (selectchange)="changeCalenderConstarint('minDate')" >
              <mat-option value="days">Days</mat-option> </mat-select>-->
                          <!-- <input matInput [(value)]="config.minDate.amount" name="xyz" (cahnge)="changeCalenderConstarint('minDate')" > -->



                        </div>
                      </div>
                    </div>
                    <!--Rollling Part-->
                    <div class="row-6" *ngIf="disableField==false">
                      <div class="row">
                        <div class="col-sd-6">
                          <mat-form-field><input matInput placeholder="Rolling Period" name="maxDate"
                              matTooltip="Rolling Periode" [(ngModel)]="config.maxDate.key"
                              (change)="changeCalenderConstarint('maxDate')" required>
                          </mat-form-field>&nbsp;&nbsp;
                        </div>
                        <div class="col-sd-6">

                          <mat-label>Weeks</mat-label>
                          <!--<mat-select [(value)]="config.maxDate.amount" name="xyz" (selectchange)="changeCalenderConstarint('maxDate')" >
              <mat-option value="days">Weeks</mat-option>
    
              </mat-select>-->
                          <!-- <input matInput [(value)]="config.maxDate.amount" name="xyz" (cahnge)="changeCalenderConstarint('maxDate')" > -->

                        </div>
                      </div>
                    </div>
                    <!-- </div> -->



                    <!-- // -->
                  </div>
                  <div class="col-6">
                    <!--  -->
                    <div class="row">
                      <div class="col">
                        <mat-checkbox (change)="onChangeOfToggle($event)" [checked]="dataPickerState">Date Range
                        </mat-checkbox>
                      </div>

                      <div class="row-6">
                        <div *ngIf="dataPickerState">
                          <div class="col">
                            <div class="row-6">
                              <mat-form-field appearance="fill">
                                <mat-label>choose start date</mat-label>
                                <input matInput [(ngModel)]="start_date" name="start_date" [matDatepicker]="picker1"
                                  placeholder="Choose Start Date" (dateChange)="choose_start_date()">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <div class="row-6">
                              <mat-form-field appearance="fill">
                                <mat-label>choose end date</mat-label>
                                <input matInput [(ngModel)]="end_date" name="end_date" [matDatepicker]="picker2"
                                  placeholder="Choose end Date" (dateChange)="choose_end_date()">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--  -->
                  </div>
                </div>
              </div>


            </div>
          </div>
        </mat-card>





        <!-- /////////////////////////////////// Second Part ////////////////////////////////////////////////////////////////////-->


        <mat-card>
          <div class="row">
            <div class="col-6">
              <b>
                <p>{{timeslots.day}}</p>
              </b>
              <div *ngIf="timeslots.slots.length > 0">
                <b>
                  <p>What time works for you?</p>
                </b>
                <div class="appointment_slot timeslotwidth " *ngFor="let timeslot of timeslots.slots">

                  <!--{{changeCalenderConstarint('timeslots') }}-->

                  <!-- {{changeCalenderConstarint('timeslots') }} -->

                  <p>{{timeslot.start}} - {{timeslot.end}}</p>

                </div>
              </div>
            </div>


            <div class="col-md-6">
              <div class="row-4">
                <br>
                <b>
                  <mat-label>Set timeslots for</mat-label>
                </b><br>
                <mat-form-field>
                  <mat-select [(value)]="day" name="day" (selectionChange)="changeTimeslot()">
                    <mat-option *ngFor="let option of SelectDays;let i=index" [value]="i">{{option}}</mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
              <div class="row-4">
                <div class="row">
                  <div class="col-4">

                    <!-- <lable>From</lable>
					<ngb-timepicker [(ngModel)]="start" name="start" [meridian]="meridian"></ngb-timepicker>
					<button class="btn btn-sm btn-outline-{{meridian ? 'success' : 'danger'}}" (click)="toggleMeridian()">
					    Meridian - {{meridian ? "ON" : "OFF"}}
					</button>
					<hr>
					 -->
                    <!--<mdb-time-picker [buttonLabel]="'Done'" [twelvehour]="true" [darktheme]="false" [placeholder]="'Selected time'" [label]="'Light version, 12hours'" [showClock]="true" [ngModel]="start" name="from" ngDefaultControl></mdb-time-picker>-->


                    <!--<input  style="height:40px" [ngxTimepicker]="picker" name="start" [(ngModel)]="start" required>
	                <ngx-material-timepicker #picker [minutesGap]="5" required  ></ngx-material-timepicker>-->

                    <mat-form-field class="exy"><input matInput placeholder="From" [(ngModel)]="start" name="start">
                    </mat-form-field>
                    <!--<input atp-time-picker value="19:00"/>-->
                    <!--<input atp-time-picker  [(ngModel)]="start"  name="start" />-->
                  </div>
                  <div class="col-md-2">


                    <!-- <ngb-timepicker [(ngModel)]="end" name="end" [meridian]="meridian"></ngb-timepicker>
					<button class="btn btn-sm btn-outline-{{meridian ? 'success' : 'danger'}}" (click)="toggleMeridian()">
					    Meridian - {{meridian ? "ON" : "OFF"}}
					</button>
					<hr>
 -->
                    <mat-form-field class="exy"><input matInput placeholder="To" [(ngModel)]="end" name="end">
                    </mat-form-field>
                    <!--<input atp-time-picker  [(ngModel)]="end"  name="end" [minutesGap]="5"/>-->
                  </div>
                  <div class="col-md-4">
                    <!-- <mat-icon class="add-btn"(click)="Add_Time_Slot()" style="font-size:30px;padding-top:10px;" >add_circle
 -->
                    <mat-icon (click)="Add_Time_Slot()" style="padding-left: 35px;" class="add-btn"
                      class="icon-btn-service1">add_circle
                    </mat-icon>
                  </div>
                </div>
              </div>
              <br>
              <div class="row-md-4">
                <div class="row" *ngIf="TimeSlot[day].slots!=[]">
                  <div *ngFor="let option of TimeSlot[day].slots;let j =index">
                    <div class="col-sm">
                      <div class="col" class="TimeSlot">
                        <button mat-raised-button class="timeslot-button">
                          {{TimeSlot[day].slots[j].start}}-{{TimeSlot[day].slots[j].end}}
                        </button>

                        <mat-icon (click)="Remove_Time_Slots(j)" class="icon-btn-service1">remove_circle
                        </mat-icon>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>




          </div>
        </mat-card>

        <mat-card>
          <div class="row">
            <div div class="col-6">
              <div>
                <div style="margin-left: 15px;">
                  <div *ngIf="show_address===true">
                    <div class="row">
                      <div class="col-md-12">
                        <mat-form-field>
                          <input matInput placeholder="Street" required>
                        </mat-form-field>
                      </div>
                    </div><br>
                    <div class="row">
                      <div class="col-md-6">
                        <mat-form-field>
                          <input matInput placeholder="City" required>
                        </mat-form-field>
                      </div>
                      <div class="col-md-6">
                        <mat-form-field>
                          <input matInput placeholder="State" required>
                        </mat-form-field>
                      </div>
                    </div>


                  </div>

                </div>

              </div><br>

              <div>
                <div style="margin-left: 15px;" *ngIf="show_message===true">
                  <div *ngIf="title==''"><b>Message</b></div>
                  <div *ngIf="title!==''"><b>{{title|titlecase}}</b></div>

                  <mat-form-field *ngIf="show_message" class="example-full-width">
                    <input matInput>
                  </mat-form-field>
                </div>

              </div>

            </div>
            <div class="col-6">
              <div>
                <mat-checkbox name="show_address" [(ngModel)]="show_address">Enable
                  Address</mat-checkbox><br>
                <mat-checkbox name="show_message" [(ngModel)]="show_message">
                  Enable Message
                </mat-checkbox>
                <mat-form-field *ngIf="show_message" class="example-full-width">
                  <input matInput placeholder="Message Title" matTooltip="Title:{{title}}" name="title"
                    [(ngModel)]="title">
                </mat-form-field>
              </div><br>
            </div>
          </div>

        </mat-card>

        <!-- /////////////////////////////////////////////////Third Part ///////////////////////////////////////////////////-->
        <mat-card>
          <div class="row">

            <div class="col-md-6 ">
              <div class="col">
                <div class="row" class="service_font">
                  <div *ngIf="service_enable==true">
                    <div class="row-6">
                      <mat-label><b>Please choose the treatments you are looking for:</b></mat-label>
                    </div><br>
                    <div class="row-6">
                      <section *ngFor="let service of this.config.services.provided; let i=index">
                        <mat-checkbox name="{{this.config.services.provided[i]}}" onclick="return false"
                          [(ngModel)]="this.config.services.provided[i]">{{service}}</mat-checkbox>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div><br><br>
            <div class="col-md-6">
              <div class="row-4" style="margin:5px;padding:5px">
                <mat-label><b>Show services</b></mat-label>
                <mat-slide-toggle labelPosition='before' name="service_enable" [(ngModel)]="service_enable"
                  (change)="changedServiceEnabled($event)" matTooltip="Services which are provided by clinic">
                </mat-slide-toggle>
              </div><br>

              <div class="row-4" *ngIf="service_enable==true">
                <div class=" row">
                  <div class=" col-md-6">
                    <mat-form-field style="margin: 10px">
                      <input matInput placeholder="Services" name="SER" [(ngModel)]="input"
                        matTooltip="Enter Services To add">
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 row">
                    <div class="col-4">
                      <mat-icon (click)="service()" class="icon-btn-service2">add_circle</mat-icon>
                    </div>
                    <div class="col-4" class="icon-btn-service2">
                      <mat-label>Delete All</mat-label>
                      <!-- <button mat-icon-button (click)="Remove_All_services()" label="Delete All" type="submit"
                        [disabled]="enable" name="try">&nbsp; -->
                      <mat-icon style="font-size: 25px;" id="icon-btn" (click)="Remove_All_services()"
                        [disabled]="enable">
                        delete
                      </mat-icon>
                      <!-- </button> -->
                    </div>
                  </div>
                </div>
              </div><br>

              <div class="row-4">
                <div *ngIf="service_enable==true">
                  <div *ngFor="let option of provided;let i =index" class="row">
                    <div class="col-6">
                      {{option}}
                    </div>
                    <div class="col-6">
                      <mat-icon (click)="Remove_Services(i)" class="icon-btn">remove_circle</mat-icon>
                    </div>
                  </div>
                </div>
              </div><br>
              <br>
            </div>
          </div>
        </mat-card>


        <!--//////////////////////////////////////////////FourthPart/////////////////////////////////////////-->
        <mat-card>
          <div class="row">
            <div class="col-md-6" style="font-size: 15px">
              <h style="font-size: 25px"><b>Your appointment with {{AccountName}} has been booked</b></h><br>
              <br><br>
              <b>Date:</b> Appointment Date<br><br>
              <b>Slot:</b> Appointment Slot<br><br>
              <b>You can reach at:</b> <textarea matInput placeholder="Enter location" name="entLoc"
                [(ngModel)]="config.location.address" matTooltip="Address Of Clinic :{{config.location.address}}"
                rows="5" required>
</textarea><br><br>

              <div *ngIf="config.location.map_link">
                <a href="{{config.location.map_link}}">Get Directions</a><br><br>
              </div>

              <b>Contact No:</b> <a href="tel:{{config.location.phone}}">
                {{config.location.phone}}</a><br>

              <!-- <textarea matInput placeholder="Message to patient" name="msg" [(ngModel)]="config.message" matTooltip="Message to patient :{{config.message}}" style="overflow :auto;resize: none" rows="5" >
</textarea><br><br> -->
              <b>Message to Patient</b>
              <div [innerHTML]="config.message" style="max-height:200px; overflow:auto;"></div><br>

              <div *ngIf="config.terms_conditions===true">
                <b>Terms and Conditions</b>
                <div [innerHtml]="config.terms_conditions_info"></div><br>
              </div>

              <br>

              Click here to <a href="">reschedule</a> or <a href>cancel</a> the appointment<br><br>

              <!-- <div *ngIf="config.payments===true">
                <b>Payment Information</b>
                <div [innerHtml]="config.payment_info"></div><br>
              </div> -->

              <mat-checkbox name="config.payments" [checked]="paymentState" [(ngModel)]="paymentState">
                Payment Information
              </mat-checkbox>
              <div class="example-full-width" *ngIf="paymentState===true">
                <!-- <textarea style="overflow :auto;resize: none" rows="10" name="payments"
                  [(ngModel)]="config.payment_info" matInput placeholder="Enter Payment Details"></textarea> -->
                <!-- <<<<<<< HEAD -->

                <div class="col">

                  <div class="row-4">
                    <div class="col">
                      <mat-form-field class="example-full-width" *ngIf="config.payments===true">
                        <textarea style="overflow :auto;resize: none" rows="10" name="payments"
                          [(ngModel)]="config.payment_info" matInput placeholder="Enter Payment Details"></textarea>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row-4">
                    <div class="col">
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter Currency Code" [(ngModel)]="currencyCode" name="currency"
                            size="5px" width="8px" required>
                        </mat-form-field>
                      </div>
                      <br>
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter The Amount" [(ngModel)]="paymentAmount" name="amount"
                            size="	5px" width="8px" required>
                        </mat-form-field>
                      </div>
                      <br>
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter Payment Timeout In Minutes" [(ngModel)]="paymentTimeout"
                            name="timeout" size="5px" width="8px" required>
                        </mat-form-field>
                      </div>
                      <br>
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter Display Link" [(ngModel)]="displayLink" name="displayLink"
                            size="5px" width="8px" required>
                        </mat-form-field>
                      </div>
                      <br>
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter Redirect Link " [(ngModel)]="redirectLink"
                            name="redirectLink" size="5px" width="8px" required>
                        </mat-form-field>
                      </div>
                      <br>
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter Redirect Link Text" [(ngModel)]="redirectLinkText"
                            name="redirectLinkText" size="5px" width="8px" required>
                        </mat-form-field>
                      </div>
                      <!-- =======
                <div class="col">
                  <div class="row-4">
                    <div class="col">
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter Currency Code" [(ngModel)]="currencyCode" name="currency"
                            size="5px" width="8px" required>
                        </mat-form-field>
                      </div>
                      <br>
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter The Amount" [(ngModel)]="paymentAmount" name="amount"
                            size="	5px" width="8px" required>
                        </mat-form-field>
                      </div>
                      <br>
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter Payment Timeout" [(ngModel)]="paymentTimeout"
                            name="timeout" size="5px" width="8px" required>
                        </mat-form-field>
                      </div>
                      <br>
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter Display Link" [(ngModel)]="displayLink" name="displayLink"
                            size="5px" width="8px" required>
                        </mat-form-field>
                      </div>
                      <br>
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter Redirect Link " [(ngModel)]="redirectLink"
                            name="redirectLink" size="5px" width="8px" required>
                        </mat-form-field>
                      </div>
                      <br>
                      <div class="row-2">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter Redirect Link Text" [(ngModel)]="redirectLinkText"
                            name="redirectLinkText" size="5px" width="8px" required>
                        </mat-form-field>
>>>>>>> 8d29ba19e29f3c53016920bf9285433a9bf61dcf
                      </div> -->
                    </div>
                  </div>
                  <br>
                  <!-- <div class="row-3">
                      <mat-radio-group [(ngModel)]="getPaymentUrl" (change)="getUrl($event)" [name]="'aList'" aria-label="Select an option">
                        <div class="row">
                          <div class="col-4">
                            <mat-radio-button value="displayLink">Logo Url</mat-radio-button>
                          </div>
                          <div class="col-4">
                            <mat-radio-button value="redirectLink">Redirect Url</mat-radio-button>
                          </div>
                          <div class="col-4">
                            <mat-radio-button value="redirectLinkText">Redirect Link Text</mat-radio-button>
                          </div>
                        </div>
                      </mat-radio-group>
                    </div> -->
                  <!-- <<<<<<< HEAD -->
                  <br>
                  <br>

                  <!-- </div> -->
                  <!-- =======
                  <br>
                  <div class="row-4">
                    <mat-checkbox (change)="onChangeOfToggle($event)" [checked]="dataPickerState">Date Range
                    </mat-checkbox>
                  </div>
                  <br>

                  <div class="row-4">
                    <div *ngIf="dataPickerState">
                      <div class="row">
                        <div class="col-6">
                          <mat-form-field appearance="fill">
                            <mat-label>choose start date</mat-label>
                            <input matInput [(ngModel)]="start_date" name="start_date" [matDatepicker]="picker1"
                              placeholder="Choose Start Date" (dateChange)="choose_start_date()">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div class="col-6">
                          <mat-form-field appearance="fill">
                            <mat-label>choose end date</mat-label>
                            <input matInput [(ngModel)]="end_date" name="end_date" [matDatepicker]="picker2"
                              placeholder="Choose end Date" (dateChange)="choose_end_date()">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
>>>>>>> 8d29ba19e29f3c53016920bf9285433a9bf61dcf
                  </div> -->
                  <!-- </div> -->
                </div>
              </div>

              <br>
              <br>
            </div><br>

            <br>

            <div class="col-md-6">

              <div class="row-3">
                <div class="col">
                  <div class="row-2">
                    <div class="row">
                      <div class="col-md-6">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Location Name" name="CNT" [(ngModel)]="config.location.name"
                            matTooltip="Location Name :{{config.location.name}}" size="	10px" width="10px" required>
                        </mat-form-field>
                      </div>
                      <div class="col-md-6">
                        {{config.location.name}} is associated with {{config.slug}}
                      </div>
                    </div>
                  </div><br>

                  <div class="row-2">
                    <mat-form-field class="example-full-width">
                      <textarea matInput placeholder="Enter location" name="entLoc"
                        [(ngModel)]="config.location.address"
                        matTooltip="Address Of Clinic :{{config.location.address}}" rows="5" required>
</textarea>
                    </mat-form-field>
                  </div><br>

                  <div class="row-2">

                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="MapLink" name="Lk" [(ngModel)]="config.location.map_link"
                        matTooltip="Link Of Clinic:{{config.location.map_link}}">
                    </mat-form-field>&nbsp;
                    <!-- <<<<<<< HEAD
                    <button mat-icon-button (click)="ShowMap()" class="icon" [disabled]="iconStatus"
                      matTooltip="Show The Information About map_link" name="mapinfo">
                      <mat-icon>contact_support</mat-icon>
                    </button>
======= -->
                    <!-- <button mat-icon-button (click)="ShowMap()" class="icon" [disabled]="iconStatus"
                      matTooltip="show the information about map_link" name="mapinfo"> -->
                    <mat-icon (click)="ShowMap()" id="icon-btn" [disabled]="iconStatus"
                      matTooltip="show the information about map_link">contact_support</mat-icon>
                    <!-- </button> -->
                  </div><br>

                  <div class="row-2">
                    <div *ngIf="showMapInfo==true">
                      <li><b>Open Google Maps -> Directions</b><br></li>
                      <li><b>Put your location in the ‘To’ section.</b><br></li>
                      <li><b>Copy the link generated in the browser URL bar.</b><br></li>
                    </div>

                  </div><br>

                  <div class="row-2">
                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="Phone Number" name="phone" [(ngModel)]="config.location.phone"
                        matTooltip="Phone of Clinic:{{config.location.phone}}" required>
                    </mat-form-field>
                  </div><br>

                  <div class="row-2">
                    <mat-form-field class="example-full-width">
                      <textarea matInput placeholder="Message to patient" name="msg" [(ngModel)]="config.message"
                        matTooltip="Message To Patient :{{config.message}}" style="overflow :auto;resize: none"
                        rows="10">
                        </textarea>
                    </mat-form-field>

                  </div>


                  <mat-checkbox name="config.terms_conditions" [(ngModel)]="config.terms_conditions">Terms and
                    Conditions</mat-checkbox>
                  <mat-form-field class="example-full-width" *ngIf="config.terms_conditions===true">
                    <textarea style="overflow :auto;resize: none" rows="10" name="termsAndCondition"
                      [(ngModel)]="config.terms_conditions_info" matInput
                      placeholder="Enter terms and conditions"></textarea>
                  </mat-form-field><br>

                  <!-- <mat-checkbox name="config.payments" [(ngModel)]="config.payments">Payment Information
                  </mat-checkbox>
                  <mat-form-field class="example-full-width" *ngIf="config.payments===true">
                    <textarea style="overflow :auto;resize: none" rows="10" name="payments"
                      [(ngModel)]="config.payment_info" matInput placeholder="Enter Payment Details"></textarea>
                  </mat-form-field> -->
                  <br>
                </div><br>

              </div><br>

            </div>
          </div>
        </mat-card><br>
        <div align="center" style="padding-bottom: 20px">
          <button mat-raised-button type="submit" (click)="show()">Save
          </button>&nbsp;&nbsp;

          <button mat-raised-button (click)="ResetChanges()">Reset</button>

        </div>



      </form>
    </div>
  </mat-card>
  <div *ngIf="config === undefined">
    <p class="no-calendars">No calendars</p>
  </div>
</div>