import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import firebase from "firebase/app";
import "firebase/firestore";
import { environment } from "../../environments/environment";
import { AngularFireDatabase } from "angularfire2/database";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "angularfire2/firestore";
import { TargetKWService } from "../_services/target-kw.service";
import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
// import {SetTargetKWTaskService} from '../_services/set-target-kwtask.service';
import * as moment from "moment";
//import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { RemoveUnderscorePipe } from "../contacts/contacts.component";

@Component({
  selector: "app-target-kw-result",
  templateUrl: "./target-kw-result.component.html",
  styleUrls: ["./target-kw-result.component.scss"],
})
export class TargetKwResultComponent implements OnInit {
  array = [];
  location = "";
  country = "";
  dataSource = new MatTableDataSource();
  storageRef = firebase.storage().ref();
  displayedColumns: string[];
  valueCols:string[]=[];
  url: string;
  responce: string = "";
  isReady: boolean = false;
  taskDate: any;
  taskCompleted: boolean = false;
  contactsFetched: boolean = false;
  pipe: DatePipe;
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) //paginator: MatPaginator;
 //paginator: MatPaginator;
  //paginator: MatPaginator;
  //paginator: MatPaginator;
  //paginator: MatPaginator;
  set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }

  @ViewChild(MatSort) //sort: MatSort;
 //sort: MatSort;
  //sort: MatSort;
  //sort: MatSort;
  //sort: MatSort;
  set sort(value1: MatSort) {
    this.dataSource.sort = value1;
  }

  pageSize: number = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  count: number = 0;

  constructor(
    // private setTask:SetTargetKWTaskService,
    private targetKWServiceObj: TargetKWService
  ) {
    this.targetKWServiceObj.getTargetKwData().subscribe((result) => {
      this.isReady = true;
      // console.log(result);
      // result.subscribe(
      //   result=>{
      if (result["status"] == "completed") {
        this.taskCompleted = true;
        console.log(result);
        this.url = result["wordCloudUrl"];
        this.readData(result["fileData"]);
        this.country = result["country"];
        // this.location ='';
        this.location = result["location"];
        this.taskDate = result["date"]; // this.storageRef.child('sancheti.png')
        //   .getDownloadURL()
        //   .then(url=>{
        //     this.getImageUrl(url)
        //   });
      } else {
        this.taskCompleted = false;
        this.responce = result["result"];
      }
      // });
    });
  }

  ngOnInit() {
    // this.getCsv();
    this.targetKWServiceObj.getTargetKWResults();
  }

  getImageUrl(url) {
    this.url = url;
    console.log(this.url);
  }

  createCsv(header, inputarray, accId) {
    var options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: header,
    };
    let finalResult = [];
    for (let i = 0; i < inputarray.length; i++) {
      let dictTargetKws = {};

      for (let j = 0; j < header.length; j++) {
        dictTargetKws[header[j]] = inputarray[i][header[j]];
      }
      finalResult.push(dictTargetKws);
    }
    /*
    new Angular5Csv(
      finalResult,
      "Target_keyword_Result_Report-" + accId,
      options
    );
    */
  }
  downloadCsv() {
    let accId = `${sessionStorage.getItem("current_acc_id")}`;
    this.createCsv(this.displayedColumns, this.array, accId);
  }

  getCsv() {
    // let check=this.targetKWServiceObj.getCsv();
    // console.log(check);
    // check.subscribe(ref=>{
    // console.log(ref);
    // this.readData(ref);
    // });
  }

  readData(ref) {
    this.array = ref;
    // console.log(ref);

    this.dataSource = new MatTableDataSource(this.array);

    this.dataSource.paginator = this.paginator;

    this.dataSource.sort = this.sort;

    this.count = ref.length;

    this.contactsFetched = true;
    // console.log(ref);
    let columns = Object.keys(ref[0]);
    // let columns = Object.keys(ref);

    this.displayedColumns = ["key", "seed keyword"];

    for (var i = 0; i < columns.length; i++) {
      if (columns[i] != "key" && columns[i] != "seed keyword") {
        this.displayedColumns.push(columns[i]);
        this.valueCols.push(columns[i]);
      }
    }

    console.log(this.valueCols);
  }
}
