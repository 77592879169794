<!--Footer-->
<footer class="">
  <mat-toolbar class="footer-copyright">
    <mat-toolbar-row class="format-center">
      <p>
        Write to us at: &nbsp;<a
          style="color: white;"
          href="mailto:themktgdoctorteam@mktg.doctor"
          >themktgdoctorteam@mktg.doctor</a
        >
      </p>
    </mat-toolbar-row>
    <mat-toolbar-row class="format-center">
      <button mat-button [routerLink]="['/privacypolicy']">
        Privacy Policy | Terms of Service
      </button>
    </mat-toolbar-row>
    <mat-toolbar-row class="format-center">
      <!--Copyright-->
      © {{ currentYear }} mktg.doctor &nbsp; All rights reserved.
      <!--/.Copyright-->
    </mat-toolbar-row>
  </mat-toolbar>
</footer>
<!--/.Footer-->
