<div class="row" id="subHeader1">
  <div id="pageTitle" *ngIf="this.slug === '/enquiry-forms'">
    <a [routerLink]="['/enquiry-forms']"> <i class="pi pi-chevron-left"></i>Enquiry Forms</a>
  </div>
  <div id="pageTitle" *ngIf="this.slug === '/survey-forms'">
    <a [routerLink]="['/survey-forms']"> <i class="pi pi-chevron-left"></i>Survey Forms</a>
  </div>

  <div id="subPageTitle">
    {{form?.name}}
  </div>
</div>


<div>
  <div *ngIf="isReady; then ready else not_ready">
  </div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <div class="row">

      <div class="col-md-4 text-center">Slug :
        <mat-form-field class="full-width">
          <input matInput type="text" [(ngModel)]="form.slug" style="font-size: 15px;" name="text" />
        </mat-form-field>
      </div>

      <div class="col-md-4 text-center">
        <mat-form-field class="full-width">
          <input matInput type="text" [(ngModel)]="form.name" (keyup)="changeSlug()" style="font-size: 20px;"
            name="text" />
        </mat-form-field>
      </div>
      <div class="col-md-4 text-center" style="margin-top:30px;">
        <a (click)="openDialogForPreview(acc.account_slug,form.slug,form.name)">
          <i class="material-icons" id="icon" style="size:80px;">remove_red_eye</i><br>Preview
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 text-center">
        <button mat-raised-button class="top-space large-button" id="button" (click)="saveForm()">Save</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="scrolling-wrapper-flexbox">
          <app-welcome [welcome_screen]="form?.welcome_screen" [previewRef]="preview"></app-welcome>
          <app-questions [questions]="form?.questions" [previewRef]="preview"></app-questions>
          <app-thank-you [thank_you_screen]="form?.thank_you_screen" [previewRef]="preview"></app-thank-you>
        </div>
      </div>
      <div class="col-md-6">
        <div class="scrolling-wrapper-flexbox">
          <mat-card class="metric-card preview">
            <app-preview #preview [form]="form"></app-preview>
          </mat-card>
        </div>
      </div>
    </div>
  </ng-template>
</div>