import { Component, OnInit, OnChanges, OnDestroy, Output, EventEmitter, Input, SimpleChange, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { MatStepper } from '@angular/material/stepper';
import { SmsCampaignService } from "../../_services/sms-campaign.service";
import { MessageService } from 'primeng/api';
import { SplitButtonModule } from 'primeng/splitbutton';
import { I } from '@angular/cdk/keycodes';
import { Subscription } from 'rxjs';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { MatDialog } from '@angular/material/dialog';
import { EmailSmsStatusActivityComponent } from '../email-sms-status-activity/email-sms-status-activity.component';
import { Router } from '@angular/router';



@Component({
  selector: 'app-add-new-campaign',
  templateUrl: './add-new-campaign.component.html',
  styleUrls: ['./add-new-campaign.component.scss']
})
export class AddNewCampaignComponent implements OnInit, OnChanges, OnDestroy {
  @Output() campaignDetails = new EventEmitter<any>();
  @Input() newCampaignAdded: boolean;
  @Input() action;
  @Input() selectedCampaignInfo: any;
  @ViewChild('stepper') private myStepper: MatStepper;
  @ViewChild('splitButton') private splitButton: SplitButtonModule;
  @ViewChild(DaterangepickerDirective, { static: false }) birthdayPicker: DaterangepickerDirective;
  @ViewChild(DaterangepickerDirective, { static: false }) lastVisitedPicker: DaterangepickerDirective;

  moment: any = moment;
  campaignInfo: FormGroup = new FormGroup({
    'name': new FormControl('', Validators.required),
    'description': new FormControl('')
  });
  campaignSchedularData: any = {
    "date": new Date(),
    "time": new Date(),
  };


  minDateStart: Date = new Date();
  campaignButton: string = "Add Campaign";
  loading: boolean = true;
  readOnly: boolean = false;
  selectedContacts: any = [];
  searchText: string = "";
  confirmation: boolean = false;
  smsBody: string = ""
  errorContent: string = ""
  display: boolean;

  maxDateStart: Date;
  selectedBirthDate = null;
  selectedLastVisited = null;
  lastVisitedDate: Date;
  filterName: string = 'birthday';
  startBDate: any = null;
  endBDate: any = null;
  amPmDate: string = "";
  timeDate: string = "";
  dateTime = new Date();
  cancelSchedule: boolean = false;

  lastVisitedLocal = {
    firstDayOfWeek: 0,
    dayNames: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    today: 'Today',
    clear: 'Clear',
  }

  rangesBDate: any = {
    'Today': [moment()],
    'Tomorrow': [moment().add(1, 'days'), moment().add(1, 'days')],
    'Next 7 Days': [moment(), moment().add(6, 'days'),],
    'Next 30 Days': [moment(), moment().add(29, 'days')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
    'All Time': [moment().startOf('year'), moment().endOf('year')]
  }
  rangesLastVisited: any = {
    'Today': [moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'All Time': [moment('01/01/2000'), moment()]
  }
  rangeFlag: boolean;
  selectedLabel: string;
  items: MenuItem[];

  endDate = new Date();
  isTaskOnSchedular: boolean;
  minDateEnd: Date;
  disabledOverall: boolean;
  isLoading: boolean;
  disabledSchedule: boolean;
  selectedDate: string;
  smsCampaigSubscription: Subscription;

  // new start
  messegeLibrary: any = []
  selectedMessege: any = {
    messege: '',
    name: 'Custom Messege',
  };
  // quickList = [`today's appointments`, `tomorrow's patients`, `tomorrow's unconfirmed patients`, `insured patients`, `all patients`];
  @Input() quickList: any = [];
  selctedCampaignIndex = -1;
  contacts = []
  selectedCampaignDetails: any;
  methodType = 'EMAIL'
  emailBody: string;
  filteredContact = []
  draftSavedCampiagnIndex: number;
  emailSubject: string;
  step1Loading: boolean = false;
  isShowMethodOption: boolean = false
  conatctLoading: boolean
  campaignAdded: boolean = false;
  campaignActivityMessege: string = 'Campaign added successfully';
  constructor(
    private smsCampaignService: SmsCampaignService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.dateTime.setDate(this.dateTime.getDate());
  }

  async ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    console.log(this.action);
    if (this.action == "add") {
      console.log("add", this.selectedCampaignInfo);
      this.selectedContacts = this.contacts;
      console.log(this.selectedContacts.length, this.contacts.length);
      this.loading = false;
    }
    else if (this.action == "edit" || this.action == "details") {
      this.setCampaignValues();
    }

    if ('newCampaignAdded' in changes) {
      if (changes.newCampaignAdded.currentValue == false) {
        this.confirmation = false;
      }
      this.loading = false;
    }
  }
  setCampaignValues() {
    if (this.action == "edit" || this.action == "details") {
      this.selctedCampaignIndex = this.quickList.findIndex((item) => item.name === this.selectedCampaignInfo.name);
      this.draftSavedCampiagnIndex = this.quickList.findIndex((item) => item.name === this.selectedCampaignInfo.name);
      if (this.selectedCampaignInfo['methodType'] == 'SMS') {
        this.methodType = 'SMS';
        this.smsBody = this.selectedCampaignInfo['smsBody']
      }
      if (this.selectedCampaignInfo['methodType'] == 'EMAIL') {
        this.methodType = 'EMAIL';
        this.emailSubject = this.selectedCampaignInfo['subject']
        this.emailBody = this.selectedCampaignInfo['emailBody']
      }
    }
    this.step1Loading = false
  }
  async ngOnInit(): Promise<void> {
    this.getDateTime('date');
    this.items = [
      {
        label: 'Run Now',
        icon: 'pi pi-send',
        command: () => {
          this.splitButton['icon'] = "pi pi-spin pi-spinner";
          if (this.action == "add") {
            this.addCampaign('run');
          }
          if (this.action == "edit") {
            this.updateCampaign('run');
          }
        }
      },
      {
        separator: true
      },
      {
        label: 'Schedule', icon: 'pi pi-send', command: () => {
          this.isTaskOnSchedular = true;
          this.disabledOverall = true;
          setTimeout(() => {
            this.myStepper.next();
          }, 10);
        }
      }
    ];
  }


  rangeClicked(range, name) {
    this.filterName = name;
    this.selectedLabel = range.label;
    console.log(this.selectedLabel, range);
    this.rangeFlag = true;
  }

  datesUpdated(name) {
    this.filterName = name;
    console.log("date updated", name);
    if (this.rangeFlag === false) {
      this.selectedLabel = 'Custom';
    }
    this.rangeFlag = false;
  }
  filterBirthDate(selected: any) {
    console.log("Filter birthday called", selected)
    // if (this.selectedLastVisited && this.selectedLastVisited.start && this.selectedLastVisited.end && selected && selected.startDate && selected.endDate) {
    //   this.lastVisitedPicker.clear();
    //   // this.filterName = "birthday";
    // }
    if (selected && selected.startDate && selected.endDate) {
      // this.filterName = "birthday";
      if (selected.startDate.isSame(selected.endDate)) {
        console.log(selected.startDate, selected.endDate)
        selected.startDate = moment(selected.startDate).startOf('day');
        selected.endDate = moment(selected.endDate).endOf('day');
      }
      let now = moment();
      let year = now.year();
      this.selectedContacts = this.contacts.filter(x => {
        if (x['birthday']) {
          var newBDate = moment(x['birthday']).year(year);
          if (newBDate.isSameOrAfter(selected.startDate) && newBDate.isSameOrBefore(selected.endDate)) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      })
    }

  }

  cleareLastVisited(value) {
    console.log(value);
    this.selectedContacts = this.contacts;
  }
  clearFilter() {
    if (this.birthdayPicker)
      this.birthdayPicker.clear();
    if (this.lastVisitedPicker)
      this.lastVisitedPicker.clear();
    this.selectedContacts = this.contacts;
  }
  filterLastVisited(selected: any) {
    console.log("Filter last visited called", selected)
    // if (this.selectedBirthDate && this.selectedBirthDate.start && this.selectedBirthDate.end && selected && selected.startDate && selected.endDate) {
    //   // this.filterName = "lastVisited";
    //   this.birthdayPicker.clear();
    // }
    if (selected && selected.startDate && selected.endDate) {
      if (selected.startDate.isSame(selected.endDate)) {
        console.log(selected.startDate, selected.endDate)
        selected.startDate = moment(selected.startDate).startOf('day');
        selected.endDate = moment(selected.endDate).endOf('day');
      }
      // this.filterName = "lastVisited"
      this.selectedContacts = this.contacts.filter(x => {
        if (x['last_visited']) {
          var newLVisited = moment(x['last_visited']);
          if (newLVisited.isSameOrAfter(selected.startDate) && newLVisited.isSameOrBefore(selected.endDate)) {
            // if(x.primaryInsuranceCompanyId || x.secondaryInsuranceCompanyId){
            //   return true;
            // }
            // else{
            //   return false;
            // }
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      });
      console.log(this.selectedContacts);
    }

  }
  showDialog(message: string) {
    this.display = true;
    this.errorContent = message;
  }

  changedFromDate(event) {
    this.minDateEnd = new Date(event);
  }

  scheduleCampaign() {
    console.log(this.campaignSchedularData);
    this.isLoading = true;
    this.disabledSchedule = true;
    console.log(this.isLoading);
    if (this.action == "add") {
      this.addCampaign("schedule");
    }
    else if (this.action == "edit") {
      this.updateCampaign("schedule");
    }
  }
  addCampaign(action) {

    this.disabledOverall = true;
    this.splitButton['icon'] = "pi pi-spin pi-spinner";
    console.log(action);
    let data = {
      'contacts': this.filteredContact,
      'campaignName': this.quickList[this.selctedCampaignIndex].name,
      'mode': this.action,
    };

    if (this.methodType == 'SMS') {
      data['methodType'] = 'SMS';
      data['smsBody'] = this.smsBody;
    }
    if (this.methodType == 'EMAIL') {
      data['subject'] = this.emailSubject
      data['methodType'] = 'EMAIL';
      data['emailBody'] = this.emailBody;
    }
    console.log(data);
    this.smsCampaignService.addNewSmsCampaign(data).then(resp => {
      console.log("added campaign", resp);
      this.campaignDetails.emit({ campaignAdded: true, mode: 'added', msg: 'success' });
      if (action == "run") {
        this.campaignActivityMessege = "Campaign is Running"
        console.log("running campaign");
        this.runCampaignNow(resp['campaignId']);
      }
      else if (action == "schedule") {
        this.campaignActivityMessege = "Campaign scheduled succesfully"
        this.smsCampaignService.scheduleCampaign(resp, this.campaignSchedularData).then(resp => {
        }, err => {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: "error occured while scheduling the task" });
        });
      }
      if (this.action == 'add') {
        this.campaignAdded = true;
      }
      else {
        this.campaignDetails.emit({ showCampaigns: true });
      }

    }, err => {
      this.campaignDetails.emit({ campaignAdded: false, mode: 'added', msg: err });
      console.log("error", err);
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: err.error });
      this.disabledOverall = false;
      this.isTaskOnSchedular = false;
      this.splitButton['icon'] = "pi pi-save";
    });
  }
  updateCampaign(action) {
    this.disabledOverall = true;
    this.splitButton['icon'] = "pi pi-spin pi-spinner";
    console.log(action);
    let data = {
      'contacts': this.filteredContact,
      'campaignName': this.quickList[this.selctedCampaignIndex].name,
      'mode': this.action,
      'campaignDocId': this.selectedCampaignInfo['campaignDocId']
    };

    if (this.methodType == 'SMS') {
      data['methodType'] = 'SMS';
      data['smsBody'] = this.smsBody;
    }
    if (this.methodType == 'EMAIL') {
      data['methodType'] = 'EMAIL';
      data['emailBody'] = this.emailBody;
      data['subject'] = this.emailSubject
    }
    // console.log(data);
    this.smsCampaignService.updateSmsCampaign(data).then(resp => {
      console.log("campaign updated");
      this.campaignDetails.emit({ campaignAdded: true });
      if (action == 'run') {
        this.campaignActivityMessege = "Campaign is Running"
        this.runCampaignNow(data['campaignDocId']);
      }
      else if (action == "schedule") {
        this.campaignActivityMessege = "Campaign scheduled succesfully"
        this.smsCampaignService.scheduleCampaign({ 'campaignId': data['campaignDocId'], 'methodType': data['methodType'] }, this.campaignSchedularData).then(resp => {
        }, err => {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: "error occured while scheduling the task" });
        });
      }
      if (this.action == 'add') {
        this.campaignAdded = true
      }
      else {
        this.campaignDetails.emit({ showCampaigns: true });
      }
    },
      err => {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: err.error });
        this.campaignDetails.emit({ campaignAdded: false });
        this.disabledOverall = false;
        this.isTaskOnSchedular = false;
        this.splitButton['icon'] = "pi pi-save";
      });
  }
  runCampaignNow(camId) {
    console.log("running campaign", camId);
    this.splitButton['icon'] = "pi pi-spin pi-spinner";
    this.smsCampaignService.runSmsCampaign({ id: camId, methodType: this.methodType }).then(rep => {
    },
      err => {
        console.log(err);
        this.disabledOverall = false;
        this.splitButton['icon'] = "pi pi-save";
      });
  }

  getDateTime(selectionType: string) {
    console.log(this.campaignSchedularData.time);
    if (selectionType == 'date') {
      this.selectedDate = moment(this.campaignSchedularData.date).format('L');
    }
    if (selectionType == 'time') {
      console.log(this.campaignSchedularData);
    }
  }

  scheduleAndSend(action: string) {
    if (action == 'cancel') {

    }
    if (action == 'send') {

    }
  }

  cancel(dialog: any) {
    this.disabledOverall = false;
    console.log(dialog);
    this.isTaskOnSchedular = false;
  }
  cancelCampaignSchedule() {
    console.log("cacel schedule");
    this.smsCampaignService.cancleScheduledCampaign(this.selectedCampaignInfo['campaignDocId']).then(resp => {
      this.action = "edit";
      this.readOnly = false;
      this.selectedCampaignInfo.status = 'DRAFT';
      console.log(resp);
      if (this.action == 'add') {

      }
      else {
        this.campaignDetails.emit({ showCampaigns: true });
      }
      // this.campaignDetails.emit({campaignAdded:true});
    },
      err => {
        console.log(err);
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: err.message });
      })
    // this.campaignDetails.emit({showCampaigns:true});
  }
  async getSelectedSelectedQuicklistsPatients() {
    if (this.myStepper.selectedIndex != 2) {
      this.myStepper.next();
    }

    this.showMethodOption()
    this.contacts = [];
    if ((this.action == 'edit' || this.action == 'details') && (this.draftSavedCampiagnIndex == this.selctedCampaignIndex)) {
      this.filteredContact = this.selectedCampaignInfo.contacts
    }
    else {
      this.conatctLoading = true
      switch (this.selctedCampaignIndex) {
        case 0: {
          this.contacts = await this.smsCampaignService.getAppointmentsByDate(moment().format('MMMM D, YYYY'))
          break;
        }
        case 1: {
          this.contacts = await this.smsCampaignService.getAppointmentsByDate(moment().add(1, 'day').format('MMMM D, YYYY'))
          break;
        }
        case 2: {
          this.contacts = await this.smsCampaignService.geUnconfirmedPatientsByDate(moment().add(1, 'day').format('MMMM D, YYYY'))
          break;
        }
        case 3: {
          this.contacts = await this.smsCampaignService.getInsuredPatients()
          break;
        }
        case 4: {
          this.contacts = await this.smsCampaignService.getAllPatientsOfTwoYears()
          break;
        }
      }
    }
    console.log(this.contacts.length, this.contacts)
    this.filterContactsBySelectedMode()
  }

  selectMessege(option) {
    this.selectedMessege = option
  }

  supress(index, status) {
    this.filteredContact[index].supress = status
  }

  filterContactsBySelectedMode() {
    if ((this.action == 'edit' || this.action == 'details') && (this.draftSavedCampiagnIndex == this.selctedCampaignIndex)) {
      this.filteredContact = this.selectedCampaignInfo.contacts
    }
    else {
      this.filteredContact = []
      if (this.methodType == 'EMAIL') {
        this.contacts.forEach(conatct => {
          if (conatct.email != "" && conatct.email != null) {
            this.filteredContact.push(conatct)
          }
        })
        this.filteredContact = this.filteredContact.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.email === value.email
          ))
        )
      }
    }
    if (this.methodType == 'SMS') {
      this.contacts.forEach(conatct => {
        if (conatct.full_phone != "" && conatct.full_phone != null) {
          this.filteredContact.push(conatct)
        }
      })
      this.filteredContact = this.filteredContact.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.full_phone === value.full_phone
        ))
      )
    }

    console.log(this.filteredContact)

    console.log(this.filteredContact)
    this.conatctLoading = false
  }

  validateSmsAndEmailBody() {
    if (this.methodType == 'SMS') {
      return !this.smsBody || this.smsBody.length > 160
    }
    if (this.methodType == 'EMAIL') {
      return !this.emailBody || !this.emailSubject
    }
  }

  showMethodOption() {

    if (this.selctedCampaignIndex >= 0) {
      if (this.quickList[this.selctedCampaignIndex].EMAIL == true) {
        this.methodType = "EMAIL"
      }
      else {
        this.methodType = "SMS"
      }
    }
    this.isShowMethodOption = this.selctedCampaignIndex >= 0 && (this.quickList[this.selctedCampaignIndex].SMS == true && this.quickList[this.selctedCampaignIndex].EMAIL == true) ? true : false
  }

  showActivityStatus(campaign) {
    this.dialog.open(EmailSmsStatusActivityComponent, {
      data: campaign
    }
    )
  }

  matStepSecondClicked() {
    console.log(this.myStepper.selectedIndex)
    if (this.action != 'add') {
      this.getSelectedSelectedQuicklistsPatients()
    }
  }
  ngOnDestroy() {
    // this.smsCampaigSubscription.unsubscribe();
  }

  addNewCamapign() {
    this.selctedCampaignIndex = -1;
    this.contacts = []
    this.methodType = 'EMAIL'
    this.emailBody = undefined;
    this.filteredContact = []
    this.draftSavedCampiagnIndex = undefined;
    this.emailSubject = undefined;
    this.step1Loading = false;
    this.isShowMethodOption = false
    this.conatctLoading = false
    this.campaignAdded = false;
    this.campaignActivityMessege = 'Campaign added successfully';
  }

}
