import { Component, OnInit } from '@angular/core';
import { ApiService} from '../../_services/index';
import { TrafficSummary } from '../../_models/index';

@Component({
moduleId: module.id.toString(),
  selector: 'app-similar-sites',
  templateUrl: './similar-sites.component.html'
// styleUrls: ['./similar-sites.component.scss']
})
export class SimilarSitesComponent implements OnInit {

sites: any[];
model: any = {};

 constructor(private apiService:ApiService){}

 fetchSites()
   {
       this.apiService.fetchSites()
       .subscribe(sites => this.sites= sites);
   }
  ngOnInit() :void{
    this.fetchSites();
    console.log("Called the API");
  }


}
