import { Component, OnInit } from "@angular/core";
import { NavigationState } from "../_models/index";
import {
  NavigationService,
  AdWordsService,
  TimePeriodService,
  GoogleAnalyticsService,
  AccountService,
  AlertService,
  ConversionValueService,
} from "../_services/index";
import { DecimalPipe, DatePipe } from "@angular/common";
import { AngularFirestore } from "angularfire2/firestore";
import { Router } from "@angular/router";

@Component({
  selector: "app-ad-words",
  templateUrl: "./ad-words.component.html",
  styleUrls: ["./ad-words.component.scss"],
})
export class AdWordsComponent implements OnInit {
  acc: any;
  campaigns: any;
  timeperiod: any;
  isAllow: boolean;
  isReady: boolean;
  analytics: any;
  total_spend: number = 0;
  total_ConvValue: number = 0;
  totalConvValue: any;
  users = 0;
  sessions = 0;
  bounce_rate = 0;
  ppv = 0;
  duration = "00:00:00";
  hasAnalyticsAccess: boolean;
  all_conversion_values: any;
  campaignConvValue: any;
  conversions: any;
  aw_totalConvValue: number = 0;

  adwordsCredentials: any;
  analyticsCredentials: any;

  totalConversions: number = 0;
  constructor(
    private navigationService: NavigationService,
    private adwordsService: AdWordsService,
    private timePeriodService: TimePeriodService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private accountService: AccountService,
    private conversionValueService: ConversionValueService,
    private alertService: AlertService,
    public afs: AngularFirestore,
    private router: Router
  ) {
    this.accountService.accChange$.subscribe((accChange) => {
      this.router.navigateByUrl("/websites");

      /*
        let state = this.navigationService.getCurrentNavigationState();
        if(state.url === '/adwords') {
          this.acc = this.accountService.getCurrentAccDetails();
          console.log('Account is changed');
          this.adwordsCredentials = this.accountService.getLinkedAccountCredentials('adwords');
          this.analyticsCredentials = this.accountService.getLinkedAccountCredentials('analytics');

          if(this.adwordsCredentials){
          this.loadData();
          this.alertService.reset('');
          this.isReady = false;
        }else{
          this.handleUnlinkedAcc();
        }
        }
        */
    });

    this.timePeriodService.timePeriod$.subscribe((timeperiod) => {
      let state = this.navigationService.getCurrentNavigationState();
      if (state.url === "/adwords") {
        this.isReady = false;
        this.timeperiod = timeperiod;
        this.loadData();
      }
    });
  }

  sortCampaignsAndSetTotalSpend(campaigns: any) {
    let temp: any;
    this.total_spend = 0;
    this.total_ConvValue = 0;

    for (let i = campaigns.length - 1; i >= 0; i--) {
      for (let j = 0; j < i; j++) {
        temp = {};
        if (campaigns[j]["@attributes"].startDate < campaigns[j + 1]["@attributes"].startDate) {
          temp = campaigns[j];
          campaigns[j] = campaigns[j + 1];
          campaigns[j + 1] = temp;
        }
      }
      campaigns[i]["@attributes"].cost = +campaigns[i]["@attributes"].cost / 1000000;
      this.total_spend += campaigns[i]["@attributes"].cost;
      campaigns[i]["@attributes"].totalConvValue = +campaigns[i]["@attributes"].totalConvValue;
      this.total_ConvValue += campaigns[i]["@attributes"].totalConvValue;
    }
  }

  handleUnlinkedAcc() {
    this.campaigns = [];
    this.isReady = true;
    this.total_spend = 0;
    this.users = 0;
    this.sessions = 0;
    this.bounce_rate = 0;
    this.ppv = 0;
    this.duration = "00:00:00";
    this.aw_totalConvValue = 0;
    this.hasAnalyticsAccess = false;

    this.alertService.error("You are trying to access an unlinked account.");
  }

  ngOnInit() {
    this.navigationService.updateState(
      new NavigationState(true, "AdWords", "Campaigns", "/main", "/adwords")
    );
    this.isReady = false;
    this.timeperiod = this.timePeriodService.getCurrentTimeperiod();
    this.acc = this.accountService.getCurrentAccDetails();

    this.adwordsCredentials = this.accountService.getLinkedAccountCredentials(
      "adwords"
    );
    this.analyticsCredentials = this.accountService.getLinkedAccountCredentials(
      "analytics"
    );

    if (this.adwordsCredentials) {
      if (this.adwordsCredentials.adwords_cust_id) {
        this.loadData();
        this.alertService.reset("");
      }
    } else {
      this.handleUnlinkedAcc();
    }
  }

  loadData() {
    this.hasAnalyticsAccess = this.analyticsCredentials ? true : false;

    if (!this.acc.adwords_factor) {
      this.acc.adwords_factor = 1;
    }

    if (!this.acc.account_currency) {
      this.acc.account_currency = "INR";
    }

    //caching
    let adwords_all_record = JSON.parse(
      sessionStorage.getItem("adwords-all-" + this.acc.name)
    );
    if (adwords_all_record !== null) {
      let adwords_date = adwords_all_record["date-range"].split("-");
      if (
        new DatePipe("en-US").transform(this.timeperiod.start, "MMM d, y") === adwords_date[0] &&
        new DatePipe("en-US").transform(this.timeperiod.end, "MMM d, y") === adwords_date[1]
      ) {
        let findDate= Date.now() - adwords_all_record.creation_timestamp < 300000;

        if (Date.now() - adwords_all_record.creation_timestamp < 300000) {
          this.campaigns = adwords_all_record.results;

          this.conversionValueService.getAllConvValuesForService("adwords").subscribe((all_conversion_values) => {
            this.all_conversion_values = all_conversion_values;
            this.calculateAwTotalAndCampaignConvValue();
            this.calculateTotalConversions();
          });
          this.isReady = true;
          this.sortCampaignsAndSetTotalSpend(this.campaigns);
        } else {
          //'We have an old record for this time period. Called API and updated record...'
          this.adwordsService.getCampaigns(
            this.adwordsCredentials.adwords_cust_id,
            this.adwordsCredentials.adwords_refresh_token,
            this.timeperiod
          )
            .subscribe(
              (campaigns) => {
                this.campaigns = campaigns;
                let date_range = this.campaigns["date-range"]["@attributes"].date;
                this.campaigns = this.campaigns.table.row;
                this.conversionValueService.getAllConvValuesForService("adwords").subscribe((all_conversion_values) => {
                  this.all_conversion_values = all_conversion_values;
                  this.calculateAwTotalAndCampaignConvValue();
                  this.calculateTotalConversions();
                });
                sessionStorage.setItem(
                  "adwords-all-" + this.acc.name,
                  JSON.stringify({
                    creation_timestamp: Date.now(),
                    results: this.campaigns,
                    "date-range": date_range,
                  })
                );
                this.isReady = true;
                this.sortCampaignsAndSetTotalSpend(this.campaigns);
              },
              (error) => {
                console.error(error);
              }
            );
        }
      } else {
        //'We do not have a record for this time period. Called API and added record...'
        this.adwordsService.getCampaigns(
          this.adwordsCredentials.adwords_cust_id,
          this.adwordsCredentials.adwords_refresh_token,
          this.timeperiod
        )
          .subscribe(
            (campaigns) => {
              this.campaigns = campaigns;
              let date_range = this.campaigns["date-range"]["@attributes"].date;
              this.campaigns = this.campaigns.table.row;
              this.conversionValueService.getAllConvValuesForService("adwords").subscribe((all_conversion_values) => {
                this.all_conversion_values = all_conversion_values;
                this.calculateAwTotalAndCampaignConvValue();
                this.calculateTotalConversions();
              });
              sessionStorage.setItem(
                "adwords-all-" + this.acc.name,
                JSON.stringify({
                  creation_timestamp: Date.now(),
                  results: this.campaigns,
                  "date-range": date_range,
                })
              );
              this.isReady = true;
              this.sortCampaignsAndSetTotalSpend(this.campaigns);
            },
            (error) => {
              console.error(error);
            }
          );
      }
    } else {
      //'We do not have a record for Adwords campaigns. Called API and added record...'
      this.adwordsService
        .getCampaigns(
          this.adwordsCredentials.adwords_cust_id,
          this.adwordsCredentials.adwords_refresh_token,
          this.timeperiod
        )
        .subscribe(
          (campaigns) => {
            this.campaigns = campaigns;
            let date_range = this.campaigns["date-range"]["@attributes"].date;
            this.campaigns = this.campaigns.table.row;

            this.conversionValueService.getAllConvValuesForService("adwords").subscribe((all_conversion_values) => {
              this.all_conversion_values = all_conversion_values;
              this.calculateAwTotalAndCampaignConvValue();
              this.calculateTotalConversions();
            });
            sessionStorage.setItem(
              "adwords-all-" + this.acc.name,
              JSON.stringify({
                creation_timestamp: Date.now(),
                results: this.campaigns,
                "date-range": date_range,
              })
            );
            this.isReady = true;
            this.sortCampaignsAndSetTotalSpend(this.campaigns);
          },
          (error) => {
            console.error(error);
          }
        );
    }

    this.users = 0;
    this.sessions = 0;
    this.bounce_rate = 0;
    this.ppv = 0;
    this.duration = "00:00:00";

    if (this.hasAnalyticsAccess) {
      this.googleAnalyticsService.getAdwordsEngagement(
        this.analyticsCredentials.analytics_refresh_token,
        this.analyticsCredentials.ga_view_id,
        this.timeperiod
      )
        .subscribe((analytics) => {
          this.analytics = analytics;
          this.isReady = true;
          if (analytics) {
            this.users = this.analytics.results.users;
            this.sessions = this.analytics.results.sessions;
            this.bounce_rate = this.analytics.results.bounce_rate;
            this.ppv = this.analytics.results.page_view_per_session;
            let hrs = new DecimalPipe("en-US").transform(
              Math.floor(this.analytics.results.avg_session_duration / 3600),
              "2.0-0"
            );
            let min = this.analytics.results.avg_session_duration / 60;
            let min_transformed = new DecimalPipe("en-US").transform(
              Math.floor(min > 60 ? min % 60 : min),
              "2.0-0"
            );
            let sec = new DecimalPipe("en-US").transform(
              this.analytics.results.avg_session_duration % 60,
              "2.0-0"
            );
            this.duration = `${hrs}:${min_transformed}:${sec}`;
          } else {
            this.users = 0;
            this.sessions = 0;
            this.bounce_rate = 0;
            this.ppv = 0;
            this.duration = "00:00:00";
          }
        });
    }
  }

  calculateAwTotalAndCampaignConvValue() {
    this.aw_totalConvValue = 0;
    for (let i = 0; i < this.campaigns.length; i++) {
      let ind = this.all_conversion_values.findIndex(
        (x) => x.campaign_id == this.campaigns[i]["@attributes"]["campaignID"]
      );
      if (ind == -1) {
        //if conversion value is not store in database
        this.campaigns[i]["conversion_value"] = -1;
      } else {
        let conversions = this.campaigns[i]["@attributes"]["conversions"];
        this.campaigns[i]["conversion_value"] =
          this.all_conversion_values[ind]["conversion_value"] * conversions;
      }
      if (this.campaigns[i]["conversion_value"] > 0) {
        this.aw_totalConvValue += this.campaigns[i]["conversion_value"];
      }
    }
  }

  calculateTotalConversions() {
    this.totalConversions = 0;
    for (let i = 0; i < this.campaigns.length; i++) {
      this.totalConversions += +this.campaigns[i]["@attributes"]["conversions"];
    }
  }
}
