import { Component, OnInit } from '@angular/core';
import { RewardCampaignService } from '../_services/reward-campaign.service';
@Component({
  selector: 'app-reward-campaigns',
  templateUrl: './reward-campaigns.component.html',
  styleUrls: ['./reward-campaigns.component.scss']
})
export class RewardCampaignsComponent implements OnInit {
  rewardData: any;
  rewardDocs: any = [];
  rewardPoints: number = 0;
  saveReward: boolean;
  showEdit: boolean;
  disabled: boolean;
  selectedReward: any;
  changedPoints: Number = 0;
  hideSettings: boolean = true;
  loadingForConfiguration: boolean = true;
  isOnValue: boolean;
  showInput: boolean = false;
  reward: any;
  constructor(
    private rewardService: RewardCampaignService
  ) { }

  ngOnInit(): void {
    this.getRewardCampaign();
  }


  async getRewardCampaign() {
    this.rewardService.getRewardCampaigns().then(rewardSnapshot => {
      console.log(rewardSnapshot.docs);
      this.rewardData = rewardSnapshot.docs;
      this.rewardData = this.rewardData.forEach(rewardDoc => {
        console.log(rewardDoc.data());
        this.rewardDocs.push((rewardDoc.data()));
        console.log(this.rewardDocs);
        this.rewardPoints = rewardDoc.data().points;
        this.saveReward = rewardDoc.data().isOn;
        this.rewardDocs = this.rewardDocs.sort((a: any, b: any) => {
          return b.date - a.date;
        });
        return this.rewardDocs;
      });
    })
  }


  editValues(index: any, reward: any) {
    console.log(index, reward);
    this.selectedReward = this.rewardDocs.findIndex((obj) => {
      if (obj === reward) {
        return true;
      }
      return false;
    });
    this.disabled = false;
    this.showEdit = false;
  }

  updateRewardValue(enteredValue: any, reward: any) {
    this.changedPoints = Number(enteredValue);
    // console.log(this.changedPoints);
  }

  updateRewardToggle(isOn: boolean, reward: any) {
    this.isOnValue = isOn;
    // console.log(this.isOnValue, reward);
    // this.reward = reward;
    this.rewardService.updateRewardToggle(this.isOnValue, reward);
  }


  updateRewardOption(value: any, reward: any) {
    this.disabled = true;
    this.showEdit = true;
    this.showInput = false;
    this.selectedReward = '';
    // console.log(value, this.reward, this.changedPoints, this.rewardPoints);
    if (this.changedPoints === 0) {
      //   console.log(this.changedPoints);
      this.rewardService.updateRewardOption(reward, reward.points);
    }
    else {
      //  console.log(this.changedPoints);
      this.rewardService.updateRewardOption(reward, this.changedPoints);
    }
  }



}
