<div class="row" id="subHeader1">
  <div id="pageTitle" style="text-align: center;">Websites</div>
</div>

<center>
  <div *ngIf="refresh">
    <!--Fetching your campaigns ...-->
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</center>
<div class="container-fluid view">
  <div *ngIf="!acknowledge">
    <app-account-processing [process]="!acknowledge"></app-account-processing>
  </div>
  <center>
    <div *ngIf="!website">
      <!--Fetching your campaigns ...-->
      <!--<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>-->
      <p>No website for this account.</p>
    </div>
  </center>

  <div *ngIf="website">
    <p>Website analysis: <strong>
        <mat-form-field style="width: 70%;"><input #websites class="myinput" matInput [value]="website"
            (keyup)="showSaveButton()">
        </mat-form-field>
      </strong>&nbsp;&nbsp;

      <mat-form-field>
        <mat-label>Country</mat-label>
        <mat-select [(value)]="selected" (selectionChange)="changeData($event.value)">
          <mat-option *ngFor="let country of countryCodeList" [value]="country.countryName">
            {{country.countryName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button *ngIf="showButton" mat-raised-button color="primary" (click)="updateUrl(websites.value)">Save new
        url</button>
    </p>

    <mat-tab-group>
      <div class="row justify-content-center">
        <div class="col-sm-6 text-center">
          <mat-tab label="Ranked keywords">
            <app-ranked-keywords [website]="website" [countryCode]="countryCode"></app-ranked-keywords>
          </mat-tab>
        </div>
        <div class="col-sm-6 text-center">
          <mat-tab label="SEO audit">
            <app-onpage [website]="website"></app-onpage>
          </mat-tab>
        </div>
      </div>
    </mat-tab-group>
  </div>
</div>


<!--<mat-icon color="">chrome_reader_mode</mat-icon>&nbsp; On Page &nbsp;

<mat-icon color="">assessment</mat-icon>&nbsp; Ranked Keywords-->