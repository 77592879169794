import { Component, OnInit, Input, ViewChild } from '@angular/core';
//import { FormService } from '../_services/form.service';
import { FormBuilderService } from '../../_services/form-builder.service';
import { Question } from '../../_services/form';
import { createFalse } from 'typescript';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @Input()
  section: any;

  @Input()
  uploadedImagePath: string;

  @Input()
  image: string;

  @ViewChild('file_input', { static: true })
  fileInputVariable: any;

  isUrl: boolean = false;
  downloadURL: string;
  snapshot: any;
  uploadProgress: number;
  showProgress: boolean = false;
  source: string = "";
  responseRef: any;
  snapshotRef: any;
  uploadTask: any;
  questions: Question[];
  fileToUpload: FileList;
  imageUrl: any;
  constructor(//private formService: FormService,
    private formBuilderService: FormBuilderService
  ) { }

  ngOnInit() {
  }

  getImage(data) {
    if (data.target.files) {
      this.fileToUpload = data.target.files;
      console.log(this.fileToUpload);
      this.onUpload(this.fileToUpload);
      const reader = new FileReader();
      reader.readAsDataURL(data.target.files[0]);
      reader.onload = () => {
        this.imageUrl = reader.result as string;
      }
    }
  }

  onUpload(selectedImage) {
    console.log(selectedImage);
    this.uploadTask = this.formBuilderService.uploadFile(selectedImage);
    this.showProgress = true;
    this.uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        this.snapshot = snapshot;
        this.uploadProgress = (this.snapshot.bytesTransferred / this.snapshot.totalBytes) * 100;
      },
      (error) => {
        // upload failed
        console.error(error)
      },
      () => {
        // upload success
        this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.section.image = downloadURL;
          this.showProgress = false;
          // this.fileInputVariable.nativeElement.value = '';
        });
        console.log("File uploaded");
      });

  }

  // if(this.fileToUpload !== undefined && this.fileToUpload.length>0){
  //      let uploadTask = this.formBuilderService.uploadFile(this.fileToUpload);
  // }

  // getImage(event, ind) {
  //   this.onUpload(event.target.files[0]);  
  // }

  // onUpload(selectedImage: File) {
  //   let uploadTask = this.formBuilderService.uploadFile(selectedImage);
  //   this.showProgress = true;
  //   uploadTask.on('state_changed',
  //     snapshot => {
  //       this.snapshot = snapshot;
  //       this.uploadProgress = (this.snapshot.bytesTransferred / this.snapshot.totalBytes) * 100;
  //     },
  //     error => {
  //       // upload failed
  //       console.error(error)
  //     },
  //     () => {
  //       // upload success
  //       console.log("File uploaded");
  //       console.log(uploadTask.snapshot);
  //       this.section.uploadedImagePath = uploadTask?.snapshot?.metadata?.fullPath;
  //       uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
  //         this.section.image = downloadURL;
  //         this.showProgress = false;
  //         this.fileInputVariable.nativeElement.value = '';
  //       });
  //     });

  // }

  deleteFile(file_path: string) {
    this.formBuilderService.deleteFromStorage(file_path);
    delete this.section.image;
    delete this.section.uploadedImagePath;
    console.log('File deleted');
  }

}
