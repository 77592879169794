import { Injectable, OnDestroy } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class ChatBotService implements OnDestroy {

    private chatbotSubject = new Subject<any>();
    chatbot$ = this.chatbotSubject.asObservable();
    chatbotUnsubscribe: any;
    constructor() {
        this.chatbotUnsubscribe = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
            .collection('chat-bot').orderBy("lastMessages.date", "desc").limit(20).onSnapshot(snapshot => {
                let list = [];
                snapshot.forEach(doc => {
                    let obj = doc.data();
                    obj.id = doc.id;
                    list.push(obj);
                })
                let pagingInfo = {
                    list: list,
                    startAfter: snapshot.docs[snapshot.docs.length - 1]
                }
                this.chatbotSubject.next(pagingInfo);
            })
    }

    ngOnDestroy(): void {
        console.log("service destroyed")
        this.chatbotUnsubscribe();
    }

    async getLastMessages(collection, startAfter) {
        let query = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
            .collection(collection).orderBy("lastMessages.date", "desc").limit(20);
        if (startAfter != null) {
            query = query.startAfter(startAfter);
        }
        let snapshot = await query.get();
        let list = [];
        snapshot.forEach(doc => {
            let obj = doc.data();
            obj.id = doc.id;
            list.push(obj);
        })
        let pagingInfo = {
            list: list,
            startAfter: snapshot.docs[snapshot.docs.length - 1]
        }
        return pagingInfo;
    }
    async getTheme() {
        firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
            .collection('settings').where('type', '==', 'chat-bot').get().then((snap) => {
                let data = snap.docs[0].data();
                let theme = data.colors.theme ? data.colors.theme : '#1b2246';
                let botBackground = data.colors.botBackground ? data.colors.botBackground : '#e5e4e9';
                let userBackground = data.colors.userBackground ? data.colors.userBackground : '#7e84f2';
                let botText = data.colors.botText ? data.colors.botText : '#363636';
                let userText = data.colors.userText ? data.colors.userText : 'white';
                let bgImage = data.themeBackground ? data.themeBackground : '';
                let bgColor = data.colors.backgroundColor ? data.colors.backgroundColor : '';
                let tabColor = data.colors.tabColor ? data.colors.tabColor : '#1B2246';
                let menuColor = data.colors.menuColor;
                let menuHoverColor = data.colors.menuHoverColor;
                document.documentElement.style.setProperty('--theme-color', theme);
                document.documentElement.style.setProperty('--bot-color', botBackground);
                document.documentElement.style.setProperty('--user-color', userBackground);
                document.documentElement.style.setProperty('--user-text-color', userText);
                document.documentElement.style.setProperty('--bot-text-color', botText);
                document.documentElement.style.setProperty('--background-image', `url(${bgImage})`);
                document.documentElement.style.setProperty('--background-color', bgColor);
                document.documentElement.style.setProperty('--tab-color', tabColor);
                document.documentElement.style.setProperty('--menu-color', menuColor);
                document.documentElement.style.setProperty('--menuHover-color', menuHoverColor);
            });
    }
    async updateReadMessage(docid) {
        await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`)
            .collection('chat-bot').doc(docid).update({ "lastMessages.unread": false });
    }

    async getWelcomeMessage() {
        return await firebase.firestore().collection('accounts')
            .doc(`${sessionStorage.getItem('current_acc_id')}`).collection('settings')
            .where('type', '==', 'chat-bot').get();
    }
    async getMenuItems() {
        return await firebase.firestore().collection('accounts')
            .doc(`${sessionStorage.getItem('current_acc_id')}`)
            .collection("chat-bot-data").where('type', '==', 'MENU')
            .get();
    }

    async getSelectedMessages(docId) {
        let snapshot = await firebase.firestore().collection("accounts").doc(`${sessionStorage.getItem('current_acc_id')}`).
            collection("chat-bot").doc(docId).get();
        return snapshot;
    }
    async addData(obj) {
        await firebase.firestore().collection('accounts')
            .doc(`${sessionStorage.getItem('current_acc_id')}`)
            .collection('chat-bot-data')
            .add(obj)
    }
    async getChatBotData() {
        let snapshot = await firebase.firestore().collection('accounts')
            .doc(`${sessionStorage.getItem('current_acc_id')}`)
            .collection("chat-bot-data")
            .orderBy("type", "asc")
            .limit(20)
            .get();
        console.log(snapshot.docs.map(doc => doc.data()));
        return snapshot.docs.map(doc => doc.data());
    }
    async updateObject(obj) {
        let snap = await firebase.firestore().collection('accounts')
            .doc(`${sessionStorage.getItem('current_acc_id')}`)
            .collection("chat-bot-data").where('type', '==', obj.type).get();
        snap.docs[0].ref.set(obj);

    }

    async getChatBotSettingData() {
        let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).
            collection('settings').where('type', '==', 'chat-bot').get();
        return snapshot;
    }

    async sendMessage(docId, botMessage) {
        console.log(docId);
        let snapshot = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).
            collection("chat-bot").doc(docId).get();
        console.log(snapshot);
        console.log(snapshot.data());
        let messages = snapshot.data().messages;
        messages.push(botMessage)
        console.log(messages);
        let lastmsg = {
            date: new Date(),
            type: 'outgoing',
            message: botMessage.data,
            displayType: 'TEXT',
            unread: false
        };
        snapshot.ref.update({ messages: messages, lastMessages: lastmsg });
    }

    async saveTheme(docRef, colorThemeObject) {
        return docRef.update({ 'colors': colorThemeObject });
    }

    async updateChatBotSettingObject(docRef, chatBotSettingData) {
        return docRef.update(chatBotSettingData);
    }

    async addNewChatData() {
        return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('settings').add(
            await (await firebase.firestore().collection('generalSettings').doc('chat_bot_settings_config').get()).data())
    }

}


