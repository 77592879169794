<div style="overflow:hidden">
    <div class="header">
        <div style="width: fit-content;margin: 18px auto;">
            <b> {{popupHeader}}</b>
        </div>
    </div>
    <div class="row" style="margin-top:35px;">
        <div class="col-1 hidePadding" *ngIf="updateReplyData">
            <img src={{updateReplyData.reviewer.profilePhotoUrl}} style="height: 50px;width: 50px;">
        </div>
        <div class="col-10 hidePadding" id="authorInfo"
            style="padding-top: 0%;padding-left :30px;padding-right :125px;">
            <div *ngIf="updateReplyData.source == 'google' ">
                <img id="googleLogo" src="assets/images/googlelogo.png" alt="image">
                {{(updateReplyData['reviewer']['displayName'])}}
            </div>
            <br>
            <div class="row" style="margin-top: -1em;">
                <div style="padding-left: 15px;">
                    <p-rating [ngModel]="(updateReplyData['starRating'])" readonly="true" stars="5" [cancel]="false">
                    </p-rating>

                </div>
                <div style="padding-left: 10px;font-size:12px;margin-top: 3px">
                    {{(moment(updateReplyData['createTime'].toDate()).format('ll'))}}
                </div>
            </div>
        </div>
        <div class="col-1">
        </div>
    </div>
    <div *ngIf="updateReplyData['comment']" style="height: auto;">
        <div style="padding-top: 5px;">
            {{updateReplyData.comment.length>200?updateReplyData.comment.substring(0,200) : updateReplyData.comment}}
            <span *ngIf="updateReplyData.comment.length > 200 && updateReplyData.display ">
                {{updateReplyData.comment.substring(200,updateReplyData.comment.length)}}
            </span> <a *ngIf="updateReplyData.comment.length > 200" (click)="showMoreContent(updateReplyData)"
                id="readMoreBtn">{{
                updateReplyData.display ? ' «less':
                '..more»'}}</a>
        </div>
    </div>
    <div class="row">
        <div style="font-size:18px;font-weight: 700;padding-left: 15px;">
            {{updateReplyData['locationName']}}
        </div>
        <div style="font-size:15px;font-weight: 400;padding-top: 4px;">(Owner)</div>
    </div>
    <div *ngIf="updateReplyData?.reviewReply?.updateTime" style="padding-left: 10px;font-size:12px;margin-top: 3px">
    </div>
    <div *ngIf="updateReplyData?.reviewReply?.comment" style="height: auto;">
        <label style="color: rgb(9, 24, 231);">Reply:</label>
        <div class="contentText" [class.show]="updateReplyData.show" style="padding-top: 5px;">
            {{(updateReplyData['reviewReply']['comment'])}}
        </div>
    </div>
    <div *ngIf="popupPlaceholder != 'Delete' " style="padding-top: 3px;">
        <textarea style="padding-left: 10px;width: 100%;" rows="4" pInputTextarea autoResize="autoResize"
            [(ngModel)]="updateReplyData.replyByOwner" [placeholder]="popupPlaceholder"></textarea>
    </div>
    <hr>
    <div class="row {{hidden}}" style="padding-top:9px ;">
        <div class="col-8" *ngIf="popupPlaceholder != 'Delete' "></div>
        <div class="col-8" *ngIf="popupPlaceholder == 'Delete' "> <b> Do you want to delete
                this reply? </b></div>
        <div *ngIf="popupPlaceholder != 'Delete' " class="col-2" style="padding-left: 0px;">
            <button [disabled]="!updateReplyData.replyByOwner || btnDisable" mat-raised-button
                (click)="submitReply(updateReplyData,0)">
                Submit</button>
        </div>
        <div *ngIf="popupPlaceholder != 'Delete' " class="col-2" style="padding-left: 0px;">
            <button mat-raised-button matDialogClose [disabled]=" btnDisable"
                (click)="cancelbtn(updateReplyData)">Cancel</button>
        </div>
        <div *ngIf="popupPlaceholder == 'Delete' " class="col-2" style="padding-left: 0px;">
            <button mat-raised-button [disabled]="btnDisable" (click)="deleteReplyOfReview(updateReplyData)">
                Yes</button>
        </div>
        <div *ngIf="popupPlaceholder == 'Delete' " class="col-2" style="padding-left: 0px;">
            <button mat-raised-button [disabled]="btnDisable" matDialogClose>
                No</button>
        </div>
    </div>
    <div *ngIf="popupLoder" style="padding-top: 0%;">
        <div style="text-align: center;">
            <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
        </div>
    </div>

</div>