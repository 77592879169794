import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { ContactService } from "../../_services/contact.service";
import { AlertService } from "../../_services/alert.service";
import { AccountService } from "../../_services/account.service";
import { environment } from "../../../environments/environment";
import * as moment from "moment";
import { MessageService } from "./../../_services/message.service";

export interface DialogData {
  contacts: any;
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {
  contacts: any[];
  textMsg: string;
  selectedMsg: string;
  messages: any = [];

  showTemplates: boolean = true;
  customTextBox: boolean = false;
  contactRef: any;
  contactDetails: any;
  sendTo: string;
  twilio_from: string;
  twilio_credentials: any;
  account_sid: string;
  auth_token: string;
  twilio_to: any;
  sendLink: boolean = false;
  links: any = []; // = ["https://interactive-appointments.herokuapp.com/dental-designer/dental-calendar"];
  calendarLink: string;
  calendar_slug: string;
  acc_slug: string;
  optOut: boolean = false;
  googleLink: boolean = false;
  googleReview: string;
  optOutMsg: string =
    "If you would like to opt out of receiving texts from us, please reply STOP.";
  apptDate: boolean = false;
  selectedDate: any;
  startTime: any;
  endTime: any;
  dateItems: any = {};
  matchedString: any;
  minDate: any = new Date();

  workingTimes: any = [];
  confirmSend: boolean = false;
  messageObj: any;
  displayMsg: string;
  defaultTime: any;
  new_sms_array: any;
  fetch_logs: boolean = false;
  contactAppt: any;
  isApptDate: boolean = false;
  isAppt: boolean;
  dateOfAppt: any;
  timeOfAppt: any;
  service_sid: string;
  contact_ids: any = []; // contain the contact id of each contact
  languages: any;
  sourceLanguage: any;
  selectedLanguage: any;
  // selectedLanguageName: any;

  constructor(
    private contactService: ContactService,
    private accountService: AccountService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.contacts = data.contacts;
    let acc = this.accountService.getCurrentAccDetails();
    this.languages = acc.languages ? acc.languages : [];
    this.sourceLanguage = acc.languageCode ? acc.languageCode : 'en';
    this.acc_slug = acc.account_slug;
    this.fetchCalendars();
    this.fetchCalendarGroups();
    this.messages = acc.text_msg_templates;
    this.twilio_from = acc.twilio_from;
    this.twilio_credentials = this.accountService.getLinkedAccountCredentials(
      "twilio"
    );
    this.account_sid = this.twilio_credentials.account_sid;
    this.auth_token = this.twilio_credentials.auth_token;
    this.service_sid = this.twilio_credentials.notify_service_sid;
    this.twilio_to = [];

    let textReminderFalse = [];
    data["contacts"].forEach(async (contactObj) => {
      if (contactObj.send_text_reminders === false) {
        textReminderFalse.push(contactObj.full_phone);
      }
      if ((contactObj.active === undefined || contactObj.active === true) && contactObj.send_text_reminders === true) {
        this.twilio_to.push(contactObj.full_phone);
        this.contact_ids.push(contactObj.ref.id);
      }
      // this.selectedLanguage = await this.messageService.getTargetLanguage(contactObj.full_phone);
      // this.languages.forEach(language => {
      //   if (language.value == this.selectedLanguage)
      //     this.selectedLanguageName = language.name;
      // })
    });
    if (textReminderFalse.length > 0) {
      alert("Send text reminder is not active for : " + textReminderFalse.join(','));
    }
  }

  fetchCalendars() {
    this.accountService.fetchAccountCalendars().then((calendar) => {
      calendar.forEach((cal) => {
        let link = `${environment.apptURL}/calendar/${this.acc_slug}/${cal}`;
        this.links.push(link);
      });
    });
  }

  fetchCalendarGroups() {
    this.accountService.fetchAccountCalendarGroups().then((calendarGroup) => {
      calendarGroup.forEach((calendar) => {
        let link = `${environment.apptURL}/calendar-groups/${this.acc_slug}/${calendar}`;
        this.links.push(link);
      });
    });
  }


  showTemplate() {
    this.customTextBox = false;
    this.selectedMsg = "";
    this.apptDate = false;
    this.isApptDate = false;
    this.optOut = false;
    this.sendLink = false;
    this.showTemplates = true;
  }

  customText() {
    this.selectedMsg = "";
    this.sendLink = false;
    this.optOut = false;
    this.apptDate = false;
    this.isApptDate = false;
    this.showTemplates = false;
    this.customTextBox = true;
  }

  confirm() {
    this.confirmSend = true;
    this.displayMsg = this.selectedMsg;
    if (this.sendLink == true) {
      this.displayMsg += " " + this.calendarLink + " .";
    }
    if (this.optOut == true) {
      this.displayMsg += " " + this.optOutMsg;
    }

    this.googleReview = this.accountService.getCurrentAccDetails().googleReviewLink;
    if (this.googleLink == true) {
      this.displayMsg += " " + this.googleReview + " ";
    }
  }

  goBackToSendSMS() {
    this.confirmSend = false;
  }

  sendMessage() {
    let index = 0;
    this.twilio_to.forEach(async to => {
      let targetLanguage = await this.messageService.getTargetLanguage(to);
      this.messageObj = {
        targetLanguage: targetLanguage,
        sourceLanguage: this.sourceLanguage,
        from: this.twilio_from,
        to: [to],
        acc_sid: this.account_sid,
        auth_token: this.auth_token,
        service_sid: this.service_sid,
        sourceMsg: this.displayMsg,
        contact_ids: [this.contact_ids[index]],
      };
      this.contactService.sendSms(this.messageObj);
      index += 1;
    });
    this.dialogRef.close();

    let msgObject = {
      direction: "outbound-api",
      sourceMsg: this.displayMsg,
      status: "sent",
      type: "message",
    };
    console.log(this.messageObj);
    this.contactService.messageNotification(msgObject);
    let snackBarRef = this.snackBar.open("SMS has been sent successfully", "", {
      duration: 4000,
    });
  }

  selectLanguage(event) {
    // this.languages.forEach(language => {
    //   if (language.value == event.value)
    //     this.selectedLanguageName = language.name;
    // })
    console.log(this.twilio_to);
    this.twilio_to.forEach(async phone => {
      await this.messageService.changeTargetLanguage(phone, this.selectedLanguage);
    });
  }

  fetchSMSLogsAgain() {
    let contactObj = {
      phone: this.twilio_to,
      acc_sid: this.account_sid,
      auth_token: this.auth_token,
    };
    this.contactService
      .fetchSMSLogs(contactObj)
      .then((smsData) => {
        let activities = smsData.data;
      })
      .catch((error) => {
        console.log(error);
        this.alertService.error(error.message);
      });
  }

  insertDate(msg, date, startTime) {
    let stringDate = moment(date).format("MMM DD, YYYY");
    this.dateItems["date"] = stringDate;
    let time = moment(startTime, "h:mm a").format("h:mm a");

    if (startTime == undefined) {
      this.populateTime();
      time = this.defaultTime;
      this.dateItems["time"] = time;
    } else {
      this.dateItems["time"] = time;
    }

    let curlyBraces = /{{[0-9a-zA-Z]+?}}/g;
    if (this.selectedMsg.match(curlyBraces) === null) {
      this.selectedMsg = this.selectedMsg + stringDate + " " + time;
    }
    while ((this.matchedString = curlyBraces.exec(msg))) {
      let dateStrings = this.matchedString[0].slice(2, -2);
      for (let i = 0; i < dateStrings.length; i++) {
        if (dateStrings == "Date") {
          this.selectedMsg = this.selectedMsg.replace(
            this.matchedString[0],
            this.dateItems["date"]
          );
        }
        if (dateStrings == "Time") {
          this.selectedMsg = this.selectedMsg.replace(
            this.matchedString[0],
            this.dateItems["time"]
          );
        }
      }
    }
  }

  populateTime() {
    this.workingTimes = [];
    let halfHours = ["00", "30"];
    for (let i = 8; i < 21; i++) {
      for (let j = 0; j < 2; j++) {
        let workingTime = moment(i + ":" + halfHours[j], "h:mm a").format(
          "h:mm A"
        );
        if (i < 10) {
          workingTime = "0" + workingTime;
        }
        this.workingTimes.push(workingTime);
      }
    }
    this.defaultTime = this.workingTimes[0];
  }

  close() {
    this.dialogRef.close();
  }

}
