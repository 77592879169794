import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
// import * as jsPDF from "jspdf";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
@Component({
  selector: "app-trial",
  templateUrl: "./trial.component.html",
  styleUrls: ["./trial.component.scss"],
})
export class TrialComponent implements OnInit {
  @ViewChild("content") content: ElementRef;
  constructor() { }

  ngOnInit() { }

  generatePdf() {
    let doc = new jsPDF({
      orientation: "landscape",
    });
    let specialElementHandlers = {
      "#editor": function (element, renderer) {
        return true;
      },
    };

    let content = this.content.nativeElement;

    // doc.fromHTML(content.innerHTML, 15, 15, {
    //   width: 190,
    //   elementHandlers: specialElementHandlers,
    // });

    // doc.save("test.pdf");
  }

  captureScreen() {
    let data = document.getElementById("contentToConvert");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      let imgWidth = 208;
      let pageHeight = 295;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF
      let position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("MYPdf.pdf"); // Generated PDF
    });
  }
}
