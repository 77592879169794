import { Component, Input, OnInit, AfterViewInit, ViewChildren, Output, EventEmitter, QueryList, ElementRef, Inject } from '@angular/core';
import { FormBuilderService } from '../../_services/form-builder.service';
import { Question, ANSWER_TYPES } from '../../_services/form';
import { SortEvent } from './../../_directives/draggable/sortable-list.directive';
import { PreviewComponent } from '../preview/preview.component';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface DialogData {
  question: any;
  questions: [];
}

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
  providers: [ConfirmationService, MessageService]

})
export class QuestionsComponent implements OnInit, AfterViewInit {

  @ViewChildren('ques_section')
  quesSections: QueryList<ElementRef>;

  // Get reference to the preview component, so this component can call it's scroll method
  @Input() previewRef: PreviewComponent;

  @Input()
  questions: Question[];
  // enquiryFormQuestion: EnquiryFormQuestion[];
  // surveyFormQuestion: SurveyFormQuestion[];
  currentQuesCount: number = 0;
  target: string;
  answerTypes: any = ANSWER_TYPES;

  showAnsTypes: boolean = false;
  currAnsType: string;
  selectedInput: string;
  showSectionOptions: boolean = false;
  isExpanded: boolean[] = [];
  msgs: any;
  operations: any = [
    { label: "Multiplied By", value: "*" },
    { label: "Divided By", value: "/" }
  ];

  formats: any = ['Number', 'Currency'];

  constructor(
    private formBuilderService: FormBuilderService,
    public dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.currentQuesCount = this.questions.length;
    this.questions.forEach(q => {
      this.isExpanded.push(true); // Showing questions as expanded
      // Older forms won't have the values property, so setting it to empty array here
      if (q.values === undefined) {
        q.values = [];
      }
      if (q.answer_type === 'calculator' && q.calculation === undefined) {
        q.calculation = {
          function: ["", "", ""],
          format: "number"
        };
      }
    });

  }

  ngAfterViewInit() {
    this.quesSections.changes.subscribe(changes => {
      if (changes.length - this.currentQuesCount === 1) {
        this.pageScroll(this.currentQuesCount.toString());
      }
      this.currentQuesCount = changes.length;
    });
  }

  getAnswerLabel(answer_type: any) {
    if (answer_type === "") {
      answer_type = 'declaration';
    }
    let value = this.answerTypes.find(x => x.value == answer_type);
    return value.label;
  }

  storeCurrAnsType(currAnsType: string) {
    this.currAnsType = currAnsType;
    this.showAnsTypes = !this.showAnsTypes;
  }

  changeAnsType(question: Question) {
    if (
      this.currAnsType === 'radio'
      || this.currAnsType === 'checkbox'
      || this.currAnsType === 'dropdown'
    ) {
      let confirmation = confirm("This will remove any options and jumps you have put in.\nWould you still like to proceed?");
      if (confirmation) {
        question.choices = [];
        question.jumps = [];
      }
      else {
        question.answer_type = this.currAnsType;
      }
    }
    this.showAnsTypes = false;
  }

  showOptions(question: Question) {
    return (question.answer_type == 'radio' || question.answer_type == 'checkbox' || question.answer_type == 'dropdown');
  }

  addChoice(question: Question) {
    question.choices.push("");
  }

  deleteChoice(question: Question, choice: string) {
    let choice_no = question.choices.findIndex(x => x === choice);
    let jump_val = question.jumps[choice_no];
    question.jumps = question.jumps.filter(x => x !== jump_val);
    question.choices = question.choices.filter(x => x !== choice);
  }

  // ----multi rating--
  addRatingParameter(que: Question) {
    que.rating.push({ question: "" });
  }

  deleteRatingParameter(question: Question, index: any) {
    if (index > -1) {
      question.rating.splice(index, 1);
    }
  }
  // ----------

  deleteQues(question: Question) {
    console.log("delete ques trigger");

    // const deletedialogRef = this.dialog.open(DeleteQuestionDialog, {
    //   width: '350px',
    //   height: '30%',
    //   data: { question: question, questions: this.questions }
    // });
    // deletedialogRef.afterClosed().subscribe(resp => {
    // });
    // -------------
    //this.deleteForm(form_id);

    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete this question?',
    //   header: 'Confirmation',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {
    //     this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
    //   },
    //   reject: () => {
    //     this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
    //   }

    // accept: () => {
    //   // this.questions = this.questions.filter(x => x !== question);
    //   this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Question deleted successfully!' });
    // },
    // reject: () => {
    //   this.messageService.add({ severity: 'info', summary: 'Rejected', detail: 'There is error while deleting this question.' });
    // }
    // });
    // --------------------------------------------------
    if (confirm('Are you sure you want to delete this question?')) {
      this.questions = this.questions.filter(x => x !== question);
      /*
      this.isExpanded = [];
      this.questions.forEach(q => this.isExpanded.push(false));
      */
      this.formBuilderService.indicateQuestionsUpdate(this.questions);
      // alert('Question successfully deleted');
      const dialogRef = this.dialog.open(DeleteQuestionDialog, {
        width: '250px',
      });

    }
  }

  pageScroll(dest: string) {
    let node = document.getElementById(dest);
    scrollIntoView(node, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
    });
  }

  addSection() {
    let question = {
      names: [],
      question: "",
      answer_type: this.selectedInput,
      required: false,
      choices: [],
      jumps: [],
      values: [],
      calculation: {
        function: ["", "", ""],
        format: "number"
      },
      rating: [],
    };
    this.questions.push(question);
    this.isExpanded.push(true);
    this.selectedInput = "";
    this.showSectionOptions = false;
    this.formBuilderService.indicateQuestionsUpdate(this.questions);
  }

  onFocus(question: string) {
    /*
     * This code toggles expansion/collapsion of the card

    let ind = +question;
    this.isExpanded[ind] = !this.isExpanded[ind];
    */
    this.previewRef.scroll(question); //scrolls the preview panel when question is in focus.
  }

  sort(event: SortEvent) {
    const current = this.questions[event.currentIndex];
    const swapWith = this.questions[event.newIndex];

    this.questions[event.newIndex] = current;
    this.questions[event.currentIndex] = swapWith;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  isConstant(funcPart: any) {
    return !isNaN(funcPart) && funcPart !== "";
  }

  makeVariable(q_cnt: number): string {
    return `{{${q_cnt}}}`;
  }

  addFuncParts(ques: Question) {
    ques.calculation.function.push("");
    ques.calculation.function.push("");
  }

  deleteFuncParts(ques: Question, ind: number) {
    ques.calculation.function.splice(ind, 2);
  }

}


@Component({
  selector: 'delete-question-dialog',
  template: `
   <div mat-dialog-content>Question deleted successfully!</div>
   <div mat-dialog-actions style="text-align: center;padding: 1em;">
   <button id="button" mat-raised-button mat-dialog-close (click)="close()"> Ok </button>
   </div>`,
  styles: [' #button {background-color: rgb(63, 81, 181);color:white;}']
})
export class DeleteQuestionDialog {
  constructor(
    public dialogRef: MatDialogRef<DeleteQuestionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  close(): void {
    this.dialogRef.close();
  }
}
