import { filter } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { TableModule } from 'primeng/table';


@Component({
  selector: 'app-list-all-campaigns',
  templateUrl: './list-all-campaigns.component.html',
  styleUrls: ['./list-all-campaigns.component.scss']
})
export class ListAllCampaignsComponent implements OnInit, AfterViewInit {

  constructor() { }
  campaignsListCopy: any = [];
  @ViewChild("dt") table: TableModule;
  @Input() campaignConfigSet: boolean;
  @Input() campaignsList: any = [];
  @Output() showCampaignDetails = new EventEmitter<any>();
  rows: number = 10;
  first: number = 0;
  columns = [
    { "name": "Quicklist Name", "field": "name" },
    { "name": "Created On", "field": "createdOn" },
    { "name": "Status", "field": "status" }
  ];
  disableSortColumns = ["description"]

  ngOnInit(): void {
    this.campaignsListCopy = this.campaignsList.filter(element => element);
  }
  campaignDetails(i) {
    this.showCampaignDetails.emit(i);
  }

  ngAfterViewInit(): void {
    // console.log(this.table);
  }

}
