<div style="background-color: white;margin: 10px 5px 0px;padding: 5px;">
    <form #form="ngForm" style="height: 100%;width: 100%;">
        <div class="row" style="margin: 5px;" *ngIf="!loadingForConfiguration">
            <div class="col-md-6">
                <div class="row" style="margin: 2px;">
                    <span style="padding: 5px 0px 5px 0px;;font-size: 1rem;">Owner's Email</span>
                    <input type="email" pInputText placeholder="Enter email" [(ngModel)]="ownerEmail" name="ownerEmail"
                        required style="width: 100%;" email="true">
                </div>
                <div class="row" style="margin: 2px;">
                    <span style="padding: 5px 0px 5px 0px;;font-size: 1rem;">Email Subject</span>
                    <input type="text" pInputText placeholder="Enter email subject" [(ngModel)]="emailSubject"
                        name="emailSubject" required style="width: 100%;">
                </div>
                <div class="row" style="margin: 2px;">
                    <span style="padding: 5px 0px 5px 0px;;font-size: 1rem;">Email Body</span>
                    <p-editor [(ngModel)]="emailBody" (onTextChange)="onTextChange($event)" name="emailBody"
                        placeholder="Enter email body" [style]="{'height':'180px'}"></p-editor>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row" style="margin: 2px;">
                    <span style="padding: 5px 0px 5px 0px;font-size: 1rem;">SMS Body</span>
                    <textarea rows="16" cols="30" pInputTextarea placeholder="Enter email body" style="width: 100%;"
                        autoResize="autoResize" [(ngModel)]="smsBody" name="smsBody" class="inputText"
                        required></textarea>
                </div>
            </div>
        </div>
        <div *ngIf="loadingForConfiguration" style="margin-top: 20px;">
            <div style="text-align: center">
                <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
            </div>
        </div>
    </form>
    <mat-card-actions *ngIf="!confirmation">
        <div style="text-align: right;margin:9px 12px;" *ngIf="!loadingForConfiguration">
            <button mat-raised-button color="primary" (click)="Save()"
                [disabled]="form.invalid || inValid">Save</button>
        </div>
    </mat-card-actions>
    <mat-card-actions *ngIf="confirmation">
        <div style="text-align: center;" *ngIf="loading">
            <b>{{displayMsg}}</b>
        </div>
        <div style="text-align: center;" *ngIf="!loading">
            <b>{{displayMsg}}</b>
        </div>
    </mat-card-actions>
</div>