import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-component',
  templateUrl: './what-component.component.html',
  styleUrls: ['./what-component.component.scss']
})
export class WhatComponentComponent implements OnInit {

  plan = JSON.parse(localStorage.getItem('newPlan'));
  plan_id = this.plan.id;

  constructor() { }

  ngOnInit() {
  }

}
