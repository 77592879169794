<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortDirection="desc" matSortDisableClear>
  <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsToDisplay">
    <container-element [ngSwitch]="column">{{ dataSource }}

      <th mat-header-cell *matHeaderCellDef>
        {{ column | removeUnderscore | titlecase }}
      </th>
      <!-- mat-sort-header -->
      <td mat-cell *matCellDef="let contact" class="mid">
        <span *ngSwitchCase="'first_name'">
          <input class="myinput" matInput placeholder="Enter" [value]="contact[column]"
            (change)="updateValue($event.target.value, column, contact)" [disabled]="contact['active'] === false" />
        </span>

        <span *ngSwitchCase="'last_name'">
          <input class="myinput" matInput placeholder="Enter" [value]="contact[column]"
            (change)="updateValue($event.target.value, column, contact)" [disabled]="contact['active'] === false" />
        </span>

        <span *ngSwitchCase="'email'">
          <input class="myinput" matInput placeholder="Enter" [value]="contact[column]"
            (change)="updateValue($event.target.value, column, contact)" [disabled]="contact['active'] === false" />
        </span>

        <span *ngSwitchCase="'phone'">
          <input class="myinput" matInput placeholder="Enter" [value]="contact[column]"
            (change)="updateValue($event.target.value, column, contact)" [disabled]="contact['active'] === false" />
        </span>

        <span *ngSwitchCase="'send_text_reminders'">

          <mat-slide-toggle [disabled]="contact['active'] === false" name="send_text_reminders"
            [(ngModel)]="contact['send_text_reminders']" style="vertical-align: middle;" (change)="
              saveSendTextReminders(
                contact['send_text_reminders'],
                contact['ref']
              )
            ">
          </mat-slide-toggle>

          <i *ngIf="
              contact['send_text_reminders'] === true &&
              twilio_from !== 'undefined' &&
              contact['active'] === true
            " matTooltip="Send SMS Now" class="material-icons"
            style="vertical-align: middle; padding-left: 20px; cursor: pointer;"
            (click)="singleMessage(contact)">message
          </i>
        </span>

        <span *ngSwitchCase="'last_visited'" class="matCalender">
          <mat-form-field style="width: 70px; margin-top: 10px;">
            <input matInput [matDatepicker]="picker" style="font-size: 12px; font-weight: 200;"
              [disabled]="contact['active'] === false" [(ngModel)]="contact.last_visited" [max]="maxDate" (dateChange)="
                updateLastVisited(contact.ref, contact.last_visited)
              " placeholder="Choose a date" (click)="_openCalendar(picker)" #elementToFocus />
            <mat-datepicker #picker="matDatepicker" disabled="false" [opened]="false"
              (closed)="_closeCalendar(e, contact_ref, refLast_visited)" [disabled]="contact['active'] === false">
            </mat-datepicker>
          </mat-form-field>
        </span>

        <span *ngSwitchCase="'created_on'">
          {{ contact.created_on | date: "MMM d, yyyy" }}
        </span>
        <!-- First Name  Last Name   Email   Phone   Send Text Reminders Last Visited    Created On  Active -->

        <!--<ng-template #othercolumn>
                        <input class="myinput" matInput placeholder="Enter" [value]="contact[column]" (change)="updateValue($event.target.value, column, contact)" [disabled]="contact['active']===false"><!--</mat-form-field>-->
        <!-- </ng-template> -->

        <span *ngSwitchCase="'active'">
          <mat-slide-toggle class="formatToggle" name="greyRow" [(ngModel)]="contact.active"
            (change)="updateRowVisible(contact, contact_ref)"></mat-slide-toggle>
        </span>
      </td>

    </container-element>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let contact" [attr.colspan]="columnsToDisplay.length">
      <div class="example-element-detail" [@detailExpand]="'expanded'">
        <div class="example-element-description">

          <!--<app-activities *ngIf="expandedElement" [contact]="contact"></app-activities>-->
          <!-- <div *ngIf="contact == expandedElement"> -->

          <app-activities [contact]="contact"></app-activities>

          <!-- <app-fetch-appointments [contact]="contact['appointments']"></app-fetch-appointments> -->
          <!-- </div> -->
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let contact; columns: columnsToDisplay"
    [ngStyle]="contact.active === false && { 'background-color': '#E7E7E7' }" class="example-element-row"
    [class.example-expanded-row]="expandedElement === contact">
    <mat-cell *matCellDef="let contact" [style.display]="'none'">
      {{ contact.last_visited }}
    </mat-cell>
  </tr>

  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>

<!-- -------------------------------------------- -->

<!-- <div class="mat-elevation-z8 scrolling-wrapper-flexbox">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef>First name</th>
      <td mat-cell *matCellDef="let element">{{ element.first_name }}</td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef>Last name</th>
      <td mat-cell *matCellDef="let element">{{ element.last_name }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
    </ng-container>

    <ng-container matColumnDef="created_on">
      <th mat-header-cell *matHeaderCellDef>Created on</th>
      <td mat-cell *matCellDef="let element">{{ element.created_on }}</td>
    </ng-container>

    <ng-container matColumnDef="last_visited">
      <th mat-header-cell *matHeaderCellDef>Last Visited</th>
      <td mat-cell *matCellDef="let element">{{ element.last_visited }}</td>
    </ng-container>

    <ng-container matColumnDef="send_text_reminders">
      <th mat-header-cell *matHeaderCellDef>Send text reminders</th>
      <td mat-cell *matCellDef="let element">
        {{ element.send_text_reminders }}
      </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef>Active</th>
      <td mat-cell *matCellDef="let element">{{ element.active }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let contact; columns: columnsToDisplay"></tr>
  </table>
</div> -->