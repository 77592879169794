import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContactService } from "../_services/contact.service";
import { PatientFormService } from "../_services/patient-form.service";
import {forkJoin} from 'rxjs'

@Component({
  selector: 'app-contact-form-details',
  templateUrl: './contact-form-details.component.html',
  styleUrls: ['./contact-form-details.component.scss']
})
export class ContactFormDetailsComponent implements OnInit {

  @Input() responses;
  @Input() contactId;
  patientFormResponses:any = [];
  expandedPatientFormDetailId: any;
  formDetails: any[];
  isDataAvailabel: boolean;
  surveyformSubscription: Subscription;
  patientformSubscription: Subscription;

  formResponseGroupOriginal: any;
  searchFields: any = ["status", "type"];
  disableSortColumns: string[] = ['firstName', 'lastName', 'action'];
  displayedColumns = [
    { header: "Created On", value: "createdOn", isFilterApply: true, style: 'width: 8rem;' },
    { header: "Submitted On", value: "submittedOn", isFilterApply: false, style: 'width: 8rem;' },
    { header: "Status", value: "status", isFilterApply: false, style: 'width: 5rem;' },
    { header: "Type", value: "type", isFilterApply: false, style: 'width: 4rem;' },
  ];
  surveyFormResponses: any;
  surveyformResponseOriginal: any;
  expandedSurveyFormDetailId: any;
  formData:any = [];

  constructor(
    private contactService: ContactService,
    private patientFormService: PatientFormService,
  ) { 

  }

  ngOnInit(): void {
    this.getFormInfo();
  }

  async getFormInfo() {
    this.patientFormService.getFormsDataByContact(this.contactId).then(async (resp)=>{
      this.surveyFormDetails();
      this.patientFormDetails();
    })
  }

  surveyFormDetails(){
    this.surveyformSubscription = this.patientFormService.surveyFormsResponse$.subscribe(async (forms) => {
      this.formData = [];
      this.surveyFormResponses = await forms;
      this.surveyformResponseOriginal = await forms;
      this.formData = this.surveyformResponseOriginal.concat(this.formResponseGroupOriginal);
      //console.log(this.formData);
      if (this.expandedSurveyFormDetailId) {
        let index = this.surveyformResponseOriginal.findIndex(element => element.id == this.expandedSurveyFormDetailId);
        this.getSelectFormData(this.surveyformResponseOriginal[index]);
      }
    });
  }

  patientFormDetails(){
    this.patientformSubscription = this.patientFormService.patientFormsResponseForSpecificContact$.subscribe(async (forms) => {
      this.formData = [];
      this.patientFormResponses = await forms;
      this.formResponseGroupOriginal = await forms;
      this.formData = this.formResponseGroupOriginal.concat(this.surveyformResponseOriginal);
      //console.log(this.formData);
      if (this.expandedPatientFormDetailId) {
        let index = this.formResponseGroupOriginal.findIndex(element => element.id == this.expandedPatientFormDetailId);
        this.getSelectFormData(this.formResponseGroupOriginal[index]);
      }
    });
  }

  async getSelectFormData(data) {
      this.expandedPatientFormDetailId = data['id'];
      this.formDetails = [];
      this.isDataAvailabel = true;
      this.performOperationForGetForms(data).then(resp => {
        if (this.formDetails.length > 0) {
          this.isDataAvailabel = true;
        }
        else {
          this.isDataAvailabel = false;
        }
      });
  }

  async performOperationForGetForms(data) {
    //console.log(data);
    if(data['type'] == 'PATIENT_FORM'){
      this.expandedPatientFormDetailId = data['id'];
      let temp = [];
      await Promise.all(data.forms.map(async content => {
        let response: any;
        if ('formResponseRef' in content)
          response = await this.patientFormService.getFormDetails(content['formResponseRef']);
        if ('form_ref' in content)
          response = await this.patientFormService.getFormDetails(content['form_ref']);
        if ('pdfUrl' in content)
          response['pdfUrl'] = content['pdfUrl'];
        response['status'] = content['status'];
        if (response) {
          temp.push(response);
        }
      }))
      this.formDetails = temp;
    }
    if(data['type'] == 'SURVEY_FORM'){
      this.expandedSurveyFormDetailId = data['id'];
      this.formDetails = [data];
      this.isDataAvailabel = true;
    }
      //console.log(this.formDetails);
  }

  downloadPdf(url, fileName) {
    //console.log(url);
    let nameOfFile = url.substr(url.lastIndexOf('/') + 1);
    //console.log(nameOfFile);
    this.patientFormService.downloadFile(url, fileName);
  }

}
