import { Component, OnInit } from '@angular/core';
import { ContactService } from '../../_services/index';
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  first_name: string = "";
  last_name: string = "";
  phone: string = "";
  email_id: string = "";

  constructor(
    private contactService:ContactService
  ) { }

  ngOnInit() {

  }

   saveContact(contactData){
     console.log(contactData);
     contactData.value.email = contactData.value.email.trim();
     contactData.value.phone_no = contactData.value.phone_no.trim();

     this.contactService.saveNewContact(contactData.value)
      .then(docRef => {
        if(docRef !== undefined){
          confirm("New contact saved!");
          console.log("New contact created");
        }
        else{
          confirm("Found old record!. Contact updated");
          console.log("Contact updated");
        }
      });


   }
}
