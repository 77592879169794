import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Paginator } from 'primeng/paginator';
import { Subscription } from 'rxjs';
import { PatientFormService } from "../../_services/patient-form.service";
import { SendPatientFormsComponent } from "../send-patient-forms/send-patient-forms.component"
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-patient-form-responses',
  templateUrl: './patient-form-responses.component.html',
  styleUrls: ['./patient-form-responses.component.scss'],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          transform: "translate3d(0, 0, 0)",
        })
      ),
      state(
        "out",
        style({
          transform: "translate3d(100%, 0, 0)",
        })
      ),
      transition("in => out", animate("400ms ease-in-out")),
      transition("out => in", animate("400ms ease-in-out")),
    ]),
  ],
})
export class PatientFormResponsesComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('paginator', { static: false }) paginator: Paginator;
  @Input() searchTerm;
  formResponseGroupOriginal: any = [];
  value: Date;
  rows: number = 10;
  first: number = 0;
  pagingInfo = {};
  totalForms: number;
  pagingEvent: any;
  formResponseGroup: any = [];
  contactsDetails: any[] = [];
  formDetails: any = [];
  show: boolean;
  datakey: string = "id";
  sortDesc: boolean = true;
  searchFields: any = ["status", "id", "first_name", "last_name"];
  disableSortColumns: string[] = ['firstName', 'lastName', 'action'];
  displayedColumns = [
    { header: "First Name", value: "firstName", isFilterApply: false, style: 'width: 7rem;' },
    { header: "Last Name", value: "lastName", isFilterApply: false, style: 'width: 7rem;' },
    { header: "Created On", value: "createdOn", isFilterApply: true, style: 'width: 8rem;' },
    { header: "Submitted On", value: "submittedOn", isFilterApply: false, style: 'width: 8rem;' },
    { header: "Status", value: "status", isFilterApply: false, style: 'width: 5rem;' },
    { header: "Action", value: "action", isFilterApply: false, style: 'width: 4rem;' },
  ];
  formSubscription: Subscription;
  isDataAvailabel: boolean;
  expandedPatientFormDetailId: any;
  totalRecords:number=0;

  constructor(
    private patientFormService: PatientFormService,
    public dialog: MatDialog
  ) {
    this.formSubscription = this.patientFormService.patientFormsResponse$.subscribe(async (forms) => {
      this.formResponseGroup = await forms;
      this.totalRecords = this.formResponseGroup.length
      this.formResponseGroupOriginal = await forms;
      //console.log(this.formResponseGroup);
      if (this.searchTerm)
        this.filterForms();
      if (this.expandedPatientFormDetailId) {
        let index = this.formResponseGroupOriginal.findIndex(element => element.id == this.expandedPatientFormDetailId);
        this.getForms(this.formResponseGroupOriginal[index]);
      }
      this.show = true;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchTerm']['currentValue'] != '') {
      this.searchTerm = changes['searchTerm']['currentValue'];
      this.filterForms();
    }
    else {
      this.formResponseGroup = this.formResponseGroupOriginal.filter(element => element);
    }
  }

  filterForms() {
    this.formResponseGroup = this.formResponseGroupOriginal.filter((form) => {
      let formObject = {
        "first_name": form['first_name'],
        "last_name": form['last_name'],
        "status": form['status'] ? form['status'] : ''
      }
      return ((JSON.stringify(formObject)).toLocaleLowerCase().includes(this.searchTerm.toLowerCase()) == true)
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }


  async getForms(data) {
    this.expandedPatientFormDetailId = data['id'];
    //console.log(data);
    this.formDetails = [];
    this.isDataAvailabel = true;
    this.performOperationForGetForms(data).then(resp => {
      if (this.formDetails.length > 0) {
        this.isDataAvailabel = true;
      }
      else {
        this.isDataAvailabel = false;
      }
    });
  }

  async performOperationForGetForms(data) {
    // //console.log(data);
    let temp = [];
    await Promise.all(data.forms.map(async content => {
      let response: any;
      if ('formResponseRef' in content)
        response = await this.patientFormService.getFormDetails(content['formResponseRef']);
      if ('form_ref' in content)
        response = await this.patientFormService.getFormDetails(content['form_ref']);
      if ('pdfUrl' in content)
        response['pdfUrl'] = content['pdfUrl'];
      response['status'] = content['status'];
      if (response) {
        temp.push(response);
      }
    }))
    this.formDetails = temp;
  }


  resendForm(data) {
    const dialogRef = this.dialog.open(SendPatientFormsComponent, {
      width: "1500px",
      height: '550px',
      data: {
        dialogTitle: "Resend Patient Form",
        screenType: "formSend",
        type: "resend",
        selectedForms: data['forms'].map(ele => ele.formRef.id),
        contactsDetails: [data['contactDetails']],
        patientFormDetailsDocId: data['id']
      },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  async copyForm(data) {
    let tempPatientForms = []
    await Promise.all(data['forms'].map(async ele => {
      let tempobject = {};
      tempobject = ((await ele.formRef.get()).data());
      tempobject['id'] = (await ele.formRef.id);
      tempPatientForms.push(tempobject);
    })
    )

    const dialogRef = this.dialog.open(SendPatientFormsComponent, {
      width: "1500px",
      height: '550px',
      data: {
        dialogTitle: "Copy Patient Form",
        screenType: "algoliaSearch",
        type: "copy",
        selectedForms: tempPatientForms
      },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
    });

  }

  downloadPdf(url, fileName) {
    let nameOfFile = url.substr(url.lastIndexOf('/') + 1);
    // saveAs.saveAs(url,nameOfFile)
    this.patientFormService.downloadFile(url, fileName);
  }

  paginate(event) {
    this.rows = event.rows;
    this.pagingInfo['limit'] = this.rows;
    this.pagingInfo['offset'] = this.rows * event.page;
  }

  selectColumnForSort(colName, index) {
    for (var i = 0; i < this.displayedColumns.length; i++) {
      if (i == index) {
        this.displayedColumns[i]['isFilterApply'] = true;
      }
      else {
        this.displayedColumns[i]['isFilterApply'] = false;
      }
    }
    if (!this.sortDesc)
      this.sortRecord(colName, 'desc');
    else
      this.sortRecord(colName, 'asc');
  }

  selectSortRecord(colName, type) {
    this.sortRecord(colName, type);
  }

  sortRecord(colName, type) {
    this.pagingInfo['colName'] = colName;
    this.sortDesc = !this.sortDesc;
    if (type == 'asc') {
      this.pagingInfo['sortCreatedOnType'] = 'asc';
    }
    else {
      this.pagingInfo['sortCreatedOnType'] = 'desc';
    }
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

}
