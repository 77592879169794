import { Component, OnInit,ChangeDetectorRef } from "@angular/core";
import {
  NavigationService,
  TipsService,
  AccountService,
} from "../_services/index";
import { NavigationState } from "../_models/index";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  notification: number;
  state: NavigationState=null;
  acc: any;

  constructor(
    private navigationService: NavigationService,
    private tipsService: TipsService,
    private accountService: AccountService,
    private detectorRef:ChangeDetectorRef,
  ) {
    this.tipsService.notification$.subscribe(
      (count) => (this.notification = count)
    );
    this.navigationService.state$.subscribe((state) => {
      this.state = state;
      // this.detectorRef.detectChanges();
    });
    this.accountService.accChange$.subscribe((accChange) => {
      this.acc = this.accountService.getCurrentAccDetails();
      // this.tipsService.setTipsCount("/tips/" + this.acc.name);
    });
  }

  ngOnInit() {
    this.acc = this.accountService.getCurrentAccDetails();
    // this.tipsService.setTipsCount("/tips/" + this.acc.name);
    this.state = new NavigationState(false, "", "", "#", "/main");
  }
}
