<div class="items" *ngIf="!loading" style="width: 100%;height: 100%;">
  <form #form="ngForm" (ngSubmit)="updateConfiguration(form)" [formGroup]="configFormGroup">
      <div class="row mobileView">
          <div class="col-md-1">
          </div>
          <div class="col-md-10">
              <div class="row">
                <!-- <div class="col-md-3 mobileViewCol" style="padding-left: 0px;">
                  <mat-form-field class="example-full-width">
                    <mat-label>Country Code </mat-label>
                    <mat-select class="form-control"  formControlName="code" name="countryCode">
                      <mat-option  *ngFor="let code of codes" [value]="code.code">
                        {{code.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div> -->
                <!-- <div class="col-md-9 mobileViewCol"> -->
                  <mat-form-field class="example-full-width">
                    <mat-label>Twilio Number </mat-label>
                      <input matInput placeholder="Enter twilio number" class="form-control" formControlName="twilioNo" name="twilioNo"  required>
                      <mat-error *ngIf="configFormGroup.get('twilioNo').hasError('required')">Twilio number required</mat-error>
                      <mat-error *ngIf="configFormGroup.get('twilioNo').hasError('pattern')">invalid number</mat-error>
                  </mat-form-field>
                <!-- </div> -->
              </div>
              <div class="row">
                <mat-form-field class="example-full-width">
                    <mat-label>Account SID </mat-label>
                    <input matInput placeholder="Enter twilio number" class="form-control"  formControlName="accSid" name="sid"  required>
                    <mat-error *ngIf="configFormGroup.get('accSid').hasError('required')">Account SID required</mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="example-full-width">
                    <mat-label>Auth Token </mat-label>
                    <input matInput placeholder="Enter Auth Token" class="form-control" formControlName="authToken" name="authToken"  required>
                    <mat-error *ngIf="configFormGroup.get('authToken').hasError('required')">Outh Token required</mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="example-full-width">
                    <mat-label>Notify Service ID </mat-label>
                    <input matInput placeholder="Enter Notify Service Id" class="form-control" formControlName="notifyServiceId" name="notifyId"  required>
                    <mat-error *ngIf="configFormGroup.get('notifyServiceId').hasError('required')">Account SID required</mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                  <div style="text-align: center;width: 100%;margin-top: 2%;margin-bottom: 1%;">
                      <button mat-raised-button color="primary" class="text-center" type="submit"
                      [disabled]="!form.form.valid"
                      >{{label}}</button>
                  </div>
              </div>
          </div>
          <div class="col-md-1">

          </div>
      </div>
  </form>
</div>
<div class="items" *ngIf="loading">
  <div style="text-align: center;padding-top: 10%;">
      <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
  </div>
</div>

