<div>
    <i class="material-icons icon-btn" style="float: right;" id="icon" (click)="close()">close</i>

    <h4>Embed Form</h4>
    <div style="padding-top: 1em;">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedRadio" (change)="radioChange($event)">
            <mat-radio-button value="code" name="embed">Embed Code</mat-radio-button>
            <mat-radio-button value="url" name="embed">Embed URL</mat-radio-button>
        </mat-radio-group>
    </div>

    <div *ngIf="selectedRadio=='code'">
        <div style="padding-top: 1em;">
            Paste the following into your site
            <i class="pi pi-clone" id="copyIcon" matTooltip="Copy To Clipboard" (click)="copyToClipboard()"></i>
            <div style="padding:1em;">
                <textarea disabled="disabled" *ngIf="!source && !category" class="textarea"><iframe width="{{width}}" height="{{height}}" style="border:0;" frameborder="0" src="{{data.formUrl}}" allowfullscreen></iframe>
                </textarea>
                <textarea disabled="disabled" *ngIf="source && !category" class="textarea"><iframe width="{{width}}" height="{{height}}" style="border:0;" frameborder="0" src="{{data.formUrl}}?source={{source}}" allowfullscreen></iframe>
                </textarea>
                <textarea disabled="disabled" *ngIf="category && !source" class="textarea"><iframe width="{{width}}" height="{{height}}" style="border:0;" frameborder="0" src="{{data.formUrl}}?category={{category}}" allowfullscreen></iframe>
                </textarea>
                <textarea disabled="disabled" *ngIf="source && category" class="textarea"><iframe width="{{width}}" height="{{height}}" style="border:0;" frameborder="0" src="{{data.formUrl}}?source={{source}}&category={{category}}" allowfullscreen></iframe>
                </textarea>
            </div>
        </div>

        <div class="mat-form-field--inline" style="padding-top: 1em;">
            <mat-form-field>
                <input matInput [(ngModel)]="width" placeholder="Width(in px)">
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="height" placeholder="Height(in px)">
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="source" placeholder="Source">
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="category" placeholder="Category">
            </mat-form-field>

        </div>
    </div>

    <div *ngIf="selectedRadio=='url'">
        <div style="padding-top: 1em;">
            Paste the following into your site
            <div style="padding:1em;">
                <i class="pi pi-clone" style="float:right;cursor: pointer;position:relative;top: 30px;left: -15px;"
                    matTooltip="Copy to Clipboard" (click)="copyToClipboard()"></i>
                <textarea disabled="disabled" *ngIf="!source && !category" class="textarea">{{data.formUrl}}
                </textarea>
                <textarea disabled="disabled" *ngIf="source && !category" class="textarea">{{data.formUrl}}?source={{source}} 
                </textarea>
                <textarea disabled="disabled" *ngIf="category && !source" class="textarea">{{data.formUrl}}?category={{category}}
                </textarea>
                <textarea disabled="disabled" *ngIf="source && category" class="textarea">{{data.formUrl}}?source={{source}}&category={{category}}
                </textarea>
            </div>
        </div>

        <div class="mat-form-field--inline" style="padding-top: 1em;">
            <mat-form-field>
                <input matInput [(ngModel)]="source" placeholder="Source">
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="category" placeholder="Category">
            </mat-form-field>
        </div>
    </div>
</div>