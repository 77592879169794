<div *ngIf="formExists else notExists">
  <div *ngIf="isStartScreen">
    <div class="container-fluid" *ngIf="loaded else loading;" style="padding: 0 15px 15px 15px;">
      <h1 style="margin:5px 10px;">Select the patient form</h1>
      <br>
      <div id="card-container">
        <div *ngFor="let form of forms" class="card-block plan">
          <mat-card [matBadge]="selectedForms.indexOf(form.id) + 1" matBadgePosition="before" matBadgeColor="accent"
            [matBadgeHidden]="selectedForms.indexOf(form.id) == -1">
            <img class="image" *ngIf="form.data?.welcome_screen.image !== ''" mat-card-image
              src={{form.data.welcome_screen.image}} (click)="selectForm(form)">
            <mat-card-actions>
              <a (click)="selectForm(form)">
                <h4 class="card-title" id="card_icon">{{form.data.name}}</h4>
              </a>
              <!-- <a [routerLink]="['/interactive-form',acc.account_slug,form.slug]">
            <i class="material-icons">remove_red_eye</i>
            </a> -->
              <!-- <a>
                <i class="material-icons" id="card_icon"
                  (click)="openDialogForPreview(form.slug,form.data.name)">remove_red_eye</i>
              </a> -->
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
      <!-- <div class="col-md">
          <p style="font-size: 20px; margin:5px 10px;">Send Via</p>
          <div class="col-md-12">
            <mat-checkbox style="margin:5px 10px;font-size:20px;" value="email" (change)="selectSmsEmail($event)">Email
            </mat-checkbox>
          </div>
          <div class="col-md-12">
            <mat-checkbox style="margin:5px 10px;font-size: 20px;" value="sms" [disabled]="disableSms"
              (change)="selectSmsEmail($event)">SMS
            </mat-checkbox>
          </div>
        </div>
        <div class="col-md text-center">
          <button mat-raised-button  style="font-size: 20px;"
            [disabled]='selectedForms.length == 0 || !(type.email || type.sms)' (click)="sendForms()">Send Forms</button>
        </div> -->
      <div style="padding-left: 0px;">
        <app-email-sms-capture-dialogs [data]="dialogInfo" (newItemEvent)="getSmsEmailStatus($event)">
        </app-email-sms-capture-dialogs>
      </div>

      <div class="row">
        <div class="col-md text-center" style="padding-top: 20px;">
          <button mat-raised-button (click)="next()" [disabled]="disabled || selectedForms.length == 0">Next</button>
        </div>
      </div>

    </div>
  </div>

  <!--
  <div class="col-md" *ngIf="!isStartScreen">
    <app-display-email-sms [data]="dialogInfo" (newItemEvent)="sendForms($event)"></app-display-email-sms>
  </div> -->


  <div class="col-md" *ngIf="!isStartScreen">
    <div class="col">

      <div class="row-10">
        <app-display-email-sms [data]="dialogInfo"></app-display-email-sms>
      </div>

      <div class="row-2">
        <div class="row text-center" *ngIf="showConfirm" style="padding-top: 50px;">
          <div class="col-md-3">
          </div>
          <div class="col-md-3">
            <button mat-raised-button (click)="previous()">Previous</button>
          </div>
          <div class="col-md-3">
            <button mat-raised-button (click)="sendForms()">Confirm and Send</button>
          </div>
          <div class="col-md-3">
          </div>
        </div>

        <ng-template *ngIf="!goLast">
          <div>
            <mat-spinner></mat-spinner>
          </div>
        </ng-template>

        <!-- <ng-template #done>
          <div class="text-center" style="font-size: 20px;padding: 100px;">
            Form Sending Job is added in the queue and it will send a message soon ......
          </div>
        </ng-template> -->

      </div>

    </div>
  </div>

  <ng-template #loading>
    <div class="center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>
<ng-template #notExists>
  <div class="center" style="font-size: 20px;">
    No forms to send.
  </div>
</ng-template>


<div *ngIf="goLast" id="content">
  <div class="text-center" style="font-size: 20px;padding: 20px;">
    <span style="background-color:#7E84F2;color:white;padding: 1% 2% 1.5%;border-radius: 10px;">
      {{displayMsg}}
    </span>
  </div>
</div>