<div *ngIf="!responseIsReady">
  <mat-progress-spinner mode="indeterminate" style="margin:0 auto;"></mat-progress-spinner>
</div>

<div *ngIf="responseIsReady">

  <!-- -------subheader------- -->
  <div class="row text-center" id="subHeader1">

    <div class="col-md-4" style="margin: auto;">
      <div id="pageTitle" *ngIf="slug === 'enquiry-forms'">
        <a [routerLink]="['/enquiry-forms','responses']"> <i class="pi pi-chevron-left"></i>Enquiry Forms</a>
      </div>
      <div id="pageTitle" *ngIf="slug === 'survey-forms'">
        <a [routerLink]="['/survey-forms']" [state]="{'tab':'responses'}"><i class="pi pi-chevron-left"></i>Survey
          Forms</a>
      </div>
    </div>

    <div class="col-md-4" style="margin: auto;">
      <div id="subPageTitle">
        {{formName}}
      </div>
    </div>

    <div class="col-md-4">
      <div id="action-btn">
        <!-- <button type="button" pButton icon="pi pi-download" iconPos="left" label="Download" (click)="download()"
          style="margin-right: 0.5em;"></button> -->
        <!-- <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="CSV" (click)="exportCSV(res)"
          style="margin-right: 0.5em;"></button> -->
        <!-- <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="exportExcel(res)"
          style="margin-right: 0.5em;" class="ui-button-success"></button> -->
      </div>
    </div>
  </div>

  <!-- -------subheader ends------- -->

  <div class="container-fluid view">
    <div class="mat-elevation-z8" id="pTable">

      <p-table [value]="responses" #res [globalFilterFields]="['status','date','responses[0]?.response']"
        [paginator]="true" [rows]="rows" [(first)]="first" [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} of {totalRecords}" [resizableColumns]="true" [autoLayout]="true"
        [rowsPerPageOptions]="[10,20,50]">

        <ng-template pTemplate="caption">
          <div class="ui-helper-clearfix" style="text-align: left">
            <!-- <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="CSV" (click)="exportCSV(res)"
            style="margin-right: 0.5em;"></button>
          <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="exportExcel(res)"
            style="margin-right: 0.5em;" class="ui-button-success"></button> -->
            <!-- <button type="button" pButton icon="pi pi-file-pdf" iconPos="left" label="PDF" (click)="exportPdf()"
            class="ui-button-warning"></button> -->
          </div>

          <div style="text-align: left">
            <input class="filterInput" type="text" pInputText size="50" placeholder="Filter"
              (input)="res.filterGlobal($event.target.value,'contains')">
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <!-- <th style="display: table-cell;" class="mid" *ngFor="let col of columns" [pSortableColumn]="col.value"
            pResizableColumn>
            {{col.header|titlecase}}
            <p-sortIcon [field]="col.value"></p-sortIcon>
          </th> -->

            <th pSortableColumn="status" class="mid">Status
              <p-sortIcon field="status"></p-sortIcon>
            </th>
            <th pSortableColumn="date" class="mid">Date
              <!-- <p-sortIcon field="date"></p-sortIcon> -->
            </th>
            <th pSortableColumn="date" class="mid" *ngIf="slug == 'survey-forms'">Name
              <!-- <p-sortIcon field="date"></p-sortIcon> -->
            </th>
            <th pSortableColumn="question?.question" *ngFor="let question of responses[0]?.response" class="mid">
              {{ question?.question }}
              <!-- <p-sortIcon field="question?.question"></p-sortIcon> -->
            </th>
            <!-- ------------------------------------------- -->
            <!-- <th pSortableColumn="questions?.question" *ngFor="let questions of responses?.response" class="mid">
            {{ questions?.question }}
            <p-sortIcon field="question?.question"></p-sortIcon>
          </th> -->

            <!-- <th pSortableColumn="question?.question" *ngFor="let question of responses[0]?.response" class="mid">
            <span *ngIf="question?.questions; else section"
              *ngFor="let sectionQuestion of question?.question.questions">
              {{ sectionQuestion?.question }}
            </span>

            <ng-template #section>
              {{ question?.question }}
            </ng-template>

            <p-sortIcon field="question?.question"></p-sortIcon>
          </th> -->
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-response>
          <!-- <tr>
          <td *ngFor="let col of columns">
            {{response[col.value]}}
          </td>
        </tr> -->
          <tr>
            <td class="mid">{{response.status | titlecase}}</td>
            <td class="mid">
              <span *ngIf="response?.status === 'submitted' ; else initiated">
                {{response?.submitted.toDate()| date:'MMM d, yyyy h:mm a'}}</span>
              <ng-template #initiated>
                {{response?.initiated.toDate()| date:'MMM d, yyyy h:mm a'}}
              </ng-template>
            </td>
            <td class="mid" *ngIf="slug == 'survey-forms'">
              <a [routerLink]="['/contacts',response.contactId ,'forms']">
                {{response.first_name}} {{response.last_name}}
              </a>
            </td>
            <td class="mid" *ngFor="let question of response?.response">
              <span class="left_space" *ngIf="question?.answer_type === 'name' && question?.names != undefined">
                {{question?.names[0]}} {{question?.names[1]}}
              </span>
              <span class="left_space" *ngIf="question?.answer_type === 'name' && question?.names == undefined">
                -
              </span>
              <span class="left_space" *ngIf="question?.answer_type !== 'file'; else notImage">
                {{question?.answer}}
              </span>
              <ng-template #notImage>
                <a *ngIf="question?.answer!=undefined" (click)="imageLink(question?.answer)" target="_self">
                  View File</a>
              </ng-template>
            </td>
          </tr>
        </ng-template>

      </p-table>
    </div>
  </div>
</div>