// used in material-side-nav component. Currently in use

export const Admin_Menu = {
  "label": "Admin",
  "icon": "person",
  "disabled": false,
  "isExpanded": false,
  "admin": true,
  "display": true,
  "routerLink": ["/#"],
  "submenu": [
    {
      "label": "Accounts",
      "icon": "add",
      "routerLink": ["/allAccounts"],
      "display": true,
      "disabled": false

    },
    {
      "label": "Target Keywords",
      "icon": "border_all",
      "routerLink": ["/target-keywords-Details"],
      "display": true,
      "disabled": false
    }
  ]
}

export const BASIC_MENU = [
  {
    "label": "Overview",
    "icon": "home",
    "routerLink": ["/#"],
    "disabled": true,
    "display": true,
    "selected": false
  },
  {
    "label": "Recommendations",
    "icon": "info",
    "routerLink": ["/#"],
    "disabled": true,
    "display": true,
    "selected": false
  },
  {
    "label": "Contacts",
    "icon": "person",
    "routerLink": ["/contacts"],
    "display": true,
    "selected": false
  },
  {
    "label": "Appointments",
    "icon": "date_range",
    "routerLink": ["/appointments"],
    "display": true,
    "selected": false
  },
  {
    "label": "Messages",
    "icon": "message",
    "routerLink": ["/message"],
    "display": true,
    "selected": false
  },
  {
    "label": "Campaigns",
    "icon": "campaign",
    "routerLink": ["/#"],
    "display": true,
    "selected": false,
    "submenu": [
      {
        "label": "Campaigns",
        "icon": "trending_up",
        "routerLink": ["/campaigns"],
        "display": true,
        "disabled": false
      },
      {
        "label": "Activity",
        "icon": "grading",
        "routerLink": ["/activity"],
        "display": true,
        "disabled": false
      }
    ]
  },
  {
    "label": "Quicklist",
    "icon": "list",
    "routerLink": ["/#"],
    "display": true,
    "selected": false,
    "submenu": [
      {
        "label": "Send Now",
        "icon": "chat",
        "routerLink": ["/quick-list"],
        "display": true,
        "disabled": false
      },
      {
        "label": "Activity",
        "icon": "grading",
        "routerLink": ["/quick-list-activity"],
        "display": true,
        "disabled": false
      },
    ]
  },
  {
    "label": "Loyalty Program",
    "icon": "star",
    "routerLink": ["/#"],
    "display": true,
    "selected": false,
    "submenu": [
      {
        "label": "Rewards",
        "icon": "list",
        "routerLink": ["/rewards"],
        "display": true,
        "disabled": false
      },
      {
        "label": "Settings",
        "icon": "settings",
        "routerLink": ["/reward-campaigns"],
        "display": true,
        "disabled": false
      }
    ]
  },
  {
    "label": "Enquiry Forms",
    "icon": "folder",
    "display": true,
    "routerLink": ["/enquiry-forms"],
    "selected": false
  },
  {
    "label": "Patient Forms",
    "icon": "folder_shared",
    "routerLink": ["/patient-intake-forms"],
    "display": true,
    "selected": false
  },
  {
    "label": "Survey Forms",
    "icon": "rule_folder",
    "routerLink": ["/survey-forms"],
    "display": true,
    "selected": false
  },
  {
    "label": "Template",
    "icon": "code",
    "disabled": false,
    "display": true,
    "routerLink": ["/template-parsing"],
    "selected": false
  },
  {
    "label": "Emails",
    "icon": "message",
    "routerLink": ["/#"],
    "disabled": true,
    "display": true,
    "selected": false
  },
  {
    "label": "Phone calls",
    "icon": "person",
    "routerLink": ["/#"],
    "disabled": true,
    "display": true,
    "selected": false
  },

  {
    "label": "Reviews",
    "icon": "zoom_in",
    "routerLink": ["/reviews"],
    "disabled": false,
    "display": true,
    "selected": false
  },

  {
    "label": "In-box",
    "icon": "login",
    "routerLink": ["/chatbot-message"],
    "display": true,
    "selected": false
  },
  {
    "label": "Out-box",
    "icon": "login",
    "routerLink": ["/#"],
    "disabled": true,
    "display": true,
    "selected": false
  },


  {
    "label": "Websites",
    "icon": "web",
    "routerLink": ["/#"],
    "display": true,
    "isExpanded": false,
    "submenu": [
      {
        "label": "Visitor and Traffic",
        "icon": "traffic",
        "routerLink": ["/#"],
        "display": true,
        "disabled": true
      },
      {
        "label": "SEO Audit",
        "icon": "shop",
        "routerLink": ["/websites"],
        "display": true,
        "disabled": false
      },
      {
        "label": "Search Rankings",
        "icon": "autorenew",
        "routerLink": ["/organic-rank"],
        "display": true,
        "disabled": false
      },
      {
        "label": "Local Rankings",
        "icon": "autorenew",
        "disabled": false,
        "display": true,
        "routerLink": ["/local-rank"]
      },
      {
        "label": "Top Pages",
        "icon": "sort",
        "routerLink": ["/#"],
        "display": true,
        "disabled": true
      },
      {
        "label": "Content Updates",
        "icon": "check",
        "routerLink": ["/#"],
        "display": true,
        "disabled": true
      }
    ]
  },
  {
    "label": "Social Media",
    "icon": "public",
    "routerLink": ["/#"],
    "display": true,
    "isExpanded": false,
    "submenu": [
      {
        "label": "Page Followers",
        "icon": "groups",
        "disabled": true,
        "display": true,
        "routerLink": ["/#"]
      },
      {
        "label": "Engagement",
        "icon": "autorenew",
        "disabled": true,
        "display": true,
        "routerLink": ["/#"]
      },
      {
        "label": "Posts",
        "icon": "collections",
        "disabled": false,
        "display": true,
        "routerLink": ["/posts"]
      }
    ]
  },
  {
    "label": "Marketing",
    "icon": "local_mall",
    "routerLink": ["/#"],
    "display": true,
    "isExpanded": false,
    "submenu": [
      {
        "label": "Campaigns",
        "icon": "trending_up",
        "routerLink": ["/#"],//["/main"],
        "disabled": false,
        "display": true,
        "isExpanded": false,
        "submenu": [
          {
            "label": "Email campaigns ",
            "icon": "message",
            "display": true,
            "disabled": true,
            "routerLink": ["/email"]
          },
          {
            "label": "SMS campaigns",
            "icon": "chat",
            "routerLink": ["/sms-campaign"],
            "display": true,
            "disabled": false
          },
          {
            "label": "Google campaigns",
            "icon": "search",
            "disabled": true,
            "display": true,
            "routerLink": ["/adwords"]
          },
          {
            "label": "Facebook campaigns",
            "icon": "refresh",
            "disabled": true,
            "display": true,
            "routerLink": ["/facebook"]
          }
        ]
      },
      {
        "label": "Annual Marketing Plan",
        "icon": "trending_up",
        "routerLink": ["/#"],
        "display": true,
        "disabled": true

      },

      {
        "label": "Offers",
        "icon": "local_offer",
        "disabled": false,
        "display": true,
        "routerLink": ["/marketing-offers"]
      },
      {
        "label": "Social Media Calendar",
        "icon": "date_range",
        "routerLink": ["/social-media-calender"],
        "display": true,
        "disabled": true

      },
      {
        "label": "Search Console",
        "icon": "refresh",
        "disabled": false,
        "display": true,
        "routerLink": ["/search-console"]

      }
    ]
  },
  {
    "label": "Settings",
    "icon": "tune",
    "routerLink": ["/#"],
    "display": true,
    "isExpanded": false,
    "submenu": [
      {
        "label": "Practice settings",
        "icon": "settings",
        "display": true,
        "disabled": false,
        "routerLink": ["/add-account"]

      },
      {
        "icon": "refresh",
        "label": "Data Channels",
        "display": true,
        "disabled": false,
        "routerLink": ["/data-channels"]
      },
      {
        "label": "User Access and Permissions",
        "icon": "edit",
        "display": true,
        "routerLink": ["/#"],
        "disabled": true
      },
      /* {
         "label": "Loyalty Settings",
         "icon": "",
         "display": true,
         "routerLink": ["/reward-campaigns"],
         "disabled": false
       },*/
      {
        "label": "Configure Calendar",
        "display": true,
        "icon": "eject",
        "disabled": false,
        "routerLink": ["/calendar-config"]

      },
      {
        "label": "Chatbot Settings",
        "display": true,
        "icon": "eject",
        "routerLink": ["/chatbot-settings"],
        "disabled": false
      }
    ]
  },

]

/**
 * {
          "label": "Admin",
          "icon": "person",
          "disabled": true,
          "isExpanded": false,
          "admin": true,
          "routerLink": ["/#"],
          "submenu": [
            {
              "label": "Accounts",
              "icon": "add",
              "routerLink": ["/allAccounts"],
              "disabled": true

            },
            {
              "label": "Target Keywords",
              "icon": "border_all",
              "routerLink": ["/target-keywords-Details"],
              "disabled": true
            }
          ]
        }
 */
/**
 * //Menu of mobile view
      this.menuList = [
        {
          label: 'Overview',
          icon: 'home',
          routerLink: ["/#"],
          disabled: true,
          selected: false
        },
        {
          label: 'Recommendations',
          icon: 'info',
          routerLink: ["/#"],
          disabled: true,
          selected: false
        },
        {
          label: 'Contacts',
          icon: 'person',
          routerLink: ['/contacts'],
          selected: false
        },
        {
          label: 'Appointments',
          icon: 'date_range',
          routerLink: ['/appointments'],
          selected: false,
        },
        {
          label: 'Messages',
          icon: 'message',
          routerLink: ['/message'],
          selected: false,
        },
        {
          label: 'Enquiry Forms',
          icon: 'folder',
          routerLink: ['/enquiry-forms'],
          selected: false,
        },
        {
          label: 'Patient Forms',
          icon: 'folder_shared',
          routerLink: ['/patient-intake-forms'],
          selected: false,
        },
        {
          label: 'Survey Forms',
          icon: 'rule_folder',
          routerLink: ["/survey-forms"],
          selected: false,
        },
        // {
        //   label: "Data Studio Report",
        //   icon: "text_snippet",
        //   routerLink: ["/data-studio-report"],
        //   selected: false,
        // },
        {
          label: "Emails",
          icon: "message",
          routerLink: ["/#"],
          disabled: true,
          selected: false,
        },
        {
          label: "Phone calls",
          icon: "person",
          routerLink: ["/#"],
          disabled: true,
          selected: false,
        },

        {
          label: "Reviews",
          icon: "zoom_in",
          routerLink: ["/reviews"],
          disabled: false,
          selected: false,
        },

        {
          label: "In-box",
          icon: "login",
          routerLink: ["/chatbot-message"],
          selected: false,
        },
        {
          label: "Out-box",
          icon: "login",
          routerLink: ["/#"],
          disabled: true,
          selected: false,
        },
        {
          label: 'Websites',
          icon: 'web',
          routerLink: ["/#"],
          isExpanded: false,
          submenu: [
            {
              label: 'Visitor and Traffic',
              icon: 'traffic',
              routerLink: ["/#"],
              disabled: true,
            },
            {
              label: 'SEO Audit',
              icon: 'shop',
              routerLink: ["/#"],
              disabled: false,
            },
            {
              label: "Search Rankings",
              icon: "autorenew",
              routerLink: ["/websites"],
            },
            {
              label: "Top Pages",
              icon: "sort",
              routerLink: ["/#"],
              disabled: true,
            },
            {
              label: "Content Updates",
              icon: "check",
              routerLink: ["/#"],
              disabled: true,
            },
          ]
        },
        {
          label: "Social Media",
          icon: "public",
          routerLink: ["/#"],
          isExpanded: false,
          submenu: [
            {
              label: "Page Followers",
              icon: "groups",
              disabled: true,
              routerLink: ["/#"],
              // command: (event) => (this.showSidebar = false),
            },
            {
              label: "Engagement",
              icon: "autorenew",
              disabled: true,
              routerLink: ["/#"],
              // command: (event) => (this.showSidebar = false),
            },
            {
              label: "Posts",
              icon: "collections",
              // command: (event) => (this.showSidebar = false),
              disabled: true,
              routerLink: ["/#"],
            },
          ],
        },
        {
          label: "Marketing",
          icon: "local_mall",
          routerLink: ["/#"],
          isExpanded: false,
          submenu: [
            {
              label: "Campaigns",
              icon: "trending_up",// "pi pi-fw pi-chart-line",
              routerLink: ["/main"],
              isExpanded: false,
              submenu: [
                {
                  label: "Email campaigns ",
                  icon: "message",//"pi pi-fw pi-envelope",
                  routerLink: ["/email"],
                  // command: (event) => (this.showSidebar = false),
                },
                {
                  label: "SMS campaigns",
                  icon: "chat",//"pi pi-fw pi-comment",
                  routerLink: ["/#"],
                  // command: (event) => (this.showSidebar = false),
                  disabled: true,
                },
                {
                  label: "Google campaigns",
                  icon: "search",//"pi pi-fw pi-google",
                  routerLink: ["/adwords"],
                  // command: (event) => (this.showSidebar = false),
                },
                {
                  label: "Facebook campaigns",
                  icon: "refresh",//"pi pi-fw pi-refresh",
                  routerLink: ["/facebook"],
                  // command: (event) => (this.showSidebar = false),
                },
              ],
            },
            {
              label: "Annual Marketing Plan",
              icon: "trending_up",//"pi pi-fw pi-chart-line",
              routerLink: ["/#"],
              disabled: true,
              // command: (event) => (this.showSidebar = false),
            },

            {
              label: "Offers",
              icon: "local_offer",//"pi pi-fw pi-tags",
              routerLink: ["/marketing-offers"],
              // command: (event) => (this.showSidebar = false),
              // disabled: true,
            },
            {
              label: "Social Media Calendar",
              icon: "date_range",
              routerLink: ["/#"],
              disabled: true,
              // command: (event) => (this.showSidebar = false),
            },
            {
              label: "Search Console",
              icon: "refresh",
              routerLink: ["/search-console"],
              // command: (event) => (this.showSidebar = false),
            },
          ],
        },
        {
          label: "Settings",
          icon: "tune",//"pi pi-fw pi-sliders-h",
          routerLink: ["/#"],
          isExpanded: false,
          submenu: [
            {
              label: "Practice settings",
              icon: 'settings',//"pi pi-fw pi-cog",
              routerLink: ["/add-account"],
              // command: (event) => (this.showSidebar = false),
            },
            {
              label: "Connect Google, Facebook and more",
              icon: 'refresh',//"pi pi-fw pi-refresh",
              routerLink: ["/account"],
              // command: (event) => (this.showSidebar = false),
            },
            {
              label: "User Access and Permissions",
              icon: 'edit',//"pi pi-fw pi-key",
              // command: (event) => (this.showSidebar = false),
              routerLink: ["/#"],
              disabled: true,
            },
            {
              label: "Configure Calendar",
              icon: 'eject',//"pi pi-fw pi-eject",
              routerLink: ["/calendar-config"],
              // command: (event) => (this.showSidebar = false),
            },
          ],
        },
        {
          label: "Admin",
          icon: 'person',
          disabled: this.disable,
          admin: true,
          // icon:  "pi pi-fw pi-user-edit",
          isExpanded: false,
          routerLink: ["/#"],
          submenu: [
            {
              label: "Accounts",
              icon: 'add',//"pi pi-fw pi-plus",
              routerLink: ["/allAccounts"],
              disabled: this.disable
              // command: (event) => (this.showSidebar = false),
            },
            {
              label: "Target Keywords",
              icon: 'border_all',//"pi pi-fw pi-table",
              routerLink: ["/target-keywords-Details"],
              // command: (event) => (this.showSidebar = false),
              disabled: this.disable
            },
          ],
          // disabled: this.disable,
        }
      ]
      */

/**
 * //Menu of desktop view
      this.menuList = [
        {
          label: 'Overview',
          icon: 'home',
          routerLink: ["/#"],
          disabled: true,
          selected: false
        },
        {
          label: 'Recommendations',
          icon: 'info',
          // routerLink: ["/local-rank"],
          routerLink:["/#"],
          disabled: true,
          selected: false
        },
        // {
        //   label: 'Data Channels',
        //   icon: 'info',
        //   routerLink: ["/data-channels"],
        //   // disabled: true,
        //   selected: false
        // },
        {
          label: 'Contacts',
          icon: 'person',
          routerLink: ['/contacts'],
          selected: false
        },
        {
          label: 'Appointments',
          icon: 'date_range',
          routerLink: ['/appointments'],
          selected: false,
        },
        {
          label: 'Messages',
          icon: 'message',
          routerLink: ['/message'],
          selected: false,
        },
        {
          label: 'Enquiry Forms',
          icon: 'folder',
          routerLink: ['/enquiry-forms'],
          selected: false,
        },
        {
          label: 'Patient Forms',
          icon: 'folder_shared',
          routerLink: ['/patient-intake-forms'],
          selected: false,
        },
        {
          label: 'Survey Forms',
          icon: 'rule_folder',
          routerLink: ["/survey-forms"],
          selected: false,
        },
        // {
        //   label: "Data Studio Report",
        //   icon: "text_snippet",
        //   routerLink: ["/data-studio-report"],
        //   selected: false,
        //   disabled: this.disable,
        // },
        {
          label: "Emails",
          icon: "message",
          routerLink: ["/#"],
          disabled: true,
          selected: false,
        },
        {
          label: "Phone calls",
          icon: "person",
          routerLink: ["/#"],
          disabled: true,
          selected: false,
        },

        {
          label: "Reviews",
          icon: "zoom_in",
          routerLink: ["/reviews"],
          disabled: false,
          selected: false,
        },

        {
          label: "In-box",
          icon: "login",
          routerLink: ["/chatbot-message"],
          selected: false,
        },
        {
          label: "Out-box",
          icon: "login",
          routerLink: ["/#"],
          disabled: true,
          selected: false,
        },
        {
          label: 'Websites',
          icon: 'web',
          routerLink: ["/#"],
          isExpanded: false,
          submenu: [
            {
              label: 'Visitor and Traffic',
              icon: 'traffic',
              routerLink: ["/#"],
              disabled: true,
            },
            {
              label: 'SEO Audit',
              icon: 'shop',
              routerLink: ["/websites"],
              disabled: false,
            },
            {
              label: "Search Rankings",
              icon: "autorenew",
              routerLink: ["/websites"],
            },
            {
              label: "Local Rankings",
              icon: "autorenew",
              routerLink: ["/local-rank"],
            },
            {
              label: "Top Pages",
              icon: "sort",
              routerLink: ["/#"],
              disabled: true,
            },
            {
              label: "Content Updates",
              icon: "check",
              routerLink: ["/#"],
              disabled: true,
            },
          ]
        },
        {
          label: "Social Media",
          icon: "public",
          routerLink: ["/#"],
          isExpanded: false,
          submenu: [
            {
              label: "Page Followers",
              icon: "groups",
              disabled: true,
              routerLink: ["/#"],
              // command: (event) => (this.showSidebar = false),
            },
            {
              label: "Engagement",
              icon: "autorenew",
              disabled: true,
              routerLink: ["/#"],
              // command: (event) => (this.showSidebar = false),
            },
            {
              label: "Posts",
              icon: "collections",
              // command: (event) => (this.showSidebar = false),
              disabled: true,
              routerLink: ["/#"],
            },
          ],
        },
        {
          label: "Marketing",
          icon: "local_mall",
          routerLink: ["/#"],
          isExpanded: false,
          submenu: [
            {
              label: "Campaigns",
              icon: "trending_up",// "pi pi-fw pi-chart-line",
              routerLink: ["/main"],
              isExpanded: false,
              submenu: [
                {
                  label: "Email campaigns ",
                  icon: "message",//"pi pi-fw pi-envelope",
                  routerLink: ["/email"],
                  // command: (event) => (this.showSidebar = false),
                },
                {
                  label: "SMS campaigns",
                  icon: "chat",//"pi pi-fw pi-comment",
                  routerLink: ["/#"],
                  // command: (event) => (this.showSidebar = false),
                  disabled: true,
                },
                {
                  label: "Google campaigns",
                  icon: "search",//"pi pi-fw pi-google",
                  routerLink: ["/adwords"],
                  // command: (event) => (this.showSidebar = false),
                },
                {
                  label: "Facebook campaigns",
                  icon: "refresh",//"pi pi-fw pi-refresh",
                  routerLink: ["/facebook"],
                  // command: (event) => (this.showSidebar = false),
                },
              ],
            },
            {
              label: "Annual Marketing Plan",
              icon: "trending_up",//"pi pi-fw pi-chart-line",
              routerLink: ["/#"],
              disabled: true,
              // command: (event) => (this.showSidebar = false),
            },

            {
              label: "Offers",
              icon: "local_offer",//"pi pi-fw pi-tags",
              routerLink: ["/marketing-offers"],
              // command: (event) => (this.showSidebar = false),
              // disabled: true,
            },
            {
              label: "Social Media Calendar",
              icon: "date_range",
              routerLink: ["/social-media-calender"],
              disabled: true,
              // command: (event) => (this.showSidebar = false),
            },
            {
              label: "Search Console",
              icon: "refresh",
              routerLink: ["/search-console"],
              // command: (event) => (this.showSidebar = false),
            },
          ],
        },
        {
          label: "Settings",
          icon: "tune",//"pi pi-fw pi-sliders-h",
          routerLink: ["/#"],
          isExpanded: false,
          submenu: [
            {
              label: "Practice settings",
              icon: 'settings',//"pi pi-fw pi-cog",
              routerLink: ["/add-account"],
              // command: (event) => (this.showSidebar = false),
            },
            {
              // label: "Connect Google, Facebook and more",
              icon: 'refresh',//"pi pi-fw pi-refresh",
              // routerLink: ["/account"],
              // command: (event) => (this.showSidebar = false),
              label: 'Data Channels',
              //   icon: 'info',
              routerLink: ["/data-channels"],
              //   // disabled: true,
              //   selected: false
            },
            {
              label: "User Access and Permissions",
              icon: 'edit',//"pi pi-fw pi-key",
              // command: (event) => (this.showSidebar = false),
              routerLink: ["/#"],
              disabled: true,
            },
            {
              label: "Configure Calendar",
              icon: 'eject',//"pi pi-fw pi-eject",
              routerLink: ["/calendar-config"],
              // command: (event) => (this.showSidebar = false),
            },
            {
              label: "Chatbot Settings",
              icon: "eject",
              routerLink: ["/chatbot-settings"],
              disabled: false
            }
          ],
        },
        {
          label: "Admin",
          icon: 'person',
          disabled: this.disable,
          // icon:  "pi pi-fw pi-user-edit",
          isExpanded: false,
          admin: true,
          routerLink: ["/#"],
          submenu: [
            {
              label: "Accounts",
              icon: 'add',//"pi pi-fw pi-plus",
              routerLink: ["/allAccounts"],
              disabled: this.disable
              // command: (event) => (this.showSidebar = false),
            },
            {
              label: "Target Keywords",
              icon: 'border_all',//"pi pi-fw pi-table",
              routerLink: ["/target-keywords-Details"],
              // command: (event) => (this.showSidebar = false),
              disabled: this.disable
            },
          ],
          // disabled: this.disable,
        }
      ]*/
