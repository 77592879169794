import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AfService } from '../_services/index';
import { SeoService } from '../_services/seo-service.service';
import { User } from '../_models/interfaces';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-aesurg-google-rankings',
  templateUrl: './aesurg-google-rankings.component.html',
  styleUrls: ['./aesurg-google-rankings.component.scss']
})
export class AesurgGoogleRankingsComponent implements OnInit {

  user:any;
  error: any;
  first_name:string;
  last_name:string;
  cell_no:string;
  url:string;
  email:string;
  password:string;
  location:string = 'India';
  //location: string = 'UK';
  locations : any = ['India', 'US', 'UK', 'UAE'];
  constructor(
    private router: Router,
    public AfService: AfService,
    public afAuth: AngularFireAuth,
    public afs:AngularFirestore,
    public seoService : SeoService
  ) { }

  ngOnInit() {
    this.user = this.afAuth.auth.currentUser;
    if(this.user) {
    /* if(this.user.emailVerified) {
        this.router.navigateByUrl('/login');
      }
      else {
        /*
         * An unverified user is trying to access the signup page, so send him back to
         * login page so that he can re-verify his account if he wants to.
        */

        this.router.navigateByUrl('/login');
      //}
    }
  }

  signUpWithEmail(formData){
  this.AfService.signUpWithEmailForWebAudit(formData);
  this.seoService.getSeoResults(formData['value']['url'], formData['value']['location'], formData['value']['email']);
  }

  goToApptForm() {
    window.open("https://mktg.doctor/#demo", "_blank");
    //window.location.href = "https://mktg.doctor/#demo";
  }

}
