import { Component, Input, OnInit, SimpleChange, OnChanges } from '@angular/core';

@Component({
  selector: 'app-local-rank-analysis',
  templateUrl: './local-rank-analysis.component.html',
  styleUrls: ['./local-rank-analysis.component.scss']
})
export class LocalRankAnalysisComponent implements OnInit, OnChanges {
  @Input() currentResultDetails: any;
  @Input() gotData: boolean;
  loading: boolean = true;
  localRankDetails: any = {};
  detailsExist: boolean = true;
  domainRankDetails: any;
  competitorsAnalysis: any;

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('currentResultDetails' in changes) {
      this.localRankDetails = changes.currentResultDetails.currentValue;
      if (this.localRankDetails) {
        if (Object.keys(this.localRankDetails).length) {
          this.detailsExist = true;
          this.domainRankDetails = this.localRankDetails.competitorsAnalysis[0];
          this.competitorsAnalysis = [...this.localRankDetails.competitorsAnalysis].sort(function(a,b){
            return a.avgTotalRankPosition - b.avgTotalRankPosition ;
          });
          this.competitorsAnalysis.shift();
        }
        else
          this.detailsExist = false;
      }
    }
    if ('gotData' in changes) {
      this.loading = changes.gotData.currentValue;
    }
  }
  constructor() { }

  ngOnInit(): void {
  }
  openLink(url) {
    // console.log("open link");
    window.open(url);
  }
  replace(location) {
    // console.log(location);
    return location.replace(/,/g, ", ");
  }
  tableSort(event) {

  }
}
function INPUT() {
}

