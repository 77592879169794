import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilderService } from '../../_services/form-builder.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactService } from '../../_services/contact.service';
import { environment } from '../../../environments/environment';
import { AccountService } from '../../_services/account.service';
import { PatientFormService } from "../../_services/patient-form.service";

@Component({
  selector: 'app-send-patient-forms',
  templateUrl: './send-patient-forms.component.html',
  styleUrls: ['./send-patient-forms.component.scss']
})
export class SendPatientFormsComponent implements OnInit {
  selectedContacts: any = [];
  inputText: string;
  screenType: string = "algoliaSearch";
  selectedForms: any = [];
  forms: any = [];
  loaded: boolean;
  selectedFormData: any[];
  formExists: boolean;
  emailSubject: string = "Patient Form Link";
  emailBody: string = "Click on below link";
  smsBody: string = "Click on below link";
  linked_accounts: any = [];
  types = { sms: true, email: true };
  disabledEmailSms: boolean;
  selectedContactsId: any = [];
  container: HTMLElement;
  loading: boolean;
  displayMsg: string = 'Form Sending Job is added in the queue and it will send a message soon....';
  confirmation: boolean;
  disabledBtn: boolean;
  ownerEmail: string = '';
  readOnly: boolean = false;

  constructor(
    private formBuilderService: FormBuilderService,
    public dialogRef: MatDialogRef<SendPatientFormsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private accountService: AccountService,
    private contactService: ContactService,
    private patientFormService: PatientFormService,
  ) { }

  ngOnInit(): void {
    //console.log(this.data);
    this.screenType = this.data['screenType'];
    this.getTwilioCredential();
    this.getPatientFormConfiguration();
    this.formBuilderService.getFormData("patient_forms")
    .then(res => {
      res.forEach(doc => {
        let form = { id: '', slug: '', data: {} };
        form.data = doc.data();
        form.id = doc.id;
        form.slug = doc.data().slug;
        this.forms.push(form);
      });
      if (this.forms.length) {
        this.loaded = true;
        if(this.data['type']=="new")
        {
          this.selectedForms = [this.forms[0].id];
          this.selectedFormData = [this.forms[0].data.name];
        }
        if(this.data['type']=="resend"){
          this.selectedForms = this.data['selectedForms'];
          this.selectedContacts = this.data['contactsDetails']; 
        }
        if(this.data['type']=="copy"){
          this.selectedForms = this.data['selectedForms'].map(ele=>ele.id);
          this.selectedFormData = this.data['selectedForms'].map(ele=>ele.name);
        }
        if(this.data['type']=="sendPatientFormFromContactScreen"){
          this.selectedForms = [this.forms[0].id];
          this.selectedFormData = [this.forms[0].data.name];
          this.data['contactsDetails'].forEach(async element=>{
            let contactsId = element.ref.id;
            this.selectedContactsId.push(contactsId);
          });
          //console.log(this.selectedContactsId);
        }
        }
        else
          this.formExists = false;
      });
  }

  onTextChange(event) {
    // console.log(event);
    if (event['htmlValue'])
      this.emailBody = event['htmlValue'];
    else
      this.emailBody = "";
    this.disabledStatus();
  }

  // async getPatientFormConfiguration(){
  //   let configuration = await (await this.patientFormService.getPatientFormConfiguration()).docs.map(data=>data.data())[0];
  //   if(configuration){
  //     if("smsBody" in configuration) 
  //       this.smsBody = configuration['smsBody'];
  //     if("emailBody" in configuration) 
  //       this.emailBody = configuration['emailBody'];
  //     if("emailSubject" in configuration) 
  //       this.emailSubject = configuration['emailSubject'];
  //   }
  // }

  async getPatientFormConfiguration() {
    this.patientFormService.getPatientFormConfiguration().then(async (data) => {
      if (!data.empty) {
        let configuration = data.docs[0].data();
        if (configuration) {
          if ("smsBody" in configuration)
            this.smsBody = configuration['smsBody'];
          if ("emailBody" in configuration)
            this.emailBody = configuration['emailBody'];
          if ("emailSubject" in configuration)
            this.emailSubject = configuration['emailSubject'];
          if ('ownerEmail' in configuration)
            this.ownerEmail = configuration['ownerEmail'];
          this.disabledStatus()
        }
      }
    })
  }

  async getTwilioCredential() {
    this.forms = [];
    this.types.email = true;
    this.linked_accounts = await this.accountService.getLinkedAccountCredentials('twilio');
    if (this.linked_accounts) {
      this.types.sms = true;
    }
    else {
      this.types.sms = false;
    }
    this.disabledStatus();

  }

  getSelectedContacts(data) {
    this.selectedContacts = JSON.parse(data['selectedContacts']);
  }


  emailSmsCheck(event) {
    if ((event.source.name == 'email')) {
      this.types['email'] = event.checked;
    }
    if ((event.source.name == 'sms')) {
      this.types['sms'] = event.checked;
    }
    if (!this.types['email'] && !this.types['sms']) {
      this.disabledEmailSms = true;
    }
    else {
      this.disabledEmailSms = false;
    }
    this.disabledStatus();
  }

  inputValidator(event: any) {
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;   
    if (!pattern.test(this.ownerEmail)) {
        this.disabledBtn = true;
      }
    else{
      this.disabledBtn = false;
    }
  }

  disabledStatus() {

    if (this.types.email && this.types.sms) {
      if (this.emailBody == "" || this.emailSubject == "" || this.smsBody == "" || this.ownerEmail == "") {
        this.disabledBtn = true;
      }
      else {
        this.disabledBtn = false;
      }
    }
    else if (this.types.email && !this.types.sms) {
      if (this.emailBody == "" || this.emailSubject == "" || this.ownerEmail == "") {
        this.disabledBtn = true;
      }
      else {
        this.disabledBtn = false;
      }
    }
    else if (!this.types.email && this.types.sms) {
      if (this.smsBody == "") {
        this.disabledBtn = true;
      }
      else {
        this.disabledBtn = false;
      }
    }
    else {
      this.disabledBtn = true;
    }

    // const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,1000})+$/;   
    // if (!pattern.test(this.ownerEmail)) {
    //     this.disabledBtn = true;
    //   }
    // else{
    //   this.disabledBtn = false;
    // } 
  }

  selectForm(form) {
    if (this.selectedForms.indexOf(form.id) == -1) {
      this.selectedForms.push(form.id);
      this.selectedFormData.push(form.data.name);
    }
    else {
      this.selectedForms.splice(this.selectedForms.indexOf(form.id), 1);
      this.selectedFormData.splice(this.selectedFormData.indexOf(form.data.name), 1);
    }
  }

  Next() {
    if (this.screenType == "algoliaSearch") {
      this.screenType = "formSelection"
    }
    else if (this.screenType == "formSelection") {
      this.screenType = "formSend"
    }
  }

  Previous() {
    if (this.screenType == "formSelection") {
      this.screenType = "algoliaSearch"
    }
    else if (this.screenType == "formSend") {
      this.screenType = "formSelection"
    }

  }

  async sendForms() {
    this.confirmation = true;
    this.readOnly = true;
    const types = {
      sms: this.types['sms'],
      email: this.types['email']
    }
    for (let i = 0; i < this.selectedContacts.length; i++) {
      this.selectedContactsId.push(this.selectedContacts[i].objectID);
    }
    let url = environment.patientIntakeFormUrl + '/patient-form/';
    let data = {
      contacts: this.selectedContactsId,
      forms: this.selectedForms,
      types: types,
      url: url,
      emailBody: this.emailBody,
      smsBody: this.smsBody,
      emailSubject: this.emailSubject,
    };
    if (this.data['type'] == "resend") {
      data['task'] = "resend";
      data['patientFormDetailsDocId'] = this.data['patientFormDetailsDocId']
    }
    else {
      data['task'] = "send";
    }
    this.loading = true;
    this.contactService.sendPatientForms(data).then(response => {
      this.loading = false;
      this.container = document.getElementById("content");
      this.container.scrollTop = this.container.scrollHeight;
      if (response.status == "sent") {
        this.displayMsg = `Message Sent.`;
      }
      else if (response.status == "error") {
        this.displayMsg = `The message can't be sent right now. Please try again later.`;
      }
    });
  }

}
