<div class="fluid-container">
  <div>
    <p-sidebar [(visible)]="showSidebar" [baseZIndex]="10000">
      <p-scrollPanel [style]="{ width: '15em', height: '100%' }" styleClass="custombar1">
        <div>
          <p-panelMenu [multiple]="false" #bigMenu [model]="menuItems"></p-panelMenu>
        </div>
      </p-scrollPanel>
    </p-sidebar>
    <button pButton type="button" (click)="showSidebar = true" icon="pi pi-bars"></button>
  </div>
</div>