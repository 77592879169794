<!--
<h1 mat-dialog-title>Appointment Details</h1>
<p *ngIf="data.calName!=undefined">{{data.calName}}</p>
<div mat-dialog-content>
    <p><b>Location:</b> {{data.location}}</p>
    <p><b>Source:</b> {{data.source}}</p>
    <p><b>New Patient:</b> {{data.new_patient}}</p>
    <mat-form-field>
        <mat-label>Notes</mat-label>
        <input matInput [(ngModel)]="data.notes">
    </mat-form-field>
</div> -->

<h1 mat-dialog-title>Appointment Details</h1>
<div mat-dialog-content>

    <div class="table-responsive">
        <table class="table">
            <thead></thead>
            <tbody>
                <tr>
                    <td>Start Date</td>
                    <td>{{apptData.appt_start | date: 'dd/MM/yyyy'}}</td>

                </tr>
                <tr>
                    <td>End Date</td>
                    <td>{{apptData.appt_end | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr>
                    <td>Slot</td>
                    <td>{{apptData.slot}}</td>
                </tr>
                <tr>
                    <td>Booked on</td>
                    <td>{{apptData.booked_on | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>{{apptData.status}}</td>
                </tr>
                <tr *ngIf="apptData.calName!=undefined">
                    <td>Calendar Name:</td>
                    <td>{{apptData.calName}}</td>
                </tr>
                <tr>
                    <td>Location</td>
                    <td>{{apptData.location}}</td>
                </tr>
                <tr>
                    <td>Source</td>
                    <td>{{apptData.source}}</td>
                </tr>
                <tr>
                    <td>New Patient:</td>
                    <td>{{apptData.new_patient}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <mat-form-field>
        <mat-label>Notes</mat-label>
        <input matInput [(ngModel)]="apptData.notes" (change)="savenote(apptData.notes)">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Close</button>
    <button mat-button [mat-dialog-close]="apptData.notes" cdkFocusInitial>Save</button>
</div>
