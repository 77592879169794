<div><i class="material-icons icon-btn" style="float: right;font-size: 3em;color:white" (click)="close()">close</i>
</div>
<div>
    <div>
        <h4 mat-dialog-title style="text-align: center;
        font-size: 1.25rem;
        background: #3f51b5;
        color: white;
        padding-top: 0.5em;
        padding-bottom: 0.5em ">Book Recurring Appointment</h4>
        <iframe width="100%" height="600px" frameborder="0" [src]="link"></iframe>
    </div>
</div>