<img src="../../assets/images/seo-hero-image.jpg" style="width: 100%;">
<div class="container-fluid justify-content-center">
  <div class="row opening">
    <div class="col-md-12 col-sm-12">
      <h1 class="h1-responsive">How well do you fare on Google's 1st Page?</h1>
      <p>Are you appearing in search results in your location pertaining to the services you provide? Does your website have meaningful and engaging content optimized for the top keywords? Are your webpages optimized to rank you on Google’s 1st page?</p>
      <p>Now, you can find out for yourself in real time.</p>
    </div>
    <div class="col-md-6 col-sm-12">
      <mat-card class="info-cards">
        <mat-card-title>
          <center>
            <h3 class="login_header">Google Rankings</h3>
          </center>
        </mat-card-title>
        <mat-card-subtitle>
          <center>
            <em>Yes, I want to know how my website ranks on Google. I understand that I'll have to enter my website's url and contact information and a free account will be created for me on the mktg.doctor platform.</em>
          </center>
        </mat-card-subtitle>
        <mat-card-content>
          <form #formData='ngForm' (ngSubmit)="signUpWithEmail(formData)" name='myForm'>
            <mat-form-field class="example-full-width">
              <input matInput name="first_name" [(ngModel)]="first_name" placeholder="First Name" required />
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <input matInput name="last_name" [(ngModel)]="last_name" placeholder="Last Name" required />
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-select class="options" name="location" [(ngModel)]="location" placeholder="Choose location" required>
                <mat-option *ngFor="let location of locations" [value]="location">{{location}}</mat-option>
              </mat-select>
            </mat-form-field>
            <!--
            <label>Choose location</label>
            <mat-radio-group name = "location" [(ngModel)]="location" required>
              <div *ngFor="let location of locations">
                <mat-radio-button [value]="location">{{ location }}</mat-radio-button>
            </div>
            </mat-radio-group>
            -->
            <mat-form-field class="example-full-width">
              <input type="url" matInput name="url" [(ngModel)]="url" placeholder="Website URL" required />
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <input matInput name="cell_no" [(ngModel)]="cell_no" placeholder="Cell Number" required />
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <input type="email" matInput name="email" [(ngModel)]="email" placeholder="Email" required />
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <input matInput type="password" name="password" [(ngModel)]="password" placeholder="Password" required />
            </mat-form-field>
            <div>
              <center>
                <button mat-raised-button color="primary" type="submit" [disabled]="!formData.valid">SignUp</button>
              </center>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-6 col-sm-12 text-justify">
      <p>Exclusively for all Aesthetic Medicine India delegates we are offering this for free. All you need to do is:</p>
      <ul>
        <li>
          <strong>Enter your website address (or URL)</strong><br />
          We will instantly show you the top 1000 keywords for which your website ranks on Google with details such as position on Google search, estimated traffic, competition and more. As an added bonus, we will also crawl up to 100 pages on your website and show you the results of SEO Audit for your website (Crawling 100 pages will take some time, but we promise it'll be no longer than 15 minutes)
        </li>
        <li>
          <strong>Give us your contact details</strong><br />
          We will need your contact details so we can share the report with you. We will also create a free account for you on the mktg.doctor platform where you'll be able to see your rankings instantly.
        </li>
        <li>
          <strong>What's next?</strong><br />
          Once you have your rankings on Google and our free SEO Audit report, schedule a meeting with us so we can discuss a marketing plan personalized for your practice. Book your appointment today.
        </li>
      </ul>
      <div>
        <center>
          <button mat-raised-button color="primary" type="submit" (click)="goToApptForm()">Schedule Demo</button>
        </center>
      </div>
    </div>
  </div>
</div>
