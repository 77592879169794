import { Component, ViewChild, OnChanges, ElementRef, Input, Output, SimpleChange, EventEmitter } from '@angular/core';
import { MatDatepicker } from "@angular/material/datepicker";
import * as moment from 'moment';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnChanges {

  @Input() contact: any;
  @Input() languages: [];
  @Output() onContactUpdate = new EventEmitter<any>();

  @ViewChild("input")
  fileInput: ElementRef;

  @ViewChild("elementToFocus")
  _input: ElementRef;

  contactDetails: any;
  apptDetails = [];
  maxDate: any = new Date();
  constructor(
  ) { }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('contact' in changes) {
      this.contactDetails = changes.contact.currentValue;
    }

  }
  ngOnInit() { }

  updateValue(newValue, colName, contact) {
    console.log(colName);
    this.onContactUpdate.emit({ 'value': newValue, 'column': colName, 'contact': contact });
  }

  // saveSendTextReminders(send_text_reminders, ref) {
  //   this.onContactUpdate.emit({ 'send_text_reminders': send_text_reminders, 'ref': ref });
  // }


  // updateLastVisited(ref, last_visited) {
  //   this.onContactUpdate.emit({ 'ref': ref, 'last_visited': last_visited });
  // }

  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open()
  }

  _closeCalendar(e, contact_ref, last_visited) {

    setTimeout(() => this._input.nativeElement.blur());
  }
  updateBirthDate(birthDate, contact, element) {
    let date =
      moment(birthDate, "MM-DD-YYYY", true).isValid() ||
      moment(birthDate, "MM/DD/YYYY", true).isValid() ||
      moment(birthDate, "M-D-YYYY", true).isValid() ||
      moment(birthDate, "M/D/YYYY", true).isValid();
    if (date) {
      this.onContactUpdate.emit({ 'value': birthDate, 'column': 'birthday', 'contact': contact });
    } else {
      element['value'] = moment(contact.birthday).format("MM/DD/YYYY")
      // alert("invalid birth date");
    }
  }

  // updateRowVisible(contact, contact_ref) {
  //   this.onContactUpdate.emit({ 'contactDetails': contact, 'contact_ref': contact_ref });
  // }
}
