import { Component, OnInit, OnDestroy, Inject, NgZone } from "@angular/core";
import { ActivatedRoute, Params, Router, UrlSegment } from "@angular/router";
import { ContactService } from "../_services/contact.service";
import { AppointmentService } from "../_services/appointment.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDatepicker } from "@angular/material/datepicker";
import { environment } from "../../environments/environment";
import { ScheduleNewAppointmentComponent } from "../appointments/schedule-new-appointment/schedule-new-appointment.component";
import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";
import { AccountService } from "../_services/account.service";
import { AlertService } from "../_services/alert.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CreateContactMessageDialog } from "../contact-id/contact-id.component";
import { NotesDialog } from "../contact-notes/contact-notes.component";
import { docChanges } from "angularfire2/firestore";
import { Subscription } from "rxjs";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PatientFormComponent } from '../contacts-list/patient-form/patient-form.component';
import { SurveyFormComponent } from '../contacts-list/survey-form/survey-form.component';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { ResponsesDataSource } from "../_services/responses.datasource";
import { PatientFormService } from "../_services/patient-form.service";
import { FilterUtils } from "primeng/utils";
import { MessageService } from "./../_services/message.service";
import { BookReccuringAppointmentComponent } from "../book-reccuring-appointment/book-reccuring-appointment.component";
import { DialogOverviewExampleDialog } from "../appointments/appointments.component";
import { SendPatientFormsComponent } from "../patient-form-details/send-patient-forms/send-patient-forms.component"

export interface DialogData {
  link: string;
  title: string;
  calendars: any;
  appointmentDetails: any;
}

export interface DialogDataForPatientForm {
  action: string;
  contact: any;
}

@Component({
  selector: "app-contact-appointment-details",
  templateUrl: "./contact-appointment-details.component.html",
  styleUrls: ["./contact-appointment-details.component.scss"],
})
export class ContactAppointmentDetailsComponent implements OnInit, OnDestroy {
  contact_id: string;
  contactDetails: any;
  contactAppointments = [];
  _input: any;
  contact: any;
  acc: any;
  twilio_from: string;
  activities_fetched: boolean = false;
  twilio_credentials: any;
  activities = [];
  dataSource: any = [];
  sessionTime: number = 30000;
  enableCaching: boolean = false;
  noActivities: boolean = false;
  fetchedNote: any;
  notes = [];
  messages = [];
  createdNote: string;
  noteDialogRef: any;
  newNote: any;
  selectedIndex: number = 0;
  apptsSubscription: Subscription;
  sendSmsSubscription: Subscription;
  smsSubscription: Subscription;
  notesSubscription: Subscription;
  patientFormDialogRef: DynamicDialogRef;
  selectedContacts: any = [];
  responses: any = [];
  columns: any = [];
  pageSize: number = 10;
  pageSizeOption: number[] = [10, 20, 50];
  expandedElement: any;
  rows: number = 20;
  first: number = 0;
  datakey: string;
  searchFields: any = [];
  acc_ref: any;
  patientFormSubscription: Subscription;
  showFormDetails: boolean;
  allTask: any;
  constructor(
    private navigationService: NavigationService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private contactService: ContactService,
    private messageService: MessageService,
    private appointmentService: AppointmentService,
    public snackBar: MatSnackBar,
    private alertService: AlertService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private patientFormService: PatientFormService,
    private zone: NgZone,

  ) {
    this.apptsSubscription = appointmentService.appointment$.subscribe(docChange => {
      if (docChange.type == 'modified') {
        let data = docChange.doc.data();
        data.booked_on = data.booked_on.toDate();
        data.appt_start = data.appt_start.toDate();
        data.appt_end = data.appt_end.toDate();
        data['ref'] = docChange.doc.ref;
        let id = this.contactAppointments.findIndex(obj => obj.ref.id == data.ref.id)
        if (id !== -1 && data.contact.id == this.contactDetails.ref.id) {
          this.contactAppointments[id] = data;
        }
        else if (data.contact.id == this.contactDetails.ref.id) {
          this.contactAppointments.unshift(data);
          this.contactAppointments.sort((a, b) => {
            return <any>new Date(b.date) - <any>new Date(a.date);
          });
        }
      }


      this.patientFormSubscription = this.patientFormService.patientForms$.subscribe(
        updatedResponses => {
          /** To emit change detection for prime ng table  used NgZone.run method  */
          this.zone.run(() => {
            if (updatedResponses.type == "modified") {
              let data = this.formatFormResponse(updatedResponses.value.data());
              data['id'] = updatedResponses.value.ref.parent.parent.id + '_' + updatedResponses.value.id;
              data['formRef'] = updatedResponses.value.ref;
              let i = this.dataSource.findIndex(x => x.id == data.id);
              if (i !== -1) {
                data['id'] = this.dataSource[i].id;
                this.dataSource[i] = data;
                this.dataSource = [...this.dataSource];
                console.log("updated form resonse @", i);
              }
              else {
                this.dataSource = [...this.dataSource, data];
                console.log("added new form response");
              }
            }
          });

        });

      FilterUtils["custom-equals"] = (value, filter): boolean => {
        if (filter === undefined || filter === null || filter.trim() === "") {
          return true;
        }

        if (value === undefined || value === null) {
          return false;
        }

        return value.toString() === filter.toString();
      };

      this.acc_ref = this.patientFormService.getCurrentAccountRef();


    });


    this.notesSubscription = contactService.notes$.subscribe((note) => {
      this.notes.unshift(note);
      sessionStorage.setItem(
        "activities" + this.contactDetails.full_phone,
        JSON.stringify({
          creation_timestamp: Date.now(),
          logs: this.activities,
        })
      );
    });

    this.smsSubscription = contactService.sms$.subscribe((sms) => {
      this.messages.unshift(sms);
      console.log(this.messages, sms);
      sessionStorage.setItem(
        "activities" + this.contactDetails.full_phone,
        JSON.stringify({
          creation_timestamp: Date.now(),
          logs: this.activities,
        })
      );
    });

    this.sendSmsSubscription = contactService.sendSMSNotification$.subscribe((sms) => {
      this.messages.unshift(sms);
      console.log(this.messages, sms);
    });

    contactService.phone$.subscribe((phone) => {
      this.activities = [];
      let contactObj = {
        phone: phone,
        acc_sid: this.twilio_credentials.account_sid,
        auth_token: this.twilio_credentials.auth_token,
      };
      this.fetchActivities();
    });

    contactService.sendSMSNotification$.subscribe((smsObject) => {
      this.activities.unshift(smsObject);
    });
  }

  formatFormResponse(formdata: any) {
    let data = formdata;
    // format the submitted form response for display purpose.
    if (data["status"] == "submitted") {
      data["status"] = "completed";
    } else if (data["status"] == "initiated") {
      data["status"] = "not completed";
    }
    if ('contact_ref' in data) {
      this.patientFormService.getcontactDetails(data.contact_ref).then(
        contactInfo => {
          data['contact'] = contactInfo;
        });
    }
    data["submitted"] =
      data.submitted !== undefined
        ? data["submitted"].toDate("MMM d, yyyy")
        : undefined;
    let identifierSec = data.response.filter(
      (section) => section.type == "identifier"
    );
    for (let que of identifierSec[0].questions) {
      if (que.answer_type == "name") {
        data[que.question] = que.answer;
      }
      if (que.answer_type == "email") {
        data['Email'] = que.answer;
      }
    }
    return data;
  }

  ngOnInit() {
    this.contactService.task$.subscribe(data => {
      this.allTask = data;
    })
    this.acc = this.accountService.getCurrentAccDetails();
    //this.currentAcc = true;
    this.twilio_from = this.acc.twilio_from;
    this.route.url.subscribe((url) => {
      this.contact_id = url[1].path;
      if (url.length > 2) {
        if (url[2].path == 'msg') {
          this.selectedIndex = 3;
        }
        else if (url[2].path == "appointment") {
          this.selectedIndex = 1;
        }
        else if (url[2].path == "forms") {
          this.selectedIndex = 5;
          this.showFormDetails = true;
        }
      }
      // else{
      //   this.selectedIndex=0;
      // }
      this.contactService.getContact(this.contact_id).then((contact) => {
        this.contactService.getPhoneNumberLanguage(contact.full_phone).then(resp => {
          this.contactDetails = contact;
          this.contactDetails.targetLanguage = resp ? resp["targetLanguage"] : "en";
          //sub-header
          this.navigationService.updateState(
            new NavigationState(
              true,
              `${this.contactDetails.first_name} ${this.contactDetails.last_name}`,
              "Contacts",
              "/contacts",
              "/contact-appointment-details"
            )
          );


          this.contactService.getAllTask(contact.ref)

          // this.contactService.getAllTask(contact.ref).then(snap => {
          //   snap.docs.map(doc => {
          //     this.allTask.push(doc.data());
          //   })

          // })
          this.appointmentService
            .fetchAppointmentsOfContact(contact.ref)
            .then((appointments) => {
              this.contactAppointments = appointments;
            });
          this.fetchActivities();
        });
      })
    });
    this.getFormInfo();
  }

  changeAppointmentStatus(value) {
    let data = value;
    if (data.action == "completed" || data.action == "no-show") {
      this.updateStatus(data.appt, data.action);
    } else if (data.action == "cancel") {
      this.cancelAppontment(data.appt);
    } else if (data.action == "reschedule") {
      this.rescheduleAppontment(data.appt);
    }
  }

  updateStatus(appointment: any, status: string) {
    this.appointmentService
      .saveAppointmentStatus(appointment.ref, status)
      .then(() => {
        if (status === "completed") {
          appointment.contact.update({ last_visited: appointment.appt_end });
          appointment.status = status;
          this.snackBar.open(
            "The appointment has been marked as 'Completed'.",
            "",
            { duration: 2000 }
          );
          this.appointmentService.updateAppointments(this.contactAppointments);
        } else if (status === "no-show") {
          appointment.status = status;
          this.snackBar.open(
            "The appointment has been marked as 'No-show'.",
            "",
            { duration: 2000 }
          );
          this.appointmentService.updateAppointments(this.contactAppointments);
        }
      });
  }

  cancelAppontment(appointment: any) {
    let cancel_link = `${environment.apptURL}/cancel/${appointment.reschedule_doc}`;
    this.openDialog(cancel_link, "CANCEL APPOINTMENT");
  }
  rescheduleAppontment(appointment: any) {
    let cancel_link = `${environment.apptURL}/reschedule/${appointment.reschedule_doc}`;
    this.openDialog(cancel_link, "RESCHEDULE APPOINTMENT");
  }

  openScheduleApointmentDialog(link: any, title: any) {
    const dialogRef = this.dialog.open(ScheduleNewAppointmentComponent, {
      panelClass: "custom-dialog-container",
      height: "680px",
      width: "1230px",
      maxWidth: "100vw",
      data: { link: link, title: title },
    });

    dialogRef.afterClosed().subscribe((closed) => {
      console.log("closed");
    });
  }

  openDialog(link: any, title: any) {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      panelClass: "custom-dialog-container",
      height: "680px",
      width: "1230px",
      maxWidth: "100vw",
      data: { link: link, title: title },
    });

    dialogRef.afterClosed().subscribe((closed) => {
      console.log("closed");
    });
  }

  openDialogForAcceptRejectPatientForm(contact, action, id): void {
    const dialogRef = this.dialog.open(DialogOverview, {
      width: "250px",
      data: { contact: contact, action: action },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      if (result == "accept") {
        this.acceptForm(contact, id);
      } else if (result == "reject") {
        this.rejectForm(contact, id);
      } else {
        console.log("Canceled the status update");
      }
    });
  }
  acceptForm(form, id) {
    this.patientFormService.acceptPatientForm(this.acc_ref, form.formRef).then(
      snap => {
        console.log('form accepted');
        let contactInfo = {
          'first_name': form['First Name'],
          'last_name': form['Last Name'],
          'email': form['Email']
        };
        this.getContactDetailsForForm(contactInfo, id);
      },
      err => {
        alert("Error : Unable to accept the form. Please Try again later.")
        console.log(err);
      });
  }

  getContactDetailsForForm(contactInfo, id) {
    this.patientFormService.getMatchingContact(contactInfo).then(
      contact => {
        if (Object.keys(contact).length > 0) {
          this.dataSource[id]['contact'] = contact;
        }
      },
      err => {
        console.log(err);
      });
  }

  rejectForm(form, id) {
    this.patientFormService.rejectPatientForm(this.acc_ref, form.formRef).then(
      snap => {
        console.log('form rejected');
      },
      err => {
        alert("Error : Unable to reject the form. Please Try again later.")
        console.log(err);
      });
  }


  downloadPdf(url) {
    var link = window.document.createElement("a");
    link.setAttribute("href", url);
    //link.setAttribute("download", "");
    link.click();
    window.document.removeChild(link);
  }

  async updateContactInformation(value) {
    let data = value;
    // if (value.column == "first_name" || value.column == "last_name" || value.column == "email" || value.column == "phone") {
    //   this.contactService.updateEditedContact(
    //     data.contact,
    //     data.column,
    //     data.value
    //   );
    // }
    // else if ((data.column == "send_text_reminders")) {
    //   this.contactService.saveSendTextReminders(data.value, data.contact);
    // }
    // else if (data.column == 'active') {
    //   this.contactService.updateRowVisibleStatus(data.contact);
    // }
    // else if (data.column == "last_visited") {
    //   this.contactService.updateLastVisitedDate(data.contact, data.value);
    // }
    if (value.column == "birthday") {
      this.contactService.updateBirthDate(value.contact.ref, value.value);
    }
    // else if (value.column == "active") {
    //   this.contactService.updateRowVisibleStatus(value.contact);
    // }
    else if (data.column == "targetLanguage") {
      this.contactService.updatePhoneNumberLanguage(value.value, value.contact);
    }
    else {
      let obj = {};
      obj[value.column] = value.value;
      console.log("obj", obj);
      await value.contact.ref.update(obj);
    }

  }

  saveSendTextReminders(send_text_reminders: any, contact_ref: any) {
    //console.log(contact_);
    this.contactService.saveSendTextReminders(send_text_reminders, contact_ref);
  }

  singleMessage(contact: any) {
    this.openDialogForMessage([contact]);
  }

  updateLastVisited(contact_ref, refLast_visited) {
    this.contactService.updateLastVisitedDate(contact_ref, refLast_visited);
    //this.contactService.updateLastVisitedDate.refLast_visited'last_visited'.format('MMM d, yyyy');
  }

  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
    //this.contactService.updateLastVisitedDate(contact_ref,refLast_visited);
    //setTimeout(() => this._input.nativeElement.focus());
  }

  _closeCalendar(e, contact_ref, refLast_visited) {
    //this.contactService.updateLastVisitedDate(contact_ref,refLast_visited);
    setTimeout(() => this._input.nativeElement.blur());
  }

  updateRowVisible(contact) {
    this.contactService.updateRowVisibleStatus(contact);
  }
  openDialogForMessage(selectedContacts: any) {
    this.alertService.reset("");
    const messagedialogRef = this.dialog.open(CreateContactMessageDialog, {
      height: "600px",
      width: "900px",
      //data: { contact:contact }
      data: { contacts: selectedContacts },
    });
  }


  //notes

  openDialogForNote(selectedContacts: any) {
    const noteDialogRef = this.dialog.open(NotesDialog, {
      height: '300px',
      width: '600px',
      data: { contact: selectedContacts }
    });
  }

  addNote() {
    this.contactService.addNoteForContact(this.contact, this.createdNote);
    this.createdNote = "";
    let snackBarRef = this.snackBar.open('Note added successfully!', '', {
      duration: 3000
    });
    this.noteDialogRef.close();
  }

  updateNote(changedNote: string) {
    this.contactService.updateEditedNote(this.contact.ref, changedNote, this.newNote.ref);
    let snackBarRef = this.snackBar.open('Note updated successfully!', '', {
      duration: 3000
    });
  }

  fetchActivities() {
    this.twilio_credentials = this.accountService.getLinkedAccountCredentials(
      "twilio"
    );
    this.contactService
      .fetchNotesForContact(this.contactDetails.ref)
      .then((notesSnapshot) => {
        notesSnapshot.forEach((note) => {
          this.fetchedNote = note.data();
          this.fetchedNote.id = note.id;
          this.fetchedNote.date = this.fetchedNote.date.toDate().toString();
          this.notes.push(this.fetchedNote);
        });
        this.notes.sort((a, b) => {
          return <any>new Date(b.date) - <any>new Date(a.date);
        });
      });
    if (this.twilio_credentials !== undefined) {
      // let contactObj = {
      //   phone: this.contactDetails.full_phone,
      //   acc_sid: this.twilio_credentials.account_sid,
      //   auth_token: this.twilio_credentials.auth_token,
      // };
      this.messageService.fetchSmsLogs(this.contactDetails.ref.id).then((smsData) => {
        this.messages = smsData.list;
        this.messages.sort((a, b) => {
          return <any>new Date(b.date.toDate()) - <any>new Date(a.date.toDate());
        });
      });
    }
  }

  scheduleNewAppointment() {
    let accName = this.accountService.getCurrentAccDetails().account_slug;
    let link = `${environment.apptURL}/account/${accName}?contact=${this.contact_id}`;
    this.openScheduleApointmentDialog(link, "Schedule New Appointment");
  }

  // updateNote(note){

  //   this.contactService.updateEditedNote(this.contactDetails.ref, note.updatedNote, note.note.id);
  //   //  this.alertService.success("Note updated successfully.");
  //   let snackBarRef = this.snackBar.open('Note updated successfully!', '', {
  //     duration: 3000
  //   });
  // }
  openDialogToSendPatientForm() {
    const dialogRef = this.dialog.open(SendPatientFormsComponent, {
      width: "1500px",
      height: '550px',
      data: {
        dialogTitle: "Send Patient Form",
        screenType: "formSelection",
        type: "sendPatientFormFromContactScreen",
        contactsDetails: [this.contactDetails]
      },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((closed) => {
      // this.selectedContacts = [];
      // this.showSendPatientForm = false;
      // this.selectRow();
    });
    // console.log("firstname:", this.contactDetails.first_name);
    // this.patientFormDialogRef = this.dialogService.open(PatientFormComponent, {
    //   header: 'Patient Forms',
    //   width: "1100px",
    //   contentStyle: { "height": "800px" },
    //   data: { contacts: [this.contactDetails] },
    // });
    // this.patientFormDialogRef.onClose.subscribe((closed) => {
    // this.selectedContacts = [];
    // this.contactSubscription = this.contactService.contacts$.subscribe((contacts) => {
    //   contacts.then((contacts) => {
    //     this.AllContacts = contacts;
    //     this.contacts = this.AllContacts.filter(
    //       (contact) =>
    //         contact["active"] == undefined || contact["active"] == true
    //     );
    //     if (this.contacts.length === 0) {
    //       this.isShow = false;
    //     } else {
    //       this.isShow = true;
    //     }
    //     this.acc = this.accountService.getCurrentAccDetails();
    //   });
    // });

    // if (this.contactSubscription)
    // this.showSendPatientForm = false;
    // });
  }

  openDialogForServeyForm() {
    console.log(this.contactDetails);
    const ref = this.dialogService.open(SurveyFormComponent, {
      data: [this.contactDetails],
      header: 'Servey Form',
      width: "1100px",
      contentStyle: { "height": "800px" },
      showHeader: true,
      closable: true
    })
  }

  openDialogForRecurringAppointment() {
    const dialogRef = this.dialog.open(BookReccuringAppointmentComponent, {
      panelClass: "custom-dialog-container",
      height: "680px",
      width: "1230px",
      maxWidth: "100vw",
      disableClose: true,
      data: { contactId: this.contact_id },
    });

  }

  ngOnDestroy() {
    this.apptsSubscription.unsubscribe();
    this.sendSmsSubscription.unsubscribe();
    this.smsSubscription.unsubscribe();
    this.notesSubscription.unsubscribe();
  }


  async getFormInfo() {
    this.contactService.getFormInfo(this.contact_id).then((querySnapshot) => {
      querySnapshot.forEach((response) => {
        let res = response.data();
        res.id = response.id
        res.date = res.initiated.toDate();
        this.responses.push(res);
      })
      console.log(this.responses);
      this.responses.sort((a, b) => {
        return b.date - a.date;
      })
    })
  }

  tabClick(tab) {
    console.log(tab);
    if (tab.index == 4)
      this.showFormDetails = true;
    else
      this.showFormDetails = false;
  }
}

@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "accept-rejectForm.html",
})
export class DialogOverview {
  action: string;
  contactDetails: any;
  status: string;

  constructor(
    public dialogRef: MatDialogRef<DialogOverview>,
    private patientFormService: PatientFormService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataForPatientForm
  ) {
    this.action = data["action"];
    this.contactDetails = data["contact"];
  }

  onNoClick(): void {
    this.dialogRef.close("no");
  }
}
