import { Component, OnInit } from "@angular/core";
import { AfService, AccountService } from "../_services/index";
import { User } from "../_models/interfaces";
import { MENU_ARRAY } from "../_constants/default-menu";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AlertService } from "../_services/index";
import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";

@Component({
  selector: "app-all-accounts",
  templateUrl: "./all-accounts.component.html",
  styleUrls: ["./all-accounts.component.scss"],
})
export class AllAccountsComponent implements OnInit {
  user: User;
  accountInfo: any = [];
  displayedColumns: any = ["name", "url"];
  dataSource = new MatTableDataSource();
  accountDetails: any;
  acc: number;
  accounts: any[] = [];
  name: string;
  url: string;
  isOpened: boolean = true;
  accountId: any;
  flag: boolean = false;

  constructor(
    private navigationService: NavigationService,

    private accountService: AccountService,
    public afService: AfService,
    public router: Router,
    private alertService: AlertService
  ) {
    this.accountService.accChange$.subscribe((accChange) => {
      this.accountDetails = this.accountService.getCurrentAccDetails();
      if (this.accountDetails.menu === undefined) {
        this.accountDetails.menu = MENU_ARRAY;
      }
    });
  }

  ngOnInit() {
    //sub-header
    this.navigationService.updateState(
      new NavigationState(false, "Accounts", "", "#", "/all-accounts")
    );
    // ------------------
    this.isOpened = false;
    this.accountInfo = this.accountService.getAllAccountInfo();
    this.dataSource = new MatTableDataSource(this.accountInfo);
    this.flag = true;

    this.afService.user$.subscribe((user) => {
      this.user = user;
      if (user) {
        this.accounts = this.accountService.getAccNames();
        this.user.accounts = this.accountService.getAccs();
        this.acc = 0;
        if (sessionStorage.getItem("current_acc_id")) {
          this.acc = user.accounts.findIndex(
            (x) => x === sessionStorage.getItem("current_acc_id")
          );
        } else {
          this.acc = 0;
        }
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  accChange(acc_id: string) {
    this.accountId = acc_id;
    this.acc = this.user.accounts.findIndex((x) => x === acc_id);
    this.accountService.updateAcc(acc_id);
    this.router.navigateByUrl("/websites");
  }

  hideForm() {
    if (this.isOpened) {
      this.isOpened = false;
    } else {
      this.isOpened = !this.isOpened;
    }
  }

  addAccount(formData) {
    let result = this.accountService.saveNewAccount(
      formData.value.name,
      formData.value.url
    );
    result.then((data) => {
      if (data > 0) {
        this.alertService.error(
          "Account is already created for this website url."
        );
        return;
      } else {
        this.router.navigateByUrl("/add-account");
      }
    });
  }
}
