<!-- <mat-nav-list>
  <ng-container  *ngFor="let menuItem of menuList; index as i" >
    <mat-list-item [routerLink]="menuItem.routerLink" routerLinkActive="active-list-item" class="parent" >
      <mat-icon mat-list-icon  >{{menuItem.icon}}</mat-icon>
      <span class="full-width" >{{menuItem.label}}</span>
    </mat-list-item>
    <div class="submenu" *ngFor="let subItem of menuItem.submenu">
      <mat-list style="margin-left:10px;">
          <mat-list-item [routerLink]="subItem.routerLink"  routerLinkActive="active-list-item" >
            <span class="full-width submenuItem" >{{ subItem.label }}</span>
            <mat-icon style="float: right;" mat-list-icon  >{{subItem.icon}}</mat-icon>
          </mat-list-item>
          <div class="submenu" *ngFor="let item of subItem.submenu" >
            <mat-list-item [routerLink]="item.routerLink"  routerLinkActive="active-list-item" >
              <span class="full-width submenuItem" >{{ item.label }}</span>
              <mat-icon style="float: right;" mat-list-icon >{{item.icon}}</mat-icon>
            </mat-list-item>
          </div>
        </mat-list>
      </div>
</ng-container> -->

<mat-nav-list>
  <ng-container *ngFor="let menuItem of menuList; index as i">
    <!-- <mat-list-item *ngIf="menuItem.admin?user?.roles.admin:true" [routerLink]="menuItem.routerLink" -->
    <mat-list-item *ngIf="menuItem.display!=undefined?menuItem.display:true" [routerLink]="menuItem.routerLink"
      routerLinkActive="active-list-item" [ngClass]="{'disabledItem':menuItem.disabled}" class="parent"
      (click)="toggle(menuItem)">

      <span class="full-width">{{menuItem.label}}</span>
      <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
        *ngIf="!menuItem.isExpanded && menuItem.submenu!=undefined">expand_more</mat-icon>
      <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
        *ngIf="menuItem.isExpanded && menuItem.submenu!=undefined">expand_less</mat-icon>
      <mat-icon mat-list-icon [ngClass]="{'disabledItem':menuItem.disabled}">{{menuItem.icon}}</mat-icon>
    </mat-list-item>
    <div class="submenu" *ngFor="let subItem of menuItem.submenu" [ngClass]="{'expanded' : menuItem.isExpanded}">
      <mat-list style="margin-left:10px;">
        <mat-list-item *ngIf="subItem.display!=undefined?subItem.display:true" [routerLink]="subItem.routerLink" routerLinkActive="active-list-item" (click)="toggle(subItem)"
          [ngClass]="{'disabledItem':subItem.disabled}">
          <span class="full-width submenuItem">{{ subItem.label }}</span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
            *ngIf="subItem.isExpanded &&subItem.submenu!=undefined">expand_less</mat-icon>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}"
            *ngIf="!subItem.isExpanded  &&subItem.submenu!=undefined">expand_more</mat-icon>
          <mat-icon style="float: right;" mat-list-icon [ngClass]="{'disabledItem':subItem.disabled}">{{subItem.icon}}
          </mat-icon>
        </mat-list-item>
        <div class="submenu" *ngFor="let item of subItem.submenu" [ngClass]="{'expanded' : subItem.isExpanded}">
          <mat-list-item *ngIf="item.display!=undefined?item.display:true" [routerLink]="item.routerLink" (click)="toggle(item)" routerLinkActive="active-list-item"
            [ngClass]="{'disabledItem':item.disabled}">
            <span class="full-width submenuItem">{{ item.label }}</span>
            <mat-icon style="float: right;" mat-list-icon [ngClass]="{'disabledItem':item.disabled}">{{item.icon}}
            </mat-icon>
          </mat-list-item>
        </div>
      </mat-list>
    </div>
  </ng-container>
</mat-nav-list>
