
<div class="statistic ui-g" [style.background-color]="colour">

  <div class="icon ui-g-5">
    <i class="fa {{ icon }}" *ngIf="icon"></i>
  </div>

  <div class="data ui-g-12">

    <div class="value">
      <b style="color: black">{{ value }}</b>
    </div>

    <div class="label" *ngIf="label">
      <b style="color: black">{{ label }}</b>
    </div>

  </div>


</div>
