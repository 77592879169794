import { Injectable } from '@angular/core';
import { AccountService } from "./account.service";
import firebase from "firebase/app";
// import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AlertService } from './alert.service';
import { Subject, Subscriber, Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { type } from 'os';
import * as moment from 'moment-timezone';
import { rejects } from 'assert';


@Injectable({
  providedIn: 'root'
})
export class SmsCampaignService {

  accId: string;
  smsCampaignConfigSet: boolean = false;
  smsCampaignConfiguration: any;

  private smsCampaignSource = new Subject<any>();
  smsCampaignChange$ = this.smsCampaignSource.asObservable();
  campaignObservable: Subscription;

  private selectedCampaignSource = new Subject<any>();
  selectedCampaign$ = this.selectedCampaignSource.asObservable();

  constructor(
    private accService: AccountService,
    private http: HttpClient,
    private afs: AngularFirestore
  ) {
    this.accId = sessionStorage.getItem('current_acc_id');
    this.checkSmsConfigSet();
    let campaingCollection = afs.collection("accounts").doc(this.accId).collection("campaigns");
    this.campaignObservable = campaingCollection.valueChanges().subscribe(
      change => {
        console.log(change);
        this.getSmsCampaigns().then(camps => {
          this.smsCampaignSource.next(camps);
        })
      })
    this.accService.accChange$.subscribe(acc => {
      let campaingCollection = afs.collection("accounts").doc(this.accId).collection("campaigns");
      this.accId = sessionStorage.getItem('current_acc_id');
      this.campaignObservable.unsubscribe();
      this.checkSmsConfigSet();
      this.campaignObservable = campaingCollection.valueChanges().subscribe(
        change => {
          this.getSmsCampaigns().then(camps => {
            this.smsCampaignSource.next(camps);
          })
        })
    });
  }

  async checkSmsConfigSet() {
    console.log(this.accId);
    let confSnap = await firebase.firestore().collection("accounts").doc(this.accId).collection("linked_accounts").where("type", "==", "twilio").get();
    if (confSnap.empty) {
      this.smsCampaignConfigSet = false;
      // return this.smsCampaignConfigSet;
    }
    else {
      this.smsCampaignConfigSet = true;
      this.smsCampaignConfiguration = confSnap.docs[0].data();
      if (!this.smsCampaignConfiguration.hasOwnProperty('twilio_from')) {
        let accSnap = await (await firebase.firestore().collection("accounts").doc(this.accId).get()).data();
        this.smsCampaignConfiguration['twilio_from'] = accSnap['twilio_from'];
        // return this.smsCampaignConfigSet;
      }
      else {
        // return this.smsCampaignConfigSet;
      }
    }
  }


  getSmsConfigurations() {
    return this.smsCampaignConfiguration;
  }

  async getSmsCampaigns() {
    let docSnap = await firebase.firestore().collection("accounts").doc(this.accId).collection("campaigns").where("type", "==", "SMS_CAMPAIGN").orderBy('createdOn', 'desc').get()
    let campaignList = [];
    if (!docSnap.empty) {
      console.log("In");
      docSnap.forEach(doc => {
        let data = doc.data();
        data['campaignRef'] = doc.ref;
        data['campaignDocId'] = doc.id;
        // if (data['filters']['name'] == 'birthday') {
        //   data['filters']['birthday']['start'] = data['filters']['birthday']['start'] != null ? moment(data['filters']['birthday']['start'].toDate()) : null;
        //   data['filters']['birthday']['end'] = data['filters']['birthday']['end'] != null ? moment(data['filters']['birthday']['end'].toDate()) : null;
        // }
        // else if (data['filters']['name'] == 'lastVisited') {
        //   data['filters']['lastVisited']['start'] = data['filters']['lastVisited']['start'] != null ? moment(data['filters']['lastVisited']['start'].toDate()) : null;
        //   data['filters']['lastVisited']['end'] = data['filters']['lastVisited']['end'] != null ? moment(data['filters']['lastVisited']['end'].toDate()) : null;
        // }
        data['createdOn'] = data['createdOn'].toDate();
        // return data;
        campaignList.push(data);
      });
    }
    return campaignList;
  }

  async runSmsCampaign(campaingInfo) {
    console.log("running campaign", campaingInfo);
    await firebase.firestore().collection('accounts').doc(this.accId).collection('campaigns').doc(campaingInfo.id).update({ 'runOn': moment().toDate(), 'status': 'RUNNING' });
    let data = JSON.stringify({
      accId: this.accId,
      campaignId: campaingInfo.id
    });
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    let cloudFunctionName = campaingInfo.methodType == 'SMS' ? 'runSmsCampaign' : 'sendEmailCampaigns'
    console.log(data, `${environment.cloudFunctionServerUrl}/runSmsCampaign`);
    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/${cloudFunctionName}`, data, header).toPromise();

  }

  async addNewSmsCampaign(data) {
    let name = data['campaignName'].toLowerCase().trim();
    let campaignInfo = {
      'name': name,
      'createdOn': moment().toDate(),
      'type': 'SMS_CAMPAIGN',
      'runOn': null,
      'status': 'DRAFT',
      'totalContacts': data['contacts'].length
    }

    if (data['methodType'] == 'SMS') {
      campaignInfo['methodType'] = 'SMS';
      campaignInfo['smsBody'] = data['smsBody']
    }
    if (data['methodType'] == 'EMAIL') {
      campaignInfo['methodType'] = 'EMAIL';
      campaignInfo['emailBody'] = data['emailBody'];
      campaignInfo['subject'] = data['subject'];
    }


    let campaignSnap = await firebase.firestore().collection('accounts').doc(this.accId).collection('campaigns').where("name", "==", name).where('createdOn', '==', moment().toDate()).get();
    return new Promise(async (resolve, reject) => {
      if (campaignSnap.empty) {
        try {
          let campaignDocSnap = await firebase.firestore().collection('accounts').doc(this.accId).collection('campaigns').add(campaignInfo);
          const batch = firebase.firestore().batch();
          let batchCnt = 0;
          await Promise.all(data['contacts'].map(contact => {
            // data['contacts'].forEach(contact => {
            let contactInfo = {
              'email': contact['email'] != undefined ? contact['email'] : '',
              'firstName': contact['first_name'] != undefined ? contact['first_name'] : '',
              'lastName': contact['last_name'] != undefined ? contact['last_name'] : '',
              'fullPhone': contact['full_phone'] != undefined ? contact['full_phone'] : "",
              'createdOn': moment().toDate(),
              'status': contact['supress'] == true ? 'Suppressed' : 'NOT_SENT',
              'campaignRef': campaignDocSnap,
              'contactRef': contact['ref'],
              'supress': contact['supress'] == true ? true : false
            }
            if (data['methodType'] == 'SMS') {
              contactInfo['methodType'] = 'SMS';
              contactInfo['msg'] = data['smsBody']
            }
            if (data['methodType'] == 'EMAIL') {
              contactInfo['methodType'] = 'EMAIL';
              contactInfo['emailBody'] = data['emailBody'];
              contactInfo['subject'] = data['subject'];
            }
            const docRef = firebase.firestore().collection('accounts').doc(this.accId).collection('campaignContacts').doc();
            batch.set(docRef, contactInfo);
            batchCnt = batchCnt + 1;
            if (batchCnt >= 499) {
              batch.commit();
              batchCnt = 0;
            }
          }));
          if (batchCnt > 0) {
            await batch.commit();
          }
          resolve({ 'campaignId': campaignDocSnap.id, 'methodType': data['methodType'], 'status': 'Ok' });
        }
        catch (err) {
          console.log(err.message);
          reject({ error: "Error while adding campaign" });
        }
      }
      else {
        reject({ 'error': "Campaign already exist" });
      }
    });
  }

  async updateSmsCampaign(data) {
    let name = data['campaignName'].toLowerCase().trim();
    let campaignInfo = {
      'name': name,
      'type': 'SMS_CAMPAIGN',
      'runOn': null,
      'status': 'DRAFT',
      'totalContacts': data['contacts'].length
    }
    if (data['methodType'] == 'SMS') {
      campaignInfo['methodType'] = 'SMS';
      campaignInfo['smsBody'] = data['smsBody']
    }
    if (data['methodType'] == 'EMAIL') {
      campaignInfo['methodType'] = 'EMAIL';
      campaignInfo['emailBody'] = data['emailBody'];
      campaignInfo['subject'] = data['subject']
    }
    return new Promise(async (resolve, reject) => {
      let campaignSnap = await firebase.firestore().collection('accounts').doc(this.accId).collection('campaigns').where("name", "==", data['campaignName'].toLowerCase()).where('createdOn', '==', moment().toDate()).get();
      console.log(campaignSnap)
      if (campaignSnap.empty || (!campaignSnap.empty && campaignSnap.docs[0].id == data['campaignDocId'])) {
        let campRef = firebase.firestore().collection('accounts').doc(this.accId).collection('campaigns').doc(data['campaignDocId']);
        console.log(data['campaignDocId'], typeof (campRef));
        let campaignDocSnap = await campRef.update(campaignInfo);
        try {
          await firebase.firestore().collection('accounts').doc(this.accId).collection('campaignContacts').where("campaignRef", "==", campRef).get().then(docs => {
            docs.forEach(doc => {
              doc.ref.delete();
            });
          });
          const batch = firebase.firestore().batch();
          let batchCnt = 0;
          await Promise.all(data['contacts'].map(contact => {
            let contactInfo = {
              'email': contact['email'] != undefined ? contact['email'] : '',
              'firstName': contact['firstName'] != undefined ? contact['firstName'] : '',
              'lastName': contact['firstName'] != undefined ? contact['firstName'] : '',
              'fullPhone': contact['fullPhone'] != undefined ? contact['fullPhone'] : "",
              'createdOn': moment().toDate(),
              'status': contact['supress'] == true ? 'Suppressed' : 'NOT_SENT',
              'campaignRef': campRef,
              'contactRef': contact['ref'] != undefined ? contact['ref'] : contact['contactRef'],
              'supress': contact['supress'] == true ? true : false
            }

            if (data['methodType'] == 'SMS') {
              contactInfo['methodType'] = 'SMS';
              contactInfo['msg'] = data['smsBody']
            }
            if (data['methodType'] == 'EMAIL') {
              contactInfo['methodType'] = 'EMAIL';
              contactInfo['emailBody'] = data['emailBody'];
              contactInfo['subject'] = data['subject']
            }
            const docRef = firebase.firestore().collection('accounts').doc(this.accId).collection('campaignContacts').doc();
            batch.set(docRef, contactInfo);
            batchCnt = batchCnt + 1;
            if (batchCnt >= 499) {
              batch.commit();
              batchCnt = 0;
            }

          }));
          if (batchCnt > 0) {
            await batch.commit();
          }
          resolve({ 'campaignId': campRef.id, 'status': 'Ok' });
        }
        catch (err) {
          console.log(err);
          // this.alertService.error('Unable to add campaign please try after some time');
          reject(err);
        }
      }
      else {
        reject({ error: "Another campaign already exist with same name" });
      }
    });
  }

  async getCampaignContacts(campaignRef) {
    try {
      let contactSnap = await firebase.firestore().collection('accounts').doc(this.accId).collection("campaignContacts")
        .where("campaignRef", "==", campaignRef).get()
      if (contactSnap.empty) {
        return [];
      }
      else {
        return contactSnap.docs.map(x => {
          let data = x.data();
          return data;
        });
      }
    }
    catch (err) {
      console.log(err);
      return err;
    }

  }


  async updateTwilioConfigurations(formVal) {
    let accId = sessionStorage.getItem('current_acc_id');
    let accSnap = firebase.firestore().collection('accounts').doc(accId);
    let accDetails = (await accSnap.get()).data();
    // let twilioNo =formVal.code+ formVal.twilioNo;
    console.log(formVal['twilioNo']);
    let docSnap = await accSnap.collection('linked_accounts').where("type", '==', 'twilio').get();
    let data = {
      'account_sid': formVal['accSid'],
      'auth_token': formVal['authToken'],
      'notify_service_sid': formVal['notifyServiceId'],
      'twilio_from': formVal['twilioNo']
    };
    if (docSnap.empty) {
      data['type'] = 'twilio';
      data['created_on'] = moment().toDate();
      await accSnap.update({ 'twilio_from': formVal['twilioNo'] });
      return await accSnap.collection('linked_accounts').doc('Twilio_accounts').set(data);
    }
    else {
      data['updated_on'] = moment().toDate();
      await accSnap.update({ 'twilio_from': formVal['twilioNo'] });
      return await docSnap.docs[0].ref.update(data);
    }
  }

  async scheduleCampaign(campaignInfo, scheduleInfo) {
    let accId = sessionStorage.getItem('current_acc_id');
    let accInfo = await (await firebase.firestore().collection("accounts").doc(accId).get()).data()
    let timezone = accInfo['timezone'] ? accInfo['timezone'] : "America/New_York";
    let scheduleDate = moment(`${scheduleInfo.date.toLocaleDateString()} ${scheduleInfo.time.toLocaleTimeString()}`).tz(timezone, true).toDate();
    let obj = { 'scheduleTime': scheduleDate, status: "SCHEDULED" };
    return new Promise((resolve, reject) => {
      firebase.firestore().collection("accounts").doc(accId).collection("campaigns").doc(campaignInfo.campaignId).update(obj).then(resp => {
        let reqData = JSON.stringify({
          accId: accId,
          campaignId: campaignInfo.campaignId,
          action: 'schedule',
          methodType: campaignInfo.methodType
        });
        let header = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          })
        };
        this.http.post<any>(`${environment.cloudFunctionServerUrl}/addSmsCampaignTask`, reqData, header).subscribe(resp => {
          console.log(resp);
          resolve(resp);
        },
          err => {
            console.log(err);
            reject(err);
          });
      },
        err => {
          console.log("error while updating schedule time", err);
          reject(err);
        });
    });
  }

  async cancleScheduledCampaign(campaignId) {
    console.log(campaignId);
    let accId = sessionStorage.getItem('current_acc_id')
    let reqData = JSON.stringify({
      accId: accId,
      campaignId: campaignId,
      action: 'cancel'
    });
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<any>(`${environment.cloudFunctionServerUrl}/addSmsCampaignTask`, reqData, header).toPromise();
    //   console.log(resp);
    //   let campSnap = await firebase.firestore().collection("accounts").doc(accId).collection("campaigns").doc(campaignId).get();
    //   let campaignRef = campSnap.ref
    //   let campData = campSnap.data();
    //   campData['contacts'] =await  this.getCampaignContacts(campaignRef);
    //   return(campData);
    // },
    // err=>{
    //   return(err);
    // })


  }


  async getMessegeLibrary() {
    let messegeLibrarySnap = await firebase.firestore().collection('generalSettings').where('type', '==', 'MESSEGE_LIBRARY').get()
    let messegeLibrary = messegeLibrarySnap.docs[0].data()
    return messegeLibrary['messegeLibrary']
  }

  async getAppointmentsByDate(date) {
    let contacts = [];

    const appointmentsSnapshot = await firebase.firestore().collection("accounts").doc(this.accId).collection('appointments').where('date', '==', date).where('status', '==', 'booked').get();
    await Promise.all(appointmentsSnapshot.docs.map(async element => {
      let temp = element.data();
      if (temp.contact) {
        let conatctDataSnap = await temp.contact.get();
        let conactData = conatctDataSnap.data()
        contacts.push({
          email: conactData.email,
          first_name: conactData.first_name,
          last_name: conactData.last_name,
          full_phone: conactData.full_phone,
          ref: temp.contact
        })
      }
    }))
    return contacts
  }


  async getAllPatientsOfTwoYears() {
    let contacts = []
    let twoYearsAgoDate = moment().subtract(2, 'years')
    let patientsSnapshot = await firebase.firestore().collection("accounts").doc(this.accId).collection('contacts').where('last_visited', '>=', twoYearsAgoDate.toDate()).where('active', '==', true).get();

    await Promise.all(patientsSnapshot.docs.map(async element => {
      let conactData = element.data();
      if (conactData.full_phone) {
        contacts.push({
          email: conactData.email,
          first_name: conactData.first_name,
          last_name: conactData.last_name,
          full_phone: conactData.full_phone,
          ref: element.ref
        })
      }
    }));
    return contacts
  }

  async getInsuredPatients() {
    let contacts = []
    let twoYearsAgoDate = moment().subtract(2, 'years')
    let patientsSnapshot = await firebase.firestore().collection("accounts").doc(this.accId).collection('contacts').where('last_visited', '>=', twoYearsAgoDate.toDate()).get();

    await Promise.all(patientsSnapshot.docs.map(async element => {
      let conactData = element.data();
      if (conactData.primaryInsuranceCompanyId != undefined && conactData.primaryInsuranceCompanyId != '') {
        contacts.push({
          email: conactData.email,
          first_name: conactData.first_name,
          last_name: conactData.last_name,
          full_phone: conactData.full_phone,
          ref: element.ref
        })
      }
    }));

    return contacts
  }

  async geUnconfirmedPatientsByDate(date) {

    let contacts = [];
    const appointmentsSnapshot = await firebase.firestore().collection("accounts").doc(this.accId).collection('appointments').where('date', '==', date).where('pmsStatus', '==', 'Unconfirmed').get();
    await Promise.all(appointmentsSnapshot.docs.map(async element => {
      let temp = element.data();
      if (temp.contact) {
        let conatctDataSnap = await temp.contact.get();
        let conactData = conatctDataSnap.data()
        contacts.push({
          email: conactData.email,
          first_name: conactData.first_name,
          last_name: conactData.last_name,
          full_phone: conactData.full_phone,
          ref: temp.contact
        })
      }
    }))
    return contacts
  }

  async getQuicklist() {
    let quickList = []
    const quicklistSnapshot = await firebase.firestore().collection("accounts").doc(this.accId).collection('quicklist').orderBy('index', 'asc').get()
    await Promise.all(quicklistSnapshot.docs.map(async element => {
      let quickListData = element.data();
      quickList.push(quickListData)
    }));
    return quickList
  }

}
