import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AfService, AlertService } from '../_services/index';
import { tap, map, take } from 'rxjs/operators';


@Injectable()
export class SubscriberGuard implements CanActivate {
  constructor(
    public af: AfService,
    private router:Router,
    private alertService:AlertService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   return this.af.user$.pipe(
    take(1),
    map(user =>
      user && user.roles.subscriber ? true : false),
    tap(isSubscriber => {
      if(!isSubscriber){
        console.error("Access Denied - Subscribers only allowed");
        this.alertService.error('You must login first', true);
       this.router.navigateByUrl('/login');
      }
    })
  );
  }
}
