import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AlertService } from "../_services/index";
import { AngularFireAuth } from "angularfire2/auth";

@Component({
  moduleId: module.id.toString(),
  templateUrl: "reset-password.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  model: any = {};
  loading = false;
  code: string;
  submitted = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    public afAuth: AngularFireAuth
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (typeof params["code"] === "string") {
        this.code = params["code"];
        this.resetPassword();
      } else {
        //this.router.navigateByUrl("/forgot-password");
        this.router.navigate(["forgot-password"]);
      }
    });
  }

  resetPassword() {
    this.loading = true;
    if (
      this.model.newPassword === this.model.verifyPassword &&
      this.model.newPassword !== undefined
    ) {
      this.afAuth.auth
        .confirmPasswordReset(this.code, this.model.newPassword)
        .then(
          () => {
            this.alertService.success(
              "Your password has been reset successfully.",
              true
            );
            this.router.navigateByUrl("/");
          },
          (error) => {
            switch (error.code) {
              case "auth/expired-action-code":
                this.alertService.error("The password reset link has expired.");
                break;
              case "auth/invalid-action-code":
                this.alertService.error(
                  "The password reset code is either invalid or has already been used."
                );
                break;
              case "auth/user-disabled":
                this.alertService.error("Your user account is disabled.");
                break;
              case "auth/user-not-found":
                this.alertService.error("Your user account has been deleted.");
                break;
              case "auth/weak-password":
                this.alertService.error(
                  "You have entered a weak password. Please try again with a stronger password"
                );
                break;
              default:
                this.alertService.error("An unknown error has occurred.");
                break;
            }
          }
        );
    } else if (this.model.newPassword !== undefined) {
      this.alertService.error(
        "The passwords did not match. Please re-enter them."
      );
    }
  }
}
