/*
 * Legacy component - unused
 */

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TipsService, AccountService } from '../../_services/index';

@Component({
  selector: 'app-create-tip',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
   acc: any;

  selectedValue: string;
  //viewValue:any;
  tip:any = {
    id: '',
    type: '',
    objective:'',
    channel: '',
    icon:'',
    //age:'',
    title: '',
    short_desc: '',
    long_desc: '',
    image1: '',
    image2:'',
    image3:'',
    image4:'',
    image5:'',
    //image:[],
    budget: '',
    target_gender:'',
    target_age:'',
    target_location:'',
    target_notes:''
};

  error: any;

  channels = [
    {value: 'facebook', viewValue: 'Facebook'},
    {value: 'google', viewValue: 'Google'},
  ];

  genders = [
    {value: 'male', viewValue: 'Male'},
    {value: 'female', viewValue: 'Female'},
    {value: 'both', viewValue: 'Both'},
  ];


  age_groups = [
    {value: '20-40', viewValue: '20-40'},
    {value: '40-60', viewValue: '40-60'},
    {value: '40-60', viewValue: '40-60'},
    {value: '60-80', viewValue: '60-80'},
    {value: '80+', viewValue: '80+'}
  ];

constructor(
    private tipsService:TipsService,
    private accountService: AccountService,
  ) {
}


AddTip(){
  this.tip.status='Active';
    if(this.tip.channel=='facebook')
    {
      this.tip.icon='fa fa-facebook';
    }
    else{
      this.tip.icon='fa fa-google';
    }
  this.tip.timestamp=Date.now();
 this.tipsService.addTip(this.tip, this.acc.name);

  this.tip = {
    id: '',
    type: '',
    objective:'',
    channel: '',
    icon:'',
    //age:'',
    title: '',
    short_desc: '',
    long_desc: '',
    image1: '',
    image2:'',
    image3:'',
    image4:'',
    image5:'',
    budget: '',
    target_gender:'',
    target_age:'',
    target_location:'',
    target_notes:''
};
}

ngOnInit() {
  this.acc = this.accountService.getCurrentAcc();
}
}
