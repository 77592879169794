import { Component, Input, OnInit,OnChanges } from '@angular/core';

@Component({
  selector: 'app-track-local-ranks',
  templateUrl: './track-local-ranks.component.html',
  styleUrls: ['./track-local-ranks.component.scss']
})
export class TrackLocalRanksComponent implements OnInit,OnChanges {
  @Input() prevRankAnalysis :any;
  @Input() loading:boolean;
cols=[];

  constructor() { }

  ngOnChanges(){

    // console.log(this.prevRankAnalysis);
    if(this.prevRankAnalysis.length > 0){
      this.cols=[];
      this.prevRankAnalysis[0]['previousRanks'].forEach(element => {
        this.cols.push(element['date'].toDate());
      });
  }
  }

  ngOnInit(): void {
  }

  getTooltipInfo(data){
    // console.log(data);
    return `SV: ${data?.search_volume?.search_volume_total}\nCPC: ${Number(data?.search_volume?.cpc_total).toFixed(2)}\nCmpetition:${Number(data?.search_volume?.competition_total).toFixed(2)}`
  }
}
