import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-top-impression-loser',
  templateUrl: './top-impression-loser.component.html',
  styleUrls: ['./top-impression-loser.component.scss']
})
export class TopImpressionLoserComponent implements OnInit {
  filterColumns: any = [];
  @Input() data: any;
  @Input() dataSourceLoss: any[];
  enteredValue: string = "01.00";
  impLoss: any[];
  dataSource: any = [];
  displayedColumns: any[] = [
    { header: "Page", value: "page", class: "page" },
    { header: "Delta Impression ", value: "deltaImpressionDiff", class: "mid" },
    { header: "Delta Impression %", value: "deltaImpressionDiffPercentage", class: "mid" },
    { header: "Delta Share %", value: "deltaImpressionSharePercentage", class: "mid" },
    { header: "Last Impression Share %", value: "lastTotalImpressionShare", class: "mid" },
    { header: "PreviousImpression Share %", value: "prevTotalImpressionShare", class: "mid" },
    { header: "Delta Position Difference", value: "deltaPositionDiff", class: "mid" },
    { header: "Last 7 Days Clicks ", value: "lastWeekClickCount", class: "mid" },
    { header: "Previous 7 Days Clicks ", value: "prevWeekClickCount", class: "mid" },
    { header: "Last 7 Days Impressions ", value: "lastWeekImpressionsCount", class: "mid" },
    { header: "Previous 7 Days Impressions ", value: "prevWeekImpressionsCount", class: "mid" },
    { header: "Last 7 Days CTR ", value: "lastWeekCtrCount", class: "mid" },
    { header: "Previous 7 Days CTR ", value: "prevWeekCtrCount", class: "mid" },
    { header: "Last 7 Days Position ", value: "lastWeekPositionCount", class: "mid" },
    { header: "Previous 7 Days Position ", value: "prevWeekPositionCount", class: "mid" },

  ];
  disableSortColumns: string[] = ['page', 'deltaImpressionDiff', 'deltaImpressionDiffPercentage', 'deltaImpressionSharePercentage',
    'lastTotalImpressionShare', 'prevTotalImpressionShare', 'deltaPositionDiff', 'lastWeekClickCount', 'prevWeekClickCount', 'lastWeekImpressionsCount',
    'prevWeekImpressionsCount', 'lastWeekCtrCount', 'prevWeekCtrCount', 'lastWeekPositionCount', 'prevWeekPositionCount'];

  constructor() { }

  ngOnInit() {
    this.filterColumns = ['page', 'deltaImpressionDiff', 'deltaImpressionDiffPercentage', 'deltaImpressionSharePercentage',
      'lastTotalImpressionShare', 'prevTotalImpressionShare', 'deltaPositionDiff', 'lastWeekClickCount', 'prevWeekClickCount', 'lastWeekImpressionsCount',
      'prevWeekImpressionsCount', 'lastWeekCtrCount', 'prevWeekCtrCount', 'lastWeekPositionCount', 'prevWeekPositionCount'];
    this.dataSource = this.dataSourceLoss;
    console.log(this.dataSource);

  }

  updateTable() {
    let gainedImpressionAnalysis = []
    let value = this.enteredValue;
    value = value.replace("%", "");
    this.data.impressionAnalysis.forEach(element => {
      if (element.deltaImpressionDiff < 0 && element.deltaImpressionSharePercentage > parseFloat(value)) {
        gainedImpressionAnalysis.push(element)
      }
    });
    gainedImpressionAnalysis.sort((a, b) => {
      return a.deltaImpressionDiff - b.deltaImpressionDiff;
    })
    this.dataSourceLoss = gainedImpressionAnalysis;
  }

}
