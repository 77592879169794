import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { NavigationState } from '../_models/index';
import { NavigationService, EmailReportService, AccountService } from '../_services/index';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-email-campaign',
  templateUrl: './email-campaign.component.html',
  styleUrls: ['./email-campaign.component.scss']
})
export class EmailCampaignComponent implements OnInit {
  campaign: any = {};
  url: UrlSegment[];
  isReady: boolean;
  acc: any;
  email_preview_url: any;
  countries: any;
  links: any;

  constructor(
    private navigationService: NavigationService,
    private emailReportService: EmailReportService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    this.accountService.accChange$.subscribe(
      acc => {
        this.router.navigateByUrl('/websites');
        /*
        let state = navigationService.getCurrentNavigationState();
        let regExp = /^\/email-campaign/;
        if(regExp.test(state.url)) {
          this.router.navigateByUrl('/email');
        }
        */
      });
  }

  ngOnInit() {
    this.acc = this.accountService.getCurrentAccDetails();

    this.route.url.subscribe(
      url => {
        this.url = url;
        this.campaign = this.emailReportService.getCampaignDetails(this.url[1].path, this.acc.name);
        this.navigationService.updateState(
          new NavigationState(true, this.campaign.name, 'Email', '/email', '/email-campaign/' + this.url[1].path)
        );
        this.isReady = true;
      });
  }
}
