<div class="container-fluid view">
  <div class="table-responsive table-bordered">
    <table class="table">
      <tr>
        <th>Domain</th>
        <th>Visibility</th>
        <th>Estimated traffic</th>
        <th>Estimated traffic channel</th>
        <th>Top 3</th>
        <th>Top 3(difference)</th>
        <th>Top 3(entered)</th>
        <th>Top 3(left)</th>
        <th>Top 3(improved)</th>
        <th>Top 3(declined)</th>
        <th>Top 10</th>
        <th>Top 10(difference)</th>
        <th>Top 10(entered)</th>
        <th>Top 10(left)</th>
        <th>Top 10(improved)</th>
        <th>Top 10(declined)</th>
        <th>Top 20</th>
        <th>Top 20(difference)</th>
        <th>Top 20(entered)</th>
        <th>Top 20(left)</th>
        <th>Top 20(improved)</th>
        <th>Top 20(declined)</th>
        <th>All</th>
        <th>All (difference)</th>
        <th>All(entered)</th>
        <th>All(left)</th>
        <th>All(improved)</th>
        <th>All(declined)</th>
      </tr>
      <tr *ngFor="let entry of seo?.results;">
        <th>{{entry.Domain}}</th>
        <td>{{entry.Visibility}}</td>
        <td>{{entry['Estimated traffic']}}</td>
        <td>{{entry['Estimated traffic change']}}</td>
        <td>{{entry['Top 3']}}</td>
        <td>{{entry['Top 3 (difference)']}}</td>
        <td>{{entry['Top 3 (entered)']}}</td>
        <td>{{entry['Top 3 (left)']}}</td>
        <td>{{entry['Top 3 (improved)']}}</td>
        <td>{{entry['Top 3 (declined)']}}</td>
        <td>{{entry['Top 10']}}</td>
        <td>{{entry['Top 10 (difference)']}}</td>
        <td>{{entry['Top 10 (entered)']}}</td>
        <td>{{entry['Top 10 (left)']}}</td>
        <td>{{entry['Top 10 (improved)']}}</td>
        <td>{{entry['Top 10 (declined)']}}</td>
        <td>{{entry['Top 20']}}</td>
        <td>{{entry['Top 20 (difference)']}}</td>
        <td>{{entry['Top 20 (entered)']}}</td>
        <td>{{entry['Top 20(left)']}}</td>
        <td>{{entry['Top 20 (improved)']}}</td>
        <td>{{entry['Top 20 (declined)']}}</td>
        <td>{{entry['All']}}</td>
        <td>{{entry['All (difference)']}}</td>
        <td>{{entry['All (entered)']}}</td>
        <td>{{entry['All (left)']}}</td>
        <td>{{entry['All (improved)']}}</td>
        <td>{{entry['All (declined)']}}</td>
      </tr>
    </table>

  </div>
</div>