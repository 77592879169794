<div class="row" id="subHeader1">
  <div id="pageTitle" style="text-align: center;">Practice Settings</div>
</div>

<form [formGroup]='docForm' (ngSubmit)="updateAccountInfo()" (keydown.enter)="$event.preventDefault()">
  <div>
    <label for="name" class="col-md-3"><strong> Practice Name </strong></label>
    <mat-form-field style="margin-left:10px;" class="example-full-width">
      <input id="name" class="col-md-6" matInput name="name" formControlName="name" required />
      <mat-error *ngIf="docForm.get('name').hasError('required')">
        {{config.requiredErrorText}}
      </mat-error>
    </mat-form-field>
  </div>

  <div>
    <label class="col-md-2"> <strong>Primary Contact</strong></label>
  </div>

  <div class="primary">
    <label class="col-md-3"> Name </label>
    <mat-form-field class="example-full-width">
      <input class="col-md-6" matInput name="primaryName" formControlName="primaryName" />
    </mat-form-field>
  </div>

  <div class="primary">
    <label class="col-md-3"> Email </label>
    <mat-form-field class="example-full-width">
      <input class="col-md-6" type="email" matInput name="primaryEmailId"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" formControlName="primaryEmailId" />
    </mat-form-field>
  </div>

  <div class="primary">
    <label class="col-md-3"> Contact No </label>
    <mat-form-field class="example-full-width">
      <input class="col-md-6" matInput name="primaryPhone"
        pattern="(((\([0-9]{3}\) |[0-9]{3}-)([0-9]{3}-[0-9]{4}))|([0-9]{3}-[0-9]{4})|([789][0-9]{9}))"
        formControlName="primaryPhone" />
    </mat-form-field>
  </div>

  <div>
    <label class="col-md-3"><strong>Website Url</strong></label>
    <mat-form-field style="margin-left:10px;" class="example-full-width">
      <input class="col-md-6" matInput name="url" formControlName="url" required />
      <mat-error *ngIf="docForm.get('url').hasError('required')">
        {{config.requiredErrorText}}
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <label class="col-md-2"><strong>SocialMedia</strong></label>
  </div>
  <div class="primary">
    <label class="col-md-3"> Facebook Link </label>
    <mat-form-field class="example-full-width">
      <input class="col-md-6" matInput name="facebook" formControlName="facebookPageLink" />
    </mat-form-field>
  </div>
  <div class="primary">
    <label class="col-md-3"> Instagram Link </label>
    <mat-form-field class="example-full-width">
      <input class="col-md-6" matInput name="instagram" formControlName="instagramLink" />
    </mat-form-field>
  </div>
  <div class="primary">
    <label class="col-md-3"> Youtube Link </label>
    <mat-form-field class="example-full-width">
      <input class="col-md-6" matInput name="youtube" formControlName="youtubeLink" />
    </mat-form-field>
  </div>

  <div>
    <label class="col-md-2"> <strong>Location </strong></label>
  </div>

  <div formArrayName="locationArray">
    <div *ngFor="let item of docForm.controls.locationArray['controls']; let i=index" [formGroupName]="i">
      <div class="primary">
        <label class="col-md-3">Add location: </label>
        <mat-form-field style="margin-left:10px;" class="example-full-width">
          <input matInput class="col-md-6" name="locationName" formControlName="locationName" required>
          <!--
                      <input  matInput
                               class="col-md-6"
                               matValidateAddress
                               matGoogleMapsAutocomplete
                               #matGoogleMapsAutocomplete="matGoogleMapsAutocomplete"
                               [country]="['IN','US']"
                               (onAutocompleteSelected)="onAutocompleteSelected($event)"
                               (onLocationSelected)="onLocationSelected($event)"
                               name="locationName"
                               formControlName="locationName"
                               required>

          <mat-error *ngIf="matGoogleMapsAutocomplete.addressSearchControl.hasError('required')">
            {{config.requiredErrorText}}
          </mat-error>
          <mat-error *ngIf="matGoogleMapsAutocomplete.addressSearchControl.hasError('validateAddress')">
            {{config.invalidErrorText}}
          </mat-error>
        -->
        </mat-form-field>
        <button mat-mini-fab class="format" type="button" (click)='removeLocation(i)' id="button"
          style="margin-left:20px;" matTooltip="Remove Doctor">
          <i class="material-icons remove">remove</i>
        </button>
      </div>
    </div>
  </div>

  <div>
    <button mat-mini-fab class="format" type="button" matTooltip="Add Location" (click)="addLocation()" id="button"
      style="margin-left:50px;margin-bottom: 20px;margin-top:10px;"><i class="material-icons add">add</i></button>
  </div>

  <div>
    <label class="col-md-2"> <strong>Staff </strong></label>
  </div>

  <div formArrayName="doctorInformation">
    <div *ngFor="let item of docForm.controls.doctorInformation['controls']; let i=index" [formGroupName]="i">
      <div class="primary">
        <label class="col-md-3"> Doctor Name </label>
        <mat-form-field class="example-full-width">
          <input class="col-md-6" matInput formControlName="name"><br>
        </mat-form-field>
        <button mat-mini-fab class="format" type="button" (click)='removeInput(i)' id="button" style="margin-left:20px;"
          matTooltip="Remove Doctor"><i class="material-icons remove">remove</i></button>
      </div>
      <div class="primary">
        <label class="col-md-3">Doctor EmailID </label>
        <mat-form-field class="example-full-width">
          <input type="email" class="col-md-6" matInput formControlName="email"><br>
        </mat-form-field>
      </div>
      <div class="primary">
        <label class="col-md-3">Doctor Phone </label>
        <mat-form-field class="example-full-width">
          <input class="col-md-6" matInput formControlName="phone"
            pattern="(((\([0-9]{3}\) |[0-9]{3}-)([0-9]{3}-[0-9]{4}))|([0-9]{3}-[0-9]{4})|([789][0-9]{9}))"><br>
        </mat-form-field>

      </div>
      <div class="primary">
        <label class="col-md-3">Speciality </label>
        <mat-form-field class="example-full-width">
          <input class="col-md-6" matInput formControlName="speciality"><br>
        </mat-form-field>
      </div>

      <div *ngIf="docForm.value.doctorInformation[i].linked_calendar" class="primary">
        <label class="col-md-3">Configured Calendars</label>
        <mat-form-field class="col-md-3" *ngFor="let calendarData of docForm.value.doctorInformation[i].calendar_link">
          <input class="col-md-6" matInput value="{{calendarData.slug}}"><br>
        </mat-form-field>
      </div>

      <div *ngIf="showEnableCalendar" class="primary">
        <label class="col-md-3">Configure New Calendar</label>
        <mat-radio-group formControlName="configureNewCal" class="example-full-width"
          (change)="addProviderForAccount($event,i)">
          <mat-radio-button class="col-md-1" value="true"> YES </mat-radio-button>
          <mat-radio-button class="col-md-1" value="false"> NO </mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="docForm.value.doctorInformation[i].linked_calendar === 'true'" class="primary">
        <div *ngFor="let s of docForm.controls.doctorInformation['controls'][i].controls.slug.value; let i = index">
          <label class="col-md-3"> Slug </label>
          <mat-form-field>
            <input class="col-md-6" type="text" matInput value="{{s}} " readonly><br>
          </mat-form-field>
        </div>
      </div>

    </div>
  </div>

  <div>

    <button mat-mini-fab class="format" type="button" matTooltip="Add Doctor" (click)="addInput()" id="button"
      style="margin-left:50px"><i class="material-icons add">add</i></button>
  </div>


  <div style="text-align:center">
    <button mat-raised-button type="submit" id="button" style="margin:10px" [disabled]="!docForm.valid"
      name="button">Submit</button> {{docForm.valid}}
  </div>

</form>