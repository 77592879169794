import { Component, Input, OnInit, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-contact-follow-up',
  templateUrl: './contact-follow-up.component.html',
  styleUrls: ['./contact-follow-up.component.scss']
})
export class ContactFollowUpComponent implements OnInit {
  @Input() contact: any;
  @Input() allTask: any;
  contactDetails: any;
  constructor(
  ) { }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('contact' in changes) {
      this.contactDetails = changes.contact.currentValue;
      console.log(this.contactDetails);
    }
    if ('allTask' in changes) {
      this.allTask = changes.allTask.currentValue;
      console.log(this.allTask);
    }


  }
  ngOnInit(): void {
  }

}
