import { Component, OnInit } from '@angular/core';
import { NavigationState } from '../_models';
import { AccountService, NavigationService } from '../_services';
import { SearchConsoleService } from '../_services/search-console.service';
import * as moment from 'moment';

interface Date {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-search-console',
  templateUrl: './search-console.component.html',
  styleUrls: ['./search-console.component.scss']
})

export class SearchConsoleComponent implements OnInit {
  data: any = [];
  filterColumns: any = [];
  dataSource: any = [];
  dataSourceGain: any = [];
  dataSourceLoss: any = [];
  dataSourceRank: any = [];
  dataSourceDrop: any = [];
  totalAnalysis: string;
  enteredValue: string = "01.00";
  searchConsoleData: any = [];
  isReady: boolean = false;
  selectedValue: string;
  noData: boolean;
  // selected: string = 'defaultDate';

  constructor(private navigationService: NavigationService,
    private searchConsoleService: SearchConsoleService, private accountService: AccountService) { }

  ngOnInit() {
    this.navigationService.updateState(
      new NavigationState(false, "Search Console", "", "", "/search-console")
    );


    this.searchConsoleService.getImpressions().then(snapshot => {
      if (snapshot == null) {
        this.noData = true;
        this.isReady = true;
        return;
      }
      snapshot.forEach(doc => {
        let document = doc.data();
        document['docId'] = doc.id;
        console.log(document);
        document.createdOn = document.createdOn.toDate('MMM d, yyyy');
        this.searchConsoleData.push(document);
      });
      this.data = this.searchConsoleData[0];
      this.selectedValue = this.searchConsoleData[0];
      console.log(this.data);
      this.totalAnalysis = this.data.overallAnalysis;
      console.log(this.totalAnalysis);

      let value = this.enteredValue;
      value = value.replace("%", "");

      //  top-impression-gainer
      let gainedImpressionAnalysis = [];
      this.data.impressionAnalysis.forEach(element => {
        if (element.deltaImpressionDiff > 0 && element.deltaImpressionSharePercentage > value) {
          gainedImpressionAnalysis.push(element)
        }
      });
      gainedImpressionAnalysis.sort((a, b) => {
        return b.deltaImpressionDiff - a.deltaImpressionDiff;
      })
      this.dataSourceGain = gainedImpressionAnalysis;

      //  top-impression-loser
      let lossedImpressionAnalysis = [];
      this.data.impressionAnalysis.forEach(element => {
        if (element.deltaImpressionDiff < 0 && element.deltaImpressionSharePercentage > parseFloat(value)) {
          lossedImpressionAnalysis.push(element)
        }
      });
      lossedImpressionAnalysis.sort((a, b) => {
        return a.deltaImpressionDiff - b.deltaImpressionDiff;
      })
      this.dataSourceLoss = lossedImpressionAnalysis;

      //  Ranking on first page
      let rankedImpressionAnalysis = [];
      this.data.impressionAnalysis.forEach(element => {
        if (element.lastWeekPositionCount < 10 && element.lastWeekPositionCount > 0 && element.lastTotalImpressionShare > value) {
          rankedImpressionAnalysis.push(element)
        }
      });
      rankedImpressionAnalysis.sort((a, b) => {
        return b.lastTotalImpressionShare - a.lastTotalImpressionShare;
      })
      this.dataSourceRank = rankedImpressionAnalysis;

      //  Dropping from first page
      let droppedImpressionAnalysis = [];
      this.data.impressionAnalysis.forEach(element => {
        if (element.prevWeekPositionCount < 10 && element.prevWeekPositionCount > 0 && element.lastWeekPositionCount > 10 && element.lastTotalImpressionShare > value) {
          droppedImpressionAnalysis.push(element)
        }
      });
      droppedImpressionAnalysis.sort((a, b) => {
        return b.lastTotalImpressionShare - a.lastTotalImpressionShare;
      })
      this.dataSourceDrop = droppedImpressionAnalysis;
      this.isReady = true;

    });

  }

  fetchLatestAnalysis() {
    this.isReady = false;
    let accObj = {
      acc_slug: this.accountService.acc_details.account_slug
    }
    this.searchConsoleService.fetchLatestSearchConsoleData(accObj).then((res) => {
      let latestAnalysis = res;
      console.log(latestAnalysis);
      this.searchConsoleData[0] = latestAnalysis;
      this.selectedValue = this.searchConsoleData[0];
      this.data = latestAnalysis;
      this.data.createdOn = moment().toDate();
      console.log(this.selectedValue);
      this.isReady = true;
    }).catch((error) => {
      console.log(error);
    });
  }

  ShowData(selectedValue) {
    console.log(selectedValue);
    this.data = selectedValue;

    this.totalAnalysis = this.data.overallAnalysis;
    console.log(this.totalAnalysis);

    let value = this.enteredValue;
    value = value.replace("%", "");

    //  top-impression-gainer
    let gainedImpressionAnalysis = [];
    this.data.impressionAnalysis.forEach(element => {
      if (element.deltaImpressionDiff > 0 && element.deltaImpressionSharePercentage > value) {
        gainedImpressionAnalysis.push(element)
      }
    });
    gainedImpressionAnalysis.sort((a, b) => {
      return b.deltaImpressionDiff - a.deltaImpressionDiff;
    })
    this.dataSourceGain = gainedImpressionAnalysis;

    //  top-impression-loser
    let lossedImpressionAnalysis = [];
    this.data.impressionAnalysis.forEach(element => {
      if (element.deltaImpressionDiff < 0 && element.deltaImpressionSharePercentage > parseFloat(value)) {
        lossedImpressionAnalysis.push(element)
      }
    });
    lossedImpressionAnalysis.sort((a, b) => {
      return a.deltaImpressionDiff - b.deltaImpressionDiff;
    })
    this.dataSourceLoss = lossedImpressionAnalysis;

    //  Ranking on first page
    let rankedImpressionAnalysis = [];
    this.data.impressionAnalysis.forEach(element => {
      if (element.lastWeekPositionCount < 10 && element.lastWeekPositionCount > 0 && element.lastTotalImpressionShare > value) {
        rankedImpressionAnalysis.push(element)
      }
    });
    rankedImpressionAnalysis.sort((a, b) => {
      return b.lastTotalImpressionShare - a.lastTotalImpressionShare;
    })
    this.dataSourceRank = rankedImpressionAnalysis;

    //  Dropping from first page
    let droppedImpressionAnalysis = [];
    this.data.impressionAnalysis.forEach(element => {
      if (element.prevWeekPositionCount < 10 && element.prevWeekPositionCount > 0 && element.lastWeekPositionCount > 10 && element.lastTotalImpressionShare > value) {
        droppedImpressionAnalysis.push(element)
      }
    });
    droppedImpressionAnalysis.sort((a, b) => {
      return b.lastTotalImpressionShare - a.lastTotalImpressionShare;
    })
    this.dataSourceDrop = droppedImpressionAnalysis;
  }
}