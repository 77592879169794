<mat-card class="card">
  <mat-card-header style="width: 100%;">
    <div class="row" style="width: 100%;">
      <div class="col-4">
      </div>
      <div class="col-4">
        <div style="text-align: center;padding-top: 10px;color: white;font-size: 20px;">{{data.dialogTitle}}</div>
      </div>
      <div class="col-4">
        <div style="text-align: right;padding-top: 10px;padding-right: 0px;">
          <i class="pi pi-times" style="font-size: 1rem;cursor: pointer;font-size: 20px;" mat-dialog-close>
          </i>
        </div>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="screenType == 'algoliaSearch'">
      <app-algolia-contact-search (sendContactEmittor)="getSelectedContacts($event)" [inputText]="inputText"
        [selectedContacts]="selectedContacts">
      </app-algolia-contact-search>
    </div>
    <div *ngIf="screenType == 'formSelection'">
      <div style="margin:10px 0px 0px 17px;font-size: 18px;font-weight: 500;">Select the patient form</div>
      <div id="card-container" [class]="data['type']">
        <div *ngFor="let form of forms" class="card-block">
          <mat-card style="display: block;width: 200px;height: 230px;margin: 10px;cursor: pointer;"
            [matBadge]="selectedForms.indexOf(form.id) + 1" matBadgePosition="before" matBadgeColor="accent"
            [matBadgeHidden]="selectedForms.indexOf(form.id) == -1">
            <mat-card-content style="height: 50%;overflow: hidden;">
              <img class="image" *ngIf="form.data?.welcome_screen.image !== ''" mat-card-image
                src={{form.data.welcome_screen.image}} (click)="selectForm(form)">
            </mat-card-content>
            <mat-card-actions style="height: 50%;overflow: hidden;padding:20px;">
              <a (click)="selectForm(form)">
                <h6 class="card-title" id="card_icon">{{form.data.name}}</h6>
              </a>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
    <div *ngIf="screenType == 'formSend'">
      <div class="row" style="margin: 5px;">
        <div class="col-md-6">
          <div class="row" style="padding: 10px;">
            <mat-checkbox style="margin:5px 10px;font-size:20px;" [disabled]="readOnly" name="email"
              [checked]="types['email']" [(ngModel)]="types['email']" (change)="emailSmsCheck($event)">Email
            </mat-checkbox>
          </div>
          <!-- <div class="row" style="margin: 2px;" *ngIf="types['email']">
            <span style="padding: 5px 0px 5px 0px;;font-size: 1rem;">Owner Email</span>
            <input type="text" [readOnly]="readOnly" pInputText placeholder="Enter owner's email"
              [(ngModel)]="ownerEmail" required style="width: 100%;" (input)="disabledStatus($event)">
          </div> -->
          <div class="row" style="margin: 2px;" *ngIf="types['email']">
            <span style="padding: 5px 0px 5px 0px;;font-size: 1rem;">Email Subject</span>
            <input type="text" [readOnly]="readOnly" pInputText placeholder="Enter emailbject"
              [(ngModel)]="emailSubject" required style="width: 100%;" (input)="disabledStatus()">
          </div>
          <div class="row" style="margin: 2px;" *ngIf="types['email']">
            <span style="padding: 5px 0px 5px 0px;;font-size: 1rem;">Email Body</span>
            <p-editor [readOnly]="readOnly" [(ngModel)]="emailBody" placeholder="Enter email body"
              [style]="{'height':'110px'}" (onTextChange)="onTextChange($event)"></p-editor>
            <!-- <textarea rows="10" [readOnly]="readOnly" cols="30" pInputTextarea placeholder="Enter email body" style="width: 100%;" autoResize="autoResize" [(ngModel)]="emailBody" class="inputText" (input)="disabledStatus()" required></textarea> -->
          </div>
        </div>
        <div class="col-md-6">
          <div class="row" style="padding: 10px;">
            <mat-checkbox style="margin:5px 10px;font-size: 20px;" [disabled]="readOnly" name="sms"
              [checked]="types['sms']" [disabled]="!linked_accounts" [(ngModel)]="types['sms']"
              (change)="emailSmsCheck($event)">SMS
            </mat-checkbox>
          </div>
          <div class="row" style="margin: 2px;" *ngIf="types['sms']">
            <span style="padding: 5px 0px 5px 0px;font-size: 1rem;">SMS Body</span>
            <textarea rows="13" cols="30" [readOnly]="readOnly" pInputTextarea placeholder="Enter email body"
              style="width: 100%;" (input)="disabledStatus()" autoResize="autoResize" [(ngModel)]="smsBody"
              class="inputText" required></textarea>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="!confirmation">
    <div style="text-align: right;padding-top:10px" *ngIf="screenType == 'algoliaSearch'">
      <button mat-raised-button color="primary" style="text-align: right;margin: 2px 9px;" (click)="Next()"
        [disabled]="selectedContacts.length==0">Next</button>
    </div>
    <div *ngIf="screenType == 'formSelection'" class="row" style="padding-left: 13px;padding-top:10px">
      <div class="col-md-6" style="text-align: left">
        <button mat-raised-button color="primary" style="margin:2px 12px"
          *ngIf="data['type']!='sendPatientFormFromContactScreen'" (click)="Previous()">Previous</button>
      </div>
      <div class="col-md-6" style="text-align: right">
        <button mat-raised-button color="primary" style="margin: 2px 9px;" (click)="Next()"
          [disabled]="selectedForms.length==0">Next</button>
      </div>
    </div>
    <div *ngIf="screenType == 'formSend'" class="row" style="padding-left: 13px;padding-top:10px">
      <div class="col-md-6" style="text-align: left">
        <button mat-raised-button color="primary" style="margin: 2px 12px;" (click)="Previous()"
          *ngIf="data['type']!='resend'">Previous</button>
      </div>
      <div class="col-md-6" style="text-align: right">
        <button mat-raised-button color="primary" style="margin: 2px 9px;" (click)="sendForms()"
          [disabled]="disabledBtn">Send</button>
      </div>
    </div>
  </mat-card-actions>
  <mat-card-actions *ngIf="confirmation" id="content">
    <!-- <div style="text-align: center;" *ngIf="loading">
      <b>{{displayMsg}}</b>
    </div>
    <div style="text-align: center;" *ngIf="!loading">
      <b>{{displayMsg}}</b>
    </div> -->
    <div style="text-align: center;">
      <b>Patient form will be sent soon, you can close the dialog.</b>
    </div>
  </mat-card-actions>
</mat-card>
