import { Component, OnInit, Input, Inject } from '@angular/core';
import { AccountService, ContactService } from '../_services/index';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  contact: any;
}
@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
  @Input()
  contact: any;

  contactObj: any;
  twilio_credentials: any;
  newNote: any;
  fetchedNote: any;
  activities_array: any = [];
  activities: any = [];
  activities_fetched: boolean = false;
  noActivities: boolean = false;
  //Session time and caching variable
  sessionTime: number = 30000;
  enableCaching: boolean = false;
  constructor(
    private contactService: ContactService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public accountService: AccountService
  ) {
    //this.fetchActivities(this.contact);
    contactService.notes$.subscribe(
      note => {
        this.activities.unshift(note);
        sessionStorage.setItem('activities' + this.contact.full_phone, JSON.stringify({ "creation_timestamp": Date.now(), "logs": this.activities }));
      });
    contactService.sms$.subscribe(
      sms => {
        this.activities.unshift(sms);
        sessionStorage.setItem('activities' + this.contact.full_phone, JSON.stringify({ "creation_timestamp": Date.now(), "logs": this.activities }));
      });

    contactService.phone$.subscribe(phone => {
      this.activities = [];
      let contactObj = {
        'phone': phone,
        'acc_sid': this.twilio_credentials.account_sid,
        'auth_token': this.twilio_credentials.auth_token
      }
      this.fetchActivities();
    });
    contactService.sendSMSNotification$.subscribe(
      smsObject => {
        this.activities.unshift(smsObject);
      });
  }


  ngOnInit() {
    this.fetchActivities();
  }

  contactAppointments() {
    if (this.contact.apppointments !== undefined) {
      if (this.contact.appointments.length > 0) {
        this.contact.appointments.forEach(appt => {
          let appt_details = {
            'type': 'appointment',
            'appt_date': appt.date,
            'status': appt.status,
            'date': appt.booked_on.toString(),
            'slot': appt.slot,
            'source': appt.source,
            'location': appt.location,
            'services': appt.services
          }
          this.activities.push(appt_details);
        });
      }
    }
  }

  addNewSmsToActivitiesArray(smsObject) {
    this.activities.unshift(smsObject);
  }


  fetchActivities() {
    this.activities_fetched = false;
    this.twilio_credentials = this.accountService.getLinkedAccountCredentials('twilio');
    /*  let contactObj = {
         'phone' : this.contact.full_phone,
         'acc_sid' : this.twilio_credentials.account_sid,
         'auth_token': this.twilio_credentials.auth_token
       }*/
    //Code for disabled caching .
    if (this.enableCaching == true) {
      let activity_record = JSON.parse(sessionStorage.getItem('activities' + this.contact.full_phone));
      if (activity_record !== null) {
        if (Date.now() - activity_record.creation_timestamp < this.sessionTime) {
          this.activities = activity_record.logs;
          /*if(this.activities.length == 0){
            console.log("No Data");
            this.activities_fetched = true;
           this.noActivities = true;
          }
        else{
            this.activities_fetched = true;
           this.noActivities = false;
         }*/
          this.activities_fetched = true;
        }
        else {
          this.contactService.fetchNotesForContact(this.contact.ref)
            .then(notesSnapshot => {
              notesSnapshot.forEach(note => {
                this.fetchedNote = note.data();
                this.fetchedNote.id = note.id;
                this.fetchedNote.date = this.fetchedNote.date.toDate().toString();
                this.activities.push(this.fetchedNote);
              });
              this.contactService.fetchFormActivitieForContact(this.contact.ref).then(activitySnap =>{
                activitySnap.forEach(formData=>{
                  let formObj = formData.data();
                  formObj.date= formObj.date.toDate().toString();
                  this.activities.push(formObj);
                  console.log(formObj);
                });
              });
              this.contactAppointments();
              if (this.twilio_credentials !== undefined) {
                let contactObj = {
                  'phone': this.contact.full_phone,
                  'acc_sid': this.twilio_credentials.account_sid,
                  'auth_token': this.twilio_credentials.auth_token
                }
                this.contactService.fetchSMSLogs(contactObj).then(smsData => {
                  let sms_array = smsData.data;
                  sms_array.forEach(sms => {
                    this.activities.push(sms);
                  });
                  if (this.activities.length == 0) {
                    console.log("No Data");
                    sessionStorage.setItem('activities' + this.contact.full_phone, JSON.stringify({ "creation_timestamp": Date.now(), "logs": this.activities }));
                    this.activities_fetched = true;
                    this.noActivities = true;
                  }
                  else {
                    this.activities.sort((a, b) => {
                      return <any>new Date(b.date) - <any>new Date(a.date);
                    });
                    sessionStorage.setItem('activities' + this.contact.full_phone, JSON.stringify({ "creation_timestamp": Date.now(), "logs": this.activities }));
                    this.activities_fetched = true;
                    this.noActivities = false;
                  }
                });
              }
              else {
                if (this.activities.length == 0) {
                  console.log("No Data");
                  sessionStorage.setItem('activities' + this.contact.full_phone, JSON.stringify({ "creation_timestamp": Date.now(), "logs": this.activities }));
                  this.activities_fetched = true;
                  this.noActivities = true;
                }
                else {
                  this.activities.sort((a, b) => {
                    return <any>new Date(b.date) - <any>new Date(a.date);
                  });
                  sessionStorage.setItem('activities' + this.contact.full_phone, JSON.stringify({ "creation_timestamp": Date.now(), "logs": this.activities }));
                  this.activities_fetched = true;
                  this.noActivities = false;
                }
              }

            });

        }

      }
      else {
        this.contactService.fetchNotesForContact(this.contact.ref)
          .then(notesSnapshot => {
            notesSnapshot.forEach(note => {
              this.fetchedNote = note.data();
              this.fetchedNote.id = note.id;
              this.fetchedNote.date = this.fetchedNote.date.toDate().toString();
              this.activities.push(this.fetchedNote);
            });
            this.contactService.fetchFormActivitieForContact(this.contact.ref).then(activitySnap =>{
              activitySnap.forEach(formData=>{
                let formObj = formData.data();
                formObj.date= formObj.date.toDate().toString();
                this.activities.push(formObj);
              });
            });
            this.contactAppointments();

            if (this.twilio_credentials !== undefined) {
              let contactObj = {
                'phone': this.contact.full_phone,
                'acc_sid': this.twilio_credentials.account_sid,
                'auth_token': this.twilio_credentials.auth_token
              }
              this.contactService.fetchSMSLogs(contactObj).then(smsData => {
                let sms_array = smsData.data;
                sms_array.forEach(sms => {
                  this.activities.push(sms);
                });
                if (this.activities.length == 0) {
                  sessionStorage.setItem('activities' + this.contact.full_phone, JSON.stringify({ "creation_timestamp": Date.now(), "logs": this.activities }));
                  this.activities_fetched = true;
                  this.noActivities = true;
                }
                else {
                  this.activities.sort((a, b) => {
                    return <any>new Date(b.date) - <any>new Date(a.date);
                  });
                  sessionStorage.setItem('activities' + this.contact.full_phone, JSON.stringify({ "creation_timestamp": Date.now(), "logs": this.activities }));
                  this.activities_fetched = true;
                  this.noActivities = false;
                }
              });
            }
            else {
              if (this.activities.length == 0) {
                console.log("No Data");
                sessionStorage.setItem('activities' + this.contact.full_phone, JSON.stringify({ "creation_timestamp": Date.now(), "logs": this.activities }));
                this.activities_fetched = true;
                this.noActivities = true;
              }
              else {
                this.activities.sort((a, b) => {
                  return <any>new Date(b.date) - <any>new Date(a.date);
                });
                sessionStorage.setItem('activities' + this.contact.full_phone, JSON.stringify({ "creation_timestamp": Date.now(), "logs": this.activities }));
                this.activities_fetched = true;
                this.noActivities = false;
              }
            }

          });
      }
    }


    //Code for disabled caching .
    if (this.enableCaching == false) {
      this.contactService.fetchNotesForContact(this.contact.ref)
        .then(notesSnapshot => {
          notesSnapshot.forEach(note => {
            this.fetchedNote = note.data();
            this.fetchedNote.id = note.id;
            this.fetchedNote.date = this.fetchedNote.date.toDate().toString();
            this.activities.push(this.fetchedNote);

          });
          this.contactService.fetchFormActivitieForContact(this.contact.ref).then(activitySnap =>{
            activitySnap.forEach(formData=>{
              let formObj = formData.data();
              formObj.date= formObj.date.toDate().toString();
              this.activities.push(formObj);
            });
          });
          this.contactAppointments();


          if (this.twilio_credentials !== undefined) {
            let contactObj = {
              'phone': this.contact.full_phone,
              'acc_sid': this.twilio_credentials.account_sid,
              'auth_token': this.twilio_credentials.auth_token
            }
            this.contactService.fetchSMSLogs(contactObj).then(smsData => {
              let sms_array = smsData.data;
              sms_array.forEach(sms => {
                this.activities.push(sms);

                /*this.activities.sort((a,b)=>{
                  return <any>new Date(b.date) - <any>new Date(a.date);
                });*/

              });
              if (this.activities.length == 0) {
                console.log("No Data");
                this.activities_fetched = true;
                this.noActivities = true;
              }
              else {
                this.activities.sort((a, b) => {
                  return <any>new Date(b.date) - <any>new Date(a.date);
                });
                this.activities_fetched = true;
                this.noActivities = false;
              }
            });
          }
          else {
            if (this.activities.length == 0) {
              console.log("No Data");
              this.activities_fetched = true;
              this.noActivities = true;
            }
            else {
              this.activities.sort((a, b) => {
                return <any>new Date(b.date) - <any>new Date(a.date);
              });
              this.activities_fetched = true;
              this.noActivities = false;
            }
          }

        });
    }
  }

  //contact-notes component functions
  openDialogForNote() {
    const noteDialogRef = this.dialog.open(AddNotesDialog, {
      height: '300px',
      width: '600px',
      data: { contact: this.contact.ref }
    });

  }

  updateNote(changedNote: string, id: string) {
    this.contactService.updateEditedNote(this.contact.ref, changedNote, id);
    let snackBarRef = this.snackBar.open('Note updated successfully!', '', {
      duration: 3000
    });
    if (this.enableCaching == true) {
      sessionStorage.setItem('activities' + this.contact.full_phone, JSON.stringify({ "creation_timestamp": Date.now(), "logs": this.activities }));
    }
  }


}

@Component({
  selector: 'create-note-dialog',
  templateUrl: './create-note-dialog.html',
  styleUrls: ['./activities.component.scss']
})

export class AddNotesDialog {
  contact: any;
  createdNote: string;
  //notes_array : any;
  constructor(
    private contactService: ContactService,
    public noteDialogRef: MatDialogRef<AddNotesDialog>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public contactData: DialogData
  ) {
    this.contact = contactData;
  }

  addNote() {
    this.contactService.addNoteForContact(this.contact, this.createdNote);
    this.createdNote = "";
    let snackBarRef = this.snackBar.open('Note added successfully!', '', {
      duration: 3000
    });
    this.noteDialogRef.close();
  }


  close() {
    this.noteDialogRef.close();
  }
}
