<form #form="ngForm">
  <div class="row">
    <div class="col-md-6">
      <div class="col">
        <div class="row-6">
          <mat-checkbox style="margin:5px 10px;font-size:20px;" name="email" [checked]="types['email']"
            [(ngModel)]="types['email']" (change)="emailSmsCheck($event)">Email
          </mat-checkbox>
        </div>
        <br>

        <div class="row-6" *ngIf="types['email']">
          <div class="col">
            <div class="row-6">
              <h6>Email Subject</h6>
              <input type="text" label="subject" name="emailSubject" style="width: 100%;" pInputText placeholder="Subject"
                [(ngModel)]="emailSubject">
            </div>
            <br>
            <div class="row-6">
              <h6>Email Message</h6>
              <textarea rows="5" style="width: 100%;" pInputTextarea name="emailBody" [(ngModel)]="emailBody"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">

      <div class="col">
        <div class="row-6">
          <mat-checkbox style="margin:5px 10px;font-size: 20px;" name="sms" [checked]="types['sms']"
            [disabled]="!linked_accounts" [(ngModel)]="types['sms']" (change)="emailSmsCheck($event)">SMS
          </mat-checkbox>
        </div>
        <br>

        <div class="row-6" *ngIf="types['sms']">
          <div class="col">
            <div class="row">
              <h6>SMS Message</h6>
              <textarea pInputTextarea rows="8" style=" height : 220px; width: 100%;" name="smsBody" pInputTextarea placeholder="Body"
                [(ngModel)]="smsBody"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- <div class="row">
  <div class="col-md text-center" style="padding-top: 20px;">
    <button mat-raised-button  (click)="next()" [disabled]="disabled">Next</button>
  </div>
</div> -->