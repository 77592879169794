import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { Subject } from "rxjs";
import { AngularFirestore, docChanges } from "angularfire2/firestore";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class AppointmentStatsService {
  constructor() { }
  fetchAppointments(after_date, before_date) {
    let apptsDocsSnapshot = firebase.firestore()
      .collection("accounts")
      .doc(`${sessionStorage.getItem('current_acc_id')}`)
      .collection("appointments")
      .where(
        "appt_start",
        ">=",
        firebase.firestore.Timestamp.fromDate(after_date)
      )
      .where(
        "appt_start",
        "<=",
        firebase.firestore.Timestamp.fromDate(before_date)
      ).orderBy("appt_start")
      .get();
    return apptsDocsSnapshot;
  }
}

