import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import { Form, Question } from '../_services/form';
import { AccountService } from './account.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { ErrorMessageOnFormSave, SaveFormDialog } from '../enquiry-forms/form-designer/form-designer.component';
// import{SaveFormDialog} from ''
const empty_form: Form = {
  name: "Name Your Form",
  slug: "",
  welcome_screen: {
    headline_text: "Questionnaire",
    short_description: "Please fill out a few questions",
    button_text: "Start"
  },
  questions: [],
  thank_you_screen: {
    headline_text: "Thank you for filling this form!",
    short_description: "We have recorded your responses",
    button_text: "Close",
    button_link: "/",
    tracking_code: {
      adwords: {
        conversion_id: "",
        conversion_label: "",
        enabled: false
      },
      facebook: {
        custom_data: {
          content_name: "",
          content_category: "",
        },
        enabled: false,
        tracking_event: ""
      }
    }

  },
  form_settings: {
    email: "",
    hubspot_form_id: "",
    hubspot_id: ""
  }
};

@Injectable()
export class FormBuilderService {

  constructor(
    private accountService: AccountService,
    private router: Router,
    private http: HttpClient,
    public afs: AngularFirestore,
    public dialog: MatDialog
  ) { }

  private questionsSource = new Subject<Question[]>();
  questions$ = this.questionsSource.asObservable();

  openDialog() {
    const dialogRef = this.dialog;
    return dialogRef;
  }

  getSettings() {
    return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).get();
  }

  setSettings(data: any, applyToAll: boolean, stateUrl: any) {
    firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).update({ "form_settings": data })
      .then(result => {
        if (applyToAll) {
          // this.getForms()
          this.getFormData(stateUrl)
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                doc.ref.update({ "form_settings": data });
              });
            });
        }
      });
  }

  indicateQuestionsUpdate(questions: Question[]) {
    this.questionsSource.next(questions);
  }

  getForm(selctedSlug: string, id: string) {
    if (selctedSlug === "/enquiry-forms") {
      return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('forms').doc(id)
        .get();
    }
    else if (selctedSlug === "/patient-intake-forms") {
      return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('patient_forms').doc(id)
        .get();
    }

    else if (selctedSlug === "/survey-forms") {
      return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('survey_forms').doc(id)
        .get();
    }
  }

  getPatientForms(id: string) {
    return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('patient_forms').doc(id)
      .get();
  }

  // uploadFile(fileName: any) {
  //   const id = Math.random().toString(36).substring(2);
  //   let storageRef = firebase.storage().ref();
  //   console.log('upload file called!');
  //   //let uploadTask = storageRef.child(`interactive_forms/${this.form.name}/${id}.${fileName.name}`).put(fileName);
  //   let uploadTask = storageRef.child(`interactive_forms/${id}.${fileName.name}`).put(fileName);
  //   return uploadTask;
  // }

  uploadFile(data) {
    console.log("in file upload ");
    var n = `image_` + Date.now();
    console.log(data);
    const file = data[0];
    console.log(file.name);
    const storageRef = firebase.storage().ref();
    let uploadTask = storageRef.child(`interactive_forms/${file.name}`).put(file);
    console.log("snapshot progress");
    return uploadTask;

  }

  deleteFromStorage(filePath: string) {
    let storageRef = firebase.storage().ref();
    storageRef.child(`${filePath}`).delete();
  }

  saveForm(formRef: any, form: Form) {
    firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('forms')
      .where("slug", "==", form.slug)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          /*
          form.slug = form.slug + "_1";
          console.log("Form slug changed");
          */
        }
        formRef.update(form)
          .then(success => {
            // alert('Form saved!');
            console.log('Form saved!');
            const dialogRef = this.dialog.open(SaveFormDialog, {
              width: '250px',
            });

            dialogRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed');
            });
          },
            error => {
              // alert("There was an error while saving your form:\n" + error.message);
              console.error(error);
              const dialogRef = this.dialog.open(ErrorMessageOnFormSave, {
                width: '300px',
              });

              dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
              });
              // this.dialog.open(ErrorMessageOnFormSave);
            });
      });

    //form.slug = form.name.replace(/[\s+/+_+]/g, '-').toLowerCase();
    /*  formRef.update(form)
        .then(
          success => {
            alert('Form saved!');
            console.log('Form saved!');
          },
          error => {
            alert("There was an error while saving your form:\n" + error.message);
            console.error(error);
          }
      );*/
  }

  getFormData(collection) {
    if (collection == 'survey_forms') {
      let formData = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('survey_forms').get();
      return formData;
    }
    else {
      let formData = firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection(collection).get();
      return formData;
    }
  }



  // getForms(selctedSlug:any) {

  //   if (selctedSlug == "/enquiry-forms") {
  //     return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('forms').get();
  //   }
  //   else if (selctedSlug == "/patient-intake-forms") {
  //     return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('patient_forms').get();
  //   }
  //   else if (selctedSlug === "/survey-forms") {
  //     return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('survey_forms').get();
  //   }
  // }

  getSampleForms(selctedCollection) {

    return firebase.firestore().collection('accounts').where("account_slug", "==", "cosmetic-surgery-&-medispa")
      .get().then(querySnapshot => {
        return querySnapshot.docs[0].ref.collection(selctedCollection).get();
      });
  }

  createForm(stateUrl) {
    let form = empty_form;

    if (this.accountService.getCurrentAccDetails().form_settings != undefined) {
      form.form_settings = this.accountService.getCurrentAccDetails().form_settings;
    }
    form.slug = form.name.replace(/[\s+/+_+]/g, '-').toLowerCase();

    if (stateUrl == "/enquiry-forms") {
      firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('forms').add(form)
        .then(docRef => {
          this.router.navigate(['/form-design', stateUrl, docRef.id]);
          //window.open('/form-design/' + docRef.id);
        });
    }
    if (stateUrl == "/patient-intake-forms") {
      firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('patient_forms').add(form)
        .then(docRef => {
          this.router.navigate(['/form-design', stateUrl, docRef.id]);
        });
    }
    if (stateUrl == "/survey-forms") {
      firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('survey_forms').add(form)
        .then(docRef => {
          this.router.navigate(['/form-design', stateUrl, docRef.id]);
        });
    }

  }

  copyForm(type) {
    if (type === "/enquiry-forms")
      return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('forms');
    else
      return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('survey_forms');

  }

  deleteForm(form_id, type) {
    console.log(form_id);
    if (type == "forms")
      return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('forms').doc(`${form_id}`).delete();
    else
      return firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('survey_forms').doc(`${form_id}`).delete();

  }

  async createResponseDoc(forms, contact) {
    let docPaths = [];
    for (let i = 0; i < forms.length; i++) {
      let docRef = await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('patient_forms').doc(`${forms[i].id}`).collection("form_response").add({
        form_name: `${forms[i].data.name}`,
        initiated: new Date(),
        status: "initiated",
        contact_ref: contact.ref
      });
      docPaths.push(docRef);
    }
    return docPaths;
  }
  async createFormsLink(docPaths, contact) {
    return await firebase.firestore().collection('accounts').doc(`${sessionStorage.getItem('current_acc_id')}`).collection('patient_form_details').add({
      forms: docPaths,
      contact_ref: contact.ref
    });
  }
}
