import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
//import { toPromise } from 'rxjs';
import { Observable ,  Subject } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database';
import { AlertService } from './alert.service';
import { AccountService } from './account.service';
import 'firebase/firestore';


@Injectable()
export class TipsService {

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private accountService: AccountService,
    private db: AngularFireDatabase
  ) { }

  private notificationSource = new Subject<number>();
  notification$ = this.notificationSource.asObservable();

  setNotification(count: number) {
    this.notificationSource.next(count);
  }

  getTips(listPath: string) {
    return this.db.list(listPath, ref => ref.orderByChild('timestamp')).valueChanges();
  }

  fetchTipDetails(id:string, listPath: string) {
    return this.db.list(listPath, ref => ref.orderByChild('id').equalTo(id)).valueChanges();
  }

  setTipsCount(listPath: string) {
    let count = 0;
    this.db.list(listPath, ref => ref.orderByChild('status').equalTo('Active'))
           .valueChanges()
           .subscribe(
             tips => {
               count = tips.length;
               this.setNotification(count);
             }
           );
  }

  addTip(tip: any, acc_name: string) {
    let tipsRef = this.db.list('/tips/' + acc_name);
    let newTipRef = tipsRef.push(tip);
    tipsRef.update(newTipRef.key, { id: newTipRef.key});
    this.alertService.success('Tip Added');

  }

  setStatus(id: string, status: string, acc_name: string) {
    let tipsRef = this.db.list('/tips/' + acc_name);

    tipsRef.update(id, { status: status });
  }
}
