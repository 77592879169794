<div class="container-fluid view">
<mat-form-field>
  <mat-select placeholder="Show" [(ngModel)]="selectedValue" name="selected_option">
      <mat-option value="none">
        All Tips
      </mat-option>
      <mat-option *ngFor="let filter of filter_options" [value]="filter.value">
        {{filter.displayAs}}
      </mat-option>
  </mat-select>
</mat-form-field>

<div class="row">
  <div class="col">
    <mat-tab-group [(selectedIndex)]="selectedIndex">
      <mat-tab label="Active">
        <div *ngFor="let tip of tips">
          <div class="col tip-cards" *ngIf="tip.channel === selectedValue || selectedValue === 'none'">
            <div *ngIf="(tip.status=='Active' || tip.status=='Snoozed')">

              <mat-card>
                <div class="triangle-topleft"></div>
                <div class="">
                <p><mat-chip>{{tip.type}}</mat-chip></p>
                <mat-card-header>
                  <mat-card-title class="">
                    <i class={{tip.icon}}> </i>
                      {{ tip.title }}
                  </mat-card-title>
                  <span class="spacer"></span>
                  <h6>{{ getage(tip) }}</h6>
                  <a [routerLink]="['/tip-details', tip.id]" [queryParams]="{selectedIndex : 0}"><mat-icon class="modal-link">chevron_right</mat-icon></a>
                </mat-card-header>

                <mat-card-content>
                  {{ tip.short_desc }}
                  <p class="tip-status"><em>{{ tip.status }}</em></p>
                </mat-card-content>
                </div>
              </mat-card>

            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="History" [selectedIndex]="1">

        <div *ngFor="let tip of tips" >
          <div class="col tip-cards" *ngIf="tip.channel === selectedValue || selectedValue === 'none'">
            <div *ngIf="(tip.status=='Accepted' || tip.status=='Declined' || tip.status=='Expired')">
              <mat-card>
                <div class="triangle-topleft"></div>
                <div class="triangle">
                <p><mat-chip>{{ tip.type}}</mat-chip></p>
                <mat-card-header>
                  <mat-card-title class="">
                    <i class={{tip.icon}}> </i>
                      {{ tip.title }}
                  </mat-card-title>
                  <span class="spacer"></span>
                  <h6>{{ getage(tip) }}</h6>
                  <a [routerLink]="['/tip-details', tip.id]" [queryParams]="{selectedIndex : 1}"><mat-icon class="modal-link">chevron_right</mat-icon></a>
                </mat-card-header>

                <mat-card-content>
                  {{ tip.short_desc }}
                  <p class="tip-status"><em>{{ tip.status }}</em></p>
                </mat-card-content>
              </div>
              </mat-card>
          </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
</div>
</div>
</div>
