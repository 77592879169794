import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import { AccountService } from "../_services/account.service";
import * as moment from 'moment';
import {environment} from "../../environments/environment";
let psl = require('psl');
import { timeout} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import {observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OrganicRankingService implements OnDestroy {
  seoDocRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

  private previousRankSubject = new BehaviorSubject<any>([]);
  previousOrganicRankObservable$ = this.previousRankSubject.asObservable();
  previousRankSubscribe: any;
  
  constructor(
    private http: HttpClient,
    private accService: AccountService) {
     }


  getCountries() {
      return this.http.get<any>('assets/countries.json')
        .toPromise()
        .then(res => <any[]>res.data)
        .then(data => {
          return data;
        });
    }

  async updateOrganicRankConfigurations(data,task) {
    let accId = sessionStorage.getItem('current_acc_id');
    let accSnap = firebase.firestore().collection('accounts').doc(accId);
    let docSnap = await accSnap.collection('settings').where("type", '==', 'organicRankConfig').get();
    if (docSnap.empty) {
      data['type'] = 'organicRankConfig';
      data['created_on'] = moment().toDate();
      return await accSnap.collection('settings').add(data).then(resp=>{
          this.schedularTask(task,accId,accSnap,data);
      })
    }
    else {
      data['updated_on'] = moment().toDate();
      return await docSnap.docs[0].ref.update(data).then(resp=>{
        this.schedularTask(task,accId,accSnap,data);
      })
    }
  }

  async schedularTask(task,accId,accSnap,data){
    if (task != 'none') {
      let obj = {
        accountId: accId,
        accountName: (await accSnap.get()).data().name,
        timezone: data.timezone,
        schedule: data.schedule,
        task: task,
      };
      console.log(task);
      this.http.post(`${environment.cloudFunctionServerUrl}/organicRanksCloudScheduler`, obj).subscribe(resp => {
        console.log(resp);
      });
    }
  }


  async getOrganicRankConfiguration(){
    let accId = sessionStorage.getItem('current_acc_id');
    return await firebase.firestore().collection('accounts').doc(accId)
      .collection('settings').where('type', '==', 'organicRankConfig').get();
  }

  //to get latest completed organic results.
  async getOrganicRanks(websiteUrl){
    let url = new URL(websiteUrl);
    let pslResp = psl.get(url.host)
    // let pslResp = psl.get(websiteUrl)
    let domain = pslResp;
    const seoDocSnap = await firebase.firestore().collection('seo')
      .where('url', '==', domain)
      .limit(1)
      .get();
    if (seoDocSnap.empty) {
      return {}
    }
    else{
      let seoRef = seoDocSnap.docs[0].ref
        this.seoDocRef = seoRef;
      let organicResultsSnap = await this.seoDocRef.collection("organicRanks")
                        .orderBy('created_on','desc')
                        .where('status', 'in', ["no-data", "completed"])
                        .get();
      if(organicResultsSnap.empty){
        return {}
      }
      else{
        let organicRankDetails = organicResultsSnap.docs[0].data();
        let organicDocRef = organicResultsSnap.docs[0].ref;
        let organicRankingsSnap = await organicDocRef.collection('organic_keywords').get();
        let organicRankAnalysisSnap = await organicDocRef.collection('prevRankAnalysis').get();
        let unresolvedOrganicRanks = []
        let unresolvedRankAnalysis = []
        organicRankingsSnap.forEach(doc => {
          let data = doc.data();
          unresolvedOrganicRanks.push(data);
        });
        organicRankAnalysisSnap.forEach(doc => {
          let data = doc.data();
          unresolvedRankAnalysis.push(data);
        });
        organicRankDetails['organicRanks']= await Promise.all(unresolvedOrganicRanks)
        organicRankDetails['rankAnalysis']= await Promise.all(unresolvedRankAnalysis)
        return organicRankDetails;
      }
    }
  }

  // to get organic rankings forcefully.
  async processOrganicRankings() {
    let accId = sessionStorage.getItem('current_acc_id');
    let data = { 
      "body":{
        accId: accId 
      }
    };
  //  return this.http.post(`${environment.cloudFunctionServerUrl}/setOrganicRankTask`, data).toPromise();getOrganicRankKeywords
   return this.http.post(`${environment.cloudFunctionServerUrl}/getOrganicRankKeywords`, data).toPromise();

  }

  async getPreviousRankAnalysis(){
    let organicRankDetails = {
    }
    let docSanp= await this.seoDocRef.collection("organicRanks")
                        .orderBy('created_on','desc')
                        .get();
    console.log(docSanp.docs[0].id);
    if(docSanp.docs[0].exists)
      organicRankDetails = docSanp.docs[0].data()

    this.seoDocRef.collection("organicRanks").doc(docSanp.docs[0].id)
    .onSnapshot(async (snapshot) => {
            // let organicRankingsSnap = await snapshot.ref.collection('organic_keywords').get();
            let organicRankAnalysisSnap = await snapshot.ref.collection('prevRankAnalysis').get();
            let unresolvedOrganicRanks = []
            let unresolvedRankAnalysis = []
            // organicRankingsSnap.forEach(doc => {
            //   let data = doc.data();
            //   unresolvedOrganicRanks.push(data);
            // });
            organicRankAnalysisSnap.forEach(doc => {
              let data = doc.data();
              unresolvedRankAnalysis.push(data);
            });
            // organicRankDetails['organicRanks']= await Promise.all(unresolvedOrganicRanks);
            organicRankDetails['rankAnalysis']= await Promise.all(unresolvedRankAnalysis);
            this.previousRankSubject.next(organicRankDetails)
    });
  }

  // to get history
  async getOrganicRankHistory(websiteUrl: string) {
    try{
      let url = new URL(websiteUrl);
      let pslResp = psl.get(url.host)
      let domain = pslResp;
      const seoDocSnap = await firebase.firestore().collection('seo')
        .where('url', '==', domain)
        .limit(1)
        .get();
      if (seoDocSnap.empty) {
        return {}
      }
      else{
        let seoRef = seoDocSnap.docs[0].ref
        this.seoDocRef = seoRef;
        let organicResultsSnap = await seoRef.collection('organicRanks')
          .orderBy('created_on', 'desc')
          .get()
        if (organicResultsSnap.empty) {
          return {}
        }
        else {
          return organicResultsSnap.docs.map(data=>{
            let obj = data.data();
            obj['id']=data.id;
            obj['ref'] = data.ref;
            return obj
          });
        }
      }
    }
    catch(e){
      console.log(e.message);
      return {'status':'error','message':e.message}
    }
  }

  async getOrganicRanksOfDoc(data){
    let organicResultsSnap = await data.ref.get();
    let organicRankDetails = organicResultsSnap.data();
    let organicDocRef = data.ref;
    let organicRankingsSnap = await organicDocRef.collection('organic_keywords').get();
    let organicRankAnalysisSnap = await organicDocRef.collection('prevRankAnalysis').get();
    let unresolvedOrganicRanks = []
    let unresolvedRankAnalysis = []
        organicRankingsSnap.forEach(doc => {
          let data = doc.data();
          unresolvedOrganicRanks.push(data);
        });
        organicRankAnalysisSnap.forEach(doc => {
          let data = doc.data();
          unresolvedRankAnalysis.push(data);
        });
        organicRankDetails['organicRanks']= await Promise.all(unresolvedOrganicRanks)
        organicRankDetails['rankAnalysis']= await Promise.all(unresolvedRankAnalysis)
        return organicRankDetails;
      }

      ngOnDestroy(): void {
        console.log("service destroyed")
        this.previousRankSubscribe();
      }

}
