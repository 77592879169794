<div class="container" style="padding: 20px;">
    <div class="row" *ngIf="allTask">
        <div class="col-md-6 d-flex d-lg-flex align-items-start align-items-lg-start">
            <div class="user_info">
                <div style="display:inline-block;">
                    <ngx-avatar name={{contactDetails?.first_name}}></ngx-avatar>
                </div>
                <!-- <span>{{fullName.firstName|titlecase}} {{fullName.lastName|titlecase}}</span> -->
            </div>
            <div class="d-inline-block user-details">
                <h5 class="d-inline">{{contactDetails?.first_name | titlecase}} {{contactDetails?.last_name |
                    titlecase}}<br></h5>
                <p style="margin-bottom: 0;">{{contactDetails?.email}}<br></p><span class="d-inline-block"
                    style="font-size: 13px;">{{contactDetails?.full_phone}}<br></span>
            </div>
        </div>
        <div class="col-md-6" style="padding: 0.5em 1em;">
            <div><span class="d-inline-block" style="color: #7e84f2;">Contact created on :&nbsp;<br></span><span
                    class="d-inline-block">{{contactDetails?.created_on | date:'MMM d, y h:mm a'}}
                    <br></span></div>
            <div><span class="d-inline-block highlighted-span">Source:&nbsp;<br></span><span class="d-inline-block"
                    style="text-transform: capitalize;">{{contactDetails?.source}}<br></span></div>
        </div>
    </div>
</div>
<div *ngIf="!allTask" id="gmbSpinnerDiv" style="text-align: center">
    <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
</div>
<div class="container">
    <div class="row" *ngIf="allTask">
        <div class="col-md-12">
            <!-- <div class="grey-column">
                <div class="row">
                    <div class="col-md-6">
                        <div class="task-div"><i class="fa fa-briefcase blue-icon"></i>
                            <div class="d-inline-block"><span class="d-inline-block" style="font-size: 16px;">Task
                                    Completed<br></span>
                                <p style="color: rgb(131,131,132);">Nov 21, 2021 10:00AM<br></p>
                            </div>
                        </div>
                        <div class="padding-right">
                            <div class="d-lg-flex justify-content-lg-start"><span class="d-inline">Call
                                    completed:&nbsp;<br></span><span class="d-inline-block highlighted-span">Follow-up
                                    sequence skipped<br></span></div>
                        </div>
                    </div>
                    <div class="col-md-6"></div>
                </div>
            </div>
            <div class="grey-column">
                <div class="row">
                    <div class="col-md-6">
                        <div class="task-div"><i class="fa fa-briefcase blue-icon"></i>
                            <div class="d-inline-block"><span class="d-inline-block" style="font-size: 16px;">Task
                                    assigned to doctor to call the lead.&nbsp;<br></span>
                                <p style="color: rgb(131,131,132);">Nov 21, 2021 10:00AM<br></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-inline-block d-lg-flex align-items-lg-center icons-div"><i
                                class="fa fa-comments-o green-icon"></i><i class="fa fa-envelope-o grey-icon"></i></div>
                    </div>
                </div>
            </div>
            <div class="grey-column">
                <div class="row">
                    <div class="col-md-6">
                        <div class="task-div"><i class="fa fa-briefcase blue-icon"></i>
                            <div class="d-inline-block"><span class="d-inline-block" style="font-size: 16px;">Task
                                    Completed<br></span>
                                <p style="color: rgb(131,131,132);">Nov 21, 2021 10:00AM<br></p>
                            </div>
                        </div>
                        <div class="padding-right">
                            <div class="d-lg-flex justify-content-lg-start"><span class="d-inline">Patient not
                                    answering:&nbsp;<br></span><span class="d-inline-block highlighted-span">Next
                                    follow-up in 2 days<br></span></div>
                        </div>
                    </div>
                    <div class="col-md-6"></div>
                </div>
            </div>
            <div class="grey-column">
                <div class="row">
                    <div class="col-md-6">
                        <div class="task-div"><i class="fa fa-briefcase blue-icon"></i>
                            <div class="d-inline-block"><span class="d-inline-block" style="font-size: 16px;">Task
                                    assigned to doctor to call the lead.&nbsp;<br></span>
                                <p style="color: rgb(131,131,132);">Nov 21, 2021 10:00AM<br></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-inline-block d-lg-flex align-items-lg-center icons-div"><i
                                class="fa fa-comments-o green-icon"></i><i class="fa fa-envelope-o grey-icon"></i></div>
                    </div>
                </div>
            </div>
            <div class="grey-column">
                <div class="row">
                    <div class="col-md-6">
                        <div class="task-div"><i class="fa fa-bell purple-icon"></i>
                            <div class="d-inline-block"><span class="d-inline-block" style="font-size: 16px;">Follow-up
                                    sent to the lead to fill out the Questionnaire.<br></span>
                                <p style="color: rgb(131,131,132);">Nov 20, 2021 10:00AM<br></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-inline-block d-lg-flex align-items-lg-center icons-div"><i
                                class="fa fa-comments-o green-icon"></i><i class="fa fa-envelope-o grey-icon"></i></div>
                    </div>
                </div>
            </div> -->
            <div *ngFor="let task of allTask">
                <div class="grey-column">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="task-div">
                                <i *ngIf="task?.type == 'TASK' " class="fa fa-briefcase blue-icon"></i>
                                <i *ngIf="task?.type == 'APPOINTMENT_BOOKED'" class="fa fa-calendar-check green-icon"
                                    style="font-size: 25px;padding :10px 10px 0 0"></i>
                                <i *ngIf="task?.type == 'ENQUIRY_FORM_SUBMISSION'" class="fa fa-file-alt green-icon"
                                    style="font-size: 25px;padding :10px 10px 0 0"></i>
                                <i *ngIf="task?.type == 'NEW_CONTACT'" class="fa fa-user-circle-o purple-icon"> </i>
                                <i *ngIf="task?.type == 'EXIST_CONTACT'" class="fa fa-file-alt purple-icon"> </i>
                                <i *ngIf="task?.type ==  'SEND_SMS' || task?.type == 'SEND_EMAIL'"
                                    class=" fa fa-bell purple-icon"></i>

                                <div class="d-inline-block"><span class="d-inline-block"
                                        style="font-size: 16px;">{{task?.title}}<br></span>
                                    <p style="color: rgb(131,131,132);">{{task?.createdOn.toDate() | date:'MMM d, y
                                        h:mm
                                        a'}}<br></p>
                                </div>
                            </div>
                            <div class="padding-right" *ngIf="task?.description">
                                <div class="d-lg-flex justify-content-lg-start"><span
                                        class="d-inline">{{task?.description}}<br></span><span
                                        class="d-inline-block highlighted-span"><br></span></div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-inline-block d-lg-flex align-items-lg-center icons-div">
                                <i *ngIf="task?.sendType == 'SMS' " class="fa fa-comments-o green-icon"></i>
                                <i *ngIf="task?.sendType == 'EMAIL' " class="fa fa-envelope-o grey-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>