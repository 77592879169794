import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import * as moment from 'moment';
import { ReviewsService } from '../../_services/reviews.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TimeoutError } from 'rxjs';
@Component({
  selector: 'app-post-review-on-social-media',
  templateUrl: './post-review-on-social-media.component.html',
  styleUrls: ['./post-review-on-social-media.component.scss']
})
export class PostReviewOnSocialMediaComponent implements OnInit {
  reviewData: any;
  popupHeader: any;
  updateComment: boolean;
  comment: string;
  reviewerName: string;
  postUrl: string;
  loading: boolean;
  moment: any = moment;
  additionlSettings = {
    zoom: 3,
    width: 400,
    height: 400,
    quality: 100
  }
  socialMediaPostFormGroup = new FormGroup({
    reviewerName: new FormControl('', [Validators.required]),
    commentFormControl: new FormControl('', [Validators.required]),
    width: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    height: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    zoom: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
  });
  timeoutError: boolean;
  errorFound: boolean;
  isErrorOccure: boolean;
  isTemplateExists: boolean;
  loadingForAll: boolean;
  selectmultipleTemplate: boolean;
  templateData: any = [];
  selectedTemplate: any;
  showPreviewOfTemplate: boolean;
  previewTemplate: any;
  showPreviewLoading: boolean;
  commentCharLimit: number = 50;
  commentLimit: number = 0;
  overallWordCountForComment: number = 500;
  factor: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private reviewsService: ReviewsService,
    public matdialogRef: MatDialogRef<PostReviewOnSocialMediaComponent>) { }

  ngOnInit(): void {
    this.getTemplate();
  }

  async getTemplate() {
    this.loadingForAll = true;
    this.reviewsService.getReviewTemplates().then(async (snapshot) => {
      if (!snapshot.empty) {
        snapshot.forEach(template => {
          this.templateData.push(template.data());
        });
        if (this.templateData.length >= 0) {
          this.selectedTemplate = this.templateData[0];
          this.previewTemplate = this.selectedTemplate.previewTemplateUrl;
          this.commentLimit = this.selectedTemplate.commentLimit ? this.selectedTemplate.commentLimit : 500;
        }
        this.isTemplateExists = true;
      }
      else {
        this.isTemplateExists = false;
      }
      this.reviewData = this.data.data;
      this.popupHeader = this.data.popupHeader;
      this.postUrl = this.reviewData.postUrl;
      this.comment = this.reviewData.comment;
      this.reviewerName = this.reviewData.reviewer.displayName;
      this.factor = this.commentLimit / 100;
      this.commentCharLimit = (this.comment.length / this.factor);
      this.commentCharLimit = Math.ceil(this.commentCharLimit);
      if (this.commentCharLimit > 100)
        this.commentCharLimit = 100;
      this.loadingForAll = false;
    })
  }

  getSelectedTemplate(event) {
    this.selectedTemplate = event.value;
    this.previewTemplate = this.selectedTemplate.previewTemplateUrl;
    this.commentLimit = this.selectedTemplate.commentLimit ? this.selectedTemplate.commentLimit : 500;
    this.factor = this.commentLimit / 100;
    this.updateProgressBar();
    this.showPreviewLoading = true;

  }

  updateProgressBar() {
    this.commentCharLimit = (this.comment.length / this.factor);
    this.commentCharLimit = Math.ceil(this.commentCharLimit);
    if (this.commentCharLimit > 100)
      this.commentCharLimit = 100;
  }

  updateCommentForPost() {
    this.selectmultipleTemplate = true;
    this.updateComment = true;
    this.showPreviewOfTemplate = true;
    this.comment = this.reviewData.comment;
    this.reviewerName = this.reviewData.reviewer.displayName;
    this.updateProgressBar();
  }

  generateUrl() {
    this.loading = true;
    this.isErrorOccure = false;
    this.timeoutError = false;
    this.errorFound = false;
    this.showPreviewOfTemplate = false;
    this.showPreviewLoading = true;
    this.reviewsService.getImageUrlForSocialMediaPost(this.reviewData, this.comment, this.reviewerName, this.additionlSettings, this.selectedTemplate)
      .subscribe(response => {
        this.postUrl = response.url;
        this.reviewsService.deleteUrlFromStorage(this.reviewData.postUrl).then(() => {
          this.reviewData.postUrl = this.postUrl;
        })
        this.updateComment = false;
        this.loading = false;
      },
        (error) => {
          this.isErrorOccure = true;
          if (error instanceof TimeoutError) {
            this.timeoutError = true;
            this.errorFound = false;
          }
          else {
            this.errorFound = true;
            this.timeoutError = false;
          }
          this.loading = false;
          this.updateComment = false;
        });
  }

  downloadImage() {
    let fileName = this.reviewData.reviewer.displayName + " " + "review.png";
    this.reviewsService.downloadFile(this.postUrl, fileName);
  }

  back() {
    this.updateComment = false;
    this.selectmultipleTemplate = false;
    this.showPreviewOfTemplate = false;
    this.comment = this.reviewData.comment;
    this.reviewerName = this.reviewData.reviewer.displayName;
    this.updateProgressBar();
  }

  reset() {
    this.reviewerName = this.reviewData.reviewer.displayName;
    this.comment = this.reviewData.comment;
    this.updateProgressBar();
    this.additionlSettings = {
      zoom: 3,
      width: 1200,
      height: 1200,
      quality: 100
    }
    this.getCommentText();
  }

  imageLoadingStatus(event: any) {
    this.showPreviewLoading = false;
  }

  getCommentText() {
    this.commentCharLimit = (this.comment.length / this.factor);
    this.commentCharLimit = Math.ceil(this.commentCharLimit);
    if (this.commentCharLimit > 100)
      this.commentCharLimit = 100;
  }

}
