import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import firebase from 'firebase/app';
import 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor() {
  }

  //to check if email already assigned to any account and if yes then check acknowledgement accepted  or not.
  async checkEmailAlreadyPresent(email) {
    let userSnap = await firebase.firestore().collection("users").where('email', '==', email.toLowerCase()).get();
    if (userSnap.docs.length == 0) {
      console.log("user not present");
      return { newUser: true, acceptedAcknowledge: false };
    }
    else {
      console.log("User already exist");
      let userInfo = userSnap.docs[0].data();
      let accId = userInfo.accounts[0];
      let accInfo = (await firebase.firestore().collection("accounts").doc(accId).get()).data();
      if (accInfo.acknowledged == true) {
        return { newUser: false, acknowledged: true, accId: accId };
      }
      else {
        return { newUser: false, acknowledged: false, accId: accId };
      }
    }
  }

  addDemoDataToAccount(accId) {
    firebase.functions().httpsCallable('addDummyDataToAccount')({ demoAccId: environment.demoAccId, newAccId: accId }).then(
      resp => {
        console.log(resp);
      },
      err => {
        console.error(err);
      }
    );
  }

  sendEmailVerificationOtp(email, otp) {
    let body = `<p>Hi,</p>
          <p><b>${otp}</b> is One time password (OTP) for your Mktg.doctor account.Please do not share this.If not requested, please inform us.</p>
          <p>Thanks,<br>The Mktg Doctor Team</p>`
    firebase.functions().httpsCallable('sendEmail')({
      "to_email": email,
      "subject": "OTP for signup",
      "body": body,
      "from": "appointments@nhansmedia.com"
    }).then(resp => {
      console.log(resp);
    },
      err => {
        console.log(err);
      })
  }
  generateOtp() {
    let otp = Math.floor(100000 + Math.random() * 900000);
    return otp;
  }

  sendSms(messageObj: any) {
    // messageObj['acc_id'] = sessionStorage.getItem('current_acc_id');
    // console.log(messageObj);
    (firebase.functions().httpsCallable('sendSms'))(messageObj)
      .then(
        result => {
          // console.log(result);
          console.log('success');
        })
      .catch(error => {
        console.log(error);
      });
  }

  async getTwilioCredentials() {
    let twilioCreds = await (await firebase.firestore().collection('generalSettings').doc('config').get()).data()['twilio_accounts'];
    return twilioCreds;
  }
}
