import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RewardCampaignService } from '../_services/reward-campaign.service';
import { AccountService } from '../_services/account.service';
import { Router, NavigationExtras } from '@angular/router';
@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit, OnChanges {
  algoliaContactList: any;
  searchWord: any;
  twilioCredentials: any;

  constructor(
    private rewardService: RewardCampaignService,
    private accountService: AccountService,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchWord']) {
      if (changes['searchWord']['firstChange']) {
        if (changes['inputText']['currentValue']) {
          this.searchWord = changes['searchWord']['currentValue'];
          this.getTwilioCredentials().then(resp => {
            console.log("on chnage", this.searchWord);
            this.fetchContactByAlgolia();
          });
        }
      }
    }

  }

  ngOnInit(): void {
    this.getTwilioCredentials().then(resp => {
      if (this.searchWord == undefined)
        this.searchWord = "";
      console.log("on init", this.searchWord);
      this.fetchContactByAlgolia();
    }
    )
  }

  async getTwilioCredentials() {
    this.twilioCredentials = await this.accountService.getTwilioCredentials("twilio")
  }

  fetchContactByAlgolia() {
    console.log("opened list");
    this.rewardService.fetchContactByPagingAlgolia(this.searchWord).then((resp: any) => {
      this.algoliaContactList = resp.hits;
      console.log(this.algoliaContactList);
    });

  }

  getRewardDetails(contact: any) {
    console.log(contact);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "contact": contact.objectID
      }
    };

    this.router.navigate(['/reward-details'], navigationExtras);
  }

}
