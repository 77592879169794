import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";

export interface DialogData {
  link: string;
  title: string;
  apptDetails: any;
}

@Component({
  selector: "app-appointment-details",
  templateUrl: "./appointment-details.component.html",
  styleUrls: ["./appointment-details.component.scss"],
})
export class AppointmentDetailsComponent {
  link: any;
  title: any;
  detailsArray: any = [];
  name: string;
  apptData: any;
  constructor(
    public dialogRef: MatDialogRef<AppointmentDetailsComponent>,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    // this.link = this.sanitizer.bypassSecurityTrustResourceUrl(data.link);
    this.title = data.title;
    // console.log(this.link);
    let details = data.link;
    console.log(details);
    this.apptData = data;
    // this.name=details.title;
    // console.log(details.changingThisBreaksApplicationSecurity.name);
    // this.detailsArray.push(details.changingThisBreaksApplicationSecurity);
    // console.log(this.detailsArray)
    // this.name = this.detailsArray.name;
  }

  close() {
    this.dialogRef.close();
  }
}
