<div>
  <div *ngIf="loading; else loaded">
    <div style="height: fit-content;width:fit-content;margin: auto;">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <ng-template #loaded>
    <div *ngIf="detailsExist;else noLocalRank">
      <p-accordion [at]>
        <p-accordionTab header="Fetched On : {{localRankDetails?.created_on.toDate() | date:'MMM d, y, hh:mm a'}}"
          [selected]="false">
          <div class="col-md-12" style="display:flex;margin-bottom:10px;">
            <label><b>Keywords:</b></label>
            <div style="margin:0 10px;text-align: start;display:flex;flex-wrap:wrap;white-space: nowrap;">
              <div *ngFor="let kw of localRankDetails?.targetKw;let last = last">{{kw}}<span
                  *ngIf="!last">,&nbsp;</span>
              </div>
            </div>
          </div>
          <div class="row" style="text-align: start;margin:0;">
            <div class="col-md-6">
              <div style="display:flex;white-space: nowrap;">
                <label><b>Locations:</b></label>&nbsp;
                <div>
                  <div *ngFor="let location of localRankDetails?.locations">{{replace(location)}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div style="width:100%;display:flex;flex-wrap: wrap;white-space: nowrap;">
                <div style="margin: 0 15px 5px;">
                  <label><b>Region:</b></label>&nbsp;{{localRankDetails?.countryRegion.code}}
                </div>
                <div style="margin: 0 15px 10px;">
                  <label><b>Radius:</b></label>&nbsp;{{localRankDetails?.radius}}
                </div>
                <!-- <div style="margin: 0 15px 10px;">
                  <label><b>Fetched On:</b></label>
                  &nbsp;{{localRankDetails?.created_on.toDate() | date:'MMM d, y, hh:mm a'}}
                </div> -->
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>

      <div style="width:100%;">
        <p-table [value]="competitorsAnalysis" dataKey="keyword" [paginator]="true" [rows]="20" [responsive]="true"
          paginatorPosition="bottom" sortMode="multiple">
          <ng-template pTemplate="header">
            <tr style="text-align: center;">
              <th colspan="2">Name</th>
              <th pSortableColumn="avgRankPosition" matTooltip="Average Rank Position"><span>
                ARP <p-sortIcon field="avgRank"></p-sortIcon>
              </span>
              </th>
              <th pSortableColumn="avgTotalRankPosition" matTooltip="Average Total Rank Position">ATRP <p-sortIcon field="avgRank"></p-sortIcon>
              </th>
            </tr>
            <tr>
              <td colspan="2"><b>{{domainRankDetails.name}}</b> <i *ngIf="domainRankDetails.website"
                  class="fas fa-external-link-alt openlink" matTooltip="Open link"
                  (click)="openLink(domainRankDetails.website)"></i></td>
              <td><b>{{domainRankDetails.avgRankPosition | number }}</b></td>
              <td><b>{{domainRankDetails.avgTotalRankPosition | number }}</b></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td colspan="2">{{rowData.name}} <i *ngIf="rowData.website" class="fas fa-external-link-alt openlink"
                  matTooltip="Open link" (click)="openLink(rowData.website)"></i></td>
              <td>{{rowData.avgRankPosition | number }}</td>
              <td>{{rowData.avgTotalRankPosition | number }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <ng-template #noLocalRank>
      You don't have local ranking.
    </ng-template>
  </ng-template>
</div>
