import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MarketingOffersService } from "../_services/marketing-offers.service";

@Component({
  selector: 'app-marketing-offers',
  templateUrl: './marketing-offers.component.html',
  styleUrls: ['./marketing-offers.component.scss']
})
export class MarketingOffersComponent implements OnInit {

  showList: boolean = true;
  offerDetail: any = {};
  emitUpdateOffer: boolean = false;
  currentOfferData = [];
  pastOfferData = [];
  upcommingOfferData = [];
  showOffers: boolean = true;
  currentOfferDataFiltered = [];
  pastOfferDataFiltered = [];
  upcommingOfferDataFiltered = [];


  constructor(
    private marketingOffers: MarketingOffersService,
  ) { }

  ngOnInit() {
    let accId = sessionStorage.getItem('current_acc_id');
    this.marketingOffers.getCurrentOffers(accId);

    let accID = sessionStorage.getItem('current_acc_id');
    this.marketingOffers.getCurrentOffers(accID).then(offers => {
      this.currentOfferData = offers;
      this.marketingOffers.getPastOffers(accID).then(pastOffers => {
        this.pastOfferData = pastOffers
      });
      this.marketingOffers.getUpcomingOffers(accID).then(comingOffer => {
        this.upcommingOfferData = comingOffer;
      });
      this.showOffers = true;
      console.log(this.currentOfferData);
      console.log(this.pastOfferData);
      console.log(this.upcommingOfferData);
    });



  }
  closeCreateModel() {
    this.showList = true;
  }
  openCreateModel() {
    this.offerDetail = {};
    this.emitUpdateOffer = false;
    this.showList = false;
  }
  messageFromCreateOffer(data) {
    this.closeCreateModel();
  }
  editSelectedOffer(data) {
    this.offerDetail = data.offerDetails;
    this.emitUpdateOffer = true;
    this.showList = false;
    // this.openCreateModel();
  }

  searchForm(event) {
    let s = event.target.value;
    this.currentOfferDataFiltered = this.currentOfferData.filter((currOffer) => {
      return currOffer.offerName.toLowerCase().indexOf(s.toLowerCase()) != -1;
    });

    this.pastOfferDataFiltered = this.pastOfferData.filter((pastOffer) => {
      return pastOffer.offerName.toLowerCase().indexOf(s.toLowerCase()) != -1;
    });

    this.upcommingOfferDataFiltered = this.upcommingOfferData.filter((upcomingOffer) => {
      return upcomingOffer.offerName.toLowerCase().indexOf(s.toLowerCase()) != -1;
    });
  }

  openOfferSetting() {
    this.showList = false;
  }

}
