<!-- Was supposed to be a landing page for email campaigns -->
  <div class="container-fluid">
    <div class="col-lg-12">
    <p> Let's face it. Digital marketing is complicated. It is a mix of art and science, in a landscape, where new channels and formats keep emerging. However, digital marketing is important for sustained growth of your medical practice. That is the reason we created "mktgbot". An AI powered digital marketing plaftorm for your practice, where we'd like to think of AI as art + intelligence.
    </p>
    </div>
    <div class="col-lg-12">
      <center> <h1> Simplify digital marketing. </h1> </center>
      <center> <h3> Get results you have never seen before. </h3> </center>
      <p> Think of "mktgbot" as a personal assistant or an advisor for all the digital marketing needs of your medical practice. Powered by AI and an easy to use interface, mktgbot understands the challenges and opportunities of your practice and helps you get the best returns on your marketing investment.
      </p>
      <center><span class=""><button mat-raised-button color="primary" class="large-button" [routerLink]="['/signup']"> Learn More </button></span></center>
    </div>
    <hr>
    <div class="col-lg-12">
      <center><mat-icon>visibility</mat-icon><h1> Complete Visibility. </h1></center>
      <center><h3> <i> Do you ever ask yourself, where are your marketing $$ spent?</i> </h3></center>
      <center><p> See the total marketing spends and performance of your Facebook, Google, and email marketing campaigns, in real-time,  on the mktgbot dashboard.</p></center>
      <center><img height="400px" src="../../assets/images/mobile-ss-1.svg"></center>
    </div>
    <hr>
    <div class="col-lg-12">
      <center> <h1> Take Control. </h1> </center>
      <center> <h3> <i> Being in control does not mean you're on your own.</i> </h3> </center>
      <p> The mktgbot AI analyzes your campaigns and competitors to automatically generates optimization tips and new campaign ideas, personalized for your practice. The mktgbot software continuously optimizes your campaigns for maximum returns. But you're still in control. You can Accept, Reject or Snooze every tip with a click of a button. Only accepted tips are activated. Or you can choose to run some campaigns on auto-pilot and mktgbot will automatically apply optimizations to get you the best results.
      </p>
      <div class="col-lg-6">
        <img class = "image1" height="400px" src="../../assets/images/mobile-ss-4.svg">
      </div>
      <div class="col-lg-6">
        <img height="400px" src="../../assets/images/mobile-ss-4.svg">
      </div>
    </div>
    <div class="col-lg-12">
      <center><h1> Return-on-investment. </h1></center>
      <center><h3><i class = "italics"> Is digital marketing really working for you? How well?</i></h3></center>
      <center><p> mktgbot automatically links leads generated and revenue booked to your marketing campaigns to show you the true return-on-investment on your marketing spend. </p></center>
    </div>
    <hr>
    <div class="col-lg-12">
      <center><h1> Performance. </h1></center>
      <center><h3><i> Why mktgbot outperforms?</i> </h3></center>
      <center><p> While most of the agencies will optimize your campaigns once a month, mktgbot AI machines optimize your campaigns continuously. This results in fewer to none lost opportunities and exponentially improves performance. mktgbot also optimizes your campaigns across multiple channels such as Facebook, Google and email to give you the best results. </p></center>
    </div>
    <hr>
    <div class="col-lg-12">
      <center><mat-icon class="myicons">attach_money</mat-icon><h1> Pricing. </h1></center>
      <center><h3><i> What will this cost you? </i> </h3></center>
      <center><p> Our pricing model is based on reducing and in some cases eliminating any fixed costs that you pay to the marketing agencies as "retainers"; on top of the percentage of media spend they charge you as "commissions". Our goal is to spend most of your money on working media. Contact us for pricing. </p></center>
      <center><span class=""><button mat-raised-button color="primary" class="large-button" [routerLink]="['/signup']"> Sign-up for a free trial </button></span></center>
    </div>
    </div>
