<div class="row" id="subHeader1">
    <div class="col-md-4"></div>
    <div class="col-md-4">
        <div style="margin:5px auto 10px;font-size: 20px;font-weight: 500;text-align: center;">Reviews</div>
    </div>
    <div class="col-md-4" style="text-align: right;margin-top: 3px;">



        <button *ngIf="allSet && locationLinks.length ==1" [disabled]="!isAccountExists" style="margin-right: 10px;"
            [cdkCopyToClipboard]="copy()" mat-mini-fab id="button" class="format" color="primary" [matTooltip]="tooltip"
            (click)="tooltip ='Copied' " (mouseleave)="tooltip = 'Copy Message'" [matTooltipHideDelay]="1000">
            <span class="material-icons" style="cursor: pointer;text-align: right;" color="primary">content_copy</span>
        </button>
        <div class="dropdown" *ngIf="allSet && locationLinks.length >1">
            <button (mouseenter)="popup = true" *ngIf="allSet" [disabled]="!isAccountExists" style="margin-right: 10px;"
                mat-mini-fab id="button" class="format" color="primary" [matTooltipHideDelay]="1000">
                <span class="material-icons" style="cursor: pointer;text-align: center;"
                    color="primary">content_copy</span>
            </button>
            <div class="dropdown-content" *ngIf="popup">
                <div *ngFor="let  location of locationLinks">
                    <a (click)="locationLink(location)" [cdkCopyToClipboard]="isMessageCopied?copiedMessage:''"
                        style="font-size: 14px;">
                        {{location.locationName}}
                    </a>
                </div>
                <div>

                </div>
            </div>
        </div>


        <button *ngIf="allSet && isAccountExists" style="margin-right: 10px;" mat-raised-button color="primary"
            (click)="sendReviewInvite()">Send
            Review Request
        </button>

        <button mat-mini-fab id="button" *ngIf="allSet" class="format" color="primary"
            (click)="openDialogForPatientConfiguration()" matTooltip="Review configuration">
            <span class="material-icons" style="cursor: pointer;text-align: right;" color="primary">settings</span>
        </button>
        <!-- <button mat-mini-fab id="button" class="format" (click)="openDialogForSendPatientForm()" matTooltip="Send patient form">
            <i class="pi pi-send" style="font-size: 1rem"></i>
          </button> -->
    </div>
</div>
<div class="row" id="subHeader2" style="width: 100%;margin: 0px;">
    <div *ngIf="allSet else notSet" style="margin: 22px 0px 0px 0px;width: 100%;">
        <div class="alert alert-danger" style="margin-bottom: 10px;" role="alert" id="all"
            *ngIf="isReviewReady && !isAccountExists">
            Your Google My Business account is disconnected!
            <div style="float: right;">
                <a routerLink="/data-channels" routerLinkActive="active">Click here to connect</a>
            </div>
        </div>

        <div *ngIf="isReviewReady else askForAddAccount">
            <div *ngIf="!noReviews; else no">
                <div *ngIf="!isReviewReady" id="spinner">
                    <div id="spinnerDiv">
                        <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                    </div>
                </div>

                <div *ngIf="isReviewReady else noReviews" style="height: 100%;width: auto; ">

                    <div class="row">
                        <div class="col-md-10" *ngIf="reviews" id="reviewCards"
                            style=" overflow: hidden;height: 80vh;margin-left: -34px;">
                            <div class="row" style="padding:0px;">
                                <div *ngIf="true" class="col-md-4" style="padding-left: 75px;">
                                    <h6>You have {{totalReview}} Reviews.</h6>
                                </div>
                                <div class="col-md-3">
                                    <div class="row" *ngIf="averageRating" matTooltip="Average Rating">
                                        <h5 style="padding-right: 10px;">{{averageRating | number : '1.1-2'}}</h5>
                                        <p-rating [ngModel]="averageRating" readonly="true" stars="5" [cancel]="false">
                                        </p-rating>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                </div>
                                <div class="col-md-2">
                                </div>
                            </div>

                            <div style="height: 100%;padding-left: 40px;">
                                <app-reviews-data *ngIf="isReady" [isAccountExists]="isAccountExists"
                                    [reviews]="reviews" [fetchReview]="fetchReview" [popupLoder]="popupLoder"
                                    (nextDataEmitter)="loadData($event)">
                                </app-reviews-data>
                                <div *ngIf="!isReady" id="spinner">
                                    <div id="spinnerDiv">
                                        <i class="pi pi-spin pi-spinner" id="spinIcon"></i>
                                    </div>
                                </div>
                                <div *ngIf="!isReviewExists" style="width: 100%;height: auto;">
                                    <div style="text-align: center;font-weight: 500;margin-top: 10%;">
                                        No results found<br><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 " style="align-items: center;">
                            <!-- <div style="text-align: center;margin-top: 0%;">
                                <button mat-raised-button color="primary" (click)="sendReviewInvite()">Send Review Request</button>
                            </div> -->

                            <div style="margin-top: 20%;font-weight: 500;" class="row">
                                <div class="col" style="float: left;">Filter by</div>
                            </div>

                            <div style="text-align: center;margin-top: 10%;">
                                <p-dropdown [options]="reviewSortOnRating" [(ngModel)]="selectedReviewRecent"
                                    [showClear]="true" placeholder="Recent reviews" optionLabel="type"
                                    (onChange)="getDropdownValue($event.value,'recent')"
                                    [style]="{'height':'2.5rem','padding-left':'10px','width':'11rem'}">
                                </p-dropdown>
                            </div>

                            <div style="text-align: center;margin-top: 10%;">
                                <p-dropdown [options]="gmbLocationData" [(ngModel)]="selectedLocation"
                                    [showClear]="true" placeholder="Location" optionLabel="locationName"
                                    (onChange)="getDropdownValue($event.value,'location')"
                                    [style]="{'height':'2.5rem','padding-left':'10px','width':'11rem'}">
                                </p-dropdown>
                            </div>

                            <!-- <div style="text-align: center;margin-top: 10%;">
                                <p-dropdown [options]="reviewsSource" [(ngModel)]="selectedReviewSource"
                                    [showClear]="true" placeholder="Review source" optionLabel="key" autoWidth="false"
                                    (onChange)="getDropdownValue($event.value,'source')"
                                    [style]="{'width':'11rem','height':'2.5rem','padding-left':'10px'}">
                                </p-dropdown>
                            </div> -->
                            <div style="text-align: center;margin-top: 10%;">
                                <p-dropdown [options]="reviewTimeSortTime" [(ngModel)]="selectedReviewTime"
                                    [showClear]="true" placeholder="Time period" optionLabel="type" autoWidth="false"
                                    (onChange)="getDropdownValue($event.value,'time')"
                                    [style]="{'width':'11rem','height':'2.5rem','padding-left':'10px'}">
                                </p-dropdown>
                            </div>
                            <div style="text-align: center;margin-top: 10%;" class="row">
                                <div class="col-md-6">
                                    <button mat-raised-button color="primary" [disabled]="disabled"
                                        (click)="filterReviews()">Apply</button>
                                </div>
                                <div class="col-md-6">
                                    <button mat-raised-button color="primary" [disabled]="disabled"
                                        (click)="clearReviews()">Clear</button>
                                </div>
                            </div>
                            <!-- <div style="text-align: center;margin-top: 30%;">
                                <button mat-raised-button class="sendReviewInitebtn">Send review invite</button>
                            </div> -->
                        </div>
                    </div>

                </div>
            </div>
            <ng-template #no>
                <div id="NoReviews" *ngIf="isAccountExists"> No Reviews </div>
            </ng-template>
        </div>
        <ng-template #askForAddAccount>
            <div id="NoReviews" *ngIf="!isAccountExists else nothing">
                <br>
                You Google My Business account is not connected<br>
                <br>
                <button mat-raised-button color="prinary" routerLink="/account" routerLinkActive="active">Click here to
                    add new Google My Bussiness Account</button>
            </div>
            <!-- <ng-template #nothing style="width:100%;height: auto;">
                <div style="text-align: center;padding-top: 10%;font-size: 20px;">
                    <b>No Reviews</b>
                </div>
            </ng-template> -->
        </ng-template>
    </div>
    <ng-template #notSet style="width: 100%;">
        <div id="spinnerDiv" style="width: 100%;text-align: center;">
            <i class="pi pi-spin pi-spinner" id="spinIcon" style="font-size: 3rem;color: #7e84f2;"></i>
        </div>
    </ng-template>
</div>