import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import firebase from "firebase";

import "firebase/firestore";

@Injectable({
  providedIn: "root"
})
export class ConfigurationService {
  ref: any;

  constructor(private http: HttpClient) {}

  getConfiguration() {
    return this.http.get("../assets/json/demo-calendar.json");
  }

  setData(config, ref) {
    /*firebase.firestore().collection('accounts').doc('5MEDGlY1uCVzu7TzHbAH')
                                 .collection('calendars').doc('bVXjxSr34NglXD5HX3yj')
                                  .update(config);*/

    ref.update(config);
  }

  // This function does not do anything. SHOULD BE REMOVED!
  getAccounts() {
    firebase
      .firestore()
      .collection("accounts")
      .get()
      .then(accountsSnapshot => {
        accountsSnapshot.forEach(docSnapshot => {});
      });
  }

  // This function is fetching all calendar objects for the current account, and it is name as getAccount.
  // WRONG NAME
  getAccount() {
    let acc_id = sessionStorage.getItem("current_acc_id");
    var array = firebase
      .firestore()
      .collection("accounts")
      .doc(acc_id)
      .collection("calendars")
      .get();
    return array;
    /*return firebase.firestore().collection('accounts').doc('5MEDGlY1uCVzu7TzHbAH')
                              .collection('calendars').doc('bVXjxSr34NglXD5HX3yj')
                              .get();*/
  }

  async updateProvider(providerId, calendarRef) {
    let accId = sessionStorage.getItem("current_acc_id");
    const providerData = await firebase
      .firestore()
      .collection("accounts")
      .doc(accId)
      .collection("providersDetails")
      .doc(providerId)
      .get();
    // then(
    //   providerSnap =>{
    if (providerData.data().calendar_link !== undefined) {
      let calendars = providerData.data().calendar_link;
      calendars.push(calendarRef);
      const updatedProvider = await firebase
        .firestore()
        .collection("accounts")
        .doc(accId)
        .collection("providersDetails")
        .doc(providerId)
        .update({ calendar_link: calendars });
    } else {
      let newDoc = {
        calendar_link: [calendarRef],
        linked_calendar: "true"
      };
      const updatedProvider = await firebase
        .firestore()
        .collection("accounts")
        .doc(accId)
        .collection("providersDetails")
        .doc(providerId)
        .update(newDoc);
      // });
    }
  }

  async addCalendar(config, providerRef) {
    let acc_id = sessionStorage.getItem("current_acc_id");
    firebase
      .firestore()
      .collection("accounts")
      .doc(acc_id)
      .collection("calendars")
      .add(config)
      .then(docRef => {
        return this.updateProvider(providerRef, docRef);
      });
  }
}
