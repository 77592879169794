import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { ApiService } from '../../_services/index';
import { MarketingPlanService, AlertService, UserService } from '../../_services/index';
import { TrafficSummary } from '../../_models/index';
import {Pipe,PipeTransform} from '@angular/core';
import { DecimalPipe } from '@angular/common';
//import { Tactics } from '../_models/index';
// @Pipe({name: 'round'})
// export class RoundPipe implements PipeTransform{}

@Component({
moduleId: module.id.toString(),
  selector: 'app-summary-plan',
  templateUrl: './summary-plan.component.html',
  styleUrls: ['./summary-plan.component.scss']
})



export class SummaryPlanComponent implements OnInit {
  @Output() savedSpecifics = new EventEmitter<boolean>();
 model: any = {};
 duration:number = 3;
 budget:number = 1000;
 spend:number = this.duration * this.budget;
 tactics: any = {};
 isReady: boolean = false;
 months: any = false;
 //months_resp: any = {three: 3,six: 6};
 allocation: any = [0.0,0.0];
 display: any = [0.0,0.0];

 constructor (
 private apiService:ApiService,
 private marketingPlanService : MarketingPlanService,
 private alertService:AlertService,
 private userService: UserService )
 {
    marketingPlanService.specifics$.subscribe(input =>{
     this.duration = input.duration;
     this.budget = input.budget;
     if(this.duration == 6){
       this.months = true;
     }
   });
     apiService.tactics$.subscribe( input => this.fetchTactics(input));
 }

/*
 * Call service to save duration, budget and allocation to DB
 */
 saveSpecifics() {
   let planID = this.marketingPlanService.getPlanId();

   let input = {"plan_id": planID, "duration": this.duration, "budget": this.budget, "adwords": this.allocation[0], "facebook": this.allocation[1]};
   this.apiService.saveSpecifics(input)
       .subscribe(
         data => {
         },
         error => {
               this.alertService.error("Could not save plan");
             });
   this.savedSpecifics.emit(true);
 }

callData(i:any)
{
   switch(i)
   {
     case 0:
             let res1=100-this.display[0];
             this.display[1] = parseFloat(res1.toFixed(2));
             break;
     case 1:
             let res2=100-this.display[1];
             this.display[0] = parseFloat(res2.toFixed(2));
             break;
     default:
            break;
    }
    this.allocation[0]=this.display[0]/100;
    this.allocation[1]=this.display[1]/100;
}

 storeTactics(tactics: any) {
  this.tactics = tactics;
  this.allocation[0] = this.tactics.breakdown[0].allocation;
  this.allocation[1] = this.tactics.breakdown[1].allocation;
  this.display[0]=this.allocation[0]*100;
  this.display[1]=this.allocation[1]*100;

  //this.clicks = this.tactics.clicks * ((this.duration * this.budget) / 1000);
 }

  fetchTactics(input: any) {

  //console.log("Function is being called....");
     this.apiService.fetchTactics(input)
      .then( tactics => {
        setTimeout( () => { this.isReady = true; }, 1000);
        this.storeTactics(tactics);
      },
      error=>{
      //  this.isReady = true;
        setTimeout( () => { this.isReady = true; }, 1000);    //this.fetchTactics();
        this.alertService.error("Failed to load data");
       }
      );
 }

 /*
 fetchSummary()
   {
       this.apiService.fetchSummary(this.model.url)
       .subscribe(traffic => this.traffic= traffic);
   }
   */
  ngOnInit() :void{
    console.log("Summary component ngOnInit called...");
  }

  testChange(event)
  {

    this.duration= this.months=== true ? 3:6;
    console.log(this.duration);
    //this.isReady = false;
    //setTimeout( () => { this.isReady = true; }, 1000 );    //this.fetchTactics();
  }

}
