import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  database: any;
  notificationServiceArray = [];
  constructor(
    private http: HttpClient,
    private db: AngularFireDatabase
  ) {

  }

  private notificationSource = new Subject<number>();
  notification$ = this.notificationSource.asObservable();

  setNotification(count: number) {
    // console.log("in notifiction service");
    this.notificationSource.next(count);
    return count;
  }


  // get all notifications of an account
  fetchNotification() {
    // console.log("in notifiction service");
    for (var i = 0; i < this.notificationServiceArray.length; i++) {
      if (this.notificationServiceArray[i]['createdOn']) {
        let current_time = moment();
        let message_time = moment(this.notificationServiceArray[i]['createdOn']);
        let difference = moment.duration(message_time.diff(current_time));
        let duration = difference.humanize(true);
        this.notificationServiceArray[i]["duration"] = duration;
      }
    }
    return this.notificationServiceArray.sort().reverse();

  }


  setNotificationCount(listPath: string) {
    // console.log("in notifiction service",listPath);
    let count = 0;
    return this.db.list(listPath, ref => ref.orderByChild('createdOn'))
      .valueChanges()
      .subscribe(
        notifications => {
          this.notificationServiceArray = [];
          this.notificationServiceArray = notifications;
          if (notifications.length > 0) {
            let unread = notifications.filter(x => x['status'] === 'unread');
            count = unread.length;
          }
          else {
            count = 0;
          }
          this.setNotification(count);
        }
      );
  }

  //change notification status to 'read'
  updateNotificationStatus(acc_slug, id) {
    // console.log("in notifiction service");
    var userref = this.db.list('/notifications/' + acc_slug);
    userref.update(id, { 'status': 'read' });
    console.log("notification updated");
  }

  //to delete a notification based on id
  clearNotification(acc_slug, id) {
    // console.log("in notifiction service");
    this.database = firebase.database();
    var userRef = this.database.ref('/notifications/' + acc_slug + '/' + id);
    userRef.remove();
    console.log("Notification removed");
  }

  // delete all notifications of an account
  clearAllNotification(acc_slug) {
    // console.log("in notifiction service");
    this.database = firebase.database();
    var userRef = this.database.ref('/notifications/' + acc_slug);
    userRef.remove();
    console.log("Deleted all notifications");
  }

}
