<div *ngIf="showVerificationMsg" class="alert alert-danger">
  Your email address is unverified. If you'd like to get the verification email
  again,
  <a (click)="resendVerification()"><strong><u>click here</u></strong></a>.
</div>

<section>
  <div class="row">
    <div class="col-md-5">
      <div class="container">
        <div style="text-align: center; color: #5b6ebf;">
          <a href="/"><img src="../../assets/images/mktg-doctor-logo-new.png" class="logo" /></a>
        </div>
        <h1 style="text-align: center;">Login</h1>

        <form #formData="ngForm" (ngSubmit)="loginWithEmail(formData)">
          <div class="form-group">
            <input type="email" class="form-control" placeholder="Email Address" (ngModel)="(email)" name="email"
              required />
          </div>

          <div class="form-group">
            <div class="input-group">
                <input [type]="hidePwd? 'password':'text'" class="form-control" placeholder="Password" (ngModel)="(password)" name="password"
                required />
              <span class="input-group-addon addToInput">
                <mat-icon matSuffix (click)="hidePwd=!hidePwd">{{hidePwd? 'visibility':'visibility_off'}} </mat-icon>
              </span>
            </div>
          </div>

          <button type="submit" class="btn btn-primary" [disabled]="!formData.valid">
            Log In
          </button>
          <p><a [routerLink]="['forgot-password']">Forgot
            <!-- <p><a [routerLink]="[{ outlets: { loggedOut: ['forgot-password'] } }]">Forgot -->
              Password?</a></p>
          <p>
            <!-- Don’t have an account? <a [routerLink]="[{ outlets: { loggedOut: ['signup'] } }]"><b>Sign -->
              Don’t have an account? <a [routerLink]="['signup']"><b>Sign
                Up</b></a>
          </p>
          <p>Questions? <a href="#">Contact Support</a></p>
        </form>
      </div>
    </div>
    <div class="col-md-7 hero-image">
    </div>
  </div>
</section>




<!-- <div *ngIf="showVerificationMsg" class="alert alert-danger">
  Your email address is unverified. If you'd like to get the verification email
  again,
  <a (click)="resendVerification()"
    ><strong><u>click here</u></strong></a
  >.
=======
>>>>>>> 82ca064bb9671d7666894cbda0d8807b11f4c5e8
</div>
<div class="row container-fluid justify-content-center">
  <div *ngIf="processing == true; then authenticated; else guest"></div>
  <ng-template #authenticated class="col-12">
    <div class="info-cards">
      <center>
        <p>Loading ...</p>
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </center>
    </div>
  </ng-template>
  <ng-template #guest class="col-12">
    <mat-card class="info-cards">
      <mat-card-title>
        Login
        <mat-card-subtitle>
        <center>Login with Google, Facebook or Email</center> -->
<!-- </mat-card-subtitle> -->
<!--
        <button mat-raised-button (click)="login()"><i class="fa fa-google"></i>Login with Google</button>
        <span class="spacer"></span>
        <button mat-raised-button (click)="loginWithFacebook()"><i class="fa fa-facebook"></i> Login with Facebook</button>
      -->
<!-- </mat-card-title>
      <mat-card-content>
        <form #formData="ngForm" (ngSubmit)="loginWithEmail(formData)">
          <div class="md-form form-group">
            <mat-icon>email</mat-icon>
            <input type="email" style="width: 90%;" placeholder="Email Address" (ngModel)="(email)" name="email"
              class="txt" required />
          </div>
          <div class="md-form form-group">
            <mat-icon>lock outline</mat-icon>
            <input type="password" style="width: 90%;" placeholder="Password" (ngModel)="(password)" name="password"
              class="txt" required />
            <p><a [routerLink]="['/forgot-password']">Forgot Password?</a></p>
          </div>
          <div>
            <center>
              <button mat-raised-button color="primary" type="submit" [disabled]="!formData.valid">
                Login
              </button>
              <div class="col">
                <p>
                  Need mktgbot.chat account?<a [routerLink]="['/signup']"><b>Sign Up</b></a>
                </p>
              </div>
            </center>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </ng-template> -->
<!-- </div> -->

<!-- prime-ng-signup  -->
<!-- <div class="ui-g-12">
  <img src="assets/img/logo-clear.svg" id="top-logo" />
</div>
<p-panel>
  <form #formData="ngForm" (ngSubmit)="loginWithEmail(formData)">
    <div class="ui-g-6">
      <label class="ui-g-12"><i class="fa fa-envelope"></i>Email</label>
      <div>
        <input class="ui-g-12" pInputText type="email" placeholder="Email Address" (ngModel)="(email)" name="email"
          class="txt" required />
      </div>
      <div class="ui-g">
        <label class="ui-g-12"><i class="fa fa-low-vision"></i>Password</label>
        <div>
          <input class="ui-g-12" pInputText type="password" placeholder="Password" (ngModel)="(password)"
            name="password" class="txt" required />
        </div>
      </div>

      <p>
        <a [routerLink]="['/forgot-password']">Forgot Password?</a>
      </p>

      <div class="ui-g">
        <button pButton type="submit" [disabled]="!formData.valid">
          Login
        </button>
      </div>
      <div class="ui-g">
        <div id="statistic" class="ui-g-6 ui-md-10 ui-g-nopad">
          <div class="icon ui-g-3">
            <i class="fa fa-user-plus"></i>
          </div>
          <div class="data ui-g-9">
            <div class="value">Need mktgbot.chat account?</div>
            <div class="label">
              <a [routerLink]="['/signup']"><b>Sign Up</b></a> -->
<!-- </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-panel>

<div class="ui-g-6" id="img">
  <p-panel>
    <img src="./../../assets/images/seo-hero-image.jpg" />
  </p-panel>
</div> -->
