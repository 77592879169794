import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
//import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { MatPaginator } from "@angular/material/paginator";
import {  MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { tap } from "rxjs/operators";
import { merge } from "rxjs";

import { CollectedFormsService } from "../../_services/collected-forms.service";
import { ResponsesDataSource } from "../../_services/responses.datasource";

@Component({
  selector: "app-collected-forms",
  templateUrl: "./collected-forms.component.html",
  styleUrls: ["./collected-forms.component.scss"],
})
export class CollectedFormsComponent implements OnInit, AfterViewInit {
  responses: any = [];
  displayedColumns: any = [
    "select",
    "created_on",
    "first_name",
    "last_name",
    "samples_chosen",
    "address",
    "apt",
    "city",
    "state",
    "zip",
    "email",
    "email_opt_in",
  ];
  dataSource: ResponsesDataSource;
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSize: number = 3;
  pageSizeOptions: number[] = [3, 5, 10];
  count: number = 100;

  constructor(private collectedFormsService: CollectedFormsService) {}

  ngOnInit() {
    this.dataSource = new ResponsesDataSource(this.collectedFormsService);
    this.dataSource.getResponses("asc", 0, 3);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.getResponses()))
      .subscribe();
  }

  getResponses() {
    this.dataSource.getResponses(
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.responses.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.responses.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  bulkAction() {
    console.log(this.selection.selected);
  }
}
