import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from '../../environments/environment';

@Injectable()
export class GoogleAnalyticsService {

  constructor(
    private http: HttpClient,
  ) { }

  formatDate(timeperiod: any) {
    let formattedDate = {'start': '', 'end': ''};

    formattedDate.start = (new DatePipe('en-US')).transform(timeperiod.start, 'yyyy-MM-dd');
    formattedDate.end = (new DatePipe('en-US')).transform(timeperiod.end, 'yyyy-MM-dd');

    return formattedDate;
  }

  getEmailEngagement(refresh_token:string, view_id:string, timeperiod: any) {
    let timeperiod_formatted = this.formatDate(timeperiod);
    return this.http.post(`${environment.serverURL}/google-analytics-service-copy.php`, JSON.stringify({"refresh_token":refresh_token,"view_id":view_id,"timeperiod":timeperiod_formatted}));
  }

 getFacebookEngagement(refresh_token:string, view_id:string, timeperiod: any){
    let timeperiod_formatted = this.formatDate(timeperiod);
    return this.http.post(`${environment.serverURL}/google-analytics-FBservice-copy.php`, JSON.stringify({"refresh_token":refresh_token, "view_id":view_id, "timeperiod":timeperiod_formatted}));
  }

  getAdwordsEngagement(refresh_token:string, view_id: string, timeperiod: any){
    let timeperiod_formatted = this.formatDate(timeperiod);
    return this.http.post(`${environment.serverURL}/google-analytics-AdWordservice-copy.php`, JSON.stringify({"refresh_token":refresh_token,"view_id":view_id, "timeperiod": timeperiod_formatted}));
  }
}
