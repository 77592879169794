import { Component, Input, OnInit } from '@angular/core';
import { Thank_you_screen } from '../../_services/form';
import { Adwords_tracking_code } from '../../_services/form';
import { Facebook_tracking_code } from '../../_services/form';
import { FormBuilderService } from '../../_services/form-builder.service';
import { PreviewComponent } from '../preview/preview.component';
@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  @Input()
  thank_you_screen: Thank_you_screen;
  useCustom: boolean = false;
  useTracking: boolean = false;
  enabled: boolean = false;
  @Input() previewRef: PreviewComponent;
  constructor(
    private formBuilderService: FormBuilderService
  ) { }

  ngOnInit() {

  }

  onFocus(thankyou_screen: string) {
    this.previewRef.scroll(thankyou_screen);
  }
}
