<div class="container-fluid view">

 <!--<mat-card class="expansion">-->
    <!--<button mat-fab class="icon-space" color="primary" matTooltip="Activity logs"><i class="material-icons">list_alt</i></button>-->
    <button mat-mini-fab class="icon-space" color="primary" matTooltip="Add Note" (click)="openDialogForNote()"><i class="material-icons">event_note</i></button>
  <!--  <button mat-fab class="icon-space" color="primary" matTooltip="SMS logs"><i class="material-icons">message</i></button>
    <button mat-fab class="icon-space" color="primary" matTooltip="Review"><i class="material-icons">star</i></button>-->
    <!--<button mat-fab class="icon-space" color="primary" matTooltip="Appointments"><i class="material-icons">calendar_today</i></button>-->
  <!--</mat-card>-->

  <center>
  <div *ngIf="!activities_fetched">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p styles="text-align : center;">Loading...</p>
  </div>
  </center>

<!-- Activity log -->

<div *ngIf="activities_fetched">

  <div *ngFor="let activity of activities">

    <div *ngIf="activity.type == 'message' ">
      <!--<mat-card class="activity-card">
          <div class="row">
            <div class="col-sm-2">
              <i *ngIf="(activity?.direction === 'outbound-api') || (activity?.direction === 'outbound-reply')" class="material-icons" style="vertical-align : middle;font-size:1.5rem;" matTooltip="Sent message">message<sup><i class="material-icons" style="font-size:1rem;">call_made</i></sup></i>
              <i *ngIf="activity?.direction === 'inbound'" class="material-icons" style="vertical-align : middle;font-size:1.5rem;">message<sup><i class="material-icons" style="font-size:1rem;" matTooltip="Received message">call_received</i></sup></i>
            </div>
            <div class="col-sm-6">
            <div *ngIf="(activity?.direction === 'outbound-api') || (activity?.direction === 'outbound-reply')"><p><strong>Outgoing message</strong></p></div>
            <div *ngIf="activity?.direction === 'inbound'"><p>Incoming message</p></div>
            </div>
            <div class="col-sm-4" styles="display: inline;float: right;">
              <p class="extraInfo" >{{activity.date | date:'MMM d, y h:mm a' }}</p>
              </div>

            </div>

          <mat-card-content>
              <p>{{activity.msg}}</p>
              <p class="extraInfo">{{activity.status | titlecase}}</p>
          </mat-card-content>

      </mat-card>-->
      <div class="row">
      <div class="col-sm-2">
            <p style="vertical-align:middle;">
            <i *ngIf="(activity?.direction === 'outbound-api') || (activity?.direction === 'outbound-reply')" class="material-icons" style="vertical-align : middle;font-size:1.5rem;" matTooltip="Sent message">message<sup><i class="material-icons" style="font-size:1rem;">call_made</i></sup></i>
            <i *ngIf="activity?.direction === 'inbound'" class="material-icons" style="vertical-align : middle;font-size:1.5rem;">message<sup><i class="material-icons" style="font-size:1rem;" matTooltip="Received message">call_received</i></sup></i>
            </p>
            <p class="extraInfo">{{activity.date | date:'MMM d, y h:mm a' }}</p>
            <p class="extraInfo">{{activity.status | titlecase}}</p>
      </div>
      <div class="col-sm-10">
        <p>{{activity.msg}}</p>
      </div>
    </div>
      <mat-divider></mat-divider>
    </div>



    <div *ngIf="activity.type == 'note' ">
      <div class="row">
        <div class="col-sm-2">
          <p style="vertical-align:middle;">
            <i class="material-icons" matTooltip="Note" >event_note</i><sup><i class="material-icons"  style="font-size:1rem;padding-left:0px;">edit</i></sup>
            <p class="extraInfo">{{activity.date | date:'MMM d, y h:mm a'  }}</p>
        </div>
        <div class="col-sm-10">
          <input class="border-box" matInput placeholder="Enter" [value]="activity.note" (change)="activity.note = $event.target.value; updateNote($event.target.value, activity.id)">
        <!--<div class="edit"><i class="material-icons">edit</i></div>-->
        </div>
      </div>
        <mat-divider></mat-divider>
  </div>
  <div *ngIf="activity.type == 'contact-form' ">
    <div class="row">
      <div class="col-sm-2">
        <p style="vertical-align:middle;">
          <i class="material-icons" matTooltip="Note" >event_note</i>

          <p class="extraInfo">{{activity.date | date:'MMM d, y h:mm a'  }}</p>
      </div>
      <div class="col-sm-10">
        <!-- <input class="border-box" matInput placeholder="Enter" [value]="activity.message" (change)="activity.message = $event.target.value; updateNote($event.target.value, activity.id)"> -->
        <p><b>Message</b><br>{{activity.message}}</p>
      </div>
    </div>
      <mat-divider></mat-divider>
</div>

  <div *ngIf="activity.type == 'appointment' ">
    <div class="row">
      <div class="col-sm-2">
        <p style="vertical-align:middle;">
          <i class="material-icons" matTooltip="Appointment">today</i><sup><i class="material-icons" style="font-size:1rem;padding-left:0px;">watch_later</i></sup>
          <p class="extraInfo">{{activity.date | date:'MMM d, y h:mm a'  }}</p>
            <p class="extraInfo">{{activity.status | titlecase}}</p>
      </div>
      <div class="col-sm-10">
      <p *ngIf="activity.status == 'booked'">Appointment booked for {{activity.appt_date | date:'MMM d, y h:mm a'}} at {{activity.slot}}.</p>
      <p *ngIf="activity.status == 'cancelled'">Appointment on {{activity.appt_date | date:'MMM d, y h:mm a'}} at {{activity.slot}} has been cancelled.</p>
      <p *ngIf="activity.status == 'rescheduled'">Appointment on {{activity.appt_date | date:'MMM d, y h:mm a'}} at {{activity.slot}} has been rescheduled.</p>
      <p *ngIf="activity.status == 'completed'">Appointment on {{activity.appt_date | date:'MMM d, y h:mm a'}} at {{activity.slot}} has been completed.</p>
      <p *ngIf="activity.status == 'no-show'">Appointment on {{activity.appt_date | date:'MMM d, y h:mm a'}} at {{activity.slot}} has been marked "no-show".</p>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>


</div>

<!---- --->
</div>
<div *ngIf="noActivities">
  <p>No activities yet.</p>
</div>
</div>
