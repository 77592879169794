/*
 * Used in legacy code
 */

import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable()
export class SeoCsvService {

  constructor(
    private http:HttpClient
  ) { }

  getCsvRank(){
    return this.http.get(`${environment.serverURL}/seoCsv2json.php`);
  }
}
