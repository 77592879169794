import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, FormGroup, NG_ASYNC_VALIDATORS, NG_VALIDATORS, RequiredValidator, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[ngModelGroup][appPercentageOrFlatDiscount]',
  providers: [{
    provide: NG_ASYNC_VALIDATORS,
    useExisting: forwardRef(() => PercentageOrFlatDiscountDirective),
    multi: true
  }]
})
export class PercentageOrFlatDiscountDirective implements Validator {

  constructor() { }

  validate(group: AbstractControl): ValidationErrors | null {
    const controls = (group as FormGroup).controls; // we expect FormGroup here
    const controlNames = Object.keys(controls);
    const filledCount = controlNames.filter(name => !!controls[name].value).length;
    return filledCount > 0 ? {required:true}:null;
    // return filledCount > 0 && filledCount < controlNames.length ? { required: true } : null;
  }
}
