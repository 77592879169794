<!--<button mat-raised-button color="primary" class="format" (click)="addTextArea()">Add a note</button>
  <!--<i class="material-icons" matTooltip="Note" >event_note</i><sup><i class="material-icons"  style="font-size:1rem;padding-left:0px;">edit</i></sup></button>-->
<!-- <button mat-fab color="primary" matTooltip="Add Note" (click)="openDialogForNote()">
  <i class="material-icons">event_note</i> -->
<!--<sup><i class="material-icons"  style="font-size:1rem;padding-left:0px;">edit</i></sup>-->
<!-- </button> -->
<!--<div *ngIf="add_note">
  <mat-form-field>
    <textarea matInput placeholder="Enter your notes here." [(ngModel)]="createdNote" (change)="addNote()">{{createdNote}}</textarea>
  </mat-form-field>
</div>-->




<div *ngIf="notes_array.length==0;else elseblock" class="text-center">
  No Notes
</div>
<ng-template #elseblock>

  <div *ngFor="let notes of notes_array">
    <div class="row">
      <div class="col-md-2">
        <p class="text-center" style="vertical-align:middle;">
          <i class="material-icons" matTooltip="Note">event_note</i><sup><i class="material-icons"
              style="font-size:1rem;padding-left:0px;">edit</i></sup>
          <!--  <i class="material-icons">notes</i>-->
        <p class="text-center extraInfo">{{notes.created_on | date:'MMM d, y h:mm a'  }}</p>
      </div>
      <div class="col-md-10">
        <input class="border-box" matInput placeholder="Enter" [value]="notes.note"
          (change)="updateNote($event.target.value,notes)">
     
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>

</ng-template>
