<div *ngIf="!isReady">
  <mat-progress-spinner mode="indeterminate" style="margin:0 auto;"></mat-progress-spinner>
</div>

<div class="container">
  <div class="card">
    <div class="card-header msg_head">
      <div class="d-flex justify-content-between bd-highlight">
        <div class="user_info">
          <div style="display:inline-block;">
            <ngx-avatar name={{fullName.firstName}}></ngx-avatar>
          </div>
          <span>{{fullName.firstName|titlecase}} {{fullName.lastName|titlecase}}</span>
        </div>
        <div style="margin-right: 20px;">
          <mat-form-field *ngIf="languages.length" style="width:120px;padding:0px;">
            <mat-label>Language</mat-label>
            <mat-select [(value)]="selectedLanguage" (selectionChange)="selectLanguage($event)">
              <mat-option *ngFor="let code of languages" [value]="code.value">
                {{code.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="isReady">
      <div *ngIf="chatMessageLoading">
        <div style="margin:5px auto;width:fit-content;height: fit-content;text-align: center;">
          <i class="pi pi-spin pi-spinner" id="spinIcon" style="font-size: 25px;color: rgb(132, 132, 132);"></i>
        </div>
      </div>
      <div *ngIf="!chatMessageLoading && allMessages.length==0">
        <div style="margin:5px auto;width:fit-content;height: fit-content;text-align: center;">
          <span>Start a conversation by sending first message.</span>
        </div>
      </div>
      <div id="scrollMe" *ngIf="!chatMessageLoading && allMessages.length>0"
        style="height:67vh; overflow-y: auto;width: 98%;" #scrollMe>
        <div class="card-body msg_card_body" *ngFor="let message of allMessages;index as i;">

          <div *ngIf="i == 0" id="date_div">
            <div id="message_date">{{message.date.toDate() | date:'MMM d, y'}}</div>
          </div>
          <div *ngIf="i>0 && checkDate(message.date,allMessages[i-1].date)" id="date_div">
            <div id="message_date">{{message.date.toDate() | date:'MMM d, y'}}</div>
          </div>

          <!-- incoming msg -->
          <div class="d-flex justify-content-start mb-4" *ngIf="message?.direction === 'inbound'">
            <div class="msg_cotainer">
              <div class="text_message">{{message.sourceMsg}}</div>
              <span class="msg_time">{{message.date.toDate() | date:'h:mm a'}}</span>
            </div>
          </div>

          <!-- outgoing msg -->
          <div class="d-flex justify-content-end mb-4"
            *ngIf="(message?.direction === 'outbound-api') || (message?.direction === 'outbound-reply')">
            <div class="msg_cotainer_send">
              <div class="text_message" [innerHTML]="message.sourceMsg"></div>
              <span class="msg_time_send">{{message.date.toDate() | date:'h:mm a'}}</span>
              <!-- <span class="msg_time_send" *ngIf="message.status==='delivered'">{{message.date.toDate() | date:'h:mm a'
                }}
                <span class="material-icons">done_all</span>
              </span>

              <span class="msg_time_send" *ngIf="message.status==='sent'">{{message.date.toDate() | date:'h:mm a' }}
                <span class="material-icons">done</span>
              </span>
              <span class="msg_time_send" *ngIf="message.status==='failed'">{{message.date | date:'h:mm a' }}
                <i class="fa fa-times" aria-hidden="true" style="color: red;font-size: 12px;"></i>
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="card-footer">
      <p *ngIf="showMsg && selectedLanguage!='en'"
        style="color: red;font-size: 10px;margin:-7px 0 3px 10px;padding: 0;">
        Message will be sent in {{selectedLanguageName}}</p>
      <div class="input-group">
        <textarea name="" class="form-control type_msg" style="border-radius: 2em;" [(ngModel)]="newMessageText"
          placeholder="Type your message..." (keyup.enter)="singleMessage()"></textarea>
        <i class="fa fa-paper-plane" aria-hidden="true" (click)="singleMessage()" id="sendIcon"></i>
      </div>
    </div>
  </div>
</div>