import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewEncapsulation, AfterContentInit, AfterViewInit, } from "@angular/core";
import { AccountService, AppointmentService, ContactService } from "../_services/index";
import { Router } from "@angular/router";
import { MatDatepicker } from "@angular/material/datepicker";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FilterUtils } from "primeng/utils";
import { AlertService } from "../_services/alert.service";
import { MessageDialogComponent } from "./message-dialog/message-dialog.component";
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';

import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContactDialogComponent } from "./contact-dialog/contact-dialog.component";
import { MergeContactComponent } from "./merge-contact/merge-contact.component";
import * as moment from "moment";
import { TooltipPosition } from "@angular/material/tooltip";
import { NavigationState } from "../_models/index";
import { NavigationService } from "../_services/index";
import { Subscription } from "rxjs";
import { MergeContactsComponent } from "./merge-contacts/merge-contacts.component";
import { PatientFormComponent } from "./patient-form/patient-form.component";
import { MatTooltipModule } from '@angular/material/tooltip';
import { SurveyFormComponent } from './survey-form/survey-form.component';
import { ExportDataService } from '../_services/export-data.service';
import { Table } from 'primeng/table'
import { SendPatientFormsComponent } from "../patient-form-details/send-patient-forms/send-patient-forms.component"


export interface DialogData {
  contact: any;
}

@Component({
  selector: "app-contacts-list",
  templateUrl: "./contacts-list.component.html",
  styleUrls: ["./contacts-list.component.scss"],
  providers: [DialogService],
  animations: [
    trigger("rowExpansionTrigger", [
      state(
        "void",
        style({
          transform: "translateX(-10%)",
          opacity: 0,
        })
      ),
      state(
        "active",
        style({
          transform: "translateX(0)",
          opacity: 1,
        })
      ),
      transition("* <=> *", animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")),
    ]),
  ],
  // encapsulation: ViewEncapsulation.None,

})
export class ContactsListComponent implements OnInit, OnDestroy, AfterViewInit {
  contacts: any = [];
  isShow: boolean = false;
  acc: any;
  currentAcc: boolean = false;
  columns: any[];
  first: number = 0;
  rows = 50;
  maxDate: any = new Date();
  value: boolean = false;
  showImportHistory: boolean = false;
  showContacts: boolean = true;
  selection = new SelectionModel<any>(true, []);
  checked: boolean = false;
  AllContacts = [];
  selectedContacts: any = [];
  importComplete: boolean = false;
  toImport: boolean = false;
  importFileSelected: boolean = false;
  displayProgress: boolean = false;
  importContactResult: string = "";
  contactsFetched: boolean = false;
  filterColumns: any = [];
  twilio_from: string;
  importFileName: string = "";
  invalidBirthDate: boolean = false;
  contactSubscription: Subscription;
  accSubscription: Subscription;
  rowData: any;
  rowIndex: number;
  showMerged: boolean = false;
  showSendPatientForm: boolean = false;
  patientFormDialogRef: DynamicDialogRef;
  SurveyFormstatus: boolean = false;
  surveyEnable: boolean = false;
  emailSendStatus: boolean = false;
  smsSendStstus: boolean = false;
  demodata: any = [{
    'name': 'Vipul',
    'age': 25,
    'city': 'Kanpur'
  },
  {
    'name': 'Anand',
    'age': 24,
    'city': 'Pune'
  }];
  col: any = [{
    header: 'Name', field: 'name'
  }, {
    header: 'Age', field: 'age'
  }]
  mobiletableView: boolean = false;
  tableData: any;
  moment: any = moment;

  @ViewChild("input")
  fileInput: ElementRef;

  @ViewChild("elementToFocus")
  _input: ElementRef;

  @ViewChild(Table)
  tableRef: Table

  @ViewChild('dt', { static: true }) dt: Table;
  constructor(
    private navigationService: NavigationService,
    private dialogService: DialogService,
    private accountService: AccountService,
    private contactService: ContactService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    private exportData: ExportDataService,
  ) {
    FilterUtils["custom-equals"] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === "") {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return value.toString() === filter.toString();
    };
    this.accSubscription = this.accountService.accChange$.subscribe((accChange) => {
      this.router.navigateByUrl("/websites");
    });

    this.contactSubscription = this.contactService.contacts$.subscribe((contacts) => {
      contacts.then((contacts) => {
        this.AllContacts = contacts;
        this.contacts = this.AllContacts.filter(
          (contact) =>
            contact["active"] == undefined || contact["active"] == true
        );
        if (this.contacts.length === 0) {
          this.isShow = false;
        } else {
          this.isShow = true;
        }
        this.acc = this.accountService.getCurrentAccDetails();
      });
    });
  }
  ngAfterViewInit(): void {
    console.log(this.dt.globalFilterFields);
    console.log(this.dt.totalRecords);
    console.log(this.dt.filteredValue);
  }


  ngOnInit() {
    //sub-header
    // this.navigationService.updateState(
    //   new NavigationState(false, "Contacts", "", "#", "/contacts")
    // );
    // ------------------

    this.filterColumns = [
      "first_name",
      "last_name",
      "phone",
      "email",
      "birthday",
      "last_visited",
      "created_on",
    ];
    this.contactService.returnContacts().then((contacts) => {
      this.AllContacts = contacts;
      this.contacts = this.AllContacts.filter(
        (contact) =>
          contact["active"] === undefined || contact["active"] === true
      );
      this.columns = [
        { header: "First Name", field: "first_name", class: "mid" },
        { header: "Last Name", field: "last_name", class: "mid" },
        { header: "Email", field: "email", class: "email" },
        { header: "Phone", field: "phone", class: "mid" },
        {
          header: "Birthday",
          field: "birthday",
          date: true,
          format: "MMM d, yyyy",
          class: "mid"
        },
        {
          header: "Last Visited",
          field: "last_visited",
          date: true,
          format: "MMM d, yyyy", class: "mid"
        },
        {
          header: "Created On",
          field: "created_on",
          date: true,
          format: "MMM d, yyyy", class: "mid"
        },

      ];

      this.tableData = {
        header: this.columns,
        data: this.contacts
      }
      this.mobiletableView = true;

    });
    this.acc = this.accountService.getCurrentAccDetails();
    this.currentAcc = true;
    this.twilio_from = this.acc.twilio_from;
  }
  reset() {
    this.first = 0;
  }
  next() {
    this.first = this.first + this.rows;
  }
  prev() {
    this.first = this.first - this.rows;
  }
  isLastPage(): boolean {
    return this.first === this.contacts.length - this.rows;
  }

  selectRow() {
    if (this.selectedContacts.length)
      this.showSendPatientForm = true;
    else
      this.showSendPatientForm = false;

    if (this.selectedContacts.length >= 2) {
      this.showMerged = true;
    }
    else {
      this.showMerged = false;
    }

    if (this.selectedContacts.length) {
      this.SurveyFormstatus = true;
    }
    else {
      this.SurveyFormstatus = false;
    }
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }

  /*
   * to notify calendar open event
   */
  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
  }

  /*
   * to notify calendar close event
   */
  _closeCalendar(e, contact_ref, refLast_visited) {
    setTimeout(() => this._input.nativeElement.blur());
  }

  /*
   * to update last visited of contact
   */
  updateLastVisited(contact_ref, refLast_visited) {
    this.contactService.updateLastVisitedDate(contact_ref, refLast_visited);
  }

  updateValue(enteredValue: string, column: string, contact: any) {
    this.selectedContacts = [];
    this.selectRow()
    contact.full_phone = contact.countryCode + enteredValue;
    this.contactService.updateEditedContact(contact, column, enteredValue);
  }

  updateBirthDate(contact_ref, birthDate, contact, birthDateRef: ElementRef) {
    // this.contactService.updateBirthDate(contact_ref, birthDate);
    let date =
      moment(birthDate, "MM-DD-YYYY", true).isValid() ||
      moment(birthDate, "MM/DD/YYYY", true).isValid() ||
      moment(birthDate, "M-D-YYYY", true).isValid() ||
      moment(birthDate, "M/D/YYYY", true).isValid();
    if (date) {
      this.invalidBirthDate = false;
      contact.invalidBirthDate = false;
      this.contactService.updateBirthDate(contact_ref, birthDate);
    } else {
      this.invalidBirthDate = true;
      contact.invalidBirthDate = true;
      birthDateRef['value'] = moment(contact.birthday).format("MM/DD/YYYY")
      // alert("invalid birth date");
    }
  }

  listContacts(contacts) {
    console.log(contacts);
  }

  showImportSection() {
    this.toImport = true;
  }

  hideImportSection() {
    this.importFileSelected = false;
    this.importComplete = false;
    this.displayProgress = false;
    this.importContactResult = "";
    this.toImport = false;
    //this.getContacts();

    this.contactsFetched = false;
    this.contactService.getContacts().then((doc) => {
      this.contactsFetched = true;
    });
  }

  toggleImportHistory() {
    this.showContacts = !this.showContacts;
    this.showImportHistory = !this.showImportHistory;

    if (this.showContacts) {
      this.contactService.resetPageEndpoints();
      //this.getContacts();

      this.contactsFetched = false;
      this.contactService.getContacts().then((doc) => {
        this.contactsFetched = true;
      });
    }

  }

  // for import contacts
  checkFileSelection() {
    this.importComplete = false;
    this.displayProgress = false;

    if (this.fileInput.nativeElement.value !== "") {
      this.importFileName = this.fileInput.nativeElement.files[0].name;
      this.importFileSelected = true;
    } else {
      this.importFileName = "";
      this.importFileSelected = false;
    }
  }

  parseFile() {
    this.displayProgress = true;
    this.importFileSelected = false;

    const reader = new FileReader();

    let fileLength = this.fileInput.nativeElement.files[0].size;
    let fileType = this.fileInput.nativeElement.files[0].type;

    if (fileLength === 0) {
      this.importContactResult = `"${this.importFileName}" is empty. No contacts to import!`;
      this.importComplete = true;
      this.importFileName = "";
      this.fileInput.nativeElement.value = "";
      this.importFileSelected = false;
      this.displayProgress = false;
    } else if (fileType !== "text/csv") {
      this.importContactResult = `"${this.importFileName}" has an invalid file type. Please import a CSV file.`;
      this.importComplete = true;
      this.importFileName = "";
      this.fileInput.nativeElement.value = "";
      this.importFileSelected = false;
      this.displayProgress = false;
    } else {
      this.uploadFile();
    }
  }

  uploadFile() {
    this.contactService
      .store_csv_to_storage(this.fileInput.nativeElement.files[0])
      .then(
        (fileURL) => {
          let downloadURL = fileURL;
          this.contactService
            .createImportLog(downloadURL, this.importFileName)
            .then(
              (importLogRef) => {
                // Make a call to the Flask app here and pass it importLogRef.id;
                this.contactService.importContacts(importLogRef.id).subscribe(
                  (results) => {
                    this.importContactResult = `"${this.importFileName}" is being imported. You can check the status of the import by accessing "Import History".`;
                    this.importComplete = true;
                    this.importFileName = "";
                    this.fileInput.nativeElement.value = "";
                    this.importFileSelected = false;
                    this.displayProgress = false;
                  },
                  (error) => {
                    console.error(error);
                    this.importContactResult = `There was an error importing "${this.importFileName}": ${error}.`;
                    this.importComplete = true;
                    this.importFileName = "";
                    this.fileInput.nativeElement.value = "";
                    this.importFileSelected = false;
                    this.displayProgress = false;
                  }
                );
              },
              (error) => {
                console.error(error);
                this.importContactResult = `There was an error importing "${this.importFileName}": ${error}.`;
                this.importComplete = true;
                this.importFileName = "";
                this.fileInput.nativeElement.value = "";
                this.importFileSelected = false;
                this.displayProgress = false;
              }
            );
        },
        (error) => {
          console.error(error);
          this.importContactResult = `There was an error importing "${this.importFileName}": ${error}.`;
          this.importComplete = true;
          this.importFileName = "";
          this.fileInput.nativeElement.value = "";
          this.importFileSelected = false;
          this.displayProgress = false;
        }
      );
  }

  resetFile() {
    this.importFileName = "";
    this.fileInput.nativeElement.value = "";
    this.importContactResult = "";
    this.importFileSelected = false;
    this.displayProgress = false;
    this.importComplete = false;
  }

  // filter active contacts
  showEnableContactsOnly() {
    if (this.checked === false) {
      this.contacts = this.AllContacts.filter(
        (contact) =>
          contact["active"] == undefined || contact["active"] === true
      );
    } else {
      this.contacts = this.AllContacts;
    }
  }

  bulkMessage() {
    this.openDialogForMessage(this.selectedContacts);
  }

  /*
Dialog box to send bulk messages
*/
  openDialogForMessage(selectdContacts) {
    this.alertService.reset("");
    const messagedialogRef = this.dialog.open(MessageDialogComponent, {
      panelClass: "custom-dialog-container",
      height: "600px",
      width: "900px",
      //data: { contactRef : contactRef, contact:contact}
      data: { contacts: selectdContacts },
    });
    messagedialogRef.afterClosed().subscribe((closed) => {
      console.log("closed");
    });
  }

  /*
Dialog box to add contact
*/
  openDialogForContact() {
    const contactDialogRef = this.dialog.open(ContactDialogComponent, {
      panelClass: "custom-dialog-container",
      height: "520px",
      width: "800px"
    });
    contactDialogRef.afterClosed().subscribe((closed) => {
      console.log("closed");
    });
  }

  openDialogForSurveyForm() {

    const ref = this.dialogService.open(SurveyFormComponent, {
      data: this.selectedContacts,
      header: 'Survey Form',
      width: "1100px",
      contentStyle: { "height": "800px" },
      showHeader: true,
      closable: true
    })

    ref.onClose.subscribe((closed) => {
      this.selectedContacts = [];
      this.SurveyFormstatus = false;
      this.selectRow();
    })

    ref.onDestroy.subscribe((destroy) => {
      this.selectedContacts = [];
      this.selectedContacts = false;
      this.selectRow();
    })

  }

  openDialogForMergeTheContact() {
    const contactDialogRef = this.dialog.open(MergeContactsComponent, {
      // const contactDialogRef = this.dialog.open(MergeContactComponent, {
      panelClass: "custom-dialog-container",
      height: "600px",
      width: "900px",
      data: { contacts: this.selectedContacts },
    });
    contactDialogRef.afterClosed().subscribe((closed) => {
      this.selectedContacts = [];
      this.selectRow();
      this.contactSubscription = this.contactService.contacts$.subscribe((contacts) => {
        contacts.then((contacts) => {
          this.AllContacts = contacts;
          this.contacts = this.AllContacts.filter(
            (contact) =>
              contact["active"] == undefined || contact["active"] == true
          );
          if (this.contacts.length === 0) {
            this.isShow = false;
          } else {
            this.isShow = true;
          }
          this.acc = this.accountService.getCurrentAccDetails();
        });
      });

      if (this.contactSubscription)
        this.showMerged = false;
      // this.showSendPatientForm = false;
    });
  }

  openDialogToSendPatientForm() {
    const dialogRef = this.dialog.open(SendPatientFormsComponent, {
      width: "1500px",
      height: '550px',
      data: {
        dialogTitle: "Send Patient Form",
        screenType: "formSelection",
        type: "sendPatientFormFromContactScreen",
        contactsDetails: this.selectedContacts
      },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((closed) => {
      this.selectedContacts = [];
      this.showSendPatientForm = false;
      this.selectRow();
    });
    // dialogRef.onDestroy.subscribe((destroy) => {
    //   this.selectedContacts = [];
    //   this.showSendPatientForm = false;
    //   this.selectRow();
    // })
    // this.patientFormDialogRef = this.dialogService.open(PatientFormComponent, {
    //   header: 'Patient Forms',
    //   width: "1100px",
    //   contentStyle: { "height": "800px" },
    //   data: { contacts: this.selectedContacts },
    // });
    // this.patientFormDialogRef.afterClosed().subscribe((closed) => {
    //   this.selectedContacts = [];
    //   this.showSendPatientForm = false;
    //   this.selectRow();
    // });
    // this.patientFormDialogRef.onDestroy.subscribe((destroy) => {
    //   this.selectedContacts = [];
    //   this.showSendPatientForm = false;
    //   this.selectRow();
    // })
  }

  exportExcel(dt) {
    let tableData = [];
    let headers = dt.columns;
    if (dt.filteredValue) {
      console.log('filtered');
      tableData = dt.filteredValue;
    }
    if (dt.selection && dt.selection.length) {
      tableData = dt.selection;
    }
    else {
      tableData = dt.value;
    }
    let exceldata = [];
    for (let i = 0; i < tableData.length; i++) {
      let temp = {};
      for (let j = 0; j < headers.length; j++) {
        temp[headers[j].header] = tableData[i][headers[j].field];
      }
      exceldata.push(temp);
    }
    this.exportData.downloadExcel([{ data: exceldata, name: 'contacts' }], 'Contacts');
  }

  ngOnDestroy() {
    this.accSubscription.unsubscribe();
    this.contactSubscription.unsubscribe();
  }
}