import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { environment } from "../../environments/environment";
import { AccountService, AppointmentService } from '../_services';

export interface DialogData {
  apptDetails: any,
  title: string;
}

@Component({
  selector: 'app-recurring-appointment-details',
  templateUrl: './recurring-appointment-details.component.html',
  styleUrls: ['./recurring-appointment-details.component.scss']
})
export class RecurringAppointmentDetailsComponent implements OnInit {


  page: number = 0;
  name: string;
  date: any;
  slot: string;
  clinic_name: string;
  city: string;
  status: string;
  service: string;
  new_patient: string;
  notes: string;
  appt: any;
  title: any;
  notesEdited: boolean = false;
  updateNotes: boolean = true;
  statusChanged: boolean = false;
  editedNote: string;
  result: any;
  link: any;
  accDetails: any;
  calenderInfo: any;
  operationFlag: string
  constructor(
    public dialogRef: MatDialogRef<RecurringAppointmentDetailsComponent>,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public accountService: AccountService,
  ) {
    this.name = data.apptDetails.name;
    this.date = data.apptDetails.appt_start;
    this.slot = data.apptDetails.slot;
    this.clinic_name = data.apptDetails.clinic_name;
    this.city = data.apptDetails.city;
    this.status = data.apptDetails.status;
    this.notes = data.apptDetails.notes;
    this.appt = data.apptDetails.appt;
    this.operationFlag = data.apptDetails.operationFlag
  }
  close() {
    if (this.notesEdited) {
      this.notes = this.editedNote;
    }
    this.result = {
      //edited: true,
      appointment: this.appt,
      status: this.status,
      notes: this.notes,
      notesEdited: this.notesEdited,
      statusChanged: this.statusChanged
    };

    this.dialogRef.close(this.result);
  }

  async ngOnInit() {
    this.accDetails = this.accountService.getCurrentAccDetails();
    let calenderSnapshot = await this.appt.calendarReference.get()
    this.calenderInfo = calenderSnapshot.data()
  }

  getNotes(event: any) {
    this.notesEdited = true;
    this.editedNote = event.target.textContent;
  }

  isApptPassed(date: any) {
    let now = moment(new Date());
    if (date < now)
      return true;
    else
      return false;
  }

  statusUpdate() {
    if (this.notesEdited)
      this.notes = this.editedNote;
    //console.log("ref", this.appt.ref);
    // firebase.firestore().doc(this.appt.ref.path).update({
    //   status: this.status,
    //   notes: this.notes
    // });
    this.result = {
      //edited: true,
      appointment: this.appt,
      status: this.status,
      notes: this.notes,
      notesEdited: this.notesEdited,
      statusChanged: this.statusChanged
    }
    this.dialogRef.close(this.result);
  }

  cancelStatus() {
    let cancel_link = `${environment.apptURL}/recurring/${this.accDetails.account_slug}/cancel/${this.appt.recurringApptDetailReference.id}`;
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(cancel_link);
  }
  rescheduleStatus() {
    let reschedule_link = `${environment.apptURL}/recurring/${this.accDetails.account_slug}/reschedule/${this.calenderInfo.slug}/${this.appt.recurringApptDetailReference.id}`;
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(reschedule_link);
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
