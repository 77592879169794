import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilderService } from '../../_services/form-builder.service';
import { Form } from '../../_services/form';
import { AccountService } from '../../_services/account.service';
import { NavigationState } from "../../_models/index";
import { NavigationService } from "../../_services/index";
import { environment } from '../../../environments/environment'
import { PreviewDialogBox } from '../all-forms/all-forms.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData { }

@Component({
  selector: 'app-form-designer',
  templateUrl: './form-designer.component.html',
  styleUrls: ['./form-designer.component.css']
})

export class FormDesignerComponent implements OnInit {
  form: Form;
  formRef: any;
  isReady: boolean = false;
  acc: any;
  collection: any;
  formUrl: any;
  slug: any;
  id: any;

  constructor(
    private formBuilderService: FormBuilderService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private dialog: MatDialog,

  ) {
    this.formBuilderService.questions$
      .subscribe(
        questions => this.form.questions = questions
      );
  }

  ngOnInit() {

    this.route.url
      .subscribe(
        url => {
          this.slug = url[1].path;
          this.id = url[2].path;
          console.log(this.slug, this.id);
          this.formBuilderService.getForm(this.slug, this.id).then(formDoc => {
            console.log(formDoc);
            this.formRef = formDoc.ref;
            this.form = <Form>formDoc.data();
            console.log(this.form);
            this.acc = this.accountService.getCurrentAccDetails();

            this.isReady = true;
            //sub-header
            if (this.slug === "/enquiry-forms") {
              this.navigationService.updateState(
                new NavigationState(
                  true, `${this.form.name}`, "Enquiry Form", "/enquiry-forms", "/form-design"
                )
              );
            }
            else if (this.slug === "/survey-forms") {
              this.navigationService.updateState(
                new NavigationState(
                  true, `${this.form.name}`, "Survey Form", "/survey-forms", "/form-design"
                )
              );
            }

          });
        }
      );
  }

  openDialogForPreview(accountSlug, formSlug, formName) {
    console.log(formName);

    if (this.slug === "/enquiry-forms") {
      this.formUrl = `${environment.interactiveFormURL}/interactive-form/${accountSlug}/${formSlug}`;
      console.log(this.formUrl);
    }
    else if (this.slug === "/patient-intake-forms") {
      this.formUrl = `${environment.patientIntakeFormUrl}/form/${accountSlug}/${formSlug}`;
      console.log(this.formUrl);
    }
    else if (this.slug === "/survey-forms") {
      this.formUrl = `${environment.interactiveFormURL}/survey-forms/${accountSlug}/${formSlug}`;
      console.log(this.formUrl);
    }

    let previewdialogRef = this.dialog.open(PreviewDialogBox, {
      panelClass: 'custom-form-preview-dialog-container',
      width: '800px',
      height: '600px',
      data: { formUrl: this.formUrl, formSlug: formSlug, formName: formName },

    });
  }

  saveForm() {
    this.formBuilderService.saveForm(this.formRef, this.form);
  }

  changeSlug() {
    this.form.slug = this.form.name.replace(/[\s+/+_+]/g, '-').toLowerCase();
  }

}

@Component({
  selector: 'SaveFormDialog',
  templateUrl: 'saveFormDialog.html',
  styleUrls: ['./form-designer.component.css']

})
export class SaveFormDialog {
  constructor(
    public dialogRef: MatDialogRef<SaveFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  close(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'ErrorMessageOnFormSave',
  templateUrl: 'ErrorMessageOnFormSave.html',
  styleUrls: ['./form-designer.component.css']

})
export class ErrorMessageOnFormSave {
  constructor(
    public dialogRef: MatDialogRef<ErrorMessageOnFormSave>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  closeErrorMsg() {
    this.dialogRef.close();
  }
}