// not in use right now
import { Router } from "@angular/router";
import { AfService, AccountService } from "../_services/index";
import { User } from "../_models/interfaces";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";

import { MenuItem } from "primeng/api";

// import { SIDE_MENU } from "../_constants/side-menu";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

export class SideMenuComponent implements OnInit {
  showSidebar: boolean = false;
  menuItems: MenuItem[];
  user: User;
  disable: boolean = false;

  constructor(public router: Router, public afService: AfService) { }

  ngOnInit() {
    this.afService.user$.subscribe((user) => {
      this.user = user;
      if (user !== null || user != undefined) {
        this.disable = user.roles.admin == true ? false : true;
      }

      // panel-menu-array
      this.menuItems = [
        {
          label: "Overview",
          icon: "pi pi-fw pi-home",
          // command: (event) => handleSelected(event),
          command: (event) => (this.showSidebar = false),
          disabled: true,
        },
        {
          label: "Recommendations",
          icon: "pi pi-fw pi-info-circle",
          command: (event) => (this.showSidebar = false),
          disabled: true,
        },
        {
          label: "Contacts",
          icon: "pi pi-fw pi-folder",
          routerLink: ["/contacts"],
          command: (event) => (this.showSidebar = false),
        },
        {
          label: "Appointments",
          icon: "pi pi-fw pi-calendar",
          routerLink: ["/appointments"],
          // command: (event) => handleSelected(event),
          command: (event) => (this.showSidebar = false),
        },
        // {
        //   label: "Nudge Messages",
        //   icon: "pi pi-fw pi-exclamation-circle",
        //   routerLink: ["/nudgeappointments"],
        //   command: (event) => (this.showSidebar = false),
        // },
        {
          label: "Messages",
          icon: "pi pi-fw pi-inbox",
          routerLink: ["/message"],
          command: (event) => (this.showSidebar = false),
          //disabled: true,
        },
        {
          label: "Enquiry Forms",
          icon: "pi pi-fw pi-folder-open",
          routerLink: ["/enquiry-forms"],
          command: (event) => (this.showSidebar = false),
        },
        {
          label: "Patient Forms",
          icon: "pi pi-fw pi-file-pdf",
          routerLink: ["/patient-intake-forms"],
          command: (event) => (this.showSidebar = false),
        },
        {
          label: "Survey Forms",
          icon: "pi pi-fw pi-file-pdf",
          routerLink: ["/survey-forms"],
          command: (event) => (this.showSidebar = false),
        },
        {
          label: "Data Studio Report",
          icon: "pi pi-fw pi-file-pdf",
          routerLink: ["/data-studio-report"],
          command: (event) => (this.showSidebar = false),
        },
        // {
        //   label: "All Forms",
        //   icon: "pi pi-fw pi-file-pdf",
        //   routerLink: ["/all-forms"],
        //   command: (event) => (this.showSidebar = false),
        // },
        // {
        //   label: "Mix Input",
        //   icon: "pi pi-fw pi-folder-open",
        //   routerLink: ["/mix-input"],
        //   command: (event) => (this.showSidebar = false),
        // },

        // {
        //   label: "OTP  verification",
        //   icon: "pi pi-fw pi-folder-open",
        //   routerLink: ["/otp-verification"],
        //   command: (event) => (this.showSidebar = false),
        // },
        {
          label: "Emails",
          icon: "pi pi-fw pi-folder",
          routerLink: ["/#"],
          command: (event) => (this.showSidebar = false),

          disabled: true,
        },
        {
          label: "Phone calls",
          icon: "pi pi-fw pi-user",
          command: (event) => (this.showSidebar = false),
          disabled: true,
        },

        {
          label: "Reviews",
          icon: "pi pi-fw pi-search-plus",
          routerLink: ["/#"],
          command: (event) => (this.showSidebar = false),
          disabled: true,
        },

        {
          label: "In-box",
          icon: "pi pi-fw pi-sign-in",
          routerLink: ["/#"],
          command: (event) => (this.showSidebar = false),
          disabled: true,
        },
        {
          label: "Out-box",
          icon: "pi pi-fw pi-sign-out",
          routerLink: ["/#"],
          command: (event) => (this.showSidebar = false),
          disabled: true,
        },
        {
          label: "Websites",
          icon: "pi pi-fw pi-pencil",
          items: [
            {
              label: "Visitors and Traffic",
              icon: "pi pi-fw pi-users",
              command: (event) => (this.showSidebar = false),
              disabled: true,
            },
            {
              label: "SEO audit",
              icon: "pi pi-fw pi-briefcase",
              routerLink: ["/websites"],
              command: (event) => (this.showSidebar = false),
            },
            {
              label: "Search Rankings",
              icon: "pi pi-fw pi-refresh",
              routerLink: ["/websites"],
              command: (event) => (this.showSidebar = false),
            },
            {
              label: "Top Pages",
              icon: "pi pi-fw pi-sort-amount-up",
              command: (event) => (this.showSidebar = false),

              disabled: true,
            },
            {
              label: "Content Updates",
              icon: "pi pi-fw pi-check",
              command: (event) => (this.showSidebar = false),

              disabled: true,
            },
          ],
        },
        {
          label: "Social Media",
          icon: "pi pi-fw pi-globe",
          items: [
            {
              label: "Page Followers",
              icon: "pi pi-fw pi-users",
              disabled: true,
              command: (event) => (this.showSidebar = false),
            },
            {
              label: "Engagement",
              icon: "pi pi-fw pi-refresh",
              disabled: true,
              command: (event) => (this.showSidebar = false),
            },
            {
              label: "Posts",
              icon: "pi pi-fw pi-images",
              command: (event) => (this.showSidebar = true),
              disabled: false,
            },
          ],
        },
        {
          label: "Marketing",
          icon: "pi pi-fw pi-briefcase",
          items: [
            {
              label: "Campaigns",
              icon: "pi pi-fw pi-chart-line",
              routerLink: ["/main"],

              items: [
                {
                  label: "Email campaigns ",
                  icon: "pi pi-fw pi-envelope",
                  routerLink: ["/email"],

                  command: (event) => (this.showSidebar = false),
                },
                {
                  label: "SMS campaigns",
                  icon: "pi pi-fw pi-comment",
                  routerLink: ["/#"],
                  command: (event) => (this.showSidebar = false),

                  disabled: true,
                },
                {
                  label: "Google campaigns",
                  icon: "pi pi-fw pi-google",
                  routerLink: ["/adwords"],
                  command: (event) => (this.showSidebar = false),
                },
                {
                  label: "Facebook campaigns",
                  icon: "pi pi-fw pi-refresh",
                  routerLink: ["/facebook"],
                  command: (event) => (this.showSidebar = false),
                },
              ],
            },
            {
              label: "Annual Marketing Plan",
              icon: "pi pi-fw pi-chart-line",
              disabled: true,
              command: (event) => (this.showSidebar = false),
            },

            {
              label: "Offers",
              icon: "pi pi-fw pi-tags",
              routerLink: ["/marketing-offers"],
              command: (event) => (this.showSidebar = false),
              // disabled: true,
            },
            {

              label: "Social Media Calendar",
              icon: "pi pi-fw pi-calendar",
              routerLink: ["/social-media-calender"],
              command: (event) => (this.showSidebar = false),
              disabled: true,
            },
            {
              label: "Search Console",
              icon: "pi pi-fw pi-refresh",
              routerLink: ["/search-console"],
              command: (event) => (this.showSidebar = false),
            },
          ],
        },
        {
          label: "Settings",
          icon: "pi pi-fw pi-sliders-h",
          items: [
            {
              label: "Practice settings",
              icon: "pi pi-fw pi-cog",
              routerLink: ["/add-account"],
              command: (event) => (this.showSidebar = false),
            },
            {
              label: "Connect Google, Facebook and more",
              icon: "pi pi-fw pi-refresh",
              routerLink: ["/account"],
              command: (event) => (this.showSidebar = false),
            },
            {
              label: "User Access and Permissions",
              icon: "pi pi-fw pi-key",
              command: (event) => (this.showSidebar = false),
              disabled: true,
            },
            {
              label: "Configure Calendar",
              icon: "pi pi-fw pi-eject",
              routerLink: ["/calendar-config"],
              command: (event) => (this.showSidebar = false),
            },
            // {
            //   label: "Chatbot Settings",
            //   icon: "pi pi-fw pi-eject",
            //   routerLink: ["/chatbot-settings"],
            //   command: (event) => (this.showSidebar = false),
            // }

          ],
        },
        {
          label: "Admin",
          icon: "pi pi-fw pi-user-edit",
          items: [
            {
              label: "Accounts",
              icon: "pi pi-fw pi-plus",
              routerLink: ["/allAccounts"],
              command: (event) => (this.showSidebar = false),
            },
            {
              label: "Target Keywords",
              icon: "pi pi-fw pi-table",
              routerLink: ["/target-keywords-Details"],
              command: (event) => (this.showSidebar = false),
            },
          ],
          disabled: this.disable,
        },
      ];
    });
  }
}
