<div class="row container-fluid justify-content-center">
  <div class="info-cards">
    <mat-card>
      <mat-card-title>
        <center>
          <h2>Forgot Password</h2>
        </center>
      </mat-card-title>
      <p class="mb-4">We'll send password reset link to your email id.</p>
      <mat-card-content>
        <form name="form" (ngSubmit)="f.form.valid && forgotPassword()" #f="ngForm" novalidate>
          <div class="md-form form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
            <div class="col-4 col-12">
              <mat-icon>email</mat-icon>
              <input type="email" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel"
                placeholder="Enter your email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required />
            </div>
            <small *ngIf="f.submitted && email.hasError('required')" class="help-block">Email is required</small>
            <small *ngIf="email.hasError('pattern')" class="help-block">Enter valid Email</small>
          </div>
          <div>
            <center>
              <button mat-raised-button color="primary" type="submit">Send</button>
              <a [routerLink]="['/']">
                <p>Return to login</p>
              </a></center>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
