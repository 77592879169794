import { Component, OnInit } from "@angular/core";
import { NavigationState } from "../_models/index";
import {
  NavigationService,
  FacebookService,
  TimePeriodService,
  GoogleAnalyticsService,
  AccountService,
  AlertService,
  ConversionValueService,
} from "../_services/index";
import { FacebookCampaign } from "../_models/index";
import { DecimalPipe, DatePipe } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: "app-facebook",
  templateUrl: "./facebook.component.html",
  styleUrls: ["./facebook.component.scss"],
})
export class FacebookComponent implements OnInit {
  spend: number = 0;
  value: number = 45000;
  campaigns: FacebookCampaign[];
  report: any = {};
  isReady: boolean;
  isAllow: boolean;
  timeperiod: any;
  analytics: any;
  users = 0;
  sessions = 0;
  bounce_rate = 0;
  ppv = 0;
  duration = "00:00:00";
  campaign: any;
  acc: any;
  conversion_value: any;
  all_conversion_values: any;
  fb_totalConvValue: number = 0;
  facebookCredentials: any;
  analyticsCredentials: any;
  hasAnalyticsAccess: any;

  totalConversions: number = 0;
  conversion: number;
  constructor(
    private navigationService: NavigationService,
    public facebookService: FacebookService,
    private timePeriodService: TimePeriodService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private accountService: AccountService,
    private alertService: AlertService,
    private conversionValueService: ConversionValueService,
    private router: Router
  ) {
    this.isReady = false;
    this.navigationService.updateState(
      new NavigationState(true, "Facebook", "Campaigns", "/main", "/facebook")
    );
    this.accountService.accChange$.subscribe((accChange) => {
      this.router.navigateByUrl("/websites");
      /*
              let state = this.navigationService.getCurrentNavigationState();
              if(state.url === '/facebook') {
                this.acc = this.accountService.getCurrentAccDetails();
                console.log('Account is changed');
                this.facebookCredentials = this.accountService.getLinkedAccountCredentials('facebook');
                this.analyticsCredentials = this.accountService.getLinkedAccountCredentials('analytics');

                 if(this.facebookCredentials){
                if(this.facebookCredentials.facebook_account_id){
                this.loadData();
                this.alertService.reset('');
              }
            }else{
                this.handleUnlinkedAcc();
              }
          }
          */
    });

    this.timePeriodService.timePeriod$.subscribe((timeperiod) => {
      let state = this.navigationService.getCurrentNavigationState();
      if (state.url === "/facebook") {
        this.timeperiod = timeperiod;
        this.loadData();
      }
    });
  }

  handleUnlinkedAcc() {
    this.spend = 0;
    this.value = 0;
    this.campaigns = [];
    this.report = {};
    this.isReady = true;
    this.users = 0;
    this.sessions = 0;
    this.bounce_rate = 0;
    this.ppv = 0;
    this.duration = "00:00:00";
    this.fb_totalConvValue = 0;

    this.alertService.error("You are trying to access an unlinked account");
  }

  ngOnInit() {
    this.timeperiod = this.timePeriodService.getCurrentTimeperiod();
    this.acc = this.accountService.getCurrentAccDetails();
    this.facebookCredentials = this.accountService.getLinkedAccountCredentials(
      "facebook"
    );
    this.analyticsCredentials = this.accountService.getLinkedAccountCredentials(
      "analytics"
    );

    if (this.facebookCredentials) {
      this.loadData();
      this.alertService.reset("");
    } else {
      this.handleUnlinkedAcc();
    }
  }

  loadData() {
    this.hasAnalyticsAccess = this.analyticsCredentials ? true : false;

    this.users = 0;
    this.sessions = 0;
    this.bounce_rate = 0;
    this.ppv = 0;
    this.duration = "00:00:00";

    if (!this.acc.facebook_factor) {
      this.acc.facebook_factor = 1;
    }

    if (!this.acc.account_currency) {
      this.acc.account_currency = "INR";
    }

    if (this.hasAnalyticsAccess) {
      this.googleAnalyticsService
        .getFacebookEngagement(
          this.analyticsCredentials.analytics_refresh_token,
          this.analyticsCredentials.ga_view_id,
          this.timeperiod
        )
        .subscribe((analytics) => {
          this.isReady = true;
          this.analytics = analytics;
          if (this.analytics) {
            this.users = this.analytics.results.users;
            this.sessions = this.analytics.results.sessions;
            this.bounce_rate = this.analytics.results.bounce_rate;
            this.ppv = this.analytics.results.page_view_per_session;
            let hrs = new DecimalPipe("en-US").transform(
              Math.floor(this.analytics.results.avg_session_duration / 3600),
              "2.0-0"
            );
            let min = this.analytics.results.avg_session_duration / 60;
            let min_transformed = new DecimalPipe("en-US").transform(
              Math.floor(min > 60 ? min % 60 : min),
              "2.0-0"
            );
            let sec = new DecimalPipe("en-US").transform(
              this.analytics.results.avg_session_duration % 60,
              "2.0-0"
            );
            this.duration = `${hrs}:${min_transformed}:${sec}`;
          } else {
            this.users = 0;
            this.sessions = 0;
            this.bounce_rate = 0;
            this.ppv = 0;
            this.duration = "00:00:00";
          }
        });
    }

    let record = JSON.parse(sessionStorage.getItem("FB-all-" + this.acc.name));
    if (record !== null) {
      if (record.results.summary) {
        if (
          new DatePipe("en-US").transform(
            this.timeperiod.start,
            "yyyy-MM-dd"
          ) === record.results.summary.date_start &&
          new DatePipe("en-US").transform(this.timeperiod.end, "yyyy-MM-dd") ===
            record.results.summary.date_stop
        ) {
          if (Date.now() - record.creation_timestamp < 300000) {
            console.log(
              "We have a fresh record for this time period. Fetched from localStorage..."
            );
            this.report = record.results;
            this.conversionValueService
              .getAllConvValuesForService("facebook")
              .subscribe((all_conversion_values) => {
                this.all_conversion_values = all_conversion_values;
                this.calculateFbTotalAndCampaignConvValue();
                this.calculateTotalConversions();
              });
            this.isReady = true;
          } else {
            console.log(
              "We have an old record for this time period. Called API and updated record..."
            );
            this.facebookService
              .getFacebookInsights(
                this.facebookCredentials.facebook_access_token,
                this.facebookCredentials.facebook_account_id,
                this.timeperiod
              )
              .then(
                (res) => {
                  this.report = res;
                  this.conversionValueService
                    .getAllConvValuesForService("facebook")
                    .subscribe((all_conversion_values) => {
                      this.all_conversion_values = all_conversion_values;
                      this.calculateFbTotalAndCampaignConvValue();
                      this.calculateTotalConversions();
                    });
                  this.isReady = true;
                  sessionStorage.setItem(
                    "FB-all-" + this.acc.name,
                    JSON.stringify({
                      creation_timestamp: Date.now(),
                      results: this.report,
                    })
                  );
                },
                (error) => console.error(error)
              );
          }
        } else {
          console.log(
            "We do not have a record for this time period. Called API and added record..."
          );
          this.facebookService
            .getFacebookInsights(
              this.facebookCredentials.facebook_access_token,
              this.facebookCredentials.facebook_account_id,
              this.timeperiod
            )
            .then(
              (res) => {
                this.report = res;
                this.conversionValueService
                  .getAllConvValuesForService("facebook")
                  .subscribe((all_conversion_values) => {
                    this.all_conversion_values = all_conversion_values;
                    this.calculateFbTotalAndCampaignConvValue();
                    this.calculateTotalConversions();
                  });
                this.isReady = true;
                sessionStorage.setItem(
                  "FB-all-" + this.acc.name,
                  JSON.stringify({
                    creation_timestamp: Date.now(),
                    results: this.report,
                  })
                );
              },
              (error) => console.error(error)
            );
        }
      } else {
        console.log(
          "We do not have a record for this time period. Called API and added record..."
        );
        this.facebookService
          .getFacebookInsights(
            this.facebookCredentials.facebook_access_token,
            this.facebookCredentials.facebook_account_id,
            this.timeperiod
          )
          .then(
            (res) => {
              this.report = res;
              this.conversionValueService
                .getAllConvValuesForService("facebook")
                .subscribe((all_conversion_values) => {
                  this.all_conversion_values = all_conversion_values;
                  this.calculateFbTotalAndCampaignConvValue();
                  this.calculateTotalConversions();
                });
              this.isReady = true;
              sessionStorage.setItem(
                "FB-all-" + this.acc.name,
                JSON.stringify({
                  creation_timestamp: Date.now(),
                  results: this.report,
                })
              );
            },
            (error) => console.error(error)
          );
      }
    } else {
      console.log(
        "We do not have a record for FB campaigns. Called API and added record..."
      );
      this.facebookService
        .getFacebookInsights(
          this.facebookCredentials.facebook_access_token,
          this.facebookCredentials.facebook_account_id,
          this.timeperiod
        )
        .then(
          (res) => {
            this.report = res;
            this.conversionValueService
              .getAllConvValuesForService("facebook")
              .subscribe((all_conversion_values) => {
                this.all_conversion_values = all_conversion_values;
                this.calculateFbTotalAndCampaignConvValue();
                this.calculateTotalConversions();
              });
            this.isReady = true;
            sessionStorage.setItem(
              "FB-all-" + this.acc.name,
              JSON.stringify({
                creation_timestamp: Date.now(),
                results: this.report,
              })
            );
          },
          (error) => console.error(error)
        );
    }
  }

  calculateFbTotalAndCampaignConvValue() {
    this.fb_totalConvValue = 0;
    for (let i = 0; i < this.report["data"].length; i++) {
      let ind = this.all_conversion_values.findIndex(
        (x) => x.campaign_id == this.report["data"][i]["campaign_id"]
      );
      if (ind == -1) {
        //if conversion value is not store to database
        this.report["data"][i]["conversion_value"] = -1;
      } else {
        let conversions = this.facebookService.getTotalConversions(
          this.report["data"][i]
        );
        this.report["data"][i]["conversion_value"] =
          this.all_conversion_values[ind]["conversion_value"] * conversions;
      }
      if (this.report["data"][i]["conversion_value"] > 0) {
        this.fb_totalConvValue += this.report["data"][i]["conversion_value"];
      }
    }
  }

  calculateTotalConversions() {
    this.totalConversions = 0;
    for (let i = 0; i < this.report["data"].length; i++) {
      this.conversion = this.facebookService.getTotalConversions(
        this.report["data"][i]
      );
      this.totalConversions += +this.conversion;
      console.log(this.totalConversions);
    }
  }
}
