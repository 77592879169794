import { Component, OnInit, OnChanges, Input } from '@angular/core';
let psl = require('psl');

@Component({
  selector: 'app-local-search-rankings',
  templateUrl: './local-search-rankings.component.html',
  styleUrls: ['./local-search-rankings.component.scss']
})
export class LocalSearchRankingsComponent implements OnInit, OnChanges {
  @Input() localPackResults: any = [];
  @Input() accountDetails: any;
  showLocalResults = [];
  localPackAtRank1 = [];
  localPackAtRank3 = [];
  localPackAtRank2 = [];
  currentLocalPack = 0;
  practiceInfoObj = {
    practice_name: '',
    ratings: 0,
    gmbSpeciality: ''
  }
  constructor() { }
  ngOnChanges() {
    // console.log(this.accountDetails);
    if(this.accountDetails['url']){
      let url = new URL(this.accountDetails['url']);
      let domain = psl.get(url.host)
      this.filterLocalPackResults(domain);
    }

  }

  ngOnInit(): void {
  }

  filterLocalPackResults(domain) {
    // this.totalLocalPackResults = this.localPackResults.length;
    // console.log(this.localPackResults.length);

    this.localPackResults.forEach(a => {
      // console.log(a);
      if (a.localResults[0]?.domain == domain) {
        // a.localResults = a.localResults[0];
        this.localPackAtRank1.push(a);
      }
      else if (a.localResults[1]?.domain == domain) {
        // a.localResults = a.localResults[1];
        this.localPackAtRank2.push(a);
      }
      else if (a.localResults[2]?.domain == domain) {
        // a.localResults = a.localResults[2];
        this.localPackAtRank3.push(a);
      }
    });
    this.showLocalResults = this.localPackAtRank1.concat(this.localPackAtRank2).concat(this.localPackAtRank3);
    this.currentLocalPack = 0;
    this.showLocalResults.sort((a, b) =>
      b.search_volume.search_volume_total - a.search_volume.search_volume_total)
    // console.log(this.localPackAtRank1);
    // console.log(this.localPackAtRank2);
    // console.log(this.localPackAtRank3);
  }
  showLocalPackAtRank(rank) {

    if (rank == 0) {
      this.showLocalResults = this.localPackAtRank1.concat(this.localPackAtRank2).concat(this.localPackAtRank3);
      this.currentLocalPack = 0;
      this.showLocalResults.sort((a, b) =>
        b.search_volume.search_volume_total - a.search_volume.search_volume_total)
        // console.log(this.showLocalResults);
      }

    else if (rank == 1) {
      this.currentLocalPack = 1;
      this.showLocalResults = this.localPackAtRank1;
      this.showLocalResults.sort((a, b) =>
        b.search_volume.search_volume_total - a.search_volume.search_volume_total)
      // console.log(this.showLocalResults);
    }

    else if (rank == 2) {
      this.currentLocalPack = 2;
      this.showLocalResults = this.localPackAtRank2;
      this.showLocalResults.sort((a, b) =>
        b.search_volume.search_volume_total - a.search_volume.search_volume_total)
      // console.log(this.showLocalResults);
    }

    else if (rank == 3) {
      this.currentLocalPack = 3;
      this.showLocalResults = this.localPackAtRank3;
      this.showLocalResults.sort((a, b) =>
        b.search_volume.search_volume_total - a.search_volume.search_volume_total)
      // console.log(this.showLocalResults);
    }
  }

}
