import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MarketingPlanService, AlertService, UserService } from '../../_services/index';
import { Allplans } from './all-plans';
//import { PLANS } from './all-plans.mock'

@Component({
  selector: 'app-all-plans',
  templateUrl: './all-plans.component.html',
  styleUrls: ['./all-plans.component.scss']
})
export class AllPlansComponent implements OnInit {
allplans: Allplans[] = [];
plan: number;
user: any;

  constructor(
    private router: Router,
    private userService: UserService,
    private marketingPlanService:MarketingPlanService,
    private alertService:AlertService
  ) { }

  createPlan()
  {
    this.user = this.userService.getUser();

    this.marketingPlanService.createPlan(this.user.uuid)
        .then( data => {
            //console.log(data);
            localStorage.setItem('plan', data.text());
            //this.router.navigate(['/new-plan', 0]);
            let plan = JSON.parse(data.text());
            this.router.navigate(['/plan', plan.id, "Untitled Plan"]);
            },
            error => {
                this.alertService.error("Failed to create plan ");
            });
    //this.router.navigate(['/new-plan', 0]);
  }

  ngOnInit(){

  this.user = this.userService.getUser();

  if ( this.user == null ) {
     this.userService.createUser()
         .then( data => {
             //console.log(data);
             localStorage.setItem('user', data.text());
             this.ngOnInit();
             },
             error => {
                 this.alertService.error("Failed to create user ");
             });
  }

  else {
    this.marketingPlanService.getAllPlans(this.user.uuid)
      .then( allplans => this.allplans = allplans );
    //console.log(this.allplans);

    localStorage.removeItem('plan');
  }
  }
}
