import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { PatientFormService } from "../../_services/patient-form.service";

@Component({
  selector: 'app-patient-form-configuration',
  templateUrl: './patient-form-configuration.component.html',
  styleUrls: ['./patient-form-configuration.component.scss']
})
export class PatientFormConfigurationComponent implements OnInit {
  @ViewChild('form', { static: true }) ngForm: NgForm;
  emailSubject: string = "Patient Form Link";
  emailBody: string = "Click on below link to fill the patient forms";
  smsBody: string = "Click on below link to fill the patient forms";
  displayMsg: string = 'Updating patient form configuration is in progress....';
  inValid: boolean = false;
  confirmation: boolean;
  loading: boolean;
  loadingForConfiguration: boolean;
  docId: string;
  ownerEmail: string;
  constructor(
    private patientFormService: PatientFormService,
  ) { }

  ngOnInit(): void {
    this.getPatientFormConfiguration();
  }

  inputValidator(event: any){
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;   
    if (!pattern.test(event.target.value))
      this.inValid = true;
    else
      this.inValid = false;
      
  }

  async getPatientFormConfiguration() {
    this.loadingForConfiguration = true;
    this.patientFormService.getPatientFormConfiguration().then(async (data) => {
      if (!data.empty) {
        this.docId = data.docs[0].id;
        // ////console.log(this.docId)
        // let configuration = await data.docs.map(data=>data.data())[0];
        let configuration = data.docs[0].data();
        this.loadingForConfiguration = false;
        if (configuration) {
          if ("smsBody" in configuration)
            this.smsBody = configuration['smsBody'];
          if ("emailBody" in configuration) {
            this.emailBody = configuration['emailBody'];
            // ////console.log(this.emailBody);
            if (this.emailBody == "") {
              this.inValid = true;
            }
          }
          if ("emailSubject" in configuration) {
            this.emailSubject = configuration['emailSubject'];
          }
          if ('ownerEmail' in configuration) {
            this.ownerEmail = configuration['ownerEmail'];
          }
        }
      }
      else {
        this.loadingForConfiguration = false;
      }
    })
  }

  Save() {
    this.confirmation = true;
    this.loading = true;
    let patientFormConfigurationData = {
      smsBody: this.smsBody,
      emailBody: this.emailBody,
      emailSubject: this.emailSubject,
      ownerEmail: this.ownerEmail,
      type: 'patient-form-configuration'
    };
    if (this.docId) {
      this.displayMsg = 'Updating patient form configuration is in progress....';
      this.patientFormService.updatePatientFormConfiguration(patientFormConfigurationData, this.docId).then(resp => {
        this.loading = false;
        this.displayMsg = `Patient form configuration updated successfully`;
        setTimeout(() => {
          this.confirmation = false;
        }, 3000);
      });
    }
    else {
      this.displayMsg = 'Adding patient form configuration is in progress....';
      this.patientFormService.addPatientFormConfiguration(patientFormConfigurationData).then(async (resp) => {
        if (resp.id) {
          this.loading = false;
          this.docId = resp.id
          this.displayMsg = `Patient form configuration added successfully`;
          setTimeout(() => {
            this.confirmation = false;
          }, 3000);
        }
      });
    }

  }

  onTextChange(event) {
    if (event['htmlValue']) {
      this.emailBody = event['htmlValue'];
      this.inValid = false;
    }
    else {
      this.emailBody = "";
      this.inValid = true;
    }
    // ////console.log(this.emailBody);
  }

}
