import { Component, OnInit } from '@angular/core';
import { FormBuilderService } from '../../_services/form-builder.service';
import { AccountService } from '../../_services/account.service';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { NavigationState } from "../../_models/index";
import { NavigationService } from "../../_services/index";

@Component({
  selector: 'app-samples',
  templateUrl: './samples.component.html',
  styleUrls: ['./samples.component.css']
})
export class SamplesComponent implements OnInit {
  sample_forms: any = [];
  acc_slug: any;
  state: any;
  constructor(
    private formBuilderService: FormBuilderService,
    private accountService: AccountService,
    private navigationService: NavigationService,

  ) {
    this.accountService.accChange$.subscribe(
      accChange => {
        this.acc_slug = this.accountService.getCurrentAccDetails().account_slug;
      });
  }

  ngOnInit() {
    this.state = this.navigationService.getCurrentNavigationState();
    console.log(this.state, this.state.url);

    if (this.state.url === "/form") {
      this.getSampleForm('forms');
    }
    else if (this.state.url === "/satisfactory-forms") {
      this.getSampleForm('satisfactory_forms');
    }
  }
  getSampleForm(selctedSlug) {
    console.log(selctedSlug);
    this.acc_slug = "cosmetic-surgery-&-medispa";
    this.sample_forms = [];
    this.formBuilderService.getSampleForms(selctedSlug).then(querySnapshot => {
      querySnapshot.forEach(doc => {
        let form = { id: '', slug: '', data: {} };
        form.data = doc.data();
        form.id = doc.id;
        form.slug = doc.data().slug;
        this.sample_forms.push(form);

        //sub-header
        this.navigationService.updateState(
          new NavigationState(
            true, `${form.slug}`, "Form", `${this.state.url}`, "/samples"
          )
        );
      });
    });
  }
}