<div class="container-fluid">
  <center>
    <div *ngIf="!isReady">
      <!--Fetching your campaigns ...-->
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
  </center>

  <!-- -----------sub header---------- -->
  <div class="row" id="subHeader1">
    <div id="pageTitle">
      <a [routerLink]="['/adwords-campaign']"> <i class="pi pi-chevron-left"></i>AdWords Campaigns
      </a>
    </div>

    <div id="subPageTitle">
      {{campaign.campaign}}
    </div>
  </div>
  <!-- ------------subheader ends--------- -->
  <div class="scrolling-wrapper-flexbox">
    <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi">
          {{(+campaign?.cost / 1000000) * acc?.adwords_factor| currency: acc?.account_currency:"symbol":"1.0-0"}}
        </div>
        <div class="metric-caption kpi">Spend</div>
      </mat-card-content>
    </mat-card>
    <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi" *ngIf="campaign_conversion_value > -1; else noConversionValue">
          {{campaign_conversion_value * campaign?.conversions| currency: acc.account_currency:"symbol":"1.0-0"}}
        </div>
        <ng-template #noConversionValue>
          <div class="metric kpi">?</div>
        </ng-template>
        <div class="metric-caption kpi">Conversion Value</div>
      </mat-card-content>
    </mat-card>
    <mat-card class="metric-card">
      <mat-card-header>
        <mat-card-title class="metric-caption"></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="metric kpi" *ngIf="campaign_conversion_value > -1; else noConversionValue">
          {{(campaign_conversion_value * campaign?.conversions) /((+campaign?.cost / 1000000) * acc?.adwords_factor)| number: "1.0-1"}}X
        </div>
        <div class="metric-caption kpi">ROI</div>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-divider></mat-divider>

  <div class="row">
    <div class="col-0 triangle-bottomleft"></div>
    <div class="col">
      <center>
        <div *ngIf="!isReady">
          <!--Fetching your campaigns ...-->
          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
      </center>
      <table border="0" width="100%">
        <tr>
          <td width="50%">
            Impressions
          </td>
          <td width="50%">
            <span class="metric-value fbfont">{{campaign?.impressions | number}}</span>
          </td>
        </tr>
      </table>

      <mat-divider> </mat-divider>
      <table border="0" width="100%">
        <tr *ngIf="campaign?.advertisingChannel == 'Video'">
          <td width="50%">
            Interactions
          </td>
          <td width="50%">
            <span class="metric-value fbfont">{{campaign?.interactions}}</span>
          </td>
        </tr>
        <tr>
          <td width="50%">
            Clicks
          </td>
          <td width="50%">
            <span class="metric-value fbfont">{{campaign?.clicks | number}}</span>
          </td>
        </tr>
        <tr>
          <td width="50%">
            CTR
          </td>
          <td width="50%">
            <span class="metric-value fbfont">{{ campaign?.ctr }}</span>
          </td>
        </tr>
        <tr>
          <td width="50%">
            Avg. CPC
          </td>
          <td width="50%">
            <span class="metric-value fbfont">
              {{(campaign?.avgCPC / 1000000) * acc.adwords_factor| currency: acc.account_currency:"symbol":"1.0-2"}}
            </span>
          </td>
        </tr>
        <tr>
          <td width="50%">
            Avg. CPM
          </td>
          <td width="50%">
            <span class="metric-value fbfont">
              {{(campaign?.avgCPM / 1000000) * acc.adwords_factor| currency: acc.account_currency:"symbol":"1.0-2"}}
            </span>
          </td>
        </tr>
        <tr *ngIf="campaign?.advertisingChannel == 'Video'">
          <td width="50%">
            Avg. CPV
          </td>
          <td width="50%">
            <span class="metric-value fbfont">
              {{(campaign?.avgCPV / 1000000) * acc.adwords_factor | currency: acc.account_currency:"symbol":"1.0-2"}}
            </span>
          </td>
        </tr>
        <tr *ngIf="campaign?.advertisingChannel == 'Search'">
          <td width="50%">
            Avg. Position
          </td>
          <td width="50%">
            <span class="metric-value fbfont">{{ campaign?.avgPosition }}</span>
          </td>
        </tr>
      </table>

      <mat-divider></mat-divider>

      <!--Video-->
      <span *ngIf="campaign?.advertisingChannel == 'Video'">
        <mat-divider></mat-divider>
        <table border="0" width="100%">
          <tr>
            <td width="50%">
              Video Views
            </td>
            <td width="50%">
              <span class="metric-value">{{ campaign?.views }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              View Rate
            </td>
            <td width="50%">
              <span class="metric-value">{{ campaign?.viewRate }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Watched at 25%
            </td>
            <td width="50%">
              <span class="metric-value">{{ campaign?.videoPlayedTo25 }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Watched at 50%
            </td>
            <td width="50%">
              <span class="metric-value">{{ campaign?.videoPlayedTo50 }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Watched at 75%
            </td>
            <td width="50%">
              <span class="metric-value">{{ campaign?.videoPlayedTo75 }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Watched at 100%
            </td>
            <td width="50%">
              <span class="metric-value">{{ campaign?.videoPlayedTo100 }}</span>
            </td>
          </tr>
        </table>
      </span>
      <!--Close Video-->
      <!--display campagin-->
      <span *ngIf="
          campaign?.advertisingChannel == 'Display' ||
          campaign?.advertisingChannel == 'Video'
        ">
        <mat-divider></mat-divider>
        <table border="0" width="100%">
          <tr>
            <td width="50%">
              Measurable Impressions
            </td>
            <td width="50%">
              <span class="metric-value">{{
                campaign?.activeViewMeasurableImpr
              }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Measurability
            </td>
            <td width="50%">
              <span class="metric-value">{{
                campaign?.activeViewMeasurableImprImpr
              }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Measurable Cost
            </td>
            <td width="50%">
              <span class="metric-value">{{
                (campaign?.activeViewMeasurableCost / 1000000) *
                  acc.adwords_factor
                  | currency: acc.account_currency:"symbol":"1.0-2"
              }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Viewable Impressions
            </td>
            <td width="50%">
              <span class="metric-value">{{
                campaign?.activeViewViewableImpressions
              }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Viewability
            </td>
            <td width="50%">
              <span class="metric-value">{{
                campaign?.activeViewViewableImprMeasurableImpr
              }}</span>
            </td>
          </tr>

          <tr>
            <td width="50%">
              Viewable CTR
            </td>
            <td width="50%">
              <span class="metric-value">{{
                campaign?.activeViewViewableCTR
              }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Avg CPM
            </td>
            <td width="50%">
              <span class="metric-value">{{
                (campaign?.activeViewAvgCPM / 1000000) * acc.adwords_factor
                  | currency: acc.account_currency:"symbol":"1.0-2"
              }}</span>
            </td>
          </tr>
        </table>
      </span>
      <!--close display-->
      <span *ngIf="campaign?.conversions > 0">
        <mat-divider></mat-divider>
        <table border="0" width="100%">
          <tr>
            <td width="50%">
              Conversions
            </td>
            <td width="50%">
              <span class="metric-value">{{
                campaign?.conversions | number
              }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              ConversionRate
            </td>
            <td width="50%">
              <span class="metric-value">{{ campaign?.convRate }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Cost Per Conversion
            </td>
            <td width="50%">
              <span class="metric-value">{{
                (campaign?.costConv / 1000000) * acc.adwords_factor
                  | currency: acc.account_currency:"symbol":"1.0-2"
              }}</span>
            </td>
          </tr>
          <tr>
            <td width="50%">
              Impression Assisted Conversions
            </td>
            <td width="50%">
              <span class="metric-value fbfont">{{
                campaign?.imprAssistedConv | number
              }}</span>
            </td>
          </tr>
        </table>
      </span>

      <mat-divider> </mat-divider>

      <span *ngIf="campaign?.advertisingChannel == 'Video'; then video"> </span>
      <span *ngIf="campaign?.advertisingChannel == 'Search'; then search">
      </span>
      <span *ngIf="campaign?.advertisingChannel == 'Display'; then display">
      </span>
      <ng-template #video>
        <div class="col metric kpi">{{ campaign?.views | number }}</div>
        <div class="col metric-caption kpi">Video Views</div>
        <div class="col metric kpi">
          {{
            (+campaign?.avgCPV / 1000000) * acc.adwords_factor
              | currency: acc.account_currency:"symbol":"1.0-0"
          }}
        </div>
        <div class="col metric-caption kpi">Cost per video view</div>
      </ng-template>
      <ng-template #search>
        <div class="col metric kpi">{{ campaign?.conversions | number }}</div>
        <div class="col metric-caption kpi">Conversions</div>
        <div class="col metric kpi">
          {{
            (campaign?.costConv / 1000000) * acc.adwords_factor
              | currency: acc.account_currency:"symbol":"1.0-0"
          }}
        </div>
        <div class="col metric-caption kpi">Cost Per Conversion</div>
      </ng-template>

      <ng-template #display>
        <div class="col metric kpi">{{ campaign?.conversions | number }}</div>
        <div class="col metric-caption kpi">Conversions</div>
        <div class="col metric kpi">
          {{
            (campaign?.costConv / 1000000) * acc.adwords_factor
              | currency: acc.account_currency:"symbol":"1.0-0"
          }}
        </div>
        <div class="col metric-caption kpi">Cost Per Conversion</div>
      </ng-template>
    </div>
    <div class="col-0 triangle-bottomright"></div>
  </div>
  <mat-divider></mat-divider>
</div>