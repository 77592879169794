import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { NgForm, NgModel, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MarketingPlanService, ApiService } from '../../_services/index';

@Component({
  selector: 'app-competitors',
  templateUrl: './competitors.component.html',
  styleUrls: ['./competitors.component.scss']
})
export class CompetitorsComponent implements OnInit {

  @Output() confirmCompetitorsValid = new EventEmitter<any>();

//-------------------for line chart
  public chartData:Array<any> = [{data:[],label:""},{data:[],label:""}];
  public chartLabels:Array<any> = [];

//------------------for bar chart
  public chartData1:Array<any> = [{data:[],label:""},{data:[],label:""}];
  public chartLabels1:Array<any> = [];

  competitorForm:FormGroup;
  isReady:boolean = false; // flag to maintain status if component is ready to display
  disableDIV:boolean = false;
  constructor(
    private marketingPlanService: MarketingPlanService,
  private apiService: ApiService,
  private fb: FormBuilder
  ) {
  this.createForm();
  marketingPlanService.competitors$.subscribe(
    info => {
      this.competitorForm.get('competitor1').setValue(info.competitor1);
      this.competitorForm.get('competitor2').setValue(info.competitor2);
      this.competitorForm.get('competitor3').setValue(info.competitor3);
      //console.log(this.competitorForm.value);
    }
  );
  }

  ngOnInit() {
  //  if(this.competitorForm.value != null) {
    //this.disableDIV=true;
    this.disableDIV=false;

      this.isReady = true;
      this.competitorForm.markAsDirty();
    //this.disableDIV=true;
    //  this.next();
    //}
  }

  createForm()
  {
  this.competitorForm = this.fb.group({
    competitor1: ['url1.com'],
    competitor2: ['url2.com'],
    competitor3: ['url3.com']
    });
  }

  saveCompetitors(competitors) {
    this.competitorForm.get('competitor1').setValue(competitors[0]);
    this.competitorForm.get('competitor2').setValue(competitors[1]);
    this.competitorForm.get('competitor3').setValue(competitors[2]);
    //console.log(this.competitorForm.value);
  }

  show(url: string)
  {
  /* Simulating a delay in response from server by setting a timeout */
    this.isReady = false;
      setTimeout(() => {
      this.isReady = true;
      }, 3000);

    //console.log(this.competitorForm.value);
    this.competitorForm.markAsDirty();
    this.apiService.fetchCompetitors()
        .subscribe( competitors => this.saveCompetitors(competitors) );


  }

  next() {
    this.fetchVisitorsforlinechart();
    this.fetchLabelsforlinechart();
    this.fetchContributionsfortrialbarchart();
    this.fetchLabelsfortrialbarchart();
    this.competitorForm.markAsPristine();
    this.confirmCompetitorsValid.emit(this.competitorForm.value);
  }

//--------------------------------------for line chart
    setLabelsforlinechart(month){
      this.chartLabels=month;
    }

    fetchLabelsforlinechart(){
        this.apiService.fetchTrendsforlinechart()
        .subscribe(input=>
                    {
                      this.setLabelsforlinechart(input.month);
                    }
                  );
    }

    setVisitesforlinechart(data){
       this.chartData=[{data:data[0],label:"competitors_site"},{data:data[1],label:"your_site"}];
      }

    fetchVisitorsforlinechart(){
        this.apiService.fetchTrendsforlinechart()
        .subscribe(response=>
                    {
                      this.setVisitesforlinechart(response.data);
                    }
                  );
    }

    public chartType:string = 'line';
    public chartColors:Array<any> = [
           {
               backgroundColor: 'rgba(220,220,220,0.2)',
               borderColor: 'rgba(220,220,220,1)',
               borderWidth: 2,
               pointBackgroundColor: 'rgba(220,220,220,1)',
               pointBorderColor: '#fff',
               pointHoverBackgroundColor: '#fff',
               pointHoverBorderColor: 'rgba(220,220,220,1)'
           },
           {
               backgroundColor: 'rgba(151,187,205,0.2)',
               borderColor: 'rgba(151,187,205,1)',
               borderWidth: 2,
               pointBackgroundColor: 'rgba(151,187,205,1)',
               pointBorderColor: '#fff',
               pointHoverBackgroundColor: '#fff',
               pointHoverBorderColor: 'rgba(151,187,205,1)'
           }
       ];

      public chartOptions:any = {
           responsive: true
       };

       public chartClicked(e: any): void {
       }

       public chartHovered(e: any): void {
       }

  //---------------------------for bar chart
    setLabelsfortrialbarchart(type){
      this.chartLabels1=type;
    }

    setContributionsfortrialbarchart(contribution){
      this.chartData1=[{data:contribution[0],label:"competitors_site"},{data:contribution[1],label:"your_site"}];
    }

    fetchLabelsfortrialbarchart(){
      this.apiService.fetchTrendsfortrialbarchart()
      .subscribe(response=>
                {
                  this.setLabelsfortrialbarchart(response.type);
                }
          );
    }

    fetchContributionsfortrialbarchart(){
      this.apiService.fetchTrendsfortrialbarchart()
      .subscribe(response=>
                {
                  this.setContributionsfortrialbarchart(response.contribution);
                }
          );
    }

    public chartType1:string = 'bar';
    public chartColors1:Array<any> = [
         {
             backgroundColor: "#F7464A",
             borderColor: 'rgba(0, 0, 0, 0.1)',
             borderWidth: 2,
             pointBackgroundColor: 'rgba(0, 0, 0, 0.1)',
             pointBorderColor: '#fff',
             pointHoverBackgroundColor: '#fff',
             pointHoverBorderColor: 'rgba(0, 0, 0, 0.1)'
         },
         {
             backgroundColor: "#46BFBD",
             borderColor: 'rgba(151,187,205,1)',
             borderWidth: 2,
             pointBackgroundColor: 'rgba(151,187,205,1)',
             pointBorderColor: '#fff',
             pointHoverBackgroundColor: '#fff',
             pointHoverBorderColor: 'rgba(151,187,205,1)'
         }
     ];

    public chartOptions1:any = {
         responsive: true
    };

    public chartClicked1(e: any): void {
    }

    public chartHovered1(e: any): void {
     }
}
