<!-- <div><i class="material-icons icon-btn" style="float: right;" (click)="close()">close</i></div> -->
<!-- <h4 mat-dialog-title style="text-align:left; font-size:1.25rem;">{{title}}</h4> -->
<!-- <iframe width="100%" height="420px" frameborder="0" [src]="detailsArray"></iframe> -->

<!-- {{detailsArray.name}} -->
<!-- <mat-dialog-content> -->
<!-- {{name}} -->
<!-- </mat-dialog-content>-->

<h1 mat-dialog-title>{{apptData?.first_name}}{{apptData?.last_name}}</h1>
<!-- <h1 mat-dialog-title>Appointment Details</h1> -->
<div mat-dialog-content>
    <div class="table-responsive">
        <table class="table">
            <thead></thead>
            <tbody>
                <tr>
                    <td>Start Date</td>
                    <td>{{apptData.appt_start | date: 'dd/MM/yyyy'}}</td>

                </tr>
                <tr>
                    <td>End Date</td>
                    <td>{{apptData.appt_end | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr>
                    <td>Slot</td>
                    <td>{{apptData?.slot}}</td>
                </tr>
                <tr>
                    <td>Booked on</td>
                    <td>{{apptData.booked_on | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>{{apptData?.status}}</td>
                </tr>
                <tr *ngIf="apptData.calName!=undefined">
                    <td>Calendar Name:</td>
                    <td>{{apptData?.calName}}</td>
                </tr>
                <tr>
                    <td>Location</td>
                    <td>{{apptData?.location}}</td>
                </tr>
                <tr>
                    <td>Source</td>
                    <td>{{apptData?.source}}</td>
                </tr>
                <tr>
                    <td>New Patient:</td>
                    <td>{{apptData?.new_patient}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <mat-form-field>
        <mat-label>Notes</mat-label>
        <input matInput [(ngModel)]="apptData.notes">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Close</button>
    <button mat-button [mat-dialog-close]="apptData.notes" cdkFocusInitial>Save</button>
</div>