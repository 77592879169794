<div *ngIf="state.showBreadcrumb == true">
  <a [routerLink]="[state.back_url]">
    <mat-icon class="breadcrumbs">chevron_left</mat-icon>{{ state.icon }}
  </a>

  <div class="account-name1">
    <span matTooltip="{{ state.title }}">
      {{ state.title | slice: 0:25 }}
      <span *ngIf="state.title.length > 25">...</span>
    </span>
  </div>
</div>

<div *ngIf="state.showBreadcrumb == false">

  <div class="account-name2">
    <span matTooltip="{{ state.title }}">
      {{ state.title | slice: 0:25 }}
      <span *ngIf="state.title.length > 25">...</span>
    </span>
  </div>
</div>