
import {switchMap} from 'rxjs/operators';
import { Component, OnInit,Input } from '@angular/core';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { AlertService,UserService } from '../../_services/index';

import { User } from '../../_models/index';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'mail-verification.html'
})

export class MailVerificationComponent {
  @Input() model: any = {};
    returnUrl: string;
    uuid:string;
    currentUser: User;

    constructor(
      private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private alertService: AlertService)
        {
      }

      ngOnInit() {

           this.route.params.pipe(
             switchMap((params:Params) => this.userService.getVerifyToken(params['uuid'])))
             .subscribe(uuid=>this.uuid=uuid);

             this.verifyMail(this.uuid);
      }

      verifyMail(uuid:string)
      {
            this.userService.verifyMail(uuid)
            .subscribe(
            data=> {
                     this.alertService.success("Email verified successfully..");
                      this.router.navigate(['/all-plans']);
                     },
            error => {
                    this.alertService.error("Link Expired");
                  });

        }
}
