<mat-card>
<p><mat-chip>{{ tip?.type}}</mat-chip></p>
  <mat-card-title>
    <i class="{{ tip?.icon }}"></i>{{ tip?.title }}
  <button type="button" class="close pull-right" aria-label="Close" [routerLink]="['/tips']" [queryParams]="{selectedIndex : selectedIndex}">
  <!--<button type="button" class="close pull-right" aria-label="Close" (click)="close(i)">-->
      <span aria-hidden="true">x</span>
    </button>
  </mat-card-title>
  <mat-card-subtitle><h6>{{ tip?.short_desc }} </h6></mat-card-subtitle>

  <mat-card-content>
    <div class="container-fluid">
      <div *ngIf="tip?.type=='New Campaign';then campaign else optimize"></div>
      <ng-template #campaign>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <h6>Campaign Summary</h6>
          <p>{{ tip?.long_desc }}</p>
          <h6><mat-icon>wifi_tethering</mat-icon>Marketing Objective</h6>
          <p>{{ tip?.objective }}</p>
          <h6><mat-icon>monetization_on</mat-icon> Suggested Budget:</h6>
          <p>{{ tip?.budget | currency:acc.account_currency:'symbol':'1.0-0' }}</p>
          <h6><mat-icon>wc</mat-icon> Target Audience:</h6>
          <p>
          <span *ngIf="tip?.target_gender=='men'">Men </span>
          <span *ngIf="tip?.target_gender=='women'">Women </span>
          <span *ngIf="tip?.target_gender=='both'">Both Men and Women </span>
          between the age of {{ tip?.target_age}}, residing in or visiting
          <ol>
          <div *ngFor="let location of tip?.target_location">
            <li>{{location}}</li>
          </div>
          </ol>
          <p><mat-icon>note</mat-icon> Special notes: {{ tip?.target_notes}}<p>
        </div>
        <div class="col-md-6">
          <h6>Ad Preview</h6>

        <!--<div *ngFor="let ad of tip?.ads">-->
          <mat-card class="ad-preview-cards">
            <mat-card-header>
              <button mat-icon-button (click)="prevAd()"><mat-icon>chevron_left</mat-icon> </button>
              <span class="spacer"> Click to see more ...</span><button mat-icon-button (click)="nextAd()"><mat-icon>chevron_right</mat-icon>  </button>
            </mat-card-header>
            <img mat-card-image [src]="tip.ads[currentAd]">
          <mat-card-actions align="end">
            <button mat-icon-button (click)="prevAd()"><mat-icon>chevron_left</mat-icon> </button>
            <span class="spacer"></span><button mat-icon-button (click)="nextAd()"><mat-icon>chevron_right</mat-icon>  </button>
          </mat-card-actions>
        </mat-card>
        <!--</div>-->
        <!--<mdb-carousel [isControls]="true" class="carousel" [animation]="'slide'" data-interval="false">
          <div *ngIf="tip?.image1 != ''; else else_img">
            <mdb-slide>
              <img class="d-block w-100 carousel" [src]='tip?.image1'>
            </mdb-slide>
          </div>

          <div *ngIf="tip?.image2 != ''; else else_img">
            <mdb-slide>
              <img class="d-block w-100 carousel" [src]='tip?.image2'>
            </mdb-slide>
          </div>

          <div *ngIf="tip?.image3 != ''; else else_img">
            <mdb-slide>
              <img class="d-block w-100 carousel" [src]='tip?.image3'>
            </mdb-slide>
          </div>

          <div *ngIf="tip?.image4 != ''; else else_img">
            <mdb-slide>
              <img class="d-block w-100 carousel" [src]='tip?.image4'>
            </mdb-slide>
          </div>

          <div *ngIf="tip?.image5 != ''; else else_img">
            <mdb-slide>
              <img class="d-block w-100 carousel" [src]='tip?.image5'>
            </mdb-slide>
          </div>

          <ng-template #else_img>
            <mdb-slide>
              <img class="d-block w-100 carousel" src='../../assets/images/download.png'>
            </mdb-slide>
          </ng-template>
        </mdb-carousel>-->
      </div>
      </div>
    </ng-template>
    <ng-template #optimize>
      <div class="row justify-content-center">
        <div class="col">
          <h6>Recommendation</h6>
          <p>{{ tip?.long_desc }}</p>
        </div>
      </div>
    </ng-template>
    </div>
  </mat-card-content>
<mat-card-actions>
  <div class="row justify-content-center" [hidden]="(tip?.status=='Accepted' || tip?.status=='Declined' || tip?.status=='Expired')">
  <button mat-raised-button color="" class="" aria-label="Accept" (click)="accept()">Accept</button>
  <button mat-raised-button color="" class="" aria-label="Snooze" (click)="snooze()" [disabled]="tip?.status === 'Snoozed'">Snooze</button>
  <button mat-raised-button color="warn" class="" aria-label="Decline" (click)="decline()">Decline</button>
  </div>
  <!--  <div class="row justify-content-center">
  <ng-template #noTip><mat-card-title>No Tips Present</mat-card-title></ng-template></div>-->
</mat-card-actions>
<p class="footer">Good {{ now | date:'bbbb' }}! Tip generated by mktgbot on {{ tip?.timestamp | date:'MMM d, y' }} at {{ tip?.timestamp | date:'h:mm a' }}. Your personal digital marketing assistant continues to work while you catch-up on some sleep.</p>
</mat-card>
