<div>
  <div *ngIf="loading; else loaded">
    <div style="height: fit-content;width:fit-content;margin: auto;">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <ng-template #loaded>
    <div style="width: 100%;padding:0px 0px 0px 10px;" *ngIf="configurations">
      <div class="row" style="display: flex;padding:10px;">
        <div class="col-md-4" style="text-align: start;"><b>Website : </b>{{configurations?.websiteUrl}}</div>
        <div class="col-md-4" style="text-align: center;"><b>Location : </b>{{configurations?.country?.name}}</div>
        <div class="col-md-4" style="text-align: right"><b>Fetched on : </b>{{createdOn|date: 'MMM d, yyyy hh:mm a'}}
        </div>
      </div>
    </div>
    <div style="width: 100%;padding:0px 0px 0px 10px;" *ngIf="!configurations">
      <div id="noData">You don't have organic rankings..</div>
    </div>
    <div style="width: 100%;padding:0px 0px 0px 10px;" *ngIf="configurations">
      <div id="noData" *ngIf="configurations['status']=='no-data'">Your website is not ranking</div>
    </div>
    <div *ngIf="ranksExist && configurations['status']!='no-data';else noLocalRank"
      style="width: 100%;padding:0px 0px 0px 10px;">
      <div class="row" style="display: flex;padding:10px;">
        <div class="col">
          <p-card
            [style]="{'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'}">
            <span style="text-align: center;">
              <b>{{configurations?.metrics?.organic?.count}}</b><br>
              Organic
            </span>
          </p-card>
        </div>
        <div class="col">
          <p-card (click)="getCardData(cardSelection[0],0)" [class.selectCard]="cardSelection[0]['selected']"
            [style]="{'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'}"
            class="card">
            <span style="text-align: center;">
              <!-- <b *ngIf="showOldRecord">{{configurations?.metrics.organic.is_new}}</b><br> -->
              <b>{{keyword_status.is_new}}</b><br>
              New
            </span>
          </p-card>
        </div>
        <div class="col">
          <p-card (click)="getCardData(cardSelection[1],1)" [class.selectCard]="cardSelection[1]['selected']"
            class="card"
            [style]="{'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'}">
            <span style="text-align: center;">
              <!-- <b *ngIf="showOldRecord">{{configurations?.metrics.organic.is_up}}</b><br> -->
              <b>{{keyword_status.is_up?keyword_status.is_up:0}}</b><br>
              Up
            </span>
          </p-card>
        </div>
        <div class="col">
          <p-card (click)="getCardData(cardSelection[2],2)" [class.selectCard]="cardSelection[2]['selected']"
            class="card"
            [style]="{'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'}">
            <span style="text-align: center;">
              <!-- <b *ngIf="showOldRecord">{{configurations?.metrics.organic.is_down}}</b><br> -->
              <b>{{keyword_status.is_down}}</b><br>
              Down
            </span>
          </p-card>
        </div>
        <div class="col">
          <p-card (click)="getCardData(cardSelection[3],3)" [class.selectCard]="cardSelection[3]['selected']"
            class="card"
            [style]="{'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'}">
            <span style="text-align: center;">
              <!-- <b *ngIf="showOldRecord">{{configurations?.metrics.organic.is_lost}}</b><br> -->
              <b>{{keyword_status.is_lost}}</b><br>
              Lost
            </span>
          </p-card>
        </div>
        <div class="col">
          <p-card
            [style]="{'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'}">
            <span style="text-align: center;">
              <b>{{configurations?.metrics?.organic?.count -
                (keyword_status.is_up +
                keyword_status.is_down +
                keyword_status.is_new)}}</b><br>
              No Change
            </span>
          </p-card>
        </div>
      </div>
      <div class="scrolling-wrapper-flexbox">
        <div class="row" *ngFor="let pos of positionArr;let i=index">
          <div style="display: flex;" class="col">
            <p-card (click)="getPositionwiseData(pos,i)" [class.selectCard]="pos['selected']"
              [style]="{width:'150px','box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'}"
              class="pCardShadow">
              <span style="text-align: center;">
                <!-- <b>{{configurations?.metrics.organic[pos.field]}}</b><br> -->
                <b>{{configurations?.metrics?.organic[pos.field]}}</b><br>
                {{pos.name}}
              </span>
            </p-card>
          </div>
        </div>
      </div>
      <div style="width:100%;padding:10px;height: 100%;margin: 0px;" class="row">
        <div class="mat-elevation-z8" id="pTable" *ngIf="!show">
          <div style="text-align: center;">
            <i class="pi pi-spin pi-spinner" style="font-size: 3rem;color: #7e84f2;"></i>
          </div>
        </div>
        <div class="col" style="width: 100%;height: 100%;padding: 0px;" *ngIf="show">
          <p-table [columns]="cols" [value]="organicRanks" [paginator]="true" [rows]="rows" #dt1 sortMode="multiple"
            [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} of {totalRecords}"
            [rowsPerPageOptions]="[10,20,50]" [first]="first" [globalFilterFields]="searchFields"
            [multiSortMeta]="[{field: 'ranked_serp_element.serp_item.rank_group', order: 1}]">

            <ng-template pTemplate="caption">
              <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto" style="width: 100%;">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search keyword" style="width: 100%;border: none;" class="form-control" />
                </span>
                <button type="button" pButton pRipple icon="pi pi-file" (click)="dt1.exportCSV()" class="mr-2"
                  pTooltip="Export CSV" tooltipPosition="bottom"></button>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns;let i=index" [pSortableColumn]="col.field"
                  [ngStyle]="(i==0)?{}:{'text-align':'center'}">
                  {{col.header}}<p-sortIcon [field]="col.field" *ngIf="col.header == 'Position'"></p-sortIcon>
                  <!-- <div *ngIf="i == 0">{{col.header}}</div>
                      <div *ngIf="i!=0" style="text-align: center;">
                        {{col.header}}<p-sortIcon [field]="col.field" *ngIf="col.header == 'Position'"></p-sortIcon>
                      </div> -->
                  <!-- <div [ngStyle]="(i==0)?{}:{'text-align':'center'}">{col.header}}</div> -->
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td>
                  {{rowData.keyword_data.keyword}}
                </td>
                <td style="text-align: center;"
                  *ngIf="rowData['rank_group_analysis'] && (rowData?.rank_group_analysis.is_up || rowData?.rank_group_analysis.is_down)">
                  <div class="row" *ngIf="rowData?.rank_group_analysis.is_up || rowData?.rank_group_analysis.is_down">
                    <div class="col-4" style="text-align: right;padding:0px">
                      {{rowData?.rank_group_analysis.previous_rank_group}}</div>
                    <div class="col-4"
                      *ngIf="rowData?.rank_group_analysis.is_up || rowData?.rank_group_analysis.is_down"
                      class="material-icons" style="margin: 0 10px;"
                      [ngClass]="{'rankInc':rowData?.rank_group_analysis.is_up,'rankDec':rowData?.rank_group_analysis.is_down}">
                      trending_flat
                    </div>
                    <div class="col-4" style="text-align: left;padding:0px">
                      {{rowData?.rank_group_analysis.current_rank_group}}
                    </div>
                  </div>
                </td>

                <td class="box new" *ngIf="rowData['rank_group_analysis'] && rowData?.rank_group_analysis.is_new">
                  <div style="text-align: center;" *ngIf="rowData?.rank_group_analysis.is_new">
                    {{rowData.ranked_serp_element.serp_item.rank_group}}
                  </div>
                </td>

                <td *ngIf="rowData['rank_group_analysis'] && rowData?.rank_group_analysis.is_lost">
                  <div style="text-align: center;" *ngIf="rowData?.rank_group_analysis.is_lost">
                    {{rowData.ranked_serp_element.serp_item.rank_group}}
                  </div>
                </td>

                <td *ngIf="rowData['rank_group_analysis'] && rowData?.rank_group_analysis.no_change">
                  <div style="overflow:hidden;padding: 0px;margin: 0px;text-align: center;"
                    *ngIf="rowData?.rank_group_analysis.no_change">
                    {{rowData.ranked_serp_element.serp_item.rank_group}}
                  </div>
                </td>

                <td style="text-align: center;" *ngIf="!rowData['rank_group_analysis']">
                  {{rowData.ranked_serp_element.serp_item.rank_group}}
                </td>
                <!-- <td style="text-align: center;" *ngIf="!rowData['rank_group_analysis'] && checkRankChange(rowData?.ranked_serp_element.serp_item.rank_changes)">
                  <div class="row" tyle="text-align: center;" *ngIf="checkRankChange(rowData?.ranked_serp_element.serp_item.rank_changes)">
                    <div class="col-4" style="text-align: right;padding:0px">{{rowData.ranked_serp_element.serp_item.rank_changes.previous_rank_absolute}}</div>
                    <div class="col-4" *ngIf="rowData.ranked_serp_element.serp_item.rank_changes.is_up || rowData.ranked_serp_element.serp_item.rank_changes.is_down" class="material-icons" style="margin: 0 10px;" [ngClass]="{'rankInc':rowData.ranked_serp_element.serp_item.rank_changes.is_up,'rankDec':rowData.ranked_serp_element.serp_item.rank_changes.is_down}" >
                      trending_flat
                      
                    </div>
                    <div class="col-4" style="text-align: left;padding:0px" [ngClass]="{'rankInc':rowData.ranked_serp_element.serp_item.rank_changes.is_up,'rankDec':rowData.ranked_serp_element.serp_item.rank_changes.is_down}">
                      {{rowData.ranked_serp_element.serp_item.rank_absolute}}
                    </div>
                  </div>
                </td>
                <td style="text-align: center;" class="box new" *ngIf="!rowData['rank_group_analysis'] && !checkRankChange(rowData?.ranked_serp_element.serp_item.rank_changes) && rowData.ranked_serp_element.serp_item.rank_changes.is_new == true">
                  <div style="text-align: center;">
                    {{rowData.ranked_serp_element.serp_item.rank_group}}
                  </div>
                </td>
                <td *ngIf="!rowData['rank_group_analysis'] && !checkRankChange(rowData?.ranked_serp_element.serp_item.rank_changes) && 
                  !rowData.ranked_serp_element.serp_item.rank_changes.is_new &&
                  !rowData.ranked_serp_element.serp_item.rank_changes.is_up &&
                  !rowData.ranked_serp_element.serp_item.rank_changes.is_down">
                      {{rowData.ranked_serp_element.serp_item.rank_group}}
                </td> -->
                <!-- <td class="box new" style="text-align: center;" *ngIf="rowData?.rank_group_analysis.is_new">
                  {{rowData.ranked_serp_element.serp_item.rank_group}} 
                </td>
                <td style="overflow:hidden;padding: 0px;margin: 0px;text-align: center;"  *ngIf="rowData?.rank_group_analysis.no_change">
                  {{rowData.ranked_serp_element.serp_item.rank_group}} 
                </td> -->
                <td style="text-align: center;">
                  {{rowData.keyword_data.keyword_info.search_volume | number : '1.2-2'}}
                </td>
                <td style="text-align: center;">
                  {{rowData.keyword_data.keyword_info.cpc | number : '1.2-2'}}
                </td>
                <td style="text-align: center;">
                  {{rowData.keyword_data.keyword_info.competition|number : '1.2-2'}}
                </td>
                <td style="text-align: center;">
                  {{rowData.ranked_serp_element.serp_item.etv | number : '1.2-2'}}
                </td>
                <td style="text-align: center;">
                  <span class="material-icons" (click)="newTab(rowData.ranked_serp_element.serp_item.url)"
                    style="font-size: 1.5rem;cursor: pointer;color: #7e84f2">
                    open_in_new
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <ng-template #noLocalRank style="height: 100%;width: 100%;">
      <div id="noData" *ngIf="!dataExists && configurations">
        <div *ngIf="configurations['status']!='no-data'">You don't have organic rankings..</div>
      </div>
    </ng-template>
  </ng-template>
</div>